import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Button, Intent, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/labs';

import { isDev } from 'util/utils';

@inject('$auth', '$dashboard', '$dictionary', '$exports', '$library')
@observer
class DashboardToolbar extends Component {
  renderLiveUpdateMenu = () => {
    const { dashboard } = this.props;
    return (
      <Menu className="condensed">
        <MenuDivider title="Update every..." />
        {isDev && <MenuItem text="5 Seconds" onClick={() => dashboard.setUpdateInterval(5)} />}
        {isDev && <MenuItem text="10 Seconds" onClick={() => dashboard.setUpdateInterval(10)} />}
        <MenuItem text="60 Seconds" onClick={() => dashboard.setUpdateInterval(60)} />
        <MenuItem text="90 Seconds" onClick={() => dashboard.setUpdateInterval(90)} />
        <MenuItem text="120 Seconds" onClick={() => dashboard.setUpdateInterval(120)} />
      </Menu>
    );
  };

  render() {
    const { $dashboard, $exports, dashboard } = this.props;
    const { isEditing } = $dashboard;
    const { canEdit } = dashboard;

    const dashboardTitle = dashboard.get('dash_title') || '';
    const exportOptions = {
      path: `/api/portal/export/dashboards/${dashboard.id}`,
      fileName: `kentik-dashboard-${dashboardTitle.replace(/\s/g, '-').toLowerCase()}`,
      type: 'pdf'
    };

    const liveUpdateInterval = dashboard.get('liveUpdateInterval');

    return (
      <Fragment>
        {canEdit &&
          dashboard.hasItems && (
            <Button
              className="pt-medium pt-small"
              iconName={!isEditing ? 'new-grid-item' : 'none'}
              intent={isEditing ? Intent.PRIMARY : Intent.NONE}
              onClick={$dashboard.toggleEditing}
            >
              {isEditing ? 'Save Changes' : 'Edit Mode'}
            </Button>
          )}

        {canEdit &&
          dashboard.hasItems &&
          isEditing && (
            <Button
              className="pt-small"
              onClick={() => $dashboard.toggleEditing({ updateLayout: false })}
              text="Cancel"
              style={{ marginLeft: 4 }}
            />
          )}

        {liveUpdateInterval === 0 &&
          dashboard.hasItems && (
            <Popover2 content={this.renderLiveUpdateMenu()}>
              <Button
                className="pt-small"
                disabled={isEditing}
                intent={Intent.NONE}
                iconName="automatic-updates"
                text="Live Update"
                rightIconName="caret-down"
                style={{ marginLeft: 4 }}
              />
            </Popover2>
          )}
        {liveUpdateInterval !== 0 && (
          <Button
            className="pt-small"
            iconName="stop"
            intent={Intent.DANGER}
            onClick={() => dashboard.setUpdateInterval(0)}
            style={{ marginLeft: 4 }}
            text="Stop Updating"
          />
        )}
        {dashboard.hasItems && (
          <Tooltip2 placement="bottom" hoverOpenDelay={250} content="Refresh" disabled={isEditing}>
            <Button
              className="pt-small"
              disabled={isEditing}
              iconName="refresh"
              onClick={$dashboard.refresh}
              style={{ marginLeft: 4 }}
            />
          </Tooltip2>
        )}
        {dashboard.hasItems && (
          <Tooltip2 placement="bottom" hoverOpenDelay={250} disabled={isEditing} content="Export">
            <AnchorButton
              disabled={isEditing}
              iconName="export"
              className="pt-small"
              onClick={() => {
                const { dashboard: d, formState } = $dashboard;
                exportOptions.urlParams = formState.getValues();
                const parametric_fields = d.get('parametric_fields');
                if (d.get('parametric') && parametric_fields && parametric_fields.length) {
                  exportOptions.urlParams.parametric_fields = parametric_fields;
                }
                if (exportOptions.urlParams.time_format === 'Local') {
                  exportOptions.urlParams.time_format = moment().utcOffset();
                }
                $exports.fetchExport(exportOptions);
              }}
              loading={$exports.isLoading(exportOptions)}
              style={{ marginLeft: 4 }}
            />
          </Tooltip2>
        )}
      </Fragment>
    );
  }
}

export default DashboardToolbar;
