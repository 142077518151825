const fields = {
  user_full_name: {
    label: 'Full Name',
    placeholder: 'Enter full name'
  },

  user_email: {
    label: 'Email',
    placeholder: 'Enter email address...',
    rules: 'required|email',
    validateDebounceDurationMs: 500
  }
};

const options = {
  name: 'User Profile'
};

export { fields, options };
