import React from 'react';
import { action, computed, observable } from 'mobx';

import ConfirmDialog from 'components/ConfirmDialog';

class ConfirmDialogModel {
  @observable
  isOpen = false;

  model;

  onConfirm;

  onCancel;

  confirmText;

  cancelButtonText;

  confirmButtonText;

  constructor({ model, onConfirm, onCancel, confirmText, cancelButtonText, confirmButtonText }) {
    this.model = model;
    this.onConfirm = onConfirm;
    this.onCancel = onCancel;
    this.confirmText = confirmText;
    this.cancelButtonText = cancelButtonText;
    this.confirmButtonText = confirmButtonText;
  }

  @action
  handleConfirm = () => {
    const result = this.onConfirm ? this.onConfirm() : this.model.destroy();

    if (result && result.then) {
      result.then(
        action(() => {
          this.isOpen = false;
        })
      );
    } else {
      this.isOpen = false;
    }
  };

  @action
  handleCancel = () => {
    if (this.onCancel) {
      this.onCancel();
    }

    this.isOpen = false;
  };

  @action
  showConfirmDialog = () => {
    this.isOpen = true;
  };

  @computed
  get component() {
    return (
      <ConfirmDialog
        title={this.model && this.model.removalConfirmText.title}
        text={this.model ? this.model.removalConfirmText.text : this.confirmText}
        confirmButtonText={this.model ? this.model.removalConfirmText.buttonText : this.confirmButtonText}
        cancelButtonText={this.cancelButtonText}
        isOpen={this.isOpen}
        model={this.model}
        onConfirm={this.handleConfirm}
        onCancel={this.handleCancel}
      />
    );
  }
}

export default ConfirmDialogModel;
