import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Page, PageBody } from 'components/page';
import { Card } from 'components';
import { showErrorToast, showSuccessToast } from 'components/Toast';

@inject('$auth')
class SuspendedCompany extends Component {
  handleRequest = () => {
    this.props.$auth.requestAssistance().then(
      () => {
        showSuccessToast('Your request has been received. Kentik will be in touch shortly.', {
          timeout: 0
        });
      },
      () => {
        showErrorToast(
          'Your request could not be processed at this time due to technical issues. Please try again later.',
          {
            timeout: 0
          }
        );
      }
    );
  };

  render() {
    return (
      <Page title="Suspended Company">
        <PageBody>
          <Flex flexColumn align="center" col={12} p={8}>
            <Card
              title="Your company's account is suspended"
              bigTitle
              titleIntent="warning"
              p={1}
              footer={
                <Flex flexAuto justify="flex-end">
                  <Button text="Request Assistance" intent={Intent.PRIMARY} onClick={this.handleRequest} />
                </Flex>
              }
            >
              <Box pt={2}>Please contact Kentik to restore access via the button below:</Box>
            </Card>
          </Flex>
        </PageBody>
      </Page>
    );
  }
}

export default SuspendedCompany;
