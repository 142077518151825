import React, { Component } from 'react';
import ResultRow from 'views/Search/ResultRow';

class Documentation extends Component {
  title = 'Kentik Documentation';

  render() {
    const { result } = this.props;
    return <ResultRow type={this.title} icon="help" url={result.url} nameField="title" {...this.props} />;
  }
}

export default Documentation;
