import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, MenuItem, Popover, Position } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import SelectInput from 'components/forms/select/SelectInput';

const tetherOptions = {
  offset: '-3px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

@inject('$savedFilters')
@observer
class SavedFilterSelector extends Component {
  static defaultProps = {
    values: [],
    buttonText: 'Add Saved Filter',
    buttonClassName: 'pt-medium pt-fill',
    buttonIcon: 'add-to-artifact'
  };

  state = {
    optionsFilter: ''
  };

  handleFilterOptions = e => {
    const { value } = e.target;

    this.setState({ optionsFilter: value });
  };

  getFilteredOptions(options) {
    const { optionsFilter } = this.state;

    if (optionsFilter) {
      const filter = optionsFilter.toLowerCase();
      return options.filter(option => option.label.toLowerCase().includes(filter));
    }

    return options;
  }

  renderSelector() {
    const { optionsFilter } = this.state;
    const { $savedFilters, onAdd, values } = this.props;

    const options = this.getFilteredOptions($savedFilters.selectOptions);

    return (
      <div>
        <Box p={0.5}>
          <SelectInput
            type="text"
            className="pt-input pt-small pt-fill"
            placeholder="Filter saved filter options..."
            autoFocus
            onChange={this.handleFilterOptions}
            value={optionsFilter}
          />
        </Box>
        <Box p={0.5} pt={0} className="pt-menu pt-small" style={{ maxHeight: 300, overflow: 'auto' }}>
          <div>
            {options.map(option => {
              const isFilterAdded = values.some(({ filter_id }) => filter_id === option.id);

              return (
                <MenuItem
                  key={option.value}
                  disabled={isFilterAdded}
                  text={option.label}
                  label={
                    <span>
                      <strong>{option.numFilters}</strong> Filters
                    </span>
                  }
                  onClick={() => onAdd(option)}
                />
              );
            })}
          </div>
        </Box>
      </div>
    );
  }

  render() {
    const { buttonText, buttonClassName, buttonIcon } = this.props;

    return (
      <Popover
        content={this.renderSelector()}
        popoverClassName="saved-filters-selector-popover pt-minimal"
        tetherOptions={tetherOptions}
        position={Position.BOTTOM_LEFT}
      >
        <Button iconName={buttonIcon} className={buttonClassName}>
          {buttonText}
        </Button>
      </Popover>
    );
  }
}

export default SavedFilterSelector;
