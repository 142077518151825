import { action, observable, computed } from 'mobx';
import { showSuccessToast } from 'components/Toast';
import CustomDimensionCollection from 'models/customDimensions/CustomDimensionCollection';
import CustomDimension from 'models/customDimensions/CustomDimension';
import AsGroupCollection from 'models/asGroups/AsGroupCollection';
import api from 'util/api';

const MARKET_DIMENSIONS = ['kt_src_market', 'kt_dst_market'];
const MARKET_DIMENSION_DIRECTIONS = { kt_src_market: 'src', kt_dst_market: 'dst' };

class CustomDimensionStore {
  @observable
  collection = new CustomDimensionCollection();

  @observable
  markets = [];

  @observable
  marketDimensions = {};

  @observable
  marketsLoaded = false;

  @observable
  asGroups = new AsGroupCollection();

  @action
  loadMarketDimension() {
    this.marketsLoaded = false;

    return Promise.all(
      ['src', 'dst'].map(direction =>
        api
          .get(`/api/portal/markets/${direction}`)
          .then(response => new CustomDimension(response.customDimension))
          .then(
            action(dimension => {
              this.marketDimensions[dimension.get('name')] = dimension;
              // populators is paged collection, so set limit 300 to override the default fetch amount.
              dimension.populators.setSortState('id');
              return dimension.populators;
            })
          )
      )
    ).then(
      action(([srcPopulators, dstPopulators]) => {
        this.markets = srcPopulators.map(model => {
          const name = model.get('value');
          const countries = model.get('country');

          return {
            srcId: model.id,
            dstId: dstPopulators.find({ value: name }).id,
            name,
            countries: countries || []
          };
        });
        this.marketsLoaded = true;
      })
    );
  }

  saveMarket(market) {
    const existingMarket = this.markets.find(m => m.srcId === market.srcId);
    if (!existingMarket) {
      this.markets.push(market);
    } else {
      Object.assign(existingMarket, market);
    }

    if (Object.keys(this.marketDimensions).length === MARKET_DIMENSIONS.length) {
      // Update src and dest dimensions
      return Promise.all(
        MARKET_DIMENSIONS.map(name => {
          let populator;
          const idField = `${MARKET_DIMENSION_DIRECTIONS[name]}Id`;

          if (existingMarket) {
            // Update populator
            populator = this.marketDimensions[name].populators.find({
              id: market[idField]
            });
          } else {
            populator = this.marketDimensions[name].populators.forge();
          }

          return populator
            .save(
              {
                value: market.name,
                direction: MARKET_DIMENSION_DIRECTIONS[name],
                country: market.countries || []
              },
              {
                toast: false
              }
            )
            .then(
              action(() => {
                market[idField] = populator.id;
                return true;
              })
            );
        })
      ).then(([srcSuccess, dstSuccess]) => {
        if (srcSuccess && dstSuccess) {
          showSuccessToast(`Custom Geo ${market.name} saved successfully`, {
            title: existingMarket ? 'Custom Geo Updated' : 'Custom Geo Added'
          });
        }
      });
    }

    console.error('No src/dst custom geo dimensions defined');

    return Promise.resolve();
  }

  @action
  deleteMarket(market) {
    return Promise.all(
      MARKET_DIMENSIONS.map(name => {
        const idField = `${MARKET_DIMENSION_DIRECTIONS[name]}Id`;
        const populator = this.marketDimensions[name].populators.find({ id: market[idField] });
        return populator.destroy();
      })
    ).then(
      action(() => {
        this.markets.remove(market);
      })
    );
  }

  @computed
  get customDimensionOptions() {
    return this.collection.map(customDimension => ({
      value: customDimension.get('name'),
      label: customDimension.get('display_name')
    }));
  }
}

export default new CustomDimensionStore();
