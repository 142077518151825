import React from 'react';
import { observable, action } from 'mobx';

import { showSuccessToast, showInfoToast, showErrorToast } from 'components/Toast';
import Export from 'components/Export';
import Collection from 'models/Collection';

class ExportsStore {
  @observable
  recentExports = new Collection([], { sortState: { field: 'generated', direction: 'desc' } });

  @observable
  loadingExports = observable.array();

  @observable
  dialogVisible = false;

  @action
  addPayload = (payload, options) => {
    const entry = { payload, ...options, generated: new Date() };
    this.recentExports.add(entry);
    showSuccessToast(
      <div>
        <div>Your export is ready for download.</div>
        <div>
          <Export {...entry} />
        </div>
      </div>,
      {
        title: 'Export complete',
        timeout: 0,
        action: {
          onClick: this.toggleDialogVisible,
          className: 'pt-medium',
          text: 'View Recent Exports'
        }
      }
    );
  };

  @action
  addLoadingExport = options => {
    this.loadingExports.push(options);
    showInfoToast(
      <span>
        Your export is processing. You may continue with other activities,
        <br />
        and we will notify you when your export is complete.
      </span>,
      {
        title: 'Export requested',
        timeout: 4000
      }
    );
  };

  @action
  clearLoadingExport = options => {
    const loadingExport = this.isLoading(options);
    this.loadingExports.remove(loadingExport);
  };

  @action
  toggleDialogVisible = () => {
    this.dialogVisible = !this.dialogVisible;
  };

  @action
  fetchExport(options) {
    const { urlParams, path, type } = options;
    const req = new XMLHttpRequest();

    let reqPath = `${path}?format=${type}`;
    if (urlParams) {
      reqPath += `&urlParams=${encodeURIComponent(JSON.stringify(urlParams))}`;
    }
    req.open('GET', reqPath, true);
    req.responseType = 'blob';
    req.timeout = 120000;

    this.addLoadingExport(options);

    req.onload = () => {
      this.clearLoadingExport(options);

      if (req.status >= 400) {
        showErrorToast(null, {
          title: 'Export failed',
          timeout: 5000,
          message:
            'Your export failed to process. Please try again, or contact support@kentik.com for further assistance.'
        });
      } else {
        this.addPayload(req.response, options);
      }
    };

    req.onerror = () => {
      this.clearLoadingExport(options);
      showErrorToast(null, {
        title: 'Export failed',
        timeout: 5000,
        message:
          'Your export failed to process. Please try again, or contact support@kentik.com for further assistance.'
      });
    };

    req.send();
  }

  isLoading({ path, type }) {
    return this.loadingExports.find(exp => exp.path === path && exp.type === type);
  }
}

export default new ExportsStore();
