import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$sites')
class Sites extends Component {
  title = 'Site';

  url = '/admin/sites';

  render() {
    const { result, $sites } = this.props;
    return (
      <ResultRow
        type={this.title}
        icon="material-icons:location_on"
        url={this.url}
        nameField="title"
        collection={$sites.collection}
        {...this.props}
      >
        <span>
          {result.city} {result.region} {result.countryName}
        </span>
      </ResultRow>
    );
  }
}

export default Sites;
