import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Box } from 'components/flexbox';
import { Form, FormDialog, Field, Textarea, Input } from 'components/forms';

const fields = {
  filter_name: {
    label: 'Filter Name',
    rules: 'required'
  },

  filter_description: {
    label: 'Filter Description'
  }
};

@Form({ fields })
@observer
export default class SaveFiltersDialog extends Component {
  onSubmit = (form, values) => {
    const { model, onClose } = this.props;

    model.set(values);

    return model.save().then(onClose);
  };

  render() {
    const { model, onClose } = this.props;
    const isSaving = model && model.isRequestActive('updating');
    const isPreset = model && model.isPreset;

    return (
      <FormDialog
        {...this.props}
        title="Save Filter"
        entityName="Filter"
        style={{ width: 620 }}
        formActionsProps={{ onSubmit: this.onSubmit, onCancel: onClose, showRemove: false }}
        formComponent={
          <Box>
            <Box className="pt-callout pt-intent-primary pt-icon-info-sign" mb={2}>
              Save your current filter set as an easily reusable Saved Filter that can be used wherever filtering is
              supported. A filter created and saved by a Kentik user is available to all other users in the same
              organization.
              <br />
              <a href="https://kb.kentik.com/?Cb07.htm#Cb07-About_Filters" rel="noopener noreferrer" target="_blank">
                Read More about Saved Filters
              </a>
            </Box>
            <Field name="filter_name" disabled={isSaving || isPreset} autoFocus>
              <Input />
            </Field>
            <Field name="filter_description" disabled={isSaving || isPreset}>
              <Textarea rows="2" />
            </Field>
          </Box>
        }
      />
    );
  }
}
