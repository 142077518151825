import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box, Flex } from 'components/flexbox';
import Icon from 'components/Icon';
import DashLink from './DashLink';

@inject('$dashboards')
@observer
export default class DashboardNavigationDisplay extends Component {
  componentDidMount = () => {
    this.props.model.fetchDependentsAndDependencies();
  };

  render() {
    const { model, $dashboards } = this.props;
    const dependents = model.get('dependents');
    const dependencies = model.get('dependencies');

    return (
      <Box>
        <h5>Dashboard Dependencies</h5>
        <Flex flexColumn justify="center" mt={2}>
          {dependents && (
            <Box mb={1}>
              <h6 className="pt-text-muted no-margin">Navigate From</h6>
              <p className="pt-text-muted pt-text-smaller">Dashboards on which this dashboard is nested.</p>
              <Box mt={1} p={1.5} className="pt-card flat">
                {!dependents.length && <div className="pt-text-muted">None</div>}
                {dependents.map((dependent, index) => {
                  const dashboard = $dashboards.getDashboard(dependent.id);
                  const marginBottom = dependents.length && index !== dependents.length - 1 ? 0.5 : undefined;
                  if (dashboard) {
                    return (
                      <DashLink key={`dashlink_dependent_${dependent.id}`} dashboard={dashboard} mb={marginBottom} />
                    );
                  }
                  return (
                    <Box
                      key={`dashlink_dependent_${dependent.id}`}
                      mb={marginBottom}
                      className="pt-text-overflow-ellipsis"
                      title={`${dependent.dash_title} ${dependent.company_name}(${dependent.company_id})`}
                    >
                      <span style={{ marginRight: 4 }}>{dependent.dash_title}</span>
                      <span className="pt-text-muted">({dependent.company_name})</span>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
          <Icon
            name="pt-icon-caret-down"
            className="dashboard-navigation-label"
            large
            muted
            style={{ marginBottom: 8 }}
          />
          <h2 className="dashboard-navigation-label">THIS DASHBOARD</h2>
          <Icon name="pt-icon-caret-down" className="dashboard-navigation-label" large muted />
          {dependencies && (
            <Box mt={1}>
              <h6 className="pt-text-muted no-margin">Navigate To</h6>
              <p className="pt-text-muted pt-text-smaller">Dashboards nested on any panel of this dashboard.</p>
              <Box mt={1} p={1.5} className="pt-card flat">
                {!dependencies.length && <div className="pt-text-muted">None</div>}
                {dependencies.map((dependency, index) => {
                  const dashboard = $dashboards.getDashboard(dependency.id);
                  const marginBottom = dependencies.length && index !== dependencies.length - 1 ? 0.5 : undefined;

                  if (dashboard) {
                    return (
                      <DashLink
                        key={`dashlink_dependency_${dependency.id}`}
                        dashboard={dashboard}
                        mb={dependencies.length && index !== dependencies.length - 1 ? 0.5 : undefined}
                      />
                    );
                  }
                  return (
                    <Box
                      key={`dashlink_dependency_${dependency.id}`}
                      mb={marginBottom}
                      className="pt-text-overflow-ellipsis"
                      title={`${dependency.dash_title})`}
                    >
                      <span style={{ marginRight: 4 }}>{dependency.dash_title}</span>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Flex>
      </Box>
    );
  }
}
