import { computed } from 'mobx';
import Collection from '../Collection';
import Otp from './Otp';

export default class OtpCollection extends Collection {
  get url() {
    return '/api/portal/totp/';
  }

  get model() {
    return Otp;
  }

  @computed
  get hasEnabledOtp() {
    return this.models.some(otp => otp.get('enabled'));
  }
}
