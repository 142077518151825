import { action, computed, observable } from 'mobx';
import api from 'util/api';

class SearchStore {
  @observable
  showSearch = false;

  @observable
  isSearchLoading = false;

  @observable.shallow
  results = undefined;

  @observable
  queryString = '';

  formatQueryString = queryString =>
    queryString
      .split(' ')
      .filter(word => word !== '')
      .join(' ');

  flattenResults = results => {
    const newResults = [];

    Object.keys(results).forEach(key => {
      if (Array.isArray(results[key])) {
        newResults.push(
          ...results[key].map(resultRow => ({
            resultCategory: key,
            ...resultRow
          }))
        );
      }
    });

    return newResults;
  };

  @computed
  get totalResults() {
    const items = this.results ? Object.keys(this.results) : [];

    return items.reduce(
      (total, item) => total + (Array.isArray(this.results[item]) ? this.results[item].length : 0),
      0
    );
  }

  getSortedResults = results =>
    this.flattenResults(results || this.results).sort((rowA, rowB) => (rowA.score < rowB.score ? 1 : -1));

  @action
  toggleSearch = () => {
    this.showSearch = !this.showSearch;
  };

  @action
  focusSearch = () => {
    document.getElementsByName('universalSearch')[0].focus();
  };

  @action
  closeSearch = () => {
    this.showSearch = false;
  };

  @action
  query(queryString, timestamp, docsOnly = false) {
    this.isSearchLoading = true;
    this.queryString = queryString;

    return api
      .get(`/api/portal/search?q=${this.formatQueryString(queryString)}&t=${timestamp}${docsOnly ? '&docsOnly=1' : ''}`)
      .then(
        response => {
          this.isSearchLoading = false;
          this.results = response;

          return response;
        },
        () => {
          this.results = undefined;
          this.isSearchLoading = false;
        }
      );
  }
}

export default new SearchStore();
