import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Intent, FormGroup } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Field, Input, Select, InputMultiple, Textarea, Switch } from 'components/forms';
import DeviceLabelSelector from 'views/Admin/DeviceGroups/DeviceLabelSelector';
import Icon from 'components/Icon';

const deviceSubtypeOptionRenderer = props => {
  const {
    className,
    disabled,
    field,
    iconCls,
    iconName,
    key,
    label,
    selectItem,
    selected,
    style,
    separator = false,
    value
  } = props;

  if (separator) {
    return <hr key="separator" style={{ margin: '4px 8px' }} />;
  }

  const onClick = !selected && !disabled ? () => selectItem(field, value) : undefined;
  const icon = iconCls || iconName;
  return (
    <Flex key={key || value} className={className} onClick={onClick} style={{ ...style, height: 48 }} align="center">
      {icon && <Icon name={icon} style={{ fontSize: 24, marginRight: 12 }} />}
      <div>{label}</div>
    </Flex>
  );
};

@inject('$app', '$dashboards', '$devices', '$dictionary')
@observer
class GeneralSettings extends Component {
  static contextTypes = {
    form: any
  };

  componentWillMount() {
    this.updateSendingIpsFieldRules();
  }

  getSubtypeOptions() {
    const { $dictionary } = this.props;
    const { device_subtypes } = $dictionary.dictionary;
    return Object.values(device_subtypes)
      .reduce((acc, deviceSubtype) => {
        if (!deviceSubtype.metadata.cloud) {
          acc.push({
            value: deviceSubtype.device_subtype,
            label: deviceSubtype.display_name,
            iconName: deviceSubtype.icon
          });
        }
        return acc;
      }, [])
      .sort((a, b) => {
        if (b.value === 'router') {
          return 4;
        }
        if (b.value === 'kprobe') {
          return 3;
        }
        if (a.value === 'kprobe' || a.value === 'router') {
          return -1;
        }

        if (a.value < b.value) {
          return -1;
        } else if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
  }
  // getTypeOptions() {
  //   const { model } = this.props;
  //   const options = [
  //     { value: 'router', label: 'Router' },
  //     { value: 'host-nprobe-dns-www', label: 'KPb (kprobe-beta)' }
  //   ];
  //   const legacyOptions = [{ value: 'host-nprobe-basic', label: 'nHst (host-nprobe-basic)' }];
  //   return model.isNew ? options : options.concat(legacyOptions);
  // }

  updateSendingIpsFieldRules() {
    const { form } = this.context;
    const { $devices } = this.props;
    const sendingIpsField = form.getField('sending_ips');
    const deviceSubtype = form.getValue('device_subtype');
    const deviceType = $devices.getDeviceTypeFromSubtype(deviceSubtype);

    if (deviceType === 'router') {
      sendingIpsField.setRules('required|array|min:1');
      sendingIpsField.setChildren({
        rules: 'required|ip',
        messages: {
          required: 'You must enter a Sending IP address',
          ip: 'Sending IP must be a valid IP address'
        }
      });
    } else {
      sendingIpsField.setRules('');
      sendingIpsField.setChildren({
        rules: 'ip',
        messages: {
          ip: 'Sending IP must be a valid IP address'
        }
      });
    }
  }

  handleDeviceTypeChange = () => {
    this.updateSendingIpsFieldRules();
  };

  render() {
    const { model, $devices, $dictionary, $app } = this.props;
    const { companySettings } = $devices;
    const { form } = this.context;

    const device_subtype = form.getValue('device_subtype');

    const deviceType = $devices.getDeviceTypeFromSubtype(device_subtype);

    const device_name = form.getValue('device_name');

    const isnProbe = deviceType === 'host-nprobe-basic';
    const isKP = deviceType === 'host-nprobe-dns-www';
    const isRouter = deviceType === 'router';
    const kbLinkFlows = $dictionary.getHelpUrl('admin', 'configuringFlows');
    const kbLinkKprobe = $dictionary.getHelpUrl('admin', 'configuringKprobe');

    const sampleRateHasChanged =
      (model.isNew &&
        `${form.getValue('device_sample_rate')}` !== `${model.getDefaultSampleRateForDeviceType(deviceType)}`) ||
      (!model.isNew && `${form.getValue('device_sample_rate')}` !== `${form.initialValues.device_sample_rate}`);

    return (
      <Box col={12}>
        {isRouter && (
          <Box className="pt-callout pt-intent-primary" mb={2}>
            <div>
              Configure {device_name ? <strong>{device_name}</strong> : 'this device'} to send flows to Kentik with the
              following details:
            </div>
            <Flex>
              <Box ml={2} style={{ width: 180 }}>
                Kentik Ingest IP:
              </Box>
              <Box className="pt-intent-primary-text pt-monospace-text">
                <b>{companySettings.flow_ips.join(', ')}</b>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Box ml={2} style={{ width: 180 }}>
                Kentik Ingest UDP port:
              </Box>
              <Box className="pt-intent-primary-text pt-monospace-text">
                <b>{companySettings.port}</b>
              </Box>
            </Flex>
            <AnchorButton
              rel="noopener noreferrer"
              className="pt-minimal"
              intent={Intent.PRIMARY}
              iconName="share"
              target="_blank"
              href={kbLinkFlows}
            >
              <strong>Need help configuring Flows?</strong>
            </AnchorButton>
          </Box>
        )}

        {isnProbe && (
          <Box className="pt-callout pt-intent-primary" mb={2}>
            <h5>nProbe Command Line Info</h5>
            <p>
              Download nprobe:{' '}
              <a href="http://packages.ntop.org" rel="noopener noreferrer" target="_blank">
                http://packages.ntop.org
              </a>
            </p>

            <p>
              The direct static version, "nprobes," requires no dependencies and is available in each package directory
              as a standalone executable.
            </p>

            <div>
              <p>Execute</p>
              <pre>nprobe: ./nprobes --kentik-host -i eth0 &</pre>
            </div>

            <p className="no-margin">
              In the command above, replace eth0 with the interface that you wish to monitor. You may run multiple
              instances for multiple interfaces. Only 1 Kentik "device" is needed per host, regardless of how many
              nprobe instances are running on that host for multiple interfaces.
            </p>
          </Box>
        )}

        {isKP && (
          <Box className="pt-callout pt-intent-primary" mb={2}>
            <AnchorButton
              rel="noopener noreferrer"
              className="pt-minimal"
              intent={Intent.PRIMARY}
              iconName="share"
              target="_blank"
              href={kbLinkKprobe}
            >
              <strong>Need help installing and configuring kprobe?</strong>
            </AnchorButton>
          </Box>
        )}

        <Field name="device_name" autoFocus>
          <Input />
        </Field>

        <Field name="device_description">
          <Textarea rows="2" />
        </Field>

        <Field
          name="device_subtype"
          inputStyle={{ width: 290 }}
          options={this.getSubtypeOptions()}
          onChange={this.handleDeviceTypeChange}
        >
          <Select menuWidth={290} optionRenderer={deviceSubtypeOptionRenderer} />
        </Field>

        <FormGroup
          label={
            <Fragment>
              <span style={{ paddingRight: 6 }}>Labels</span>{' '}
              <Link to={$app.adjustUrlForShim(window.location, '/admin/devices/labels')}>
                <small className="pt-normal">Manage</small>
              </Link>
            </Fragment>
          }
          helperText="Assign labels to ease discoverability of this device"
        >
          <DeviceLabelSelector device={model} form={form} field={form.getField('labels')} />
        </FormGroup>

        {deviceType === 'host-nprobe-dns-www' && (
          <Field name="cdn_attr">
            <Switch />
          </Field>
        )}

        <hr />
        <h5>Flow export configuration</h5>
        <br />

        <Field name="sending_ips" transferProps={false}>
          <InputMultiple name="sending_ips" inputStyle={{ width: 250 }} addButtonText="Add Sending IP" />
        </Field>

        <Flex>
          <Box>
            <Field name="device_sample_rate" helpText={this.sampleRateHelpText} inputStyle={{ width: 100 }}>
              <Input />
            </Field>
          </Box>
          {deviceType === 'host-nprobe-dns-www' &&
            sampleRateHasChanged && (
              <div>
                <Box className="pt-callout pt-intent-danger" p={2} ml={2}>
                  kprobe sample rate changes for instances where sample rate is CLI controlled will cause the kprobe
                  process to exit. We recommend running kprobe supervised with a time between restarts of 30 seconds and
                  give up after 5 retries.
                </Box>
              </div>
            )}
        </Flex>
      </Box>
    );
  }
}

export default GeneralSettings;
