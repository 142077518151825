import { computed } from 'mobx';
import BaseModel from 'models/BaseModel';
import $dictionary from 'stores/$dictionary';

class InterfaceSettings extends BaseModel {
  get defaults() {
    return $dictionary.dictionary.interfaceClassification.settingsDefaults;
  }

  get url() {
    return '/api/portal/rules/settings';
  }

  /**
   * Override the isNew check because this particular model doesn't return with an `id`, but instead `company_id`.
   */
  @computed
  get isNew() {
    return false;
  }

  get jsonRoot() {
    return 'settings';
  }
}

export default InterfaceSettings;
