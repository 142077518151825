import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import FilterOptions from 'components/FilterOptions/FilterOptions';
import { groupHasFilters } from 'components/FilterOptions/FilterGroup';

@observer
export default class FilterOptionsDialog extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    fieldName: 'filters',
    showSaveFiltersButton: true,
    showSavedFilters: true
  };

  state = {
    lastValue: null
  };

  componentWillMount() {
    this.setState({ lastValue: JSON.stringify(this.getField().getValue()) });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({ lastValue: JSON.stringify(this.getField().getValue()) });
    }
  }

  handleCancel = e => {
    // in this handler, we always want to close the dialog and prevent any other hotkeys/actions from firing.
    // method 1 to get here: ESC key (27). In this case, we want to revert changes
    // method 2 to get here: Click cancel button. In this case, we want to revert changes
    // method 3 to get here: click outside dialog. In this case, we want to preserve changes

    // standard event stoppage
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // handle revert cases
    if (e && (e.keyCode === 27 || e.currentTarget.className === 'pt-button')) {
      this.props.onClose();

      const { lastValue } = this.state;
      if (lastValue) {
        this.getField().setValue(JSON.parse(lastValue));
      }
    } else {
      this.props.onClose();
    }
  };

  removeEmptyGroups() {
    const groups = this.getField();

    groups.forEach((group, index) => {
      // if there are no filters in a group, the group removes itself.
      if (!groupHasFilters(group)) {
        groups.remove(index);
      }
    });
  }

  handleSave = () => {
    this.removeEmptyGroups();
    this.props.onClose();
  };

  getField() {
    const { form } = this.context;
    const { field, fieldName } = this.props;

    return field || form.getField(`${fieldName}.filterGroups`);
  }

  render() {
    const { isOpen, title = 'Filtering Options', dialogRef } = this.props;

    const field = this.getField();

    const hasErrors = field.errors && field.errors.length > 0;

    return (
      <Dialog
        {...this.props}
        isOpen={isOpen}
        onClose={this.handleCancel}
        style={{ width: 800, top: 50 }}
        title={title}
        transitionName="pt-dialog"
      >
        <Box className="pt-dialog-body" pb={1} boxRef={dialogRef}>
          <Box flexAuto>
            <FilterOptions {...this.props} />
          </Box>
        </Box>
        <Flex className="pt-dialog-footer" justify="flex-end">
          <Button text="Cancel" onClick={this.handleCancel} style={{ marginRight: 8, width: 110 }} />
          <Button
            className="pt-medium"
            intent={Intent.PRIMARY}
            text="Save"
            style={{ width: 110, marginLeft: 8 }}
            onClick={this.handleSave}
            disabled={hasErrors}
          />
        </Flex>
      </Dialog>
    );
  }
}
