/* eslint-disable no-bitwise, func-names, no-continue */
import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { extent as d3_extent } from 'd3-array';

export default function HorizonChart() {
  let colors = ['#313695', '#4575b4', '#74add1', '#abd9e9', '#fee090', '#fdae61', '#f46d43', '#d73027'];
  let bands = colors.length >> 1; // number of bands in each direction (positive / negative)
  let width; // width in pixels
  let height;
  let offsetX = 0;
  let step = 1;
  let spacing = 0;
  let mode = 'offset';
  let title = null;
  let extent = null;
  let canvas = null;
  let span = null;
  let marker = null;
  let data = [];
  const y = scaleLinear();

  // Appends a canvas element to the current element
  // and draws a horizon graph based on data & settings
  function horizonChart(inData) {
    data = inData;
    const selection = select(this);
    const dIncrement = step + spacing;

    // update the width
    // width = horizon.node().getBoundingClientRect().width;
    width = dIncrement * data.length;
    canvas = selection.append('canvas');

    canvas.attr('width', width).attr('height', height);

    selection
      .append('span')
      .attr('class', 'title')
      .text(title);

    span = selection.append('span').attr('class', 'value');
    marker = selection
      .append('span')
      .attr('class', 'marker')
      .style('display', 'none');

    const context = canvas.node().getContext('2d');

    // update the y scale, based on the data extents
    const _extent = extent || d3_extent(data);

    const max = Math.max(-_extent[0], _extent[1]);
    y.range([0, height]);
    y.domain([0, max]);

    // Draw ----------------------------------------------------------------------------

    context.clearRect(0, 0, width, height);
    // context.translate(0.5, 0.5);

    // the data frame currently being shown:
    const increment = step + spacing;
    const startIndex = ~~Math.max(0, -(offsetX / increment));
    const endIndex = ~~Math.min(data.length, startIndex + width / increment);

    // skip drawing if there's no data to be drawn
    if (startIndex > data.length) return;

    // we are drawing positive & negative bands separately to avoid mutating canvas state
    let negative = false;
    let b = 0;
    // draw positive bands
    for (; b < bands; b += 1) {
      context.fillStyle = colors[bands + b];

      // Adjust the range based on the current band index.
      const bExtents = (b + 1 - bands) * height;
      y.range([bands * height + bExtents, bExtents]);

      // only the current data frame is being drawn i.e. what's visible:
      for (let i = startIndex, value; i < endIndex; i += 1) {
        value = data[i];
        if (value <= 0) {
          negative = true;
          continue;
        }
        if (value === undefined) continue;
        context.fillRect(offsetX + i * increment, y(value), step, y(0) - y(value));
      }
    }

    // draw negative bands
    if (negative) {
      // mirror the negative bands, by flipping the canvas
      if (mode === 'offset') {
        context.translate(0, height);
        context.scale(1, -1);
      }

      for (b = 0; b < bands; b += 1) {
        context.fillStyle = colors[bands - b - 1];

        // Adjust the range based on the current band index.
        const bExtents = (b + 1 - bands) * height;
        y.range([bands * height + bExtents, bExtents]);

        // only the current data frame is being drawn i.e. what's visible:
        for (let j = startIndex, nvalue; j < endIndex; j += 1) {
          nvalue = data[j];
          if (nvalue >= 0) continue;
          context.fillRect(offsetX + j * increment, y(-nvalue), step, y(0) - y(-nvalue));
        }
      }
    }
  }

  horizonChart.showValue = function(index, formatter) {
    span.text(formatter ? formatter(data[index]) : data[index]);
  };

  horizonChart.canvas = function(_) {
    return arguments.length ? ((canvas = _), horizonChart) : canvas;
  };

  horizonChart.span = function() {
    return span;
  };

  horizonChart.marker = function() {
    return marker;
  };

  horizonChart.y = function() {
    return y;
  };

  // Array of colors representing the number of bands
  horizonChart.colors = function(_) {
    if (!arguments.length) return colors;
    colors = _;

    // update the number of bands
    bands = colors.length >> 1;

    return horizonChart;
  };

  // get/set the height of the graph
  horizonChart.height = function(_) {
    return arguments.length ? ((height = _), horizonChart) : height;
  };

  // get/set the step of the graph, i.e. the width of each bar
  horizonChart.step = function(_) {
    return arguments.length ? ((step = _), horizonChart) : step;
  };

  // get/set the spacing between the bars of the graph
  horizonChart.spacing = function(_) {
    return arguments.length ? ((spacing = _), horizonChart) : spacing;
  };

  // get/set the title of the horizon
  horizonChart.title = function(_) {
    return arguments.length ? ((title = _), horizonChart) : title;
  };

  // mirror or offset
  horizonChart.mode = function(_) {
    return arguments.length ? ((mode = _), horizonChart) : mode;
  };

  // get/set the extents of the Y axis. If not set the extents are derived from the data
  horizonChart.extent = function(_) {
    return arguments.length ? ((extent = _), horizonChart) : extent;
  };

  horizonChart.offsetX = function(_) {
    return arguments.length ? ((offsetX = _), horizonChart) : offsetX;
  };

  // the data frame currently being shown:
  horizonChart.indexExtent = function() {
    const increment = step + spacing;
    const startIndex = -offsetX / increment;
    const endIndex = startIndex + width / increment;

    return [startIndex, endIndex];
  };

  return horizonChart;
}
