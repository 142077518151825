import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Field, Input, Select } from 'components/forms';

@inject('$dashboard', '$deviceGroups', '$devices', '$dictionary', '$lookups', '$sites')
@observer
class ApplyParametricOptions extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    applyButtonText: 'Apply Changes',
    className: 'banner pt-normal',
    inputClassName: 'pt-fill'
  };

  componentWillMount() {
    const { form } = this.context;
    const { getFilterValueValidator } = this.props.$dictionary;
    const { queryFilters } = this.props.$dictionary.dictionary;
    const { errorMessages } = queryFilters;
    const parametric_fields = form.getField('parametric_fields').at(0);
    const type = parametric_fields.type.getValue();
    const filter = queryFilters.parametricFilterTypes.find(filterType => type === filterType.type);
    if (filter) {
      const filterValidatorRules = getFilterValueValidator(filter.filterFields[0]);

      // If you have a validation that's a regex and a value that's not a string, you're in trouble
      // Values here should always be strings anyway, but sometimes it appears they're not. /shrug
      if (filterValidatorRules.some(rule => rule.startsWith && rule.startsWith('regex'))) {
        parametric_fields.value.transform = {
          in: value => `${value}`
        };
        parametric_fields.value.setValue(`${parametric_fields.value.getValue()}`);
      }

      // dynamically set the rule and error message for the parametric field
      parametric_fields.value.setRules(filterValidatorRules);
      parametric_fields.value.messages = { regex: `${type} ${errorMessages[filter.filterFields[0]]}` };
    }
  }

  handleKeyPress = e => {
    const { handleSubmit } = this.props;
    const { form } = this.context;

    if (e && e.key === 'Enter' && handleSubmit && form.valid) {
      handleSubmit();
    }
  };

  getFieldProps(field) {
    const { $deviceGroups, $devices, $dictionary, $lookups, $sites, dashboard } = this.props;
    const parametric_value_type = dashboard.get('parametric_value_type');
    const parametric_value_options = dashboard.get('parametric_value_options');
    const type = field.type.getValue();

    let options = null;
    let onQuery;

    if (Array.isArray(parametric_value_options) && parametric_value_options.length) {
      options = parametric_value_options.map(opt => ({ value: opt, label: opt }));
    } else if (type === 'country') {
      onQuery = $lookups.countries;
    } else if (type === 'cdn') {
      onQuery = $lookups.cdns;
    } else if (type === 'network_boundary') {
      options = $dictionary.getSelectOptions('interfaceClassification.networkBoundaryTypes');
    } else if (type === 'connectivity_type') {
      options = $dictionary.getSelectOptions('interfaceClassification.connectivityTypes');
    } else if (type === 'device') {
      options = $devices.deviceNameOptions;
    } else if (type === 'device_label') {
      options = $deviceGroups.deviceLabelSelectOptions;
    } else if (type === 'site') {
      options = $sites.collection.generateSelectOptions({ valueKey: 'title', sort: 'id' });
    } else if (type === 'provider') {
      onQuery = $lookups.providers;
    } else if (type === 'as_name') {
      onQuery = $lookups.asNames;
    } else if (type === 'as_number') {
      onQuery = $lookups.asNumbers;
    } else if (type === 'tag') {
      onQuery = $lookups.flowTags;
    } else if (type === 'city') {
      onQuery = $lookups.cities;
    } else if (type === 'region') {
      onQuery = $lookups.regions;
    } else if (type === 'interface_desc') {
      onQuery = $lookups.interfaceDescriptions;
    } else if (type === 'interface_name') {
      onQuery = $lookups.snmpAliases;
    } else if (type === 'market') {
      onQuery = $lookups.market;
    } else if (type === 'cloud_provider') {
      onQuery = $lookups.cloud_provider;
    } else if (type === 'gce_proj_id') {
      onQuery = $lookups.gceProject;
    } else if (type === 'aws_region') {
      onQuery = $lookups.awsRegion;
    } else if (type === 'azure_region') {
      onQuery = $lookups.azureRegion;
    } else if (type === 'application') {
      onQuery = $lookups.applications;
    }

    const validateOptions = (onQuery || (options && options.length > 0)) && parametric_value_type === 'predefined';

    return {
      options,
      onQuery,
      validateOptions
    };
  }

  getField(field, index) {
    const { applyButtonText, dashboard, inputClassName, className, autoFocusInput, menuWidth } = this.props;
    const { onQuery, options, validateOptions } = this.getFieldProps(field);

    const parametric_value_type = dashboard.get('parametric_value_type');
    const fieldValue = field.value.getValue();

    let inputComponent;
    if (onQuery || (options && options.length > 0)) {
      inputComponent = (
        <Select
          autoComplete
          exactMatch={parametric_value_type === 'predefined' || (options && options.length)}
          showFilter={parametric_value_type === 'predefined' || (options && options.length)}
          onQuery={!options || options.length === 0 ? onQuery : undefined}
          menuWidth={menuWidth}
        />
      );
    } else {
      inputComponent = <Input onKeyPress={this.handleKeyPress} />;
    }

    const inputField = (
      <Field
        className="no-margin pt-fill parametric-input"
        inputGroupClassName={inputClassName}
        field={field.value}
        options={options}
        validateOptions={validateOptions}
        showLabel={false}
        autoFocus={autoFocusInput}
      >
        {inputComponent}
      </Field>
    );

    return (
      <div key={field.value._id} className={className}>
        {this.props.children({
          buttonProps: {
            onClick: this.handleSubmit,
            disabled: parametric_value_type === 'predefined' && [undefined, null].includes(fieldValue),
            text: applyButtonText
          },
          inputField,
          question: field.question.getValue(),
          index
        })}
      </div>
    );
  }

  render() {
    const { form } = this.context;
    const parametric_fields = form.getField('parametric_fields');

    return parametric_fields.map((field, index) => this.getField(field, index));
  }
}

export default ApplyParametricOptions;
