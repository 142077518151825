import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { applyRestrictions } from 'forms/utils';
import SavedFilter from 'models/filters/SavedFilter';
import $dictionary from 'stores/$dictionary';

import FilterGroup from './FilterGroup';
import FilterGroupConnector from './FilterGroupConnector';
import SaveFiltersDialog from './SaveFiltersDialog';
import SavedFilterSelector from './SavedFilterSelector';

const bigButtonIconStyle = {
  marginLeft: -4,
  marginRight: 3,
  fontSize: 15,
  fontWeight: 500,
  top: -1,
  position: 'relative'
};

export function addFilterGroup(filterGroupsField, { named = false, filters, saved_filters, filterGroups, connector }) {
  const { flatFilterFieldOptions } = $dictionary;
  const group = filterGroupsField.add({
    filters: filterGroups || saved_filters ? filters : [{}], // if saved_filters are provided don't make an empty
    saved_filters,
    named,
    filterGroups,
    connector
  });

  if (!filters && !filterGroups && !saved_filters) {
    const { filterField } = group.filters.at(0);
    const options = applyRestrictions(flatFilterFieldOptions, filterField);

    if (
      options.length > 0 &&
      (!filterField.defaultValue || !options.some(option => option.value === filterField.defaultValue))
    ) {
      filterField.setValue(options[0].value);
    }
  }

  // TODO: we need to decide whether or not this makes sense. It's obtrusive, but helpful.
  if (named) {
    group.name.setPristine(false);
  }
}

@inject('$savedFilters', '$auth')
@observer
export default class FilterOptions extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    disabled: false,
    readOnly: false,
    showEmptyText: true,
    showButtonText: true,
    showNames: false,
    fieldName: 'filters',
    groupLabel: 'Group',
    groupLabelPlural: 'Groups',
    filterGroupLabel: 'filter group',
    filterGroupLabelPlural: 'filter groups',
    adHocLabel: 'Filter Groups',
    showTopLevelConnector: true,
    allowSavedFilters: true
  };

  state = {
    savedFilterModel: null
  };

  handleAddFilterGroupAdHoc = () => {
    const { fieldName, showNames } = this.props;
    const { form } = this.context;
    const filterGroups = form.getField(`${fieldName}.filterGroups`);

    addFilterGroup(filterGroups, { named: !!showNames });
  };

  handleAddFilterGroupSavedFilter = ({ id }) => {
    const { fieldName, showNames } = this.props;
    const { form } = this.context;
    const filterGroups = form.getField(`${fieldName}.filterGroups`);

    addFilterGroup(filterGroups, { named: !!showNames, saved_filters: [{ filter_id: id }] });
  };

  /**
   * When we "Save" a set of filters, we're just creating a new "SavedFilter". Take whatever
   * values are in the form, and clone them into a new SavedFilter and show a form.
   */
  handleSaveActiveFilters = () => {
    const { form } = this.context;

    const { filters } = form.getValues();

    this.setState({ savedFilterModel: new SavedFilter({ filters }) });
  };

  closeSaveActiveFiltersDialog = () => {
    const { $savedFilters } = this.props;

    $savedFilters.collection.fetch();

    this.setState({ savedFilterModel: null });
  };

  handleRemoveAllFilterGroups = () => {
    const { fieldName } = this.props;
    const { form } = this.context;
    const filterGroups = form.getField(`${fieldName}.filterGroups`);
    filterGroups.setValue([]);
  };

  render() {
    const { form } = this.context;
    const { savedFilterModel } = this.state;
    const {
      $auth,
      readOnly,
      disabled,
      fieldName,
      showButtonText,
      showSaveFiltersButton,
      showSavedFilters,
      groupLabelPlural,
      filterGroupLabel,
      filterGroupLabelPlural,
      adHocLabel,
      showTopLevelConnector,
      required,
      allowSavedFilters
    } = this.props;

    const filterGroups = form.getField(`${fieldName}.filterGroups`);
    const filterGroupsValue = form.getValue(`${fieldName}.filterGroups`);
    const groupConnector = form.getField(`${fieldName}.connector`);

    const filterGroupClassName = classNames('filter-groups', {
      'filter-groups-readonly': readOnly
    });

    const numFilterGroups = filterGroupsValue && filterGroupsValue.length;

    return (
      <Box {...this.props}>
        <Flex mb={1} align="center">
          <Box className={classNames('pt-navbar-heading', { 'pt-disabled': disabled })} flexAuto>
            {required && numFilterGroups === 0 && <span className="pt-intent-danger">{adHocLabel}</span>}
            {(!required || numFilterGroups > 0) && adHocLabel}
            {required && <span className="required-indicator">*</span>}
          </Box>
          {showTopLevelConnector &&
            numFilterGroups > 1 &&
            !disabled && (
              <Box flexAuto>
                <FilterGroupConnector connector={groupConnector} readOnly={readOnly} />
              </Box>
            )}
          <Flex flexAuto justify="flex-end">
            {showSaveFiltersButton &&
              $auth.isAdministrator &&
              numFilterGroups > 0 && (
                <Button
                  onClick={this.handleSaveActiveFilters}
                  iconName="floppy-disk"
                  className="pt-small pt-intent-primary pt-minimal"
                  text="Save Filters"
                  disabled={disabled}
                />
              )}
            {numFilterGroups > 0 && (
              <Button
                onClick={this.handleRemoveAllFilterGroups}
                disabled={disabled || numFilterGroups === 0}
                className="pt-small pt-intent-danger pt-minimal"
                text={`Remove All ${groupLabelPlural}`}
              />
            )}
          </Flex>
        </Flex>

        {numFilterGroups === 0 &&
          !disabled && (
            <Box className="pt-callout" style={{ textAlign: 'center' }} p={2}>
              {!required && (
                <Box mb={1} className="pt-text-muted">
                  No {filterGroupLabelPlural} have been applied
                </Box>
              )}
              {required && (
                <Box className="pt-form-group" mt={0} mb={1}>
                  <span className="pt-form-helper-text pt-intent-danger" style={{ fontSize: 14, marginTop: 0 }}>
                    At least one {filterGroupLabel} with an ad hoc or saved filter is required
                  </span>
                </Box>
              )}
              <Button
                onClick={this.handleAddFilterGroupAdHoc}
                className="pt-medium text-capitalize"
                style={{ marginRight: allowSavedFilters ? 8 : 0 }}
              >
                <span className="pt-icon pt-icon-plus" style={bigButtonIconStyle} />
                Add Ad Hoc Filter
              </Button>
              {allowSavedFilters && (
                <SavedFilterSelector
                  buttonClassName="pt-medium"
                  buttonIcon=""
                  buttonText={
                    <Fragment>
                      <span className="pt-icon pt-icon-plus" style={bigButtonIconStyle} />
                      Add Saved Filter
                    </Fragment>
                  }
                  onAdd={this.handleAddFilterGroupSavedFilter}
                />
              )}
            </Box>
          )}

        {numFilterGroups > 0 && (
          <Box className={filterGroupClassName}>
            {filterGroups.map((group, index) => (
              <FilterGroup
                {...this.props}
                key={group.connector._id}
                form={form}
                group={group}
                index={index}
                groups={filterGroups}
                readOnly={readOnly}
                showButtonText={showButtonText}
                flat
              />
            ))}

            {numFilterGroups > 0 && (
              <Box className={classNames('pt-navbar-heading', { 'pt-disabled': disabled })} flexAuto>
                <div className="text-capitalize">Add {filterGroupLabel}</div>
                <Button onClick={this.handleAddFilterGroupAdHoc} disabled={disabled} style={{ marginRight: 8 }}>
                  <span className="pt-icon pt-icon-plus" style={bigButtonIconStyle} />
                  Add Ad Hoc Filter
                </Button>
                {allowSavedFilters && (
                  <SavedFilterSelector
                    buttonClassName=""
                    buttonIcon=""
                    buttonText={
                      <Fragment>
                        <span className="pt-icon pt-icon-plus" style={bigButtonIconStyle} />
                        Add Saved Filter
                      </Fragment>
                    }
                    onAdd={this.handleAddFilterGroupSavedFilter}
                  />
                )}
              </Box>
            )}
          </Box>
        )}

        {showSavedFilters && (
          <SaveFiltersDialog
            isOpen={savedFilterModel}
            onClose={this.closeSaveActiveFiltersDialog}
            model={savedFilterModel}
          />
        )}
      </Box>
    );
  }
}
