import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { Banner } from 'components';
import { Box } from 'components/flexbox';
import InterfaceClassDependency from 'dataviews/dependencies/InterfaceClassDependency';
import NetworkClassDependency from 'dataviews/dependencies/NetworkClassDependency';
import ProviderClassDependency from 'dataviews/dependencies/ProviderClassDependency';
import SnmpDependency from 'dataviews/dependencies/SnmpDepdendency';
import SiteCountryDependency from 'dataviews/dependencies/SiteCountryDependency';
import SiteDependency from 'dataviews/dependencies/SiteDependency';
import GCEDependency from 'dataviews/dependencies/GCEDependency';
import AWSDependency from 'dataviews/dependencies/AWSDependency';
import CloudDependency from 'dataviews/dependencies/CloudDependency';

@inject('$app')
@observer
export default class DependencyCallout extends Component {
  dependencies = [];

  componentWillMount() {
    this.setupDependencies();
  }

  componentDidUpdate() {
    this.setupDependencies();
  }

  setupDependencies() {
    const { $app, dataview, dataviews } = this.props;
    const dataviewParam = dataview ? [dataview] : dataviews;

    if (!$app.isSubtenant) {
      this.dependencies = [
        new InterfaceClassDependency(dataviewParam),
        new NetworkClassDependency(dataviewParam),
        new ProviderClassDependency(dataviewParam),
        new SnmpDependency(dataviewParam),
        new SiteDependency(dataviewParam),
        new SiteCountryDependency(dataviewParam),
        new GCEDependency(dataviewParam),
        new AWSDependency(dataviewParam),
        new CloudDependency(dataviewParam)
      ];
    }
  }

  get dependencyCheckFailures() {
    return this.dependencies.filter(dep => dep.requires && !dep.validate());
  }

  render() {
    const dependencyFailures = this.dependencyCheckFailures;

    if (dependencyFailures.length === 0) {
      return null;
    }

    const [firstFailure] = dependencyFailures;
    const description = (
      <Fragment>
        {dependencyFailures.map(failure => (
          <Box key={failure.key} className="pt-callout" mt={1} ml={-4} mr={-1}>
            {failure.message}
          </Box>
        ))}
      </Fragment>
    );

    return (
      <Banner
        boxProps={this.props}
        title={firstFailure.title}
        intent={firstFailure.intent}
        icon={firstFailure.icon}
        description={description}
      />
    );
  }
}
