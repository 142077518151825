import React from 'react';
import classNames from 'classnames';

import { Flex } from 'components/flexbox';

const DeviceValueRenderer = props => {
  const { option, style = {}, onUnselect, field, asTag = false, readOnly, value } = props;

  const className = classNames(option.className, {
    'pt-tag': asTag,
    'pt-tag-removable': asTag && !readOnly && onUnselect,
    'pt-minimal': asTag
  });

  const position = 'relative';
  const paddingRight = 20;
  const width = '100%';

  return (
    <Flex className={className} style={{ position, paddingRight, width, ...style }}>
      <div className={classNames('pt-icon', option.iconCls)} style={{ marginLeft: 2, marginRight: 8 }} />
      <div style={{ textAlign: 'left', width: 'calc(100% - 24px)' }}>
        <div className="pt-strong pt-text-overflow-ellipsis" style={{ width: '100%' }}>
          {option.label}
        </div>
        <div className="pt-text-muted pt-text-overflow-ellipsis group-name" style={{ width: '100%' }}>
          {option.group}
        </div>
      </div>
      {!readOnly &&
        onUnselect && (
          <button
            className="pt-tag-remove"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onUnselect(field, value || option.value);
            }}
          />
        )}
    </Flex>
  );
};

export default DeviceValueRenderer;
