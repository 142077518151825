import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$interfaces')
class Interfaces extends Component {
  title = 'Interface';

  render() {
    const { result, $interfaces } = this.props;
    const link = `/admin/devices/${result.device_id}/interfaces/${result.id}`;

    return (
      <ResultRow
        type={this.title}
        icon="material-icons:view_comfy"
        nameField="interface_description"
        link={link}
        collection={$interfaces.collection}
        {...this.props}
      >
        <span>
          {result.snmp_alias} IP: {result.interface_ip ? result.interface_ip : '-'}
        </span>
      </ResultRow>
    );
  }
}

export default Interfaces;
