import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Intent } from '@blueprintjs/core';
import classNames from 'classnames';

export const dimensionTagClassNames = {
  Destination: 'dimension-tag-destination',
  Source: 'dimension-tag-source',
  'Source or Dest': 'dimension-tag-source-or-destination',
  Full: 'dimension-tag-full',
  Metric: 'metric'
};

export const dimensionCategoryIntentMap = {
  Destination: Intent.PRIMARY,
  Source: Intent.WARNING,
  'Source or Dest': Intent.DANGER
};

/**
 * Responsible for displaying a Dimension in <Tag> form. Used when you need to display a dimension's display label
 * i.e. "Destination AS Name" instead of `dst_as'
 */
@inject('$dictionary')
@observer
class Dimension extends Component {
  static defaultProps = {
    useChartTypes: false
  };

  render() {
    const { dimension, $dictionary, className, children, useChartTypes, style } = this.props;
    const options = useChartTypes ? $dictionary.flatDimensionOptions : $dictionary.flatFilterFieldOptions;
    const dimensionDisplay = options.find(option => option.value === dimension);

    if (!dimensionDisplay) {
      return <span className={classNames('dimension-tag pt-intent-critical', className)}>Invalid: {dimension}</span>;
    }

    const displayGroups = ['Source', 'Destination', 'Source or Destination'];
    const { group, label } = dimensionDisplay;

    if (children) {
      return children({
        group,
        label,
        className: classNames('dimension-tag', dimensionDisplay.className, className),
        dimensionClassName: dimensionDisplay.className,
        name: `${group} ${label}`,
        style
      });
    }

    return (
      <span className={classNames('dimension-tag', dimensionDisplay.className, className)}>
        {displayGroups.includes(dimensionDisplay.group) ? `${dimensionDisplay.group} ` : ''}
        {dimensionDisplay.label}
      </span>
    );
  }
}

export default Dimension;
