import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Icon from 'components/Icon';
import { Flex } from 'components/flexbox';
import { HelpLink } from 'components';
import KentikLogo from 'components/KentikLogo';

import FavoritesList from './FavoritesList';
import RecentlyViewedList from './RecentlyViewedList';
import ReportSelector from './ReportSelector';

@inject('$auth', '$library', '$recentlyViewed', '$app')
@observer
class LibrarySidebar extends Component {
  render() {
    const {
      $auth,
      $recentlyViewed,
      history,
      onDashboardCreate,
      reports,
      $app: { isSubtenant }
    } = this.props;

    const hasFavoritesOrRecents = reports.favoriteReports.length > 0 || $recentlyViewed.reports.length > 0;

    return (
      <Fragment>
        {!isSubtenant && (
          <Fragment>
            <Flex className="pt-menu-item" align="center" style={{ borderRadius: 0 }} onClick={onDashboardCreate}>
              <Icon name="plus" style={{ marginRight: 7 }} className="pt-intent-success-text" />
              <Icon name="control" style={{ marginRight: 7 }} className="pt-intent-success-text" />
              <strong>New Dashboard</strong>
            </Flex>
            <Flex
              className="pt-menu-item"
              align="center"
              style={{ borderRadius: 0 }}
              onClick={() => history.push('/explorer')}
            >
              <Icon name="plus" style={{ marginRight: 7 }} className="pt-intent-success-text" />
              <Icon name="timeline-area-chart" style={{ marginRight: 7 }} className="pt-intent-success-text" />
              <strong>New Saved View</strong>
            </Flex>
            <HelpLink
              helpTopic="About The Library"
              buttonSource="nav"
              style={{ top: 0, width: '100%', textAlign: 'left', paddingLeft: '30px' }}
              text="Library Help"
            />
            <hr className="sidebar-separator" />
          </Fragment>
        )}

        <FavoritesList {...this.props} />

        <RecentlyViewedList {...this.props} />

        {hasFavoritesOrRecents && <hr className="sidebar-separator" />}

        <ReportSelector
          {...this.props}
          reportType="presetReports"
          label={
            <Fragment>
              <KentikLogo
                onlyMark
                fillColor="#ff850e"
                style={{
                  cursor: 'pointer',
                  width: '13px',
                  position: 'relative',
                  top: '2px',
                  transform: 'scale(1.6)',
                  marginRight: '6px'
                }}
              />
              Kentik
            </Fragment>
          }
          labelMargin="0 4px 6px 12px"
        />

        <hr className="sidebar-separator" />

        <ReportSelector {...this.props} reportType="userReports" label={$auth.activeUser.user_full_name} />

        <hr className="sidebar-separator" />

        <ReportSelector
          {...this.props}
          reportType="companyReports"
          label={$auth.activeUser.company.company_name_full}
        />
      </Fragment>
    );
  }
}

export default withRouter(LibrarySidebar);
