import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { MaterialIcon } from 'components/Icon';
import { Box, Flex } from 'components/flexbox';

@inject('$savedFilters')
@observer
class SavedFilterDisplay extends Component {
  render() {
    const { $savedFilters, filter_id, is_not } = this.props;
    const savedFilter = $savedFilters.getSavedFilterById(filter_id);
    const isExcluded = is_not.getValue();

    if (!savedFilter) {
      return (
        <Box className="pt-card" p={1}>
          <Flex justify="space-between">
            <Flex align="center" flexAuto>
              <Box className="pt-intent-danger-text">Invalid saved filter ({filter_id})</Box>
            </Flex>
          </Flex>
        </Box>
      );
    }

    return (
      <Flex align="center" className="filter-item filter-item-readonly">
        <MaterialIcon
          className="pt-text-muted"
          name="subdirectory_arrow_right"
          style={{ fontSize: 12, marginRight: 2, marginTop: 3, alignSelf: 'flex-start' }}
        />
        <Flex flexAuto wrap align="center" style={{ textAlign: 'left', fontSize: 11 }}>
          <Flex align="center" className="saved-filter-readonly">
            <MaterialIcon name="star_rate" style={{ color: '#E28E4A', fontSize: 12, marginRight: 3, width: 12 }} />
            <span>
              <span className="pt-text-muted">{isExcluded ? 'Exclude' : 'Include'}</span>{' '}
              <strong>{savedFilter.get('filter_name')}</strong>
            </span>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export default SavedFilterDisplay;
