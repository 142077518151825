import React from 'react';
import { observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';

const ModalSelectFooter = props => {
  const { field, multi, closeOnSelect, saveButtonText = 'Save', selectState } = props;

  if (field.closeOnSelect === true || closeOnSelect === true || multi === false) {
    return null;
  }

  return (
    <Flex className="pt-dialog-footer" justify="flex-end">
      <Button text="Cancel" onClick={selectState.resetToLastOpened} style={{ marginRight: 8, width: 110 }} />
      <Button
        className="pt-medium"
        intent={Intent.PRIMARY}
        onClick={selectState.saveOptions}
        text={saveButtonText}
        style={{ minWidth: 110 }}
      />
    </Flex>
  );
};

export default observer(ModalSelectFooter);
