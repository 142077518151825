import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box } from 'components/flexbox';
import { Button, Intent, FormGroup, ControlGroup, InputGroup } from '@blueprintjs/core';
import { Field, Select } from 'components/forms';
import Icon, { MaterialIcon } from 'components/Icon';

const categoryOptionRenderer = props => {
  const {
    className,
    field,
    iconCls,
    iconName,
    key,
    label,
    selectItem,
    selected,
    style,
    separator = false,
    value,
    depth = 0
  } = props;

  if (separator) {
    return <hr key="separator" style={{ margin: '4px 8px' }} />;
  }

  const onClick = !selected ? () => selectItem(field, value) : undefined;
  const icon = iconCls || iconName;
  return (
    <div key={key || value} className={className} onClick={onClick} style={{ ...style, marginLeft: depth * 6 }}>
      {depth > 0 && <MaterialIcon className="pt-text-muted" name="subdirectory_arrow_right" />}
      {icon && <Icon name={icon} style={{ marginRight: 6 }} />}
      {label}
    </div>
  );
};

@inject('$queryTemplates', '$auth')
@observer
class CategorySelector extends Component {
  static defaultProps = {
    fieldName: 'category_id',
    saveAfterCreateCategory: true
  };

  state = {
    showCreateField: false,
    newCategoryName: '',
    saving: false
  };

  handleAddCategory = async () => {
    const { $queryTemplates, field, template, saveAfterCreateCategory } = this.props;
    const { newCategoryName } = this.state;

    this.setState({ saving: true });

    const category = $queryTemplates.categories.forge();
    await category.save({ name: newCategoryName }, { toast: false });

    field.setValue(category.id);

    if (saveAfterCreateCategory) {
      template.save({ category_id: category.id }, { clearSelection: false, toast: false });
    }

    this.setState({ showCreateField: false, newCategoryName: '', saving: false });
  };

  handleCategoryNameChange = e => {
    this.setState({ newCategoryName: e.target.value });
  };

  handleCancelAdd = () => {
    const { template, model, field } = this.props;
    const m = template || model;

    this.setState({ showCreateField: false, newCategoryName: '' });
    field.setValue(m.get('category_id'));
  };

  handleCategoryChange = (field, value) => {
    if (value === 'ADD_NEW') {
      this.setState({ showCreateField: true });
    }
  };

  render() {
    const { field, $auth, $queryTemplates } = this.props;
    const { showCreateField, newCategoryName, saving } = this.state;

    // hiding "Add" new categories for regular users for now.
    const options = $auth.hasSudo ? $queryTemplates.categoryOptions : $queryTemplates.categoryOptions.slice(1);

    return (
      <Box>
        {!showCreateField && (
          <Field {...this.props} onChange={this.handleCategoryChange} options={options}>
            <Select menuWidth={349} optionRenderer={categoryOptionRenderer} />
          </Field>
        )}

        {showCreateField && (
          <FormGroup label={field.label} labelFor="newCategory" required>
            <ControlGroup fill vertical={false}>
              <InputGroup
                name="newCategory"
                onChange={this.handleCategoryNameChange}
                placeholder="New category name..."
                value={newCategoryName}
              />
              <Button
                className="pt-strong"
                disabled={newCategoryName === ''}
                loading={saving}
                iconName="plus"
                intent={Intent.PRIMARY}
                onClick={this.handleAddCategory}
              >
                Add
              </Button>
              <Button disabled={saving} onClick={this.handleCancelAdd}>
                Cancel
              </Button>
            </ControlGroup>
          </FormGroup>
        )}
      </Box>
    );
  }
}

export default CategorySelector;
