import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, InputGroup } from '@blueprintjs/core';

import { Box } from 'components/flexbox';

@observer
class SelectFilter extends Component {
  static defaultProps = {
    filterable: true,
    filterPlaceholder: 'Search options...',
    multi: true
  };

  handleFilterValueChange = e => {
    this.props.selectState.setFilterValue(e.target.value);
  };

  handleClearFilterValue = () => {
    this.props.selectState.setFilterValue('');
  };

  render() {
    const { filterable, filterPlaceholder, filterRef, multi, selectState, disabled } = this.props;
    const { flatFilteredOptions, filterValue } = selectState;

    const selectSearchResults = (
      <Box style={{ marginLeft: 4 }}>
        <Button className="pt-minimal" onClick={this.handleClearFilterValue} iconName="cross" />
      </Box>
    );

    const hasResults = flatFilteredOptions.length > 0 && filterValue;

    let rightElement = null;

    if (multi) {
      if (hasResults) {
        rightElement = selectSearchResults;
      } else if (filterValue !== '') {
        rightElement = <Button className="pt-minimal" disabled text="No Results" />;
      }
    }

    if (filterable === true) {
      return (
        <Box flexAuto style={{ maxWidth: 350 }}>
          <InputGroup
            className="pt-fill"
            autoFocus
            leftIconName="search"
            onChange={this.handleFilterValueChange}
            placeholder={filterPlaceholder}
            value={filterValue}
            rightElement={rightElement}
            inputRef={filterRef}
            disabled={disabled}
          />
        </Box>
      );
    }

    return null;
  }
}

export default SelectFilter;
