import { computed, action, runInAction, toJS } from 'mobx';
import { sortBy, groupBy } from 'lodash';

import { showSuccessToast } from 'components/Toast';
import { hasDependencyFailures } from 'dataviews/dependencies/ReportDependencyChecker';
import Collection from 'models/Collection';
import Dashboard from 'models/dashboards/Dashboard';
import SavedView from 'models/savedViews/SavedView';
import QueryTemplate from 'models/query/QueryTemplate';
import api from 'util/api';

export const reportTypes = new Map();

reportTypes.set('report', {
  iconName: 'series-configuration',
  model: QueryTemplate,
  descField: 'description',
  nameField: 'name'
});

reportTypes.set('dashboard', {
  iconName: 'control',
  model: Dashboard,
  descField: 'description',
  nameField: 'dash_title'
});

reportTypes.set('savedView', {
  iconName: 'timeline-area-chart',
  model: SavedView,
  descField: 'view_description',
  nameField: 'view_name'
});

class ReportsCollection extends Collection {
  get defaultGroupBy() {
    return 'category.name';
  }

  get url() {
    return '/api/portal/reports';
  }

  get jsonRoot() {
    return 'reports';
  }

  get presetFilters() {
    return [
      {
        label: 'Dashboards',
        fn: model => model.constructor === Dashboard,
        iconName: 'grid-view'
      },
      {
        label: 'Saved Views',
        fn: model => model.constructor === SavedView,
        iconName: 'timeline-area-chart'
      },
      {
        label: 'Templates',
        fn: model => model.constructor === QueryTemplate,
        iconName: 'document'
      }
    ];
  }

  getFilterValues(model) {
    return ['category.name', 'id', 'name', 'type'].map(name => model[name] || model.get(name));
  }

  @action
  removeReport = async report => {
    const removed = api.del(`${report.urlRoot}/${report.id}`);
    if (removed) {
      this.remove(report.id);
      showSuccessToast(`${report.name} was removed.`);
    }
  };

  @computed
  get sortedCategories() {
    return Object.keys(this.groupedData).sort();
  }

  @computed
  get favoriteReports() {
    const reports = this.get().filter(report => report.get('favorite'));
    return sortBy(reports, ['name']);
  }

  @computed
  get featuredReports() {
    const reports = this.get().filter(report => report.get('featured'));
    return sortBy(reports, ['name']);
  }

  @computed
  get availableReports() {
    return toJS(sortBy(this.models, ['name']));
  }

  getSortedReports(reports) {
    return sortBy(reports, [
      report => report.get('category.name'),
      report => !report.get('featured'),
      report => report.name
    ]);
  }

  @computed
  get presetReports() {
    const reports = this.models.filter(report => report.isPreset);
    return groupBy(this.getSortedReports(reports), m => m.get('category.name'));
  }

  @computed
  get userReports() {
    const reports = this.models.filter(report => report.isUserLevel);
    return groupBy(this.getSortedReports(reports), m => m.get('category.name'));
  }

  @computed
  get companyReports() {
    const reports = this.models.filter(report => report.isCompanyLevel);
    return groupBy(this.getSortedReports(reports), m => m.get('category.name'));
  }

  @computed
  get recents() {
    const reports = this.models.filter(report => report.get('recent'));
    return sortBy(reports, ['name']);
  }

  @computed
  get subtenant() {
    return groupBy(this.getSortedReports(this.models), m => m.get('category.name'));
  }

  @action
  checkDependencies() {
    this.models.forEach(model => (model.hasDependencyFailures = hasDependencyFailures(model)));
  }

  build = (attributes, options = {}) => {
    const type = reportTypes.get(attributes.type);

    const ModelClass = type.model;

    const model = new ModelClass(attributes, options);

    model.collection = this;
    model.set(model.deserialize(attributes));
    model.hasDependencyFailures = hasDependencyFailures(model);

    runInAction(() => {
      if (options.select) {
        this.selected = model;
      }
    });

    return model;
  };

  forgeDashboard(attributes, options) {
    return this.build({ ...attributes, type: 'dashboard' }, options);
  }

  forgeSavedView(attributes, options) {
    return this.build({ ...attributes, type: 'savedView' }, options);
  }
}

export default ReportsCollection;
