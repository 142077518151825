import React from 'react';
import { observer } from 'mobx-react';
import { Position } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';

import { whitelistFieldProps } from 'forms/utils';

export const defaultPopoverProps = {
  position: Position.BOTTOM_LEFT,
  inline: false,
  popoverClassName: 'pt-minimal',
  tetherOptions: {
    offset: '-3px 0',
    constraints: [{ attachment: 'together', pin: true, to: 'window' }]
  }
};

export default observer(props => {
  const { field } = props;
  return <DateInput popoverProps={defaultPopoverProps} {...field.getProps()} {...whitelistFieldProps(props)} />;
});
