import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Hotkey, Hotkeys, HotkeysTarget, PopoverInteractionKind, Position } from '@blueprintjs/core';

import { ESC, ENTER } from 'util/keyCodes';
import DropdownMenu from 'components/DropdownMenu';
import { Box } from 'components/flexbox';

import Results from './Results';
import SearchInput from './SearchInput';

@inject('$search')
@HotkeysTarget
@observer
class SearchDropdown extends Component {
  renderHotkeys() {
    const { $search } = this.props;
    return (
      <Hotkeys>
        <Hotkey global combo="shift + s" label="Open Search" preventDefault onKeyDown={$search.focusSearch} />
      </Hotkeys>
    );
  }

  handleSearchInput = e => {
    const { $search } = this.props;
    const input = e.target.value.trim();

    if (input.length === 0) {
      $search.closeSearch();
    }

    if (input.length > 0 && !$search.showSearch) {
      $search.toggleSearch();
    }
  };

  handleKeyPress = e => {
    const { $search } = this.props;

    if (e.keyCode === ESC) {
      $search.closeSearch();
      document.getElementById('userMenu').focus();
    }

    if (e.keyCode === ENTER && !$search.showSearch) {
      $search.toggleSearch();
    }
  };

  render() {
    const { $search } = this.props;

    return (
      <DropdownMenu
        position={Position.BOTTOM_RIGHT}
        interactionKind={PopoverInteractionKind.CLICK}
        openOnTargetFocus
        isOpen={$search.showSearch}
        onClose={$search.closeSearch}
        autoFocus={false}
        tetherOptions={{ offset: '-2px 8px' }}
      >
        <Box mx={1} className="pt-dark">
          <SearchInput
            inputName="universalSearch"
            handleKeyPress={this.handleKeyPress}
            handleSearchInput={this.handleSearchInput}
            handleSearchResults={this.handleSearchResults}
          />
        </Box>

        {$search.showSearch ? <Results results={$search.results} onResultClick={$search.closeSearch} /> : null}
      </DropdownMenu>
    );
  }
}

export default SearchDropdown;
