import QueryModel from '../query/QueryModel';
import Collection from '../Collection';
import Dashboard from './Dashboard';

class DashboardCollection extends Collection {
  get url() {
    return '/api/portal/dashboards';
  }

  get model() {
    return Dashboard;
  }

  get defaultGroupBy() {
    return 'category.name';
  }

  get presetFilters() {
    return [
      {
        label: 'Personal',
        fn: model => model.isUserLevel
      },
      {
        label: 'Company',
        fn: model => model.isCompanyLevel && !model.isPreset
      },
      {
        label: 'Presets',
        fn: model => model.isPreset
      }
    ];
  }

  get useAsyncAdd() {
    return true;
  }

  get defaultSortState() {
    return {
      field: 'dash_title',
      direction: 'asc'
    };
  }

  deserialize(data) {
    data = super.deserialize(data);
    data.forEach(row => {
      row.query = QueryModel.create(row.query || {}).get();
    });
    return data;
  }
}

export default DashboardCollection;
