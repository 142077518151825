import { observable, action } from 'mobx';
import { safelyParseJSON } from 'util/utils';
import api from 'util/api';
import { set } from 'lodash';
import { showSuccessToast, showErrorToast } from 'components/Toast';

class AppStore {
  renderPromise = Promise.resolve();

  @observable
  navigationMenuCollapsed = false;

  @observable
  helpText = false;

  @observable
  helpButtonSource = false;

  @observable
  showHelp = false;

  @observable
  showMainNav = true;

  // global way to track if a Network request is happening. activates
  // little <Spinner /> in Topbar for now.
  @observable
  activeNetworkRequest;

  @observable
  darkThemeEnabled = false;

  @observable
  reportIssueDialogOpen = false;

  @observable
  sendingIssueRequest = false;

  @observable
  sendingErrorReport = false;

  @observable
  errorReportSuccess = false;

  @observable
  isExport = false;

  @observable
  isSubtenant = false;

  @observable
  debugModeOpen = false;

  @observable
  debugModeEnabled = false;

  @observable
  debugModeInfo = null;

  @observable
  buildInfoOpen = false;

  portalVersion = null;

  constructor() {
    if (window.localStorage) {
      this.darkThemeEnabled = safelyParseJSON(localStorage.getItem('darkThemeEnabled')) || false;
    }

    this.fetchPortalVersion().then(portalVersion => {
      this.portalVersion = portalVersion;
    });
  }

  fetchPortalVersion() {
    return api.get('/hc-full').then(response => ({
      portal: {
        rev: response.rev.portal,
        commit: response.versionControl.portal
      },
      node: {
        rev: response.rev.node,
        commit: response.versionControl.node
      }
    }));
  }

  renderSync(fn) {
    this.renderPromise = this.renderPromise.then(
      () =>
        new Promise(resolve => {
          setTimeout(() => {
            try {
              fn();
            } catch (e) {
              console.warn('Error occurred in renderSync', e, e && e.message, e && e.stack);
              /* eslint-disable no-console */
              console.trace();
              resolve();
            }
            setTimeout(resolve, this.isExport ? 10 : 300);
          }, 0);
        })
    );
  }

  // This handles an issue where the collapse button appears over search and help dialogs. Z-index fixes did not work.
  handleExplorerSidebarButton = hide => {
    const sidebarCollapse = document.getElementsByClassName('sidebar-collapse-button')[0];

    if (sidebarCollapse) {
      if (hide) {
        sidebarCollapse.style.display = 'none';
      } else {
        sidebarCollapse.style.display = 'block';
      }
    }
  };

  goToV4(redirectUrl) {
    if (this.store.$auth.isSubtenantUser) {
      return undefined;
    }

    let redirect = '/v4';

    if (redirectUrl) {
      redirect = `/v4/${redirectUrl}`;
    } else if (window.location.pathname.includes('/explorer')) {
      redirect = window.location.pathname.replace('/explorer', '/v4/core/explorer');
    } else if (window.location.pathname.includes('/library/dashboard')) {
      redirect = window.location.pathname.replace('/library/dashboard', '/v4/library/dashboards');
    } else if (window.location.pathname.includes('/library/savedView')) {
      redirect = window.location.pathname.replace('/library/savedView', '/v4/library/saved-views');
    }

    // if for any reason the path is missing the /v4/ prefix, we have a bad URL. Send them to the dashboard.
    if (!redirect.startsWith('/v4/')) {
      redirect = '/v4';
    }

    if (this.store.$auth.isSpoofed) {
      window.location = redirect;
      return undefined;
    }

    return api.post('/api/portal/users/goToV4').then(() => (window.location = redirect));
  }

  get showV4Notice() {
    return (
      window.location.pathname !== '/account-activation' &&
      window.location.pathname !== '/password' &&
      window.location.pathname !== '/email/verify' &&
      window.location.pathname !== '/verify-two-factor'
    );
  }

  get isV4() {
    return (
      window.location.pathname.startsWith('/alerting-admin') ||
      window.location.pathname.startsWith('/admin4') ||
      window.location.pathname.startsWith('/profile4')
    );
  }

  adjustUrlForShim(location, url) {
    if (location.pathname.startsWith('/alerting-admin') && url.includes('/alerting/')) {
      return url.replace('/alerting/', '/alerting-admin/');
    }
    if (location.pathname.startsWith('/admin4') && url.includes('/admin/')) {
      return url.replace('/admin/', '/admin4/');
    }
    if (location.pathname.startsWith('/profile4') && url.includes('/profile/')) {
      return url.replace('/profile/', '/profile4/');
    }
    return url;
  }

  @action
  toggleNavMenuCollapse = () => (this.navigationMenuCollapsed = !this.navigationMenuCollapsed);

  @action
  openHelp = buttonSource => {
    this.showHelp = true;
    this.helpButtonSource = buttonSource;
    this.handleExplorerSidebarButton(true);
  };

  @action
  loadHelp = text => {
    this.helpText = text;
  };

  @action
  closeHelp = () => {
    this.showHelp = false;
    this.handleExplorerSidebarButton(false);
  };

  @action
  toggleReportIssueDialog = () => {
    this.sendingIssueRequest = false;
    this.reportIssueDialogOpen = !this.reportIssueDialogOpen;
  };

  @action
  submitReportIssue = data => {
    this.sendingIssueRequest = true;

    return api
      .post('/api/portal/report/issue', { data })
      .then(res => {
        this.sendingIssueRequest = false;
        this.toggleReportIssueDialog();
        showSuccessToast('Issue was reported successfully. Thanks!');
        return res;
      })
      .catch(err => {
        this.sendingIssueRequest = false;
        showErrorToast('Something went wrong sending your report. Please try again.');
        return err;
      });
  };

  @action
  submitErrorReport = data => {
    this.sendingErrorReport = true;
    this.errorReportSuccess = false;

    return api
      .post('/api/portal/report/error', { data })
      .then(res => {
        this.sendingErrorReport = false;
        this.errorReportSuccess = true;
        return res;
      })
      .catch(
        action(err => {
          this.sendingErrorReport = false;
          this.errorReportSuccess = false;
          return err;
        })
      );
  };

  @action
  toggleDarkTheme = (opts = {}) => {
    const { persist = true } = opts;
    this.darkThemeEnabled = !this.darkThemeEnabled;

    if (persist) {
      localStorage.setItem('darkThemeEnabled', this.darkThemeEnabled);
    }

    // so this is somewhat gross, but it's really an escape hatch way to make sure `pt-dark` goes to everything.
    // i'm OK with it for now.
    document.body.classList.toggle('pt-dark', this.darkThemeEnabled);
  };

  @action
  setIsExport(isExport) {
    this.isExport = isExport;
  }

  @action
  setIsSubtenant(isSubtenant) {
    this.isSubtenant = isSubtenant;
  }

  // simple way to set values through an action.
  @action
  set = (key, value) => {
    set(this, key, value);
  };

  @action
  toggleLoginInterstitial = () => {
    this.loginInterstitialOpen = !this.loginInterstitialOpen;
  };

  @action
  setDebugModeOpen = open => {
    this.debugModeOpen = open;
  };

  @action
  setBuildInfoOpen = open => {
    this.buildInfoOpen = open;
  };

  @action
  toggleDebugMode = () => {
    if (this.debugModeEnabled) {
      this.debugModeEnabled = false;
    } else {
      this.setDebugModeOpen(true);
    }
  };

  @action
  enableDebugMode = ({ info }) => {
    this.debugModeInfo = info;
    this.debugModeEnabled = true;
    this.setDebugModeOpen(false);
  };
}

export default new AppStore();
