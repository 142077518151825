/**
 * A generic Dialog that allows us to display configuration options for a given Dataview.
 * This will (should) serve as the basis for which new Dataviews are configured, and should
 * be re-used wherever a DataView is being displayed and needs configuration changes.
 */

import React, { Component } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Form, FormDialog } from 'components/forms';

const fields = {};

@Form({ fields })
@observer
class ConfigureDataViewDialog extends Component {
  static defaultProps = {
    showRemove: true
  };

  componentDidMount() {
    const { dataview } = this.props;
    this.configureDialogDisposer = reaction(
      () => dataview.isConfigureDialogOpen,
      isOpen => {
        if (isOpen) {
          this.initializeDataViewFields();
        }
      }
    );
  }

  componentWillUnmount() {
    this.configureDialogDisposer();
  }

  handleSave = async (form, values) => {
    const { dataview, onAfterSave } = this.props;
    const data = await dataview.save(values);
    if (onAfterSave) {
      return onAfterSave(data);
    }

    return data;
  };

  initializeDataViewFields = () => {
    const { form, dataview } = this.props;
    form.addFields(dataview.formConfig);
    form.init(dataview.viewModel.get());
  };

  render() {
    const { dataview, onRemove } = this.props;
    const DataViewConfigurationForm = dataview.formComponent;

    return (
      <FormDialog
        {...this.props}
        style={{ width: 600 }}
        title={dataview.viewName}
        model={dataview.viewModel}
        entityName={dataview.viewName}
        isOpen={dataview.isConfigureDialogOpen}
        onClose={dataview.hideConfigureDialog}
        formActionsProps={{
          onSubmit: this.handleSave,
          onCancel: dataview.hideConfigureDialog,
          onRemove,
          showRemove: this.props.showRemove && !!onRemove
        }}
        formComponent={<DataViewConfigurationForm {...this.props} />}
      />
    );
  }
}

export default ConfigureDataViewDialog;
