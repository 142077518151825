import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Dialog } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { Filter, PresetFilterButtons, Table } from 'components/table';

const tableColumns = [
  {
    label: 'Share Level',
    name: 'isPreset',
    computed: true,
    renderer: ({ model }) => (
      <span>
        {model.isPreset && <span className="pt-tag pt-minimal pt-intent-warning">Preset</span>}
        {model.isCompanyLevel &&
          !model.isPreset && <span className="pt-tag pt-minimal pt-intent-primary">Company</span>}
        {model.isUserLevel && <span className="pt-tag pt-minimal pt-intent-success">Personal</span>}
      </span>
    ),
    width: 120
  },
  {
    name: 'dash_title',
    label: 'Name',
    wrapText: true,
    renderer: ({ value, model }) => (
      <div>
        <strong>{value}</strong>
        <div className="pt-text-muted">{model.get('description')}</div>
      </div>
    )
  }
];

const groupSummaryLookup = ({ groupKey }) => groupKey;

@inject('$dashboards')
@observer
export default class DashboardsDialog extends Component {
  render() {
    const { $dashboards, isOpen, onClose, dialogProps = {}, title = 'Dashboards', onClick } = this.props;
    const { collection } = $dashboards;

    return (
      <Dialog
        {...dialogProps}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        style={{ width: 650, top: 72 }}
        transitionName="pt-dialog"
      >
        <div className="pt-dialog-body">
          <Flex mb={2}>
            <Filter style={{ minWidth: 250 }} collection={collection} placeholder="Search Dashboards..." autoFocus />
            <PresetFilterButtons collection={collection} />
          </Flex>
          <div className="pt-card flat">
            <Table
              collection={collection}
              columns={tableColumns}
              onRowClick={onClick}
              groupSummaryLookup={groupSummaryLookup}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}
