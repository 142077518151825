import classNames from 'classnames';
import React, { Fragment } from 'react';
import { observable, action, computed } from 'mobx';
import { groupBy, uniqBy, uniq, countBy } from 'lodash';

import { Flex, Box } from 'components/flexbox';
import DeviceCollection from 'models/devices/DeviceCollection';
import api from 'util/api';

const deviceIconClsMap = {
  router: 'pt-icon-dev-router-plain',
  nprobe: 'pt-icon-send-to',
  kprobe: 'pt-icon-dev-kprobe',
  aws_subnet: 'pt-icon-dev-cloud-aws',
  gcp_subnet: 'pt-icon-dev-cloud-gcp',
  paloalto: 'pt-icon-paloalto'
};

// const deviceTypeAcronyms = {
//   router: 'Use All Routers',
//   host: 'cHst',
//   'host-nprobe-basic': 'nHst',
//   'host-nprobe-dns-www': 'Use All Hosts',
//   'aws-subnet': 'Use All AWS Subnets',
//   'gcp-subnet': 'Use All GCP Subnets'
// };

class DeviceStore {
  @observable
  fetchFlowCount = 0;

  @observable
  collection = new DeviceCollection();

  @observable
  deviceSummaries = [];

  @observable
  hasReceivedFlow = false;

  @observable
  masterBGPDevices = [];

  @observable
  fetchedDeviceStatistics = false;

  @action
  async initialize() {
    if (this.store.$auth.activeUser.company.company_status === 'V') {
      return Promise.all([this.loadDeviceSummaries(), this.fetchFlowHeartbeat()]).then(([deviceSummaries, hasFlow]) => {
        // start checking for flow every 60 seconds if they have devices, but we haven't received flow yet.
        if (hasFlow === false && deviceSummaries.length > 0) {
          this.flowHeartbeatInterval = setInterval(this.fetchFlowHeartbeat, 60000);
        }
      });
    }
    return Promise.resolve();
  }

  @computed
  get companySettings() {
    return {
      flow_ips: this.store.$companySettings.flow_ips,
      port: this.store.$companySettings.port
    };
  }

  @computed
  get routerSettings() {
    const { router_snmp_polling_ips: snmpPollingIps } = this.store.$dictionary.dictionary.general_router_settings;

    return {
      snmpPollingIps
    };
  }

  @computed
  get numUnlabeledDevices() {
    return this.collection.unfiltered.filter(device => !device.hasLabels).length;
  }

  @computed
  get devicesLabeledBelowThreshold() {
    return this.devicesLabeled < 1;
  }

  @computed
  get devicesLabeled() {
    const labels = this.deviceSummaries.filter(device => device.labels && device.labels.length).length;
    return labels / this.deviceSummaries.length;
  }

  @computed
  get devicesLabeledDisplay() {
    return `${Math.floor(this.devicesLabeled * 100)}%`;
  }

  @computed
  get deviceOptions() {
    const options = [];
    this.collection.unfiltered.forEach(device => {
      const { device_name, all_interfaces, device_snmp_ip } = device.get();
      options.push({
        value: device.id,
        label: device_name,
        snmp_ip: device_snmp_ip,
        total_interfaces: all_interfaces.length
      });
    });

    return options;
  }

  // uses the available Devices and their types to derive which ones to show.
  @computed
  get deviceTypeOptions() {
    const { device_subtypes } = this.store.$dictionary.dictionary;
    const uniqueSubtypes = uniq(this.deviceSummaries.map(({ device_subtype }) => device_subtype));
    return uniqueSubtypes.map(subtype => {
      const deviceSubtype = device_subtypes[subtype];
      if (deviceSubtype) {
        return {
          value: subtype,
          label: deviceSubtype.display_name,
          iconName: deviceSubtype.icon || deviceIconClsMap[subtype],
          numDevices: this.deviceTypeCounts[subtype] || 0,
          devices: this.deviceSummariesByType[subtype] || []
        };
      }

      return {
        value: subtype,
        label: <span className="pt-text-muted">{subtype}</span>,
        iconName: 'pt-icon-help',
        numDevices: this.deviceTypeCounts[subtype] || 0,
        devices: this.deviceSummariesByType[subtype] || []
      };
    });
  }

  // builds the { label, value } from deviceSummaries instead of the whole collection
  @computed
  get deviceSummaryOptions() {
    return this.deviceSummaries.map(({ device_name, id, device_type, device_subtype }) => ({
      value: device_name,
      label: device_name,
      iconName: deviceIconClsMap[device_subtype],
      id,
      device_type,
      device_subtype
    }));
  }

  @computed
  get deviceSummariesByType() {
    return groupBy(this.deviceSummaries, 'device_subtype');
  }

  /**
   * {
   *  router: 8,
   *  host-nprobe-dns-www: 1
   * }
   */
  @computed
  get deviceTypeCounts() {
    return this.deviceSummaries ? countBy(this.deviceSummaries.map(({ device_subtype }) => device_subtype)) : {};
  }

  @computed
  get deviceNameOptions() {
    return this.deviceSummaries.map(({ device_name }) => ({
      value: device_name,
      label: device_name
    }));
  }

  @computed
  get hasDnsProbe() {
    return this.deviceSummaries.some(({ device_subtype }) => device_subtype === 'kprobe');
  }

  @computed
  get hasDevices() {
    return this.deviceSummaries.length > 0;
  }

  @computed
  get deviceSiteOptions() {
    return this.deviceSummaries.map(({ device_name, title }) => ({
      value: device_name,
      label: `${title} - ${device_name}`
    }));
  }

  @computed
  get hasGCEDevice() {
    return this.deviceSummaries.some(
      device => device.cloudExportTask && device.cloudExportTask.cloud_provider === 'gce'
    );
  }

  @computed
  get hasAWSDevice() {
    return this.deviceSummaries.some(
      device => device.cloudExportTask && device.cloudExportTask.cloud_provider === 'aws'
    );
  }

  @computed
  get hasAzureDevice() {
    return this.deviceSummaries.some(
      device => device.cloudExportTask && device.cloudExportTask.cloud_provider === 'azure'
    );
  }

  @action
  loadDevices = () => {
    this.store.$companySettings.load();

    return this.collection
      .fetch({
        query: {
          filterCloud: true
        }
      })
      .then(() => {
        // load device statistics, and BGP information for display.
        this.loadDeviceStatistics().then(
          action(devices => {
            Object.keys(devices).forEach(deviceId => {
              const model = this.collection.get(deviceId);
              if (model) {
                model.set(devices[deviceId]);
              }
            });

            this.fetchedDeviceStatistics = true;
          })
        );
      });
  };

  @action
  loadDeviceSummaries() {
    return api.get('/api/portal/devices/summaries').then(response => {
      this.deviceSummaries = response;

      const deviceIdOptions = response.map(option => ({
        value: option.id,
        name: option.device_name,
        filterLabel: option.device_name,
        label: (
          <Flex align="center">
            <div
              className={classNames('pt-icon pt-text-muted', deviceIconClsMap[option.device_subtype])}
              style={{ marginRight: 6 }}
            />
            <Box className="pt-text-overflow-ellipsis" mr={0.5} flexAuto>
              {option.device_name}
            </Box>
            <span className="pt-text-muted">{option.id}</span>
          </Flex>
        )
      }));
      const deviceNameOptions = response.map(option => ({
        value: option.device_name,
        name: option.device_name,
        label: (
          <Fragment>
            <span
              className={classNames('pt-icon', deviceIconClsMap[option.device_subtype])}
              style={{ marginRight: 6 }}
            />
            {option.device_name}
          </Fragment>
        )
      }));
      const siteNameOptions = uniqBy(
        response.map(option => ({ value: option.title, label: option.title })).filter(option => option.value),
        option => option.value
      );

      this.store.$dictionary.addFilterFieldValueOptions({
        i_device_id: deviceIdOptions,
        i_device_name: deviceNameOptions,
        i_ult_exit_device_name: deviceNameOptions,
        i_device_site_name: siteNameOptions,
        i_ult_exit_site: siteNameOptions
      });

      return this.deviceSummaries;
    });
  }

  @action
  loadDeviceDetails(deviceId) {
    return api.get(`/api/portal/devices/${deviceId}`).then(response => {
      this.collection.selected.set(response);
      return this.collection.selected;
    });
  }

  // does this Company have any flow at all?
  @action
  fetchFlowHeartbeat = () =>
    api.get('/api/portal/devices/flowHeartbeat').then(result => {
      this.hasReceivedFlow = !!result && !!result.hasReceivedFlow; // force boolean just in case

      if (this.hasReceivedFlow) {
        clearInterval(this.flowHeartbeatInterval);
      }

      return result;
    });

  fetchMasterBGPDevices() {
    return api.get('/api/portal/devices/getMasterBgpDevicesList').then(response => {
      this.masterBGPDevices = response
        .map(device => ({
          label: device.device_name,
          value: device.id
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1));
    });
  }

  fetchBgpIngestIps(deviceId) {
    return api.get(`/api/portal/companySettings/getBgpIngestIps/${deviceId || ''}`);
  }

  @action
  loadDeviceStatistics() {
    return api.get('/api/portal/devices/stats').then(response => response);
  }

  @action
  async getDevicesNamesByType(type) {
    const hostTypes = ['host', 'nprobe', 'kprobe'];

    if (!type) {
      return [];
    }

    if (this.collection.models.length === 0) {
      await this.collection.fetch();
    }

    let devices = [];

    if (type === 'routers') {
      devices = this.collection.models.filter(device => device.get('device_subtype') === 'router');
    } else if (type === 'hosts') {
      devices = this.collection.models.filter(device => hostTypes.includes(device.get('device_subtype')));
    }

    return devices.map(device => device.get('device_name'));
  }

  /**
   * { device_types, device_labels, device_sites, device_name }
   */
  @action
  getUniqueSelectedDevices(identifiers) {
    const { $sites, $deviceGroups } = this.store;
    const devices = [];

    if (!$sites.collection.hasFetched || !$deviceGroups.labelCollection.hasFetched) {
      return devices;
    }

    const { device_ids = [], device_labels = [], device_sites = [], device_types = [], device_name = [] } = identifiers;

    device_ids.forEach(id => {
      const device = this.deviceSummaries.find(d => d.id === id);
      if (device) {
        devices.push(device);
      }
    });

    device_labels.forEach(labelId => {
      const label = this.store.$deviceGroups.labelCollection.get(labelId);
      if (label) {
        devices.push(...label.get('devices'));
      }
    });

    device_sites.forEach(siteId => {
      const site = this.store.$sites.collection.get(siteId);
      if (site) {
        devices.push(...site.get('devices'));
      }
    });

    device_types.forEach(type => devices.push(...this.deviceSummariesByType[type]));

    device_name.forEach(name => {
      const device = this.deviceSummaries.find(d => d.device_name === name);
      if (device) {
        devices.push(device);
      }
    });

    // take only the uniques
    return uniqBy(devices, 'device_name');
  }

  filterMissingDevices(identifiers) {
    const { all_devices, device_labels, device_sites, device_types, device_name } = identifiers;
    const { $sites, $deviceGroups } = this.store;

    if (all_devices) {
      return identifiers;
    }

    return {
      all_devices,
      device_name: device_name.filter(name => this.deviceSummaries.find(d => d.device_name === name)),
      device_types: device_types.filter(
        type => this.deviceSummariesByType[type] && this.deviceSummariesByType[type].length
      ),
      device_sites: device_sites.filter(site => $sites.collection.get(site)),
      device_labels: device_labels.filter(label => $deviceGroups.labelCollection.get(label))
    };
  }

  getDeviceOptions(valueField = 'device_name', deviceFilter) {
    const deviceOptions = {};
    let { deviceSummaries } = this;

    if (deviceFilter) {
      deviceSummaries = deviceSummaries.filter(deviceFilter);
    }

    deviceSummaries.forEach(device => {
      const { device_name, device_subtype, title } = device;
      const site = title || 'Unassigned';

      const deviceOption = {
        value: device[valueField],
        label: device_name,
        className: device_subtype,
        iconCls: deviceIconClsMap[device_subtype],
        site
      };

      if (!deviceOptions[site]) {
        deviceOptions[site] = [[]];
      }

      deviceOptions[site][0].push(deviceOption);
    });

    return deviceOptions;
  }

  /**
   * Pass in a string of device_ids, get a comma separated string of device_names (for QueryModel)
   */
  @action
  async getDeviceNames(ids) {
    if (!ids) {
      return '';
    }

    const devices = [];

    if (this.collection.models.length === 0) {
      await this.collection.fetch();
    }

    ids.split(',').forEach(id => {
      const device = this.collection.get(Number(id));
      if (device) {
        devices.push(device.get('device_name'));
      }
    });

    return devices;
  }

  containsDnsProbe({ device_name = [], device_labels = [], device_sites = [], device_types = [], device_ids = [] }) {
    if (
      !Array.isArray(device_name) ||
      !Array.isArray(device_labels) ||
      !Array.isArray(device_sites) ||
      !Array.isArray(device_types) ||
      !Array.isArray(device_ids)
    ) {
      return false;
    }

    if (device_types.includes('kprobe')) {
      return true;
    }

    return this.getUniqueSelectedDevices({ device_ids, device_name, device_labels, device_sites, device_types }).some(
      device => ['kprobe', 'aws_subnet', 'gcp_subnet', 'azure_subnet'].includes(device.device_subtype)
    );
  }

  getDeviceTypeFromSubtype(device_subtype) {
    const deviceSubtypeRecord = this.store.$dictionary.dictionary.device_subtypes[device_subtype];
    return deviceSubtypeRecord ? deviceSubtypeRecord.parent_type : undefined;
  }
}

export default new DeviceStore();
