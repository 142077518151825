import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Page, PageHeader, PageBody } from 'components/page';
import NotificationsSettings from './NotificationsSettings';
import DefaultSettings from './DefaultSettings';

const breadcrumbLinks = [
  {
    text: 'My Profile',
    href: '/profile'
  }
];

@observer
class ProfileSettings extends Component {
  render() {
    return (
      <Page constrainWidth title="Profile">
        <PageHeader parents={breadcrumbLinks} title="User Information" />
        <PageBody scrollable bodyStyle={{ maxWidth: '800px' }}>
          <NotificationsSettings />
          <DefaultSettings />
        </PageBody>
      </Page>
    );
  }
}

export default ProfileSettings;
