import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$alertingMitigation')
class MitigationPlatforms extends Component {
  title = 'Mitigation Platform';

  url = '/alerting/mitigations/platforms';

  render() {
    const { result, $alertingMitigation } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="office"
        url={this.url}
        nameField="platform_name"
        collection={$alertingMitigation.platforms}
        {...this.props}
      >
        <span>type: {result.platform_mitigation_device_type}</span>
      </ResultRow>
    );
  }
}

export default MitigationPlatforms;
