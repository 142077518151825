import React, { Component } from 'react';
import { sortBy } from 'lodash';
import { toJS, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Box } from 'components/flexbox';
import { Form, Field, ButtonGroup } from 'components/forms/index';
import { getAggregateTypeLabel, getAggregateUnitLabel } from 'components/forms/metric/metricRenderers';
import { getAggregates } from 'models/query/QueryModel';

const fields = {
  aggregateTypes: {}
};

@Form({ fields })
@inject('$basicExplorer')
@observer
class Metrics extends Component {
  componentWillMount() {
    const { $basicExplorer, form } = this.props;
    this.activeAggregatesDisposer = reaction(
      () => $basicExplorer.activeAggregateTypes,
      () => {
        form.init({ aggregateTypes: $basicExplorer.activeAggregateTypes });
      },
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.activeAggregatesDisposer();
  }

  handleMetricsChange = (field, values) => {
    const { $basicExplorer } = this.props;
    const aggregateTypes = toJS(values);
    $basicExplorer.setAggregateTypes(aggregateTypes);
  };

  formatAggregateOption = aggregate => {
    const typeLabel = getAggregateTypeLabel({ aggregate, useLineBreaks: false });
    const unitLabel = getAggregateUnitLabel({ aggregate, useLineBreaks: false, wrap: false });

    return {
      label: `${typeLabel}${unitLabel}`,
      value: aggregate.value
    };
  };

  formatAggregateOptionNames = aggregate => {
    const label = aggregate.label.replace('Src', 'Source').replace('Dst', 'Destination');
    return { ...aggregate, label };
  };

  render() {
    const { template } = this.props;

    const aggregates = getAggregates(template.get('options.metrics.aggregateTypes'));
    const aggregateOptions = aggregates.map(this.formatAggregateOption).map(this.formatAggregateOptionNames);
    const options = sortBy(aggregateOptions, 'label');

    return (
      <Box mb={1} className="basic-explorer-metric-options">
        <h6 className="pt-text-muted small-caps" style={{ fontSize: 12, marginBottom: 12 }}>
          Metrics
        </h6>

        <Field name="aggregateTypes" options={options} onChange={this.handleMetricsChange} className="no-margin">
          <ButtonGroup className="pt-vertical pt-align-left pt-fill" />
        </Field>
      </Box>
    );
  }
}

export default Metrics;
