import { computed } from 'mobx';
import { orderBy } from 'lodash';

import Collection from '../Collection';
import User from './User';

class UserCollection extends Collection {
  get url() {
    return '/api/portal/users';
  }

  get model() {
    return User;
  }

  get jsonRoot() {
    return 'users';
  }

  get defaultSortState() {
    return {
      field: 'user_email',
      direction: 'asc'
    };
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'user_full_name', 'user_email']);
  }

  @computed
  get userSelectOptions() {
    const options = this.models.map(user => {
      const { id, user_full_name, user_email, user_group_id } = user.toJS();
      return {
        user_email,
        value: id,
        label: `${user_full_name} (${user_email})`,
        user_group_id,
        role: user.userLevel,
        user_level: user.get('user_level'),
        model: user
      };
    });

    return orderBy(options, ['user_group_id', 'user_level', 'user_name'], ['desc', 'desc', 'asc']);
  }
}

export default UserCollection;
