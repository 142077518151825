import React, { Fragment, Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse, Menu, Tag } from '@blueprintjs/core';

import { ShareLevelBadge } from 'components';
import { Flex, Box } from 'components/flexbox';
import { Fade } from 'components/animations';

import SidebarGroup from './SidebarGroup';
import ReportMenuItem from './ReportMenuItem';

@observer
class ReportSelector extends Component {
  static defaultProps = {
    labelMargin: '0 4px 0 12px'
  };

  state = {
    collapsed: false,
    categories: undefined,
    reportCount: undefined
  };

  componentWillMount() {
    // Default to expanded
    const { reports, reportType, queryTemplateCategories } = this.props;
    const collapsed = window.localStorage.getItem(this.getLocalStorageKey()) === 'true';
    this.setState({
      reportCount: reports.size,
      categories: this.getCategories(reports, reportType, queryTemplateCategories),
      collapsed
    });
  }

  componentWillReceiveProps({ reports, reportType, queryTemplateCategories }) {
    if (reports.size !== this.state.reportCount) {
      this.setState({
        reportCount: reports.size,
        categories: this.getCategories(reports, reportType, queryTemplateCategories)
      });
    }
  }

  getCategories(reports, reportType, queryTemplateCategories) {
    const allCategories = Object.keys(reports[reportType]).reduce((acc, name) => {
      const category = queryTemplateCategories.find({ name });

      if (category && !acc.find(cat => cat.id === category.id)) {
        acc.push(category.toJS());

        const parentId = category.get('parent_id');
        if (parentId && !acc.find(cat => cat.id === parentId)) {
          acc.push(queryTemplateCategories.get(parentId).toJS());
        }
      }
      return acc;
    }, []);

    return this.nestCategories(allCategories);
  }

  getLocalStorageKey() {
    const { reportType } = this.props;
    return `library-${reportType}-collapsed`;
  }

  handleToggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed }, () => {
      window.localStorage.setItem(this.getLocalStorageKey(), this.state.collapsed);
    });
  };

  renderReport = report => {
    const { reports } = this.props;

    return (
      <div key={`${report.name}-${report.id}`} style={{ marginRight: 6 }}>
        <ReportMenuItem {...this.props} report={report} selected={reports.selected === report} highlightFeatured />
      </div>
    );
  };

  nestCategories(categories) {
    return categories.reduce((acc, category) => {
      if (category.parent_id && categories.find(cat => cat.id === category.parent_id)) {
        const parent = categories.find(cat => cat.id === category.parent_id);
        if (!parent.childCategories) {
          parent.childCategories = [];
        }
        parent.childCategories.push(category);
      } else {
        acc.push(category);
      }

      return acc;
    }, []);
  }

  sumChildReports(reports, category) {
    let sum = 0;
    if (category.childCategories) {
      category.childCategories.forEach(childCategory => {
        sum += this.sumChildReports(reports, childCategory);
      });
    }

    return sum + (reports[category.name] || []).length;
  }

  renderSidebarGroup(category, reports, reportType, depth = 0) {
    let childSidebarGroups;

    if (category.childCategories) {
      childSidebarGroups = category.childCategories.map(cat =>
        this.renderSidebarGroup(cat, reports, reportType, depth + 1)
      );
    }

    return (
      <SidebarGroup
        key={`${reportType}${category.id}`}
        title={category.name}
        iconName={category.icon}
        reportType={reportType}
        collection={reports}
        subGroups={category.childCategories}
        depth={depth}
        badge={
          <Tag className="pt-minimal pt-round pt-strong" style={{ width: 25 }}>
            {this.sumChildReports(reports[reportType], category)}
          </Tag>
        }
      >
        {reports[reportType][category.name] && reports[reportType][category.name].map(this.renderReport)}
        {childSidebarGroups}
      </SidebarGroup>
    );
  }

  render() {
    const { reports, reportType, label } = this.props;
    const { collapsed, categories } = this.state;
    const uncategorized = reports[reportType].undefined || [];

    let shareLevel = 'preset';
    if (reportType !== 'presetReports') {
      shareLevel = reportType === 'userReports' ? 'self' : 'org';
    }

    return (
      <Fragment>
        {label && (
          <Flex
            align="center"
            className="pt-menu-item library-menu-header overflow-hidden"
            justify="space-between"
            tabIndex={0}
            onClick={this.handleToggleCollapsed}
          >
            <Box flexAuto className="pt-text-overflow-ellipsis small-caps" pl={0.5}>
              {label}
            </Box>
            <ShareLevelBadge type={shareLevel} minimal showIcon strong={false} style={{ marginLeft: 8 }} />
          </Flex>
        )}
        <Collapse isOpen={!collapsed}>
          <Fade in={!collapsed} timeout={250}>
            {({ styles }) => (
              <Box style={styles}>
                <Menu>
                  {categories && categories.map(category => this.renderSidebarGroup(category, reports, reportType))}
                  <div style={{ marginLeft: 6, marginRight: 6 }}>{uncategorized.map(this.renderReport)}</div>
                </Menu>
              </Box>
            )}
          </Fade>
        </Collapse>
      </Fragment>
    );
  }
}

export default ReportSelector;
