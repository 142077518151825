import { action, observable } from 'mobx';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import UserCollection from 'models/users/UserCollection';
import OtpCollection from 'models/users/OtpCollection';
import $auth from 'stores/$auth';
import api from 'util/api';

class UserStore {
  @observable
  collection = new UserCollection();

  @observable
  fullUserProfile = {};

  @observable
  fetchedUserProfile = false;

  @observable
  updatingUser = false;

  @observable
  initiatingPasswordReset = false;

  @observable
  initiatingEmailUpdate = false;

  @observable
  resettingApiToken = false;

  @observable
  disablingTotp = false;

  @observable
  generatingTotp = false;

  @observable
  registeringTotp = false;

  @observable
  totpQrCode;

  @observable
  totpSecret;

  @observable
  otps = new OtpCollection();

  @action
  fetchFullProfile() {
    return api.get('/api/portal/profile/full').then(
      response => {
        this.fullUserProfile = response;
        this.fetchedUserProfile = true;
      },
      () => {
        this.fetchedUserProfile = true;
      }
    );
  }

  @action
  updateUserProfile(user, label = 'User Profile') {
    this.updatingUser = true;

    return api.put(`/api/portal/users/${$auth.activeUser.id}`, { data: { user } }).then(
      () => {
        this.fullUserProfile = { ...this.fullUserProfile, ...user };
        $auth.activeUser = { ...$auth.activeUser, ...user };
        showSuccessToast(`${label} updated successfully`);
        this.updatingUser = false;
      },
      error => {
        showErrorToast(`${label} could not be saved at this time`);
        console.error('Error saving user profile', error);
        this.updatingUser = false;
      }
    );
  }

  @action
  updateUserEmail = token => {
    this.initiatingEmailUpdate = true;
    const data = { token };

    return api.post('/api/portal/profile/updateUserEmail', { showErrorToast: false, data, rawResponse: true }).then(
      res => {
        const { status } = res;
        this.initiatingEmailUpdate = false;
        return { success: true, status, msg: 'Email updated' };
      },
      errRes => {
        const { status } = errRes;
        const msg = (errRes.body && errRes.body.error) || 'Error updating email';
        this.initiatingEmailUpdate = false;
        return { success: false, status, msg };
      }
    );
  };

  @action
  initiatePasswordReset = () => {
    this.initiatingPasswordReset = true;

    return api.post('/api/portal/profile/initiatePasswordReset').then(
      () => {
        showSuccessToast('Password reset initiated. Please check your inbox.');
        this.initiatingPasswordReset = false;
      },
      error => {
        showErrorToast('Unable to initiate password reset. Please try again.');
        console.error('Error initiating password reset', error);
        this.initiatingPasswordReset = false;
      }
    );
  };

  @action
  generateTotpSecret = () => {
    this.generatingTotp = true;

    return api.get('/api/portal/totp/register').then(
      success => {
        this.otpauth_url = success.otpauth_url;
        this.totpSecret = success.secret;
        this.generatingTotp = false;
      },
      error => {
        showErrorToast('TOTP Secret could not be generated. Please try again.');
        console.error('Error generating TOTP secret', error);
        this.generatingTotp = false;
      }
    );
  };

  @action
  fetchOtps = () =>
    api.get('/api/portal/totp/').then(
      data => {
        this.otps.set(data);
        this.fullUserProfile.totpEnabled = this.otps.hasEnabledOtp;
      },
      error => {
        showErrorToast('Totps  could not be retrieved.');
        console.error('Error retrieving Totps', error);
      }
    );

  @action
  registerTotp = (token, isYubi, name) => {
    const data = {
      secret: isYubi ? undefined : this.totpSecret,
      token,
      name,
      type: isYubi ? 'yubi' : 'token'
    };

    this.registeringTotp = true;

    return api.post('/api/portal/totp/register', { data, showErrorToast: false }).then(
      otps => {
        this.otps.set(otps);
        this.registeringTotp = false;
        this.otpauth_url = false;
        this.totpSecret = false;
        this.fullUserProfile.totpEnabled = true;
        this.fullUserProfile.isYubiOtp = !!isYubi;
        showSuccessToast('TOTP Registration was successful');
      },
      error => {
        this.registeringTotp = false;
        showErrorToast('TOTP Token validation was unsuccessful. Please try again.');
        console.error('Error validating TOTP secret', error);
        throw new Error(error);
      }
    );
  };

  @action
  disableTotp = () => {
    this.disablingTotp = true;

    return api.del(`/api/portal/totp/${$auth.activeUser.id}`).then(
      () => {
        this.fullUserProfile.totpEnabled = false;
        this.fullUserProfile.isYubiOtp = false;
        this.disablingTotp = false;
        this.otps.reset();
        showSuccessToast('TOTP is now disabled.');
      },
      error => {
        this.disablingTotp = false;
        showErrorToast('TOTP could not be disabled at this time');
        console.error('Error saving user profile', error);
      }
    );
  };

  @action
  deleteTotp(totpId) {
    return api.del(`/api/portal/totp/${$auth.activeUser.id}/${totpId}`).then(
      () => {
        this.otps.remove(totpId);
      },
      error => {
        showErrorToast('TOTP could not be deleted at this time');
        console.error('Error removing totp', error);
      }
    );
  }

  @action
  updateTotp(totp, params) {
    return totp.save(params).then(
      action(() => {
        this.fullUserProfile.totpEnabled = this.otps.hasEnabledOtp;
      })
    );
  }

  @action
  disableUserTotps(userId) {
    this.disablingTotp = true;

    return api.post(`/api/portal/totp/disableAllTotps/${userId}`).then(
      data => {
        this.otps.reset();
        this.otps.set(data);
        const userModel = this.collection.get(userId);
        if (userModel) {
          userModel.set({ totpEnabled: false });
        }
        if (userId === this.fullUserProfile.id) {
          this.fullUserProfile.totpEnabled = false;
        }

        this.disablingTotp = false;
      },
      error => {
        this.disablingTotp = false;
        showErrorToast('TOTP could not be disabled at this time');
        console.error('Error saving user profile', error);
      }
    );
  }

  @action
  resetUserApiToken(userId) {
    this.resettingApiToken = true;
    this.updatingUser = true;

    return api.post(`/api/portal/profile/resetApiKey/${userId}`).then(
      response => {
        this.resettingApiToken = false;
        this.updatingUser = false;
        // Only update fullUserProfile if resetting for current user.
        if (userId === $auth.activeUser.id) {
          this.fullUserProfile.user_api_key = response.newApiKey;
        }
        return response.newApiKey;
      },
      error => {
        this.resettingApiToken = false;
        this.updatingUser = false;
        showErrorToast('API Token could not be updated');
        console.error('API Token could not be updated', error);
      }
    );
  }
}

export default new UserStore();
