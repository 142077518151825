import React from 'react';
import { computed } from 'mobx';

import { injectLegacySavedFilters } from 'services/filters';
import $dashboards from 'stores/$dashboards';
import $dictionary from 'stores/$dictionary';
import $devices from 'stores/$devices';
import $userGroups from 'stores/$userGroups';
import $auth from 'stores/$auth';
import { MOMENT_FN, DEFAULT_DATE_FORMAT } from 'util/dateUtils';

import BaseModel from '../BaseModel';

const dimensionFlowspecMap = {
  src_ip: 'IP_src',
  dst_ip: 'IP_dst',
  protocol: 'Proto',
  src_port: 'Port_src',
  dst_port: 'Port_dst'
};

class Policy extends BaseModel {
  constructor(attributes) {
    super(attributes);

    const dimensions = this.get('dimensions');
    if (dimensions && !Array.isArray(dimensions)) {
      this.set('dimensions', dimensions.split(','));
    }

    const baseline = this.get('baseline');
    const lookbackValue = baseline && baseline.min_lookback_sec ? baseline.min_lookback_sec : 345600; // Default of 4 days
    const numLookbackHours = lookbackValue / 3600;
    const minLookbackUnit = numLookbackHours > 24 && numLookbackHours % 24 === 0 ? 'days' : 'hours';
    const minLookbackValue =
      numLookbackHours > 24 && numLookbackHours % 24 === 0 ? numLookbackHours / 24 : numLookbackHours;

    if (baseline) {
      baseline.min_lookback_unit = minLookbackUnit;
      baseline.min_lookback_value = minLookbackValue || 4;
      this.set({ baseline });
    } else {
      this.set({
        baseline: {
          min_lookback_unit: minLookbackUnit,
          min_lookback_value: minLookbackValue
        }
      });
    }

    // Because of the bizarre logic in Collection.add(), things we're trying to remove via deserialize still get added
    this.set({ policySavedFilters: [] });
  }

  serialize(data) {
    const { baseline } = data;
    const thresholds = [];

    data.policy_window = parseInt(data.policy_window);

    data.thresholds.forEach(threshold => {
      if (threshold.enabled !== false && threshold.conditions.length > 0) {
        // Compare
        if (threshold.compare.auto_calc) {
          threshold.compare = null;
        }

        // Notification channels
        const notificationChannels = threshold.notificationChannels.map(channel => ({ id: parseInt(channel) }));

        // Mitigations
        delete threshold.mitigationOptions;

        if (threshold.mitigations) {
          threshold.mitigations.forEach(mitigation => {
            if (!mitigation.id) {
              delete mitigation.id;
            }
          });
        }

        if (!threshold.id) {
          delete threshold.id;
        }

        thresholds.push({ ...threshold, enabled: undefined, notificationChannels });
      }
    });

    const minLookback =
      baseline.min_lookback_unit === 'hours'
        ? parseInt(baseline.min_lookback_value) * 60 * 60
        : parseInt(baseline.min_lookback_value) * 60 * 60 * 24;

    const metric = [data.primary_metric].concat(data.secondary_metrics);
    data.baseline.lookback_sec = parseInt(baseline.lookback_sec);
    data.baseline.lookback_step_sec = parseInt(baseline.lookback_step_sec);
    data.baseline.min_lookback_sec = minLookback;
    data.baseline.point_width_sec = parseInt(baseline.point_width_sec);
    data.baseline.start_step = parseInt(baseline.start_step);

    if (data.dimension_grouping_enabled && data.primary_dimension) {
      data.primary_dimension.NumGroupingDimensions = parseInt(data.primary_dimension.NumGroupingDimensions, 10);
      data.primary_dimension.MaxPerGroup = parseInt(data.primary_dimension.MaxPerGroup, 10);
      data.primary_dimension.GroupingDimensions = data.dimensions.slice(
        0,
        data.primary_dimension.NumGroupingDimensions
      );
    } else {
      data.primary_dimension = {
        NumGroupingDimensions: 0,
        MaxPerGroup: 0,
        GroupingDimensions: []
      };
    }
    delete data.dimension_grouping_enabled;

    // Handle CIDR dimensions. The alerting backend understands individual dimensions like
    // IP_src_cidr_24_128
    // even though the UI here only lets you pick one cidr prefix per policy.
    // Transform the dimensions here. See corresponding code in Policy.deserialize.
    let { cidr, cidr6 } = data;
    cidr = parseInt(cidr, 10);
    cidr6 = parseInt(cidr6, 10);
    delete data.cidr;
    delete data.cidr6;
    const dimensions = data.dimensions.map(
      d =>
        $dictionary.dictionary.cidrMetrics.includes(d) && (cidr !== 32 || cidr6 !== 128)
          ? `${d}_cidr_${cidr}_${cidr6}`
          : d
    );

    return super.serialize({ ...data, metric, thresholds, dimensions });
  }

  deserialize(dataI = {}) {
    const data = super.deserialize(dataI);

    if (!Object.keys(data).length) {
      return {};
    }

    let all_devices = false;
    let device_name = [];
    let { selected_devices } = data;
    if (!data.selected_devices) {
      all_devices = data.devices.length === 1 && data.devices[0] === 100;
      if (!all_devices) {
        device_name = data.devices.map(device => {
          const deviceSummary = $devices.deviceSummaries.find(d => d.id === device.toString());

          return deviceSummary && deviceSummary.device_name;
        });
      }
      selected_devices = {
        all_devices,
        device_name,
        device_labels: [],
        device_types: [],
        device_sites: []
      };
    }

    selected_devices = $devices.filterMissingDevices(selected_devices);

    const { metric, dimensions = [], filters, policySavedFilters, baseline } = data;
    const primary_metric = metric[0];
    const secondary_metrics = metric.length > 1 ? metric.slice(1) : [];

    data.dimension_grouping_enabled = !!(data.primary_dimension && data.primary_dimension.NumGroupingDimensions > 0);

    // Handle CIDR dimensions. See corresponding code in Policy.serialize.
    let { cidr = 32, cidr6 = 128 } = data;
    data.dimensions = dimensions.map(dim => {
      if (dim && `${dim}`.indexOf('_cidr_') >= 0) {
        const prefix = $dictionary.dictionary.cidrMetrics.find(m => dim.startsWith(m));
        if (prefix) {
          const parts = dim.split('_');
          cidr = parseInt(parts[parts.length - 2], 10);
          cidr6 = parseInt(parts[parts.length - 1], 10);
          return prefix;
        }
      }
      return dim;
    });

    if (policySavedFilters && policySavedFilters.length > 0) {
      data.filters = injectLegacySavedFilters(policySavedFilters, filters);
      delete data.policySavedFilters;
    }

    if (data.thresholds) {
      data.thresholds.forEach(threshold => {
        threshold.compare = threshold.compare || {
          auto_calc: true,
          threshold_top: data.limit_num || 25,
          threshold_store: data.store_num || 25
        };
      });
    }

    const lookbackValue = baseline && baseline.min_lookback_sec ? baseline.min_lookback_sec : 345600; // Default of 4 days
    const numLookbackHours = lookbackValue / 3600;
    const minLookbackUnit = numLookbackHours > 24 && numLookbackHours % 24 === 0 ? 'days' : 'hours';
    const minLookbackValue =
      numLookbackHours > 24 && numLookbackHours % 24 === 0 ? numLookbackHours / 24 : numLookbackHours;

    if (baseline) {
      baseline.min_lookback_unit = minLookbackUnit;
      baseline.min_lookback_value = minLookbackValue || 4;
    } else {
      data.baseline = {
        min_lookback_unit: minLookbackUnit,
        min_lookback_value: minLookbackValue
      };
    }

    return { ...data, selected_devices, primary_metric, secondary_metrics, cidr, cidr6 };
  }

  get omitDuringSerialize() {
    return [
      'alertPolicySavedFilters',
      'all_devices',
      'primary_metric',
      'secondary_metrics',
      'baseline.min_lookback_value',
      'baseline.min_lookback_unit',
      'thresholds[].conditions.metric'
    ];
  }

  get defaults() {
    const defaultDashboard = $dashboards.dashboards.find(d => d.get('dash_title') === 'Attack' && d.isPreset);
    const dashboard_id = defaultDashboard ? defaultDashboard.get('id') : undefined;

    return {
      all_devices: true,
      cidr: 32,
      cidr6: 128,
      dashboard_id,
      devices: [],
      dimensions: [],
      learning_mode: true,
      learning_mode_expire_date: this.learningModeDefaultDate,
      status: 'D',
      thresholds: [],
      selected_devices: {
        device_name: [],
        device_labels: [],
        device_sites: [],
        device_types: []
      }
    };
  }

  get thresholdDefaults() {
    return {
      id: undefined,
      activate: {
        gracePeriod: 20,
        operator: '>=',
        timeUnit: 'hour',
        timeWindow: 2,
        times: 5
      },
      uniqueId: Date.now(),
      threshold_ack_required: false,
      mode: 'baseline',
      direction: 'current_to_history',
      compare: {
        auto_calc: true,
        threshold_top: 25,
        threshold_store: 25
      },
      notificationChannels: [],
      mitigations: [],
      fallback: {
        miss_op: 'lowest',
        is_use_static_value: true,
        miss_val: 0
      },
      conditions: []
    };
  }

  get messages() {
    return {
      create: 'Policy added successfully',
      update: 'Policy updated successfully',
      destroy: 'Policy removed successfully'
    };
  }

  get removalConfirmText() {
    const removeUserGroupText = (
      <div style={{ marginBottom: 16 }} className="pt-callout pt-intent-warning pt-icon-warning-sign">
        Removing this policy will affect My Kentik Portal users.
      </div>
    );

    return {
      title: 'Remove Policy',
      text: (
        <div>
          {this.hasUserGroups && removeUserGroupText}
          {`Are you sure you want to remove ${this.get('policy_name')}?`}
        </div>
      )
    };
  }

  @computed
  get devices() {
    const selected_devices = this.get('selected_devices');
    const { all_devices } = selected_devices;

    if (all_devices) {
      return 'All Devices';
    }

    const devices = $devices.getUniqueSelectedDevices(selected_devices);
    return `${devices.length} Device${devices.length > 1 ? 's' : ''}`;
  }

  @computed
  get learningMode() {
    return this.get('learning_mode') ? 'Enabled' : 'Disabled';
  }

  @computed
  get isSilenced() {
    return this.get('learning_mode');
  }

  @computed
  get silentModeExpireTime() {
    return this.toDate('learning_mode_expire_date');
  }

  @computed
  get learningModeDefaultDate() {
    return MOMENT_FN(Date.now() + 6 * 24 * 60 * 60 * 1000).format(DEFAULT_DATE_FORMAT);
  }

  @computed
  get policyStatus() {
    switch (this.get('status')) {
      case 'A':
        return 'Enabled';
      case 'D':
        return 'Disabled';
      case 'ERR':
        return 'ERROR';
      default:
        return 'Disabled';
    }
  }

  @computed
  get hasUserGroups() {
    const hasSubtenancy = $auth.hasPermission('subtenancy.enabled') || $auth.isActiveTrial;

    return (
      hasSubtenancy &&
      !!$userGroups.collection.models.find(userGroup => {
        const { alerts } = userGroup.get('config');
        return alerts && alerts.find(alert => alert.policy_id === this.id);
      })
    );
  }

  @computed
  get hasTemplates() {
    const hasSubtenancy = $auth.hasPermission('subtenancy.enabled') || $auth.isActiveTrial;

    return (
      hasSubtenancy &&
      !!$userGroups.templates.models.find(userGroup => {
        const { alerts } = userGroup.get('config');
        return alerts && alerts.find(alert => alert.policy_id === this.id);
      })
    );
  }

  isFlowspecMethodValid(mitigationMethod, dimensions) {
    const flowSpecData = mitigationMethod.get
      ? mitigationMethod.get('method_mitigation_device_detail').flowSpec
      : mitigationMethod.method_mitigation_device_detail.flowSpec;
    return !['src_ip', 'dst_ip', 'protocol', 'src_port', 'dst_port'].some(type =>
      this.hasInvalidDimensionsForFlowspecMatch(flowSpecData, dimensions || this.get('dimensions'), type)
    );
  }

  hasInvalidDimensionsForFlowspecMatch = (flowSpecData, dimensions, type) =>
    flowSpecData[`${type}`].enabled &&
    flowSpecData[`${type}`].infer &&
    !dimensions.includes(dimensionFlowspecMap[`${type}`]);
}

export default Policy;
