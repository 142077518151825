import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { MenuDivider, MenuItem } from '@blueprintjs/core';
import { getTotalTrafficOverlay, getHistoricalOverlay } from 'models/query/QueryModel';

@inject('$queryEditor')
@observer
class ViewSqlMenuItem extends Component {
  showQuerySql(query) {
    this.props.history.push('/analytics/queryEditor');
    this.props.$queryEditor.loadQuery(query.serialize());
  }

  render() {
    const { dataview } = this.props;

    return (
      <MenuItem iconName="database" text="View SQL" {...this.props}>
        {dataview.queryBuckets.activeBuckets.reduce((elems, bucket) => {
          elems.push(<MenuDivider key={`bucket-${bucket.id}`} title={bucket.get('name')} />);
          bucket.queries.each(query => {
            const { descriptor, query_title, show_overlay, show_total_overlay } = query.get();
            elems.push(
              <MenuItem
                key={`query-${query.id}`}
                text={descriptor || query_title || 'Default'}
                onClick={() => this.showQuerySql(query)}
              />
            );
            if (!bucket.overlayQueries.size) {
              if (show_total_overlay) {
                elems.push(
                  <MenuItem
                    key={`query-${query.id}-total`}
                    text="Total"
                    onClick={() => this.showQuerySql(getTotalTrafficOverlay(query))}
                    label="Overlay"
                  />
                );
              }
              if (show_overlay) {
                elems.push(
                  <MenuItem
                    key={`query-${query.id}-historical`}
                    text="Historical"
                    onClick={() => this.showQuerySql(getHistoricalOverlay(query))}
                    label="Overlay"
                  />
                );
              }
            }
          });
          bucket.overlayQueries.each(query => {
            const { descriptor, query_title } = query.get();
            elems.push(
              <MenuItem
                key={`query-${query.id}`}
                text={descriptor || query_title}
                onClick={() => this.showQuerySql(query)}
                label="Overlay"
              />
            );
          });
          return elems;
        }, [])}
      </MenuItem>
    );
  }
}

export default withRouter(ViewSqlMenuItem);
