import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Spinner, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { Fade } from 'components/animations';
import $app from 'stores/$app';

@observer
export default class DataViewSpinner extends Component {
  static defaultProps = {
    text: 'Your query is running, please wait...'
  };

  state = {
    hide: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading && this.state.hide) {
      this.setState({ hide: false });
    }
  }

  render() {
    const { loading, text, compactMode, showLegend, calloutClassName } = this.props;
    const { hide } = this.state;
    const { darkThemeEnabled } = $app;
    const calloutClassnames = classNames('pt-callout', 'dataview-spinner', calloutClassName, {
      'pt-intent-warning': !darkThemeEnabled
    });

    return (
      <Fade in={loading || !hide} unmountOnExit>
        {({ styles }) => (
          <Flex
            flexColumn
            align="center"
            justify="center"
            style={{
              height: showLegend ? '50%' : '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 2,
              ...styles
            }}
          >
            <Box className={calloutClassnames} p={1}>
              <Flex align="center">
                <Spinner className="pt-small" intent={Intent.WARNING} />
                {!compactMode && (
                  <span style={{ verticalAlign: 'top', marginLeft: 8 }}>
                    <strong>{text}</strong>
                  </span>
                )}
              </Flex>
            </Box>
          </Flex>
        )}
      </Fade>
    );
  }
}
