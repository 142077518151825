import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { whitelistFieldProps, shiftInputCursor } from 'forms/utils';

const defaultStyles = {
  resize: 'none'
};

const Textarea = props => {
  const { field, className } = props;

  const style = Object.assign({}, defaultStyles, props.style);

  return (
    <textarea
      {...field.getProps()}
      {...whitelistFieldProps(props)}
      className={classNames('pt-input', className)}
      style={style}
      onFocus={props.autoFocus ? shiftInputCursor : undefined}
    />
  );
};

export default observer(Textarea);
