import moment from 'moment';
import { times } from 'lodash';
import Validator from 'validatorjs';

import $savedFilters from 'stores/$savedFilters';
import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

function transformFilterGroups(value = []) {
  return value.map(filterGroup => ({ filterGroups: [], not: false, connector: 'All', ...filterGroup }));
}

Validator.register(
  'isValidSavedFilterId',
  /* eslint-disable prefer-arrow-callback */
  function isValidSavedFilterId(value) {
    if (!value) {
      return false;
    }

    return !!$savedFilters.getSavedFilterById(value);
  },
  'Invalid saved filter'
);

function getFilterGroups(prefix, blacklist, options, depth = 0) {
  const canNestFilterGroups = depth < 2;

  const filterGroups = {
    [`${prefix}.filterGroups`]: {
      isComplexArray: true,
      transform: {
        in: value => (canNestFilterGroups ? transformFilterGroups(value) : value)
      },
      rules: options.filterGroupsRules
    },

    [`${prefix}.filterGroups[].name`]: {
      label: 'Name',
      rules: [{ requiredIfMultiple: ['filterDimensionsEnabled', true, 'named', true] }],
      messages: {
        required_if: 'Series name is required'
      }
    },

    [`${prefix}.filterGroups[].named`]: {
      defaultValue: false
    },

    [`${prefix}.filterGroups[].connector`]: {
      defaultValue: 'All',
      options: [
        {
          label: 'All',
          value: 'All'
        },
        {
          label: 'Any',
          value: 'Any'
        }
      ]
    },

    [`${prefix}.filterGroups[].not`]: {
      defaultValue: false,
      options: [
        {
          label: 'Exclude',
          value: true
        },
        {
          label: 'Include',
          value: false
        }
      ]
    },

    [`${prefix}.filterGroups[].autoAdded`]: {},

    [`${prefix}.filterGroups[].filters`]: {
      isComplexArray: true
    },

    [`${prefix}.filterGroups[].saved_filters`]: {
      isComplexArray: true
    },

    [`${prefix}.filterGroups[].saved_filters[].filter_id`]: {
      rules: 'required|isValidSavedFilterId'
    },

    [`${prefix}.filterGroups[].saved_filters[].is_not`]: {
      defaultValue: false,
      options: [
        {
          label: 'Exclude Results',
          value: true
        },
        {
          label: 'Include Results',
          value: false
        }
      ]
    },

    [`${prefix}.filterGroups[].filters[].filterField`]: {
      defaultValue: 'dst_as',
      rules: 'required',
      optionsBlacklist: new Set(blacklist),
      label: 'field'
    },

    [`${prefix}.filterGroups[].filters[].operator`]: {
      defaultValue: '=',
      rules: 'required',
      label: 'operator'
    },

    [`${prefix}.filterGroups[].filters[].filterValue`]: {
      placeholder: 'Enter value...',
      label: 'value',
      messages: {
        required: 'A filter value is required'
      }
    }
  };

  if (canNestFilterGroups) {
    Object.assign(filterGroups, getFilterGroups(`${prefix}.filterGroups[]`, blacklist, {}, depth + 1));
  }

  return filterGroups;
}

export function generateFilterFields(fieldName = 'filters', blacklist = [], options = {}) {
  const filterFields = {
    [`${fieldName}.connector`]: {
      defaultValue: 'All',
      options: [
        {
          label: 'All',
          value: 'All'
        },
        {
          label: 'Any',
          value: 'Any'
        }
      ]
    }
  };

  Object.assign(filterFields, getFilterGroups(fieldName, blacklist, options));

  return filterFields;
}

export function generateTimeFields(rootFieldName) {
  const prefix = rootFieldName ? `${rootFieldName}.` : '';

  const timeInTransform = (value, form) => {
    const time_format = form.getValue(`${prefix}time_format`);
    const lookback_seconds = form.getValue(`${prefix}lookback_seconds`);
    if (!value || lookback_seconds) {
      return value;
    }

    if (!time_format || time_format === 'UTC') {
      // we're assuming UTC here but we want to force the format so timezone offsets don't throw moment off inside the field
      return moment.utc(value).format(DEFAULT_DATETIME_FORMAT);
    }
    // converting UTC time to local time.
    return moment(moment.utc(value).valueOf()).format(DEFAULT_DATETIME_FORMAT);
  };

  const timeOutTransform = (value, form) => {
    const time_format = form.getValue(`${prefix}time_format`);
    const lookback_seconds = form.getValue(`${prefix}lookback_seconds`);
    if (!value || lookback_seconds) {
      return value;
    }

    // sometimes we get a Date object here because the user used the form field to change something
    // Blueprint Date components return Date objects.
    if (value instanceof Date) {
      value = moment(value).format(DEFAULT_DATETIME_FORMAT);
    }
    if (!time_format || time_format === 'UTC') {
      // UTC mode, so just return the value that came out of the field
      return value;
    }
    // Local mode, so take value and convert it to UTC.
    return moment(value)
      .utc()
      .format(DEFAULT_DATETIME_FORMAT);
  };

  return {
    [`${prefix}lookback_seconds`]: {
      label: 'Show The'
    },
    [`${prefix}from_to_lookback`]: {
      label: 'Show The',
      defaultValue: 0
    },
    [`${prefix}time_format`]: {
      label: 'Time Zone',
      options: [{ value: 'UTC', label: 'UTC' }, { value: 'Local', label: 'Local' }]
    },
    [`${prefix}starting_time`]: {
      label: 'From',
      messages: {
        before: 'Start date must be before end date'
      },
      transform: {
        in: timeInTransform,
        out: timeOutTransform
      }
    },
    [`${prefix}ending_time`]: {
      label: 'To',
      transform: {
        in: timeInTransform,
        out: timeOutTransform
      }
    }
  };
}

export function getWeeklyOptions() {
  return [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' }
  ];
}

export function getMonthlyOptions() {
  return times(28).map(day => ({ value: day + 1, label: day + 1 }));
}
