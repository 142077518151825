import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { any } from 'prop-types';
import StaticRangesDisplay from 'components/bracketOptions/StaticRangesDisplay';
import PercentageRangesDisplay from 'components/bracketOptions/PercentageRangesDisplay';

@inject('$dictionary')
@observer
class BracketOptionsDisplay extends Component {
  static contextTypes = {
    form: any
  };

  render() {
    const { form } = this.context;
    const fieldValue = form.getValue('bracketOptions');
    const bracketType = fieldValue && fieldValue.type;

    return (
      <div>
        {bracketType === 'staticRanges' && <StaticRangesDisplay />}
        {(bracketType === 'percentages' || bracketType === 'percentiles') && <PercentageRangesDisplay />}
      </div>
    );
  }
}

export default BracketOptionsDisplay;
