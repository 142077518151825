import React, { Component } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';
import moment from 'moment';

import { Flex } from 'components/flexbox';
import { DateTime, Field, Select } from 'components/forms';
import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

import JumpButtons from './JumpButtons';

const MOMENT_FN = moment.utc;

const QueryTypeButton = observer(({ type, label, currentType, onClick, disabled = false }) => {
  const className = classNames({
    'pt-active': type === currentType
  });

  return (
    <Button className={className} onClick={() => onClick(type)} disabled={disabled}>
      {label}
    </Button>
  );
});

@inject('$dictionary', '$explorer')
@observer
export default class TimeOptions extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    showJumpButtons: true,
    showModes: ['lookback', 'lookback-from', 'lookback-to', 'range'],
    showFieldLabels: true,
    showTimeZoneSelector: true,
    rootFieldName: false,
    onModeChange: null,
    disabled: false
  };

  state = {
    queryType: 'lookback'
  };

  componentWillMount() {
    const { form } = this.context;
    if (!form.getValue(this.lookback_seconds)) {
      this.setState({ queryType: 'range' });
    }
  }

  componentDidUpdate() {
    if (this.context.form.getValue(this.lookback_seconds)) {
      if (this.state.queryType !== 'lookback') {
        this.handleModeSwitch('lookback');
      }
    } else if (this.state.queryType === 'lookback') {
      this.handleModeSwitch('range');
    }
  }

  getFullFieldName(fieldName) {
    const { rootFieldName } = this.props;
    return rootFieldName ? `${rootFieldName}.${fieldName}` : fieldName;
  }

  get lookback_seconds() {
    return this.getFullFieldName('lookback_seconds');
  }

  get starting_time() {
    return this.getFullFieldName('starting_time');
  }

  get ending_time() {
    return this.getFullFieldName('ending_time');
  }

  get from_to_lookback() {
    return this.getFullFieldName('from_to_lookback');
  }

  get time_format() {
    return this.getFullFieldName('time_format');
  }

  handleModeSwitch = queryType => {
    const { form } = this.context;

    this.setState({ queryType });

    const lookback_seconds = form.getValue(this.lookback_seconds);
    const starting_time = form.getValue(this.starting_time);
    const ending_time = form.getValue(this.ending_time);
    const from_to_lookback = form.getValue(this.from_to_lookback);

    if (queryType !== 'lookback') {
      form.setValue(this.lookback_seconds, 0);
      if (lookback_seconds && (!ending_time || !starting_time)) {
        form.setValue(this.ending_time, MOMENT_FN().format(DEFAULT_DATETIME_FORMAT));
        form.setValue(
          this.starting_time,
          MOMENT_FN()
            .subtract(lookback_seconds, 'second')
            .format(DEFAULT_DATETIME_FORMAT)
        );
        if (queryType === 'lookback-from' || queryType === 'lookback-to') {
          form.setValue(this.from_to_lookback, lookback_seconds);
        }
      } else if (!from_to_lookback && (queryType === 'lookback-from' || queryType === 'lookback-to')) {
        form.setValue(this.from_to_lookback, MOMENT_FN(ending_time).diff(MOMENT_FN(starting_time), 'second'));
      }
    } else {
      let diff = -1;
      if (starting_time && ending_time) {
        diff = MOMENT_FN(ending_time).diff(MOMENT_FN(starting_time), 'second');
      }
      if (this.getLookbackOptions().find(option => option.value === diff)) {
        form.setValue(this.lookback_seconds, diff);
      } else {
        form.setValue(this.lookback_seconds, lookback_seconds || 3600);
      }
      form.setValue(this.from_to_lookback, 0);
      form.setValue(this.starting_time, '');
      form.setValue(this.ending_time, '');
    }

    if (this.props.onModeChange) {
      this.props.onModeChange(queryType);
    }
  };

  handleTimeZoneChange = timezone => {
    if (this.state.queryType !== 'lookback') {
      const { form } = this.context;
      const prevMomentFn = timezone.getValue() === 'Local' ? MOMENT_FN : moment;
      const currMomentFn = timezone.getValue() === 'Local' ? moment : MOMENT_FN;
      const offset = currMomentFn().utcOffset(); // returns minutes
      form.setValue(
        this.starting_time,
        prevMomentFn(form.getValue(this.starting_time))
          .add(offset, 'minute')
          .valueOf()
      );
      form.setValue(
        this.ending_time,
        prevMomentFn(form.getValue(this.ending_time))
          .add(offset, 'minute')
          .valueOf()
      );
    }
  };

  handleStartingTimeChange = () => {
    const { queryType } = this.state;
    const { form } = this.context;

    if (queryType === 'lookback-from') {
      form.setValue(
        this.ending_time,
        MOMENT_FN(form.getValue(this.starting_time))
          .add(form.getValue(this.from_to_lookback), 'second')
          .format(DEFAULT_DATETIME_FORMAT)
      );
    }
  };

  handleEndingTimeChange = () => {
    const { queryType } = this.state;
    const { form } = this.context;

    form.getField(this.starting_time).setPristine(false);

    if (queryType === 'lookback-to') {
      form.setValue(
        this.starting_time,
        MOMENT_FN(form.getValue(this.ending_time))
          .subtract(form.getValue(this.from_to_lookback), 'second')
          .format(DEFAULT_DATETIME_FORMAT)
      );
    }
  };

  onFromToLookbackChange = lookback => {
    const { form } = this.context;
    const { queryType } = this.state;

    if (queryType === 'lookback-from') {
      form.setValue(
        this.ending_time,
        MOMENT_FN(form.getValue(this.starting_time))
          .add(lookback.value, 'second')
          .format(DEFAULT_DATETIME_FORMAT)
      );
    } else if (queryType === 'lookback-to') {
      form.setValue(
        this.starting_time,
        MOMENT_FN(form.getValue(this.ending_time))
          .subtract(lookback.value, 'second')
          .format(DEFAULT_DATETIME_FORMAT)
      );
    }
  };

  getStartingTimeProps() {
    const { queryType } = this.state;
    const { maxDateRange } = this.props;
    if (queryType === 'lookback' || queryType === 'lookback-to') {
      return {
        hidden: true,
        rules: ''
      };
    }
    let maxDateRangeValidation = '';
    if (maxDateRange) {
      maxDateRangeValidation = `|maxDateRange:${maxDateRange},${this.ending_time}`;
    }

    return {
      rules: `required|date|before:${this.ending_time}|beforeNow${maxDateRangeValidation}`
    };
  }

  getEndingTimeProps() {
    const { queryType } = this.state;
    const { maxDateRange } = this.props;

    if (queryType === 'lookback' || queryType === 'lookback-from') {
      return {
        hidden: true,
        rules: ''
      };
    }
    let maxDateRangeValidation = '';
    if (maxDateRange) {
      maxDateRangeValidation = `|maxDateRange:${maxDateRange},${this.starting_time}`;
    }

    return {
      rules: `required|date|beforeNow${maxDateRangeValidation}`,
      label: queryType === 'range' ? 'To' : 'Before'
    };
  }

  getLookbackOptions() {
    const { $dictionary, omitValues = [0] } = this.props;
    const options = $dictionary.getSelectOptions('showLast', { parseKeys: true, omitValues });
    const { queryType } = this.state;

    if (queryType === 'lookback-to') {
      return options.map(option => ({ ...option, label: option.label.replace('Last', 'Previous') }));
    } else if (queryType === 'lookback-from') {
      const secondsToNow = moment.utc().diff(moment.utc(this.context.form.getValue(this.starting_time)), 'second');
      return options
        .filter(option => option.value < secondsToNow)
        .map(option => ({ ...option, label: option.label.replace('Last', 'Next') }));
    }

    return options;
  }

  render() {
    let { queryType } = this.state;
    const { showJumpButtons, showModes, showFieldLabels, showTimeZoneSelector, tetherOptions, disabled } = this.props;

    const fieldProps = { flex: 1, disabled };

    // we are fooling these fields into working as "UTC" date time but under the covers they output local Dates.
    const maxDate = moment(
      (this.context.form.getValue(this.time_format) === 'Local' ? moment : MOMENT_FN)().format(DEFAULT_DATETIME_FORMAT)
    ).toDate();

    // If the component is only showing 'lookback' for example, we want to hide the controls to switch between views.
    const isShowingSingleMode = showModes.length === 1;

    if (this.context.form.getValue(this.lookback_seconds) && queryType !== 'lookback') {
      queryType = 'lookback';
    }

    return (
      <div>
        {!isShowingSingleMode && (
          <div className="pt-button-group pt-small pt-fill">
            {showModes.includes('lookback') && (
              <QueryTypeButton
                currentType={queryType}
                type="lookback"
                label="Lookback"
                onClick={this.handleModeSwitch}
                disabled={disabled}
              />
            )}
            {showModes.includes('lookback-from') && (
              <QueryTypeButton
                currentType={queryType}
                type="lookback-from"
                label="From"
                onClick={this.handleModeSwitch}
                disabled={disabled}
              />
            )}
            {showModes.includes('lookback-to') && (
              <QueryTypeButton
                currentType={queryType}
                type="lookback-to"
                label="To"
                onClick={this.handleModeSwitch}
                disabled={disabled}
              />
            )}
            {showModes.includes('range') && (
              <QueryTypeButton
                currentType={queryType}
                type="range"
                label="From + To"
                onClick={this.handleModeSwitch}
                disabled={disabled}
              />
            )}
          </div>
        )}
        <Flex align="flex-start" className="time-inputs" mt={1}>
          <Field
            name={this.starting_time}
            showLabel={showFieldLabels}
            {...fieldProps}
            {...this.getStartingTimeProps()}
            onChange={this.handleStartingTimeChange}
          >
            <DateTime className="pt-small" maxDate={maxDate} />
          </Field>
          <Field
            className="no-margin"
            name={this.lookback_seconds}
            showLabel={showFieldLabels}
            {...fieldProps}
            hidden={queryType !== 'lookback'}
            options={this.getLookbackOptions()}
          >
            <Select className="pt-small" tetherOptions={tetherOptions} />
          </Field>
          <Field
            className="no-margin"
            name={this.from_to_lookback}
            showLabel={showFieldLabels}
            {...fieldProps}
            hidden={queryType === 'lookback' || queryType === 'range'}
            options={this.getLookbackOptions()}
            onChange={this.onFromToLookbackChange}
          >
            <Select className="pt-small" tetherOptions={tetherOptions} />
          </Field>
          <Field
            name={this.ending_time}
            showLabel={showFieldLabels}
            {...fieldProps}
            {...this.getEndingTimeProps()}
            onChange={this.handleEndingTimeChange}
          >
            <DateTime className="pt-small" maxDate={maxDate} />
          </Field>
        </Flex>
        <Flex align="flex-start" mt={1} className="time-inputs">
          {showTimeZoneSelector && (
            <Field
              className="no-margin"
              name={this.time_format}
              showLabel={showFieldLabels}
              onChange={this.handleTimeZoneChange}
              disabled={disabled}
            >
              <Select className="pt-small" tetherOptions={tetherOptions} menuWidth={113} />
            </Field>
          )}
          {showJumpButtons && (
            <JumpButtons queryType={queryType} handleModeSwitch={this.handleModeSwitch} disabled={disabled} />
          )}
        </Flex>
      </div>
    );
  }
}
