import { action, computed, observable } from 'mobx';
import BaseModel from 'models/BaseModel';
import $dictionary from 'stores/$dictionary';

export const ALERT_SEVERITY_DISPLAY = {
  minor: 'Minor',
  minor2: 'Warning',
  major: 'Major',
  major2: 'Severe',
  critical: 'Critical',
  ack_req: 'ACK Required'
};

export const ALERT_SEVERITY_INTENT = {
  minor: 'pt-intent-warning',
  minor2: 'pt-intent-warning',
  major: 'pt-intent-danger',
  major2: 'pt-intent-danger',
  critical: 'pt-intent-critical',
  ack_req: 'pt-intent-primary'
};

const DEFAULT_THRESHOLDS = [
  { enabled: true, color: '#a82a2a', min: { value: 1, type: 'critical' }, above: { value: 2, type: 'major' } },
  { enabled: true, color: '#d65b4a', min: { value: 1, type: 'major' }, above: { value: 10, type: 'minor' } },
  { enabled: true, color: '#e68622', min: { value: 1, type: 'minor' } },
  { enabled: false, color: '#3A80EB', min: { value: 1, type: 'ack_req' } }
];

export default class ScoreboardModel extends BaseModel {
  @observable
  isAlarmsDialogOpen = false;

  get urlRoot() {
    return '/api/portal/alerts/scoreboards';
  }

  get defaults() {
    return {
      rows: [],
      cols: [],
      xaxis: { dimension: 'ip', limit: 6 },
      activeMitigations: [],

      // display: 'all_active' || [1234, 3456]
      yaxis: { display: 'all_active', limit: 6, policies: [] },
      thresholds: DEFAULT_THRESHOLDS
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Active Alerts Scoreboard',
      text: 'Are you sure you want to remove this Alert Scoreboard? This change will affect all users in your company.'
    };
  }

  @computed
  get title() {
    return this.get('query_title') || `Active Policies by ${this.xAxisDisplay}`;
  }

  @computed
  get hasData() {
    return this.get('rows').length > 0 || this.showMitigationsRow;
  }

  @computed
  get xAxisDisplay() {
    const xAxis = this.get('xaxis');
    const dimension = $dictionary.dictionary.alertScoreboardDimensionTypes[xAxis.dimension];
    return dimension ? dimension.label : xAxis.dimension;
  }

  @computed
  get showMitigationsRow() {
    const dimension = this.get('xaxis.dimension');
    const activeMitigations = this.get('activeMitigations');
    return dimension === 'ip' && activeMitigations && activeMitigations.length > 0;
  }

  @action
  handleToggleAlarmsDialog = () => (this.isAlarmsDialogOpen = !this.isAlarmsDialogOpen);
}
