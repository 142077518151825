import { Address4, Address6 } from 'ip-address';

export function fn(value) {
  if (value) {
    const v4 = new Address4(value);
    const v6 = new Address6(value);
    return value.indexOf('/') === -1 && (v4.isValid() || v6.isValid());
  }

  return true;
}

export default {
  message: 'Enter a valid IPv4 or IPv6 address. CIDRs are not supported.',
  fn
};
