import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import { Flex, Box } from 'components/flexbox';
import { ValidationErrorsOrHelpText } from 'components/forms';

import ModalOptionGroup from './ModalOptionGroup';
import ModalOptionGroupTitle from './ModalOptionGroupTitle';
import { getClassNameFromGroup } from './selectHelpers';

@observer
class ModalOptions extends Component {
  static defaultProps = {
    padding: 2, // coefficient for internal padding
    padTop: false,
    collapsedState: {},
    collapsible: true
  };

  @observable
  headingWidth;

  componentWillReceiveProps(nextProps) {
    const { collapsedState } = this.props;
    const { collapsedState: nextCollapsedState } = nextProps;

    if (
      (this.headingCmp && Object.keys(collapsedState).length !== Object.keys(nextCollapsedState).length) ||
      Object.keys(collapsedState).some(group => collapsedState[group] !== nextCollapsedState[group])
    ) {
      setTimeout(
        action(() => {
          this.headingWidth = this.headingCmp.offsetWidth;
        }),
        250
      );
    }
  }

  handleSelectGroup = options => {
    const { field, selectState, disabledValues = [] } = this.props;
    const { values, selectItems, unselectItems } = selectState;

    let groupOptions;
    if (Array.isArray(options)) {
      groupOptions = options;
    } else {
      groupOptions = Object.keys(options).reduce((result, group) => result.concat(options[group]), []);
    }

    groupOptions = groupOptions.filter(option => !disabledValues.includes(option.value));

    const groupValues = groupOptions.map(option => option.value);

    const allSelect = groupValues.every(value => values.includes(value));

    if (allSelect) {
      unselectItems(field, groupValues);
    } else {
      selectItems(field, groupValues);
    }
  };

  getOptions({ options = [], group = '', values, disabled }) {
    const { multi, disabledValues = [], collapsedState } = this.props;
    const onTitleClick = multi ? () => this.handleSelectGroup(options) : undefined;

    if (Array.isArray(options)) {
      return (
        <ModalOptionGroup
          {...this.props}
          className={getClassNameFromGroup(group)}
          group={group}
          key={group}
          onTitleClick={onTitleClick}
          options={options}
          values={values}
          disabled={disabled}
          collapsed={collapsedState[group]}
        />
      );
    }

    const optionsMarkup = (
      <div className="modal-options" ref={this.handleOptionsContainerRef}>
        {Object.keys(options).map(groupName =>
          this.getOptions({
            options: options[groupName],
            group: groupName,
            values,
            disabled: disabled || disabledValues.includes(groupName)
          })
        )}
      </div>
    );

    if (group) {
      return (
        <Box key={group} className="pt-card flat modal-options-parent-group">
          <ModalOptionGroupTitle {...this.props} group={group} onTitleClick={onTitleClick} disabled={disabled} />
          {optionsMarkup}
        </Box>
      );
    }

    return optionsMarkup;
  }

  handleOptionsContainerRef = box => {
    if (box) {
      this.headingCmp = box;
      setTimeout(
        action(() => {
          this.headingWidth = box.offsetWidth;
        }),
        100
      );
    }
  };

  render() {
    const { selectState, field, disabled, headings, padding, padTop } = this.props;
    const showError = field.hasError && (field.form.options.showPristineErrors || !field.pristine);

    return (
      <Flex flexColumn className="overflow-hidden" flexAuto>
        {showError && (
          <Box mb={padding} px={padding} pt={padTop ? padding : 0}>
            <ValidationErrorsOrHelpText calloutStyle field={field} />
          </Box>
        )}
        {headings && (
          <Box ml={padding} pt={padTop && !showError ? padding : 0} w={this.headingWidth} px={0.75}>
            {headings}
          </Box>
        )}
        <Box
          flexAuto
          className="overflow-auto"
          px={padding}
          pb={padding}
          pt={headings || (padTop && !showError) ? padding : 0}
        >
          {this.getOptions({ options: selectState.filteredOptions, values: selectState.values, disabled })}
        </Box>
      </Flex>
    );
  }
}

export default ModalOptions;
