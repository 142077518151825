import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Form, DateTime, Field, Input, Select, Checkbox } from 'components/forms/index';
import { fields, options } from 'forms/config/historyFilters';

const FilterGroup = observer(props => {
  const { form, $alerts } = props;
  const {
    myPolicies: { policyOptions }
  } = $alerts;

  const filterBy = form.getField('filterBy');
  const filterVal = form.getField('filterVal');

  const policyFilterOptions = [{ value: '', label: 'None' }].concat(policyOptions);

  return (
    <Box className="pt-form-group no-margin" mr={1} mb={2}>
      <label className="pt-label">Filter by</label>
      <div className="pt-form-content">
        <div className="pt-control-group">
          <Select
            placeholder="Filter By"
            field={filterBy}
            onChange={e => filterBy.onChange(e)}
            className="no-border-right-radius"
          />
          {filterBy.value !== 'alert_id' && (
            <Input
              field={filterVal}
              placeholder="Enter filter string..."
              onChange={e => filterVal.onChange(e)}
              style={{ minWidth: 250 }}
            />
          )}
          {filterBy.value === 'alert_id' && (
            <Field
              className="no-margin no-border-left-radius"
              name="filterVal"
              options={policyFilterOptions}
              inputStyle={{ minWidth: 300 }}
              style={{ marginRight: 8 }}
            >
              <Select menuWidth={350} />
            </Field>
          )}
        </div>
      </div>
    </Box>
  );
});

const FilterAlarmTypes = observer(props => {
  const { form } = props;

  const { mitigations, alarms, matches, learningMode } = form.getValues();

  const values = {
    Mitigations: mitigations,
    Alarms: alarms,
    Matches: matches,
    Silenced: learningMode
  };

  const buttonText = Object.keys(values)
    .filter(value => values[value])
    .map(value => (
      <div key={value} className="pt-tag pt-intent-primary pt-minimal" style={{ marginRight: 4 }}>
        {value}
      </div>
    ));

  return (
    <Box className="pt-form-group no-margin" mr={1} mb={2}>
      <label className="pt-label">Show Alert Types</label>
      <div className="pt-form-content">
        <Popover
          content={
            <div>
              <Field name="mitigations" showLabel={false} className="no-margin">
                <Checkbox />
              </Field>

              <Field name="alarms" showLabel={false} className="no-margin">
                <Checkbox />
              </Field>

              <Field name="matches" showLabel={false} className="no-margin">
                <Checkbox />
              </Field>

              <Field name="learningMode" showLabel={false} className="no-margin">
                <Checkbox />
              </Field>
            </div>
          }
          interactionKind={PopoverInteractionKind.CLICK}
          popoverClassName="pt-popover-content-sizing"
          position={Position.BOTTOM}
        >
          <Button rightIconName="caret-down">{buttonText}</Button>
        </Popover>
      </div>
    </Box>
  );
});

@Form({ fields, options })
@inject('$alerts')
@observer
class HistoryFilters extends Component {
  handleSubmit = () => {
    const { form, onSubmit } = this.props;

    if (onSubmit) {
      onSubmit(form.getValues());
    }
  };

  handleReset = () => {
    const { form, $alerts } = this.props;
    form.reset();
    $alerts.resetHistoryFilters();
  };

  handleRefresh = () => {
    const { form } = this.props;
    form.getField('endTime').setValue(new Date());
    this.handleSubmit();
  };

  render() {
    const { form, fetchingAlerts } = this.props;

    return (
      <Flex gutter={1} align="flex-end" px={1} wrap>
        <Box mr={1} mb={2}>
          <Field name="startTime" inputStyle={{ width: 140 }} className="no-margin">
            <DateTime />
          </Field>
        </Box>
        <Box mb={2}>
          <Field name="endTime" inputStyle={{ width: 140 }} className="no-margin">
            <DateTime />
          </Field>
        </Box>
        <Button
          className="pt-minimal"
          iconName="fast-forward"
          onClick={this.handleRefresh}
          style={{ marginBottom: 16, padding: 0 }}
        />
        <FilterGroup {...this.props} />
        <FilterAlarmTypes form={form} />
        <Flex mb={2}>
          <Button
            loading={fetchingAlerts}
            type="button"
            text="Apply"
            intent={Intent.PRIMARY}
            className="pt-medium"
            onClick={this.handleSubmit}
          />
          <Button
            disabled={fetchingAlerts}
            iconName="undo"
            onClick={this.handleReset}
            style={{ marginLeft: 8 }}
            text="Reset"
          />
        </Flex>
      </Flex>
    );
  }
}

export default HistoryFilters;
