import React from 'react';
import { observer } from 'mobx-react';
import MatchesByTimeRow from './MatchesByTimeRow';

const MatchesByTimeTable = props => {
  const { matches } = props.debugDataByTime;

  return (
    <table className="pt-table alerts-table pt-elevation-1">
      <thead>
        <tr>
          <th>Key</th>
          <th>Position</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {matches.map(values => (
          <MatchesByTimeRow key={values.id} values={values} {...props} />
        ))}
      </tbody>
    </table>
  );
};

export default observer(MatchesByTimeTable);
