import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box } from 'components/flexbox';

@inject('$dashboard')
@observer
class DashboardDescription extends Component {
  render() {
    const { $dashboard } = this.props;
    const description = $dashboard.dashboard.get('description');

    if (!description) {
      return null;
    }

    return (
      <Box px={2} className="pt-text-muted pt-text-smaller">
        {description}
      </Box>
    );
  }
}

export default DashboardDescription;
