import { action, observable, computed } from 'mobx';
import { Intent } from '@blueprintjs/core';
import moment from 'moment';

import $dictionary from 'stores/$dictionary';
import $alertingMitigation from 'stores/$alertingMitigation';
import Alarm from 'models/alerting/Alarm';
import AlarmCollection from 'models/alerting/AlarmCollection';
import AlertHistoryCollection from 'models/alerting/AlertHistoryCollection';
import AlarmsChartCollection from 'models/alerting/AlarmsChartCollection';
import AlertSummary from 'models/alerting/AlertSummary';
import PolicyCollection from 'models/alerting/PolicyCollection';
import PolicyLibraryCollection from 'models/alerting/PolicyLibraryCollection';
import SilentModeCollection from 'models/alerting/SilentModeCollection';
import SqlAlertsCollection from 'models/alerting/SqlAlertsCollection';
import SqlAlertIncidentsCollection from 'models/alerting/SqlAlertIncidentsCollection';
import ScoreboardCollection from 'models/alerting/ScoreboardCollection';
import { ALERT_SEVERITY_INTENT } from 'models/alerting/Scoreboard';
import Socket from 'util/Socket';
import api from 'util/api';
import { THRESHOLD_SEVERITIES } from 'util/constants';
import {
  deserializeCidrDimension,
  getBaselineAggrField,
  getQueryDeviceName,
  getAlarmFilters,
  getQueryTimeRange,
  getQueryUnits
} from 'views/Alerting/Alerts/alarmHelpers';

import SilentMode from '../models/alerting/SilentMode';

const defaultAlarmsFilterValues = () => ({
  startTime: moment()
    .utc()
    .add(-7, 'days')
    .format('YYYY-MM-DD HH:mm:00Z'),
  endTime: moment()
    .utc()
    .format('YYYY-MM-DD HH:mm:00Z'),
  filterBy: '',
  filterVal: ''
});

const defaultFilterValues = () => ({
  startTime: moment()
    .utc()
    .add(-1, 'days')
    .format('YYYY-MM-DD HH:mm:00Z'),
  endTime: moment()
    .utc()
    .format('YYYY-MM-DD HH:mm:00Z'),
  filterBy: 'alert_id',
  filterVal: '',
  mitigations: 1,
  alarms: 1,
  matches: 0,
  learningMode: 0
});

class AlertsStore {
  @observable
  alarms = new AlarmCollection();

  @observable
  alertHistory = new AlertHistoryCollection();

  @observable
  chart = new AlarmsChartCollection();

  @observable
  summary = new AlertSummary();

  @observable
  myPolicies = new PolicyCollection();

  @observable
  myPoliciesFull = new PolicyCollection(); // policies w/ subtenant thresholds

  @observable
  policyLibrary = new PolicyLibraryCollection();

  @observable
  silentModeKeys = new SilentModeCollection();

  @observable
  sqlAlerts = new SqlAlertsCollection();

  @observable
  sqlAlertsIncidents = new SqlAlertIncidentsCollection();

  @observable
  scoreboards = new ScoreboardCollection();

  @observable
  filterValues = Object.assign({}, defaultFilterValues());

  @observable
  alertCompanySettings;

  @observable
  allCounts = {};

  @observable
  alertCount = 0;

  @observable
  alertIntent = Intent.NONE;

  @observable
  fetchingAlerts = false;

  @observable
  fetchingDebug = false;

  @observable
  debugFields = {};

  @observable
  graphsData = {};

  @observable
  debugDataByPolicy = {
    bl: {
      values: []
    },
    last: {
      values: []
    }
  };

  @observable
  debugDataByTime = {
    long_set: [],
    matches: []
  };

  @observable
  kentikDebugData = {};

  alertSocket = null;

  @action
  setFilterValues(filters) {
    const { startTime, endTime, mitigations, alarms, matches, learningMode, filterBy, filterVal } = filters;

    const formatted = {
      filterBy,
      filterVal
    };

    if (startTime !== undefined && moment(startTime).isValid()) {
      formatted.startTime = moment(startTime)
        .utc()
        .format('YYYY-MM-DD HH:mm:00Z');
    }
    if (endTime !== undefined && moment(endTime).isValid()) {
      formatted.endTime = moment(endTime)
        .utc()
        .format('YYYY-MM-DD HH:mm:00Z');
    }
    if (mitigations !== undefined) {
      formatted.mitigations = mitigations === true || parseInt(mitigations, 10) ? 1 : 0;
    }
    if (alarms !== undefined) {
      formatted.alarms = alarms === true || parseInt(alarms, 10) ? 1 : 0;
    }
    if (matches !== undefined) {
      formatted.matches = matches === true || parseInt(matches, 10) ? 1 : 0;
    }
    if (learningMode !== undefined) {
      formatted.learningMode = learningMode === true || parseInt(learningMode, 10) ? 1 : 0;
    }
    this.filterValues = Object.assign({}, this.filterValues, formatted);
  }

  @action
  mitigate(data) {
    return api.post('/api/portal/alerts-active/mitigate', { data }).then(
      () => {
        console.info('mitigate action successful', data);
      },
      error => {
        console.error('error in mitigation', error);
      }
    );
  }

  @action
  getPlatformStatus({ platformID }) {
    return api.post('/api/portal/alerts/platform-status', {
      data: {
        platformID: parseInt(platformID, 10)
      }
    });
  }

  @action
  setFilterValue(filterField, filterFieldValue) {
    const VALID_FIELDS = [
      'startTime',
      'endTime',
      'mitigations',
      'alarms',
      'matches',
      'learningMode',
      'filterBy',
      'filterVal'
    ];
    if (VALID_FIELDS.indexOf(filterField) === -1 || filterFieldValue === undefined) {
      return;
    }
    if ((filterField === 'startTime' || filterField === 'endTime') && moment(filterFieldValue).isValid()) {
      this.filterValues[filterField] = moment(filterFieldValue)
        .utc()
        .format('YYYY-MM-DD HH:mm:00Z');
    } else {
      this.filterValues[filterField] = filterFieldValue;
    }
  }

  @action
  resetHistoryFilters() {
    this.setFilterValues(defaultFilterValues());
    this.fetch();
  }

  @action
  setDebugFields(debugFields) {
    this.debugFields = debugFields;
  }

  addToSilentMode = model => {
    const { alert_key, alert_dimension } = model.get();
    const alert_key_array = alert_key.split('__##__');
    const alert_dimension_array = alert_dimension.split(':');
    const alertKey = alert_key_array.map((value, idx) => {
      const dimension = deserializeCidrDimension(alert_dimension_array[idx]);
      return { dimension, value };
    });
    const expireDate = moment
      .utc()
      .add(7, 'days')
      .format('YYYY-MM-DD');
    const silentMode = new SilentMode();
    silentMode.save({
      alert_key: alertKey,
      alert_type: 'partial',
      expire: expireDate
    });
  };

  clearAlerts = () => {
    this.alarms.reset();
    this.alertHistory.reset();
    this.chart.reset();
    this.sqlAlerts.reset();
    this.sqlAlertsIncidents.reset();
  };

  getPoliciesWithDimensionType(dimensionType) {
    // which Dimensions should the Policies we want to show contain?
    // Lookups returns: `["IP_src", "IP_dst"]`
    const { dimensions: includeDimensions } = $dictionary.dictionary.alertScoreboardDimensionTypes[dimensionType];

    return this.myPolicies.models
      .filter(policy => {
        const policyDimensions = policy.get('dimensions');
        return policyDimensions.some(dim => includeDimensions.includes(dim));
      })
      .filter(policy => policy.get('status') === 'A')
      .map(policy => {
        const { policy_name, policy_description, id } = policy.get();
        return {
          value: policy.id,
          label: `${policy_name} (${id})`,
          policy_description
        };
      });
  }

  fixDebugPositionData(data) {
    // convert position from 0-based to 1-based, and replace nulls with 0
    if (data && data.alert_activate && data.alert_long_set) {
      const data_alert_long_set = data.alert_long_set;
      const data_alert_activate = data.alert_activate;
      if (data_alert_activate && Array.isArray(data_alert_activate)) {
        data_alert_activate.forEach(d => {
          d.current_position = Number.isNaN(parseInt(d.current_position)) ? 0 : d.current_position + 1;
          d.history_position = Number.isNaN(parseInt(d.history_position)) ? 0 : d.history_position + 1;
        });
      }
      if (data_alert_long_set && Array.isArray(data_alert_long_set)) {
        data_alert_long_set.forEach(d => {
          d.alert_position = Number.isNaN(parseInt(d.alert_position)) ? 0 : d.alert_position + 1;
        });
      }
      data.alert_long_set = data_alert_long_set;
      data.alert_activate = data_alert_activate;
    }
    return data;
  }

  sortDebugByTimeData({ alert_id, data }) {
    // sort by aggregated value desc
    const policy = this.myPolicies.get(alert_id);
    const { baseline } = policy.get();
    const { aggr_field, aggr_title } = getBaselineAggrField({ bl: baseline, prefix: 'alert_value_' });
    data.aggr_title = aggr_title;
    data.aggr_field = aggr_field;
    const { long_set } = data;
    if (long_set && Array.isArray(long_set)) {
      long_set.sort((a, b) => b[aggr_field] - a[aggr_field]);
    }
    return data;
  }

  sortPolicyDebugData(data) {
    // sort by aggregated value desc
    if (data) {
      const { bl, last } = data;
      if (bl && bl.values && Array.isArray(bl.values)) {
        const { aggr_field, aggr_title } = getBaselineAggrField({ bl });
        bl.aggr_title = aggr_title;
        bl.aggr_field = aggr_field;
        const { values } = bl;
        values.sort((a, b) => b[aggr_field] - a[aggr_field]);
      }
      if (last && last.values && Array.isArray(last.values)) {
        const { values } = last;
        values.sort((a, b) => b.value - a.value);
      }
    }
    return data;
  }

  @action
  setDebugGraphsData(data) {
    this.graphsData = data;
  }

  @action
  fetchDebugGraphsData(options) {
    const { alert_id, alert_key } = options;
    const debugUrl = `/api/portal/alerts-active/alert-key-graph/${alert_id}/${encodeURIComponent(alert_key)}`;
    this.fetchingDebug = true;
    return api
      .get(debugUrl)
      .then(results => this.fixDebugPositionData(results))
      .then(graphsData => this.setDebugGraphsData(graphsData));
  }

  @action
  fetchDebugDataByPolicy(options) {
    const { alert_id } = options;
    const debugUrl = `/api/portal/alerts-active/alert-debug/${alert_id}`;
    this.fetchingDebug = true;
    api.get(debugUrl).then(
      results => {
        this.debugDataByPolicy = this.sortPolicyDebugData(results);
        this.fetchingDebug = false;
      },
      () => {
        this.fetchingDebug = false;
      }
    );
  }

  @action
  fetchDebugDataByTime(options) {
    const { alert_id, policy_name, ctime } = options;
    if (alert_id && moment(ctime).isValid()) {
      const formattedTime = moment(ctime)
        .utc()
        .toISOString();
      const debugByTimeUrl = `/api/portal/alerts-active/alert-details-by-time?alert_id=${alert_id}&ctime=${formattedTime}`;
      api.get(debugByTimeUrl).then(results => {
        const debugDataByTime = this.sortDebugByTimeData({ alert_id, data: results });
        this.debugDataByTime = { policy_name, alert_id, ctime, ...debugDataByTime };
      });
    }
  }

  @action
  fetchKentikDebugData(params) {
    const { policyID, key, time } = params;
    const baselineSettings = params.baselineSettings ? JSON.parse(params.baselineSettings) : null;
    const baselineSingleKeyQueryURL = '/api/portal/alerts/active/baseline-single-key-query';
    const data = { policyID, key, time, baselineSettings };
    return api.post(baselineSingleKeyQueryURL, { data }).then(results => {
      this.kentikDebugData = results;
    });
  }

  // Used to color the AlertSummaryBox. Colored based on # of severities
  @computed
  get activeAlarmClassName() {
    const counts = this.summary.get();

    if (counts.critical > 0) {
      return 'pt-intent-critical';
    }

    if (counts.major > 0 || counts.major2 > 0) {
      return 'pt-intent-danger';
    }

    if (counts.minor > 0 || counts.minor2 > 0) {
      return 'pt-intent-warning';
    }

    return '';
  }

  getSelectedAlertsByTypes() {
    const selectedAlarms = [];
    const selectedMitigations = [];
    const { selected } = this.alarms;
    selected.forEach(model => {
      const { alarm_id, mitigation_id, row_type, alarm_state } = model.get();

      if (row_type === 'Alarm' && alarm_id !== 0) {
        selectedAlarms.push(alarm_id);
      } else if (row_type === 'Mitigation' && alarm_state === 'ACK_REQ' && mitigation_id !== 0) {
        // group action on mitigations is only supported for ack_req
        selectedMitigations.push(mitigation_id);
      }
    });
    this.alarms.clearSelection();
    return { alarms: selectedAlarms, mitigations: selectedMitigations };
  }

  clearSelectedAlarms({ comment, alarms }) {
    if (alarms && alarms.length > 0) {
      const data = {
        comment,
        ids: alarms.join(',')
      };
      const url = '/api/portal/alerts-active/clear-alarm';
      return api.post(url, { data }).then(
        () => {
          console.info('Alarms cleared successful');
        },
        error => {
          console.error('error clearing alarms', error);
        }
      );
    }
    return Promise.resolve();
  }

  ackSelectedMitigations({ comment, mitigations }) {
    if (mitigations && mitigations.length > 0) {
      const data = {
        comment,
        ids: mitigations.join(','),
        act: 'clear'
      };
      const url = '/api/portal/alerts-active/mitigate-ack-group';
      return api.post(url, { data }).then(
        () => {
          console.info('mitigations cleared successfully');
        },
        error => {
          console.error('error clearing mitigations', error);
        }
      );
    }
    return Promise.resolve();
  }

  getPoliciesUsingMitigationMethod(mitigationMethod) {
    return this.myPolicies.models.filter(policy => {
      const thresholds = policy.get('thresholds');
      if (thresholds.length) {
        return (
          thresholds.filter(
            threshold =>
              threshold.mitigations.length &&
              threshold.mitigations.find(
                thresholdMitigation =>
                  $alertingMitigation.platformMethods
                    .get(thresholdMitigation.pairing_id)
                    .get('mitigation_method_id') === mitigationMethod.id
              )
          ).length > 0
        );
      }
      return false;
    });
  }

  @action
  clearAlarms(options) {
    const { comment } = options;
    const { alarms, mitigations } = this.getSelectedAlertsByTypes();

    return Promise.all([
      this.clearSelectedAlarms({ comment, alarms }),
      this.ackSelectedMitigations({ comment, mitigations })
    ]);
  }

  @action
  fetch = async (options = {}) => {
    this.fetchingAlerts = true;
    const { isAlarms = false, loadFilters = false, policyOptions = {} } = options;

    if (!(loadFilters && this.filterValues)) {
      const defaultFilters = isAlarms ? defaultAlarmsFilterValues() : defaultFilterValues();
      this.setFilterValues(defaultFilters);
    }

    const query = this.filterValues;
    this.alerts = isAlarms ? this.alarms : this.alertHistory;
    this.clearAlerts();
    this.summary = new AlertSummary();

    let requests = [
      this.alerts.fetch({ query }),
      this.summary.fetch({ query: { ...query, isAlarmsPage: isAlarms ? 1 : 0 } }),
      this.myPolicies.fetch(policyOptions)
    ];

    if (!isAlarms) {
      requests = [...requests, this.chart.fetch({ query })];
    }

    this.alertCompanySettings = await api.get('/api/portal/alerts/alertSettings/');
    return Promise.all(requests).then(
      action(res => {
        this.fetchingAlerts = false;
        return res;
      })
    );
  };

  alertCountSubscribe() {
    if (!this.alertSocket) {
      this.alertSocket = new Socket({
        outType: 'subscribeAlerts',
        inType: 'alert',
        frequency: 30,
        delaySend: true,
        onSuccess: action(data => {
          const { mit_count = 0, alarm_count = 0, ack_count = 0, alarm_severities = '' } = data;

          this.alertCount = mit_count || alarm_count || ack_count;
          this.allCounts = { mit_count, alarm_count, ack_count };

          if (mit_count > 0) {
            this.alertIntent = 'pt-intent-purple';
          } else if (alarm_count > 0) {
            this.alertIntent =
              Object.keys(THRESHOLD_SEVERITIES).reduce((intent, severity) => {
                if (!intent && alarm_severities.includes(severity)) {
                  return ALERT_SEVERITY_INTENT[severity];
                }
                return intent;
              }, '') || 'pt-intent-warning';
          } else {
            this.alertIntent = 'pt-intent-primary';
          }
        }),
        onError(err) {
          console.warn('Received Alert Error (alertCountSubscribe)', err);
        },
        onReconnect: () => {
          this.alertSocket.send();
        }
      });
      this.alertSocket.setPayload({});
      this.alertSocket.send();
    }
  }

  loadViewByAlarmId(alarmId, destination, openInNewWindow) {
    const alarm = new Alarm({ id: alarmId });
    return alarm.fetch().then(() => this.loadViewByAlarm(alarm, destination, openInNewWindow));
  }

  loadViewByAlarm(alarm, destination, openInNewWindow) {
    const {
      alert_id,
      alert_key,
      alert_key_lookup,
      alert_dimension,
      alert_metric,
      alarm_start,
      alarm_end,
      alarm_start_time,
      ctime
    } = alarm.get();

    this.myPolicies.fetch().then(() => {
      const selectedPolicy = this.myPolicies.get(alert_id);
      // sometimes we show "Deleted Policy", which means that it won't be in our collection, and
      // we can't actually do anything because we do not have the Policy information to build a Query from.

      if (!selectedPolicy) {
        console.warn('Cannot navigate to Explorer: Policy not found');
        return;
      }

      const { filters, selected_devices, dashboard_id } = selectedPolicy.get();

      // track whether or not we get a device from alert_key
      let deviceNameFromAlertKey = false;
      // the getQueryDeviceName helper looks for i_device_id in dimension, extracts it from key, and converts
      // it to a device name. it returns null in any case that does not successfully result in a device name.
      let device_name = getQueryDeviceName(alert_dimension, alert_key);

      // if device_name is null, getQueryDeviceName couldn't come up with anything. process devices here then.
      if (device_name === null) {
        device_name = [];
        // only populate device_name if original query is not all_devices.
        if (!selected_devices.all_devices) {
          device_name = this.store.$devices
            .getUniqueSelectedDevices(selected_devices)
            .map(device => device.device_name);
        }
      } else {
        // device_name was not null from the helper, so we set this flag to true. this forces all_devices
        // to be false below.
        deviceNameFromAlertKey = true;
      }

      const alarmFilters = getAlarmFilters({ policyFilter: filters, alert_key, alert_key_lookup, alert_dimension });
      const timeRange = getQueryTimeRange({ alarm_start, alarm_end, alarm_start_time, ctime });
      const units = getQueryUnits(alert_metric);

      const explorerQuery = {
        filters: alarmFilters,
        starting_time: timeRange.query_start,
        ending_time: timeRange.query_end,
        time_format: 'UTC',
        lookback_seconds: 0,
        metric: ['i_device_id'],
        units,
        all_devices: !deviceNameFromAlertKey && selected_devices.all_devices,
        device_name
      };

      if (destination === 'dashboard') {
        const params = encodeURIComponent(JSON.stringify(explorerQuery));
        const url = `/library/dashboard/${dashboard_id}/urlParams/${params}`;
        if (openInNewWindow) {
          window.open(url, openInNewWindow ? '_blank' : undefined);
        } else {
          window.location = url;
        }
      } else {
        this.store.$explorer.preset(explorerQuery);
        if (openInNewWindow) {
          window.open('/explorer/preset');
        } else {
          window.location = '/explorer/preset';
        }
      }
    });
  }

  // tests a given policy name for a standard DDoS prefix
  // it is backwards compatible with previous versions of the prefix
  isDDoSPolicyName(name) {
    return /^(DDoS - |V4 DDoS - )/.test(name);
  }

  @action
  destroy() {
    this.clearAlerts();
    this.summary = new AlertSummary();
    this.alertCount = 0;
    this.alertIntent = Intent.NONE;
  }
}

export default new AlertsStore();
