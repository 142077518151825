import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import $sites from 'stores/$sites';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['i_ult_exit_site', 'i_device_site_name', 'i_device_site_country', 'i_ult_exit_site_country'],
  filterFields: ['i_ult_exit_site', 'i_device_site_name', 'i_device_site_country', 'i_ult_exit_site_country']
};

class SiteDependency extends AbstractDependency {
  get key() {
    return 'site';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $sites.collection.unfilteredSize > 0;
  }

  get message() {
    return (
      <Fragment>
        <Box className="pt-text-smaller" mb={0.5}>
          <strong>Sites</strong> are required by dimensions you're using, but your organization currently has none
          defined.
        </Box>
        {this.showConfigureButton && (
          <Button iconName="cog" className="pt-small" onClick={() => $sites.store.history.push('/admin/sites')}>
            Configure
          </Button>
        )}
      </Fragment>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $sites.collection.unfilteredSize > 0;
  }
}

export default SiteDependency;
