import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Form, FormDialog } from 'components/forms';
import { fields } from 'forms/config/alertManualMitigation';

import ManualMitigationForm from './ManualMitigationForm';

@Form({ fields })
@observer
class ManualMitigationDialogForm extends Component {
  render() {
    return (
      <FormDialog
        {...this.props}
        title="Start Manual Mitigation"
        entityName="Manual Mitigation"
        formComponent={<ManualMitigationForm />}
        style={{ width: 550 }}
      />
    );
  }
}

export default ManualMitigationDialogForm;
