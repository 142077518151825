import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Flex } from 'components/flexbox';
import { Tag } from '@blueprintjs/core';
import { hexToRgbA } from 'util/colors';
import { ColorBlock } from 'components/index';

@inject('$deviceGroups', '$devices')
@observer
class LabelTag extends Component {
  state = {
    mouseOver: false
  };

  handleMouse = e => {
    this.setState({ mouseOver: e.type === 'mouseenter' });
  };

  @computed
  get color() {
    const { $deviceGroups, label } = this.props;
    const { labelColors } = $deviceGroups.labelCollection;
    return label.color || labelColors[label.id];
  }

  @computed
  get showHoverStyles() {
    const { onClick } = this.props;
    const { mouseOver } = this.state;
    return mouseOver && onClick;
  }

  getTagStyle = () => {
    const { style } = this.props;

    const color = this.color;
    const background = this.showHoverStyles ? hexToRgbA(this.color, 0.85) : this.color;
    const cursor = this.showHoverStyles ? 'pointer' : undefined;
    const border = `1px solid ${color}`;

    return {
      background,
      border,
      color: '#fff',
      cursor,
      ...style
    };
  };

  render() {
    const { label, showColorBlock = false, onClick } = this.props;

    if (!label || !label.name) {
      return null;
    }

    return (
      <Tag onMouseEnter={this.handleMouse} onMouseLeave={this.handleMouse} onClick={onClick} style={this.getTagStyle()}>
        <Flex align="center">
          {showColorBlock && <ColorBlock color={this.color} style={{ width: 12, height: 12, marginRight: 4 }} />}
          {label.name || label.get('name')}
        </Flex>
      </Tag>
    );
  }
}

export default LabelTag;
