import React, { Component } from 'react';
import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';

import ModalSelectTitle from './ModalSelectTitle';
import ModalValuesPanel from './ModalValuesPanel';
import SelectState from './SelectState';

class AbstractSelect extends Component {
  constructor(props, context) {
    super(props, context);

    this.selectState = new SelectState(props);
  }

  componentWillReceiveProps(nextProps) {
    const { isEditing } = this.props;
    if (!isEditing && nextProps.isEditing) {
      this.selectState.initLastOpenedValue();
      this.selectState.setFilterValue('');
    }
  }

  renderDialog() {
    const { isEditing, multi, dialogStyle, canEscapeKeyClose, title, helpContents } = this.props;
    const { closeOptions } = this.selectState;
    const popoverCls = classNames('modal-select modal-select-popover pt-minimal', { multi });

    return (
      <Dialog
        title={<ModalSelectTitle title={title || 'Select Values'} helpContents={helpContents} />}
        canEscapeKeyClose={canEscapeKeyClose}
        className={popoverCls}
        isOpen={isEditing}
        onClose={closeOptions}
        style={dialogStyle}
        transitionName="pt-dialog"
      >
        {this.renderDialogBody()}
      </Dialog>
    );
  }

  render() {
    const { multi, dialogOnly, style } = this.props;

    const dialog = this.renderDialog();

    if (dialogOnly) {
      return dialog;
    }

    return (
      <div className={classNames('modal-select-input', { multi })} style={style}>
        {dialog}
        <ModalValuesPanel {...this.props} selectState={this.selectState} />
      </div>
    );
  }
}

export default AbstractSelect;
