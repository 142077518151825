import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Box } from 'components/flexbox';

@observer
class FilterConnectorDisplay extends Component {
  render() {
    const { group } = this.props;
    const connector = group.connector.getValue();
    const shouldExcludeResults = group.not.getValue();

    return (
      <Box p={0.5}>
        <small>
          <span
            className={classNames('pt-strong', {
              'pt-intent-success-text': !shouldExcludeResults,
              'pt-intent-danger-text': shouldExcludeResults
            })}
          >
            {shouldExcludeResults ? 'Exclude' : 'Include'}
          </span>{' '}
          results matching <span className="pt-tag pt-small">{connector.toLowerCase()}</span>
        </small>
      </Box>
    );
  }
}

export default FilterConnectorDisplay;
