import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Checkbox, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';

import VirtualizedTable from 'components/table/VirtualizedTable';
import { CELL_TYPES } from 'components/table';
import { Box } from 'components/flexbox';

import PolicyCell from './PolicyCell';
import TypeCell from './TypeCell';
import KeyDimensionCell from './KeyDimensionCell';
import AlertValueCell from './AlertValueCell';
import MitAlarmIdCell from './MitAlarmIdCell';
import TimestampCell from './TimestampCell';
import AlarmStartEndCell from './AlarmStartEndCell';

@inject('$auth', '$dictionary')
@observer
class AlertTable extends Component {
  getTableActions(model) {
    const { row_type, alarm_state } = model.get();
    const actions = row_type === 'Mitigation' ? this.getMitigationActions(alarm_state) : this.getAlarmActions();
    const tdClassName = classNames('td actions');
    return <Box className={tdClassName}>{actions.map(action => action(model))}</Box>;
  }

  getMitigationActions(alarm_state) {
    const { onMitigation, $auth, $dictionary, isAlarmsPage } = this.props;

    const { actionsForState } = $dictionary.dictionary.mitigations.actions || {};
    const actions = (actionsForState && actionsForState[alarm_state]) || [];

    const actionsExtraInfo = {
      // machineV1 events:
      stopLegacy: {
        iconName: 'stop',
        permission: 'alerts.mitigations.stop'
      },
      startLegacy: {
        iconName: 'play',
        permission: 'alerts.mitigations.start'
      },
      // machineV2 events:
      manualControl: {
        iconName: 'send-to',
        permission: 'alerts.mitigations.stop'
      },
      stop: {
        iconName: 'stop',
        permission: 'alerts.mitigations.stop'
      },
      start: {
        iconName: 'play',
        permission: 'alerts.mitigations.start'
      },
      delete: {
        iconName: 'trash',
        permission: 'alerts.mitigations.stop'
      },
      approveStart: {
        iconName: 'step-forward',
        permission: 'alerts.mitigations.start'
      },
      skipWait: {
        iconName: 'step-forward',
        permission: 'alerts.mitigations.start'
      },
      retry: {
        iconName: 'redo',
        permission: 'alerts.mitigations.start'
      },
      ack: {
        iconName: 'step-forward',
        permission: 'alerts.mitigations.start'
      }
    };

    return actions.map(action => {
      const { actionName, actionDescription, eventName } = action;
      const iconName = (actionsExtraInfo[actionName] && actionsExtraInfo[actionName].iconName) || 'asterisk';
      const permission =
        (actionsExtraInfo[actionName] && actionsExtraInfo[actionName].permission) || 'alerts.mitigations.start';
      const hasPermission = permission && $auth.hasPermission(permission);

      return model =>
        hasPermission &&
        isAlarmsPage &&
        eventName !== 'userAck' && (
          <Tooltip key={actionName} content={actionDescription}>
            <Button
              iconName={iconName}
              className="pt-minimal"
              text={actionDescription}
              title=""
              onClick={() => {
                onMitigation(model, action);
              }}
            />
          </Tooltip>
        );
    });
  }

  getAlarmActions() {
    const { onDebugClick, onDebugByTimeClick, onViewQuery, $auth, isAlarmsPage } = this.props;

    return [
      model =>
        $auth.hasPermission('alerts.explorer.view') && (
          <Tooltip key="explorer" content="Open in Explorer">
            <Button
              iconName="timeline-area-chart"
              className="pt-minimal"
              text="Open in Explorer"
              title=""
              onClick={() => {
                onViewQuery(model, 'explorer');
              }}
            />
          </Tooltip>
        ),
      model => (
        <Tooltip key="dashboard" content="Open in Dashboard">
          <Button
            iconName="dashboard"
            className="pt-minimal"
            text="Open in Dashboard"
            title=""
            onClick={() => {
              onViewQuery(model, 'dashboard');
            }}
          />
        </Tooltip>
      ),
      model =>
        $auth.hasPermission('alerts.debug.view') && (
          <Tooltip key="debug" content="Debug">
            <Button
              iconName="scatter-plot"
              className="pt-minimal"
              text="Debug"
              title=""
              onClick={() => {
                onDebugClick(model);
              }}
            />
          </Tooltip>
        ),
      model =>
        !isAlarmsPage && (
          <Tooltip key="zoom" content="Positional Data">
            <Button
              iconName="zoom-in"
              className="pt-minimal"
              text="Positional Data"
              title=""
              onClick={() => {
                onDebugByTimeClick(model);
              }}
            />
          </Tooltip>
        )
    ];
  }

  getTableColumns() {
    const { $auth, isAlarmsPage, showAlarmSelect = true } = this.props;
    const showSilentButton = $auth.hasPermission('alerts.silentMode.create');
    const showPolicyEdit = $auth.hasPermission('alerts.policies.edit');
    const tableColumns = [];

    if ($auth.hasPermission('alerts.alarms.clear') && showAlarmSelect) {
      tableColumns.push({
        key: 'select',
        type: CELL_TYPES.ACTION,
        customSelect: true,
        actions: [
          model => (
            <Checkbox
              style={{ margin: 0, minHeight: 13 }}
              className="pt-inline"
              key="select"
              checked={model.isSelected && !model.isActiveMitigation}
              onChange={null}
              disabled={model.isActiveMitigation}
              onClick={e => {
                e.stopPropagation();
                model.select({ multi: true });
              }}
            />
          )
        ],
        hidden: !isAlarmsPage,
        width: 36
      });
    }

    tableColumns.push(
      {
        label: 'State',
        name: 'alarm_state',
        className: 'policy-cell column align-center',
        sortable: true,
        ellipsis: false,
        hidden: !isAlarmsPage,
        width: 90,
        renderer: ({ model }) => <TypeCell model={model} {...this.props} />
      },
      {
        label: 'Policy',
        name: 'policy',
        className: 'policy-cell',
        sortable: false,
        flexBasis: 190,
        ellipsis: false,
        renderer: ({ model }) => <PolicyCell model={model} showPolicyEdit={showPolicyEdit} {...this.props} />
      },
      {
        label: 'Key / Dimension',
        name: 'keyDimension',
        sortable: false,
        flexBasis: 210,
        ellipsis: false,
        renderer: ({ model }) => <KeyDimensionCell model={model} showSilentButton={showSilentButton} {...this.props} />
      },
      {
        label: 'Value',
        name: 'alertValue',
        className: 'column overflow-hidden',
        sortable: false,
        ellipsis: false,
        renderer: ({ model }) => <AlertValueCell model={model} {...this.props} />
      },
      {
        label: 'Mit ID / Alarm ID',
        name: 'mitAlarmId',
        className: 'column overflow-hidden',
        sortable: false,
        ellipsis: false,
        renderer: ({ model }) => <MitAlarmIdCell model={model} {...this.props} />
      },
      {
        label: 'Timestamp (UTC)',
        name: 'timeStamp',
        hidden: isAlarmsPage,
        sortable: false,
        ellipsis: false,
        renderer: ({ model }) => <TimestampCell model={model} {...this.props} />
      },
      {
        label: 'Start / End (UTC)',
        name: 'alarmStartEnd',
        hidden: !isAlarmsPage,
        className: 'column overflow-hidden',
        sortable: false,
        ellipsis: false,
        renderer: ({ model }) => <AlarmStartEndCell model={model} {...this.props} />
      },
      {
        label: 'Actions',
        key: 'actions',
        className: 'actions',
        sortable: false,
        renderer: ({ model }) => this.getTableActions(model)
      }
    );

    return tableColumns;
  }

  getRowHeight = ({ index }) => {
    const { collection, isAlarmsPage } = this.props;
    const model = collection.at(index);

    const alert_metric = model.get('alert_metric');
    const alert_key_lookup = model.get('alert_key_lookup');
    const row_type = model.get('row_type');

    const minSubRows = isAlarmsPage ? 2 : 2.5;

    if (model && row_type !== 'Mitigation') {
      const keys = alert_key_lookup.split('__##__').length;
      const metrics = alert_metric.length;
      // There are always two IDs to show in the MitAlarmIdCell, so it has to be at least this high
      // When we use metrics, we'll always show the baseline too, so need +1
      return Math.max(keys, metrics + 1, minSubRows) * 20 + 24;
    }

    return 80;
  };

  render() {
    const { collection, isAlarmsPage, hideColumns } = this.props;

    let columns = this.getTableColumns();

    if (hideColumns) {
      columns = columns.filter(({ name, key }) => !hideColumns.includes(name) && !hideColumns.includes(key));
    }

    return (
      <VirtualizedTable
        className={classNames('alerts-table', 'condensed', { active: isAlarmsPage })}
        flexed
        rowHeight={this.getRowHeight}
        columns={columns}
        collection={collection}
        selectOnRowClick={false}
        style={{ minHeight: 400 }}
      />
    );
  }
}

export default AlertTable;
