import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Collapse } from '@blueprintjs/core';

import { Icon } from 'components';
import { Flex, Box } from 'components/flexbox';
import { Field, Select } from 'components/forms';
import FiltersDisplay from 'components/FiltersDisplay/FiltersDisplay';

const savedFilterIncludeExcludeRenderer = option => (
  <span className={option.value ? 'pt-intent-danger-text' : 'pt-intent-success-text'}>
    <Icon name={option.value ? 'cross' : 'tick'} style={{ fontSize: 12, marginRight: 4 }} />
    <strong>{option.label}</strong>
  </span>
);

@inject('$savedFilters')
@observer
class SavedFilter extends Component {
  state = {
    isOpen: false
  };

  handleToggleDetails = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { $savedFilters, filter_id, filterIdx, is_not, onCustomize, onRemove } = this.props;
    const { isOpen } = this.state;
    const savedFilter = $savedFilters.getSavedFilterById(filter_id);

    if (!savedFilter) {
      return (
        <Box className="pt-card" p={1}>
          <Flex justify="space-between">
            <Flex align="center" flexAuto>
              <Box className="pt-intent-danger-text">Invalid saved filter ({filter_id})</Box>
            </Flex>
            <Box>
              <Button
                iconName="cross"
                className="pt-small pt-minimal pt-intent-danger"
                onClick={() => onRemove(filterIdx)}
                style={{ marginLeft: 5, marginRight: -3 }}
              />
            </Box>
          </Flex>
        </Box>
      );
    }

    return (
      <Box className="pt-card" p={1}>
        <Flex justify="space-between">
          <Flex align="center" flexAuto>
            <Box mr={0.5}>
              <Button
                iconName={isOpen ? 'caret-down' : 'caret-right'}
                className="pt-minimal pt-small"
                onClick={this.handleToggleDetails}
              />
            </Box>
            <Box>
              <strong>{savedFilter.get('filter_name')}</strong>
              <div style={{ lineHeight: '12px' }}>
                <small className="pt-text-muted">{savedFilter.get('filter_description')}</small>
              </div>
            </Box>
          </Flex>
          <Box>
            <Field field={is_not} showLabel={false} className="no-margin">
              <Select className="pt-small" valueRenderer={savedFilterIncludeExcludeRenderer} />
            </Field>
          </Box>
          <Box>
            <Button
              className="pt-small"
              iconName="edit"
              onClick={() => onCustomize(filterIdx)}
              style={{ marginLeft: 5 }}
              text="Customize"
            />
            <Button
              iconName="cross"
              className="pt-small pt-minimal pt-intent-danger"
              onClick={() => onRemove(filterIdx)}
              style={{ marginLeft: 5, marginRight: -3 }}
            />
          </Box>
        </Flex>
        <Collapse isOpen={isOpen}>
          <Box pt={1}>
            <FiltersDisplay filters={savedFilter.get('filters')} />
          </Box>
        </Collapse>
      </Box>
    );
  }
}

export default SavedFilter;
