import React, { Component } from 'react';
import { any } from 'prop-types';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Field, Input, Select } from 'components/forms';
import { Tag } from '@blueprintjs/core';

const tetherOptions = {
  offset: '-3px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

const platformMethodOptionRenderer = props => {
  const { className, disabled, field, key, label, selectItem, selected, style, separator = false, value } = props;

  if (separator) {
    return <hr key="separator" style={{ margin: '4px 8px' }} />;
  }

  const onClick = !selected && !disabled ? () => selectItem(field, value) : undefined;

  return (
    <div key={key || value} className={className} onClick={onClick} style={style}>
      {label}
      {disabled && <Tag className="pt-small">Provisioning</Tag>}
    </div>
  );
};

@inject('$alertingMitigation', '$alerts')
@observer
class ManualMitigationForm extends Component {
  static contextTypes = {
    form: any
  };

  state = {
    ready: false
  };

  componentWillMount() {
    const { $alertingMitigation } = this.props;
    Promise.all([$alertingMitigation.methods.fetch(), $alertingMitigation.platformMethods.fetch()]).then(() => {
      this.setState({ ready: true });
    });
  }

  componentWillUnmount() {
    this.props.$alertingMitigation.platformMethods.reset();
    this.props.$alerts.fetch({ isAlarms: true });
  }

  @action
  onPlatformChange = (field, value) => {
    const { form } = this.context;
    const { $alertingMitigation } = this.props;
    const alertKeyField = form.getField('alertKey');

    const platformMethod = $alertingMitigation.platformMethods.find({ id: value });

    const mitigationMethod = $alertingMitigation.methods.find({ id: platformMethod.get('mitigation_method_id') });

    alertKeyField.helpText = '';
    alertKeyField.setRules('required|ip|rtbhMeetsNexthopFamily');
    alertKeyField.enable();
    alertKeyField.setValue('');

    if (mitigationMethod.get('method_mitigation_device_type') === 'Flowspec') {
      const {
        flowSpec: { src_ip, dst_ip }
      } = mitigationMethod.get('method_mitigation_device_detail');

      if (src_ip.enabled && !src_ip.infer && dst_ip.enabled && !dst_ip.infer) {
        alertKeyField.helpText = 'Selected Mitigation Method does not have an overridable IP/CIDR';
        alertKeyField.setRules('');
        alertKeyField.setValue('N/A');
        alertKeyField.disable();
      }
    }
  };

  getPlatformOptions = () => {
    const { $alertingMitigation } = this.props;

    if ($alertingMitigation.platformMethodOptions.length && this.state.ready) {
      return $alertingMitigation.platformMethodOptions.filter(option => {
        const mitigationMethod = $alertingMitigation.methods.get(
          $alertingMitigation.platformMethods.get(option.value).get('mitigation_method_id')
        );
        if (mitigationMethod.get('method_mitigation_device_type') === 'Flowspec') {
          const flowSpecData = mitigationMethod.get('method_mitigation_device_detail').flowSpec;
          return !(
            (flowSpecData.protocol.enabled && flowSpecData.protocol.infer) ||
            (flowSpecData.src_port.enabled && flowSpecData.src_port.infer) ||
            (flowSpecData.dst_port.enabled && flowSpecData.dst_port.infer)
          );
        }

        return true;
      });
    }

    return undefined;
  };

  render() {
    return (
      <div>
        <p className="pt-running-text">
          Use this form to manually start a mitigation. A mitigation started manually will automatically stop after the
          specified TTL.
        </p>
        <Field options={this.getPlatformOptions()} name="pairingID" onChange={this.onPlatformChange}>
          <Select tetherOptions={tetherOptions} menuWidth={494} optionRenderer={platformMethodOptionRenderer} />
        </Field>
        <Field name="alertKey">
          <Input />
        </Field>
        <Field name="comment">
          <Input />
        </Field>
        <Field name="minutesBeforeAutoStop">
          <Input />
        </Field>
      </div>
    );
  }
}

export default ManualMitigationForm;
