import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Collapse, Icon } from '@blueprintjs/core';
import Recaptcha from 'react-grecaptcha';

import { setUtmCookies } from 'util/utmTracking';

import { Box, Flex } from 'components/flexbox';
import { showClientErrorToast } from 'components/Toast';
import { Select } from 'components/forms/index';
import { KentikLogo } from 'components';
import { Redirect } from 'react-router-dom';
import { Field, Form, Input, Checkbox } from 'components/forms';
import { fields } from 'forms/config/signup';

import SocialButtons from 'components/SocialButtons';

import CookieNotice from './Login/CookieNotice';
import RegionSelect from './Login/RegionSelect';
import TermsOfService from './TermsOfService';
import TrialDetails from './TrialDetails';

@Form({ fields })
@inject('$auth', '$signup')
@observer
class Signup extends Component {
  state = {
    menuOpen: false,
    termsOpen: false,
    trialDetailsOpen: false
  };

  componentDidMount() {
    const { $auth, form } = this.props;
    setUtmCookies();
    $auth
      .getOpenConfig()
      .then(() => {
        form.getField('recaptcha').setRules($auth.openConfig.recaptchaEnabled ? 'required' : []);
      })
      .catch(() => {
        showClientErrorToast('Error fetching initial configuration');
      });
  }

  recaptchaCallback = response => {
    this.props.form.getField('recaptcha').setValue(response);
  };

  recaptchaExpire = () => {
    this.props.form.getField('recaptcha').setValue(null);
  };

  handleMenuButtonClick = () => this.setState({ menuOpen: !this.state.menuOpen });

  handleSubmit = async (form, formData) => {
    const { $auth, $signup } = this.props;
    const region = $auth.currentRegion ? $auth.currentRegion.name : 'US';
    const {
      user_email,
      first_name,
      last_name,
      company_name,
      job_title,
      phone,
      email_optin,
      accept_terms,
      recaptcha,
      Lead_Region__c
    } = formData;

    form.getFields().forEach(field => {
      field.showPristineErrors = true;
    });

    if (!form.validate()) {
      return;
    }

    const result = await $signup.createAccount({
      user_email,
      first_name,
      last_name,
      company_name,
      job_title,
      phone,
      region,
      email_optin,
      accept_terms,
      recaptcha,
      Lead_Region__c
    });

    if ($auth.openConfig.recaptchaEnabled) {
      window.grecaptcha.reset();
      form.getField('recaptcha').setValue(null);
    }

    if (result.success) {
      this.setState({ reqComplete: true });
      if (window.dataLayer) {
        try {
          window.dataLayer.push({
            event: 'freeTrialSignupSuccess'
          });
        } catch (e) {
          console.warn('error pushing GTM', e);
        }
      }
    } else {
      form.invalidate(result.msg || 'Error creating account');
    }
  };

  handleLeadRegionChange = (field, value) => {
    const { form } = this.props;

    if (value !== 'European Union') {
      form.setValue('accept_terms', true);
    } else {
      form.setValue('accept_terms', false);
    }
  };

  renderHeaderLinks() {
    return (
      <Fragment>
        <Box flexAuto>
          <a className="trial-signup-header-link uppercase" href="https://kentik.com/product">
            Product
          </a>
          <a className="trial-signup-header-link uppercase" href="https://kentik.com/solutions">
            Solutions
          </a>
          <a className="trial-signup-header-link uppercase" href="https://kentik.com/customers">
            Customers
          </a>
          <a className="trial-signup-header-link uppercase" href="https://kentik.com/resources">
            Resources
          </a>
          <a className="trial-signup-header-link uppercase" href="https://kentik.com/about">
            About
          </a>
        </Box>
        <Flex flexAuto justify="flex-end">
          <a className="trial-signup-header-link" href="https://kentik.com/blog">
            Blog
          </a>
          <a className="trial-signup-header-link" href="https://portal.kentik.com/">
            Login
          </a>
        </Flex>
      </Fragment>
    );
  }

  render() {
    const { form, $auth, $signup } = this.props;
    const { reqComplete, termsOpen, trialDetailsOpen } = this.state;
    const showRecaptcha = $auth.openConfig && $auth.openConfig.recaptchaEnabled;

    if (!$auth.openConfig) {
      return null;
    }

    // if signup is disabled, redirect to /
    if ($auth.openConfig && $auth.openConfig.signupDisabled) {
      return (
        <Redirect
          to={{
            pathname: '/'
          }}
        />
      );
    }

    return (
      <Flex flexColumn className="trial-signup">
        <Box className="trial-signup-header">
          <Box className="inner">
            <a href="https://kentik.com">
              <KentikLogo style={{ width: 210 }} />
            </a>
            <Button
              className="trial-signup-header-button pt-minimal"
              iconName="menu"
              onClick={this.handleMenuButtonClick}
            />
            <Flex className="trial-signup-header-links" flexAuto justify="space-between" ml={3}>
              {this.renderHeaderLinks()}
            </Flex>
          </Box>
        </Box>
        <Flex className="trial-signup-wrap" justify="center" px={2} mb={5} wrap style={{ flex: '1 0 auto' }}>
          <Collapse className="trial-signup-menu-collapse" isOpen={this.state.menuOpen}>
            <Box className="trial-signup-header-links">{this.renderHeaderLinks()}</Box>
          </Collapse>
          <Box className="trial-signup-content" mr={4} mb={4}>
            <h1>Start your free 30-day trial</h1>
            <p style={{ marginBottom: 20 }}> Learn how Kentik can help you:</p>
            <ul>
              <li>
                <strong>Improve real-time visibility</strong> across your entire network infrastructure: edge, cloud,
                datacenter, and everywhere in between.
              </li>
              <li>
                <strong>Detect and isolate performance problems</strong> in applications, providers, and networks.
              </li>
              <li>
                <strong>Get automatic notifications</strong> about unexpected traffic patterns and quickly analyze &
                isolate root causes.
              </li>
              <li>
                <strong>Improve network ROI</strong>: eliminate unnecessary spend and allocate cloud and network costs
                to teams, departments, or customers.{' '}
              </li>
              <li>
                <strong>Visualize</strong> your network traffic data, or explore with our pre-populated demo data.
              </li>
            </ul>
            <p>
              Not ready to try it yourself?{' '}
              <a href="https://www.kentik.com/go/get-demo/" id="demoLink" className="demo-link">
                Get a personalized demo
              </a>{' '}
              with a Kentik product expert.
            </p>

            <TrialDetails
              isOpen={trialDetailsOpen}
              onClose={() => this.setState({ trialDetailsOpen: false })}
              onOpen={() => this.setState({ trialDetailsOpen: true })}
            />
          </Box>

          <Box sm={12} col={4} className="pt-dark trial-signup-form">
            {!reqComplete && (
              <Fragment>
                {form.error && (
                  <Box className="pt-callout pt-intent-danger pt-icon-error" my={2}>
                    <h5>Unable to Create Account</h5>
                    {form.error}
                  </Box>
                )}

                <RegionSelect />

                <Flex gutter={1} mb={2}>
                  <Box px={1}>
                    <Field className="no-margin" placeholder="First Name" showLabel={false} name="first_name" autoFocus>
                      <Input className="pt-large" />
                    </Field>
                  </Box>
                  <Box px={1}>
                    <Field className="no-margin" placeholder="Last Name" showLabel={false} name="last_name">
                      <Input className="pt-large" />
                    </Field>
                  </Box>
                </Flex>

                <Flex gutter={1} mb={2}>
                  <Box px={1}>
                    <Field className="no-margin" placeholder="Email" showLabel={false} name="user_email">
                      <Input className="pt-large" />
                    </Field>
                  </Box>
                  <Box px={1}>
                    <Field className="no-margin" placeholder="Phone" showLabel={false} name="phone">
                      <Input className="pt-large" />
                    </Field>
                  </Box>
                </Flex>

                <Flex gutter={1} mb={2}>
                  <Box px={1}>
                    <Field className="no-margin" placeholder="Job Title" showLabel={false} name="job_title">
                      <Input className="pt-large" />
                    </Field>
                  </Box>
                  <Box px={1}>
                    <Field className="no-margin" placeholder="Company" showLabel={false} name="company_name">
                      <Input className="pt-large" />
                    </Field>
                  </Box>
                </Flex>

                <Box mb={2}>
                  <Field
                    className="no-margin"
                    placeholder="Region"
                    showLabel={false}
                    name="Lead_Region__c"
                    onChange={this.handleLeadRegionChange}
                  >
                    <Select className="pt-large" menuWidth={300} />
                  </Field>
                </Box>

                {showRecaptcha && (
                  <Box mb={2}>
                    <Field class="no-margin" showLabel={false} name="recaptcha" transferProps={false}>
                      <Recaptcha
                        sitekey={$auth.openConfig.recaptchaSiteKey}
                        callback={this.recaptchaCallback}
                        expiredCallback={this.recaptchaExpire}
                      />
                    </Field>
                  </Box>
                )}

                {form.getValue('Lead_Region__c') === 'European Union' && (
                  <Fragment>
                    <Field name="accept_terms" className="no-margin">
                      {({ field, onChange }) => (
                        <Checkbox
                          field={field}
                          form={form}
                          onChange={onChange}
                          label={
                            <Fragment>
                              <p style={{ color: '#ffffff', fontSize: 14, lineHeight: '18px' }}>
                                I agree to the Kentik <a href="/privacy-policy">Privacy Policy</a> <br /> and{' '}
                                <a href="/terms-of-use">Terms of Use</a>.
                              </p>
                            </Fragment>
                          }
                        />
                      )}
                    </Field>
                    <Field name="email_optin" className="no-margin">
                      {({ field, onChange }) => (
                        <Checkbox
                          field={field}
                          form={form}
                          onChange={onChange}
                          label={
                            <p style={{ color: '#ffffff', fontSize: 14, lineHeight: '18px' }}>
                              I would like to receive communications from Kentik.
                            </p>
                          }
                        />
                      )}
                    </Field>
                  </Fragment>
                )}

                {form.getValue('Lead_Region__c') !== 'European Union' && (
                  <p
                    className="pt-text-center"
                    style={{
                      color: 'rgb(206, 206, 206)',
                      lineHeight: '18px',
                      fontSize: 14
                    }}
                  >
                    By completing this form, you agree to <br /> our{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.kentik.com/privacy-policy/">
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <TermsOfService
                      isOpen={termsOpen}
                      onClose={() => this.setState({ termsOpen: false })}
                      onOpen={() => this.setState({ termsOpen: true })}
                    />
                  </p>
                )}

                <Button
                  className="pt-large pt-fill pt-intent-kentik"
                  text="START MY FREE TRIAL"
                  disabled={!$auth.openConfig}
                  loading={$signup.reqInProgress}
                  onClick={() => form.submit(this.handleSubmit)}
                  style={{ marginTop: 8, fontWeight: 500 }}
                />
              </Fragment>
            )}
            {reqComplete && (
              <Flex flexColumn align="center" className="pt-callout" p={2} mt={2}>
                <Icon iconName="pt-icon-tick-circle" style={{ color: 'white', fontSize: 22, marginBottom: 8 }} />
                <h4 style={{ color: 'white' }}>Success!</h4>
                <Box py={1} style={{ color: 'rgba(255, 255, 255, 0.7)' }} className="pt-text-center">
                  A confirmation email has been sent to the address provided. Please follow the instructions provided in
                  the email within approx. 24 hours to confirm your email address and complete account setup.
                </Box>
                <Box mt={0.5}>
                  <a className="pt-strong" href="/">
                    Return to Kentik
                  </a>
                </Box>
              </Flex>
            )}
          </Box>
        </Flex>
        <Box className="trial-signup-footer">
          <Box className="inner">
            <SocialButtons />
            <div>Copyright 2019 Kentik. All rights reserved.</div>
            <Flex className="trial-signup-footer-links" justify="space-around">
              <a target="_blank" rel="noopener noreferrer" href="https://kentik.com/privacy-policy">
                Privacy Policy
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://kentik.com/terms-of-use">
                Terms of Use
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://kentik.com/sitemap">
                Site Map
              </a>
            </Flex>
          </Box>
        </Box>
        <CookieNotice />
      </Flex>
    );
  }
}

export default Signup;
