import React, { Component } from 'react';
import { action, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import { Fade } from 'components/animations';
import { Box, Flex } from 'components/flexbox';
import DataViewWrapper from 'dataviews/DataViewWrapper';
import DataViewTools from 'dataviews/DataViewTools';
import { AppliedFiltersButton, ChangeViewTypeMenu, DashboardItemControls } from 'dataviews/tools';

import DashboardItemFooter from './DashboardItemFooter';

@inject('$app', '$auth', '$dashboard', '$dashboards')
@observer
class DashboardItem extends Component {
  static defaultProps = {
    isParentPanel: false
  };

  viewTypeDisposer;

  componentDidMount() {
    const { item } = this.props;

    this.selectedModelsDisposer = reaction(
      () => item.selectedModels.length,
      () => {
        this.onSelectedModelsChange();
      }
    );
  }

  componentWillUnmount() {
    if (this.viewTypeDisposer) {
      this.viewTypeDisposer();
    }

    if (this.selectedModelsDisposer) {
      this.selectedModelsDisposer();
    }
  }

  get showFooter() {
    const { item, $dashboard, isParentPanel, collapsed } = this.props;
    const { dataview } = item;
    const { isEditing } = $dashboard;
    const isNavigationEnabled = item.get('dashboard_navigation');
    const hasDashboardDestination = isNavigationEnabled && item.get('destination_dashboard');

    return (
      dataview.isFlowVisualization &&
      !collapsed &&
      !isParentPanel &&
      !isEditing &&
      (item.selectedModels.length > 0 || hasDashboardDestination)
    );
  }

  onSelectedModelsChange() {
    setTimeout(() => this.props.item.reflow(), 150);
  }

  @action
  handleEdit = () => {
    const { $dashboard, item } = this.props;

    $dashboard.isEditingDashboardItem = true;
    item.set({ editActiveTab: 'query' });
    item.openDashboardItemConfigurationDialog();
  };

  @action
  handleNestedDashboardEdit = () => {
    const { $dashboard, item } = this.props;

    $dashboard.isEditingDashboardItem = true;
    item.set({ editActiveTab: 'navigate' });
    item.openDashboardItemConfigurationDialog();
  };

  @action
  handleClone = cloneType => {
    const { $dashboard, item } = this.props;
    if (cloneType === 'new') {
      item.select();
      $dashboard.setCloningDashboardItem(true);
    } else {
      $dashboard.addDashboardItem(item.get('panel_type'), item.toJS(), cloneType === 'this', item);
    }
  };

  handleViewTypeChange = () => {
    const { $dashboard, isParentPanel, item } = this.props;

    if (isParentPanel) {
      this.viewTypeDisposer = reaction(
        () => item.dataview.loading,
        () => {
          if (!item.dataview.loading) {
            item.dataview.setSelectedModels(isParentPanel ? item.selectedModels : $dashboard.selectedModels);

            this.viewTypeDisposer();
            this.viewTypeDisposer = null;
          }
        },
        {
          delay: 100
        }
      );
    }
  };

  onNestedDashboardClick = () => {
    const { dashboard, item, $dashboards, $dashboard } = this.props;
    const parametric = item.get('dashboard_navigation_options.parametric');

    $dashboard.setCompletedInitialParametricSubmit(parametric !== 'prompt');
    $dashboards.navigateToNestedDashboard(dashboard, item);
  };

  @action
  onClearSelectionsClick = () => {
    const { item, $dashboard } = this.props;
    $dashboard.clearSelectedModels(item);
  };

  render() {
    const {
      $app,
      $auth,
      $dashboard,
      $dashboards,
      dashboard,
      item,
      isParentPanel,
      navigationHistory,
      onToggleCollapsed,
      collapsed
    } = this.props;
    const { dataview } = item;
    const { viewType } = dataview;
    const panelStyle = { height: '100%', width: '100%' };
    const { isEditing, selectModel } = $dashboard;
    const isNavigationEnabled = item.get('dashboard_navigation');
    const hasDashboardDestination = isNavigationEnabled && item.get('destination_dashboard');

    if ($app.isExport) {
      panelStyle.width = 'auto';
      panelStyle.height = viewType === 'table' || viewType === 'generator' ? 'auto' : 'calc(8.5in - 100px)';
    }

    if (
      (dashboard.isIncompleteParametric ||
        ($dashboard.dashboard.isParametric && !$dashboard.completedInitialParametricSubmit)) &&
      !isParentPanel
    ) {
      return (
        <Fade timeout={250} in appear>
          {({ styles }) => (
            <Box className="dataview-wrapper" style={styles}>
              <Box p={2}>
                <h6 className="header">{item.get('panel_title')}</h6>
              </Box>
              <Flex px={2} className="pt-text-muted" align="center">
                <Icon iconName="info-sign" style={{ marginRight: 6 }} />{' '}
                <span>
                  {dashboard.parametricField.question} from the sidebar and press <strong>Apply</strong>.
                </span>
              </Flex>
            </Box>
          )}
        </Fade>
      );
    }

    const dataViewTools = (
      <DataViewTools dataview={dataview}>
        {({ Wrapper, ...rest }) => (
          <Wrapper>
            {isEditing &&
              !isParentPanel && (
                <DashboardItemControls item={item} onEdit={this.handleEdit} onClone={this.handleClone} {...rest} />
              )}
            {(isEditing || $app.isSubtenant) &&
              dataview.appliedFilters && <AppliedFiltersButton item={item} {...rest} />}

            {onToggleCollapsed &&
              !isEditing && (
                <Button className="pt-small" onClick={onToggleCollapsed} text={collapsed ? 'Expand' : 'Collapse'} />
              )}

            {viewType !== 'alertScoreboard' &&
              viewType !== 'rawFlow' &&
              (!$app.isSubtenant || !$auth.hasPermission('subtenancy.disableVizTypeChanges')) &&
              !isEditing && (
                <ChangeViewTypeMenu {...rest} onViewTypeChange={this.handleViewTypeChange} showButtonText={false} />
              )}
          </Wrapper>
        )}
      </DataViewTools>
    );

    return (
      <Flex
        flexColumn
        style={panelStyle}
        className={classNames('dashboard-item', { 'has-selection': item.selectedModels.length > 0 })}
      >
        <DataViewWrapper
          dataview={dataview}
          sourceLink={{ type: 'dashboard', model: item }}
          hasDashboardDestination={hasDashboardDestination}
          onModelSelect={selectModel}
          viewProps={{
            isDashboardView: true,
            showNativeLegend: true,
            showLegendOptions: false,
            hasFooter: this.showFooter
          }}
          headerProps={{
            showCategory: isParentPanel,
            category: isParentPanel ? 'Parent Panel' : undefined,
            showTitleLink:
              !$app.isSubtenant && viewType !== 'alertScoreboard' && viewType !== 'rawFlow' && !$dashboard.isEditing,
            tools: dataViewTools,
            dashboard,
            navigationHistory
          }}
        >
          {({ component, header, className }) => (
            <Flex className={className} flexAuto flexColumn style={{ overflow: 'hidden' }}>
              <Flex flexColumn p={2}>
                {header}
              </Flex>
              <Flex flexAuto className="dashboard-item-view-component">
                {component}
              </Flex>
            </Flex>
          )}
        </DataViewWrapper>
        {this.showFooter && (
          <DashboardItemFooter
            dashboard={$dashboards.getDashboard(item.get('destination_dashboard'))}
            item={item}
            onClear={this.onClearSelectionsClick}
            onClick={this.onNestedDashboardClick}
            selectedModels={item.selectedModels}
          />
        )}
      </Flex>
    );
  }
}

export default DashboardItem;
