import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Button, Popover, Position } from '@blueprintjs/core';
import { Flex, Box } from 'components/flexbox';
import { Field, Input } from 'components/forms';
import $dictionary from 'stores/$dictionary';
import { getUnitsLegacy } from 'models/query/ExplorerQueryModel';
import { stopBubbling } from 'util/utils';
import SelectedValuesPanel from '../select/SelectedValuesPanel';

const aggregateTypeLabelOverrides = {
  p95th_in_bits_per_sec: (
    <span>
      95th Percentile
      <br />
      Ingress
    </span>
  ),
  p95th_out_bits_per_sec: (
    <span>
      95th Percentile
      <br />
      Egress
    </span>
  ),
  p95th_in_pkts_per_sec: (
    <span>
      95th Percentile
      <br />
      Ingress
    </span>
  ),
  p95th_out_pkts_per_sec: (
    <span>
      95th Percentile
      <br />
      Egress
    </span>
  )
};

const aggregateUnitLabelOverrides = {
  repeated_retransmits: (
    <span>
      Repeated
      <br />
      Retransmits/s
    </span>
  ),
  perc_repeated_retransmits: (
    <span>
      % Repeated
      <br />
      Retransmits
    </span>
  ),
  client_latency: (
    <span>
      Client
      <br />
      Latency (ms)
    </span>
  ),
  server_latency: (
    <span>
      Server
      <br />
      Latency (ms)
    </span>
  ),
  appl_latency: (
    <span>
      Application
      <br />
      Latency (ms)
    </span>
  ),
  unique_src_route_prefix: (
    <span>
      Unique Src
      <br />
      Prefixes
    </span>
  ),
  unique_dst_route_prefix: (
    <span>
      Unique Dst
      <br />
      Prefixes
    </span>
  ),
  unique_src_port: (
    <span>
      Unique Src
      <br />
      Ports
    </span>
  ),
  unique_dst_port: (
    <span>
      Unique Dst
      <br />
      Ports
    </span>
  ),
  unique_src_as: (
    <span>
      Unique Src
      <br />
      ASNs
    </span>
  ),
  unique_dst_as: (
    <span>
      Unique Dst
      <br />
      ASNs
    </span>
  ),
  unique_dst_nexthop_asn: (
    <span>
      Unique Dst
      <br />
      Nexthop ASNs
    </span>
  ),
  unique_src_geo: (
    <span>
      Unique Src
      <br />
      Countries
    </span>
  ),
  unique_dst_geo: (
    <span>
      Unique Dst
      <br />
      Countries
    </span>
  ),
  unique_src_geo_region: (
    <span>
      Unique Src
      <br />
      Regions
    </span>
  ),
  unique_dst_geo_region: (
    <span>
      Unique Dst
      <br />
      Regions
    </span>
  ),
  unique_src_geo_city: (
    <span>
      Unique Src
      <br />
      Cities
    </span>
  ),
  unique_dst_geo_city: (
    <span>
      Unique Dst
      <br />
      Cities
    </span>
  ),
  max_sample_rate: (
    <span>
      Max Sampling
      <br />
      Rate
    </span>
  ),
  avg_sample_rate: (
    <span>
      Avg Sampling
      <br />
      Rate
    </span>
  )
};

export const getAggregateTypeLabel = ({ aggregate, useLineBreaks = true }) => {
  const { aggregateLabels } = $dictionary.dictionary;
  const { value, label } = aggregate;

  let result;
  if (useLineBreaks) {
    result = aggregateTypeLabelOverrides[value];
  }

  return result || aggregateLabels[value.startsWith('sum_logsum') ? 'sum_logsum' : value] || label;
};

export const getAggregateUnitLabel = ({ aggregate, prefix = {}, useLineBreaks = true, wrap = true }) => {
  const { units } = $dictionary.dictionary;

  let unit = aggregate.parentUnit || aggregate.unit;
  if (unit === 'kt_intell_order') {
    unit = aggregate.value.replace('sum_logsum_', '');
  }
  const unitPrefix = prefix[unit] || '';

  let unitLabel;
  if (useLineBreaks) {
    unitLabel = aggregateUnitLabelOverrides[unit];
  }
  unitLabel = unitLabel || units[unit] || 'Unknown';
  if (unitPrefix) {
    unitLabel = unitLabel.toLowerCase();
  }

  if (wrap) {
    return (
      <span>
        {unitPrefix}
        {unitLabel}
      </span>
    );
  }

  return `${unitPrefix} ${unitLabel}`;
};

export const metricLabelRenderer = option => (
  <span>
    {getAggregateTypeLabel({ aggregate: option, useLineBreaks: false })}{' '}
    {option.value && getAggregateUnitLabel({ aggregate: option, useLineBreaks: false })}
  </span>
);

@observer
export class MetricRenderer extends Component {
  state = {
    thresholdPopoverOpen: false
  };

  handlePopoverClose = e => {
    if (e) {
      stopBubbling(e);
    }
    this.setState({ thresholdPopoverOpen: false });
  };

  render() {
    const { option, onClick, readOnly, onUnselect, rootPrefix, field } = this.props;
    if (onUnselect && option) {
      const thresholdFieldName = rootPrefix
        ? `${rootPrefix}.aggregateThresholds.${option.value}`
        : `aggregateThresholds.${option.value}`;

      const thresholdFieldState = field && field.form.getField(thresholdFieldName);

      const thresholdValid = !!(thresholdFieldState && thresholdFieldState.valid);
      const thresholdClassName = classNames('pt-small pt-minimal', {
        'pt-intent-warning': !!(field && thresholdValid && field.form.getValue(thresholdFieldName)),
        'pt-intent-danger': !thresholdValid
      });

      const dimensionValueClassName = classNames(
        'pt-tag dimension-tag dimension-tag-single solid metric',
        option.className,
        {
          'pt-tag-removable': !readOnly && onUnselect
        }
      );

      const style = readOnly ? {} : { cursor: 'pointer' };
      return (
        <Flex>
          <Flex align="stretch" className={dimensionValueClassName} onClick={onClick} style={style}>
            <span className="dimension-tag-group">
              {getAggregateTypeLabel({ aggregate: option, useLineBreaks: false })}
            </span>
            <Flex flexColumn flexAuto justify="center">
              {getAggregateUnitLabel({ aggregate: option, useLineBreaks: false })}
            </Flex>
            {!readOnly &&
              onUnselect && (
                <button
                  className="pt-tag-remove"
                  style={{ alignSelf: 'flex-start' }}
                  onClick={e => {
                    stopBubbling(e);
                    onUnselect(field, option.value);
                  }}
                />
              )}
          </Flex>

          <Popover
            isOpen={this.state.thresholdPopoverOpen}
            onInteraction={nextOpenState => this.setState({ thresholdPopoverOpen: nextOpenState })}
            position={Position.TOP}
            tetherOptions={{
              offset: '-5px 0',
              constraints: [{ attachment: 'together', pin: true, to: 'window' }]
            }}
            content={
              <Box p={2}>
                <Field
                  name={rootPrefix ? `${rootPrefix}.${thresholdFieldName}` : thresholdFieldName}
                  autoFocus
                  className="no-margin"
                  fieldStyle={{ width: 300 }}
                >
                  <Input
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        this.handlePopoverClose(e);
                      }
                    }}
                    rightElement={
                      <button
                        className="pt-tag-remove"
                        style={{ paddingTop: 7 }}
                        onClick={e => {
                          stopBubbling(e);
                          field.form.setValue(
                            rootPrefix ? `${rootPrefix}.${thresholdFieldName}` : thresholdFieldName,
                            0
                          );
                          this.setState({ thresholdPopoverOpen: false });
                        }}
                      />
                    }
                  />
                </Field>
              </Box>
            }
          >
            <Button
              className={thresholdClassName}
              iconName="exclude-row"
              onClick={e => {
                stopBubbling(e);
                this.setState({ thresholdPopoverOpen: true });
              }}
            />
          </Popover>
        </Flex>
      );
    }

    const dimensionButtonClassName = classNames('pt-small dimension-tag solid', option ? option.className : null);

    return (
      <div className="select-wrapper pt-button-group pt-small">
        <Button className={dimensionButtonClassName}>
          {option && <span className="dimension-tag-group">{option.group} </span>}
          {option ? <strong>{option.label}</strong> : 'Select a metric...'}
          <span className="pt-icon-standard pt-icon-chevron-down pt-align-right" />
        </Button>
      </div>
    );
  }
}

export const MetricsRenderer = props => {
  const { values, field, onUnselect, readOnly } = props;
  const valueRenderer = valueProps => <MetricRenderer {...valueProps} field={field} onUnselect={onUnselect} />;

  const className = classNames('dimensions', {
    'read-only': readOnly,
    'pt-text-muted': values.length === 0 && !field.hasError
  });

  const unitsLegacy = getUnitsLegacy(values);

  return (
    <div style={{ width: '100%' }}>
      {!unitsLegacy && (
        <div className={className} style={{ marginTop: 8 }}>
          {values.length === 0 && <div className="empty-text">No metrics selected</div>}
          {values.length > 0 && (
            <SelectedValuesPanel
              {...props}
              reorderable={!readOnly && values.length > 1}
              valueRenderer={valueRenderer}
              showCard={false}
              allowSelection={false}
            />
          )}
        </div>
      )}
      <Button iconName="edit" className="pt-minimal pt-intent-primary pt-small" style={{ marginTop: 8 }}>
        {unitsLegacy ? 'Customize Metrics' : 'Edit Metrics'}
      </Button>
    </div>
  );
};
