import Collection from 'models/Collection';

import SilentMode from './SilentMode';

class SilentModeCollection extends Collection {
  get url() {
    return '/api/portal/alerts/silentMode';
  }

  get model() {
    return SilentMode;
  }

  get defaultSortState() {
    return {
      field: 'expire',
      direction: 'asc'
    };
  }

  getFilterValues(model) {
    return model.get('alert_key').map(key => model.lookups.get(key.dimension) || key.value);
  }
}

export default SilentModeCollection;
