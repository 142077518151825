import React from 'react';
import { observer } from 'mobx-react';

import { Box } from 'components/flexbox';

const Fieldset = props => {
  const { children, title, description } = props;
  return (
    <Box className="Fieldset" mb={2} {...props}>
      <Box mb={1}>
        <h5>{title}</h5>
        {description && (
          <p className="pt-text-muted">
            <small>{description}</small>
          </p>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default observer(Fieldset);
