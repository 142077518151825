import React from 'react';

import { getAllFormsPayload } from 'components/AbstractErrorBoundary';
import { Page, PageBody } from 'components/page';
import $app from 'stores/$app';

export default function asyncComponent(getComponent) {
  return class AsyncComponent extends React.Component {
    static Component = null;

    state = {
      Component: AsyncComponent.Component,
      error: null
    };

    componentWillMount() {
      if (!this.state.Component) {
        getComponent()
          .then(({ default: Component }) => {
            AsyncComponent.Component = Component;
            this.setState({ Component });
          })
          .catch(error => {
            const { platform, userAgent } = window.navigator;
            const { pathname } = window.location;
            const { clientWidth, clientHeight } = document.querySelector('body');

            console.error('Error Boundary AsyncComponent', error);

            $app.submitErrorReport({
              clientHeight,
              clientWidth,
              info: {
                componentName: 'AsyncComponent',
                allForms: getAllFormsPayload()
              },
              pathname,
              platform,
              userAgent,
              error: error.toString(),
              errorInfo: 'Bundle load error'
            });

            this.setState({ error });
          });
      }
    }

    render() {
      const { Component, error } = this.state;

      if (Component) {
        return <Component {...this.props} />;
      }

      if (error) {
        return (
          <Page constrainWidth={600} title="Not Found">
            <PageBody scrollable={false} bodyStyle={{ paddingTop: 16 }}>
              <h2>424</h2>
              <p className="pt-text-muted pt-monospace-text">
                A critical resource failed to load. Please try refreshing the page to resolve. An attempt was made to
                notify Kentik of this issue.
              </p>
            </PageBody>
          </Page>
        );
      }

      return null;
    }
  };
}
