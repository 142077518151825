import React from 'react';
import { observer } from 'mobx-react';

import AbstractErrorBoundary, { ErrorMessage } from 'components/AbstractErrorBoundary';
import $app from 'stores/$app';

export default function(componentName) {
  const boundaryClass = class ErrorBoundary extends AbstractErrorBoundary(componentName) {
    static defaultProps = {
      fullScreen: false
    };

    render() {
      const { error, hasOutdatedBundle } = this.state;
      const { children, fullScreen } = this.props;
      const { errorReportSuccess } = $app;

      if (error) {
        return (
          <ErrorMessage
            fullScreen={fullScreen}
            showDescription={errorReportSuccess}
            hasOutdatedBundle={hasOutdatedBundle}
          />
        );
      }

      // when there's not an error, render children untouched
      return children;
    }
  };

  return observer(boundaryClass);
}
