import Collection from 'models/Collection';
import MitigationMethod from 'models/alerting/MitigationMethod';
import api from 'util/api';
import { showErrorToast, showSuccessToast } from 'components/Toast';

class MitigationMethodCollection extends Collection {
  get url() {
    return '/api/portal/alerts/mitigation-methods';
  }

  get model() {
    return MitigationMethod;
  }

  cloneMethod(mitigationMethod) {
    return api
      .post(`${this.url}/${mitigationMethod.id}/copy`)
      .then(
        () => showSuccessToast(`Mitigation Method '${mitigationMethod.get('method_name')}' was successfully copied`),
        () => showErrorToast(`Mitigation Method '${mitigationMethod.get('method_name')}' failed to copy`)
      )
      .then(() => this.fetch());
  }
}

export default MitigationMethodCollection;
