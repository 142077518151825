const options = {
  validationOnInit: false,
  showPristineErrors: true,
  groups: {
    general: ['device_name', 'device_description', 'device_type', 'device_flow_type', 'sending_ips'],
    planSite: ['plan.id', 'site.id'],
    ipSnmp: [
      'device_snmp_v3_conf_enabled',
      'minimize_snmp',
      'device_snmp_v3_conf.UserName',
      'device_snmp_v3_conf.AuthenticationProtocol',
      'device_snmp_v3_conf.AuthenticationPassphrase',
      'device_snmp_v3_conf.PrivacyProtocol',
      'device_snmp_v3_conf.PrivacyPassphrase'
    ],
    bgp: [
      'device_bgp_type',
      'device_bgp_neighbor_ip',
      'device_bgp_neighbor_ip6',
      'device_bgp_neighbor_asn',
      'device_bgp_password',
      'device_bgp_flowspec',
      'use_bgp_device_id'
    ]
  }
};

const fields = {
  device_name: {
    label: 'Name',
    placeholder: 'eg: my_router1',
    rules: 'required|max:60',
    helpText: 'This name will be used as a display name inside of Kentik',
    messages: {
      required: 'The Device Name field is required'
    }
  },

  device_description: {
    label: 'Device Description',
    rules: 'max:100',
    placeholder: 'Short device description here'
  },

  device_subtype: {
    label: 'Type',
    rules: 'required',
    helpText: 'Specify the category of the device',
    defaultValue: 'router'
  },

  cdn_attr: {
    label: 'Contribute to CDN Attribution',
    helpText: "If this is a DNS server, you can contribute its queries to Kentik's CDN attribution database",
    transform: {
      in: value => value === 'Y',
      out: value => (value && value === true ? 'Y' : 'N')
    }
  },

  device_flow_type: {
    label: 'Flow Type',
    defaultValue: 'Auto'
  },

  labels: {
    isComplexArray: true
  },

  'labels[].id': {},
  'labels[].name': {},
  'labels[].color': {},

  'plan.id': {
    label: 'Billing Plan',
    placeholder: 'Select a Plan',
    rules: 'required',

    messages: {
      required: 'The "Plan" field is required'
    }
  },

  'site.id': {
    label: 'Site',
    placeholder: 'Select a site',
    transform: {
      out: value => value && Number(value).toString()
    }
  },

  device_snmp_v3_conf_enabled: {
    label: 'SNMP v3 Auth'
  },

  'device_snmp_v3_conf.UserName': {
    label: 'SNMP v3 User Name'
  },

  'device_snmp_v3_conf.AuthenticationProtocol': {
    label: 'SNMP v3 Auth Type',
    defaultValue: 'NoAuth',
    options: [{ value: 'NoAuth', label: 'None' }, { value: 'MD5', label: 'MD5' }, { value: 'SHA', label: 'SHA' }]
  },

  'device_snmp_v3_conf.AuthenticationPassphrase': {
    label: 'SNMP v3 Auth Passphrase'
  },

  'device_snmp_v3_conf.PrivacyProtocol': {
    label: 'SNMP v3 Privacy Type',
    defaultValue: 'NoPriv',
    options: [{ value: 'NoPriv', label: 'None' }, { value: 'DES', label: 'DES' }, { value: 'AES', label: 'AES' }]
  },

  'device_snmp_v3_conf.PrivacyPassphrase': {
    label: 'SNMP v3 Privacy Passphrase'
  },

  minimize_snmp: {
    label: 'SNMP Polling',
    rules: 'boolean',
    defaultValue: false,
    options: [{ value: false, label: 'Standard' }, { value: true, label: 'Minimum' }]
  },

  'sending_ips[]': {
    label: 'Sending IPs',
    messages: {
      required: 'You must add at least 1 Sending IP address'
    },
    children: {
      rules: 'ip',
      messages: {
        required: 'You must enter a Sending IP address',
        ip: 'Sending IP must be a valid IP address'
      }
    },
    validateDebounceDurationMs: 500,
    transform: {
      out: value => (value && value.trim ? value.trim() : value)
    }
  },

  device_snmp_ip: {
    label: 'Device SNMP IP'
  },

  device_snmp_community: {
    label: 'SNMP Community'
  },

  device_sample_rate: {
    label: 'Sample Rate',
    rules: 'required|numeric|between:0,4000000',
    type: 'number',
    messages: {
      required: 'You must define a Sample Rate',
      'between:0,4000000': 'Sample Rate must be between 0 and 4,000,000,',
      numeric: 'Sample Rate must be a number'
    }
  },

  device_bgp_type: {
    label: 'BGP Type',
    rules: 'required',
    defaultValue: 'none',
    options: [
      { value: 'none', label: 'No peer, use generic IP/ASN mapping' },
      { value: 'device', label: 'Peer with device' },
      { value: 'other_device', label: 'Use table from another peered device' }
    ]
  },

  device_bgp_neighbor_ip: {
    label: 'IPv4 Peering Address',
    placeholder: 'Enter IP Address...',
    messages: {
      required: 'Peering IPv4 is required when IPv6 is not present.'
    }
  },

  device_bgp_neighbor_ip6: {
    label: 'IPv6 Peering Address',
    placeholder: 'Enter IP Address...',
    messages: {
      required: 'Peering IPv6 is required when IPv4 is not present.'
    }
  },

  device_bgp_neighbor_asn: {
    label: 'ASN',
    rules: 'integer'
  },

  device_bgp_password: {
    label: 'BGP md5 password',
    rules: 'noSpaceOrSemicolon'
  },

  device_bgp_flowspec: {
    label: 'BGP Flowspec Compatible',
    defaultValue: false
  },

  use_bgp_device_id: {
    label: 'Master BGP device',
    placeholder: 'Select a Peered Device...',
    rules: [{ required_if: ['device_bgp_type', 'other_device'] }]
  }
};

const tetherOptions = {
  offset: '-3px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

export { fields, options, tetherOptions };
