export function getCookie(name) {
  const cookies = document.cookie.split(/;\s*/);

  for (const cookie of cookies) {
    const [cName, cVal] = cookie.split('=', 2);

    if (cName === name) {
      return decodeURIComponent(cVal);
    }
  }

  return null;
}

export function setCookie(name, val, maxAge = 0) {
  const domain = window.location.hostname.replace(/.+\.(?=kentik)/, '');
  let cookie = `${name}=${encodeURIComponent(val)}; path=/; domain=${domain}`;

  if (maxAge > 0) {
    cookie += `; max-age=${maxAge}`;
  }

  document.cookie = cookie;
}
