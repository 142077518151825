import React from 'react';
import { observer } from 'mobx-react';
import BaselineByTimeRow from './BaselineByTimeRow';

const BaselineByTimeTable = props => {
  const { long_set, aggr_title, aggr_field } = props.debugDataByTime;

  return (
    <table className="pt-table alerts-table pt-elevation-1">
      <thead>
        <tr>
          <th>Key</th>
          <th>Position</th>
          {aggr_field && <th>{aggr_title} (Hourly Aggr)</th>}
          <th>Value p25th</th>
          <th>Value p50th</th>
          <th>Value p95th</th>
        </tr>
      </thead>
      <tbody>
        {long_set.map((values, index) => (
          <BaselineByTimeRow key={values.id} values={values} aggr_field={aggr_field} position={index + 1} {...props} />
        ))}
      </tbody>
    </table>
  );
};

export default observer(BaselineByTimeTable);
