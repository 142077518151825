import React, { Component } from 'react';
import classNames from 'classnames';

const SPACING_MULTIPLE = 8;

function getSpacingValue(...args) {
  let result;

  args.some(arg => {
    if (arg !== undefined) {
      result = arg * SPACING_MULTIPLE;
    }

    return arg !== undefined;
  });

  return result;
}

function getMargin(props) {
  const { m, mx, my, mt, mb, mr, ml } = props;

  const gutter = props.gutter !== undefined ? props.gutter * -1 : undefined;

  const marginTop = getSpacingValue(mt, my, m);
  const marginRight = getSpacingValue(mr, mx, gutter, m);
  const marginBottom = getSpacingValue(mb, my, m);
  const marginLeft = getSpacingValue(ml, mx, gutter, m);

  return { marginTop, marginRight, marginBottom, marginLeft };
}

function getPadding(props) {
  const { p, px, py, pt, pb, pr, pl } = props;

  const paddingTop = getSpacingValue(pt, py, p);
  const paddingRight = getSpacingValue(pr, px, p);
  const paddingBottom = getSpacingValue(pb, py, p);
  const paddingLeft = getSpacingValue(pl, px, p);

  return { paddingTop, paddingRight, paddingBottom, paddingLeft };
}

// forces a Box/Flex to a specific width.
function getWidthHeight(props) {
  const { w, h, maxWidth } = props;
  const width = w;
  const height = h;
  const minWidth = w;
  const maxWidthValue = maxWidth || w;

  const minHeight = h;
  const maxHeight = h;

  return { width, height, maxWidth: maxWidthValue, minWidth, maxHeight, minHeight };
}

function getStyle(props) {
  return Object.assign(
    {
      flex: props.flexAuto ? 1 : props.flex
    },
    getMargin(props),
    getPadding(props),
    getWidthHeight(props),
    props.style
  );
}

function hasTruthyProp(value) {
  return value !== undefined && value !== false;
}

function getClassName(props) {
  return classNames(props.className, {
    [`align-${props.align}`]: hasTruthyProp(props.align),
    [`justify-${props.justify}`]: hasTruthyProp(props.justify),
    wrap: hasTruthyProp(props.wrap),
    column: hasTruthyProp(props.flexColumn),
    [`xs-${props.xs}`]: hasTruthyProp(props.xs),
    [`sm-${props.sm}`]: hasTruthyProp(props.sm),
    [`md-${props.md}`]: hasTruthyProp(props.md),
    [`lg-${props.lg}`]: hasTruthyProp(props.lg),
    [`xl-${props.xl}`]: hasTruthyProp(props.xl),
    [`col-${props.col}`]: hasTruthyProp(props.col)
  });
}

export class Box extends Component {
  render() {
    const {
      boxRef,
      children,
      id,
      keyboardOnClick,
      onClick,
      onDoubleClick,
      onMouseOver,
      onMouseOut,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      tabIndex,
      title
    } = this.props;

    let onKeyPress;
    if (keyboardOnClick && onClick) {
      onKeyPress = e => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      };
    }

    return (
      <div
        className={getClassName(this.props)}
        style={getStyle(this.props)}
        title={title}
        id={id}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onKeyPress={onKeyPress}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={boxRef}
        tabIndex={tabIndex}
      >
        {children}
      </div>
    );
  }
}

export const Flex = props => <Box {...props} className={classNames('flex', props.className)} />;
