import Collection from '../Collection';
import CloudExportTask from './CloudExportTask';

export default class CloudExportTaskCollection extends Collection {
  get url() {
    return '/api/portal/cloudExport';
  }

  get model() {
    return CloudExportTask;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get presetGroups() {
    return [
      {
        name: 'cloud_provider',
        label: 'Provider',
        iconName: 'cloud'
      },
      {
        name: 'plan_id',
        label: 'Plan',
        iconName: 'card_membership',
        materialIcon: true
      },
      {
        name: 'enabled',
        label: 'Enabled',
        iconName: 'tick'
      }
    ];
  }

  getFilterValues(model) {
    return [
      'name',
      'properties.description',
      'cloud_provider',
      'properties.gce_project',
      'properties.gce_subscription',
      'properties.aws_region',
      'properties.aws_bucket',
      'properties.aws_iam_role_arn',
      'properties.azure_resource_group',
      'properties.azure_storage_account'
    ].map(name => model[name] || model.get(name));
  }
}
