import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Spinner, NonIdealState, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';

import GroupRow from './GroupRow';
import Row from './Row';
import Header from './Header';
import TotalRow from './TotalRow';

const groupSummaryFn = props => {
  const { collection, groupKey, isExpanded } = props;
  const group = collection.groupedData[groupKey];
  const collapseCls = classNames('group-details', { collapsed: !isExpanded });

  return [
    <GroupRow {...props} key={groupKey} />,
    ...group.map(model => (
      <Row
        {...props}
        key={model.id}
        model={model}
        className={collapseCls}
        selected={model.isSelected}
        lastUpdated={model.lastUpdated}
      />
    ))
  ];
};

const SpinnerBody = () => (
  <Flex className="tr loading">
    <Box className="td" style={{ textAlign: 'center' }} p={4} flexAuto>
      <Spinner intent={Intent.WARNING} />
    </Box>
  </Flex>
);

const EmptyBody = observer(props => {
  const { collection, notFetchedText } = props;
  let { emptyState } = props;

  if (!collection.hasFetched && notFetchedText) {
    emptyState = <NonIdealState title={notFetchedText} visual="help" />;
  } else if (collection.hasFilter && collection._original && collection._original.length > 0) {
    emptyState = <NonIdealState title="No matches found" visual="inbox" />;
  }

  return (
    <Flex className="tr empty">
      <Box className="td" style={{ textAlign: 'center' }} p={3} flexAuto>
        {emptyState ? React.cloneElement(emptyState) : <NonIdealState title="No Results" visual="inbox" />}
      </Box>
    </Flex>
  );
});

@observer
export default class Table extends Component {
  static defaultProps = {
    clearFiltersOnUnmount: true,
    selectOnRowClick: true,
    showTotalRow: false,
    isCollapsed: false,
    breakpoint: 1024,
    responsive: false,
    hideNonIdealState: false
  };

  state = {
    collapseChanged: false,
    responsiveMode: false
  };

  @observable
  collapsedRows;

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount() {
    this.collapsedRows = observable.map();
  }

  componentWillUnmount() {
    const { collection, clearFiltersOnUnmount } = this.props;
    if (clearFiltersOnUnmount && collection && collection.models.length) {
      collection.clearFilters();
    }
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.width = document.body.offsetWidth;
    const { breakpoint, responsive } = this.props;
    const responsiveMode = responsive && this.width < breakpoint;

    if (responsiveMode !== this.state.responsiveMode) {
      this.setState({ responsiveMode });
    }
  };

  componentDidUpdate(nextProps) {
    const { isCollapsed } = this.props;
    const { collapseChanged } = this.state;
    if (isCollapsed && !collapseChanged) {
      if (nextProps && nextProps.collection && nextProps.collection.groupedData) {
        const groupKeys = Object.keys(nextProps.collection.groupedData);
        this.collapseRows(groupKeys);
      }
    }
  }

  @action
  collapseRows = groupKeys => {
    groupKeys.forEach(groupKey => {
      this.collapsedRows.set(groupKey, true);
    });
  };

  @action
  toggleGroup = rowId => {
    this.setState({ collapseChanged: true });
    if (this.collapsedRows.has(rowId)) {
      this.collapsedRows.delete(rowId);
    } else {
      this.collapsedRows.set(rowId, true);
    }
  };

  isRowSelected(model) {
    const { isRowSelected } = this.props;
    return isRowSelected ? isRowSelected(model) : model.isSelected;
  }

  renderEmpty() {
    const { hideNonIdealState } = this.props;

    return hideNonIdealState ? null : <EmptyBody {...this.props} />;
  }

  renderBody(options = {}) {
    const { style } = options;
    const { collection, groupFn = groupSummaryFn } = this.props;
    const { responsiveMode } = this.state;

    let body;
    if (collection.isRequestActive('fetching')) {
      body = <SpinnerBody />;
    } else if (collection.models.length === 0) {
      body = this.renderEmpty();
    } else if (collection.groupBy) {
      body = Object.keys(collection.groupedData).reduce(
        (rows, groupKey, idx, arr) =>
          rows.concat(
            groupFn({
              ...this.props,
              responsiveMode,
              groupKey,
              isExpanded: !this.collapsedRows.has(groupKey),
              onToggle: () => this.toggleGroup(groupKey),
              last: idx === arr.length - 1
            })
          ),
        []
      );
    } else {
      body = collection.models.map(model => (
        <Row
          {...this.props}
          key={model.id}
          model={model}
          selected={this.isRowSelected(model)}
          responsiveMode={responsiveMode}
          lastUpdated={model.lastUpdated}
        />
      ));
    }

    return (
      <Box className="tbody" style={style}>
        {body}
      </Box>
    );
  }

  renderTotals(options = {}) {
    const { collection, showTotalRow } = this.props;

    if (showTotalRow && !collection.isRequestActive('fetching') && collection.models.length > 0) {
      return <TotalRow {...this.props} {...options} />;
    }

    return null;
  }

  render() {
    const { style, className, selectOnRowClick, onRowClick } = this.props;
    const { responsiveMode } = this.state;

    const tableClassName = classNames('pt-table', className, {
      'pt-interactive': selectOnRowClick || onRowClick,
      'responsive-table': responsiveMode
    });

    return (
      <div style={style} className={tableClassName}>
        <Header {...this.props} responsiveMode={responsiveMode} />
        {this.renderBody()}
        {this.renderTotals()}
      </div>
    );
  }
}
