import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Intent, Position, ProgressBar, Tooltip, Tag } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import { CELL_TYPES, VirtualizedTable } from 'components/table';
import Icon from 'components/Icon';
import { numberWithCommas } from 'util/utils';
import DataViewModel from 'models/DataViewModel';
import QueryModel from 'models/query/QueryModel';
import LabelTag from 'views/Admin/DeviceGroups/LabelTag';
import ViewInExplorerDialog from 'components/ViewInExplorerDialog';

const indicatorTooltips = {
  snmp: {
    client: 'SNMP detected',
    none: 'No SNMP detected'
  },
  flow: {
    client: 'Direct flow detected',
    agent: 'Flow from Kentik Agent detected',
    none: 'No flow detected'
  }
};

const indicator = (active, model, options = {}) => {
  const { snmp = false } = options;
  const tooltips = indicatorTooltips[snmp ? 'snmp' : 'flow'];

  let intent = Intent.SUCCESS;
  let text = tooltips.client;

  if (active) {
    if (!snmp && model.flowSource !== 'client') {
      intent = Intent.PRIMARY;
      text = tooltips[model.flowSource] || tooltips.agent;
    }

    return (
      <Tooltip content={text}>
        <Tag intent={intent} className="pt-minimal">
          <Icon name="tick" style={{ fontSize: 14 }} />
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Tooltip content={tooltips.none}>
      <Box style={{ width: 28, textAlign: 'center' }}>
        <Icon className="pt-text-muted" name="dot" style={{ opacity: 0.3, fontSize: 16 }} />
      </Box>
    </Tooltip>
  );
};

const DeviceOptionIndicator = ({ enabled, label, isLast }) => (
  <Box mb={isLast ? 0 : 0.25}>
    {enabled ? (
      <Tag intent={Intent.SUCCESS} className="pt-minimal" style={{ marginRight: 4 }}>
        <Icon name="tick" style={{ fontSize: 14 }} />
      </Tag>
    ) : (
      <Icon
        className="pt-text-muted"
        name="dot"
        style={{ opacity: 0.3, fontSize: 16, width: 26, marginRight: 4, textAlign: 'center' }}
      />
    )}
    <span>{label}</span>
  </Box>
);
@inject('$app', '$devices', '$plans', '$sites', '$dictionary', '$alertingMitigation')
@observer
class DeviceTable extends Component {
  dataview = new DataViewModel();

  state = {
    isChartOpen: false,
    viewInExplorerDevice: null
  };

  tableColumnMap = {
    FpsPostCap: {
      key: 'fps24h-status',
      name: 'FpsPostCap',
      label: 'Flow',
      title: 'Flow indicator: green if direct from router, blue if from Kentik agent, black when no flow is detected',
      ellipsis: false,
      renderer: ({ value, model }) => indicator(!!value, model),
      width: 50
    },
    snmp: {
      name: 'snmp',
      label: 'SNMP',
      title: 'SNMP indicator: green when SNMP is detected, black when no SNMP is detected',
      ellipsis: false,
      renderer: ({ value, model }) => indicator(value, model, { snmp: true }),
      width: 50
    },
    device_name: {
      name: 'device_name',
      label: 'Name',
      title: 'User-defined name string assigned at device registration',
      renderer: ({ value, model }) => (
        <Fragment>
          {model.isArchived && (
            <div>
              <Tag intent={Intent.WARNING}>ARCHIVED</Tag>
            </div>
          )}
          {model.isIncomplete && (
            <div>
              <Tag intent={Intent.WARNING}>INCOMPLETE</Tag>
            </div>
          )}
          <span style={{ fontWeight: model.isActive ? 500 : 400, fontStyle: model.isActive ? undefined : 'italic' }}>
            {value}
          </span>{' '}
          <span style={{ fontSize: 9 }} className="pt-text-muted pt-text-overflow-ellipsis">
            ({model.id})
          </span>
          {model.isActive && (
            <div>
              {model.labels.map(label => (
                <LabelTag key={label.id} style={{ marginRight: 4 }} label={label} />
              ))}
            </div>
          )}
        </Fragment>
      ),
      flexBasis: 200
    },
    deviceType: {
      name: 'deviceSubtype',
      label: 'Type',
      computed: true,
      title: 'Type of device',
      flexBasis: 100
    },
    flowType: {
      name: 'flowType',
      computed: true,
      label: 'Flow Type',
      title: 'Device flow type',
      ellipsis: false,
      renderer: ({ value }) => <span className={!value ? 'pt-text-muted' : undefined}>{value || 'N/A'}</span>,
      width: 60
    },
    sending_ips: {
      name: 'sending_ips',
      label: 'IP',
      title: 'IP address(es) from which device is sending flow data',
      ellipsis: false,
      wrapText: true,
      renderer: ({ value }) => (
        <Tooltip content={value.join(', ')} className="ellipsis">
          <span>
            {value[0]} {value.length > 1 && '...'}
          </span>
        </Tooltip>
      ),
      flexBasis: 130
    },
    fps2h: {
      key: 'fps2h',
      name: 'fps2h',
      label: (
        <span>
          Pre-Cap FPS
          <br />
          <span className="pt-normal pt-text-muted">2h</span>
        </span>
      ),
      title:
        'The 99th percentile rate, expressed in flows per second, at which the device has been sending data to Kentik over the last 2 hours (not limited by service agreement flow cap).',
      ellipsis: false,
      renderer: ({ value }) => (value ? numberWithCommas(value) : 0),
      flexBasis: 65
    },
    maxFps: {
      computed: true,
      name: 'maxFps',
      label: (
        <span>
          Max FPS
          <br />
          <span className="pt-normal pt-text-muted">5m</span>
        </span>
      ),
      title:
        'The maximum rate, expressed in flows per second, at which the device has sent data to Kentik during the last 5 minutes (limited by service agreement flow cap).',
      ellipsis: false,
      renderer: ({ value }) => (value ? numberWithCommas(value) : <span className="pt-text-muted">0</span>),
      flexBasis: 65
    },
    bgpSession: {
      name: 'bgpSession',
      label: 'BGP Status',
      computed: true,
      title: 'Indicates device BGP peering setting',
      ellipsis: false,
      renderer: ({ value, model }) => {
        if (value) {
          let linkedDevice = '';
          if (model.get('device_bgp_type') === 'other_device') {
            linkedDevice = this.props.$devices.collection.models.find(
              item => item.get('id') === model.get('use_bgp_device_id').toString()
            );
          }

          return (
            <div>
              {value.map(item => (
                <div key={item.label || item.iconName}>
                  {item.label && <span className="pt-text-muted">{item.label} </span>}
                  <Tooltip content={`${item.message}${linkedDevice && linkedDevice.get('device_name')}`}>
                    <Icon name={item.iconName} style={{ fontSize: 14 }} intent={Intent[item.intent]} />
                  </Tooltip>
                </div>
              ))}
            </div>
          );
        }
        return null;
      }
    },
    RibPrefixes: {
      name: 'RibPrefixes',
      label: (
        <span>
          BGP Prefixes
          <br />
          <span className="pt-normal pt-text-muted">5m</span>
        </span>
      ),
      title: 'Current number of prefixes in BGP routing table',
      ellipsis: false,
      renderer: ({ value, model }) => (
        <div style={{ overflow: 'hidden' }}>
          <div className="pt-text-overflow-ellipsis">
            <span>{value ? numberWithCommas(value) : '-'}</span>
          </div>
          <div className="pt-text-overflow-ellipsis">
            <span>{model.get('RibPrefixes6') ? numberWithCommas(model.get('RibPrefixes6')) : '-'}</span>
          </div>
        </div>
      )
    },
    sampleRate: {
      computed: true,
      name: 'sampleRate',
      label: (
        <span>
          Sample Rate
          <br />
          <span className="pt-normal pt-text-muted">24h</span>
        </span>
      ),
      title:
        'If device reports sample rate in flow, the 95th percentile of the sample rate received in last 24 hours, else the device’s sample rate setting in the portal',
      renderer: ({ value }) => (value ? numberWithCommas(value) : '-')
    },
    classificationStats: {
      name: 'interfaceCount',
      label: 'Interface Classification',
      ellipsis: false,
      renderer: ({ model, value }) => {
        if (value) {
          const interfacesClassifiedCount = model.get('interfacesClassifiedCount');
          const percentMatched = value ? interfacesClassifiedCount / value : 0;
          let intent;
          if (percentMatched > 0.75) {
            intent = 'pt-intent-success';
          } else if (percentMatched < 0.75 && percentMatched > 0.4) {
            intent = 'pt-intent-warning';
          } else if (percentMatched < 0.4) {
            intent = 'pt-intent-danger';
          }

          const percentTextClass = `${intent}-text`;
          return (
            <div>
              <strong className={percentTextClass}>{interfacesClassifiedCount}</strong>
              <span style={{ padding: '0 3px' }}>/</span>
              <span className="pt-text-muted">{value}</span>
              <span className="pt-text-muted" style={{ padding: '0 2px' }}>
                ({Math.floor(percentMatched * 100)}
                %)
              </span>
              <ProgressBar className={`pt-no-animation pt-no-stripes ${intent}`} value={percentMatched} />
            </div>
          );
        }
        return <span className="pt-text-muted">No Interfaces</span>;
      }
    },
    cdn_attr: {
      name: 'cdn_attr',
      label: 'Device Options',
      ellipsis: false,
      renderer: ({ value, model }) => {
        const cdnEnabled = value;
        const flowSpecEnabled = model.get('device_bgp_flowspec');
        const rtbhEnabled = this.props.$alertingMitigation.isDeviceInRTBHPlatform(model);

        return (
          <Box>
            <DeviceOptionIndicator enabled={cdnEnabled} label="CDN Attribution" />
            <DeviceOptionIndicator enabled={flowSpecEnabled} label="FlowSpec" />
            <DeviceOptionIndicator enabled={rtbhEnabled} label="RTBH" isLast />
          </Box>
        );
      }
    },
    planSite: {
      name: 'plan',
      label: 'Plan & Site',
      ellipsis: false,
      className: 'column',
      renderer: ({ value, model }) => (
        <Fragment>
          <div className="pt-text-overflow-ellipsis" title={`Plan: ${value.name}`}>
            {value.name}
          </div>
          <div className="pt-text-overflow-ellipsis" title={`Site: ${model.get('site').site_name}`}>
            {model.get('site').site_name}
          </div>
        </Fragment>
      )
    },
    actions: {
      type: CELL_TYPES.ACTION,
      actions: [
        model => (
          <Tooltip content="View in Chart" key="chart">
            <Button
              iconName="chart"
              className="pt-minimal"
              text="View In Data Explorer"
              onClick={e => this.handleShowDeviceInExplorer(e, model)}
            />
          </Tooltip>
        ),
        model => (
          <Tooltip content="View Interfaces" key="interfaces">
            <Button
              iconName="comparison"
              className="pt-minimal"
              text="View Interfaces"
              onClick={e => this.handleShowDeviceInterfaces(e, model)}
            />
          </Tooltip>
        ),
        model => (
          <Tooltip content="View Interface Classification" key="interfaceClassification" position={Position.TOP_RIGHT}>
            <Button
              iconName="form"
              className="pt-minimal"
              text="View Interface Classification"
              onClick={e => this.handleShowDeviceInterfaceClassification(e, model)}
            />
          </Tooltip>
        )
      ]
    }
  };

  columnMapping = {
    base: ['FpsPostCap', 'snmp', 'device_name', 'deviceType', 'flowType', 'sending_ips', 'bgpSession', 'actions'],
    deviceStats: [
      'FpsPostCap',
      'snmp',
      'device_name',
      'deviceType',
      'flowType',
      'sending_ips',
      'fps2h',
      'maxFps',
      'bgpSession',
      'RibPrefixes',
      'sampleRate',
      'actions'
    ],
    deviceDetails: [
      'FpsPostCap',
      'snmp',
      'device_name',
      'deviceType',
      'flowType',
      'sending_ips',
      'bgpSession',
      'classificationStats',
      'cdn_attr',
      'planSite',
      'actions'
    ]
  };

  getTableColumns = (columnGroup = 'base') => {
    const columns = this.columnMapping[columnGroup];
    return columns.map(key => this.tableColumnMap[key]);
  };

  groupSummaryLookup = params => {
    const { groupBy, groupKey, group = [] } = params;
    const { $plans, $sites, $dictionary } = this.props;

    let option = { label: 'Unassigned' };

    if (groupBy === 'site.site_name') {
      const site = $sites.collection.models.find(model => model.get('title') === groupKey);
      if (site) {
        option = { label: site.get('title') };
      }
    } else if (groupBy === 'plan.id') {
      const plan = $plans.collection.get(groupKey);
      return (
        plan && (
          <Fragment>
            {plan.get('name')}
            <span className="pt-text-muted" style={{ paddingLeft: 4 }}>
              ({plan.id})
            </span>
            <Tag className="pt-small" style={{ marginLeft: 6 }}>
              {plan.get('devices').size} / {plan.get('max_devices')}
            </Tag>
          </Fragment>
        )
      );
    } else if (groupBy === 'deviceSubtype') {
      const subtype = Object.values($dictionary.dictionary.device_subtypes).find(
        deviceSubtype => deviceSubtype.display_name === groupKey
      );
      option = {
        label: (
          <span>
            {subtype && <Icon name={subtype.icon} style={{ fontSize: 24, marginRight: 16 }} />}
            {groupKey}
          </span>
        )
      };
    } else if (groupBy === 'appliedLabels') {
      option = { label: groupKey };
    }

    return (
      option && (
        <Fragment>
          {option.label}
          {group.length > 1 && (
            <Tag className="pt-small" style={{ marginLeft: 6, marginTop: 2 }}>
              {group.length}
            </Tag>
          )}
        </Fragment>
      )
    );
  };

  handleShowDeviceInExplorer = (e, device) => {
    e.stopPropagation();

    this.setState({ isChartOpen: true, viewInExplorerDevice: device });

    const query = QueryModel.create();
    query.set({
      device_name: device.get('device_name'),
      descriptor: device.get('device_name'),
      lookback_seconds: 86400,
      metric: [],
      query_title: 'Last 24h by Max Flows per second',
      aggregateTypes: ['max_flows_per_sec', 'avg_bits_per_sec'],
      outsort: 'max_flow_per_sec',
      viz_type: 'line'
    });
    this.dataview.initializeQueries([
      {
        bucket: 'Left +Y Axis',
        query: query.serialize()
      }
    ]);
  };

  handleShowDeviceInterfaces = (e, device) => {
    const { history, location, $app } = this.props;
    e.stopPropagation();
    history.push($app.adjustUrlForShim(location, `/admin/devices/${device.id}/interfaces`));
  };

  handleShowDeviceInterfaceClassification = (e, device) => {
    const { history, location, $app } = this.props;
    e.stopPropagation();
    history.push($app.adjustUrlForShim(location, `/admin/devices/${device.id}/interfaces/classification`));
  };

  getRowHeight = ({ index }, table) => {
    const {
      $devices: { collection }
    } = this.props;

    let model = index < collection.size ? collection.at(index) : null;

    if (collection.groupBy) {
      model = index < table.groupedRows.length ? table.groupedRows[index] : null;

      if (model && model.isGroupSummary) {
        return 48;
      }
    }

    if (!model) {
      return 48;
    }

    return 83;
  };

  render() {
    const {
      collection,
      selectedColumnGroup,
      onRowClick,
      columns,
      getRowHeight,
      groupSummaryLookup,
      rootUrl
    } = this.props;
    const { isChartOpen, viewInExplorerDevice } = this.state;

    return (
      <Fragment>
        <VirtualizedTable
          columns={columns || this.getTableColumns(selectedColumnGroup)}
          collection={collection}
          groupSummaryLookup={groupSummaryLookup || this.groupSummaryLookup}
          onRowClick={onRowClick}
          rowHeight={getRowHeight || this.getRowHeight}
          stickyHeader
          flexed
          rootUrl={rootUrl}
        />
        {isChartOpen && (
          <ViewInExplorerDialog
            dialogTitle={`${viewInExplorerDevice.get('device_name')}: Current and Historical Traffic`}
            dataview={this.dataview}
            isOpen={isChartOpen}
            onClose={() => {
              this.setState({ isChartOpen: false });
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default withRouter(DeviceTable);
