import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Tooltip } from '@blueprintjs/core';

@observer
export default class RefreshButton extends Component {
  render() {
    const { dataview, loading, buttonStyle } = this.props;
    return (
      <Tooltip content="Refresh">
        <Button
          className="pt-small"
          disabled={loading}
          iconName="refresh"
          style={buttonStyle}
          onClick={() => dataview.refresh()}
        />
      </Tooltip>
    );
  }
}
