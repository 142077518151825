import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { uniqueId } from 'lodash';
import { Button, Popover, Position } from '@blueprintjs/core';
import { Flex, Box } from 'components/flexbox';
import { getAggregateTypeLabel, getAggregateUnitLabel } from 'components/forms/metric/metricRenderers';
import { CELL_TYPES, Table, VirtualizedTable } from 'components/table';
import { MaterialIcon } from 'components/Icon';
import FilterGroup from 'components/FiltersDisplay/FilterGroup';
import $app from 'stores/$app';
import $dictionary from 'stores/$dictionary';
import $dataviews from 'stores/$dataviews';
import $lookups from 'stores/$lookups';
import { getQueryTimeInterval } from 'util/dateUtils';

import LegendRowOptions from './LegendRowOptions';
import {
  portRenderer,
  toggleRenderer,
  cloudRenderer,
  countryRenderer,
  asnRenderer,
  valueOrNameRenderer,
  getAggregateRenderer,
  bracketRenderer
} from './legendRenderers';

function findMetricOption(metric, options) {
  const opts = options || $dictionary.dimensionOptions;
  let option = null;

  Object.keys(opts).some(groupName =>
    opts[groupName].some(optGroup => {
      if (Array.isArray(optGroup)) {
        option = optGroup.find(opt => opt.value === metric);
      } else {
        option = findMetricOption(metric, optGroup);
      }
      return !!option;
    })
  );

  return option;
}

const totalRowFilter = model => !model.get('isOverlay');

const LegendAggregateLabel = props => (
  <span>
    <div className="pt-normal pt-text-muted">{getAggregateTypeLabel(props)}</div>
    {getAggregateUnitLabel(props)}
  </span>
);

const LegendLastDatapointLabel = props => (
  <span>
    <div className="pt-normal pt-text-muted">Last Datapoint</div>
    {getAggregateUnitLabel(props)}
  </span>
);

const groupSummaryLookup = ({ /* groupBy, */ groupKey, group }) => `${groupKey} : ${group.length}`;

const countryMetrics = ['Geography_src', 'Geography_dst', 'i_device_site_country', 'i_ult_exit_site_country'];
const asnMetrics = ['AS_src', 'AS_dst'];
const cloudMetrics = ['src_cloud', 'dst_cloud'];

@observer
export default class LegendTable extends Component {
  componentWillMount() {
    this.checkCountryOptions();
  }

  componentWillReceiveProps(nextProps) {
    this.checkCountryOptions();
    const { visibleKeys, bucket } = this.props;

    if (nextProps.visibleKeys.length !== visibleKeys.length) {
      if (nextProps.visibleKeys.length > 0) {
        bucket.queryResults.filter(model => nextProps.visibleKeys.includes(model.get('key')));
      } else {
        bucket.queryResults.clearFilters();
      }
    }
  }

  checkCountryOptions() {
    if (
      !$lookups.countryOptions &&
      this.props.bucket.firstQuery.get('metric').some(metric => countryMetrics.includes(metric))
    ) {
      $lookups.countries();
    }
  }

  getTableColumns(prefix) {
    const { bucket, showLegendOptions, compactMode, isViewCmp } = this.props;
    const aggregates = bucket.firstQuery.get('aggregates');
    const metrics = bucket.firstQuery.get('metric');
    const bracketOptions = bucket.firstQuery.get('bracketOptions');
    const filterDimensionsEnabled = bucket.firstQuery.get('filterDimensionsEnabled');
    const filterDimensionName = bucket.firstQuery.get('filterDimensionName');
    const filterDimensions = bucket.firstQuery.get('filterDimensions');
    const viz_type = bucket.firstQuery.get('viz_type');
    const fastData = bucket.firstQuery.get('fastData');
    const show_total_overlay = bucket.firstQuery.get('show_total_overlay');
    const { metricColumns, metricWidths } = $dictionary.dictionary;

    const columns = [];
    const { enableToggle } = $dataviews.getConfig(viz_type);

    if (enableToggle) {
      columns.push({
        name: 'toggled',
        className: 'legend-toggle-column',
        referencedFields: ['toggled', 'color'],
        renderer: toggleRenderer,
        ellipsis: false,
        width: 40,
        colSpan: (model, totalRow) => (totalRow ? 2 : 1)
      });
    }

    if (filterDimensionsEnabled) {
      columns.push({
        name: metricColumns.Traffic,
        flexBasis: 100,
        ellipsis: false,
        label: (
          <span>
            <div className="pt-normal pt-text-muted">Filter-Based</div>
            {filterDimensionName}
          </span>
        ),
        colSpan: () => 1,
        renderer: ({ value, model }) => {
          let filterGroup = filterDimensions.filterGroups.find(group => group.name === value);
          if (!filterGroup && value === 'Other') {
            filterGroup = {
              name: 'Other',
              connector: 'Any',
              filterGroups: filterDimensions.filterGroups,
              not: true
            };
          }

          return (
            <Fragment>
              <span className="pt-text-overflow-ellipsis">{valueOrNameRenderer(!isViewCmp)({ value, model })}</span>
              {filterGroup &&
                !model.get('isOverlay') && (
                  <Popover
                    position={Position.RIGHT_BOTTOM}
                    content={
                      <Box p={2}>
                        This value is derived from the following filter:
                        <FilterGroup group={filterGroup} connector={filterDimensions.connector} isLastGroup />
                      </Box>
                    }
                  >
                    <Button className="pt-minimal pt-small" style={{ marginLeft: 4 }}>
                      <MaterialIcon name="filter_list" />
                    </Button>
                  </Popover>
                )}
            </Fragment>
          );
        }
      });
    } else {
      metrics.forEach((metric, index) => {
        const { label, group, tagLabel } = findMetricOption(metric) || { label: metric };
        const column = {
          name: metricColumns[metric] || metric,
          flexBasis: metricWidths[metric] || 100,
          label: (
            <span>
              {group && <div className="pt-normal pt-text-muted">{group}</div>}
              {tagLabel || label}
            </span>
          ),
          colSpan: (model, totalRow) =>
            ((totalRow && !enableToggle) || (model && model.isOverlay)) && index === 0 ? metrics.length : 1
        };
        if (fastData !== 'Full' && (metric === 'Port_src' || metric === 'Port_dst')) {
          const lookback_seconds = bucket.firstQuery.get('lookback_seconds');
          const starting_time = bucket.firstQuery.get('starting_time');
          const ending_time = bucket.firstQuery.get('ending_time');
          const queryInterval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
          column.renderer = portRenderer(index === 0, !isViewCmp, fastData, queryInterval);
        } else if (countryMetrics.includes(metric)) {
          column.renderer = countryRenderer(!isViewCmp);
        } else if (cloudMetrics.includes(metric)) {
          column.renderer = cloudRenderer(!isViewCmp);
        } else if (asnMetrics.includes(metric)) {
          column.renderer = asnRenderer(!isViewCmp);
        } else if (index === 0) {
          column.renderer = valueOrNameRenderer(!isViewCmp);
        }
        columns.push(column);
      });
    }

    if (bracketOptions && bracketOptions.ranges && bracketOptions.ranges.length) {
      columns.unshift({
        name: 'tag_table',
        type: 'bracket',
        key: uniqueId(),
        renderer: bracketRenderer,
        width: 10,
        ellipsis: false,
        showTotal: false,
        colSpan: (model, totalRow) => {
          let span = 1;
          if (totalRow && enableToggle) {
            span = 3;
          } else if (totalRow && !enableToggle) {
            span = 2;
          }
          if (totalRow && metrics && metrics.length) {
            span += metrics.length - 1;
          }
          return span;
        }
      });
    }

    const lastDatapointColumns = [];
    aggregates.forEach(aggregate => {
      if (aggregate.label) {
        const renderer = getAggregateRenderer({ aggregate, prefix, bucket });
        const isAverage = show_total_overlay && aggregate.fn === 'average';
        const align = !isAverage ? 'right' : undefined;
        const headerAlign = isAverage ? 'center' : 'right';
        const width = isAverage ? 125 : 113;
        const showTotal = !aggregate.value.includes('perc');

        columns.push({
          name: aggregate.value,
          label: <LegendAggregateLabel aggregate={aggregate} prefix={prefix} />,
          renderer,
          totalRenderer: renderer,
          width,
          ellipsis: false,
          showTotal,
          align,
          headerAlign
        });

        if (aggregate.raw && !aggregate.value.includes('agg_total')) {
          lastDatapointColumns.push({
            name: `${aggregate.column}__k_last`,
            label: <LegendLastDatapointLabel aggregate={aggregate} prefix={prefix} />,
            renderer,
            totalRenderer: renderer,
            width,
            ellipsis: false,
            showTotal,
            align,
            headerAlign
          });
        }
      }
    });

    if (lastDatapointColumns) {
      columns.push(...lastDatapointColumns);
    }

    if (showLegendOptions && !compactMode && !filterDimensionsEnabled && metrics[0] !== 'Traffic') {
      columns.push({
        name: 'options',
        type: CELL_TYPES.ACTION,
        actions: [
          model => !model.get('isOverlay') && <LegendRowOptions {...this.props} key="legend-tool" model={model} />
        ],
        width: 45
      });
    }

    return columns;
  }

  onRowClick = (rowModel, e) => {
    const { onModelSelect, sourceLink } = this.props;
    if (onModelSelect && !rowModel.get('isOverlay')) {
      const { model } = sourceLink;
      onModelSelect(rowModel, model, e.metaKey);
    }
  };

  render() {
    const { bucket, isViewCmp, selectedKeys } = this.props;
    if (!bucket) {
      return null;
    }
    const { firstQuery, queryResults } = bucket;
    const { prefix } = queryResults;
    const aggregateTypes = firstQuery.get('aggregateTypes');

    const showTotalRow = aggregateTypes.some(aggregateType => aggregateType.includes('per_sec'));

    const viewStyle = {};
    if (isViewCmp) {
      viewStyle.boxShadow = 'none';
    }

    if ($app.isExport) {
      return (
        <Table
          className="condensed"
          collection={queryResults}
          columns={this.getTableColumns(prefix)}
          groupSummaryLookup={groupSummaryLookup}
          showTotalRow={showTotalRow}
          totalRowFilter={totalRowFilter}
        />
      );
    }

    return (
      <Flex flexAuto className="overflow-hidden" style={viewStyle}>
        <VirtualizedTable
          className="condensed legend-table"
          collection={queryResults}
          columns={this.getTableColumns(prefix)}
          groupSummaryLookup={groupSummaryLookup}
          flexed
          stickyHeader
          rowHeight={45}
          hideNonIdealState={!isViewCmp}
          onRowClick={this.onRowClick}
          selectOnRowClick={false}
          isRowSelected={model => selectedKeys.includes(model.get('key'))}
          onRowMouseOver={model => {
            if (this.mouseoverModel) {
              this.mouseoverModel.set({ mouseover: false });
            }
            model.set({ mouseover: true });
            this.mouseoverModel = model;
          }}
          onRowMouseOut={model => {
            model.set({ mouseover: false });
            this.mouseoverModel = null;
          }}
          showTotalRow={showTotalRow}
          totalRowFilter={totalRowFilter}
        />
      </Flex>
    );
  }
}
