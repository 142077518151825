import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { getOutsortAggregateUnit } from 'forms/config/bracketOptions';
import { uniqueId } from 'lodash';
import { Box, Flex } from 'components/flexbox';
import { generatorMap } from 'util/queryResultTagging/tagEngine';
import { getRangeMetadata } from 'services/bracketing';

@inject('$explorer', '$dictionary')
@observer
export default class StaticRangeSummaryForm extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    fieldName: 'bracketOptions'
  };

  render() {
    const { fieldName } = this.props;
    const { form } = this.context;
    const units = getOutsortAggregateUnit(form.model.toJS());
    const fieldValue = form.getValue(fieldName);
    const rangeMetadata = getRangeMetadata({
      bracketOptions: Object.assign(fieldValue, { bracketAggregationUnit: units })
    });
    const bracketType = fieldValue ? fieldValue.type : 'N/A';
    const displayName = generatorMap[bracketType] ? generatorMap[bracketType].display : 'N/A';
    const ranges = rangeMetadata.metaRanges.map((metaRange, index) => {
      // metaRanges include all ranges plus the "over" range, the field ranges are just user input ranges (one less), so account for that.
      const backgroundColor =
        index === fieldValue.ranges.length ? fieldValue.over : fieldValue.ranges[index].data.value;
      return (
        <Box key={uniqueId()} mb={1}>
          <div>
            <span className="pt-tag pt-minimal" style={{ backgroundColor, width: '100%', textAlign: 'left' }}>
              {metaRange.bracketLabel}
            </span>
          </div>
        </Box>
      );
    });

    return (
      <Flex flexColumn>
        <Box flexAuto mb={1}>
          <span style={{ fontWeight: 700 }}>{displayName}:</span>
        </Box>
        <Box>{ranges}</Box>
      </Flex>
    );
  }
}
