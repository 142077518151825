import React from 'react';
import { observer } from 'mobx-react';
import { select } from 'd3-selection';
import { scaleOrdinal, schemeCategory20 } from 'd3-scale';
import { rgb } from 'd3-color';
import { debounce } from 'lodash';

import sankey from 'dataviews/d3/sankey';
import { getMetadata, getBracketingDataClasses } from 'services/bracketing';
import $app from 'stores/$app';
import $dictionary from 'stores/$dictionary';
import { getToFixed, zeroToText, adjustByGreekPrefix } from 'util/utils';

import BaseDataview from './BaseDataview';

@observer
export default class SankeyView extends BaseDataview {
  constructor(props) {
    super(props);
    window.addEventListener('resize', debounce(() => this.reflow(), 100));
  }

  chartRef = ref => {
    if (ref) {
      this.chart = ref;
    }
  };

  parsedMetrics = {
    IP_src: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
    IP_dst: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
    src_nexthop_ip: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
    dst_nexthop_ip: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
    src_route_prefix_len: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
    dst_route_prefix_len: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
    InterfaceID_src: /(?:(?:.*:\s((?!---\s).{2,8}).*\s\()|(?:^((?!---\s).{2,8}).*\s:)|\(([0-9]+)\))/,
    InterfaceID_dst: /(?:(?:.*:\s((?!---\s).{2,8}).*\s\()|(?:^((?!---\s).{2,8}).*\s:)|\(([0-9]+)\))/,
    bgp_ult_exit_interface: /(?:(?:.*:\s((?!---\s).{2,8}).*\s\()|(?:^((?!---\s).{2,8}).*\s:)|\(([0-9]+)\))/,
    AS_src: /(^.{6}).*(\([0-9]+\))/,
    src_nexthop_asn: /(^.{6}).*(\([0-9]+\))/,
    src_second_asn: /(^.{6}).*(\([0-9]+\))/,
    src_third_asn: /(^.{6}).*(\([0-9]+\))/,
    AS_dst: /(^.{6}).*(\([0-9]+\))/,
    dst_nexthop_asn: /(^.{6}).*(\([0-9]+\))/,
    dst_second_asn: /(^.{6}).*(\([0-9]+\))/,
    dst_third_asn: /(^.{6}).*(\([0-9]+\))/
  };

  getShortName(metric, name) {
    let matches;
    if (metric === 'AS_src' || metric === 'AS_dst') {
      if (name.match(/\(.*,.*\)/)) {
        return name.substring(0, name.indexOf('(') - 1);
      }
    }

    if (this.parsedMetrics[metric]) {
      matches = this.parsedMetrics[metric].exec(name);
      // in this first scenario, there were matches (matches not null), but some are undefined meaning it's a conditional parser
      if (matches && matches.indexOf(undefined) !== -1) {
        while (matches[matches.length - 1] === undefined) {
          matches.splice(-1, 1);
        }
        return matches[matches.length - 1];
        // in this scenario, there were matches (matches not null), but no undefined so we want to join them all together
      } else if (matches) {
        matches.splice(0, 1);
        return matches.map(match => match.replace(/---/g, '')).join(' ');
      }
      // no matches, so just return the name (guard)
      return name;
    }
    return name;
  }

  graph = {
    nodes: [],
    links: []
  };

  drawSankey() {
    if (!this.chart) {
      return;
    }
    const { graph, props } = this;
    const { dataview } = props;
    const { dictionary } = $dictionary;
    const dict = dictionary.chartTypesValidations;
    const { firstQuery } = dataview.queryBuckets.activeBuckets[0];
    const { outsortUnit, prefixUnit } = firstQuery;
    const units = dictionary.units[outsortUnit];
    const prefix = dataview.queryBuckets.activeBuckets[0].queryResults.prefix;

    const margin = {
      top: 10,
      right: 40,
      bottom: 10,
      left: 40
    };
    this.chart.innerHTML = '';
    const chartContainer = select(this.chart);
    const width = parseInt(chartContainer.style('width')) - margin.left - margin.right;
    const height = parseInt(chartContainer.style('height')) - margin.top - margin.bottom;
    const format = d =>
      `${zeroToText(adjustByGreekPrefix(d, prefix[prefixUnit]), { fix: getToFixed(outsortUnit) })} ${prefix[
        prefixUnit
      ] || ''}${units}`;
    const color = scaleOrdinal(schemeCategory20);

    const getTitle = d => {
      let title = '';
      if (d.isRatioBased) {
        title = `${d.source.name.substring(d.source.name.indexOf('__k_') + 4)} (${format(
          d.source.value
        )}) → ${d.target.name.substring(d.target.name.indexOf('__k_') + 4)} (${format(d.target.value)})`;
      } else {
        title = `${d.source.name.substring(d.source.name.indexOf('__k_') + 4)} → ${d.target.name.substring(
          d.target.name.indexOf('__k_') + 4
        )}
      ${format(d.value)}`;
      }
      return title;
    };

    const getCssClass = d => {
      let cssClass = d.causesCycle ? 'cycleLink' : 'link';
      if (d.isRatioBased) cssClass += ' fillLink';
      return cssClass;
    };

    const getColor = value => {
      if (this.bracketDataClasses) {
        return this.getBracketColor(value);
      }

      return $app.darkThemeEnabled ? '#ffffff' : '#000000';
    };

    // append the svg canvas to the page
    const svg = chartContainer
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Set the sankey diagram properties
    const sankeyChart = sankey()
      .nodeWidth(36)
      .nodePadding(10)
      .size([width, height]);

    const path = sankeyChart.link();

    sankeyChart
      .nodes(graph.nodes)
      .links(graph.links)
      .layout(32, true);

    // add in the links
    const link = svg
      .append('g')
      .selectAll('.link')
      .data(graph.links)
      .enter()
      .append('path')
      .attr('class', d => getCssClass(d))
      .attr('d', path)
      // .sort((a, b) => b.dy - a.dy) // in pathLinks and mouseover handler we assume graph.links and links appended to svg are in the same order
      .style('stroke', d => (d.isRatioBased ? 'none' : getColor(d.value)))
      .style('stroke-width', d => (d.isRatioBased ? 0 : Math.max(1, d.dy)))
      .style('fill', d => (d.isRatioBased ? getColor(d.value) : 'none'));

    // add the link titles
    link.append('title').text(d => getTitle(d));

    link.on('mouseover', d => {
      for (let x = 0, xlen = d.pathLinks.length; x < xlen; x += 1) {
        if (d.isRatioBased) {
          select(link._groups[0][d.pathLinks[x]]).style('fill-opacity', 0.5);
        } else {
          select(link._groups[0][d.pathLinks[x]]).style('stroke-opacity', 0.5);
        }
      }
    });

    link.on('mouseout', d => {
      for (let x = 0, xlen = d.pathLinks.length; x < xlen; x += 1) {
        if (d.isRatioBased) {
          select(link._groups[0][d.pathLinks[x]]).style('fill-opacity', 0.2);
        } else {
          select(link._groups[0][d.pathLinks[x]]).style('stroke-opacity', 0.2);
        }
      }
    });

    // add in the nodes
    const node = svg
      .append('g')
      .selectAll('.node')
      .data(graph.nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${d.x},${d.y})`);

    // add the rectangles for the nodes
    node
      .append('rect')
      .attr('height', d => d.dy)
      .attr('width', sankeyChart.nodeWidth())
      .style(
        'fill',
        d => (d.color = this.bracketDataClasses ? this.getBracketColor(d.value) : color(d.name.replace(/ .*/, '')))
      )
      .style('stroke', d => rgb(d.color).darker(2))
      .attr('pointer-events', 'all')
      .on('mouseover', d => {
        let x;
        let xlen;
        let y;
        let ylen;
        const linksToHighlight = [];
        for (x = 0, xlen = d.sourceLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.sourceLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.sourceLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.sourceLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = d.targetLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.targetLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.targetLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.targetLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = linksToHighlight.length; x < xlen; x += 1) {
          select(link._groups[0][linksToHighlight[x]]).style('stroke-opacity', 0.5);
        }
      })
      .on('mouseout', d => {
        let x;
        let xlen;
        let y;
        let ylen;
        const linksToHighlight = [];
        for (x = 0, xlen = d.sourceLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.sourceLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.sourceLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.sourceLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = d.targetLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.targetLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.targetLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.targetLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = linksToHighlight.length; x < xlen; x += 1) {
          select(link._groups[0][linksToHighlight[x]]).style('stroke-opacity', 0.2);
        }
      })
      .append('title')
      .text(d => {
        let aspathInfo;
        const arr = d.name.split('__k_');
        if (arr[0].indexOf('bgp_aspath') !== -1) {
          aspathInfo = /^(.*bgp_aspath)_(\d)/.exec(arr[0]);
          if (aspathInfo) {
            arr[0] = `${dict[aspathInfo[1]]} Step ${parseInt(aspathInfo[2]) + 1}`;
          } else {
            arr[0] = `${dict[arr[0]]} Step 1`;
          }
        } else {
          arr[0] = dict[arr[0]];
        }
        arr.push(format(d.value));
        return arr.join('\n');
      });

    // add clickable label to nodes
    node
      .append('foreignObject')
      .attr('x', d => {
        let label_width = d.shortName.length * 10;
        if (label_width > 250) label_width = 250;
        const label_padding = 6;
        const label_x = d.x < width / 2 ? sankeyChart.nodeWidth() + label_padding : -1 * (label_width + label_padding);
        return label_x;
      })
      .attr('y', d => d.dy / 2 - 10)
      .attr('width', d => {
        let label_width = d.shortName.length * 10;
        if (label_width > 250) label_width = 250;
        return label_width;
      })
      .attr('height', 16)
      .append('xhtml:div')
      .style('margin', 0)
      .style('padding', 0)
      .style('background-color', 'transparent')
      .style('text-align', d => {
        const label_align = d.x < width / 2 ? 'left' : 'right';
        return label_align;
      })
      .append('span')
      .text(d => d.shortName);
  }

  getRatioFields = outsortAggregate => {
    const compositeAggs = $dictionary.dictionary.aggregateComposites;
    const { leftOperand, rightOperand, fn, compositeFn } = outsortAggregate;
    let numeratorField;
    let denominatorField;
    if (fn === 'composite') {
      // Currently only percent and latency apply
      const isPercent = compositeFn === 'multiply' && parseInt(rightOperand, 10) === 100;
      const cfg = isPercent ? compositeAggs.find(agg => agg.value === leftOperand) : outsortAggregate;
      if (cfg && cfg.compositeFn === 'divide') {
        numeratorField = cfg.leftOperand;
        denominatorField = cfg.rightOperand;
      }
    }
    return {
      numeratorField,
      denominatorField
    };
  };

  getBracketColor = value => {
    const linkDataClass =
      Array.isArray(this.bracketDataClasses) &&
      this.bracketDataClasses.find(d => value >= d.from && (value <= d.to || d.to === undefined));
    return linkDataClass && linkDataClass.color;
  };

  buildSeriesInternal(bucket, models) {
    this.clear();
    if (!this.chart || !models.length) {
      return;
    }

    const { metric } = bucket.firstQuery.get();
    const { outsortDataKey, outsortAggregate } = bucket.firstQuery;

    const isRatioBased = outsortDataKey.includes('perc_') || outsortDataKey.includes('latency');
    let ratioFields;
    if (isRatioBased) {
      ratioFields = this.getRatioFields(outsortAggregate);
    }

    const bracketOptions = bucket.firstQuery.get('bracketOptions');

    if (bracketOptions) {
      const metadata = getMetadata({ queryResults: bucket.queryResults, bracketOptions });
      this.bracketDataClasses = getBracketingDataClasses({ metadata, bracketOptions });
    } else {
      this.bracketDataClasses = null;
    }

    models.forEach(model => {
      const { lookup, key, isOverlay } = model.get();
      if (isOverlay) {
        return;
      }

      const value = model.get(outsortDataKey);
      let numerator;
      let denominator;
      if (isRatioBased && ratioFields) {
        const { numeratorField, denominatorField } = ratioFields;
        numerator = model.get(numeratorField);
        denominator = model.get(denominatorField);
        if (outsortDataKey.includes('perc_')) numerator *= 100;
      }

      const metrics = [].concat(metric);
      const nodes = (lookup || key).split(' ---- ').slice(0, metrics.length);
      let x = 0;
      let xlen = nodes.length;
      let path;
      let y;
      let ylen;
      let found;
      let foundNext;
      const initialLinkLength = this.graph.links.length;
      const linkedLinks = [];

      for (; x < xlen; x += 1) {
        if (metrics[x].indexOf('bgp_aspath') !== -1) {
          path = nodes[x].split(' ');
          nodes[x] = path[0];
          for (y = 1, ylen = path.length; y < ylen; y += 1) {
            metrics.splice(x + y, 0, `${metrics[x]}_${y}`);
            nodes.splice(x + y, 0, path[y]);
          }
          x += path.length - 1;
          xlen += path.length - 1;
        }
      }

      for (x = 0, xlen = nodes.length; x < xlen - 1; x += 1) {
        linkedLinks.push(initialLinkLength + x);
      }

      for (x = 0, xlen = nodes.length; x < xlen - 1; x += 1) {
        found = null;
        foundNext = null;
        for (y = 0, ylen = this.graph.nodes.length; y < ylen; y += 1) {
          if (this.graph.nodes[y].name === `${metrics[x]}__k_${nodes[x]}`) {
            found = y;
          }
          if (this.graph.nodes[y].name === `${metrics[x + 1]}__k_${nodes[x + 1]}`) {
            foundNext = y;
          }
        }
        if (found === null) {
          found = this.graph.nodes.length;
          this.graph.nodes.push({
            node: found,
            name: `${metrics[x]}__k_${nodes[x]}`,
            shortName: this.getShortName(metrics[x], nodes[x])
          });
        }
        if (foundNext === null) {
          foundNext = this.graph.nodes.length;
          this.graph.nodes.push({
            node: foundNext,
            name: `${metrics[x + 1]}__k_${nodes[x + 1]}`,
            shortName: this.getShortName(metrics[x + 1], nodes[x + 1])
          });
        }
        this.graph.links.push({
          source: found,
          target: foundNext,
          pathLinks: [].concat(linkedLinks),
          isRatioBased,
          value,
          numerator,
          denominator
        });
      }
    });

    this.redraw();
  }

  redraw() {
    $app.renderSync(() => {
      this.drawSankey(this.graph);
    });
    $app.renderSync(() => {
      this.dismissSpinner(50);
    });
  }

  reflow() {
    this.redraw();
  }

  clear() {
    this.graph.nodes = [];
    this.graph.links = [];
  }

  getComponent() {
    const { queryBuckets } = this.props.dataview;
    if (!queryBuckets.activeBucketCount) {
      return null;
    }

    const metric = queryBuckets.activeBuckets[0].firstQuery.get('metric');
    if (metric.length < 2 && !metric.find(m => m.includes('bgp_aspath'))) {
      throw new Error(
        'You must select at least one AS Path or two (2) group by dimensions to visualize data in a Sankey Diagram.'
      );
    }

    return <svg width="100%" height="100%" ref={this.chartRef} />;
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  singleDataseries: true,
  timeBased: false,
  enableToggle: false,
  buckets: [
    {
      name: 'Flow'
    }
  ]
};

export { config };
