import React from 'react';
import { observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { Select, SelectButtonGroup } from 'components/forms';
import { orderBy } from 'lodash';
import HeaderCell from './HeaderCell';

const directionOptions = [
  {
    label: 'Asc',
    value: 'asc'
  },
  {
    label: 'Desc',
    value: 'desc'
  }
];

const ResponsiveHeader = props => {
  const { collection, columns } = props;
  const sortDirection = collection.sortState.direction;

  let sortOptions = columns.map(column => ({
    label: column.label,
    value: column.sortField || column.name,
    key: column.key || column.name || column.type
  }));

  sortOptions = orderBy(sortOptions, ['label']);

  const sortField = {
    defaultValue: collection.sortState.field,
    value: collection.sortState.field,
    getValue: () => collection.sortState.field,
    options: sortOptions
  };

  const changeSortField = value => collection.sort(value);

  const directionField = {
    defaultValue: sortDirection,
    value: sortDirection,
    getValue: () => sortDirection,
    options: directionOptions
  };

  const changeSortDirection = value => {
    if (sortDirection !== value) {
      collection.sort(collection.sortState.field);
    }
  };

  return (
    <Box className="thead-sort-controls">
      <strong className="sort-title">Sort By</strong>
      <Select field={sortField} onChange={changeSortField} />
      <SelectButtonGroup className="sort-direction" field={directionField} onChange={changeSortDirection} />
    </Box>
  );
};

const Header = props => {
  const { collection, columns, responsiveMode, style } = props;
  const visibleColumns = columns.filter(column => !column.hidden && column.type !== 'spacer');

  return (
    <Box className="thead">
      {responsiveMode && <ResponsiveHeader {...props} columns={visibleColumns} />}
      {!responsiveMode && (
        <Flex className="tr" style={style}>
          {visibleColumns.map((column, idx) => (
            <HeaderCell
              {...props}
              key={column.key || column.name || column.type}
              column={column}
              {...collection.sortState}
              isAllSelected={collection.isAllSelected}
              columns={visibleColumns}
              index={idx}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default observer(Header);
