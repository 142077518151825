import { any } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';

import { Box } from 'components/flexbox';

@observer
class SidebarSectionWarnings extends Component {
  static contextTypes = {
    form: any
  };

  render() {
    const { form } = this.context;
    const { fieldGroup, sidebar, isSectionDirty } = this.props;

    const isSidebarCollapsed = sidebar && sidebar.collapsed;
    const sectionHasErrors = form.invalidGroups[fieldGroup];
    const sectionIncomplete = form.incompleteGroups[fieldGroup];

    return (
      <Fragment>
        {isSectionDirty &&
          !sectionHasErrors &&
          !isSidebarCollapsed && (
            <Box mt={1} className="pt-tag pt-intent-warning pt-minimal">
              Unapplied changes
            </Box>
          )}
        {(sectionHasErrors || sectionIncomplete) &&
          !isSidebarCollapsed && (
            <Box mt={1} className="pt-tag pt-intent-danger pt-minimal">
              {sectionIncomplete ? 'Changes required' : 'Invalid changes'}
            </Box>
          )}{' '}
      </Fragment>
    );
  }
}

export default SidebarSectionWarnings;
