import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Flex, Box } from 'components/flexbox';
import { Dialog } from '@blueprintjs/core';

@inject('$app')
@observer
export default class BuildInfoDialog extends Component {
  onClose = () => {
    this.props.$app.setBuildInfoOpen(false);
  };

  render() {
    const {
      $app: { portalVersion }
    } = this.props;
    const portalSplit = portalVersion.portal.commit.split('/');
    const nodeSplit = portalVersion.node.commit.split('/');
    return (
      <Dialog
        title="Version Info"
        {...this.props}
        style={{ top: 100, width: 425 }}
        onClose={this.onClose}
        canEscapeKeyClose
        transitionName="pt-dialog"
      >
        <Flex p={2}>
          <Box mr={3}>
            <strong>&nbsp;</strong>
            <div className="pt-text-muted">Build</div>
            <div className="pt-text-muted">Commit</div>
          </Box>
          <Box mr={2} flexAuto style={{ minWidth: 150 }}>
            <strong>Portal</strong>

            <div>{portalVersion.portal.rev} </div>
            <div className="pt-text-overflow-ellipsis">{portalSplit[0]}</div>
            <div className="pt-text-overflow-ellipsis">{portalSplit[1]}</div>
          </Box>
          <Box flexAuto style={{ minWidth: 150 }}>
            <strong>Node</strong>
            <div>{portalVersion.node.rev} </div>
            <div className="pt-text-overflow-ellipsis">{nodeSplit[0]}</div>
            <div className="pt-text-overflow-ellipsis">{nodeSplit[1]}</div>
          </Box>
        </Flex>
      </Dialog>
    );
  }
}
