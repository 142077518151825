import React from 'react';
import { observer } from 'mobx-react';

import { Flex } from 'components/flexbox';

const ToolsWrapper = observer(({ children, ...rest }) => (
  <Flex justify="flex-end" {...rest} onMouseDown={e => e.stopPropagation()}>
    <Flex className="pt-small" align="center">
      {children}
    </Flex>
  </Flex>
));

const DataViewTools = observer(({ children, dataview, shouldCollapseButtonText, showButtonText, dataViewSize }) => {
  if (!dataview) {
    return null;
  }

  const { loading } = dataview.loading;

  const toolProps = {
    buttonStyle: { marginLeft: 4 },
    dataview,
    dataViewSize,
    isDisabled: loading,
    loading,
    shouldCollapseButtonText,
    showButtonText,
    Wrapper: ToolsWrapper
  };

  return children({
    ...toolProps
  });
});

export default DataViewTools;
