import moment from 'moment';

function dateRangeValidation(value, req) {
  const [maxRange, otherFieldName] = req.split(',');
  const otherField = moment(this.validator.input[otherFieldName]);
  const currentField = moment(value);
  if (!(otherField.isValid() && currentField.isValid())) {
    return false;
  }
  if (currentField.isBefore(otherField)) {
    return otherField.subtract(maxRange, 'milliseconds').isSameOrBefore(currentField);
  }
  return currentField.subtract(maxRange, 'milliseconds').isSameOrBefore(otherField);
}

export default {
  message: ':attribute range must not exceed maximum date range',
  fn: dateRangeValidation
};
