const fields = {
  user_email: {
    label: 'User Email',
    rules: 'required|email'
  },
  first_name: {
    label: 'First Name',
    rules: 'required'
  },
  last_name: {
    label: 'Last Name',
    rules: 'required'
  },
  phone: {
    label: 'Phone',
    rules: 'required'
  },
  job_title: {
    label: 'Job Title',
    rules: 'required'
  },
  company_name: {
    label: 'Organization Name',
    rules: 'required'
  },
  email_optin: {
    defaultValue: false
  },

  Lead_Region__c: {
    rules: 'required',
    defaultValue: 'United States',
    options: [
      { label: 'United States', value: 'United States' },
      { label: 'Canada', value: 'Canada' },
      { label: 'European Union', value: 'European Union' },
      { label: 'Europe (non-EU)', value: 'Europe (non-EU)' },
      { label: 'Asia/Pacific', value: 'Asia/Pacific' },
      { label: 'Latin America', value: 'Latin America' },
      { label: 'Middle East and Africa', value: 'Middle East and Africa' }
    ]
  },

  region: {
    rules: 'required',
    defaultValue: 'US'
  },

  // this will get translated to `Consent_to_Processing__c` on the backend.

  accept_terms: {
    defaultValue: true,
    rules: 'accepted'
  },

  recaptcha: {}
};

export { fields };
