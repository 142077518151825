import { action, computed } from 'mobx';
import { getQueriesForHash, getHashForQueries } from 'services/urlHash';
import api from 'util/api';

import AbstractDataViewModel from 'models/AbstractDataViewModel';
import Scoreboard from 'models/alerting/Scoreboard';

import { fields as scoreboardFormFields } from 'forms/config/scoreboard';
import ConfigureScoreboardForm from './ConfigureScoreboardForm';

export default class AlertScoreboardDataViewModel extends AbstractDataViewModel {
  constructor(viewModel) {
    super();
    this.viewModel = viewModel || new Scoreboard();
  }

  get formConfig() {
    return scoreboardFormFields;
  }

  get formComponent() {
    return ConfigureScoreboardForm;
  }

  get isFlowVisualization() {
    return false;
  }

  get viewType() {
    return 'alertScoreboard';
  }

  get viewName() {
    return 'Policy Scoreboard';
  }

  @computed
  get title() {
    return this.viewModel.title;
  }

  @action
  refresh = async () => {
    this.loading = true;
    const scoreboardConfig = await getQueriesForHash(this.hash, { normalize: false });
    const scoreboardData = await this.fetchScoreboardData(scoreboardConfig);
    this.viewModel.set({
      id: this.hash,
      ...scoreboardData
    });
    this.loading = false;
  };

  fetchScoreboardData = config => api.post('/api/portal/alerts/scoreboardData', { data: config });

  @action
  save = async values => {
    this.viewModel.setRequestStatus('updating');
    const saved_query_id = await getHashForQueries(values, { persist: true });
    const scoreboardData = await this.initializeHash(saved_query_id);
    this.hideConfigureDialog();
    this.viewModel.setRequestStatus(null);

    const config = { saved_query_id, ...values };

    // and whatever comes back from the server

    return { config, result: scoreboardData };
  };

  @action
  initializeHash = async hash => {
    this.loading = true;

    if (hash) {
      this.hash = hash;
    }

    await this.refresh();

    this.setFullyLoaded();

    return this.viewModel.get();
  };

  setUpdateFrequency(frequency = 0) {
    if (frequency) {
      this.updateFrequency = setInterval(this.refresh, frequency * 1000);
    } else if (this.updateFrequency) {
      clearInterval(this.updateFrequency);
      delete this.updateFrequency;
    }
  }

  destroy() {
    clearInterval(this.updateFrequency);
  }
}
