import React, { Component } from 'react';
import { computed, autorun } from 'mobx';
import { observer } from 'mobx-react';
import { Flex, Box } from 'components/flexbox';
import $dictionary from 'stores/$dictionary';
import $app from 'stores/$app';
import { get } from 'lodash';
import { NonIdealState, Button } from '@blueprintjs/core';
import DataViewSpinner from 'dataviews/DataViewSpinner';

import {
  defaultPrimaryOverlayColor,
  defaultOverlayColor,
  defaultDarkPrimaryOverlayColor,
  defaultDarkOverlayColor,
  defaultLabelColor,
  defaultDarkLabelColor,
  DEFAULT_PALETTES,
  DEFAULT_QUALITATIVE_PALETTES
} from 'models/Colors';

@observer
export default class BaseDataview extends Component {
  alwaysShowDataView = false;

  // Show dataview even when loading, avoids remounts
  spinnerClassName = undefined;

  // Apply different css to dataviewSpinner
  series = {};

  overlaySeries = {};

  buildPromise = Promise.resolve();

  togglerDisposers = [];

  allowScroll = false;

  spinnerRef = refs => {
    this.spinner = refs;
  };

  dismissSpinner = (timeout = 200, setFullyLoaded = true) => {
    setTimeout(() => {
      if (this.spinner && !this.spinner.state.hide) {
        this.spinner.setState(() => ({ hide: true }));
      }
      if (setFullyLoaded) {
        this.props.dataview.setFullyLoaded();
      }
    }, timeout);
  };

  @computed
  get chartColors() {
    return $app.darkThemeEnabled ? this.darkColors : this.lightColors;
  }

  @computed
  get lightColors() {
    const { viewProps } = this.props;
    const customPalette = get(viewProps, 'colors.standard.paletteCustom', []);
    const palette = get(viewProps, 'colors.standard.palette', 'default');

    return customPalette.length > 0 ? customPalette : DEFAULT_PALETTES[palette];
  }

  @computed
  get darkColors() {
    const { viewProps } = this.props;
    const customPalette = get(viewProps, 'colors.dark.paletteCustom', []);
    const palette = get(viewProps, 'colors.dark.palette', 'leGreg');

    return customPalette.length > 0 ? customPalette : DEFAULT_PALETTES[palette];
  }

  @computed
  get primaryOverlayColor() {
    const { viewProps } = this.props;
    const color = get(viewProps, 'colors.standard.primaryOverlay', defaultPrimaryOverlayColor);
    const darkColor = get(viewProps, 'colors.dark.primaryOverlay', defaultDarkPrimaryOverlayColor);

    if ($app.darkThemeEnabled) {
      return darkColor;
    }

    return color;
  }

  @computed
  get overlayColor() {
    const { viewProps } = this.props;
    const color = get(viewProps, 'colors.standard.overlay', defaultOverlayColor);
    const darkColor = get(viewProps, 'colors.dark.overlay', defaultDarkOverlayColor);

    if ($app.darkThemeEnabled) {
      return darkColor;
    }

    return color;
  }

  @computed
  get chartLabelColor() {
    const { viewProps } = this.props;
    const color = get(viewProps, 'colors.standard.labels', defaultLabelColor);
    const darkColor = get(viewProps, 'colors.dark.labels', defaultDarkLabelColor);

    if ($app.darkThemeEnabled) {
      return darkColor;
    }

    return color;
  }

  @computed
  get qualitativeColors() {
    const { viewProps } = this.props;

    const palette = get(viewProps, 'colors.standard.qualitative', 'default');
    const darkPalette = get(viewProps, 'colors.dark.qualitative', 'leGreg');

    const customPalette = get(viewProps, 'colors.standard.qualitativeCustom', []);
    const customDarkPalette = get(viewProps, 'colors.standard.qualitativeCustom', []);

    if ($app.darkThemeEnabled) {
      return customDarkPalette.length > 0 ? customDarkPalette : DEFAULT_QUALITATIVE_PALETTES[darkPalette];
    }

    return customPalette.length > 0 ? customPalette : DEFAULT_QUALITATIVE_PALETTES[palette];
  }

  getRawDataKey(query, outsortOverride) {
    const aggregates = query.get('aggregates');
    const outsort = outsortOverride || query.get('outsort');
    const { countColumns, countRegex } = $dictionary.dictionary;
    const rawAgg = aggregates.find(
      agg =>
        agg.raw &&
        agg.column &&
        (agg.name === outsort || (outsort.startsWith('sum_logsum') && agg.unit === outsort.replace('sum_logsum_', '')))
    );

    let newColName = '';
    if (rawAgg && rawAgg.column) {
      newColName = rawAgg.column
        .replace('f_sum_', '')
        .replace('bytes', 'bits')
        .replace('trautocount', 'flows');
      if (!countColumns.includes(rawAgg.column) && !countRegex.some(regex => new RegExp(regex).test(rawAgg.column))) {
        newColName += '_per_sec';
      }
    }

    return newColName;
  }

  buildSeries(bucket, models) {
    this.buildPromise = this.buildPromise.then(() => {
      this.buildSeriesInternal(bucket, models);
      const secondaryOutsort = bucket.firstQuery.get('secondaryOutsort');
      const secondaryBucketIndex = bucket.firstQuery.get('secondaryTopxMirrored')
        ? bucket.get('secondaryMirrorBucket')
        : bucket.get('secondaryOverlayBucket');
      if (
        secondaryOutsort &&
        secondaryBucketIndex !== undefined &&
        bucket.firstQuery.get('secondaryTopxSeparate') === false
      ) {
        const secondaryBucket = this.props.dataview.queryBuckets.at(secondaryBucketIndex);
        this.buildSeriesInternal(bucket, models, secondaryBucket, secondaryOutsort);
      }
    });
  }

  buildSeriesInternal(primaryBucket, models, bucketOverride, outsortOverride) {
    if (!models || !models.length) {
      return;
    }

    const bucket = bucketOverride || primaryBucket;

    const query = primaryBucket.firstQuery;
    const bucketName = bucket.get('name');
    const rawDataKey = this.getRawDataKey(query, outsortOverride);
    const names = [];

    if (!this.series[bucketName]) {
      this.series[bucketName] = {};
    }

    models.forEach(model => {
      if (model.get('isOverlay')) {
        return this.buildOverlaySeries(primaryBucket, model, bucketOverride, outsortOverride);
      }

      const lookup = model.get('lookup');
      const key = model.get('key');
      const rawData = model.get('rawData');
      if (!rawData || !rawData[rawDataKey]) {
        return null;
      }

      const name = lookup || key;
      names.push(name);

      if (this.series[bucketName][name]) {
        return this.updateRenderedSeries(bucket, name, rawData[rawDataKey], query);
      }

      const series = this.renderSeries(bucket, name, rawData[rawDataKey].flow, query);
      if (series) {
        this.series[bucketName][name] = model;
        series.options.bucketName = bucketName;
        if (series.color) {
          series.options.model = model;
          model.set({ color: series.color, toggled: false });
          this.togglerDisposers.push(
            autorun(() => {
              const toggled = model.get('toggled');
              const mouseover = model.get('mouseover');
              if (toggled === series.visible) {
                this.toggleSeries(series, toggled);
              }
              if (mouseover) {
                this.highlightSeries(series);
              } else {
                this.unhighlightSeries(series);
              }
            })
          );
        }
      }
      return series;
    });

    this.removeOldSeries(bucketName, names);

    this.redraw();
  }

  buildOverlaySeries(primaryBucket, model, bucketOverride, outsortOverride) {
    const bucket = bucketOverride || primaryBucket;
    const name = model.get('name');
    const rawData = model.get('rawData');
    const bucketName = bucket.get('name');
    const query = primaryBucket.overlayQueries.find(q => q.get('descriptor') === name) || primaryBucket.firstQuery;
    const rawDataKey = this.getRawDataKey(query, outsortOverride);

    if (!rawData || !rawData[rawDataKey]) {
      return null;
    }

    if (!this.overlaySeries[bucketName]) {
      this.overlaySeries[bucketName] = {};
    }

    if (this.overlaySeries[bucketName][name]) {
      return this.updateRenderedOverlay(bucket, name, rawData[rawDataKey], query);
    }

    const series = this.renderOverlay(bucket, name, rawData[rawDataKey].flow, query);
    if (series) {
      this.overlaySeries[bucketName][name] = model;
      series.options.model = model;
      if (series.color) {
        model.set({ color: series.color, toggled: false });
        this.togglerDisposers.push(
          autorun(() => {
            const toggled = model.get('toggled');
            const mouseover = model.get('mouseover');
            if (toggled === series.visible) {
              this.toggleSeries(series, toggled);
            }
            if (mouseover) {
              this.highlightSeries(series);
            } else {
              this.unhighlightSeries(series);
            }
          })
        );
      }
    }
    return series;
  }

  removeOldSeries(bucketName, names) {
    Object.keys(this.series[bucketName]).forEach(name => {
      if (!names.includes(name)) {
        this.removeSeries(bucketName, name);
        delete this.series[bucketName][name];
      }
    });
  }

  renderSeries() {
    console.warn('renderSeries not implemented');
  }

  renderOverlay() {
    console.warn('renderOverlay not implemented');
  }

  updateRenderedSeries() {
    console.warn('updateRenderedSeries not implemented');
  }

  updateRenderedOverlay() {
    console.warn('updateRenderedOverlay not implemented');
  }

  removeSeries() {
    console.warn('removeSeries not implemented');
  }

  removeOverlay() {
    console.warn('removeOverlay not implemented');
  }

  toggleSeries() {
    console.warn('toggleSeries not implemented');
  }

  renderTimeReset() {
    console.warn('renderTimeReset not implemented');
  }

  clear() {
    console.warn('clear not implemented');
  }

  redraw() {
    console.warn('redraw not implemented');
  }

  reflow() {
    console.warn('reflow not implemented');
  }

  syncAxes() {
    // making sure we don't get an error if dataview doesn't have this
  }

  getExtremes() {
    // making sure we don't get an error if dataview doesn't have this
  }

  setExtremes() {
    // making sure we don't get an error if dataview doesn't have this
  }

  getComponent() {
    throw new Error('You must implement getComponent when extending BaseDataview');
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.lastUpdated > this.props.lastUpdated;
  }

  componentWillMount() {
    let darkTheme = $app.darkThemeEnabled;
    this.darkThemeDisposer = autorun(() => {
      if (darkTheme !== $app.darkThemeEnabled) {
        this.redraw({ setColors: true });
        darkTheme = $app.darkThemeEnabled;
      }
    });
    this.afterComponentWillMount();
  }

  componentWillUnmount() {
    this.darkThemeDisposer();
    this.darkThemeDisposer = null;
    this.togglerDisposers.forEach(disposer => disposer());
    this.togglerDisposers = [];
    this.afterComponentWillUnmount();
  }

  afterComponentWillMount() {
    // override this if need logic on mount
  }

  afterComponentWillUnmount() {
    // override this if need logic on unmount
  }

  componentDidMount() {
    this.props.dataview.component = this;
  }

  componentWillUpdate(nextProps) {
    if (nextProps.dataview.loading) {
      this.series = {};
      this.overlaySeries = {};
      this.togglerDisposers.forEach(disposer => disposer());
      this.togglerDisposers = [];
      this.clear();
    }
  }

  componentDidUpdate(prevProps) {
    // we do this here (for now?) because mobx-react is calling forceUpdate()
    // perhaps we need to figure out how to decouple this from observables better...
    if (this.props.dataview.loading || (!this.props.forceLoad && this.props.lastUpdated <= prevProps.lastUpdated)) {
      // nothing's updated, return;
      return;
    }

    const { dataview } = this.props;
    const { queryBuckets } = dataview;
    const { activeBuckets, activeBucketCount } = queryBuckets;

    if (!activeBucketCount) {
      return;
    }

    activeBuckets.forEach(bucket => {
      if (!bucket.loading) {
        const outsort = bucket.firstQuery.get('outsort');
        const topx = bucket.firstQuery.get('topx');

        if (outsort.includes('agg_total')) {
          this.buildSeries(
            bucket,
            bucket.queryResults.nonOverlayRows.slice(0, topx).concat(bucket.queryResults.overlayRows)
          );
        } else {
          this.buildSeries(bucket, bucket.queryResults.getRawDataRows(true));
        }
      }
    });

    if (dataview.hasTimeReset) {
      $app.renderSync(() => this.renderTimeReset());
    }

    if (queryBuckets.selectedQuery.get('sync_axes')) {
      this.syncAxes(true);
    }

    if (this.selectedModels) {
      setTimeout(() => this.setSelectedModels(this.selectedModels), 50);
    }
  }

  render() {
    const { dataview, lastUpdated } = this.props;
    const {
      loading,
      preventQuery,
      queryBuckets: { activeBuckets }
    } = dataview;
    let DataViewComponent;

    if (preventQuery || (!loading && !activeBuckets.some(bucket => !!bucket.queryResults.size))) {
      this.dismissSpinner(0);
      DataViewComponent = (
        <Flex flexAuto flexColumn justify="center" align="center" style={{ height: '100%' }}>
          <NonIdealState
            title="No Results"
            visual="issue"
            description={
              dataview.hasTimeReset && (
                <Button className="pt-large pt-intent-primary" onClick={dataview.resetTimeRange}>
                  Zoom out
                </Button>
              )
            }
          />
        </Flex>
      );
    } else {
      try {
        DataViewComponent = this.getComponent();
      } catch (err) {
        this.dismissSpinner(0);
        DataViewComponent = (
          <Flex flexAuto flexColumn justify="center" align="center" style={{ height: '100%' }}>
            <Box mx={2} className="pt-callout pt-intent-warning pt-minimal pt-icon-warning-sign">
              <small>{err.message}</small>
            </Box>
          </Flex>
        );
      }
    }

    return (
      <Flex
        flexAuto
        lastUpdated={lastUpdated}
        style={{ position: 'relative', overflow: this.allowScroll ? 'auto' : 'hidden' }}
      >
        <DataViewSpinner
          {...this.props}
          loading={loading}
          ref={this.spinnerRef}
          calloutClassName={this.spinnerClassName}
        />
        {(!loading || this.alwaysShowDataView) && DataViewComponent}
      </Flex>
    );
  }
}
