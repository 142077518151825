import React from 'react';
import { Tag, Intent, Classes } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Box } from 'components/flexbox';
import { KentikLogo, Icon } from './index';

const { intentClass } = Classes;

const iconStyle = {
  fontSize: 11,
  marginRight: 4,
  position: 'relative',
  top: -1,
  opacity: 0.75
};

const smallCircleStyle = {
  borderRadius: 12,
  display: 'inline-block',
  height: 10,
  width: 10,
  marginRight: 8
};

const presetKentikMarkStyle = {
  cursor: 'pointer',
  width: 12,
  position: 'relative',
  top: 2,
  marginRight: 3,
  opacity: 0.75,
  transform: 'scale(1.2)'
};

export const typesHash = {
  preset: {
    intent: Intent.WARNING,
    icon: undefined,
    text: 'Preset'
  },

  org: {
    intent: Intent.SUCCESS,
    icon: 'people',
    text: 'Shared',
    helpText: 'Visible to other users'
  },

  self: {
    intent: Intent.PRIMARY,
    icon: 'person',
    text: 'Private',
    helpText: 'Only visible to you'
  }
};

export const getShareLevelTag = (item, type) => {
  if (!item && type) {
    return typesHash[type];
  }

  const { isCompanyLevel, isPreset } = item;

  if (isPreset) {
    return typesHash.preset;
  }

  if (isCompanyLevel) {
    return typesHash.org;
  }

  return typesHash.self;
};

export const ShareLevelIcon = props => {
  const { item, type, minimal } = props;

  const { icon } = getShareLevelTag(item, type);

  if (icon) {
    return <Icon style={iconStyle} name={`pt-icon-${icon}`} />;
  } else if ((item && item.isPreset) || type === 'preset') {
    return <KentikLogo onlyMark style={presetKentikMarkStyle} fillColor={minimal ? '#ff850e' : '#ffffff'} />;
  }

  return null;
};

const ShareLevelBadge = props => {
  const {
    item,
    style,
    type,
    size,
    showHelpText = false,
    showText = true,
    showIcon = true,
    large = false,
    fill = false,
    strong = true,
    round = false,
    minimal = true
  } = props;
  const { intent, text, helpText } = getShareLevelTag(item, type);

  if (size === 'small') {
    const className = classNames({
      'item-share-badge': true,
      [intentClass(intent)]: true
    });

    return <Box className={className} style={{ ...smallCircleStyle, ...style }} />;
  }

  const tagClass = classNames('pt-tag ', {
    'pt-round': round,
    'pt-minimal': minimal,
    'pt-fill': fill,
    'pt-strong': strong,
    'pt-normal': !strong,
    'pt-large': large
  });

  return (
    <Tag intent={intent} className={tagClass} style={style}>
      {showIcon && <ShareLevelIcon {...props} />}
      {showText && item && item.shareLevel}
      {showText && !item && text}
      {showHelpText && helpText && <span className="pt-normal">: {helpText}</span>}
    </Tag>
  );
};

export default observer(ShareLevelBadge);
