import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$devices')
class Devices extends Component {
  title = 'Device';

  url = '/admin/devices';

  render() {
    const { result, $devices } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="material-icons:storage"
        url={this.url}
        nameField="device_name"
        collection={$devices.collection}
        {...this.props}
      >
        <span>type: {result.device_type}</span>
      </ResultRow>
    );
  }
}

export default Devices;
