import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { observable, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Tag, Dialog, Button, Icon, InputGroup } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { ValidationErrorsOrHelpText } from 'components/forms';
import { getDeviceValues } from 'util/devices';

import DeviceSelectorDisplay from './DeviceSelectorDisplay';
import ShortcutGroup from './ShortcutGroup';
import SelectedValueButton from './SelectedValueButton';

@inject('$deviceGroups', '$devices', '$sites')
@observer
class DeviceSelector2 extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    allowAllDevices: true,
    editButtonText: 'Edit Devices',
    showEditButton: true,
    showLabels: true,
    showSites: true,
    showTypes: true,
    deviceTypesFieldName: 'device_types',
    deviceLabelsFieldName: 'device_labels',
    deviceSitesFieldName: 'device_sites',
    deviceNameFieldName: 'device_name',
    allDevicesFieldName: 'all_devices',
    readOnly: false,
    required: true
  };

  state = {
    ready: true,
    isOpen: false,
    searchInputValue: ''
  };

  componentWillMount() {
    const { isOpen } = this.props;

    this.state.isOpen = isOpen;
  }

  componentDidMount() {
    this.preserveLastSavedValues();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });

      if (nextProps.isOpen) {
        this.preserveLastSavedValues();
      }
    }
  }

  preserveLastSavedValues() {
    const { form } = this.context;
    const {
      allDevicesFieldName,
      deviceTypesFieldName,
      deviceLabelsFieldName,
      deviceSitesFieldName,
      deviceNameFieldName
    } = this.props;
    this.lastSavedValues = {
      [allDevicesFieldName]: form.getValue(allDevicesFieldName),
      [deviceTypesFieldName]: form.getValue(deviceTypesFieldName),
      [deviceLabelsFieldName]: form.getValue(deviceLabelsFieldName),
      [deviceSitesFieldName]: form.getValue(deviceSitesFieldName),
      [deviceNameFieldName]: form.getValue(deviceNameFieldName)
    };
  }

  revertToLastSavedValues() {
    const { form } = this.context;
    const {
      allDevicesFieldName,
      deviceTypesFieldName,
      deviceLabelsFieldName,
      deviceSitesFieldName,
      deviceNameFieldName
    } = this.props;

    form.setValue(allDevicesFieldName, this.lastSavedValues[allDevicesFieldName], { validate: false });
    form.setValue(deviceTypesFieldName, this.lastSavedValues[deviceTypesFieldName], { validate: false });
    form.setValue(deviceLabelsFieldName, this.lastSavedValues[deviceLabelsFieldName], { validate: false });
    form.setValue(deviceSitesFieldName, this.lastSavedValues[deviceSitesFieldName], { validate: false });
    form.setValue(deviceNameFieldName, this.lastSavedValues[deviceNameFieldName], { validate: false });

    form.validate();
  }

  handleCancel = () => {
    const { onClose } = this.props;

    this.revertToLastSavedValues();

    this.setState({ isOpen: false });

    if (onClose) {
      onClose();
    }
  };

  handleToggleEditing = () => {
    const { onClose } = this.props;
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });

    if (isOpen && onClose) {
      onClose();
    }
  };

  handleToggleAllDevices = () => {
    const { form } = this.context;
    const { allDevicesFieldName } = this.props;
    form.setValue(allDevicesFieldName, !form.getValue(allDevicesFieldName));
  };

  handleSearchInputChange = e => this.setState({ searchInputValue: e.target.value });

  handleClearSearchInput = () => this.setState({ searchInputValue: '' });

  handleShortcutClick = (option, field, force = null) => {
    const values = field.getValue();
    const index = values.findIndex(id => id === option.value);

    if (index === -1 && force !== false) {
      field.setValue([...values, option.value]);
    } else if (index !== -1 && force !== true) {
      field.setValue([...values.slice(0, index), ...values.slice(index + 1)]);
    }
  };

  @computed
  get noneSelected() {
    return (
      this.selectedValues.get('labels').length === 0 &&
      this.selectedValues.get('sites').length === 0 &&
      this.selectedValues.get('types').length === 0 &&
      this.selectedValues.get('devices').length === 0
    );
  }

  // handles some reverse lookups, since we're working only with IDs at this point for values.
  @computed
  get selectedValues() {
    const { ready } = this.state;

    if (!ready) {
      return observable.map({
        all: [],
        labels: [],
        sites: [],
        types: [],
        devices: []
      });
    }

    const { form } = this.context;
    const {
      allDevicesFieldName,
      deviceTypesFieldName,
      deviceLabelsFieldName,
      deviceSitesFieldName,
      deviceNameFieldName
    } = this.props;

    const all_devices = form.getValue(allDevicesFieldName);
    const device_types = form.getValue(deviceTypesFieldName);
    const device_labels = form.getValue(deviceLabelsFieldName);
    const device_sites = form.getValue(deviceSitesFieldName);
    const device_name = form.getValue(deviceNameFieldName);

    return getDeviceValues({ all_devices, device_types, device_labels, device_sites, device_name });
  }

  renderDialogTitle = () => (
    <Flex justify="space-between" align="center">
      <Box flexAuto>Devices</Box>
      <Flex justify="flex-end" style={{ padding: 3 }}>
        <InputGroup
          autoFocus
          leftIconName="search"
          placeholder="Search..."
          value={this.state.searchInputValue}
          onChange={this.handleSearchInputChange}
          type="search"
          rightElement={
            this.state.searchInputValue !== '' ? (
              <Button iconName="cross" onClick={this.handleClearSearchInput} className="pt-small pt-minimal" />
            ) : (
              undefined
            )
          }
        />
      </Flex>
    </Flex>
  );

  render() {
    const { form } = this.context;
    const {
      $deviceGroups,
      $devices,
      $sites,
      allowAllDevices,
      editButtonText,
      showEditButton,
      showSites,
      showLabels,
      showTypes,
      allDevicesFieldName,
      deviceTypesFieldName,
      deviceLabelsFieldName,
      deviceSitesFieldName,
      deviceNameFieldName,
      disabled,
      readOnly,
      required
    } = this.props;

    const all_devices = form.getValue(allDevicesFieldName);
    const device_types = form.getValue(deviceTypesFieldName);
    const device_labels = form.getValue(deviceLabelsFieldName);
    const device_sites = form.getValue(deviceSitesFieldName);
    const device_name = form.getValue(deviceNameFieldName);

    const { isOpen, searchInputValue } = this.state;

    const allDevicesCount = $devices.deviceSummaries.length;

    const labelOptions = $deviceGroups.deviceLabelOptions.filter(opt => opt.activeDevices.length > 0);
    const siteOptions = $sites.siteOptions.filter(opt => opt.activeDevices.length > 0);

    const selectedDevices = !all_devices
      ? $devices.getUniqueSelectedDevices({ device_types, device_labels, device_sites, device_name })
      : $devices.deviceSummaries;

    const selectedDeviceNames = selectedDevices.map(d => d.device_name);

    const hasLabels = showLabels && labelOptions.length > 0;
    const showSiteSelection = showSites && siteOptions.length > 0;

    // if a label / site / type has selected a device, that device should be disabled (or hidden?) from selection individually
    const deviceNameOptions = $devices.deviceSummaryOptions.filter(opt => !selectedDeviceNames.includes(opt.label));

    const allDevicesField = form.getField(allDevicesFieldName);

    return (
      <div>
        <DeviceSelectorDisplay
          {...this.props}
          allDevices={all_devices}
          onRemove={this.handleShortcutClick}
          selectedDevices={selectedDevices}
          selectedValues={this.selectedValues}
        />
        {allDevicesField.hasError && (
          <Box className="pt-form-group" mb={0.5} mt={0}>
            <ValidationErrorsOrHelpText field={allDevicesField} />
          </Box>
        )}
        {!readOnly &&
          showEditButton && (
            <Button
              className="pt-button pt-small"
              disabled={disabled}
              text={editButtonText}
              onClick={this.handleToggleEditing}
            />
          )}
        {!readOnly && (
          <Dialog
            isOpen={isOpen}
            title={this.renderDialogTitle()}
            canEscapeKeyClose
            onClose={this.handleToggleEditing}
            style={{ width: 'auto', height: 550, top: 50, paddingBottom: 0 }}
            transitionName="pt-dialog"
          >
            <Flex className="overflow-hidden" style={{ height: '100%' }}>
              <Flex
                className="pt-card flat overflow-hidden"
                flexColumn
                style={{
                  minWidth: 276,
                  maxWidth: 276,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0
                }}
              >
                <Box flexAuto className="overflow-auto" p={1}>
                  {allowAllDevices && (
                    <Button
                      className={classNames('pt-minimal pt-fill pt-text-left', {
                        'pt-active pt-strong': all_devices
                      })}
                      style={{ marginBottom: 16 }}
                      onClick={this.handleToggleAllDevices}
                      text={
                        <Flex align="center">
                          <Icon iconName={all_devices ? 'tick' : 'multi-select'} />
                          <Box className="pt-text-overflow-ellipsis" flexAuto>
                            Use All devices
                          </Box>
                          {<small className="pt-text-muted pt-normal">{allDevicesCount}</small>}
                        </Flex>
                      }
                    />
                  )}

                  {showTypes && (
                    <ShortcutGroup
                      searchInputValue={searchInputValue}
                      disabled={all_devices}
                      expandable={false}
                      filterable={false}
                      field={form.getField(deviceTypesFieldName)}
                      onAdd={this.handleShortcutClick}
                      options={$devices.deviceTypeOptions}
                      selectedValues={this.selectedValues.get('types')}
                      showClearButton={false}
                      title="Types"
                      type="deviceType"
                    />
                  )}

                  <ShortcutGroup
                    searchInputValue={searchInputValue}
                    disabled={all_devices}
                    field={form.getField(deviceLabelsFieldName)}
                    iconName={undefined}
                    onAdd={this.handleShortcutClick}
                    options={labelOptions}
                    selectedValues={this.selectedValues.get('labels')}
                    noResultsText={
                      !hasLabels ? (
                        <Fragment>
                          Labels enable dynamic grouping of your devices. <br />
                          <Link
                            className="pt-button pt-minimal pt-icon-share pt-intent-primary"
                            to="/admin/devices/labels"
                            style={{ marginTop: 6 }}
                          >
                            Configure Labels
                          </Link>
                        </Fragment>
                      ) : null
                    }
                    title={
                      <Flex align="center">
                        Labels
                        <Tag className="pt-small pt-intent-warning pt-round" style={{ marginLeft: 4, fontSize: 9 }}>
                          <strong>NEW</strong>
                        </Tag>
                      </Flex>
                    }
                    type="label"
                  />

                  {showSiteSelection && (
                    <ShortcutGroup
                      searchInputValue={searchInputValue}
                      disabled={all_devices}
                      field={form.getField(deviceSitesFieldName)}
                      iconName="map-marker"
                      onAdd={this.handleShortcutClick}
                      options={siteOptions}
                      selectedValues={this.selectedValues.get('sites')}
                      title="Sites"
                      type="site"
                    />
                  )}

                  <ShortcutGroup
                    searchInputValue={searchInputValue}
                    disabled={all_devices}
                    expandable={false}
                    field={form.getField(deviceNameFieldName)}
                    iconName="cog"
                    onAdd={this.handleShortcutClick}
                    options={deviceNameOptions}
                    selectedValues={this.selectedValues.get('devices')}
                    title="Devices"
                    type="device"
                    mb={0}
                  />
                </Box>
              </Flex>
              <Flex flexColumn p={2} style={{ minWidth: 500, maxWidth: 500 }} flexAuto className="overflow-hidden">
                {required &&
                  this.noneSelected &&
                  !all_devices && (
                    <div className="pt-callout pt-intent-danger">
                      <h5 style={{ fontSize: 15 }}>No Devices selected</h5>
                      Select at least 1 group or individual device from the left pane.
                    </div>
                  )}
                <Box flexAuto className="overflow-auto" mr={-2} style={{ padding: '1px 16px 1px 1px' }}>
                  {all_devices && (
                    <SelectedValueButton
                      type="all"
                      label="All Devices"
                      iconName="multi-select"
                      devices={this.selectedValues.get('all')}
                      onRemove={() => form.setValue(allDevicesFieldName, false)}
                    />
                  )}

                  {!all_devices &&
                    !this.noneSelected && (
                      <Fragment>
                        {this.selectedValues.get('types').map(selectedType => (
                          <SelectedValueButton
                            type="deviceTypes"
                            label={selectedType.label}
                            onRemove={() => this.handleShortcutClick(selectedType, form.getField(deviceTypesFieldName))}
                            key={selectedType.value}
                            iconName={selectedType.iconName}
                            devices={selectedType.devices}
                          />
                        ))}
                        {this.selectedValues
                          .get('labels')
                          .map(
                            selectedLabel =>
                              selectedLabel && (
                                <SelectedValueButton
                                  type="deviceLabels"
                                  iconName="tag"
                                  onRemove={() =>
                                    this.handleShortcutClick(selectedLabel, form.getField(deviceLabelsFieldName))
                                  }
                                  label={selectedLabel.label}
                                  key={selectedLabel.value}
                                  devices={selectedLabel.activeDevices}
                                  color={selectedLabel.color}
                                />
                              )
                          )}
                        {this.selectedValues
                          .get('sites')
                          .map(
                            selectedSite =>
                              selectedSite && (
                                <SelectedValueButton
                                  type="deviceSites"
                                  iconName="map-marker"
                                  onRemove={() =>
                                    this.handleShortcutClick(selectedSite, form.getField(deviceSitesFieldName))
                                  }
                                  label={selectedSite.label}
                                  key={selectedSite.value}
                                  devices={selectedSite.activeDevices}
                                />
                              )
                          )}

                        {this.selectedValues
                          .get('devices')
                          .map(
                            device =>
                              device && (
                                <SelectedValueButton
                                  type="deviceNames"
                                  onRemove={() => this.handleShortcutClick(device, form.getField(deviceNameFieldName))}
                                  iconName={device.iconName}
                                  label={device.label}
                                  key={device.value}
                                />
                              )
                          )}
                      </Fragment>
                    )}
                </Box>

                <Flex justify="flex-end" pt={2}>
                  <Button text="Cancel" style={{ marginRight: 8, width: 110 }} onClick={this.handleCancel} />
                  <Button
                    text="Save"
                    disabled={this.noneSelected && !all_devices}
                    onClick={this.handleToggleEditing}
                    className="pt-medium pt-intent-primary"
                    style={{ width: 110 }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Dialog>
        )}
      </div>
    );
  }
}

export default DeviceSelector2;
