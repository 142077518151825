import React from 'react';
import { observer } from 'mobx-react';
import { Button, Tooltip, Menu, MenuItem, Position, Popover } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex } from 'components/flexbox';
import Icon, { MaterialIcon } from 'components/Icon';
import AlertFilterField from '../AlertFilterField';
import { getHistoryFilters } from '../alarmHelpers';

const severityTextStyles = {
  fontSize: 10,
  fontWeight: 700,
  textTransform: 'uppercase',
  lineHeight: '12px',
  marginTop: -2
};

const menuTetherOptions = {
  offset: '0 -18px'
};

const PolicyMenu = props => {
  const { model, onFilterFieldClick, showPolicyEdit = true, onEditPolicy } = props;
  const policy_id = model.get('policy_id');
  const policy_name = model.get('policy_name');
  const alert_id = model.get('alert_id');

  const menuContent = (
    <Menu>
      <MenuItem
        text="View Policy History"
        iconName="notifications"
        onClick={() => onFilterFieldClick(getHistoryFilters('alert_id', alert_id))}
      />
      {policy_name.trim() !== '' &&
        policy_name.indexOf('Deleted Policy') === -1 &&
        showPolicyEdit && (
          <MenuItem
            iconName="edit"
            text="Edit Policy"
            onClick={() => {
              onEditPolicy(model);
            }}
          />
        )}
    </Menu>
  );

  return (
    <Popover
      popoverClassName="pt-minimal"
      position={Position.BOTTOM_LEFT}
      content={menuContent}
      tetherOptions={menuTetherOptions}
    >
      <Button
        style={{ marginRight: 6, marginTop: 4, lineHeight: '19px' }}
        className="pt-small pt-strong"
        title={`${policy_name} (${policy_id})`}
        iconName="chevron-down"
      />
    </Popover>
  );
};

const PolicyCell = props => {
  const { model, onFilterFieldClick, isAlarmsPage } = props;
  const row_type = model.get('row_type');
  const new_alarm_state = model.get('new_alarm_state');
  const old_alarm_state = model.get('old_alarm_state');
  const policy_id = model.get('policy_id');
  const policy_name = model.get('policy_name');
  const comment = model.get('comment');
  const learning_mode = model.get('learning_mode');

  let policy_link = <strong style={{ padding: isAlarmsPage ? '0 7px' : 0 }}>Mitigation</strong>;
  let alarm_state_display = null;

  const severityTextClass = classNames('pt-text-overflow-ellipsis small-caps', model.severityTextClass);

  if (row_type !== 'Mitigation') {
    policy_link = (
      <Flex className="overflow-hidden">
        <PolicyMenu {...props} />
        <div className="overflow-hidden">
          <div className="pt-text-overflow-ellipsis pt-strong" title={`${policy_name} (${policy_id})`}>
            {policy_name}
          </div>
          <div style={severityTextStyles} className={severityTextClass}>
            {model.severityDisplay}
          </div>
        </div>
      </Flex>
    );
  }

  if (new_alarm_state && old_alarm_state) {
    alarm_state_display = (
      <Flex align="center">
        <AlertFilterField
          model={model}
          filterBy="old_state"
          filterVal={old_alarm_state}
          displayName={model.oldStateDisplay}
          state={old_alarm_state}
          onFilterFieldClick={onFilterFieldClick}
          useLinkStyle={false}
          showStateIcons
          oldState
        />

        <Icon style={{ fontSize: 9, margin: '0 4px' }} className="pt-text-muted" name="arrow-right" />

        <AlertFilterField
          model={model}
          filterBy="new_state"
          filterVal={new_alarm_state}
          state={new_alarm_state}
          displayName={model.newStateDisplay}
          onFilterFieldClick={onFilterFieldClick}
          useLinkStyle={false}
          showStateIcons
          newState
        />
        {comment && (
          <Tooltip content={<div style={{ width: 300 }}>{comment}</div>}>
            <Icon name="comment" style={{ marginLeft: 8 }} />
          </Tooltip>
        )}
      </Flex>
    );
  }

  return (
    <Flex flexColumn={alarm_state_display} style={{ width: '100%' }}>
      {policy_link}
      {alarm_state_display}
      {learning_mode > 0 && (
        <Flex style={{ marginTop: 2 }} className="pt-minimal pt-text-muted pt-normal">
          <MaterialIcon name="notifications_off" />
          <span>Silenced</span>
        </Flex>
      )}
    </Flex>
  );
};

export default observer(PolicyCell);
