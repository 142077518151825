import Collection from '../Collection';
import Subscription from './Subscription';

class SubscriptionCollection extends Collection {
  get url() {
    return '/api/portal/subscription/reports/email';
  }

  get model() {
    return Subscription;
  }

  get filterFieldWhitelist() {
    return new Set(['title', 'recipients']);
  }
}

export default SubscriptionCollection;
