import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';

const ConfigureDataViewButton = ({ dataview, loading, ...rest }) => (
  <Button
    disabled={loading}
    text="Configure"
    onClick={dataview.showConfigureDialog}
    className="pt-small pt-medium"
    iconName="cog"
    {...rest}
  />
);

export default observer(ConfigureDataViewButton);
