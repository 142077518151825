import classNames from 'classnames';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { MenuItem, Checkbox } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';

const SingleSelectItemText = observer(props => (
  <Flex>
    <span className="pt-icon-dot" style={{ marginRight: 8 }} />
    <span className="pt-text-overflow-ellipsis" style={{ whiteSpace: 'normal' }}>
      {props.label}
    </span>
  </Flex>
));

const MultiSelectItemText = observer(({ disabled, field, iconCls, label, multi, selected, selectItem, value }) => (
  <Flex>
    {multi && (
      <Checkbox
        checked={selected}
        style={{ margin: 0 }}
        onChange={() => selectItem(field, value)}
        disabled={disabled}
      />
    )}
    <span className={iconCls} style={{ marginRight: iconCls ? 8 : 0 }} />
    <span className="pt-text-overflow-ellipsis" style={{ whiteSpace: 'normal' }}>
      {label}
    </span>
  </Flex>
));

@observer
class ModalOptionItem extends Component {
  static defaultProps = {
    disabled: false,
    selected: false
  };

  shouldComponentUpdate(nextProps) {
    const { disabled, selected } = this.props;
    return disabled !== nextProps.disabled || selected !== nextProps.selected;
  }

  render() {
    const { disabled, field, label, multi, selected, selectState, value } = this.props;
    const { selectItem } = selectState;

    const className = classNames('pt-menu-item', { 'pt-active': selected });

    return (
      <Box px={1} style={{ paddingBottom: 2, breakInside: 'avoid' }}>
        {!multi && (
          <MenuItem
            disabled={disabled}
            onClick={() => selectItem(field, value)}
            text={<SingleSelectItemText label={label} />}
          />
        )}
        {multi && (
          <MenuItem
            disabled={disabled}
            text={<MultiSelectItemText {...this.props} selectItem={selectItem} />}
            className={className}
            onClick={() => selectItem(field, value)}
          />
        )}
      </Box>
    );
  }
}

export default ModalOptionItem;
