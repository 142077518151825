import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import { Box } from 'components/flexbox';
import classNames from 'classnames';

@observer
class ButtonGroup extends Component {
  handleSelect = value => {
    const { field, onChange } = this.props;

    const newValues = toJS(field.value) || [];
    const indexOfValue = newValues.indexOf(value);

    if (indexOfValue === -1) {
      newValues.push(value);
    } else {
      newValues.splice(indexOfValue, 1);
    }

    onChange(toJS(newValues));
  };

  defaultButtonRenderer = option => {
    const { field } = this.props;
    const { label, value } = option;
    const selected = field.getValue().includes(value);

    return (
      <Button
        active={selected}
        className="pt-text-overflow-ellipsis"
        iconName={selected ? 'small-tick' : undefined}
        key={label}
        onClick={() => this.handleSelect(value)}
        text={label}
      />
    );
  };

  render() {
    const { field, options, className, buttonRenderer } = this.props;
    const buttons = options || field.options;

    const buttonGroupClassName = classNames('pt-button-group', className);
    const renderer = buttonRenderer || this.defaultButtonRenderer;

    return <Box className={buttonGroupClassName}>{buttons.map(option => renderer(option))}</Box>;
  }
}

export default observer(ButtonGroup);
