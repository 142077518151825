import React, { Component } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { fields } from 'forms/config/alertNotification';
import { Field, Input, Select, Switch } from 'components/forms';
import { updateRules } from 'forms/utils';

const tetherOptions = {
  offset: '-3px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

@inject('$dictionary', '$app')
@observer
class NotificationForm extends Component {
  static contextTypes = {
    form: any
  };

  componentWillMount() {
    this.setChannelRules();
  }

  componentWillUpdate() {
    this.onChannelTypeChange();
  }

  getChannelTypeOptions = () => {
    const { $app } = this.props;

    if ($app.isSubtenant) {
      return fields.channel_type.options.filter(type => type.value !== 'slack');
    }
    return fields.channel_type.options;
  };

  onChannelTypeChange = () => {
    const { form } = this.props;
    form.setValue('notification_value', '');
    this.setChannelRules();
  };

  setChannelRules = () => {
    const { form } = this.props;

    const variableRules = {
      email: {
        notification_value: { label: 'Email', rules: 'required|commaSeparatedEmails' }
      },
      json: {
        notification_value: { label: 'Webhook URL', rules: 'required' }
      },
      slack: {
        'slack.incomingWebhook.url': {
          rules: [{ required_if: ['slack.manualOverride', true] }, 'url']
        }
      },
      syslog: {
        'syslog.host': 'required',
        'syslog.port': 'required|integer',
        'syslog.transport': 'required|integer',
        'syslog.sysLogHostname': 'required|string',
        'syslog.severity': 'required',
        'syslog.facility': 'required'
      },
      opsgenie: {},
      pagerduty: {
        notification_value: { label: 'PagerDuty 32 Character Integration Key', rules: 'required' }
      },
      servicenow: {
        'servicenow.instance': 'required'
      }
    };

    if (form.model.isNew) {
      variableRules.servicenow['servicenow.user'] = 'required';
      variableRules.servicenow['servicenow.password'] = 'required';
    } else {
      variableRules.servicenow['servicenow.user'] = '';
      variableRules.servicenow['servicenow.password'] = '';
    }

    updateRules(form, form.getValue('channel_type'), variableRules);
  };

  render() {
    const { $dictionary, $app, form } = this.props;
    const channelType = form.getValue('channel_type');
    return (
      <div>
        <p className="pt-running-text">
          Notification Channels are additional ways to receive alerts when they occur. Channels must be added to
          individual alert policies to become active.
        </p>
        {$app.isSubtenant && (
          <Field name="config.receiveTenantNotifications">
            <Switch />
          </Field>
        )}
        <Field name="notification_name">
          <Input />
        </Field>
        <Field
          name="channel_type"
          onChange={this.onChannelTypeChange}
          inputStyle={{ width: 230 }}
          options={this.getChannelTypeOptions()}
        >
          <Select tetherOptions={tetherOptions} />
        </Field>
        {channelType === 'email' && (
          <div>
            <Field
              name="notification_value"
              label="Email(s)"
              labelStyle={{ textTransform: 'none' }}
              helpText="Enter a comma separated list of emails"
            >
              <Input />
            </Field>
            <Field name="reminder_interval" inputStyle={{ width: 230 }}>
              <Select tetherOptions={tetherOptions} />
            </Field>
          </div>
        )}
        {channelType === 'json' && (
          <div>
            <Field name="notification_value" label="Webhook URL">
              <Input />
            </Field>
            <div className="pt-callout pt-intent-primary">
              <h5>Webhook Security Information</h5>
              <ul>
                <li>
                  You will receive JSON notifications from:{' '}
                  {$dictionary.dictionary.general_router_settings.router_snmp_polling_ips}
                </li>
                <li>The User-Agent HTTP Header will be set as: Kentik-Alerting</li>
              </ul>
              <p>
                See the{' '}
                <a
                  href="https://kb.kentik.com/Gc04.htm#Gc04-Syslog_Notification_Settings"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Kentik Knowledge Base
                </a>{' '}
                for full documentation on the JSON format.
              </p>
            </div>
          </div>
        )}
        {channelType === 'slack' && (
          <div className="pt-callout pt-intent-primary">
            <h5>Enabling Slack Notifications</h5>
            {!form.getValue('slack.manualOverride') && (
              <p>
                Creating this notification channel will install the Kentik Alerts Slack app for your selected team. Once
                you save, you will be redirected to Slack for authorization and team/channel selection.
              </p>
            )}
            <Field name="slack.manualOverride">
              <Switch switchLabel="Manually enter Slack webhook URL" />
            </Field>
            {form.getValue('slack.manualOverride') && (
              <Field name="slack.incomingWebhook.url">
                <Input />
              </Field>
            )}
          </div>
        )}

        {channelType === 'syslog' && (
          <div>
            <Field name="syslog.host">
              <Input />
            </Field>
            <Field name="syslog.port">
              <Input />
            </Field>
            <Field name="syslog.transport">
              <Select tetherOptions={tetherOptions} />
            </Field>
            <Field name="syslog.sysLogHostname">
              <Input />
            </Field>
            <Field name="syslog.severity">
              <Select tetherOptions={tetherOptions} />
            </Field>
            <Field name="syslog.facility">
              <Select tetherOptions={tetherOptions} />
            </Field>
            <div className="pt-callout pt-intent-primary">
              <h5>Syslog Security Information</h5>
              <ul>
                <li>
                  You will receive syslog entries from:{' '}
                  {$dictionary.dictionary.general_router_settings.router_snmp_polling_ips}
                </li>
              </ul>
              <p>
                See the{' '}
                <a
                  href="https://kb.kentik.com/?Gb03.htm#Gb03-Syslog_Notification_Settings"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Kentik Knowledge Base
                </a>{' '}
                for full documentation on the JSON format.
              </p>
            </div>
          </div>
        )}
        {channelType === 'opsgenie' && (
          <div>
            <Field name="opsgenie.routing_key" rules="required">
              <Input />
            </Field>
            <Field name="opsgenie.apiUrl" rules="url">
              <Input />
            </Field>
          </div>
        )}
        {channelType === 'pagerduty' && (
          <Field name="notification_value" label="PagerDuty 32 Character Integration Key">
            <Input />
          </Field>
        )}
        {channelType === 'servicenow' && (
          <div>
            {!form.model.isNew && <p>Leave username and password fields blank if you do not wish to change them.</p>}
            <Field name="servicenow.instance">
              <Input />
            </Field>
            <Field name="servicenow.user">
              <Input />
            </Field>
            <Field name="servicenow.password">
              <Input />
            </Field>
          </div>
        )}
      </div>
    );
  }
}

export default NotificationForm;
