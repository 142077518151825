import React from 'react';
import Transition from 'react-transition-group/Transition';

const defaultStyle = {
  transition: 'max-width 300ms ease, min-width 300ms ease',
  maxWidth: 0,
  minWidth: 0,
  width: 0,
  opacity: 1
};

const transitionStyles = {
  entering: { width: 'auto' },
  entered: { minWidth: 275, maxWidth: 275 },

  exiting: { width: 'auto' },
  exited: { minWidth: 50, maxWidth: 50 }
};

const defaultProps = {
  timeout: 0
};

const SidebarCollapse = ({ in: inProp, children, ...rest }) => (
  <Transition in={inProp} {...defaultProps} {...rest}>
    {state => {
      const styles = {
        ...defaultStyle,
        ...transitionStyles[state]
      };

      return children({
        state,
        styles
      });
    }}
  </Transition>
);

const buttonStyles = {
  entering: { left: 0 },
  entered: { position: 'absolute', transform: 'rotate(180deg)', opacity: 1, left: 50 },

  // exiting: { opacity: 1, width: 275 },
  exited: { transform: 'rotate(0deg)', position: 'relative' }
};

export const MoveCollapseButton = ({ when, children, ...rest }) => (
  <Transition in={when} {...defaultProps} {...rest}>
    {state => {
      const styles = {
        ...defaultStyle,
        ...buttonStyles[state]
      };

      return children({
        state,
        styles
      });
    }}
  </Transition>
);

export default SidebarCollapse;
