import React, { Component } from 'react';
import { any } from 'prop-types';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';

import Label from './Label';
import ValidationErrorsOrHelpText from './ValidationErrorsOrHelpText';

@observer
class Field extends Component {
  componentWillMount() {
    const { form } = this.context;
    const { name, field } = this.props;

    this.field = field || form.getField(name);
    this.updateFieldConfig(this.props);
  }

  componentDidUpdate(prevProps) {
    const { form } = this.context;
    if (this.props.name !== prevProps.name) {
      this.field = this.props.field || form.getField(this.props.name);
    }
    this.updateFieldConfig(this.props);
  }

  /**
   *
   * Field doesn't (and shouldn't) need to trigger a re-render. The underlying Input components
   * are handling the component rendering. Unless ...
   * 1. Validation rules have been changed
   * 2. Validation state changes
   * 3. className changes
   */
  shouldComponentUpdate(nextProps) {
    const hasRulesChanged = nextProps.rules !== this.props.rules;
    const hasClassNameChanged = nextProps.className !== this.props.className;
    const hasOptionsChanged = nextProps.options !== this.props.options;
    const hasValidateOptionsChanged = nextProps.validateOptions !== this.props.validateOptions;
    const hasLabelChanged = nextProps.label !== this.props.label;
    const isInvalid = !this.field.valid;

    return (
      !nextProps.transferProps ||
      hasRulesChanged ||
      isInvalid ||
      hasClassNameChanged ||
      hasOptionsChanged ||
      hasValidateOptionsChanged ||
      hasLabelChanged
    );
  }

  @action
  updateFieldConfig(nextProps) {
    const { form } = this.context;
    const { rules, placeholder, options, label, validateOptions, disabled } = nextProps;
    if (rules !== undefined) {
      form.setRules(this.field, rules);
    }

    if (placeholder) {
      this.field.placeholder = placeholder;
    }

    if (options !== undefined) {
      this.field.options = options;
    }

    if (validateOptions !== undefined) {
      this.field.validateOptions = validateOptions;
    }

    if (label) {
      this.field.label = label;
    }

    if (disabled !== undefined) {
      this.field.disabled = disabled;
    }
  }

  render() {
    const {
      autoFocus,
      className,
      children,
      fieldStyle = { width: '100% ' },
      flex,
      isEditing,
      helpText,
      helpTextStyle,
      hidden,
      inputStyle,
      inputGroupClassName,
      labelAlign = 'top',
      labelStyle,
      options,
      onChange,
      showLabel = true,
      showHelpText = true,
      toggleEditing,
      transferProps = true,
      width,
      placeholder
    } = this.props;

    const onChangeWrapper = e => {
      const previousValue = this.field.getValue();
      this.field.onChange(e);

      if (onChange) {
        onChange(this.field, this.field.value, previousValue);
      }
    };

    const labelInline = labelAlign !== 'top';
    const { permissions, model } = this.context.form;
    const isNew = model ? model.isNew : true;
    const fieldType = children.type && children.type.displayName;

    let { disabled = this.field.disabled, readOnly = this.field.readOnly } = this.props;
    let isSummary = false;

    if (isNew) {
      disabled = disabled || !permissions.create;
      readOnly = readOnly || !permissions.create;
    } else {
      disabled = disabled || !permissions.edit;
      readOnly = readOnly || !permissions.edit;
      isSummary = ['Input', 'Textarea'].includes(fieldType);
    }

    const showError = this.field.hasError && (this.field.showPristineErrors || !this.field.pristine);
    const shouldShowHelpText = showHelpText !== false && this.field.showHelpText !== false;
    const fieldPlaceholder = this.field.placeholder || placeholder;

    const formGroupClass = classNames(className, {
      'pt-disabled': disabled || this.field.disabled,
      'pt-form-group': true,
      'pt-inline': labelInline,
      'pt-intent-danger': showError
    });

    const inputGroupClass = classNames(
      {
        'pt-disabled': disabled || this.field.disabled,
        'pt-input-group': true,
        'pt-intent-danger': showError
      },
      inputGroupClassName
    );

    const style = { width, flex, display: hidden ? 'none' : undefined };

    const showField = permissions.edit || (!permissions.edit && !isSummary) || isNew;
    const showSummary = !permissions.edit && isSummary;
    let summaryValue = this.field.getValue() || 'None';
    summaryValue = this.field.getValue() === 0 ? 0 : summaryValue;

    // allow more generic usage of `Field`
    if (typeof this.props.children === 'function') {
      return children({
        field: this.field,
        onChange: onChangeWrapper,
        value: this.field.value,
        showError,
        valid: !showError,
        disabled
      });
    }

    return (
      <Box className={formGroupClass} style={{ ...this.props.style, ...style }}>
        {showLabel && (
          <Flex align="center">
            {showLabel &&
              this.field.label && (
                <Label field={this.field} showError={showError} labelAlign={labelAlign} style={labelStyle} />
              )}
          </Flex>
        )}
        <div className="pt-form-content" style={fieldStyle}>
          {showField && (
            <div className={transferProps ? inputGroupClass : undefined}>
              {transferProps &&
                React.cloneElement(children, {
                  autoFocus,
                  disabled,
                  isEditing,
                  field: this.field,
                  onChange: onChangeWrapper,
                  onEditComplete: toggleEditing,
                  options,
                  placeholder: fieldPlaceholder,
                  readOnly,
                  style: inputStyle
                })}

              {!transferProps && React.cloneElement(children)}
              {shouldShowHelpText && (
                <ValidationErrorsOrHelpText field={this.field} helpText={helpText} style={helpTextStyle} />
              )}
            </div>
          )}
          {showSummary && <div>{summaryValue}</div>}
        </div>
      </Box>
    );
  }
}

Field.contextTypes = {
  form: any
};

export default Field;
