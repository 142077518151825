import React, { Fragment } from 'react';
import { MenuItem, MenuDivider } from '@blueprintjs/core';

const SupportSubMenu = ({ supportURL, supportEmail }) => {
  if (!supportURL || !supportEmail) {
    return null;
  }

  return (
    <Fragment>
      <MenuDivider />
      {supportURL && (
        <MenuItem iconName="send-to" text="Visit Support Page" onClick={() => window.open(supportURL, '_blank')} />
      )}
      {supportEmail && (
        <MenuItem iconName="envelope" text="Send Support Email" onClick={() => window.open(`mailto:${supportEmail}`)} />
      )}
    </Fragment>
  );
};

export default SupportSubMenu;
