import React from 'react';
import { observer } from 'mobx-react';
import { Button, Popover, Position, PopoverInteractionKind, Tag } from '@blueprintjs/core';
import { Box, Flex } from 'components/flexbox';
import { ALERT_SEVERITY_DISPLAY, ALERT_SEVERITY_INTENT } from 'models/alerting/Scoreboard';
import classNames from 'classnames';
import { MaterialIcon } from 'components/Icon';

const buttonStyles = {
  minWidth: 65,
  fontSize: 18
};

const mitigationTagStyles = {
  ...buttonStyles,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 30
};

const tetherOptions = {
  offset: '-5px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

const ScoreboardCell = ({ data, row, isMitigation, onCellSelect, onShowCellAlarms }) => {
  const popoverContent = !isMitigation && (
    <Box p={2}>
      <Flex align="center">
        {data.counts.map(count => {
          const tagClassName = classNames('pt-large', ALERT_SEVERITY_INTENT[count.type]);
          return (
            count.value !== 0 && (
              <Flex key={count.type} flexColumn align="center" justify="center" mr={2}>
                <Tag className={tagClassName}>
                  <strong style={{ fontSize: 16 }}>{count.value}</strong>
                  <br />
                  <small className="small-caps">{ALERT_SEVERITY_DISPLAY[count.type]}</small>
                </Tag>
              </Flex>
            )
          );
        })}
        <Box>
          <strong>{data.name}</strong>
          <br />
          <small className="pt-text-muted">{row.policy_name}</small>
        </Box>
      </Flex>

      <Flex justify="flex-end" mt={1}>
        <Button className="pt-small" iconName="search" onClick={() => onShowCellAlarms(data, row)}>
          Show Alarms
        </Button>
      </Flex>
    </Box>
  );

  let cell;

  if (isMitigation) {
    const isMitigating = data.counts.Mitigation.mitigating > 0;

    cell = isMitigating ? (
      <Tag style={mitigationTagStyles} className="pt-intent-purple">
        <MaterialIcon name="security" />
      </Tag>
    ) : (
      <Button
        style={buttonStyles}
        className={classNames('pt-strong scoreboard-cell-button', {
          'scoreboard-cell-threshold-ack_req': data.counts.Mitigation.ack_req > 0,
          'pt-intent-purple ': data.counts.Mitigation.mitigating > 0
        })}
        text={data.counts.Mitigation.ack_req}
      />
    );
  } else {
    cell = (
      <Button
        iconName={data.exceeded === false ? 'tick' : null}
        style={buttonStyles}
        text={data.exceeded ? data.value : null}
        className={classNames('pt-strong scoreboard-cell-button', {
          [`scoreboard-cell-threshold-${data.type}`]: data.exceeded,
          'pt-intent-success-text': !data.exceeded
        })}
      />
    );
  }

  return (
    <Flex
      className="td"
      align="center"
      justify="center"
      onClick={onCellSelect ? () => onCellSelect(data, row) : null}
      style={{ minWidth: 65, maxWidth: 400, flex: 1 }}
    >
      <Popover
        content={popoverContent}
        isDisabled={isMitigation || !data.exceeded}
        popoverClassName="pt-minimal"
        position={Position.BOTTOM_LEFT}
        tetherOptions={tetherOptions}
        interactionKind={PopoverInteractionKind.CLICK}
        style={{ width: '100%' }}
      >
        {cell}
      </Popover>
    </Flex>
  );
};

export default observer(ScoreboardCell);
