import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Button, InputGroup, Intent } from '@blueprintjs/core';
import { pick } from 'lodash';

import { Box, Flex } from 'components/flexbox';
import { Page, PageBody } from 'components/page';
import { showErrorToast } from 'components/Toast';
import store from 'stores/store';

@inject('$auth')
@observer
class VerifyTwoFactor extends Component {
  state = { redirectToReferrer: false, token: '', validatingToken: false };

  handleKeyboardSubmit = e => {
    const { token, validatingToken } = this.state;
    if (e && e.key === 'Enter') {
      e.stopPropagation();
      if (token && !validatingToken) {
        this.handleSubmit();
      }
    }
  };

  handleSubmit = async param => {
    const { $auth } = this.props;
    this.setState({ validatingToken: true });

    const verifyParams = pick(this.state, ['token']);
    if ($auth.activeUser.allowTotpSkip && param && typeof param === 'object' && param.skipValidation) {
      verifyParams.force = true;
    }
    // Make call to verify 2fa token.
    const twoFactorRequired = await $auth.verifyTotp(verifyParams);

    // FINALLY REPORTED by Dan on 10/10/2017. Leaving here for posterity's sake :)
    // if (!$auth.isDan || Math.random() < 0.5) {
    //   twoFactorRequired = await $auth.verifyTotp(verifyParams);
    // }

    if (twoFactorRequired) {
      if ($auth.twoFactorDelay) {
        showErrorToast(`Too many failed tries; please wait ${$auth.twoFactorDelay} seconds before retrying`);
      } else {
        showErrorToast('Invalid Token');
      }
      this.setState({ redirectToReferrer: false, token: '', validatingToken: false });
    } else {
      await store.initializeApp();
      this.setState({ redirectToReferrer: true });
    }
  };

  skipValidationClickHandler = this.handleSubmit.bind(this, { skipValidation: true });

  handleTokenChange = e => this.setState({ token: e.target.value });

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer, token, validatingToken } = this.state;
    const { $auth } = this.props;
    const { twoFactorDelay } = $auth;

    if (redirectToReferrer) {
      return (
        <Redirect
          to={{
            pathname: from.pathname,
            state: { verified2fa: true }
          }}
        />
      );
    }

    const sectionStyle = { minWidth: 400 };

    return (
      <Page>
        <PageBody>
          <Flex flexColumn align="center" col={12} p={4}>
            <Box col={4} pb={1} style={sectionStyle}>
              <h4>Two-factor Authentication</h4>
              <p className="pt-text-muted">
                Please enter the one-time token from your authentication app or physical key. If you are having issues
                signing in, please contact your administrator.
              </p>
            </Box>
            {!!twoFactorDelay && (
              <Box className="pt-callout pt-intent-danger" col={4} mb={2} p={1} pb={0} style={sectionStyle}>
                <p>You have tried an invalid token too many times. Please wait a few moments to try again.</p>
              </Box>
            )}
            <Box col={4} pb={2} style={sectionStyle}>
              <InputGroup
                onKeyPress={this.handleKeyboardSubmit}
                autoFocus
                disabled={!!twoFactorDelay}
                className="pt-large"
                leftIconName="lock"
                onChange={this.handleTokenChange}
                placeholder="One-Time Token"
                value={token}
              />
            </Box>
            <Flex col={4} justify="space-between" style={sectionStyle}>
              <Button
                className="pt-large"
                disabled={validatingToken}
                onClick={store.logout}
                style={{ marginRight: 8 }}
                text="Cancel"
              />

              <Box>
                {$auth.activeUser.allowTotpSkip && (
                  <Button
                    className="pt-large"
                    text="Skip"
                    style={{ marginRight: 8 }}
                    onClick={this.skipValidationClickHandler}
                  />
                )}

                <Button
                  className="pt-large"
                  disabled={!token || !!twoFactorDelay}
                  intent={Intent.PRIMARY}
                  loading={validatingToken}
                  onClick={this.handleSubmit}
                  text="Verify"
                />
              </Box>
            </Flex>
          </Flex>
        </PageBody>
      </Page>
    );
  }
}

export default withRouter(VerifyTwoFactor);
