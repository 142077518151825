import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Form, Field, Textarea } from 'components/forms';
import { Button, Dialog, Intent } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { getAllFormsPayload } from 'components/AbstractErrorBoundary';

const fields = {
  summary: {
    label: 'Summary',
    placeholder: 'i.e. Browser froze when opening chart',
    rules: 'required'
  },
  description: {
    label: 'Additional Details',
    placeholder: '...'
  }
};

const options = {
  name: 'Report Issue'
};

@Form({ fields, options })
@inject('$app')
@observer
class ReportIssueDialog extends Component {
  handleSubmit = () => {
    const { $app, form, location } = this.props;
    const { platform, userAgent } = window.navigator;
    const { pathname } = location;
    const { clientWidth, clientHeight } = document.querySelector('body');

    const payload = {
      clientHeight,
      clientWidth,
      info: {
        ...form.getValues(),
        allForms: getAllFormsPayload(form)
      },
      pathname,
      platform,
      userAgent
    };

    $app.submitReportIssue(payload).then(() => form.reset());
  };

  handleClose = () => {
    const { form, onClose } = this.props;
    form.reset();
    onClose();
  };

  render() {
    const { form, $app, isOpen } = this.props;
    const { sendingIssueRequest } = $app;

    return (
      <Dialog
        isOpen={isOpen}
        title="Report an Issue"
        onClose={this.handleClose}
        canEscapeKeyClose
        transitionName="pt-dialog"
      >
        <div className="pt-dialog-body">
          <Field name="summary" autoFocus disabled={sendingIssueRequest}>
            <Textarea rows={2} />
          </Field>
          <Field name="description" disabled={sendingIssueRequest}>
            <Textarea rows={6} />
          </Field>
        </div>
        <Flex justify="flex-end" className="pt-dialog-footer">
          <Button
            text="Cancel"
            onClick={this.props.onClose}
            disabled={$app.sendingIssueRequest}
            style={{ width: 110 }}
          />
          <Button
            className="pt-medium"
            disabled={form.pristine || !form.valid}
            intent={Intent.PRIMARY}
            onClick={this.handleSubmit}
            loading={$app.sendingIssueRequest}
            style={{ marginLeft: 8 }}
            text="Submit Report"
          />
        </Flex>
      </Dialog>
    );
  }
}

export default withRouter(ReportIssueDialog);
