import React, { Component, Children } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Box, Flex } from 'components/flexbox';

class TabPanel extends Component {
  static defaultProps = {
    vertical: false
  };

  state = {
    activeTabId: null
  };

  componentWillMount() {
    const { activeTabId } = this.props;
    if (activeTabId) {
      this.setState({ activeTabId });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.activeTabId && nextProps.activeTabId !== this.props.activeTabId) {
      this.setState({ activeTabId: nextProps.activeTabId });
    }
  }

  handleTabClick = tabId => {
    const { onTabChange } = this.props;

    this.setState({ activeTabId: tabId });

    if (onTabChange) {
      onTabChange(tabId);
    }
  };

  renderTabBar() {
    const { vertical, tools } = this.props;

    const tabList = (
      <Flex flexColumn={vertical} className={classNames({ 'pt-tab-list': !tools })}>
        {this.renderTabs()}
      </Flex>
    );

    if (tools) {
      return (
        <Flex justify="space-between" align="flex-start" className="pt-tab-list">
          {tabList}
          <Box flexAuto mr={1}>
            {tools}
          </Box>
        </Flex>
      );
    }

    return tabList;
  }

  renderTabs() {
    const { children } = this.props;
    const { activeTabId } = this.state;

    return Children.map(children, child => {
      if (child) {
        const { tabId, tab } = child.props;

        return (
          <div
            key={tabId}
            className="pt-tab"
            onClick={() => this.handleTabClick(tabId)}
            tabIndex="0"
            aria-selected={activeTabId === tabId}
          >
            {tab}
          </div>
        );
      }
      return null;
    });
  }

  renderChildren() {
    const { children } = this.props;
    const { activeTabId } = this.state;

    return Children.map(children, child => {
      if (child && activeTabId === child.props.tabId) {
        return child;
      }
      return null;
    });
  }

  render() {
    const { vertical, flexAuto } = this.props;
    const className = classNames('pt-tabs', { 'pt-vertical': vertical }, this.props.className);
    return (
      <Flex flexColumn={!vertical} flexAuto={flexAuto} className={className}>
        {this.renderTabBar()}
        {this.renderChildren()}
      </Flex>
    );
  }
}

export default observer(TabPanel);
