import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { KeyCombo } from '@blueprintjs/core';

import { Banner } from 'components';
import { Box } from 'components/flexbox';
import InterfaceClassDependency from 'dataviews/dependencies/InterfaceClassDependency';
import NetworkClassDependency from 'dataviews/dependencies/NetworkClassDependency';
import ProviderClassDependency from 'dataviews/dependencies/ProviderClassDependency';
import SnmpDependency from 'dataviews/dependencies/SnmpDepdendency';
import SiteCountryDependency from 'dataviews/dependencies/SiteCountryDependency';
import SiteDependency from 'dataviews/dependencies/SiteDependency';
import GCEDependency from 'dataviews/dependencies/GCEDependency';
import AWSDependency from 'dataviews/dependencies/AWSDependency';
import CloudDependency from 'dataviews/dependencies/CloudDependency';

@inject('$app')
@observer
export default class UnappliedChanges extends Component {
  static defaultProps = {
    buttonText: 'Run Query'
  };

  dependencies = [];

  componentWillMount() {
    this.setupDependencies();
  }

  componentDidUpdate() {
    this.setupDependencies();
  }

  setupDependencies() {
    const { dataview, dataviews, form, $app } = this.props;
    const dataviewParam = dataview ? [dataview] : dataviews;
    const showConfigureButton = !$app.isSubtenant;

    if (!$app.isSubtenant) {
      this.dependencies = [
        new InterfaceClassDependency(dataviewParam, form, showConfigureButton),
        new NetworkClassDependency(dataviewParam, form, showConfigureButton),
        new ProviderClassDependency(dataviewParam, form, showConfigureButton),
        new SnmpDependency(dataviewParam, form, showConfigureButton),
        new SiteDependency(dataviewParam, form, showConfigureButton),
        new SiteCountryDependency(dataviewParam, form, showConfigureButton),
        new GCEDependency(dataviewParam, form, showConfigureButton),
        new AWSDependency(dataviewParam, form, showConfigureButton),
        new CloudDependency(dataviewParam, form, showConfigureButton)
      ];
    }
  }

  get isDirty() {
    const { form } = this.props;
    return form && form.dirty;
  }

  get hasErrors() {
    const { form } = this.props;
    return form && form.errors.length > 0;
  }

  get dependencyCheckFailures() {
    return this.dependencies.filter(dep => dep.requires && !dep.validate());
  }

  get hadDependencyCheckFailures() {
    return this.dependencies.some(dep => dep.queryRequires);
  }

  render() {
    const { form, buttonText } = this.props;

    const dependencyFailures = this.dependencyCheckFailures;

    if (!this.isDirty && dependencyFailures.length === 0) {
      return null;
    }

    let title = 'You have unapplied changes.';
    let intent;
    let icon;
    let description = (
      <span>
        Click <strong>{buttonText}</strong> or press <KeyCombo combo="shift+enter" /> to apply.
      </span>
    );

    if (this.hasErrors) {
      title = 'You have unapplied changes, but there are errors.';
      intent = 'pt-intent-danger';
      icon = 'pt-icon-error';
      description = (
        <span>
          Please check your{' '}
          {Object.keys(Object.assign(form.invalidGroups, form.incompleteGroups)).map((section, index, arr) => (
            <span key={section}>
              {index > 1 || (index === 1 && index < arr.length - 1) ? ', ' : ''}
              {index > 0 && index === arr.length - 1 ? ' and ' : ''}
              <strong style={{ textTransform: 'capitalize' }}>{section}</strong>
            </span>
          ))}{' '}
          Options.
        </span>
      );
    } else if (dependencyFailures.length > 0) {
      const [firstFailure] = dependencyFailures;
      title = this.isDirty
        ? `You have unapplied changes, but there are ${
            this.hadDependencyCheckFailures ? 'still ' : ''
          }dependency check failures.`
        : firstFailure.title;
      intent = firstFailure.intent;
      icon = firstFailure.icon;
      description = (
        <Fragment>
          {dependencyFailures.map(failure => (
            <Box key={failure.key} className="pt-callout" mt={1} ml={-4} mr={-1}>
              {failure.message}
            </Box>
          ))}
          {this.isDirty && (
            <Box mt={1} ml={-4} mr={-1}>
              However, you may run this query anyway. {description}
            </Box>
          )}
        </Fragment>
      );
    }

    return <Banner boxProps={this.props} title={title} intent={intent} icon={icon} description={description} />;
  }
}
