import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Dialog, Button } from '@blueprintjs/core';
import { parseQueryString, createQueryStringFromObject } from 'util/utils';
import { Flex, Box } from 'components/flexbox';
import { Page, PageHeader, PageBody } from 'components/page';
import { Filter } from 'components/table';
import { ToggleButtonGroup, ValueButton } from 'components';
import { ExportCsvButton } from 'components/ExportCsvButton';

import HistoryFilters from './HistoryFilters';
import AlarmsChart from './AlarmsChart';
import AlertSummaryBox from './AlertSummaryBox';

import AlertTable from './Table/AlertTable';
import AlertKeyDebug from './AlertDebug/AlertKeyDebug/AlertKeyDebug';
import AlertDebugByTime from './AlertDebug/DebugByTime/AlertDebugByTime';
import AlarmCommentDialog from './AlarmCommentDialog';

import AlertPoliciesScoreboard from './AlertPoliciesScoreboard/AlertPoliciesScoreboard';
import ManualMitigationDialogForm from '../Mitigations/ManualMitigationDialogForm';

import { getQueryVariable } from './alarmHelpers';
import TenantNotificationsDialog from '../Notifications/TenantNotificationsDialog';

const columns = [
  { name: 'policy_name', label: 'Policy' },
  { name: 'alert_severity', label: 'Severity', renderer: ({ value }) => (value ? value.toUpperCase() : value) },
  { name: 'old_alarm_state', label: 'Previous State' },
  { name: 'new_alarm_state', label: 'New State' },
  { name: 'alert_key_lookup', label: 'Alert Key Lookup' },
  { name: 'alert_dimension', label: 'Alert Dimension' },
  { name: 'alert_value', label: 'Value' },
  { name: 'alert_value2nd', label: 'Value 2' },
  { name: 'alert_value3rd', label: 'Value 3' },
  { name: 'alert_baseline', label: 'Baseline' },
  { name: 'alarm_id', label: 'Alarm ID' },
  { name: 'mitigation_id', label: 'Mitigation ID' },
  { name: 'ctime', label: 'Created Time' }
];

@inject('$app', '$alerts', '$alertingMitigation', '$auth')
@observer
class AlertHistory extends Component {
  static defaultProps = {
    isAlarmsPage: true
  };

  state = {
    showDebugDialog: false,
    showCommentDialog: false,
    showDebugByTimeDialog: false,
    refreshInterval: undefined,
    showTenantNotificationsDialog: false
  };

  componentWillMount() {
    const { $alerts, location, isAlarmsPage } = this.props;

    const loadFilters = getQueryVariable({ location, param: 'loadFilters' });
    if (!isAlarmsPage && loadFilters === 'u' && location && location.search && location.search.substring(1)) {
      const urlFilters = parseQueryString(location.search.substring(1));
      $alerts.setFilterValues(urlFilters);
    }

    $alerts.fetch({ isAlarms: isAlarmsPage, loadFilters: loadFilters === '1' || loadFilters === 'u' });
  }

  componentDidMount() {
    const { isAlarmsPage } = this.props;
    if (isAlarmsPage) {
      this.startTimer(this.state.refreshInterval);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { refreshInterval } = nextState;
    const { isAlarmsPage } = nextProps;
    if (isAlarmsPage && refreshInterval !== this.state.refreshInterval) {
      this.startTimer(refreshInterval);
    }
  }

  componentWillUnmount() {
    this.clearTimer();
    this.props.$alerts.clearAlerts();
  }

  startTimer(interval) {
    this.clearTimer();

    if (interval) {
      this.timerID = setTimeout(() => {
        this.updateAlarms().then(() => this.startTimer(interval));
      }, interval);
    }
  }

  clearTimer() {
    if (this.timerID) {
      clearTimeout(this.timerID);
    }
  }

  updateAlarms = () => {
    const { $alerts } = this.props;
    $alerts.setFilterValue('endTime', new Date());
    return $alerts.fetch({ isAlarms: true, policyOptions: { preserveSelection: true } });
  };

  toggleDebugDialog = () => {
    const { showDebugDialog } = this.state;
    this.setState({ showDebugDialog: !showDebugDialog });
  };

  toggleCommentDialog = () => {
    const { showCommentDialog } = this.state;
    this.setState({ showCommentDialog: !showCommentDialog });
  };

  toggleDebugByTimeDialog = () => {
    const { showDebugByTimeDialog } = this.state;
    this.setState({ showDebugByTimeDialog: !showDebugByTimeDialog });
  };

  handleToggleManualMitigationDialog = () => {
    window.open('/v4/protect/mitigations', '_blank');
  };

  onRefreshIntervalButtonClick = refreshInterval => {
    const { refreshInterval: currentRefreshInterval } = this.state;
    if (refreshInterval !== currentRefreshInterval) {
      this.setState(() => ({ refreshInterval }));
    }
  };

  openDebug = model => {
    const { alert_key, alert_id, alert_key_lookup, policy_name, alert_dimension, ctime, alarm_start } = model.get();
    this.props.$alerts.setDebugFields({
      alert_key,
      alert_id,
      alert_key_lookup,
      policy_name,
      alert_dimension,
      ctime: ctime ? new Date(ctime) : new Date(alarm_start)
    });
    this.setState({
      showDebugDialog: true
    });
  };

  openDebugByTime = model => {
    const { alert_id, policy_name, ctime } = model.get();
    this.props.$alerts.fetchDebugDataByTime({
      alert_id,
      policy_name,
      ctime: new Date(ctime)
    });
    this.setState({
      showDebugByTimeDialog: true
    });
  };

  applyFilters = filterValues => {
    const { isAlarmsPage, $alerts, history } = this.props;
    // make sure dialogs are not open
    this.closeDialogs();

    $alerts.setFilterValues(filterValues);

    if (isAlarmsPage) {
      history.push('/alerting/history?loadFilters=1');
    } else {
      history.push(`/alerting/history?loadFilters=u&${createQueryStringFromObject(filterValues)}`);
      $alerts.fetch({ loadFilters: true });
    }
  };

  closeDialogs = () => {
    this.setState({
      showDebugDialog: false,
      showCommentDialog: false,
      showDebugByTimeDialog: false
    });
  };

  clearSelectedAlerts = () => {
    const { $alerts } = this.props;
    const { selected } = $alerts.alerts;
    if (selected.length > 0) {
      this.setState({
        showCommentDialog: true
      });
    }
  };

  handleCommentSubmit = options => {
    const { comment } = options;
    const { isAlarmsPage, $alerts } = this.props;
    $alerts
      .clearAlarms({
        comment
      })
      .then(() => {
        $alerts.fetch({ isAlarms: isAlarmsPage });
        this.setState({
          showCommentDialog: false
        });
      });
  };

  openPolicyEdit = model => {
    const { alert_id } = model.get();
    const url = `/v4/protect/policies/edit/${alert_id}`;
    window.open(url, '_blank');
  };

  viewQuery = (model, dest) => {
    const { $alerts } = this.props;
    $alerts.loadViewByAlarm(model, dest, true);
  };

  handleMitigation = (model, action) => {
    const { $alerts, isAlarmsPage } = this.props;
    const { mit_alert_id, mit_alert_ip, mit_threshold_id, mitigation_id, alarm_state } = model.get();
    const data = {
      mitigation_id,
      alert_id: mit_alert_id,
      threshold_id: mit_threshold_id,
      alarm_state,
      action,
      alert_key: mit_alert_ip
    };
    $alerts.mitigate(data).then(() => $alerts.fetch({ isAlarms: isAlarmsPage }));
  };

  onManageTenantNotificationsClick = () => {
    this.setState(() => ({
      showTenantNotificationsDialog: true
    }));
  };

  onTenantNotificationsDialogClose = () => {
    this.setState(() => ({
      showTenantNotificationsDialog: false
    }));
  };

  render() {
    const { $app, $auth, $alerts, $alertingMitigation, isAlarmsPage } = this.props;

    const {
      showDebugDialog,
      showCommentDialog,
      showDebugByTimeDialog,
      refreshInterval,
      showTenantNotificationsDialog
    } = this.state;

    const {
      fetchingAlerts,
      chart,
      alerts,
      alertHistory,
      alertCompanySettings,
      filterValues,
      summary,
      activeAlarmClassName,
      debugFields
    } = $alerts;

    const title = isAlarmsPage ? 'Active Alerts' : 'Alert History';
    const alertsCollection = isAlarmsPage ? alerts : alertHistory;

    const { manualMitigations } = $alertingMitigation;

    const selectedAlarms =
      alertsCollection.selected && alertsCollection.selected.filter(alert => !alert.isActiveMitigation);
    const numSelected = selectedAlarms && selectedAlarms.length;

    const debugDialogTitle = debugFields.policy_name
      ? `${debugFields.policy_name} (${debugFields.alert_id}) : Debug`
      : `Policy ID ${debugFields.alert_id} : Debug`;

    return (
      <Page p={0} flexColumn disableScroll title={title}>
        {!$app.isSubtenant && (
          <PageHeader
            parents={[
              {
                text: 'Alerting',
                href: '/alerting/alerts'
              }
            ]}
            title={isAlarmsPage ? 'Active Alerts' : 'Alert History'}
            helpTopic={
              isAlarmsPage ? 'Active Alerts Page UI Scoreboard List' : 'History Filter Alert History List About History'
            }
          />
        )}
        <PageBody bodyStyle={{ paddingTop: 16, minHeight: '100%' }} scrollable>
          {!isAlarmsPage && (
            <HistoryFilters fetchingAlerts={fetchingAlerts} values={filterValues} onSubmit={this.applyFilters} />
          )}

          {isAlarmsPage && (
            <Flex col={12} mb={2}>
              <AlertSummaryBox
                loading={summary.isRequestActive('fetching')}
                type="mitigations"
                value={summary.get('mit_count')}
                text="Mitigations"
                onStartManualMitigation={this.handleToggleManualMitigationDialog}
                showManualMitigationButton={$auth.hasPermission('alerts.mitigations.create')}
              />
              <AlertSummaryBox
                loading={summary.isRequestActive('fetching')}
                type="alarms"
                value={summary.get('alarm_count')}
                counts={summary}
                text="Alarms"
                activeAlarmClassName={activeAlarmClassName}
                mx={2}
              />
              <AlertSummaryBox
                loading={summary.isRequestActive('fetching')}
                type="ack_req"
                counts={summary}
                value={summary.get('ack_req_count')}
                text="ACK Required"
              />
            </Flex>
          )}

          {isAlarmsPage && <AlertPoliciesScoreboard alertCompanySettings={alertCompanySettings} />}

          {!isAlarmsPage && (
            <Flex flexColumn mb={2} col={12} className="pt-card flat">
              <Box p={2}>
                <h6 className="no-margin pt-text-muted">Alert Activity </h6>
              </Box>
              <AlarmsChart collection={chart} />
            </Flex>
          )}

          <Flex justify="space-between" align="center" mb={2}>
            <Flex>
              <Filter
                style={{ minWidth: 275 }}
                collection={alertsCollection}
                placeholder="Search Alerts..."
                autoFocus
              />

              {numSelected > 0 &&
                $auth.hasPermission('alerts.alarms.clear') && (
                  <Box ml={2}>
                    <Button onClick={this.clearSelectedAlerts}>
                      Clear <strong>{numSelected}</strong> {numSelected > 1 ? 'Alerts' : 'Alert'}
                    </Button>
                  </Box>
                )}
            </Flex>

            {isAlarmsPage && (
              <Flex align="center">
                <span className="pt-text-muted" style={{ marginRight: 8 }}>
                  Auto-Refresh:{' '}
                </span>
                <ToggleButtonGroup selectedValue={refreshInterval}>
                  <ValueButton text="None" value={undefined} onClick={this.onRefreshIntervalButtonClick} />
                  <ValueButton text="30s" value={30 * 1000} onClick={this.onRefreshIntervalButtonClick} />
                  <ValueButton text="1m" value={60 * 1000} onClick={this.onRefreshIntervalButtonClick} />
                  <ValueButton text="2m" value={2 * 60 * 1000} onClick={this.onRefreshIntervalButtonClick} />
                  <ValueButton text="5m" value={5 * 60 * 1000} onClick={this.onRefreshIntervalButtonClick} />
                  <ValueButton text="10m" value={10 * 60 * 1000} onClick={this.onRefreshIntervalButtonClick} />
                </ToggleButtonGroup>
                {$app.isSubtenant && (
                  <Box ml={2}>
                    <Button
                      iconName="cog"
                      text="Manage Notifications"
                      onClick={this.onManageTenantNotificationsClick}
                    />
                  </Box>
                )}
              </Flex>
            )}

            {!isAlarmsPage && (
              <ExportCsvButton
                style={{ marginRight: 0 }}
                fileNameText="kentik-export-alerting-history"
                disabled={!alertsCollection}
                collection={alertsCollection}
                columns={columns}
              />
            )}
          </Flex>

          <Flex className="pt-card flat" flexAuto flexColumn>
            <AlertTable
              collection={alertsCollection}
              isAlarmsPage={isAlarmsPage}
              onFilterFieldClick={this.applyFilters}
              onDebugClick={this.openDebug}
              onDebugByTimeClick={this.openDebugByTime}
              onAlertSelect={this.selectAlert}
              clearSelectedAlerts={this.clearSelectedAlerts}
              onEditPolicy={this.openPolicyEdit}
              onViewQuery={this.viewQuery}
              onMitigation={this.handleMitigation}
              addToSilentMode={$alerts.addToSilentMode}
            />
          </Flex>

          <Dialog
            isOpen={showDebugDialog}
            onClose={this.toggleDebugDialog}
            title={debugDialogTitle}
            style={{ width: '90%', top: 40 }}
            transitionName="pt-dialog"
          >
            <AlertKeyDebug />
          </Dialog>
          <Dialog
            isOpen={showDebugByTimeDialog}
            onClose={this.toggleDebugByTimeDialog}
            title="Positional Details"
            style={{ width: '90%', top: 40 }}
            transitionName="pt-dialog"
          >
            <div className="pt-dialog-body">
              <AlertDebugByTime onFilterFieldClick={this.applyFilters} />
            </div>
          </Dialog>
          <AlarmCommentDialog
            isOpen={showCommentDialog}
            onClose={this.toggleCommentDialog}
            onSubmit={this.handleCommentSubmit}
          />
          {manualMitigations.selected && (
            <ManualMitigationDialogForm
              isOpen={manualMitigations.selected}
              model={manualMitigations.selected}
              collection={manualMitigations}
            />
          )}
          {$app.isSubtenant && (
            <TenantNotificationsDialog
              isOpen={showTenantNotificationsDialog}
              onClose={this.onTenantNotificationsDialogClose}
              userGroupId={$auth.activeUser.userGroup.id}
            />
          )}
        </PageBody>
      </Page>
    );
  }
}

export default withRouter(AlertHistory);
