import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'components/flexbox';
import { Button, Intent, Tag } from '@blueprintjs/core';
import Icon from 'components/Icon';
import { Field, Input, Select } from 'components/forms';
import AzureManualDialog from './AzureManualDialog';
import AzurePowershellDialog from './AzurePowershellDialog';

@inject('$clouds')
@observer
export default class Azure extends Component {
  static contextTypes = {
    form: any
  };

  state = {
    showManualDialog: false,
    showPowershellDialog: false
  };

  componentWillMount() {
    const { $clouds } = this.props;
    if (!$clouds.collection.hasFetched) {
      $clouds.collection.fetch({ preserveSelection: true });
    }
  }

  onShowManualClick = () => this.setState({ showManualDialog: true });

  onShowPowershellClick = () => this.setState({ showPowershellDialog: true });

  onCloseManualDialog = () => this.setState({ showManualDialog: false });

  onClosePowershellDialog = () => this.setState({ showPowershellDialog: false });

  onEnableAzureSubscriptionClick = () => {
    const { form } = this.context;

    const subscriptionId = form.getValue('properties.azure_subscription_id');
    const name = form.getValue('name');
    const description = form.getValue('properties.description');
    const plan_id = form.getValue('plan_id');
    const cloud_provider = form.getValue('cloud_provider');

    const params = {
      properties: {
        azure_subscription_id: subscriptionId,
        description
      },
      name,
      plan_id,
      cloud_provider
    };
    window.location.href = `https://azure.kntk.cloud/authorize?subscription_id=${encodeURIComponent(
      subscriptionId
    )}&redirect=${encodeURIComponent(
      `${window.location.href}/createcloud/params=${JSON.stringify(params)}&success={0}&errorMsg={1}`
    )}`;
  };

  onValidateAccessClick = () => {
    const { form } = this.context;
    const { $clouds } = this.props;
    const data = {
      subscription_id: form.getValue('properties.azure_subscription_id'),
      resource_group: form.getValue('properties.azure_resource_group'),
      location: form.getValue('properties.azure_location'),
      storage_account: form.getValue('properties.azure_storage_account')
    };

    $clouds.checkAzure(data).then(access_check => {
      form.setValue('properties.azure_access_check', access_check);
      form.setValue('enabled', access_check.api_access && access_check.storage_account_access);
    });
  };

  onSubscriptionIdChange = (field, value) => {
    const { $clouds } = this.props;
    const { form } = this.context;

    if (value.length === 36) {
      const hasCloud = $clouds.collection.models.find(
        cloud =>
          cloud.get('cloud_provider') === 'azure' &&
          cloud.get('properties').azure_subscription_id === value &&
          cloud.get('properties').azure_access_check
      );
      form.setValue('properties.azure_security_principal_enabled', !!hasCloud);
    } else if (form.getValue('properties.azure_security_principal_enabled') && (!field.valid || value.length <= 36)) {
      form.setValue('properties.azure_security_principal_enabled', false);
    }
  };

  render() {
    const { form } = this.context;
    const { showManualDialog, showPowershellDialog } = this.state;
    const storageAccountField = form.getField('properties.azure_storage_account');
    const subscriptionIdField = form.getField('properties.azure_subscription_id');
    const canConfigure =
      form.getField('properties.azure_resource_group').valid &&
      form.getField('properties.azure_location').valid &&
      storageAccountField.valid;
    const accessCheck = form.getValue('properties.azure_access_check');
    const securityPrincipalEnabled = form.getValue('properties.azure_security_principal_enabled');

    return (
      <Fragment>
        <Box className="pt-card" p={2}>
          <Flex justify="space-between">
            <h6>Step 1: Authorize Access to Azure</h6>
            {securityPrincipalEnabled && (
              <Box>
                <Tag intent={Intent.SUCCESS} className="pt-minimal">
                  Authorized
                </Tag>
              </Box>
            )}
          </Flex>
          <p className="pt-text-muted">
            Enter the Subscription ID of the Azure instance from which Kentik's NSG Flow Exporter application will
            export flow logs.
          </p>
          <Field
            name="properties.azure_subscription_id"
            key="azure_subscription_id"
            rules={subscriptionIdField.providerRules}
            onChange={this.onSubscriptionIdChange}
          >
            <Input />
          </Field>
          <Flex justify="flex-end">
            <Button
              text="Authorize"
              disabled={!form.getField('properties.azure_subscription_id').valid}
              intent={Intent.PRIMARY}
              onClick={this.onEnableAzureSubscriptionClick}
            />
          </Flex>
        </Box>
        {securityPrincipalEnabled && (
          <Fragment>
            <Box className="pt-card" mt={2} p={2}>
              <Flex justify="space-between">
                <h6>Step 2: Specify Azure Resources</h6>
                {canConfigure && (
                  <Box>
                    <Tag intent={Intent.SUCCESS} className="pt-minimal">
                      Ready to Validate
                    </Tag>
                  </Box>
                )}
              </Flex>
              <p>Flow logs will be generated on Azure resources in the following Resource Group and Location.</p>
              <Field name="properties.azure_resource_group" key="azure_resource_group" rules="required">
                <Input />
              </Field>
              <Field name="properties.azure_location" key="azure_location" rules="required">
                <Select showFilter />
              </Field>
              <Field
                name="properties.azure_storage_account"
                key="azure_storage_account"
                rules={storageAccountField.providerRules}
              >
                <Input />
              </Field>
            </Box>
            <Box className="pt-card" mt={2} p={2}>
              <h6>Step 3: Configure Flow Log Export</h6>
              <p>
                Configure your Azure instance to export flow logs to a Storage Account from the specified Resource Group
                and Location. You can configure manually or with a generated PowerShell script (recommended).
              </p>

              <Flex justify="space-between">
                <Button text="Configure Manually" disabled={!canConfigure} onClick={this.onShowManualClick} />
                <Button
                  text="Configure using PowerShell"
                  disabled={!canConfigure}
                  intent={Intent.PRIMARY}
                  onClick={this.onShowPowershellClick}
                />
              </Flex>
            </Box>
            <Box className="pt-card" mt={2} p={2}>
              <h6>Step 4: Validate Configuration</h6>
              <p>
                Click Validate to validate configuration of flow log export to this Cloud. Validation may take up to an
                hour, but you can click the Add Azure Cloud button after the first two checkmarks appear below. You can
                also skip validation and click Add Azure Cloud immediately. Either way, the dialog will close and the
                Cloud’s status will be “Pending” until Kentik completes registration.
              </p>

              {accessCheck && (
                <Box p={2}>
                  {accessCheck.error_msg && (
                    <Box className="pt-callout pt-intent-danger pt-icon-error" mb={2}>
                      {accessCheck.error_msg}
                    </Box>
                  )}
                  <ol className="pt-list pt-list-unstyled">
                    <li>
                      <Icon
                        name={accessCheck.storage_account_access ? 'tick-circle' : 'dot'}
                        intent={accessCheck.storage_account_access ? Intent.SUCCESS : Intent.NONE}
                        style={{ fontSize: 14, marginRight: 8 }}
                      />
                      Access to storage account
                    </li>
                    <li>
                      <Icon
                        name={accessCheck.api_access ? 'tick-circle' : 'dot'}
                        intent={accessCheck.api_access ? Intent.SUCCESS : Intent.NONE}
                        style={{ fontSize: 14, marginRight: 8 }}
                      />
                      Access to Azure Cloud Metadata
                    </li>
                    <li>
                      <Icon
                        name={accessCheck.flow_found ? 'tick-circle' : 'dot'}
                        intent={accessCheck.flow_found ? Intent.SUCCESS : Intent.NONE}
                        style={{ fontSize: 14, marginRight: 8 }}
                      />
                      Flow Validated
                    </li>
                  </ol>
                </Box>
              )}
              <Flex justify="flex-end">
                <Button
                  text="Validate"
                  disabled={!canConfigure}
                  intent={Intent.PRIMARY}
                  onClick={this.onValidateAccessClick}
                />
              </Flex>
            </Box>
          </Fragment>
        )}

        {showManualDialog && (
          <AzureManualDialog
            resourceGroup={form.getValue('properties.azure_resource_group')}
            location={form.getValue('properties.azure_location')}
            storageAccount={form.getValue('properties.azure_storage_account')}
            isOpen={showManualDialog}
            onClose={this.onCloseManualDialog}
          />
        )}

        {showPowershellDialog && (
          <AzurePowershellDialog
            resourceGroup={form.getValue('properties.azure_resource_group')}
            location={form.getValue('properties.azure_location')}
            storageAccount={form.getValue('properties.azure_storage_account')}
            subscriptionId={form.getValue('properties.azure_subscription_id')}
            isOpen={showPowershellDialog}
            onClose={this.onClosePowershellDialog}
          />
        )}
      </Fragment>
    );
  }
}
