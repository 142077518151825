import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { AutoSizer, Grid, ScrollSync } from 'react-virtualized';
import { Flex, Box } from 'components/flexbox';
import Icon from 'components/Icon';
import { scrollbarSize } from 'util/utils';

const rowHeight = 28;
const columnWidth = 150;

@observer
export default class RawFlowResultsTable extends Component {
  render() {
    const { collection, columns, onSortClick } = this.props;
    if (!collection) {
      return null;
    }
    const results = collection.toJS();

    return (
      <Flex flexAuto className="pt-card flat">
        <Flex flexAuto className="pt-table virtualized query-editor-results">
          <AutoSizer>
            {({ width, height }) => (
              <ScrollSync>
                {({ onScroll, scrollLeft }) => {
                  const colWidth =
                    width - scrollbarSize <= 0 ||
                    columns.length === 0 ||
                    columns.length * columnWidth > width - scrollbarSize
                      ? columnWidth
                      : Math.floor((width - scrollbarSize) / columns.length);

                  return (
                    <div style={{ width, height }}>
                      <Grid
                        columnWidth={colWidth}
                        columnCount={columns.length}
                        height={rowHeight}
                        cellRenderer={({ columnIndex, key, style }) => (
                          <Flex
                            key={key}
                            style={style}
                            align="center"
                            className="th sortable pt-text-overflow-ellipsis pt-strong query-result-cell"
                            p={1}
                            title={columns[columnIndex].label}
                            onClick={() => onSortClick(columns[columnIndex])}
                          >
                            {columns[columnIndex].label}
                            {columns[columnIndex].sorted && (
                              <Icon name={columns[columnIndex].sortDir === 'asc' ? 'chevron-up' : 'chevron-down'} />
                            )}
                            {!columns[columnIndex].sorted && <div style={{ width: 16 }} />}
                          </Flex>
                        )}
                        rowHeight={rowHeight}
                        rowCount={1}
                        scrollLeft={scrollLeft}
                        width={width - scrollbarSize}
                        className="query-result-header"
                        style={{ overflow: 'hidden' }}
                      />
                      <Grid
                        width={width}
                        height={height - rowHeight}
                        columnWidth={colWidth}
                        rowHeight={rowHeight}
                        columnCount={columns.length}
                        onScroll={onScroll}
                        rowCount={results.length}
                        cellRenderer={({ columnIndex, key, rowIndex, style }) => (
                          <Box
                            key={key}
                            style={style}
                            className="pt-text-overflow-ellipsis query-result-cell"
                            p={1}
                            title={results[rowIndex][columns[columnIndex].name]}
                          >
                            {columns[columnIndex].name !== 'ctimestamp' && (
                              <span>{results[rowIndex][columns[columnIndex].name]}</span>
                            )}
                            {columns[columnIndex].name === 'ctimestamp' && (
                              <span>
                                {moment
                                  .utc(results[rowIndex][columns[columnIndex].name] * 1000)
                                  .format('YYYY-MM-DD HH:mm:ss')}
                              </span>
                            )}
                          </Box>
                        )}
                      />
                    </div>
                  );
                }}
              </ScrollSync>
            )}
          </AutoSizer>
        </Flex>
      </Flex>
    );
  }
}
