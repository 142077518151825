import { isoParse } from 'd3-time-format';
import { max } from 'd3-array';
import moment from 'moment';

export function zeroFill(data) {
  const zeroItems = [];
  for (let i = 0; i < data.length - 1; i += 1) {
    const hourDiff = (data[i + 1].date.getTime() - data[i].date.getTime()) / 3600000;
    if (hourDiff > 1) {
      for (let j = 1; j < hourDiff; j += 1) {
        const zeroItem = {
          date: isoParse(data[i].date.getTime() + j * 3600000),
          time_start: isoParse(data[i].date.getTime() - j * 1800000),
          time_end: isoParse(data[i].date.getTime() + j * 1800000),
          alert_value_25: 0,
          alert_value_50: 0,
          alert_value_75: 0,
          alert_value_95: 0,
          alert_value_98: 0,
          alert_value_max: 0,
          alert_value_min: 0,
          alert_position: 0
        };
        zeroItems.push(zeroItem);
      }
    }
  }
  const zeroFilledData = data.concat(zeroItems);
  zeroFilledData.sort((a, b) => {
    // return a.date > b.date ? 1 : b.date > a.date ? -1 : 0;
    let order = 0;
    if (a.date > b.date) {
      order = 1;
    } else if (b.date > a.date) {
      order = -1;
    }
    return order;
  });
  return zeroFilledData;
}

export function formatData(graphsData, start_date, end_date) {
  const filteredGraphsData = {
    alert_activate: [],
    alert_long_set: []
  };

  const startDate = start_date ? new Date(start_date) : null;
  const endDate = end_date ? new Date(end_date) : null;

  if (startDate && endDate) {
    graphsData.alert_activate.forEach(d => {
      d.date = isoParse(d.ctime);
      if (d.date >= startDate && d.date <= endDate) {
        filteredGraphsData.alert_activate.push(d);
      }
    });

    graphsData.alert_long_set.forEach(d => {
      // use middle of time_start and time_end
      d.date = isoParse((isoParse(d.time_start).getTime() + isoParse(d.time_end).getTime()) / 2);
      if (d.date >= startDate && d.date <= endDate) {
        filteredGraphsData.alert_long_set.push(d);
      }
    });
    filteredGraphsData.alert_long_set = zeroFill(filteredGraphsData.alert_long_set);
  }
  return filteredGraphsData;
}

export function getMaxY(data_alert_activate, data_alert_long_set, data_alert_threshold, isPositionGraph) {
  let maxValue;
  let max_alert_activate;
  let max_alert_long_set;
  let max_threshold;

  if (isPositionGraph) {
    max_alert_activate =
      data_alert_activate.length > 0
        ? max(data_alert_activate, d => Math.max(parseInt(d.current_position), parseInt(d.history_position)))
        : 0;
    max_alert_long_set = data_alert_long_set.length > 0 ? max(data_alert_long_set, d => parseInt(d.alert_position)) : 0;
    maxValue = Math.max(max_alert_activate, max_alert_long_set);
  } else {
    max_alert_activate =
      data_alert_activate.length > 0 ? max(data_alert_activate, d => Math.max(d.alert_value, d.alert_baseline)) : 0;

    max_alert_long_set = data_alert_long_set.length > 0 ? max(data_alert_long_set, d => d.alert_value_max) : 0;
    max_threshold =
      data_alert_threshold && data_alert_threshold.baseline && data_alert_threshold.baseline.miss_val
        ? data_alert_threshold.baseline.miss_val
        : 0;
    maxValue = Math.max(max_alert_activate, max_alert_long_set, max_threshold);
  }
  return maxValue;
}

export function getGraphStartEndTime(data_alert_activate, data_alert_long_set, ctime) {
  let startTime;
  let endTime;
  let start_alert_activate;
  let end_alert_activate;
  let start_alert_long_set;
  let end_alert_long_set;
  if (data_alert_activate && Array.isArray(data_alert_activate) && data_alert_activate.length > 0) {
    start_alert_activate = new Date(data_alert_activate[0].date);
    end_alert_activate = new Date(data_alert_activate[data_alert_activate.length - 1].date);
  }
  if (data_alert_long_set && Array.isArray(data_alert_long_set) && data_alert_long_set.length > 0) {
    start_alert_long_set = new Date(data_alert_long_set[0].time_start);
    end_alert_long_set = new Date(data_alert_long_set[data_alert_long_set.length - 1].time_end);
  }
  if (start_alert_activate && start_alert_long_set) {
    startTime = Math.min(start_alert_activate, start_alert_long_set);
  } else {
    startTime = start_alert_activate || start_alert_long_set;
  }
  if (end_alert_activate && end_alert_long_set) {
    endTime = Math.max(end_alert_activate, end_alert_long_set);
  } else {
    endTime = end_alert_activate || end_alert_long_set;
  }
  startTime = new Date(startTime);
  endTime = new Date(endTime);
  if (new Date(ctime) > endTime) {
    endTime = new Date(ctime);
  }
  return {
    startTime,
    endTime
  };
}

export function createDataGroup(data, isPositionGraph) {
  let fields;
  if (isPositionGraph) {
    fields = [
      {
        field_name: 'Stored',
        field_column: 'alert_position'
      }
    ];
  } else {
    fields = [
      {
        field_name: 'Max',
        field_column: 'alert_value_max'
      },
      {
        field_name: 'p98th',
        field_column: 'alert_value_98'
      },
      {
        field_name: 'p95th',
        field_column: 'alert_value_95'
      },
      {
        field_name: 'p75th',
        field_column: 'alert_value_75'
      },
      {
        field_name: 'p50th',
        field_column: 'alert_value_50'
      },
      {
        field_name: 'p25th',
        field_column: 'alert_value_25'
      },
      {
        field_name: 'Min',
        field_column: 'alert_value_min'
      }
    ];
  }

  const dataGroup = [];
  for (let i = 0; i < fields.length; i += 1) {
    const fieldValues = [];
    for (let j = 0; j < data.length; j += 1) {
      fieldValues.push({
        date: data[j].date,
        time_start: isoParse(data[j].time_start),
        time_end: isoParse(data[j].time_end),
        traffic: data[j][fields[i].field_column]
      });
    }
    dataGroup.push({
      key: fields[i].field_name,
      values: fieldValues
    });
  }
  return dataGroup;
}

export function getTimeRange(graphRange, ctime) {
  let startDate;
  let endDate;
  if (graphRange === '14 hr') {
    startDate = moment(ctime)
      .utc()
      .add(-7, 'hours')
      .toISOString();
    endDate = moment(ctime)
      .utc()
      .add(7, 'hours')
      .toISOString();
    // shift time if end date is in the future
    if (moment(endDate).unix() > moment().unix()) {
      endDate = moment.utc().toISOString();
      startDate = moment()
        .utc()
        .add(-14, 'hours')
        .toISOString();
    }
  } else if (graphRange === '56 hr') {
    startDate = moment(ctime)
      .utc()
      .add(-28, 'hours')
      .toISOString();
    endDate = moment(ctime)
      .utc()
      .add(28, 'hours')
      .toISOString();
    // shift time if end date is in the future
    if (moment(endDate).unix() > moment().unix()) {
      endDate = moment.utc().toISOString();
      startDate = moment()
        .utc()
        .add(-56, 'hours')
        .toISOString();
    }
  } else if (graphRange === '15 days') {
    startDate = moment(ctime)
      .utc()
      .add(-12, 'days')
      .toISOString();
    endDate = moment(ctime)
      .utc()
      .add(3, 'days')
      .toISOString();
    // shift time if end date is in the future
    if (moment(endDate).unix() > moment().unix()) {
      endDate = moment.utc().toISOString();
      startDate = moment()
        .utc()
        .add(-15, 'days')
        .toISOString();
    }
  } else if (graphRange === '30 days') {
    startDate = moment()
      .utc()
      .add(-30, 'days')
      .toISOString();
    endDate = moment()
      .utc()
      .toISOString();
  }
  return {
    startDate,
    endDate
  };
}

export function getPrimaryMetric(alert_metric) {
  const metric = alert_metric && JSON.parse(alert_metric);
  const primaryMetric = metric && metric[0];
  let label;
  let metric_alt;
  // metric names are inconsistent, check for both
  if (primaryMetric === 'bits') {
    label = 'bits/sec';
    metric_alt = 'bps';
  } else if (primaryMetric === 'packets') {
    label = 'packets/sec';
    metric_alt = 'pps';
  } else {
    label = primaryMetric;
    metric_alt = primaryMetric;
  }
  return { metric: primaryMetric, metric_alt, label };
}

export function getBaselineValue(baseline_used) {
  const BASELINE_VALUES = {
    0: {
      desc: 'NO_USE_BASELINE',
      match: true,
      baselineFallback: true
    },
    1: {
      desc: 'SKIPED_BASELINE_CALCULATION',
      match: true,
      baselineFallback: true
    },
    2: {
      desc: 'TRIGGER_USED_NO_BASELINE',
      match: true,
      baselineFallback: true
    },
    3: {
      desc: 'CALCULATED_USED_FOR_BASELINE',
      match: true,
      baselineFallback: false
    },
    4: {
      desc: 'DEFAULT_USED_FOR_BASELINE',
      match: true,
      baselineFallback: true
    },
    5: {
      desc: 'LOWEST_USED_FOR_BASELINE',
      match: true,
      baselineFallback: true
    },
    6: {
      desc: 'HIGHEST_USED_FOR_BASELINE',
      match: true,
      baselineFallback: true
    },
    7: {
      desc: 'NOT_FOUND_EXISTS_NO_BASELINE',
      match: true,
      baselineFallback: true
    },
    8: {
      desc: 'ACT_CURRENT_MISSING_SKIP',
      match: false,
      baselineFallback: false
    },
    9: {
      desc: 'ACT_CURRENT_MISSING_TRIGGER',
      match: false,
      baselineFallback: false
    },
    10: {
      desc: 'ACT_CURRENT_USED_FOUND',
      match: true,
      baselineFallback: false
    },
    11: {
      desc: 'ACT_CURRENT_MISSING_DEFAULT',
      match: false,
      baselineFallback: false
    },
    12: {
      desc: 'ACT_CURRENT_MISSING_LOWEST',
      match: false,
      baselineFallback: false
    },
    13: {
      desc: 'ACT_CURRENT_MISSING_HIGHEST',
      match: false,
      baselineFallback: false
    },
    14: {
      desc: 'ACT_CURRENT_NOT_FOUND_EXISTS',
      match: false,
      baselineFallback: false
    },
    15: {
      desc: 'ACT_BASELINE_MISSING_DEFAULT_INSTEAD_OF_LOWEST',
      match: true,
      baselineFallback: true
    },
    16: {
      desc: 'ACT_BASELINE_MISSING_DEFAULT_INSTEAD_OF_HIGHEST',
      match: true,
      baselineFallback: true
    },
    17: {
      desc: 'ACT_CURRENT_MISSING_DEFAULT_INSTEAD_OF_LOWEST',
      match: false,
      baselineFallback: false
    },
    18: {
      desc: 'ACT_CURRENT_MISSING_DEFAULT_INSTEAD_OF_HIGHEST',
      match: false,
      baselineFallback: false
    }
  };

  return BASELINE_VALUES[baseline_used];
}
