import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Flex } from 'components/flexbox';
import { Page, PageHeader, PageBody } from 'components/page';
import { Filter, NewButton } from 'components/table';
import UserDetailsDialog from './UserDetailsDialog';
import UserTable from './UserTable';

const breadcrumbLinks = [
  {
    text: 'Admin',
    href: '/admin'
  }
];

const componentBase = '/users';

@inject('$users', '$userGroups', '$dictionary', '$auth')
@observer
class Users extends Component {
  state = {};

  componentDidMount() {
    const { $auth } = this.props;
    $auth.getSsoStatus().then(results => {
      this.setState({ ssoStatus: results });
    });
  }

  componentWillMount() {
    const { $users, $userGroups, match } = this.props;
    const { userId } = match.params;

    $users.collection.fetch().then(() => {
      if (userId) {
        if (userId === 'new') {
          $users.collection.forge();
        } else {
          $users.collection.find({ id: userId }).select();
        }
      }
    });
    $userGroups.collection.fetch();
  }

  render() {
    const { $users, location } = this.props;
    const { ssoStatus } = this.state;
    const { collection } = $users;
    const { selected } = collection;
    const rootUrl = location.pathname.substring(0, location.pathname.indexOf(componentBase) + componentBase.length);
    const showAddUserBtn =
      ssoStatus && !(ssoStatus.meta_ssoRequired && ssoStatus.meta_allowAutoProvision && ssoStatus.meta_ssoEnabled);

    // adjust breadcrumbs if coming from onboarding.
    if (rootUrl.startsWith('/onboarding')) {
      breadcrumbLinks[0].text = 'Onboarding';
      breadcrumbLinks[0].href = '/library';
    }

    return (
      <Page constrainWidth centered title="Users">
        <PageHeader
          loading={collection.isRequestActive('fetching') || !ssoStatus}
          parents={breadcrumbLinks}
          title="Users"
          helpTopic="User List"
          subSection={
            <Flex mx={3} my={1} justify="space-between">
              <Filter
                style={{ maxWidth: 350, width: '100%' }}
                collection={collection}
                placeholder="Search Users..."
                autoFocus
              />
              {showAddUserBtn && <NewButton collection={collection} entityName="User" rootUrl={rootUrl} />}
            </Flex>
          }
        />
        <PageBody scrollable>
          <Flex className="pt-card flat" flexAuto flexColumn>
            <UserTable collection={collection} rootUrl={rootUrl} />
          </Flex>
          <UserDetailsDialog isOpen={selected} model={selected} collection={collection} rootUrl={rootUrl} />
        </PageBody>
      </Page>
    );
  }
}

export default withRouter(Users);
