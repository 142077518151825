import { computed } from 'mobx';
import { observer } from 'mobx-react';

import $app from 'stores/$app';
import $customDimensions from 'stores/$customDimensions';
import $sites from 'stores/$sites';
import api from 'util/api';

import BaseMapboxDataview from './BaseMapboxDataview';

@observer
export default class GeoHeatMapView extends BaseMapboxDataview {
  allMapData;

  siteMarkers = false;

  afterComponentWillMount() {
    super.afterComponentWillMount();

    if (!$sites.collection.hasFetched) {
      $sites.collection.fetch();
    }

    if ($customDimensions.markets.length === 0) {
      $customDimensions.loadMarketDimension();
    }
  }

  @computed
  get metric() {
    const { activeBucketCount, activeBuckets } = this.props.dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;
    return query.get('metric');
  }

  @computed
  get isCountry() {
    const metric = this.metric;
    return (
      metric.length === 1 &&
      (metric[0] === 'Geography_dst' ||
        metric[0] === 'Geography_src' ||
        metric[0] === 'i_device_site_country' ||
        metric[0] === 'i_ult_exit_site_country')
    );
  }

  @computed
  get isRegion() {
    const metric = this.metric;
    if (metric.length === 1) {
      return metric[0] === 'src_geo_region' || metric[0] === 'dst_geo_region';
    }

    return (
      metric.length === 2 &&
      ((metric.indexOf('Geography_src') !== -1 && metric.indexOf('src_geo_region') !== -1) ||
        (metric.indexOf('Geography_dst') !== -1 && metric.indexOf('dst_geo_region') !== -1))
    );
  }

  @computed
  get isCity() {
    const metric = this.metric;

    if (metric.length === 1) {
      return metric[0] === 'src_geo_city' || metric[0] === 'dst_geo_city';
    }

    if (metric.length === 2) {
      return (
        (metric.indexOf('src_geo_city') !== -1 && metric.indexOf('src_geo_region') !== -1) ||
        (metric.indexOf('dst_geo_city') !== -1 && metric.indexOf('dst_geo_region') !== -1)
      );
    }

    return (
      metric.length === 3 &&
      ((metric.indexOf('Geography_src') !== -1 &&
        metric.indexOf('src_geo_region') !== -1 &&
        metric.indexOf('src_geo_city') !== -1) ||
        (metric.indexOf('Geography_dst') !== -1 &&
          metric.indexOf('dst_geo_region') !== -1 &&
          metric.indexOf('dst_geo_city') !== -1))
    );
  }

  @computed
  get isSite() {
    const metric = this.metric;
    return metric.length === 1 && (metric[0] === 'i_ult_exit_site' || metric[0] === 'i_device_site_name');
  }

  @computed
  get isMarket() {
    const metric = this.metric;
    return metric.length === 1 && (metric[0] === 'kt_src_market' || metric[0] === 'kt_dst_market');
  }

  @computed
  get key() {
    const metric = this.metric;

    if (this.isMarket || this.isSite || this.isCountry) {
      return metric[0];
    } else if (this.isRegion) {
      return metric.indexOf('src_geo_region') !== -1 ? 'src_geo_region' : 'dst_geo_region';
    } else if (this.isCity) {
      return metric.indexOf('src_geo_city') !== -1 ? 'src_geo_city' : 'dst_geo_city';
    }

    return null;
  }

  getCountryFromRegion(model) {
    if (this.isRegion) {
      const metric = this.metric;
      return model.get(metric.indexOf('src_geo_region') !== -1 ? 'Geography_src' : 'Geography_dst');
    }

    return null;
  }

  getBoundsPadding() {
    return this.isSite || this.isCity ? 50 : 10;
  }

  getCountryMapData(countryCode) {
    return api.get(`/api/portal/mapData/country/${countryCode}`);
  }

  getRegionMapData(countryCode, regionName) {
    return api.get(`/api/portal/mapData/region/${countryCode}/${regionName}`);
  }

  getTagColor(bracketOptions, model) {
    if (bracketOptions && bracketOptions.tagKey) {
      const tagData = model.get(bracketOptions.tagKey);
      return tagData && tagData.value;
    }
    return null;
  }

  getMapKeyValues(model) {
    const value = model.get(this.key);

    if (this.isMarket) {
      return this.getMarket(value).countries;
    }

    return [value];
  }

  getMappableSites() {
    if (this.siteMarkers && !this.isSite) {
      return $sites.collection.models.filter(site => !Number.isNaN(site.get('lat')) && !Number.isNaN(site.get('lon')));
    }
    return null;
  }

  showSiteMarkers(show) {
    this.siteMarkers = show;
  }

  async buildSeriesInternal(bucket, models) {
    const geoRecords = [];
    const bracketOptions = bucket.firstQuery.get('bracketOptions');

    const key = this.key;
    const populatedModels = models.filter(model => this.isPopulatedNonOverlay(model, model.get(key)));
    populatedModels.forEach((model, idx) => {
      const mapKey = model.get(key);
      const value = this.getModelValue(bucket, model);
      const formattedValue = this.getFormattedValue(bucket, value);

      let color;
      let darkColor;
      if (bracketOptions) {
        color = this.getTagColor(bracketOptions, model);
        darkColor = color;
      } else {
        color = this.getColor(idx, populatedModels.length);
        darkColor = this.getDarkColor(idx, populatedModels.length);
      }

      geoRecords.push({ code: mapKey, value, formattedValue, color, darkColor, model, mapKey });

      if (!bracketOptions) {
        model.set({ color, toggled: false });
        model.visible = true;
      }
    });

    if (this.isCountry) {
      this.addCountryLayers(geoRecords);
    } else if (this.isRegion) {
      this.addRegionLayers(geoRecords);
    } else if (this.isCity) {
      this.addCityLayers(geoRecords);
    } else if (this.isSite) {
      this.addSiteLayers(geoRecords);
    } else if (this.isMarket) {
      this.addMarketLayers(geoRecords);
    }

    $app.renderSync(() => {
      this.dismissSpinner($app.isExport ? 5000 : 1000);
    });
  }

  isPopulatedNonOverlay(model, keyValue) {
    const testValue = keyValue ? keyValue.replace(/-/g, '').trim() : null;
    return !model.get('isOverlay') && testValue;
  }

  getModelValue(bucket, model) {
    const outsort = bucket.firstQuery.get('outsort');
    const units = bucket.firstQuery.get('units');
    const { aggregates } = bucket.firstQuery;

    let value = model.get(outsort);
    if (units.includes('sample_rate') && aggregates.find(agg => agg.name === outsort).fn !== 'percentile') {
      value /= 100;
    }

    return value;
  }

  addCountryLayers(countries) {
    Promise.all(
      countries.map(country => {
        const { model, code, color, darkColor, value, formattedValue } = country;

        model.opacityKey = 'fill-opacity';

        return this.getCountryMapData(code).then(data => {
          const { bounds, ...geoJson } = data;

          this.addLayer({ id: code, name: code, data: geoJson, color, darkColor, value, formattedValue }, model);

          return bounds;
        });
      })
    ).then(allBounds => this.fitToBounds(allBounds));
  }

  addRegionLayers(regions) {
    Promise.all(
      regions.map(region => {
        const { model, code, mapKey, color, darkColor, value, formattedValue } = region;

        model.opacityKey = 'fill-opacity';

        return this.getRegionMapData(this.getCountryFromRegion(model), mapKey).then(data => {
          if (Object.keys(data).length === 0) {
            console.warn(`Couldn't find region data for ${mapKey}`);
            return null;
          }

          const { bounds, ...geoJson } = data;

          this.addLayer({ id: code, name: code, data: geoJson, color, darkColor, value, formattedValue }, model);

          return bounds;
        });
      })
    ).then(allBounds => this.fitToBounds(allBounds));
  }

  getMaxAbsValue(geoRecords) {
    return geoRecords.reduce((max, { value }) => {
      if (Math.abs(value) > max) {
        return Math.abs(value);
      }

      return max;
    }, 0);
  }

  getCityLabel(model) {
    const isSrcDimension = this.metric.indexOf('src_geo_city') !== -1;
    const cityKey = isSrcDimension ? 'src_geo_city' : 'dst_geo_city';
    const regionKey = isSrcDimension ? 'src_geo_region' : 'dst_geo_region';

    return `${model.get(cityKey)}, ${model.get(regionKey)}`;
  }

  addCityLayers(cities) {
    const maxValue = this.getMaxAbsValue(cities);

    const points = cities.map(city => {
      const { model, code, color, darkColor, value, formattedValue } = city;
      model.opacityKey = 'circle-opacity';

      const latLong = model.get('latLong');

      if (latLong && latLong.length > 0) {
        const { coord_lat, coord_long } = latLong[0];
        const name = this.getCityLabel(model);

        this.addCircle(
          { id: code, name, lat: coord_lat, lon: coord_long, color, darkColor, value, formattedValue, maxValue },
          model
        );

        return { minLat: coord_lat, minLon: coord_long, maxLat: coord_lat, maxLon: coord_long };
      }

      return null;
    });

    this.fitToBounds(points.filter(p => p));
  }

  addSiteLayers(sites) {
    const maxValue = this.getMaxAbsValue(sites);

    const points = sites.map(siteResult => {
      const { model, code, color, darkColor, value, formattedValue } = siteResult;
      const site = $sites.collection.find({ title: code });

      model.opacityKey = 'circle-opacity';

      if (site) {
        const lat = site.get('lat');
        const lon = site.get('lon');

        if (lat !== undefined && lat !== null && lon !== undefined && lon !== null) {
          this.addCircle({ id: code, name: code, lat, lon, color, darkColor, value, formattedValue, maxValue }, model);

          return { minLat: lat, minLon: lon, maxLat: lat, maxLon: lon };
        }
      }

      return null;
    });

    this.fitToBounds(points.filter(p => p));
  }

  getAllMapData() {
    if (this.allMapData) {
      return Promise.resolve(this.allMapData);
    }

    return api.get('/api/portal/mapData/all').then(countries => {
      this.allMapData = {};

      this.allMapData = countries.reduce((acc, country) => {
        const { id } = country.features[0];
        acc[id] = country;
        return acc;
      }, {});

      return this.allMapData;
    });
  }

  getMarket(code) {
    const marketInfo = $customDimensions.markets.find(market => market.name.toUpperCase() === code);
    return marketInfo || { countries: [] };
  }

  addMarketLayers(markets) {
    this.getAllMapData().then(allMapData => {
      // Iterate over markets and get countries for each
      markets.forEach(market => {
        const { model, code, color, darkColor, value, formattedValue } = market;
        model.opacityKey = 'fill-opacity';

        const { name, countries } = this.getMarket(code);
        countries.forEach(country => {
          const data = allMapData[country];
          if (!data) {
            console.warn(`Could not find country map data for ${country} in market ${name}.`);
          } else {
            data.features[0].properties.name = name;
            this.addLayer({ id: country, name, data, color, darkColor, value, formattedValue }, model);
          }
        });

        this.fitToBounds([{ minLat: -50.0, minLon: -179.0, maxLat: 80.0, maxLon: 179.0 }]);
      });
    });
  }

  toggleSeries(model, toggled) {
    this.getMapKeyValues(model).forEach(id => {
      if (this.map.getLayer(id)) {
        this.map.setLayoutProperty(id, 'visibility', toggled ? 'none' : 'visible');
      }
    });
    model.visible = toggled;
  }

  highlightSeries(model) {
    const matchingModels = this.getMapKeyValues(model);
    this.layerIds.filter(id => !matchingModels.includes(id)).forEach(id => {
      if (this.map.getLayer(id)) {
        this.map.setPaintProperty(id, model.opacityKey, 0.25);
      }
    });
    this.showPopupFromModel(model);
  }

  unhighlightSeries(model) {
    const matchingModels = this.getMapKeyValues(model);
    this.layerIds.filter(id => !matchingModels.includes(id)).forEach(id => {
      if (this.map.getLayer(id)) {
        this.map.setPaintProperty(id, model.opacityKey, 0.75);
      }
    });
    this.hidePopup();
  }

  getComponent() {
    if (!this.isCountry && !this.isRegion && !this.isCity && !this.isSite && !this.isMarket) {
      throw new Error(
        'To use the Geo HeatMap view, you must choose a single group-by dimension, either Country, Region, City, Site, or Custom Geo.'
      );
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: false,
  showLegend: true,
  timeBased: false,
  enableToggle: true,
  buckets: [
    {
      name: 'HeatMap'
    }
  ]
};

export { config };
