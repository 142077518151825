import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Form, FormDialog } from 'components/forms';
import { fields } from 'forms/config/alertNotification';

import NotificationForm from './NotificationForm';

@Form({ fields })
@observer
class NotificationDialogForm extends Component {
  render() {
    return (
      <FormDialog
        {...this.props}
        entityName="Notification Channel"
        formComponent={<NotificationForm />}
        style={{ width: 600 }}
      />
    );
  }
}

export default NotificationDialogForm;
