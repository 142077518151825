const TYPES = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  RESERVED: 'Reserved',
  UNKNOWN: 'Unknown'
};

const typesMap = [
  { range: [0, 0], type: TYPES.RESERVED },
  { range: [1, 23455], type: TYPES.PUBLIC },
  { range: [23456, 23456], type: TYPES.RESERVED },
  { range: [23457, 64495], type: TYPES.PUBLIC },
  { range: [64496, 64511], type: TYPES.RESERVED },
  { range: [64512, 65534], type: TYPES.PRIVATE },
  { range: [65535, 131071], type: TYPES.RESERVED },
  { range: [131072, 4199999999], type: TYPES.PUBLIC },
  { range: [4200000000, 4294967294], type: TYPES.PRIVATE },
  { range: [4294967295, 4294967295], type: TYPES.RESERVED }
];

export function getASNType(asn) {
  const value = Number(asn.replace(/\D.+$/, ''));
  return (typesMap.find(({ range }) => value >= range[0] && value <= range[1]) || { type: TYPES.UNKNOWN }).type;
}
