import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import { Button } from '@blueprintjs/core';
import { MaterialIcon } from 'components/Icon';
import { CELL_TYPES, VirtualizedTable } from 'components/table/index';
import { numberWithCommas } from 'util/utils';
import { dateFormatter, durationFromNow } from 'util/dateUtils';

export const companyPlanRenderer = ({ value }) => (
  <span
    style={{ width: 75 }}
    className={classNames('pt-tag pt-minimal', {
      'pt-intent-success': value === 'Customer',
      'pt-intent-warning': value === 'Free'
    })}
  >
    {value}
  </span>
);

@inject('$app', '$sudo')
@observer
export default class CompanyTable extends Component {
  getTableColumns() {
    return [
      {
        name: 'companyId',
        label: 'ID',
        computed: true,
        className: 'pt-text-muted',
        width: 60
      },
      {
        name: 'company_name',
        label: 'Company Name',
        renderer: ({ value }) => (
          <span className="pt-strong" style={{ fontSize: 14 }}>
            {value}
          </span>
        ),
        flexBasis: 125
      },
      {
        name: 'companyPlan',
        label: 'Plan',
        computed: true,
        renderer: companyPlanRenderer,
        flexBasis: 75
      },
      {
        name: 'company_status',
        label: 'Status',
        renderer: ({ value, model }) => (
          <span className={value !== 'V' ? 'pt-text-muted' : undefined}>
            {model.getCompanyStatusMap()[model.get('company_status')]}
          </span>
        ),
        flexBasis: 60
      },
      {
        name: 'p98_fps_rate_limited',
        label: '24h p98 FPS',
        renderer: ({ value }) => (value !== -1 ? numberWithCommas(value) : '-'),
        flexBasis: 65
      },
      {
        name: 'active_device_count',
        label: 'Active Devices',
        flexBasis: 50
      },
      {
        name: 'device_count',
        label: 'Total Devices',
        flexBasis: 50
      },
      {
        name: 'user_count',
        label: 'Users',
        flexBasis: 50
      },
      {
        name: 'trialEndDate',
        label: 'Trial End',
        computed: true,
        flexBasis: 75
      },
      {
        name: 'max_last_login',
        label: 'Last Login',
        renderer: ({ value }) => (value ? durationFromNow(value) : 'N/A'),
        flexBasis: 75
      },
      {
        name: 'cdate',
        label: 'Created',
        renderer: dateFormatter,
        flexBasis: 75
      },
      {
        type: CELL_TYPES.ACTION,
        actions: [
          model => {
            const text = `Spoof into ${model.get('company_name')}`;
            return (
              <Button
                key="spoof-company"
                title={text}
                className="pt-minimal"
                onClick={e => {
                  e.stopPropagation();
                  this.props.$sudo.setSudoOpen(true, model.get('id'));
                }}
                text={text}
              >
                <MaterialIcon style={{ margin: 0 }} name="vpn_key" />
              </Button>
            );
          }
        ]
      }
    ];
  }

  render() {
    const { $app, collection } = this.props;
    return (
      <VirtualizedTable
        collection={collection}
        columns={this.getTableColumns()}
        style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        bodyStyle={{ flex: 1 }}
        rowHeight={48}
        responsiveHeightMultiplier={4}
        rootUrl={$app.adjustUrlForShim(window.location, '/admin/companies')}
      />
    );
  }
}
