import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

import ConfirmDialogModel from 'models/ConfirmDialogModel';

@observer
class RemoveButton extends Component {
  static defaultProps = {
    className: 'pt-minimal',
    showText: true,
    showIcon: false,
    text: 'Remove',
    iconName: 'trash',
    intent: Intent.DANGER
  };

  componentWillMount() {
    const { model, onRemove } = this.props;
    this.confirmDialog = new ConfirmDialogModel({ model, onConfirm: onRemove });
  }

  componentWillUpdate(nextProps) {
    const { model, onRemove } = this.props;
    const { model: nextModel } = nextProps;
    if (nextModel && nextModel.id !== model.id) {
      this.confirmDialog = new ConfirmDialogModel({ model: nextProps.model, onConfirm: onRemove });
    }
  }

  render() {
    const { disabled, iconName, model, showIcon, showText, text, ...props } = this.props;

    const isCreating = model && model.isRequestActive('creating');
    const isSaving = model && model.isRequestActive('updating');

    const buttonText = model && model.removalConfirmText && model.removalConfirmText.buttonText;

    return (
      <span>
        <Button
          {...props}
          disabled={disabled || isSaving || isCreating}
          onClick={e => {
            e.stopPropagation();
            this.confirmDialog.showConfirmDialog();
          }}
          iconName={showIcon ? iconName : undefined}
          text={showText ? buttonText || text : undefined}
        />
        {this.confirmDialog.component}
      </span>
    );
  }
}

export default RemoveButton;
