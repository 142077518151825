import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Intent, Classes, MenuItem, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { ColorBlock } from 'components';

import { MultiSelect } from '@blueprintjs/labs';

import { defaultLabelColor } from './LabelItemAdd';

@inject('$deviceGroups')
@observer
class DeviceLabelSelector extends Component {
  state = {
    creatingLabel: false,
    inputValue: ''
  };

  static defaultProps = {
    popoverProps: {
      popoverClassName: Classes.MINIMAL
    }
  };

  componentWillMount() {
    const { $deviceGroups } = this.props;
    if (!$deviceGroups.labelCollection.hasFetched) {
      $deviceGroups.labelCollection.fetch();
    }
  }

  get selectedLabels() {
    const { field } = this.props;
    return field.getValue().map(v => ({ ...v, id: parseInt(v.id) }));
  }

  renderLabelTag = label => label.name;

  renderLabel = ({ handleClick, isActive, item: label }) => {
    const selected = this.selectedLabels.find(l => l.id === label.id);

    const classes = classNames({
      [Classes.ACTIVE]: isActive
    });

    return (
      <MenuItem
        className={classes}
        iconName={selected ? 'tick' : 'blank'}
        key={label.id}
        onClick={handleClick}
        shouldDismissPopover={false}
        text={this.getLabelItemText(label)}
      />
    );
  };

  getLabelItemText(label) {
    return (
      <Flex align="center">
        <ColorBlock color={label.color} style={{ width: 16, height: 16, marginRight: 6 }} />
        <Box flexAuto className="pt-text-overflow-ellipsis">
          {label.name}
        </Box>
      </Flex>
    );
  }

  getLabelTagProps = label => {
    const { $deviceGroups } = this.props;
    const { labelNameColors } = $deviceGroups.labelCollection;

    return {
      style: {
        background: labelNameColors[label],
        color: '#ffffff'
      }
    };
  };

  handleInputChange = eventOrValue => {
    if (eventOrValue.target) {
      this.setState({ inputValue: eventOrValue.target.value });
    } else {
      this.setState({ inputValue: eventOrValue });
    }
  };

  onFilterLabel = (query, label) => label && label.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;

  handleSelect(label) {
    const { field, device, onChange } = this.props;

    const values = [...this.selectedLabels, label];
    field.setValue(values);

    if (onChange) {
      onChange(field.getValue(), device);
    }
  }

  handleRemove = (label, index) => {
    const { field, device, onChange } = this.props;
    const removeIndex = index !== undefined ? index : this.selectedLabels.findIndex(l => l.id === label.id);
    const values = [...this.selectedLabels.splice(0, removeIndex), ...this.selectedLabels.splice(removeIndex + 1)];

    if (onChange) {
      onChange(this.selectedLabels, device);
    }

    if (field) {
      field.setValue(values);
    }
  };

  handleLabelSelect = label => {
    const selected = this.selectedLabels.find(l => l.id === label.id);

    if (!selected) {
      this.handleSelect(label);
    } else {
      this.handleRemove(label);
    }
  };

  handleCreateLabel = async () => {
    const { $deviceGroups } = this.props;
    this.setState({ creatingLabel: true });
    const label = await $deviceGroups.labelCollection
      .forge()
      .save({ name: this.state.inputValue, color: defaultLabelColor }, { toast: false });
    this.setState({ creatingLabel: false });
    this.handleSelect(label);
    this.handleInputChange('');
  };

  render() {
    const { $deviceGroups, popoverProps, field } = this.props;
    const { creatingLabel, inputValue } = this.state;
    const { deviceLabelOptions } = $deviceGroups;

    return (
      <MultiSelect
        items={deviceLabelOptions}
        itemPredicate={this.onFilterLabel}
        itemRenderer={this.renderLabel}
        noResults={
          <MenuItem
            intent={Intent.PRIMARY}
            iconName="plus"
            onClick={this.handleCreateLabel}
            text={
              <span>
                Add <strong>"{inputValue}"</strong> label
              </span>
            }
          />
        }
        onItemSelect={this.handleLabelSelect}
        tagRenderer={this.renderLabelTag}
        tagInputProps={{
          placeholder: 'Select Labels...',
          onRemove: this.handleRemove,
          onInputChange: this.handleInputChange,
          disabled: creatingLabel,
          rightElement: creatingLabel ? <Spinner className="pt-small" /> : undefined,
          inputValue,
          inputProps: {
            placeholder: 'Add labels...'
          },
          tagProps: this.getLabelTagProps
        }}
        popoverProps={popoverProps}
        selectedItems={field.getValue()}
      />
    );
  }
}

export default DeviceLabelSelector;
