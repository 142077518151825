import { action, computed } from 'mobx';

import SavedViewCollection from 'models/savedViews/SavedViewCollection';

class SavedViews {
  @action
  createView(queries) {
    queries.save(true).then(saved_query_id => {
      this.store.$library.reportsCollection.forgeSavedView({ saved_query_id }, { select: true });
    });
  }

  @computed
  get savedViews() {
    return this.store.$library.reportsCollection.models.filter(model => model.get('type') === 'savedView');
  }

  @computed
  get collection() {
    return new SavedViewCollection(null, { models: this.savedViews });
  }

  @computed
  get selected() {
    const { selected } = this.store.$library.reportsCollection;

    if (selected && selected.get('type') === 'savedView') {
      return selected;
    }

    return null;
  }

  getSavedView(id) {
    /* eslint-disable eqeqeq */
    return this.savedViews.find(savedView => savedView.id == id);
  }
}

export default new SavedViews();
