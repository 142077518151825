import React from 'react';
import { autorun, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Popup } from 'mapbox-gl';
import geojsonArea from '@mapbox/geojson-area';
import { createGradient } from '@zsoltc/gradient-generator';
import polylabel from 'polylabel';

import Map, { getBoundsFromBoundsArray } from 'components/Map';
import $app from 'stores/$app';
import $dictionary from 'stores/$dictionary';
import { getToFixed, zeroToText, adjustByGreekPrefix } from 'util/utils';

import BaseDataview from './BaseDataview';

@observer
export default class BaseMapboxDataview extends BaseDataview {
  alwaysShowDataView = true;

  spinnerClassName = 'opaque';

  map;

  bounds;

  popup;

  layerIds = [];

  redrawQueue = [];

  afterComponentWillMount() {
    this.popup = new Popup({
      closeButton: false,
      closeOnClick: false
    });
  }

  removeSeries() {}

  toggleSeries() {}

  highlightSeries() {}

  unhighlightSeries() {}

  getBoundsPadding() {
    return 10;
  }

  clear() {
    this.redrawQueue = [];

    if (this.map) {
      this.layerIds.forEach(layerId => {
        if (this.map.getLayer(layerId)) {
          this.map.removeLayer(layerId);
        }
        if (this.map.getSource(layerId)) {
          this.map.removeSource(layerId);
        }
      });

      this.layerIds = [];

      this.hidePopup();
    }
  }

  @computed
  get gradient() {
    const colors = this.lightColors.slice().reverse();
    return createGradient(colors);
  }

  @computed
  get darkGradient() {
    const colors = this.darkColors.slice().reverse();
    return createGradient(colors);
  }

  getColor(index, length) {
    return this.gradient.getColorHexAt(index / length);
  }

  getDarkColor(index, length) {
    return this.darkGradient.getColorHexAt(index / length);
  }

  redraw() {}

  addLayer({ id, name, data, color, darkColor, formattedValue }, model) {
    this.executeWhenMapReady(() => {
      if (!this.map.getLayer(id)) {
        this.layerIds.push(id);

        const fillColor = $app.darkThemeEnabled ? darkColor : color;
        model.set({ color: fillColor, popupId: name, popupValue: formattedValue, popupGeoJson: data });

        this.map.addLayer({
          id,
          type: 'fill',
          source: {
            type: 'geojson',
            data
          },
          layout: {},
          paint: {
            'fill-color': fillColor,
            'fill-opacity': 0.75
          }
        });

        this.map.on('mouseenter', id, e => this.showPopup(e, formattedValue));
      }

      this.addInteractivity(model);
    });
  }

  addCircle({ id, name, lat, lon, color, darkColor, value, formattedValue, maxValue }, model) {
    const maxRadius = 50;
    const minRadius = 5;

    this.executeWhenMapReady(() => {
      if (!this.map.getLayer(id)) {
        this.layerIds.push(id);

        const geoJson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                name
              },
              geometry: {
                type: 'Point',
                coordinates: [lon, lat]
              }
            }
          ]
        };

        const circleColor = $app.darkThemeEnabled ? darkColor : color;
        model.set({ color: circleColor, popupValue: formattedValue, popupGeoJson: geoJson });

        this.map.addLayer({
          id,
          type: 'circle',
          source: {
            type: 'geojson',
            data: geoJson
          },
          layout: {},
          paint: {
            'circle-radius': Math.round(((maxRadius - minRadius) * Math.abs(value)) / maxValue + minRadius),
            'circle-color': circleColor,
            'circle-opacity': 0.75,
            'circle-stroke-width': 2,
            'circle-stroke-color': circleColor
          }
        });

        this.map.on('mouseenter', id, e => this.showPopup(e, formattedValue));
      }

      this.addInteractivity(model);
    });
  }

  addInteractivity(model) {
    this.togglerDisposers.push(
      autorun(() => {
        const toggled = model.get('toggled');
        const mouseover = model.get('mouseover');

        if (toggled !== model.visible) {
          this.toggleSeries(model, toggled);
        }

        if (mouseover) {
          this.highlightSeries(model);
        } else {
          this.unhighlightSeries(model);
        }
      })
    );
  }

  getFormattedValue = (bucket, value) => {
    const { prefix } = bucket.queryResults;
    const { outsortUnit, prefixUnit } = bucket.firstQuery;

    const formattedValue = zeroToText(adjustByGreekPrefix(value, prefix[prefixUnit]), { fix: getToFixed(outsortUnit) });
    const prefixedLabel = `${prefix[prefixUnit] || ''}${$dictionary.dictionary.units[outsortUnit]}`;

    return `${formattedValue} ${prefixedLabel}`;
  };

  polygonArea(coords) {
    let area = 0;
    if (coords && coords.length > 0) {
      area += Math.abs(geojsonArea.ring(coords[0]));
      for (let i = 1; i < coords.length; i += 1) {
        area -= Math.abs(geojsonArea.ring(coords[i]));
      }
    }
    return area;
  }

  largestPolygon(polygons) {
    let maxArea = 0;
    let maxPolygon;

    polygons.forEach(polygon => {
      const area = this.polygonArea(polygon);

      if (area > maxArea) {
        maxArea = area;
        maxPolygon = polygon;
      }
    });

    return maxPolygon;
  }

  getPopupLatLng(geometry) {
    switch (geometry.type) {
      case 'Polygon':
        return polylabel(geometry.coordinates);
      case 'MultiPolygon':
        return polylabel(this.largestPolygon(geometry.coordinates));
      case 'Point':
        return geometry.coordinates;
      default:
        return null;
    }
  }

  showPopup = (e, value) => {
    this.map.getCanvas().style.cursor = 'pointer';

    const { geometry, properties } = e.features[0];

    const latLng = this.getPopupLatLng(geometry);

    // Ensure that if the map is zoomed out such that multiple copies of the feature are visible,
    // the popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - latLng[0]) > 180) {
      latLng[0] += e.lngLat.lng > latLng[0] ? 360 : -360;
    }

    this.popup
      .setLngLat(latLng)
      .setHTML(`<b>${properties.name}</b><br/>${value}`)
      .addTo(this.map);
  };

  showPopupFromModel = model => {
    const { geometry, properties } = model.get('popupGeoJson').features[0];
    const value = model.get('popupValue');

    const latLng = this.getPopupLatLng(geometry);

    this.popup
      .setLngLat(latLng)
      .setHTML(`<b>${properties.name}</b><br/>${value}`)
      .addTo(this.map);
  };

  fitToBounds(allBounds) {
    this.executeWhenMapReady(() => {
      const bounds = allBounds.filter(b => b);

      if (bounds.length > 0) {
        this.bounds = getBoundsFromBoundsArray(bounds);
        this.map.fitBounds(this.bounds, { padding: this.getBoundsPadding(), easing: t => t * (2 - t) });
      }
    });
  }

  hidePopup = () => {
    if (this.popup) {
      this.map.getCanvas().style.cursor = '';
      this.popup.remove();
    }
  };

  reflow() {
    if (this.map) {
      this.map.resize();
      if (this.bounds) {
        this.map.fitBounds(this.bounds, { padding: this.getBoundsPadding(), easing: t => t * (2 - t) });
      }
    }
  }

  executeWhenMapReady = fn => {
    this.redrawQueue.push(fn);

    if (this.mapLoaded) {
      fn();
    }
  };

  handleLoad = map => {
    this.map = map;
    this.mapLoaded = true;

    this.redrawQueue.forEach(fn => fn());

    this.map.on('mousemove', e => {
      const features = this.map.queryRenderedFeatures(e.point);
      if (!features.some(feature => feature.layer && this.layerIds.includes(feature.layer.id))) {
        this.hidePopup();
      }
    });

    this.dismissSpinner($app.isExport ? 5000 : 1000);
  };

  dismissSpinner = (timeout = 200, setFullyLoaded = true) => {
    setTimeout(() => {
      if (this.spinner && !this.spinner.state.hide) {
        this.spinner.setState(() => ({ hide: true }));
      }
      if (setFullyLoaded && this.mapLoaded) {
        this.props.dataview.setFullyLoaded();
      }
    }, timeout);
  };

  handleUpdateMapStyle = () => {};

  getComponent() {
    const { hasFooter, isDashboardView } = this.props;
    const borderWidth = hasFooter ? '1px 0' : '1px 0 0 0';
    const marginBottom = hasFooter ? 16 : 0;

    return (
      <Map
        onLoad={this.handleLoad}
        onUpdateMapStyle={this.handleUpdateMapStyle}
        useDarkestTheme={isDashboardView}
        scrollZoom={!isDashboardView}
        containerWrapperProps={{
          flexAuto: true,
          className: 'map-container',
          style: { borderWidth, marginBottom }
        }}
        markers={this.getMappableSites()}
        disableFitToMarkers
      />
    );
  }
}
