import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/labs';

import { Flex, Box } from 'components/flexbox';
import { Form, Field, Input, Select } from 'components/forms';
import CategorySelector from 'views/Admin/Templates/CategorySelector';

const fields = {
  name: {
    label: 'Name',
    rules: 'required',
    messages: {
      required: 'A name is required'
    }
  },

  description: {
    label: 'Description'
  },

  category_id: {
    label: 'Category',
    rules: 'required'
  },

  company_id: {
    label: 'Company',
    rules: 'required'
  },

  share_level: {
    label: 'Share Level',
    defaultValue: 'org'
  }
};

@Form({ fields })
@inject('$sudo', '$library', '$basicExplorer', '$queryTemplates')
@observer
export default class CreateTemplateButton extends Component {
  state = {
    isOpen: false,
    saving: false,
    template: undefined
  };

  componentWillUpdate(nextProps, nextState) {
    const { $sudo } = this.props;
    if (nextState.isOpen && !$sudo.companiesCollection.hasFetched) {
      $sudo.companiesCollection.fetch();
    }
  }

  handleOpenPopover = () => {
    const { $queryTemplates, form } = this.props;
    const template = $queryTemplates.collection.forge();
    form.validate();
    this.setState({ isOpen: true, template });
  };

  handleCancel = () => {
    const { form } = this.props;
    this.setState({ isOpen: false, template: undefined });
    form.reset();
  };

  handleSubmit = async () => {
    const { form, $queryTemplates, dataview } = this.props;
    const { template } = this.state;
    this.setState({ saving: true });

    const attributes = form.getValues();

    const queryBuckets = dataview.queryBuckets;
    await $queryTemplates.createTemplateFromQueries(template, attributes, queryBuckets);

    this.setState({ saving: false, template: undefined });
  };

  render() {
    const { $sudo, form } = this.props;
    const { isOpen, template, saving } = this.state;

    return (
      <Popover2
        isOpen={isOpen}
        content={
          <Box p={2} style={{ width: 400 }}>
            <p className="pt-text-muted">
              <small>
                Create a new Explorer Lite template. The templates "base query" will use the currently active query
                options.
              </small>
            </p>
            <Field name="name" autoFocus>
              <Input />
            </Field>
            <Flex>
              <Box mr={1} flexAuto>
                <Field name="company_id" options={$sudo.companiesCollection.selectOptions}>
                  <Select autoComplete exactMatch showFilter optionLimit={50} menuWidth={200} />
                </Field>
              </Box>
              <Box ml={1} flexAuto>
                <CategorySelector template={template} field={form.getField('category_id')} />
              </Box>
            </Flex>
            <Flex justify="flex-end" pt={2}>
              <Button
                text="Cancel"
                disabled={saving}
                onClick={this.handleCancel}
                style={{ width: 110, marginRight: 8 }}
              />
              <Button
                className="pt-medium pt-intent-primary"
                text="Save"
                loading={saving}
                onClick={this.handleSubmit}
                disabled={!form.valid}
                style={{ width: 110 }}
              />
            </Flex>
          </Box>
        }
      >
        <Button
          className="pt-small pt-medium"
          text="Create Template"
          onClick={this.handleOpenPopover}
          iconName="plus"
          style={{ marginRight: 4 }}
        />
      </Popover2>
    );
  }
}
