import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { stopBubbling } from 'util/utils';

import CELL_TYPES from './CELL_TYPES';

function getColSpan(column, model, totalRow) {
  if (column.colSpan && typeof column.colSpan === 'function') {
    return column.colSpan(model, totalRow);
  }

  return column.colSpan || 1;
}

export function getCellStyle({ column, columns, index, model, totalRow }) {
  const colSpan = getColSpan(column, model, totalRow);
  const defaultFlexBasis = 100;

  // Check to see if anything is obscuring this cell via colSpan if this isn't a header row
  if (model || totalRow) {
    for (let i = 0; i < index; i += 1) {
      if (i + getColSpan(columns[i], model, totalRow) - 1 >= index) {
        return {
          display: 'none'
        };
      }
    }
  }

  // Get this column's width/flex and if it has colSpan > 1, get from the columns it'll obscure
  let sumFlexBasis = 0;
  let sumWidth = 0;
  for (let i = index; i < index + colSpan; i += 1) {
    const col = columns[i];
    if (col.width) {
      sumWidth += col.width;
    } else if (col.actions) {
      sumWidth += 30 * col.actions.length + 32;
    } else {
      sumFlexBasis += col.flexBasis || defaultFlexBasis;
    }
  }

  // Get the width/flex totals for all columns
  // We need to exclude widths of fixed-width columns that wind up flexed due to colSpans too.
  // This is why this atrocious looking 'colSpanBlocker' exists...
  let totalFlexBasis = 0;
  let totalWidth = 0;
  let colSpanBlocker = -1;
  let colSpanWidth = 0;
  let colSpanHasFlex = false;
  for (let i = 0; i < columns.length; i += 1) {
    const col = columns[i];
    const colColSpan = getColSpan(columns[i], model, totalRow);

    if (colSpanBlocker < i) {
      if (!colSpanHasFlex && colSpanWidth > 0) {
        totalWidth += colSpanWidth;
      }

      if (colColSpan > 1) {
        colSpanBlocker = i + colColSpan - 1;
      }

      colSpanWidth = 0;
      colSpanHasFlex = false;
    }

    if (col.width) {
      if (colSpanBlocker < i) {
        totalWidth += col.width;
      } else {
        colSpanWidth += col.width;
      }
    } else if (col.actions) {
      if (colSpanBlocker < i) {
        totalWidth += 30 * col.actions.length + 32;
      } else {
        colSpanWidth += col.width;
      }
    } else {
      totalFlexBasis += col.flexBasis || defaultFlexBasis;
      if (colSpanBlocker >= i) {
        colSpanHasFlex = true;
      }
    }
  }

  if (!colSpanHasFlex && colSpanWidth > 0) {
    totalWidth += colSpanWidth;
  }

  return {
    width: sumFlexBasis > 0 ? `calc((100% - ${totalWidth}px) * ${sumFlexBasis} / ${totalFlexBasis})` : sumWidth
  };
}

@observer
class Cell extends Component {
  shouldComponentUpdate(nextProps) {
    const { value, model, responsiveMode, columns, virtualized } = this.props;

    return (
      virtualized ||
      value !== nextProps.value ||
      model !== nextProps.model ||
      responsiveMode !== nextProps.responsiveMode ||
      columns.length !== nextProps.columns.length
    );
  }

  render() {
    const { column, model, responsiveMode, value: rawValue, collection } = this.props;
    const style = getCellStyle(this.props);
    style.order = responsiveMode ? column.responsiveOrder : undefined;

    if (column.type === CELL_TYPES.ACTION) {
      const tdClassName = classNames('td actions', { select: column.key === 'select' });
      return (
        <Box className={tdClassName} style={style} onClick={stopBubbling}>
          {column.actions.map(action => action(model))}
        </Box>
      );
    }

    if (column.type === CELL_TYPES.SPACER) {
      return <Box className="td spacer" style={style} />;
    }

    const value = column.renderer ? column.renderer({ value: rawValue, column, model, collection }) : rawValue;
    const className = classNames('td', column.className, {
      'align-right': column.align === 'right',
      'small-row-title': responsiveMode && column.responsiveOrder === 1,
      bracket: column.type === 'bracket'
    });
    const ellipsis = column.ellipsis !== undefined ? column.ellipsis : !column.wrapText;

    return (
      <Flex className={className} style={style} wrap={column.wrapText}>
        {responsiveMode && <span className="table-column-name">{column.label}</span>}
        {ellipsis && (
          <span className="pt-text-overflow-ellipsis" title={rawValue ? rawValue.toString() : undefined}>
            {value}
          </span>
        )}
        {!ellipsis && value}
      </Flex>
    );
  }
}

export default Cell;
