import React, { Component } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Field, Input, Switch, Select } from 'components/forms';

@inject('$devices', '$dictionary')
@observer
class IpSnmpSettings extends Component {
  static contextTypes = {
    form: any
  };

  componentWillUpdate() {
    this.updateRules();
  }

  updateRules = () => {
    const { form } = this.context;
    const renderSnmpV3 = form.getValue('device_snmp_v3_conf_enabled');
    const fieldState = form.getField('device_snmp_v3_conf.UserName');
    let rules = '';
    if (renderSnmpV3) {
      rules = 'required';
    }
    form.setRules(fieldState, rules);
  };

  render() {
    const { helpText, $devices, $dictionary } = this.props;
    const { form } = this.context;
    const { device_snmp_v3_conf_enabled, device_subtype, device_name: deviceName } = form.getValues();
    const { snmpPollingIps } = $devices.routerSettings;
    const kbLink = $dictionary.getHelpUrl('admin', 'configuringSNMP');
    const deviceType = $devices.getDeviceTypeFromSubtype(device_subtype);

    return (
      <Box col={12}>
        <Box className="pt-callout pt-intent-primary" mb={2}>
          <p>
            Allow us to SNMP poll your router from these IPs using the Community supplied in the device configuration.
          </p>
          <Flex mb={2}>
            <Box ml={2} style={{ width: 220 }}>
              Kentik SNMP polling IPs:
            </Box>
            <Box className="pt-intent-primary-text pt-monospace-text">
              <b>{snmpPollingIps}</b>
            </Box>
          </Flex>
          <AnchorButton
            rel="noopener noreferrer"
            className="pt-minimal"
            intent={Intent.PRIMARY}
            iconName="share"
            target="_blank"
            href={kbLink}
          >
            <strong>
              Need help configuring SNMP polling to {deviceName ? <strong>{deviceName}</strong> : 'this device'}?
            </strong>
          </AnchorButton>
        </Box>
        <Field
          name="minimize_snmp"
          helpText={helpText.minimize_snmp}
          inputStyle={{ width: 160 }}
          rules={deviceType === 'router' ? 'required' : []}
        >
          <Select />
        </Field>

        <Field name="device_snmp_ip" helpText={helpText.device_snmp_ip} inputStyle={{ width: 200 }}>
          <Input />
        </Field>

        <div>
          <Field name="device_snmp_community" helpText={helpText.snmp_community} inputStyle={{ width: 300 }}>
            <Input />
          </Field>

          <Field name="device_snmp_v3_conf_enabled" onChange={this.updateRules}>
            <Switch />
          </Field>

          {device_snmp_v3_conf_enabled && (
            <Box className="pt-callout" mb={2}>
              <Field name="device_snmp_v3_conf.UserName" inputStyle={{ width: 350 }}>
                <Input />
              </Field>

              <Field name="device_snmp_v3_conf.AuthenticationProtocol" inputStyle={{ width: 160 }}>
                <Select />
              </Field>

              <Field name="device_snmp_v3_conf.AuthenticationPassphrase" inputStyle={{ width: 300 }}>
                <Input />
              </Field>

              <Field name="device_snmp_v3_conf.PrivacyProtocol" inputStyle={{ width: 160 }}>
                <Select />
              </Field>

              <Field name="device_snmp_v3_conf.PrivacyPassphrase" inputStyle={{ width: 300 }}>
                <Input />
              </Field>
            </Box>
          )}
        </div>
      </Box>
    );
  }
}

export default IpSnmpSettings;
