import { action, observable, toJS } from 'mobx';

import { showSuccessToast } from 'components/Toast';
import AccessControlModel from 'models/AccessControlModel';
import CompanySubtenancy from 'models/CompanySubtenancy';
import CompanyPermissions from 'models/sudo/CompanyPermissions';
import AddOnCollection from 'models/addOns/AddOnCollection';

import api from 'util/api';

class CompanySettingsStore {
  @observable
  company_max_fps;

  @observable
  flow_ips;

  @observable
  port;

  @observable
  acls = new AccessControlModel();

  @observable
  saving = {};

  @observable
  error = null;

  @observable
  loadedAcls = false;

  @observable
  subtenancy = new CompanySubtenancy();

  @observable
  dns = null;

  @observable
  permissions = new CompanyPermissions({});

  @observable
  permissionsLastLoaded;

  @observable
  addOns = new AddOnCollection({});

  @observable
  addOnsLastLoaded;

  deviceLabelRegexDialogOpen = false;

  @observable
  deviceLabelRegexEnabled = null;

  @action
  initialize() {
    const promises = [];
    if (this.store.$auth.isAdministrator) {
      promises.push(this.load());
      promises.push(this.loadAddOns(true));
      promises.push(this.loadPermissions(true)); // this may have to be for all users at some point.
      promises.push(this.fetchDeviceNameAsRegex());
    }
    return Promise.all(promises);
  }

  @action
  load() {
    api.get('/api/portal/companySettings').then(({ company_max_fps, flow_ips, access_control, port }) => {
      this.company_max_fps = company_max_fps;
      this.flow_ips = flow_ips;
      this.port = port;

      if (access_control) {
        this.acls.set(AccessControlModel.fromPayload(access_control));

        this.loadedAcls = true;
      }
    });
  }

  @action
  loadPermissions(force) {
    // only fetch if not already fetched or force param is truthy.
    if (!this.permissionsLastLoaded || force) {
      this.permissions.fetch().then(() => (this.permissionsLastLoaded = Date.now()));
    }
  }

  @action
  loadAddOns(force) {
    // only fetch if not already fetched or force param is truthy.
    if (!this.addOnsLastLoaded || force) {
      this.addOns.fetch().then(() => (this.addOnsLastLoaded = Date.now()));
    }
  }

  @action
  saveAcls = (type, access_control) => {
    this.saving[type] = true;

    const payload = this.acls.set(access_control).toPayload();

    return api.put('/api/portal/companySettings', { data: { access_control: payload } }).then(
      () => {
        this.acls.set(access_control);
        this.saving[type] = false;
        showSuccessToast('Access controls saved successfully');
      },
      () => {
        this.saving[type] = false;
      }
    );
  };

  @action
  loadDns() {
    api.get('/api/portal/companySettings/dns').then(dns => {
      this.dns = dns;
    });
  }

  @action
  removeDnsIp = ip => {
    if (ip && this.dns) {
      const dnsSettings = toJS(this.dns);
      const idx = dnsSettings.dnsIps.indexOf(ip);
      if (idx > -1) {
        dnsSettings.dnsIps.splice(idx, 1);
        this.saveDns(dnsSettings);
      }
    }
  };

  @action
  addDnsIp = ip => {
    if (ip && this.dns) {
      const dnsSettings = toJS(this.dns);
      dnsSettings.dnsIps.push(ip);
      this.saveDns(dnsSettings);
    }
  };

  @action
  reverseLookupIp = async ip => api.post('/api/portal/companySettings/dns/verify', { data: { ip } });

  @action
  saveDns(dnsSettings) {
    if (this.dns) {
      this.saving.dns = true;
      api.post('/api/portal/companySettings/dns', { data: dnsSettings }).then(
        () => {
          this.dns = dnsSettings;
          this.saving.dns = false;
          showSuccessToast('DNS settings saved successfully');
        },
        () => {
          // reset dns info on fail.
          this.loadDns();
          this.saving.dns = false;
        }
      );
    }
  }

  @action
  setDeviceLabelRegexDialogOpen = open => {
    this.deviceLabelRegexDialogOpen = open;
  };

  @action
  fetchDeviceNameAsRegex = () =>
    api
      .get('/api/portal/companySettings/deviceNameAsRegex')
      .then(resp => (this.deviceLabelRegexEnabled = resp.tagNamesRegex));

  @action
  setDeviceNameAsRegex = enabled =>
    api.post('/api/portal/companySettings/deviceNameAsRegex', { data: { enabled } }).then(() => {
      this.deviceLabelRegexEnabled = enabled;
      this.setDeviceLabelRegexDialogOpen(false);
    });
}

export default new CompanySettingsStore();
