/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { isEqual } from 'lodash';
import { toJS } from 'mobx';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex } from 'components/flexbox';
import Icon from 'components/Icon';
import { UP, DOWN, ENTER, ESC, TAB } from 'util/keyCodes';

import SelectPopover from './SelectPopover';
import { getOption, getOptionsWithNone, getValues } from './selectHelpers';

export const defaultValueRenderer = (option, placeholder) => {
  if (!option) {
    return <span className="pt-text-muted">{placeholder || 'Select a value...'}</span>;
  }

  const className = classNames('pt-text-overflow-ellipsis', option.className);

  const iconName = option ? option.iconCls || option.iconName : false;

  return (
    <span className={className}>
      {iconName && <Icon name={iconName} style={{ marginRight: 6 }} />}
      {option && option.label}
    </span>
  );
};

@observer
class Select extends Component {
  static defaultProps = {
    autoComplete: false,
    exactMatch: true,
    onQuery: null,
    disabled: false,
    loading: false,
    multi: false,
    clearable: false,
    valueRenderer: defaultValueRenderer,
    optionLimit: -1,
    minFilterChars: 0
  };

  state = {
    isOpen: false,
    options: null,
    optionsFilter: '',
    selectedIndex: -1
  };

  componentWillMount() {
    const { autoComplete, multi, onQuery, exactMatch, field } = this.props;

    if (multi && autoComplete) {
      throw new Error('Kentik AutoComplete component does not support multi-select');
    } else if (onQuery && exactMatch) {
      if (multi) {
        this.filterOptions('', { debounce: false });
      } else {
        const value = field.getValue();

        if (value) {
          this.filterOptions(value, { debounce: false });
        }
      }
    }
  }

  componentWillUpdate(nextProps) {
    const { isOpen } = this.props;

    if (!isOpen && nextProps.isOpen) {
      this.showOptions();
    }
  }

  handleFilterOptions = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    this.filterOptions(value);

    onChange(value);
  };

  handleKeyboardEvents = e => {
    const { field } = this.props;
    const { isOpen, selectedIndex } = this.state;
    const selectOptions = this.getFilteredOptions();

    const key = e.keyCode ? e.keyCode : e.which;
    const keyCodes = [UP, DOWN, ENTER, ESC, TAB];

    if (keyCodes.includes(key)) {
      e.stopPropagation();

      if (key === DOWN) {
        this.setState(
          {
            selectedIndex: selectedIndex < selectOptions.length - 1 ? selectedIndex + 1 : 0
          },
          this.updateScrollPosition
        );
      }
      if (key === UP) {
        this.setState(
          {
            selectedIndex: selectedIndex > 0 ? selectedIndex - 1 : selectOptions.length - 1
          },
          this.updateScrollPosition
        );
      }

      if (key === ENTER) {
        if (selectedIndex !== -1) {
          const item = selectOptions[selectedIndex];

          if (item && field.getValue() !== item.value) {
            this.handleSelectItem(field, item.value);
          } else {
            this.setState({ isOpen: false, selectedIndex: -1 });
          }
        } else {
          this.setState({ isOpen: false });
        }
      }

      if (key === ESC || key === TAB) {
        this.setState({ isOpen: false, selectedIndex: -1 });
      }
    } else if (!isOpen) {
      this.setState({ isOpen: true });
    }
  };

  updateScrollPosition = () => {
    const { selectedIndex } = this.state;

    if (this.scrollEl) {
      const itemHeight = this.scrollEl.firstChild.offsetHeight;
      const selectedIndexY = (selectedIndex + 1) * itemHeight;

      if (selectedIndexY > this.scrollEl.scrollTop + this.scrollEl.offsetHeight) {
        this.scrollEl.scrollTop = selectedIndexY - this.scrollEl.offsetHeight + 8;
      } else if (selectedIndexY - itemHeight < this.scrollEl.scrollTop) {
        this.scrollEl.scrollTop = selectedIndexY - itemHeight;
      }
    }
  };

  handleScrollRef = refs => {
    this.scrollEl = refs;
  };

  handleSelectItem = (field, value, option) => {
    const { onChange, multi } = this.props;

    if (option && !multi) {
      this.state.options = [option];
    }

    const options = this.getFilteredOptions();
    /* eslint-disable eqeqeq */
    const selectedIndex = options.findIndex(opt => opt.value == value || isEqual(opt.value, value));

    this.setState({ selectedIndex });

    if (multi) {
      onChange(getValues(field).concat(value));
    } else {
      onChange(value);
    }

    this.closeOptions();
  };

  handleUnselectItem = (field, value) => {
    const { onChange } = this.props;

    return e => {
      e.stopPropagation();

      const values = getValues(field);
      /* eslint-disable eqeqeq */
      const idx = values.findIndex(currValue => currValue == value || isEqual(currValue, value));
      values.splice(idx, 1);
      onChange(values);
    };
  };

  handleRef = refs => {
    this.wrapperEl = refs;
  };

  handlePopoverRef = refs => {
    this.popoverEl = refs;
  };

  handleDocumentClick = e => {
    if (this.wrapperEl && !this.wrapperEl.contains(e.target) && this.popoverEl && !this.popoverEl.contains(e.target)) {
      this.closeOptions();
    }
  };

  showOptions() {
    const { field, showFilter } = this.props;

    if (!showFilter) {
      this.filterOptions(field.getValue());
    }

    this.setState({ isOpen: true });
    document.addEventListener('click', this.handleDocumentClick);
  }

  closeOptions() {
    const { onClose } = this.props;

    this.setState({ isOpen: false });
    document.removeEventListener('click', this.handleDocumentClick);

    if (onClose) {
      onClose();
    }
  }

  filterOptions(optionsFilter, onQueryOpts = {}) {
    const { onQuery, field, exactMatch } = this.props;

    if (onQuery) {
      if (exactMatch) {
        const { selectedIndex, options } = this.state;
        const value =
          selectedIndex >= 0 && options && options.length > selectedIndex
            ? options[selectedIndex].value
            : field.getValue();
        this.setState({ fetchingOptions: true });
        onQuery(optionsFilter, { ...onQueryOpts, id: field._id }).then(newOptions => {
          this.setState({ fetchingOptions: false });
          const newSelectedIndex = newOptions.findIndex(option => option.value === value);
          this.setState({ options: newOptions, selectedIndex: newSelectedIndex });
        });
      } else {
        this.setState({ fetchingOptions: true });
        onQuery(optionsFilter, { ...onQueryOpts, id: field._id }).then(options => {
          this.setState({ fetchingOptions: false });
          this.setState({ options, selectedIndex: -1 });
        });
      }
    } else {
      this.setState({ selectedIndex: -1 });
    }
  }

  getFilteredOptions() {
    const { autoComplete, clearable, exactMatch, field, minFilterChars, onQuery, options, optionLimit } = this.props;
    const { optionsFilter, options: dynamicOptions } = this.state;

    const allOptions = field.options || options ? toJS(field.options || options) : [];
    let filteredOptions = allOptions;

    if (onQuery && dynamicOptions) {
      filteredOptions = dynamicOptions;
    } else if (autoComplete && optionsFilter) {
      const filter = optionsFilter.toLowerCase();
      filteredOptions = allOptions.filter(
        ({ label, value, filterLabel }) =>
          (filterLabel && filterLabel.toLowerCase().includes(filter)) ||
          (typeof label === 'string' && label.toLowerCase().includes(filter)) ||
          (typeof value === 'string' && value.toLowerCase().includes(filter)) ||
          (typeof value === 'number' && value.toString().startsWith(filter))
      );
    }

    if (minFilterChars > 0 && optionsFilter.length < minFilterChars && !exactMatch) {
      return [];
    }

    const allFilteredOptions = clearable ? getOptionsWithNone(filteredOptions) : filteredOptions;

    if (optionLimit > 0 && !exactMatch) {
      return allFilteredOptions.slice(0, optionLimit);
    }

    return allFilteredOptions;
  }

  getValueOptions(selectOptions) {
    const { field, multi, onQuery, placeholder, valueRenderer } = this.props;
    const { options } = this.state;
    const values = getValues(field);
    const value = values && values.join ? values.join(',') : values;

    if (onQuery && value) {
      const option = options ? options.find(opt => opt.value === value) : null;
      return valueRenderer({ value, label: option ? option.label : value }, placeholder);
    } else if (!multi) {
      const selectedOption = getOption(selectOptions, value);
      return valueRenderer(selectedOption, placeholder);
    }

    return values.map(val => {
      const valueOption = getOption(selectOptions, val);

      if (!valueOption) {
        return null;
      }

      return (
        <Flex key={valueOption.key || val} className="pt-tag pt-tag-removable pt-minimal">
          {valueRenderer(valueOption, placeholder)}
          <button className="pt-tag-remove" onClick={this.handleUnselectItem(field, val)} />
        </Flex>
      );
    });
  }

  renderMultiValues(isOpen, valueOptions) {
    const { inputStyle, placeholder, field } = this.props;

    const inputClassNames = classNames('pt-input', { 'pt-input-focus': isOpen });

    return (
      <div className="pt-input-group" style={inputStyle}>
        <div className={inputClassNames} tabIndex="0" onClick={() => this.showOptions()}>
          {valueOptions.length ? (
            valueOptions
          ) : (
            <span style={{ color: '#ADB7BF' }}>{placeholder || field.placeholder}</span>
          )}
        </div>
      </div>
    );
  }

  renderSingleValue(isOpen, valueOptions) {
    const { exactMatch, className, disabled, loading, buttonStyle } = this.props;

    if (!exactMatch) {
      return this.renderAutoComplete();
    }

    return (
      <Button
        active={isOpen}
        className={classNames('pt-fill', className)}
        disabled={disabled}
        loading={loading}
        onClick={() => this.showOptions()}
        rightIconName="chevron-down"
        style={buttonStyle}
      >
        {valueOptions}
      </Button>
    );
  }

  renderAutoComplete() {
    const { className, field } = this.props;

    const groupClassName = classNames('pt-input-group', className);
    const inputClassName = classNames('pt-input', className);

    return (
      <div className={groupClassName}>
        <input
          {...field.getProps()}
          type="text"
          className={inputClassName}
          onFocus={() => this.showOptions()}
          onKeyDownCapture={this.handleKeyboardEvents}
          onChange={this.handleFilterOptions}
          value={field.getValue()}
        />
      </div>
    );
  }

  render() {
    const { exactMatch, field, onQuery, options, multi, showFilter, style } = this.props;
    const { isOpen, selectedIndex, fetchingOptions } = this.state;

    const selectOptions = field.options || options ? toJS(field.options || options) : [];
    const valueOptions = this.getValueOptions(selectOptions);

    return (
      <div ref={this.handleRef} className={classNames('select-wrapper', { multi })} style={style}>
        <SelectPopover
          {...this.props}
          canEscapeKeyClose
          isModal={multi}
          isOpen={isOpen}
          onSelectItem={this.handleSelectItem}
          onClose={() => this.closeOptions()}
          options={this.getFilteredOptions()}
          selectedIndex={selectedIndex}
          values={getValues(field)}
          popoverRef={this.handlePopoverRef}
          scrollRef={!exactMatch ? this.handleScrollRef : undefined}
          fetchingOptions={fetchingOptions}
          onQuery={showFilter ? onQuery : null}
        >
          {multi ? this.renderMultiValues(isOpen, valueOptions) : this.renderSingleValue(isOpen, valueOptions)}
        </SelectPopover>
      </div>
    );
  }
}

export default Select;
