import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { AnchorButton, PopoverInteractionKind, Position, Tag } from '@blueprintjs/core';
import { NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { DropdownMenu, KentikLogo } from 'components';
import Icon, { MaterialIcon } from 'components/Icon';
import { Flex } from 'components/flexbox';
import store from 'stores/store';
import SudoDialog from 'views/Admin/Companies/components/SudoDialog';
import HelpDialog from 'views/Help/HelpDialog';

import ReportIssueDialog from './ReportIssueDialog';
import TrialDaysRemaining from './TrialDaysRemaining';
import UserMenu from './UserMenu';

const APP_ROUTES = [
  {
    name: 'Reports',
    to: '/library',
    icon: 'folder-open'
  },
  {
    name: 'Alarms',
    to: '/alerting/alerts',
    icon: 'notifications',
    showAlertBadge: true,
    isMaterialIcon: true
  },
  {
    name: 'Alert History',
    to: '/alerting/history',
    icon: 'history'
  }
];

@inject('$app', '$auth', '$alerts', '$companySettings', '$sudo', '$exports')
@observer
class SubtenantNavMenu extends Component {
  handleSudo = () => {
    this.props.$sudo.setSudoOpen(true);
  };

  handleLogout = async () => store.logout();

  renderNavLink(route) {
    const { history, $alerts } = this.props;

    const Menu = route.menu;

    const LinkCmp = route.to ? NavLink : 'button';
    const NavIcon = route.isMaterialIcon ? MaterialIcon : Icon;
    const className = classNames('pt-dark pt-button pt-minimal nav-button', {
      'pt-active': history.location.pathname.startsWith(route.to || route.urlRoot)
    });

    const navLink = (
      <LinkCmp className={className} key={route.name} to={route.to}>
        <NavIcon name={route.icon} title={route.name} />
        <span className="hidden-sm" style={{ marginLeft: 6 }}>
          {route.name}
        </span>
        {route.showAlertBadge &&
          $alerts.alertCount > 0 && (
            <Tag className={`pt-small ${$alerts.alertIntent}`} style={{ marginLeft: 6 }}>
              {$alerts.alertCount}
            </Tag>
          )}
        {route.menu && <span className="pt-icon-standard pt-icon-caret-down pt-align-right" />}
      </LinkCmp>
    );

    if (Menu) {
      return (
        <DropdownMenu
          key={route.name}
          content={Menu && <Menu history={history} />}
          interactionKind={PopoverInteractionKind.CLICK}
          tetherOptions={{ offset: '-4px 0' }}
        >
          {navLink}
        </DropdownMenu>
      );
    }

    return navLink;
  }

  renderNavLinks() {
    const { $auth } = this.props;

    const menuItems = [];

    APP_ROUTES.forEach(route => {
      if (!route.adminOnly || $auth.isAdministrator) {
        menuItems.push(this.renderNavLink(route));
      }
    });

    return menuItems;
  }

  render() {
    const { $app, $auth, $sudo, location } = this.props;
    const { activeUser, activeUserDisplay } = $auth;
    const className = classNames('pt-navbar portal-navbar');
    const buttonClassName = classNames('pt-dark', 'pt-minimal', 'nav-button', 'pt-intent-primary');
    const { subtenancy } = $auth.openConfig;

    if (location.pathname === '/login' || location.pathname === '/signup') {
      return null;
    }

    return (
      <Flex className={className} align="center" justify="space-between" px={2}>
        {/* {envName && <EnvironmentCallout env={envName} />} */}
        <Flex align="center" flexAuto>
          <a href={window.location.origin}>
            {subtenancy &&
              subtenancy.config &&
              subtenancy.config.icon && (
                <img
                  alt={subtenancy.subdomain}
                  src={`/api/portal/icons/${subtenancy.company_id}`}
                  style={{ width: 'auto', height: 40 }}
                />
              )}
            {(!subtenancy || !subtenancy.config || !subtenancy.config.icon) && <KentikLogo />}
          </a>
          {$auth.authenticated && !$auth.twoFactorVerifyRequired && this.renderNavLinks()}
        </Flex>
        {$auth.authenticated &&
          !$auth.twoFactorVerifyRequired && (
            <Flex align="center">
              <TrialDaysRemaining user={activeUser} />

              <DropdownMenu
                content={<UserMenu />}
                position={Position.BOTTOM_RIGHT}
                interactionKind={PopoverInteractionKind.CLICK}
                tetherOptions={{ offset: '-4px 0' }}
              >
                <AnchorButton className={buttonClassName} style={{ margin: 0, padding: '2px 8px' }}>
                  <Flex align="center">
                    <MaterialIcon style={{ margin: 0 }} name="person" />
                    <div style={{ lineHeight: '15px', marginLeft: 8, textAlign: 'left' }}>
                      {activeUserDisplay} <br />
                      <small className="pt-normal pt-text-muted">{activeUser.userGroup.name}</small>
                    </div>
                    <span className="pt-icon-standard pt-icon-caret-down pt-align-right" />
                  </Flex>
                </AnchorButton>
              </DropdownMenu>
            </Flex>
          )}
        {$auth.authenticated &&
          $auth.twoFactorVerifyRequired && (
            <div>
              <AnchorButton className={buttonClassName} onClick={this.handleLogout}>
                <MaterialIcon style={{ margin: 0 }} name="exit_to_app" />
              </AnchorButton>
            </div>
          )}
        <ReportIssueDialog isOpen={$app.reportIssueDialogOpen} onClose={$app.toggleReportIssueDialog} />
        {($auth.hasSudo || $auth.isSpoofed) && (
          <SudoDialog isOpen={$sudo.sudoOpen} onClose={() => $sudo.setSudoOpen(false)} />
        )}
        {$app.showHelp &&
          $app.helpButtonSource === 'nav' && <HelpDialog isOpen response={$app.helpText} buttonSource="nav" />}
      </Flex>
    );
  }
}

export default withRouter(SubtenantNavMenu);
