import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { NonIdealState } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex } from 'components/flexbox';
import $app from 'stores/$app';

export function getAllFormsPayload(formToIgnore) {
  const forms = {};

  (window.currentForm || []).filter(f => f !== formToIgnore).forEach((f, idx) => {
    forms[f.options.name || idx] = f.getValues();
  });

  return forms;
}

export const ErrorMessage = observer(props => {
  const { fullScreen, hasOutdatedBundle, showDescription } = props;
  const className = classNames({ page: fullScreen });

  const message = (
    <span>
      {hasOutdatedBundle && (
        <span>
          Kentik has been notified of this error, but it appears you are running with outdated resources.
          <br />
          We recommend refreshing your browser and feel free to report an issue if the problem persists.
        </span>
      )}
      {!hasOutdatedBundle && (
        <span>Kentik has been notified of this error, but feel free to report an issue if the problem persists</span>
      )}
    </span>
  );

  console.info(showDescription, message);

  return (
    <Flex className={className} flexAuto flexColumn justify="center" align="center" style={{ height: '100%' }}>
      <NonIdealState title="Error Occurred" visual="issue" description={showDescription && message} />
    </Flex>
  );
});

export default function(componentName) {
  const boundaryClass = class AbstractErrorBoundary extends Component {
    state = {
      hasOutdatedBundle: false
    };

    componentDidCatch(error, errorInfo) {
      const { platform, userAgent } = window.navigator;
      const { pathname } = window.location;
      const { clientWidth, clientHeight } = document.querySelector('body');

      console.error(`Error Boundary ${componentName}`, error, errorInfo);

      $app.fetchPortalVersion().then(portalVersion => {
        const hasOutdatedBundle = portalVersion.portal.commit !== $app.portalVersion.portal.commit;

        $app.submitErrorReport({
          clientHeight,
          clientWidth,
          info: {
            hasOutdatedBundle,
            componentName,
            allForms: getAllFormsPayload()
          },
          pathname,
          platform,
          userAgent,
          error: error.toString(),
          errorInfo
        });

        this.setState({ error, hasOutdatedBundle });
      });
    }

    renderBody() {
      console.warn('Required renderBody() not implemented!');
    }

    render() {
      const { error, hasOutdatedBundle } = this.state;
      const { fullScreen } = this.props;
      const { errorReportSuccess } = $app;

      if (error) {
        return (
          <ErrorMessage
            fullScreen={fullScreen}
            showDescription={errorReportSuccess}
            hasOutdatedBundle={hasOutdatedBundle}
          />
        );
      }

      return this.renderBody();
    }
  };

  return observer(boundaryClass);
}
