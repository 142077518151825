import { computed } from 'mobx';
import { uniq, flatten } from 'lodash';

import Collection from '../Collection';
import Device from './Device';

class DeviceCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'device_name',
      direction: 'asc'
    };
  }

  get secondarySort() {
    return this.defaultSortState;
  }

  get url() {
    return '/api/portal/devices';
  }

  get model() {
    return Device;
  }

  get jsonRoot() {
    return 'devices';
  }

  get useAsyncAdd() {
    return true;
  }

  get presetGroups() {
    return [
      {
        name: 'site.site_name',
        label: 'Site',
        sortBy: 'site.site_name',
        iconName: 'map-marker'
      },
      {
        name: 'plan.id',
        label: 'Plan',
        iconName: 'card_membership',
        materialIcon: true
      },
      {
        name: 'deviceSubtype',
        label: 'Type',
        iconName: 'dev-router-plain'
      },
      {
        name: 'appliedLabels',
        label: 'Label',
        sortBy: 'appliedLabels',
        iconName: 'tag'
      }
    ];
  }

  getFilterValues(model) {
    return ['id', 'device_name', 'site.site_name', 'flowType', 'deviceType', 'sending_ips', 'appliedLabels'].map(
      name => model[name] || model.get(name)
    );
  }

  @computed
  get percentUnlabeled() {
    return this.isRequestActive('fetching') ? 0 : Math.floor((this.unlabeledDevices.length / this.size) * 100);
  }

  @computed
  get unlabeledDevices() {
    return this.models.filter(device => !device.hasLabels);
  }

  @computed
  get deviceIdNameHash() {
    const hash = {};
    this.models.forEach(device => {
      hash[device.id] = device.get('device_name');
    });
    return hash;
  }

  // returns a unique array of label names for the selected models
  @computed
  get selectedLabelNames() {
    if (!this.selected || !this.selected.map) {
      return [];
    }

    const labels = flatten(this.selected.map(m => m.get('labels').map(l => l.name)));
    return uniq(labels);
  }
}

export default DeviceCollection;
