import React from 'react';
import { observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { FormActions } from 'components/forms';

const Summary = ({ children, model, form, onRemove, onSubmit, onCancel, entityName, title }) => (
  <Flex flexColumn p={0} style={{ height: '100%' }}>
    <Box flexAuto p={2}>
      <h4>{title || 'Summary'}</h4>
      {children}
    </Box>
    <FormActions
      form={form}
      model={model}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onRemove={onRemove}
      entityName={entityName}
    />
  </Flex>
);

export default observer(Summary);
