import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MOMENT_FN } from 'util/dateUtils';

import Highchart from 'components/Highchart';

function formatTotalData(data) {
  return data.map(({ ts, total_alarms }) => ({
    x: MOMENT_FN(ts).valueOf(),
    y: parseInt(total_alarms, 10)
  }));
}

function formatNewData(data) {
  return data.map(({ ts, new_alarms }) => ({
    x: MOMENT_FN(ts).valueOf(),
    y: parseInt(new_alarms, 10)
  }));
}

@inject('$app')
@observer
class AlarmsChart extends Component {
  getSeries = data => [
    {
      data: formatTotalData(data),
      type: 'line',
      color: '#db3737',
      name: 'Total Alarms'
    },
    {
      data: formatNewData(data),
      type: 'column',
      color: '#157ff3',
      name: 'New Alarms'
    }
  ];

  getChartOptions() {
    const { darkThemeEnabled } = this.props.$app;

    const textColor = darkThemeEnabled ? '#dedede' : '#6666666';

    return {
      chart: {
        animation: true,
        zoomType: 'x',
        height: 90
      },
      title: null,
      credits: {
        enabled: false
      },
      xAxis: {
        gridLineColor: textColor,
        gridLineWidth: 1,
        crosshair: true,
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%m/%e',
          minute: '%H:%M'
        },
        title: {
          style: { color: textColor }
        },
        labels: { style: { color: textColor } }
      },
      yAxis: {
        min: 0,
        gridLineColor: textColor,
        gridLineWidth: 1,
        title: {
          text: null
        },
        labels: { style: { color: textColor } }
      },
      plotOptions: {
        series: {
          animation: true
        }
      },
      legend: false,
      tooltip: {
        shared: true
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
              },
              yAxis: {
                labels: {
                  align: 'left',
                  x: 0,
                  y: -5
                },
                title: {
                  text: null
                }
              },
              subtitle: {
                text: null
              },
              credits: {
                enabled: false
              }
            }
          }
        ]
      }
    };
  }

  render() {
    const { collection } = this.props;

    return (
      <Highchart
        collection={collection}
        lastUpdated={collection.lastUpdated}
        config={this.getChartOptions()}
        getSeries={this.getSeries}
      />
    );
  }
}

export default AlarmsChart;
