import { observable } from 'mobx';
import $devices from 'stores/$devices';
import $deviceGroups from 'stores/$deviceGroups';
import $sites from 'stores/$sites';
import $auth from 'stores/$auth';
import $dictionary from 'stores/$dictionary';

export function getDeviceValues({ all_devices, device_types, device_labels, device_sites, device_name }) {
  const values = observable.map({
    all: [],
    labels: [],
    sites: [],
    types: [],
    devices: []
  });

  if (all_devices) {
    values.set('all', $devices.deviceSummaries);
    return values;
  }

  const types = device_types.map(type => $devices.deviceTypeOptions.find(t => t.value === type));

  const labels = $deviceGroups.deviceLabelOptions.length
    ? device_labels.map(labelId => $deviceGroups.deviceLabelOptions.find(d => d.value === labelId))
    : [];

  const sites = $sites.siteOptions.length
    ? device_sites.map(siteId => $sites.siteOptions.find(s => s.value === siteId))
    : [];

  const devices = $devices.deviceSummaryOptions
    ? device_name.map(name => $devices.deviceSummaryOptions.find(t => t.value === name))
    : [];

  values.set('labels', labels.filter(Boolean));
  values.set('sites', sites.filter(Boolean));
  values.set('types', types.filter(Boolean));
  values.set('devices', devices.filter(Boolean));

  return values;
}

export const getDisabledCloudDimensions = ({ device_name, device_labels, device_sites, device_types, all_devices }) => {
  if ($auth.isPresetCompany) {
    return [];
  }

  const gceFilter = device => device.cloudExportTask && device.cloudExportTask.cloud_provider === 'gce';
  const awsFilter = device => device.cloudExportTask && device.cloudExportTask.cloud_provider === 'aws';
  const azureFilter = device => device.cloudExportTask && device.cloudExportTask.cloud_provider === 'azure';

  let hasGCEDevice;
  let hasAWSDevice;
  let hasAzureDevice;

  if (all_devices) {
    hasGCEDevice = $devices.hasGCEDevice;
    hasAWSDevice = $devices.hasAWSDevice;
    hasAzureDevice = $devices.hasAzureDevice;
  } else {
    const devices = $devices.getUniqueSelectedDevices({
      device_name,
      device_labels,
      device_sites,
      device_types
    });

    hasGCEDevice = devices.some(gceFilter);
    hasAWSDevice = devices.some(awsFilter);
    hasAzureDevice = devices.some(azureFilter);
  }

  if (!hasGCEDevice && !hasAWSDevice && !hasAzureDevice) {
    return 'Cloud';
  }

  const disabledGroups = [];
  if (!hasGCEDevice) {
    disabledGroups.push('Google Cloud Platform');
  }

  if (!hasAWSDevice) {
    disabledGroups.push('Amazon Web Services');
  }

  if (!hasAzureDevice) {
    disabledGroups.push('Microsoft Azure');
  }

  return disabledGroups;
};

export const getDisabledSubtypeDimensions = ({
  all_devices,
  device_types,
  device_labels,
  device_sites,
  device_name
}) => {
  if ($auth.isPresetCompany) {
    return [];
  }
  const subtypes = { ...$dictionary.dictionary.device_subtypes };
  const devices = all_devices
    ? $devices.deviceSummaries
    : $devices.getUniqueSelectedDevices({
        device_name,
        device_labels,
        device_sites,
        device_types
      });

  devices.forEach(device => delete subtypes[device.device_subtype]);
  return Object.values(subtypes).map(subtype => subtype.display_name);
};
