import { observable, action } from 'mobx';

import { showSuccessToast } from 'components/Toast';
import api from 'util/api';

const fixedColumns = {
  i_duration: 90,
  row_number: 100,
  i_start_time: 165,
  f_sum_both_bytes: 150,
  f_sum_both_pkts: 150
};

class QueryEditorStore {
  @observable
  activeQuery = '';

  @observable
  executingQuery = false;

  @observable
  queryResults = [];

  @observable
  columns = [];

  @action
  loadQuery(data) {
    this.executingQuery = true;
    this.setQuery('Loading your query, please wait...');

    return api.post('/api/portal/query/sql/get', { data }).then(response => {
      this.executingQuery = false;
      this.setQuery(response);
      return response;
    });
  }

  @action
  sendRawQuery(query) {
    this.executingQuery = true;

    const data = {
      query
    };

    return api.post('/api/portal/query/sql', { data }).then(
      response => {
        this.executingQuery = false;
        this.queryResults = response.rows;
        this.columns = this.getTableColumns(response.rows);
        showSuccessToast('Query executed successfully');
        return response;
      },
      err => {
        this.executingQuery = false;
        this.queryResults.set([]);
        this.columns.set([]);

        console.warn('Query Editor received SQL error', err);
      }
    );
  }

  @action
  destroy() {
    this.queryResults = [];
  }

  @action
  setQuery(query) {
    this.activeQuery = query;
  }

  @action
  sortQueryResults(column) {
    const { columns, queryResults } = this;
    const { name, sortDir: currentSortDir } = column;
    const sortDir = currentSortDir === 'asc' ? 'desc' : 'asc';
    const sortedQueryResults = queryResults.sort((a, b) => a[name] - b[name]);

    this.columns = columns.map(col => {
      if (col === column) {
        return {
          ...col,
          sorted: true,
          sortDir
        };
      }
      return {
        ...col,
        sorted: false
      };
    });

    this.queryResults = sortDir === 'asc' ? sortedQueryResults : sortedQueryResults.reverse();
  }

  getTableColumns(queryResults) {
    const firstRow = queryResults[0];

    return Object.keys(firstRow).map(col => ({
      name: col,
      label: col,
      width: fixedColumns[col] || null,
      sortDir: 'desc',
      sorted: false
    }));
  }
}

export default new QueryEditorStore();
