import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { any } from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { FormActions } from 'components/forms';
import HelpDialog from 'views/Help/HelpDialog';

@inject('$app')
@observer
class FormDialog extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    canOutsideClickClose: false,
    bodyStyle: {
      padding: 24
    }
  };

  handleCancel = () => {
    const { onClose, collection, history, location, rootUrl, $app } = this.props;

    if (onClose) {
      onClose();
      return;
    }

    if (collection) {
      collection.clearSelection();
    }

    if (rootUrl) {
      history.push($app.adjustUrlForShim(location, rootUrl));
    }
  };

  renderBodyWithSummary() {
    const { formComponent, summaryComponent, summaryHeaderComponent, model, form } = this.props;

    return (
      <Flex gutter={1} style={{ width: 'calc(100% + 16px)' }}>
        <div className="pt-dialog-body-padded-card-scroll-area">
          {React.cloneElement(formComponent, { model, form })}
        </div>

        <Box>
          {summaryHeaderComponent && React.cloneElement(summaryHeaderComponent, { model, form })}
          <div
            className={classNames('pt-dialog-body-padded-card-scroll-area', {
              'with-header': !!summaryHeaderComponent
            })}
          >
            {React.cloneElement(summaryComponent, { model, form })}
          </div>
        </Box>
      </Flex>
    );
  }

  renderBody() {
    const { $app, form, formComponent, formError, model, summaryComponent } = this.props;

    if (formError) {
      return (
        <div className="pt-dialog-body">
          <div className="pt-callout pt-intent-danger pt-icon-error">
            <h5>A fatal error occurred in this form</h5>
            Kentik has been notified of this error, but feel free to report an issue if the problem persists
          </div>
        </div>
      );
    }

    if (model) {
      return (
        <div className="pt-dialog-body" style={{ padding: 0, margin: 0, paddingBottom: 0 }}>
          <Box style={this.props.bodyStyle}>
            {summaryComponent ? this.renderBodyWithSummary() : React.cloneElement(formComponent, { model, form })}
          </Box>
          {$app.showHelp && $app.helpButtonSource === 'dialog' && <HelpDialog isOpen response={$app.helpText} />}
        </div>
      );
    }

    return null;
  }

  renderTitle() {
    const { title, model, headerTools, entityName } = this.props;
    const isNew = model && model.isNew;
    const titleString = title || `${isNew ? 'Add' : 'Edit'} ${entityName}`;

    if (headerTools) {
      return (
        <Flex justify="space-between" align="center">
          <Box flexAuto>{titleString}</Box>
          <Flex justify="flex-end">{React.cloneElement(headerTools, { model })}</Flex>
        </Flex>
      );
    }

    return titleString;
  }

  render() {
    const {
      form,
      model,
      isOpen,
      entityName,
      summaryComponent,
      iconName,
      style,
      formActionsProps,
      canOutsideClickClose
    } = this.props;

    const dialogWidth = summaryComponent ? 'calc(100vw - 80px)' : 1100;

    return (
      <Dialog
        isOpen={isOpen}
        title={this.renderTitle()}
        canOutsideClickClose={canOutsideClickClose}
        onClose={this.handleCancel}
        iconName={iconName}
        transitionName="pt-dialog"
        style={{
          top: 40,
          width: dialogWidth,
          maxWidth: 1600,
          ...style
        }}
      >
        {this.renderBody()}
        <FormActions
          {...formActionsProps}
          onCancel={this.handleCancel}
          form={form}
          model={model}
          entityName={entityName}
        />
      </Dialog>
    );
  }
}

export default withRouter(FormDialog);
