import React from 'react';
import { observer } from 'mobx-react';
import KeyDimensionCell from '../../Table/KeyDimensionCell';
import { formatAlertValue } from '../../alarmHelpers';

const MatchesByTimeRow = props => {
  const { values, onFilterFieldClick, aggr_field, position } = props;
  const { alert_value_25, alert_value_50, alert_value_95, alert_metric } = values;
  const aggr_value = aggr_field && values[aggr_field];

  return (
    <tr>
      <td>
        <KeyDimensionCell values={values} onFilterFieldClick={onFilterFieldClick} />
      </td>
      <td>{position}</td>
      {aggr_field && <td>{formatAlertValue(aggr_value, alert_metric[0])}</td>}
      <td>{formatAlertValue(alert_value_25, alert_metric[0])}</td>
      <td>{formatAlertValue(alert_value_50, alert_metric[0])}</td>
      <td>{formatAlertValue(alert_value_95, alert_metric[0])}</td>
    </tr>
  );
};

export default observer(MatchesByTimeRow);
