import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import classNames from 'classnames';

// import DevTool, { configureDevtool } from 'mobx-react-devtools';
// import { isDev } from 'util/utils';

import { Flex } from 'components/flexbox';
import { ProtectedRoute } from 'components';
import store from 'stores/store';
import initValidators from 'forms/validators';
import asyncComponent from 'util/components/asyncComponent';
import DefaultView from 'views/DefaultView';
import ExpiredTrial from 'views/ExpiredTrial';
import Login from 'views/Login/Login';
import SsoLookup from 'views/Login/SsoLookup';
import LoginInterstitial from 'views/Login/LoginInterstitial';
import NavMenu from 'views/nav/NavMenu';
import InactiveCompany from 'views/InactiveCompany';
import SuspendedCompany from 'views/SuspendedCompany';
import Devices from 'views/Admin/Devices/Devices';
import Users from 'views/Admin/Users/Users';
import FullResults from 'views/Search/FullResults';
import getErrorBoundary from '../util/components/getErrorBoundary';

import NotFound from './NotFound';
import Password from './Password/Password';
import Signup from './Signup';
import VerifyTwoFactor from './VerifyTwoFactor';
import VerifyEmail from './VerifyEmail';
import MessagesDialog from './MessagesDialog';
import ExportDialog from './ExportDialog';
import SudoEjectButtonDialog from './SudoEjectButtonDialog';

const Admin = asyncComponent(() => import(/* webpackChunkName: "admin" */ 'views/Admin/Admin'));
const Profile = asyncComponent(() => import(/* webpackChunkName: "profile" */ 'views/Admin/Profile/Profile'));
const ProfileV4 = asyncComponent(() => import(/* webpackChunkName: "profilev4" */ 'views/Admin/Profile/ProfileV4'));
const Alerting = asyncComponent(() => import(/* webpackChunkName: "alerting" */ 'views/Alerting/Alerting'));
const Analytics = asyncComponent(() => import(/* webpackChunkName: "analytics" */ 'views/Analytics/Analytics'));
const AlertingV4 = asyncComponent(() => import(/* webpackChunkName: "alertingv4" */ 'views/Alerting/AlertingV4'));
const AdminV4 = asyncComponent(() => import(/* webpackChunkName: "adminv4" */ 'views/Admin/AdminV4'));

const ExplorerView = asyncComponent(() => import(/* webpackChunkName: "explorer" */ 'views/Explorer/ExplorerView'));
const BasicExplorerView = asyncComponent(() =>
  import(/* webpackChunkName: "library" */ 'views/Library/BasicExplorerView')
);

// if (isDev) {
//   configureDevtool({
//     updatesEnabled: false
//   });
// }

const ErrorBoundaryCmp = getErrorBoundary('AppWrapper');

@inject('$app', '$auth', '$alerts', '$devices', '$onboarding')
@observer
class AppWrapper extends Component {
  state = {
    appReady: false
  };

  componentWillMount() {
    initValidators();

    /**
     * Assign the `history` instance that React Router uses to all of our stores.
     * This allows stores to control navigation if necessary. See `$explorer.load`
     * for a use case.
     */
    store.setHistory(this.props.history);
  }

  componentDidMount() {
    const { $auth } = this.props;
    $auth.initialize();
    Promise.all([$auth.verifyAuth(), $auth.getOpenConfig()])
      .then(resultsArray => {
        const [authenticated] = resultsArray;
        if (authenticated && !$auth.twoFactorVerifyRequired) {
          return store.initializeApp();
        }
        return null;
      })
      .then(() => {
        this.setState({ appReady: true });
      });
  }

  render() {
    const { $app, $auth, children, location, $onboarding } = this.props;
    const { isAdministrator, hasSudo, isSpoofed, authenticated, twoFactorVerifyRequired } = $auth;

    if (this.state.appReady) {
      // if authenticated with no devices and path isn't already "home" (or other exceptions), force them there.
      if (
        authenticated &&
        !twoFactorVerifyRequired &&
        $onboarding.forceOnboarding &&
        (location.pathname !== '/library' &&
          !location.pathname.startsWith('/onboarding') &&
          !location.pathname.startsWith('/library/createcloud') &&
          !location.pathname.startsWith('/profile') &&
          !location.pathname.startsWith('/verify-two-factor') &&
          !location.pathname.startsWith('/login') &&
          !location.pathname.startsWith('/suspended-company') &&
          !location.pathname.startsWith('/expired-trial') &&
          !location.pathname.startsWith('/inactive-company') &&
          !location.pathname.startsWith('/email/verify'))
      ) {
        return <Redirect to="/library" />;
      }

      return (
        <Flex
          flexColumn
          className={classNames('app', {
            'pt-dark': $app.darkThemeEnabled && $auth.authenticated
          })}
        >
          {!$auth.hideLoginInterstitial && (
            <LoginInterstitial isOpen={$auth.loginInterstitialOpen} onClose={$auth.handleToggleLoginInterstitial} />
          )}
          {!location.pathname.startsWith('/alerting-admin') &&
            !location.pathname.startsWith('/admin4') &&
            !location.pathname.startsWith('/profile4') && <NavMenu />}

          {/* {isDev && <DevTool />} */}

          {children}

          <ErrorBoundaryCmp>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/login.html" render={() => <Redirect to="/" />} />
              <Route exact path="/login/sso/:companyIdentifier" component={Login} />
              <Route exact path="/ssolookup" component={SsoLookup} />
              <Route exact path="/password" component={Password} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/signup.html" render={() => <Redirect to="/signup" />} />
              <Route
                exact
                path="/account-activation"
                render={routeProps => <Password {...routeProps} accountActivation />}
              />
              <ProtectedRoute exact path="/suspended-company" component={SuspendedCompany} />
              <ProtectedRoute exact path="/expired-trial" component={ExpiredTrial} />
              <ProtectedRoute exact path="/inactive-company" component={InactiveCompany} />
              <ProtectedRoute exact path="/email/verify" component={VerifyEmail} />
              <ProtectedRoute exact skip2fa path="/verify-two-factor" component={VerifyTwoFactor} />
              <ProtectedRoute exact path="/" render={DefaultView} />
              <Redirect exact from="/dashboards" to="/library" />
              <Route
                exact
                path="/dashboards/:id"
                render={({ match }) => <Redirect to={`/library/dashboard/${match.params.id}`} />}
              />
              <Route
                exact
                path="/dashboards/:id/urlParams/:params"
                render={({ match }) => (
                  <Redirect to={`/library/dashboard/${match.params.id}/urlParams/${match.params.params}`} />
                )}
              />
              <ProtectedRoute
                path="/portal"
                render={props => {
                  const { hash } = props.location;

                  if (hash.startsWith('#Dashboards/')) {
                    const dashboardId = hash.substring(hash.indexOf('/') + 1);
                    return <Redirect to={`/library/dashboard/${dashboardId}`} />;
                  } else if (hash.startsWith('#Charts/shortUrl/')) {
                    const urlHash = hash.substring(hash.lastIndexOf('/') + 1);
                    return <Redirect to={`/explorer/${urlHash}`} />;
                  }

                  return <Redirect to="/404" />;
                }}
              />
              {isAdministrator && <ProtectedRoute path="/onboarding/devices" component={Devices} />}
              {isAdministrator && <ProtectedRoute path="/onboarding/users" component={Users} />}
              <ProtectedRoute exact path="/explorer" component={ExplorerView} />
              <ProtectedRoute exact path="/explorer/preset" component={ExplorerView} />
              <ProtectedRoute exact path="/explorer/alarm/:alarmId" component={ExplorerView} />
              <ProtectedRoute exact path="/explorer/:urlHash" component={ExplorerView} />
              <ProtectedRoute exact path="/explorer/urlParams/:params" component={ExplorerView} />
              <ProtectedRoute exact path="/savedView/:viewId" component={ExplorerView} />
              <ProtectedRoute exact path="/library" component={BasicExplorerView} />
              <ProtectedRoute exact path="/library/createcloud/:params" component={BasicExplorerView} />
              <ProtectedRoute exact path="/library/alarm/:alarmId" component={BasicExplorerView} />
              <ProtectedRoute exact path="/library/:templateIdOrHash" component={BasicExplorerView} />

              <ProtectedRoute exact path="/library/:type/:templateIdOrHash" component={BasicExplorerView} />
              <ProtectedRoute
                exact
                path="/library/:type/:templateIdOrHash/urlParams/:params"
                component={BasicExplorerView}
              />
              <ProtectedRoute exact path="/search/:input" component={FullResults} />
              <ProtectedRoute path="/analytics" component={Analytics} />
              <ProtectedRoute path="/alerting" component={Alerting} />
              <ProtectedRoute path="/alerting-admin" component={AlertingV4} />
              {isAdministrator && <ProtectedRoute path="/admin" component={Admin} />}
              {isAdministrator && <ProtectedRoute path="/admin4" component={AdminV4} />}
              {hasSudo && <ProtectedRoute path="/sudo" render={() => <Redirect to="/admin/companies" />} />}
              <ProtectedRoute path="/profile" component={Profile} />
              <ProtectedRoute path="/profile4" component={ProfileV4} />
              <ProtectedRoute path="/v3" component={DefaultView} />
              <ProtectedRoute path="*" component={NotFound} />
            </Switch>
          </ErrorBoundaryCmp>

          <ExportDialog />
          <MessagesDialog />
          {(hasSudo || isSpoofed) && <SudoEjectButtonDialog />}
        </Flex>
      );
    }

    return <div />;
  }
}

export default withRouter(AppWrapper);
