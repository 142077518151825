import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';

import FilterItem from './FilterItem';
import FilterGroup from './FilterGroup';
import FilterConnectorDisplay from './FilterConnectorDisplay';
import SavedFilterDisplay from './SavedFilterDisplay';

@observer
class FilterGroupDisplay extends FilterGroup {
  render() {
    const { group, readOnly } = this.props;

    const className = classNames('pt-card', 'filter-group-body-readonly', {
      'filter-group-body-all': group.connector.getValue() === 'All'
    });

    return (
      <Flex className={className}>
        <Box flexAuto>
          <Flex justify="space-between" align="center">
            <FilterConnectorDisplay {...this.props} />
            {!readOnly && <button style={{ padding: 3 }} className="pt-tag-remove" onClick={this.handleRemoveGroup} />}
          </Flex>

          {group.filters.map((filter, idx) => (
            <FilterItem
              {...this.props}
              key={filter.filterValue._id}
              filter={filter}
              filterField={filter.filterField.getValue()}
              operator={filter.operator.getValue()}
              filterValue={filter.filterValue.getValue()}
              index={idx}
              onRemove={this.handleRemoveFilter}
            />
          ))}

          {group.saved_filters &&
            group.saved_filters.fieldStates.length > 0 && (
              <Box className="filter-groups" p={0}>
                {group.saved_filters.map(filter => (
                  <SavedFilterDisplay
                    key={filter.filter_id._id}
                    filter_id={filter.filter_id.getValue()}
                    is_not={filter.is_not}
                  />
                ))}
              </Box>
            )}

          {group.filterGroups &&
            group.filterGroups.fieldStates.length > 0 && (
              <Box className="filter-groups" p={0} mt={1}>
                {group.filterGroups.map((nestedGroup, index) => (
                  <FilterGroupDisplay
                    {...this.props}
                    key={nestedGroup.connector._id}
                    group={nestedGroup}
                    index={index}
                    groups={group.filterGroups}
                  />
                ))}
              </Box>
            )}
        </Box>
      </Flex>
    );
  }
}

export default FilterGroupDisplay;
