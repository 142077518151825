import React, { Component } from 'react';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { PageNavMenu } from 'components/page';
import getErrorBoundary from 'util/components/getErrorBoundary';
import NotFound from 'views/NotFound';

import ProfileFormWrapper from './ProfileFormWrapper';
import ProfileSettings from './ProfileSettings';
import AuthSettings from './AuthSettings';
import KdeSettings from './KdeSettings';
import Visualizations from './Visualizations';

const profileLinks = [
  {
    divider: true,
    title: 'Profile'
  },
  {
    to: '/profile/info',
    text: 'User Information',
    iconName: 'user'
  },
  {
    to: '/profile/settings',
    text: 'User Settings',
    iconName: 'settings',
    materialIcon: true
  },
  {
    to: '/profile/authentication',
    text: 'Authentication',
    iconName: 'fingerprint',
    materialIcon: true
  },
  {
    to: '/profile/visualizations',
    text: 'Visualizations',
    iconName: 'timeline-area-chart'
  }
];

const navWidth = 225;
const bodyStyle = {
  width: `calc(100vw - ${navWidth}px)`
};

const ErrorBoundaryCmp = getErrorBoundary('Profile');

@inject('$app', '$auth', '$users')
@observer
class Profile extends Component {
  componentWillMount() {
    const { $users } = this.props;

    $users.fetchFullProfile();
    $users.fetchOtps();
  }

  getProfileLinks() {
    const {
      $app: { isSubtenant },
      $auth
    } = this.props;

    if (!isSubtenant && $auth.hasPermission('sql.psql')) {
      return [
        ...profileLinks,
        {
          to: '/profile/kde',
          text: 'Kentik Data Engine (KDE)',
          iconName: 'view_comfy',
          materialIcon: true
        }
      ];
    }

    return profileLinks;
  }

  render() {
    const {
      $auth,
      $app: { isSubtenant }
    } = this.props;

    return (
      <Flex>
        <PageNavMenu links={this.getProfileLinks()} width={navWidth} />
        <Box style={bodyStyle}>
          <ErrorBoundaryCmp>
            <Switch>
              <Route exact path="/profile" render={() => <Redirect to="/profile/info" />} />
              <Route path="/profile/info" component={ProfileFormWrapper} />
              <Route path="/profile/settings" component={ProfileSettings} />
              <Route path="/profile/authentication" component={AuthSettings} />
              <Route path="/profile/visualizations" component={Visualizations} />
              {!isSubtenant && $auth.hasPermission('sql.psql') && <Route path="/profile/kde" component={KdeSettings} />}
              <Route path="*" component={NotFound} />
            </Switch>
          </ErrorBoundaryCmp>
        </Box>
      </Flex>
    );
  }
}

export default withRouter(Profile);
