import React from 'react';
import moment from 'moment';
import {
  DEFAULT_DATETIME_FORMAT,
  MOMENT_FN,
  USER_TIMEZONE,
  getQueryTimeInterval,
  secondsIntervalToText
} from 'util/dateUtils';

const TimeDisplay = props => {
  const { lookback_seconds, starting_time, ending_time, time_format } = props.model ? props.model.get() : props;

  let start;
  let end;

  let momentFn = MOMENT_FN;
  let utcOffset = 0;
  if (time_format) {
    momentFn = time_format === 'Local' ? moment : moment.utc;
    utcOffset = momentFn().utcOffset(); // in minutes
  }
  if (lookback_seconds) {
    start = momentFn().subtract(lookback_seconds, 'second');
    end = momentFn();
  } else {
    start = momentFn(starting_time).add(utcOffset, 'minute');
    end = momentFn(ending_time).add(utcOffset, 'minute');
  }

  const interval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
  const textInterval = secondsIntervalToText(interval);

  const fromToLabelStyle = { display: 'inline-block', width: 35 };

  return (
    <div className="section-display-view">
      <div>
        <span className="pt-text-muted" style={fromToLabelStyle}>
          From:
        </span>
        <strong>{start.format(DEFAULT_DATETIME_FORMAT)}</strong>
      </div>
      <div>
        <span className="pt-text-muted" style={fromToLabelStyle}>
          To:
        </span>
        <strong>{end.format(DEFAULT_DATETIME_FORMAT)}</strong>
      </div>
      <div>
        <span className="pt-text-muted">Window: </span> <strong>{textInterval}</strong>
      </div>
      <div>
        <span className="pt-text-muted">Time Zone: </span> <strong>{time_format || USER_TIMEZONE}</strong>
      </div>
    </div>
  );
};

export default TimeDisplay;
