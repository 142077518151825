import React from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Box } from 'components/flexbox';
import { getSummarySelectLabels } from 'forms/utils';

const SummaryField = (props, context) => {
  const {
    className,
    children,
    defaultValue,
    name,
    showLabel = true,
    inline = false,
    suffix = '',
    summarySelectLabels = false,
    style
  } = props;

  const field = props.field || context.form.getField(name);
  const label = props.label || field.label;
  const value = props.value || field.getValue();
  const options = props.options || field.options;

  const valueClassName = classNames(className, {
    'pt-intent-danger-text': field.hasError && field.dirty,
    'pt-intent-warning-text': field.hasError && field.pristine
  });

  const summaryLabels = summarySelectLabels && getSummarySelectLabels(field, false, suffix, options);

  let formattedValue = value || defaultValue;
  if (!summaryLabels && Array.isArray(value || defaultValue)) {
    formattedValue = formattedValue.join(', ');
  }

  if (inline) {
    return (
      <span className="pt-medium pt-intent-primary-text">
        {children || summaryLabels || formattedValue || 'None'} {!summaryLabels && suffix}
      </span>
    );
  }

  return (
    <Box style={style} mb={1}>
      {showLabel && label && <small className="pt-text-muted">{label}</small>}
      <Box className={valueClassName}>
        {children || summaryLabels || formattedValue || 'None'} {!summaryLabels && suffix}
      </Box>
    </Box>
  );
};

SummaryField.contextTypes = {
  form: any
};

export default observer(SummaryField);
