import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dialog, Button, Intent, Spinner } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Form, Field, Input, Select } from 'components/forms/index';
import { companyPlanRenderer } from 'views/Admin/Companies/components/CompanyTable';
import { isDev } from 'util/utils';

const fields = {
  company: {
    label: 'Company'
  },
  target_user_id: {
    label: 'Spoof As User',
    placeholder: 'Select User...',
    rules: 'required'
  },
  token: {
    label: 'Please enter your TOTP code',
    placeholder: isDev ? 'Token is ignored in dev environments' : undefined
  },
  redirect: {
    label: 'Redirect URL',
    placeholder: '(Optionally) enter a URL to redirect to...',
    defaultValue: '/'
  }
};

const options = {
  name: 'Sudo'
};

@Form({ fields, options })
@inject('$sudo', '$auth')
@observer
class SudoDialog extends Component {
  state = {
    isMakingRequest: false,
    isClearing: false,
    isFetchingCompany: false,
    isOpen: false,
    loading: false
  };

  componentWillReceiveProps(nextProps) {
    const { form, isOpen, $sudo } = this.props;
    const { companiesCollection, selectedCompanyId } = $sudo;

    if (!isOpen && nextProps.isOpen) {
      this.setState({ loading: true });

      companiesCollection.fetch().then(() => {
        this.setState({ isOpen: true, loading: false });
      });
    }

    if (selectedCompanyId !== null) {
      form.getField('company').setValue(selectedCompanyId);
      this.handleCompanyChange(null, selectedCompanyId);
      $sudo.clearSelectedCompanyId();
    }
  }

  handleKeyboardSubmit = e => {
    const { form } = this.props;
    if (e && e.key === 'Enter') {
      e.stopPropagation();
      if (form.valid) {
        this.handleSubmit();
      }
    }
  };

  handleUnspoof = () => {
    const { $sudo } = this.props;

    this.setState({ isClearing: true });

    $sudo.clearSpoofSession({ reload: true });
  };

  handleSubmit = async () => {
    const { form, $sudo } = this.props;
    this.setState({ isMakingRequest: true });

    await $sudo.clearSpoofSession();

    $sudo.launchSudoSession(form.getValues()).then(
      () => {
        const redirect = form.getValue('redirect');
        if (redirect) {
          window.location = redirect;
        } else {
          window.location.reload();
        }
      },
      () => {
        this.setState({ isMakingRequest: false });
      }
    );
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleCancel = () => {
    const { form, onClose } = this.props;

    setTimeout(() => {
      form.reset();
      this.setState({ model: null });
    }, 500);

    onClose();
  };

  handleCompanyChange = (field, companyId) => {
    const { form, $sudo } = this.props;

    this.setState({ isFetchingCompany: true });

    const company = $sudo.companiesCollection.get(companyId);

    company.fetch().then(() => {
      form.setValue('company', companyId);

      this.setState({ model: company, isFetchingCompany: false });

      const users = company.get('users').models;
      const target =
        users.find(model => model.get('user_level') === 2) ||
        users.find(model => model.get('user_level') === 1 && model.get('user_group_id') === null) ||
        users.find(model => model.get('user_group_id') === null) ||
        users[0];

      form.setValue('target_user_id', target.get('id'));
    });
  };

  render() {
    const { form, $auth, $sudo } = this.props;

    if (!form) {
      return null;
    }

    const { isMakingRequest, isFetchingCompany, isOpen, loading, model } = this.state;

    return (
      <Dialog title="Spoof" {...this.props} style={{ top: 100, width: 476, minHeight: 275 }} transitionName="pt-dialog">
        <Box p={3} pb={0} flexAuto>
          <Field name="company" options={$sudo.companiesCollection.selectOptions} onChange={this.handleCompanyChange}>
            <Select
              isOpen={isOpen}
              loading={loading}
              onClose={this.handleClose}
              autoComplete
              exactMatch
              showFilter
              menuWidth={428}
              optionLimit={50}
              minFilterChars={3}
              optionRenderer={({ label, plan, className, selected, selectItem, field, value }) => {
                const onClick = !selected ? () => selectItem(field, value) : undefined;
                return (
                  <Flex key={value} justify="space-between" className={className} onClick={onClick}>
                    <div>
                      {label} <span className="pt-text-muted">({value})</span>
                    </div>
                    <Box>{companyPlanRenderer({ value: plan })}</Box>
                  </Flex>
                );
              }}
            />
          </Field>
        </Box>
        {isFetchingCompany && (
          <Flex justify="center" align="center" p={2}>
            <Spinner />
          </Flex>
        )}
        {model &&
          model.spoofAsUser &&
          !isFetchingCompany && (
            <Box px={3}>
              <Field name="target_user_id" options={model.get('users').userSelectOptions}>
                <Select
                  autoComplete
                  exactMatch
                  showFilter
                  menuWidth={427}
                  optionRenderer={({ label, className, selected, selectItem, field, value, role, user_group_id }) => {
                    const onClick = !selected ? () => selectItem(field, value) : undefined;
                    return (
                      <div key={value} className={className} onClick={onClick}>
                        {label}
                        <div className="pt-text-smaller pt-text-muted">
                          {user_group_id ? '[SUBTENANT] ' : ''}
                          {role}
                        </div>
                      </div>
                    );
                  }}
                />
              </Field>

              <Box className="pt-callout" mt={2}>
                {$auth.isTotpExpired && (
                  <Field name="token" autoFocus>
                    <Input leftIconName="key" onKeyPress={this.handleKeyboardSubmit} />
                  </Field>
                )}
                <Field name="redirect" autoFocus={!$auth.isTotpExpired}>
                  <Input leftIconName="document-open" onKeyPress={this.handleKeyboardSubmit} />
                </Field>
              </Box>
            </Box>
          )}
        <Box className="pt-dialog-footer" mt={2}>
          <Flex>
            <Box flexAuto>
              {$auth.isSpoofed && (
                <Button
                  text="Unspoof"
                  className="pt-intent-danger"
                  onClick={this.handleUnspoof}
                  loading={this.state.isClearing}
                  style={{ minWidth: 110 }}
                />
              )}
            </Box>
            <Button text="Cancel" onClick={this.handleCancel} style={{ marginRight: 8, width: 110 }} />
            <Button
              disabled={!form.valid || !form.dirty}
              intent={Intent.PRIMARY}
              className="pt-medium"
              loading={isMakingRequest}
              text="Spoof"
              onClick={this.handleSubmit}
              style={{ minWidth: 110 }}
            />
          </Flex>
        </Box>
      </Dialog>
    );
  }
}

export default SudoDialog;
