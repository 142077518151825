import React from 'react';
import classNames from 'classnames';

import SelectedValuesPanel from '../select/SelectedValuesPanel';
import DimensionValueRenderer from './DimensionValueRenderer';

const DimensionMultiValuesRenderer = props => {
  const { values, field, onUnselect, readOnly } = props;
  const valueRenderer = valueProps => <DimensionValueRenderer {...valueProps} field={field} onUnselect={onUnselect} />;

  const className = classNames('dimensions', {
    'read-only': readOnly,
    'pt-intent-danger-text': field.hasError && field.dirty,
    'pt-intent-warning-text': field.hasError && field.pristine,
    'pt-text-muted': values.length === 0 && !field.hasError
  });

  return (
    <span className={className}>
      {values.length === 0 && <div className="empty-text">No dimensions selected</div>}
      {values.length > 0 && (
        <SelectedValuesPanel
          {...props}
          reorderable={!readOnly && values.length > 1}
          valueRenderer={valueRenderer}
          showCard={false}
          allowSelection={false}
        />
      )}
    </span>
  );
};

export default DimensionMultiValuesRenderer;
