import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import $sites from 'stores/$sites';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['i_device_site_country', 'i_ult_exit_site_country'],
  filterFields: ['i_device_site_country', 'i_ult_exit_site_country']
};

export function getInvalidSitesCount() {
  return $sites.collection.unfiltered.reduce((acc, model) => {
    const country = model.get('country');

    return !country || country.length !== 2 ? acc + 1 : acc;
  }, 0);
}

class SiteCountryDependency extends AbstractDependency {
  get key() {
    return 'siteCountry';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return getInvalidSitesCount() === 0;
  }

  get message() {
    return (
      <Fragment>
        <Box className="pt-text-smaller" mb={0.5}>
          Your organization currently has <strong>{getInvalidSitesCount()} sites with invalid countries</strong>{' '}
          defined. These will not will map correctly in the GeoHeatmap and may appear inconsistently in other
          visualizations.
        </Box>
        {this.showConfigureButton && (
          <Button iconName="cog" className="pt-small" onClick={() => $sites.store.history.push('/admin/sites')}>
            Configure
          </Button>
        )}
      </Fragment>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || getInvalidSitesCount() === 0;
  }
}

export default SiteCountryDependency;
