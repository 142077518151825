import React, { Component } from 'react';
import { GithubPicker } from 'react-color';
import { Button, Tag } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { Box } from 'components/flexbox';

// TODO: This is dupe of ScoreboardThreshold, refactor to common component.
export const ColorDisplay = observer(({ field }) => (
  <Tag
    className="pt-large"
    style={{
      width: 20,
      height: 20,
      padding: 0,
      minWidth: 'auto',
      boxShadow: 'inset 4px 4px 0px rgba(255, 255, 255, 0.5)',
      background: field && field.value ? field.value : '#5c7080'
    }}
  >
    &nbsp;
  </Tag>
));

// TODO: Make more configurable (i.e. select different pickers react-color has to offer)
class ColorPicker extends Component {
  state = {
    displayColorPicker: false
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeComplete = value => {
    const { onChange } = this.props;
    onChange(value.hex);
    this.setState({ displayColorPicker: false });
  };

  render() {
    const { field, pickerWidth } = this.props;
    const { placeholder, value } = field.getProps();
    return (
      <div>
        <Button className="pt-button" style={{ height: '30px', margin: '0' }} onClick={this.handleClick}>
          <ColorDisplay field={field} />
          <span style={{ marginLeft: '8px' }}>{placeholder}</span>
        </Button>
        {this.state.displayColorPicker ? (
          <Box mt={1}>
            <GithubPicker
              width={pickerWidth || null}
              color={value}
              triangle="hide"
              colors={
                field.options && field.options.length ? field.options.concat([]) : undefined /* use stock colors */
              }
              onChangeComplete={this.handleChangeComplete}
            />
          </Box>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
