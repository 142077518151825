import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { Page, PageHeader, PageBody } from 'components/page';
import { ChangeViewTypeMenu } from 'dataviews/tools';
import DataViewWrapper from 'dataviews/DataViewWrapper';
import DataViewTools from 'dataviews/DataViewTools';
import QueryModel from 'models/query/QueryModel';
import DataViewModel from 'models/DataViewModel';

import VisualizationsForm from './VisualizationsForm';

const breadcrumbLinks = [
  {
    text: 'My Profile',
    href: '/profile'
  }
];

@inject('$auth')
@observer
class Visualizations extends Component {
  @observable
  quantitativeDataview = new DataViewModel();

  @observable
  qualitativeDataview = new DataViewModel();

  state = {
    settings: undefined
  };

  componentDidMount() {
    const quantitativeQuery = QueryModel.create({ query_title: 'Preview: Quantitative Views' });
    const qualitativeQuery = QueryModel.create({ query_title: 'Preview: Qualitative Views', viz_type: 'line' });

    this.quantitativeDataview.initializeQueries(
      [quantitativeQuery].map(query => ({
        bucket: query.get('bucket'),
        query: query.serialize()
      }))
    );

    this.qualitativeDataview.initializeQueries(
      [qualitativeQuery].map(query => ({
        bucket: query.get('bucket'),
        query: query.serialize()
      }))
    );
  }

  onResetDefaults = ({ settings }) => {
    this.redrawDataviews(settings.visualizations);
  };

  onSettingsChange = ({ settings }) => {
    this.redrawDataviews(settings.visualizations);
  };

  redrawDataviews = settings => {
    this.setState({ settings }, () => {
      this.quantitativeDataview.component.redraw({ setColors: true });
      this.qualitativeDataview.component.redraw({ setColors: true });
    });
  };

  render() {
    const { $auth } = this.props;
    const { settings } = this.state;

    const quantitativeTools = (
      <DataViewTools dataview={this.quantitativeDataview}>
        {({ Wrapper, ...rest }) => (
          <Wrapper>
            <ChangeViewTypeMenu
              {...rest}
              filterView={view =>
                ['Stacked Area Chart', '100% Stacked Area Chart', 'Stacked Column Chart', 'Bar Chart'].includes(
                  view.label
                )
              }
            />
          </Wrapper>
        )}
      </DataViewTools>
    );

    const qualitativeTools = (
      <DataViewTools dataview={this.qualitativeDataview}>
        {({ Wrapper, ...rest }) => (
          <Wrapper>
            <ChangeViewTypeMenu
              {...rest}
              filterView={view => ['Line Chart', 'Pie Chart', 'Sunburst'].includes(view.label)}
            />
          </Wrapper>
        )}
      </DataViewTools>
    );

    const previewDataviewColors = settings || $auth.userSettings.visualizations;

    return (
      <Page title="Visualizations" subnavSection>
        <PageHeader
          parents={breadcrumbLinks}
          title="Visualizations"
          subSection={
            <Box pb={1} px={3} className="pt-text-muted">
              Choose the colors used in visualizations (excluding Sankey and Horizon charts)
            </Box>
          }
        />
        <PageBody>
          <Flex>
            <Box mr={2} w={300}>
              <VisualizationsForm
                values={{ settings: toJS($auth.userSettings) }}
                dataview={this.quantitativeDataview}
                onChange={this.onSettingsChange}
                onResetDefaults={this.onResetDefaults}
              />
            </Box>
            <Flex flexColumn flexAuto>
              <Flex className="pt-card flat" flexAuto style={{ height: 425 }} mb={2}>
                {previewDataviewColors && (
                  <DataViewWrapper
                    dataview={this.quantitativeDataview}
                    viewProps={{
                      showNativeLegend: true,
                      colors: previewDataviewColors
                    }}
                    headerProps={{
                      showTitleLink: false,
                      tools: quantitativeTools
                    }}
                  >
                    {({ component, header, className }) => (
                      <Flex className={className} flexAuto flexColumn style={{ overflow: 'hidden' }}>
                        <Box p={2}>{header}</Box>
                        {component}
                      </Flex>
                    )}
                  </DataViewWrapper>
                )}
              </Flex>

              <Flex className="pt-card flat" flexAuto style={{ height: 425 }}>
                {previewDataviewColors && (
                  <DataViewWrapper
                    dataview={this.qualitativeDataview}
                    viewProps={{
                      showNativeLegend: true,
                      colors: previewDataviewColors
                    }}
                    headerProps={{
                      showTitleLink: false,
                      tools: qualitativeTools
                    }}
                  >
                    {({ component, header, className }) => (
                      <Flex className={className} flexAuto flexColumn style={{ overflow: 'hidden' }}>
                        <Box p={2}>{header}</Box>
                        {component}
                      </Flex>
                    )}
                  </DataViewWrapper>
                )}
              </Flex>
            </Flex>
          </Flex>
        </PageBody>
      </Page>
    );
  }
}

export default Visualizations;
