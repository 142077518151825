import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';

import BracketOptionsDialog from 'components/bracketOptions/BracketOptionsDialog';
import BracketOptionsDisplay from 'components/bracketOptions/BracketOptionsDisplay';
import QueryModel from 'models/query/QueryModel';
import ExplorerQueryModel from 'models/query/ExplorerQueryModel';
import BracketOptionsModel from 'models/BracketOptionsModel';

const bracketTypeOptions = [
  {
    value: 'staticRanges',
    label: 'Static Ranges'
  },
  {
    value: 'percentages',
    label: 'Percentages'
  },
  {
    value: 'percentiles',
    label: 'Percentiles'
  }
];

@inject('$explorer', '$dataviews')
@observer
class BracketOptions extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    fieldName: 'bracketOptions',
    emptyText: 'No bracketing criteria have been applied'
  };

  handleBracketDialogSave = bracketOptions => {
    if (bracketOptions && Object.keys(bracketOptions).length) {
      const { form } = this.context;
      const { fieldName } = this.props;
      form.setValues({ [fieldName]: bracketOptions });
    }
  };

  handleBracketOptionRemove = () => {
    const { form } = this.context;
    const { fieldName } = this.props;
    form.setValues({ [fieldName]: undefined });
  };

  render() {
    const { $explorer, $dataviews } = this.props;
    const { form } = this.context;
    const { fieldName, emptyText, className } = this.props;

    const vizType = form.getField('viz_type').value;

    // create duplicate EQM of sidebar EQM (form.model is sidebar ExplorerQueryModel at this point)
    const eqmClone = ExplorerQueryModel.createFromQueryModel(new QueryModel(form.model.serialize()));
    // apply unsaved sidebar form state to duplicate EQM
    eqmClone.set(form.getValues());
    // create QueryModel by serializing EQM clone (to get computeds), then serialize
    const currentStateQuery = QueryModel.create(eqmClone.serialize()).serialize();

    const hasBracketOptions = !!currentStateQuery[fieldName];
    currentStateQuery[fieldName] = hasBracketOptions ? currentStateQuery[fieldName] : {};
    // set tagKey in currentStateQuery.bracketOptions for current tagKey
    Object.assign(currentStateQuery[fieldName], {
      tagKey: `${$explorer.tagPrefix}${vizType}`
    });

    const bracketOptionsModel = new BracketOptionsModel(currentStateQuery, {});

    return (
      <div className={className}>
        {!hasBracketOptions && <div className="pt-small pt-text-muted">{emptyText}</div>}

        {hasBracketOptions && <BracketOptionsDisplay {...this.props} />}
        <BracketOptionsDialog
          {...this.props}
          model={bracketOptionsModel}
          allowLastDataPoint={!$dataviews.getConfig(vizType).suppressLastPointBracketing}
          allowRangeRestriction
          allowBracketingGroupOption={!$dataviews.getConfig(vizType).suppressBracketingGroupOption}
          bracketTypeOptions={bracketTypeOptions}
          entityName="Bracketing"
          onSave={this.handleBracketDialogSave}
          onBracketOptionRemove={this.handleBracketOptionRemove}
        />
      </div>
    );
  }
}

export default BracketOptions;
