import { action, computed, observable } from 'mobx';

import $lookups from 'stores/$lookups';
import $dictionary from 'stores/$dictionary';

import BaseModel from '../BaseModel';

const lookupKeys = [
  'Geography_src',
  'dst_geo_region',
  'src_geo_region',
  'src_geo_city',
  'dst_geo_city',
  'Geography_dst',
  'InterfaceID_src',
  'InterfaceID_dst',
  'Proto',
  'AS_src',
  'AS_dst',
  'src_nexthop_asn',
  'dst_nexthop_asn',
  'src_second_asn',
  'dst_second_asn',
  'src_third_asn',
  'dst_third_asn'
];

class SilentMode extends BaseModel {
  lookups = observable.map();

  constructor(attributes, options) {
    super(attributes, options);

    this.doLookups();
  }

  save(attributes) {
    return super.save(attributes).then(success => {
      this.doLookups();
      return success;
    });
  }

  get defaults() {
    return {
      alert_key: [
        {
          dimension: 'AS_src',
          value: ''
        }
      ]
    };
  }

  get urlRoot() {
    return '/api/portal/alerts/silentMode';
  }

  get messages() {
    return {
      create: 'Pattern added successfully',
      update: 'Pattern updated successfully',
      destroy: 'Pattern removed successfully'
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Pattern',
      text: 'Are you sure you want to remove this pattern?'
    };
  }

  @computed
  get alertKeyValues() {
    return Object.keys(this.lookups).map(dimension => this.lookups[dimension]);
  }

  @action
  doLookups() {
    const alertKeys = this.get('alert_key');

    this.lookups.clear();

    alertKeys.forEach(alertKey => {
      if (lookupKeys.includes(alertKey.dimension)) {
        $lookups.autoCompleteDimensionHandlers[alertKey.dimension]('', alertKey.value).then(
          action(options => {
            const option = options.find(opt => opt.value === alertKey.value);
            this.lookups.set(alertKey.dimension, option ? option.label : alertKey.value);
          })
        );
      } else {
        const options = $dictionary.filterFieldValueOptions[alertKey.dimension];
        if (options) {
          const option = options.find(opt => opt.value === alertKey.value);
          this.lookups.set(alertKey.dimension, option ? option.label : alertKey.value);
        }
      }
    });
  }

  get omitDuringSerialize() {
    return ['user'];
  }
}

export default SilentMode;
