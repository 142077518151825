import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Checkbox } from '@blueprintjs/core';
import { Box } from 'components/flexbox';
import classNames from 'classnames';

const labelStyle = {
  fontWeight: 400
};

const defaultItemRenderer = ({ label, helpText }) => (
  <span style={labelStyle}>
    {label}
    {helpText && <div className="pt-text-muted">{helpText}</div>}
  </span>
);

const selectAllOrNone = (field, options, select) => {
  field.setValue(select ? options.map(({ value }) => value) : []);
};

const CheckboxGroup = ({ field, options, onChange, itemRenderer, inline = false, disabled, selectAll = false }) => {
  const checkboxOptions = options || field.options;

  const handleChange = value => {
    const newValues = toJS(field.value) || [];
    const indexOfValue = newValues.indexOf(value);

    if (indexOfValue === -1) {
      newValues.push(value);
    } else {
      newValues.splice(indexOfValue, 1);
    }

    onChange(toJS(newValues));
  };

  const className = classNames({ 'pt-inline': inline });
  const values = field.value || [];

  return (
    <div>
      {(!checkboxOptions || !checkboxOptions.length) && 'There are no available options.'}
      {checkboxOptions &&
        checkboxOptions.length > 1 &&
        selectAll && (
          <Box mb={2}>
            <Checkbox
              label="Select All"
              indeterminate={values.length > 0 && values.length < checkboxOptions.length}
              checked={values.length === checkboxOptions.length}
              onChange={() => selectAllOrNone(field, checkboxOptions, values.length === 0)}
            />
          </Box>
        )}
      {checkboxOptions &&
        checkboxOptions.map(({ value, label, helpText, ...rest }) => {
          const checkboxLabel = itemRenderer
            ? itemRenderer({ value, label, helpText, ...rest })
            : defaultItemRenderer({ value, label, helpText });

          return (
            <Checkbox
              disabled={disabled}
              className={className}
              key={`${field.id || field.name}-${value}`}
              label={checkboxLabel}
              checked={values.includes(value)}
              onChange={() => handleChange(value)}
            />
          );
        })}
    </div>
  );
};

export default observer(CheckboxGroup);
