import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { select, mouse, event } from 'd3-selection';
import { axisTop, axisBottom } from 'd3-axis';
import { scaleTime, scaleUtc } from 'd3-scale';
import { extent, bisector } from 'd3-array';
import { brushX } from 'd3-brush';
import { timeMinute } from 'd3-time';
import { debounce } from 'lodash';
import HorizonChart from 'dataviews/d3/horizon';
import { Flex } from 'components/flexbox';
import $app from 'stores/$app';
import $dictionary from 'stores/$dictionary';
import { getToFixed, zeroToText, adjustByGreekPrefix } from 'util/utils';

import BaseDataview from './BaseDataview';

@observer
export default class HorizonView extends BaseDataview {
  constructor(props) {
    super(props);
    window.addEventListener('resize', debounce(() => this.reflow(), 100));
  }

  chartRef = ref => {
    if (ref) {
      this.chart = ref;
    }
  };

  data = {};

  charts = [];

  renderSeries(bucket, name, data) {
    this.data[name] = data;
  }

  updateRenderedSeries(bucket, name, data) {
    this.data[name] = data;
  }

  removeSeries(bucket, name) {
    delete this.data[name];
  }

  clear() {
    this.data = {};
    this.charts = [];
  }

  handleTimeZoom = zoomData => {
    const { dataview } = this.props;
    if (zoomData) {
      setTimeout(() => dataview.updateTimeRange(zoomData.min, zoomData.max), 0);
    } else {
      setTimeout(() => dataview.resetTimeRange(), 0);
    }
  };

  renderTimeReset() {
    if (!this.chart) {
      return;
    }

    select(this.chart)
      .select('.zoom-out-button')
      .classed('hidden', false);
  }

  drawHorizons() {
    if (!this.chart) {
      return;
    }
    const that = this;
    const { data, props } = this;
    const { dataview } = props;
    const { dictionary } = $dictionary;
    const { firstQuery } = dataview.queryBuckets.activeBuckets[0];
    const time_format = firstQuery.get('time_format');
    const sync_extents = firstQuery.get('sync_extents');
    const { outsortUnit, prefixUnit, dateRangeDisplay } = firstQuery;
    const units = dictionary.units[outsortUnit];
    const prefix = dataview.queryBuckets.activeBuckets[0].queryResults.prefix;

    this.chart.innerHTML = '';
    const chartContainer = select(this.chart);

    const width = parseInt(chartContainer.style('width')) - 32;
    const height = parseInt(chartContainer.style('height')) - 60;
    const format = d =>
      `${zeroToText(adjustByGreekPrefix(d, prefix[prefixUnit]), { fix: getToFixed(outsortUnit) })} ${prefix[
        prefixUnit
      ] || ''}${units}`;

    const keys = Object.keys(data);
    let offset = 0;
    if (typeof time_format === 'number') {
      offset = (new Date().getTimezoneOffset() + time_format) * 60000;
    }
    const startTime = new Date(data[keys[0]][0][0] + offset);
    const endTime = new Date(data[keys[0]].slice(-1)[0][0] + offset);
    const scale = time_format === 'UTC' ? scaleUtc() : scaleTime();
    scale.range([0, width]).domain([startTime, endTime]);

    let dataExtent = null;
    if (sync_extents) {
      dataExtent = extent(Object.keys(data).reduce((arr, key) => arr.concat(data[key].map(row => row[1])), []));
    }

    const rowCount = Object.keys(data).length;
    const rowHeight = Math.floor(height / rowCount);
    const brushHeight = Math.max(rowHeight, 30) * rowCount + 20;

    chartContainer
      .append('div')
      .attr('class', 'flex-auto horizon-chart-body overflow-auto')
      .append('div')
      .attr('class', 'horizon-chart-wrapper overflow-hidden')
      .style('height', `${brushHeight}px`)
      .selectAll('.horizon')
      .data(Object.keys(data).map(title => ({ title: title.replace(/----/g, '\u2192'), values: data[title] })))
      .enter()
      .insert('div', '.bottom')
      .attr('class', 'horizon')
      .each(function renderHorizon(d) {
        if (!d.values.length) {
          return;
        }
        const horizon = HorizonChart()
          .step(Math.round(width / d.values.length))
          .height(rowHeight)
          .extent(dataExtent)
          .title(d.title);
        that.charts.push(horizon);
        horizon.call(this, d.values.map(row => row[1]));
      });

    chartContainer
      .select('.horizon-chart-wrapper')
      .append('svg')
      .attr('width', width)
      .attr('height', brushHeight)
      .style('top', `${-brushHeight}px`)
      .attr('class', 'horizon-brush')
      .append('g')
      .attr('class', 'brush')
      .call(
        brushX()
          .extent([[0, 0], [width, brushHeight]])
          .on('end', () => {
            if (!event.sourceEvent) return; // Only transition after input.
            if (!event.selection) return; // Ignore empty selections.
            const d0 = event.selection.map(scale.invert);
            const d1 = d0.map(timeMinute.round);

            // If empty when rounded, use floor & ceil instead.
            if (d1[0] >= d1[1]) {
              d1[0] = timeMinute.floor(d0[0]);
              d1[1] = timeMinute.offset(d1[0]);
            }

            that.handleTimeZoom({ min: d1[0], max: d1[1] });
          })
      )
      .on('mousemove', function mousemove() {
        const mouseX = mouse(this)[0];
        const x = scale.invert(mouseX);
        const values = data[keys[0]];
        const right = bisector(row => new Date(row[0] + offset)).left(values, x, 1);
        const left = right - 1;
        const index = x.getTime() - values[left][0] > values[right][0] - x.getTime() ? right : left;
        that.charts.forEach(chart => {
          chart.showValue(index, format);
        });
        chartContainer
          .selectAll('.marker')
          .style('left', `${mouseX}px`)
          .style('display', null);
      })
      .on('mouseout', () => {
        that.charts.forEach(chart => {
          chart.span().text('');
        });
        chartContainer.selectAll('.marker').style('display', 'none');
      });

    chartContainer
      .selectAll('.axis')
      .data(['bottom'])
      .enter()
      .append('svg')
      .attr('width', width)
      .attr('height', 20)
      .attr('class', d => `${d} axis`)
      .each(function renderAxis(d) {
        select(this).call((d === 'top' ? axisTop : axisBottom)(scale));
      });

    chartContainer
      .append('div')
      .attr('class', 'axis title')
      .text(dateRangeDisplay);

    chartContainer
      .select('.axis.title')
      .append('button')
      .attr(
        'class',
        classNames('zoom-out-button pt-button pt-small pt-intent-primary', { hidden: !dataview.hasTimeReset })
      )
      .text('Zoom Out')
      .on('click', () => this.handleTimeZoom());
  }

  redraw() {
    $app.renderSync(() => {
      this.drawHorizons();
    });
    $app.renderSync(() => {
      this.dismissSpinner(50);
    });
  }

  reflow() {
    this.redraw();
  }

  getComponent() {
    const { queryBuckets } = this.props.dataview;
    if (!queryBuckets.activeBucketCount) {
      return null;
    }

    return <Flex flexColumn flexAuto px={2} boxRef={this.chartRef} className="horizon-chart" />;
  }

  syncExtents() {
    this.redraw();
  }
}

const config = {
  showTotalTrafficOverlay: false,
  showLegend: true,
  singleDataseries: true,
  timeBased: true,
  enableToggle: false,
  supportsSyncExtents: true,
  buckets: [
    {
      name: 'Horizons'
    }
  ]
};

export { config };
