import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Icon from 'components/Icon';
import { Flex } from 'components/flexbox';

import { getOption, getValues } from './selectHelpers';
import Select from './Select';

const defaultValueRenderer = (option, placeholder) => {
  if (!option) {
    return <span className="pt-text-muted">{placeholder || 'Select a value...'}</span>;
  }

  const className = classNames('pt-text-overflow-ellipsis', option.className);

  return (
    <span className={className}>
      {option && option.iconCls && <Icon name={option.iconCls} style={{ marginRight: 6 }} />}
      {option && option.label}
    </span>
  );
};

@observer
export default class LookupMultiSelect extends Select {
  static defaultProps = {
    autoComplete: false,
    exactMatch: true,
    onQuery: null,
    disabled: false,
    loading: false,
    multi: true,
    clearable: false,
    valueRenderer: defaultValueRenderer,
    getOptionFn: getOption,
    optionLimit: -1,
    minFilterChars: 0
  };

  state = {
    isOpen: false,
    options: [],
    optionsFilter: '',
    selectedIndex: -1
  };

  getValueOptions() {
    const { getOptionFn, field, valueRenderer } = this.props;
    const { options } = this.state;
    const values = getValues(field);

    return values.map(val => {
      const valueOption = getOptionFn(options, val);

      if (!valueOption) {
        return null;
      }

      return (
        <Flex key={val} className="pt-tag pt-tag-removable pt-minimal">
          {valueRenderer(valueOption)}
          <button className="pt-tag-remove" onClick={this.handleUnselectItem(field, val)} />
        </Flex>
      );
    });
  }
}
