import { computed, observable } from 'mobx';
import moment from 'moment';

import MitigationMethodCollection from 'models/alerting/MitigationMethodCollection';
import MitigationPlatformCollection from 'models/alerting/MitigationPlatformCollection';
import MitigationPlatformMethodCollection from 'models/alerting/MitigationPlatformMethodCollection';
import ManualMitigationCollection from 'models/alerting/ManualMitigationCollection';

export const MANUAL_MITIGATION_DUMMY_IP = '127.42.42.42';

class MitigationStore {
  @observable
  platforms = new MitigationPlatformCollection();

  @observable
  methods = new MitigationMethodCollection();

  @observable
  platformMethods = new MitigationPlatformMethodCollection();

  @observable
  manualMitigations = new ManualMitigationCollection();

  @computed
  get platformMethodOptions() {
    const threshold = moment.utc().subtract(2, 'minutes');

    return this.platformMethods.models.map(model => {
      const disabled =
        moment.utc(model.get('platform_edate')).isAfter(threshold) ||
        moment.utc(model.get('method_edate')).isAfter(threshold);

      return {
        label: `${model.get('platform_name')} - ${model.get('method_name')}`,
        value: model.id,
        disabled
      };
    });
  }

  @computed
  get methodOptionsRadware() {
    return this.getMethodOptions('Radware');
  }

  @computed
  get methodOptionsRTBH() {
    return this.getMethodOptions('RTBH');
  }

  @computed
  get methodOptionsA10() {
    return this.getMethodOptions('A10');
  }

  @computed
  get methodOptionsCFMT() {
    return this.getMethodOptions('CFMT');
  }

  @computed
  get methodOptionsFlowspec() {
    return this.getMethodOptions('Flowspec');
  }

  getMethodOptions(type) {
    const options = [];

    this.methods.models.forEach(model => {
      const { method_name, id, method_mitigation_device_type } = model.get();

      if (method_mitigation_device_type === type) {
        options.push({
          label: method_name,
          value: id
        });
      }
    });

    return options;
  }

  isDeviceInRTBHPlatform(device) {
    return this.platforms.models.find(
      platform =>
        platform.get('platform_mitigation_device_type') === 'RTBH' &&
        platform.get('platform_mitigation_device_detail.devices').includes(parseFloat(device.id))
    );
  }
}

export default new MitigationStore();
