import React, { Component, Fragment } from 'react';
import { Button, Collapse, Icon } from '@blueprintjs/core';
import ColorBlock from 'components/ColorBlock';
import classNames from 'classnames';
import { Box, Flex } from 'components/flexbox';
import { observer } from 'mobx-react';

@observer
class SelectedValueButton extends Component {
  state = {
    isOpen: false
  };

  handleToggleOpen = e => {
    e.stopPropagation();

    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { label, devices = [], color, iconName, style, onRemove, className, disabled } = this.props;
    const { isOpen } = this.state;

    const hasDevices = devices && devices.length > 0;
    const removeButton = onRemove && (
      <Button className={classNames('pt-minimal', className)} onClick={onRemove} iconName="cross" disabled={disabled} />
    );

    return (
      <Box className="pt-card flat" mb={1} style={style}>
        {!hasDevices && (
          <Flex p={0.5} justify="space-between">
            <Flex align="center" flexAuto pl={0.5}>
              <Icon iconName={iconName} className="pt-text-muted" style={{ marginRight: 7 }} /> {label}
            </Flex>
            {removeButton}
          </Flex>
        )}
        {hasDevices && (
          <Flex p={0.5} justify="space-between">
            <Flex align="center" flexAuto pl={0.5}>
              {color && <ColorBlock color={color} style={{ width: 14, height: 14, marginRight: 7 }} />}
              {!color && <Icon iconName={iconName} className="pt-text-muted" style={{ marginRight: 7 }} />} {label}
            </Flex>
            {devices.length > 0 && (
              <Button
                iconName={isOpen ? 'caret-down' : 'caret-right'}
                onClick={this.handleToggleOpen}
                className={classNames('pt-minimal', className)}
                text={<Fragment>{devices.length} devices</Fragment>}
                disabled={disabled}
              />
            )}

            {removeButton}
          </Flex>
        )}

        <Collapse isOpen={isOpen}>
          <Box style={{ padding: '0 8px 4px' }}>
            {devices.map(device => (
              <div key={device.id} className="pt-tag pt-minimal" style={{ marginRight: 4, marginBottom: 4 }}>
                {device.device_name}
              </div>
            ))}
          </Box>
        </Collapse>
      </Box>
    );
  }
}

export default SelectedValueButton;
