import React from 'react';
import { Flex } from 'components/flexbox';
import { MaterialIcon } from 'components/Icon';

const ParametricTag = () => (
  <Flex align="center" mr={0.5} className="pt-tag pt-strong pt-minimal">
    <MaterialIcon name="question_answer" className="pt-text-muted" style={{ marginRight: 4, fontSize: 11 }} />
    Guided
  </Flex>
);

export default ParametricTag;
