import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { AnchorButton, Button, Intent, PopoverInteractionKind, Position, Tag } from '@blueprintjs/core';
import { NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import { Flex, Box } from 'components/flexbox';
import { DropdownMenu, KentikLogo } from 'components';
import Icon, { MaterialIcon } from 'components/Icon';
import store from 'stores/store';
import SudoDialog from 'views/Admin/Companies/components/SudoDialog';
import DebugModeDialog from 'views/Admin/DebugModeDialog';
import HelpDialog from 'views/Help/HelpDialog';
import AssetMigration from 'views/Library/AssetMigration';
import SearchDropdown from 'views/Search/SearchDropdown';
import BuildInfoDialog from 'components/BuildInfoDialog';
import DeviceLabelRegexDialog from 'views/Admin/DeviceLabelRegexDialog';

import ReportIssueDialog from './ReportIssueDialog';
import AnalyticsMenu from './AnalyticsMenu';
import TrialDaysRemaining from './TrialDaysRemaining';
import UserMenu from './UserMenu';
import SubtenantViewDialog from '../Admin/Subtenancy/SubtenantViewDialog';

const APP_ROUTES = [
  {
    name: 'Library',
    to: '/library',
    icon: 'folder-open'
  },
  {
    name: 'Data Explorer',
    to: '/explorer',
    icon: 'insert_chart',
    isMaterialIcon: true
  },
  {
    name: 'Alerting',
    to: '/alerting',
    icon: 'notifications',
    showAlertBadge: true,
    isMaterialIcon: true
  },
  {
    name: 'Analytics',
    icon: 'lightbulb_outline',
    menu: AnalyticsMenu,
    urlRoot: '/analytics',
    isMaterialIcon: true
  },
  {
    name: 'Admin',
    to: '/admin',
    icon: 'settings',
    adminOnly: true,
    isMaterialIcon: true,
    showRegexDialog: true
  }
];

@inject(
  '$app',
  '$auth',
  '$alerts',
  '$companySettings',
  '$devices',
  '$exports',
  '$onboarding',
  '$search',
  '$sudo',
  '$userGroups'
)
@observer
class NavMenu extends Component {
  handleLogout = async () => store.logout();

  handleNavLinkClick() {
    const { $app, $search } = this.props;
    $search.closeSearch();
    $app.closeHelp();
  }

  handleShowRegexDialogClick = e => {
    const { $companySettings } = this.props;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    $companySettings.setDeviceLabelRegexDialogOpen(true);
  };

  renderNavLink(route) {
    const { history, $alerts, $companySettings } = this.props;
    const Menu = route.menu;
    const LinkCmp = route.to ? NavLink : 'button';
    const NavIcon = route.isMaterialIcon ? MaterialIcon : Icon;
    const className = classNames('pt-dark pt-button pt-minimal nav-button', {
      'pt-active': history.location.pathname.startsWith(route.to || route.urlRoot)
    });

    const onClickHandler = route.to ? () => this.handleNavLinkClick() : () => {};

    const navLink = (
      <LinkCmp className={className} key={route.name} to={route.to} onClick={onClickHandler}>
        <NavIcon name={route.icon} title={route.name} />
        <span className="hidden-sm" style={{ marginLeft: '6px' }}>
          {route.name}
        </span>
        {route.showAlertBadge &&
          $alerts.alertCount > 0 && (
            <Tag className={`pt-small ${$alerts.alertIntent}`} style={{ marginLeft: '6px' }}>
              {$alerts.alertCount}
            </Tag>
          )}

        {route.showRegexDialog &&
          !$companySettings.deviceLabelRegexEnabled &&
          moment().isBefore('2019-03-01') && (
            <Button
              iconName="warning-sign"
              className="pt-small pt-minimal"
              intent={Intent.DANGER}
              style={{ marginLeft: 4, paddingBottom: 1, verticalAlign: 'initial' }}
              onClick={this.handleShowRegexDialogClick}
            />
          )}
        {route.menu && <span className="pt-icon-standard pt-icon-caret-down pt-align-right" />}
      </LinkCmp>
    );

    if (Menu) {
      return (
        <DropdownMenu
          key={route.name}
          content={Menu && <Menu history={history} />}
          interactionKind={PopoverInteractionKind.CLICK}
          tetherOptions={{ offset: '0 0' }}
        >
          {navLink}
        </DropdownMenu>
      );
    }

    return navLink;
  }

  renderNavLinks() {
    const {
      $onboarding: { forceOnboarding },
      $auth: { isAdministrator },
      $devices: { hasReceivedFlow }
    } = this.props;

    const menuItems = [];

    if (hasReceivedFlow) {
      APP_ROUTES.forEach(route => {
        if (!route.adminOnly || isAdministrator) {
          menuItems.push(this.renderNavLink(route));
        }
      });
    } else {
      APP_ROUTES.forEach(route => {
        if (!forceOnboarding && route.adminOnly && isAdministrator) {
          menuItems.push(this.renderNavLink(route));
        }
      });
    }

    return menuItems;
  }

  render() {
    const { $app, $auth, $sudo, location, $userGroups, $companySettings } = this.props;
    const { activeUser, activeUserDisplay, isActiveTrial } = $auth;

    if (location.pathname.startsWith('/login') || location.pathname === '/signup') {
      return null;
    }

    const className = classNames('pt-navbar portal-navbar');
    const buttonClassName = classNames('pt-dark', 'pt-minimal', 'nav-button', 'pt-intent-primary');
    const showNavLinks = $auth.authenticated && !$auth.twoFactorVerifyRequired;
    const hasSubtenancy =
      $auth.hasPermission('subtenancy.enabled') || $auth.hasPermission('subtenancy.trialMode') || isActiveTrial;
    return (
      <div>
        {$app.showV4Notice && (
          <Box
            style={{
              background: '#22548b',
              textAlign: 'center',
              top: 0,
              height: 25,
              width: '100%',
              lineHeight: '25px'
            }}
          >
            <Flex justify="center">
              <div style={{ color: 'white' }}>
                We just launched our next generation product, Kentik v4! You can see it{' '}
                <a
                  onClick={e => {
                    e.stopPropagation();
                    $app.goToV4();
                  }}
                  style={{ color: '#ffffffb3', textDecoration: 'none' }}
                >
                  here.
                </a>
              </div>
            </Flex>
          </Box>
        )}
        <Flex className={className} align="center" justify="space-between" px={2}>
          <Flex align="flex-start">
            <a href={window.location.origin}>
              <KentikLogo />
            </a>
          </Flex>

          <Flex align="center" flexAuto>
            {showNavLinks && this.renderNavLinks()}
          </Flex>

          {$auth.authenticated &&
            !$auth.twoFactorVerifyRequired && (
              <Flex align="center">
                <TrialDaysRemaining user={activeUser} />
                <SearchDropdown />

                <DropdownMenu
                  content={<UserMenu />}
                  position={Position.BOTTOM_RIGHT}
                  interactionKind={PopoverInteractionKind.CLICK}
                  tetherOptions={{ offset: '-4px 0' }}
                >
                  <AnchorButton
                    id="userMenu"
                    className={buttonClassName}
                    style={{ margin: 0, padding: '6px 5px 4px 7px', color: $auth.isSpoofed ? '#d65b4a' : '#ffffff' }}
                  >
                    <Flex align="center">
                      <div style={{ lineHeight: '15px', textAlign: 'left' }}>
                        {activeUserDisplay} <br />
                        <small className="pt-normal pt-text-muted">{activeUser.company.company_name_full}</small>
                      </div>
                      <span className="pt-icon-standard pt-icon-caret-down pt-align-right" />
                    </Flex>
                  </AnchorButton>
                </DropdownMenu>
              </Flex>
            )}

          {$auth.authenticated &&
            $auth.twoFactorVerifyRequired && (
              <div>
                <AnchorButton className={buttonClassName} onClick={this.handleLogout}>
                  <MaterialIcon style={{ margin: 0 }} name="exit_to_app" />
                </AnchorButton>
              </div>
            )}

          <ReportIssueDialog isOpen={$app.reportIssueDialogOpen} onClose={$app.toggleReportIssueDialog} />

          {($auth.hasSudo || $auth.isSpoofed) && (
            <SudoDialog isOpen={$sudo.sudoOpen} onClose={() => this.props.$sudo.setSudoOpen(false)} />
          )}

          {($auth.hasSudo || $auth.isSpoofed) && <DebugModeDialog isOpen={$app.debugModeOpen} />}

          {$app.showHelp &&
            $app.helpButtonSource === 'nav' && <HelpDialog isOpen response={$app.helpText} buttonSource="nav" />}

          {$sudo.assetMigrationOpen && <AssetMigration />}
          {hasSubtenancy && (
            <SubtenantViewDialog
              isOpen={$userGroups.subtenantViewDialogOpen}
              onClose={() => $userGroups.setSubtenantViewDialogOpen(false)}
            />
          )}

          {($auth.hasSudo || $auth.isSpoofed) && <BuildInfoDialog isOpen={$app.buildInfoOpen} />}
          <DeviceLabelRegexDialog isOpen={$companySettings.deviceLabelRegexDialogOpen} />
        </Flex>
      </div>
    );
  }
}

export default withRouter(NavMenu);
