import React from 'react';

import logoSvg from 'kentik_logo_white_r.svg';
import logoDarkSvg from 'kentik_logo_r.svg';

const logoSvgStyle = {
  width: 100,
  position: 'relative',
  left: -6
};

const KentikLogo = ({ onlyMark, blackText, fillColor = '#ffffff', handleClick, style }) => {
  if (onlyMark) {
    return (
      <svg
        version="1.1"
        id="kentik-logo"
        x="0px"
        y="0px"
        viewBox="0 0 25 25"
        style={{ enableBackground: 'new 0 0 90 25', ...style }}
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              className="path-1"
              fill={fillColor}
              d="M18.5,10.9c-0.4-0.3-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3
			c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5c0.4-0.3,0.5-0.8,0.5-1.3C19.1,11.7,18.9,11.3,18.5,10.9z"
            />
            <g>
              <path
                className="path-2"
                fill={fillColor}
                d="M12.1,12.2L18.3,4c-0.2-0.2-0.5-0.3-0.9-0.4c-0.3-0.1-0.7-0.2-1.1-0.2c-0.6,0-1.1,0.1-1.5,0.3
				c-0.4,0.2-0.8,0.5-1.2,1l-5.6,7.5l5.6,7.5c0.4,0.5,0.8,0.8,1.2,1c0.4,0.2,0.9,0.3,1.5,0.3c0.4,0,0.7-0.1,1.1-0.2
				c0.3-0.1,0.6-0.3,0.9-0.4L12.1,12.2z"
              />
            </g>
            <g>
              <path
                className="path-3"
                fill={fillColor}
                d="M12.5,4c-0.2-0.2-0.5-0.3-0.9-0.4c-0.3-0.1-0.7-0.2-1.1-0.2c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.5-1.2,1
				l-5.6,7.5l5.6,7.5c0.4,0.5,0.8,0.8,1.2,1c0.4,0.2,0.9,0.3,1.5,0.3c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.4l-6.2-8.2
				L12.5,4z"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }

  return (
    <span className="logo-container" onClick={handleClick}>
      <img src={blackText ? logoDarkSvg : logoSvg} alt="Kentik" style={style || logoSvgStyle} />
    </span>
  );
};

export default KentikLogo;
