import { observable, action } from 'mobx';
import { showSuccessToast } from 'components/Toast';
import api from 'util/api';
import Collection from 'models/Collection';

class UsageStatsCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'ctime',
      direction: 'desc'
    };
  }
}

class UsageStatStore {
  selectedCompanyId;

  @observable
  isFetching = false;

  @observable
  usageStatsCollection = new UsageStatsCollection();

  @observable
  myUsageStatsCollection = new UsageStatsCollection();

  @action
  fetchMyStats(options) {
    this.myUsageStatsCollection.reset();
    this.isFetching = true;
    const { start = '', end = '', keyword = '', logType = 'portal' } = options || {};

    api.get(`/api/portal/userLogging/activityLog?type=${logType}&start=${start}&end=${end}&keyword=${keyword}`).then(
      res => {
        this.isFetching = false;
        this.myUsageStatsCollection.set(res);
      },
      err => {
        this.isFetching = false;
        this.myUsageStatsCollection.set([]);
        console.warn('Error Getting Usage Stats', err);
      }
    );
  }

  @action
  fetchResults(options) {
    if (!this.selectedCompanyId) {
      return;
    }

    this.usageStatsCollection.reset();
    this.isFetching = true;
    const { start = '', end = '', keyword = '' } = options || {};

    api.get(`/api/sudo/companies/${this.selectedCompanyId}/stats?start=${start}&end=${end}&keyword=${keyword}`).then(
      res => {
        this.isFetching = false;
        this.usageStatsCollection.set(res);
        showSuccessToast('Usage Stats updated successfully');
      },
      err => {
        this.isFetching = false;
        this.usageStatsCollection.set([]);
        console.warn('Error Getting Usage Stats', err);
      }
    );
  }

  @action
  setCompanyId(company_id) {
    this.selectedCompanyId = company_id;
  }

  @action
  logEvent(data) {
    api.post('/api/portal/userLogging/clientEvent', { data });
  }
}

export default new UsageStatStore();
