import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Form, FormDialog } from 'components/forms';
import { fields } from 'forms/config/userDetails';

import UserDetailsForm from './UserDetailsForm';

const dialogStyles = {
  width: 800
};

@Form({ fields })
@observer
class UserDetailsDialog extends Component {
  static defaultProps = {
    fetchDetails: true
  };

  componentWillUpdate(nextProps) {
    const { model, form, fetchDetails } = this.props;
    const { model: nextModel } = nextProps;

    if (fetchDetails && nextModel && nextModel !== model) {
      if (!nextModel.isNew) {
        nextModel.fetchFullProfile().then(results => {
          form.setModel(results);
        });
      } else {
        form.setModel(nextModel);
      }
    }
  }

  render() {
    return <FormDialog {...this.props} style={dialogStyles} entityName="User" formComponent={<UserDetailsForm />} />;
  }
}

export default UserDetailsDialog;
