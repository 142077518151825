import { computed, observable } from 'mobx';
import SavedFilterCollection from 'models/filters/SavedFilterCollection';

class SavedFilterStore {
  @observable
  collection = new SavedFilterCollection();

  initialize() {
    return this.collection.fetch();
  }

  @computed
  get selectOptions() {
    return this.collection.unfiltered.map(model => ({
      value: model.get('id'),
      label: model.get('filter_name'),
      id: model.get('id'),
      filters: model.get('filters'),
      numFilters: model.numFilters
    }));
  }

  getSavedFilterById(filter_id) {
    return this.collection.models.find(f => f.id === filter_id);
  }
}

export default new SavedFilterStore();
