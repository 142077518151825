import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { MenuDivider, MenuItem } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { MaterialIcon } from 'components/Icon';

import DeprecatedNavMenu from './DeprecatedNavMenu';

export const renderMenuItem = link => {
  const { to, path, text, iconName, disabled, intent, materialIcon, deprecated, label, target } = link;

  /* eslint-disable react/no-children-prop */
  return (
    <Route
      path={path || to}
      key={`${text}-${to}`}
      children={({ match, history }) => {
        let menuText = text;

        if (materialIcon) {
          menuText = (
            <span className="menu-text">
              <MaterialIcon name={iconName} /> {text}
            </span>
          );
        }

        const className = classNames({ 'pt-active': match && match.isExact, 'pt-text-muted': deprecated });

        return (
          <MenuItem
            label={label}
            disabled={disabled}
            intent={intent}
            className={className}
            href={target ? to : undefined}
            onClick={target ? undefined : () => history.push(to)}
            iconName={!materialIcon ? iconName : ''}
            text={menuText}
            target={target}
          />
        );
      }}
    />
  );
};

export const renderMenuLink = (link, history) => {
  if (link.display !== undefined && !link.display) {
    return '';
  }

  if (!history) {
    throw new Error('Must pass a `history` instance to renderMenuLink');
  }

  if (link.divider) {
    const key = `divider${link.title}`;
    return link.deprecated ? <DeprecatedNavMenu key={key} link={link} /> : <MenuDivider key={key} title={link.title} />;
  }

  return renderMenuItem(link);
};

const PageNavMenu = props => {
  const { history, links, width = 215 } = props;

  return (
    <Box className="section-subnav" style={{ minWidth: width, width }}>
      <Flex className="pt-menu" flexColumn p={0} pb={2} style={{ height: '100%', overflow: 'auto' }}>
        {links.map(link => renderMenuLink(link, history))}
      </Flex>
    </Box>
  );
};

export default withRouter(observer(PageNavMenu));
