import React, { Component, Fragment } from 'react';

import { Flex, Box } from 'components/flexbox';
import { Field, RadioGroup, Select } from 'components/forms';

export default class GuidedModeBehavior extends Component {
  onParametricModeChange = (field, value) => {
    const { form } = this.props;
    form.getField('parametric_overrides.filterField').setRules(value !== 'ignore' ? 'required' : '');
    form.getField('parametric_overrides.operator').setRules(value !== 'ignore' ? 'required' : '');
  };

  onParametricFieldChange = (field, value) => {
    const { form, queryFilters } = this.props;
    const operators = queryFilters.operators[value] || queryFilters.standardFilterOperators;
    const parametricOperatorField = form.getField('parametric_overrides.operator');

    if (!(parametricOperatorField.getValue() in operators)) {
      parametricOperatorField.setValue(Object.keys(operators)[0]);
    }
  };

  render() {
    const {
      form,
      availableFiltersConfig,
      parametric_mode,
      filterOperatorOptions,
      standardFilterOperators,
      boxProps = { p: 2 },
      fieldBoxProps = { ml: 3, mt: 2 },
      disabled
    } = this.props;

    const parametricFilterField =
      form.getValue('parametric_overrides.filterField') || availableFiltersConfig.availableFilters[0];
    const parametricOperatorOptions = filterOperatorOptions[parametricFilterField] || standardFilterOperators;

    const selectWidth = 350;
    const selectProps = {
      menuWidth: selectWidth,
      tetherOptions: { constraints: [{ attachment: 'together', pin: true, to: 'window' }] }
    };

    return (
      <Flex flexColumn className="overflow-auto" flexAuto {...boxProps}>
        <h6>Panel Guided Mode Behavior</h6>

        <p className="pt-text-muted">Select the effect you want the {"user's"} input to have on this panel</p>
        <Box {...fieldBoxProps}>
          <Field
            name="parametric_mode"
            className="no-margin"
            onChange={this.onParametricModeChange}
            labelStyle={{ textTransform: 'none' }}
            showLabel={false}
            disabled={disabled}
          >
            <RadioGroup
              inline={false}
              disabledOptions={!availableFiltersConfig.canOverrideSpecific ? ['override_specific'] : []}
            />
          </Field>
        </Box>

        {parametric_mode !== 'ignore' && (
          <Box className="pt-callout" p={2} mt={2}>
            {parametric_mode === 'override_all' && <p>Ignore panels existing filters and add a new filter group:</p>}
            {parametric_mode === 'override_specific' && <p>Overriding all existing filters that contain:</p>}
            {parametric_mode === 'add_group' && <p>Add a new filter group:</p>}
            <Fragment>
              <Field
                name="parametric_overrides.filterField"
                disabled={disabled}
                options={availableFiltersConfig.availableFilters}
                onChange={this.onParametricFieldChange}
              >
                <Select {...selectProps} />
              </Field>
              {(parametric_mode === 'override_all' || parametric_mode === 'add_group') && (
                <Field
                  name="parametric_overrides.operator"
                  className="no-margin"
                  disabled={disabled}
                  options={parametricOperatorOptions}
                >
                  <Select {...selectProps} />
                </Field>
              )}
            </Fragment>
          </Box>
        )}
      </Flex>
    );
  }
}
