import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$subscriptions')
class Subscriptions extends Component {
  title = 'Subscription';

  url = '/admin/subscriptions';

  render() {
    const { result, $subscriptions } = this.props;
    return (
      <ResultRow
        type={this.title}
        icon="material-icons:email"
        url={this.url}
        nameField="title"
        collection={$subscriptions.collection}
        {...this.props}
      >
        <span>frequency: {result.deliveryFrequency}</span>
      </ResultRow>
    );
  }
}

export default Subscriptions;
