import moment from 'moment';

/**
 * This validator assumes values in UTC (since that is how our platform operates; time fields should be
 * outputting times in UTC even if the input is editing in local.
 *
 * @param value
 * @returns {boolean}
 */
function beforeNowValidation(value) {
  const val = moment.utc(value);
  const now = moment.utc();

  return val.isValid() && val.isBefore(now);
}

export default {
  message: ':attribute cannot be in the future',
  fn: beforeNowValidation
};
