import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, InputGroup, Intent } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { Card } from 'components';
import { Page, PageHeader, PageBody } from 'components/page';

import Label from './ProfileLabel';
import OtpItems from './OtpItems';
import TotpDialog from './TotpDialog';
import YubiDialog from './YubiDialog';

const breadcrumbLinks = [
  {
    text: 'My Profile',
    href: '/profile'
  }
];

@inject('$users', '$app')
@observer
class AuthSettings extends Component {
  static defaultProps = {
    showAPI: true
  };

  state = {
    updatingTotp: false,
    updatingYubiKey: false
  };

  resetApiToken = () => {
    const { id: userId } = this.props.$users.fullUserProfile;
    this.props.$users.resetUserApiToken(userId);
  };

  toggleRegisterTotp = () => {
    this.setState({ updatingTotp: !this.state.updatingTotp });
  };

  toggleRegisterYubikey = () => {
    this.setState({ updatingYubiKey: !this.state.updatingYubiKey });
  };

  onRemoveTotp = totpId => {
    const { $users } = this.props;
    $users.deleteTotp(totpId);
  };

  onDisableTotps = () => {
    const {
      $users,
      $users: { fullUserProfile }
    } = this.props;

    $users.disableUserTotps(fullUserProfile.id);
  };

  onOtpStatusToggle = (otp, enabled) => {
    this.props.$users.updateTotp(otp, { enabled });
  };

  render() {
    const showAPI = !this.props.$app.isSubtenant;
    const { fullUserProfile, disablingTotp, initiatingPasswordReset, initiatePasswordReset, otps } = this.props.$users;
    const { updatingTotp, updatingYubiKey } = this.state;

    const showSsoSection = fullUserProfile.meta_ssoEnabled;
    const showPasswordReset = !fullUserProfile.meta_ssoRequired || fullUserProfile.user_level >= 2;

    return (
      <Page constrainWidth title="Profile">
        <PageHeader
          loading={!fullUserProfile}
          parents={breadcrumbLinks}
          title="Authentication"
          helpTopic="User Authentication"
        />
        <PageBody scrollable bodyStyle={{ maxWidth: '800px' }}>
          <Flex flexColumn mb={2} p={2} className="pt-callout pt-intent-primary">
            {showSsoSection && (
              <Fragment>
                <h6>Single Sign On</h6>
                <div>
                  SSO is {fullUserProfile.meta_ssoRequired ? 'required' : 'enabled'} for your company, please use the
                  following URL for the login page and update your bookmarks{' '}
                  <a href={`/login/sso/${fullUserProfile.company_name}`}>
                    http://portal.kentik.com/login/sso/
                    {fullUserProfile.company_name}
                  </a>
                  <br />
                  Contact one of your Super Admins for any help with SSO.
                </div>
                {showPasswordReset && <hr />}
              </Fragment>
            )}
            {showPasswordReset && (
              <Fragment>
                <h6>Password Reset</h6>
                <div>
                  Click the reset button below to send password reset instructions to the email address associated with
                  this account.
                </div>
                <Button
                  style={{ marginTop: 16, alignSelf: 'flex-end' }}
                  icon="reset"
                  intent={Intent.PRIMARY}
                  loading={initiatingPasswordReset}
                  onClick={initiatePasswordReset}
                  text="Initiate Password Reset"
                />
              </Fragment>
            )}
          </Flex>
          <Card mb={2}>
            <Flex mb={2}>
              <Label style={{ marginBottom: 0 }}>Two-Factor Authentication</Label>
              <Flex flexAuto justify="flex-end" align="center">
                {fullUserProfile.totpEnabled && (
                  <Button
                    text="Disable 2FA"
                    iconName="unlock"
                    className="pt-minimal"
                    intent={Intent.DANGER}
                    loading={disablingTotp}
                    onClick={this.onDisableTotps}
                  />
                )}

                <Button onClick={this.toggleRegisterYubikey} style={{ marginLeft: 12 }}>
                  Add YubiKey
                </Button>
                <Button onClick={this.toggleRegisterTotp} style={{ marginLeft: 12 }}>
                  Add TOTP Token
                </Button>
              </Flex>
            </Flex>

            <OtpItems otps={otps} onRemoveClick={this.onRemoveTotp} onStatusClick={this.onOtpStatusToggle} />

            <YubiDialog isOpen={updatingYubiKey} title="Register YubiKey" onClose={this.toggleRegisterYubikey} />
            <TotpDialog isOpen={updatingTotp} title="Register TOTP" onClose={this.toggleRegisterTotp} />
          </Card>
          {showAPI && (
            <Card>
              <Flex>
                <Label>API Token</Label>
                <Flex flexColumn style={{ minWidth: 260 }} flexAuto justify="flex-end">
                  <InputGroup className="pt-monospace-text" readOnly value={fullUserProfile.user_api_key || ''} />
                  <Button
                    style={{ marginTop: 16, alignSelf: 'flex-end' }}
                    icon="reset"
                    onClick={this.resetApiToken}
                    text="Reset API Token"
                  />
                </Flex>
              </Flex>
            </Card>
          )}
        </PageBody>
      </Page>
    );
  }
}

export default AuthSettings;
