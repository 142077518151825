import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

const options = {
  validationOnInit: false
};

const fields = {
  alert_id: {
    label: 'Alert ID'
  },

  alert_key: {
    label: 'Key'
  },

  alert_key_lookup: {
    label: 'Key Lookup'
  },

  alert_dimension: {
    label: 'Dimension'
  },

  ctime: {
    label: 'Match/Alarm Time (UTC)',
    transform: {
      in: value => value && moment(moment.utc(value).format(DEFAULT_DATETIME_FORMAT)).toDate(),
      out: value => `${moment(value).format(DEFAULT_DATETIME_FORMAT)}+00:00`
    }
  }
};

export { fields, options };
