import React, { Component } from 'react';
import { any } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';

import FormState from 'forms/state/FormState';
import $app from 'stores/$app';
import { getAllFormsPayload } from 'components/AbstractErrorBoundary';

export default function Form({ fields, options = {} }) {
  return function formWrapper(WrappedComponent) {
    return withRouter(
      class FormWrapperComponent extends Component {
        static childContextTypes = {
          form: any
        };

        state = {};

        getChildContext() {
          return {
            form: this.form
          };
        }

        createFormState() {
          const { onChange, permissions } = this.props;
          window.currentForm = window.currentForm || [];

          if (!this.form) {
            this.form = new FormState({ fields, options, permissions, onChange, onSubmit: this.handleSave });

            // extend our `form` to include the callbacks for Saving, Updating, Removing, etc.
            this.form.handleCancel = this.handleCancel;
            this.form.handleSave = this.handleSave;
            this.form.handleRemove = this.handleRemove;

            if (!options.suppressCurrentForm) {
              window.currentForm.push(this.form);
            }
          }
        }

        componentWillMount() {
          const { model, values } = this.props;

          // Note: we'd like to defer this, but there's many cases where a model will never
          // be provided and we don't want to force people to pass empty values object instead
          this.createFormState();

          if (model) {
            this.form.setModel(model);
          }

          if (values) {
            this.form.init(values);
          }
        }

        componentWillUpdate(nextProps) {
          const { model, values } = nextProps;
          const { model: oldModel, values: oldValues } = this.props;

          if (model && !model.isEqual) {
            console.warn('Bad model', model);
          }

          if (nextProps && model && !model.isEqual(oldModel)) {
            this.createFormState();

            this.form.setModel(model);
          }

          if (nextProps && values && !isEqual(values, oldValues)) {
            this.createFormState();

            this.form.init(values);
          }
        }

        componentDidCatch(error, errorInfo) {
          const { platform, userAgent } = window.navigator;
          const { pathname } = window.location;
          const { clientWidth, clientHeight } = document.querySelector('body');

          console.error('Error Boundary Form', error, errorInfo);

          $app.submitErrorReport({
            clientHeight,
            clientWidth,
            info: {
              componentName: 'Form',
              state: this.form.getValues(),
              allForms: getAllFormsPayload(this.form)
            },
            pathname,
            platform,
            userAgent,
            error: error.toString(),
            errorInfo
          });

          this.form.invalidate('A fatal error occurred.');

          this.setState({ formError: error });
        }

        componentWillUnmount() {
          if (this.form && window.currentForm.includes(this.form)) {
            // delay removing from currentForm state for a bit so if this is unmounting due to an error, a parent boundary can catch the state
            setTimeout(() => {
              window.currentForm.splice(window.currentForm.indexOf(this.form), 1);
            }, 100);
          }
        }

        /**
         * Default "Save" functionality. Simply takes the `getModelValues` and calls `save()`
         * on the Model. Override this behavior if you want to handle more complex submit logic.
         */
        handleSave = (form, values) => {
          const { handleSave, model } = this.props;

          if (handleSave) {
            return handleSave(values);
          }

          return model.save(values);
        };

        /**
         * Handles most of the "cancel" cases where we are editing a Model inside a Collection, and
         * want to throw away changes and clear our selected model.
         */
        handleCancel = () => {
          const { handleCancel, collection } = this.props;

          if (handleCancel) {
            handleCancel();
          } else if (collection) {
            collection.clearSelection();
          }
        };

        /**
         * Most destroy cases are fairly straightforward when handled within Forms. Override
         * if you want to do custom things.
         */
        handleRemove = () => {
          const { handleRemove, model } = this.props;

          if (handleRemove) {
            return handleRemove();
          }
          return model.destroy();
        };

        render() {
          return (
            <WrappedComponent
              {...this.props}
              {...this.state}
              form={this.form}
              handleSave={this.handleSave}
              handleCancel={this.handleCancel}
              handleRemove={this.handleRemove}
            />
          );
        }
      }
    );
  };
}
