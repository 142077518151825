import React from 'react';
import { action, computed, observable } from 'mobx';

import { showErrorToast } from 'components/Toast';
import BaseModel from 'models/BaseModel';
import $lookups from 'stores/$lookups';
import { geocode } from 'util/googleMaps';

const ConfirmBody = ({ site }) => (
  <p>
    Are you sure you want to remove <strong>{site.get('title')}</strong>
  </p>
);

class Site extends BaseModel {
  @observable
  countryName;

  constructor(attributes, options) {
    super(attributes, options);

    this.doLookups();
  }

  get defaults() {
    return {
      devices: []
    };
  }

  get urlRoot() {
    return '/api/portal/sites';
  }

  get omitDuringSerialize() {
    return ['devices'];
  }

  /**
   * Allowed to pass a React component as `text` as well. React must
   * be in scope for this to work.
   */
  get removalConfirmText() {
    return {
      title: 'Remove Site',
      text: <ConfirmBody site={this} />
    };
  }

  get messages() {
    return {
      create: `Site "${this.get('title')}" was added successfully`,
      update: `Site "${this.get('title')}" was updated successfully`,
      destroy: `Site "${this.get('title')}" was removed successfully`
    };
  }

  @computed
  get numDevices() {
    const devices = this.get('devices');
    return devices && devices.length > 1 ? `${devices.length} Devices` : '1 Device';
  }

  save(attributes = {}) {
    const { city, country, region, postal } = attributes;

    const onSuccess = success => {
      this.doLookups();
      return success;
    };

    if (country && region && (city || postal)) {
      return geocode(attributes).then(
        ({ lat, lng: lon }) => super.save({ ...attributes, lat, lon }).then(onSuccess),
        error => {
          showErrorToast(error.message);
          return super.save({ ...attributes, lat: undefined, lon: undefined }).then(onSuccess);
        }
      );
    }

    return super.save({ ...attributes, lat: undefined, lon: undefined }).then(onSuccess);
  }

  @action
  doLookups() {
    const country = this.get('country');

    if (country) {
      $lookups.countries(country).then(
        action(countries => {
          const countryOption = countries.find(option => option.value === country);
          this.countryName = countryOption ? countryOption.label : country;
          this.setLastUpdated();
        })
      );
    } else {
      this.countryName = '';
      this.setLastUpdated();
    }
  }
}

export default Site;
