import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Radio, RadioGroup } from '@blueprintjs/core';
import { Flex } from 'components/flexbox';
import Icon from 'components/Icon';
import { KentikLogo } from '../index';

@inject('$auth')
@observer
class ShareLevelSelector extends Component {
  static defaultProps = {
    showLabel: true,
    privateValue: 'self',
    sharedValue: 'org',
    globalValue: 'global',
    showPrivateShareLevel: true,
    showGlobalShareLevel: true
  };

  handleChange = e => {
    const { field } = this.props;
    field.setValue(e.target.value);
  };

  render() {
    const {
      field,
      label,
      showLabel,
      $auth,
      privateValue,
      sharedValue,
      globalValue,
      showGlobalShareLevel,
      showPrivateShareLevel
    } = this.props;

    return (
      <RadioGroup
        className="pt-form-group"
        label={showLabel && (label || field.label || 'Share Level')}
        onChange={this.handleChange}
        selectedValue={field.getValue()}
      >
        {$auth.isPresetCompany &&
          showGlobalShareLevel && (
            <Radio
              value={globalValue}
              label={
                <Flex style={{ position: 'relative', top: -4 }}>
                  <KentikLogo
                    onlyMark
                    alt="Kentik"
                    style={{ width: 20, height: 20, marginRight: 8 }}
                    fillColor="#ff850e"
                  />

                  <div>
                    <strong>Global / Preset</strong> <br />
                    <small className="pt-text-muted">Visible to all Kentik customers as a Preset.</small>
                  </div>
                </Flex>
              }
            />
          )}

        {showPrivateShareLevel && (
          <Radio
            value={privateValue}
            label={
              <Flex style={{ position: 'relative', top: -4 }}>
                <Icon name="person" className="pt-text-muted" style={{ marginRight: 8, fontSize: 20, marginTop: 3 }} />
                <div>
                  <strong>Private</strong> <br />
                  <small className="pt-text-muted">Visible only to me</small>
                </div>
              </Flex>
            }
          />
        )}

        <Radio
          value={sharedValue}
          label={
            <Flex style={{ position: 'relative', top: -4 }}>
              <Icon name="people" className="pt-text-muted" style={{ marginRight: 8, fontSize: 20, marginTop: 3 }} />
              <div>
                <strong>Shared</strong> <br />
                <small className="pt-text-muted">Visible to others in my company</small>
              </div>
            </Flex>
          }
        />
      </RadioGroup>
    );
  }
}

export default ShareLevelSelector;
