import React from 'react';
import { action } from 'mobx';
import $auth from 'stores/$auth';

export function filterGroupContains(groups, filterFields) {
  return (
    Array.isArray(groups) &&
    groups.some(group => {
      if (Array.isArray(group.filters) && group.filters.some(filter => filterFields.includes(filter.filterField))) {
        return true;
      }

      return group.filterGroups ? filterGroupContains(group.filterGroups, filterFields) : false;
    })
  );
}

export function dataviewContains(dataviews, constraints) {
  const { metrics, filterFields } = constraints;

  return dataviews.some(
    dataview =>
      dataview.queryBuckets &&
      dataview.queryBuckets.some(bucket =>
        bucket.queries.some(queryModel => {
          const metric = queryModel.get('metric');
          const filters = queryModel.get('filters');
          const filters_obj = queryModel.get('filters_obj');

          return (
            (metric && metrics.some(m => metric.includes(m))) ||
            (filters && filterGroupContains(filters.filterGroups, filterFields)) ||
            (filters_obj && filterGroupContains(filters_obj.filterGroups, filterFields))
          );
        })
      )
  );
}

export function queryContains(query, constraints) {
  const { metric, filters, viz_type } = query;
  const { metrics, filterFields, vizTypes } = constraints;

  return (
    (metric && metrics.some(m => metric.includes(m))) ||
    (filters && filterGroupContains(filters.filterGroups, filterFields)) ||
    (vizTypes && vizTypes.includes(viz_type))
  );
}

class AbstractDependency {
  dataviews;

  formState;

  showConfigureButton = true;

  constructor(dataviews, formState, showConfigureButton) {
    this.dataviews = dataviews;
    this.formState = formState;
    this.showConfigureButton = showConfigureButton;
  }

  get constraints() {
    return { metrics: [], filterFields: [] };
  }

  get formRequires() {
    return queryContains(this.formState.getValues(), this.constraints);
  }

  get queryRequires() {
    return dataviewContains(this.dataviews, this.constraints);
  }

  get requires() {
    return this.formState && this.formState.dirty ? this.formRequires : this.queryRequires;
  }

  get meets() {
    console.error('Dependency setup w/o a meets impl');
    return true;
  }

  get title() {
    return 'You have dependency check failures.';
  }

  get icon() {
    return 'pt-icon-warning-sign';
  }

  get intent() {
    return 'pt-intent-warning';
  }

  get message() {
    console.error('Dependency setup w/o a message impl');
    return <span />;
  }

  get preventQuery() {
    return false;
  }

  @action
  validate() {
    const { meets, preventQuery } = this;

    if (preventQuery && this.dataviews.length && !$auth.isPresetCompany) {
      this.dataviews.forEach(dataview => (dataview.preventQuery = !meets));
    }

    return meets;
  }
}

export default AbstractDependency;
