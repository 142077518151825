import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Flex, Box } from 'components/flexbox';
import { Button, Intent } from '@blueprintjs/core';
import { getCookie, setCookie } from 'util/cookies';

@observer
class CookieNotice extends Component {
  state = {
    isOpen: false
  };

  componentDidMount() {
    if (!getCookie('hidecookiemessage')) {
      setTimeout(() => this.setState({ isOpen: true }), 1300);
    }
  }

  handleAgree = () => {
    setCookie('hidecookiemessage', true, 5 * 365 * 24 * 60 * 60);
    this.setState({ isOpen: false });
  };

  render() {
    if (!this.state.isOpen) return null;

    return (
      <Flex justify="center">
        <Flex className="cookie-notice pt-ui-text-large" align="center">
          <Box>
            We use cookies to deliver our services.
            <br />
            By using our website, you agree to the use of cookies as described in our{' '}
            <a href="https://www.kentik.com/privacy-policy">Privacy Policy</a>.
          </Box>
          <Box ml={2} style={{ flexShrink: 0 }}>
            <Button className="pt-large" onClick={this.handleAgree} intent={Intent.PRIMARY}>
              I Agree
            </Button>
          </Box>
        </Flex>
      </Flex>
    );
  }
}

export default CookieNotice;
