import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { NonIdealState, Spinner } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { Page } from 'components/page';
import ConfirmDialogModel from 'models/ConfirmDialogModel';

import DashboardHeader from './DashboardHeader';
import DashboardItemGrid from './DashboardItemGrid';
import DashboardDetailsDialog from './DashboardDetailsDialog';
import DashboardItemDialog from './DashboardItem/DashboardItemDialog';
import CloneDashboardItemDialog from './DashboardItem/CloneDashboardItemDialog';

@inject('$dashboard', '$explorer')
@observer
// Note: we must export here because withRouter makes the default export stateless
// and we need to extend this class for export
export class DashboardView extends Component {
  static defaultProps = {
    showSidebar: true,
    showHeader: true
  };

  @observable
  dockCollapsed = false;

  confirmDialog;

  componentWillMount() {
    const { $dashboard } = this.props;
    this.confirmDialog = new ConfirmDialogModel({
      confirmText: (
        <span>
          There are dashboards that link to the dashboard you are trying to delete. Removing the dashboard will prevent
          those dashboards from navigating to it. Are you sure you want to continue?
        </span>
      ),
      model: $dashboard.dashboard,
      onConfirm: this.finalizeDashboardRemove
    });
  }

  componentWillUnmount() {
    this.props.$dashboard.reset();
  }

  handleDashboardRemove = () => {
    const { dashboard } = this.props.$dashboard;

    dashboard.fetchDependentsAndDependencies().then(() => {
      if (dashboard.get('dependents').length) {
        this.confirmDialog.showConfirmDialog();
      } else {
        this.finalizeDashboardRemove();
      }
    });
  };

  finalizeDashboardRemove = () => {
    const { dashboard } = this.props.$dashboard;

    return dashboard.destroy().then(success => success && this.props.history.push('/library'));
  };

  handleAddDashboardItem = panel_type => {
    const { $dashboard } = this.props;

    $dashboard.addDashboardItem(panel_type || 'explorer_query');
  };

  handleCloseDashboardItem = () => {
    this.props.$dashboard.setEditingDashboardItem(false);
  };

  checkNeedsScrollToBottom = () => {
    const { $dashboard } = this.props;
    if ($dashboard.needsScrollToBottom) {
      this.grid.scrollTop = this.grid.scrollHeight;
      $dashboard.needsScrollToBottom = false;
    }
  };

  @action
  toggleDockCollapsed = () => {
    this.dockCollapsed = !this.dockCollapsed;
  };

  gridRef = ref => {
    this.grid = ref;
  };

  render() {
    const { $dashboard, history } = this.props;
    const { dashboard, isEditingProperties, isFetching, isEditingDashboardItem, isCloningDashboardItem } = $dashboard;
    const {
      location: { state: historyState }
    } = history;

    if (!dashboard) {
      return (
        <Page className="dashboard-view" title="Loading Dashboard...">
          <Flex flexAuto justify="center" align="center">
            <Spinner />
          </Flex>
        </Page>
      );
    }

    const dash_title = dashboard.get('dash_title');

    const showEmptyView = !isFetching && !dashboard.hasItems;
    const showItemGrid = !isFetching && dashboard.hasItems;
    const dashboardNavigationHistory = historyState && historyState.dashboardNavigationHistory;

    let parentPanel;
    if (dashboardNavigationHistory) {
      parentPanel = dashboardNavigationHistory[dashboardNavigationHistory.length - 1];
    }

    return (
      <Page title={dash_title} className="dashboard-view">
        <Flex style={{ height: '100%' }}>
          <Flex flexAuto flexColumn>
            <DashboardHeader
              parentPanel={parentPanel}
              navigationHistory={dashboardNavigationHistory}
              onAddItem={this.handleAddDashboardItem}
              onDashboardRemove={this.handleDashboardRemove}
            />

            {isFetching && (
              <Flex flexAuto justify="center" align="center">
                <Spinner />
              </Flex>
            )}
            {showEmptyView && (
              <div style={{ height: 'calc(100vh - 400px)' }}>
                <NonIdealState
                  title="No Panels"
                  visual="new-grid-item"
                  description="This dashboard does not have any panels added yet."
                />
              </div>
            )}
            <Flex flexAuto className="overflow-auto" boxRef={this.gridRef}>
              {showItemGrid && (
                <DashboardItemGrid
                  checkScroll={this.checkNeedsScrollToBottom}
                  parentPanel={parentPanel}
                  collapsed={this.dockCollapsed}
                  navigationHistory={dashboardNavigationHistory}
                  onToggleDockCollapsed={this.toggleDockCollapsed}
                  dockCollapsed={this.dockCollapsed}
                />
              )}
            </Flex>
          </Flex>
        </Flex>

        {isEditingProperties && (
          <DashboardDetailsDialog
            isOpen={isEditingProperties}
            onSubmit={$dashboard.handleDashboardSave}
            onClose={() => $dashboard.setEditingProperties(false)}
            model={dashboard}
          />
        )}

        {isEditingDashboardItem &&
          dashboard.selectedPanel && (
            <DashboardItemDialog
              collection={dashboard.get('items')}
              dashboard={dashboard}
              isOpen={isEditingDashboardItem && dashboard.selectedPanel}
              onClose={this.handleCloseDashboardItem}
              model={dashboard.selectedPanel}
              activeTab={dashboard.selectedPanel && dashboard.selectedPanel.get('editActiveTab')}
              showDashboardSelect={dashboard.selectedPanel && !dashboard.selectedPanel.get('dashboard')}
            />
          )}

        {isCloningDashboardItem && (
          <CloneDashboardItemDialog isOpen={isCloningDashboardItem} srcDashboardItem={dashboard.selectedPanel} />
        )}
        {this.confirmDialog.component}
      </Page>
    );
  }
}

export default withRouter(DashboardView);
