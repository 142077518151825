import React from 'react';

import { ShareLevelIcon } from 'components/ShareLevelBadge';

const CategoryHeading = props => {
  const { item } = props;
  return (
    <div className={`category-heading ${item.shareLevel}`}>
      <ShareLevelIcon item={item} minimal />
      {item.get('category.name') || 'Uncategorized'}
    </div>
  );
};

export default CategoryHeading;
