import React from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

import { Box, Flex } from 'components/flexbox';
import { Field, Input } from 'components/forms';
import ColorPicker from 'components/forms/ColorPicker';

const StaticRange = (props /* , context */) => {
  const { range, onRemove, valueLabel, allowRemove, count, valueMaxWidth } = props;

  return (
    /* fix this css, this is not a threshold */
    <Box className="threshold">
      <Box>
        <Flex className="static-condition single-line-fields" style={{ alignItems: 'center' }} mb={2}>
          <Field
            className="no-margin"
            field={range['data.value']}
            fieldStyle={{ minWidth: 137 }}
            showLabel={false}
            showHelpText={false}
          >
            <ColorPicker />
          </Field>
          <Box style={{ whiteSpace: 'nowrap' }}>
            {count === 1 ? 'values up to (inclusive)' : ' values above previous range up to (inclusive)'}
          </Box>
          <Field
            className="no-margin"
            field={range.to}
            inputClassName="small-number-field"
            showLabel={false}
            showHelpText={false}
            fieldStyle={{ maxWidth: valueMaxWidth || 125 }}
          >
            <Input />
          </Field>
          <Flex>{valueLabel}</Flex>
          {allowRemove && (
            <Button
              style={{ marginTop: 1, height: 0, minWidth: '10px' }}
              className="pt-minimal"
              onClick={onRemove}
              iconName="cross"
              intent={Intent.DANGER}
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
};

StaticRange.contextTypes = {
  form: any
};

export default observer(StaticRange);
