import { action } from 'mobx';
import api from 'util/api';
import AbstractDataViewModel from 'models/AbstractDataViewModel';
import RawFlowQueryModel from 'models/analytics/RawFlowQueryModel';
import { getQueriesForHash } from 'services/urlHash';
import { nestFilterGroup } from 'util/utils';
import { fields as rawFlowFormFields } from 'forms/config/rawFlow';
import ConfigureRawFlowForm from './ConfigureRawFlowForm';

export default class RawFlowDataViewModel extends AbstractDataViewModel {
  constructor(viewModel) {
    super();
    this.viewModel = viewModel || new RawFlowQueryModel();
  }

  get formConfig() {
    return rawFlowFormFields;
  }

  get formComponent() {
    return ConfigureRawFlowForm;
  }

  get isFlowVisualization() {
    return false;
  }

  get viewType() {
    return 'rawFlow';
  }

  get viewName() {
    return 'Raw Flow';
  }

  @action
  setTitle = title => {
    this.dvTitle = title;
  };

  @action
  refresh = async () => {
    this.loading = true;
    const rawFlowQuery = await getQueriesForHash(this.hash, { normalize: false });
    if (this.dashboardQuery) {
      this.overlaySettings(rawFlowQuery, this.dashboardQuery);
    }
    const rawFlowData = await this.fetchRawFlowData(rawFlowQuery);
    this.viewModel.set({
      id: this.hash,
      rawFlowQuery,
      ...rawFlowData
    });
    this.loading = false;
  };

  fetchRawFlowData = queryData => {
    const data = Object.assign({}, queryData, {
      filters_obj: queryData.filters,
      devices: queryData.device_name
    });
    return api.post('/api/portal/analytics/raw-flow/', { data });
  };

  @action
  initializeHash = async hash => {
    this.loading = true;
    if (hash) {
      this.hash = hash;
    }
    await this.refresh();
    this.setFullyLoaded();
    return this.viewModel.get();
  };

  @action
  addFilters(filters, filters_overlay) {
    if (filters) {
      if (filters.connector === 'Any') {
        nestFilterGroup(filters);
      }
      if (filters_overlay && filters_overlay.filterGroups && filters_overlay.filterGroups.length) {
        if (filters_overlay.connector === 'Any') {
          nestFilterGroup(filters_overlay);
        }
        filters.filterGroups = (filters.filterGroups || []).concat(filters_overlay.filterGroups);
      }
      return filters;
    }
    return filters_overlay;
  }

  @action
  addSavedFilters(saved_filters1, saved_filters2) {
    return (saved_filters1 || []).concat(saved_filters2 || []);
  }

  @action
  overlaySettings(rawFlowQuery, query) {
    const { time_locked, device_locked, filter_source } = rawFlowQuery;
    if (!time_locked) {
      const { starting_time, ending_time, lookback_seconds, time_format } = query;
      Object.assign(rawFlowQuery, { starting_time, ending_time, lookback_seconds, time_format });
    }
    if (!device_locked) {
      const { all_devices, device_labels, device_name, device_sites, device_types } = query;
      Object.assign(rawFlowQuery, { all_devices, device_labels, device_name, device_sites, device_types });
    }
    let dashboardFilters;
    if (filter_source === 'dashboard') {
      const { filters, filters_obj, saved_filters } = query;
      dashboardFilters = filters_obj || filters;
      Object.assign(rawFlowQuery, { filters: dashboardFilters, filters_obj: dashboardFilters, saved_filters });
    } else if (filter_source === 'additive') {
      const { filters, filters_obj, saved_filters } = query;
      dashboardFilters = filters_obj || filters;
      rawFlowQuery.filters = this.addFilters(rawFlowQuery.filters, dashboardFilters);
      rawFlowQuery.saved_filters = this.addSavedFilters(rawFlowQuery.saved_filters, saved_filters);
    }
  }

  @action
  initializeHashWithOverrides = async (saved_query_id, query) => {
    this.loading = true;
    this.hash = saved_query_id;
    this.dashboardQuery = query;
    await this.refresh();
    this.setFullyLoaded();
    return this.viewModel.get();
  };

  setUpdateFrequency(frequency = 0) {
    if (frequency) {
      this.updateFrequency = setInterval(this.refresh, frequency * 1000);
    } else if (this.updateFrequency) {
      clearInterval(this.updateFrequency);
      delete this.updateFrequency;
    }
  }

  destroy() {
    clearInterval(this.updateFrequency);
  }
}
