import React, { Component } from 'react';
import { observer } from 'mobx-react';
import sizeMe from 'react-sizeme';
import { Spinner } from '@blueprintjs/core';
import { select } from 'd3-selection';
import { scaleLinear, scaleOrdinal, scaleUtc, schemeCategory20 } from 'd3-scale';
import { range } from 'd3-array';
import { line as d3Line } from 'd3-shape';
import { axisBottom, axisLeft } from 'd3-axis';
import ReactFauxDOM from 'react-faux-dom';
import { Box } from 'components/flexbox';
import {
  formatData,
  getMaxY,
  createDataGroup,
  getTimeRange,
  getGraphStartEndTime,
  getPrimaryMetric,
  getBaselineValue
} from './debugGraphHelpers';

const sizeMeHOC = sizeMe({
  monitorHeight: false
});

@observer
class DebugGraph extends Component {
  addLine(options) {
    const { svg, x1, y1, x2, y2, cssClass, color } = options;
    svg
      .append('line')
      .attr('x1', x1)
      .attr('y1', y1)
      .attr('x2', x2)
      .attr('y2', y2)
      .attr('class', cssClass)
      .style('stroke-width', 1)
      .style('stroke', color)
      .style('fill', 'none');
  }

  addActivateGraphLegends(options) {
    const { svg, containerClassName, isPositionGraph, legendX, legendY_last, colorSeries } = options;

    const positionGraphLegends = [
      { label: 'Current', color: '#565656', cssClass: 'current_position', active: false },
      { label: 'Baseline Final', color: '#EE5500', cssClass: 'history_position', active: false }
    ];

    const activateGraphLegends = [
      { label: 'Matches', color: '#565656', cssClass: 'matches', active: false },
      { label: 'Baseline', color: colorSeries(7), cssClass: 'baseline_no_fallback', active: false },
      { label: 'Baseline Fallback', color: colorSeries(8), cssClass: 'baseline_fallback', active: false },
      { label: 'No Current Value', color: colorSeries(12), cssClass: 'matches_no_current', active: false },
      { label: 'Fallback Value', color: colorSeries(9), cssClass: 'fallback_value', active: false },
      { label: 'Static Threshold', color: colorSeries(10), cssClass: 'static_threshold', active: false },
      { label: 'Policy Min Traffic', color: colorSeries(11), cssClass: 'policy_min_traffic', active: false }
    ];
    const graphLegends = isPositionGraph ? positionGraphLegends : activateGraphLegends;

    let legendY_activate;

    graphLegends.forEach((l, i) => {
      legendY_activate = legendY_last + i * 30;

      // add legend
      const legend = svg
        .append('g')
        .attr('class', 'legend')
        .attr('height', 100)
        .attr('width', 100)
        .attr('transform', 'translate(-20,25)');

      legend
        .append('rect')
        .attr('x', legendX)
        .attr('y', legendY_activate)
        .attr('width', 10)
        .attr('height', 10)
        .attr('class', `legend legend_${l.cssClass}`)
        .style('fill', l.color)
        .on('click', () => {
          const active = !l.active;
          const opacity = active ? 0 : 1;

          select(`.${containerClassName}`)
            .selectAll(`.${l.cssClass}`)
            .style('opacity', opacity);
          l.active = active;
          const labelOpacity = active ? 0.2 : 1;

          select(`.${containerClassName}`)
            .selectAll(`.legend_${l.cssClass}`)
            .style('opacity', labelOpacity);
        });

      legend
        .append('text')
        .attr('x', legendX + 16)
        .attr('y', legendY_activate + 10)
        .attr('class', `legend legend_${l.cssClass}`)
        .text(l.label)
        .on('click', () => {
          const active = !l.active;
          const opacity = active ? 0 : 1;

          select(`.${containerClassName}`)
            .selectAll(`.${l.cssClass}`)
            .style('opacity', opacity);
          l.active = active;
          const labelOpacity = active ? 0.2 : 1;

          select(`.${containerClassName}`)
            .selectAll(`.legend_${l.cssClass}`)
            .style('opacity', labelOpacity);
        });
    });
  }

  // @TODO: figure out why it's double rendering every time.
  render() {
    const {
      graphsData,
      graphRange,
      ctime,
      isPositionGraph,
      size: { width: containerWidth }
    } = this.props;
    const timeRange = getTimeRange(graphRange, ctime);
    const { startDate, endDate } = timeRange;

    if (graphsData && graphsData.alert_activate && graphsData.alert_long_set) {
      const formattedGraphsData = formatData(graphsData, startDate, endDate);
      const data_alert_activate = formattedGraphsData.alert_activate;
      const data_alert_long_set = formattedGraphsData.alert_long_set;
      const data_alert_threshold = graphsData.alert_threshold && graphsData.alert_threshold[0];
      // const units = 'bps';
      const WIDTH = containerWidth;
      const HEIGHT = 500;
      const margin = { top: 30, right: 250, bottom: 30, left: 100 };
      const width = WIDTH - margin.left - margin.right;
      const height = HEIGHT - margin.top - margin.bottom;
      // const MIN_WIDTH = 600;

      const primaryMetricLabel = data_alert_activate[0]
        ? getPrimaryMetric(data_alert_activate[0].alert_metric).label
        : '';
      const yAxisLabel = isPositionGraph ? 'Position' : `Value (${primaryMetricLabel})`;

      const x = scaleUtc().range([0, width]);
      const y = scaleLinear().range([height, 0]);
      const xAxis = axisBottom(x)
        .tickSize(0 - height)
        .tickPadding(8);
      const yAxis = axisLeft(y)
        .tickSize(0 - width)
        .tickPadding(8);
      const line = d3Line()
        .x(d => x(d.date))
        .y(d => y(d.traffic));

      const svgNode = ReactFauxDOM.createElement('div');

      const containerClassName = `graph_${Math.floor(Math.random() * 100000)}`;

      const svg = select(svgNode)
        .append('svg')
        .attr('width', '100%')
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // find max y between two datasets
      const maxValue = getMaxY(data_alert_activate, data_alert_long_set, data_alert_threshold, isPositionGraph);
      y.domain([0, maxValue * 1.1]); // add some padding on top
      if (maxValue < 20) {
        yAxis.ticks(maxValue);
      } else {
        yAxis.ticks(20, 's');
      }

      // find max time range between two datasets
      const graphStartEndTime = getGraphStartEndTime(data_alert_activate, data_alert_long_set, ctime);
      const { startTime, endTime } = graphStartEndTime;
      x.domain([startTime, endTime]);

      svg
        .append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${height})`)
        .call(xAxis);

      svg
        .append('g')
        .attr('class', 'y axis')
        .call(yAxis)
        .append('text')
        .attr('transform', 'translate(-80),rotate(-90)')
        .attr('y', 6)
        .attr('dy', '.71em')
        .style('text-anchor', 'end')
        .text(yAxisLabel);

      const legendX = width + margin.left + 10; // (i * lSpace * 0.9) + (lSpace / 8);
      let legendY = 0;

      const colorSeries = scaleOrdinal(schemeCategory20).domain(range(0, 19));

      // add long_set graph
      if (data_alert_long_set && data_alert_long_set.length > 0) {
        // format data for d3 multi charts
        const dataGroup = createDataGroup(data_alert_long_set, isPositionGraph);
        // const lSpace = WIDTH / dataGroup.length;
        dataGroup.forEach((d, i) => {
          d.active = false;
          const lineId = d.key.replace(/,/g, '_').replace(/:/g, '_');
          // const lineTitle = d.key;
          const lineData = d.values;
          const lineColor = colorSeries(i);
          // let point;
          // let pos;

          if (lineData.length > 0) {
            lineData.forEach(long_set_item => {
              if (long_set_item.traffic > 0) {
                svg
                  .append('line')
                  .attr('class', `chart-line line_${lineId}`)
                  .attr('x1', x(long_set_item.time_start))
                  .attr('y1', y(long_set_item.traffic))
                  .attr('x2', x(long_set_item.time_end))
                  .attr('y2', y(long_set_item.traffic))
                  .style('stroke-width', 1.5)
                  .style('stroke', lineColor)
                  .attr('id', `line_${lineId}`)
                  .style('fill', 'none');
              }
            });

            svg
              .append('path')
              .datum(lineData)
              .attr('stroke', lineColor)
              .attr('stroke-width', 1)
              .attr('fill', 'none')
              .attr('class', `chart-line line_connect_${lineId}`)
              .attr('d', line)
              .attr('opacity', 0.3)
              .attr('id', `line_${lineId}`);
          }

          // var legendX = width + margin.left + 10; //(i * lSpace * 0.9) + (lSpace / 8);
          legendY = i * 30;

          // add legend
          const legend = svg
            .append('g')
            .attr('class', 'legend')
            .attr('height', 100)
            .attr('width', 100)
            .attr('transform', 'translate(-20,25)');

          legend
            .append('rect')
            .attr('x', legendX)
            .attr('y', legendY)
            .attr('width', 10)
            .attr('height', 10)
            .attr('class', `legend legend_${lineId}`)
            .style('fill', colorSeries(i))
            .on('click', () => {
              const active = !d.active;
              const opacity = active ? 0 : 1;
              const opacity_connect = active ? 0 : 0.3;

              select(`.${containerClassName}`)
                .selectAll(`.line_${lineId}`)
                .style('opacity', opacity);

              select(`.${containerClassName}`)
                .selectAll(`.line_connect_${lineId}`)
                .style('opacity', opacity_connect);
              d.active = active;
              const labelOpacity = active ? 0.2 : 1;

              select(`.${containerClassName}`)
                .selectAll(`.legend_${lineId}`)
                .style('opacity', labelOpacity);
            });

          legend
            .append('text')
            .attr('x', legendX + 16)
            .attr('y', legendY + 10)
            .attr('class', `legend legend_${lineId}`)
            .text(d.key)
            .on('click', () => {
              const active = !d.active;
              const opacity = active ? 0 : 1;
              const opacity_connect = active ? 0 : 0.3;

              select(`.${containerClassName}`)
                .selectAll(`.line_${lineId}`)
                .style('opacity', opacity);

              select(`.${containerClassName}`)
                .selectAll(`.line_connect_${lineId}`)
                .style('opacity', opacity_connect);
              d.active = active;
              const labelOpacity = active ? 0.2 : 1;

              select(`.${containerClassName}`)
                .selectAll(`.legend_${lineId}`)
                .style('opacity', labelOpacity);
            });
        });
      }

      // add alert_activate graph
      let xValue;
      let yValue;
      let xMap;
      let yMap;
      let yValueBaseline;
      let yMapBaseline;

      if (data_alert_activate && data_alert_activate.length > 0) {
        // draw activate scatter graph
        xValue = d => d.date;
        xMap = d => x(xValue(d));
        if (isPositionGraph) {
          const yMap_current = d => y(d.current_position);
          // draw current_position scatter plot
          svg
            .selectAll('.current_position')
            .data(data_alert_activate)
            .enter()
            .append('circle')
            .attr('class', 'current_position')
            .attr('r', 2)
            .attr('cx', xMap)
            .attr('cy', yMap_current)
            .style('fill', '#565656');

          // draw history_position scatter plot
          const yMap_history = d => y(d.history_position);
          svg
            .selectAll('.history_position')
            .data(data_alert_activate)
            .enter()
            .append('circle')
            .attr('class', 'history_position')
            .attr('r', 2)
            .attr('cx', xMap)
            .attr('cy', yMap_history)
            .style('fill', '#EE5500');
        } else {
          yValue = d => d.alert_value;
          yMap = d => y(yValue(d));
          yValueBaseline = d => d.alert_baseline;
          yMapBaseline = d => y(yValueBaseline(d));

          // add the tooltip area to the webpage
          // const tooltip = d3.select('body').append('div').attr('class', 'tooltip').style('opacity', 0);

          const getMatchColor = d => {
            const baselineValue = getBaselineValue(parseInt(d.baseline_used));
            const matchColor = baselineValue && baselineValue.match ? '#565656' : colorSeries(12);
            return matchColor;
          };
          const getMatchCssClass = d => {
            const baselineValue = getBaselineValue(parseInt(d.baseline_used));
            const matchCss = baselineValue && baselineValue.match ? 'dot matches' : 'dot matches_no_current';
            return matchCss;
          };

          // draw match dots
          svg
            .selectAll('.dot')
            .data(data_alert_activate)
            .enter()
            .append('circle')
            .attr('class', getMatchCssClass)
            .attr('r', 2)
            .attr('cx', xMap)
            .attr('cy', yMap)
            .style('fill', getMatchColor);

          const getColor = d => {
            const baselineValue = getBaselineValue(parseInt(d.baseline_used));
            const baselineColor = baselineValue && baselineValue.baselineFallback ? colorSeries(8) : colorSeries(7);
            return baselineColor;
          };
          const getCssClass = d => {
            const baselineValue = getBaselineValue(parseInt(d.baseline_used));
            const baselineCss =
              baselineValue && baselineValue.baselineFallback
                ? 'baseline baseline_fallback'
                : 'baseline baseline_no_fallback';
            return baselineCss;
          };

          svg
            .selectAll('.baseline')
            .data(data_alert_activate)
            .enter()
            .append('circle')
            .attr('class', getCssClass)
            .attr('r', 2)
            .attr('cx', xMap)
            .attr('cy', yMapBaseline)
            .style('fill', getColor);
        }
      }

      // draw a vertical line at ctime
      if (ctime && ctime <= endTime && ctime >= startTime) {
        this.addLine({
          svg,
          x1: x(ctime),
          y1: 0,
          x2: x(ctime),
          y2: height,
          cssClass: 'ctime_line',
          color: 'red'
        });
      }

      // check for data from threshold table, unless it's position graph
      if (!isPositionGraph && data_alert_threshold) {
        const policy_min_traffic = data_alert_threshold.min_traffic;
        let fallback_value;
        let static_threshold;

        if (data_alert_threshold.conditions && JSON.parse(data_alert_threshold.conditions)) {
          const _conditions = JSON.parse(data_alert_threshold.conditions);
          const primaryMetric = data_alert_activate[0] && getPrimaryMetric(data_alert_activate[0].alert_metric);
          if (primaryMetric) {
            const primaryCondition = _conditions.find(
              cond =>
                cond.type === 'basic' &&
                (cond.metric === primaryMetric.metric || cond.metric === primaryMetric.metric_alt)
            );
            if (primaryCondition) {
              static_threshold = primaryCondition.value;
              if (primaryCondition.metric && primaryCondition.metric.includes('perc_')) {
                static_threshold /= 1000;
              }
            }
          }
        }

        if (data_alert_threshold.baseline && data_alert_threshold.baseline.miss_val) {
          fallback_value = data_alert_threshold.baseline.miss_val;
        }

        if (fallback_value) {
          // draw horizontal line for fallback_value
          this.addLine({
            svg,
            x1: 0,
            y1: y(fallback_value),
            x2: width,
            y2: y(fallback_value),
            cssClass: 'fallback_value',
            color: colorSeries(9)
          });
        }
        if (static_threshold) {
          // draw horizontal line for static_threshold
          this.addLine({
            svg,
            x1: 0,
            y1: y(static_threshold),
            x2: width,
            y2: y(static_threshold),
            cssClass: 'static_threshold',
            color: colorSeries(10)
          });
        }
        if (policy_min_traffic) {
          // draw horizontal line for min_traffic
          this.addLine({
            svg,
            x1: 0,
            y1: y(policy_min_traffic),
            x2: width,
            y2: y(policy_min_traffic),
            cssClass: 'policy_min_traffic',
            color: colorSeries(11)
          });
        }
      }

      // add legends for activate graph
      const legendY_last = legendY + 30;
      this.addActivateGraphLegends({ svg, containerClassName, isPositionGraph, legendX, legendY_last, colorSeries });
      const graphTitle = isPositionGraph ? 'Position Graph' : 'Graph';

      return (
        <div className="debug-graph">
          <h4>{`${graphRange} ${graphTitle}`}</h4>
          <div className={`line-chart ${containerClassName}`}>{svgNode.toReact()}</div>
        </div>
      );
    }
    if (!isPositionGraph) {
      return (
        <Box style={{ textAlign: 'center' }}>
          <Spinner className="pt-small" />
        </Box>
      );
    }
    return null;
  }
}

export default sizeMeHOC(props => <DebugGraph {...props} />);
