import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box } from 'components/flexbox';

import DebugGraph from './DebugGraph';
import DebugFieldsForm from './DebugFields';

@inject('$alerts')
@observer
class AlertKeyDebug extends Component {
  componentWillMount() {
    const { $alerts } = this.props;
    const { debugFields } = $alerts;
    const { alert_id, alert_key, ctime } = debugFields;
    if (alert_id && typeof alert_key === 'string' && ctime && moment(ctime).isValid()) {
      $alerts.fetchDebugGraphsData(debugFields);
    }
  }

  handleFieldsChange = debugFields => {
    this.props.$alerts.setDebugFields(debugFields);
  };

  render() {
    const ranges = ['14 hr', '56 hr', '15 days', '30 days'];
    const { debugFields, graphsData } = this.props.$alerts;
    const { ctime } = debugFields;

    return (
      <Fragment>
        <Box pl={3.5} mt={2}>
          <DebugFieldsForm values={debugFields} onSubmit={this.handleFieldsChange} />
        </Box>
        <Box className="pt-dialog-body" pt={1}>
          {ranges.map(range => (
            <Box className="pt-card flat" px={1} py={2} key={range} mb={2}>
              <DebugGraph graphsData={graphsData} graphRange={range} ctime={ctime} />
              <DebugGraph graphsData={graphsData} graphRange={range} ctime={ctime} isPositionGraph />
            </Box>
          ))}
        </Box>
      </Fragment>
    );
  }
}

export default AlertKeyDebug;
