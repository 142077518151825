import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect, withRouter } from 'react-router-dom';

import { Flex, Box } from 'components/flexbox';
import Icon from 'components/Icon';

export const TaskItem = ({
  title,
  src,
  className,
  rightIconName = 'chevron-right',
  description,
  iconName,
  onClick,
  style,
  small = false,
  showNewTag = false
}) => (
  <Flex align="center" className={className || 'onboarding-task'} onClick={onClick} style={style} flexAuto>
    <Box maxWidth={90} mr={2}>
      {src && <img src={src} style={{ maxWidth: small ? 36 : 50 }} alt="task icon" />}
      {iconName && <Icon className="pt-text-muted" name={iconName} style={{ fontSize: 30 }} />}
    </Box>
    <Box flexAuto>
      <Flex>
        <h5 style={{ marginBottom: 6, fontWeight: 500, lineHeight: '1.2em', fontSize: small ? 15 : 17 }}>{title}</h5>
        {showNewTag && (
          <div
            className="pt-tag pt-strong pt-round pt-intent-warning"
            style={{ position: 'relative', top: -4, marginLeft: 6, alignSelf: 'center' }}
          >
            NEW
          </div>
        )}
      </Flex>
      <span className="pt-text-muted">{description}</span>
    </Box>
    <Box maxWidth={90} ml={2}>
      <Icon className="pt-text-muted" name={rightIconName} style={{ fontSize: 30 }} />
    </Box>
  </Flex>
);

@observer
class TaskList extends Component {
  state = {};

  handleItemClick = path => {
    if (path) {
      this.setState({ redirect: path });
    }
  };

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect push to={redirect} />;
    }
    const { tasks, layout } = this.props;

    if (layout === 'grid') {
      return tasks.map(taskRow => (
        <Flex
          gutter={1}
          align="stretch"
          wrap
          key={`taskRow${taskRow.length + Math.random()}`}
          style={{ minHeight: 124 }}
        >
          {taskRow.map(task => (
            <TaskItem
              key={task.title}
              {...task}
              doOnClick={this.handleItemClick}
              style={{
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 16
              }}
            />
          ))}
        </Flex>
      ));
    }
    const taskComponents = tasks.flat().map(task => (
      <Box px={1} key={task.title} sm={12} md={12} col={6} pb={2}>
        <TaskItem {...task} doOnClick={this.handleItemClick} />
      </Box>
    ));

    return <Box>{taskComponents}</Box>;
  }
}

export default withRouter(TaskList);
