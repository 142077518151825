import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

@inject('$app', '$search')
@observer
class HelpLink extends Component {
  triggerHelp = () => {
    const { $app, $search, buttonSource, helpTopic } = this.props;
    $app.openHelp(buttonSource);

    const timestamp = Date.now();

    $search.query(helpTopic, timestamp, true).then(response => {
      delete response.timestamp;
      $app.loadHelp(response);
    });
  };

  render() {
    const { helpTopic, buttonSource, $app, $search, ...rest } = this.props;

    if ($app.isSubtenant) {
      return null;
    }

    return (
      <span>
        <Button iconName="help" className="pt-minimal" intent={Intent.PRIMARY} onClick={this.triggerHelp} {...rest} />
      </span>
    );
  }
}

export default HelpLink;
