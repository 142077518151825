import { action } from 'mobx';
import Collection from 'models/Collection';
import api from 'util/api';

import QueryTemplateCategory from './QueryTemplateCategory';

class CategoryCollection extends Collection {
  get model() {
    return QueryTemplateCategory;
  }

  get url() {
    return '/api/portal/queryTemplateCategories';
  }

  // bunk save
  @action
  save = categories => api.put(this.url, { data: categories });

  @action
  remove = categoryId => api.del(`${this.url}/${categoryId}`);
}

export default CategoryCollection;
