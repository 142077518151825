import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent, Radio, RadioGroup } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import SavedViewsDialog from 'views/SavedViews/SavedViewsDialog';
import DashboardsDialog from 'views/Dashboards/DashboardsDialog';

@inject('$app', '$users', '$savedViews', '$dashboards')
@observer
class LandingPage extends Component {
  state = {
    savedViewsDialogOpen: false,
    dashboardsDialogOpen: false
  };

  componentWillMount() {
    const { $users, typeField, valueField } = this.props;

    if (typeField) {
      this.setState({
        selectedType: typeField,
        [`selectedValue${typeField}`]: valueField
      });
    } else if ($users.fullUserProfile.defaultLandingPageType) {
      const { defaultLandingPageType, defaultLandingPageValue } = $users.fullUserProfile;

      this.setState({
        selectedType: defaultLandingPageType,
        [`selectedValue${defaultLandingPageType}`]: defaultLandingPageValue
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedType, selectedValue } = this.state;

    if (!selectedType && !selectedValue && nextProps.typeField) {
      const { typeField, valueField } = nextProps;

      this.setState({
        selectedType: typeField,
        [`selectedValue${typeField}`]: valueField
      });
    } else if (!selectedType && !selectedValue && nextProps.$users.fullUserProfile.defaultLandingPageType) {
      const { defaultLandingPageType, defaultLandingPageValue } = nextProps.$users.fullUserProfile;

      this.setState({
        selectedType: defaultLandingPageType,
        [`selectedValue${defaultLandingPageType}`]: defaultLandingPageValue
      });
    }
  }

  handleTypeChange = e => {
    const { value } = e.target;
    this.setState({ selectedType: value });
    if (this.props.setLandingPageType) {
      this.props.setLandingPageType(value);
    }
    this.setState({ [`selectedValue${value}`]: null });
  };

  handleValueSelect = model => {
    this.setState({
      [`selectedValue${this.state.selectedType}`]: model.id,
      savedViewsDialogOpen: false,
      dashboardsDialogOpen: false
    });
    if (this.props.setLandingPageValue) {
      this.props.setLandingPageValue(model.id);
    }
  };

  toggleSavedViewsDialog = () => {
    this.setState({ savedViewsDialogOpen: !this.state.savedViewsDialogOpen });
  };

  toggleDashboardsDialog = () => {
    this.setState({ dashboardsDialogOpen: !this.state.dashboardsDialogOpen });
  };

  persistChanges = () => {
    const { selectedType } = this.state;
    this.props.$users.updateUserProfile(
      { defaultLandingPageType: selectedType, defaultLandingPageValue: this.getSelectedValue() },
      'Landing Page'
    );
  };

  getSelectedValue() {
    const { selectedType } = this.state;
    return this.state[`selectedValue${selectedType}`];
  }

  renderSelectButton(text, onClick) {
    return <Button className="pt-small" text={text} onClick={onClick} style={{ marginTop: -4, marginLeft: 8 }} />;
  }

  renderDashboardLabel() {
    const { $dashboards } = this.props;
    const { selectedType } = this.state;

    const selectedValue = this.getSelectedValue();
    const selected = selectedType === 'dashboard';
    const selectedModel = selected ? $dashboards.getDashboard(selectedValue) : null;

    return (
      <Flex flexColumn style={{ width: '100%' }}>
        <span>
          Dashboard
          {!selected && '...'}
        </span>
        {selected && (
          <Flex flexAuto justify="space-between" mt={1}>
            {selectedModel && <strong className="pt-text-overflow-ellipsis">{selectedModel.get('dash_title')}</strong>}
            {!selectedModel && <span className="pt-text-muted pt-text-overflow-ellipsis">No dashboard selected</span>}
            {this.renderSelectButton('Select Dashboard', this.toggleDashboardsDialog)}
          </Flex>
        )}
      </Flex>
    );
  }

  renderSavedViewsLabel() {
    const { $savedViews } = this.props;
    const { selectedType } = this.state;

    const selectedValue = this.getSelectedValue();
    const selected = selectedType === 'saved-view';
    const selectedModel = selected ? $savedViews.getSavedView(selectedValue) : null;

    return (
      <Flex flexColumn style={{ width: '100%' }}>
        <span>
          Saved View
          {!selected && '...'}
        </span>
        {selected && (
          <Flex flexAuto justify="space-between" mt={1}>
            {selectedModel && <strong className="pt-text-overflow-ellipsis">{selectedModel.get('view_name')}</strong>}
            {!selectedModel && <span className="pt-text-muted pt-text-overflow-ellipsis">No saved view selected</span>}
            {this.renderSelectButton('Select Saved View', this.toggleSavedViewsDialog)}
          </Flex>
        )}
      </Flex>
    );
  }

  render() {
    const { $app } = this.props;
    const { selectedType = 'library', savedViewsDialogOpen, dashboardsDialogOpen } = this.state;
    const selectedValue = this.getSelectedValue();

    const disabled = !selectedValue && (selectedType === 'saved-view' || selectedType === 'dashboard');

    return (
      <Flex flexColumn mt={2}>
        <h6 className="pt-text-muted" style={{ marginBottom: 12 }}>
          Landing Page
        </h6>
        <RadioGroup selectedValue={selectedType} onChange={this.handleTypeChange}>
          <Radio
            value="library"
            label={
              <span>
                {$app.isV4 ? 'Network Explorer' : 'Library'} <span className="pt-text-muted">(default)</span>
              </span>
            }
          />
          {$app.isV4 && <Radio value="synthetics" label="Synthetics" />}
          <Radio value="explorer" label="Data Explorer" />
          <Radio value="alerting" label="Alerting" />
          <Radio value="dashboard" label={this.renderDashboardLabel()} />
          <Radio value="saved-view" label={this.renderSavedViewsLabel()} />
        </RadioGroup>
        {!this.props.typeField && (
          <Button
            style={{ alignSelf: 'flex-end' }}
            text="Update Landing Page"
            intent={Intent.PRIMARY}
            disabled={disabled}
            onClick={this.persistChanges}
          />
        )}
        <DashboardsDialog
          isOpen={dashboardsDialogOpen}
          onClose={this.toggleDashboardsDialog}
          title="Select A Dashboard"
          onClick={this.handleValueSelect}
        />
        <SavedViewsDialog
          isOpen={savedViewsDialogOpen}
          onClose={this.toggleSavedViewsDialog}
          title="Select A Saved View"
          onClick={this.handleValueSelect}
        />
      </Flex>
    );
  }
}

export default LandingPage;
