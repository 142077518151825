import React from 'react';
import { Flex } from 'components/flexbox';
import KentikLogo from './KentikLogo';

const ShimHeader = () => (
  <Flex flexAuto justify="center" align="center" className="shim-header">
    <a href={`${window.location.origin}/v4`}>
      <KentikLogo />
    </a>
  </Flex>
);

export default ShimHeader;
