import React, { Component } from 'react';
import { observer } from 'mobx-react';

import CopyToClipboardDialog from 'components/CopyToClipboardDialog';

@observer
export default class ShareViewDialog extends Component {
  render() {
    const { shareUrlDialogOpen, onClose } = this.props;

    return (
      shareUrlDialogOpen && (
        <CopyToClipboardDialog
          title="Share View"
          isOpen={shareUrlDialogOpen}
          onClose={onClose}
          value={window.location.href}
        />
      )
    );
  }
}
