import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';
import api from 'util/api';

import { Flex, Box } from 'components/flexbox';
import DataViewWrapper from 'dataviews/DataViewWrapper';
import { ConfigureDataViewButton } from 'dataviews/tools/index';
import DataViewSpinner from 'dataviews/DataViewSpinner';
import AlertScoreboardDataViewModel from 'dataviews/views/AlertScoreboard/AlertScoreboardDataViewModel';

import NoAlertScoreboards from './NoAlertScoreboards';

@inject('$auth', '$alerts')
@observer
class AlertPoliciesScoreboard extends Component {
  dataview = new AlertScoreboardDataViewModel();

  componentWillReceiveProps(nextProps) {
    const { alertCompanySettings } = nextProps;
    if (alertCompanySettings && alertCompanySettings.settings) {
      const { alert_scoreboard_saved_query_id } = alertCompanySettings.settings;
      if (alert_scoreboard_saved_query_id && this.dataview.hash !== alert_scoreboard_saved_query_id) {
        this.dataview.initializeHash(alert_scoreboard_saved_query_id);
      }
    }
  }

  @action
  showConfigureDialog = () => {
    this.dataview.isConfigureDialogOpen = true;
  };

  @action
  closeConfigureDialog = () => {
    this.dataview.isConfigureDialogOpen = false;
  };

  handleUpdateCompanyScoreboard = ({ config }) => {
    const { $alerts } = this.props;
    const settings = { alert_scoreboard_saved_query_id: config.saved_query_id };
    return api.put('/api/portal/alerts/alertSettings', { data: settings }).then(
      action(res => {
        $alerts.alertCompanySettings = res;
      })
    );
  };

  handleRemove = () =>
    this.handleUpdateCompanyScoreboard({ config: { saved_query_id: null } }).then(
      action(() => {
        this.dataview.isConfigureDialogOpen = false;
      })
    );

  render() {
    const { $auth, alertCompanySettings } = this.props;
    const hasScoreboard =
      alertCompanySettings &&
      alertCompanySettings.settings &&
      alertCompanySettings.settings.alert_scoreboard_saved_query_id;

    return (
      <DataViewWrapper dataview={this.dataview}>
        {({ component, className, loading, ConfigureDialog }) => (
          <Box className="pt-card flat" mb={2} style={{ position: 'relative', minHeight: 85 }}>
            {!hasScoreboard && <NoAlertScoreboards onCreateScoreboard={this.showConfigureDialog} />}
            {hasScoreboard && loading && <DataViewSpinner text="Loading Scoreboard data..." loading={loading} />}
            {hasScoreboard &&
              !loading && (
                <Flex className={className} flexAuto flexColumn>
                  {!loading && (
                    <Flex align="center" m={1}>
                      <h5 className="pt-normal no-margin">{this.dataview.title}</h5>
                      {$auth.isAdministrator &&
                        !$auth.isSubtenantUser && (
                          <ConfigureDataViewButton dataview={this.dataview} style={{ marginLeft: 6 }} />
                        )}
                    </Flex>
                  )}
                  {component}
                </Flex>
              )}
            <ConfigureDialog
              dataview={this.dataview}
              onAfterSave={this.handleUpdateCompanyScoreboard}
              onRemove={this.handleRemove}
            />
          </Box>
        )}
      </DataViewWrapper>
    );
  }
}

export default withRouter(AlertPoliciesScoreboard);
