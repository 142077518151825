// reference data
import Validator from 'validatorjs';
import awsLogo from 'aws-logo.svg';
import googleLogo from 'google-cloud-logo.svg';
import azureLogo from 'azure-logo.svg';

Validator.register(
  'azureUniqueStorageAccount',
  function azureUniqueStorageAccount(value) {
    const { model } = this.validator.input;

    return (
      model.collection.filter(
        cloudExport =>
          cloudExport.id !== model.id &&
          cloudExport.get('cloud_provider') === 'azure' &&
          cloudExport.get('properties').azure_storage_account === value,
        { immutable: true }
      ).length === 0
    );
  },
  'The Storage Account Name must be unique'
);
const PROVIDERS = {
  AWS: { id: 'aws', code: 'AWS', name: 'Amazon Web Services (AWS)', logo: awsLogo },
  GCE: { id: 'gce', code: 'GCP', name: 'Google Cloud Platform (GCP)', logo: googleLogo },
  AZURE: { id: 'azure', code: 'Azure', name: 'Microsoft Azure', logo: azureLogo },
  IBM: { id: 'ibm', code: 'IBM', name: 'IBM' },
  UNIVERSAL: { id: 'universal', code: 'Universal', name: 'Universal' },

  byId(id) {
    return this[id.toUpperCase()];
  }
};

const fields = {
  name: {
    label: 'Name',
    rules: 'required'
  },
  'properties.description': {
    label: 'Description',
    defaultValue: ''
  },
  plan_id: {
    label: 'Billing Plan',
    placeholder: 'Select a Plan',
    rules: 'required',

    messages: {
      required: 'The "Plan" field is required'
    }
  },
  enabled: {
    defaultValue: false,
    label: 'Enabled'
  },
  cloud_provider: {
    label: 'Cloud Provider',
    rules: 'required'
  },
  'properties.gce_project': {
    label: 'Project',
    providerRules: 'required'
  },
  'properties.gce_subscription': {
    label: 'Subscription',
    providerRules: 'required'
  },
  'properties.aws_region': {
    // defaultValue: 'us-west-2',
    label: 'S3 Bucket Region',
    providerRules: 'required',
    options: [
      // aws regions are generated via data from this spreadsheet:
      // https://docs.google.com/spreadsheets/d/1RfzKsMPcEZGd3jbncgO5UOllVkSDGsia8TtDHi8ytd0/edit#gid=202311621
      { label: 'US West (Oregon)', value: 'us-west-2' },
      { label: 'US West (N. California)', value: 'us-west-1' },
      { label: 'US East (Ohio)', value: 'us-east-2' },
      { label: 'US East (N. Virginia)', value: 'us-east-1' },
      { label: 'Asia Pacific (Mumbai)', value: 'ap-south-1' },
      { label: 'Asia Pacific (Seoul)', value: 'ap-northeast-2' },
      { label: 'Asia Pacific (Singapore)', value: 'ap-southeast-1' },
      { label: 'Asia Pacific (Sydney)', value: 'ap-southeast-2' },
      { label: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
      { label: 'Canada (Central)', value: 'ca-central-1' },
      { label: 'China (Beijing)', value: 'cn-north-1' },
      { label: 'EU (Frankfurt)', value: 'eu-central-1' },
      { label: 'EU (Ireland)', value: 'eu-west-1' },
      { label: 'EU (London)', value: 'eu-west-2' },
      { label: 'EU (Paris)', value: 'eu-west-3' },
      { label: 'South America (São Paulo)', value: 'sa-east-1' }
    ]
  },
  'properties.aws_bucket': {
    label: 'S3 Bucket Name',
    providerRules: 'required|min:3|max:63|regex:/^\\S*$/|string'
  },
  'properties.aws_iam_role_arn': {
    label: 'IAM Role ARN',
    providerRules: 'required|min:20|string'
  },
  'properties.aws_delete_after_read': {
    defaultValue: false,
    label: 'Delete after read'
  },
  'properties.api_root': {},
  'properties.flow_dest': {},
  'properties.task_status': {
    defaultValue: 'PENDING'
  },
  'properties.error_message': {},

  'properties.apply_bgp': {
    defaultValue: false
  },
  'properties.device_bgp_type': {
    defaultValue: 'other_device'
  },

  'properties.use_bgp_device_id': {
    label: 'Master BGP device',
    placeholder: 'Select a Peered Device...',
    defaultValue: null
  },
  'properties.azure_subscription_id': {
    label: 'Subscription ID',
    helpText:
      'Authorize the Azure portal to create a Service Principal representing NSG Flow Exporter. Your Azure role (e.g. Global Administrator) must allow you to grant access by enterprise applications.',
    providerRules:
      'required|max:36|regex:^({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}$'
  },
  'properties.azure_security_principal_enabled': {
    defaultValue: false
  },
  'properties.azure_resource_group': {
    label: 'Resource Group to Monitor',
    providerRules: 'required'
  },
  'properties.azure_storage_account': {
    label: 'Storage Account Name',
    helpText:
      'Flow logs will be exported to this Kentik Cloud from an Azure Storage Account with the above name. The name must be a globally unique.',
    providerRules: 'required|min:3|max:24|string|alpha_num|azureUniqueStorageAccount'
  },
  'properties.azure_location': {
    label: 'Location',
    providerRules: 'required',
    options: [
      { value: 'australiacentral', label: 'Australia Central' },
      { value: 'australiacentral2', label: 'Australia Central 2' },
      { value: 'australiaeast', label: 'Australia East' },
      { value: 'australiasoutheast', label: 'Australia Southeast' },
      { value: 'brazilsouth', label: 'Brazil South' },
      { value: 'canadacentral', label: 'Canada Central' },
      { value: 'canadaeast', label: 'Canada East' },
      { value: 'centralindia', label: 'Central India' },
      { value: 'centralus', label: 'Central US' },
      { value: 'eastasia', label: 'East Asia' },
      { value: 'eastus', label: 'East US' },
      { value: 'eastus2', label: 'East US 2' },
      { value: 'francecentral', label: 'France Central' },
      { value: 'francesouth', label: 'France South' },
      { value: 'japaneast', label: 'Japan East' },
      { value: 'japanwest', label: 'Japan West' },
      { value: 'koreacentral', label: 'Korea Central' },
      { value: 'koreasouth', label: 'Korea South' },
      { value: 'northcentralus', label: 'North Central US' },
      { value: 'northeurope', label: 'North Europe' },
      { value: 'southafricanorth', label: 'South Africa North' },
      { value: 'southafricawest', label: 'South Africa West' },
      { value: 'southcentralus', label: 'South Central US' },
      { value: 'southeastasia', label: 'Southeast Asia' },
      { value: 'southindia', label: 'South India' },
      { value: 'uksouth', label: 'UK South' },
      { value: 'ukwest', label: 'UK West' },
      { value: 'westcentralus', label: 'West Central US' },
      { value: 'westeurope', label: 'West Europe' },
      { value: 'westindia', label: 'West India' },
      { value: 'westus', label: 'West US' },
      { value: 'westus2', label: 'West US 2' }
    ]
  },
  'properties.azure_access_check': {}
};

const options = {
  name: 'Cloud Device'
};

export { PROVIDERS, fields, options };
