import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Tag } from '@blueprintjs/core';

import { Box } from 'components/flexbox';

import SidebarGroup from './SidebarGroup';
import ReportMenuItem from './ReportMenuItem';

@inject('$recentlyViewed')
@observer
class RecentlyViewedList extends Component {
  render() {
    const { $recentlyViewed, showClearButton = true } = this.props;
    const size = $recentlyViewed.reports.length;

    if (size === 0) {
      return null;
    }

    return (
      <SidebarGroup
        {...this.props}
        title={<strong>Recently Viewed</strong>}
        iconName="time"
        badge={
          <Tag className="pt-minimal pt-round pt-strong" style={{ width: 25 }}>
            {size}
          </Tag>
        }
      >
        <Fragment>
          {$recentlyViewed.reports.map(report => (
            <ReportMenuItem {...this.props} key={report.id} report={report} />
          ))}

          {showClearButton && (
            <Box py={0.5}>
              <Button
                className="pt-small pt-minimal pt-text-muted pt-strong"
                onClick={$recentlyViewed.clear}
                text="Clear Recently Viewed"
              />
            </Box>
          )}
        </Fragment>
      </SidebarGroup>
    );
  }
}

export default RecentlyViewedList;
