import { action, computed } from 'mobx';
import { isString, pick, flatten } from 'lodash';
import moment from 'moment';

import BaseModel from 'models/BaseModel';
import { injectLegacySavedFilters } from 'services/filters';
import $dataviews from 'stores/$dataviews';
import $dictionary from 'stores/$dictionary';
import $devices from 'stores/$devices';
import $auth from 'stores/$auth';
import { USER_TIMEZONE, DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT, getQueryTimeInterval } from 'util/dateUtils';
import { deepClone, PREFIXABLE_UNITS } from 'util/utils';
import { getAppProtocol } from 'util/dimensions';

// Pass me an object, not a model instance, or else
export function queryHasDevices(query) {
  return (
    query.all_devices ||
    (query.device_name && query.device_name.length > 0) ||
    (query.device_labels && query.device_labels.length > 0) ||
    (query.device_sites && query.device_sites.length > 0) ||
    (query.device_types && query.device_types.length > 0)
  );
}

function getAggregate(aggregateType, metrics) {
  let aggregate = null;

  Object.keys(metrics).some(group => {
    const aggregatesOrGroup = metrics[group];
    let matchingAggregate;

    if (Array.isArray(aggregatesOrGroup)) {
      matchingAggregate = aggregatesOrGroup.find(agg => agg.value === aggregateType);
    } else {
      matchingAggregate = getAggregate(aggregateType, aggregatesOrGroup);
    }

    if (matchingAggregate) {
      // Return a copy so we don't modify the dictionary
      const fullGroupName = matchingAggregate.group ? `${group} ${matchingAggregate.group}` : group;
      const origLabel = matchingAggregate.origLabel || matchingAggregate.label;

      aggregate = {
        ...matchingAggregate,
        group: fullGroupName,
        origLabel,
        label: `${fullGroupName} ${origLabel}`
      };
    }

    return !!aggregate;
  });

  return aggregate;
}

export function getAggregates(aggregateTypes) {
  const { aggregates } = $dictionary.dictionary;

  return aggregateTypes.map(aggregateType => getAggregate(aggregateType, aggregates));
}

function findAggregate(aggregates, value) {
  return aggregates.find(aggregate => aggregate.value === value);
}

/*
 * This is going to build a full list of required aggregates for the provided top-level ones by getting aggregate(s)
 * for each composite provided and merging them all together. Will not allow dupes as long as top-level ones are not
 * ever used in composites.
 */
export function getAllAggregates(aggregates) {
  const { aggregateComposites } = $dictionary.dictionary;

  return aggregates.reduce((all, aggregate) => {
    const { fn, leftOperand, rightOperand } = aggregate;

    if (fn === 'composite') {
      const secondaryAggregates = [];
      const leftAggregate = findAggregate(aggregateComposites, leftOperand);
      if (leftAggregate) {
        secondaryAggregates.push(leftAggregate);
      }

      const rightAggregate = findAggregate(aggregateComposites, rightOperand);
      if (rightAggregate) {
        secondaryAggregates.push(rightAggregate);
      }

      const allSecondaryAggregates = getAllAggregates(secondaryAggregates);

      return all.concat(allSecondaryAggregates.filter(agg => !all.includes(agg)), aggregate);
    }

    return all.concat(aggregate);
  }, []);
}

export const templateTargetFieldOptions = [
  { value: 'dimensions', label: 'Dimensions', iconCls: 'device_hub' },
  { value: 'filter', label: 'Filters', iconCls: 'filter_list' },
  { value: 'device_name', label: 'Devices', iconCls: 'storage' },
  { value: 'metrics', label: 'Metrics', iconCls: 'merge_type' }
];
/**
    QueryModel
     - deserialized from server JSON via `QueryModel.create()`
     - serialize to get JSON to send to server via `this.serialize()`
     - adapter to construct QueryFormModel from QueryModel via `QueryFormModel.create()`
     - QueryFormModel will serialize to same as QueryModel

    QueryFormModel
     - only fields that sidebar needs
     - @computed fields to exactly map to QueryModel
     - serialize to get to QueryModel - (TODO: excluding all fields that don't belong in QueryModel)
 */
export default class QueryModel extends BaseModel {
  constructor(attributes = {}) {
    if (attributes.filters_obj && !attributes.filters) {
      attributes.filters = attributes.filters_obj;
    }
    super(attributes);
  }

  get defaults() {
    const { activeUser } = $auth;
    return {
      all_devices: false,
      aggregateTypes: ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'],
      aggregateThresholds: {},
      appProtocol: undefined,
      bracketOptions: null,
      bucket: 'Left +Y Axis',
      bucketIndex: 0,
      cidr: 32,
      cidr6: 128,
      customAsGroups: $dictionary.dictionary.asGroups.populated,
      cutFn: {},
      cutFnRegex: {},
      cutFnSelector: {},
      depth: 100,
      descriptor: '',
      device_name: 'all_devices',

      // an array of { id: 8 }, similar to saved filters.
      device_labels: [],
      device_sites: [],

      // valid options: ['router', 'host-nprobe-dns-www']
      device_types: [],

      fastData: 'Auto',
      filterDimensionsEnabled: false,
      filterDimensionName: 'Total',
      filterDimensionOther: false,
      filterDimensions: {},
      hostname_lookup: !!activeUser && activeUser.userDnsLookup !== 'OFF',
      isOverlay: false,
      lookback_seconds: 3600,
      from_to_lookback: 3600,
      generatorDimensions: [],
      generatorPanelMinHeight: 250,
      generatorMode: false,
      generatorColumns: 1,
      generatorQueryTitle: '{{generator_series_name}}',
      generatorTopx: 8,
      matrixBy: [],
      metric: ['AS_dst'],
      mirror: false,
      mirrorUnits: true,
      outsort: 'max_bits_per_sec',
      overlay_day: -7,
      overlay_timestamp_adjust: false,
      query_title: '',
      // populated when we're loading a SavedView into Explorer.
      saved_query_id: undefined,
      secondaryOutsort: '',
      secondaryTopxSeparate: false,
      secondaryTopxMirrored: false,
      show_overlay: !!activeUser && activeUser.userHistoryState !== 'OFF',
      show_total_overlay: true,
      starting_time: '',
      ending_time: '',
      sync_all_axes: false,
      sync_axes: false,
      sync_extents: true,
      show_site_markers: false,
      template_id: undefined,
      time_format: USER_TIMEZONE,
      topx: 8,
      update_frequency: 0,
      use_log_axis: false,
      use_secondary_log_axis: false,
      viz_type: 'stackedArea'
    };
  }

  @computed
  get units() {
    return this.aggregates.reduce(
      (units, { unit }) => (unit && !units.includes(unit) ? units.concat(unit) : units),
      []
    );
  }

  @computed
  get prefixableFieldUnits() {
    const aggregateTypes = this.get('aggregateTypes');
    // Uses 'parentUnit' (when provided) to bucket e.g. in_bytes with bytes for the purposes of prefixing
    return this.aggregates
      .map(({ value, unit, parentUnit }) => ({ value, unit: parentUnit || unit }))
      .filter(
        aggregate =>
          aggregate.unit && PREFIXABLE_UNITS.includes(aggregate.unit) && aggregateTypes.includes(aggregate.value)
      )
      .reduce((fieldUnits, aggregate) => {
        fieldUnits[aggregate.value] = aggregate.unit;
        return fieldUnits;
      }, {});
  }

  @computed
  get aggregates() {
    const aggregateTypes = this.get('aggregateTypes');
    const viz_type = this.get('viz_type');
    const bucket = this.get('bucket');
    const outsort = this.get('outsort') || '';

    const mappedAggregates = getAggregates(aggregateTypes);
    // Mixin composite aggregates
    const aggregates = getAllAggregates(mappedAggregates);

    const isLogsum = outsort && outsort.startsWith('sum_logsum');

    if (isLogsum) {
      aggregates.push({ value: outsort, fn: 'sum', column: 'kt_intell_order', unit: 'kt_intell_order' });
    }

    const vizTypeConfig = $dataviews.getConfig(viz_type);
    const bucketConfig = vizTypeConfig.buckets.find(b => b.name === bucket);
    if (bucketConfig) {
      const sample_rate = bucketConfig.sampleRateFactor || 1;

      aggregates.forEach(aggregate => {
        if (aggregate.value.includes('sample_rate')) {
          aggregate.sample_rate = sample_rate * 0.01;
        } else {
          aggregate.sample_rate = sample_rate;
        }
      });
    }

    let outsortAggregate;
    if (isLogsum) {
      const logsumAggregates = mappedAggregates.filter(
        aggregate => aggregate.unit === outsort.replace('sum_logsum_', '')
      );
      outsortAggregate = logsumAggregates.find(aggregate => aggregate.fn === 'average') || logsumAggregates[0];
    } else {
      outsortAggregate = mappedAggregates.find(aggregate => aggregate.value === outsort);
    }
    if (outsortAggregate) {
      outsortAggregate.raw = viz_type !== 'table';
    }

    const secondaryOutsort = this.get('secondaryOutsort');
    if (vizTypeConfig.allowsSecondaryOverlay && secondaryOutsort && this.get('secondaryTopxSeparate') === false) {
      const secondaryOutsortAggregate = aggregates.find(aggregate => aggregate.value === secondaryOutsort);
      if (secondaryOutsortAggregate) {
        secondaryOutsortAggregate.raw = viz_type !== 'table';
        if (bucketConfig) {
          const secondaryBucketIndex =
            this.get('secondaryTopxMirrored') === true
              ? bucketConfig.secondaryMirrorBucket
              : bucketConfig.secondaryOverlayBucket;
          secondaryOutsortAggregate.sample_rate = vizTypeConfig.buckets[secondaryBucketIndex].sampleRateFactor;
        }
      }
    }

    return aggregates;
  }

  @computed
  get outsortAggregate() {
    const outsort = this.get('outsort');
    const { aggregates } = this;
    if (outsort && outsort.startsWith('sum_logsum')) {
      const aggregateTypes = this.get('aggregateTypes');
      return aggregates.find(agg => aggregateTypes.includes(agg.value) && agg.unit === this.outsortUnit);
    }
    return aggregates.find(agg => agg.value === outsort);
  }

  @computed
  get outsortDataKey() {
    const outsort = this.get('outsort');
    if (outsort && outsort.startsWith('sum_logsum')) {
      return this.outsortAggregate.value;
    }
    return this.get('outsort');
  }

  @computed
  get outsortUnit() {
    return this.getOutsortUnit(this.get('outsort'));
  }

  @computed
  get secondaryOutsortUnit() {
    return this.getOutsortUnit(this.get('secondaryOutsort'));
  }

  @computed
  get prefixUnit() {
    return this.getPrefixUnit(this.get('outsort'));
  }

  @computed
  get secondaryPrefixUnit() {
    return this.getPrefixUnit(this.get('secondaryOutsort'));
  }

  getOutsortUnit(outsort) {
    if (outsort && outsort.startsWith('sum_logsum')) {
      return outsort.replace('sum_logsum_', '');
    }
    const outsortAggregate = this.aggregates.find(aggregate => aggregate.value === outsort);
    return outsortAggregate && outsortAggregate.unit;
  }

  getPrefixUnit(outsort) {
    const outsortAggregate = this.aggregates.find(aggregate => aggregate.value === outsort);
    return outsortAggregate && (outsortAggregate.parentUnit || outsortAggregate.unit);
  }

  @computed
  get autoTitle() {
    const {
      query_title,
      outsort,
      secondaryOutsort,
      metric,
      matrixBy,
      mirror,
      generatorMode,
      generatorDimensions,
      topx,
      filterDimensionsEnabled,
      filterDimensionName
    } = this.get();

    if (query_title) {
      return query_title;
    }

    const { aggregateLabels, units, chartTypesValidations, chartTypeInverses } = $dictionary.dictionary;

    let sortName = '';

    this.aggregates.some(({ value, label }) => {
      if (value === outsort) {
        sortName = `${aggregateLabels[value.startsWith('sum_logsum') ? 'sum_logsum' : value] || label} ${
          units[this.outsortUnit]
        }`;
        return true;
      }
      return false;
    });

    if (secondaryOutsort) {
      this.aggregates.some(({ value, label }) => {
        if (value === secondaryOutsort) {
          sortName = `${sortName}, ${aggregateLabels[value] || label} ${units[this.secondaryOutsortUnit]}`;
          return true;
        }
        return false;
      });
    }

    let metrics = '';
    let generatorMatrix = '';
    if (filterDimensionsEnabled && filterDimensionName !== '') {
      metrics = filterDimensionName;
    } else if (generatorMode && generatorDimensions && generatorDimensions.length) {
      metrics = generatorDimensions.map(m => chartTypesValidations[m]).join(', ');
      generatorMatrix = ` for Top ${topx} ${metric.map(m => chartTypesValidations[m]).join(', ')}`;
    } else {
      metrics = chartTypesValidations[metric] || metric.map(m => chartTypesValidations[m]).join(', ');
    }

    let matrix = '';
    if (matrixBy && matrixBy.length) {
      matrix = ` vs Top ${chartTypesValidations[matrixBy] || matrixBy.map(m => chartTypesValidations[m]).join(', ')}`;
    }

    if (mirror) {
      if (generatorMode && generatorDimensions && generatorDimensions.length) {
        matrix = ` vs Top ${generatorDimensions.map(m => chartTypesValidations[chartTypeInverses[m] || m]).join(', ')}`;
      } else {
        matrix = ` vs Top ${metric.map(m => chartTypesValidations[chartTypeInverses[m] || m]).join(', ')}`;
      }
    }

    return `${
      filterDimensionsEnabled || metric.indexOf('Traffic') !== -1 ? '' : 'Top '
    }${metrics}${matrix}${generatorMatrix} by ${sortName}`;
  }

  /**
   * Returns an array of `filterField` that are currently in use for this Query. This is used to determine if the
   * query contains any overridable `filterField`s to be used for Parametric Dashboards
   */
  @computed
  get activeFilterFields() {
    const { filters } = this.get();

    if (!filters || !filters.filterGroups) {
      return [];
    }

    return flatten(filters.filterGroups.map(group => group.filters.map(filter => filter.filterField)));
  }

  @computed
  get host_selected() {
    const { all_devices, device_name } = this.get();
    const { deviceSummaries: devices } = $devices;
    if (all_devices) {
      return !!devices.find(device => device.device_type.startsWith('host'));
    }
    return !!devices.find(device => device.device_type.startsWith('host') && device_name.includes(device.device_name));
  }

  get dateRangeDisplay() {
    const { lookback_seconds, starting_time, ending_time, time_format } = this.get();
    let start;
    let end;

    const MOMENT_FN = time_format === 'UTC' ? moment.utc : moment;

    if (lookback_seconds) {
      start = MOMENT_FN().subtract(lookback_seconds, 'second');
      end = MOMENT_FN();
    } else {
      start = MOMENT_FN(starting_time);
      end = MOMENT_FN(ending_time);
    }

    if (typeof time_format === 'number') {
      start = start.utcOffset(time_format);
      end = end.utcOffset(time_format);
    }

    const startOut = start.format(DEFAULT_DATE_FORMAT);
    const endOut = end.format(DEFAULT_DATE_FORMAT);

    return `${startOut}${startOut !== endOut ? ` to ${endOut}` : ''} UTC${
      time_format !== 'UTC'
        ? MOMENT_FN()
            .utcOffset(time_format)
            .format('ZZ')
        : ''
    }`;
  }

  // NOTE: these invert functions expect a serialized query!
  invertDimensions(query) {
    query.metric = query.metric.map(metric => $dictionary.dictionary.chartTypeInverses[metric] || metric);
  }

  invertAggregateTypes(query) {
    query.aggregateTypes = query.aggregateTypes.map(
      aggregateType => $dictionary.dictionary.unitInverses[aggregateType] || aggregateType
    );
  }

  invertAggregateThresholds(query) {
    query.aggregateThresholds = Object.keys(query.aggregateThresholds).reduce((thresholds, threshold) => {
      const newKey = $dictionary.dictionary.unitInverses[threshold] || threshold;
      thresholds[newKey] = query.aggregateThresholds[threshold];
      return thresholds;
    }, {});
  }

  invertOutsorts(query) {
    const { unitInverses } = $dictionary.dictionary;
    query.outsort = unitInverses[query.outsort] || query.outsort;
    query.secondaryOutsort = unitInverses[query.secondaryOutsort] || query.secondaryOutsort;
  }

  invertFilters(query) {
    const { filters_obj } = query;
    if (filters_obj && filters_obj.filterGroups) {
      filters_obj.filterGroups.forEach(group => this.invertFilterGroup(group));
    }
  }

  invertFilterGroup(group) {
    if (group && group.filters) {
      group.filters.forEach(filter => {
        filter.filterField =
          $dictionary.dictionary.queryFilters.filterTypeInverses[filter.filterField] || filter.filterField;
        if (filter.filterField === 'i_host_direction') {
          if (filter.filterValue === 'in') {
            filter.filterValue = 'out';
          } else if (filter.filterValue === 'out') {
            filter.filterValue = 'in';
          }
        }
      });
    }
    if (group && group.filterGroups) {
      group.filterGroups.forEach(innerGroup => this.invertFilterGroup(innerGroup));
    }
  }

  invertFilterDimensions(query) {
    if (query && query.filterDimensionsEnabled) {
      const { filterDimensions } = query;
      if (filterDimensions && filterDimensions.filterGroups) {
        filterDimensions.filterGroups.forEach(group => this.invertFilterGroup(group));
      }
    }
  }

  invert() {
    const query = this.serialize();
    if (query.mirror) {
      this.invertDimensions(query);
      this.invertFilters(query);
      this.invertFilterDimensions(query);

      if (query.mirrorUnits) {
        this.invertAggregateTypes(query);
        this.invertAggregateThresholds(query);
        this.invertOutsorts(query);
      }
    }
    return query;
  }

  serialize() {
    const query = deepClone(this.attributes.toJS());

    if (!query.metric.length) {
      query.metric = ['Traffic'];
    }
    query.filters_obj = query.filters_obj || query.filters;
    query.aggregates = this.aggregates.map(aggregate => ({ ...aggregate, name: aggregate.value }));
    query.units = this.units;

    const validModelKeys = Object.keys(this.defaults);
    const validKeys = [...validModelKeys, 'aggregates', 'filters_obj', 'units', 'pivot', 'template_id'];
    // only return the keys which are valid for a QueryModel;
    return pick(query, validKeys);
  }

  @action
  static create(query = {}) {
    // console.log(query);
    // bw compat for all_selected vs all_devices
    if (query.all_selected !== undefined && query.all_selected !== null) {
      query.all_devices = query.all_selected;
    }

    // Assign a device_name if none is provided
    if (!queryHasDevices(query) && $devices.deviceSummaries.length > 0) {
      query.device_name = [$devices.deviceSummaries[0].device_name];
    }

    // clear out device_name if all_devices
    if (query.all_devices) {
      query.device_name = [];
    }

    // bw compat for device names being a comma delimited str
    if (!Array.isArray(query.device_name)) {
      if (query.device_name) {
        query.device_name = query.device_name.split(',');
      } else {
        query.device_name = [];
      }
    }

    // drop devices not in deviceSummaries
    if (query.device_name) {
      query.device_name = query.device_name.filter(
        device => !!$devices.deviceSummaries.find(d => d.device_name === device)
      );
    }

    if (query.metric && !Array.isArray(query.metric)) {
      query.metric = query.metric.split(',');
    }

    // Handling of units/aggregates, need to map legacy units to new aggregateTypes for multi-metric
    // Throw out units and aggregates, which we ultimately compute and then units computed goes away
    // and aggregateTypes gets renamed to units everywhere
    if (!query.aggregateTypes && query.units && !Array.isArray(query.units)) {
      const { unitsLegacy, unitsToOutsortLegacy } = $dictionary.dictionary;
      if (query.outsort === 'agg_total') {
        query.outsort = `agg_total_${query.units}`;
        query.aggregateTypes = [query.outsort];
      } else {
        query.aggregateTypes = unitsLegacy[query.units];
      }

      if (query.secondaryUnits) {
        const secondaryAggregateTypes = unitsLegacy[query.secondaryUnits];
        if (secondaryAggregateTypes) {
          secondaryAggregateTypes.forEach(aggregateType => {
            if (!query.aggregateTypes.includes(aggregateType)) {
              query.aggregateTypes.push(aggregateType);
            }
          });
          query.secondaryOutsort = unitsToOutsortLegacy[query.secondaryUnits];
        }
      }
    }
    query.units = undefined;
    query.aggregates = undefined;

    // Fix time
    if (query.lookback_seconds > 0) {
      query.starting_time = null;
      query.ending_time = null;
    }
    // remap legacy filters_obj to new filters member
    if (!query.filters && query.filters_obj) {
      query.filters = query.filters_obj;
      delete query.filters_obj;
    }

    // cleanup old filters_obj structs we don't use anymore to match our form configs
    if (query.filters) {
      delete query.filters.custom;
      delete query.filters.filterString;
      if (query.filters.filterGroups && query.filters.filterGroups.length) {
        query.filters.filterGroups.forEach(group => {
          delete group.id;
          delete group.filterString;
          group.named = group.named || false;
          group.name = group.name || '';
          if (group.filters && group.filters.length) {
            group.filters.forEach(filter => delete filter.id);
          }
        });
      }
    }

    if (query.saved_filters && query.saved_filters.length > 0) {
      if (!query.filters.filterGroups) {
        query.filters.filterGroups = [];
      }
      query.filters = injectLegacySavedFilters(query.saved_filters, query.filters);
      delete query.saved_filters;
    }

    // If fastData settings come in, such that we don't allow them, fix them to avoid invalid option/dirty state in Explorer
    const interval = getQueryTimeInterval(query);
    if (interval < 3 * 3600 || interval > 3 * 24 * 3600) {
      query.fastData = 'Auto';
    }

    const queryModel = new QueryModel(query);

    const buckets = $dataviews.getConfig(queryModel.get('viz_type')).buckets;
    const defaultBucket = buckets[0].name;
    const queryBucket = queryModel.get('bucket');

    if (!queryBucket || !buckets.find(bucket => bucket.name === queryBucket)) {
      queryModel.set({ bucket: defaultBucket });
    }

    // assign sort if not included or bad
    if (!query.outsort || !queryModel.aggregates.find(aggregate => aggregate.value === query.outsort)) {
      queryModel.set({ outsort: queryModel.get('aggregateTypes')[0] });
    }

    if (isString(queryModel.get('topx'))) {
      queryModel.set({ topx: parseInt(queryModel.get('topx'), 10) });
    }

    return queryModel;
  }
}

const getTotalTrafficOverlay = query => {
  const totalOverlayQuery = QueryModel.create(query.serialize());

  totalOverlayQuery.set({
    isOverlay: true,
    appProtocol: getAppProtocol(query.get('metric'), query.get('filters') || query.get('filters_obj')),
    metric: ['Traffic'],
    descriptor: 'Total'
  });

  return totalOverlayQuery;
};

const getHistoricalOverlay = query => {
  const overlayQuery = QueryModel.create(query.serialize());

  let { lookback_seconds, starting_time, ending_time } = overlayQuery.get();
  const overlay_day = overlayQuery.get('overlay_day');

  if (lookback_seconds) {
    starting_time = moment
      .utc()
      .subtract(lookback_seconds, 'second')
      .add(overlay_day * 24, 'hour')
      .format(DEFAULT_DATETIME_FORMAT);
    ending_time = moment
      .utc()
      .add(overlay_day * 24, 'hour')
      .format(DEFAULT_DATETIME_FORMAT);
    lookback_seconds = 0;
  } else {
    starting_time = moment
      .utc(starting_time)
      .add(overlay_day * 24, 'hour')
      .format(DEFAULT_DATETIME_FORMAT);
    ending_time = moment
      .utc(ending_time)
      .add(overlay_day * 24, 'hour')
      .format(DEFAULT_DATETIME_FORMAT);
  }

  overlayQuery.set({
    isOverlay: true,
    metric: ['Traffic'],
    appProtocol: getAppProtocol(query.get('metric'), query.get('filters') || query.get('filters_obj')),
    descriptor: `Historical Total: ${overlay_day * -1} days back`,
    overlay_timestamp_adjust: true,
    lookback_seconds,
    starting_time,
    ending_time
  });

  return overlayQuery;
};

export { getTotalTrafficOverlay, getHistoricalOverlay };
