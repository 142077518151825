import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';

import { MultistepTabs } from 'components';
import { Flex } from 'components/flexbox';
import { Button, Intent } from '@blueprintjs/core';

import GeneralSettings from './Form/GeneralSettings';
import PlanSite from './Form/PlanSite';
import IpSnmpSettings from './Form/IpSnmpSettings';
import BGPSettings from './Form/BGPSettings';

@inject('$dictionary', '$devices', '$sites', '$plans', '$alertingMitigation')
@observer
class DeviceDetailsForm extends Component {
  static contextTypes = {
    form: any
  };

  componentDidMount() {
    const { $sites, $plans, $devices, $alertingMitigation } = this.props;

    if (!$sites.collection.hasFetched) {
      $sites.collection.fetch();
    }

    $plans.collection.fetch();
    $alertingMitigation.platforms.fetch();
    $devices.fetchMasterBGPDevices();

    this.getBgpIngestIps();
  }

  getBgpIngestIps = (field, value) => {
    const { $devices, model } = this.props;
    const isBgpIngestSet = !!model.get('bgpPeerIP4');
    const bgpType = value || model.get('device_bgp_type');
    const deviceId = model.get('id');

    if (!isBgpIngestSet && bgpType === 'device') {
      $devices.fetchBgpIngestIps(deviceId).then(result => {
        if (result) {
          model.set('bgpPeerIP4', result.bgpPeerIP4);
          model.set('bgpPeerIP6', result.bgpPeerIP6);
        }
      });
    }
  };

  render() {
    const { form } = this.context;
    const { model, $dictionary, $devices, onSubmit } = this.props;
    const helpText = $dictionary.getTooltips('admin.device.deviceDetails');
    const deviceType = $devices.getDeviceTypeFromSubtype(form.getValue('device_subtype'));

    if (!model.isNew && model.isArchived) {
      return (
        <Flex flexColumn align="center" p={3}>
          <span style={{ fontSize: 16, marginBottom: 8 }}>
            {model.get('device_name')} has been <strong>archived</strong>.
          </span>
          <Button intent={Intent.PRIMARY} onClick={() => onSubmit(form, { device_status: 'V' })}>
            Unarchive now
          </Button>
        </Flex>
      );
    }

    const tabs = [
      {
        id: 'general',
        group: 'general',
        title: 'General',
        iconName: 'label',
        panel: <GeneralSettings model={model} helpText={helpText} />
      },
      {
        id: 'planSite',
        group: 'planSite',
        title: 'Plan & Site',
        iconName: 'map-marker',
        panel: <PlanSite helpText={helpText} />
      },
      {
        id: 'ipSnmp',
        group: 'ipSnmp',
        title: 'IP & SNMP',
        iconName: 'ip-address',
        panel: <IpSnmpSettings helpText={helpText} />
      },
      {
        id: 'bgp',
        group: 'bgp',
        title: 'BGP',
        iconName: 'link',
        panel: <BGPSettings model={model} helpText={helpText} onGetBgpIngestIps={this.getBgpIngestIps} />
      }
    ];

    if (deviceType !== 'router') {
      tabs.splice(2, 1); // Remove IP & SNMP tab
    }

    return (
      <MultistepTabs
        showNavigation={false}
        tabs={tabs}
        scrollAreaStyle={{ maxHeight: 'calc(100vh - 270px)', overflow: 'auto' }}
      />
    );
  }
}

export default DeviceDetailsForm;
