import React from 'react';
import { Button } from '@blueprintjs/core';
import { Flex } from 'components/flexbox';
import { observer } from 'mobx-react';

const SidebarTabs = ({ onChange, activeTab, report }) => (
  <Flex className="library-sidebar-tabs">
    <Button
      className="pt-minimal"
      active={activeTab === 'library'}
      value="library"
      onClick={() => onChange('library')}
      text="Library"
    />
    {report &&
      report.type !== 'Saved View' && (
        <Button
          className="pt-minimal"
          active={activeTab === 'controls'}
          value="controls"
          onClick={() => onChange('controls')}
          text="Options"
        />
      )}
  </Flex>
);

export default observer(SidebarTabs);
