import React, { Component } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import { inject } from 'mobx-react';
import getErrorBoundary from 'util/components/getErrorBoundary';

const ErrorBoundaryCmp = getErrorBoundary('Page');

@inject('$auth', '$app')
class Page extends Component {
  state = {
    scrolled: false
  };

  componentWillMount() {
    const { title } = this.props;

    document.title = this.getDocumentTitle(title);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.title !== this.props.title) {
      document.title = this.getDocumentTitle(nextProps.title);
    }
  }

  getDocumentTitle = title => {
    const { $auth } = this.props;
    const appName = get($auth.openConfig, 'subtenancy.config.branding.appName') || $auth.companyFullName;
    return title ? `${title} · ${appName}` : appName;
  };

  handleScroll = e => {
    const scrolled = e.target.scrollTop > 15;

    if (this.state.scrolled !== scrolled) {
      this.setState({ scrolled });
    }
  };

  render() {
    const { $app, children, constrainWidth, className, centered = false, style } = this.props;
    const pageClassNames = classNames('page', className, {
      'no-notice': !$app.showV4Notice,
      centered,
      'constrain-width-2000': constrainWidth === 2000,
      'constrain-width-1500': constrainWidth === 1500,
      'constrain-width-1200': constrainWidth === true || constrainWidth === 1200,
      'constrain-width-1000': constrainWidth === 1000,
      'constrain-width-800': constrainWidth === 800,
      'constrain-width-600': constrainWidth === 600
    });

    return (
      <ErrorBoundaryCmp fullScreen>
        <div className={pageClassNames} style={style}>
          {React.Children.map(children, child => {
            if (!React.isValidElement(child)) {
              return child;
            }

            return React.cloneElement(child, {
              onScroll: this.handleScroll,
              scrolled: this.state.scrolled
            });
          })}
        </div>
      </ErrorBoundaryCmp>
    );
  }
}

export default Page;
