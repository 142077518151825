import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import AlertFilterField from '../AlertFilterField';

const MitAlarmIdCell = props => {
  const { model, onFilterFieldClick } = props;
  const mitigation_id = model.get('mitigation_id');
  const alarm_id = model.get('alarm_id');
  const row_type = model.get('row_type');
  const platform_name = model.get('platform_name');
  const method_name = model.get('method_name');

  let mitigation_id_el;
  let alarm_id_el;

  if (alarm_id) {
    alarm_id_el = (
      <AlertFilterField
        model={model}
        filterBy="alarm_id"
        filterVal={alarm_id}
        displayName={alarm_id}
        onFilterFieldClick={onFilterFieldClick}
      />
    );
  }

  if (mitigation_id) {
    mitigation_id_el = (
      <AlertFilterField
        model={model}
        filterBy="mitigation_id"
        filterVal={mitigation_id}
        displayName={mitigation_id}
        onFilterFieldClick={onFilterFieldClick}
      />
    );
  }

  return (
    <Fragment>
      {alarm_id_el && <div className="pt-text-overflow-ellipsis">Alarm: {alarm_id_el}</div>}
      {mitigation_id_el && (
        <Fragment>
          <div className={classNames('pt-text-overflow-ellipsis', { 'pt-text-muted': row_type !== 'Mitigation' })}>
            Mitigation: {mitigation_id_el}
          </div>
          {platform_name && method_name && <div className="pt-text-overflow-ellipsis">{platform_name}</div>}
          {platform_name && method_name && <div className="pt-text-overflow-ellipsis">{method_name}</div>}
        </Fragment>
      )}
    </Fragment>
  );
};

export default observer(MitAlarmIdCell);
