import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import qrcode from 'qrcode';
import { Button, Dialog, InputGroup, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import Card from 'components/Card';

@inject('$users')
@observer
class TotpDialog extends Component {
  state = {
    invalid: false,
    token: '',
    name: ''
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      nextProps.$users.generateTotpSecret();
    }
  }

  componentDidUpdate() {
    const { otpauth_url } = this.props.$users;
    if (this.qrcode) {
      qrcode.toCanvas(this.qrcode, otpauth_url, () => {});
    }
  }

  qrcodeRef = refs => {
    if (refs) {
      this.qrcode = refs;
    }
  };

  registerTotp = () => {
    const { onClose, $users } = this.props;
    const { token, name } = this.state;
    $users.registerTotp(token, false, name).then(onClose, () => {
      this.setState({ invalid: true, token: '' });
    });
  };

  handleTokenChange = e => {
    this.setState({ invalid: false, token: e.target.value });
  };

  handleNameChange = e => {
    const { value } = e.target;

    this.setState(() => ({ name: value }));
  };

  handleSecretChange = action(e => {
    this.props.$users.totpSecret = e.target.value;
  });

  render() {
    const { isOpen, onClose, $users } = this.props;
    const { invalid, token, name } = this.state;

    return (
      <Dialog isOpen={isOpen} title="Register TOTP" onClose={onClose} style={{ width: 700 }} transitionName="pt-dialog">
        <div className="pt-dialog-body" style={{ paddingBottom: 8 }}>
          <Flex style={{ width: '100%' }}>
            <Card
              mr={2}
              flexAuto
              style={{ textAlign: 'center', minHeight: 300, minWidth: 350 }}
              footer={
                <Button
                  className="pt-minimal"
                  intent={Intent.PRIMARY}
                  loading={$users.generatingTotp}
                  onClick={$users.generateTotpSecret}
                  text="Generate New Secret"
                />
              }
            >
              <canvas ref={this.qrcodeRef} />
              <InputGroup value={$users.totpSecret} onChange={this.handleSecretChange} />
            </Card>
            <Box flexAuto>
              <Box className="" mb={2}>
                <h6>Token Name</h6>
                <InputGroup placeholder="Enter token name" value={name} onChange={this.handleNameChange} />
              </Box>
              <div className="pt-intent-primary">
                <Box mb={2}>
                  <h6>Token Validation</h6>
                  <div>
                    You MUST validate with a token generated from the displayed TOTP secret in order to validate the
                    secret for future use. If you do not validate, no registration will take place.
                  </div>
                </Box>
                <InputGroup
                  value={token}
                  placeholder="Enter token"
                  onChange={this.handleTokenChange}
                  intent={invalid ? Intent.DANGER : Intent.NONE}
                />
              </div>
            </Box>
          </Flex>
        </div>

        <Flex className="pt-dialog-footer" justify="flex-end">
          <Button onClick={onClose} style={{ marginRight: 12 }} text="Cancel" />
          <Button
            disabled={$users.registeringTotp || !token}
            loading={$users.registeringTotp}
            onClick={this.registerTotp}
            intent={Intent.PRIMARY}
            text="Finish Registration"
          />
        </Flex>
      </Dialog>
    );
  }
}

export default TotpDialog;
