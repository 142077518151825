import React from 'react';
import { Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { MaterialIcon } from 'components/Icon';

const PopoverLabel = ({ label = '', popoverText, content }) => (
  <span>
    {label}{' '}
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName="pt-popover-content-sizing"
      content={content}
    >
      <a>
        {popoverText}
        <MaterialIcon name="search" style={{ marginLeft: 4, verticalAlign: 'middle', fontSize: 14 }} />
      </a>
    </Popover>
  </span>
);

export default PopoverLabel;
