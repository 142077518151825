import { action } from 'mobx';

import DeviceCollection from 'models/devices/DeviceCollection';

class InterfacesDeviceCollection extends DeviceCollection {
  getFilterValues(model) {
    return ['device_name'].map(name => model[name] || model.get(name));
  }

  get secondarySort() {
    return {
      field: 'interfaceCount',
      direction: 'desc'
    };
  }

  get defaultSortState() {
    return {
      field: 'percentMatched',
      direction: 'desc'
    };
  }

  @action
  updateDeviceInterfaceMatchData(deviceMatches) {
    Object.keys(deviceMatches).forEach(key => {
      const matchInfo = deviceMatches[key];
      if (matchInfo) {
        const model = this.get(key);
        if (model) {
          model.set({ loadingInterfaceResults: false, ...matchInfo });
        }
      }
    });
  }
}

export default InterfacesDeviceCollection;
