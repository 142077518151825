import React from 'react';
import { Popover, PopoverInteractionKind } from '@blueprintjs/core';

import { privateIpOptions } from 'util/constants';

import { Flex, Box } from './flexbox';

const tetherOptions = {
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

const PrivateSubnetPopover = props => {
  const { children, interactionKind = PopoverInteractionKind.CLICK, title } = props;

  return (
    <Popover
      interactionKind={interactionKind}
      tetherOptions={tetherOptions}
      content={
        <Box p={2}>
          <h6>{title}</h6>
          {privateIpOptions.map(option => (
            <Flex key={option.value}>
              <span style={{ width: 300 }}>{option.label}</span>
              <span>{option.description}</span>
            </Flex>
          ))}
        </Box>
      }
    >
      {children}
    </Popover>
  );
};

export default PrivateSubnetPopover;
