import { action, computed } from 'mobx';

import BaseModel from 'models/BaseModel';
import QueryModel from 'models/query/QueryModel';
import $devices from 'stores/$devices';
import $dictionary from 'stores/$dictionary';
import { USER_TIMEZONE } from 'util/dateUtils';

const LIMIT_PERCENTAGE = 20;
const LIMIT_MBPS = 50;

function getErrorPercentageAndPercentageOver(flowMbps, snmpMbps) {
  let percentage;
  let isAboveThresholds = false;

  if (flowMbps !== undefined && snmpMbps !== undefined && flowMbps > 0) {
    percentage = Math.round(Math.abs(((flowMbps - snmpMbps) / flowMbps) * 100));
  }

  const condition1 = flowMbps >= 1 && snmpMbps >= 1;
  const condition2 = flowMbps >= LIMIT_MBPS || snmpMbps >= LIMIT_MBPS;
  const condition3 = percentage !== undefined && percentage >= LIMIT_PERCENTAGE;

  if (condition1 && condition2 && condition3) {
    isAboveThresholds = true;
  }

  return { percentage, isAboveThresholds };
}

class Interface extends BaseModel {
  get defaults() {
    return {
      snmpFlow: {
        outbound: {
          value: 0
        },
        inbound: {
          value: 0
        }
      }
    };
  }

  get sortValues() {
    return {
      'flowInbound.flowMbps': model => Math.max(model.flowInbound.flowMbps || 0, model.flowInbound.snmpMbps || 0),
      'flowOutbound.flowMbps': model => Math.max(model.flowOutbound.flowMbps || 0, model.flowOutbound.snmpMbps || 0),
      snmp_speed: model => parseInt(model.get('snmp_speed')),
      snmp_id: model => parseInt(model.get('snmp_id')),
      ruleStatus: model => model.isClassifiedByActiveRule
    };
  }

  serialize(data) {
    const { interface_description, snmp_alias, snmp_speed, snmp_id, interface_ip, interface_ip_netmask } = data;
    const attributes = {
      interface_description,
      snmp_speed,
      snmp_id,
      snmp_alias,
      interface_ip,
      interface_ip_netmask
    };

    return super.serialize(attributes);
  }

  save(attributes = {}) {
    return super.save(attributes).then(() => this.collection.calculateInterfaceCounts());
  }

  @action
  getQueryModel = async () => {
    const filterValue = this.get('snmp_id');
    const filters_obj = {
      connector: 'Any',
      filterGroups: [
        {
          connector: 'Any',
          filters: [{ filterField: 'input_port', operator: '=', filterValue }]
        }
      ]
    };

    let device_name = this.get('device_name');
    if (!device_name) {
      device_name = await $devices.getDeviceNames(this.get('device_id'));
    }

    const queryModel = QueryModel.create({
      device_name,
      filters_obj,
      lookback_seconds: 86400,
      metric: ['Traffic'],
      aggregateTypes: ['max_bits_per_sec'],
      query_title: 'Last 24h by Max Bits per second',
      mirror: true,
      time_format: USER_TIMEZONE,
      viz_type: 'line'
    });

    return queryModel.serialize();
  };

  @action
  resetField = name => {
    this.save({ [name]: this.get(`initial_${name}`) });
  };

  /**
   * Merges the RuleCollection information with our Interfaces `rules_matched` information.
   * Allows us to display richer Rule match information in the table.
   */
  get matchingRule() {
    if (this.ruleMatchInfo && this.collection && this.collection.ruleCollection) {
      const collection = this.collection.ruleCollection;
      return collection.get(this.ruleMatchInfo.id);
    }

    return null;
  }

  get ruleMatchInfo() {
    const rules_matched = this.get('rules_matched') || [];
    return rules_matched.find(matchInfo => matchInfo.didClassify);
  }

  @computed
  get isMatchedByActiveRule() {
    const { activeRule } = this.collection;
    const rules_matched = this.get('rules_matched') || [];

    return !!(activeRule && rules_matched.some(rule => rule.id === activeRule.id));
  }

  @computed
  get isClassifiedByActiveRule() {
    const { activeRule } = this.collection;
    return !!(this.ruleMatchInfo && activeRule && this.ruleMatchInfo.id === activeRule.id);
  }

  @computed
  get networkBoundary() {
    return $dictionary.dictionary.interfaceClassification.networkBoundaryTypes[this.get('network_boundary')] || 'None';
  }

  @computed
  get connectivityType() {
    return $dictionary.dictionary.interfaceClassification.connectivityTypes[this.get('connectivity_type')] || 'None';
  }

  @computed
  get topNexthopAsns() {
    return (this.get('top_nexthop_asns') || []).map(asn => asn.Asn || '').join(', ');
  }

  @computed
  get secondaryIps() {
    return (this.get('secondary_ips') || []).map(ip => ip.address || '').join(', ');
  }

  @computed
  get isUnclassified() {
    return !this.get('network_boundary') && !this.get('connectivity_type');
  }

  @computed
  get hasOverriddenFields() {
    const initial_snmp_id = this.get('initial_snmp_id');
    const initial_snmp_alias = this.get('initial_snmp_alias');
    const initial_interface_description = this.get('initial_interface_description');
    const initial_snmp_speed = this.get('initial_snmp_speed');

    const snmp_id = this.get('snmp_id');
    const snmp_alias = this.get('snmp_alias');
    const snmp_speed = this.get('snmp_speed');
    const interface_description = this.get('interface_description');

    return (
      initial_snmp_id &&
      (snmp_id !== initial_snmp_id ||
        snmp_alias !== initial_snmp_alias ||
        interface_description !== initial_interface_description ||
        snmp_speed !== initial_snmp_speed)
    );
  }

  @computed
  get isManuallyAdded() {
    const initial_snmp_id = this.get('initial_snmp_id');
    return !initial_snmp_id;
  }

  @computed
  get flowStatus() {
    const inFlow = this.get('f_sum_both_bytes$in_mbps');
    const outFlow = this.get('f_sum_both_bytes$out_mbps');

    if (!inFlow && !outFlow) {
      return 'None';
    }

    return inFlow ? 'Ingress' : 'Egress';
  }

  @computed
  get inboundErrorPercentage() {
    const f_sum_both_bytes$in_mbps = this.get('f_sum_both_bytes$in_mbps');
    const snmpFlow = this.get('snmpFlow');

    return getErrorPercentageAndPercentageOver(f_sum_both_bytes$in_mbps, snmpFlow.inmbps);
  }

  @computed
  get outboundErrorPercentage() {
    const f_sum_both_bytes$out_mbps = this.get('f_sum_both_bytes$out_mbps');
    const snmpFlow = this.get('snmpFlow');

    return getErrorPercentageAndPercentageOver(f_sum_both_bytes$out_mbps, snmpFlow.outmbps);
  }

  @computed
  get flowInbound() {
    const f_sum_both_bytes$in_mbps = this.get('f_sum_both_bytes$in_mbps');
    const snmpFlow = this.get('snmpFlow');

    return {
      error: this.inboundErrorPercentage,
      flowMbps: f_sum_both_bytes$in_mbps || 0,
      snmpMbps: snmpFlow ? snmpFlow.inmbps : 0
    };
  }

  @computed
  get flowOutbound() {
    const f_sum_both_bytes$out_mbps = this.get('f_sum_both_bytes$out_mbps');
    const snmpFlow = this.get('snmpFlow');

    return {
      error: this.outboundErrorPercentage,
      flowMbps: f_sum_both_bytes$out_mbps || 0,
      snmpMbps: snmpFlow ? snmpFlow.outmbps : 0
    };
  }

  get messages() {
    return {
      create: `Interface ${this.get('snmp_alias')} was added successfully`,
      update: `Interface ${this.get('snmp_alias')} was updated successfully`,
      destroy: `Interface ${this.get('snmp_alias')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Interface',
      text: `Are you sure you want to remove Interface - ${this.get('snmp_alias')}?`
    };
  }
}

export default Interface;
