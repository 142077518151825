import Collection from 'models/Collection';
import QueryTemplate from './QueryTemplate';

class QueryTemplateCollection extends Collection {
  get model() {
    return QueryTemplate;
  }

  get url() {
    return '/api/portal/queryTemplates';
  }

  get defaultGroupBy() {
    return 'company_id';
  }

  fetchAll() {
    return this.fetch({ fetchUrl: '/api/portal/queryTemplates/all' });
  }
}

export default QueryTemplateCollection;
