import React from 'react';
import { Position, Toaster, Intent, ProgressBar } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';

const DEFAULT_TOAST_TIMEOUT = 3000;

const basicToast = Toaster.create({
  className: 'portal-toasts',
  position: Position.TOP,
  canEscapeKeyClear: false
});

const progressToast = Toaster.create({
  className: 'portal-toasts-form-errors',
  position: Position.TOP,
  canEscapeKeyClear: false
});

const showToast = options => {
  let { message } = options;
  const { title, timeout = DEFAULT_TOAST_TIMEOUT } = options;

  if (typeof message !== 'string' && !React.isValidElement(message)) {
    message = 'Unknown Error';
    console.warn('Non-string error message provided to Toast');
  }

  return basicToast.show({
    ...options,
    timeout,
    message: (
      <Box>
        {title && <h5 className="toast-title small-caps strong">{title}</h5>}
        {message}
      </Box>
    )
  });
};

const showErrorToast = (message, options = {}) =>
  showToast({ ...options, intent: Intent.DANGER, iconName: 'error', message: options.message || message });

const showWarningToast = (message, options = {}) =>
  showToast({
    ...options,
    intent: Intent.WARNING,
    iconName: 'warning-sign',
    message: options.message || message
  });

const showInfoToast = (message, options = {}) =>
  showToast({ ...options, intent: Intent.PRIMARY, iconName: 'info-sign', message: options.message || message });

const showSuccessToast = (message, options = {}) =>
  showToast({ ...options, intent: Intent.SUCCESS, iconName: 'tick', message: options.message || message });

const getProgressToastOptions = (message, amount) => ({
  canEscapeKeyClear: false,
  iconName: 'time',
  className: 'progress-toast',
  timeout: amount < 1 ? 0 : 2000,
  message: (
    <Flex flexColumn col={12} style={{ marginLeft: -8 }}>
      <small style={{ paddingBottom: 6 }} className="pt-text-muted">
        {amount < 1 ? message : 'Complete!'}
      </small>
      <ProgressBar
        className={classNames({ 'pt-no-stripes': amount >= 1 })}
        intent={amount < 1 ? Intent.PRIMARY : Intent.SUCCESS}
        value={amount ? amount / 1 : null}
      />
    </Flex>
  )
});

const showProgressToast = (message, amount) => {
  const options = getProgressToastOptions(message, amount);
  return progressToast.show(options);
};

const updateProgressToast = (toast, message, amount) => {
  const options = getProgressToastOptions(message, amount);
  return progressToast.update(toast, options);
};

const finishProgressToast = (toast, options = { text: 'Done!', hideDelay: 750, iconName: 'tick' }) => {
  updateProgressToast(toast, {
    intent: Intent.SUCCESS,
    iconName: options.iconName,
    text: options.text,
    className: '',
    showSpinner: false
  });
  setTimeout(() => {
    basicToast.clear(toast);
  }, options.hideDelay);
};

const parseError = text => {
  let parsedText = text;

  if (text) {
    try {
      const body = JSON.parse(text);
      if (body && body.error) {
        parsedText = body.error;
      }
    } catch (e) {
      // Nothing to do here
    }
  }

  return parsedText;
};

const showClientErrorToast = response => {
  const { statusText, body, text } = response;
  const message = body ? body.error : parseError(text);
  return showErrorToast(message, { title: statusText });
};

const showNetworkErrorToast = (response, rawError) => {
  const { status, statusText, url } = response;
  const message = (
    <div>
      {rawError && rawError.error && <div>{rawError.error}</div>}
      <div>
        <strong>Status:</strong> {status} - {statusText}
      </div>
      {url && (
        <div>
          <strong>URL:</strong>
          {url}
        </div>
      )}
    </div>
  );
  return showErrorToast(message, { title: 'Network Error' });
};

export {
  showSuccessToast,
  showInfoToast,
  showWarningToast,
  showErrorToast,
  showClientErrorToast,
  showNetworkErrorToast,
  showProgressToast,
  finishProgressToast,
  updateProgressToast
};
