import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { FormDialog, Form } from 'components/forms';
import { fields, options } from 'forms/config/dashboardItemDetails';
import QueryModel from 'models/query/QueryModel';
import { getHashForQueries } from 'services/urlHash';

import DashboardItemForm from './DashboardItemForm';

@Form({ fields, options })
@inject('$explorer', '$dashboard', '$rawFlow')
@observer
class DashboardItemDialog extends Component {
  static defaultProps = {
    showDashboardSelect: false
  };

  panel_subtitle = {
    explorer_query: 'View',
    alert_scoreboard: 'Scoreboard',
    raw_flow: 'Raw Flow'
  };

  componentDidMount() {
    const { model } = this.props;

    if (model.dataview.formConfig) {
      this.initializeDataViewFields(model);
    }

    if (model && !model.fetchedQuery) {
      model.fetchSavedQuery();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen, model: dashboardItem } = nextProps;
    const { isOpen: isCurrentlyOpen } = this.props;

    if (dashboardItem && isOpen && !isCurrentlyOpen) {
      /**
       * A DashboardItem's DataView informs us about which Component and form fields we need to render.
       * They live on model.formState
       */
      if (dashboardItem.dataview.formConfig) {
        this.initializeDataViewFields(dashboardItem);
      }
      dashboardItem.fetchSavedQuery();
    }
  }

  componentWillUnmount() {
    this.props.$explorer.destroy();
  }

  /**
   * Adds the required form fields required by this DashboardItem's dataview for configuration,
   * and initializes their values.
   */
  initializeDataViewFields = dashboardItem => {
    const { form } = this.props;

    const dataviewFields = dashboardItem.dataview.formConfig;
    const dashboardItemValues = dashboardItem.get();
    const dataviewValues = dashboardItem.dataview.viewModel.get();

    const formValues = {
      ...dashboardItemValues,
      ...dataviewValues
    };

    // add the dataviews fields to the form
    form.addFields(dataviewFields);

    // add them to the `dataviews` field group so it's easier to pull them out later.
    form.addFieldsToGroup(dataviewFields, 'dataview');
    form.init(formValues);
  };

  handleSave = async form => {
    const { model: dashboardItem, $dashboard, $explorer, history, $rawFlow } = this.props;
    const { isNew, dataview } = dashboardItem;
    const { formState, queryModel } = $explorer;

    $dashboard.needsScrollToBottom = isNew;

    const dashboardItemValues = form.getFieldGroupValues('item');

    const dataviewValues =
      dataview && dataview.viewType === 'rawFlow'
        ? { ...$rawFlow.formState.getValues(), query_title: dashboardItemValues.panel_title }
        : { ...form.getFieldGroupValues('dataview'), query_title: dashboardItemValues.panel_title };

    let saved_query_id;
    dashboardItem.setRequestStatus('updating');

    if (!dataview.isFlowVisualization || (dashboardItem.isNew && dataview.isFlowVisualization)) {
      saved_query_id = await getHashForQueries(dataviewValues, { persist: true });
    } else {
      saved_query_id = dashboardItem.get('saved_query_id');
    }

    const attributes = { ...dashboardItemValues, saved_query_id };
    let explorerQuerySwitches = {};

    if (formState) {
      formState.submit(() => {
        queryModel.set(formState.getValues());
        $explorer.apply(QueryModel.create(queryModel.serialize()));
        formState.setModel(queryModel);
      });
      explorerQuerySwitches = {
        time_locked: formState.getValue('time_locked'),
        device_locked: formState.getValue('device_locked'),
        filter_source: formState.getValue('filter_source')
      };
    }

    if (dataview.isFlowVisualization) {
      $explorer.dataview.queryBuckets.save(true).then(hash =>
        dashboardItem
          .save(
            Object.assign(form.getValues(), { saved_query_id: hash, ...explorerQuerySwitches }),
            { toast: false },
            attributes
          )
          .then(() => {
            if (dashboardItem.get('dashboard')) {
              if (!$dashboard.dashboard.isIncompleteParametric) {
                dashboardItem.updateQuery(QueryModel.create(dashboardItem.get('dashboard').get('query')).serialize());
              }
            } else {
              history.push(`/library/dashboard/${dashboardItem.get('dashboard_id')}`);
            }
          })
      );
    } else {
      dashboardItem.save(attributes).then(() => {
        dashboardItem.updateQuery();
        if (dataview.viewType === 'rawFlow') {
          const rawFlowQuery = QueryModel.create(dataviewValues);
          dashboardItem.set({ rawFlowQuery });
        }
      });
    }
  };

  render() {
    const { onClose, showDashboardSelect, model } = this.props;

    const { isNew } = model || {};
    const panel_type = model && model.get('panel_type');
    const title = `${isNew ? 'Add' : 'Edit'} ${this.panel_subtitle[panel_type]} Panel`;

    return (
      <FormDialog
        {...this.props}
        style={{
          width: 'calc(100vw - 200px)',
          maxWidth: 1600
        }}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          paddingBottom: 0,
          marginBottom: 8
        }}
        entityName="Dashboard Panel"
        title={title}
        formActionsProps={{ onSubmit: this.handleSave, onCancel: onClose }}
        formComponent={
          <DashboardItemForm {...this.props} handleSave={this.handleSave} showDashboardSelect={showDashboardSelect} />
        }
      />
    );
  }
}

export default withRouter(DashboardItemDialog);
