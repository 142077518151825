import moment from 'moment';
import { observable, computed } from 'mobx';
import BaseModel from 'models/BaseModel';
import { translateFromUTCForUser, numberWithCommas } from 'util/utils';
import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

export default class DatasetResult extends BaseModel {
  @observable
  loadingExplorerResult = false;

  @computed
  get avg() {
    return numberWithCommas(parseInt((this.get('avgsum') * 8) / 1000000 / this.get('timeRange'), 10));
  }

  @computed
  get max() {
    return numberWithCommas(parseInt((this.get('max') * 8) / 1000000 / 3600, 10));
  }

  @computed
  get time_of_95th() {
    const start_time = this.get('start_time');
    return start_time && translateFromUTCForUser(moment.utc(start_time)).format(DEFAULT_DATETIME_FORMAT);
  }

  @computed
  get p95th_mbps() {
    return numberWithCommas(parseInt((this.get('nth_value') * 8) / 1000000 / 3600, 10));
  }

  @computed
  get per_at_95th() {
    const percentile = this.get('per_at_95th');
    return percentile ? `${parseInt(percentile, 10)}%` : 'N/A';
  }

  get sortValues() {
    return {
      avg: () => parseInt(this.get('avgsum'), 10),
      max: () => parseInt(this.get('max'), 10),
      per_at_95th: () => {
        const percentile = this.get('per_at_95th');
        return percentile ? parseFloat(percentile) : 0;
      },
      reportfield: () => this.get('reportfield').length,
      p95th_mbps: () => parseInt(this.get('nth_value'), 10)
    };
  }
}
