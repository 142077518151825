import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Intent, Position, Tag, Tooltip } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';

const DashboardTooltipContent = ({ dashboard }) => {
  if (dashboard) {
    return (
      <div>
        <div className="pt-strong">Navigate To:</div>
        <div>{dashboard.get('dash_title')}</div>
      </div>
    );
  }
  return <div>No Dashboard Destination Set</div>;
};

@inject('$dashboards')
@observer
export default class DashboardItemFooter extends Component {
  prevSize = 0;

  componentWillMount() {
    const {
      selectedModels: { length }
    } = this.props;
    this.prevSize = length;
  }

  componentDidUpdate() {
    const {
      item,
      selectedModels: { length }
    } = this.props;
    const {
      dataview: { component }
    } = item;

    if (
      component &&
      component.reflow &&
      this.prevSize !== length &&
      (length === 0 || length === 1) &&
      !(item.get('dashboard_navigation') && item.get('destination_dashboard'))
    ) {
      component.reflow();
    }
  }

  render() {
    const { dashboard, item, selectedModels, onClick, onClear, $dashboards } = this.props;

    return (
      <Flex p={1} justify="space-between">
        <Box flexAuto>
          {selectedModels.length > 0 && (
            <Button className="pt-intent-danger pt-small pt-minimal" onClick={onClear}>
              Clear Selections
            </Button>
          )}
        </Box>
        {item.get('dashboard_navigation') &&
          item.get('destination_dashboard') &&
          !!$dashboards.getDashboard(item.get('destination_dashboard')) && (
            <Tooltip content={<DashboardTooltipContent dashboard={dashboard} />} position={Position.TOP_RIGHT}>
              <Button onClick={onClick} intent={Intent.PRIMARY} className="pt-medium pt-small pt-minimal">
                {item.get('dashboard_navigation_options.nesting.drillDownButtonText')}
                {selectedModels.length > 0 && (
                  <Tag intent={Intent.PRIMARY} className="pt-small">
                    {selectedModels.length}
                  </Tag>
                )}
              </Button>
            </Tooltip>
          )}
      </Flex>
    );
  }
}
