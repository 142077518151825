import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AnchorButton } from '@blueprintjs/core';
import { withRouter } from 'react-router-dom';

import { Flex, Box } from 'components/flexbox';
import resultSets from './ResultSets';

@inject('$search')
@observer
class Results extends Component {
  static propTypes = {
    results: PropTypes.object,
    maxResults: PropTypes.number,
    showFullButton: PropTypes.bool,
    onResultClick: PropTypes.func
  };

  static defaultProps = {
    results: undefined,
    maxResults: 16,
    showFullButton: true,
    onResultClick: () => {}
  };

  handleShowFullResults = () => {
    const { history, onResultClick, $search } = this.props;

    history.push(`/search/${$search.queryString}`);
    onResultClick();
  };

  renderResult = (result, idx) => {
    const { onResultClick, showFullButton } = this.props;
    const ResultRow = resultSets[result.resultCategory];

    if (!result || result.length === 0 || !ResultRow) {
      return null;
    }

    return (
      <ResultRow
        result={result}
        onResultClick={onResultClick}
        key={`${result.resultCategory}-${result.id || idx}`}
        truncateDescription={showFullButton}
      />
    );
  };

  render = () => {
    const { maxResults, showFullButton, results, $search } = this.props;
    const totalResults = $search.totalResults;

    const className = classNames('search-results overflow-auto', {
      'search-results-full': !showFullButton
    });

    const resultsReady = !$search.isSearchLoading && results;

    return (
      <Flex flexAuto flexColumn className={className}>
        {resultsReady && totalResults === 0 && <Box p={2}>No results found.</Box>}

        {resultsReady &&
          totalResults > 0 && (
            <Fragment>
              <Box
                p={0.5}
                pb={showFullButton ? 0 : 0.5}
                className={classNames({ 'search-results-dropdown': maxResults > 0 })}
              >
                {$search
                  .getSortedResults(results)
                  .slice(0, maxResults > 0 ? maxResults : totalResults)
                  .map(this.renderResult)}
              </Box>

              {showFullButton && (
                <AnchorButton
                  className="pt-dark pt-minimal nav-button pt-intent-primary"
                  onClick={this.handleShowFullResults}
                >
                  Show Full Results ({totalResults})
                </AnchorButton>
              )}
            </Fragment>
          )}
      </Flex>
    );
  };
}

export default withRouter(Results);
