import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from '@blueprintjs/core';
import { Flex, Box } from 'components/flexbox';

import Icon, { MaterialIcon } from 'components/Icon';

// alignment seems slightly off.
const viewTagIconStyle = {
  fontSize: 11,
  marginRight: 4,
  position: 'relative',
  top: -1
};

const ViewTag = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Box className="pt-tag pt-intent-primary" ml={1}>
      <Icon name="star" style={viewTagIconStyle} />
      <strong>{text}</strong>
    </Box>
  );
};

@observer
class DataViewHeader extends Component {
  render() {
    const {
      category,
      dataview,
      size: dataViewSize,
      isGenerated,
      isSavedView,
      navigationHistory,
      sourceLink,
      shouldArrangeVertically,
      showTitleLink,
      showViewTypeTag,
      showCategory,
      suppressDataviewLoaded = false,
      tools
    } = this.props;

    let { title } = dataview;
    let tagText = '';
    let description = '';
    if (sourceLink) {
      if (sourceLink.type === 'view') {
        tagText = 'Saved View';
        if (!isGenerated) {
          title = sourceLink.model.get('view_name');
        }
      } else if (sourceLink.type === 'dashboard') {
        tagText = 'Dashboard Panel';
      }

      if (sourceLink.model && !isGenerated) {
        description = sourceLink.model.panelDescription;
      }
    }

    const savedViewDescription = isSavedView && sourceLink.model.get('view_description');
    const headerClassName = classNames('header', {
      'dataview-loaded': !suppressDataviewLoaded && dataview.fullyLoaded
    });

    const arrangeVertically = shouldArrangeVertically && dataViewSize.width < 700;
    const shouldCollapseButtonText = dataViewSize.width <= 465;

    let titleTag;
    if (showTitleLink) {
      const template_id = dataview.queryBuckets.selectedQuery.get('template_id');
      const link = template_id ? `/library/${template_id}` : `/explorer/${dataview.hash}`;

      titleTag = <Link to={{ pathname: link, state: { navigationHistory } }}>{title}</Link>;
    } else {
      titleTag = <span>{title}</span>;
    }

    return (
      <Flex flexColumn={arrangeVertically} align="flex-start" wrap flexAuto style={{ zIndex: 3 }}>
        <Box flexAuto mr={2} mb={arrangeVertically ? 2 : 0}>
          <h6 className={headerClassName}>
            {showCategory && (
              <Box className="small-caps pt-intent-warning-text" style={{ fontSize: 11, fontWeight: 600 }}>
                {category}
              </Box>
            )}
            {titleTag}
            {description && (
              <Tooltip
                content={<div style={{ maxWidth: 500 }}>{description}</div>}
                tetherOptions={{
                  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
                }}
              >
                <MaterialIcon
                  className="pt-text-muted"
                  name="help_outline"
                  style={{ marginTop: 4, marginLeft: 4, verticalAlign: 'top', fontSize: 16 }}
                />
              </Tooltip>
            )}
            {showViewTypeTag && <ViewTag text={tagText} sourceLink={sourceLink} />}
          </h6>
          {savedViewDescription && (
            <Box>
              <small className="pt-text-muted">{savedViewDescription}</small>
            </Box>
          )}
        </Box>

        {tools && React.cloneElement(tools, { dataViewSize, shouldCollapseButtonText })}
      </Flex>
    );
  }
}

export default DataViewHeader;
