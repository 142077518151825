import React from 'react';
import { Button, Tooltip, Popover } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import Icon from 'components/Icon';
import $lookups from 'stores/$lookups';
import { getToFixed, zeroToText, adjustByGreekPrefix } from 'util/utils';
import ListASNs from 'views/Admin/AsGroups/ListASNs';

const overlayRenderer = (showOverlayTag, model) => (
  <div>
    {model.get('name')}
    {showOverlayTag && (
      <span className="pt-tag pt-minimal pt-intent-primary" style={{ marginLeft: 5 }}>
        Overlay
      </span>
    )}
  </div>
);

export const sampleRateValueRenderer = adjust => ({ value }) => (
  <Flex flexAuto>
    <Box flexAuto mr={0.5} style={{ textAlign: 'right' }}>
      {Math.round(value / (adjust ? 100 : 1))}
    </Box>
    <Box className="pt-text-muted" style={{ width: 50 }}>
      : 1
    </Box>
  </Flex>
);

export const valueRenderer = ({ fix, prefix }) => ({ value }) =>
  zeroToText(adjustByGreekPrefix(value, prefix) || 0, { fix });

export const averageRenderer = ({ fix, prefix, query }) => {
  if (query.get('show_total_overlay')) {
    return ({ value, model, column, collection }) => {
      let pctValue;
      if (collection && !model.get('isOverlay')) {
        let totalOverlayIndex = 0;
        if (query.get('show_overlay') && collection.at(0).get('name') !== 'Total') {
          totalOverlayIndex = 1;
        }
        const totalOverlay = collection.at(totalOverlayIndex);
        if (totalOverlay) {
          const totalValue = totalOverlay.get(column.name);
          pctValue = totalValue === 0 ? `(${(100).toFixed(fix)}%)` : `(${((value / totalValue) * 100).toFixed(fix)}%)`;
        }
      }

      return (
        <Flex flexAuto>
          <Box flexAuto mr={0.5} style={{ textAlign: 'right' }}>
            {valueRenderer({ fix, prefix })({ value })}
          </Box>
          <Box className="pt-text-muted" style={{ width: 50 }}>
            {pctValue}
          </Box>
        </Flex>
      );
    };
  }

  return valueRenderer({ fix, prefix });
};

export const portRenderer = (checkOverlay, showOverlayTag, fastData, queryInterval) => ({ value, model }) => {
  if (checkOverlay && model.get('isOverlay')) {
    return overlayRenderer(showOverlayTag, model);
  }
  // return `${value}` === '65535' && fastData === 'Fast' ? (
  return `${value}` === '65535' && (fastData === 'Fast' || (fastData === 'Auto' && queryInterval > 86400)) ? (
    <Tooltip
      content={
        <div>
          <div>When viewing fast data, all port numbers above 32767 are aggregated and represented as port 65535.</div>
          <div>Please switch to full data to see a complete port breakdown.</div>
        </div>
      }
    >
      <div>
        {value}
        <span className="pt-intent-danger-text" style={{ marginLeft: 3, fontSize: 20, lineHeight: '14px' }}>
          &bull;
        </span>
      </div>
    </Tooltip>
  ) : (
    value
  );
};

export const countryRenderer = showOverlayTag => ({ value, model }) => {
  if (model.get('isOverlay')) {
    return overlayRenderer(showOverlayTag, model);
  }
  if ($lookups.countryOptions) {
    const countryOption = $lookups.countryOptions.find(country => country.value === value);
    return countryOption ? `${countryOption.label} (${value})` : value;
  }
  return value;
};

export const asnRenderer = showOverlayTag => ({ value, model }) => {
  if (model.get('isOverlay')) {
    return overlayRenderer(showOverlayTag, model);
  }

  const match = (value || '').match(/(.+?)\s\((.+?)\)/);
  const name = match && match[1];
  const ASNs = match && match[2].split(/,\s*/);
  if (ASNs && ASNs.length > 1) {
    return (
      <Popover
        target={
          <span>
            <Icon name="group-objects" /> {name}
          </span>
        }
        content={
          <Box p={2} style={{ maxWidth: 500 }}>
            <h5>AS Group: {name}</h5>
            <Box mb={2} style={{ maxHeight: 200, overflow: 'auto' }}>
              <ListASNs asNumbers={ASNs} />
            </Box>
            <Button className="pt-popover-dismiss">Dismiss</Button>
          </Box>
        }
        tetherOptions={{ constraints: [{ attachment: 'together', pin: true, to: 'window' }] }}
      />
    );
  }

  return value;
};

export const cloudRenderer = showOverlayTag => ({ value, model }) => {
  if (model.get('isOverlay')) {
    return overlayRenderer(showOverlayTag, model);
  }

  const providerMap = {
    aws: 'Amazon Web Services',
    gcp: 'Google Cloud Platform',
    azure: 'Microsoft Azure'
  };

  return providerMap[value] || value;
};

export const valueOrNameRenderer = showOverlayTag => ({ value, model }) => {
  if (model.get('isOverlay')) {
    return overlayRenderer(showOverlayTag, model);
  }
  return value;
};

export const toggleRenderer = ({ model }) => {
  const { toggled, color, rawData } = model.get();

  if (!rawData || !color) {
    return null;
  }

  return (
    <Button
      className="pt-minimal legend-indicator"
      onClick={() => model.set({ toggled: !toggled })}
      title={toggled ? 'Click to enable this row' : 'Click to disable this row'}
    >
      <div style={{ borderColor: color, backgroundColor: toggled ? 'transparent' : color }} />
    </Button>
  );
};

export const bracketRenderer = ({ model }) => {
  const tagDataKey = Object.keys(model.toJS()).find(prop => prop.startsWith('tag_'));
  const tagInfo = tagDataKey ? model.get(tagDataKey) : undefined;
  if (!tagInfo) {
    return null;
  }
  const { value, bracketLabel, bracketAggregationDisplay, bracketAggregationDisplayUnit } = tagInfo;
  // not sure if this is how we want to show bracket range, but will work for now.
  return (
    <Tooltip
      content={
        <span>
          {bracketLabel} <br /> ({bracketAggregationDisplay} {bracketAggregationDisplayUnit})
        </span>
      }
    >
      <div style={{ borderColor: value, backgroundColor: value, width: '10px', height: '100%' }} />
    </Tooltip>
  );
};

export const getAggregateRenderer = ({ aggregate, prefix, bucket }) => {
  const show_total_overlay = bucket.firstQuery.get('show_total_overlay');
  const unit = aggregate.parentUnit || aggregate.unit;

  if (unit.includes('sample_rate')) {
    return sampleRateValueRenderer(aggregate.fn !== 'percentile');
  } else if (show_total_overlay && aggregate.fn === 'average') {
    return averageRenderer({
      fix: getToFixed(unit),
      prefix: prefix[unit],
      query: bucket.firstQuery
    });
  }

  return valueRenderer({
    fix: getToFixed(unit),
    prefix: prefix[unit]
  });
};
