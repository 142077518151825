import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$tags')
class FlowTags extends Component {
  title = 'Flow Tag';

  url = '/admin/tags';

  render() {
    const { result, $tags } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="material-icons:bookmark"
        url={this.url}
        nameField="value"
        collection={$tags.pagedCollection}
        {...this.props}
      >
        <span>ID: {result.id}</span>
      </ResultRow>
    );
  }
}

export default FlowTags;
