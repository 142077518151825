import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import { Box } from 'components/flexbox';

@inject('$basicExplorer', '$dataviews', '$library')
@observer
class Pivots extends Component {
  handleSelectPivot = pivot => {
    const { $basicExplorer } = this.props;
    $basicExplorer.setPivot(pivot);
  };

  renderPivotButton = pivot => {
    const { $basicExplorer, $library } = this.props;
    const { activePivot } = $basicExplorer;
    const selected = activePivot && activePivot.label === pivot.label;

    return (
      <Button
        active={activePivot && activePivot.label === pivot.label}
        className="pt-fill pt-text-overflow-ellipsis pt-text-left"
        disabled={$library.isLoading}
        iconName="pivot"
        key={pivot.label}
        onClick={!selected ? () => this.handleSelectPivot(pivot) : undefined}
        text={pivot.label}
      />
    );
  };

  render() {
    const { $basicExplorer, template } = this.props;
    const { activePivot } = $basicExplorer;

    const pivots = template.get('pivots');

    return (
      <Fragment>
        {pivots.length === 1 && this.renderPivotButton(pivots[0])}
        {pivots.length > 1 && (
          <Box className="pt-button-group pt-vertical pt-align-left pt-fill" mb={activePivot ? 1 : 0}>
            {template.get('pivots').map(this.renderPivotButton)}
          </Box>
        )}
        {activePivot && (
          <Button
            iconName="undo"
            text="Reset"
            className="pt-minimal pt-text-muted pt-small pt-strong"
            style={{ marginTop: 4 }}
            onClick={() => $basicExplorer.setPivot()}
          />
        )}
      </Fragment>
    );
  }
}

export default Pivots;
