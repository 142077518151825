import React from 'react';
import Transition from 'react-transition-group/Transition';

const defaultStyle = {
  transition: 'transform 325ms ease-in-out, opacity 175ms ease-out'
};

const transitionStyles = {
  left: {
    entering: {
      opacity: 0,
      transform: 'translateX(-275px)'
    },
    entered: {
      opacity: 1,
      transform: 'translateX(0)'
    },
    exited: {
      opacity: 0,
      transform: 'translateX(-275px)'
    }
  },

  right: {
    entering: {
      opacity: 0,
      transform: 'translateX(275px)'
    },
    entered: {
      opacity: 1,
      transform: 'translateX(0)'
    },
    exited: {
      opacity: 0,
      transform: 'translateX(275px)'
    }
  }
};

const defaultProps = {
  timeout: 0
};

const SlideIn = ({ in: inProp, from = 'left', children, ...rest }) => (
  <Transition in={inProp} {...defaultProps} {...rest}>
    {state => {
      const styles = {
        ...defaultStyle,
        ...transitionStyles[from][state]
        // ...getTransitionStyles(direction, state)
        // ...transitionStyles[state]
      };

      return children({
        state,
        styles
      });
    }}
  </Transition>
);

export default SlideIn;
