import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

const TimestampCell = props => {
  const { model } = props;
  const ctime = model.get('ctime');

  return <span className="pt-text-overflow-ellipsis">{moment.utc(ctime).format(DEFAULT_DATETIME_FORMAT)}</span>;
};

export default observer(TimestampCell);
