import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import startCase from 'lodash/startCase';
import { reportTypes } from 'models/reports/ReportsCollection';
import ResultRow from 'views/Search/ResultRow';

@inject('$library')
@observer
class Reports extends Component {
  url = '/library';

  getReportLink = result => {
    if (result.type === 'dashboard') {
      return `${this.url}/dashboard/${result.id}`;
    }
    if (result.type === 'savedView') {
      return `${this.url}/savedView/${result.id}`;
    }

    return `${this.url}/${result.id}`;
  };

  render() {
    const { result } = this.props;
    const reportType = reportTypes.get(result.type);

    return (
      <ResultRow
        type={startCase(result.type)}
        link={this.getReportLink(result)}
        icon={reportType.iconName}
        nameField={reportType.nameField}
        {...this.props}
      >
        {result[reportType.descField]}
      </ResultRow>
    );
  }
}

export default Reports;
