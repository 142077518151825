import React from 'react';

import DeviceValuesRenderer from 'components/forms/device/DeviceValuesRenderer';
import $devices from 'stores/$devices';

const DeviceDisplay = ({ model, query, getDevices }) => {
  let all_devices;
  let device_name;

  if (model) {
    all_devices = model.get('all_devices');
    device_name = model.get(getDevices ? 'devices' : 'device_name');
  } else {
    all_devices = query.all_devices;
    device_name = query[getDevices ? 'devices' : 'device_name'];
  }

  if (all_devices) {
    return (
      <div className="section-display-view">
        <div className="pt-tag pt-minimal pt-round pt-intent-success">
          <strong>All Devices</strong>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-select-input pt-input-group">
      <DeviceValuesRenderer values={device_name} options={$devices.getDeviceOptions()} />
    </div>
  );
};

export default DeviceDisplay;
