import Highcharts from 'highcharts';
import BrokenAxis from 'highcharts/modules/broken-axis';
import { observer } from 'mobx-react';
import $dictionary from 'stores/$dictionary';
import moment from 'moment';
import $app from '../../stores/$app';
import BaseHighchartsDataview from './BaseHighchartsDataview';
import { getSharedTooltip } from './tooltipRenderers';

BrokenAxis(Highcharts);

@observer
export default class StackedColumnView extends BaseHighchartsDataview {
  chartOptions = {
    chart: {
      zoomType: 'x',
      events: {
        selection: this.handleTimeZoom
      }
    },
    colors: this.chartColors,
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%m/%e',
        minute: '%H:%M'
      },
      labels: { style: { color: this.chartLabelColor } },
      title: {
        text: 'UTC',
        style: { color: this.chartLabelColor }
      },
      gridLineWidth: 1
    },
    yAxis: {
      min: 0.001,
      labels: { style: { color: this.chartLabelColor } },
      title: {
        text: 'units go here',
        style: { color: this.chartLabelColor }
      },
      gridLineWidth: 1
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      },
      column: {
        stacking: 'normal',
        groupPadding: 0,
        pointPadding: 0.05
      }
    },
    legend: { enabled: false },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter() {
        return getSharedTooltip(this.points, 'column', getSharedTooltip(this.points));
      }
    },
    series: []
  };

  renderSeries(bucket, name, data) {
    if (!this.chart) {
      return null;
    }

    if (data.length) {
      const breaks = [];
      const origDuration = data[0][2] * 1000;
      for (let x = 0, xlen = data.length - 1; x < xlen; x += 1) {
        if (data[x + 1][0] - data[x][0] > origDuration) {
          breaks.push({
            from: data[x][0] + data[x][2] * 1000,
            to: data[x + 1][0]
          });
        } else if (data[x + 1][0] - data[x][0] < origDuration) {
          data.splice(x, 1);
          x -= 1;
          xlen -= 1;
        }
      }

      if (!this.chart.xAxis[0].options.breaks) {
        this.chart.xAxis[0].update({
          breaks
        });
      }
    }

    const type = 'column';
    const seriesData = { type, name, data };
    const matchingSeries = this.chart.series.find(series => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = name;
      seriesData.color = matchingSeries.color;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
    }
    return this.chart.addSeries(seriesData, false);
  }

  findSeries(bucket, name) {
    const isNegative = bucket.get('name') === 'Left -Y Axis';
    return (
      this.chart &&
      this.chart.series.find(
        series => series.name === name && ((isNegative && series.dataMax < 0) || series.dataMax > 0)
      )
    );
  }

  removeSeries(bucketName, name) {
    const isNegative = bucketName === 'Left -Y Axis';
    const existingSeries =
      this.chart &&
      this.chart.series.find(
        series => series.name === name && ((isNegative && series.dataMax < 0) || series.dataMax > 0)
      );
    if (existingSeries) {
      existingSeries.remove(false);
    } else {
      console.warn('Attempted to remove series', name, 'from bucket', bucketName, ', but no series was found.');
    }
  }

  renderOverlay(bucket, name, data) {
    if (!this.chart) {
      return null;
    }

    const type = 'line';
    let color = this.primaryOverlayColor;
    let dashStyle = 'Solid';

    if (name.startsWith('Historical Total')) {
      color = this.overlayColor;
      dashStyle = 'Dash';
    }

    for (let x = 0, xlen = data.length - 1, origDuration = data[0][2] * 1000; x < xlen; x += 1) {
      if (data[x + 1][0] - data[x][0] < origDuration) {
        data.splice(x, 1);
        x -= 1;
        xlen -= 1;
      }
    }

    const seriesData = { type, name, data, color, dashStyle, zIndex: 1 };
    const matchingSeries = this.chart.series.find(series => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = name;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
    }

    return this.chart.addSeries(seriesData, false);
  }

  redraw(opts) {
    $app.renderSync(() => {
      if (this.chart) {
        if (this.chart.series.length > 0) {
          const { dateRangeDisplay } = this.props.dataview.queryBuckets.activeBuckets[0].firstQuery;
          this.chart.xAxis[0].setTitle({
            text: `${dateRangeDisplay} (${this.chart.series[0].userOptions.data[0][2] / 60} minute intervals)`
          });
        }

        const [axis] = this.chart.yAxis;
        const { dataMin, dataMax } = axis.getExtremes();
        const extreme = Math.max(Math.abs(dataMin), dataMax);
        if (extreme) {
          axis.setExtremes(dataMin < 0 ? extreme * -1 : 0.001, extreme, false);
        }
      }
    });

    super.redraw(opts);
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview, showNativeLegend } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;

    if (query.get('outsort').includes('agg_total')) {
      throw new Error('This Time-Series chart does not support Display & Sort By Total.');
    }

    if (activeBucketCount > 1) {
      chartOptions.yAxis.min = null;
    }

    chartOptions.xAxis.momentFn = query.get('time_format') === 'UTC' ? moment.utc : moment;
    chartOptions.xAxis.title.text = query.dateRangeDisplay;
    chartOptions.yAxis.title.text = $dictionary.dictionary.units[query.outsortUnit];
    chartOptions.yAxis.unit = query.outsortUnit;
    chartOptions.yAxis.type = query.get('use_log_axis') ? 'logarithmic' : 'linear';
    chartOptions.colors = this.chartColors;

    if (showNativeLegend) {
      chartOptions.legend = {
        itemStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'bold !important'
        },
        labelFormatter() {
          return this.name.substr(0, 16);
        },
        verticalAlign: 'bottom',
        align: 'center'
      };
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: true,
  enableToggle: true,
  mirrorable: true,
  supportsLogAxis: true,
  buckets: [
    {
      name: 'Left +Y Axis',
      mirrorBucket: 1
    },
    {
      name: 'Left -Y Axis',
      sampleRateFactor: -1
    }
  ]
};

export { config };
