import React from 'react';
import { observer } from 'mobx-react';
import { DateRangeInput, TimePickerPrecision } from '@blueprintjs/datetime';

import { defaultPopoverProps } from 'components/forms/DateInput';
import { whitelistFieldProps } from 'forms/utils';

const defaultProps = {
  closeOnSelection: true,
  popoverProps: defaultPopoverProps
};

const DateTimeRange = props => {
  const { field } = props;
  return (
    <DateRangeInput
      startInputProps={{ onFocus: () => field.setPristine(false) }}
      endInputProps={{ onFocus: () => field.setPristine(false) }}
      {...field.getProps()}
      {...defaultProps}
      {...whitelistFieldProps(props)}
      format="YYYY-MM-DD HH:mm:ss"
      timePrecision={TimePickerPrecision.MINUTE}
    />
  );
};

export default observer(DateTimeRange);
