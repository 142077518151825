import React, { PureComponent } from 'react';

export default class SelectInput extends PureComponent {
  componentDidMount() {
    setTimeout(() => this.focus(), 0);
  }

  focus() {
    this.inputEl.focus();
  }

  render() {
    return <input ref={inputEl => (this.inputEl = inputEl)} {...this.props} />;
  }
}
