import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import { isEqual } from 'lodash';

import { Flex, Box } from 'components/flexbox';
import { Field, Input, Select, Switch } from 'components/forms';
import FilterOptions from 'components/sidebar/FilterOptions';
import FilterOptionsDialog from 'components/FilterOptions/FilterOptionsDialog';
import LandingPage from '../Profile/LandingPage';

const selectButtonStyle = {
  marginTop: -8,
  marginBottom: 16
};

@inject('$users', '$auth', '$userGroups')
@observer
class UserDetailsForm extends Component {
  static contextTypes = {
    form: any
  };

  state = {
    editingFilters: false
  };

  handleToggleFiltersEditing = () => {
    this.setState({ editingFilters: !this.state.editingFilters });
  };

  handleResetApiToken = () => {
    const { model, $users, form } = this.props;
    $users.resetUserApiToken(model.id).then(user_api_token => {
      form.setValue('user_api_token', user_api_token);
    });
  };

  handleDisableTotp = () => {
    const { model, $users } = this.props;
    $users.disableUserTotps(model.id);
  };

  getRoleOptions() {
    const { $auth, model } = this.props;
    const { user_level: modelUserLevel } = model.get();
    let roles = [
      {
        value: 'Administrator',
        label: 'Administrator'
      },
      {
        value: 'Member',
        label: 'Member'
      }
    ];

    // if authenticated user is superAdmin, let them create more superAdmins
    if ($auth.isSuperAdministrator) {
      roles.unshift({
        value: 'Super Administrator',
        label: 'Super Administrator'
      });

      // else if authenticated user is NOT superAdmin, and they are looking at a superAdmin, they can't de-level.
    } else if (modelUserLevel.toString() === '2') {
      roles = [
        {
          value: 'Super Administrator',
          label: 'Super Administrator'
        }
      ];
    }

    return roles;
  }

  getUserGroups() {
    const {
      $userGroups: { userGroupOptions }
    } = this.props;

    return [{ value: null, label: 'N/A' }, ...userGroupOptions];
  }

  // TODO: this should move and become reusable
  passwordSameValidator = () => {
    const { form } = this.props;

    return {
      message: '":attribute" must be the same',
      fn: (value, field) => {
        const otherField = form.getField(field);
        return isEqual(value, otherField.getValue());
      }
    };
  };

  setLandingPageType = defaultLandingPageType => {
    const { form } = this.props;
    form.setValue('defaultLandingPageType', defaultLandingPageType);
    const landingPageValueField = form.getField('defaultLandingPageValue');

    landingPageValueField.setValue(null);

    if (defaultLandingPageType === 'saved-view' || defaultLandingPageType === 'dashboard') {
      landingPageValueField.setRules('required|integer');
    } else {
      landingPageValueField.setRules('');
    }
  };

  setLandingPageValue = id => {
    const { form } = this.props;
    form.setValue('defaultLandingPageValue', id);
  };

  render() {
    const { model, $users, $auth } = this.props;
    const { editingFilters } = this.state;
    const { form } = this.context;
    const isNew = model && model.isNew;
    const { totpEnabled } = (model && model.get()) || {};
    const filterGroupValues = form.getValue('filters.filterGroups');

    return (
      <Box>
        <Field
          name="user_email"
          autoFocus
          inputStyle={{ width: 300 }}
          labelAlign="left"
          helpText="Changes will send a verification message to the updated address. The new address will only appear
          after user has completed verification"
        >
          <Input />
        </Field>
        <Field name="user_full_name" inputStyle={{ width: 300 }} labelAlign="left">
          <Input />
        </Field>

        <Field
          name="role"
          inputStyle={{ width: 180 }}
          labelAlign="left"
          helpText="Administrators have full access to the system. Members have view only access."
          options={this.getRoleOptions()}
        >
          <Select />
        </Field>

        {($auth.hasPermission('subtenancy.enabled') ||
          $auth.hasPermission('subtenancy.trialMode') ||
          $auth.isActiveTrial) && (
          <Field
            name="user_group_id"
            inputStyle={{ width: 180 }}
            labelAlign="left"
            helpText="Add the user to one of the user groups. By default the user belongs to this company."
            options={this.getUserGroups()}
          >
            <Select />
          </Field>
        )}

        {!isNew && (
          <div>
            <hr />

            <Field name="user_api_token" labelAlign="left" readOnly>
              <Input />
            </Field>

            <Flex>
              <Box style={{ width: 140 }} />
              <Button text="Reset API Token" loading={$users.resettingApiToken} onClick={this.handleResetApiToken} />
            </Flex>

            <hr />

            <Field name="user_2fa" labelAlign="left" transferProps={false}>
              <Flex mt={totpEnabled ? 0 : 1}>
                {totpEnabled && (
                  <Button
                    text="Disable 2-Factor Authentication"
                    loading={$users.disablingTotp}
                    onClick={this.handleDisableTotp}
                  />
                )}
                {!totpEnabled && <span>2-Factor authentication is disabled for this user.</span>}
              </Flex>
            </Field>
          </div>
        )}

        <hr />

        <Field name="email_service" labelAlign="left" helpText="Announcements from Kentik related to the platform.">
          <Switch />
        </Field>
        <Field name="email_product" labelAlign="left" helpText="Release schedules, and planned maintenance.">
          <Switch />
        </Field>

        {($auth.isSuperAdministrator || $auth.isSpoofed) && (
          <Fragment>
            <hr />
            <h6 style={{ marginBottom: 16 }}>User Permissions</h6>
            <Field
              name="settings.canCancelRawFlow"
              labelAlign="left"
              labelStyle={{ width: 155 }}
              helpText="Grants access to cancel queries in the forensic viewer"
            >
              <Switch />
            </Field>
          </Fragment>
        )}

        <hr />
        <Box mt={1}>
          <div className="pt-form-group">
            <label>User Specific Filters</label>
            <p className="pt-text-muted">
              <strong>Note: </strong> Updates to user filters can take up to 20 minutes to take effect.
            </p>
            <FilterOptions />
          </div>
          <Button iconName="multi-select" style={selectButtonStyle} onClick={this.handleToggleFiltersEditing}>
            {filterGroupValues.length > 0 ? 'Edit Filters' : 'Add Filters'}
          </Button>
          <FilterOptionsDialog
            isOpen={editingFilters}
            showSaveFiltersButton={false}
            onClose={this.handleToggleFiltersEditing}
          />
          <LandingPage
            typeField={form.getValue('defaultLandingPageType')}
            valueField={form.getValue('defaultLandingPageValue')}
            setLandingPageType={this.setLandingPageType}
            setLandingPageValue={this.setLandingPageValue}
          />
        </Box>
      </Box>
    );
  }
}

export default UserDetailsForm;
