import { action, useStrict } from 'mobx';

import Collection from 'models/Collection';
import Socket from 'util/Socket';
// import { isDev } from 'util/utils';

import $alertingMitigation from './$alertingMitigation';
import $alerts from './$alerts';
import $app from './$app';
import $auth from './$auth';
import $basicExplorer from './$basicExplorer';
import $bgpDatasets from './$bgpDatasets';
import $clouds from './$clouds';
import $companySettings from './$companySettings';
import $customApplications from './$customApplications';
import $customDimensions from './$customDimensions';
import $dashboard from './$dashboard';
import $dashboards from './$dashboards';
import $dataviews from './$dataviews';
import $dictionary from './$dictionary';
import $devices from './$devices';
import $deviceGroups from './$deviceGroups';
import $explorer from './$explorer';
import $exports from './$exports';
import $interfaceClass from './$interfaceClass';
import $interfaces from './$interfaces';
import $library from './$library';
import $lookups from './$lookups';
import $messages from './$messages';
import $networkClassification from './$networkClassification';
import $notifications from './$notifications';
import $plans from './$plans';
import $queryEditor from './$queryEditor';
import $queryTemplates from './$queryTemplates';
import $rawFlow from './$rawFlow';
import $recentlyViewed from './$recentlyViewed';
import $savedFilters from './$savedFilters';
import $savedViews from './$savedViews';
import $search from './$search';
import $sites from './$sites';
import $subscriptions from './$subscriptions';
import $sudo from './$sudo';
import $tags from './$tags';
import $usageStats from './$usageStats';
import $users from './$users';
import $userGroups from './$userGroups';
import $signup from './$signup';
import $onboarding from './$onboarding';

// Force all observables everywhere to be modified only via actions
useStrict(true);

class Store {
  constructor() {
    Object.assign(this, {
      $alertingMitigation,
      $alerts,
      $app,
      $auth,
      $basicExplorer,
      $bgpDatasets,
      $clouds,
      $companySettings,
      $customApplications,
      $customDimensions,
      $dashboard,
      $dashboards,
      $dataviews,
      $devices,
      $deviceGroups,
      $dictionary,
      $explorer,
      $exports,
      $interfaceClass,
      $interfaces,
      $library,
      $lookups,
      $messages,
      $networkClassification,
      $notifications,
      $plans,
      $queryEditor,
      $queryTemplates,
      $rawFlow,
      $recentlyViewed,
      $savedFilters,
      $savedViews,
      $search,
      $sites,
      $subscriptions,
      $sudo,
      $tags,
      $usageStats,
      $users,
      $userGroups,
      $signup,
      $onboarding
    });

    $sudo.store = this;
  }

  /**
   * Common app initialization logic here, prevents duplication between login and AppWrapper's componentDidMount
   * function when user is already authenticated and login's logic after successful authentication.
   * @returns {Promise}
   */
  async initializeApp(options = {}) {
    await this.initialize(options);

    if (!options.minimal) {
      this.$alerts.alertCountSubscribe();
      this.$sudo.sudoSubscribe();
      this.$auth.socketSessionSubscribe();
    }

    // we don't want pt-dark on body until after auth so that it doesn't contaminate login/signup pages
    // since initializeApp depends on successful auth, we add it here instead of $app or AppWrapper
    if (this.$app.darkThemeEnabled && !document.body.classList.contains('pt-dark')) {
      document.body.classList.add('pt-dark');
    }
  }

  logout = async () => {
    const loggedOut = await $auth.logout();
    if (loggedOut) {
      this.destroy();
      new Socket().destroy();
      this.history.push('/');

      // manage pt-dark here so that the login/signup pages don't get contaminated
      if (document.body.classList.contains('pt-dark')) {
        document.body.classList.remove('pt-dark');
      }
    }
  };

  getStores() {
    return Object.keys(this).map(key => this[key]);
  }

  getCollections(store) {
    return Object.keys(store)
      .map(key => store[key])
      .filter(value => value instanceof Collection && value.reset);
  }

  setHistory(history) {
    this.history = history;
    this.getStores().forEach(store => (store.history = history));
  }

  @action
  initialize(options = {}) {
    const { minimal = false } = options;
    const allStores = this.getStores();
    const stores = minimal
      ? [$dictionary, $devices, $dataviews]
      : allStores.filter(store => store !== $dictionary && store !== $auth);

    allStores.forEach(store => (store.store = this));

    return $dictionary.initialize().then(
      action(() => {
        if (!minimal) {
          this.$auth.initializePendo();
        }

        return Promise.all(
          stores.map(store => {
            if (store.initialize) {
              return store.initialize();
            }

            return Promise.resolve(true);
          })
        );
      })
    );
  }

  @action
  destroy() {
    return Promise.all(
      this.getStores().map(store => {
        // Reset all collections in store
        this.getCollections(store).forEach(collection => collection.reset({ hard: true }));

        // Call destroy on store if present if more specific cleanup required.
        if (store.destroy) {
          return store.destroy();
        }

        return Promise.resolve(true);
      })
    );
  }
}

const store = new Store();

window.$store = store;

export default store;
