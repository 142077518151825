import { action, computed } from 'mobx';

import BaseModel from 'models/BaseModel';
import { getAggregates } from 'models/query/QueryModel';
import $dictionary from 'stores/$dictionary';

export function getBaseSorts(aggregateTypes, viz_type) {
  const { display_type_restrictions, logsumUnits } = $dictionary.dictionary;

  const baseSorts = getAggregates(aggregateTypes);
  const restrictions = display_type_restrictions[viz_type];
  const sortables = baseSorts.filter(
    sort => sort.sortable !== false && (!restrictions || restrictions.outsort.includes(sort.fn))
  );
  baseSorts.forEach(sort => {
    if (logsumUnits.includes(sort.unit) && !sortables.find(s => s.value === 'sum_logsum' && s.unit === sort.unit)) {
      sortables.push({ value: 'sum_logsum', column: 'kt_intell_order', fn: 'sum', unit: sort.unit });
    }
  });
  return sortables;
}

export function getVisualizationDepthLabel(depth) {
  let label = 'Very Low';

  if (depth >= 32) {
    label = 'Very High';
  } else if (depth >= 25) {
    label = 'High';
  } else if (depth >= 16) {
    label = 'Normal';
  } else if (depth >= 8) {
    label = 'Low';
  }

  return label;
}

export function getAllUnitsLegacyNames(hasKProbeSelected) {
  const { aggregates, unitsLegacy } = $dictionary.dictionary;

  const options = Object.keys(unitsLegacy).filter(
    unit =>
      ![
        'in_bytes',
        'out_bytes',
        'in_packets',
        'out_packets',
        'retransmits_in',
        'perc_retransmits_in',
        'repeated_retransmits',
        'perc_repeated_retransmits'
      ].includes(unit)
  );

  if (!hasKProbeSelected) {
    const layer7metrics = aggregates.TCP;

    return options.filter(
      option =>
        !Object.keys(layer7metrics).some(group =>
          layer7metrics[group].some(aggregate => option && unitsLegacy[option].includes(aggregate.value))
        )
    );
  }

  return options;
}

export function getUnitsLegacy(aggregateTypes, hasKProbeSelected) {
  const { unitsLegacy } = $dictionary.dictionary;
  const unitsLegacyNames = getAllUnitsLegacyNames(hasKProbeSelected);

  return (
    unitsLegacyNames.find(
      unitName =>
        unitsLegacy[unitName].every(aggType => aggregateTypes.includes(aggType)) &&
        aggregateTypes.every(aggType => unitsLegacy[unitName].includes(aggType))
    ) || ''
  );
}

export default class ExplorerQueryModel extends BaseModel {
  @computed
  get visualizationDepthLabel() {
    const depth = this.get('topx');
    return getVisualizationDepthLabel(depth);
  }

  @computed
  get numDevices() {
    const devices = this.get('device_name');
    const numDevices = devices && devices.length;
    return devices && `${numDevices} ${numDevices > 1 ? 'Devices' : 'Device'}`;
  }

  @computed
  get historicalOverlay() {
    const { overlay_day, show_overlay } = this.get();
    if (show_overlay) {
      const days = overlay_day * -1;
      return `${days} ${days > 1 ? 'Days' : 'Day'}`;
    }

    return 'Off';
  }

  @computed
  get numFilters() {
    const groups = this.get('filters_obj') && this.get('filters_obj').filterGroups;
    return (groups && groups.reduce((sum, group) => sum + group.filters.length, 0)) || 0;
  }

  serialize() {
    const query = this.attributes.toJS();

    // clean up cut fn configs
    Object.keys(query.cutFn).forEach(cutField => {
      if (!query.metric.includes(cutField)) {
        delete query.cutFn[cutField];
        delete query.cutFnRegex[cutField];
        delete query.cutFnSelector[cutField];
      }
    });

    // Take all the computed fields
    query.device_name = query.all_devices ? [] : query.device_name;
    query.device_labels = query.all_devices ? [] : query.device_labels;
    query.device_sites = query.all_devices ? [] : query.device_sites;
    query.device_types = query.all_devices ? [] : query.device_types;

    return query;
  }

  /*
   Note: this should be created from a QueryModel object always, parameter cannot be null
   */
  @action
  static createFromQueryModel(queryModel) {
    const serialized = queryModel.serialize();

    if (serialized.all_devices) {
      serialized.device_name = [];
    } else if (!Array.isArray(serialized.device_name)) {
      serialized.device_name = serialized.device_name.split(',');
    }

    if (serialized.filters_obj && !serialized.filters) {
      serialized.filters = serialized.filters_obj;
      delete serialized.filters_obj;
    }

    const explorerModel = new ExplorerQueryModel(serialized);

    // Remove 'Traffic' default from metrics
    let metric = queryModel.get('metric');
    if (metric.length === 1 && metric[0] === 'Traffic') {
      metric = [];
    }
    explorerModel.set({ metric });

    // Setup outsort
    const sortOptions = getBaseSorts(explorerModel.get('aggregateTypes'), explorerModel.get('viz_type'));
    const sortOption = sortOptions.find(sort => sort.value === queryModel.get('outsort'));
    if (sortOption) {
      explorerModel.set({ outsort: sortOption.value });
    } else {
      // console.warn('No aggregationType set when mapping queryModel to queryFormModel');
    }

    // Setup unitsLegacy
    const aggregateTypes = explorerModel.get('aggregateTypes');
    explorerModel.set({ unitsLegacy: getUnitsLegacy(aggregateTypes) });

    return explorerModel;
  }
}
