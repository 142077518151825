import React from 'react';

import $dictionary from 'stores/$dictionary';
import BaseModel from 'models/BaseModel';
import api from 'util/api';

import PopulatorCollection from '../populators/PopulatorCollection';

export default class CustomDimension extends BaseModel {
  populators = new PopulatorCollection([], { parent: this });

  constructor(attributes = {}) {
    super(attributes);

    if (attributes.populators) {
      this.populators.set(attributes.populators);
    }
  }

  get defaults() {
    return {
      type: 'string'
    };
  }

  get urlRoot() {
    return '/api/portal/customdimensions';
  }

  get omitDuringSerialize() {
    return ['populatorCount', 'populators'];
  }

  serialize(data) {
    const serialized = { ...data };
    return super.serialize(serialized);
  }

  deserialize(data = {}) {
    const dimension = data.customDimension;

    if (dimension && dimension.populators) {
      this.populators.set(dimension.populators);
    }

    return super.deserialize(dimension);
  }

  get messages() {
    return {
      create: `Dimension ${this.get('display_name')} was added successfully`,
      update: `Dimension ${this.get('display_name')} was updated successfully`,
      destroy: `Dimension ${this.get('display_name')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Dimension',
      text: (
        <p>
          Are you sure you want to remove <strong>{this.get('display_name')}?</strong> This action is not reversible.
        </p>
      )
    };
  }

  destroy() {
    return super.destroy().then(() => $dictionary.initialize());
  }

  static fetchByName(name, options = {}) {
    return api.get(`/api/portal/customdimensions/name/${name}`, options).then(response => {
      const dimension = new CustomDimension();
      dimension.set(dimension.deserialize(response));
      return dimension;
    });
  }
}
