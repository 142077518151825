import { generateFilterFields } from './helpers';

const options = {
  name: 'Dashboard Details',
  showPristineErrors: false
};

const fields = {
  dash_title: {
    label: 'name',
    rules: 'required',
    placeholder: 'eg. Sec-Ops Dashboard'
  },

  description: {
    label: 'description',
    rules: 'max:300',
    placeholder: 'A description for this dashboard'
  },

  category_id: {
    label: 'Category'
  },

  parametric: {
    label: 'Guided Mode',
    rules: 'boolean',
    defaultValue: false
  },

  parametric_fields: {
    isComplexArray: true
  },

  'parametric_fields[].type': {
    label: 'Dimension family to filter by',
    rules: 'required'
  },

  'parametric_fields[].value': {
    label: 'Value'
  },

  'parametric_fields[].label': {},
  'parametric_fields[].question': {
    label: 'Prompt text',
    rules: 'required'
  },

  parametric_value_type: {
    label: 'Input type',
    helpText: 'Allow the user to enter free-form values, or define a set of options to choose from.',
    defaultValue: 'freeform',
    options: [
      {
        value: 'freeform',
        label: 'Freeform'
      },
      {
        value: 'predefined',
        label: 'Predefined'
      }
    ]
  },

  parametric_value_options: {
    label: 'Predefined options',
    helpText:
      'User will be able to select an option from a dropdown. Use double-quotes for options that contain commas.',
    transform: {
      in: val =>
        val
          ? val.reduce((commaSepStr, entry) => {
              if (commaSepStr !== '') {
                commaSepStr += ',';
              }
              if (entry.includes(',')) {
                commaSepStr += `"${entry}"`;
              } else {
                commaSepStr += entry;
              }
              return commaSepStr;
            }, '')
          : '',
      out: val =>
        val
          ? val.split(val.includes('"') ? '"' : ',').reduce((arr, entry) => {
              entry = entry.trim();
              if (entry.startsWith(',') || entry.endsWith(',')) {
                entry.split(',').forEach(item => (item ? arr.push(item) : null));
              } else if (entry) {
                arr.push(entry);
              }
              return arr;
            }, [])
          : []
    }
  },

  share_level: {
    label: 'visible to',
    rules: 'required'
  },

  'query.all_devices': {
    label: 'Devices',
    rules: [
      {
        atLeastOneIfNotAllDevices: [
          'query.device_name',
          'query.device_types',
          'query.device_sites',
          'query.device_labels'
        ]
      }
    ],
    defaultValue: true
  },

  'query.device_name': {
    label: 'devices',
    defaultValue: []
  },

  'query.device_types': {
    defaultValue: []
  },

  'query.device_sites': {
    defaultValue: []
  },

  'query.device_labels': {
    defaultValue: []
  },

  'query.lookback_seconds': {
    label: 'Time Range',
    defaultValue: 3600
  },

  panel_title: {
    label: 'Title'
  },

  parametric_mode: {
    label: 'Guided Mode',
    defaultValue: 'add_group',
    options: [
      {
        label: 'Override all filters',
        value: 'override_all'
      },
      {
        label: 'Override specific filters',
        value: 'override_specific'
      },
      {
        label: 'Add filter group',
        value: 'add_group'
      },
      {
        label: 'Ignore',
        value: 'ignore'
      }
    ]
  },

  'parametric_overrides.filterField': {
    label: 'Select Filter to Override'
  },

  'parametric_overrides.operator': {
    label: 'Operator'
  },

  ...generateFilterFields('query.filters')
};

export { fields, options };
