import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { DimensionSelector, Field, Input } from 'components/forms';
import { Flex } from 'components/flexbox';
import { flowColumns, orderByColumns } from './flowColumns';

@inject('$devices')
@observer
class Edit extends Component {
  state = {
    collapsedDimensions: {},
    collapsedOrderBy: {}
  };

  componentWillMount() {
    this.updateCollapsedDimensions(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { disabledDimensions } = this.props;
    if (nextProps.disabledDimensions.length !== disabledDimensions.length) {
      this.updateCollapsedDimensions(nextProps);
    }
  }

  updateCollapsedDimensions(props) {
    const collapsedDimensions = {};
    const { $devices } = this.props;

    if (!$devices.hasDnsProbe) {
      collapsedDimensions['Layer 7'] = true;
    }
    props.disabledDimensions.forEach(dimension => {
      if (!dimension.includes('_')) {
        collapsedDimensions[dimension] = true;
      }
    });

    this.setState(prevState => ({ collapsedDimensions: { ...prevState.collapsedDimensions, ...collapsedDimensions } }));
  }

  handleDimensionSectionCollapse = group => {
    const { collapsedDimensions } = this.state;
    const newDimensions = { ...collapsedDimensions };
    newDimensions[group] = !collapsedDimensions[group];
    this.setState({ collapsedDimensions: newDimensions });
  };

  handleOrderBySectionCollapse = group => {
    const { collapsedOrderBy } = this.state;
    const newDimensions = { ...collapsedOrderBy };
    newDimensions[group] = !collapsedOrderBy[group];
    this.setState({ collapsedOrderBy: newDimensions });
  };

  render() {
    const { collapsedDimensions, collapsedOrderBy } = this.state;
    const { disabledDimensions } = this.props;
    const disabledValues = ['Traffic', ...disabledDimensions];

    return (
      <div>
        <DimensionSelector
          options={flowColumns()}
          onCollapse={this.handleDimensionSectionCollapse}
          collapsedState={collapsedDimensions}
          disabledValues={disabledValues}
          className="pt-small"
          selectButtonClassName="pt-minimal pt-small pt-intent-primary"
          showClearButton
          fieldName="flow_fields"
        />
        <hr />
        <label className="pt-label">Order By</label>
        <Flex flexAuto>
          <DimensionSelector
            options={orderByColumns()}
            onCollapse={this.handleOrderBySectionCollapse}
            collapsedState={collapsedOrderBy}
            disabledValues={disabledValues}
            className="no-margin filter-item-field"
            showLabel={false}
            fieldName="order_by"
            multi={false}
            isPopover
          />
        </Flex>
        <hr />
        <Field name="row_count">
          <Input className="pt-small" />
        </Field>
      </div>
    );
  }
}

const Display = observer(({ disabledDimensions }) => {
  const disabledValues = ['Traffic', ...disabledDimensions];

  return (
    <div>
      <DimensionSelector
        options={flowColumns()}
        disabledValues={disabledValues}
        className="pt-small"
        selectButtonClassName="pt-minimal pt-small pt-intent-primary"
        showClearButton
        fieldName="flow_fields"
        readOnly
      />
      <hr />
      <label className="pt-label">Order By</label>
      <Flex flexAuto>
        <DimensionSelector
          options={orderByColumns()}
          disabledValues={disabledValues}
          className="no-margin filter-item-field"
          showLabel={false}
          fieldName="order_by"
          multi={false}
          isPopover
          readOnly
        />
      </Flex>
      <hr />
      <Field name="row_count">
        <Input className="pt-small" />
      </Field>
    </div>
  );
});

export default { Edit, Display };
