import React from 'react';
import { action, computed } from 'mobx';
import { safelyParseJSON } from 'util/utils';
import BaseModel from '../BaseModel';

class Notification extends BaseModel {
  get defaults() {
    return {
      channel_type: 'email'
    };
  }

  get omitDuringSerialize() {
    return ['syslog', 'opsgenie', 'slack'];
  }

  @computed
  get icon() {
    const iconMap = {
      syslog: 'comparison',
      json: 'code',
      slack: 'chat',
      opsgenie: 'opsgenie',
      pagerduty: 'pagerduty',
      servicenow: 'power'
    };

    return iconMap[this.get('channel_type')] || 'envelope';
  }

  get removalConfirmText() {
    return {
      text: (
        <p>
          Are you sure you want to remove <strong>{this.get('notification_name')}</strong>? This action is not
          reversible.
        </p>
      )
    };
  }

  serialize(data) {
    if (data.servicenow) {
      delete data.servicenow;
    }

    return super.serialize(data);
  }

  deserialize(data = {}) {
    if (data.channel_type === 'servicenow') {
      data.servicenow = JSON.parse(data.notification_value);
    } else if (data.channel_type === 'opsgenie') {
      data.opsgenie = safelyParseJSON(data.notification_value);
    } else if (data.channel_type === 'syslog') {
      data.syslog = JSON.parse(data.notification_value);
    } else if (data.channel_type === 'slack') {
      data.slack = JSON.parse(data.notification_value);
    }

    return super.deserialize(data);
  }

  @action
  async save(values) {
    if (values.channel_type !== 'slack' || values.slack.manualOverride) {
      if (values.channel_type === 'syslog') {
        values.notification_value = JSON.stringify(values.syslog);
      } else if (values.channel_type === 'servicenow') {
        // Only update user/password if there are new values.
        if (values.servicenow.user === '') {
          delete values.servicenow.user;
        }

        if (values.servicenow.password === '') {
          delete values.servicenow.password;
        }

        values.notification_value = JSON.stringify(values.servicenow);
      } else if (values.channel_type === 'opsgenie') {
        values.notification_value = JSON.stringify(values.opsgenie);
      } else if (values.channel_type === 'slack') {
        values.notification_value = JSON.stringify(values.slack);
      }

      return super.save(values).then(response => {
        this.set({ syslog: values.syslog });
        return response;
      });
    }

    // TODO: This URL needs to exist on the server
    const urlParams = {
      name: values.notification_name,
      userGroup: values.user_group_id,
      receiveTenantNotifications: values.config.receiveTenantNotifications
    };
    const url = `/api/portal/slack/ncwebhook?${Object.keys(urlParams)
      .map(key => `${key}=${encodeURIComponent(urlParams[key])}`)
      .join('&')}`;

    window.location.assign(url);
    return null;
  }
}

export default Notification;
