import { computed } from 'mobx';
import { Intent } from '@blueprintjs/core';
import $dictionary from 'stores/$dictionary';
import BaseModel from '../BaseModel';

export const ALARM_SEVERITY_INTENT = {
  critical: 'pt-intent-critical',
  minor: 'pt-intent-warning',
  minor2: 'pt-intent-warning',
  major: 'pt-intent-danger',
  major2: 'pt-intent-danger',
  ack_req: 'pt-intent-primary',
  Radware1_PAL: 'pt-intent-primary'
};

export const ALARM_SEVERITY_CLASSNAME = {
  critical: 'pt-intent-critical-text',
  minor: 'pt-intent-warning-text',
  minor2: 'pt-intent-warning-text',
  major: 'pt-intent-danger-text',
  major2: 'pt-intent-danger-text',
  ack_req: 'pt-intent-primary-text',
  Radware1_PAL: 'pt-intent-primary-text'
};

export const ALARM_SEVERITY_DISPLAY = {
  critical: 'Critical',
  minor: 'Minor',
  minor2: 'Minor2',
  major: 'Major',
  major2: 'Major2',
  ack_req: 'ACK Req'
};

export const ALERT_STATE_PROPS = {
  ALARM: {
    iconName: 'notifications',
    intent: Intent.WARNING,
    className: 'pt-intent-warning'
  },
  Mitigation: {
    iconName: 'security',
    // no Intent because purple is a custom thing.
    className: 'pt-intent-purple',
    materialIcon: true
  },
  ACK_REQ: {
    iconName: 'endorsed',
    intent: Intent.PRIMARY,
    className: 'pt-intent-primary'
  },
  CLEAR: {
    iconName: 'tick',
    intent: Intent.SUCCESS,
    className: 'pt-intent-success'
  },
  ROUGE_MITIGATING_FAIL: {
    iconName: 'cross',
    className: 'pt-intent-danger'
  }
};

class Alarm extends BaseModel {
  get url() {
    if (this.isNew) {
      return this.urlRoot;
    }

    return `${this.urlRoot}/${this.get('id')}`;
  }

  get urlRoot() {
    return '/api/portal/alerts-active/alarms';
  }

  @computed
  get isActiveMitigation() {
    return this.get('row_type') === 'Mitigation' && this.get('alarm_state') !== 'ACK_REQ';
  }

  @computed
  get isAckReq() {
    return this.get('alarm_state') === 'ACK_REQ';
  }

  @computed
  get severityClassName() {
    const alert_severity = this.get('alert_severity');
    const row_type = this.get('row_type');

    let className = ALARM_SEVERITY_INTENT[alert_severity];
    if (this.isAckReq) {
      className = 'pt-intent-primary';
    } else if (row_type === 'Mitigation') {
      className = 'pt-intent-purple';
    }

    return className;
  }

  @computed
  get isCritical() {
    return this.get('alert_severity') === 'critical';
  }

  // 'Critical', 'Severe', and the like.
  @computed
  get severityDisplay() {
    const alert_severity = this.get('alert_severity');
    const row_type = this.get('row_type');
    return row_type === 'Mitigation' ? 'MITIGATION' : ALARM_SEVERITY_DISPLAY[alert_severity];
  }

  @computed
  get stateDisplay() {
    const { stateLabels } = $dictionary.dictionary.mitigations;
    const alarm_state = this.get('alarm_state');
    return stateLabels[alarm_state] || alarm_state;
  }

  @computed
  get severityTextClass() {
    const alert_severity = this.get('alert_severity');
    const row_type = this.get('row_type');
    return row_type === 'Mitigation' ? 'pt-intent-purple-text' : ALARM_SEVERITY_CLASSNAME[alert_severity];
  }

  @computed
  get tagDisplayProps() {
    const alarm_state = this.get('alarm_state');
    const row_type = this.get('row_type');
    if (alarm_state === 'ACK_REQ') {
      return ALERT_STATE_PROPS.ACK_REQ;
    }

    return (
      ALERT_STATE_PROPS[row_type] ||
      ALERT_STATE_PROPS[alarm_state] || {
        iconName: 'tick',
        intent: Intent.SUCCESS,
        className: 'pt-intent-success'
      }
    );
  }

  getAlarmHistoryTagDisplayProps(state) {
    const row_type = this.get('row_type');
    if (state === 'ACK_REQ') {
      return ALERT_STATE_PROPS.ACK_REQ;
    }

    return (
      ALERT_STATE_PROPS[row_type] ||
      ALERT_STATE_PROPS[state] || {
        iconName: 'tick',
        intent: Intent.SUCCESS,
        className: 'pt-intent-success'
      }
    );
  }

  @computed
  get oldStateDisplay() {
    const { stateLabels } = $dictionary.dictionary.mitigations;
    const old_alarm_state = this.get('old_alarm_state');
    return stateLabels[old_alarm_state] || old_alarm_state;
  }

  @computed
  get newStateDisplay() {
    const { stateLabels } = $dictionary.dictionary.mitigations;
    const new_alarm_state = this.get('new_alarm_state');
    return stateLabels[new_alarm_state] || new_alarm_state;
  }

  @computed
  get isManualMitigation() {
    const old_alarm_state = this.get('old_alarm_state') || '';
    const new_alarm_state = this.get('new_alarm_state') || '';
    const alarm_state = this.get('alarm_state') || '';

    return (
      old_alarm_state.includes('MANUAL_') || new_alarm_state.includes('MANUAL_') || alarm_state.includes('MANUAL_')
    );
  }
}

export default Alarm;
