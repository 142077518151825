import { Address4, Address6 } from 'ip-address';
import { privateIpOptions } from 'util/constants';

const privateSubnets = privateIpOptions.reduce((list, opt) => {
  list.push(
    ...opt.value.split(',').map(subnet => {
      let addr = new Address4(subnet.trim());
      if (addr.isValid()) {
        return addr;
      }
      addr = new Address6(subnet.trim());
      if (addr.isValid()) {
        return addr;
      }
      return null;
    })
  );
  return list;
}, []);

function isPrivate(address) {
  return privateSubnets.reduce((res, privateSubnet) => res || address.isInSubnet(privateSubnet), false);
}

function fn(value) {
  if (value) {
    const v4 = new Address4(value);
    const v6 = new Address6(value);
    const v4NoPort = new Address4(value.substr(0, value.lastIndexOf(':')));
    const v6NoPort = new Address6(value.substr(0, value.lastIndexOf(':')));
    return (
      (v4.isValid() && !isPrivate(v4)) ||
      (v4NoPort.isValid() && !isPrivate(v4NoPort)) ||
      (v6.isValid() && !isPrivate(v6)) ||
      (v6NoPort.isValid() && !isPrivate(v6NoPort))
    );
  }

  return true;
}

export default {
  message: 'The provided IP address must be publicly accessible.',
  fn
};
