import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { isEqual } from 'lodash';
import { Callout, Intent, Spinner } from '@blueprintjs/core';
import { Field, Input, Select, Switch, Textarea } from 'components/forms';
import { Box, Flex } from 'components/flexbox';
import { tetherOptions } from 'forms/config/deviceDetails';
import { PROVIDERS } from 'forms/config/cloudExportDetails';
import CloudHelpSection from './CloudHelpSection';
import Azure from './Form/Azure';

const MENU_WIDTH = 400;

@inject('$clouds', '$dictionary', '$plans', '$devices', '$auth')
@observer
export default class CloudExportForm extends Component {
  static contextTypes = {
    form: any
  };

  state = {
    awsMessageCode: null
  };

  componentDidMount() {
    const { $clouds, $devices, $plans } = this.props;
    const { form } = this.context;

    $plans.collection.fetch().then(() => {
      form.validate();
    });
    $devices.fetchMasterBGPDevices();
    $clouds.init();

    this.handleAwsChecker();

    const applyBGPField = form.getField('properties.apply_bgp');
    this.onApplyBGPChange(applyBGPField, applyBGPField.getValue());
  }

  regionOptionRenderer = option => {
    const { className, label, value, field, selectItem, selected, disabled } = option;
    const onClick = !selected && !disabled ? () => selectItem(field, value) : undefined;

    return (
      <div key={value} className={className} onClick={onClick}>
        {label} <span className="pt-text-muted">{value}</span>
      </div>
    );
  };

  regionValueRenderer = (option, placeholder) => {
    if (!option) {
      return <span className="pt-text-muted">{placeholder || 'Select a value...'}</span>;
    }

    const { label, value } = option;
    return (
      <div>
        {label} <span className="pt-text-muted">{value}</span>
      </div>
    );
  };

  handleAwsChecker = () => {
    const { form } = this.context;
    const regionField = form.getField('properties.aws_region');
    const bucketField = form.getField('properties.aws_bucket');
    const roleField = form.getField('properties.aws_iam_role_arn');
    const hasError = regionField.hasError || bucketField.hasError || roleField.hasError;
    const isAws = form.getField('cloud_provider').getValue() === PROVIDERS.AWS.id;
    const data = {
      bucket: bucketField.getValue(),
      region: regionField.getValue(),
      role: roleField.getValue()
    };

    if (isAws && !hasError && !isEqual(this.lastAwsCheckData, data)) {
      this.setState({ awsMessageCode: null });
      this.lastAwsCheckData = data;

      this.props.$clouds.checkAws(data).then(({ errorCode }) => {
        this.setState({ awsMessageCode: errorCode || 'Success' });

        if (errorCode) {
          // Even if the combination of region, bucket, and role is invalid at this point
          // we still want the user to be able to save the config and perhaps make any relevant
          // adjustments on the aws side. Here we set the export task enabled state to false
          // and later on in render, we'll also disable that field entirely since the aws check failed.
          form.getField('enabled').setValue(false);
        }
      });
    }
  };

  /**
   * We generally need to support three aws checker error codes: NoSuchBucket, BucketRegionError and the fallback
   * AccessDenied. During testing we did occasionally get back AuthorizationHeaderMalformed and AllAccessDisabled
   * but they will leverage the fallback for now.
   */
  get awsError() {
    const { awsMessageCode } = this.state;
    const isSuccess = awsMessageCode === 'Success';
    const config = {
      AccessDenied: (
        <Fragment>
          {`AWS is unable to match the provided bucket name and region with the provided role ARN. Please double-check
          the provided information (see our Knowledge Base topics `}
          <a
            className="pt-link-minimal pt-intent-danger"
            href="https://kb.kentik.com/Fc14.htm#Fc14-Create_an_AWS_Role"
            rel="noopener noreferrer"
            target="_blank"
          >
            Create an AWS Role
          </a>
          {' and '}
          <a
            className="pt-link-minimal pt-intent-danger"
            href="https://kb.kentik.com/Fc14.htm#Fc14-Create_an_S3_Bucket"
            rel="noopener noreferrer"
            target="_blank"
          >
            Create an S3 Bucket
          </a>
          ).
        </Fragment>
      ),
      NoSuchBucket: (
        <Fragment>
          {`AWS is unable to find a bucket with the name provided below. Please double-check the bucket name (see our
          Knowledge Base topic `}
          <a
            className="pt-link-minimal pt-intent-danger"
            href="https://kb.kentik.com/Fc14.htm#Fc14-Create_an_S3_Bucket"
            rel="noopener noreferrer"
            target="_blank"
          >
            Create an S3 Bucket
          </a>
          ).
        </Fragment>
      ),
      BucketRegionError: (
        <Fragment>
          {`AWS is unable to find a bucket with the provided name in the specified region. Please double-check the bucket
          name and region (see our Knowledge Base topic `}
          <a
            className="pt-link-minimal pt-intent-danger"
            href="https://kb.kentik.com/Fc14.htm#Fc14-Create_an_S3_Bucket"
            rel="noopener noreferrer"
            target="_blank"
          >
            Create an S3 Bucket
          </a>
          ).
        </Fragment>
      ),
      Success: 'Validation succeeded.'
    };

    if (!awsMessageCode) {
      return null;
    }

    return (
      <p
        className={`pt-form-helper-text pt-text-small pt-intent-${isSuccess ? 'success' : 'danger'}-text`}
        style={{ lineHeight: 'unset' }}
      >
        {config[awsMessageCode] || config.AccessDenied}
      </p>
    );
  }

  onApplyBGPChange = (field, value) => {
    const { form } = this.context;
    const {
      $devices: { masterBGPDevices }
    } = this.props;

    form.getField('properties.use_bgp_device_id').setRules(value && masterBGPDevices.length ? 'required' : '');
  };

  renderExportTaskProperties = () => {
    const { form } = this.context;
    const {
      $clouds: { isAwsCheckLoading }
    } = this.props;
    const gceProjectField = form.getField('properties.gce_project');
    const gceSubscriptionField = form.getField('properties.gce_subscription');
    const awsRegionField = form.getField('properties.aws_region');
    const awsBucketField = form.getField('properties.aws_bucket');
    const awsIAMRoleField = form.getField('properties.aws_iam_role_arn');

    const config = {
      [PROVIDERS.GCE.id]: (
        <Fragment>
          <Field name="properties.gce_project" key="gce_project" rules={gceProjectField.providerRules}>
            <Input />
          </Field>
          <Field name="properties.gce_subscription" key="gce_subscription" rules={gceSubscriptionField.providerRules}>
            <Input />
          </Field>
        </Fragment>
      ),
      [PROVIDERS.AWS.id]: (
        <Fragment>
          {!this.lastAwsCheckData && (
            <p className="pt-text-muted" style={{ fontSize: 12 }}>
              We'll check to ensure that the region, bucket, and role you enter are valid.
            </p>
          )}
          {isAwsCheckLoading && (
            <Flex align="center" mb={1}>
              <Spinner className="pt-small" />
              <span className="pt-text-muted pt-text-small">Checking AWS configuration...</span>
            </Flex>
          )}
          {this.awsError}
          <Field
            inputStyle={{ width: MENU_WIDTH }}
            key="aws_region"
            name="properties.aws_region"
            onChange={this.handleAwsChecker}
            rules={awsRegionField.providerRules}
          >
            <Select
              menuWidth={MENU_WIDTH}
              optionRenderer={this.regionOptionRenderer}
              tetherOptions={tetherOptions}
              valueRenderer={this.regionValueRenderer}
            />
          </Field>
          <Field name="properties.aws_bucket" key="aws_bucket" rules={awsBucketField.providerRules}>
            <Input onBlur={this.handleAwsChecker} />
          </Field>
          <Field name="properties.aws_iam_role_arn" key="aws_iam_role_arn" rules={awsIAMRoleField.providerRules}>
            <Input onBlur={this.handleAwsChecker} />
          </Field>
          <Field name="properties.aws_delete_after_read" key="aws_delete_after_read" showLabel={false}>
            <Switch switchLabel={<label className="pt-label">Delete After Read</label>} />
          </Field>
        </Fragment>
      ),
      [PROVIDERS.AZURE.id]: (
        <Azure />
        // <Fragment>
        //   <Box className="pt-card" p={2}>
        //     <Field name="properties.azure_subscription_id" key="azure_subscription_id" rules="required">
        //       <Input />
        //     </Field>
        //     <Button text="Enable" intent={Intent.PRIMARY} onClick={this.onEnableAzureSubscriptionClick} />
        //   </Box>
        //   {form.getValue('properties.azure_security_principal_enabled') && (
        //     <Box className="pt-card" mt={2} p={2}>
        //       <Field name="properties.azure_resource_group" key="azure_resource_group" rules="required">
        //         <Input />
        //       </Field>
        //       <Field name="properties.azure_location" key="azure_location" rules="required">
        //         <Select />
        //       </Field>
        //       <Field name="properties.azure_storage_account" key="azure_storage_account" rules="required">
        //         <Input />
        //       </Field>
        //       <Flex justify="space-between">
        //         <Button text="Configure Manually" />
        //         <Button text="Configure using PowerShell" />
        //       </Flex>
        //     </Box>
        //   )}
        // </Fragment>
      )
    };

    return config[form.getValue('cloud_provider')];
  };

  render() {
    const { form } = this.context;
    const {
      $clouds: { isAwsValid },
      $dictionary,
      $plans,
      $devices: { masterBGPDevices }
    } = this.props;
    const helpText = $dictionary.getTooltips('admin.device.deviceDetails');
    const provider = form.getField('cloud_provider').getValue();
    const showBGPSettings = form.getValue('properties.apply_bgp');
    const availableCloudPlanOptions = $plans.availableCloudPlanOptions(provider);

    let intent = isAwsValid ? Intent.SUCCESS : Intent.DANGER;

    if (isAwsValid === null || provider === PROVIDERS.GCE.id) {
      intent = Intent.NONE;
    }

    return (
      <Flex>
        <Box flexAuto style={{ minWidth: 500 }}>
          <Field name="name">
            <Input />
          </Field>
          <Field name="properties.description">
            <Textarea />
          </Field>
          <Field
            name="plan_id"
            disabled={!availableCloudPlanOptions.length}
            helpText={
              availableCloudPlanOptions.length
                ? helpText.plan
                : 'There are no available device slots in your plans. Please contact sales@kentik.com to add more devices.'
            }
            options={availableCloudPlanOptions}
            inputStyle={{ width: MENU_WIDTH }}
          >
            <Select menuWidth={MENU_WIDTH} tetherOptions={tetherOptions} />
          </Field>
          <Field name="enabled" showLabel={false} disabled={isAwsValid === false}>
            <Switch switchLabel={<label className="pt-label">Enabled</label>} />
          </Field>

          <Callout intent={intent} title={`${PROVIDERS.byId(provider).code} Provider Settings`} style={{ padding: 16 }}>
            {this.renderExportTaskProperties()}
          </Callout>

          <Callout title="BGP Settings" style={{ marginTop: 16 }}>
            <Field
              name="properties.apply_bgp"
              showLabel={false}
              style={{ marginTop: 16 }}
              disabled={!masterBGPDevices.length}
              className={!showBGPSettings && 'no-margin'}
              onChange={this.onApplyBGPChange}
            >
              <Switch switchLabel={<label className="pt-label">Inherit BGP data from another device</label>} />
            </Field>
            {masterBGPDevices.length === 0 && (
              <Callout intent={Intent.WARNING} style={{ marginTop: 8 }}>
                Cloud data sources can inherit BGP information from other devices in your account. Please enable direct
                BGP peering with a router to get started.
              </Callout>
            )}
            {showBGPSettings && (
              <Field
                name="properties.use_bgp_device_id"
                className="no-margin"
                inputStyle={{ width: 300 }}
                options={masterBGPDevices}
              >
                <Select menuWidth={300} tetherOptions={tetherOptions} />
              </Field>
            )}
          </Callout>
        </Box>
        {<CloudHelpSection cloudProvider={provider} />}
      </Flex>
    );
  }
}
