import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Box } from 'components/flexbox';
import FilterItem from './FilterItem';

@observer
class FilterGroup extends Component {
  render() {
    const { connector: groupConnector, group, isLastGroup } = this.props;

    const shouldExcludeResults = group.not;

    // between each Filter.
    const filterConnector = group.connector;

    return (
      <Box>
        <Box mb={isLastGroup ? 0 : 0.5} className="pt-callout">
          <Box mb={0.5}>
            <small>
              <strong
                className={classNames({
                  'pt-intent-success-text': !shouldExcludeResults,
                  'pt-intent-danger-text': shouldExcludeResults
                })}
              >
                {' '}
                {shouldExcludeResults ? 'Exclude' : 'Include'}{' '}
              </strong>
              results matching <div className="pt-tag pt-strong pt-small">{filterConnector.toUpperCase()}</div>
            </small>
          </Box>
          <Box ml={1}>
            {group.filters &&
              group.filters.map((filter, index) => {
                const key = `${filter.filterField}.${filter.operator}.${filter.filterValue}`;
                return <FilterItem key={key} isLast={index === group.filters.length - 1} {...filter} />;
              })}
          </Box>
          {group.filterGroups &&
            group.filterGroups.length > 0 && (
              <Box className="filter-groups nested" p={1} mt={1} pb={0}>
                {group.filterGroups.map((nestedGroup, index) => (
                  <FilterGroup
                    key={nestedGroup.connector._id}
                    group={nestedGroup}
                    index={index}
                    connector={group.connector}
                    isLastGroup={index === group.filterGroups.length - 1}
                  />
                ))}
              </Box>
            )}
        </Box>
        {!isLastGroup && (
          <Box mb={0.5} ml={1}>
            <small className="pt-strong pt-text-muted">{groupConnector === 'All' ? 'AND' : 'OR'}</small>
          </Box>
        )}
      </Box>
    );
  }
}

export default FilterGroup;
