import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { Field, Input, Switch } from 'components/forms';
import FilterOptions, { addFilterGroup } from 'components/FilterOptions/FilterOptions';

import DimensionSelector from './DimensionSelector';
import ExplorerDimensionMultiValuesRenderer from './ExplorerDimensionMultiValuesRenderer';

function clearGroupRules(group) {
  group.filters.forEach(filter => filter.filterValue.setRules([], { validate: false }));

  if (group.filterGroups) {
    group.filterGroups.forEach(clearGroupRules);
  }
}

function resetGroupRules(group, getFilterValueValidator) {
  group.filters.forEach(filter => {
    filter.filterValue.setRules(getFilterValueValidator(filter.filterField.getValue()), { validate: false });
  });

  if (group.filterGroups) {
    group.filterGroups.forEach(filterGroup => resetGroupRules(filterGroup, getFilterValueValidator));
  }
}

@inject('$dictionary')
@observer
class ExplorerDimensionSelector extends Component {
  static contextTypes = {
    form: any
  };

  onFilterDimensionsToggled = (field, value) => {
    const { form } = this.context;

    const filterDimensionGroups = form.getField('filterDimensions.filterGroups');

    if (value) {
      if (filterDimensionGroups.size() === 0) {
        const filterGroups = form.getField('filterDimensions.filterGroups');

        addFilterGroup(filterGroups, { named: true });
      }

      // Reset all of the rules on the filterValue fields to what they need to be
      const { getFilterValueValidator } = this.props.$dictionary;
      filterDimensionGroups.forEach(group => resetGroupRules(group, getFilterValueValidator));
    } else {
      // Because of dynamic field rules on filterValue we need to clear the rules
      filterDimensionGroups.forEach(clearGroupRules);
    }

    form.validate();
  };

  render() {
    const { form } = this.context;
    const { disabledValues } = this.props;
    const filterDimensionsEnabled = form.getValue('filterDimensionsEnabled');
    const filterDimensionsField = form.getField('filterDimensions.filterGroups');
    const filterDimensionNameField = form.getField('filterDimensionName');
    const hasFilterDimensionError = filterDimensionsField.hasError || filterDimensionNameField.hasError;

    const tabName = 'Preset';
    const tabs = (
      <Flex
        key="split-filters"
        tab={
          <Fragment>
            Filter-Based{' '}
            <span className="pt-tag pt-small pt-intent-warning pt-round" style={{ marginLeft: 4 }}>
              New
            </span>
          </Fragment>
        }
        tabId="split-filters"
        flexColumn
        flexAuto
        className="overflow-auto modal-select-tab"
        p={2}
      >
        <Box mb={2} p={2} className="pt-callout pt-intent-primary">
          <p>
            A filter-based dimension is a set of independent series that are each based on complex filter criteria.
            Settings made on this tab will always overwrite all currently selected preset dimensions.
          </p>
          <Field name="filterDimensionsEnabled" onChange={this.onFilterDimensionsToggled}>
            <Switch className="pt-large" switchLabel="Enable filter-based dimension" />
          </Field>
          <Field name="filterDimensionName" disabled={!filterDimensionsEnabled} inputStyle={{ width: 300 }}>
            <Input className="pt-large" />
          </Field>
          <Field name="filterDimensionOther" disabled={!filterDimensionsEnabled} style={{ width: 300 }}>
            <Switch switchLabel="Auto-Add &quot;Other&quot; Series" />
          </Field>
        </Box>
        <FilterOptions
          fieldName="filterDimensions"
          showSaveFiltersButton={false}
          disabled={!filterDimensionsEnabled}
          disabledDimensions={disabledValues}
          showNames
          allowNestedFilters
          flat
          groupLabel="Series"
          groupLabelPlural="Series"
          filterGroupLabel="series"
          filterGroupLabelPlural="series"
          adHocLabel="Series"
          showTopLevelConnector={false}
          required={filterDimensionsEnabled}
        />
      </Flex>
    );

    return (
      <Fragment>
        <DimensionSelector
          {...this.props}
          tabName={tabName}
          tabs={tabs}
          isTabbed
          mainTabDisabled={filterDimensionsEnabled}
          multiValuesRenderer={ExplorerDimensionMultiValuesRenderer}
        />
        {hasFilterDimensionError && (
          <Box className="pt-form-group" mt={-2} mb={2}>
            <span className="pt-form-helper-text pt-intent-danger">Filter Dimension is not configured correctly</span>
          </Box>
        )}
      </Fragment>
    );
  }
}

export default ExplorerDimensionSelector;
