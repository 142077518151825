import BaseModel from 'models/BaseModel';

export default class Otp extends BaseModel {
  get defaults() {
    return {
      name: 'Default',
      type: 'yubi',
      enabled: true
    };
  }
}
