import { action, observable, computed } from 'mobx';

import InterfacesDeviceCollection from 'models/interfaces/InterfacesDeviceCollection';
import InterfaceCollection from 'models/interfaces/InterfaceCollection';
import InterfaceSettings from 'models/interfaces/InterfaceSettings';
import RuleCollection from 'models/interfaces/RuleCollection';
import RuleState from 'models/interfaces/RuleState';
import api from 'util/api';

class InterfaceClassStore {
  // Basic UI state flags
  @observable
  isConfigureIcDialogOpen = false;

  @observable
  isUnclassifiedInterfacesDialogOpen = false;

  @observable
  isRuleEvaluationRequired = false;

  @observable
  isClassificationDataReady = false;

  // Current Rules State (as represented on server)
  ruleState;

  @observable
  ruleCollection = new RuleCollection();

  @observable
  deviceCollection = new InterfacesDeviceCollection();

  // Active Rule Test State (not yet persisted stuff)
  ruleTestState;

  @observable
  testResultsInterfaceCollection = new InterfaceCollection();

  @observable
  testResultsDeviceCollection = new InterfacesDeviceCollection();

  @observable
  settings = new InterfaceSettings();

  @observable
  allUnclassifiedInterfacesCollection = new InterfaceCollection({}, { groupBy: 'device_name' });

  @action
  initialize() {
    this.allUnclassifiedInterfacesCollection.hasFetched = true;

    this.ruleState = new RuleState({
      onSuccess: this.handleSuccess,
      deviceCollection: this.deviceCollection,
      ruleCollection: this.ruleCollection,
      settings: this.settings
    });

    this.ruleTestState = new RuleState({
      deviceCollection: this.testResultsDeviceCollection,
      settings: this.settings
    });
  }

  @action
  initClassificationCollections(deviceId) {
    this.isClassificationDataReady = false;

    Promise.all([this.ruleCollection.fetch(), this.settings.fetch(), this.deviceCollection.fetch()]).then(
      ([, , devices]) => {
        this.testResultsDeviceCollection.set(this.testResultsDeviceCollection.deserialize(devices));

        this.ruleCollection
          .getRuleMatchResults()
          .then(results => {
            if (results.length === 0) {
              this.evaluateRules();
            } else {
              this.loadExistingRuleResults(results);
            }
            this.deviceCollection.sort();
            this.isClassificationDataReady = true;
          })
          .then(() => deviceId && this.toggleDeviceInterfaceDetails(this.deviceCollection.get(deviceId)));
      }
    );
  }

  @action
  setAllUnclassifiedInterfaces() {
    // decorate results with device_name
    const results = this.ruleState.evaluationResults.map(result => ({
      ...result,
      device_name: this.deviceCollection.deviceIdNameHash[result.device_id]
    }));

    const resultInterfaces = results.map(ruleResult => {
      const { device_name, device_id, interfaces } = ruleResult;

      return interfaces.map(intf => ({ ...intf, device_name, device_id }));
    });

    // just flatten it out.
    const flat = resultInterfaces.reduce((a, b) => a.concat(...b), []);

    // and return unclassified stuff.
    const allUnclassifiedInterfaces = flat.filter(
      intf => !intf.rules_matched || intf.rules_matched.every(rule => !rule.didClassify)
    );

    this.allUnclassifiedInterfacesCollection.set(allUnclassifiedInterfaces);
  }

  @action
  resetRuleStates() {
    this.isClassificationDataReady = false;
    this.ruleCollection.reset();
    this.deviceCollection.reset();
    this.testResultsDeviceCollection.reset();
    this.ruleState.reset();
  }

  @action
  resetRuleTestStates() {
    this.testResultsDeviceCollection.clearSelection();
    this.testResultsInterfaceCollection.reset();
    this.ruleTestState.reset();
  }

  @action
  loadExistingRuleResults(results) {
    results.forEach(device => {
      if (Object.keys(device.results).length > 0) {
        this.ruleState.addEvaluationResult(device.results);
      }
    });
    this.deviceCollection.updateDeviceInterfaceMatchData(this.ruleState.deviceMatches);
    this.ruleCollection.updateRuleInterfaceMatchData(this.ruleState.ruleInterfaceMatches);
    this.setAllUnclassifiedInterfaces();

    this.ruleState.setEvaluationComplete(true);
  }

  @action
  toggleDeviceInterfaceDetails(device) {
    if (device.isSelected) {
      this.deviceCollection.clearSelection();
      const interfaceCollection = device.get('interfaceCollection');
      interfaceCollection.reset();
      interfaceCollection.ruleCollection = null;
    } else {
      const interfaceCollection = new InterfaceCollection(device.get('interfaces'));
      interfaceCollection.hasFetched = true;

      if (this.ruleTestState.rule) {
        // Need to propagate rule information to the rule collection so the resultant view will see it,
        // making sure that we don't overwrite anything (existing rules) or skip anything (IDs).
        const ruleCollection = new RuleCollection(this.ruleCollection.toJS());
        if (this.ruleTestState.rule.isNew) {
          ruleCollection.add([this.ruleTestState.rule.toJS()]);
          ruleCollection.at(ruleCollection.size - 1).optimisticId = this.ruleTestState.rule.optimisticId;
        } else {
          const existingRule = ruleCollection.get(this.ruleTestState.rule.id);
          existingRule.set(this.ruleTestState.rule.toJS());
        }

        // Set the "active" rule on the Interface collection so we can do some data gathering
        interfaceCollection.activeRule = this.ruleTestState.rule;
        interfaceCollection.ruleCollection = ruleCollection;
        interfaceCollection.sortState = { field: 'isClassifiedByActiveRule', direction: 'desc' };
        interfaceCollection.sort();
      }

      interfaceCollection.each(model => model.set('device_id', device.get('id')));

      device.set({ interfaceCollection });
      device.select();
    }
  }

  @action
  toggleDeviceBreakdown = () => {
    this.ruleTestState.deviceBreakdownVisible = !this.ruleTestState.deviceBreakdownVisible;
  };

  @action
  toggleUnclassifiedInterfacesTable = () => {
    this.ruleTestState.unclassifiedInterfacesTableVisible = !this.ruleTestState.unclassifiedInterfacesTableVisible;
  };

  handleSuccess = data => {
    if (data.complete) {
      this.setAllUnclassifiedInterfaces();
    }
  };

  @action
  evaluateRules = () => {
    this.isRuleEvaluationRequired = false;

    // each device in the collection is now evaluating results
    this.deviceCollection.models.forEach(model => model.set({ loadingInterfaceResults: true }));

    this.ruleState.send({ persist: true });
  };

  /**
   * Tests a single rule. `values` are the new values we want to test, and have not yet
   * been persisted to `rule: BaseModel`
   */
  @action
  testRule = (rule, values) => {
    this.ruleTestState.deviceBreakdownVisible = false;

    // The rule has been tested, we toggle some UI state based on this in the form.
    rule.isTested = true;

    // Take a clone of the rule and persist the temporary tested values but only if it's not new.
    // If it's new, there's no original state to maintain and it loses its optimistic ID so things don't match.
    let ruleClone = rule;
    if (!rule.isNew) {
      ruleClone = rule.duplicate({ removeId: false, save: false });
    }
    ruleClone.set(values);

    this.ruleTestState.rule = ruleClone;

    // construct our data to send to the socket, with the values we want to override.
    this.ruleTestState.send({
      persist: false,
      overrideRules: [
        {
          id: rule.id,
          enabled: true,
          ...values
        }
      ]
    });
  };

  // TODO: this is all kinds of suspect
  @action
  reorderRule = (oldIndex = 0, newIndex = 0) => {
    // move our item first
    if (newIndex !== -1) {
      this.ruleCollection.move(oldIndex, newIndex);
    }

    // generate our data for the id/order
    const data = this.ruleCollection.models.map(({ id, index }) => ({
      id,
      rank: newIndex === -1 && index > oldIndex ? index - 1 : index
    }));

    api.post('/api/portal/rules/reorder', { data });
    this.setRuleEvaluationRequired();
  };

  addRule = () => {
    const rule = this.ruleCollection.forge({ rank: this.ruleCollection.models.length });
    this.setActiveTestRule(rule);
  };

  @action
  removeRule = rule => {
    const rank = rule.get('rank');

    return rule.destroy().then(() => {
      if (this.ruleCollection.size > 0) {
        this.reorderRule(rank, -1);
      } else {
        this.evaluateRules();
      }
    });
  };

  @action
  setActiveTestRule = rule => {
    this.ruleTestState.rule = rule.duplicate({ removeId: false, save: false });
    this.ruleTestState.evaluationResults = observable.shallowArray(this.ruleState.evaluationResults.slice());

    // Set the test device breakdown with the current results (will be overwritten if/when we test the rule)
    this.testResultsDeviceCollection.updateDeviceInterfaceMatchData(this.ruleState.deviceMatches);
    this.testResultsDeviceCollection.sort();
  };

  @action
  toggleRuleEnabled = rule => {
    const { enabled } = rule.get();
    rule.save({ enabled: !enabled }, { toast: false });
    this.setRuleEvaluationRequired();
  };

  @action
  setRuleEvaluationRequired() {
    this.isRuleEvaluationRequired = true;
  }

  /**
   * If we're loading Devices or we're still parsing rule results.
   *
   * TODO: I think the "we're still parsing rule results" logic is wrong here
   */
  @computed
  get isLoadingDevicesInterfaces() {
    return (
      this.deviceCollection.isRequestActive('fetching') || (!this.ruleState.complete && !this.ruleState.evaluating)
    );
  }

  @action
  openConfigureIcDialog = () => {
    this.isConfigureIcDialogOpen = true;
  };

  @action
  closeConfigureIcDialog = () => {
    this.isConfigureIcDialogOpen = false;
  };

  @action
  openUnclassifiedInterfacesDialog = () => {
    this.isUnclassifiedInterfacesDialogOpen = true;
  };

  @action
  closeUnclassifiedInterfacesDialog = () => {
    this.isUnclassifiedInterfacesDialogOpen = false;
  };

  getDefaultNetworkBoundary(connectivity_type) {
    return this.settings.get(connectivity_type);
  }
}

export default new InterfaceClassStore();
