import React from 'react';

import Collection from '../Collection';
import BaseModel from '../BaseModel';

class Note extends BaseModel {
  get omitDuringSerialize() {
    return ['id', 'user', 'note_date', 'user_id'];
  }

  get removalConfirmText() {
    return {
      text: <p>Are you sure you want to remove this note?</p>
    };
  }
}

class NoteCollection extends Collection {
  constructor(data, options) {
    super(data);
    this.companyId = options.companyId;
  }

  get defaultSortState() {
    return {
      field: 'note_date',
      direction: 'asc'
    };
  }

  get url() {
    return `/api/sudo/companies/${this.companyId}/notes`;
  }

  get model() {
    return Note;
  }
}

export default NoteCollection;
