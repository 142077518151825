import { action, computed } from 'mobx';
import { isString } from 'lodash';
import BaseModel from 'models/BaseModel';

export default class Tag extends BaseModel {
  get urlRoot() {
    if (this.get('flow_value')) {
      return '/api/portal/tags';
    }
    return '/api/portal/tags/hyperscale';
  }

  get jsonRoot() {
    return 'tag';
  }

  @computed
  get created() {
    return this.toDate('created_date');
  }

  @computed
  get edited() {
    return this.toDate('updated_date');
  }

  get omitDuringSerialize() {
    return ['mac_count', 'addr_count', 'edited_by', 'created_date', 'updated_date'];
  }

  get messages() {
    return {
      // flow_tag = legacy, value = new hippo hotness to be consistent across tags/populators.
      create: `Tag ${this.get('flow_tag') || this.get('value')} was added successfully`,
      update: `Tag ${this.get('flow_tag') || this.get('value')} was updated successfully`,
      destroy: `Tag ${this.get('flow_tag') || this.get('value')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Tag',
      // flow_tag = legacy, value = new hippo hotness to be consistent across tags/populators.
      text: `Are you sure you want to remove ${this.get('flow_tag') || this.get('value')}?`
    };
  }

  /**
   * For field count is greater than actual length of field array, field was too large and has been truncated.
   * Legacy populators don't have count fields and actual fields are not arrays, so values should compare equal
   * at MAX_SAFE_INTEGER and just return false.
   * @returns {boolean}
   */
  @computed
  get truncatedFields() {
    const macCount = parseInt(this.get('mac_count'));
    const addrCount = parseInt(this.get('addr_count'));
    const macList = this.get('mac');
    const addrList = this.get('addr');

    // only evaluate if fields are correct (hippo tags), otherwise bail with false.
    if (!Number.isNaN(macCount) && !Number.isNaN(addrCount) && Array.isArray(macList) && Array.isArray(addrList)) {
      return macCount > macList.length || addrCount > addrList.length;
    }
    return false;
  }

  /*
    overrode 'set' and 'deserialize' to fix bug when deleted fields show up after update.
  */
  @action
  set = (dataOrFieldName = {}, fieldData) => {
    if (isString(dataOrFieldName) && fieldData) {
      this.attributes.set(dataOrFieldName, fieldData);
    } else {
      this.attributes.replace(dataOrFieldName);
    }

    this.setLastUpdated();

    return this;
  };

  deserialize(data) {
    // need extra logic because collection doesn't have individual model data under a "tag" field, but create/save responses do.
    return data && data.tag ? data.tag : data;
  }
}
