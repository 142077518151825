import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Button, ButtonGroup } from '@blueprintjs/core';
import DismissibleCallout from 'components/DismissibleCallout';
import { Flex, Box } from 'components/flexbox';
import TaskList from 'views/Onboarding/TaskList';

import noFlowIcon from 'no-flow-icon.svg';

import ProductUpdates from './ProductUpdates';
import Resources from './Resources';

const NoFlowReceived = ({ isAdministrator }) => (
  <Box className="pt-callout pt-running-text" p={4} mt={6}>
    <img src={noFlowIcon} alt="noflow icon" style={{ maxWidth: 100 }} />
    <h1 className="pt-normal">No flow data received</h1>
    <p className="pt-running-text pt-text-muted no-margin" style={{ fontSize: 18 }}>
      We haven't received flow data from any of your configured devices.
      {isAdministrator && (
        <Fragment>
          <br />
          <a href="https://kb.kentik.com/Bd01.htm#Bd01-Router_Configuration" rel="noopener noreferrer" target="_blank">
            Get help configuring your devices
          </a>
        </Fragment>
      )}
    </p>
  </Box>
);

@inject('$auth', '$devices', '$library', '$onboarding')
@observer
class LibraryEmptyState extends Component {
  handleSelectReport = report => {
    const { $library } = this.props;
    $library.selectReport(report);
  };

  render() {
    const { $auth, $devices, $onboarding, featuredReports } = this.props;
    const { hasDevices, hasReceivedFlow } = $devices;
    const { isAdministrator } = $auth;

    return (
      <Box px={3} style={{ width: '100%', maxWidth: 1000, margin: '0px auto' }}>
        {!hasReceivedFlow && hasDevices && <NoFlowReceived isAdministrator={isAdministrator} />}

        {isAdministrator && (
          <DismissibleCallout name="libraryAddDevices" mt={4}>
            {({ handleClose }) => (
              <Fragment>
                <Box mb={2}>
                  <h4>Add more data sources</h4>
                  <p className="pt-running-text">
                    Use the links below to register devices in Kentik, including hardware (routers and switches),
                    software host agents (kprobe), and VPCs from your cloud storage providers.{' '}
                  </p>
                </Box>

                <TaskList tasks={$onboarding.tasks} layout="grid" />
                <Flex justify="flex-end">
                  <Button className="pt-intent-primary pt-minimal" onClick={handleClose} iconName="close">
                    Hide and don't show again
                  </Button>
                </Flex>
              </Fragment>
            )}
          </DismissibleCallout>
        )}

        {hasReceivedFlow &&
          featuredReports.length > 0 && (
            <Box mt={4}>
              <h4>Featured Content</h4>

              <ButtonGroup
                vertical
                className="pt-fill pt-minimal pt-align-left"
                style={{ marginTop: 16, marginLeft: -9 }}
              >
                {featuredReports.slice(0, 5).map(report => (
                  <Button
                    className="pt-large"
                    key={`${report.id}-${report.type}`}
                    onClick={() => this.handleSelectReport(report)}
                    text={
                      <Flex align="center" justify="space-between">
                        <Flex align="center">
                          <Icon iconName={report.iconName} style={{ fontSize: 20, marginRight: 8 }} />
                          <Box pr={0.5} className="pt-text-muted">
                            {report.get('category.name')}:
                          </Box>{' '}
                          <Box>{report.name}</Box>
                        </Flex>
                        <Icon iconName="chevron-right" />
                      </Flex>
                    }
                  />
                ))}
              </ButtonGroup>
            </Box>
          )}

        <Flex wrap mt={6} gutter={1}>
          <Box sm={12} col={6} px={1} mb={3}>
            <Resources />
          </Box>
          <Box sm={12} col={6} px={1} mb={3}>
            <ProductUpdates />
          </Box>
        </Flex>
      </Box>
    );
  }
}

export default LibraryEmptyState;
