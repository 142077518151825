import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from '@blueprintjs/core';

import { formatAlertValue, getFallbackName } from '../alarmHelpers';

const BaselineCell = props => {
  const { model } = props;
  const { alert_baseline, alert_metric, baseline_used, row_type } = model.get();

  if (row_type !== 'Mitigation' && alert_metric && alert_metric[0]) {
    const baseline = formatAlertValue(alert_baseline, alert_metric[0]);
    const fallbackName = getFallbackName(baseline_used);

    return (
      <Tooltip className="ellipsis" content={<div>{fallbackName}</div>}>
        <div className="pt-text-overflow-ellipsis" title={baseline}>
          <small className="pt-text-muted">Baseline: </small> {baseline}
        </div>
      </Tooltip>
    );
  }

  return null;
};

export default observer(BaselineCell);
