import { computed, observable, action } from 'mobx';
import { flatten } from 'lodash';

import { showErrorToast } from 'components/Toast';
import { getTotalTrafficOverlay, getHistoricalOverlay } from 'models/query/QueryModel';
import BaseModel from 'models/BaseModel';
import $app from 'stores/$app';
import $dataviews from 'stores/$dataviews';
import $dictionary from 'stores/$dictionary';
import $auth from 'stores/$auth';
import { tagQueryBucket } from 'util/queryResultTagging/tagEngine';
import Socket from 'util/Socket';
import {
  addFilter,
  addFilterGroup,
  getDefaultFiltersObj,
  mergeFilterGroups,
  augmentQueryFiltersWithFilterGroup,
  augmentOrNestQueryFiltersWithFilterGroups
} from 'util/utils';

import QueryCollection from './QueryCollection';
import QueryResultsCollection from './QueryResultsCollection';

function splitQueriesForCustomGroups(queries, prop) {
  return flatten(
    queries.map(query => {
      const hasSrc = query[prop].some(m => m.includes('AS_src'));
      const hasDst = query[prop].some(m => m.includes('AS_dst'));

      if (query.customAsGroups && (hasSrc || hasDst)) {
        const q = [];

        const groupFilter = (srcOp, dstOp) => ({
          filterGroups: [
            {
              connector: 'All',
              filters: [
                srcOp && {
                  filterField: 'kt_src_as_group',
                  operator: srcOp,
                  filterValue: ''
                },
                dstOp && {
                  filterField: 'kt_dst_as_group',
                  operator: dstOp,
                  filterValue: ''
                }
              ].filter(a => a)
            }
          ]
        });

        const groupQuery = { ...query };
        const nativeQuery = { ...query };

        groupQuery[prop] = query[prop].map(m => m.replace(/^AS_(src|dst)$/, 'kt_$1_as_group'));
        groupQuery.filters_obj = mergeFilterGroups(query.filters_obj, groupFilter(hasSrc && '!=', hasDst && '!='));
        nativeQuery.filters_obj = mergeFilterGroups(query.filters_obj, groupFilter(hasSrc && '=', hasDst && '='));
        q.push(groupQuery, nativeQuery);

        if (hasSrc && hasDst) {
          const srcGroupQuery = { ...query };
          const dstGroupQuery = { ...query };

          srcGroupQuery[prop] = query[prop].map(m => m.replace('AS_src', 'kt_src_as_group'));
          srcGroupQuery.filters_obj = mergeFilterGroups(query.filters_obj, groupFilter('!=', '='));
          dstGroupQuery[prop] = query[prop].map(m => m.replace('AS_dst', 'kt_dst_as_group'));
          dstGroupQuery.filters_obj = mergeFilterGroups(query.filters_obj, groupFilter('=', '!='));
          q.push(srcGroupQuery, dstGroupQuery);
        }

        return q;
      }

      return query;
    })
  );
}

class QueryBucketModel extends BaseModel {
  @observable
  queryResults = new QueryResultsCollection();

  @observable
  queries = new QueryCollection();

  @observable
  overlayQueries = new QueryCollection();

  @observable
  loading = true;

  sockets = [];

  get url() {
    return '';
  }

  get defaults() {
    return {
      name: 'Default',
      sampleRateFactor: 1
    };
  }

  @computed
  get firstQuery() {
    return this.queries.size ? this.queries.at(0) : null;
  }

  @computed
  get hasQueries() {
    return this.queries.size || this.overlayQueries.size;
  }

  @computed
  get updateFrequency() {
    return this.firstQuery && this.firstQuery.get('update_frequency');
  }

  set updateFrequency(frequency) {
    this.firstQuery.set({ update_frequency: frequency });
  }

  @action
  setLoading(loading) {
    this.loading = loading;
  }

  get filterDimensionCsv() {
    const { aggregates, filterDimensionName } = this.firstQuery.get();
    const { units: unitsDict } = $dictionary.dictionary;

    const rows = [];
    const header = [];

    header.push(`"${filterDimensionName}"`);

    aggregates.forEach(aggregate => {
      if (aggregate.origLabel) {
        header.push(`"${aggregate.origLabel} ${unitsDict[aggregate.unit]}"`);
      } else if (aggregate.label) {
        header.push(`"${aggregate.label} ${unitsDict[aggregate.unit]}"`);
      }
    });

    rows.push(header);

    this.queryResults.nonOverlayRows.forEach(row => {
      const values = [];

      values.push(`"${row.get('name')}"`);

      aggregates.forEach(aggregate => {
        if (aggregate.origLabel || aggregate.label) {
          values.push(row.get(aggregate.value));
        }
      });

      rows.push(values);
    });

    return rows.map(row => row.join(',')).join('\r\n');
  }

  get filterDimensionRawCsv() {
    const { aggregates, outsort, secondaryOutsort, filterDimensionName } = this.firstQuery.get();
    const { outsortUnit, secondaryOutsortUnit } = this.firstQuery;
    const { countColumns, units: unitsDict } = $dictionary.dictionary;

    const outsortDataKey = aggregates.reduce((value, aggregate) => {
      const { column, name, raw } = aggregate;
      if (name === outsort && raw) {
        let newColName = column
          .replace('f_sum_', '')
          .replace('bytes', 'bits')
          .replace('trautocount', 'flows');
        if (countColumns.indexOf(column) === -1) {
          newColName += '_per_sec';
        }
        return newColName;
      }
      return value;
    }, '');

    let secondaryOutsortDataKey = null;
    if (secondaryOutsort) {
      secondaryOutsortDataKey = aggregates.reduce((value, aggregate) => {
        const { column, name, raw } = aggregate;
        if (name === secondaryOutsort && raw) {
          let newColName = column
            .replace('f_sum_', '')
            .replace('bytes', 'bits')
            .replace('trautocount', 'flows');
          if (countColumns.indexOf(column) === -1) {
            newColName += '_per_sec';
          }
          return newColName;
        }
        return value;
      }, '');
    }

    const models = this.queryResults.getRawDataRows();
    const rows = [];
    const header = [];

    header.push(`"${filterDimensionName}"`);

    header.push('timestamp');
    header.push(`"${unitsDict[outsortUnit]}"`);
    if (secondaryOutsort) {
      header.push(`"${unitsDict[secondaryOutsortUnit]}"`);
    }
    rows.push(header);

    models.forEach(row => {
      const rawData = row.get('rawData');
      if (rawData && rawData[outsortDataKey] && rawData[outsortDataKey].flow) {
        const metricValues = [];

        metricValues.push(`"${row.get('name')}"`);

        rawData[outsortDataKey].flow.forEach((timeRow, index) => {
          const values = [].concat(metricValues);
          values.push(timeRow[0]); // timestamp
          values.push(timeRow[1]); // value for outsort unit
          if (
            secondaryOutsortDataKey &&
            rawData[secondaryOutsortDataKey] &&
            rawData[secondaryOutsortDataKey].flow &&
            rawData[secondaryOutsortDataKey].flow[index]
          ) {
            // value for secondary outsort unit (abs here because sometimes they're negative for graphing purposes)
            values.push(Math.abs(rawData[secondaryOutsortDataKey].flow[index][1]));
          }
          rows.push(values);
        });
      }
    });

    return rows.map(row => row.join(',')).join('\r\n');
  }

  get csv() {
    const { aggregates, metric: metrics } = this.firstQuery.get();
    const { chartTypesValidations, metricColumns, units: unitsDict } = $dictionary.dictionary;

    const rows = [];
    const header = [];

    metrics.forEach(metric => {
      header.push(`"${chartTypesValidations[metric] || metric}"`);
    });

    aggregates.forEach(aggregate => {
      if (aggregate.origLabel) {
        header.push(`"${aggregate.origLabel} ${unitsDict[aggregate.unit]}"`);
      } else if (aggregate.label) {
        header.push(`"${aggregate.label} ${unitsDict[aggregate.unit]}"`);
      }
    });

    rows.push(header);

    this.queryResults.nonOverlayRows.forEach(row => {
      const values = [];
      metrics.forEach(metric => {
        values.push(`"${row.get(metricColumns[metric] || metric)}"`);
      });

      aggregates.forEach(aggregate => {
        if (aggregate.origLabel || aggregate.label) {
          values.push(row.get(aggregate.value));
        }
      });

      rows.push(values);
    });

    return rows.map(row => row.join(',')).join('\r\n');
  }

  get rawCsv() {
    const { aggregates, metric: metrics, outsort, secondaryOutsort } = this.firstQuery.get();
    const { outsortUnit, secondaryOutsortUnit } = this.firstQuery;
    const { chartTypesValidations, countColumns, metricColumns, units: unitsDict } = $dictionary.dictionary;

    const outsortDataKey = aggregates.reduce((value, aggregate) => {
      const { column, name, raw } = aggregate;
      if (name === outsort && raw) {
        let newColName = column
          .replace('f_sum_', '')
          .replace('bytes', 'bits')
          .replace('trautocount', 'flows');
        if (countColumns.indexOf(column) === -1) {
          newColName += '_per_sec';
        }
        return newColName;
      }
      return value;
    }, '');

    let secondaryOutsortDataKey = null;
    if (secondaryOutsort) {
      secondaryOutsortDataKey = aggregates.reduce((value, aggregate) => {
        const { column, name, raw } = aggregate;
        if (name === secondaryOutsort && raw) {
          let newColName = column
            .replace('f_sum_', '')
            .replace('bytes', 'bits')
            .replace('trautocount', 'flows');
          if (countColumns.indexOf(column) === -1) {
            newColName += '_per_sec';
          }
          return newColName;
        }
        return value;
      }, '');
    }

    const models = this.queryResults.getRawDataRows();
    const rows = [];
    const header = [];

    metrics.forEach(metric => {
      header.push(`"${chartTypesValidations[metric] || metric}"`);
    });
    header.push('timestamp');
    header.push(`"${unitsDict[outsortUnit]}"`);
    if (secondaryOutsort) {
      header.push(`"${unitsDict[secondaryOutsortUnit]}"`);
    }
    rows.push(header);

    models.forEach(row => {
      const rawData = row.get('rawData');
      if (rawData && rawData[outsortDataKey] && rawData[outsortDataKey].flow) {
        const metricValues = [];
        metrics.forEach(metric => {
          metricValues.push(`"${row.get(metricColumns[metric] || metric)}"`);
        });

        rawData[outsortDataKey].flow.forEach((timeRow, index) => {
          const values = [].concat(metricValues);
          values.push(timeRow[0]); // timestamp
          values.push(timeRow[1]); // value for outsort unit
          if (
            secondaryOutsortDataKey &&
            rawData[secondaryOutsortDataKey] &&
            rawData[secondaryOutsortDataKey].flow &&
            rawData[secondaryOutsortDataKey].flow[index]
          ) {
            // value for secondary outsort unit (abs here because sometimes they're negative for graphing purposes)
            values.push(Math.abs(rawData[secondaryOutsortDataKey].flow[index][1]));
          }
          rows.push(values);
        });
      }
    });

    return rows.map(row => row.join(',')).join('\r\n');
  }

  subscribeSocket(query, isOverlay = false, overlayIndex) {
    const {
      activeUser: { userGroup },
      isSubtenantUser
    } = $auth;

    if (isSubtenantUser && !userGroup.config.devices.all_devices) {
      if (userGroup.config.devices.device_labels.length) {
        const deviceLabelFilters = getDefaultFiltersObj();
        addFilterGroup(deviceLabelFilters, 'Any');
        userGroup.config.devices.device_labels.forEach(deviceLabel => {
          addFilter(deviceLabelFilters, 'i_device_label', '=', deviceLabel);
        });
        augmentOrNestQueryFiltersWithFilterGroups(query, deviceLabelFilters);
      }
      if (userGroup.config.devices.device_types.length) {
        const deviceSubtypeFilters = getDefaultFiltersObj();
        addFilterGroup(deviceSubtypeFilters, 'Any');
        userGroup.config.devices.device_types.forEach(deviceSubtype => {
          addFilter(deviceSubtypeFilters, 'i_device_subtype', '=', deviceSubtype);
        });
        augmentOrNestQueryFiltersWithFilterGroups(query, deviceSubtypeFilters);
      }
    }

    if ($app.debugModeEnabled) {
      augmentQueryFiltersWithFilterGroup(query, {
        connector: 'All',
        not: false,
        filterGroups: [],
        filters: [{ filterField: 'i_debug_info', operator: '=', filterValue: $app.debugModeInfo }]
      });
    }

    const socket = new Socket({
      outType: 'subscribe',
      inType: 'queryResults',
      delaySend: true,
      frequency: this.updateFrequency,
      onSuccess: action(data => {
        // this function gets called every time we receive results from the server.
        // we do an upsert operation for overlays, but a wipe and replace for regular rows.
        // this is to avoid having to do diffs for row removal.
        if (isOverlay) {
          data.forEach(row => {
            const existingRow = this.queryResults.find({ isOverlay, key: row.key });

            if (existingRow) {
              existingRow.set(row);
            } else {
              this.queryResults.add(Object.assign({ isOverlay, overlayIndex }, row));
            }
            this.queryResults.sort();
          });
        } else {
          this.queryResults.requestStatus = null;

          if (this.queryResults.nonOverlayRows.length) {
            this.queryResults.overwrite(data);
          } else {
            this.queryResults.add(data);
          }
          this.queryResults.sort();

          // All non-overlay queries for a bucket come back at once, but overlays are done with additional
          // subscribeSocket call. Only bracket on non-overlay results to prevent bracketing multiple times.
          if (!isOverlay) {
            this.bracketResults();
          }

          this.loading = false;
        }
        this.collection.lastUpdated = Date.now();
      }),
      onError: action(err => {
        console.warn('Error received from query engine', err);
        showErrorToast((err && err.text) || 'An unknown error has occurred when running your query');
        this.loading = false;
        this.collection.lastUpdated = Date.now();
      }),
      onReconnect: () => {
        // we are banking on only one non-overlay socket here.
        if (!isOverlay && this.updateFrequency) {
          this.refresh();
        }
      }
    });

    socket.setPayload({ query });
    socket.send();

    this.sockets.push(socket);
  }

  unsubscribeSocket(socket) {
    socket.setOutType('unsubscribe');
    socket.setPayload();
    socket.send();
    socket.cancelAll();
  }

  @action
  bracketResults({ options = {} } = {}) {
    if (this.queries.isEmpty() || this.queryResults.isEmpty()) {
      return;
    }

    tagQueryBucket({ queryBucket: this, options });
  }

  cloneForBracketing() {
    const clone = new QueryBucketModel();

    function attributesToCollection(attributes, collection) {
      const attributeClones = JSON.parse(JSON.stringify(attributes));
      attributeClones.reduce((accumulatorCollection, attribs) => {
        accumulatorCollection.add(accumulatorCollection.build(attribs));
        return accumulatorCollection;
      }, collection);
    }

    attributesToCollection(this.queries.toJS(), clone.queries);
    attributesToCollection(this.overlayQueries.toJS(), clone.overlayQueries);
    attributesToCollection(this.queryResults.toJS(), clone.queryResults);

    return clone;
  }

  @action
  subscribe() {
    if (this.queries.isEmpty()) {
      return;
    }

    this.loading = true;
    this.queryResults.requestStatus = 'fetching';

    this.queryResults.reset();
    const { outsort, metric, show_overlay, show_total_overlay, filterDimensionsEnabled } = this.firstQuery.get();
    this.queryResults.prefixableFieldUnits = this.firstQuery.prefixableFieldUnits;
    this.queryResults.defaultSortField = outsort;
    if (this.queryResults.sortState.field !== outsort) {
      this.queryResults.sort(outsort);
    }

    let queries = this.queries.models.map(query => query.serialize());

    if (this.queries.size === 1 && filterDimensionsEnabled === true) {
      const { filters_obj, filterDimensions, filterDimensionOther } = this.firstQuery.serialize();

      if (filterDimensions && filterDimensions.filterGroups && filterDimensions.filterGroups.length) {
        queries = [];
        filterDimensions.filterGroups.forEach(filterGroup => {
          const queryToAdd = this.firstQuery.serialize(); // call this every time to get the deepClone
          queryToAdd.filterDimensionsEnabled = false;
          queryToAdd.metric = ['Traffic'];
          queryToAdd.descriptor = filterGroup.name;
          queryToAdd.filters_obj = mergeFilterGroups(filters_obj, { filterGroups: [filterGroup] });

          queries.push(queryToAdd);
        });

        if (filterDimensionOther) {
          const filterGroup = {
            name: 'Other',
            connector: 'Any',
            filterGroups: filterDimensions.filterGroups,
            not: true
          };

          const queryToAdd = this.firstQuery.serialize(); // call this every time to get the deepClone

          queryToAdd.filterDimensionsEnabled = false;
          queryToAdd.metric = ['Traffic'];
          queryToAdd.descriptor = filterGroup.name;
          queryToAdd.filters_obj = mergeFilterGroups(filters_obj, filterGroup);

          queries.push(queryToAdd);
        }
      }
    }

    queries = splitQueriesForCustomGroups(queries, 'metric');
    queries = splitQueriesForCustomGroups(queries, 'matrixBy');

    this.subscribeSocket(queries);

    // if there are no overlay queries in this bucket, we auto subscribe overlays based on user input.
    const autoOverlays = [];
    const { showTotalTrafficOverlay } = $dataviews.getConfig(this.firstQuery.get('viz_type'));
    if (this.overlayQueries.isEmpty()) {
      if (show_total_overlay && showTotalTrafficOverlay && (!metric.includes('Traffic') || filterDimensionsEnabled)) {
        autoOverlays.push(getTotalTrafficOverlay(this.firstQuery));
      }
      if (show_overlay) {
        autoOverlays.push(getHistoricalOverlay(this.firstQuery));
      }
    }

    // now we process each overlay in its own socket
    autoOverlays
      .concat(this.overlayQueries.models.toJS())
      .forEach((query, index) => this.subscribeSocket(query.serialize(), true, index));
  }

  @action
  unsubscribe() {
    this.sockets.forEach(socket => this.unsubscribeSocket(socket));
    this.sockets = [];
  }

  @action
  refresh() {
    this.unsubscribe();
    this.subscribe();
  }

  @action
  applyToEachQuery(overrides) {
    this.queries.each(query => query.set(overrides));
    this.overlayQueries.each(query => query.set(overrides));
  }
}

export default QueryBucketModel;
