import PagedCollection from '../PagedCollection';
import Populator from './Populator';

class PopulatorCollection extends PagedCollection {
  get jsonRoot() {
    return 'populators';
  }

  get queuedFetchKey() {
    return 'PopulatorCollection';
  }

  get defaultSortState() {
    return {
      field: 'value',
      direction: 'desc'
    };
  }

  get url() {
    const dimension = this.parent;
    return `/api/portal/customdimensions/${dimension.id}/hyperscale/populators`;
  }

  get urlPaths() {
    const dimension = this.parent;
    return {
      pagedFetch: `/api/portal/customdimensions/${dimension.id}/populators/search`
    };
  }

  get model() {
    return Populator;
  }
}

export default PopulatorCollection;
