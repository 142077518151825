import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

const options = {
  validationOnInit: false
};

const fields = {
  startTime: {
    label: 'From (UTC)',
    transform: {
      in: value => value && moment(moment.utc(value).format(DEFAULT_DATETIME_FORMAT)).toDate(),
      out: value => `${moment(value).format(DEFAULT_DATETIME_FORMAT)}+00:00`
    }
  },

  endTime: {
    label: 'To (UTC)',
    transform: {
      in: value => value && moment(moment.utc(value).format(DEFAULT_DATETIME_FORMAT)).toDate(),
      out: value => `${moment(value).format(DEFAULT_DATETIME_FORMAT)}+00:00`
    }
  },

  filterBy: {
    options: [
      {
        value: 'alert_id',
        label: 'Alert Policy'
      },
      {
        value: 'alert_key',
        label: 'Key (exact)'
      },
      {
        value: 'alert_key_partial',
        label: 'Key (partial)'
      },
      {
        value: 'dimension_key',
        label: 'Dimension: Key'
      },
      {
        value: 'alarm_id',
        label: 'Alarm ID'
      },
      {
        value: 'mitigation_id',
        label: 'Mitigation ID'
      },
      {
        value: 'old_state',
        label: 'Old State (partial)'
      },
      {
        value: 'new_state',
        label: 'New State (partial)'
      },
      {
        value: 'any_state',
        label: 'Any State (partial)'
      }
    ]
  },

  filterVal: {
    defaultValue: ''
  },

  mitigations: {
    label: 'Mitigations',
    defaultValue: null
  },

  alarms: {
    label: 'Alarms',
    defaultValue: null
  },

  matches: {
    label: 'Matches',
    defaultValue: null
  },

  learningMode: {
    label: 'Silenced',
    defaultValue: null
  }
};

export { fields, options };
