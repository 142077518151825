import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Slider } from '@blueprintjs/core';

@observer
export default class TextSlider extends Component {
  state = {
    max: 0,
    value: 0
  };

  componentWillMount() {
    const { field } = this.props;
    const existingValue = field.value.toString() || field.defaultValue.toString();
    const values = field.options.map(option => option.value);

    if (existingValue && !values.includes(existingValue)) {
      field.options.push({
        label: existingValue,
        value: existingValue
      });
    }

    const defaultValue = field.options.findIndex(option => option.value === existingValue);

    this.setState({
      max: field.options.length - 1,
      value: defaultValue
    });
  }

  renderLabel = value => {
    const { field } = this.props;
    return field.options[value].label;
  };

  handleChange = value => {
    const { field } = this.props;
    field.onChange(field.options[value].value);
    return this.setState({ value });
  };

  render() {
    const { min = 0, labelStepSize = 3, showTrackFill = true, stepSize = 1 } = this.props;

    return (
      <Slider
        className="pt-text-slider"
        initialValue={0}
        labelStepSize={labelStepSize}
        max={this.state.max}
        min={min}
        onChange={this.handleChange}
        renderLabel={this.renderLabel}
        showTrackFill={showTrackFill}
        stepSize={stepSize}
        value={this.state.value}
      />
    );
  }
}
