import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Select, Switch } from 'components/forms';
import { getBaseSorts } from 'models/query/ExplorerQueryModel';

import Field from '../Field';
import { ModalSelect } from '../select';
import { getAggregateTypeLabel, getAggregateUnitLabel, MetricRenderer, MetricsRenderer } from './metricRenderers';

const dialogStyle = {
  width: 'calc(100vw - 50px)',
  maxWidth: 1300
};

const tetherOptions = {
  offset: '-3px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

const helpContents = (
  <Box p={2}>
    The metric(s) you select below will be shown in the Chart Legend (or Table widget).
    <br />
    The order of your selected metrics determines the order they appear in the legend. <br />
    You can use the shortcut menu below to automatically select preset groups of metrics.
  </Box>
);

@inject('$dataviews', '$dictionary', '$explorer')
@observer
class MetricSelector extends Component {
  static contextTypes = {
    form: any
  };

  state = {
    isEditing: false
  };

  static defaultProps = {
    multi: true,
    readOnly: false,
    fieldName: 'aggregateTypes',
    aggregateThresholdsFieldName: 'aggregateThresholds',
    rootPrefix: undefined,
    noSelectedValuesText: 'No metrics selected',
    reorderable: true,
    showClearButton: false,
    isPopover: false,
    itemFlexed: true,
    rules: undefined,
    allowSelectAll: true,
    showTools: true,
    showPrimaryDisplaySortMetric: true,
    allowMinimumThresholds: true
  };

  componentDidMount() {
    this.updateThresholdFieldStates();
  }

  componentWillUpdate() {
    this.updateThresholdFieldStates();
  }

  updateThresholdFieldStates() {
    const { form } = this.context;
    const { rootPrefix, vizType } = this.props;
    const { units } = this.props.$dictionary.dictionary;
    const aggTypes = this.getField().getValue();
    const thresholds = this.getFieldValue('aggregateThresholds');
    const aggregates = getBaseSorts(aggTypes, vizType || this.getFieldValue('viz_type'));

    form
      .getFields()
      .filter(field =>
        field.name.startsWith(rootPrefix ? `${rootPrefix}.aggregateThresholds.` : 'aggregateThresholds.')
      )
      .map(f => (rootPrefix ? f.name.replace(`${rootPrefix}.`, '') : f.name))
      .forEach(name => {
        if (!aggTypes.includes(name.split('.')[1])) {
          form.removeField(rootPrefix ? `${rootPrefix}.${name}` : name);
        }
      });
    aggTypes.forEach(agg => {
      const fieldName = rootPrefix ? `${rootPrefix}.aggregateThresholds.${agg}` : `aggregateThresholds.${agg}`;
      const matchingAgg = aggregates.find(a => a.value === agg);
      if (matchingAgg && !form.getFields().find(field => field.name === fieldName)) {
        const { origLabel, unit } = matchingAgg;
        form.addField(
          fieldName,
          {
            defaultValue: 0,
            label: 'Set Minimum Threshold',
            helpText: `Excludes any series where ${origLabel} ${units[unit]} falls below the specified value.`,
            rules: 'numeric|min:0',
            transform: { out: value => parseFloat(value) }
          },
          thresholds[agg],
          { validate: false }
        );
      }
    });
  }

  getField(name) {
    const { fieldName, rootPrefix } = this.props;
    const { form } = this.context;

    return rootPrefix ? form.getField(`${rootPrefix}.${name || fieldName}`) : form.getField(name || fieldName);
  }

  getFieldValue(name) {
    return this.getField(name).getValue();
  }

  getFieldNameString(name) {
    const { rootPrefix } = this.props;
    return rootPrefix ? `${rootPrefix}.${name}` : name;
  }

  getTools = () => (
    <Flex align="center" className="metric-selector-tools">
      <Field
        name={this.getFieldNameString('unitsLegacy')}
        labelAlign="left"
        onChange={(field, value) => {
          const {
            onChange,
            $dictionary: {
              dictionary: { unitsLegacy }
            }
          } = this.props;
          const aggTypesField = this.getField();
          const aggTypes = unitsLegacy[value] || [];
          aggTypesField.setValue(aggTypes);
          aggTypesField.onChange(aggTypes);
          this.getField('outsort').setValue('');

          if (onChange) {
            onChange(aggTypesField, aggTypes);
          }

          this.updateThresholdFieldStates();
        }}
        style={{ margin: 0 }}
        labelStyle={{ width: 185, margin: 0 }}
        inputStyle={{ minWidth: 150 }}
      >
        <Select tetherOptions={tetherOptions} />
      </Field>
      <Button iconName="remove" text="Clear Selections" onClick={this.handleClearValues} style={{ marginLeft: 10 }} />
    </Flex>
  );

  getBaseSorts() {
    const { vizType } = this.props;
    const viz_type = vizType || this.getFieldValue('viz_type');
    return getBaseSorts(this.getField().getValue(), viz_type);
  }

  getOutsortOptions(disabledValue) {
    const baseSorts = this.getBaseSorts();
    const options = [];

    if (disabledValue) {
      options.push({
        label: 'None',
        value: ''
      });
    }

    return options.concat(
      baseSorts.map(option => ({
        ...option,
        value: option.value === 'sum_logsum' ? `${option.value}_${option.unit}` : option.value,
        disabled: option.value === disabledValue,
        label: (
          <span>
            {getAggregateTypeLabel({ aggregate: option, useLineBreaks: false })}{' '}
            {option.value && getAggregateUnitLabel({ aggregate: option, useLineBreaks: false })}
          </span>
        )
      }))
    );
  }

  getSelectedUnits() {
    const baseSorts = this.getBaseSorts();

    return baseSorts.reduce(
      (units, { unit }) => (unit && unit !== 'kt_intell_order' && !units.includes(unit) ? units.concat(unit) : units),
      []
    );
  }

  getSelectedUnitOptions() {
    const { units } = this.props.$dictionary.dictionary;
    const baseSorts = this.getBaseSorts();
    const outsort = this.getFieldValue('outsort');
    let outsortAggregate = baseSorts.find(agg => agg.value === outsort) || baseSorts[0];
    if (outsort.startsWith('sum_logsum')) {
      outsortAggregate = { unit: outsort.replace('sum_logsum_', ''), fn: 'logsum' };
    }

    return [
      {
        label: 'None',
        value: ''
      }
    ].concat(
      this.getSelectedUnits().reduce((options, unit) => {
        if (outsortAggregate && unit === outsortAggregate.unit) {
          return options;
        }

        const matchedAggregate =
          baseSorts.find(agg => agg.unit === unit && agg.fn === outsortAggregate.fn) ||
          baseSorts.find(agg => agg.unit === unit);

        let label = units[unit];
        if (unit.startsWith('in_')) {
          label = `Ingress ${label}`;
        } else if (unit.startsWith('out_')) {
          label = `Egress ${label}`;
        }

        options.push({
          label,
          value: matchedAggregate.value
        });

        return options;
      }, [])
    );
  }

  getOutsortAggregateCategory(outsortOverride, aggregates) {
    if (aggregates === undefined) {
      aggregates = this.props.$dictionary.dictionary.aggregates;
    }

    const outsort = outsortOverride || this.getFieldValue('outsort');

    return Object.keys(aggregates).reduce((val, category) => {
      const aggs = aggregates[category];
      if (val) {
        return val;
      } else if (!Array.isArray(aggs)) {
        const subCategory = this.getOutsortAggregateCategory(outsort, aggs);
        if (subCategory !== null) {
          return `${category} ${subCategory}`;
        }
      } else if (aggs.some(agg => agg.value === outsort)) {
        return category;
      }
      return val;
    }, null);
  }

  handleOutsortChange = (field, value) => {
    const selectedUnitOptions = this.getSelectedUnitOptions();
    const secondaryOutsortField = this.getField('secondaryOutsort');
    const secondaryOutsort = secondaryOutsortField.getValue();
    if (value === secondaryOutsort || !selectedUnitOptions.find(opt => opt.value === secondaryOutsort)) {
      secondaryOutsortField.setValue('');
    }
  };

  handleSecondaryOutsortChange = (field, value) => {
    if (!value) {
      this.getField('secondaryTopxSeparate').setValue(false);
      this.getField('secondaryTopxMirrored').setValue(false);
    }
  };

  getBottomTools = () => {
    const { vizType } = this.props;
    const outsort = this.getFieldValue('outsort');
    const secondaryOutsort = this.getFieldValue('secondaryOutsort');
    const secondaryTopxSeparate = this.getFieldValue('secondaryTopxSeparate');
    // const viz_type = this.getFieldValue('viz_type');
    const viz_type = vizType || this.getFieldValue('viz_type');
    const aggregateTypes = this.getField().getValue();

    if (!aggregateTypes.length || (!outsort.startsWith('sum_logsum') && !aggregateTypes.includes(outsort))) {
      return null;
    }

    const {
      $dataviews,
      $dictionary: {
        dictionary: { units }
      }
    } = this.props;
    const { allowsSecondaryOverlay, suppressSecondaryTopxSeparate, buckets, timeBased } = $dataviews.getConfig(
      viz_type
    );
    let outsortHelpText = '';
    if (!timeBased && outsort.startsWith('sum_logsum')) {
      outsortHelpText = `With Kentik IntelliSort enabled, your ${$dataviews.getViewDisplayLabel(
        viz_type
      )} will display values for the first ${
        units[outsort.replace('sum_logsum_', '')]
      } metric in your Selected Metrics list.`;
    }

    const selectedUnits = this.getSelectedUnits();

    const secondaryOutsortOptions = secondaryTopxSeparate
      ? this.getOutsortOptions(outsort)
      : this.getSelectedUnitOptions();
    const secondaryMirrorable = secondaryOutsort && buckets.some(bucket => bucket.secondaryMirrorBucket !== undefined);

    return (
      <Box className="pt-card flat" p={2}>
        <Field
          options={this.getOutsortOptions()}
          name={this.getFieldNameString('outsort')}
          helpText={outsortHelpText}
          onChange={this.handleOutsortChange}
          style={{ maxWidth: 268 }}
        >
          <Select menuWidth={265} tetherOptions={tetherOptions} />
        </Field>
        {allowsSecondaryOverlay &&
          selectedUnits.length > 1 && (
            <Field
              options={secondaryOutsortOptions}
              name={this.getFieldNameString('secondaryOutsort')}
              label={`Secondary Display ${secondaryTopxSeparate ? '& Sort ' : ''}Metric`}
              onChange={this.handleSecondaryOutsortChange}
            >
              <Select menuWidth={265} tetherOptions={tetherOptions} />
            </Field>
          )}
        {allowsSecondaryOverlay &&
          suppressSecondaryTopxSeparate !== true &&
          selectedUnits.length > 1 &&
          secondaryOutsort && (
            <Field
              name={this.getFieldNameString('secondaryTopxSeparate')}
              style={{ marginBottom: secondaryMirrorable ? 16 : 0, maxWidth: 268 }}
            >
              <Switch />
            </Field>
          )}
        {secondaryMirrorable && (
          <Field flexAuto name={this.getFieldNameString('secondaryTopxMirrored')} style={{ marginBottom: 0 }}>
            <Switch />
          </Field>
        )}
      </Box>
    );
  };

  handleClearValues = () => {
    const { onChange } = this.props;
    const field = this.getField();
    field.onChange([]);
    if (onChange) {
      onChange(field, []);
    }
  };

  handleToggleEditing = () => {
    this.getField().setPristine(false);
    this.setState({ isEditing: !this.state.isEditing });
  };

  render() {
    const {
      $dictionary,
      className,
      multi,
      options,
      onChange,
      readOnly,
      showLabel,
      onEditComplete,
      inputStyle,
      dialogOnly,
      isEditing,
      rules
    } = this.props;

    const formField = this.getField();
    const selectOptions = options || $dictionary.dictionary.aggregates;

    const selectField = (
      <Field
        className={className}
        field={formField}
        isEditing={isEditing || this.state.isEditing}
        onChange={(field, value) => {
          this.updateThresholdFieldStates();
          if (onChange) {
            onChange(field, value);
          }
        }}
        options={selectOptions}
        showLabel={showLabel}
        toggleEditing={onEditComplete || this.handleToggleEditing}
        readOnly={readOnly}
        inputStyle={inputStyle}
        rules={rules}
      >
        <ModalSelect
          title="Metrics"
          helpContents={helpContents}
          dialogStyle={dialogStyle}
          maxColumns={1}
          valuesPanelWidth={300}
          {...this.props}
          filterPlaceholder="Search Available Metrics..."
          multi={multi}
          multiValuesRenderer={MetricsRenderer}
          selectedValuesTitle="Selected Metrics"
          valueRenderer={MetricRenderer}
          tools={this.getTools}
          bottomTools={this.getBottomTools}
          useLegacyOptionsFormat
        />
      </Field>
    );

    if (!dialogOnly && !readOnly && multi) {
      return (
        <div onClick={onEditComplete || this.handleToggleEditing} style={{ cursor: 'pointer' }}>
          {selectField}
        </div>
      );
    }

    return selectField;
  }
}

export default MetricSelector;
