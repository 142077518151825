import React from 'react';
import $app from 'stores/$app';
import { KentikLogo } from 'components';

const PoweredByKentik = props => (
  <div style={{ display: 'inline-block', ...props.style }}>
    <span style={{ fontWeight: 500 }}>Powered by</span>
    <a href="https://www.kentik.com" target="_blank" rel="noopener noreferrer">
      <KentikLogo
        blackText={props.blackText || !$app.darkThemeEnabled}
        style={{ width: 70, verticalAlign: 'bottom', left: 8 }}
      />
    </a>
  </div>
);

export default PoweredByKentik;
