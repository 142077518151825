import React, { Fragment } from 'react';
import { Callout, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { PROVIDERS } from 'forms/config/cloudExportDetails';

const config = {
  [PROVIDERS.GCE.id]: {
    title: 'Configure your Google Cloud',
    sections: [
      {
        title: 'Enable VPC Flow Logs',
        description:
          "Enable VPC flow logging for each VPC subnet, existing or new, that you'd like to cover with Kentik.",
        helpUrl: 'https://kb.kentik.com/Fc12.htm#Fc12-Enable_VPC_Flow_Logs'
      },
      {
        title: 'Create a New Topic',
        description: "Configure Google's Stackdriver Logging to export flow logs to a new Pub/Sub topic.",
        helpUrl: 'https://kb.kentik.com/Fc12.htm#Fc12-Create_a_New_Topic'
      },
      {
        title: 'Create a Pull Subscription',
        description:
          'A pull subscription enables its members to request flow logs from a topic on the Cloud Pub/Sub server.',
        helpUrl: 'https://kb.kentik.com/Fc12.htm#Fc12-Create_a_Pull_Subscription'
      },
      {
        title: 'Enable Kentik Access',
        description:
          'Make the Google Cloud account where we run our flow log application a member of your flow log subscription.',
        helpUrl: 'https://kb.kentik.com/Fc12.htm#Fc12-Set_Permissions'
      }
    ]
  },
  [PROVIDERS.AWS.id]: {
    title: 'Configure your AWS Cloud',
    sections: [
      {
        title: 'Create an AWS Role',
        description:
          "In the AWS IAM console, create a new AWS role with permissions that allow access by Kentik's AWS services.",
        helpUrl: 'https://kb.kentik.com/Fc14.htm#Fc14-Create_an_AWS_Role'
      },
      {
        title: 'Create an S3 Bucket',
        description:
          'In the AWS S3 console, create a bucket to which logs can be published and from which they can be pulled.',
        helpUrl: 'https://kb.kentik.com/Fc14.htm#Fc14-Create_an_S3_Bucket'
      },
      {
        title: 'Configure Log Publishing',
        description:
          'In the AWS VPC Dashboard, configure each VPC (or subnet or interface) to publish logs to a destination folder in the bucket.',
        helpUrl: 'https://kb.kentik.com/Fc14.htm#Fc14-Configure_Log_Publishing'
      },
      {
        title: 'Confirm Log Publishing',
        description:
          'Back in the S3 console, confirm that logs for each VPC are being published to the destination folders in the bucket.',
        helpUrl: 'https://kb.kentik.com/proto/Fc14.htm#Fc14-Check_Log_Collection'
      }
    ]
  },
  [PROVIDERS.AZURE.id]: {
    title: 'Configure your Azure Cloud',
    sections: [
      {
        title: "Enable Network Watcher for your VM's Region(s)",
        description:
          'The first step to enabling NSG Flow Logs is to set up a Network Watcher instance in each Azure region that you’d like to export flow logs from.',
        helpUrl:
          'https://portal.azure.com/?feature.quickstart=false#blade/Microsoft_Azure_Network/NetworkWatcherMenuBlade'
      },
      {
        title: 'Register an Insights Provider',
        description:
          'Configure the Azure Resource Manager to enable the Microsoft.Insights resource provider for your environment.',
        helpUrl: 'https://portal.azure.com/?feature.quickstart=false#blade/Microsoft_Azure_Billing/SubscriptionsBlade'
      },
      {
        title: 'Enable NSG flow logging',
        description: 'Create or configure an Azure Storage account to receive NSG flow logs.',
        helpUrl: 'https://portal.azure.com/?feature.quickstart=false#create/Microsoft.StorageAccount-ARM'
      },
      {
        title: 'Wait for NSG flow log creation',
        description: 'NSG flow logs can take up to an hour to begin populating. Check back soon to view your traffic!',
        helpUrl:
          'https://portal.azure.com/?feature.quickstart=false#blade/Microsoft_Azure_Network/NetworkWatcherMenuBlade?'
      }
    ]
  }
};

export const CloudHelpEmbedded = ({ cloudProvider }) => {
  const data = config[cloudProvider];

  if (!data) {
    return null;
  }

  return (
    <Flex gutter={1} wrap>
      {data.sections.map(section => (
        <Box key={section.helpUrl} md={6} lg={3} px={1} pt={2.5}>
          <div style={{ marginBottom: 4, fontSize: 14 }}>
            <a
              rel="noopener noreferrer"
              className="pt-minimal pt-intent-primary pt-small"
              target="_blank"
              href={section.helpUrl}
            >
              {section.title}
            </a>
          </div>
          <p className="pt-text-muted" style={{ lineHeight: 'unset', fontSize: 12, marginBottom: 0 }}>
            {section.description}
          </p>
        </Box>
      ))}
    </Flex>
  );
};

const CloudHelpSection = ({ cloudProvider }) => {
  const data = config[cloudProvider];

  if (!data) {
    return null;
  }

  return (
    <Callout intent={Intent.PRIMARY} style={{ marginLeft: 16, padding: 16, width: 250 }}>
      <div>
        <h4 style={{ marginBottom: 16, lineHeight: '1.2em' }}>{data.title}</h4>
      </div>
      {data.sections.map(section => (
        <Fragment key={section.helpUrl}>
          <Flex align="center" mt={3}>
            <h6 style={{ marginBottom: 4, lineHeight: '1.2em' }}>
              <a
                rel="noopener noreferrer"
                className="pt-minimal pt-intent-primary pt-small"
                target="_blank"
                href={section.helpUrl}
              >
                {section.title}
              </a>
            </h6>
          </Flex>
          <p className="pt-text-muted" style={{ lineHeight: 'unset', fontSize: 12 }}>
            {section.description}
          </p>
        </Fragment>
      ))}
    </Callout>
  );
};

export default CloudHelpSection;
