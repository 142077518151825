import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';

import Forbidden from 'views/Forbidden';

const loginRedirect = props => (
  <Redirect
    to={{
      pathname: '/login',
      state: { from: props.location }
    }}
  />
);

const totpRedirect = props => (
  <Redirect
    to={{
      pathname: '/verify-two-factor',
      state: { from: props.location }
    }}
  />
);

const expiredRedirect = () => <Redirect to="/expired-trial" />;
const inactiveRedirect = () => <Redirect to="/inactive-company" />;
const suspendedRedirect = () => <Redirect to="/suspended-company" />;
const defaultRedirect = () => <Redirect to="/" />;

@inject('$auth')
class ProtectedRoute extends Component {
  render() {
    const { $auth, skip2fa, force2fa, location, permission, ...rest } = this.props;
    const verified2fa = location && location.state && location.state.verified2fa;

    let redirect;

    if (!$auth.authenticated) {
      redirect = loginRedirect;
    } else if (($auth.twoFactorVerifyRequired && !skip2fa) || (force2fa && !verified2fa)) {
      redirect = totpRedirect;
    } else if (
      $auth.isSuspendedCompany &&
      location.pathname !== '/suspended-company' &&
      location.pathname !== '/verify-two-factor'
    ) {
      redirect = suspendedRedirect;
    } else if (
      $auth.isExpiredTrial &&
      location.pathname !== '/expired-trial' &&
      location.pathname !== '/verify-two-factor'
    ) {
      redirect = expiredRedirect;
    } else if (
      $auth.isInactiveCompany &&
      location.pathname !== '/inactive-company' &&
      location.pathname !== '/verify-two-factor'
    ) {
      redirect = inactiveRedirect;
    } else if (
      (!$auth.isExpiredTrial && location.pathname === '/expired-trial') ||
      (!$auth.isInactiveCompany && location.pathname === '/inactive-company') ||
      (!$auth.isSuspendedCompany && location.pathname === '/suspended-company')
    ) {
      redirect = defaultRedirect;
    }
    if (redirect) {
      return <Route location={location} render={redirect} />;
    }

    if (permission) {
      if ($auth.hasPermission(permission)) {
        return <Route location={location} {...rest} />;
      }
      return <Route path="*" component={Forbidden} />;
    }

    return <Route location={location} {...rest} />;
  }
}

export default withRouter(ProtectedRoute);
