import { observable, computed } from 'mobx';
import { getInvalidSitesCount } from 'dataviews/dependencies/SiteCountryDependency';
import SiteCollection from 'models/sites/SiteCollection';

class SiteStore {
  @observable
  collection = new SiteCollection();

  initialize() {
    return this.collection.fetch().then(() => {
      this.store.$library.reportsCollection.checkDependencies();
    });
  }

  @computed
  get showSitesError() {
    return getInvalidSitesCount() > 0;
  }

  @computed
  get noSitesConfigured() {
    return this.collection.unfilteredSize === 0;
  }

  @computed
  get numSites() {
    return this.collection.unfilteredSize;
  }

  @computed
  get siteOptions() {
    if (!this.collection.hasFetched) {
      return [];
    }

    return this.collection.map(site => ({
      id: site.id,
      value: site.id,
      label: site.get('title'),
      devices: site.get('devices'),
      activeDevices: site.get('devices').filter(device => device.device_status === 'V'),
      numDevices: site.get('devices').length
    }));
  }
}

export default new SiteStore();
