import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

@observer
class CollapseSidebarButton extends Component {
  render() {
    const { showCollapseButton, sidebar } = this.props;

    const className = classNames('sidebar-collapse-button', {
      'sidebar-collapse-button-shown': showCollapseButton,
      'sidebar-is-collapsed': sidebar && sidebar.collapsed
    });

    const iconName = sidebar && sidebar.collapsed ? 'double-chevron-right' : 'double-chevron-left';

    return <Button className={className} onClick={sidebar && sidebar.handleToggleCollapsed} iconName={iconName} />;
  }
}

export default CollapseSidebarButton;
