import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Box } from 'components/flexbox';

import { getCellStyle } from './Cell';

const TotalCell = observer(props => {
  const { column, index, models } = props;
  const { className, computed, name, showTotal, totalRenderer } = column;

  let value = index === 0 ? `Total of Top ${models.length}` : '';

  if (index > 0 && showTotal) {
    value = models.reduce((total, model) => total + (computed ? model[name] : model.get(name)), 0.0);
    if (totalRenderer) {
      value = totalRenderer({ value });
    }
  }

  return (
    <Box
      className={classNames('td', className, { 'align-right': column.align === 'right' })}
      style={getCellStyle(props)}
    >
      {value}
    </Box>
  );
});

const TotalRow = props => {
  const { collection, columns, totalRowFilter, style } = props;

  const visibleColumns = columns.filter(column => !column.hidden);

  return (
    <Box className="tr total" style={style} align="stretch">
      {visibleColumns.map((column, idx) => (
        <TotalCell
          {...props}
          key={column.key || column.name || column.type}
          column={column}
          index={idx}
          columns={visibleColumns}
          totalRow
          models={totalRowFilter ? collection.models.filter(totalRowFilter) : collection.models}
        />
      ))}
    </Box>
  );
};

export default observer(TotalRow);
