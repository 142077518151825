import React from 'react';
import { observer } from 'mobx-react';
import { Popover, Position } from '@blueprintjs/core';
import classNames from 'classnames';

import $app from 'stores/$app';

const DropdownMenu = ({ children, className, tetherOptions = {}, ...props }) => {
  const popoverClassName = classNames('pt-minimal', className, { 'pt-dark': $app.darkThemeEnabled });
  const options = {
    offset: '-8px 18px',
    constraints: [{ attachment: 'together', pin: true, to: 'scrollParent' }],
    ...tetherOptions
  };

  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      {...props}
      popoverClassName={popoverClassName}
      tetherOptions={options}
      openOnTargetFocus
    >
      {children}
    </Popover>
  );
};

export default observer(DropdownMenu);
