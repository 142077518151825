import Validator from 'validatorjs';
import { action, computed, extendObservable, observable, toJS } from 'mobx';
import { isFunction } from 'lodash';

import { guid } from 'util/utils';

export function getInitialValue({ value, defaultValue }) {
  const initialValue = isFunction(defaultValue) ? defaultValue() : defaultValue;
  return value !== undefined && value !== null ? value : initialValue;
}

const falseyEquivalents = [null, undefined, ''];
/**
 * Non-strict-ish, deep comparison to replace strict _.isEqual() behavior.
 *
 * This has one intentional limitation specific to FieldState usage:
 *   null, undefined and empty string values are treated as equal.
 */
function isEqual(value1, value2) {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return value1.length === value2.length && value1.every((val1, idx) => isEqual(val1, value2[idx]));
  } else if (typeof value1 === 'object' && typeof value2 === 'object') {
    const val1Keys = Object.keys(value1 || {});
    const val2Keys = Object.keys(value2 || {});
    const keys = Array.from(new Set(val1Keys.concat(val2Keys)));

    return keys.every(key => isEqual(value1[key], value2[key]));
  }

  return value1 === value2 || (falseyEquivalents.includes(value1) && falseyEquivalents.includes(value2));
}

class FieldState {
  @observable
  _id = guid();

  @observable
  defaultValue = '';

  @observable
  disabled = false;

  @observable
  errors = [];

  @observable
  label;

  @observable
  name;

  @observable
  placeholder;

  @observable
  pristine = true;

  @observable
  readOnly = false;

  @observable
  initialValue = undefined;

  @observable
  value;

  @observable
  validateDebounceDurationMs = 250;

  @observable
  showPristineErrors;

  @observable
  helpText;

  form = undefined;

  initialConfig = undefined;

  parent = undefined;

  // If it's part of an array field
  rules = [];

  transform = undefined;

  debouncedFormValidate = undefined;

  validateOptions = true;

  constructor(form, config) {
    this.form = form;
    this.initialConfig = config;
    // we do this so that observable doesn't make copies. we want the actual reference to stick.
    this.adjacentFields = this.initialConfig.adjacentFields;
    delete this.initialConfig.adjacentFields;

    // this extends this with initialConfig, where all keys become observables #FYI
    extendObservable(this, this.initialConfig);

    this.init(config.value);
  }

  validateWithDebounce(options = {}) {
    if (this.debouncedFormValidate) {
      clearTimeout(this.debouncedFormValidate);
    }

    this.debouncedFormValidate = setTimeout(
      action(() => {
        if (options.removePristineState === true) {
          this.pristine = false;
        }
        this.form.validate();
      }),
      this.validateDebounceDurationMs
    );
  }

  @action
  init(value) {
    extendObservable(this, this.initialConfig);

    const initialValue = getInitialValue({ value, defaultValue: this.defaultValue });

    this.setValue(initialValue, { validate: false });
    this.initialValue = initialValue;
    this.pristine = true;
    this.showPristineErrors =
      this.initialConfig.showPristineErrors !== undefined
        ? this.initialConfig.showPristineErrors
        : this.form.options.showPristineErrors || false;
  }

  @action
  reset() {
    const initialValue = toJS(this.initialValue);
    this.init(initialValue);
  }

  @action
  enable = () => {
    this.disabled = false;
  };

  @action
  disable = () => {
    this.disabled = true;
  };

  @action
  setPristine = value => {
    this.pristine = value;
  };

  @action
  setLabel = label => {
    this.label = label;
  };

  @action
  setReadOnly = () => {
    this.readOnly = true;
  };

  @action
  setRules = (rules, options = {}) => {
    const { validate = true } = options;

    this.rules = rules;

    if (validate) {
      this.form.validate();
    }
  };

  @action
  setFieldStates = fieldStates => {
    this.fieldStates = fieldStates;
    this.form.validate();
  };

  @action
  setValue(value, options = {}) {
    const { validate = true } = options;

    this.value = this._transformInValue(toJS(value));

    if (validate) {
      this.validateWithDebounce();
    }
  }

  @action
  onChange(e) {
    let value;

    if (e && e.target) {
      value = e.target.value;
    } else {
      value = e;
    }

    this.value = value;
    this.validateWithDebounce({ removePristineState: true });
  }

  @computed
  get dirty() {
    return !isEqual(this.getValue(), this.getInitialValue());
  }

  @computed
  get hasError() {
    return this.errors && this.errors.length > 0;
  }

  @computed
  get valid() {
    return !this.hasError;
  }

  getOptionValues(obj) {
    if (Array.isArray(obj)) {
      const values = [];
      obj.forEach(opt => {
        if (typeof opt === 'object' && opt.value === undefined) {
          values.push(...this.getOptionValues(opt));
        } else {
          values.push(opt.value || opt);
        }
      });
      return values;
    }
    return Object.keys(obj).reduce((acc, key) => [...acc, ...this.getOptionValues(obj[key])], []);
  }

  @action
  validate(values) {
    if (!this.rules && !this.options) {
      this.errors = [];
      return;
    }

    let rulesArray = toJS(this.rules || []);
    if (typeof rulesArray === 'string') {
      rulesArray = rulesArray.split('|');
      this.rules = rulesArray;
    }

    const options = toJS(this.options);
    const messages = this.messages || {};
    const ruleIdx = rulesArray.findIndex(rule => !!rule.validOptions);
    if (options && this.validateOptions) {
      const validOptions = { validOptions: this.getOptionValues(options) };
      if (ruleIdx === -1) {
        rulesArray.push(validOptions);
      } else {
        rulesArray.splice(ruleIdx, 1, validOptions);
      }
    } else if (ruleIdx !== -1) {
      rulesArray.splice(ruleIdx, 1);
    }

    rulesArray = toJS(rulesArray);
    const ruleParams = toJS(this.ruleParams || {});
    Object.keys(ruleParams).forEach(k => {
      const v = ruleParams[k]();
      rulesArray = rulesArray.map(r => r.replace(`$${k}`, v));
    });

    const rules = {
      [this.name]: rulesArray
    };

    const attributeNames = {
      [this.name]: this.label || this.name
    };

    const { model } = this.form;

    const validator = new Validator({ model, fieldName: this.name, ...values }, rules, messages);
    validator.setAttributeNames(attributeNames);
    validator.check();

    this.errors = validator.errors.get(this.name);
  }

  getProps() {
    return {
      name: this.name,
      value: this.value,
      placeholder: this.placeholder,
      disabled: this.disabled
    };
  }

  getInitialValue() {
    return toJS(this.initialValue);
  }

  getValue() {
    return this._transformOutValue(toJS(this.value));
  }

  _transformInValue(value) {
    return this.transform && this.transform.in ? this.transform.in(value, this.form, this) : value;
  }

  _transformOutValue(value) {
    return this.transform && this.transform.out ? this.transform.out(value, this.form, this) : value;
  }

  @action
  remove() {
    if (!this.parent) {
      console.warn('Calling remove() on a field with no parent', this.name);
      return;
    }

    this.parent.remove(this);
  }
}

export default FieldState;
