import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Page } from 'components/page';
import { Icon, Intent } from '@blueprintjs/core';
import inviteTeam from 'add-team-icon.svg';

import { Flex, Box } from 'components/flexbox';
import ProductUpdates from 'views/Library/ProductUpdates';
import Resources from 'views/Library/Resources';
import TaskList, { TaskItem } from './TaskList';

@inject('$auth', '$devices', '$onboarding', '$users')
@observer
class Onboarding extends Component {
  getOnboardingContent() {
    const { $auth, $devices, $onboarding, $users } = this.props;
    const { isAdministrator, isSubtenantUser } = $auth;

    return (
      <Box px={3} style={{ width: '100%', maxWidth: 855, margin: '0px auto' }}>
        {isAdministrator && (
          <Box className="pt-running-text">
            <h1 className="pt-normal">Getting started with Kentik</h1>
            <p className="pt-running-text pt-text-muted" style={{ fontSize: 18, marginBottom: 24 }}>
              Welcome to Kentik Portal! There are a few important setup steps that need to be completed before we move
              on.
            </p>
          </Box>
        )}

        {isAdministrator &&
          !isSubtenantUser && (
            <Fragment>
              <Box mb={2}>
                <h4>Configure data sources</h4>
              </Box>
              <TaskList tasks={$onboarding.tasks} layout="grid" />
            </Fragment>
          )}

        {isAdministrator && (
          <Box mt={4}>
            <Box mb={2}>
              <h4>Additional setup</h4>
            </Box>
            <TaskItem
              title="Add collaborators"
              description="Invite the rest of your team, assign user roles and access controls"
              src={inviteTeam}
              onClick={() => $users.collection.forge()}
              small
            />
          </Box>
        )}

        {!isAdministrator && (
          <Box className="pt-callout pt-running-text" p={4} mt={6}>
            {!$devices.hasDevices && (
              <Fragment>
                <Icon intent={Intent.DANGER} iconName="warning-sign" style={{ fontSize: 64 }} />
                <h1 className="pt-normal">No devices have been added</h1>
              </Fragment>
            )}

            <p className="pt-running-text pt-text-muted" style={{ fontSize: 18 }}>
              Your company administrator has to add devices so we can start showing data
            </p>
          </Box>
        )}

        <Flex wrap mt={6} gutter={1}>
          <Box sm={12} col={6} px={1} mb={3}>
            <Resources />
          </Box>
          <Box sm={12} col={6} px={1} mb={3}>
            <ProductUpdates />
          </Box>
        </Flex>
      </Box>
    );
  }

  render() {
    const { pageWrap } = this.props;

    // pageWrap is probably only temporary
    return pageWrap ? (
      <Page constrainWidth title="Onboarding">
        <Box p={4}>{this.getOnboardingContent()}</Box>
      </Page>
    ) : (
      <Fragment>{this.getOnboardingContent()}</Fragment>
    );
  }
}

export default Onboarding;
