import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { Box } from 'components/flexbox';

const ValidationErrorsOrHelpText = props => {
  const { field, helpText: helpTextProp, calloutStyle, style = {} } = props;

  const showError = field.hasError && (field.showPristineErrors || !field.pristine);
  const helpText = helpTextProp || field.helpText;

  if (showError || helpText) {
    if (calloutStyle) {
      const calloutClassName = classNames('pt-callout', {
        'pt-intent-danger': showError,
        'pt-intent-primary': !showError
      });

      return (
        <Box className={calloutClassName} p={2}>
          {showError &&
            field.errors.map(error => (
              <div className="pt-intent-danger-text" key={error}>
                {error}
              </div>
            ))}
          {!showError && helpText}
        </Box>
      );
    }

    return (
      <Box className="error-or-help" style={style}>
        {showError &&
          field.errors.map(error => (
            <div className="pt-form-helper-text pt-intent-danger" key={error}>
              {error}
            </div>
          ))}
        {!showError && helpText && <div className="pt-form-helper-text pt-text-muted">{helpText}</div>}
      </Box>
    );
  }

  return <div className="error-or-help" />;
};

export default observer(ValidationErrorsOrHelpText);
