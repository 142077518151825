import BaseModel from 'models/BaseModel';

export default class LoginModel extends BaseModel {
  get defaults() {
    return {
      user_email: '',
      password: ''
    };
  }
}
