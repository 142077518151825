import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box } from 'components/flexbox';

import FilterGroup from './FilterGroup';

@inject('$savedFilters')
@observer
class FiltersDisplay extends Component {
  render() {
    const { filters } = this.props;

    const hasFilters = filters && filters.filterGroups && filters.filterGroups.length > 0;

    if (!hasFilters) {
      return (
        <Box {...this.props} className="pt-text-muted">
          No filtering criteria has been applied
        </Box>
      );
    }

    /* eslint-disable react/no-array-index-key */
    return (
      <Box {...this.props}>
        {filters &&
          filters.filterGroups.map((group, index) => (
            <FilterGroup
              key={index}
              index={index}
              group={group}
              connector={filters.connector}
              isLastGroup={index === filters.filterGroups.length - 1}
            />
          ))}
      </Box>
    );
  }
}

export default FiltersDisplay;
