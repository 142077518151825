import React from 'react';
import { observer } from 'mobx-react';
import { Intent, Button, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { Box } from 'components/flexbox';
import RemoveButton from 'components/forms/actions/RemoveButton';
import { DropdownMenu } from 'components/index';

const DashboardItemControls = observer(({ loading, item: dashboardItem, onEdit, onClone }) => (
  <Box mr={0.5}>
    <Button
      className="pt-small"
      iconName="edit"
      text="Edit"
      onClick={onEdit}
      disabled={loading}
      style={{ marginRight: 4 }}
    />
    <DropdownMenu
      tetherOptions={{ offset: '0px 0px' }}
      content={
        <Menu>
          <MenuItem
            iconName="grid-view"
            text="This Dashboard"
            intent={Intent.PRIMARY}
            onClick={() => onClone('this')}
          />
          <MenuDivider />
          <MenuItem iconName="new-grid-item" text="Existing Dashboard" onClick={() => onClone('existing')} />
          <MenuItem iconName="plus" text="New Dashboard" onClick={() => onClone('new')} />
        </Menu>
      }
    >
      <Button className="pt-small" iconName="duplicate" text="Clone" disabled={loading} />
    </DropdownMenu>

    <RemoveButton model={dashboardItem} showIcon className="pt-small" intent={Intent.NONE} style={{ marginLeft: 4 }} />
  </Box>
));

export default DashboardItemControls;
