import React from 'react';
import { observer } from 'mobx-react';
import { formatDateTime } from 'util/dateUtils';
import { Box } from 'components/flexbox';

const LastUpdatedTag = observer(({ loading, dataview }) => {
  if (loading) {
    return null;
  }

  return (
    <Box ml={1} style={{ paddingTop: 2 }}>
      <small className="pt-text-muted">
        <strong>Last Updated:</strong> {formatDateTime(dataview.lastUpdated)}
      </small>
    </Box>
  );
});

export default LastUpdatedTag;
