import { computed, observable } from 'mobx';
import { capitalize } from 'lodash';
import { dateFormatter } from 'util/dateUtils';

import BaseModel from 'models/BaseModel';
import $dictionary from 'stores/$dictionary';
import UserCollection from 'models/users/UserCollection';
import DeviceCollection from 'models/devices/DeviceCollection';
import PlanCollection from './PlanCollection';
import NoteCollection from './NoteCollection';
import CompanyPermissions from './CompanyPermissions';
import AddOnCollection from '../addOns/AddOnCollection';

export default class Company extends BaseModel {
  @observable
  fetchingDetails = false;

  get urlRoot() {
    return '/api/sudo/company';
  }

  get defaults() {
    return {
      maxFps24h: -1
    };
  }

  deserialize(company) {
    company = super.deserialize(company);
    if (company) {
      const { id: companyId, devices, notes, plans, users, permissions, addOns } = company;
      company.devices = new DeviceCollection(devices);
      company.notes = new NoteCollection(notes, { companyId });
      company.plans = new PlanCollection(plans, { companyId, devices, hasFetched: true });
      company.users = new UserCollection(users);
      if (company.permissions) {
        company.permissions = new CompanyPermissions(permissions);
      }
      if (addOns) {
        company.addOns = new AddOnCollection(addOns);
      }
    }
    return company;
  }

  @computed
  get companyId() {
    return Number(this.id);
  }

  @computed
  get numActivePlans() {
    return (
      (!this.fetchingDetails &&
        this.get('plans') &&
        this.get('plans').models.filter(plan => plan.get('active')).length) ||
      0
    );
  }

  @computed
  get numDevices() {
    const { devices } = this.get();
    return (devices && devices.length) || 0;
  }

  @computed
  get numUsers() {
    const { users } = this.get();
    return (users && users.length) || 0;
  }

  @computed
  get trialEndDate() {
    const { trial_end_date, company_plan_id } = this.get();
    return trial_end_date && company_plan_id === 'TRIAL' ? dateFormatter({ value: trial_end_date }) : 'N/A';
  }

  @computed
  get companyPlan() {
    return capitalize(this.get('company_plan_id'));
  }

  @computed
  get isKentik() {
    return this.get('company_name').includes('kentik');
  }

  @computed
  get isActiveTrial() {
    const { company_plan_id, company_status } = this.get();
    return company_plan_id === 'TRIAL' && company_status === 'V';
  }

  @computed
  get isExpiredTrial() {
    return this.get('company_plan_id') === 'TRIAL' && this.get('company_status') === 'EXP';
  }

  @computed
  get isSuspendedCompany() {
    return this.get('company_status') === 'SUSP';
  }

  @computed
  get isActiveCustomer() {
    return (
      this.get('company_plan_id') === 'CUSTOMER' &&
      this.get('company_status') === 'V' &&
      !this.get('company_name').includes('auvik')
    );
  }

  @computed
  get isFreePlan() {
    return this.get('company_plan_id') === 'FREE' && this.get('company_status') === 'V';
  }

  @computed
  get lastLogin() {
    const { users } = this.get();
    return users && users.map(user => user.last_login).sort()[0];
  }

  @computed
  get platformLabel() {
    const platformKey = this.get('platform');
    if (platformKey) {
      const result = Object.values($dictionary.dictionary.platforms).find(p => p.key === platformKey);
      return result ? result.label : $dictionary.dictionary.platforms.CUSTOM.label;
    }
    return $dictionary.dictionary.platforms.CUSTOM.label;
  }

  /**
   * Just returns the first user in a company that is an Admin or Super Admin. We allow you to specify the user to spoof as,
   * but as a default we just select an Admin for now. At least one admin or super admin must exist in a company to be able
   * to spoof to this company!!!
   */
  @computed
  get spoofAsUser() {
    const { users } = this.get();
    return users && users.models.find(user => user.get('user_level') >= 1);
  }

  get messages() {
    return {
      create: `Company ${this.get('company_name')} was added successfully`,
      update: `Company ${this.get('company_name')} was updated successfully`,
      destroy: `Company ${this.get('company_name')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Company',
      text: `Are you sure you want to remove ${this.get('company_name')}?`
    };
  }

  getCompanyStatusMap() {
    return {
      V: 'Active',
      EXP: 'Expired',
      INI: 'Initializing',
      INACTIVE: 'Inactive',
      SUSP: 'Suspended'
    };
  }

  getCompanyStatusOptions() {
    return [
      { value: 'V', label: 'Active' },
      { value: 'EXP', label: 'Expired' },
      { value: 'INACTIVE', label: 'Inactive' },
      { value: 'SUSP', label: 'Suspended' }
    ];
  }

  getAccountTypeOptions() {
    return [
      { value: 'CUSTOMER', label: 'Customer' },
      { value: 'TRIAL', label: 'Trial' },
      { value: 'FREE', label: 'Free' }
    ];
  }

  getPlatformOptions() {
    const { platforms } = $dictionary.dictionary;
    return Object.values(platforms).map(platform => ({ value: platform.key, label: platform.label }));
  }
}
