import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { AnchorButton } from '@blueprintjs/core';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';

import { getOption } from './selectHelpers';

const DragHandle = SortableHandle(() => (
  <AnchorButton
    className="pt-minimal pt-small"
    style={{ cursor: 'move', margin: '0 5px 0 0' }}
    iconName="drag-handle-horizontal"
  />
));

export const ModalValueItem = props => {
  const {
    reorderable,
    option,
    onClose,
    valueRenderer: SingleValue,
    itemStyle,
    showCard,
    readOnly,
    itemFlexed = true
  } = props;

  const itemClassName = classNames({
    'pt-menu-item': showCard || !readOnly,
    draggable: reorderable
  });

  return (
    <Flex justify="space-between" align="center" className={itemClassName} style={itemStyle}>
      {reorderable && <DragHandle />}
      <Flex flexColumn flexAuto={itemFlexed} style={{ overflow: 'hidden' }}>
        <SingleValue {...props} onUnselect={onClose} option={option} style={{ marginBottom: 0 }} />
      </Flex>
    </Flex>
  );
};

const ModalValueList = props => {
  const { values, options, noSelectedValuesText, onClose, reorderable } = props;

  const ValueItem = reorderable ? SortableElement(ModalValueItem) : ModalValueItem;

  return (
    <div className="pt-input-group">
      {values.map((value, index) => {
        const valueOption = getOption(options, value) || {
          value,
          label: value,
          className: 'pt-intent-critical',
          group: 'Invalid'
        };

        return <ValueItem key={value} {...props} option={valueOption} onClose={() => onClose(index)} index={index} />;
      })}
      {values.length === 0 && <div className="pt-text-muted pt-callout pt-text-center">{noSelectedValuesText}</div>}
    </div>
  );
};

@observer
class SelectedValuesPanel extends Component {
  static defaultProps = {
    selectedValuesTitle: 'Selected Items',
    noSelectedValuesText: 'No items selected',
    showCard: true,
    allowSelection: true,
    itemStyle: {},
    reorderable: false
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { values, onChange } = this.props;
    onChange(arrayMove(values, oldIndex, newIndex));
  };

  onCloseItem = index => {
    const { values, onChange } = this.props;
    onChange(values.slice(0, index).concat(values.slice(index + 1)));
  };

  render() {
    const { reorderable, selectedValuesTitle, showCard, disabled } = this.props;

    const ValueList = reorderable ? SortableContainer(ModalValueList) : ModalValueList;
    const valueList = (
      <ValueList {...this.props} lockAxis="y" onClose={this.onCloseItem} onSortEnd={this.onSortEnd} useDragHandle />
    );

    if (!showCard) {
      return valueList;
    }

    const cardClassName = classNames('pt-card flat overflow-hidden', { 'pt-disabled': disabled });

    return (
      <Flex flexAuto flexColumn className={cardClassName}>
        <Box col={12} className="pt-menu-item pt-active pt-menu-item-group-title">
          <strong>{selectedValuesTitle}</strong>
        </Box>
        <Box p={1} flexAuto style={{ overflow: 'auto' }}>
          {valueList}
        </Box>
      </Flex>
    );
  }
}

export default SelectedValuesPanel;
