import { observer } from 'mobx-react';
import { getToFixed, zeroToText, adjustByGreekPrefix, escapeHtml } from 'util/utils';
import $dictionary from 'stores/$dictionary';
import $app from 'stores/$app';
import Highcharts from 'highcharts';
import Sunburst from 'highcharts/modules/sunburst';
import BaseHighchartsDataview from './BaseHighchartsDataview';

Sunburst(Highcharts);

@observer
export default class SunburstView extends BaseHighchartsDataview {
  chartOptions = {
    chart: {
      className: 'sunburst',
      events: {
        redraw() {
          const button = this.container.querySelector('.highcharts-button');
          if (button) {
            // HC only uses a tspan if the text has at least one whitespace in it.
            // So we do this instead of trying to select text > tspan in order to
            // cover cases where the button text has no whitespace.
            button.querySelector('text').innerHTML = '<tspan>Zoom Out</tspan>';
            button.querySelector('rect').setAttribute('width', 68);
            button.setAttribute(
              'transform',
              `translate(${this.container.querySelector('.highcharts-plot-border').getAttribute('width') - 70}, 20)`
            );
          }
        }
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: true,
      position: { align: 'center' },
      style: { cursor: 'default' },
      href: null,
      text: 'Click to Zoom In/Out'
    },
    legend: { enabled: false },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter() {
            if (this.key.startsWith('Total of Top')) {
              return '';
            }
            return this.key;
          },
          filter: {
            property: 'innerArcLength',
            operator: '>',
            value: 16
          }
        }
      }
    },
    series: [
      {
        type: 'sunburst',
        allowDrillToNode: true,
        cursor: 'pointer',
        data: [],
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: 'outerArcLength',
                operator: '>',
                value: 64
              }
            }
          },
          {
            level: 2,
            colorByPoint: true
          },
          {
            level: 3,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          },
          {
            level: 4,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          },
          {
            level: 5,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          },
          {
            level: 6,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          },
          {
            level: 7,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          },
          {
            level: 8,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          }
        ]
      }
    ],
    tooltip: {
      formatter() {
        const { prefix, suffix, unit } = this.point.options;
        const val = escapeHtml(zeroToText(adjustByGreekPrefix(this.point.value, prefix), { fix: getToFixed(unit) }));
        return `${this.key}:<br/><b>${val} ${prefix || ''}${suffix}</b>`;
      }
    }
  };

  buildSeriesInternal(bucket, models) {
    this.clear();

    if (!this.chart) {
      return;
    }

    const query = bucket.firstQuery;
    const metric = query.get('metric');
    const outsort = query.get('outsort');
    const units = query.get('units');
    const topx = query.get('topx');
    const { aggregates, outsortUnit, outsortDataKey } = query;
    const { dictionary } = $dictionary;
    const prefix = bucket.queryResults.prefix[outsortUnit];
    let suffix = dictionary.units[outsortUnit];
    if (units.includes('sample_rate')) {
      suffix = ` : 1 ${suffix}`;
    }

    const [series] = this.chart.series;
    const nodes = [
      {
        id: 'node0',
        parent: '',
        name: `Total of Top ${topx}`,
        prefix,
        suffix,
        color: 'transparent'
      }
    ];

    const aspathMetrics = metric.reduce(
      (arr, mtrc, index) => (mtrc.includes('bgp_aspath') ? arr.concat([index]) : arr),
      []
    );

    let colorCounter = 0;
    models.forEach(model => {
      const { lookup, key, isOverlay } = model.get();
      let data = model.get(outsortDataKey);
      if (units.includes('sample_rate') && aggregates.find(agg => agg.name === outsort).fn !== 'percentile') {
        data /= 100;
      }

      if (!isOverlay) {
        const path = (lookup || key).split(' ---- ').slice(0, metric.length);
        if (aspathMetrics.length) {
          aspathMetrics.forEach(index => {
            path.splice(index, 1, ...path[index].split(' '));
          });
        }
        path.reduce((parent, name, index) => {
          let node = nodes.find(n => n.name === name && n.parent === parent);
          if (!node) {
            node = { id: `node${nodes.length}`, parent, name, prefix, suffix };
            if (index === 0) {
              node.color = this.qualitativeColors[colorCounter % this.qualitativeColors.length];
              colorCounter += 1;
            }
            nodes.push(node);
          }
          if (index === path.length - 1) {
            node.value = node.value || 0;
            node.value += data;
          }
          return node.id;
        }, 'node0');
      }
    });

    series.setData(nodes, false);

    $app.renderSync(() => {
      if (this.chart) {
        this.chart.redraw();
      }
    });
    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  redraw({ setColors = false } = {}) {
    if (this.chart) {
      if (setColors && this.chart.series[0].points) {
        this.chart.series[0].points.reduce((counter, node) => {
          if (node.parent === 'node0') {
            node.update(
              {
                color: this.qualitativeColors[counter % this.qualitativeColors.length]
              },
              false
            );
            return counter + 1;
          }
          return counter;
        }, 0);
      }
      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
        }
      });
      $app.renderSync(() => {
        this.dismissSpinner();
      });
    }
  }

  clear() {
    if (this.chart) {
      const [series] = this.chart.series;
      if (series.drillUpButton) {
        series.drillToNode('');
      }
      series.setData([]);
    }
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const outsort = activeBuckets[0].firstQuery.get('outsort');
    if (outsort.includes('perc_') || outsort.includes('latency')) {
      throw new Error('Sunburst is incompatible with your selected units.');
    }

    if (chartOptions.plotOptions && chartOptions.plotOptions.sunburst) {
      const { sunburst } = chartOptions.plotOptions;

      if (this.props.onModelSelect) {
        if (!sunburst.point) {
          sunburst.point = { events: {} };
        }
        sunburst.point.events.click = this.getLegendItemClick();
      }
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: false,
  showLegend: true,
  timeBased: false,
  enableToggle: false,
  buckets: [
    {
      name: 'Sunburst'
    }
  ]
};

export { config };
