import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import { formatAlertValue } from '../alarmHelpers';
import BaselineCell from './BaselineCell';

const AlertValueCell = props => {
  const { model, values } = props;
  const cellValues = model ? model.get() : values;
  const { alert_value, alert_value2nd, alert_value3rd, alert_metric, row_type } = cellValues;

  if (row_type === 'Mitigation') {
    return <span>N/A</span>;
  }

  if (!alert_metric) {
    return null;
  }

  const emptyValues = [undefined, null];

  return (
    <Fragment>
      {!emptyValues.includes(alert_value) &&
        alert_metric[0] && (
          <div className="pt-strong pt-text-overflow-ellipsis">{formatAlertValue(alert_value, alert_metric[0])}</div>
        )}
      {!emptyValues.includes(alert_value2nd) > 0 &&
        alert_metric[1] && (
          <div className="pt-text-overflow-ellipsis">{formatAlertValue(alert_value2nd, alert_metric[1])}</div>
        )}
      {!emptyValues.includes(alert_value3rd) > 0 &&
        alert_metric[2] && (
          <div className="pt-text-overflow-ellipsis">{formatAlertValue(alert_value3rd, alert_metric[2])}</div>
        )}
      {model && <BaselineCell model={model} />}
    </Fragment>
  );
};

export default observer(AlertValueCell);
