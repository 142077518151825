import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { orderBy } from 'lodash';

import { Field, Select } from 'components/forms';

@inject('$dashboards')
@observer
class DashboardSelector extends Component {
  static defaultProps = {
    onlyEditable: false,
    showPresets: true,
    menuWidth: 300,
    placeholder: 'Select a dashboard...',
    tetherOptions: {
      offset: '-3px 0',
      constraints: [{ attachment: 'together', pin: true, to: 'scrollParent' }]
    }
  };

  render() {
    const { $dashboards, onlyEditable, showPresets, hiddenDashboards, menuWidth, tetherOptions } = this.props;

    let dashboardOptions = $dashboards.dashboards;

    if (onlyEditable) {
      dashboardOptions = dashboardOptions.filter(dash => dash.canEdit);
    }

    if (!showPresets) {
      dashboardOptions = dashboardOptions.filter(dash => !dash.isPreset);
    }

    if (hiddenDashboards) {
      dashboardOptions = dashboardOptions.filter(dash => hiddenDashboards.indexOf(dash.id) === -1);
    }

    const dashboards = orderBy(
      dashboardOptions,
      [model => model.getSortValue('sortableShareLevel'), model => model.getSortValue('dash_title')],
      ['asc']
    );

    const options = $dashboards.getDashboardOptions(dashboards);

    return (
      <Field {...this.props} options={options} showFilter>
        <Select showFilter menuWidth={menuWidth} tetherOptions={tetherOptions} />
      </Field>
    );
  }
}

export default DashboardSelector;
