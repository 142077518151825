import React from 'react';
import { observer } from 'mobx-react';
import { Collapse } from '@blueprintjs/core';
import classNames from 'classnames';
import $dictionary from 'stores/$dictionary';
import { Box } from 'components/flexbox';

import ModalOptionGroupTitle from './ModalOptionGroupTitle';
import ModalOptionItem from './ModalOptionItem';

const subSectionClassMap = {
  'Amazon Web Services': 'aws',
  'Google Cloud Platform': 'gcp',
  'Microsoft Azure': 'azure'
};

const threeColMap = ['src', 'dst', 'full'];
const fourColMap = ['src', 'dst', 'src_or_dst', 'full'];

function getSubGroupClassName(col, numColumns) {
  return numColumns === 4 ? fourColMap[col] : threeColMap[col];
}

function getSubtypeClass(subGroupName) {
  const subtype = Object.values($dictionary.dictionary.device_subtypes).find(
    deviceSubtype => deviceSubtype.display_name === subGroupName
  );
  if (subtype) {
    return subtype.icon;
  }
  return undefined;
}

const ModalOptionGroup = props => {
  const {
    disabled,
    disabledValues = [],
    className,
    group,
    maxColumns = 3,
    options,
    optionTitleRenderer,
    selectState,
    isSubGroup = false,
    isSubSection = false,
    collapsedState,
    collapsed = false
  } = props;
  const { values } = selectState;

  const hasSubGroups = options.some(option => Array.isArray(option) || !option.value);

  let numColumns;
  if (!isSubGroup) {
    numColumns = options.filter(option => Array.isArray(option)).length;
  }

  const GroupTitle = optionTitleRenderer || ModalOptionGroupTitle;
  const groupClassName = classNames({
    'modal-options-subgroup': isSubSection,
    'modal-options-group': !isSubGroup && !isSubSection,
    'overflow-hidden': isSubGroup
  });
  const optionsClassName = classNames({
    [`column-count-${maxColumns}`]: !isSubGroup,
    flex: hasSubGroups,
    wrap: hasSubGroups
  });

  return (
    <Box key={group} className={groupClassName} flexAuto={isSubGroup}>
      <div className={className}>
        {!isSubGroup && <GroupTitle {...props} className={classNames(props.className, getSubtypeClass(group))} />}
        <Collapse isOpen={!collapsed}>
          <Box className={optionsClassName}>
            {options.map((option, idx) => {
              if (Array.isArray(option)) {
                const key = getSubGroupClassName(idx, numColumns);
                let subGroupClass;
                if (option.length > 0) {
                  subGroupClass = option[0].excludeGroupClassName ? 'none' : key;
                }

                return (
                  <ModalOptionGroup
                    {...props}
                    key={key}
                    options={option}
                    isSubGroup
                    isSubSection={false}
                    className={subGroupClass}
                  />
                );
              } else if (!option.value) {
                return Object.keys(option).map(subGroup => (
                  <ModalOptionGroup
                    {...props}
                    key={subGroup}
                    group={subGroup}
                    options={option[subGroup]}
                    className={subSectionClassMap[subGroup]} // add subtypecheck here
                    isSubSection
                    collapsed={collapsedState[subGroup]}
                    disabled={disabled || disabledValues.includes(subGroup)}
                  />
                ));
              }

              return (
                <ModalOptionItem
                  {...props}
                  {...option}
                  key={option.value}
                  selected={values.includes(option.value)}
                  disabled={disabled || disabledValues.includes(option.value)}
                />
              );
            })}
          </Box>
        </Collapse>
      </div>
    </Box>
  );
};

export default observer(ModalOptionGroup);
