import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { formatDate } from 'util/dateUtils';
import ResultRow from 'views/Search/ResultRow';

@inject('$alerts')
class SilentMode extends Component {
  title = 'Silent Mode';

  url = '/alerting/silent-mode';

  render() {
    const { result, $alerts } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="cog"
        url={this.url}
        nameField="comment"
        collection={$alerts.silentModeKeys}
        {...this.props}
      >
        <span>expires: {formatDate(result.expire)}</span>
      </ResultRow>
    );
  }
}

export default SilentMode;
