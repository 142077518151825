import moment from 'moment';
import { invert } from 'lodash';
import $dictionary from 'stores/$dictionary';
import $devices from 'stores/$devices';
import { nestFilterGroup, deepClone } from 'util/utils';
import { PERCENT_METRICS } from 'util/constants';
import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

export function getHistoryFilters(filterBy, filterVal) {
  const validFilterFields = [
    'alert_id',
    'alarm_id',
    'alert_key',
    'alert_key_partial',
    'dimension_key',
    'mitigation_id',
    'old_state',
    'new_state',
    'any_state'
  ];
  const filters = {};
  if (filterVal && validFilterFields.indexOf(filterBy) !== -1) {
    filters.filterBy = filterBy;
    filters.filterVal = filterVal;
  }
  // lookback 30 days
  // const startTime = translateFromUTCForUser(moment().utc()).add(-30, 'days').format('YYYY-MM-DD HH:mm:00Z');
  // const endTime = translateFromUTCForUser(moment().utc()).format('YYYY-MM-DD HH:mm:00Z');
  filters.startTime = moment()
    .utc()
    .add(-30, 'days')
    .format('YYYY-MM-DD HH:mm:00Z');
  filters.endTime = moment()
    .utc()
    .format('YYYY-MM-DD HH:mm:00Z');
  return filters;
}

export function formatAlertValue(alert_value, alert_metric) {
  let alert_metric_display = $dictionary.dictionary.units[alert_metric] || alert_metric;
  let fix = 0;
  if (alert_metric === 'bits') {
    alert_value /= 1000000;
    fix = 2;
    alert_metric_display = 'Mbps';
  } else if (alert_metric === 'packets') {
    alert_value /= 1000;
    fix = 2;
    alert_metric_display = 'kpps';
  } else if (PERCENT_METRICS.includes(alert_metric)) {
    fix = 4;
  }
  return `${alert_value.toFixed(fix)} ${alert_metric_display}`;
}

// export function getHistoryRowColor(model) {
//   const row_type = model.get('row_type');
//   const new_alarm_state = model.get('new_alarm_state');
//   const old_alarm_state = model.get('old_alarm_state');
//   const row_color = '';
//   // if (row_type === 'Alarm') {
//   //   if (old_alarm_state === 'ALARM' && new_alarm_state === 'ACK_REQ') {
//   //     row_color = 'row row-blue';
//   //   } else if (new_alarm_state === 'ALARM') {
//   //     row_color = 'row row-red';
//   //   } else if (new_alarm_state === 'CLEAR') {
//   //     row_color = 'row row-green';
//   //   }
//   // }
//   // if (row_type === 'Match') {
//   //   row_color = 'row row-gray';
//   // }
//   // if (row_type === 'Mitigation') {
//   //   if (new_alarm_state === 'MITIGATING') {
//   //     row_color = 'row row-dark-red';
//   //   } else if (new_alarm_state === 'CLEAR') {
//   //     row_color = 'row row-dark-green';
//   //   } else if (new_alarm_state === 'MITIGATING_FAIL') {
//   //     row_color = 'row row-dark-gray';
//   //   } else {
//   //     row_color = 'row row-dark-blue';
//   //   }
//   // }
//   return row_color;
// }

// export function getAlarmsRowColor(model) {
//   const row_type = model.get('row_type');
//   const alarm_state = model.get('alarm_state');
//   const row_color = 'row';
//   // if (row_type === 'Alarm' && alarm_state === 'ACK_REQ') {
//   //   row_color = 'row row-blue';
//   // } else if (row_type === 'Alarm' && alarm_state === 'ALARM') {
//   //   row_color = 'row row-red';
//   // }
//   // if (row_type === 'Mitigation' && alarm_state === 'MITIGATING') {
//   //   row_color = 'row row-dark-red';
//   // } else if (row_type === 'Mitigation' && alarm_state === 'ACK_REQ') {
//   //   row_color = 'row row-dark-blue';
//   // } else if (row_type === 'Mitigation' && alarm_state === 'MITIGATING_FAIL') {
//   //   row_color = 'row row-dark-gray';
//   // } else if (row_type === 'Mitigation') {
//   //   row_color = 'row row-dark-red';
//   // }
//   return row_color;
// }

export function getQueryVariable(options) {
  const { location, param } = options;
  const query = location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === param) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}

// change from int to valid MAC address
function convertMacAddress(mac) {
  mac = parseInt(mac, 10);
  let converted_mac;
  if (mac && mac.toString(16)) {
    let macHex = mac.toString(16);
    macHex = '000000000000'.substring(0, 12 - macHex.length) + macHex;
    converted_mac = macHex.match(/.{1,2}/g).join(':');
  }
  return converted_mac || mac;
}

export function deserializeCidrDimension(dim) {
  if (dim.indexOf('_cidr_') >= 0) {
    const prefix = $dictionary.dictionary.cidrMetrics.find(m => dim.startsWith(m));
    if (prefix) {
      return prefix;
    }
  }
  return dim;
}

function getRowFilter({ alert_dimension, alert_key, alert_key_lookup }) {
  const filtersDict = {
    InterfaceID_dst: 'output_port',
    InterfaceID_src: 'input_port',
    Proto: 'protocol',
    device_id: 'i_device_id',
    AS_src: 'src_as',
    AS_dst: 'dst_as',
    Geography_src: 'src_geo',
    Geography_dst: 'dst_geo',
    Port_src: 'l4_src_port',
    Port_dst: 'l4_dst_port',
    VLAN_src: 'vlan_in',
    VLAN_dst: 'vlan_out',
    IP_src: 'inet_src_addr',
    IP_dst: 'inet_dst_addr',
    src_nexthop_ip: 'ipv4_src_next_hop',
    src_nexthop_asn: 'src_nexthop_as',
    dst_nexthop_ip: 'ipv4_dst_next_hop',
    dst_nexthop_asn: 'dst_nexthop_as',
    tcp_flags: 'tcp_flags_raw'
  };

  const { cidrMetrics } = $dictionary.dictionary;

  // add a filter for alert_key & dimensions
  const alert_dimension_array = alert_dimension.split(':');
  const alert_key_array = alert_key.split('__##__');
  const alert_key_lookup_arr = alert_key_lookup.split('__##__');

  const row_filter = {
    connector: 'All',
    not: false,
    metric: [],
    filters: []
  };
  const operatorDict = $dictionary.dictionary.queryFilters.operators;
  for (let i = 0; i < alert_dimension_array.length; i += 1) {
    let cur_dimension = alert_dimension_array[i];
    const cur_key = alert_key_array[i];
    const cur_key_lookup = alert_key_lookup_arr[i];
    if (cur_dimension.indexOf('_prefix_len') !== -1) {
      const values = cur_key.split('/');
      const isSrc = cur_dimension.indexOf('src') === 0;
      row_filter.filters.push({
        filterField: isSrc ? 'inet_src_route_prefix' : 'inet_dst_route_prefix',
        operator: 'ILIKE',
        filterValue: values[0]
      });
      row_filter.filters.push({
        filterField: isSrc ? 'src_route_length' : 'dst_route_length',
        operator: '=',
        filterValue: values[1]
      });
    } else if (cur_dimension !== 'Traffic') {
      let filterValue =
        cur_dimension === 'src_eth_mac' || cur_dimension === 'dst_eth_mac'
          ? convertMacAddress(parseInt(cur_key, 10))
          : cur_key;
      if (cur_dimension.indexOf('_cidr_') >= 0) {
        cur_dimension = deserializeCidrDimension(cur_dimension);
      }
      // change _ to : for ipv6 keys
      if (cidrMetrics.indexOf(cur_dimension) > -1) {
        filterValue = filterValue.replace(/_/g, ':');
      }
      if ((cur_dimension === 'src_geo_region' || cur_dimension === 'dst_geo_region') && cur_key !== cur_key_lookup) {
        filterValue = cur_key_lookup;
      }
      cur_dimension = filtersDict[cur_dimension] || cur_dimension;
      row_filter.filters.push({
        filterField: cur_dimension,
        operator: invert(operatorDict[cur_dimension]).equals || '=',
        filterValue
      });
    }
  }
  return row_filter;
}

export function getQueryDeviceName(alert_dimension, alert_key) {
  if (alert_dimension.includes('i_device_id')) {
    const dimensions = alert_dimension.split(':');
    const keys = alert_key.split('__##__');
    const deviceIdIndex = dimensions.findIndex(dim => dim === 'i_device_id');
    // we should always have an index here, but just to be safe...
    if (deviceIdIndex > -1 && keys[deviceIdIndex] !== undefined) {
      const deviceId = keys[deviceIdIndex];
      const device = $devices.deviceSummaries.find(d => d.id.toString() === deviceId);
      if (device) {
        return [device.device_name];
      }
    }
  }

  return null;
}

export function getQueryUnits(alert_metric) {
  // map units to correct value, currently only bits is different
  // ['bytes', 'in_bytes', 'out_bytes', 'packets', 'in_packets', 'out_packets', 'fps', 'unique_src_ip', 'unique_dst_ip']
  const unitsDict = {
    bits: 'bytes',
    unique_src_region: 'unique_src_geo_region',
    unique_dst_region: 'unique_dst_geo_region',
    unique_src_city: 'unique_src_geo_city',
    unique_dst_city: 'unique_dst_geo_city',
    retransmits_in: 'retransmits_out',
    perc_retransmits_in: 'perc_retransmits_out'
  };

  let queryUnits = (alert_metric && alert_metric[0]) || '';
  queryUnits = unitsDict[alert_metric[0]] || queryUnits || 'bytes';
  return queryUnits;
}

export function getQueryTimeRange({ alarm_start, alarm_end, alarm_start_time, ctime }) {
  let query_start;
  let query_end;

  if (alarm_start && alarm_end && moment(alarm_start).isValid() && moment(alarm_end).isValid()) {
    const hoursDiff = moment.duration(moment(alarm_end).diff(moment(alarm_start))).asHours();
    const timeMargin = hoursDiff > 24 ? 180 : 60;
    query_start = moment
      .utc(alarm_start)
      .subtract(timeMargin, 'minutes')
      .format(DEFAULT_DATETIME_FORMAT);
    query_end =
      moment.utc(alarm_end).add(timeMargin, 'minutes') > moment.utc()
        ? moment.utc().format(DEFAULT_DATETIME_FORMAT)
        : moment
            .utc(alarm_end)
            .add(timeMargin, 'minutes')
            .format(DEFAULT_DATETIME_FORMAT);
  } else {
    // Start 45 minutes back and if "now" is more than 4 hours from the start of the attack,
    // then only look at 4 hours after the start (total width 4:45)
    const start = alarm_start || alarm_start_time || ctime;
    query_start = moment
      .utc(start)
      .subtract(45, 'minutes')
      .format(DEFAULT_DATETIME_FORMAT);
    query_end =
      moment.utc(start).add(240, 'minutes') > moment.utc()
        ? moment.utc().format(DEFAULT_DATETIME_FORMAT)
        : moment
            .utc(start)
            .add(240, 'minutes')
            .format(DEFAULT_DATETIME_FORMAT);
  }
  return {
    query_start,
    query_end
  };
}

export function getAlarmFilters({ policyFilter, alert_key, alert_key_lookup, alert_dimension }) {
  const row_filter = getRowFilter({ alert_dimension, alert_key, alert_key_lookup });
  const alarmFilter = deepClone(policyFilter);

  if (alarmFilter.connector === 'Any') {
    nestFilterGroup(alarmFilter);
  }
  if (row_filter && row_filter.filters.length) {
    alarmFilter.filterGroups.push(row_filter);
  }

  // add cleanup for legacy filter objects
  delete alarmFilter.filterString;
  delete alarmFilter.custom;
  alarmFilter.filterGroups.forEach(group => {
    delete group.filterString;
    delete group.id;
  });

  return alarmFilter;
}

export function getFallbackName(baseline_used) {
  const fallback_enum = {
    0: 'NO_USE_BASELINE',
    1: 'SKIPED_BASELINE_CALCULATION',
    2: 'TRIGGER_USED_NO_BASELINE',
    3: 'CALCULATED_USED_FOR_BASELINE',
    4: 'DEFAULT_USED_FOR_BASELINE',
    5: 'LOWEST_USED_FOR_BASELINE',
    6: 'HIGHEST_USED_FOR_BASELINE',
    7: 'NOT_FOUND_EXISTS_NO_BASELINE',
    8: 'ACT_CURRENT_MISSING_SKIP',
    9: 'ACT_CURRENT_MISSING_TRIGGER',
    10: 'ACT_CURRENT_USED_FOUND',
    11: 'ACT_CURRENT_MISSING_DEFAULT',
    12: 'ACT_CURRENT_MISSING_LOWEST',
    13: 'ACT_CURRENT_MISSING_HIGHEST',
    14: 'ACT_CURRENT_NOT_FOUND_EXISTS',
    15: 'ACT_BASELINE_MISSING_DEFAULT_INSTEAD_OF_LOWEST',
    16: 'ACT_BASELINE_MISSING_DEFAULT_INSTEAD_OF_HIGHEST',
    17: 'ACT_CURRENT_MISSING_DEFAULT_INSTEAD_OF_LOWEST',
    18: 'ACT_CURRENT_MISSING_DEFAULT_INSTEAD_OF_HIGHEST'
  };
  return fallback_enum[baseline_used] || '';
}

export function getBaselineAggrField({ bl, prefix = 'AlertValue' }) {
  // address inconsistency in field naming from backend data
  const final_aggr = bl && bl.hour_agg && bl.hour_agg.toUpperCase();
  let aggr_field;
  let aggr_title;

  switch (final_aggr) {
    case 'REL_P05':
      aggr_field = `${prefix}05`;
      aggr_title = 'Value p05th';
      break;
    case 'REL_P10':
      aggr_field = `${prefix}10`;
      aggr_title = 'Value p10th';
      break;
    case 'REL_P25':
      aggr_field = `${prefix}25`;
      aggr_title = 'Value p25th';
      break;
    case 'REL_P50':
      aggr_field = `${prefix}50`;
      aggr_title = 'Value p50th';
      break;
    case 'REL_P75':
      aggr_field = `${prefix}75`;
      aggr_title = 'Value p75th';
      break;
    case 'REL_P95':
      aggr_field = `${prefix}95`;
      aggr_title = 'Value p95th';
      break;
    case 'REL_P98':
      aggr_field = `${prefix}98`;
      aggr_title = 'Value p98th';
      break;
    case 'REL_MAX':
      aggr_field = prefix === 'AlertValue' ? `${prefix}Max` : `${prefix}max`;
      aggr_title = 'Value Max';
      break;
    case 'REL_MIN':
      aggr_field = prefix === 'AlertValue' ? `${prefix}Min` : `${prefix}min`;
      aggr_title = 'Value Min';
      break;
    default:
      break;
  }
  return { aggr_field, aggr_title };
}
