import React from 'react';
import { observer } from 'mobx-react';
import { Tag } from '@blueprintjs/core';
import { Box, Flex } from 'components/flexbox';
import { Field, Input, Switch } from 'components/forms';
import classNames from 'classnames';
import { ALERT_SEVERITY_DISPLAY, ALERT_SEVERITY_INTENT } from 'models/alerting/Scoreboard';

export const ColorBlock = observer(({ threshold }) => (
  <Tag
    className="pt-large"
    style={{
      width: 20,
      height: 20,
      padding: 0,
      minWidth: 'auto',
      boxShadow: 'inset 4px 4px 0px rgba(255, 255, 255, 0.5)',
      background: threshold && threshold.enabled ? threshold.color : '#5c7080'
    }}
  >
    &nbsp;
  </Tag>
));

const ScoreboardThreshold = observer(({ threshold, index, onEnableDisable }) => {
  const min = threshold.min && threshold.min.getValue();
  const above = threshold.above && threshold.above.getValue();
  const color = threshold.color.getValue();

  const enabled = threshold.enabled.getValue();

  return (
    <Box mb={1}>
      <Flex flexAuto>
        <Flex justify="center" align="center" mr={1}>
          <ColorBlock threshold={{ enabled, color }} />
        </Flex>
        {min && (
          <Flex align="center" className={classNames({ 'pt-text-muted': !enabled })}>
            <span style={{ marginRight: 8 }}>At least</span>
            <Field disabled={!enabled} field={threshold['min.value']} className="no-margin" inputStyle={{ width: 140 }}>
              <Input
                rightElement={
                  <Tag className={ALERT_SEVERITY_INTENT[min.type]}>
                    <span className="small-caps">{ALERT_SEVERITY_DISPLAY[min.type]}</span>
                  </Tag>
                }
              />
            </Field>
          </Flex>
        )}
        {above &&
          threshold['above.value'].getValue() && (
            <Flex align="center" ml={1} className={classNames({ 'pt-text-muted': !enabled })}>
              <span style={{ marginRight: 8 }}>or above</span>
              <Field
                disabled={!enabled}
                field={threshold['above.value']}
                className="no-margin"
                inputStyle={{ width: 140 }}
              >
                <Input
                  rightElement={
                    <Tag className={ALERT_SEVERITY_INTENT[above.type]}>
                      <span className="small-caps">{ALERT_SEVERITY_DISPLAY[above.type]}</span>
                    </Tag>
                  }
                />
              </Field>
            </Flex>
          )}
        <Flex ml={1} flexAuto align="center" justify="flex-end">
          <Field
            field={threshold.enabled}
            className="no-margin"
            onChange={field => onEnableDisable(field.value, index)}
          >
            <Switch switchLabel={enabled ? 'Enabled' : 'Disabled'} />
          </Field>
        </Flex>
      </Flex>
    </Box>
  );
});

export default ScoreboardThreshold;
