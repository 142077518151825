// import { computed, action } from 'mobx';
import BaseModel from 'models/BaseModel';

class DeviceGroup extends BaseModel {
  get urlRoot() {
    return '/api/portal/deviceGroups';
  }

  get jsonRoot() {
    return 'device';
  }

  get defaults() {
    return {
      device_snmp_v3_conf_enabled: false,
      sending_ips: [''],
      cdn_attr: 'N',
      bgpPeerIP4: null,
      bgpPeerIP6: null
    };
  }

  get messages() {
    return {
      create: `Device ${this.get('device_name')} was added successfully`,
      update: `Device ${this.get('device_name')} was updated successfully`,
      destroy: `Device ${this.get('device_name')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Device',
      text: `Are you sure you want to remove ${this.get('device_name')}?`
    };
  }
}

export default DeviceGroup;
