import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Spinner, Hotkeys, Hotkey, HotkeysTarget } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import CollapseSidebarButton from './CollapseSidebarButton';

const keyIndexMap = {
  0: 'q',
  1: 'w',
  2: 'e',
  3: 'r',
  4: 't'
};

@HotkeysTarget
@observer
export default class Sidebar extends Component {
  state = {
    sidebarScrollPosition: 0,
    showCollapseButton: false
  };

  renderHotkeys() {
    const { sidebar } = this.props;
    const { collapsed, collapsible, handleToggleCollapsed } = sidebar;

    return (
      <Hotkeys>
        {collapsible && (
          <Hotkey
            global
            group="Sidebar"
            combo="b"
            label={collapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
            onKeyDown={handleToggleCollapsed}
          />
        )}
        {/* only enable these keyboard controls when the Sidebar is collapsed. feels wonky otherwise */}
        {collapsed &&
          sidebar.sections.map((section, index) => {
            const isEditing = sidebar.editingSections.get(section);
            return (
              <Hotkey
                key={section}
                global
                group="Sidebar"
                combo={keyIndexMap[index]}
                label={
                  <span>
                    {isEditing ? 'Hide' : 'Show'} <strong style={{ textTransform: 'capitalize' }}>{section}</strong>{' '}
                    Settings
                  </span>
                }
                onKeyDown={() => sidebar.toggleActiveSection(section)}
              />
            );
          })}
      </Hotkeys>
    );
  }

  handleSidebarScroll = value => {
    this.setState({ sidebarScrollPosition: value.topPosition });
  };

  handleSidebarMouseOver = e => {
    const { sidebar } = this.props;

    // if the mouse is close enough to the right of the sidebar, show the control
    const sidebarRightZone = sidebar.collapsed ? 15 : 45;
    const { clientX, currentTarget } = e;
    if (clientX >= currentTarget.offsetWidth - sidebarRightZone) {
      this.setState({
        showCollapseButton: true
      });
    } else {
      this.setState({ showCollapseButton: false });
    }
  };

  handleSidebarMouseLeave = () => this.setState({ showCollapseButton: false });

  renderBody() {
    const { sidebar, toolbar, loading, onSubmit, onReset, formError, footer } = this.props;
    const { sidebarScrollPosition } = this.state;
    const { collapsed } = sidebar;

    if (formError) {
      return (
        <div className="pt-dialog-body">
          <div className="pt-callout pt-intent-danger pt-icon-error">
            <h5>A fatal error occurred</h5>
            Kentik has been notified of this error, but feel free to report an issue if the problem persists. You will
            need to refresh the page to continue.
          </div>
        </div>
      );
    } else if (loading) {
      return (
        <Flex align="center" justify="center" my={4}>
          <Spinner />
        </Flex>
      );
    }

    return (
      <Fragment>
        {toolbar &&
          React.cloneElement(toolbar, {
            sidebarScrollPosition,
            onSubmit,
            onReset,
            sidebar
          })}
        <div
          style={{
            height: `calc(100vh - ${footer ? 146 : 98}px)`,
            minHeight: `calc(100vh - ${footer ? 146 : 98}px)`,
            overflow: 'auto',
            padding: '2px 12px'
          }}
          onScroll={this.handleSidebarScroll}
        >
          <Box mt={collapsed ? 2 : 0}>
            {React.Children.map(
              this.props.children,
              child => (child ? React.cloneElement(child, { onSubmit, onReset, sidebar }) : undefined)
            )}
          </Box>
        </div>
        {footer}
      </Fragment>
    );
  }

  render() {
    const { sidebar, className, style } = this.props;
    const { showCollapseButton } = this.state;

    const { collapsible, collapsed } = sidebar;

    const sidebarClassName = classNames('pt-dark', 'sidebar', { 'sidebar-collapsed': collapsed }, className);

    return (
      <div
        style={style}
        className={sidebarClassName}
        onMouseMove={this.handleSidebarMouseOver}
        onMouseLeave={this.handleSidebarMouseLeave}
      >
        {collapsible && <CollapseSidebarButton {...this.props} showCollapseButton={showCollapseButton} />}
        {this.renderBody()}
      </div>
    );
  }
}
