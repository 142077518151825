import BaseModel from 'models/BaseModel';
import { computed } from 'mobx';

export default class UserGroupTemplate extends BaseModel {
  get defaults() {
    return {
      config: {
        asset: {
          reports: [],
          defaultReport: undefined
        },
        alerts: []
      }
    };
  }

  get urlRoot() {
    return '/api/portal/userGroup/templates';
  }

  get messages() {
    return {
      create: `Template ${this.get('name')} was added successfully`,
      update: `Template ${this.get('name')} was updated successfully`,
      destroy: `Template ${this.get('name')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Template',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  @computed
  get numUsers() {
    return this.get('users').size;
  }

  @computed
  get numViews() {
    const reports = this.get('config.assets.reports');
    return reports ? reports.length : 0;
  }

  @computed
  get numAlertPolicies() {
    const alertPolicies = this.get('config.alerts');
    return alertPolicies ? alertPolicies.length : 0;
  }

  serialize(data) {
    const { config } = data;
    const { alerts } = config;
    // from [10] to [{ id: 10 }]
    config.alerts = alerts.map(alert => ({
      ...alert,
      thresholds: alert.thresholds.map(threshold => ({
        ...threshold,
        notificationChannels: threshold.notificationChannels.map(channelId => ({
          id: channelId
        }))
      }))
    }));

    return super.serialize(data);
  }
}
