import { action, observable, autorun } from 'mobx';

import $app from 'stores/$app';
import DataViewModel from 'models/DataViewModel';
import QueryModel from 'models/query/QueryModel';
import SidebarModel from 'models/SidebarModel';
import { MOMENT_FN } from 'util/dateUtils';

class ExplorerStore {
  @observable
  sidebar = new SidebarModel({
    name: 'explorer',
    sections: ['query', 'time', 'bracketing', 'filters', 'devices'],
    directToggleSections: ['devices', 'bracketing', 'filters'],
    synchronizedToggle: true,
    collapsible: true,
    onCollapse: () => {
      setTimeout(() => this.dataview.component.reflow(), 250);
    }
  });

  @observable
  dataview = new DataViewModel();

  @observable
  formState;

  tagPrefix = 'tag_';

  initializeHashDisposer(navigate = true) {
    if (this.dataview) {
      this.dataview.history = this.history;
    }

    if (!this.hashDisposer) {
      this.hashDisposer = autorun(() => {
        if (!this.dataview.hash || (this.hasLoaded && this.dataview.hashSource === 'init')) {
          return;
        }

        if (!$app.isExport && navigate) {
          const state = { lastHash: this.lastHash };
          const path = `/explorer/${this.dataview.hash}`;

          if (this.hasLoaded && this.lastHash) {
            this.history.push(path, state);
          } else {
            this.history.replace(path, state);
          }
        }

        this.lastHash = this.dataview.hash;
        this.hasLoaded = true;
      });
    }
  }

  @action
  loadView(view, navigate, overrides) {
    this.loadHash(view.get('saved_query_id'), navigate, overrides);
  }

  @action
  reloadView(view) {
    this.loadView(view);
  }

  @action
  loadHash(hash, navigate, overrides) {
    this.initializeHashDisposer(navigate);

    if (hash) {
      if (overrides) {
        this.dataview.initializeHashWithOverrides(hash, overrides);
      } else {
        this.dataview.initializeHash(hash);
      }
    } else if (this.lastHash) {
      if (overrides) {
        this.dataview.initializeHashWithOverrides(this.lastHash, overrides);
      } else {
        this.dataview.initializeHash(this.lastHash);
      }
    } else {
      const defaultQuery = QueryModel.create();
      defaultQuery.set(overrides);
      this.loadFromQueries(defaultQuery);
    }
  }

  @action
  reloadHash(hash) {
    if (hash && hash !== this.lastHash) {
      this.dataview.initializeHash(hash);
    } else {
      this.history.replace(`/explorer/${this.lastHash}`, { lastHash: this.lastHash });
    }
  }

  loadFromQueries(queries) {
    if (!Array.isArray(queries)) {
      queries = [queries];
    }

    this.dataview.initializeQueries(
      queries.map(query => ({
        bucket: query.get('bucket'),
        query: query.serialize()
      }))
    );
  }

  loadPreset() {
    this.initializeHashDisposer();

    let defaultQuery;

    if (window.localStorage && window.localStorage.kt_explorer_preset) {
      defaultQuery = QueryModel.create(JSON.parse(window.localStorage.kt_explorer_preset));
      delete window.localStorage.kt_explorer_preset;
    } else {
      defaultQuery = QueryModel.create();
    }

    this.loadFromQueries(defaultQuery);
  }

  loadUrlParams(params) {
    this.initializeHashDisposer();

    const defaultQuery = QueryModel.create();

    if (params) {
      defaultQuery.set(JSON.parse(decodeURIComponent(params)));
    }

    this.loadFromQueries(defaultQuery);
  }

  @action
  registerFormState(form) {
    this.formState = form;
    if (this.dataview) {
      this.dataview.registerFormState(form);
    }
    this.timeDisposer =
      this.timeDisposer ||
      autorun(() => {
        const { dataview, formState } = this;
        if (formState.dirtyGroups && formState.dirtyGroups.time) {
          dataview.resetTimeRange(true);
          const { lookback_seconds, starting_time, ending_time } = formState.getValues();
          let width = lookback_seconds;
          if (!width) {
            width = MOMENT_FN(ending_time).diff(MOMENT_FN(starting_time), 'second');
          }
          if (width > 3600 * 24 * 7) {
            formState.getField('show_overlay').init(false);
          }
        }
        if (dataview.timeOverride) {
          ['lookback_seconds', 'starting_time', 'ending_time'].forEach(fieldName => {
            formState.getField(fieldName).init(dataview.timeOverride[fieldName]);
          });
        }
      });
  }

  apply(query) {
    this.dataview.applyToSelectedQuery(query);
  }

  preset(query) {
    if (window.localStorage) {
      window.localStorage.kt_explorer_preset = JSON.stringify(query);
    }
  }

  @action
  destroy(preserveHash = false) {
    if (this.hashDisposer) {
      this.hashDisposer();
      this.hashDisposer = null;
    }
    if (this.timeDisposer) {
      this.timeDisposer();
      this.timeDisposer = null;
    }
    this.dataview.destroy();
    this.hasLoaded = false;
    if (!preserveHash) {
      this.lastHash = null;
    }
    this.formState = null;
    this.sidebar.setEditingSections(false);
  }
}

export default new ExplorerStore();
