import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';

const wrapperStyle = {
  position: 'relative',
  display: 'inline-block'
};

@observer
class CompoundIcon extends Component {
  static defaultProps = {
    iconName: '',
    size: 16,
    secondaryIconName: '',
    secondaryIconSize: 16,
    secondaryIconStyle: {
      top: 0,
      right: -8,
      position: 'absolute'
    }
  };

  render() {
    const {
      iconName,
      className,
      secondaryIconName,
      secondaryIconStyle,
      secondaryIconSize,
      secondaryIconClassName,
      style
    } = this.props;

    return (
      <div className="pt-icon-compound" style={{ ...wrapperStyle, ...style }}>
        <Icon iconName={iconName} className={className} />
        {secondaryIconName && (
          <Icon
            iconName={secondaryIconName}
            className={classNames('pt-icon-secondary', secondaryIconClassName)}
            style={{ ...secondaryIconStyle, fontSize: secondaryIconSize }}
          />
        )}
      </div>
    );
  }
}

export default CompoundIcon;
