import React from 'react';
import { isFunction } from 'lodash';
import { RPKI_OPTIONS } from 'util/constants';
import api from 'util/api';

function lookup(url, value, label, matcher) {
  return api.get(url).then(
    success => {
      const options = [];

      success.forEach(result => {
        const option = {
          value: isFunction(value) ? value(result) : result[value],
          label: isFunction(label) ? label(result) : result[label || value],
          matcher: matcher ? result[matcher] : undefined
        };

        // If there's multiple matches, just show the raw value so nothing is misleading
        const dupeIdx = options.findIndex(opt => option.value === opt.value);
        if (dupeIdx >= 0) {
          options[dupeIdx].label = option.value;
        } else {
          options.push(option);
        }
      });

      return options;
    },
    () => []
  );
}

/**
 * This function is used for local filtering of what we assume to be complete in-memory options lists.
 * It assumes a user preference for exact value matches, then startsWith value matches, then contains label matches;
 * shown in that order of precedence. IF USING THIS FUNCTION, ensure this behavior is desired. As of November 2017 all
 * usages of this function (countries and protocols) have been validated.
 *
 * @param options
 * @param filter
 */
function localOptionFilter(options, filter) {
  const lowerFilter = (filter || '').toLowerCase();

  let valueMatches = options.filter(({ value }) => (value || '').toString().toLowerCase() === lowerFilter);
  valueMatches = valueMatches.concat(
    options.filter(option => {
      const { value } = option;
      return (
        !valueMatches.includes(option) &&
        (value || '')
          .toString()
          .toLowerCase()
          .startsWith(lowerFilter)
      );
    })
  );
  return valueMatches.concat(
    options.filter(option => {
      const { matcher, label } = option;
      return (
        !valueMatches.includes(option) &&
        ((matcher !== undefined ? matcher : label) || '').toLowerCase().includes(lowerFilter)
      );
    })
  );
}

const DEBOUNCE_DURATION = 250;

class LookupStore {
  protocolPromise;

  protocolNamePromise;

  countriesPromise;

  siteCountriesPromise;

  debouncer = {};

  constructor() {
    this.autoCompleteFilterQueryHandlers = {
      kt_dst_market: this.market,
      kt_src_market: this.market,

      input_port: this.interfaces,
      output_port: this.interfaces,
      ult_exit_port: this.interfaces,
      'input|output_port': this.interfaces,
      InterfaceID_src: this.interfaces,
      InterfaceID_dst: this.interfaces,

      i_input_interface_description: this.interfaceDescriptions,
      i_output_interface_description: this.interfaceDescriptions,
      i_ult_exit_interface_description: this.interfaceDescriptions,
      'i_input|output_interface_description': this.interfaceDescriptions,

      i_input_snmp_alias: this.snmpAliases,
      i_output_snmp_alias: this.snmpAliases,
      i_ult_exit_snmp_alias: this.snmpAliases,
      'i_input|output_snmp_alias': this.snmpAliases,

      i_src_provider_classification: this.providers,
      i_dst_provider_classification: this.providers,
      'i_src|dst_provider_classification': this.providers,
      i_ult_provider_classification: this.providers,

      protocol: this.protocols,
      i_protocol_name: this.protocolNames,
      Proto: this.protocols,

      dst_geo: this.countries,
      src_geo: this.countries,
      'src|dst_geo': this.countries,
      Geography_src: this.countries,
      Geography_dst: this.countries,
      i_device_site_country: this.siteCountries,
      i_ult_exit_site_country: this.siteCountries,

      dst_geo_region: this.regions,
      src_geo_region: this.regions,
      'src|dst_geo_region': this.regions,

      dst_geo_city: this.cities,
      src_geo_city: this.cities,
      'src|dst_geo_city': this.cities,

      src_as: this.asNumbers,
      src_nexthop_as: this.asNumbers,
      src_second_asn: this.asNumbers,
      src_third_asn: this.asNumbers,
      dst_as: this.asNumbers,
      dst_nexthop_as: this.asNumbers,
      dst_second_asn: this.asNumbers,
      dst_third_asn: this.asNumbers,
      'src|dst_as': this.asNumbers,
      AS_src: this.asNumbers,
      AS_dst: this.asNumbers,
      src_nexthop_asn: this.asNumbers,
      dst_nexthop_asn: this.asNumbers,

      i_src_as_name: this.asNames,
      i_src_nexthop_as_name: this.asNames,
      i_src_second_as_name: this.asNames,
      i_src_third_as_name: this.asNames,
      i_dst_as_name: this.asNames,
      i_dst_nexthop_as_name: this.asNames,
      i_dst_second_asn_name: this.asNames,
      i_dst_third_asn_name: this.asNames,
      'i_src|dst_as_name': this.asNames,

      i_device_label: this.deviceLabels,

      dst_flow_tags: this.flowTags,
      src_flow_tags: this.flowTags,
      'src|dst_flow_tags': this.flowTags,

      application: this.applications,

      src_cdn: this.cdns,
      dst_cdn: this.cdns,

      cloud_provider: this.cloudProviders,
      kt_aws_src_region: this.awsRegion,
      kt_aws_dst_region: this.awsRegion,
      kt_az_src_region: this.azureRegion,
      kt_az_dst_region: this.azureRegion,

      i_dst_rpki_min_name: this.rpkiQuickStatus
    };

    this.autoCompleteDimensionHandlers = {
      Geography_dst: this.countries,
      Geography_src: this.countries,

      dst_geo_region: this.regionIds,
      src_geo_region: this.regionIds,

      dst_geo_city: this.cityIds,
      src_geo_city: this.cityIds,

      InterfaceID_src: this.interfaces,
      InterfaceID_dst: this.interfaces,

      Proto: this.protocols,

      AS_src: this.asNumbers,
      AS_dst: this.asNumbers,
      src_nexthop_asn: this.asNumbers,
      dst_nexthop_asn: this.asNumbers,
      src_second_asn: this.asNumbers,
      dst_second_asn: this.asNumbers,
      src_third_asn: this.asNumbers,
      dst_third_asn: this.asNumbers
    };
  }

  getAutoCompleteFilterHandler = fieldName =>
    this.getAutoCompleteHandler(fieldName, this.autoCompleteFilterQueryHandlers);

  getAutoCompleteDimensionHandler = fieldName =>
    this.getAutoCompleteHandler(fieldName, this.autoCompleteDimensionHandlers);

  getAutoCompleteHandler = (fieldName, handlers) => {
    let handler = handlers[fieldName];

    if (!handler) {
      if (this.store.$dictionary.flatCustomDimensionOption.find(opt => opt.value === fieldName)) {
        handler = (filter, options) =>
          this.populators(fieldName, filter, options).then(opts => [{ value: '', label: 'None' }].concat(opts));
      }
    }

    return handler;
  };

  _clearDebounce(id) {
    if (this.debouncer[id]) {
      clearTimeout(this.debouncer[id]);
    }
  }

  _debounce(fn, id) {
    return new Promise(resolve => {
      this._clearDebounce(id);

      this.debouncer[id] = setTimeout(() => {
        delete this.debouncer[id];
        resolve(fn());
      }, DEBOUNCE_DURATION);
    });
  }

  _lookup(filter, lookupFn, options = {}) {
    const { debounce = true, id = 'empty' } = options;

    if (filter && debounce) {
      return this._debounce(lookupFn, id);
    }

    this._clearDebounce();
    return lookupFn();
  }

  market = filter => this.populators('kt_src_market', filter);

  populators = (customDimension, filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/customdimensions/${customDimension}/populators?term=${encodeURIComponent(filter) || ''}`,
        'field_value'
      );

    return this._lookup(filter, lookupFn, options);
  };

  providers = (filter, options) => {
    const lookupFn = () => lookup(`/api/portal/lookups/providers?term=${encodeURIComponent(filter) || ''}`, 'provider');
    return this._lookup(filter, lookupFn, options);
  };

  interfaces = (filter, options, snmp_id, device_id) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/interfaces?term=${encodeURIComponent(filter) || ''}${
          snmp_id ? `&snmp_id=${snmp_id}` : ''
        }${device_id ? `&device_id=${device_id}` : ''}&orderBy=snmp_id`,
        'snmp_id',
        option => (
          <span>
            {option.snmp_id} <span className="pt-text-muted">({option.interface_description})</span>
            <br />
            <span className="pt-text-smaller pt-text-muted option-description">{option.snmp_alias}</span>
          </span>
        )
      );

    return this._lookup(filter, lookupFn, options);
  };

  interfaceDescriptions = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/interfaces?term=${encodeURIComponent(filter) || ''}&orderBy=interface_description`,
        'interface_description',
        option => (
          <span>
            {option.interface_description}
            <br />
            <span className="pt-text-smaller pt-text-muted option-description">{option.snmp_alias}</span>
          </span>
        )
      );

    return this._lookup(filter, lookupFn, options);
  };

  snmpAliases = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/interfaces?term=${encodeURIComponent(filter) || ''}&orderBy=snmp_alias`,
        'snmp_alias'
      );

    return this._lookup(filter, lookupFn, options);
  };

  protocols = filter => {
    if (!this.protocolPromise) {
      this.protocolPromise = lookup(
        '/api/portal/lookups/protocols?orderBy=name',
        option => option.id.toString(),
        option => (
          <span>
            {option.name} <span className="pt-text-muted">({option.id})</span>
            <br />
            <span className="pt-text-smaller pt-text-muted option-description">{option.description}</span>
          </span>
        ),
        'name'
      );
    }

    return this.protocolPromise.then(options => localOptionFilter(options, filter));
  };

  protocolNames = filter => {
    if (!this.protocolNamePromise) {
      this.protocolNamePromise = lookup(
        '/api/portal/lookups/protocols?orderBy=name',
        'name',
        option => (
          <span>
            {option.name} <span className="pt-text-muted">({option.id})</span>
            <br />
            <span className="pt-text-smaller pt-text-muted option-description">{option.description}</span>
          </span>
        ),
        'name'
      );
    }

    return this.protocolNamePromise.then(options => localOptionFilter(options, filter));
  };

  countries = filter => {
    if (!this.countriesPromise) {
      this.countriesPromise = lookup('/api/portal/lookups/countries', 'iso_country_code', 'country_name');
      this.countriesPromise.then(options => {
        this.countryOptions = options;
      });
    }

    return this.countriesPromise.then(options => localOptionFilter(options, filter));
  };

  siteCountries = filter => {
    if (!this.siteCountriesPromise) {
      this.siteCountriesPromise = lookup('/api/portal/lookups/siteCountries', 'country', 'country_name');
      this.siteCountriesPromise.then(options => {
        this.sideCountryOptions = options;
      });
    }

    return this.siteCountriesPromise.then(options => localOptionFilter(options, filter));
  };

  regions = (filter, options) => {
    const lookupFn = () =>
      lookup(`/api/portal/lookups/regions?term=${encodeURIComponent(filter) || ''}`, 'region_name');
    return this._lookup(filter, lookupFn, options);
  };

  regionIds = (filter, options) => {
    const lookupFn = () =>
      lookup(`/api/portal/lookups/regions?term=${encodeURIComponent(filter) || ''}`, 'region_code', 'region_name');

    return this._lookup(filter, lookupFn, options);
  };

  regionsByCountry = (filter, options, country) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/regions?iso_country_code=${country}&term=${encodeURIComponent(filter) || ''}`,
        'region_name'
      );
    return this._lookup(filter, lookupFn, options);
  };

  cities = (filter, options) => {
    const lookupFn = () => lookup(`/api/portal/lookups/cities?term=${encodeURIComponent(filter) || ''}`, 'city_name');
    return this._lookup(filter, lookupFn, options);
  };

  cityIds = (filter, options) => {
    const lookupFn = () =>
      lookup(`/api/portal/lookups/cities?term=${encodeURIComponent(filter) || ''}`, 'id', 'city_name');
    return this._lookup(filter, lookupFn, options);
  };

  citiesByCountry = (country = '', region = '', filter = '', options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/cities?iso_country_code=${country}${
          region ? `&region_name=${region}` : ''
        }&term=${encodeURIComponent(filter)}`,
        'city_name'
      );

    return this._lookup(filter, lookupFn, options);
  };

  asNumbers = (filter, options = {}) => {
    const { limit = 100 } = options;

    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/asns?term=${encodeURIComponent(filter) || ''}&limit=${limit}&orderBy=id`,
        option => option.id.toString(),
        option => (
          <span>
            {option.id}{' '}
            <span key={option.id} className="pt-text-muted">
              ({option.description})
            </span>
          </span>
        ),
        'description'
      );

    return this._lookup(filter, lookupFn, options);
  };

  asNames = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/asns?term=${encodeURIComponent(filter) || ''}&orderBy=description`,
        'description',
        option => (
          <span>
            {option.description}
            <br />
            <span className="pt-text-smaller pt-text-muted option-description">{option.id}</span>
          </span>
        )
      );

    return this._lookup(filter, lookupFn, options);
  };

  applications = (filter, options) => {
    const lookupFn = () =>
      lookup(`/api/portal/lookups/applications?term=${encodeURIComponent(filter) || ''}`, 'name', option => (
        <span>
          {option.name}
          <br />
          <span className="pt-text-smaller pt-text-muted option-description">{option.description}</span>
        </span>
      ));
    return this._lookup(filter, lookupFn, options);
  };

  cdns = (filter, options) => {
    const lookupFn = () => lookup(`/api/portal/lookups/cdns?term=${encodeURIComponent(filter) || ''}`, 'name');
    return this._lookup(filter, lookupFn, options);
  };

  deviceLabels = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/deviceLabels?term=${encodeURIComponent(filter) || ''}&orderBy=name`,
        option => option.id.toString(),
        'name'
      );
    return this._lookup(filter, lookupFn, options);
  };

  flowTags = (filter, options) => {
    const lookupFn = () => lookup(`/api/portal/lookups/flowtags?term=${encodeURIComponent(filter) || ''}`, 'flow_tag');
    return this._lookup(filter, lookupFn, options);
  };

  sites = (filter, options) => {
    const lookupFn = () =>
      lookup(`/api/portal/lookups/sites?term=${encodeURIComponent(filter) || ''}&orderBy=title`, 'title', option => (
        <span>
          {option.title}
          <br />
          <span className="pt-text-smaller pt-text-muted option-description">{option.id}</span>
        </span>
      ));

    return this._lookup(filter, lookupFn, options);
  };

  cloudProviders = (filter, options) => {
    const lookupFn = () =>
      lookup(`/api/portal/lookups/cloudProvider?term=${encodeURIComponent(filter) || ''}`, 'cloud_provider');
    return this._lookup(filter, lookupFn, options);
  };

  gceProject = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/gceProject?term=${encodeURIComponent(filter) || ''}`,
        option => option,
        option => option,
        'gce_project'
      );
    return this._lookup(filter, lookupFn, options);
  };

  awsRegion = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/awsRegion?term=${encodeURIComponent(filter) || ''}`,
        option => option,
        option => option,
        'aws_region'
      );
    return this._lookup(filter, lookupFn, options);
  };

  azureRegion = (filter, options) => {
    const lookupFn = () =>
      lookup(
        `/api/portal/lookups/azureRegion?term=${encodeURIComponent(filter) || ''}`,
        option => option,
        option => option,
        'azure_region'
      );
    return this._lookup(filter, lookupFn, options);
  };

  rpkiQuickStatus = (filter, options) =>
    this._lookup(
      filter,
      async () => RPKI_OPTIONS.filter(option => option.value.toLowerCase().includes(filter)),
      options
    );

  as_number = this.asNumbers;

  as_name = this.asNames;

  site = this.sites;

  tag = this.flowTags;

  country = this.countries;

  interface_name = this.snmpAliases;

  interface_description = this.interfaceDescriptions;

  region = this.regionsByCountry;

  cloud_provider = this.cloudProviders;

  gce_proj_id = this.gceProject;

  aws_region = this.awsRegion;

  azure_region = this.azureRegion;

  rpkiQuickStatus = this.rpkiQuickStatus;
}

export default new LookupStore();
