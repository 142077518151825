import Collection from '../Collection';
import Plan from './Plan';

class PlanCollection extends Collection {
  get url() {
    return '/api/portal/plans';
  }

  get model() {
    return Plan;
  }

  get jsonRoot() {
    return 'plans';
  }
}

export default PlanCollection;
