import React, { Component } from 'react';
import { observer } from 'mobx-react';
import TabPanel from 'components/TabPanel';
import { Box } from 'components/flexbox';
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import $dictionary from 'stores/$dictionary';
import $app from 'stores/$app';
import classNames from 'classnames';

import FiltersDisplay from 'components/FiltersDisplay/FiltersDisplay';

import LegendTable from './LegendTable';

@observer
export default class Legend extends Component {
  state = {
    selectedKeys: [],
    visibleKeys: []
  };

  componentDidUpdate() {
    const { isViewCmp, dataview } = this.props;
    const { loading } = dataview;
    if (isViewCmp) {
      dataview.component = this;

      if (!loading) {
        dataview.setFullyLoaded();
      }
    }
  }

  setSelectedModels(models) {
    this.setState({ selectedKeys: models.map(model => model.get('key')) });
  }

  setVisibleModels(models) {
    this.setState({ visibleKeys: models.map(model => model.get('key')) });
  }

  render() {
    const { dataview } = this.props;
    const { selectedKeys, visibleKeys } = this.state;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (activeBucketCount > 1) {
      if ($app.isExport) {
        return (
          <Box>
            {activeBuckets.map(bucket => (
              <div className="break-before" key={bucket.get('name')}>
                <h5 style={{ marginBottom: 16 }}>{bucket.get('name')}</h5>
                <LegendTable
                  key={bucket.get('name')}
                  loading={dataview.loading}
                  bucket={bucket}
                  {...this.props}
                  selectedKeys={selectedKeys}
                  visibleKeys={visibleKeys}
                />
              </div>
            ))}
          </Box>
        );
      }
      return (
        <TabPanel flexAuto flexColumn className="multistep-tabs" activeTabId={activeBuckets[0].get('name')}>
          {activeBuckets.map(bucket => {
            const { mirror, mirrored, secondaryOutsort, isSecondary, metric, filters } = bucket.firstQuery.get();
            const unit = bucket.firstQuery.outsortUnit;
            const name = bucket.get('name');

            const hasTwoLines = mirror || secondaryOutsort || isSecondary;

            return (
              <LegendTable
                key={name}
                tabId={name}
                loading={dataview.loading}
                selectedKeys={selectedKeys}
                visibleKeys={visibleKeys}
                tab={
                  <div
                    style={{ lineHeight: hasTwoLines ? '16px' : 'inherit', textAlign: hasTwoLines ? 'left' : 'center' }}
                  >
                    <span>
                      {mirror &&
                        !mirrored && (
                          <div>
                            <strong>Original Query</strong>
                          </div>
                        )}
                      {mirrored && (
                        <div>
                          <strong>Opposite Query</strong>
                        </div>
                      )}
                      {(secondaryOutsort || isSecondary) && (
                        <div>
                          <strong>{$dictionary.dictionary.units[unit]}</strong>
                        </div>
                      )}
                      {hasTwoLines && (
                        <span className="pt-text-muted">
                          <small>{name}</small>
                        </span>
                      )}
                      {!mirror && !secondaryOutsort && !isSecondary && name}
                    </span>
                    {isSecondary && (
                      <Popover
                        popoverClassName={classNames({ 'pt-dark': $app.darkThemeEnabled })}
                        content={
                          <Box p={2} style={{ maxWidth: 500 }}>
                            <h5>Secondary Metrics</h5>
                            <Box className="pt-callout pt-minimal pt-intent-primary" mt={1}>
                              This tab&#39;s results were generated using your selected Secondary Metric --{' '}
                              <strong>{$dictionary.dictionary.units[unit]}</strong> -- and the resulting flow data was
                              plotted on your chart for direct comparison.
                            </Box>
                          </Box>
                        }
                        position={Position.TOP}
                        interactionKind={PopoverInteractionKind.HOVER}
                      >
                        <span className="pt-intent-primary pt-icon-comparison" style={{ marginLeft: 8 }} />
                      </Popover>
                    )}
                    {mirrored && (
                      <Popover
                        popoverClassName={classNames({ 'pt-dark': $app.darkThemeEnabled })}
                        content={
                          <Box p={2} style={{ maxWidth: 500 }}>
                            <h5>Bi-directional Charting</h5>
                            <Box className="pt-callout pt-minimal pt-intent-primary" mt={1}>
                              This tab&#39;s results were generated by automatic bi-directional charting. When
                              bi-directional charting is enabled, it takes your original settings from the left, swaps
                              any Dimensions, Metrics, and/or Filters you have with their native opposite (Source vs.
                              Destination) ones, and plots the resulting flow data on your chart for direct comparison.
                              A summary of your resultant settings can be seen below:
                            </Box>
                            <Box mt={1}>
                              <span className="pt-text-muted">Opposite Group By Dimensions:</span>{' '}
                              {metric.length === 0 && <strong>None</strong>}
                              {metric.map(dimension => (
                                <Box key={dimension} style={{ marginTop: -4 }} pl={1}>
                                  <strong>{$dictionary.dictionary.chartTypesValidations[dimension]}</strong>
                                </Box>
                              ))}
                            </Box>
                            <Box mt={1}>
                              <span className="pt-text-muted">Opposite Metric: </span>{' '}
                              <strong>{$dictionary.dictionary.units[unit]}</strong>
                            </Box>
                            <Box mt={1}>
                              <span className="pt-text-muted">Opposite Filters: </span>{' '}
                              <Box pl={1}>
                                <FiltersDisplay filters={filters} />
                              </Box>
                            </Box>
                          </Box>
                        }
                        position={Position.TOP}
                        interactionKind={PopoverInteractionKind.HOVER}
                      >
                        <span className="pt-intent-primary pt-icon-swap-horizontal" style={{ marginLeft: 8 }} />
                      </Popover>
                    )}
                  </div>
                }
                bucket={bucket}
                {...this.props}
              />
            );
          })}
        </TabPanel>
      );
    }

    return (
      <LegendTable
        bucket={activeBuckets[0]}
        loading={dataview.loading}
        {...this.props}
        selectedKeys={selectedKeys}
        visibleKeys={visibleKeys}
      />
    );
  }
}

const type = 'table';
const config = {
  showTotalTrafficOverlay: true,
  showHistoricalOverlay: false,
  isTimeBased: false,
  showLegend: false,
  buckets: [
    {
      name: 'Table'
    }
  ]
};

export { type, config };
