import React from 'react';

import HelpLink from './HelpLink';
import { Flex } from './flexbox';

const Heading = props => {
  const { children, helpTopic } = props;

  return (
    <Flex align="center">
      <h5>{children}</h5>
      {helpTopic && <HelpLink helpTopic={helpTopic} buttonSource="nav" style={{ top: 0, marginBottom: 6 }} />}
    </Flex>
  );
};

export default Heading;
