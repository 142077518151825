/**
 * Have a separate collection for sudo, because the URLs are different for managing
 * plans. Use the same model we use in the Portal though. Should always have an
 * associated Company stored as `company`.
 */
import Collection from '../Collection';
import SudoPlan from './SudoPlan';

class PlanCollection extends Collection {
  constructor(data, { companyId, devices, ...options }) {
    super(data, options);
    this.companyId = companyId;
    this.companyDevices = devices;
  }

  get presetFilters() {
    return [
      {
        label: 'Active',
        fn: model => model.get('active')
      },
      {
        label: 'Expired',
        fn: model => !model.get('active')
      }
    ];
  }

  get url() {
    return `/api/sudo/companies/${this.companyId}/plans`;
  }

  get model() {
    return SudoPlan;
  }

  get jsonRoot() {
    return 'plans';
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get defaultPresetFilter() {
    return this.presetFilters[0];
  }
}

export default PlanCollection;
