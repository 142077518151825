import { observable, action, computed, reaction } from 'mobx';
// import { groupBy, mapValues } from 'lodash';

import api from 'util/api';

import Collection from '../Collection';
import Interface from './Interface';

class InterfaceCollection extends Collection {
  // used for calculating interface classification rule matching
  @observable
  activeRule = undefined;

  @observable
  fetchedFlow = false;

  counts = observable.map();

  constructor(data = [], options = {}) {
    super(data, options);
    reaction(() => this.unfiltered.length, () => this.calculateInterfaceCounts());
  }

  get url() {
    return `/api/portal/devices/${this.deviceId}/interfaces`;
  }

  get model() {
    return Interface;
  }

  @computed
  get presetGroups() {
    return [
      {
        name: 'device_name',
        label: 'Device'
      }
    ];
  }

  get useAsyncAdd() {
    return true;
  }

  get filterFieldWhitelist() {
    return new Set([
      'snmp_id',
      'interface_description',
      'snmp_alias',
      'interface_ip',
      'secondaryIps',
      'connectivityType',
      'networkBoundary',
      'topNexthopAsns',
      'provider',
      'snmp_speed'
    ]);
  }

  get presetFilters() {
    return [
      {
        label: 'With Flow',
        fn: model => model.flowStatus !== 'None',
        disabled: !this.fetchedFlow
      },
      {
        label: 'SNMP but No Flow',
        fn: model =>
          (model.flowInbound.snmpMbps > 0 && model.flowInbound.flowMbps === 0) ||
          (model.flowOutbound.snmpMbps > 0 && model.flowOutbound.flowMbps === 0),
        disabled: !this.fetchedFlow
      },
      {
        label: 'Manually Added',
        fn: model => model.isManuallyAdded
      },
      {
        label: 'Classified',
        fn: model => model.get('connectivity_type') && model.get('network_boundary')
      },
      {
        label: 'Unclassified',
        fn: model => !model.get('connectivity_type') && !model.get('network_boundary')
      },
      {
        label: 'Manually Overridden',
        fn: model => model.hasOverriddenFields
      }
    ];
  }

  @computed
  get activeRuleDisplay() {
    return this.activeRule && this.activeRule.readableDisplay;
  }

  @computed
  get interfacesWithFlow() {
    return this.unfiltered.filter(model => model.flowStatus !== 'None');
  }

  @action
  calculateInterfaceCounts() {
    this.presetFilters.forEach(({ label, fn, disabled }) => {
      const count = disabled ? 'N/A' : this.unfiltered.filter(fn).length;

      this.counts.set(label, count);
      this.filter();
    });
  }

  @action
  showFlowRowsOnly = () => {
    this.filter(model => model.flowStatus !== 'None');
  };

  @action
  async fetch(options = {}) {
    this.fetchedFlow = false;
    return super.fetch(options);
  }

  @action
  fetchInterfaceFlow = device => {
    const query = { device_name: device.get('device_name') };

    const inbound = api.get('/api/portal/lookups/interfaces/inbound_flow', { query });
    const outbound = api.get('/api/portal/lookups/interfaces/outbound_flow', { query });
    const snmp = api.get('/api/portal/lookups/interfaces/snmp', { query: { device_id: device.id } });

    return Promise.all([inbound, outbound, snmp]).then(
      action(response => {
        const data = response.map(flow => flow.rows);
        const snmpValues = data[2];
        if (!snmpValues.length) {
          console.warn('No SNMP information was retrived for: ', query);
        }

        /**
         * data[0, 1] are the 2 respective inbound and outbound flow data. To avoid iteration
         * unnecessarily, we can zip them together and set both properties at once by matching
         * `input_port` and `output_port`
         */

        let mergedInboundOutboundFlow = data[0].map(v => {
          const outboundFlow = data[1].find(flow => flow.output_port === v.input_port);
          if (outboundFlow) {
            return { ...v, ...outboundFlow };
          }
          return v;
        });

        // make sure we include outbound-only interfaces too.
        const outboundOnlyFlow = data[1].filter(
          ({ output_port }) => !data[0].find(flow => flow.input_port === output_port)
        );
        if (outboundOnlyFlow.length) {
          mergedInboundOutboundFlow = [...mergedInboundOutboundFlow, ...outboundOnlyFlow];
        }

        // const groupedSnmpValues = groupBy(snmpValues, 'index');
        // const mergedSnmpValues = mapValues(groupedSnmpValues, inOutSet => {
        //   const interfaceSNMP = {
        //     inbound: {},
        //     outbound: {}
        //   };
        //
        //   inOutSet.forEach(set => {
        //     const mbps = Number(set.valuembps);
        //     const value = Number(set.value);
        //     const receiving = value > 0;
        //
        //     const setValue = { mbps, value, receiving };
        //
        //     if (set.metric === 'snmp.ifHCInOctets') {
        //       interfaceSNMP.inbound = setValue;
        //     } else {
        //       interfaceSNMP.outbound = setValue;
        //     }
        //   });
        //
        //   return interfaceSNMP;
        // });

        this.requestStatus = null;
        this.fetchedFlow = true;

        this.updateInterfacesFlow(mergedInboundOutboundFlow, snmpValues);
        this.calculateInterfaceCounts();
        this.filter();
        this.setLastUpdated();
      })
    );
  };

  @action
  updateInterfacesFlow(flowResults, snmpResults) {
    flowResults.forEach(row => {
      const { input_port, output_port } = row;

      const iface = this.unfiltered.find(model => {
        const snmp_id = model.get('snmp_id');
        return (input_port && snmp_id === input_port.toString()) || (output_port && snmp_id === output_port.toString());
      });

      if (iface) {
        iface.set(row);
      }
    });

    // Object.keys(snmpResults).forEach(snmp_id => {
    //   const iface = this.unfiltered.find(model => {
    //     const iface_snmp_id = model.get('snmp_id');
    //     return iface_snmp_id === snmp_id;
    //   });
    //
    //   if (iface) {
    //     iface.set({ snmpFlow: snmpResults[snmp_id] });
    //   }
    // });

    snmpResults.forEach(row => {
      const iface = this.unfiltered.find(model => `${model.get('snmp_id')}` === `${row.input_port}`);

      if (iface) {
        iface.set({ snmpFlow: row });
      }
    });
  }
}

export default InterfaceCollection;
