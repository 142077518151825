const fields = {
  notification_name: {
    label: 'Name',
    rules: 'required|max:50|min:2|string',
    placeholder: ''
  },

  channel_type: {
    label: 'Notification Type',
    options: [
      {
        value: 'email',
        label: 'Email'
      },
      {
        value: 'json',
        label: 'JSON POST Webhook'
      },
      {
        value: 'opsgenie',
        label: 'OpsGenie'
      },
      {
        value: 'pagerduty',
        label: 'PagerDuty'
      },
      {
        value: 'servicenow',
        label: 'ServiceNow'
      },
      {
        value: 'slack',
        label: 'Slack Channel'
      },
      {
        value: 'syslog',
        label: 'System Log'
      }
    ]
  },

  notification_value: {
    rules: 'required|string'
  },

  reminder_interval: {
    label: 'Status Digest Interval',
    defaultValue: 0,
    options: [
      {
        value: 0,
        label: "Don't Send"
      },
      {
        value: 1,
        label: '1 Hour'
      },
      {
        value: 2,
        label: '2 Hours'
      },
      {
        value: 4,
        label: '4 Hours'
      },
      {
        value: 8,
        label: '8 Hours'
      },
      {
        value: 12,
        label: '12 Hours'
      },
      {
        value: 24,
        label: '24 Hours'
      }
    ]
  },

  'servicenow.instance': {
    label: 'ServiceNow Instance Name'
  },

  'servicenow.user': {
    label: 'ServiceNow Username'
  },

  'servicenow.password': {
    label: 'ServiceNow Password'
  },

  'syslog.host': {
    label: 'Destination IP',
    defaultValue: '10.1.1.1'
  },

  'syslog.port': {
    label: 'Port',
    defaultValue: 514
  },

  'syslog.transport': {
    label: 'Transport Type',
    defaultValue: 2,
    options: [
      {
        value: 1,
        label: 'TCP'
      },
      {
        value: 2,
        label: 'UDP'
      }
    ]
  },

  'syslog.sysLogHostname': {
    label: 'Hostname',
    defaultValue: 'kentik-alerts'
  },

  'syslog.severity': {
    label: 'Severity',
    defaultValue: 1,
    options: [
      {
        value: 0,
        label: 'Emergency'
      },
      {
        value: 1,
        label: 'Alert'
      },
      {
        value: 2,
        label: 'Critical'
      },
      {
        value: 3,
        label: 'Error'
      },
      {
        value: 4,
        label: 'Warning'
      },
      {
        value: 5,
        label: 'Notice'
      },
      {
        value: 6,
        label: 'Informational'
      },
      {
        value: 7,
        label: 'Debug'
      }
    ]
  },

  'syslog.facility': {
    label: 'Facility',
    defaultValue: 14,
    options: [
      {
        value: 0,
        label: 'Kernel'
      },
      {
        value: 1,
        label: 'User'
      },
      {
        value: 3,
        label: 'System'
      },
      {
        value: 13,
        label: 'Audit'
      },
      {
        value: 14,
        label: 'Alert'
      },
      {
        value: 16,
        label: 'Local0'
      },
      {
        value: 17,
        label: 'Local1'
      },
      {
        value: 18,
        label: 'Local2'
      },
      {
        value: 19,
        label: 'Local3'
      },
      {
        value: 20,
        label: 'Local4'
      },
      {
        value: 21,
        label: 'Local5'
      },
      {
        value: 22,
        label: 'Local6'
      },
      {
        value: 23,
        label: 'Local7'
      }
    ]
  },
  'config.receiveTenantNotifications': {
    label: 'Receive Alert Notifications',
    defaultValue: false
  },
  'opsgenie.routing_key': {
    label: 'OpsGenie GenieKey'
  },
  'opsgenie.apiUrl': {
    label: 'OpsGenie API URL',
    defaultValue: 'https://api.opsgenie.com/v2/alerts'
  },
  user_group_id: {
    defaultValue: null
  },

  'slack.manualOverride': {
    defaultValue: false
  },
  'slack.incomingWebhook.url': {
    defaultValue: '',
    placeholder: 'Enter webhook URL (required)',
    messages: { required_if: 'A Slack webhook URL is required.' }
  },
  'slack.incomingWebhook.channel.name': {
    defaultValue: ''
  },
  'slack.team.name': {
    defaultValue: ''
  }
};

export { fields };
