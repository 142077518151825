import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$alerts')
class Policies extends Component {
  title = 'Alert Policy';

  url = '/alerting/policies';

  render() {
    const { result, $alerts } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="briefcase"
        url={this.url}
        nameField="policy_name"
        collection={$alerts.myPolicies}
        {...this.props}
      >
        <span>{result.policy_description}</span>
      </ResultRow>
    );
  }
}

export default Policies;
