import InterfaceClassDependency from './InterfaceClassDependency';
import NetworkClassDependency from './NetworkClassDependency';
import SiteCountryDependency from './SiteCountryDependency';
import SiteDependency from './SiteDependency';
import SnmpDependency from './SnmpDepdendency';
import GCEDependency from './GCEDependency';
import AWSDependency from './AWSDependency';
import CloudDependency from './CloudDependency';

export const hasDependencyFailures = model => {
  const type = model.get('type');

  if (type === 'savedView') {
    const modelQuery = model.get('query');

    if (modelQuery && modelQuery.length > 0) {
      const query = modelQuery[0].query;

      return (
        !InterfaceClassDependency.queryMeets(query) ||
        !NetworkClassDependency.queryMeets(query) ||
        !SnmpDependency.queryMeets(query) ||
        !SiteDependency.queryMeets(query) ||
        !SiteCountryDependency.queryMeets(query) ||
        !GCEDependency.queryMeets(query) ||
        !AWSDependency.queryMeets(query) ||
        !CloudDependency.queryMeets(query)
      );
    }
  }

  return false;
};
