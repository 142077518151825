import React from 'react';
import { observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { MaterialIcon } from 'components/Icon';
import Dimension from 'components/dimensions/Dimension';
import FilterOperator from './FilterOperator';

const FilterItem = ({ filterField, operator, filterValue, isLast, ...rest }) => (
  <Flex {...rest} wrap align="center" mb={isLast ? 0 : 0.5} className="overflow-hidden">
    <MaterialIcon
      className="pt-text-muted"
      name="subdirectory_arrow_right"
      style={{ fontSize: 14, marginRight: 3, alignSelf: 'flex-start', paddingTop: 2 }}
    />
    <Dimension dimension={filterField} />
    <FilterOperator operator={operator} />
    <Box>
      <small className="pt-strong">{filterValue}</small>
    </Box>
  </Flex>
);

export default observer(FilterItem);
