import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Box } from 'components/flexbox';

export default observer(
  ({ boxProps, title, description, intent = 'pt-intent-primary', icon = 'pt-icon-info-sign' }) => (
    <Box className="banner" {...boxProps}>
      <div className={classNames('pt-callout', intent, icon)}>
        <h5>{title}</h5>
        {description}
      </div>
    </Box>
  )
);
