import BaseModel from 'models/BaseModel';
import { action, observable } from 'mobx';
import $auth from 'stores/$auth';

export default class CloudExportTask extends BaseModel {
  @observable
  deviceCollection;

  @action
  resetTaskStatus() {
    const properties = this.get('properties');

    return this.save({
      enabled: true,
      properties: {
        ...properties,
        task_status: 'PENDING',
        error_message: ''
      }
    });
  }

  get environment() {
    const { baseUrl } = $auth.openConfig;
    const matcher = baseUrl.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(.+)\.kentik.com$/);
    if (matcher) {
      return matcher[2];
    }

    // dev environment (localhost) will default
    // down to the portal environment
    return 'portal';
  }

  get defaults() {
    const env = this.environment;
    const isPortalHost = env === 'portal';
    const isEu = env.includes('fra1');
    const subdomain = env.replace(/^portal\./, '');

    let api_root = isPortalHost ? 'https://api.kentik.com' : `https://${env}.kentik.com`;
    let flow_dest = isPortalHost ? 'https://flow.kentik.com' : `https://flow.${subdomain}.kentik.com`;
    if (isEu) {
      api_root = 'https://api.kentik.eu';
      flow_dest = 'https://flow.kentik.eu';
    }

    return {
      properties: {
        apply_bgp: false,
        api_root,
        flow_dest,
        task_status: 'PENDING'
      }
    };
  }

  get urlRoot() {
    return '/api/portal/cloudExport';
  }

  get sortValues() {
    return {
      properties: () => this.get('properties.gce_project') || this.get('properties.aws_region')
    };
  }

  get omitDuringSerialize() {
    return ['devices', 'fps2hTotal', 'fps5mTotal', 'flowVol30dTotal', 'awsChecker', 'mouseover', 'plan'];
  }

  get awsBucketPath() {
    return `${this.get('properties.aws_bucket')}.s3-${this.get('properties.aws_region')}.amazonaws.com`;
  }

  get removalConfirmText() {
    return {
      title: 'Remove Cloud Export',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  serialize(data) {
    const { properties } = data;
    if (properties.apply_bgp) {
      if (properties.device_bgp_type !== 'device') {
        properties.device_bgp_neighbor_ip = null;
        properties.device_bgp_neighbor_ip6 = null;
        properties.device_bgp_neighbor_asn = null;
        properties.device_bgp_password = null;
        properties.device_bgp_flowspec = false;
        properties.bgpPeerIP4 = null;
        properties.bgpPeerIP6 = null;
      }
    }

    return super.serialize(data);
  }
}
