import React from 'react';
import { observer } from 'mobx-react';
import { Tag } from '@blueprintjs/core';
import Icon, { MaterialIcon } from 'components/Icon';
import classNames from 'classnames';
import { Flex } from 'components/flexbox';
import { getHistoryFilters } from './alarmHelpers';

const stateTagStyles = {
  borderRadius: 17,
  height: 17,
  minWidth: 17,
  width: 17,
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 4
};

const stateIconStyles = {
  fontSize: 11
};

const AlertFilterField = props => {
  const {
    useLinkStyle = true,
    filterBy,
    filterVal,
    displayName,
    onFilterFieldClick,
    showStateIcons = false,
    showTooltip = true,
    model,
    state,
    style
  } = props;
  // const isStateField = filterBy === 'new_state' || filterBy === 'old_state';
  const className = classNames(props.className, 'pt-strong');

  let tooltipText;
  if (showTooltip) {
    tooltipText = filterVal !== displayName ? `${displayName} (${filterVal})` : displayName;
  }

  if (useLinkStyle || model === undefined) {
    return (
      <a
        onClick={() => onFilterFieldClick(getHistoryFilters(filterBy, filterVal))}
        className={className}
        title={tooltipText}
        style={style}
      >
        {displayName}
      </a>
    );
  }

  const { iconName, className: tagClassName, materialIcon } = model.getAlarmHistoryTagDisplayProps(state);

  const IconCmp = materialIcon ? MaterialIcon : Icon;

  return (
    <Flex align="center" title={tooltipText} onClick={() => onFilterFieldClick(getHistoryFilters(filterBy, filterVal))}>
      {showStateIcons && (
        <Tag style={stateTagStyles} className={tagClassName}>
          <IconCmp name={iconName} style={stateIconStyles} />
        </Tag>
      )}
      <a className="pt-link-minimal">{displayName}</a>
    </Flex>
  );
};

export default observer(AlertFilterField);
