import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import $app from 'stores/$app';

const NewButton = props => {
  const {
    className,
    collection,
    disabled,
    entityName,
    history,
    iconName,
    onClick,
    rootUrl,
    showIcon,
    style,
    text,
    intent,
    location
  } = props;

  const defaultOnClick = () => {
    collection.forge();
    if (rootUrl) {
      history.push(`${$app.adjustUrlForShim(location, rootUrl)}/new`);
    }
  };

  return (
    <Button
      className={classNames('pt-medium', className)}
      disabled={disabled}
      iconName={showIcon ? 'plus' : iconName}
      intent={intent || Intent.PRIMARY}
      onClick={onClick || defaultOnClick}
      style={style}
      text={text || `Add ${entityName}`}
    />
  );
};

export default withRouter(observer(NewButton));
