import { Address4, Address6 } from 'ip-address';

export default {
  message: 'Enter a valid IPv4 or IPv6 address. CIDR/netmask supported',
  fn: value => {
    let ips = value;
    if (!Array.isArray(value)) {
      ips = value.split(',');
    }

    return ips.every(ip => new Address4(ip.trim()).isValid() || new Address6(ip.trim()).isValid());
  }
};
