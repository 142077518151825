import React from 'react';
import { observer } from 'mobx-react';
import { Radio, RadioGroup as BlueprintRadioGroup } from '@blueprintjs/core';
import classNames from 'classnames';
import { Box } from 'components/flexbox';
import Icon from 'components/Icon';

const RadioGroup = ({
  field,
  options,
  labelStyle,
  disabledOptions = [],
  onChange,
  inline = true,
  disabled: disabledProp
}) => {
  const radioOptions = options || field.options;

  const handleChange = e => {
    let { value } = e.target;

    if (e.target.value === 'true') {
      value = true;
    } else if (e.target.value === 'false') {
      value = false;
    }

    onChange(value);
  };

  const className = classNames({ 'pt-inline': inline });

  return (
    <BlueprintRadioGroup onChange={handleChange} selectedValue={field.getValue()}>
      {radioOptions &&
        radioOptions.map(({ value, label, helpText, iconName, disabled: optionDisabled }) => {
          const radioLabel = (
            <span style={labelStyle}>
              {iconName && <Icon className="pt-text-muted" name={iconName} style={{ marginRight: 8 }} />}
              {label}
            </span>
          );

          const disabled = disabledProp || optionDisabled || field.disabled || disabledOptions.includes(value);

          return [
            <Radio
              className={className}
              disabled={disabled}
              key={`${field.id}-${value}`}
              label={radioLabel}
              value={value}
            />,
            helpText && (
              <Box style={{ marginLeft: 25, marginTop: -6, marginBottom: 8 }} className="pt-text-muted pt-normal">
                <small style={{ opacity: disabled ? 0.45 : 1 }}>{helpText}</small>
              </Box>
            )
          ];
        })}
    </BlueprintRadioGroup>
  );
};

export default observer(RadioGroup);
