import Collection from '../Collection';
import Template from './Template';

export default class TemplateCollection extends Collection {
  get url() {
    return '/api/portal/userGroup/templates';
  }

  get model() {
    return Template;
  }

  get defaultSortState() {
    return {
      field: 'is_default',
      direction: 'desc'
    };
  }
}
