import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';

import Field from '../Field';
import { ModalSelect, PopoverSelect, TabbedModalSelect } from '../select';
import DimensionValueRenderer from './DimensionValueRenderer';
import DimensionMultiValuesRenderer from './DimensionMultiValuesRenderer';

function filterWithRestrictions(array, restrictions) {
  const { optionsWhitelist, optionsBlacklist } = restrictions;

  return array
    .filter(option => !optionsWhitelist || option.value === undefined || optionsWhitelist.has(option.value))
    .filter(option => !optionsBlacklist || option.value === undefined || !optionsBlacklist.has(option.value));
}

function applyRestrictions(options, restrictions = {}) {
  const { optionsWhitelist, optionsBlacklist } = restrictions;
  const dimensionOptions = {};

  const categories = Object.keys(options).filter(category => !optionsBlacklist || !optionsBlacklist.has(category));
  categories.forEach(category => {
    let categoryOptions;
    let optionCount = 0;
    if (optionsWhitelist && optionsWhitelist.has(category)) {
      dimensionOptions[category] = options[category];
    } else {
      categoryOptions = options[category].map(subCategory => {
        let matchingOptions;

        if (Array.isArray(subCategory)) {
          matchingOptions = filterWithRestrictions(subCategory, restrictions);
          optionCount += matchingOptions.length;
        } else if (subCategory.value !== undefined) {
          optionCount += 1;
          return subCategory;
        } else {
          matchingOptions = applyRestrictions(subCategory, restrictions);
          optionCount += Object.keys(matchingOptions).length;
        }

        return matchingOptions;
      });

      if (optionCount > 0) {
        dimensionOptions[category] = categoryOptions;
      }
    }
  });

  return dimensionOptions;
}

const filterHeadings = (
  <Flex col={12}>
    <Box flexAuto style={{ fontWeight: 700, paddingLeft: 30 }}>
      Source
    </Box>
    <Box flexAuto style={{ fontWeight: 700, paddingLeft: 30 }}>
      Destination
    </Box>
    <Box flexAuto style={{ fontWeight: 700, paddingLeft: 30 }}>
      Source or Destination
    </Box>
    <Box flexAuto style={{ fontWeight: 700, paddingLeft: 30 }}>
      Non-Directional / Other
    </Box>
  </Flex>
);

const dimensionHeadings = (
  <Flex col={12}>
    <Box flexAuto style={{ fontWeight: 700, paddingLeft: 44 }}>
      Source
    </Box>
    <Box flexAuto style={{ fontWeight: 700, paddingLeft: 44 }}>
      Destination
    </Box>
    <Box flexAuto style={{ fontWeight: 700, paddingLeft: 44 }}>
      Non-Directional / Other
    </Box>
  </Flex>
);

@inject('$explorer', '$dictionary')
@observer
class DimensionSelector extends Component {
  static contextTypes = {
    form: any
  };

  state = {
    isEditing: false
  };

  static defaultProps = {
    multi: true,
    readOnly: false,
    fieldName: 'dimensions',
    noSelectedValuesText: 'No dimensions selected',
    reorderable: true,
    showClearButton: false,
    isPopover: false,
    itemFlexed: true,
    rules: undefined,
    title: 'Group By Dimensions',
    selectedValuesTitle: 'Selected Dimensions',
    mainTabDisabled: false,
    multiValuesRenderer: DimensionMultiValuesRenderer
  };

  getField() {
    const { field, fieldName } = this.props;
    return field || this.context.form.getField(fieldName);
  }

  getTools = () => {
    const { mainTabDisabled, multi } = this.props;
    return (
      multi && (
        <Button
          className="pt-fill"
          iconName="remove"
          text="Clear Selections"
          onClick={this.handleClearValues}
          disabled={mainTabDisabled}
        />
      )
    );
  };

  handleClearValues = () => {
    this.getField().onChange([]);
  };

  handleToggleEditing = () => {
    this.getField().setPristine(false);
    this.setState({ isEditing: !this.state.isEditing });
  };

  getSelectComponent() {
    const { isTabbed, isPopover } = this.props;

    let SelectComponent = ModalSelect;
    if (isTabbed) {
      SelectComponent = TabbedModalSelect;
    } else if (isPopover) {
      SelectComponent = PopoverSelect;
    }

    return SelectComponent;
  }

  render() {
    const {
      className,
      multi,
      options,
      onChange,
      readOnly,
      showLabel,
      onEditComplete,
      inputStyle,
      dialogOnly,
      isEditing,
      restrictions,
      rules,
      $dictionary,
      useFilterHeadings
    } = this.props;

    const formField = this.getField();
    const selectOptions = applyRestrictions(
      options || $dictionary.dimensionOptions,
      restrictions || formField.initialConfig
    );

    const SelectComponent = this.getSelectComponent();

    const selectField = (
      <Field
        className={className}
        field={formField}
        isEditing={isEditing || this.state.isEditing}
        onChange={onChange}
        options={selectOptions}
        showLabel={showLabel}
        toggleEditing={onEditComplete || this.handleToggleEditing}
        readOnly={readOnly}
        inputStyle={inputStyle}
        rules={rules}
      >
        <SelectComponent
          {...this.props}
          filterPlaceholder="Search Available Dimensions..."
          multi={multi}
          selectedValuesTitle="Selected Dimensions"
          valueRenderer={DimensionValueRenderer}
          bottomTools={this.getTools}
          allowSelectAll={false}
          headings={useFilterHeadings ? filterHeadings : dimensionHeadings}
        />
      </Field>
    );

    if (!dialogOnly && !readOnly && multi) {
      return (
        <div onClick={this.handleToggleEditing} style={{ cursor: 'pointer' }}>
          {selectField}
        </div>
      );
    }

    return selectField;
  }
}

export default DimensionSelector;
