import io from 'socket.io-client';
import { isDev } from 'util/utils';
import uuid from 'uuid';

const socketBaseUrl = isDev ? 'http://localhost:3000/' : `${window.location.protocol}//${window.location.host}`;
let socket = null;

export default class Socket {
  frequency = 'once';

  constructor(options = {}) {
    if (socket === null) {
      socket = io.connect(socketBaseUrl);

      if (isDev) {
        socket.on('event', data => {
          console.info('socket event', data);
        });
      }
    }

    this.id = uuid.v4();

    Object.assign(this, options);

    if (options.type) {
      this.inType = options.type;
      this.outType = options.type;
    }
  }

  setOutType(outType) {
    this.outType = outType;
  }

  setPayload(payload) {
    this.payload = payload;
  }

  send(payload) {
    const channel = this.getOutChannel();
    this.listen();

    // allow payload param to serve as an ultimate override;
    // if no payload param, then try this.payload, or default to {}
    this.payload = Object.assign(payload || this.payload || {}, {
      requestID: this.id
    });

    socket.emit(channel, this.payload);
  }

  listen() {
    if (this.listening) return;

    const channel = this.getInChannel();
    const handler = data => {
      if (data.error) {
        setTimeout(() => this.onError(data.error), 10);
      } else {
        setTimeout(() => this.onSuccess(data), 10);
      }
    };

    if (this.frequency === 'once') {
      socket.once(channel, handler);
    } else {
      socket.on(channel, handler);
    }

    this.reconnect = () => {
      setTimeout(this.onReconnect, 10);
    };

    socket.on('reconnect', this.onReconnect);

    this.listening = true;
  }

  cancelAll() {
    socket.removeAllListeners(this.getInChannel());
    socket.removeListener('reconnect', this.onReconnect);
    this.listening = false;
  }

  destroy() {
    socket.disconnect();
    socket = null;
  }

  getInChannel() {
    if (this.listenToBroadcast) {
      return this.inType;
    }
    return `${this.inType}/${this.id}`;
  }

  getOutChannel() {
    return this.outType; // + "/" + this.id;
  }

  onSuccess = () => {
    if (isDev) {
      console.info(`Success on Socket: "${this.id}", but no handler defined`);
    }
  };

  onReconnect = () => {
    if (isDev) {
      console.warn(`Reconnecting Socket: "${this.id}" but no handler defined`);
    }
  };

  onError = error => {
    if (isDev) {
      console.error(`Error from Socket: "${this.id}"`, error);
    }
  };
}
