import React, { Fragment } from 'react';
import { AnchorButton, Button, ButtonGroup } from '@blueprintjs/core';

const Resources = () => (
  <Fragment>
    <h4>Resources</h4>
    <p className="pt-text-muted">
      Find a{' '}
      <a href="https://kentik.com/resources" target="_blank" rel="noopener noreferrer">
        full list of Resources page
      </a>{' '}
      on our website
    </p>
    <ButtonGroup vertical className="pt-fill pt-minimal pt-align-left" style={{ marginTop: 16, marginLeft: -9 }}>
      <AnchorButton onClick={() => window.open('https://kb.kentik.com')} iconName="help" text="Knowledge Base" />
      <Button iconName="play" text="Video Tutorials" onClick={() => window.open('https://www.kentik.com/resources')} />
      <AnchorButton
        onClick={() => window.open('https://www.kentik.com/netflow-guide-types-of-network-flow-analysis')}
        iconName="document"
      >
        Types of Network Flow Analysis
      </AnchorButton>
      <AnchorButton
        onClick={() => window.open('https://www.kentik.com/how-to-prevent-ddos-attacks')}
        iconName="document"
      >
        How to Prevent DDoS Attacks
      </AnchorButton>

      <AnchorButton
        onClick={() => window.open('https://www.kentik.com/blog/visualizing-traffic-exit-points-in-complex-networks')}
        iconName="document"
      >
        Visualizing Traffic Exit Points in Complex Networks
      </AnchorButton>
    </ButtonGroup>
  </Fragment>
);

export default Resources;
