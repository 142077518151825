import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ProfileForm from './ProfileForm';

@inject('$users')
@observer
class ProfileFormWrapper extends Component {
  render() {
    const { $users } = this.props;
    const { fullUserProfile } = $users;

    return <ProfileForm values={fullUserProfile} />;
  }
}

export default ProfileFormWrapper;
