import { action, computed, observable, runInAction } from 'mobx';

import DataViewModel from 'models/DataViewModel';
import QueryModel from 'models/query/QueryModel';
import { getQueryForHash, getHashForQuery } from 'services/urlHash';
import { addFilterGroup, getDefaultFiltersObj, deepClone } from 'util/utils';

class BasicExplorerStore {
  @observable.shallow
  activePivot;

  @observable
  activeLookbackSeconds = 3600;

  @observable.shallow
  activeFilters = [];

  @observable.shallow
  activeDimensions = [];

  @observable.shallow
  activeAggregateTypes = [];

  @observable
  activeVizType;

  templateBaseQueryAttributes = undefined;

  @observable
  dataview = new DataViewModel();

  @action
  loadSavedView = view => {
    view.select();

    const saved_query_id = view.get('saved_query_id');
    this.dataview.initializeHash(saved_query_id);
  };

  @action
  loadTemplate = async template => {
    const { devices, dimensions, metrics, vizTypes } = template.get('options');

    let baseQuery = await getQueryForHash(template.get('saved_query_id'));
    baseQuery.template_id = template.id;

    // apply device overrides - for now, since users cannot select devices, we need
    // to do this upfront so they are persisted when making other changes to the query
    // but I think this will always be necessary upfront
    if (devices) {
      const overrides = await this.applyDeviceOverrides(devices.mode);
      baseQuery = { ...baseQuery, ...overrides };
    }

    this.templateBaseQueryAttributes = QueryModel.create(baseQuery).serialize();
    let query = { ...this.templateBaseQueryAttributes };

    // apply the dimension overrides
    if (dimensions && dimensions.enabled && dimensions.locked) {
      query = { ...query, metric: dimensions.optionsWhitelist };
    }

    // apply vizType overrides
    if (vizTypes && vizTypes.enabled && vizTypes.locked) {
      query = { ...query, viz_type: vizTypes.optionsWhitelist };
    }

    const { aggregateTypes } = metrics;

    if (metrics && metrics.enabled && metrics.locked) {
      query = {
        ...query,
        aggregateTypes
      };
    }

    runInAction(() => {
      this.activePivot = undefined;
      this.activeFilters = [];
      this.activeDimensions = query.metric;
      this.activeVizType = query.viz_type;
      this.activeAggregateTypes = query.aggregateTypes;
      this.activeLookbackSeconds = query.lookback_seconds;
    });

    this.initializeQuery(query, { generateHash: false });
  };

  @action
  loadHash = async hash => {
    // get the overrides which we need to apply.
    const query = await getQueryForHash(hash);
    const { activeFilters = [], aggregateTypes, metric, lookback_seconds, pivot, template_id, viz_type } = query;

    // If the report isn't selected, we need to find and select it
    if (!this.selectedReport) {
      if (!template_id) {
        return;
      }

      const { reportsCollection } = this.store.$library;
      const report = reportsCollection.find({ id: template_id });

      if (!report) {
        return;
      }

      reportsCollection.select(report);
    }

    const template = this.selectedReport;
    template.select();

    if (!this.templateBaseQueryAttributes) {
      const baseQuery = await getQueryForHash(this.selectedReport.get('saved_query_id'));
      this.templateBaseQueryAttributes = QueryModel.create(baseQuery).serialize();
    }

    runInAction(() => {
      this.activePivot = pivot;
      this.activeVizType = viz_type;
      this.activeDimensions = metric;
      this.activeAggregateTypes = aggregateTypes;
      this.activeFilters = activeFilters;
      this.activeLookbackSeconds = lookback_seconds;
    });

    query.template_id = template.id;

    this.initializeQuery(query, { generateHash: false });
  };

  @action
  loadInAdvancedExplorer = async report => {
    if (report.get('type') === 'savedView') {
      report.deselect();
      this.history.push(`/savedView/${report.id}`);
    } else {
      const hash = await this.dataview.queryBuckets.save();
      this.history.push(`/explorer/${hash}`);
    }
  };

  /**
   * ['all_devices', 'routers', 'hosts', 'user_configurable']
   */
  applyDeviceOverrides = async mode => {
    const all_devices = mode === 'all_devices';
    const result = { device_name: [], all_devices };

    const devices = await this.store.$devices.getDevicesNamesByType(mode);

    // if we're not showing all devices, do the lookup on the type of devices and add
    // them to the device_name array.
    if (mode !== 'all_devices') {
      result.device_name = devices;
    }

    return result;
  };

  @action
  initializeQuery = (query, options = {}) => {
    const { $library } = this.store;
    const { generateHash = true } = options;
    $library.setLoading(true);

    // generate the new hash from our changes.
    if (generateHash) {
      getHashForQuery(query).then(hash => {
        this.history.replace(`/library/${hash}`);
        $library.setLoading(false);
      });
    } else {
      this.dataview.setQuery(query);
      $library.setLoading(false);
    }
  };

  @action
  buildQuery = () => {
    let query = { ...this.templateBaseQueryAttributes };

    query = this.applyPivot(query);
    query = this.applyDimensions(query);
    query = this.applyLookbackSeconds(query);
    query = this.applyFilters(query);
    query = this.applyVizType(query);
    query = this.applyMetrics(query);

    return query;
  };

  @action
  setPivot = pivot => {
    this.activePivot = pivot;
    this.initializeQuery(this.buildQuery());
  };

  @action
  setLookbackSeconds = lookback_seconds => {
    this.activeLookbackSeconds = lookback_seconds;
    this.initializeQuery(this.buildQuery());
  };

  @action
  setFilters = filters => {
    this.activeFilters = filters;
    this.initializeQuery(this.buildQuery());
  };

  @action
  setAggregateTypes = aggregateTypes => {
    this.activeAggregateTypes = aggregateTypes;
    this.initializeQuery(this.buildQuery());
  };

  @action
  setVizType(vizType) {
    this.activeVizType = vizType;
    this.initializeQuery(this.buildQuery());
  }

  @action
  setDimensions(dimensions) {
    this.activeDimensions = dimensions;
    this.initializeQuery(this.buildQuery());
  }

  @action
  applyPivot = query => {
    if (!this.activePivot) {
      return query;
    }

    const { filters: pivotFilters, ...pivotOverrides } = this.activePivot.overrides;
    const filters_obj = query.filters_obj ? deepClone(query.filters_obj) : getDefaultFiltersObj();

    // always remove existing filterGroups that were created by Pivots, because
    // only 1 Pivot can be active at a time.
    if (filters_obj && filters_obj.filterGroups.length) {
      filters_obj.filterGroups.forEach((group, index) => {
        if (group.name === 'pivot') {
          filters_obj.filterGroups.splice(index, 1);
        }
      });
    }

    if (pivotFilters) {
      // add a name to the filterGroups that are created by this Pivot
      const pivotGroups = pivotFilters.filterGroups.map(group => ({
        ...group,
        name: 'pivot'
      }));

      const merged = filters_obj.filterGroups.concat(pivotGroups);
      filters_obj.filterGroups = merged;
      query = { ...query, filters_obj };
    }

    // pivotOverrides being literally every other query attribute that we don't have to worry
    // about merging together, and just override directly.
    query = { ...query, pivot: this.activePivot, ...pivotOverrides };

    return query;
  };

  @action
  applyFilters = query => {
    if (!this.activeFilters.length) {
      return query;
    }

    const existingFilters = query.filters_obj || getDefaultFiltersObj();

    // always remove template filters and rebuild them.
    if (existingFilters && existingFilters.filterGroups.length) {
      existingFilters.filterGroups.forEach((group, index) => {
        if (group.name === 'template_filters') {
          existingFilters.filterGroups.splice(index, 1);
        }
      });
    }

    const filters_obj = addFilterGroup(existingFilters, 'All', false, [], this.activeFilters, 'template_filters');
    return { ...query, activeFilters: this.activeFilters, filters_obj };
  };

  applyDimensions = query => {
    if (this.selectedReport.get('options.dimensions.enabled')) {
      return { ...query, metric: this.activeDimensions };
    }
    return query;
  };

  applyLookbackSeconds = query => ({ ...query, lookback_seconds: this.activeLookbackSeconds });

  applyVizType = query => {
    if (this.selectedReport.get('options.vizTypes.enabled')) {
      return { ...query, viz_type: this.activeVizType };
    }
    return query;
  };

  applyMetrics = query => {
    if (this.selectedReport.get('options.metrics.enabled')) {
      return { ...query, aggregateTypes: this.activeAggregateTypes };
    }
    return query;
  };

  @computed
  get selectedReport() {
    return this.store.$library.reportsCollection.selected;
  }

  getFilterOptions(filterField) {
    const deviceFields = ['i_device_id', 'i_device_name', 'i_ult_exit_device_name'];
    const { filterFieldValueOptions } = this.store.$dictionary;
    const options = filterFieldValueOptions[filterField];

    if (!deviceFields.includes(filterField) || !this.templateBaseQueryAttributes) {
      return options;
    }

    const { all_devices, device_name } = this.templateBaseQueryAttributes;

    if (all_devices) {
      return options;
    }

    return options.filter(option => device_name.includes(option.name));
  }
}

export default new BasicExplorerStore();
