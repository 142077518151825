import React, { PureComponent } from 'react';
import { Button } from '@blueprintjs/core';

export default class ValueButton extends PureComponent {
  onClick = () => {
    const { value, onClick } = this.props;
    if (onClick) {
      onClick(value);
    }
  };

  render() {
    const { onClick, ...buttonProps } = this.props;
    return <Button onClick={this.onClick} {...buttonProps} />;
  }
}
