import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { Field, Input } from 'components/forms';
import $savedFilters from 'stores/$savedFilters';

import FilterConnector from './FilterConnector';
import FilterItemEdit from './FilterItemEdit';
import { addFilterGroup } from './FilterOptions';
import SavedFilterSelector from './SavedFilterSelector';
import SavedFilter from './SavedFilter';

export function groupHasFilters(group) {
  return (
    group.filters.fieldStates.length > 0 ||
    (group.saved_filters && group.saved_filters.fieldStates.length > 0) ||
    (group.filterGroups && group.filterGroups.fieldStates.length > 0)
  );
}

@observer
class FilterGroup extends Component {
  static defaultProps = {
    allowNestedFilters: false,
    flat: false
  };

  handleAddNestedGroup = () => {
    const { group } = this.props;
    addFilterGroup(group.filterGroups, {});
  };

  handleAddFilter = () => {
    const { group } = this.props;
    const { filters } = group;

    const existingFilters = filters.getValue();

    const lastFilter = existingFilters.length && existingFilters[existingFilters.length - 1];

    // if a previous filter was added, use the same filterField and operator to speed up
    // multiple conditions being added.
    filters.add({
      filterField: lastFilter.filterField,
      operator: lastFilter.operator
    });
  };

  handleRemoveFilter = filterIndex => {
    const { group } = this.props;
    group.filters.remove(filterIndex);
  };

  handleAddSavedFilter = ({ id }) => {
    const { group } = this.props;
    group.saved_filters.add({ filter_id: id });
  };

  handleCustomizeSavedFilter = filterIndex => {
    const { group } = this.props;
    const filter = group.saved_filters.at(filterIndex);
    const filter_id = filter.filter_id.getValue();
    const not = filter.is_not.getValue();
    const savedFilter = $savedFilters.getSavedFilterById(filter_id);

    group.saved_filters.remove(filterIndex);

    group.filterGroups.add({ ...savedFilter.get('filters'), not });
  };

  handleRemoveSavedFilter = filterIndex => {
    const { group } = this.props;
    group.saved_filters.remove(filterIndex);
  };

  handleRemoveGroup = e => {
    const { groups, index } = this.props;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    groups.remove(index);
  };

  renderAddButtons() {
    const { group, allowNestedFilters, allowSavedFilters, required } = this.props;
    const showNestedFilterControls = allowNestedFilters && group.filterGroups;
    const hasFilters = groupHasFilters(group);

    const flexProps = hasFilters ? { p: 1 } : { p: 1, [required ? 'pb' : 'py']: 3, justify: 'center' };
    const buttonClassName = classNames('pt-small', { 'pt-medium': !hasFilters });
    const buttonMargin = hasFilters ? 4 : 8;
    const buttonIconStyle = { marginRight: 3 };

    return (
      <Flex {...flexProps}>
        <Button className={buttonClassName} onClick={this.handleAddFilter} style={{ marginRight: buttonMargin }}>
          <span className="pt-icon pt-icon-plus" style={buttonIconStyle} />
          Add Ad Hoc Filter
        </Button>
        {allowSavedFilters && (
          <SavedFilterSelector
            buttonClassName={buttonClassName}
            buttonIcon=""
            buttonText={
              <Fragment>
                <span className="pt-icon pt-icon-plus" style={buttonIconStyle} />
                Add Saved Filter
              </Fragment>
            }
            onAdd={this.handleAddSavedFilter}
          />
        )}
        {showNestedFilterControls && (
          <Button
            className={buttonClassName}
            onClick={this.handleAddNestedGroup}
            style={{ marginLeft: allowSavedFilters ? buttonMargin : 0 }}
          >
            <span className="pt-icon pt-icon-add-to-artifact" style={buttonIconStyle} />
            Add Nested Group
          </Button>
        )}
      </Flex>
    );
  }

  render() {
    const { group, disabled, flat, showNames, groupLabel, filterGroupLabel, required } = this.props;

    const className = classNames('pt-card', { flat, 'pt-disabled': disabled });

    let headerContents = <FilterConnector group={group} />;

    if (showNames) {
      headerContents = (
        <Flex flexColumn>
          <Field field={group.name}>
            <Input />
          </Field>
          {headerContents}
        </Flex>
      );
    }

    return (
      <Box className={className}>
        <Flex className="header" p={1} align="flex-start" justify="space-between">
          {headerContents}
          <Button
            text={`Remove ${groupLabel}`}
            className="pt-minimal pt-small pt-intent-danger"
            onClick={this.handleRemoveGroup}
          />
        </Flex>
        <Flex flexColumn>
          {group.filters.map((filter, idx) => (
            <Box pr={1} key={filter.filterField._id}>
              <FilterItemEdit {...this.props} filter={filter} index={idx} onRemove={this.handleRemoveFilter} />
            </Box>
          ))}

          {group.saved_filters &&
            group.saved_filters.fieldStates.length > 0 && (
              <Box className="filter-groups nested" px={1} mt={1} pb={0}>
                {group.saved_filters.map((filter, idx) => (
                  <SavedFilter
                    key={filter.filter_id._id}
                    filter_id={filter.filter_id.getValue()}
                    is_not={filter.is_not}
                    filterIdx={idx}
                    onCustomize={this.handleCustomizeSavedFilter}
                    onRemove={this.handleRemoveSavedFilter}
                  />
                ))}
              </Box>
            )}

          {group.filterGroups &&
            group.filterGroups.fieldStates.length > 0 && (
              <Box className="filter-groups nested" px={1} mt={1} pb={0}>
                {group.filterGroups.map((nestedGroup, index) => (
                  <FilterGroup
                    {...this.props}
                    key={nestedGroup.connector._id}
                    group={nestedGroup}
                    index={index}
                    groups={group.filterGroups}
                    showNames={false}
                    groupLabel="Group"
                  />
                ))}
              </Box>
            )}

          {required &&
            !groupHasFilters(group) && (
              <Flex align="center" className="pt-form-group" mt={3} mb={0}>
                <span className="pt-form-helper-text pt-intent-danger" style={{ fontSize: 12, marginTop: 0 }}>
                  At least one {filterGroupLabel} with an ad hoc or saved filter is required
                </span>
              </Flex>
            )}

          {this.renderAddButtons()}
        </Flex>
      </Box>
    );
  }
}

export default FilterGroup;
