import { endsWith as ends, startsWith as starts, isEqual } from 'lodash';

export const startsWith = {
  message: '":attribute" must begin with the proper values.',
  fn: (value, requirement) => starts(value, requirement)
};

export const endsWith = {
  message: '":attribute" must end the proper values.',
  fn: (value, requirement) => ends(value, requirement)
};

export const sameAs = {
  message: '":attribute" must be the same',
  fn: (value, requirement) => isEqual(value, requirement)
};
