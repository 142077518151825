import React from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Button } from '@blueprintjs/core';
import moment from 'moment';
import { Flex, Box } from 'components/flexbox';
import { getQueryTimeInterval, secondsIntervalToText, DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

const MOMENT_FN = moment.utc;

const JumpButtons = ({ queryType, handleModeSwitch, disabled }, { form }) => {
  const { lookback_seconds, starting_time, ending_time } = form.getValues();
  const interval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
  const textInterval = secondsIntervalToText(interval);
  const millisecondInterval = interval * 1000;

  let forwardDisabled = true;
  if (ending_time) {
    const endTime = MOMENT_FN(ending_time).valueOf();
    forwardDisabled = endTime + millisecondInterval > MOMENT_FN().valueOf();
  } else if (starting_time) {
    const startTime = MOMENT_FN(starting_time).valueOf();
    forwardDisabled = startTime + millisecondInterval * 2 > MOMENT_FN().valueOf();
  }

  const backOnClick = () => {
    let startTime;
    let endTime;

    if (starting_time) {
      startTime = MOMENT_FN(starting_time).valueOf() - millisecondInterval;
      endTime = MOMENT_FN(starting_time).valueOf();
    } else if (ending_time) {
      startTime = MOMENT_FN(ending_time).valueOf() - millisecondInterval * 2;
      endTime = MOMENT_FN(ending_time).valueOf() - millisecondInterval;
    } else {
      startTime = MOMENT_FN().valueOf() - millisecondInterval * 2;
      endTime = MOMENT_FN().valueOf() - millisecondInterval;
    }

    if (startTime && endTime) {
      if (queryType === 'lookback') {
        handleModeSwitch('range');
      }
      form.setValue('starting_time', MOMENT_FN(startTime).format(DEFAULT_DATETIME_FORMAT));
      form.setValue('ending_time', MOMENT_FN(endTime).format(DEFAULT_DATETIME_FORMAT));
    }
  };

  const forwardOnClick = () => {
    let startTime;
    let endTime;

    if (ending_time) {
      startTime = MOMENT_FN(ending_time).valueOf();
      endTime = MOMENT_FN(ending_time).valueOf() + millisecondInterval;
    } else if (starting_time) {
      startTime = MOMENT_FN(starting_time).valueOf() + millisecondInterval;
      endTime = MOMENT_FN(starting_time).valueOf() + millisecondInterval * 2;
    }

    if (startTime && endTime) {
      if (queryType === 'lookback') {
        handleModeSwitch('range');
      }
      form.setValue('starting_time', MOMENT_FN(startTime).format(DEFAULT_DATETIME_FORMAT));
      form.setValue('ending_time', MOMENT_FN(endTime).format(DEFAULT_DATETIME_FORMAT));
    }
  };

  return (
    <Box className={classNames({ 'pt-disabled': disabled }, 'pt-form-group')} mb={0}>
      <Flex align="center">
        <label className="pt-label">Jump {textInterval}</label>
      </Flex>
      <div className="pt-button-group pt-small" style={{ width: '100%' }}>
        <Button
          iconName="arrow-left"
          title={`Back ${textInterval}`}
          onClick={backOnClick}
          style={{ width: '50%' }}
          disabled={disabled}
        />
        <Button
          iconName="arrow-right"
          disabled={disabled || forwardDisabled}
          title={forwardDisabled ? undefined : `Forward ${textInterval}`}
          onClick={forwardOnClick}
          style={{ width: '50%' }}
        />
      </div>
    </Box>
  );
};

JumpButtons.contextTypes = {
  form: any
};

export default observer(JumpButtons);
