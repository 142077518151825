import React from 'react';
import { observable, action, computed } from 'mobx';
import { sortBy } from 'lodash';
import DeviceGroupCollection from 'models/deviceGroups/DeviceGroupCollection';
import { Flex, Box } from 'components/flexbox';
import { ColorBlock } from 'components';

import api from 'util/api';

import Collection from 'models/Collection';
import BaseModel from 'models/BaseModel';

class DeviceLabel extends BaseModel {
  get omitDuringSerialize() {
    return ['devices', 'groups', 'user'];
  }

  get messages() {
    return {
      create: 'Label was added successfully',
      destroy: `Label "${this.get('name')}" was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Label',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  @computed
  get numDevices() {
    const num = this.get('devices').length;
    return `${num} ${num === 1 ? 'device' : 'devices'}`;
  }
}

class DeviceLabelCollection extends Collection {
  get url() {
    return '/api/portal/deviceLabels';
  }

  get model() {
    return DeviceLabel;
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  @computed
  get formValues() {
    return this.toJS().map(label => ({ id: label.id, name: label.name, color: label.color }));
  }

  @computed
  get labelColors() {
    const colors = {};
    this.models.forEach(label => {
      colors[label.id] = label.get('color');
    });
    return colors;
  }

  @computed
  get labelNameColors() {
    const colors = {};
    this.models.forEach(label => {
      colors[label.get('name')] = label.get('color');
    });
    return colors;
  }
}

class DeviceGroupsStore {
  @observable
  collection = new DeviceGroupCollection();

  @observable
  labelCollection = new DeviceLabelCollection();

  @action
  async initialize() {
    await this.labelCollection.fetch();
  }

  @action
  bulkUpdateLabels = labels =>
    api.post('/api/portal/deviceLabels/updateLabels', { data: { labels } }).then(res => {
      this.labelCollection.set(res);
    });

  // the standard { label: '', value: '' }, but include the
  @computed
  get deviceLabelSelectOptions() {
    return this.deviceLabelOptions.map(({ numDevices, ...opt }) => ({
      label: (
        <Flex align="center">
          <ColorBlock color={opt.color} style={{ width: 16, height: 16, marginRight: 6 }} />
          <Box flexAuto className="pt-text-overflow-ellipsis">
            {opt.name}
          </Box>
        </Flex>
      ),
      value: opt.id,
      ...opt
    }));
  }

  @computed
  get deviceLabelOptions() {
    const labels = this.labelCollection.models.map(m => ({
      color: m.get('color'),
      description: m.get('description'),
      devices: m.get('devices'),
      id: parseInt(m.id),
      label: m.get('name'),
      name: m.get('name'),
      activeDevices: m.get('devices').filter(device => device.device_status === 'V'),
      numDevices: m.get('devices').length,
      value: parseInt(m.id)
    }));

    return sortBy(labels, ['name']);
  }
}

export default new DeviceGroupsStore();
