import { getToFixed, zeroToText, greekPrefix, adjustByGreekPrefix, escapeHtml } from 'util/utils';
import { MOMENT_FN, DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

export function getSharedTooltip(points, seriesType = 'line', tooltip = '') {
  if (!points.length) {
    return tooltip;
  }

  if (tooltip === '') {
    tooltip = (points[0].series.xAxis.options.momentFn || MOMENT_FN)(points[0].x).format(DEFAULT_DATETIME_FORMAT);
  }

  const primarySeriesPoints = points.filter(
    point => point.series.type === seriesType && point.series.chart.yAxis[0] === point.series.yAxis
  );
  const secondarySeriesPoints = points.filter(
    point => point.series.type === seriesType && point.series.chart.yAxis[1] === point.series.yAxis
  );
  const seriesPoints = primarySeriesPoints;
  if (secondarySeriesPoints.length) {
    secondarySeriesPoints.forEach(point => {
      const matchingPrimarySeries = primarySeriesPoints.find(
        primaryPoint => primaryPoint.series.name === point.series.name
      );
      if (matchingPrimarySeries) {
        matchingPrimarySeries.secondary = point;
      } else {
        seriesPoints.push(point);
      }
    });
  }

  return seriesPoints.reduce((value, point) => {
    const prefix = point.series.yAxis.prefix || greekPrefix([point.series.yAxis.tickInterval]);
    point.series.yAxis.prefix = prefix;
    const suffix = point.series.yAxis.options.title.text.replace(/(\u2014|\u2011|\u2192)/g, '').trim();
    let tooltipMarkup =
      `${value}<br/><span style="color:${point.series.color};">\u25CF</span> ` +
      `${point.series.name.replace(/----/g, '\u2192')}: <b>${escapeHtml(
        zeroToText(adjustByGreekPrefix(point.y, prefix), { fix: getToFixed(point.series.yAxis.options.unit) })
      )} ` +
      `${suffix.includes('sampling rate') ? ': 1 ' : ''}${prefix}${suffix}</b>`;
    if (point.secondary) {
      const { secondary } = point;
      const {
        series: { yAxis }
      } = secondary;
      const secondaryPrefix = yAxis.prefix || greekPrefix([yAxis.tickInterval]);
      yAxis.prefix = secondaryPrefix;
      tooltipMarkup +=
        `, <b>${escapeHtml(
          zeroToText(adjustByGreekPrefix(secondary.y, secondaryPrefix), { fix: getToFixed(yAxis.options.unit) })
        )} ` +
        `${suffix.includes('sampling rate') ? ': 1 ' : ''}${secondaryPrefix}${yAxis.options.title.text
          .replace(/(\u2014|\u2011|\u2192)/g, '')
          .trim()}</b>`;
    }
    return tooltipMarkup;
  }, tooltip);
}
