import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Icon, { MaterialIcon } from 'components/Icon';

const tagStyles = {
  width: 30,
  height: 30,
  borderRadius: 30,
  padding: '5px 0',
  marginTop: -2
};

const iconStyle = {
  fontSize: 16
};

const TypeCell = ({ model }) => {
  const { iconName, materialIcon } = model.tagDisplayProps;

  const tagClass = classNames('pt-tag pt-strong pt-round pt-large', model.severityClassName);
  const IconCmp = materialIcon ? MaterialIcon : Icon;

  return (
    <Fragment>
      <span className={tagClass} style={tagStyles}>
        <IconCmp name={iconName} style={iconStyle} />
      </span>

      <strong className={`${model.severityClassName}-text`} style={{ fontSize: 10 }}>
        {model.stateDisplay}
      </strong>
    </Fragment>
  );
};

export default observer(TypeCell);
