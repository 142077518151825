import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Collapse, Tag } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';

import { renderMenuLink } from './PageNavMenu';

class DeprecatedNavMenu extends Component {
  state = {
    isOpen: false
  };

  render() {
    const { link, history } = this.props;
    const { isOpen } = this.state;

    const key = `divider${link.title}`;

    return (
      <Flex key={key} flexAuto justify="flex-end" flexColumn>
        <Box
          className="pt-menu-item pt-text-muted"
          key={key}
          style={{ width: '100%' }}
          onClick={() => this.setState({ isOpen: !isOpen })}
        >
          <h6 style={{ display: 'inline', marginRight: 8 }}>{link.title}</h6>
          <Tag>deprecated</Tag>
        </Box>
        <Collapse isOpen={isOpen}>
          {link.links.map(subLink => renderMenuLink({ ...subLink, deprecated: true }, history))}
        </Collapse>
      </Flex>
    );
  }
}

export default withRouter(DeprecatedNavMenu);
