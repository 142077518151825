import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dialog, Button, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';

@inject('$companySettings')
@observer
export default class DeviceLabelRegexDialog extends Component {
  handleSubmit = () => {
    const { $companySettings } = this.props;
    return $companySettings.setDeviceNameAsRegex(true);
  };

  handleCancel = () => {
    this.props.$companySettings.setDeviceLabelRegexDialogOpen(false);
  };

  render() {
    return (
      <Dialog
        title="Regular Expressions for Device Names"
        {...this.props}
        onClose={this.handleCancel}
        style={{ top: 100, width: 475, minHeight: 100 }}
        transitionName="pt-dialog"
      >
        <Box flexAuto p={3}>
          <p>
            We’ve enhanced our ingest flow tagging logic and now support regular expression matching for device names.
            All accounts will be transitioned by 3/1/2019. Please contact support if you require help transitioning your
            current tagging to regular expressions or you may enable this new logic immediately.
          </p>
        </Box>
        <Box className="pt-dialog-footer">
          <Flex justify="flex-end">
            <Button text="Cancel" onClick={this.handleCancel} style={{ marginRight: 8 }} />
            <Button
              intent={Intent.PRIMARY}
              className="pt-medium"
              text="Enable Regular Expressions Matching"
              onClick={this.handleSubmit}
            />
          </Flex>
        </Box>
      </Dialog>
    );
  }
}
