import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { Box } from 'components/flexbox';
import { MaterialIcon } from 'components/Icon';

const PresetGroupingButtons = props => {
  const { collection, className, ml = 2, ...otherProps } = props;
  const { presetGroups } = collection;

  if (!presetGroups || presetGroups.length === 0) {
    return null;
  }

  const buttonGroupClassName = classNames('pt-button-group', className);

  return (
    <Box ml={ml} className={buttonGroupClassName} {...otherProps}>
      <Button
        text="None"
        active={!collection.groupBy}
        onClick={() => {
          collection.resetSortState();
          collection.group();
        }}
        disabled={!collection.hasFetched && collection.size === 0}
      />
      {presetGroups.map(groupBy => (
        <Button
          key={groupBy.name}
          iconName={!groupBy.materialIcon ? groupBy.iconName : undefined}
          active={collection.groupBy === groupBy.name}
          onClick={() => {
            if (groupBy.sortBy) {
              collection.sort(groupBy.sortBy);
            } else {
              collection.resetSortState();
            }
            collection.group(groupBy.name);
          }}
          disabled={groupBy.disabled || !collection.hasFetched}
        >
          {groupBy.materialIcon && <MaterialIcon name={groupBy.iconName} />}
          {groupBy.label}
        </Button>
      ))}
    </Box>
  );
};

export default observer(PresetGroupingButtons);
