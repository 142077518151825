import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Box } from 'components/flexbox';
import { Tag, Dialog } from '@blueprintjs/core';
import AlertTable from 'views/Alerting/Alerts/Table/AlertTable';

@inject('$alerts')
@observer
class AlarmsDialog extends Component {
  applyFilters = filterValues => {
    const { $alerts, onClose, history } = this.props;
    $alerts.setFilterValues(filterValues);
    onClose();
    history.push('/alerting/history?loadFilters=1');
  };

  render() {
    const { $alerts, isOpen, onClose, collection, activeCell, activeRow } = this.props;

    return (
      <Dialog transitionName="pt-dialog" title="Alert Details" style={{ width: 900 }} isOpen={isOpen} onClose={onClose}>
        <Box className="pt-dialog-body">
          {activeRow &&
            activeCell && (
              <p>
                Alerts matching: <strong>{activeRow.policy_name}</strong> <Tag style={{ margin: '0 4px' }}>AND</Tag>
                <strong>{activeCell.name}</strong>
              </p>
            )}
          <Box className="pt-card flat">
            <AlertTable
              isAlarmsPage
              collection={collection}
              hideColumns={['baseline', 'miscAlarmData', 'mitAlarmId', 'actions']}
              onFilterFieldClick={this.applyFilters}
              showPolicyEdit={false}
              showAlarmSelect={false}
              addToSilentMode={$alerts.addToSilentMode}
            />
          </Box>
        </Box>
      </Dialog>
    );
  }
}

export default withRouter(AlarmsDialog);
