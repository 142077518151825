import React, { Fragment } from 'react';
import { has, omit, uniqueId } from 'lodash';
import { applyBlacklist, applyWhitelist } from 'util/utils';

export function getSummarySelectLabel(field, value, options) {
  value = value || field.getValue();
  const option = (options || field.options).find(o => o.value === value);

  return option && option.label ? option.label : option;
}

export function getSummarySelectLabels(field, evaluateNumericalIds, suffix, options) {
  const labels = [];
  const opts = options || field.options;
  let values = field.getValue();
  if (values === null || values === undefined) {
    values = [];
  }

  if (!Array.isArray(values)) {
    const item = getSummarySelectLabel(field, values, options);

    if (item) {
      labels.push(item);
    }
  } else {
    values.forEach(value => {
      const item =
        opts &&
        opts.filter(option => {
          value = typeof value === 'object' ? value.value : value;
          if (evaluateNumericalIds) {
            return option.value === parseInt(value);
          }
          return option.value === value;
        })[0];

      if (item) {
        labels.push(item);
      }
    });
  }

  return (
    <Fragment>
      {labels.map(item => (
        <Fragment key={uniqueId()}>
          {item.iconCls && <span className={`pt-icon-standard pt-icon-${item.iconCls}`} />}
          {item.label && item.label}
          {!item.label && item} {suffix}
        </Fragment>
      ))}
      {labels.length === 0 && 'None'}
    </Fragment>
  );
}

export function remapLabel(label, cfg = { 'Bits/second': 'Mbps', 'Packets/second': 'kpps' }) {
  return cfg[label] || label;
}

const whitelistFields = [
  'field',
  'inputRef',
  'rules',
  'options',
  'placeholder',
  'onEditComplete',
  'isEditing',
  'noEditLink',
  'hasEditLink',
  'switchLabel',
  'showOptionLabel',
  'showFieldLabel',
  'disabled'
];

export function whitelistFieldProps(props) {
  return omit(props, whitelistFields);
}

export function applyRestrictions(options, field) {
  const { optionsBlacklist, optionsWhitelist } = field.initialConfig;
  return applyWhitelist(applyBlacklist(options, optionsBlacklist), optionsWhitelist);
}

/**
 * This will update rules of fields that are dependent on the value (masterValue) of another field.
 * The format of rules is as follows:
 * {
 *   masterValueVariation1: {
 *     fieldName1: ruleset (pipe-separated string of validation rules),
 *     fieldName2: {label: updated label string, rules: ruleset}
 *   },
 *   masterValueVariation2: {},
 *   masterValueVariation3: {
 *    fieldName2: {label: updated label string, rules: ruleset}
 *   }
 * }
 *
 * Omitting a field name from a masterValueVariation object will set the omitted field's rules to ''.
 *
 * If a field label changes as well, you will need to pass in an updated label along with the ruleset in an object. If not,
 * just pass in the ruleset. Not doing this will result in the wrong label being applied to error messages.
 *
 * When using this function in a form, call it in 3 places: the change handler, componentWillMount, and componentWillUpdate.
 *
 * See an example of this used in the notification channels form.
 */
export function updateRules(form, masterValue, rules) {
  const fields = [];
  Object.keys(rules).forEach(fieldValue => {
    Object.keys(rules[fieldValue]).forEach(field => {
      fields.push(field);
    });
  });

  const currentRuleset = rules[masterValue];
  fields.forEach(field => {
    const fieldState = form.getField(field);
    if (has(currentRuleset, field)) {
      let fieldRuleset = currentRuleset[field];

      // Change the label if necessary to update the error messages with a new field label. Use props as well for rendering the label change.
      if (typeof fieldRuleset === 'object') {
        fieldState.setLabel(fieldRuleset.label);
        fieldRuleset = fieldRuleset.rules;
      }

      form.setRules(fieldState, fieldRuleset);
    } else {
      form.setRules(fieldState, '');
    }
  });
}

/* Shifts cursor in inputs/textareas/etc to the right on focus */
export function shiftInputCursor(e) {
  const tmp = e.target.value;
  e.target.value = '';
  e.target.value = tmp;
}

function isRule(rule, ruleName) {
  return typeof rule === 'string' ? rule === ruleName : Object.keys(rule).includes(ruleName);
}

function checkRequiredIfField(field, requiredIfField, requiredIfValue) {
  const { adjacentFields, form } = field;
  const actualValue =
    adjacentFields && adjacentFields[requiredIfField]
      ? adjacentFields[requiredIfField].getValue()
      : form.getValue(requiredIfField);

  return requiredIfValue === actualValue;
}

function evaluateRequiredIf(field, requiredIfRule, ruleKey = 'required_if') {
  if (typeof requiredIfRule === 'string') {
    const [requiredIfField, requiredIfValue] = requiredIfRule.split(/[:,]/);

    return checkRequiredIfField(field, requiredIfField, requiredIfValue);
  }

  let required = true;
  const matches = requiredIfRule[ruleKey];
  for (let i = 0; i < matches.length && required; i += 2) {
    const requiredIfField = matches[i];
    const requiredIfValue = matches[i + 1];
    required = checkRequiredIfField(field, requiredIfField, requiredIfValue);
  }

  return required;
}

export function isRequired(field) {
  // Prepare the rules
  let { rules } = field;

  if (!rules) {
    return false;
  }

  if (rules.split) {
    rules = rules.split('|');
  }

  // Check for simple required
  const hasRequiredRule = rules.some(rule => isRule(rule, 'required'));
  if (hasRequiredRule) {
    return true;
  }

  // Check required_if rule(s) against current values
  const isRequiredIf = rules.some(rule => isRule(rule, 'required_if') && evaluateRequiredIf(field, rule));
  if (isRequiredIf) {
    return true;
  }

  const isRequiredIfMultiple = rules.some(
    rule => isRule(rule, 'requiredIfMultiple') && evaluateRequiredIf(field, rule, 'requiredIfMultiple')
  );
  if (isRequiredIfMultiple) {
    return true;
  }

  // TODO: add support for required_unless, required_with, etc.

  return false;
}
