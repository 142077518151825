import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { isFunction } from 'lodash';
import classNames from 'classnames';

import { Box } from 'components/flexbox';

import Cell from './Cell';

@inject('$app', '$search')
@observer
class Row extends Component {
  handleRowClick = e => {
    const {
      $app,
      $search,
      selectOnRowClick,
      model,
      onRowClick,
      history,
      location,
      rootUrl,
      multiSelect = false
    } = this.props;

    /* detect if the click was on a `pt-button`, which generally *always* means that we
        want to not propagate the event upwards towards a `select() which is the default */
    e.persist();
    const { className, nodeName } = e && e.target;
    const shouldPreventSelection =
      className.includes('pt-control-indicator') ||
      className.includes('pt-button') ||
      className.includes('pt-switch') ||
      nodeName === 'INPUT' ||
      nodeName === 'BUTTON' ||
      false;

    if (onRowClick) {
      onRowClick(model, e);
    } else if (selectOnRowClick !== false) {
      if (shouldPreventSelection) {
        e.stopPropagation();
      } else if (!shouldPreventSelection) {
        model.select({ multi: multiSelect });
        if (history && rootUrl) {
          history.push(`${$app.adjustUrlForShim(location, rootUrl)}/${model.get('id')}`);
        }
      }
    }
    $search.closeSearch();
  };

  handleRowDoubleClick = () => {
    const { onRowDoubleClick, model } = this.props;
    if (onRowDoubleClick) {
      onRowDoubleClick(model);
    }
  };

  handleMouseOver = e => {
    e.persist();
    const { onRowMouseOver, model } = this.props;
    if (onRowMouseOver) {
      onRowMouseOver(model);
    }
  };

  handleMouseOut = e => {
    e.persist();
    const { onRowMouseOut, model } = this.props;
    if (onRowMouseOut) {
      onRowMouseOut(model);
    }
  };

  shouldComponentUpdate(nextProps) {
    const { lastUpdated, columns, selected, className, model, style = {}, responsiveMode, virtualized } = this.props;

    if (virtualized) {
      return true;
    }

    const {
      lastUpdated: nextLastUpdated,
      columns: nextColumns,
      selected: nextSelected,
      className: nextClassName,
      model: nextModel,
      style: nextStyle = {},
      responsiveMode: nextResponsiveMode
    } = nextProps;

    // check simple 1:1 comparisons first
    if (
      lastUpdated !== nextLastUpdated ||
      nextColumns.length !== columns.length ||
      selected !== nextSelected ||
      className !== nextClassName ||
      model !== nextModel ||
      style.top !== nextStyle.top ||
      style.height !== nextStyle.height ||
      responsiveMode !== nextResponsiveMode
    ) {
      return true;
    }

    // if the simple checks fail, then walk the columns to see if any require changes
    if (nextColumns.some((column, index) => column.hidden !== columns[index].hidden)) {
      return true;
    }

    // all failed
    return false;
  }

  render() {
    const { model, style, className, rowClass, columns, selected, responsiveMode } = this.props;

    const rowClassName = classNames('tr', { selected }, isFunction(rowClass) ? rowClass(model) : rowClass, className);

    const visibleColumns = columns.filter(column => !column.hidden && (responsiveMode || column.type !== 'spacer'));

    return (
      <Box
        className={rowClassName}
        onClick={this.handleRowClick}
        onDoubleClick={this.handleRowDoubleClick}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        style={style}
        align="stretch"
      >
        {visibleColumns.map((column, idx) => {
          const value = column.computed ? model[column.name] : model.get(column.name);
          const values = column.referencedFields
            ? column.referencedFields.map(name => model[name] || model.get(name)).join('|')
            : value;

          return (
            <Cell
              {...this.props}
              key={column.key || column.name || column.type}
              column={column}
              value={values}
              index={idx}
              columns={visibleColumns}
            />
          );
        })}
      </Box>
    );
  }
}

export default withRouter(Row);
