import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { get } from 'lodash';

import { defaultVisualizationSettings } from 'models/Colors';
import Socket from 'util/Socket';
import { safelyParseJSON, isDev } from 'util/utils';
import api from 'util/api';

const sudoOverriddenPermissions = ['sql.editor', 'sql.psql'];

class AuthStore {
  @observable
  activeUser;

  @observable
  authenticated = false;

  @observable
  authenticating = false;

  @observable
  isTrialNoticeVisible = false;

  @observable
  loginInterstitialOpen = false;

  @observable
  hideLoginInterstitial = true;

  @observable
  passwordReqInProgress = false;

  @observable
  ssoLookupReqInProgress = false;

  @observable
  showLoginRecaptcha = false;

  @observable
  openConfig;

  @observable
  twoFactorDelay = 0;

  twoFactorVerifyRequired = false;

  regions = [
    { name: 'US', baseUrl: 'https://portal.kentik.com', matcher: /portal(\..+)?\.kentik\.com/ },
    { name: 'EU', baseUrl: 'https://portal.kentik.eu', matcher: /portal(\..+)?\.kentik\.eu/ }
  ];

  constructor() {
    if (window.localStorage) {
      this.hideLoginInterstitial = true || safelyParseJSON(localStorage.getItem('kentik.hideEventInterstitial')); // || false;
    }
  }

  destroy() {
    this.clearActiveUser();
  }

  // Don't "observe" this or it will reload app in original tab when spoofing to a non-admin user.
  get isAdministrator() {
    return this.activeUser && this.activeUser.user_level > 0;
  }

  get isSuperAdministrator() {
    return this.activeUser && (this.activeUser.user_level >= 2 || this.hasSudo);
  }

  get isPresetCompany() {
    return this.activeUser && this.activeUser.company_id === this.store.$dictionary.dictionary.templateDashboardsCID;
  }

  @computed
  get userTimezone() {
    return (this.activeUser && this.activeUser.userTimezone) || 'UTC';
  }

  @computed
  get hasSudo() {
    return this.activeUser && this.activeUser.sudoEnabled;
  }

  @computed
  get isDemoUser() {
    return this.isSpoofed && !!this.activeUser.user_email.match(/demouser-\d+-\d+@kentik.com/);
  }

  @computed
  get isDan() {
    return this.activeUser && this.activeUser.user_email === 'drohan@kentik.com';
  }

  @computed
  get isGreg() {
    return (
      (this.activeUser && this.activeUser.id === '15267') ||
      (this.activeUser && this.activeUser.chfAdmin && this.activeUser.chfAdmin.id === '15267')
    );
  }

  @computed
  get isSubtenantUser() {
    return !!(this.activeUser && this.activeUser.userGroup);
  }

  @computed
  get isSpoofed() {
    return !!(
      this.activeUser &&
      this.activeUser.id &&
      this.activeUser.chfAdmin &&
      this.activeUser.chfAdmin.id &&
      this.activeUser.id !== this.activeUser.chfAdmin.id
    );
  }

  @computed
  get isLandlordSpoofed() {
    return !!(
      this.activeUser &&
      this.activeUser.id &&
      this.activeUser.landlord &&
      this.activeUser.landlord.id &&
      this.activeUser.id !== this.activeUser.landlord.id
    );
  }

  @computed
  get isExpiredTrial() {
    return !!(
      this.activeUser &&
      this.activeUser.company &&
      this.activeUser.company.company_status === 'EXP' &&
      this.activeUser.company.trial_end_date &&
      this.activeUser.company.company_plan_id === 'TRIAL' &&
      moment(this.activeUser.company.trial_end_date).diff(moment()) <= 0
    );
  }

  @computed
  get isActiveTrial() {
    return !!(
      this.activeUser &&
      this.activeUser.company &&
      this.activeUser.company.company_status !== 'EXP' &&
      this.activeUser.company.trial_end_date &&
      this.activeUser.company.company_plan_id === 'TRIAL' &&
      moment(this.activeUser.company.trial_end_date).diff(moment()) > 0
    );
  }

  @computed
  get isCompany() {
    return !!(this.activeUser && this.activeUser.company && this.activeUser.company.company_plan_id === 'CUSTOMER');
  }

  @computed
  get isTotpExpired() {
    if (!this.activeUser) {
      return true;
    }

    const user = this.activeUser.chfAdmin || this.activeUser;
    const { last2fa = 0, totpTtl = 300000 } = user;

    return Date.now() - last2fa > totpTtl;
  }

  @computed
  get isInactiveCompany() {
    return !!(this.activeUser && this.activeUser.company && this.activeUser.company.status === 'INACTIVE');
  }

  @computed
  get isSuspendedCompany() {
    return !!(this.activeUser && this.activeUser.company && this.activeUser.company.company_status === 'SUSP');
  }

  @computed
  get activeUserDisplay() {
    if (!this.activeUser) return '';

    const { chfAdmin, user_name, user_full_name, id } = this.activeUser;
    const name = user_full_name || user_name;
    return chfAdmin && chfAdmin.id !== id ? `[Kentik] ${chfAdmin.user_full_name}` : name;
  }

  @computed
  get maxEnabledAlertPolicies() {
    return this.getPermission('alerts.limits.activePolicies') || 600;
  }

  @computed
  get maxAlertPolicies() {
    return this.getPermission('alerts.limits.totalPolicies') || 720;
  }

  @computed
  get snmpEstablished() {
    return (this.activeUser && this.activeUser.company && this.activeUser.company.has_snmp) || false;
  }

  @computed
  get interfacesClassified() {
    return (this.activeUser && this.activeUser.company && this.activeUser.company.interfaces_classified) || 0;
  }

  @computed
  get interfacesClassifiedDisplay() {
    return Math.round(this.interfacesClassified * 100).toString();
  }

  @computed
  get interfaceClassThreshold() {
    return (this.activeUser && this.activeUser.company && this.activeUser.company.interfaces_class_threshold) || 0.75;
  }

  @computed
  get interfaceClassBelowThreshold() {
    return this.interfacesClassified < this.interfaceClassThreshold;
  }

  @computed
  get providerClassBelowThreshold() {
    return false; // TODO: implement backend for provider class
  }

  @computed
  get networkClassEstablished() {
    return (
      (this.activeUser && this.activeUser.company && this.activeUser.company.network_classification_established) ||
      false
    );
  }

  @computed
  get userSettings() {
    if (this.activeUser && this.activeUser.settings) {
      return Object.assign({ visualizations: defaultVisualizationSettings }, this.activeUser.settings);
    }

    return { visualizations: defaultVisualizationSettings };
  }

  @computed
  get companyFullName() {
    if (this.openConfig.subtenancy) {
      return this.activeUser && this.activeUser.company
        ? this.activeUser.company.company_name_full
        : this.openConfig.subtenancy.company.company_name_full;
    }
    return 'Kentik';
  }

  @computed
  get currentRegion() {
    return this.regions.find(region => region.matcher.test(this.openConfig.baseUrl)) || null;
  }

  @action
  initialize() {
    this.csrfToken = document.getElementsByName('_token')[0].content;
    this.ssoErrorMsg = document.getElementsByName('errorMsg')[0].content;
  }

  @action
  async requestPasswordReset(params) {
    const { user_email: username, recaptcha } = params;
    const data = { username, recaptcha }; // legacy naming as reset could use username OR email
    const rawResponse = true;

    this.passwordReqInProgress = true;
    return api.post('/api/portal/password/requestreset', { showErrorToast: false, data, rawResponse }).then(
      res => {
        const { status } = res;
        this.passwordReqInProgress = false;
        return { success: true, status, msg: 'Password set request successful' };
      },
      res => {
        const { status } = res;
        this.passwordReqInProgress = false;
        return { success: false, status, msg: 'Password set request unsuccessful' };
      }
    );
  }

  @action
  setTwoFactorDelay = delay => {
    this.twoFactorDelay = delay;
  };

  @action
  async passwordSet(params) {
    const { password, token } = params;
    const data = { pass: password, passConfirm: password, token };
    const rawResponse = true;

    this.passwordReqInProgress = true;
    return api.post('/api/portal/password/reset', { showErrorToast: false, data, rawResponse }).then(
      res => {
        const { status } = res;
        this.passwordReqInProgress = false;
        return { success: true, status, msg: 'Password successfully set.' };
      },
      errRes => {
        const { status } = errRes;
        const msg = (errRes.body && errRes.body.error) || 'Error setting password';
        this.passwordReqInProgress = false;
        return { success: false, status, msg };
      }
    );
  }

  @action
  async getOpenConfig() {
    if (this.openConfig) {
      return this.openConfig;
    }

    // must use cb (cacheBust) param here to keep Safari from 'helping' and making the req before the cookie
    // for the orginal '/' req has been set, thus resetting csrf token AFTER '/' req was make, but before login call,
    // thus giving csrf errors on everything until browser refresh.
    return api.get(`/api/portal/open-config?cb=${Date.now()}`, { showErrorToast: false }).then(
      res => {
        this.openConfig = res;
        return res;
      },
      error => {
        throw error;
      }
    );
  }

  @action
  async ssoLookup({ email, recaptcha }) {
    this.ssoLookupReqInProgress = true;
    return api.post('/api/portal/ssolookup', { showErrorToast: false, data: { email, recaptcha } }).then(
      res => {
        this.ssoLookupReqInProgress = false;
        return res;
      },
      () => {
        this.ssoLookupReqInProgress = false;
        return { companies: [] };
      }
    );
  }

  @action
  async getSsoStatus() {
    return api.get(`/api/portal/sso/status/${this.activeUser.company_id}`).then(res => res, () => ({}));
  }

  @action
  async verifyTotp(params) {
    const { token, force } = params;
    const data = { token };
    if (force) {
      data.force = true;
    }
    return api.post('/api/portal/totp/verify', { showErrorToast: false, data }).then(
      () => {
        this.activeUser.last2fa = Date.now();
        this.twoFactorVerifyRequired = false;
        if (!this.hideLoginInterstitial && this.isCompany) {
          this.loginInterstitialOpen = true;
        }
        return this.twoFactorVerifyRequired;
      },
      () => {
        this.twoFactorVerifyRequired = true;
        return this.twoFactorVerifyRequired;
      }
    );
  }

  checkV4Redirect(locationState) {
    const { settings } = this.activeUser;
    const { search } = window.location;

    let { pathname } = window.location;

    if (locationState && locationState.from && locationState.from.pathname !== '/') {
      pathname = locationState.from.pathname;
    }

    // https://hackerone.com/reports/1536728
    // avoid open redirects to external domains; just send to application root as the path is clearly bad
    if (pathname.indexOf('//') >= 0) {
      window.location = '/';
      return true;
    }

    if (
      this.isDemoUser ||
      (!this.isSubtenantUser &&
        (!settings || (settings && settings.UiAppDefault !== false)) &&
        !pathname.startsWith('/v3') &&
        !pathname.startsWith('/alerting/notifications') &&
        !pathname.startsWith('/admin4') &&
        !pathname.startsWith('/profile4') &&
        !pathname.startsWith('/export') &&
        !pathname.startsWith('/analytics/rawFlow') &&
        !pathname.startsWith('/v4'))
    ) {
      let redirect = '/v4';
      if (pathname.includes('/explorer')) {
        if (pathname.includes('/alarm')) {
          redirect = pathname.replace('/explorer/alarm/', '/v4/core/explorer/alarm/a');
        } else {
          redirect = pathname.replace('/explorer', '/v4/core/explorer');
        }
      } else if (pathname.includes('/library/dashboard')) {
        redirect = pathname.replace('/library/dashboard', '/v4/library/dashboards');
      } else if (pathname.includes('/library/savedView')) {
        redirect = pathname.replace('/library/savedView', '/v4/library/saved-views');
      } else if (pathname.includes('/library/alarm')) {
        redirect = pathname.replace('/library/alarm/', '/v4/library/alarm/a');
      } else if (pathname.includes('/email/verify')) {
        redirect = pathname.replace('/email/verify', '/v4/email/verify');
      } else if (pathname.includes('/dashboards')) {
        redirect = pathname.replace('/dashboards', '/v4/library/dashboards');
      }

      // if for any reason the path is missing the /v4/ prefix, we have a bad URL. Send them to the dashboard.
      if (!redirect.startsWith('/v4/')) {
        window.location = '/v4';
        return true;
      }

      window.location = redirect + search;
      return true;
    }

    if (this.isSubtenantUser && !pathname.startsWith('/profile4')) {
      setTimeout(() => window.location.assign('/v4'), 50);
      return true;
    }

    return false;
  }

  @action
  async verifyAuth() {
    return api.get('/api/portal/users/whoami', { showErrorToast: false }).then(
      res => {
        if (res.user) {
          this.activeUser = res.user;
          this.authenticated = true;
          this.authenticating = false;
          this.isTrialNoticeVisible = this.activeUser.company && this.activeUser.company.company_plan_id === 'TRIAL';

          // always show the login interstitial, no matter where they land.
          if (!this.hideLoginInterstitial && this.isCompany) {
            this.loginInterstitialOpen = true;
          }

          this.checkV4Redirect();
        }
        return this.authenticated;
      },
      () => {
        this.clearActiveUser();
        return this.authenticated;
      }
    );
  }

  @action
  async authenticate(credentials, locationState) {
    this.authenticating = true;
    const data = {
      user_email: credentials.user_email,
      password: credentials.password,
      'g-recaptcha-response': credentials.recaptcha,
      noRedirect: true
    };

    // hide the error Toast on API requests, because we're handling it different for Login
    return api.post('/api/portal/login', { showErrorToast: false, data, rawResponse: true }).then(
      res => {
        const { body } = res;
        let redirectingToV4 = false;
        if (body.user) {
          this.showLoginRecaptcha = false;
          this.activeUser = body.user;
          this.authenticated = true;
          this.authenticating = false;
          this.isTrialNoticeVisible = this.activeUser.company && this.activeUser.company.company_plan_id === 'TRIAL';

          redirectingToV4 = this.checkV4Redirect(locationState);

          // if there's no TOTP enabled, we can show the interstitial.
          if (!this.activeUser.totpEnabled && !this.hideLoginInterstitial && this.isCompany) {
            this.loginInterstitialOpen = true;
          }
        }
        return { success: true, user: this.activeUser, suppressRedirect: redirectingToV4 };
      },
      errRes => {
        const errorMsg = (errRes && errRes.body && errRes.body.message) || '';
        this.showLoginRecaptcha = errRes && errRes.body && errRes.body.recaptcha;
        this.authenticating = false;

        const aclRegex = new RegExp('ACL. (.*) is not whitelisted');
        let ip = null;
        if (aclRegex.test(errorMsg)) {
          [, ip] = aclRegex.exec(errorMsg);
        }

        return { success: false, isAcl: !!ip, ip };
      }
    );
  }

  initializePendo() {
    if (!this.isSpoofed && !isDev && window.pendo) {
      const envName = this.store.$dictionary.dictionary.envName;

      const { pathname } = window.location;
      const isV3 =
        !pathname.startsWith('/alerting-admin') && !pathname.startsWith('/admin4') && !pathname.startsWith('/profile4');

      window.pendo.initialize({
        visitor: {
          id: `${envName}-${this.activeUser.id}`,
          // Note: intentionally avoiding sharing PII here
          role: this.activeUser.user_level,
          status: this.activeUser.user_status,
          tenantId: this.activeUser.user_group_id,
          lastUsedVersion: isV3 ? 'v3' : 'v4',
          envName
        },
        account: {
          id: `${envName}-${get(this.activeUser, 'company.id')}`,
          company_id: get(this.activeUser, 'company.id'),
          name: get(this.activeUser, 'company.company_name'),
          is_paying: get(this.activeUser, 'company.company_plan_id') === 'Customer',
          company_type: get(this.activeUser, 'company.company_plan_id'),
          company_status: get(this.activeUser, 'company.company_status')
        }
      });
    }
  }

  @action
  clearTrialNotice = () => (this.isTrialNoticeVisible = false);

  @action
  handleToggleLoginInterstitial = () => (this.loginInterstitialOpen = !this.loginInterstitialOpen);

  @action
  handleSetShowInterstitial = val => {
    this.hideLoginInterstitial = val;
    window.localStorage.setItem('kentik.hideEventInterstitial', this.hideLoginInterstitial);
  };

  @action
  getPermissions = () => this.activeUser.permissions;

  @action
  getPermission = path => {
    const permission = get(this.activeUser.permissions, path);

    if (permission !== undefined) {
      return permission;
    }
    console.error(`Permission '${path}' does not exist!`);
    return null;
  };

  @action
  hasPermission = path => {
    if (!this.activeUser || !this.activeUser.permissions) {
      return false;
    }

    if (sudoOverriddenPermissions.includes(path) && (this.hasSudo || this.isSpoofed)) {
      return true;
    }

    const permission = get(this.activeUser.permissions, path);

    if (permission !== undefined) {
      return permission;
    }

    if (path === 'v4Preview.enabled' && this.activeUser.company.company_plan_id === 'CUSTOMER') {
      return true;
    }

    console.error(`Permission '${path}' does not exist!`);
    return false;
  };

  @action
  clearActiveUser = () => {
    this.loginInterstitialOpen = false;
    this.authenticated = false;
    this.activeUser = null;
    this.twoFactorVerifyRequired = false;
  };

  @action
  requestAssistance() {
    return api.post('/api/portal/trial/assistance');
  }

  @action
  logout = async () => {
    const result = await api.get('/api/portal/logout', { noRedirect: true });
    this.clearActiveUser();
    return result;
  };

  socketSessionSubscribe() {
    if (!this.sessionSocket) {
      this.sessionSocket = new Socket({
        outType: 'sessionEvent',
        inType: 'sessionEvent',
        listenToBroadcast: true,
        frequency: 'heartbeat',
        delaySend: true,
        onSuccess: data => {
          if (data && data.code === 401) {
            this.store.logout();
          }
        },
        onError(err) {
          console.warn('Received Socket Error (session)', err);
        }
      });
    }
    this.sessionSocket.setPayload({});
    this.sessionSocket.send();
  }
}

export default new AuthStore();
