import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$bgpDatasets')
class PeeringAnalytics extends Component {
  title = 'Peering Analytic';

  url = '/analytics/peering';

  render() {
    const { result, $bgpDatasets } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="exchange"
        url={this.url}
        nameField="report_name"
        collection={$bgpDatasets.collection}
        {...this.props}
      >
        <span>type: {result.report_pub_name}</span>
      </ResultRow>
    );
  }
}

export default PeeringAnalytics;
