import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Intent, Button, Icon } from '@blueprintjs/core';
import { MaterialIcon } from 'components/Icon';

import classNames from 'classnames';
import { numberWithCommas } from 'util/utils';

import { Flex, Box } from 'components/flexbox';

export const summaryTypes = {
  alarms: {
    iconName: 'notifications',
    intent: Intent.WARNING,
    className: 'pt-intent-warning'
  },
  mitigations: {
    iconName: 'security',
    // intent: Intent.DANGER,
    className: 'pt-intent-purple',
    materialIcon: true
  },
  ack_req: {
    iconName: 'endorsed',
    intent: Intent.PRIMARY,
    className: 'pt-intent-primary'
  }
};

const smallNumberLabelStyle = {
  fontSize: 10,
  color: 'rgba(255, 255, 255, 0.6)'
};

const SeverityCount = observer(({ label, value }) => {
  if (value === 0 || Number.isNaN(value)) {
    return null;
  }

  return (
    <Flex flexAuto align="center" justify="center" flexColumn mx={2}>
      <h5 className="no-margin">{numberWithCommas(value)}</h5>
      <span className="pt-text-muted" style={smallNumberLabelStyle}>
        {label}
      </span>
    </Flex>
  );
});

const AlertSummaryBox = observer(
  ({
    isLoading,
    type,
    text,
    loading,
    value,
    onStartManualMitigation,
    counts,
    activeAlarmClassName,
    onShowAlarms,
    showManualMitigationButton,
    ...rest
  }) => {
    const { className, iconName, materialIcon } = summaryTypes[type];

    const cardClassName = classNames('pt-text-left', {
      [`pt-tag pt-large ${activeAlarmClassName || className}`]: value > 0,
      'pt-callout': loading || value === 0
    });

    const iconClassName = classNames({ 'pt-text-muted': value === 0 });
    const valueClassName = classNames('no-margin strong', { 'pt-text-muted': value === 0 });
    const mitigationButtonClassName = classNames('pt-small pt-intent-danger', { active: value > 0 });

    const iconProps = {
      style: { fontSize: 36 },
      className: iconClassName,
      [`${materialIcon ? 'name' : 'iconName'}`]: iconName
    };

    const alarmsCritical = counts && counts.get('critical');
    const alarmsMajor = counts && counts.get('major') + counts.get('major2');
    const alarmsMinor = counts && counts.get('minor') + counts.get('minor2');
    const ackReqCritical = counts && counts.get('ack_req_critical_count');
    const ackReqMitigations = counts && counts.get('ack_req_mit_count');
    const ackReqMajor = counts && counts.get('ack_req_major_count');
    const ackReqMinor = counts && counts.get('ack_req_minor_count');
    return (
      <Flex flexAuto align="center" p={2} className={cardClassName} {...rest}>
        <Box mr={2}>{materialIcon ? <MaterialIcon {...iconProps} /> : <Icon {...iconProps} />}</Box>
        <Box flexAuto>
          <h1 className={valueClassName}>{numberWithCommas(value)}</h1>
          <span className="pt-text-muted" style={{ fontSize: 14, color: value > 0 ? 'rgba(255, 255, 255, 0.6)' : '' }}>
            {text}
          </span>
        </Box>
        {type === 'alarms' && (
          <Flex justify="space-between">
            <SeverityCount value={alarmsCritical} label="Critical" />
            <SeverityCount value={alarmsMajor} label="Major" />
            <SeverityCount value={alarmsMinor} label="Minor" />
          </Flex>
        )}

        {type === 'ack_req' && (
          <Flex justify="space-between">
            <SeverityCount value={ackReqMitigations} label="Mitigations" />
            <SeverityCount value={ackReqCritical} label="Critical" />
            <SeverityCount value={ackReqMajor} label="Major" />
            <SeverityCount value={ackReqMinor} label="Minor" />
          </Flex>
        )}

        {type === 'mitigations' &&
          showManualMitigationButton && (
            <Box style={{ alignSelf: 'flex-end' }}>
              <Tooltip content="Start manual mitigation">
                <Button
                  className={mitigationButtonClassName}
                  iconName="plus"
                  onClick={() => onStartManualMitigation()}
                  style={{ boxShadow: 'none', borderRadius: 40 }}
                />
              </Tooltip>
            </Box>
          )}
      </Flex>
    );
  }
);

export default AlertSummaryBox;
