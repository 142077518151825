import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import $auth from 'stores/$auth';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: [
    'i_src_connect_type_name',
    'i_dst_connect_type_name',
    'i_src_network_bndry_name',
    'i_dst_network_bndry_name'
  ],
  filterFields: [
    'i_src_connect_type_name',
    'i_dst_connect_type_name',
    'i_src_network_bndry_name',
    'i_dst_network_bndry_name'
  ]
};

class InterfaceClassDependency extends AbstractDependency {
  get key() {
    return 'ic';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return !$auth.interfaceClassBelowThreshold;
  }

  get message() {
    return (
      <Fragment>
        <Box className="pt-text-smaller" mb={0.5}>
          <strong>Interface Classification</strong> is required by dimensions you're using, but your organization
          currently has only {$auth.interfacesClassifiedDisplay}% of interfaces classified. Results shown herein may be
          highly inaccurate.
        </Box>
        {this.showConfigureButton && (
          <Button
            iconName="cog"
            className="pt-small"
            onClick={() => $auth.store.history.push('/admin/interfaceClassification')}
          >
            Configure
          </Button>
        )}
      </Fragment>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || !$auth.interfaceClassBelowThreshold;
  }
}

export default InterfaceClassDependency;
