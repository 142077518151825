import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import { observer, inject } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { Field, Input, Switch, Select, Textarea, ShareLevelSelector } from 'components/forms';
import DeviceSelector2 from 'components/forms/device/DeviceSelector2';
import { FilterOptions } from 'components/sidebar';
import Icon, { MaterialIcon } from 'components/Icon';

import CategorySelector from 'views/Admin/Templates/CategorySelector';
import ParametricOptionsForm from './ParametricOptionsForm';
import DashboardNavigationDisplay from './DashboardNavigationDisplay';
import GuidedModeBehavior from '../Dashboards/DashboardItem/Form/GuidedModeBehavior';

const lookbackValueRenderer = (option, placeholder) => {
  if (!option) {
    return <span className="pt-text-muted">{placeholder || 'Select a value...'}</span>;
  }

  return (
    <span>
      <Icon name="time" style={{ marginRight: 7 }} />
      {option && option.label}
    </span>
  );
};

@inject('$auth', '$dictionary', '$queryTemplates')
@observer
class DashboardDetailsForm extends Component {
  componentWillMount() {
    const { dashboardItem, form } = this.props;
    if (dashboardItem) {
      const field = form.getField('panel_title');
      field.setRules('required');
      field.setValue(dashboardItem.get('panel_title'));

      form.setValue('parametric_mode', dashboardItem.get('parametric_mode'));
      form.setValue('parametric_overrides.filterField', dashboardItem.get('parametric_overrides.filterField'));
      form.setValue('parametric_overrides.operator', dashboardItem.get('parametric_overrides.operator'));
    }
  }

  handleParametricToggle = (field, value) => {
    const { form, dashboardItem } = this.props;

    // if they're toggling to "on", set a value
    if (value && !form.getValue('parametric_fields').length) {
      form.setValue('parametric_fields', [
        { type: 'as_name', value: '', label: 'AS Name', question: 'Which AS Name would you like to see?' }
      ]);
    }

    if (dashboardItem) {
      form.getField('parametric_overrides.filterField').setRules(value ? 'required' : '');
      form.getField('parametric_overrides.operator').setRules(value ? 'required' : '');
    }
  };

  getAvailableFiltersConfig = parametricType => {
    const { $dictionary, form, dashboardItem: item } = this.props;
    const { activeFilterFields } = item.get('queries')[0];

    const { parametric_mode } = form.getValues();

    // we disable the `override_specific` option if there are no filters in this Items existing filters
    // that can be overridden by the selected parametric type
    const availableFilters = $dictionary.getAvailableFiltersForParametricType(parametricType);
    const canOverrideSpecific = availableFilters.some(filter => activeFilterFields.includes(filter.value));

    if (canOverrideSpecific) {
      if (parametric_mode === 'override_specific') {
        const filters = availableFilters.filter(filter => activeFilterFields.includes(filter.value));
        return { availableFilters: filters, canOverrideSpecific };
      }
    }

    return { availableFilters, canOverrideSpecific };
  };

  render() {
    const { $dictionary, form, onEditFilters, editingFilters, model, dashboardItem } = this.props;
    const isParametric = form.getValue('parametric');

    const {
      filterOperatorOptions,
      standardFilterOperators,
      dictionary: { queryFilters }
    } = $dictionary;
    let availableFiltersConfig = {};
    if (dashboardItem && isParametric) {
      const parametricFields = form.getValue('parametric_fields');
      const parametricType = parametricFields && parametricFields[0];
      availableFiltersConfig = this.getAvailableFiltersConfig(parametricType);
    }

    const lookbackOptions = $dictionary
      .getSelectOptions('showLast', { parseKeys: true })
      .filter(opt => opt.label !== 'Custom')
      .map(opt => ({ ...opt, iconCls: 'clock' }));

    return (
      <Flex align="flex-start">
        <Box col={6} className="pt-card flat">
          <Box p={3}>
            <Field name="dash_title" autoFocus>
              <Input />
            </Field>
            <Field name="description">
              <Textarea rows={4} />
            </Field>

            <Box>
              <CategorySelector saveAfterCreateCategory={false} model={model} field={form.getField('category_id')} />
            </Box>

            <Box mb={2}>
              <ShareLevelSelector
                showGlobalShareLevel={false}
                label="Visibility"
                field={form.getField('share_level')}
              />
            </Box>

            <h5>Default Query Options</h5>
            <p className="pt-text-muted pt-text-smaller">
              Define query options that will be applied to all Panels when the Dashboard is loaded.
            </p>

            <Field
              name="query.lookback_seconds"
              label="Time Range"
              options={lookbackOptions}
              onChange={this.handleRunQuery}
            >
              <Select
                valueRenderer={lookbackValueRenderer}
                menuWidth={243}
                tetherOptions={{
                  constraints: [{ attachment: 'together', pin: true, to: 'scrollParent' }]
                }}
              />
            </Field>

            <Box mb={2}>
              <label className="pt-label">Devices</label>
              <DeviceSelector2
                deviceNameFieldName="query.device_name"
                deviceTypesFieldName="query.device_types"
                deviceLabelsFieldName="query.device_labels"
                deviceSitesFieldName="query.device_sites"
                allDevicesFieldName="query.all_devices"
              />
            </Box>

            <Box>
              <label className="pt-label">Filters</label>
              <FilterOptions
                fieldName="query.filters"
                isOpen={editingFilters}
                onClose={onEditFilters}
                showEmptyText={false}
              />
              <Button
                className="pt-small"
                onClick={onEditFilters}
                iconName="multi-select"
                style={{ marginTop: 8 }}
                text="Edit Filters"
              />
            </Box>
          </Box>
        </Box>
        <Box col={6}>
          {dashboardItem && (
            <Box ml={2} className="pt-card flat" p={3} mb={2}>
              <Flex align="center" mb={1}>
                <MaterialIcon name="assessment" className="pt-text-muted" style={{ marginRight: 8, fontSize: 20 }} />
                <h5 className="no-margin">Dashboard Panel</h5>
              </Flex>
              <Field name="panel_title">
                <Input />
              </Field>
            </Box>
          )}
          <Box ml={2} className="pt-card flat" p={3} mb={2}>
            <Flex align="center" justify="space-between" mb={1}>
              <Flex align="center">
                <MaterialIcon
                  name="question_answer"
                  className="pt-text-muted"
                  style={{ marginRight: 8, fontSize: 20 }}
                />
                <h5 className="no-margin">
                  Guided Mode{' '}
                  <span style={{ marginLeft: 4 }} className="pt-tag pt-intent-warning">
                    beta
                  </span>
                </h5>
              </Flex>

              <Field name="parametric" showLabel={false} className="no-margin" onChange={this.handleParametricToggle}>
                <Switch className="pt-large" switchLabel={form.getValue('parametric') ? 'Enabled' : 'Disabled'} />
              </Field>
            </Flex>
            <p className="pt-text-muted pt-text-smaller">
              Guided Mode lets users see data about a dimension from a preselected dimension family without setting
              complex query options. When enabled, the dashboard will include the prompt specified below, and the user’s
              response will determine the value of a predefined filter that is applied to each panel.
            </p>
            <ParametricOptionsForm form={form} />
            {dashboardItem &&
              isParametric && (
                <GuidedModeBehavior
                  availableFiltersConfig={availableFiltersConfig}
                  parametric_mode={form.getValue('parametric_mode')}
                  filterOperatorOptions={filterOperatorOptions}
                  standardFilterOperators={standardFilterOperators}
                  queryFilters={queryFilters}
                  boxProps={{ mt: 2 }}
                  fieldBoxProps={{}}
                  disabled={!isParametric}
                  form={form}
                />
              )}
          </Box>
          {!model.isNew && (
            <Box ml={3} className="pt-card flat" p={3}>
              <DashboardNavigationDisplay model={model} />
            </Box>
          )}
        </Box>
      </Flex>
    );
  }
}

export default DashboardDetailsForm;
