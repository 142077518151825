import React from 'react';
import { observer } from 'mobx-react';
import { EditableText } from '@blueprintjs/core';

import { whitelistFieldProps } from 'forms/utils';

const EditInPlace = props => {
  const { field } = props;
  return <EditableText {...field.getProps()} {...whitelistFieldProps(props)} autoComplete="off" />;
};

export default observer(EditInPlace);
