import Validator from 'validatorjs';
import { generateFilterFields, generateTimeFields } from './helpers';

const filterFields = generateFilterFields();
const filterDimensions = generateFilterFields('filterDimensions', [], {
  filterGroupsRules: [{ required_if: ['filterDimensionsEnabled', true] }]
});
const timeFields = generateTimeFields();

function skipDimensionValidation(attribute) {
  return (
    (attribute === 'generatorDimensions' && !this.validator.input.generatorMode) ||
    (attribute === 'metric' && this.validator.input.generatorMode)
  );
}

Validator.registerImplicit(
  'customRequireDimensions',
  function customRequireDimensions(val, req, attribute) {
    const chartTypes = ['sankey', 'matrix', 'geoHeatMap'];
    const { input } = this.validator;

    if (attribute === 'generatorDimensions' && !input.generatorMode) {
      return true;
    }

    if (
      (attribute === 'metric' && input.generatorMode && !input.filterDimensionsEnabled) ||
      chartTypes.includes(input.viz_type)
    ) {
      return this.validator.getRule('required').validate(val);
    }

    return true;
  },
  'A dimension is required'
);

Validator.register(
  'customMinDimensions',
  function customMinDimensions(val, req, attribute) {
    const [type, min] = this.getParameters();

    if (skipDimensionValidation.call(this, attribute)) {
      return true;
    }

    if (this.validator.input.viz_type === type) {
      return this.validator.getRule('min').validate(val, min);
    }

    return true;
  },
  'Too few dimensions selected for this chart type'
);

Validator.register(
  'customGeoHeatMapDimensions',
  function customGeoHeatMapDimensions(val, req, attribute) {
    const dimensions = [
      'i_device_site_country',
      'i_device_site_name',
      'kt_dst_market',
      'Geography_dst',
      'dst_geo_region',
      'dst_geo_city',
      'kt_src_market',
      'src_geo_region',
      'src_geo_city',
      'Geography_src',
      'i_ult_exit_site_country',
      'i_ult_exit_site'
    ];

    if (skipDimensionValidation.call(this, attribute)) {
      return true;
    }

    if (this.validator.input.viz_type === 'geoHeatMap') {
      return val.length === 1 && dimensions.includes(val[0]);
    }

    return true;
  },
  'Only a single geographical dimension (either Country, Region, City, Site, Site Country, Ultimate Exit Site, Ultimate Exit Site Country, or Custom Geo) can be selected'
);

const options = {
  showPristineErrors: true,
  groups: {
    query: [
      '^aggregate',
      'cidr.*',
      'customAsGroups',
      '^cutFn',
      'fastData',
      '^filterDimension',
      'generator.*',
      'matrixBy',
      'metric',
      'mirror',
      'overlay_day',
      'outsort',
      'secondaryOutsort',
      '^secondaryTopx',
      '^show.*overlay',
      'topx'
    ],
    time: Object.keys(timeFields),
    filtering: ['filters.filterGroups', 'filters.connector'],
    devices: ['device_types', 'device_labels', 'device_sites', 'device_name', 'all_devices'],
    bracketing: ['bracketOptions'],
    view: ['sync_axes', 'use_log_axis', 'use_secondary_log_axis', 'sync_extents', 'update_frequency', 'viz_type']
  }
};

const dimensionsFieldValidation = {
  rules: ['customRequireDimensions', 'customGeoHeatMapDimensions', 'customMinDimensions:sankey,2', 'max:8'],
  messages: {
    customMinDimensions: 'At least 2 dimensions must be selected for this chart type',
    max: 'A maximum of 8 dimensions can be selected at once'
  }
};

const fields = {
  /*
   * Query
   */
  metric: {
    ...dimensionsFieldValidation,
    label: 'Group By Dimensions',
    closeOnSelect: false,
    reorderable: true,
    filterable: true,
    optionsBlacklist: new Set(['Traffic'])
  },
  cidr: {
    label: 'v4 CIDR'
  },
  cidr6: {
    label: 'v6 CIDR'
  },
  customAsGroups: {
    label: 'Use AS Groups'
  },
  matrixBy: {
    label: 'Matrix With',
    rules: 'required_if:viz_type,matrix',
    messages: {
      required_if: 'Matrix With Dimensions are required'
    }
  },
  hostname_lookup: {
    label: 'Reverse DNS Lookups'
  },
  fastData: {
    label: 'Dataseries'
  },
  aggregateTypes: {
    rules: 'required|noConflictingAggregateTypes|maxGenericAggregates:4',
    messages: {
      required: 'At least one metric is required'
    }
  },
  aggregateThresholds: {},
  unitsLegacy: { label: 'Use Preset Selections for:' },
  outsort: {
    label: 'Primary Display & Sort Metric',
    rules: 'required'
  },
  secondaryOutsort: {
    rules: 'different:outsort|validMirrorAndSecondaryOutsort',
    messages: {
      different: 'Secondary Sort must be different from Primary Sort'
    }
  },
  bracketOptions: {
    messages: {
      required_if: 'Bracket Options are required for this chart type'
    }
  },
  secondaryTopxSeparate: {
    label: 'Sort Secondary Metric Separately'
  },
  secondaryTopxMirrored: {
    label: 'Show Secondary Metric on Negative Axis',
    rules: 'mirrorOrSecondaryMirrorOnly'
  },
  topx: {
    label: 'Visualization Depth'
  },
  depth: {},
  sync_all_axes: {},
  sync_axes: {},
  use_log_axis: {},
  use_secondary_log_axis: {},
  sync_extents: {},
  show_site_markers: {},
  update_frequency: {},
  show_overlay: {
    label: 'Historical Overlay'
  },
  show_total_overlay: {
    label: 'Total Overlay'
  },
  overlay_day: {
    label: 'Days Back',
    type: 'number',
    transform: {
      in: value => (value ? value * -1 : value),
      out: value => (value ? value * -1 : value)
    }
  },
  mirror: {
    label: 'Bi-directional Charting',
    rules: 'validMirrorAndSecondaryOutsort|mirrorOrSecondaryMirrorOnly',
    beta: true
  },
  filterDimensionsEnabled: {},
  filterDimensionName: {
    label: 'Dimension Name',
    rules: [{ required_if: ['filterDimensionsEnabled', true] }],
    messages: {
      required_if: 'Filter Dimension Name is required when filter-based dimension is enabled'
    }
  },
  filterDimensionOther: {
    helpText: 'Includes all traffic that does not match any of the series defined below'
  },
  ...filterDimensions,
  ...filterFields,
  ...timeFields,

  /*
   * Devices
   */
  all_devices: {
    defaultValue: false,
    rules: 'atLeastOneIfNotAllDevices'
  },
  device_name: {
    defaultValue: []
  },
  device_labels: {
    defaultValue: []
  },
  device_sites: {
    defaultValue: []
  },
  device_types: {
    defaultValue: []
  },

  /*
   * Display
   */
  viz_type: {
    label: 'Display Type'
  },
  generatorDimensions: {
    ...dimensionsFieldValidation,
    label: 'Chart-level Group By Dimensions'
  },
  generatorMode: {
    label: 'Generate one chart per series'
  },
  generatorColumns: {
    label: 'Charts per row'
  },
  generatorQueryTitle: {
    label: 'Chart Titles'
  },
  generatorTopx: {
    label: 'Chart Visualization Depth'
  },
  generatorPanelMinHeight: {
    label: 'Chart Height'
  }
};

export { fields, options };
