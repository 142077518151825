import React from 'react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex } from 'components/flexbox';

const DimensionValueRenderer = ({ option, onClick, readOnly, onUnselect, field }) => {
  let group = option && option.group;
  let className = option ? option.className : null;
  let label = option ? option.label : null;

  if (onUnselect && option) {
    const dimensionValueClassName = classNames('pt-tag dimension-tag dimension-tag-single solid', option.className, {
      'pt-tag-removable': !readOnly && onUnselect
    });

    const style = readOnly ? {} : { cursor: 'pointer' };

    return (
      <Flex
        align="stretch"
        className={dimensionValueClassName}
        onClick={onClick}
        style={style}
        pl={group ? undefined : 1}
      >
        {group && <span className="dimension-tag-group">{group}</span>}
        <Flex flexColumn flexAuto justify="center">
          {option.tagLabel || option.label}
        </Flex>
        {!readOnly &&
          onUnselect && (
            <button
              className="pt-tag-remove"
              style={{ alignSelf: 'flex-start' }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onUnselect(field, option.value);
              }}
            />
          )}
      </Flex>
    );
  } else if (!option && field.getValue()) {
    group = 'Invalid';
    className = 'pt-intent-critical';
    label = field.getValue();
  }

  const dimensionButtonClassName = classNames('pt-small dimension-tag solid', className);

  return (
    <div className="select-wrapper pt-button-group pt-small">
      <Button className={dimensionButtonClassName}>
        {group && <span className="dimension-tag-group">{group}</span>}
        {label ? <strong>{label}</strong> : 'Select a dimension...'}
        <span className="pt-icon-standard pt-icon-chevron-down pt-align-right" />
      </Button>
    </div>
  );
};

export default DimensionValueRenderer;
