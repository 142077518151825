import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'components/flexbox';
import { Link } from 'react-router-dom';
import { Page, PageBody } from 'components/page';
import { Spinner } from '@blueprintjs/core';

@inject('$users')
@observer
class VerifyEmail extends Component {
  state = { reqComplete: false };

  getSearchParam(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(this.props.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  componentDidMount() {
    const { $users } = this.props;
    const token = this.getSearchParam('token');
    $users.updateUserEmail(token).then(res => {
      if (res.success) {
        this.setState({ reqComplete: true, success: true });
      } else {
        this.setState({ reqComplete: true, success: false, msg: res.msg });
      }
    });
  }

  render() {
    const { reqComplete, success, msg } = this.state;

    return (
      <Page title="Email Verification">
        <PageBody>
          <Flex flexColumn align="center" col={12} p={4}>
            <Box pb={1}>
              <h4>Email Address Verification</h4>
            </Box>
            {!reqComplete && (
              <Flex flexColumn align="center" className="pt-callout pt-intent-success">
                <Box>
                  <Spinner />
                </Box>
                <Box>
                  <p>Verifying email address update...</p>
                </Box>
              </Flex>
            )}
            {reqComplete &&
              success && (
                <Box col={4} pb={1} className="pt-callout pt-intent-success">
                  <h5>Success!</h5>
                  <p>Your email address has been updated.</p>
                  <Link to="/" replace>
                    Return to Kentik
                  </Link>
                </Box>
              )}
            {reqComplete &&
              !success && (
                <Box col={4} pb={1} className="pt-callout pt-intent-danger">
                  <h5>Unable to update email. </h5> <p>{msg}</p>
                  <Link to="/" replace>
                    Return to Kentik
                  </Link>
                </Box>
              )}
          </Flex>
        </PageBody>
      </Page>
    );
  }
}

export default VerifyEmail;
