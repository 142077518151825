import { action, computed, observable } from 'mobx';
import { uniqueId, isArray, isMatchWith, isString, orderBy, groupBy, union } from 'lodash';
import { processLargeArrayAsync } from 'util/utils';

import api from 'util/api';
import collatePromises from '../services/collatePromises';

import BaseModel from './BaseModel';

class Collection {
  @observable
  requestStatus = null;

  @observable
  models = [];

  error = observable.shallow(null);

  id = uniqueId('c_');

  @observable
  filterState = '';

  @observable
  activePresetFilter;

  @observable
  sortState = {};

  @observable
  groupBy = null;

  @observable
  selected = null;

  @observable
  hasFetched = false;

  @observable
  original = null;

  @observable
  lastUpdated;

  fetchPromise = null;

  constructor(data = [], options = {}) {
    const {
      activePresetFilter = this.defaultPresetFilter,
      sortState = this.defaultSortState,
      groupBy: currGroupBy = this.defaultGroupBy,
      hasFetched = false,
      models
    } = options;

    // manual bind for converted arrow functions. Allows subclasses to override but keeps arrow function "this" behavior.
    this.processData = this.processData.bind(this);
    this.reset = this.reset.bind(this);

    this.activePresetFilter = activePresetFilter;

    this.sortState = sortState;
    this.groupBy = currGroupBy;

    if (models) {
      this.models = models;
    } else {
      this.set(data);
    }

    // Most common use case for this would be when a collection is initialized with data upfront.
    // When a collection is initialized with data and a PresetFilterButton group is applied to it,
    // you may encounter a situation where the filter buttons all become disabled when filtering by a property
    // that yields 0 results. Setting this option will prevent that.
    this.hasFetched = hasFetched;

    this.parent = options.parent || undefined;
  }

  get jsonRoot() {
    return false;
  }

  get urlPaths() {
    return false;
  }

  get fetchMethod() {
    return 'get';
  }

  get queuedFetchKey() {
    return null;
  }

  get defaultSortState() {
    return {};
  }

  get baseSort() {
    return null;
  }

  get secondarySort() {
    return null;
  }

  get defaultGroupBy() {
    return null;
  }

  get defaultPresetFilter() {
    return (this.presetFilters && this.presetFilters.find(filter => filter.default)) || undefined;
  }

  get presetFilters() {
    return false;
  }

  get useAsyncAdd() {
    return false;
  }

  /**
   * Returns the URL where the model's resource would be located on the server.
   *
   * @abstract
   */
  get url() {
    throw new Error('You must implement this method');
  }

  /**
   * Specifies the model class for that collection
   */
  get model() {
    return BaseModel;
  }

  /**
   * Returns this Collection's Models as raw JSON.
   */
  toJS() {
    return this.models.map(model => model.toJS());
  }

  /**
   * Creates a new model instance with the given attributes
   */
  build = (attributes, options = {}) => {
    const { select = false } = options;
    const ModelClass = this.model;
    const model = new ModelClass(attributes, options);

    // models should always have a collection.
    model.collection = this;
    model.set(model.deserialize(attributes));

    if (select) {
      this.selected = model;
    }

    return model;
  };

  /**
   * Questions whether the request exists and matches a certain label
   * If no label are passed it will check if any requests are active.
   */
  isRequestActive(label) {
    if (!label) {
      return this.requestStatus !== null;
    }

    return !!this.requestStatus && this.requestStatus === label;
  }

  /**
   * Whether the collection is empty
   */
  isEmpty() {
    return this.models.length === 0;
  }

  /**
   * Get a resource at a given position
   */
  at(index) {
    if (!this.models[index]) {
      console.warn(`No model found at index ${index}`);
      return false;
    }

    return this.models[index];
  }

  /**
   * Get a resource with the given id or uuid or if no id is provided, will return all the models
   */
  get(id) {
    const models = this.original || this.models;
    /* eslint-disable eqeqeq */
    return id !== undefined ? models.find(item => item.id == id) : models;
  }

  each(iterator) {
    this.models.forEach(iterator);
  }

  map(iterator) {
    return this.models.map(iterator);
  }

  some(iterator) {
    return this.models.some(iterator);
  }

  every(iterator) {
    return this.models.every(iterator);
  }

  /**
   * Finds an element with the given matcher
   *
   * TODO: this needs to die and be replaced by normal find()
   */
  find(query, customizer) {
    return this.models.find(({ attributes }) => isMatchWith(attributes.toJS(), query, customizer));
  }

  @action
  group(value) {
    this.groupBy = value;
  }

  get groupedData() {
    if (!this.groupBy) {
      return [];
    }

    // add ability to set this.groupBy to object. Expecting type(string) and groupByFn(function) properties.
    if (typeof this.groupBy === 'object') {
      return groupBy(this.models, this.groupBy.groupByFn);
    }

    return this.groupBy ? groupBy(this.models, model => model[this.groupBy] || model.get(this.groupBy)) : [];
  }

  @action
  move(oldIndex, newIndex) {
    this.models.splice(newIndex, 0, this.models.splice(oldIndex, 1)[0]);
  }

  @action
  sort(field) {
    let sortField = this.sortState.field;
    let sortDir = this.sortState.direction;

    // If a field was explicitly passed, use it
    if (field !== undefined) {
      sortField = field;
      sortDir = this.defaultSortState.direction || 'asc';

      // If the field passed was the same as current, toggle the direction (or turn off)
      if (field === this.sortState.field) {
        sortDir = this.sortState.direction === 'asc' ? 'desc' : 'asc';
      }
    }

    if (sortField) {
      if (this.sortState.field !== sortField || this.sortState.direction !== sortDir) {
        this.sortState = {
          field: sortField,
          direction: sortDir
        };
      }

      this.original = this.get();

      const iteratees = [];
      const orders = [];

      if (this.baseSort) {
        iteratees.push(model => model.getSortValue(this.baseSort.field));
        orders.push(this.baseSort.direction || 'asc');
      }

      iteratees.push(model => model.getSortValue(sortField));
      orders.push(sortDir);

      if (this.secondarySort) {
        iteratees.push(model => model.getSortValue(this.secondarySort.field));
        orders.push(this.secondarySort.direction || 'asc');
      }

      this.models = orderBy(this.models, iteratees, orders);
    }
  }

  @action
  serverSort(field) {
    let sortField = this.sortState.field;
    let sortDir = this.sortState.direction;

    // If a field was explicitly passed, use it
    if (field !== undefined) {
      sortField = field;
      sortDir = this.defaultSortState.direction || 'asc';

      // If the field passed was the same as current, toggle the direction (or turn off)
      if (field === this.sortState.field) {
        sortDir = this.sortState.direction === 'asc' ? 'desc' : 'asc';
      }
    }

    if (sortField) {
      if (this.sortState.field !== sortField || this.sortState.direction !== sortDir) {
        this.sortState = {
          field: sortField,
          direction: sortDir
        };
      }

      if (this.serverSortFn) {
        this.serverSortFn();
      }
    }
  }

  /**
   * Sets the sort state without forcing a sort. Useful to do prior to fetching.
   */
  @action
  setSortState(field, direction = 'asc') {
    this.sortState = { field, direction };
  }

  @action
  clearSort() {
    this.sortState = {};
    this.sort();
  }

  @action
  filter(query, options = {}) {
    const { immutable = false } = options;

    if (immutable) {
      return this.get().filter(query);
    }

    if (query !== undefined) {
      this.filterState = query;
    }
    this.original = this.get();

    if (this.filterState) {
      if (isString(this.filterState)) {
        const regex = new RegExp(this.filterState, 'i');

        this.models = this.original.filter(model => this.getFilterValues(model).some(value => regex.test(value)));
      } else {
        this.models = this.original.filter(this.filterState);
      }
    } else {
      this.models = this.get();
    }

    if (this.activePresetFilter) {
      this.models = this.models.filter(this.activePresetFilter.fn);
    }

    this.sort();

    return this.models;
  }

  @action
  setPresetFilter(filter) {
    this.activePresetFilter = filter;
    this.filter();
  }

  @action
  clearFilters() {
    this.filterState = '';
    this.activePresetFilter = null;
    this.models = this.get();
  }

  @computed
  get hasFilter() {
    return !!(this.filterState || this.activePresetFilter);
  }

  /**
   * Returns an array of values that filters can match against.
   * This can support matching against model properties and any other observables and computed properties.
   * To support observables and computed properties, define the property name in the collection's "filterFieldWhitelist" set.
   */
  getFilterValues(model) {
    const modelKeys = model.attributes.keys();
    const whiteListKeys = this.filterFieldWhitelist && Array.from(this.filterFieldWhitelist);
    const keys = union(modelKeys, whiteListKeys);

    return keys
      .filter(name => !this.filterFieldBlacklist || !this.filterFieldBlacklist.has(name))
      .filter(name => !this.filterFieldWhitelist || this.filterFieldWhitelist.has(name))
      .map(name => model.get(name) || model[name])
      .filter(value => value !== undefined);
  }

  get filterFieldBlacklist() {
    return null;
  }

  get filterFieldWhitelist() {
    return null;
  }

  @computed
  get size() {
    return this.models.length;
  }

  @computed
  get unfilteredSize() {
    return this.get().length;
  }

  @computed
  get unfiltered() {
    return this.get();
  }

  @computed
  get status() {
    return this.requestStatus || 'none';
  }

  @computed
  get isAllSelected() {
    return this.selected && this.selected.length === this.models.length;
  }

  generateSelectOptions = ({ valueKey = 'id', labelKey, sortBy }) => {
    const options = this.models.map(model => ({
      value: model.get(valueKey),
      label: labelKey ? model.get(labelKey) : model.get(valueKey),
      ...model.get()
    }));

    if (sortBy) {
      return orderBy(options, sortBy);
    }

    return options;
  };

  @action
  selectAll = () => {
    this.selected = this.isAllSelected ? null : this.models.slice();
  };

  @action
  select = (model, options = {}) => {
    const { multi = false, shiftKey } = options;
    const m = model && this.get(model.id);

    if (!m) {
      console.warn(`Model (id: ${model && model.id}) not found in ${this.constructor.name}`);
    }

    if (multi) {
      if (!this.selected) {
        this.selected = [];
      }

      if (model.isSelected) {
        this.selected.remove(model);

        if (this.selected.length === 0) {
          this.selected = null;
        }

        // adding to a multi selection
      } else {
        // select all the models in between
        if (shiftKey) {
          const firstIndex = this.selected[0].index;

          if (firstIndex > model.index) {
            this.selected = this.models.slice(model.index, firstIndex + 1);
          } else {
            this.selected = this.models.slice(firstIndex, model.index);
          }

          // Browsers (on some platforms) will try to select text in between the 2 cursor points. Revert that.
          document.getSelection().removeAllRanges();
        }

        this.selected.push(model);
      }
    } else {
      this.selected = model;
    }
  };

  @action
  clearSelection = () => {
    this.selected = null;
  };

  @action
  setLastUpdated = () => {
    this.lastUpdated = Date.now();
  };

  @action
  setRequestStatus = status => {
    this.requestStatus = status;
  };

  @action
  reset(options = {}) {
    this.models.clear();
    this.original = null;
    this.hasFetched = false;
    this.setLastUpdated();

    if (options.hard) {
      this.resetState();
    }
  }

  @action
  resetState() {
    this.filterState = '';
    this.activePresetFilter = this.defaultPresetFilter;
    this.sortState = this.defaultSortState;
    this.groupBy = this.defaultGroupBy;
    this.filter();
  }

  @action
  resetSortState() {
    this.sortState = this.defaultSortState;
    this.sort();
  }

  /**
   * Adds one or more models to the collection. Accepts an array of models/raw objects or a simple model/object.
   * Returns the added models.
   */
  @action
  add = data => {
    if (!isArray(data)) {
      data = [data];
    }

    const models = data.map(row => (row instanceof BaseModel ? row : this.build(row)));
    this.original = this.get().concat(models);
    this.filter();
    this.setLastUpdated();

    return models;
  };

  @action
  remove = (ids, destroyModels = false) => {
    if (ids.forEach) {
      ids.forEach(id => this.remove(id, destroyModels));
    } else {
      const model = this.get(ids);
      if (model) {
        // If models and original are not the same (i.e. there was sorting/filtering), we need to touch both
        if (this.original && this.original !== this.models) {
          const idx = this.original.indexOf(model);
          if (idx >= 0) {
            this.original.splice(idx, 1);
          }
        }

        const idx = this.models.indexOf(model);
        if (idx >= 0) {
          this.models.splice(idx, 1);
        }

        if (destroyModels) {
          model.destroy({ confirm: false });
        }
        if (this.selected && this.selected.id === model.id) {
          this.clearSelection();
        }
      }
    }
    this.setLastUpdated();
  };

  /**
   * Sets the model(s) in the collection. Accepts an array of models/raw objects or a simple model/object.
   */
  @action
  set = models => {
    this.original = [];
    this.add(models);
    this.setLastUpdated();
  };

  @action
  processData(response) {
    this.original = [];

    const data = this.deserialize(response);

    if (this.useAsyncAdd) {
      let models = [];
      return processLargeArrayAsync(data, row => {
        models = models.concat(this.build(row));
      }).then(
        action(() => {
          this.original = this.get().concat(models);
          this.filter();
          this.setLastUpdated();
          this.resetFetchState();
          return response;
        })
      );
    }

    this.set(data);
    this.resetFetchState();
    return Promise.resolve(response);
  }

  @action
  resetFetchState() {
    this.requestStatus = null;
    this.fetchPromise = null;
  }

  /**
   * Creates a model object of the type the Collection instance uses with the provided data
   * optionally selecting it (this is the default behavior). The resultant model is not added
   * to the collection!
   */
  @action
  forge = (attributes, options = {}) => {
    const { select = true } = options;
    return (attributes && attributes.id && this.get(attributes.id)) || this.build(attributes, { ...options, select });
  };

  /**
   * Fetches collection data from the backend and overwrites the models, applying filter/sorts/groups.
   */
  @action
  async fetch(options = {}) {
    const { data, fetchUrl, fetchMethod, preserveSelection, query } = options;

    const label = 'fetching';

    // Never fetch if already fetching unless parameters are diff
    if (this.requestStatus === label && this.fetchPromise && !options.query) {
      return this.fetchPromise;
    }

    this.requestStatus = label;
    this.hasFetched = true;

    if (!preserveSelection) {
      this.clearSelection();
    }

    const httpMethod = fetchMethod || this.fetchMethod;
    const url = fetchUrl || (this.urlPaths && this.urlPaths.fetch ? this.urlPaths.fetch : this.url);

    this.fetchPromise = api[httpMethod](url, { data, query }).then(this.processData, error => {
      this.error = { label, body: error };
      this.models.clear();
      this.resetFetchState();
      throw error;
    });

    return this.fetchPromise;
  }

  /**
   * This is very similar to fetch but allows for multiple fetch requests to go out at same time, but ONLY
   * last one will actually update the collection with results (paging, server-based collection filtering, etc.)
   */
  @action
  async queuedFetch(options = {}) {
    const { data, fetchUrl, fetchMethod, preserveSelection, query } = options;

    const label = 'fetching';

    this.requestStatus = label;
    this.hasFetched = true;

    if (!preserveSelection) {
      this.clearSelection();
    }

    const key = this.queuedFetchKey;
    if (!key) {
      return Promise.reject(new Error('Collection must provide queuedFetchKey'));
    }
    const qfHttpMethod = fetchMethod || this.fetchMethod;
    const qfUrl = fetchUrl || (this.urlPaths && this.urlPaths.fetch ? this.urlPaths.fetch : this.url);

    return collatePromises({ key, promise: api[qfHttpMethod](qfUrl, { data, query }) }).then(
      results => this.processData(results),
      error => {
        this.error = { label, body: error };
        this.models.clear();
        this.resetFetchState();
        throw error;
      }
    );
  }

  deserialize(data) {
    return this.jsonRoot ? data[this.jsonRoot] : data;
  }
}

export default Collection;
