import BaseModel from 'models/BaseModel';

export default class CompanySubtenancy extends BaseModel {
  get defaults() {
    return {
      config: {}
    };
  }

  get urlRoot() {
    return '/api/portal/companySettings/companySubtenancy';
  }

  deserialize(data) {
    data.id = data.company_id;
    return super.deserialize(data);
  }

  serialize(data) {
    return super.serialize({ ...data, id: undefined });
  }
}
