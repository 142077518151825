import React, { Component } from 'react';
import { Intent } from '@blueprintjs/core';
import copy from 'copy-to-clipboard';

export default class CopyToClipboard extends Component {
  state = {
    copied: false
  };

  onClick = e => {
    const { text, onCopyComplete, children, options } = this.props;
    const elem = React.Children.only(children);

    copy(text, options);

    // change button text on success, then change back
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });

      if (onCopyComplete) {
        onCopyComplete();
      }
    }, 1000);

    // Bypass onClick if it was present
    if (elem && elem.props && typeof elem.props.onClick === 'function') {
      elem.props.onClick(e);
    }
  };

  render() {
    const { children, onCopyComplete, ...props } = this.props;
    const { copied } = this.state;
    const elem = React.Children.only(children);

    return React.cloneElement(elem, {
      ...props,
      onClick: this.onClick,
      iconName: copied ? 'tick' : elem.props.iconName,
      intent: Intent.PRIMARY,
      text: copied ? 'Copied!' : elem.props.text,
      disabled: copied
    });
  }
}
