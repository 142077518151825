import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ToggleButtonGroup from 'components/ToggleButtonGroup';
import { Button } from '@blueprintjs/core';

@observer
export default class Select extends Component {
  handleOnClick = value => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const { field, buttonProps } = this.props;

    const buttons = field.options.map(option => (
      <Button
        key={option.value}
        value={option.value}
        text={option.label}
        iconName={option.iconName}
        onClick={() => this.handleOnClick(option.value)}
        {...buttonProps}
      />
    ));

    return <ToggleButtonGroup selectedValue={field.value}>{buttons}</ToggleButtonGroup>;
  }
}
