import React from 'react';
import { action, extendObservable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Flex, Box } from 'components/flexbox';
import { deepClone, mergeFilterGroups } from 'util/utils';
import { getModelFilters } from 'services/filters';
import colors from 'util/colors';
import DataViewWrapper from 'dataviews/DataViewWrapper';
import DataViewModel from 'models/DataViewModel';
import BaseDataview from './BaseDataview';

@observer
export default class GeneratorView extends BaseDataview {
  dataviewModels = {};

  allowScroll = true;

  dataviewDisposers = [];

  componentDidUpdate() {}

  componentWillReceiveProps() {
    const { activeBuckets, selectedQuery } = this.props.dataview.queryBuckets;
    if (activeBuckets[0].queryResults.size) {
      this.dataviewDisposers = [];
      this.dismissSpinner(0, false);
      const bracketOptions = selectedQuery.get('bracketOptions');
      const rows = activeBuckets[0].queryResults.nonOverlayRows.slice(0, selectedQuery.get('topx'));
      rows.forEach(row => {
        const lookup = row.get('lookup');
        const key = row.get('key');
        let tagData;
        if (bracketOptions) {
          tagData = row.get(bracketOptions.tagKey);
        }
        if (!Object.keys(this.dataviewModels).find(existingKey => existingKey === key)) {
          this.dataviewModels[key] = new DataViewModel();
          const subDataview = this.dataviewModels[key];
          this.dataviewDisposers.push(
            reaction(
              () => subDataview.fullyLoaded,
              () => {
                if (selectedQuery.get('sync_all_axes')) {
                  this.syncAllAxes(true);
                }
                if (Object.values(this.dataviewModels).every(model => model.fullyLoaded)) {
                  this.props.dataview.setFullyLoaded();
                  this.dataviewDisposers.forEach(disposer => disposer());
                  this.dataviewDisposers = [];
                }
              }
            )
          );
          extendObservable(this.dataviewModels[key], {
            lookup,
            key,
            bracketColor: (tagData && tagData.value) || null
          });

          const seriesName = (lookup || key)
            .split('----')
            .slice(0, selectedQuery.get('metric').length)
            .join(' \u2192 ');

          const filters = deepClone(selectedQuery.get('filters'));
          const filterDimensionsEnabled = selectedQuery.get('filterDimensionsEnabled');
          const filterDimensions = selectedQuery.get('filterDimensions');
          const filterDimensionOther = selectedQuery.get('filterDimensionOther');

          this.dataviewModels[key].setQuery(
            Object.assign({}, selectedQuery.serialize(), {
              filters_obj: filterDimensionsEnabled
                ? mergeFilterGroups(filters, {
                    filterGroups: [
                      key === 'Other' && filterDimensionOther
                        ? {
                            name: 'Other',
                            connector: 'Any',
                            filterGroups: filterDimensions.filterGroups,
                            not: true
                          }
                        : filterDimensions.filterGroups.find(group => group.name === key)
                    ]
                  })
                : getModelFilters({
                    model: row,
                    bucket: activeBuckets[0],
                    filtersAcc: filters
                  }),
              filterDimensionsEnabled: false,
              generatorMode: false,
              metric: selectedQuery.get('generatorDimensions'),
              query_title: selectedQuery.get('generatorQueryTitle').replace(/{{generator_series_name}}/g, seriesName),
              topx: selectedQuery.get('generatorTopx')
            }),
            true
          );
          this.dataviewModels[key].queryBuckets.activeBuckets[0].queryResults.dataview = this.dataviewModels[key];
        }
      });
      Object.keys(this.dataviewModels).forEach(existingKey => {
        if (!rows.some(row => row.get('key') === existingKey)) {
          delete this.dataviewModels[existingKey];
        }
      });
    }
  }

  clear() {
    this.dataviewModels = {};
  }

  reflow() {
    Object.values(this.dataviewModels).forEach(model => {
      if (model.component && model.component.reflow) {
        model.component.reflow();
      }
    });
  }

  syncAxes(sync) {
    Object.values(this.dataviewModels).forEach(model => model.component.syncAxes(sync));
  }

  useLogAxis(use) {
    Object.values(this.dataviewModels).forEach(model => model.component.useLogAxis(use));
  }

  useSecondaryLogAxis(use) {
    Object.values(this.dataviewModels).forEach(model => model.component.useSecondaryLogAxis(use));
  }

  syncAllAxes(sync) {
    let min = null;
    let max = null;
    if (sync) {
      Object.values(this.dataviewModels).forEach(model => {
        const extremes = model.component.getExtremes();
        if (extremes) {
          min = Math.min(min, extremes.min);
          max = Math.max(max, extremes.max);
        }
      });
    }
    Object.values(this.dataviewModels).forEach(model => model.component.setExtremes(min, max));
  }

  syncExtents(sync_extents) {
    Object.values(this.dataviewModels).forEach(model => {
      model.component.syncExtents();
      model.queryBuckets.selectedQuery.set({ sync_extents });
      model.saveAndReinitialize(true);
    });
  }

  @action
  setSelectedModels(selectedModels) {
    if (Array.isArray(selectedModels) && typeof selectedModels[0] === 'string') {
      selectedModels = selectedModels.reduce((arr, selectedModelKey) => {
        let selectedModel;
        Object.values(this.dataviewModels).forEach(dataviewModel => {
          const { queryResults } = dataviewModel.queryBuckets.activeBuckets[0];
          selectedModel = queryResults.find({ key: selectedModelKey });
        });
        arr.push(selectedModel);
        return arr;
      }, []);
    }
    Object.values(this.dataviewModels).forEach(model => {
      model.bracketColor = null;

      model.setSelectedModels(selectedModels);
    });
    selectedModels.forEach(action(model => (model.collection.dataview.bracketColor = colors.ORANGE3)));
  }

  getComponent() {
    const { dataview, size, ...restOfProps } = this.props;
    const { selectedQuery } = dataview.queryBuckets;
    const subpanelCount = Object.keys(this.dataviewModels).length;
    const generatorColumns = Math.min(subpanelCount, selectedQuery.get('generatorColumns'));
    const subpanelRows = Math.ceil(subpanelCount / generatorColumns);
    const panelWrapperStyle = {
      width: `${100 / generatorColumns}%`,
      minHeight: subpanelRows === 1 ? null : selectedQuery.get('generatorPanelMinHeight'),
      padding: 5
    };

    return (
      <Flex flexAuto wrap style={{ padding: 5 }} className="generator-wrap">
        {Object.values(this.dataviewModels).map(model => (
          <Box flexAuto={subpanelRows === 1} style={panelWrapperStyle} key={model.key}>
            <DataViewWrapper
              {...restOfProps}
              dataview={model}
              viewProps={{
                showNativeLegend: !!model.queryBuckets.selectedQuery.get('metric').length
              }}
              headerProps={{
                isGenerated: true,
                showTitleLink: true,
                showViewTypeTag: false,
                shouldArrangeVertically: true,
                suppressDataviewLoaded: true
              }}
              footerProps={{
                showLastUpdated: false,
                showLiveUpdate: false,
                showSiteMarkers: false
              }}
            >
              {({ component, header }) => (
                <Flex
                  className="pt-card flat"
                  flexAuto
                  flexColumn
                  style={{ outline: model.bracketColor ? `4px auto ${model.bracketColor}` : null, height: '100%' }}
                >
                  <Box p={2} className="overflow-hidden">
                    {header}
                  </Box>
                  <Flex flexAuto>{component}</Flex>
                </Flex>
              )}
            </DataViewWrapper>
          </Box>
        ))}
      </Flex>
    );
  }
}

const config = {
  showLegend: true,
  enableToggle: false,
  buckets: [
    {
      name: 'Generator'
    }
  ]
};

export { config };
