import { toJS } from 'mobx';
import api from 'util/api';

export function normalizeQueries(queries) {
  return Array.isArray(queries) ? queries : [queries];
}

export function getQueriesForHash(hash, options = {}) {
  const { normalize = true } = options;
  return api
    .get(`/api/portal/urlHash?key=${hash}`)
    .then(response => (normalize ? normalizeQueries(response) : response));
}

export function getHashForQueries(queries, persist = false) {
  return api.post(`/api/portal/urlHash${persist ? '/persist' : ''}`, {
    data: { queries: toJS(queries) }
  });
}

export function persistHash(hash) {
  return api.post('/api/portal/urlHash/persistKey', {
    data: { key: hash }
  });
}

/**
 * Gets an unwrapped, serialized query object for a hash.
 * This assumes the hash will only ever have one bucket, with one query,
 * which means no legacy queries can use this.
 */
export function getQueryForHash(hash) {
  return getQueriesForHash(hash).then(buckets => buckets[0].query);
}

/**
 * Gets a hash for a single, serialized query with the proper bucket wrapping
 * that Explorer will expect (since it has to deal with legacy queries).
 */
export function getHashForQuery(query, persist = false) {
  const queries = [
    {
      bucket: query.bucket,
      isOverlay: false,
      query
    }
  ];

  return getHashForQueries(queries, persist);
}

window.getHashForQueries = getHashForQueries;
