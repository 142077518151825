import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Form, Field, Input } from 'components/forms';
import { Button } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/labs';
import { Flex, Box } from 'components/flexbox';
import { ColorBlock, ColorPicker } from 'components';

export const defaultLabelColor = '#63707D';

const fields = {
  name: {
    rules: ['required', 'min:1', 'max:100']
  },
  color: {
    defaultValue: defaultLabelColor
  }
};

@Form({ fields })
@inject('$deviceGroups', '$devices')
@observer
class LabelItemAdd extends Component {
  state = {
    saving: false
  };

  componentDidMount() {
    this.labelInput.focus();
    this.labelInput.addEventListener('keypress', this.handleEnterSubmit);
  }

  handleEnterSubmit = e => {
    const { form } = this.props;
    const key = e.which || e.keyCode;
    if (key === 13 && form.valid) {
      this.handleSave();
    }
  };

  handleColorChange = ({ hex: color }) => {
    const { form } = this.props;
    form.setValue('color', color);
  };

  handleSave = async () => {
    const { form, $deviceGroups } = this.props;
    this.setState({ saving: true });

    const values = form.getValues();
    const newLabel = $deviceGroups.labelCollection.forge();
    try {
      await newLabel.save(values);
    } catch (error) {
      this.setState({ saving: false });
      this.labelInput.focus();
      return;
    }

    this.setState({ saving: false });
    form.init({});
    this.labelInput.focus();
  };

  inputRef = ref => {
    this.labelInput = ref;
  };

  render() {
    const { saving } = this.state;
    const { form } = this.props;
    const color = form.getValue('color');

    return (
      <Box mb={3}>
        <h6 className="pt-strong">Add Label</h6>
        <Flex align="flex-start" mb={1}>
          <Popover2 rootElement="div" placement="auto">
            <Button style={{ marginRight: 8 }}>
              <Flex align="center" justify="center">
                <ColorBlock color={color} />
              </Flex>
            </Button>
            <ColorPicker color={color} onChange={this.handleColorChange} />
          </Popover2>

          <Box flexAuto>
            <Field field={form.getField('name')} className="no-margin">
              <Input
                className="pt-fill"
                placeholder="Label name..."
                inputRef={this.inputRef}
                rightElement={
                  <Button
                    className="pt-fixed pt-strong pt-intent-primary"
                    text="Add"
                    loading={saving}
                    disabled={!form.valid || !form.dirty}
                    iconName="plus"
                    onClick={this.handleSave}
                  />
                }
              />
            </Field>
          </Box>
        </Flex>
      </Box>
    );
  }
}

export default LabelItemAdd;
