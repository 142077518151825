import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dialog, Switch, Tooltip, Icon } from '@blueprintjs/core';
import { Box, Flex } from 'components/flexbox';
import RemoveButton from 'components/forms/actions/RemoveButton';
import { VirtualizedTable, CELL_TYPES, NewButton } from 'components/table/index';
import { stopBubbling } from 'util/utils';

import NotificationDialogForm from './NotificationDialogForm';

@inject('$notifications')
@observer
export default class TenantNotificationsDialog extends Component {
  componentDidMount() {
    const { $notifications } = this.props;
    $notifications.collection.fetch();
  }

  columns = [
    {
      name: 'config.receiveTenantNotifications',
      label: 'Receiving Notifications',
      width: 150,
      renderer: ({ value: receiveTenantNotifications, model }) => {
        const className = receiveTenantNotifications ? 'pt-strong' : 'pt-text-muted';
        return (
          <Flex flexColumn onClick={stopBubbling} style={{ marginTop: 2 }} align="center" justify="center">
            <Switch
              label={
                <Flex flexColumn align="flex-start" style={{ marginLeft: -3 }}>
                  <div>
                    <small className={className}>{receiveTenantNotifications ? 'Enabled' : 'Disabled'}</small>
                  </div>
                </Flex>
              }
              onChange={() => this.handleTenantStatusChange(receiveTenantNotifications, model)}
              checked={receiveTenantNotifications}
            />
          </Flex>
        );
      }
    },

    {
      name: 'notification_name',
      label: 'Notification Channel',
      ellipsis: false,
      renderer: ({ value, model }) => (
        <Flex>
          <Flex flexColumn align="center" justify="center">
            <Icon style={{ marginRight: 8 }} iconName={model.icon} />
          </Flex>
          <div className="pt-text-overflow-ellipsis">{value}</div>
        </Flex>
      )
    },

    {
      type: CELL_TYPES.ACTION,
      actions: [
        model => (
          <Tooltip key="remove" content="Remove">
            <RemoveButton model={model} showIcon onRemove={() => this.handleRemoveNotificationChannel(model)} />
          </Tooltip>
        )
      ]
    }
  ];

  handleTenantStatusChange = (value, model) => {
    model.save({ config: { receiveTenantNotifications: !value } }).then(this.onSaveTenantNotifications());
  };

  handleNewNotificationChannel = () => {
    const { $notifications, userGroupId } = this.props;
    $notifications.collection.forge({ user_group_id: userGroupId });
  };

  handleRemoveNotificationChannel = model => model.destroy().then(this.onSaveTenantNotifications);

  onSaveTenantNotifications = () => {
    const { $notifications } = this.props;
    return $notifications.saveTenantNotifications();
  };

  onNotificationDialogSubmit = ({ model }, values) => model.save(values).then(this.onSaveTenantNotifications);

  render() {
    const { $notifications, isOpen, onClose } = this.props;
    return (
      <Dialog
        title="Tenant Notifications"
        isOpen={isOpen}
        onClose={onClose}
        style={{ width: 600, top: 40 }}
        transitionName="pt-dialog"
      >
        <Box className="pt-dialog-body">
          <Flex justify="flex-end" align="flex-end" mb={2}>
            <NewButton
              collection={$notifications.collection}
              entityName="Notification Channel"
              onClick={this.handleNewNotificationChannel}
            />
          </Flex>
          <Box className="pt-card flat">
            <VirtualizedTable
              rowHeight={44}
              columns={this.columns}
              collection={$notifications.collection}
              style={{ minHeight: 300 }}
              flexed
            />
          </Box>

          <NotificationDialogForm
            isOpen={$notifications.collection.selected}
            model={$notifications.collection.selected}
            collection={$notifications.collection}
            formActionsProps={{ onSubmit: this.onNotificationDialogSubmit }}
          />
        </Box>
      </Dialog>
    );
  }
}
