import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Tag } from '@blueprintjs/core';
import { startCase } from 'lodash';

import { Page, PageBody } from 'components/page';
import { Flex, Box } from 'components/flexbox';

import SearchInput from './SearchInput';
import Results from './Results';

@inject('$search')
@observer
class FullResults extends Component {
  state = {
    showType: {}
  };

  componentDidMount() {
    const { $search, match } = this.props;

    if (!$search.results) {
      this.executeSearch(this.getMatchString(match));
    }
  }

  componentDidUpdate = () => {
    const { $search, history, location, match } = this.props;
    const matchString = this.getMatchString(match);
    const newPath = location.pathname.split('/');

    if (matchString !== $search.queryString) {
      newPath[newPath.length - 1] = $search.queryString;
      history.replace(newPath.join('/'));
    }
  };

  getMatchString = match => (match && match.params ? match.params.input : '');

  executeSearch = queryString => {
    const { $search } = this.props;

    if (queryString !== '') {
      $search.query(queryString, 0);
    }
  };

  onSwitchChange = event => {
    const { name, checked } = event.currentTarget;

    this.setState(prevState => ({
      showType: {
        ...prevState.showType,
        [name]: checked
      }
    }));
  };

  getResultTypes = results => {
    const types = [];

    if (results) {
      Object.keys(results).forEach(key => {
        if (Array.isArray(results[key].slice()) && results[key].length > 0) {
          types.push(key);
        }
      });
    }

    return types.sort();
  };

  filterResults = results => {
    const { showType } = this.state;
    const allOff = Object.keys(showType).every(key => !showType[key]);
    const filteredResults = {
      ...(allOff ? results : {})
    };

    if (!allOff) {
      Object.keys(showType).forEach(type => {
        if (showType[type]) {
          filteredResults[type] = results[type];
        }
      });
    }

    return filteredResults;
  };

  render() {
    const { $search } = this.props;
    const { showType } = this.state;
    const types = this.getResultTypes($search.results);
    const pageTitle = `${$search.totalResults} results found for`;

    return (
      <Page title={`${pageTitle} "${$search.queryString}"`} className="search-full">
        <PageBody>
          <Flex flexColumn className="search-full-sidebar" p={2}>
            <h6>Limit Results To</h6>

            {types.map(type => (
              <Flex key={type} align="center" justify="space-between" mt={1.5}>
                <Switch
                  name={type}
                  label={startCase(type)}
                  onChange={this.onSwitchChange}
                  checked={showType[type] === true}
                />
                <Tag className="pt-round pt-minimal pt-normal">{$search.results[type].length}</Tag>
              </Flex>
            ))}
          </Flex>

          <Flex flexColumn flexAuto className="search-full-content">
            <Flex align="center" p={2}>
              <Box className="pt-ui-text-large pt-strong" mr={1}>
                {pageTitle}
              </Box>
              <SearchInput
                inputName=""
                input={$search.queryString}
                key={$search.queryString}
                autoFocus
                flexAuto
                mx={0}
              />
            </Flex>

            <Results results={this.filterResults($search.results)} maxResults={0} showFullButton={false} />
          </Flex>
        </PageBody>
      </Page>
    );
  }
}

export default FullResults;
