import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import TabPanel from 'components/TabPanel';
import CompoundIcon from 'components/CompoundIcon';

@observer
class MultistepTabs extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    renderActiveTabPanelOnly: true,
    show: true,
    scrollAreaStyle: { height: 'calc(100vh - 390px)', overflow: 'auto' }
  };

  renderTabPanel(tab) {
    const { scrollAreaStyle } = this.props;

    return (
      <Box tabTitle={tab.id}>
        <div className="tab-content-scroll-area" style={scrollAreaStyle}>
          <Flex p={2} style={{ minHeight: '100%' }}>
            {tab.panel}
          </Flex>
        </div>
      </Box>
    );
  }

  renderTabStateIcon(form, tab) {
    const errorState = Object.keys(form.invalidGroups).includes(tab.group);
    const incompleteState = Object.keys(form.incompleteGroups).includes(tab.group);
    const completeState = form.completeGroups.includes(tab.group);

    const iconClass = classNames({
      'pt-intent-danger': errorState,
      'pt-intent-warning': incompleteState,
      'pt-intent-primary': completeState
    });

    return (
      <CompoundIcon
        iconName={tab.iconName}
        secondaryIconName={errorState || incompleteState ? 'symbol-circle' : null}
        secondaryIconClassName={iconClass}
        style={{ marginRight: 6 }}
      />
    );
  }

  render() {
    const { form } = this.context;
    const { tabs, className, flexAuto, tools, renderActiveTabPanelOnly, show } = this.props;
    const activeTabId = this.props.activeTabId || (tabs[0] && tabs[0].id);

    return (
      <TabPanel
        animate={false}
        className={classNames('multistep-tabs', { hidden: !show }, className)}
        flexAuto={flexAuto}
        horizontal
        key="horizontal"
        renderActiveTabPanelOnly={renderActiveTabPanelOnly}
        activeTabId={activeTabId}
        tools={tools}
      >
        {tabs.map((tab, idx) => (
          <Box
            tabId={tab.id}
            key={tab.id}
            tab={
              <Flex align="center">
                {this.renderTabStateIcon(form, tab)}
                {tab.title}
              </Flex>
            }
          >
            {this.renderTabPanel(tab, idx)}
          </Box>
        ))}
      </TabPanel>
    );
  }
}

export default MultistepTabs;
