import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Collapse } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { Icon } from 'components';
import { Fade } from 'components/animations';

const iconStyle = {
  marginRight: 7 // same margin as `pt-menu-item` icons,
};

@observer
class SidebarGroup extends Component {
  state = {
    isOpen: false
  };

  static defaultProps = {
    collapsible: true,
    isOpen: false,
    showBadge: true,
    showHeader: true
  };

  componentWillMount() {
    // Default to closed unless isOpen is provided as a prop (as true)
    const isOpen = this.props.isOpen || window.localStorage.getItem(this.getLocalStorageKey()) === 'true';
    this.setState({ isOpen });
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props;

    if (nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (nextProps.collection && nextProps.collection.hasFilter && !isOpen) {
      this.setState({ isOpen: true });
    }
  }

  getLocalStorageKey() {
    const { reportType, title } = this.props;

    if (reportType) {
      return `library-${reportType}-${title}-isOpen`;
    }

    return `library-${title}-isOpen`;
  }

  toggleIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen }, () => {
      window.localStorage.setItem(this.getLocalStorageKey(), this.state.isOpen);
    });
  };

  render() {
    const { title, collapsible, className, children, badge, iconName, showBadge, showHeader } = this.props;
    const { isOpen } = this.state;

    const iconClassName = classNames('pt-text-muted', {
      'pt-intent-warning': iconName === 'star',
      'pt-intent-primary': iconName === 'time'
    });

    return (
      <Box className={classNames('pt-menu', className)} mb={0}>
        {showHeader && (
          <Flex
            className="pt-menu-item"
            justify="space-between"
            align="center"
            onClick={collapsible ? this.toggleIsOpen : undefined}
            style={{ borderRadius: 0 }}
            tabIndex={0}
            keyboardOnClick
          >
            <Flex align="center pt-text-overflow-ellipsis" pr={0.5}>
              {collapsible && (
                <Icon
                  name={isOpen ? 'caret-down' : 'caret-right'}
                  className="pt-text-muted"
                  style={{ marginRight: 6 }}
                />
              )}
              {iconName && <Icon name={iconName} className={iconClassName} style={iconStyle} />}
              <div className="pt-text-overflow-ellipsis">
                <strong>{title}</strong>
              </div>
            </Flex>
            {showBadge && badge}
          </Flex>
        )}

        <Collapse isOpen={isOpen}>
          <Fade in={isOpen} appear timeout={0}>
            {({ styles }) => (
              <div style={collapsible ? { marginLeft: 23, marginRight: 0, ...styles } : styles}>{children}</div>
            )}
          </Fade>
        </Collapse>
      </Box>
    );
  }
}

export default SidebarGroup;
