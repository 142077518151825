export const hexToRgbA = (hex, alpha = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`;
  }
  // if bad hex, just toss back a neutral gray for now.
  return 'rgb(92,112,128, 1)';
};

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return null;
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
}

export function findClosestMatch(hexColor, colors) {
  if (colors.includes(hexColor)) {
    return hexColor;
  }

  const rgbColor = hexToRgb(hexColor);
  let closestMatch = null;
  let minDistance;

  colors.forEach(currHexColor => {
    const currRgbColor = hexToRgb(currHexColor);
    const currDistance =
      Math.abs(currRgbColor.r - rgbColor.r) +
      Math.abs(currRgbColor.g - rgbColor.g) +
      Math.abs(currRgbColor.b - rgbColor.b);

    if (minDistance === undefined || currDistance < minDistance) {
      minDistance = currDistance;
      closestMatch = currHexColor;
    }
  });

  return closestMatch;
}

export const colors = {
  BLACK: '#10161A',

  BLUE1: '#0E5A8A',
  BLUE2: '#106BA3',
  BLUE3: '#5687F7',
  BLUE4: '#2B95D6',
  BLUE5: '#48AFF0',

  COBALT1: '#1F4B99',
  COBALT2: '#2458B3',
  COBALT3: '#2965CC',
  COBALT4: '#4580E6',
  COBALT5: '#669EFF',

  DARK_GRAY1: '#182026',
  DARK_GRAY2: '#202B33',
  DARK_GRAY3: '#293742',
  DARK_GRAY4: '#30404D',
  DARK_GRAY5: '#394B59',

  GREEN1: '#0A6640',
  GREEN2: '#0D8050',
  GREEN3: '#0f9960',
  GREEN4: '#15B371',
  GREEN5: '#3DCC91',

  ORANGE1: '#A66321',
  ORANGE2: '#BF7326',
  ORANGE3: '#ff850e',
  ORANGE4: '#F29D49',
  ORANGE5: '#FFB366',

  RED1: '#A82A2A',
  RED2: '#C23030',
  RED3: '#DB3737',
  RED4: '#F55656',
  RED5: '#FF7373',

  ROSE1: '#A82255',
  ROSE2: '#C22762',
  ROSE3: '#DB2C6F',
  ROSE4: '#F5498B',
  ROSE5: '#FF66A1',

  SEPIA1: '#63411E',
  SEPIA2: '#7D5125',
  SEPIA3: '#96622D',
  SEPIA4: '#B07B46',
  SEPIA5: '#C99765',

  TURQUOISE1: '#008075',
  TURQUOISE2: '#00998C',
  TURQUOISE3: '#00B3A4',
  TURQUOISE4: '#14CCBD',
  TURQUOISE5: '#2EE6D6',

  VERMILION1: '#9E2B0E',
  VERMILION2: '#B83211',
  VERMILION3: '#D13913',
  VERMILION4: '#EB532D',
  VERMILION5: '#FF6E4A',

  VIOLET1: '#5C255C',
  VIOLET2: '#752F75',
  VIOLET3: '#8F398F',
  VIOLET4: '#A854A8',
  VIOLET5: '#C274C2',

  WHITE: '#FFFFFF'
};

export default colors;
