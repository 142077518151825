import React, { Fragment } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

const AlarmStartEndCell = props => {
  const { model } = props;
  const alarm_start = moment.utc(model.get('alarm_start'));
  const alarm_end = moment.utc(model.get('alarm_end'));
  const autoStopTime = model.get('auto_stop_time') && moment.utc(model.get('auto_stop_time'));

  let endMarkup = <span className="pt-text-muted">Currently Active</span>;
  if (alarm_end.isValid() && alarm_end.valueOf() > 0) {
    endMarkup = alarm_end.format(DEFAULT_DATETIME_FORMAT);
  }

  let mitTTLMarkup = null;
  if (autoStopTime && autoStopTime.isValid() && autoStopTime.valueOf() > 0) {
    mitTTLMarkup = autoStopTime.format(DEFAULT_DATETIME_FORMAT);
  }

  return (
    <Fragment>
      <div className="pt-text-overflow-ellipsis">{alarm_start.format(DEFAULT_DATETIME_FORMAT)}</div>
      <div className="pt-text-overflow-ellipsis">{endMarkup}</div>
      {mitTTLMarkup && <div className="pt-text-overflow-ellipsis">Auto Stop Time: {mitTTLMarkup}</div>}
    </Fragment>
  );
};

export default observer(AlarmStartEndCell);
