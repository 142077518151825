import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { uniqueId } from 'lodash';
import { Box, Flex } from 'components/flexbox';
import { generatorMap } from 'util/queryResultTagging/tagEngine';
import { getOrdinalSuffix } from 'util/utils';

@inject('$explorer', '$dictionary')
@observer
export default class PercentileRangesDisplay extends Component {
  static contextTypes = {
    form: any
  };

  render() {
    const { form } = this.context;
    const fieldValue = form.getValue('bracketOptions');
    const bracketType = fieldValue ? fieldValue.type : 'N/A';
    const isPercentile = bracketType === 'percentiles';
    const displayName = generatorMap[bracketType] ? generatorMap[bracketType].display : 'N/A';

    const ranges = fieldValue.ranges.map((range, index, array) => {
      const rangeValue = range.to;
      const previousRangeValue = index !== 0 ? array[index - 1].to : 0;

      return (
        <div
          key={uniqueId()}
          style={{ minWidth: '30px', flex: `1 ${rangeValue - previousRangeValue}%`, borderRight: '1px solid' }}
        >
          <div className="pt-text-overflow-ellipsis" style={{ textAlign: 'right', paddingRight: '2px' }}>
            {rangeValue}
            {isPercentile ? getOrdinalSuffix(+rangeValue) : '%'}
          </div>
          <div style={{ height: '20px', backgroundColor: range.data.value }} />
        </div>
      );
    });

    ranges.push(
      <div
        key={uniqueId()}
        style={{
          minWidth: '30px',
          flex: `1 ${100 - fieldValue.ranges[fieldValue.ranges.length - 1].to}%`
        }}
      >
        <div className="pt-text-overflow-ellipsis" style={{ textAlign: 'right', paddingRight: '2px' }}>
          &nbsp;
        </div>
        <div style={{ height: '20px', backgroundColor: fieldValue.over }} />
      </div>
    );

    return (
      <Flex flexColumn>
        <Box flexAuto mb={1}>
          <span style={{ fontWeight: 700 }}>{displayName}:</span>
        </Box>
        <Flex>{ranges}</Flex>
      </Flex>
    );
  }
}
