import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

@inject('$auth', '$devices', '$library', '$app')
@observer
class DefaultView extends Component {
  render() {
    const { $auth, $library } = this.props;
    let { defaultLandingPageType = 'library', defaultLandingPageValue } = $auth.activeUser;
    const { userGroup } = $auth.activeUser;

    if (userGroup) {
      if (!defaultLandingPageValue) {
        const defaultReport =
          userGroup.config.assets.defaultReport ||
          (userGroup.template && userGroup.template.length && userGroup.template[0].config.assets.defaultReport);

        if (defaultReport) {
          defaultLandingPageType = defaultReport.type;
          defaultLandingPageValue = defaultReport.id;
        } else {
          const firstReport = $library.reportsCollection.at(0);
          defaultLandingPageType = firstReport.get('type');
          defaultLandingPageValue = firstReport.id;
        }
      }
    }

    const redirects = {
      library: () => '/library',
      alerting: () => '/alerting',
      explorer: () => '/explorer',
      deviceAdmin: () => '/admin/devices/new',
      dashboard: value => `/library/dashboard/${value}`,
      'saved-view': value => `/library/savedView/${value}`,
      savedView: value => `/library/savedView/${value}`,
      report: value => `/library/report/${value}`
    };

    let redirectUrl = 'library';
    if (defaultLandingPageType && redirects[defaultLandingPageType]) {
      redirectUrl = redirects[defaultLandingPageType](defaultLandingPageValue);
    }

    return <Redirect to={redirectUrl} />;
  }
}

export default withRouter(DefaultView);
