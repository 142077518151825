import { action, computed, observable } from 'mobx';

import StackedAreaView, { config as stackedAreaConfig } from 'dataviews/views/StackedArea';
import StackedAreaHundredPercentView, {
  config as stackedAreaHundredPercentConfig
} from 'dataviews/views/StackedAreaHundredPercent';
import StackedColumnView, { config as stackedColumnConfig } from 'dataviews/views/StackedColumn';
import LineView, { config as lineConfig } from 'dataviews/views/Line';
import HorizonView, { config as horizonConfig } from 'dataviews/views/Horizon';
import BarView, { config as barConfig } from 'dataviews/views/Bar';
import PieView, { config as pieConfig } from 'dataviews/views/Pie';
import SunburstView, { config as sunburstConfig } from 'dataviews/views/Sunburst';
import SankeyView, { config as sankeyConfig } from 'dataviews/views/Sankey';
import TableView, { config as tableConfig } from 'dataviews/views/legend/Legend';
import MatrixView, { config as matrixConfig } from 'dataviews/views/Matrix';
import GaugeView, { config as gaugeConfig } from 'dataviews/views/Gauge';
import GeoHeatMapView, { config as geoHeatMapConfig } from 'dataviews/views/GeoHeatMap';
import AlertScoreboard, { config as alertScoreboardConfig } from 'dataviews/views/AlertScoreboard/AlertScoreboard';
import RawFlow, { config as rawFlowConfig } from 'dataviews/views/RawFlow/RawFlow';
import GeneratorView, { config as generatorConfig } from 'dataviews/views/GeneratorView';

const categories = ['time', 'comparison', 'hierarchical', 'heatmap', 'tables'];
const categoryTitles = ['Time-Based', 'Comparative', 'Hierarchical', 'Density-Based', 'Table'];

const viewTypes = observable({
  stackedArea: {
    category: 'time',
    label: 'Stacked Area Chart',
    icon: 'timeline-area-chart',
    iconName: 'timeline-area-chart',
    component: StackedAreaView,
    config: stackedAreaConfig
  },
  stackedAreaHundredPercent: {
    category: 'time',
    label: '100% Stacked Area Chart',
    icon: 'full-stacked-chart',
    iconName: 'full-stacked-chart',
    component: StackedAreaHundredPercentView,
    config: stackedAreaHundredPercentConfig
  },
  stackedBar: {
    category: 'time',
    label: 'Stacked Column Chart',
    icon: 'stacked-chart',
    iconName: 'stacked-chart',
    component: StackedColumnView,
    config: stackedColumnConfig
  },
  line: {
    category: 'time',
    label: 'Line Chart',
    icon: 'timeline-line-chart',
    iconName: 'timeline-line-chart',
    component: LineView,
    config: lineConfig
  },
  horizon: {
    category: 'time',
    label: 'Horizon Chart',
    icon: 'align-justify',
    iconName: 'align-justify',
    component: HorizonView,
    config: horizonConfig
  },
  bar: {
    category: 'comparison',
    label: 'Bar Chart',
    icon: 'horizontal-bar-chart',
    iconName: 'horizontal-bar-chart',
    component: BarView,
    config: barConfig
  },
  pie: {
    category: 'comparison',
    label: 'Pie Chart',
    icon: 'doughnut-chart',
    iconName: 'doughnut-chart',
    component: PieView,
    config: pieConfig
  },
  sankey: {
    category: 'hierarchical',
    label: 'Sankey',
    icon: 'flows',
    iconName: 'flows',
    component: SankeyView,
    config: sankeyConfig
  },
  sunburst: {
    category: 'hierarchical',
    label: 'Sunburst',
    icon: 'flash',
    iconName: 'flash',
    component: SunburstView,
    config: sunburstConfig
  },
  matrix: {
    category: 'heatmap',
    label: 'Matrix',
    icon: 'heat-grid',
    iconName: 'heat-grid',
    component: MatrixView,
    config: matrixConfig
  },
  gauge: {
    category: 'heatmap',
    label: 'Gauge',
    icon: 'selection',
    iconName: 'selection',
    component: GaugeView,
    config: gaugeConfig
  },
  geoHeatMap: {
    category: 'heatmap',
    label: 'Geo HeatMap',
    icon: 'globe',
    iconName: 'globe',
    component: GeoHeatMapView,
    config: geoHeatMapConfig
  },
  table: {
    category: 'tables',
    label: 'Table',
    icon: 'th',
    iconName: 'th-list',
    component: TableView,
    config: tableConfig
  },
  alertScoreboard: {
    label: 'Alert Scoreboard',
    icon: 'globe',
    iconName: 'globe',
    component: AlertScoreboard,
    config: alertScoreboardConfig,
    hideFromViewMenu: true
  },
  rawFlow: {
    label: 'Raw Flow',
    icon: 'globe',
    iconName: 'globe',
    component: RawFlow,
    config: rawFlowConfig,
    hideFromViewMenu: true
  },
  generator: {
    label: '',
    icon: 'grid-view',
    iconName: 'grid-view',
    component: GeneratorView,
    config: generatorConfig,
    hideFromViewMenu: true
  }
});

class DataViewStore {
  hasViewModel(type) {
    return !!viewTypes[type];
  }

  getConfig(type) {
    return viewTypes[type] && viewTypes[type].config;
  }

  getIcon(type) {
    return viewTypes[type] && viewTypes[type].icon;
  }

  getIconName(type) {
    return viewTypes[type] && viewTypes[type].iconName;
  }

  getViewDisplayLabel(type) {
    return viewTypes[type] && viewTypes[type].label;
  }

  getComponent(type) {
    return viewTypes[type].component;
  }

  get categories() {
    return categories;
  }

  get categoryTitles() {
    return categoryTitles;
  }

  @computed
  get viewTypeOptions() {
    return Object.keys(viewTypes).map(viz_type => ({
      value: viz_type,
      ...viewTypes[viz_type]
    }));
  }

  @action
  getViewType(type) {
    return this.viewTypeOptions.find(view => view.value === type);
  }
}

export default new DataViewStore();
