import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

@observer
class ModalOptionGroupTitle extends Component {
  static defaultProps = {
    allowSelectAll: true,
    collapsible: true,
    collapsed: false
  };

  render() {
    const { disabled, collapsed, collapsible, onTitleClick, group, multi, allowSelectAll, onCollapse } = this.props;

    const className = classNames('modal-options-group-title', { 'pt-disabled': disabled });

    return (
      <div className={className}>
        {collapsible && (
          <Button
            className="pt-small pt-minimal"
            iconName={collapsed ? 'caret-right' : 'caret-down'}
            onClick={() => onCollapse(group)}
            style={{ marginRight: 4 }}
          />
        )}
        <span className="title">{group}</span>
        {multi &&
          allowSelectAll && (
            <Button
              text="Select All"
              className="pt-small pt-minimal pt-intent-primary"
              onClick={onTitleClick}
              disabled={disabled}
              style={{ marginLeft: 8 }}
            />
          )}
      </div>
    );
  }
}

export default ModalOptionGroupTitle;
