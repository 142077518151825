import { computed } from 'mobx';
import BaseModel from 'models/BaseModel';
import QueryBucketCollection from 'models/query/QueryBucketCollection';
import QueryModel from 'models/query/QueryModel';
import { arrayToCommaSeparatedString, commaSeparatedStringToArray } from 'util/utils';

export default class CustomApplication extends BaseModel {
  hash = null;

  get urlRoot() {
    return '/api/portal/customApplications';
  }

  get defaults() {
    return {
      name: '',
      description: '',
      ip_range: [],
      protocol: [],
      port: [],
      asn: []
    };
  }

  get messages() {
    return {
      create: `Custom application ${this.get('name')} was successfully added.`,
      update: `Custom application ${this.get('name')} was successfully updated.`,
      destroy: `Custom application ${this.get('name')} was successfully removed.`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Custom Application',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  @computed
  get query() {
    const queryModel = QueryModel.create({
      all_devices: true,
      metric: ['application'],
      filters_obj: {
        connector: 'All',
        filterGroups: [
          {
            connector: 'All',
            filters: [
              {
                filterField: 'application',
                operator: '=',
                filterValue: this.get('name')
              }
            ],
            filterGroups: [],
            saved_filters: [],
            autoAdded: false,
            not: false
          }
        ]
      }
    });

    return queryModel.serialize();
  }

  async fetchHash() {
    if (this.hash) return this.hash;
    const queryBuckets = new QueryBucketCollection();
    queryBuckets.set([{ query: this.query }]);
    return queryBuckets.save();
  }

  serialize(data) {
    const { ip_range, protocol, port, asn } = data;

    return super.serialize({
      ...data,
      ip_range: arrayToCommaSeparatedString(ip_range, false),
      protocol: arrayToCommaSeparatedString(protocol, false),
      port: arrayToCommaSeparatedString(port, false),
      asn: arrayToCommaSeparatedString(asn, false)
    });
  }

  deserialize(data = {}) {
    const { ip_range, protocol, port, asn } = data;

    return super.deserialize({
      ...data,
      ip_range: commaSeparatedStringToArray(ip_range),
      protocol: commaSeparatedStringToArray(protocol),
      port: commaSeparatedStringToArray(port),
      asn: commaSeparatedStringToArray(asn)
    });
  }
}
