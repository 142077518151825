import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Dialog, Button, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { formatDateTime } from 'util/dateUtils';

@inject('$messages', '$auth')
@observer
class MessagesDialog extends Component {
  render() {
    const { $auth, $messages } = this.props;
    const { dismissMessages, messages } = $messages;
    const { userTimezone } = $auth;

    return (
      <Dialog
        isOpen={messages.length > 0}
        title="System Messages"
        canEscapeKeyClose={false}
        canClickOutside={false}
        isCloseButtonShown={false}
        transitionName="pt-dialog"
      >
        <div className="pt-dialog-body" style={{ minHeight: 0 }}>
          {messages.map(({ id, message, cdate }) => (
            <Box key={id} className="pt-callout pt-intent-warning" mb={1}>
              <h5>
                {formatDateTime(cdate)} ({userTimezone})
              </h5>
              <p style={{ marginBottom: 0 }}>{message}</p>
            </Box>
          ))}
        </div>
        <Flex className="pt-dialog-footer" justify="flex-end" mt={-1}>
          <Button intent={Intent.PRIMARY} onClick={dismissMessages} text="Close" />
        </Flex>
      </Dialog>
    );
  }
}

export default MessagesDialog;
