import { action, computed, observable } from 'mobx';

import ReportsCollection, { reportTypes } from 'models/reports/ReportsCollection';
import { set } from 'lodash';
import api from 'util/api';

class LibraryStore {
  @observable
  reportsCollection = new ReportsCollection();

  @observable
  sidebarCollapsed = false;

  @observable
  loading = false;

  @observable
  loadingFailed = false;

  @action
  initialize() {
    this.setLoading(true);

    return this.reportsCollection.fetch().then(() => {
      this.reportsCollection.each(model => {
        model.set({ ...reportTypes.get(model.get('type')) });
      });

      this.setLoading(false);
    });
  }

  loadReport = (params, state) => {
    const { $basicExplorer, $dashboard } = this.store;
    const { type, templateIdOrHash } = params;

    console.info('Loading report', params, state || 'with no state');

    if (templateIdOrHash) {
      this.store.$basicExplorer.dataview.destroy();
      this.setLoading(true);

      let report;
      if (parseInt(templateIdOrHash).toString() === templateIdOrHash) {
        const matcher = {
          id: parseInt(templateIdOrHash),
          type: type || 'report'
        };

        report = this.reportsCollection.find(matcher);

        if (!report) {
          this.setLoadingFailed(true);
          return;
        }
      }

      if (report) {
        this.reportsCollection.select(report);

        if (type === 'dashboard') {
          const urlParams = params.params && JSON.parse(decodeURIComponent(params.params));
          $dashboard.loadDashboard(report.id, {
            ...state,
            params: params.params,
            completedInitialParametricSubmit:
              !state || !state.completedInitialParametricSubmit
                ? urlParams && urlParams.parametric_fields && urlParams.parametric_fields.length
                : (state && state.completedInitialParametricSubmit) || false
          });
        } else if (type === 'savedView') {
          $basicExplorer.loadSavedView(report);
        } else {
          $basicExplorer.loadTemplate(report);
        }
      } else {
        $basicExplorer.loadHash(templateIdOrHash);
      }

      this.setLoading(false);
    }
  };

  @action
  selectReport = (report, options) => {
    const reportType = report.get('type');

    report.set({ recent: true });

    console.info('Selecting report', report.toJS(), options || 'with no options');

    if (reportType === 'report') {
      this.history.push(`/library/${report.id}`, options);
    } else if (reportType === 'dashboard') {
      this.history.push(`/library/dashboard/${report.id}`, options);
    } else if (reportType === 'savedView') {
      this.history.push(`/library/savedView/${report.id}`, options);
    }

    this.store.$recentlyViewed.add('report', report);
  };

  @action
  fetchDashboardsForCompany = company_id => api.get(`/api/sudo/companies/${company_id}/dashboards`);

  @action
  setLoading = loading => {
    if (loading) {
      this.setLoadingFailed(false);
    }

    this.loading = loading;
  };

  @action
  setLoadingFailed = failed => {
    this.loading = false;
    this.loadingFailed = failed;
  };

  @action
  toggleReportFavorite = report => {
    report.set({ favorite: !report.get('favorite') });

    api
      .post(`/api/portal/reports/favorite/${report.id}`, {
        data: { entity_type: report.get('type'), favorite: report.get('favorite') }
      })
      .then(({ favorite }) => {
        report.set({ favorite });

        // also need to set it in the actual ReportsCollection
        const collectionReport = this.reportsCollection.get().find(r => r.id === report.id && r.type === report.type);

        if (collectionReport) {
          collectionReport.set({ favorite });
        }
      });
  };

  @action
  toggleReportFeatured = report => {
    this.setReportFeatured(report, !report.get('featured'));
  };

  @action
  setReportFeatured = (report, featured) => {
    const entity_type = report.get('type') || 'report';

    report.set({ featured: !report.get('featured') });

    api
      .post(`/api/portal/reports/feature/${report.id}`, {
        data: { entity_type, featured }
      })
      .then(() => {
        // set after optimistically setting to avoid state errors
        report.set({ featured });

        // also need to set it in the actual ReportsCollection
        const collectionReport = this.reportsCollection
          .get()
          .find(r => r.id === report.id && r.get('type') === entity_type);

        if (collectionReport) {
          collectionReport.set({ featured });
        }
      });
  };

  @action
  toggleSidebarCollapse = () => {
    this.sidebarCollapsed = !this.sidebarCollapsed;

    if (this.dataview && this.dataview.component) {
      setTimeout(() => this.dataview.component.reflow(), 350);
    }
  };

  @action
  set = (key, value) => {
    set(this, key, value);
  };

  @computed
  get availableReports() {
    return this.reportsCollection.models.map(m => ({ id: m.id, name: m.name, type: m.get('type') }));
  }

  @computed
  get reportOptions() {
    return this.reportsCollection.models.map(report => ({
      key: `${report.get('type')}.${report.id}`,
      value: { id: report.id, type: report.get('type') },
      label: report.name,
      report
    }));
  }
}

export default new LibraryStore();
