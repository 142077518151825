import React, { Component } from 'react';
import { CustomPicker, GithubPicker } from 'react-color';

import { labelColors } from 'models/Colors';
import { findClosestMatch } from 'util/colors';

/* eslint-disable react/prefer-stateless-function */
class ColorPicker extends Component {
  render() {
    const { color, onChange } = this.props;
    const closestColor = findClosestMatch(color, labelColors);

    return (
      <GithubPicker
        width={110}
        style={{ padding: 3, border: 'none', boxShadow: 'none', borderRadius: 0 }}
        onChange={onChange}
        color={closestColor}
        colors={labelColors}
      />
    );
  }
}

export default CustomPicker(ColorPicker);
