import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import ColorBlock from 'components/ColorBlock';

@observer
export default class ShortcutButton extends Component {
  render() {
    const { text, iconName, count, active, type, label, disabled, activeDevices, numDevices, ...rest } = this.props;
    const numberOfDevices = (activeDevices && activeDevices.length) || numDevices;

    return (
      <Button
        className={classNames('pt-minimal pt-fill pt-text-left', { 'pt-active': active })}
        style={{ marginBottom: 1 }}
        disabled={disabled}
        {...rest}
        text={
          <Flex align="center">
            {type !== 'label' && <Icon iconName={iconName} />}
            {type === 'label' && (
              <ColorBlock disabled={disabled} color={rest.color} style={{ width: 12, height: 12, marginRight: 7 }} />
            )}
            <Box className="pt-text-overflow-ellipsis" flexAuto>
              {text}
            </Box>
            {!active && (
              <small className="pt-text-muted" style={{ opacity: numberOfDevices === 0 ? 0.45 : 1 }}>
                {numberOfDevices}
              </small>
            )}
            {active && <Icon iconName="tick" style={{ marginRight: 0 }} />}
          </Flex>
        }
      />
    );
  }
}
