import React from 'react';
import { observer } from 'mobx-react';
import { Switch } from '@blueprintjs/core';

import { whitelistFieldProps } from 'forms/utils';

const getSwitchLabel = (field, switchLabel, showSwitchLabel, showOptionLabel, showFieldLabel) => {
  if (showSwitchLabel) {
    if (showOptionLabel) {
      const selectedOption = field.options.find(option => option.value === !!field.value);
      return selectedOption && selectedOption.label;
    }
    if (showFieldLabel) {
      return field.label;
    }
    return switchLabel || (field.value ? 'On' : 'Off');
  }
  return undefined;
};

export default observer(props => {
  const {
    field,
    onChange,
    className,
    switchLabel,
    showSwitchLabel = true,
    showOptionLabel = false,
    showFieldLabel = false
  } = props;

  const handleChange = e => {
    onChange(e.target.checked);
  };

  return (
    <Switch
      {...field.getProps()}
      {...whitelistFieldProps(props)}
      onChange={handleChange}
      className={className}
      checked={field.value}
      label={getSwitchLabel(field, switchLabel, showSwitchLabel, showOptionLabel, showFieldLabel)}
    />
  );
});
