import React, { Component } from 'react';
import { uniqueId } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Flex } from 'components/flexbox';
import DataViewSpinner from 'dataviews/DataViewSpinner';

import AlarmCollection from 'models/alerting/AlarmCollection';
import AlertScoreboardDataViewModel from './AlertScoreboardDataViewModel';
import ScoreboardCell from './ScoreboardCell';
import AlarmsDialog from './AlarmsDialog';

export const config = {
  model: AlertScoreboardDataViewModel,
  hideFromViewMenu: true
};

@inject('$alerts')
@observer
class AlertScoreboard extends Component {
  state = {
    isAlarmsDialogOpen: false,
    filteredAlarmsCollection: new AlarmCollection()
  };

  static defaultProps = {
    onAlarmsDialogClose: null,
    onCellClick: null,
    onPolicyClick: null,
    onShowCellDetails: null,
    onScoreboardSave: null
  };

  componentWillMount() {
    const { $alerts } = this.props;
    $alerts.myPolicies.fetch();
    $alerts.alarms.fetch();
  }

  handlePolicyClick = policy => {
    const { $alerts } = this.props;

    // filter the alertsCollection to show the relevant Alarms
    const filteredAlarms = $alerts.alarms.get().filter(alarm => alarm.get('policy_name').includes(policy.policy_name));
    this.setState({ filteredAlarmsCollection: new AlarmCollection(filteredAlarms), isAlarmsDialogOpen: true });
  };

  handleShowCellAlarms = (cell, row) => {
    const { $alerts } = this.props;

    // filter the alertsCollection to show the relevant Alarms
    const filteredAlarms = $alerts.alarms.models.filter(
      alarm => alarm.get('alert_key_lookup').includes(cell.name) && alarm.get('policy_name') === row.policy_name
    );

    this.setState({
      filteredAlarmsCollection: new AlarmCollection(filteredAlarms),
      isAlarmsDialogOpen: true,
      activeCell: cell,
      activeRow: row
    });
  };

  handleCloseAlarmsDialog = () => {
    this.setState({ isAlarmsDialogOpen: !this.state.isAlarmsDialogOpen });
  };

  render() {
    const { dataview } = this.props;
    const { viewModel } = dataview;

    const { hasData } = viewModel;
    const rows = viewModel.get('rows');
    const cols = viewModel.get('cols');

    const colHeaders = (
      <div className="tr align-stretch">
        <Flex className="td" style={{ minWidth: 65, maxWidth: 400, flex: 3 }} />
        {cols.map(header => (
          <div
            className="td pt-text-center pt-text-overflow-ellipsis"
            key={header.name}
            style={{ minWidth: 65, maxWidth: 400, flex: 1 }}
          >
            <small title={header.name}>
              <strong>{header.name}</strong>
            </small>
          </div>
        ))}
      </div>
    );

    if (!hasData && !dataview.loading) {
      return (
        <Flex flexAuto m={1} mt={0} align="center" justify="center" className="pt-callout">
          <p className="pt-text-muted no-margin">
            No active policies firing on dimension: <strong>{viewModel.xAxisDisplay}</strong>
          </p>
        </Flex>
      );
    }

    return (
      <Flex flexAuto flexColumn>
        <Flex flexAuto className="dataview-scoreboard">
          {dataview.loading && <DataViewSpinner text="Loading Scoreboard data..." loading={dataview.loading} />}
          {!dataview.loading &&
            hasData && (
              <div className="pt-table pt-interactive condensed" style={{ background: 'none' }}>
                {cols.length > 0 && <div className="thead">{colHeaders}</div>}

                <div className="tbody">
                  {viewModel.showMitigationsRow && (
                    <div className="tr">
                      <Flex
                        align="center"
                        className="td pt-text-overflow-ellipsis"
                        style={{ minWidth: 65, maxWidth: 400, flex: 3 }}
                      >
                        <strong style={{ fontSize: 14 }} className="pt-text-muted">
                          Mitigations
                        </strong>
                      </Flex>
                      {cols.map(col => (
                        <Flex
                          key={col.name}
                          className="td"
                          align="center"
                          justify="center"
                          style={{ minWidth: 65, maxWidth: 400, flex: 1 }}
                        >
                          {viewModel
                            .get('activeMitigations')
                            .some(mitigation => mitigation.alert_key_lookup === col.name) ? (
                            <ScoreboardCell data={col} isMitigation />
                          ) : (
                            <span className="pt-text-muted">--</span>
                          )}
                        </Flex>
                      ))}
                    </div>
                  )}

                  {rows.map((row, index) => (
                    <div className="tr" key={row.id}>
                      <Flex flexColumn className="td" style={{ minWidth: 65, maxWidth: 400, flex: 3 }}>
                        <a
                          style={{ fontSize: 14 }}
                          className="pt-strong pt-text-overflow-ellipsis"
                          onClick={() => this.handlePolicyClick(row)}
                        >
                          {row.policy_name}
                        </a>
                        <small className="pt-text-overflow-ellipsis pt-text-muted"> {row.policy_description}</small>
                      </Flex>

                      {rows[index].cols.length === 0 && (
                        <ScoreboardCell
                          key={uniqueId()}
                          onShowCellAlarms={this.handleShowCellAlarms}
                          row={row}
                          data={{ exceeded: false, counts: [] }}
                        />
                      )}

                      {rows[index].cols.length > 0 &&
                        rows[index].cols.map(data => (
                          <ScoreboardCell
                            key={uniqueId()}
                            onShowCellAlarms={this.handleShowCellAlarms}
                            row={row}
                            data={data}
                          />
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            )}
        </Flex>

        <AlarmsDialog
          isOpen={this.state.isAlarmsDialogOpen}
          onClose={this.handleCloseAlarmsDialog}
          collection={this.state.filteredAlarmsCollection}
          activeRow={this.state.activeRow}
          activeCell={this.state.activeCell}
        />
      </Flex>
    );
  }
}

export default AlertScoreboard;
