/* eslint-disable max-len */
/* eslint-disable prettier/prettier */

export const TOOLTIP_DATA = {
  shell: {
    fullUserProfile_html: {
      customer_short_name: 'The short name of the customer as specified at initial registration.',
      customer_full_name: 'The full name of the customer as specified at initial registration.',
      psql_command_line: 'The psql command line with which your organization’s users access the Kentik Data Engine (e.g. via PostgreSQL client).',
      KDE_host_port: 'System-assigned host and port for your organization’s access to the Kentik Data Engine via SQL.',
      KDE_user: 'System-assigned unique customer identifier; used to authenticate SQL.',
      KDE_password: 'System-assigned customer-wide password for access to the Kentik Data Engine; used to authenticate SQL.',
      user_full_name: 'A full name specified when the user was added/edited.',
      username: 'The name of the user as specified when the user was registered.',
      user_email: 'Your email address.',
      user_level: 'Administrator (full access) or member (view data only).',
      service_update: 'Choose whether or not to receive service update emails (sent to user email specified above).',
      product_update: 'Choose whether or not to receive product update emails (sent to user email specified above).',
      user_api_token: 'A system-assigned user-unique string used to authenticate API requests.'
    }
  },
  shared: {
    Pagination_html: {
      first_page: 'First page.',
      previous_page: 'Previous page.',
      all: 'List all rows on one page.',
      next_page: 'Next page.',
      lasts_page: 'Last page.'
    },
    Savedqueries_html: {
      name: 'The name of the Query.',
      date_created: 'The date that the query was created.',
      devices: 'The number of devices whose data is covered in the query. Hover over the number to see a list of devices.',
      time_range: 'The time range covered by the query.',
      metric: 'The GROUP BY metric of the query.',
      filters: 'The number of filters applied in the query. Hover over the number to see a list of filters.',
      graph: 'See a query in the Data Explorer.',
      sql: 'See a query’s SQL in the Query Editor.',
      add_to_dash: 'Add a graph of a query to a Dashboard panel.',
      delete_query: 'Delete a query',
      graph_icon: 'See this query in the Data Explorer.',
      sql_icon: 'Open Query Editor showing the query for this Query.',
      add_to_dash_icon: 'Add a graph of this query to a panel in a Dashboard.',
      delete_query_icon: 'Delete this Query.'
    }
  },
  admin: {
    users: {
      EditUser_html: {
        username: 'The name of the user as specified when the user was registered.',
        user_full_name: 'The full name of the user.',
        user_email: 'User-supplied email address.',
        portal_password: 'User-supplied password for portal access.',
        user_level: 'Administrator (full access) or member (view data only).',
        service_update: 'Choose whether or not to receive service update emails (sent to user email specified above).',
        product_update: 'Choose whether or not to receive product update emails (sent to user email specified above).',
        user_id: 'System-assigned unique user identifier.',
        user_api_token: 'System-assigned user-unique string, used to authenticate API requests.'
      }
    },
    device: {
      DeviceListing: {
        status: 'Flow and SNMP indicators: Indicates whether flow and SNMP are being received (hover for more detail)',
        type: 'Type of device: router host, host-nprobe-basic or kprobe-beta.',
        name: 'User-defined name string assigned at device registration.',
        flow_type: 'Device flow type.',
        auto_flow_type: 'Auto detected flow type.',
        description: 'User-defined description string assigned at device registration.',
        id: 'System-assigned device ID.',
        ip: 'IP address(es) from which device is sending flow data.',
        fps: 'Flows-per-second metrics.',
        fps_pre_cap_last_24h: 'The 99th percentile rate, expressed in flows per second, at which the device has been sending data to Kentik over the last 24 hours (not limited by service agreement flow cap).',
        fps_max_last_5m: 'The maximum rate, expressed in flows per second, at which the device has sent data to Kentik during the last 5 minutes (limited by service agreement flow cap).',
        bgp: 'BGP metrics.',
        bgp_enabled: 'Indicates device BGP peering setting.',
        bgp_enabled_icon: 'BGP peering for this device is enabled but not established.',
        bgp_enabled_icon_with_BGP_session: 'BGP peering for this device is enabled and established.',
        shared_bgp_enabled_icon: 'Indicates that BGP peering is enabled through another device.',
        bgp_routes_last_5m: 'Current number of routes in BGP routing table.',
        bgp_updates_last_24h: 'Number of routing table updates in last 24 hours.',
        sampling: 'Sample-rate metrics.',
        rate_last_3m: 'Max sampling rate reported by device where applicable including resampling due to fps exceeding contracted limit.',
        edit: 'Edit Device page.',
        graph: 'Data Explorer page.',
        interface: 'Interfaces page.',
        delete: 'Device deletion.',
        flow_indicator: 'Flow indicator: green if direct from router, blue if from Kentik agent, black when no flow is detected.',
        snmp_indicator: 'SNMP indicator: green when SNMP is detected, black when no SNMP is detected.',
        flow_from_router: 'Direct Flow detected',
        flow_from_kentik_agent: 'Flow from kentik agent detected',
        no_flow: 'No Flow detected',
        snmp_detected: 'SNMP detected',
        no_snmp: 'No SNMP detected',
        edit_icon: 'Edit Device page for this device.',
        graph_icon: 'Data Explorer for this device.',
        go_interfaces: 'Interfaces for this device.',
        delete_icon: 'Delete this device.',
        site_title: 'The site to which the following devices are assigned.',
        unassigned_devices: '"\'Devices in this group haven\'t been assigned to any site."'
      },
      deviceDetails: {
        device_name: 'User-supplied name string.',
        device_id: 'System-assigned device ID.',
        client_id: 'System-assigned string structured as: company id:device name:device id.',
        device_type_if_not_did: 'Specify whether device is router or nprobe basic or kprobe host.',
        device_type_if_router: 'This device is registered as a router.',
        device_type_if_host: 'This device is registered as a chfagent host.',
        device_type_if_host_nprobe_basic: 'This device is registered as a nprobe-basic host.',
        device_type_if_host_nprobe_dns_www: 'This device is registered as a kprobe host',
        description: 'User-supplied description string.',
        plan: 'The billing plan to which the device should be added.',
        sending_ips: 'IP address(es) from which the router sends flow.',
        flow_type: 'The type of flow the router is sending to Kentik.',
        sample_rate: 'Total packets transiting the device for each packet processed for flow data.',
        device_ip: 'IP address(es) from which the router sends flow.',
        minimize_snmp: 'If Standard, SNMP polling is every 10 min for interface counters and 3 hrs for descriptions. If Minimum, interface polling is disabled and descriptions are polled every 6 hours.',
        device_snmp_ip: 'Flow Collectors IP address.',
        snmp_community: 'The SNMP community to use when polling the router.',
        snmp_polling: 'If Standard, SNMP polling is every 10 min for interface counters and 3 hrs for descriptions. If Minimum, interface polling is disabled and descriptions are polled every 6 hours.',
        bgp: 'Choose whether Kentik will BGP peer with this device.',
        peering_device_ip: 'The IPv4 address of the device to peer with for BGP. An IPv4 or IPv6 peer is required when BGP is set to Peer with device.',
        peering_device_ip6: 'The IPv6 address of the device to peer with for BGP. An IPv4 or IPv6 peer is required when BGP is set to Peer with device.',
        yourASN: 'The number of the autonomous system (AS) that the peering device belongs to. Required when BGP is set to Peer with device.',
        select_peered_device: 'Select the peered device whose BGP table you want to use.',
        site_id: 'The site to which this device is assigned'
      }
    },
    tags: {
      details: {
        flow_tag: 'Name of this tag. This will appear in places where selecting a tag is necessary.',
        value: 'Name of this tag. This will appear in places where selecting a tag is necessary.',
        device_name: 'Name of the device(s) to match.',
        interface_name: 'Name/description of interface(s) to match.',
        addr: 'Comma delimited list of IP addresses, in IP/CIDR format',
        port: 'Port(s) to match.',
        tcp_flags: 'TCP flag(s) to match.',
        protocol: 'Comma delimited list of number(s) of the protocol(s) to match (e.g. "17" for UDP).',
        asn: 'Last-hop (Origin) ASN to match.',
        nexthop_asn: 'Next-hop ASN to match.',
        bgp_aspath: 'Comma separated list of numbers or regular expression representing bgp aspath. Example: "^3737 1212,_7801_,2906$" would look for any of those 3 combinations in the AS path. Permitted characters []*:_^$.0123456789()+?,space-',
        bgp_community: 'Comma separated list of numbers or regular expression representing bgp community (i.e. 2096:2212) Permitted characters []*:_^$.0123456789()+?,space-',
        tag_id: 'System-generated unique ID for tag.',
        device_type: 'Type of device(s) to match, accepts regular expression',
        nexthop_as_name: 'Comma separated list of Next-hop AS Names or regular expression',
        lasthop_as_name: 'Comma separated list of Last-hop AS Names or regular expression',
        site: 'Comma separated list of sites or regular expression',
        mac: 'Comma delimited list of 6 or 8 byte MAC addresses',
        country: 'Comma delimited list of country codes',
        nexthop: 'Comma delimited list of IP addresses, in IP/CIDR format',
        vlans: 'Comma delimited list of integers or ranges of integers (0-4095)'
      },
      TagsListing_html: {
        tag_name: 'The user-supplied name of the tag.',
        last_edited: 'Date of last edition.',
        edited_by: 'The email address of the last user who edited the tag.',
        tag_id: 'System-assigned unique tag identifier.',
        edit: 'Editing of tags.',
        graph_src: 'Graphing on src flow.',
        graph_dst: 'Graphing on dst flow.',
        delete_heading: 'Deletion of tags.',
        edit_icon: 'Edit this tag.',
        graph_src_icon: 'Go: Data Explorer showing total traffic for last hour (first device), with src flow filtered by this tag.',
        graph_dst_icon: 'Go: Data Explorer showing total traffic for last hour (first device), with dst flow filtered by this tag.',
        delete_icon: 'Delete this tag.'
      }
    },
    alerts: {
      AlertEdit_html: {
        alert_id: 'System-assigned unique alert ID.',
        enable: 'Check to enable, uncheck to disable.',
        alert_name: 'Name of the alert. Five to 64 characters: alphanumeric, underscore, or period; no spaces.',
        alert_extra: 'Optional description of the alert (user specified).',
        criticality: 'The seriousness of the condition triggering the alert: Info, Minor, Major, or Critical.',
        sql: 'The SQL query that defines the conditions that will be recognized as a match.',
        test_query: 'Go to the Query Editor with this query loaded in the query field.',
        lookback_window: 'The timespan (back in time from "now") over which flow is averaged each time an alert query is run.',
        event_window: 'The time range over which to count matches.',
        trigger_threshold: 'The number of matches that must occur within the event window to trigger a notification.',
        reset_period: 'Minutes that must pass without a new match before an alarm can be cleared.',
        supplemental_query_desc: 'Optional description of the supplemental query.',
        supplemental_query: 'A query that provides additional information for notifications. Run at the polling frequency whenever an alert is in alarm state.',
        supplemental_query_lookback: 'The lookback window for supplemental queries 1 and 2.'
      },
      AlertsListing_html: {
        global_alert_settings: 'Go to Global Alert Settings page.',
        filter: 'Enter text to filter the list.',
        enabled: 'Indicates whether an alert is enabled.',
        enabled_icon: 'Alert is enabled.',
        alert_name: 'The name of the alert.',
        criticality: 'The criticality of the alert: critical, major, minor, info.',
        trigger: 'Current alert settings for Trigger Threshold and Event Window.',
        supplemental: 'The number of supplemental queries currently set for this alert.',
        alert_id: 'System-assigned unique alert ID.',
        enable_disable: 'Toggle alert on or off.',
        edit: 'Alert editing.',
        test_query: 'Test queries in the Query Editor.',
        alert_history: 'Go to the Alert Log to see the history of this alert.',
        copy_alert: 'Alert duplication.',
        delete_alert: 'Alert deletion.',
        click_to_enable_icon: 'Click to enable.',
        click_to_disable_icon: 'Click to disable.',
        edit_icon: 'Edit this alert.',
        test_query_icon: 'Open Query Editor showing the query for this alert.',
        alert_history_icon: 'Go to Alert Log showing history of this alert.',
        copy_icon: 'Copy this alert.',
        delete_icon: 'Delete this alert.'
      },
      AlertSettings_html: {
        enable_alerts: 'Check to enable, uncheck to disable.',
        SQL_poll_frequency: 'The time interval at which an alert query is run.',
        enable_email_notifications: 'Check to enable, uncheck to disable.',
        send_updates_every: 'The interval between status update emails when one or more update is in alarm state.',
        email_addresses: 'Comma-delimited list of the email addresses of alert subscribers.',
        enable_URL_notifications: 'Check to enable, uncheck to disable.',
        notification_URL: 'URL for alert notifications.',
        enable_syslog_notifications: 'Check to enable, uncheck to disable.',
        syslog_receiver_IP: 'IP address to which syslog alert notification should be sent.',
        syslog_port: 'The port number for syslog on the receiving server.'
      }
    },
    interfaces: {
      ListInterfaces_html: {
        router_id: 'System-assigned device ID.',
        router_ip: 'IP address(es) from which device is sending flow data.',
        router_name: 'User-defined name string assigned at device registration.',
        status: 'Indicates whether flow or SNMP data representing either inbound or outbound traffic has (green disc) or has not (black disc) been reported to Kentik for this interface in the last 15 minutes.',
        interface_id: 'Interface ID as defined in the device itself and retrieved via SNMP.',
        interface_name: 'Name string as defined in the device itself and retrieved via SNMP.',
        interface_ip: 'IP address for this interface.',
        interface_description: 'Description string as defined in the device itself and retrieved via SNMP.',
        inbound_flow_detector: 'Inbound Flow detector (arrow): green if inbound flow is detected.',
        outbound_flow_detector: 'Outbound Flow detector (arrow): green if outbound flow is detected.',
        inbound_snmp_detector: 'Inbound SNMP detector (arrow): green if inbound SNMP is detected, red if percentage error between inbound Flow and SNMP is 20% or bigger.',
        outbound_snmp_detector: 'Outbound SNMP detector (arrow): green if outbound SNMP is detected, red if percentage error between outbound Flow and SNMP is 20% or bigger.',
        snmp_speed: 'The maximum capacity for this interface in mbps reported by SNMP.',
        graph: 'Data Explorer page.',
        inbound_flow: 'Inbound Flow detected',
        inbound_flow_in_mbps: 'Inbound Flow in Mbps',
        inbound_error: 'Inbound Flow-SNMP error',
        no_inbound_flow: 'No Inbound Flow detected',
        outbound_flow: 'Outbound Flow detected',
        outbound_flow_in_mbps: 'Outbound Flow in Mbps',
        outbound_error: 'Outbound Flow-SNMP error',
        no_outbound_flow: 'No Outbound Flow detected',
        no_ingress_or_egress_flow: 'No Ingress or Egress Flow detected',
        ingress_flow: 'Ingress Flow detected',
        egress_flow: 'Egress Flow detected',
        ingress_and_egress_flow: 'Ingress and Egress Flow detected',
        inbound_snmp: 'Inbound SNMP detected',
        inbound_snmp_in_mbps: 'Inbound SNMP in Mbps',
        outbound_snmp_in_mbps: 'Outbound SNMP in Mbps',
        no_inbound_snmp: 'No Inbound SNMP detected',
        outbound_snmp: 'Outbound SNMP detected',
        no_outbound_snmp: 'No Outbound SNMP detected',
        graph_icon: 'Go: Data Explorer for this device.'
      }
    },
    customDimensions: {
      CustomDimensionsListing_html: {
        name: 'The user-supplied name of the custom dimension.',
        last_edited: 'Date of last edition.',
        type: 'The user-supplied type of the custom dimension.',
        display_name: 'The user-supplied display name of the custom dimension.',
        customDimensions_id: 'System-assigned unique dimension identifier.',
        edit_heading: 'Editing of custom dimension.',
        graph_heading: 'Show in Explorer',
        delete_heading: 'Deletion of custom dimension.',
        edit_icon: 'Edit this custom dimension.',
        graph_icon: 'Show data from this custom dimension in Data Explorer.',
        delete_icon: 'Delete this custom dimension.',
        number_of_populators: 'Number of populators added to the custom dimension.',
        max_num: 'You reached the maximum number of custom dimensions.'
      },
      CustomDimensionsEdit_html: {
        dimension_id: 'System-generated unique ID for custom dimension.',
        dimension_name: 'Name of this custom dimension.',
        dimension_type: 'Type of this custom dimension.',
        display_name: 'Display name of this custom dimension.',
        populator_id: 'System-generated unique ID for populator.',
        populator_value: 'The user-supplied value of the populator.',
        populator_direction: 'The user-supplied direction of the populator.',
        edit_heading: 'Editing of populator.',
        graph_heading: 'Show in Explorer.',
        delete_heading: 'Deletion of populator.',
        edit_icon: 'Edit this populator.',
        graph_icon: 'Show data matching this populator in Data Explorer.',
        delete_icon: 'Delete this populator.'
      },
      populators: {
        dimension_name: 'The dimension this populator will be assigned to.',
        dimension_value: 'Value of this dimension.',
        device_name: 'Name of device(s) to match.',
        interface_name: 'Name/description of interface(s) to match.',
        addr: 'Comma delimited list of IP addresses, in IP/CIDR format',
        port: 'Comma delimited list of Ports or ranges Ports to match (e.g. 80, 8080, 6000-7000).',
        tcp_flags: 'TCP flag(s) to match.',
        protocol: 'Comma delimited list of number(s) of the protocol(s) to match (e.g. "17" for UDP).',
        asn: 'Last-hop (Origin) ASN to match.',
        nexthop_asn: 'Next-hop ASN to match.',
        bgp_aspath: 'Comma separated list of numbers or regular expression representing bgp aspath. Example: "^3737 1212,_7801_,2906$" would look for any of those 3 combinations in the AS path. Permitted characters []*:_^$.0123456789()+?,space-',
        bgp_community: 'Comma separated list of numbers or regular expression representing bgp community (i.e. 2096:2212) Permitted characters []*:_^$.0123456789()+?,space-',
        direction: 'Direction of the populator.',
        populator_id: 'System-generated unique ID for populator.',
        device_type: 'Type of device(s) to match, accepts regular expression',
        nexthop_as_name: 'Comma separated list of Next-hop AS Names or regular expression',
        lasthop_as_name: 'Comma separated list of Last-hop AS Names or regular expression',
        site: 'Comma separated list of sites or regular expression',
        mac: 'Comma delimited list of 6 or 8 byte MAC addresses',
        country: 'Comma delimited list of country codes',
        nexthop: 'Comma delimited list of IP addresses, in IP/CIDR format',
        vlans: 'Comma delimited list of integers or ranges of integers (0-4095)'
      }
    }
  }
};

export const DEVICE_STRINGS = {
  listing: {
    status: 'Flow and SNMP indicators: Indicates whether flow and SNMP are being received (hover for more detail)',
    type: 'Type of device: router host, host-nprobe-basic or kprobe.',
    name: 'User-defined name string assigned at device registration.',
    flow_type: 'Device flow type.',
    auto_flow_type: 'Auto detected flow type.',
    description: 'User-defined description string assigned at device registration.',
    id: 'System-assigned device ID.',
    ip: 'IP address(es) from which device is sending flow data.',
    fps: 'Flows-per-second metrics.',
    fps_pre_cap_last_24h: 'The 99th percentile rate, expressed in flows per second, at which the device has been sending data to Kentik over the last 24 hours (not limited by service agreement flow cap).',
    fps_max_last_5m: 'The maximum rate, expressed in flows per second, at which the device has sent data to Kentik during the last 5 minutes (limited by service agreement flow cap).',
    bgp: 'BGP metrics.',
    bgp_enabled: 'Indicates device BGP peering setting.',
    bgp_enabled_icon: 'BGP peering for this device is enabled but not established.',
    bgp_enabled_icon_with_BGP_session: 'BGP peering for this device is enabled and established.',
    shared_bgp_enabled_icon: 'Indicates that BGP peering is enabled through another device.',
    bgp_routes_last_5m: 'Current number of routes in BGP routing table.',
    bgp_updates_last_24h: 'Number of routing table updates in last 24 hours.',
    sampling: 'Sample-rate metrics.',
    rate_last_3m: 'Max sampling rate reported by device where applicable including resampling due to fps exceeding contracted limit.',
    edit: 'Edit Device page.',
    graph: 'Data Explorer page.',
    interface: 'Interfaces page.',
    delete: 'Device deletion.',
    flow_indicator: 'Flow indicator: green if direct from router, blue if from Kentik agent, black when no flow is detected.',
    snmp_indicator: 'SNMP indicator: green when SNMP is detected, black when no SNMP is detected.',
    flow_from_router: 'Direct Flow detected',
    flow_from_kentik_agent: 'Flow from kentik agent detected',
    no_flow: 'No Flow detected',
    snmp_detected: 'SNMP detected',
    no_snmp: 'No SNMP detected',
    edit_icon: 'Edit Device page for this device.',
    graph_icon: 'Data Explorer for this device.',
    go_interfaces: 'Interfaces for this device.',
    delete_icon: 'Delete this device.',
    site_title: 'The site to which the following devices are assigned.',
    unassigned_devices: 'Devices in this group have not been assigned to any site.'
  },
  details: {
    device_name: 'User-supplied name string.',
    device_id: 'System-assigned device ID.',
    client_id: 'System-assigned string structured as: company id:device name:device id.',
    device_type_if_not_did: 'Specify whether device is router or nprobe basic or kprobe host.',
    device_type_if_router: 'This device is registered as a router.',
    device_type_if_host: 'This device is registered as a chfagent host.',
    device_type_if_host_nprobe_basic: 'This device is registered as a nprobe-basic host.',
    device_type_if_host_nprobe_dns_www: 'This device is registered as a kprobe host',
    description: 'User-supplied description string.',
    plan: 'The billing plan to which the device should be added.',
    site_id: 'Select a site which this device should be associated with',
    flow_type: 'The type of flow the router is sending to Kentik.',
    sample_rate: 'Total packets transiting the device for each packet processed for flow data.',
    device_ip: 'IP address(es) from which the router sends flow.',
    device_snmp_ip: 'Flow Collectors IP address.',
    snmp_community: 'The SNMP community to use when polling the router.',
    snmp_polling: 'If Standard, SNMP polling is every 10 min for interface counters and 3 hrs for descriptions. If Minimum, interface polling is disabled and descriptions are polled every 6 hours.',
    bgp: 'Choose whether Kentik will BGP peer with this device.',
    peering_device_ip: 'The IPv4 address of the device to peer with for BGP. An IPv4 or IPv6 peer is required when BGP is set to Peer with device.',
    peering_device_ip6: 'The IPv6 address of the device to peer with for BGP. An IPv4 or IPv6 peer is required when BGP is set to Peer with device.',
    yourASN: 'The number of the autonomous system (AS) that the peering device belongs to. Required when BGP is set to Peer with device.',
    bgp_md5_pass: 'Optional BGP MD5 password.',
    select_peered_device: 'Select the peered device whose BGP table you want to use.'
  }
};
