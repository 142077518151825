import { computed, action } from 'mobx';
import { greekPrefix } from 'util/utils';

import QueryResultModel from './QueryResultModel';
import Collection from '../Collection';

class QueryResultsCollection extends Collection {
  prefixableFieldUnits = {};

  defaultSortField = null;

  get model() {
    return QueryResultModel;
  }

  get url() {
    return '';
  }

  get defaultSortState() {
    return {
      direction: 'desc'
    };
  }

  get baseSort() {
    return {
      field: 'isOverlay',
      direction: 'desc'
    };
  }

  @computed
  get overlayRows() {
    return this.models.filter(model => model.get('isOverlay'));
  }

  @computed
  get outsortNonOverlays() {
    return this.unfiltered.filter(model => !model.get('isOverlay'));
  }

  @computed
  get nonOverlayRows() {
    return this.models.filter(model => !model.get('isOverlay'));
  }

  @computed
  get prefix() {
    // Return map of units -> prefixes
    const prefix = {};

    // Prefixable fields is now map of fieldNames -> units for convenience
    const prefixableFieldNames = Object.keys(this.prefixableFieldUnits);
    const prefixableUnits = prefixableFieldNames.map(name => this.prefixableFieldUnits[name]);
    const nonOverlayRows = this.nonOverlayRows;

    // Find sorted field and prefix on its unit if applicable
    let outsortField = this.sortState.field;
    if (!prefixableFieldNames.includes(outsortField)) {
      outsortField = this.defaultSortField;
    }
    const outsortUnit = this.prefixableFieldUnits[outsortField];

    if (outsortField) {
      const data = nonOverlayRows.map(model => model.get(outsortField));
      prefix[outsortUnit] = greekPrefix(data);
    }

    // Prefix on all other prefixable units
    const otherUnits = prefixableUnits.filter(unit => unit !== outsortUnit);
    otherUnits.forEach(unit => {
      const fields = prefixableFieldNames.filter(name => this.prefixableFieldUnits[name] === unit);
      const data = fields.reduce(
        (combined, field) => combined.concat(nonOverlayRows.map(model => model.get(field))),
        []
      );
      prefix[unit] = greekPrefix(data);
    });

    return prefix;
  }

  @computed
  get outsortArray() {
    let outsortField = this.sortState.field;
    if (!this.prefixableFields.includes(outsortField)) {
      outsortField = this.defaultSortField;
    }
    return this.nonOverlayRows.map(model => model.get(outsortField));
  }

  getRawDataRows = (withOverlays = false) => {
    if (withOverlays) {
      return this.models.filter(model => model.hasRawData);
    }
    return this.models.filter(model => model.hasRawData && !model.get('isOverlay'));
  };

  @action
  overwrite(data) {
    const moddedIds = {};

    if (!Array.isArray(data) || !data.length) {
      return;
    }

    data.forEach(row => {
      const existingRecord = this.find({ key: row.key });
      if (existingRecord) {
        existingRecord.set(row);
        if (existingRecord.hasRawData && !row.rawData) {
          existingRecord.set({ rawData: null });
        }
        moddedIds[existingRecord.id] = true;
      } else {
        moddedIds[this.add(row)[0].id] = true;
      }
    });

    this.remove(this.nonOverlayRows.filter(model => !moddedIds[model.id]).map(model => model.id));
  }
}

export default QueryResultsCollection;
