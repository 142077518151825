import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { getToFixed, zeroToText, adjustByGreekPrefix, escapeHtml } from 'util/utils';
import $dictionary from 'stores/$dictionary';
import $app from 'stores/$app';
import BaseHighchartsDataview from './BaseHighchartsDataview';

@observer
export default class PieView extends BaseHighchartsDataview {
  chartOptions = {
    chart: {
      zoomType: 'x'
    },
    colors: this.qualitativeColors,
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true
      },
      series: {
        cursor: 'pointer',
        dataLabels: {
          color: this.chartLabelColor,
          enabled: true,
          formatter() {
            const str = this.key.replace(/----/g, '\u2192');
            if (str.length > 15) {
              return `${str.substring(0, 11)}... (${this.percentage.toFixed(1)}%)`;
            }
            return `${str} (${this.percentage.toFixed(1)}%)`;
          }
        }
      }
    },
    legend: { enabled: false },
    series: [
      {
        type: 'pie',
        name: '',
        colorByPoint: true,
        innerSize: '75%',
        data: []
      }
    ],
    tooltip: {
      formatter() {
        const { prefix, suffix, unit } = this.point.options;
        const val = escapeHtml(zeroToText(adjustByGreekPrefix(this.y, prefix), { fix: getToFixed(unit) }));
        return `${this.key.replace(/----/g, '\u2192')}:<br/><b>${val} ${prefix ||
          ''}${suffix}</b> (${this.percentage.toFixed(1)}%)`;
      }
    }
  };

  buildSeriesInternal(bucket, models) {
    this.clear();

    if (!this.chart) {
      return;
    }

    const { outsort, units } = bucket.firstQuery.get();
    const { aggregates, outsortUnit, outsortDataKey } = bucket.firstQuery;
    const { dictionary } = $dictionary;
    const prefix = bucket.queryResults.prefix[outsortUnit];
    let suffix = dictionary.units[outsortUnit];
    if (units.includes('sample_rate')) {
      suffix = ` : 1 ${suffix}`;
    }
    let total = 0;
    let sum = 0;

    const [series] = this.chart.series;

    models.forEach(model => {
      const { lookup, key, isOverlay } = model.get();
      let data = model.get(outsortDataKey);
      if (units.includes('sample_rate') && aggregates.find(agg => agg.name === outsort).fn !== 'percentile') {
        data /= 100;
      }

      if (isOverlay) {
        if (key === 'Total') {
          series.addPoint({
            name: 'Other',
            y: 0,
            prefix,
            suffix
          });
          total = data;
        } else {
          return; // we do this because we want to ignore other overlays
        }
      } else {
        series.addPoint(
          {
            name: lookup || key,
            y: data,
            prefix,
            suffix,
            unit: outsortUnit
          },
          false
        );
        sum += data;
      }

      const point = series.data[series.data.length - 1];
      point.options.model = model;
      model.set({ color: point.color, toggled: false });
      this.togglerDisposers.push(
        autorun(() => {
          const { toggled, mouseover } = model.get();
          if (toggled === point.sliced) {
            this.toggleSeries(point, toggled);
          }
          if (mouseover) {
            this.highlightSeries(point);
          } else {
            this.unhighlightSeries(point);
          }
        })
      );
    });

    if (total) {
      series.data.find(point => point.options.name === 'Other').update({ y: Math.max(total - sum, 0) }, false);
    }

    $app.renderSync(() => {
      if (this.chart) {
        this.chart.redraw();
      }
    });
    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  redraw({ setColors = false } = {}) {
    if (this.chart) {
      let pointMap = {};
      if (setColors) {
        const { legend } = this.chart;
        pointMap = this.chart.series[0].data.reduce((map, point) => {
          map[point.name] = point.options.model;
          return map;
        }, {});
        if (legend.options.enabled) {
          legend.update(
            {
              itemStyle: { color: `${this.chartLabelColor} !important` },
              itemHoverStyle: { color: `${this.chartLabelColor} !important` }
            },
            false
          );
        }
        this.chart.update(
          { colors: this.qualitativeColors, plotOptions: { series: { dataLabels: { color: this.chartLabelColor } } } },
          false
        );
      }
      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
          this.chart.series[0].data.forEach(point => {
            if (pointMap[point.name]) {
              point.options.model = pointMap[point.name];
              point.options.model.set({ color: point.color });
            }
          });
        }
      });
      $app.renderSync(() => {
        this.dismissSpinner();
      });
    }
  }

  highlightSeries(series) {
    if (series && series.setState) {
      series.onMouseOver();
      series.setState('hover');
    }
  }

  unhighlightSeries(series) {
    if (series && series.setState) {
      series.setState();
      series.onMouseOut();
    }
  }

  clear() {
    if (this.chart) {
      this.chart.series[0].setData([]);
    }
  }

  toggleSeries(point, toggled) {
    point.slice(!toggled, true);
    this.redraw();
  }

  setVisibleModels(models) {
    if (this.chart) {
      this.chart.series[0].data.forEach(point => {
        if (point.options && point.options.model) {
          const { model } = point.options;
          const pointKey = model.get('key');

          const selected = models.some(m => m.get('key') === pointKey);
          point.slice(selected, true);
        }
      });

      this.chart.redraw();
    }
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview, showNativeLegend } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;
    const { outsort } = query.get();

    chartOptions.series[0].name = outsort;

    if (showNativeLegend) {
      chartOptions.legend = {
        itemStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'bold !important'
        },
        verticalAlign: 'bottom',
        align: 'center'
      };
    }

    if (chartOptions.plotOptions && chartOptions.plotOptions.pie) {
      const { pie } = chartOptions.plotOptions;

      if (this.props.onModelSelect) {
        if (!pie.point) {
          pie.point = { events: {} };
        }
        pie.point.events.click = this.getLegendItemClick();
      }
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: false,
  enableToggle: true,
  buckets: [
    {
      name: 'Pie'
    }
  ]
};

export { config };
