import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';

@observer
class Toolbar extends Component {
  static contextTypes = {
    form: any
  };

  render() {
    const { form } = this.context;
    const {
      sidebar,
      onSubmit,
      sidebarScrollPosition,
      isFooter,
      submitBtnText = 'Run Query',
      allowCleanSubmit,
      btnIcon
    } = this.props;

    const canSubmit = (allowCleanSubmit || form.dirty) && form.valid;
    const isSidebarCollapsed = sidebar && sidebar.collapsed;

    const toolbarClassNames = classNames({
      footer: isFooter,
      toolbar: !isFooter,
      'toolbar-scrolled pt-elevation-2': !isFooter && sidebarScrollPosition > 0,
      'footer-scrolled pt-elevation-2': isFooter && sidebarScrollPosition > 0
    });

    if (isSidebarCollapsed) {
      return null;
    }

    return (
      <Box flexAuto>
        <Flex className={toolbarClassNames}>
          <Button
            className={`pt-medium ${canSubmit && 'pt-intent-primary'}`}
            disabled={!canSubmit}
            onClick={onSubmit}
            iconName={btnIcon || 'play'}
            text={submitBtnText}
            style={{ flex: '1 1 0%' }}
          />
        </Flex>
      </Box>
    );
  }
}

export default Toolbar;
