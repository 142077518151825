import { observable } from 'mobx';
import Collection from 'models/Collection';
import CustomApplication from './CustomApplication';

export default class CustomApplicationCollection extends Collection {
  @observable
  dimensions = {};

  get url() {
    return '/api/portal/customApplications';
  }

  get model() {
    return CustomApplication;
  }
}
