import { computed } from 'mobx';
import BaseModel from '../BaseModel';
import { deviceTypeByKflow } from '../../util/utils';

class PlanHistory extends BaseModel {
  get defaults() {
    return {
      devices: []
    };
  }

  get jsonRoot() {
    return 'planHistory';
  }

  get omitDuringSerialize() {
    return ['devices'];
  }

  deserialize(history) {
    const {
      metadata: {
        user: { user_full_name }
      },
      plan: { id, cdate, ...planData },
      ...historyData
    } = history;

    return {
      ...historyData,
      ...planData,
      user_full_name
    };
  }

  @computed
  get numDevices() {
    const devices = this.get('devices');
    const numDevices = (devices.models && devices.models.length) || 0;

    if (numDevices === 0) {
      return 'No Devices';
    }

    return numDevices > 1 ? `${numDevices} Devices` : '1 Device';
  }

  @computed
  get allowedDeviceTypes() {
    return this.get('deviceTypes')
      .map(({ device_type, kflow_type }) => deviceTypeByKflow(device_type || kflow_type).map(dt => dt.label))
      .join(', ');
  }
}

export default PlanHistory;
