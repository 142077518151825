import React, { Component, Fragment } from 'react';
import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, ButtonGroup, Switch, Intent, Tag, InputGroup } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/labs';
import classNames from 'classnames';

import { ColorPicker, ColorBlock } from 'components';
import { Flex, Box } from 'components/flexbox';
import { Form, Field, Input, InputMultiple } from 'components/forms';
import { fields, options } from 'forms/config/visualizations';
import { defaultVisualizationSettings, DEFAULT_PALETTES, DEFAULT_QUALITATIVE_PALETTES } from 'models/Colors';

const Theme = ({ colors, selected, onClick }) => (
  <Flex className={classNames('color-palette', { selected })} onClick={onClick}>
    {colors.map(color => (
      <Box className="swatch" key={color} style={{ backgroundColor: color }} />
    ))}
  </Flex>
);

const ColorButton = ({ color }) => (
  <Button className="pt-fixed" style={{ padding: '0 6px', marginRight: 8 }}>
    <ColorBlock
      color={color}
      style={{
        boxShadow: 'none',
        width: 16,
        height: 16,
        position: 'relative',
        top: 4,
        border: '1px solid rgba(16, 22, 26, 0.2)'
      }}
    />
  </Button>
);

const ColorFieldWrapper = ({ mode, name, label, onSettingsChange }) => (
  <Field name={`settings.visualizations.${mode}.${name}`}>
    {({ field, showError, value, onChange }) => {
      const change = val => {
        onChange(val);
        onSettingsChange();
      };

      return (
        <Flex align="center">
          <Popover2 minimal rootElement="div" placement="bottom-start">
            <ColorButton color={value} />
            <ColorPicker color={value} onChange={newVal => change(newVal.hex)} />
          </Popover2>
          <Input
            style={{ width: '100%' }}
            field={field}
            intent={showError && Intent.DANGER}
            onChange={change}
            placeholder="Enter hex..."
            rightElement={<Tag className="pt-minimal">{label}</Tag>}
            value={value}
          />
        </Flex>
      );
    }}
  </Field>
);

const CustomPaletteFields = ({ form, fieldName, onChange }) => (
  <InputMultiple field={form.getField(fieldName)}>
    {({ field }) => (
      <Fragment>
        {field.map(f => (
          <Flex key={f._id} align="center" mb={1}>
            <Popover2 minimal rootElement="div" placement="bottom-start">
              <ColorButton color={f.value} />
              <ColorPicker
                color={f.value}
                onChange={newVal => {
                  f.onChange(newVal.hex);
                  onChange(newVal.hex);
                }}
              />
            </Popover2>
            <Field key={f._id} field={f} showLabel={false} onChange={onChange}>
              {({ onChange: fieldChange, value }) => (
                <InputGroup className="pt-fill" placeholder="Enter hex..." value={value} onChange={fieldChange} />
              )}
            </Field>
          </Flex>
        ))}
      </Fragment>
    )}
  </InputMultiple>
);

@inject('$app', '$auth', '$users', '$dictionary')
@Form({ fields, options })
@observer
class VisualizationsForm extends Component {
  state = {
    selectedColorMode: 'standard',
    showCustomPalette: false,
    showCustomQualitative: false
  };

  componentWillMount() {
    const { $app, form } = this.props;

    const selectedColorMode = $app.darkThemeEnabled ? 'dark' : 'standard';
    const customPaletteField = `settings.visualizations.${selectedColorMode}.paletteCustom`;
    const customQualitativeField = `settings.visualizations.${selectedColorMode}.qualitativeCustom`;

    this.setState({
      showCustomPalette: form.getValue(customPaletteField) && !!form.getValue(customPaletteField).length,
      showCustomQualitative: form.getValue(customQualitativeField) && !!form.getValue(customQualitativeField).length,
      selectedColorMode
    });

    const darkTheme = $app.darkThemeEnabled;
    this.darkThemeDisposer = autorun(() => {
      if (darkTheme !== $app.darkThemeEnabled) {
        this.setState({ selectedColorMode: $app.darkThemeEnabled ? 'dark' : 'standard' }, () => {
          this.props.dataview.component.redraw({ setColors: true });
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.darkThemeDisposer) {
      this.darkThemeDisposer();
    }
  }

  handleSetColorMode = selectedColorMode => {
    if (selectedColorMode !== this.state.selectedColorMode) {
      const { $app, form } = this.props;
      const customPalette = form.getValue(`settings.visualizations.${selectedColorMode}.paletteCustom`);
      const customQualitative = form.getValue(`settings.visualizations.${selectedColorMode}.qualitativeCustom`);

      this.setState(
        {
          showCustomPalette: customPalette && customPalette.length > 0,
          showCustomQualitative: customQualitative && customQualitative.length > 0,
          selectedColorMode
        },
        () => {
          $app.toggleDarkTheme({ persist: false });
        }
      );
    }
  };

  handleResetDefaults = () => {
    const { form, onResetDefaults } = this.props;

    form.init({ ...form.getValues(), settings: { visualizations: defaultVisualizationSettings } });

    this.setState({
      showCustomPalette: false,
      showCustomQualitative: false
    });

    onResetDefaults(form.getValues());
  };

  updateUserProfile = () => {
    const { $users, form } = this.props;
    $users.updateUserProfile(form.getValues());
  };

  // field 'palette' || 'qualitative'
  handleShowCustomInputs = (colorField = 'Palette') => {
    const { form } = this.props;
    const { selectedColorMode } = this.state;
    const defaultPalettes = colorField === 'Palette' ? DEFAULT_PALETTES : DEFAULT_QUALITATIVE_PALETTES;

    this.setState({ [`showCustom${colorField}`]: !this.state[`showCustom${colorField}`] }, () => {
      const selectedPalette = form.getValue(`settings.visualizations.${selectedColorMode}.${colorField.toLowerCase()}`);
      const customFieldName = `settings.visualizations.${selectedColorMode}.${colorField.toLowerCase()}Custom`;

      if (this.state[`showCustom${colorField}`]) {
        form.setValue(customFieldName, defaultPalettes[selectedPalette] || defaultPalettes.default);
      } else {
        form.setValue(customFieldName, []);
      }
    });
  };

  onSettingsChange = () => {
    const { form, onChange } = this.props;
    onChange(form.getValues());
  };

  render() {
    const { $auth, $users, form } = this.props;
    const { selectedColorMode, showCustomPalette, showCustomQualitative } = this.state;
    const { updatingUser } = $users;

    return (
      <Fragment>
        <Box mb={2}>
          <ButtonGroup className="pt-fill">
            <Button
              iconName="flash"
              active={selectedColorMode === 'standard'}
              onClick={() => this.handleSetColorMode('standard')}
            >
              Standard Theme
            </Button>
            <Button
              iconName="moon"
              active={selectedColorMode === 'dark'}
              onClick={() => this.handleSetColorMode('dark')}
            >
              Dark Theme
            </Button>
          </ButtonGroup>
        </Box>

        <Box mb={2}>
          <Box className="pt-text-muted">
            <p>Labels</p>
          </Box>

          {selectedColorMode === 'standard' && (
            <ColorFieldWrapper mode="standard" name="labels" label="Text" onSettingsChange={this.onSettingsChange} />
          )}
          {selectedColorMode === 'dark' && (
            <ColorFieldWrapper mode="dark" name="labels" label="Text" onSettingsChange={this.onSettingsChange} />
          )}
        </Box>

        <Box mb={2}>
          <Box className="pt-text-muted">
            <p>Overlays</p>
          </Box>

          <Box mb={1}>
            {selectedColorMode === 'standard' && (
              <ColorFieldWrapper
                mode="standard"
                label="Total"
                name="primaryOverlay"
                onSettingsChange={this.onSettingsChange}
              />
            )}
            {selectedColorMode === 'dark' && (
              <ColorFieldWrapper
                mode="dark"
                label="Total"
                name="primaryOverlay"
                onSettingsChange={this.onSettingsChange}
              />
            )}
          </Box>

          {selectedColorMode === 'standard' && (
            <ColorFieldWrapper
              mode="standard"
              label="Historical"
              name="overlay"
              onSettingsChange={this.onSettingsChange}
            />
          )}
          {selectedColorMode === 'dark' && (
            <ColorFieldWrapper mode="dark" label="Historical" name="overlay" onSettingsChange={this.onSettingsChange} />
          )}
        </Box>

        <Box mb={3}>
          <Flex flexColumn flexAuto>
            <Box className="pt-text-muted">
              <p>Quantitative</p>
            </Box>

            {showCustomPalette && (
              <Fragment>
                {selectedColorMode === 'standard' && (
                  <CustomPaletteFields
                    form={form}
                    fieldName="settings.visualizations.standard.paletteCustom"
                    onChange={this.onSettingsChange}
                  />
                )}
                {selectedColorMode === 'dark' && (
                  <CustomPaletteFields
                    form={form}
                    fieldName="settings.visualizations.dark.paletteCustom"
                    onChange={this.onSettingsChange}
                  />
                )}
              </Fragment>
            )}

            <Box flexAuto>
              {!showCustomPalette && (
                <Fragment>
                  {selectedColorMode === 'standard' && (
                    <Field
                      field={form.getField('settings.visualizations.standard.palette')}
                      onChange={this.onSettingsChange}
                    >
                      {({ value, onChange }) =>
                        Object.keys(DEFAULT_PALETTES).map(theme => (
                          <Fragment key={theme}>
                            <Theme
                              name={theme}
                              colors={DEFAULT_PALETTES[theme]}
                              selected={value === theme}
                              onClick={() => onChange(theme)}
                            />
                          </Fragment>
                        ))
                      }
                    </Field>
                  )}
                  {selectedColorMode === 'dark' && (
                    <Field
                      field={form.getField('settings.visualizations.dark.palette')}
                      onChange={this.onSettingsChange}
                    >
                      {({ value, onChange }) =>
                        Object.keys(DEFAULT_PALETTES).map(theme => (
                          <Fragment key={theme}>
                            <Theme
                              name={theme}
                              colors={DEFAULT_PALETTES[theme]}
                              selected={value === theme}
                              onClick={() => onChange(theme)}
                            />
                          </Fragment>
                        ))
                      }
                    </Field>
                  )}
                </Fragment>
              )}

              <Box mb={3}>
                <Switch
                  onChange={() => this.handleShowCustomInputs('Palette')}
                  checked={showCustomPalette}
                  value={showCustomPalette}
                  label="Use custom values"
                />
              </Box>

              <Box className="pt-text-muted">
                <p>{$auth.isDan ? 'Rohanitative' : 'Qualitative'}</p>
              </Box>

              {showCustomQualitative && (
                <Fragment>
                  {selectedColorMode === 'standard' && (
                    <CustomPaletteFields
                      form={form}
                      fieldName="settings.visualizations.standard.qualitativeCustom"
                      onChange={this.onSettingsChange}
                    />
                  )}
                  {selectedColorMode === 'dark' && (
                    <CustomPaletteFields
                      form={form}
                      fieldName="settings.visualizations.dark.qualitativeCustom"
                      onChange={this.onSettingsChange}
                    />
                  )}
                </Fragment>
              )}

              {!showCustomQualitative && (
                <Fragment>
                  {selectedColorMode === 'standard' && (
                    <Field
                      field={form.getField('settings.visualizations.standard.qualitative')}
                      onChange={this.onSettingsChange}
                    >
                      {({ value, onChange }) =>
                        Object.keys(DEFAULT_QUALITATIVE_PALETTES).map(theme => (
                          <Theme
                            key={theme}
                            name={theme}
                            colors={DEFAULT_QUALITATIVE_PALETTES[theme]}
                            selected={value === theme}
                            onClick={() => onChange(theme)}
                          />
                        ))
                      }
                    </Field>
                  )}
                  {selectedColorMode === 'dark' && (
                    <Field
                      field={form.getField('settings.visualizations.dark.qualitative')}
                      onChange={this.onSettingsChange}
                    >
                      {({ value, onChange }) =>
                        Object.keys(DEFAULT_QUALITATIVE_PALETTES).map(theme => (
                          <Theme
                            key={theme}
                            name={theme}
                            colors={DEFAULT_QUALITATIVE_PALETTES[theme]}
                            selected={value === theme}
                            onClick={() => onChange(theme)}
                          />
                        ))
                      }
                    </Field>
                  )}
                </Fragment>
              )}
            </Box>
            <Box mb={1}>
              <Switch
                onChange={() => this.handleShowCustomInputs('Qualitative')}
                checked={showCustomQualitative}
                value={showCustomQualitative}
                label="Use custom values"
              />
            </Box>
          </Flex>
        </Box>

        <Flex justify="space-between" flexAuto pb={2}>
          <Button onClick={this.handleResetDefaults}>Reset Themes to Defaults</Button>

          <Button
            className="pt-intent-primary pt-medium"
            onClick={this.updateUserProfile}
            text="Save Themes"
            loading={updatingUser}
            style={{ minWidth: 110 }}
          />
        </Flex>
      </Fragment>
    );
  }
}

export default VisualizationsForm;
