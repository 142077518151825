import React from 'react';
import { observer } from 'mobx-react';
import { NumericInput as NumericInputComponent } from '@blueprintjs/core';
import { whitelistFieldProps, shiftInputCursor } from 'forms/utils';

const defaultProps = {
  selectAllOnFocus: true,
  selectAllOnIncrement: false,
  minorStepSize: 1
};

const NumericInput = props => {
  const { field, onChange, style } = props;

  const handleChange = value => {
    onChange(value);
  };

  return (
    <NumericInputComponent
      {...field.getProps()}
      {...defaultProps}
      {...whitelistFieldProps(props)}
      onValueChange={handleChange}
      allowNumericCharactersOnly
      autoComplete="off"
      style={style}
      onFocus={props.autoFocus ? shiftInputCursor : undefined}
    />
  );
};

export default observer(NumericInput);
