import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import classNames from 'classnames';

// import DevTool, { configureDevtool } from 'mobx-react-devtools';
// import { isDev } from 'util/utils';

import { Flex } from 'components/flexbox';
import { ProtectedRoute } from 'components';
import store from 'stores/store';
import initValidators from 'forms/validators';

import Profile from 'views/Admin/Profile/Profile';
import BasicExplorerView from 'views/Library/BasicExplorerView';
import DefaultView from 'views/DefaultView';
import ExpiredTrial from 'views/ExpiredTrial';
import Login from 'views/Login/Login';
import SsoLookup from 'views/Login/SsoLookup';
import LoginInterstitial from 'views/Login/LoginInterstitial';
import SubtenantNavMenu from 'views/nav/SubtenantNavMenu';
import InactiveCompany from 'views/InactiveCompany';
import SuspendedCompany from 'views/SuspendedCompany';
import Alerts from 'views/Alerting/Alerts/Alerts';
import AlertHistory from 'views/Alerting/Alerts/AlertHistory';

import getErrorBoundary from '../util/components/getErrorBoundary';

import NotFound from './NotFound';
import Password from './Password/Password';
import Signup from './Signup';
import VerifyTwoFactor from './VerifyTwoFactor';
import VerifyEmail from './VerifyEmail';
import ExportDialog from './ExportDialog';
import SudoEjectButtonDialog from './SudoEjectButtonDialog';
import PoweredByKentik from '../components/PoweredByKentik';
import ProfileV4 from './Admin/Profile/ProfileV4';

// if (isDev) {
//   configureDevtool({
//     updatesEnabled: false
//   });
// }
const ErrorBoundaryCmp = getErrorBoundary('AppWrapper');

@inject('$app', '$auth', '$alerts')
@observer
class SubtenantAppWrapper extends Component {
  state = {
    appReady: false
  };

  componentWillMount() {
    initValidators();

    /**
     * Assign the `history` instance that React Router uses to all of our stores.
     * This allows stores to control navigation if necessary. See `$explorer.load`
     * for a use case.
     */
    store.setHistory(this.props.history);
  }

  componentDidMount() {
    const { $auth, $app } = this.props;

    $app.setIsSubtenant(true);

    $auth.initialize();
    Promise.all([$auth.verifyAuth(), $auth.getOpenConfig()])
      .then(resultsArray => {
        const [authenticated, openConfig] = resultsArray;

        if (!openConfig.subtenancy) {
          window.location = openConfig.baseUrl;
        }

        if (authenticated && !$auth.twoFactorVerifyRequired) {
          return store.initializeApp();
        }

        return null;
      })
      .then(() => {
        this.setState({ appReady: true });
      });
  }

  render() {
    const { $app, $auth, children, match } = this.props;
    const { hasSudo, isSpoofed, openConfig } = $auth;
    const baseMatchPath = match.path === '/' ? '' : match.path;
    if (this.state.appReady) {
      return (
        <Flex
          flexColumn
          className={classNames('app', {
            'pt-dark': $app.darkThemeEnabled && $auth.authenticated
          })}
        >
          <LoginInterstitial isOpen={$auth.loginInterstitialOpen} onClose={$auth.handleToggleLoginInterstitial} />
          <SubtenantNavMenu />
          {$auth.authenticated &&
            !openConfig.subtenancy.permissions.advancedMode && (
              <PoweredByKentik style={{ position: 'fixed', left: 64, bottom: 5 }} />
            )}

          {/* {isDev && <DevTool />} */}

          {children}

          <ErrorBoundaryCmp>
            <Switch>
              <Route exact path={`${baseMatchPath}/login`} component={Login} />
              <Route exact path={`${baseMatchPath}/login.html`} render={() => <Redirect to={`${baseMatchPath}/`} />} />
              <Route exact path={`${baseMatchPath}/login/sso/:companyIdentifier`} component={Login} />
              <Route exact path={`${baseMatchPath}/ssolookup`} component={SsoLookup} />
              <Route exact path={`${baseMatchPath}/password`} component={Password} />
              <Route exact path={`${baseMatchPath}/signup`} component={Signup} />
              <Route exact path={`${baseMatchPath}/signup.html`} render={() => <Redirect to="/signup" />} />
              <Route
                exact
                path="/account-activation"
                render={routeProps => <Password {...routeProps} accountActivation />}
              />
              <ProtectedRoute exact path={`${baseMatchPath}/suspended-company`} component={SuspendedCompany} />
              <ProtectedRoute exact path={`${baseMatchPath}/expired-trial`} component={ExpiredTrial} />
              <ProtectedRoute exact path={`${baseMatchPath}/inactive-company`} component={InactiveCompany} />
              <ProtectedRoute exact path={`${baseMatchPath}/email/verify`} component={VerifyEmail} />
              <ProtectedRoute exact skip2fa path={`${baseMatchPath}/verify-two-factor`} component={VerifyTwoFactor} />
              <ProtectedRoute exact path={`${baseMatchPath}/`} render={DefaultView} />

              <Redirect exact from={`${baseMatchPath}/dashboards`} to={`${baseMatchPath}/library`} />
              <Route
                exact
                path={`${baseMatchPath}/dashboards/:id`}
                render={({ match: routeMatch }) => (
                  <Redirect to={`${routeMatch.path}/library/dashboard/${routeMatch.params.id}`} />
                )}
              />
              <Route
                exact
                path={`${baseMatchPath}/dashboards/:id/urlParams/:params`}
                render={({ match: routeMatch }) => (
                  <Redirect
                    to={`${baseMatchPath}/library/dashboard/${routeMatch.params.id}/urlParams/${
                      routeMatch.params.params
                    }`}
                  />
                )}
              />
              <ProtectedRoute
                path={`${baseMatchPath}/portal`}
                render={props => {
                  const { hash } = props.location;

                  if (hash.startsWith('#Dashboards/')) {
                    const dashboardId = hash.substring(hash.indexOf('/') + 1);
                    return <Redirect to={`${baseMatchPath}/library/dashboard/${dashboardId}`} />;
                  }

                  return <Redirect to="/404" />;
                }}
              />

              <ProtectedRoute exact path={`${baseMatchPath}/library`} component={BasicExplorerView} />
              <ProtectedRoute exact path={`${baseMatchPath}/library/alarm/:alarmId`} component={BasicExplorerView} />
              <ProtectedRoute exact path={`${baseMatchPath}/library/:templateIdOrHash`} component={BasicExplorerView} />
              <ProtectedRoute
                exact
                path={`${baseMatchPath}/library/:type/:templateIdOrHash`}
                component={BasicExplorerView}
              />
              <ProtectedRoute
                exact
                path={`${baseMatchPath}/library/:type/:templateIdOrHash/urlParams/:params`}
                component={BasicExplorerView}
              />

              <ProtectedRoute path={`${baseMatchPath}/profile`} component={Profile} />
              <ProtectedRoute path="/alerting/alerts" component={Alerts} permission="alerts.alarms.view" />
              <ProtectedRoute path="/alerting/history" component={AlertHistory} permission="alerts.alarms.view" />
              <ProtectedRoute path={`${baseMatchPath}/profile`} component={ProfileV4} />

              <Route exact path="/alerting" render={() => <Redirect to="/alerting/alerts" />} />

              <ProtectedRoute path="*" component={NotFound} />
            </Switch>
          </ErrorBoundaryCmp>

          <ExportDialog />
          {(hasSudo || isSpoofed) && <SudoEjectButtonDialog />}
        </Flex>
      );
    }

    return <div />;
  }
}

export default withRouter(SubtenantAppWrapper);
