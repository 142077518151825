import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Form from '../Form';
import DimensionSelector from './DimensionSelector';

const fields = { dimension: { defaultValue: [] } };

@Form({ fields })
@observer
class StandaloneDimensionSelector extends Component {
  static defaultProps = {
    isOpen: false,
    multi: false
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      isOpen && (
        <DimensionSelector
          {...this.props}
          fieldName="dimension"
          dialogOnly
          isEditing={isOpen}
          onEditComplete={onClose}
        />
      )
    );
  }
}

export default StandaloneDimensionSelector;
