import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { Form, FormDialog } from 'components/forms';
import { fields, options } from 'forms/config/dashboardItemDetails';
import DashboardItemForm from 'views/Dashboards/DashboardItem/DashboardItemForm';

@Form({ fields, options })
@inject('$dashboards')
@observer
class DashboardPanelFormDialog extends Component {
  componentDidMount() {
    const { model } = this.props;

    if (model && !model.fetchedQuery) {
      model.fetchSavedQuery();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen, model } = nextProps;
    const { isOpen: isCurrentlyOpen } = this.props;

    if (model && isOpen && !isCurrentlyOpen) {
      model.fetchSavedQuery();
    }
  }

  handleSubmit = (form, values) => {
    const { history, model, $dashboards, dataview } = this.props;

    return dataview.queryBuckets
      .save(true)
      .then(hash => model.save(Object.assign(values, { saved_query_id: hash }), { toast: false }))
      .then(({ dashboard_id }) => {
        $dashboards.getDashboard(dashboard_id).select();
        history.push(`/library/dashboard/${dashboard_id}`);
      });
  };

  render() {
    const { model, $dashboards, onCancel } = this.props;

    if (!model) {
      return null;
    }

    const dashboard = $dashboards.getDashboard(model.get('dashboard_id'));
    const { isNew } = model;

    return (
      <FormDialog
        {...this.props}
        onClose={onCancel}
        title={`${isNew ? 'Add' : 'Edit'} Data Explorer View Panel`}
        entityName="Panel"
        style={{ width: 680 }}
        bodyStyle={{
          padding: '24px 24px 8px'
        }}
        formActionsProps={{ showRemove: false, onSubmit: this.handleSubmit, onCancel }}
        formComponent={
          <DashboardItemForm
            {...this.props}
            dashboard={dashboard}
            showItemProperties={false}
            showDataviewConfiguration={false}
            p={0}
          />
        }
      />
    );
  }
}

export default withRouter(DashboardPanelFormDialog);
