import { Address4, Address6 } from 'ip-address';

function fn(value) {
  if (value) {
    const v4 = new Address4(value);
    const v6 = new Address6(value);
    const portStripped = value.substr(0, value.lastIndexOf(':'));
    const v4NoPort = new Address4(portStripped);
    const v6NoPort = new Address6(portStripped);
    return v4.isValid() || v6.isValid() || v4NoPort.isValid() || v6NoPort.isValid();
  }

  return true;
}

export default {
  message: 'Enter a valid IPv4 or IPv6 address. Port and/or CIDR/netmask is supported.',
  fn
};
