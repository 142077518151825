import React from 'react';
import { observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';

import SelectedValuesPanel from './SelectedValuesPanel';

const ModalSelectValuesPanel = props => {
  const { field, reorderable, valuesPanelWidth, selectState } = props;

  // We need to wrap the tools, but only if they actually yield tools
  let bottomTools;
  if (props.bottomTools) {
    bottomTools = props.bottomTools(selectState.filterValue);
  }

  return (
    <Flex
      flexAuto
      flexColumn
      p={0.25}
      style={{ width: valuesPanelWidth, minWidth: valuesPanelWidth, overflow: 'hidden' }}
    >
      <SelectedValuesPanel
        {...props}
        values={selectState.values}
        options={selectState.options}
        reorderable={field.reorderable || reorderable}
      />
      {bottomTools && <Box mt={2}>{bottomTools}</Box>}
    </Flex>
  );
};

export default observer(ModalSelectValuesPanel);
