import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import { Box, Flex } from 'components/flexbox';
import { Page, PageBody } from 'components/page';

import PasswordSetRequestForm from './PasswordSetRequestForm';
import PasswordSetForm from './PasswordSetForm';

@inject('$auth')
@observer
class Password extends Component {
  getSearchParam(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(this.props.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  render() {
    const passwordSetToken = this.getSearchParam('token');
    const { accountActivation } = this.props;

    // Check for invalid state, somebody is manually messing with urls, send them to login and be done with it.
    if (accountActivation && !passwordSetToken) {
      return (
        <Redirect
          to={{
            pathname: '/login'
          }}
        />
      );
    }

    return (
      <Page title="Password">
        <PageBody>
          <Flex flexColumn align="center" col={12} p={4}>
            {!accountActivation && (
              <Box col={4} pb={1}>
                <h4>Password Reset</h4>
              </Box>
            )}
            {accountActivation && (
              <Box col={4} pb={1}>
                <h4>Account Activation</h4>
                <p>Please set a password to activate your Kentik account</p>
              </Box>
            )}
            <Box col={4} pb={1}>
              {!passwordSetToken && <PasswordSetRequestForm />}
              {passwordSetToken && (
                <PasswordSetForm passwordSetToken={passwordSetToken} accountActivation={accountActivation} />
              )}
            </Box>
          </Flex>
        </PageBody>
      </Page>
    );
  }
}

export default Password;
