import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { deepClone } from 'util/utils';
import CopyToClipboardDialog from 'components/CopyToClipboardDialog';

@inject('$dictionary')
@observer
export default class ShowApiCallDialog extends Component {
  convertQueriesForAPI(queries, type) {
    return queries.map(query => {
      // hacky deep clone here
      const res = deepClone(query);
      const q = res.query;
      q.dimension = q.metric;
      q.metric = q.units;
      delete q.units;
      q.update_frequency = 0;
      delete q.host_selected;
      delete q.filter_string;
      delete q.isEditing;
      delete q.bucket;
      delete q.bucketIndex;
      delete q.time_format;
      if (q.filters_obj) {
        delete q.filters_obj.custom;
        delete q.filters_obj.filterString;
        if (q.filters_obj.filterGroups && q.filters_obj.filterGroups.length) {
          q.filters_obj.filterGroups.forEach(filterGroup => {
            delete filterGroup.filterString;
            delete filterGroup.id;
            delete filterGroup.metric;
            if (filterGroup.filters && filterGroup.filters.length) {
              filterGroup.filters.forEach(filter => {
                delete filter.id;
              });
            }
          });
        }
      }
      if (q.aggregates) {
        q.aggregates.forEach(agg => {
          delete agg.properName;
          delete agg.sortable;
        });
      }
      if (type === 'topxdata') {
        delete res.isOverlay;
        delete q.viz_type;
        delete q.show_overlay;
        delete q.overlay_day;
        delete q.sync_axes;
      }
      return res;
    });
  }

  getCurlCommand(queries, type) {
    const data = { queries: this.convertQueriesForAPI(queries, type) };
    if (type === 'topxchart') {
      data.imageType = '<CHOOSE ONE OF:pdf|png>';
    }

    return `curl -v -H 'X-CH-Auth-Email:<YOUR_EMAIL_HERE>' -H 'X-CH-Auth-API-Token:<YOUR_API_TOKEN_HERE>' -H 'Content-Type: application/json' -d '${JSON.stringify(
      data
    )}' ${this.props.$dictionary.dictionary.baseAPIUrl}/query/${type}`;
  }

  render() {
    const {
      dataview,
      showChartApiCurlDialogOpen,
      showDataApiCurlDialogOpen,
      showApiJsonDialogOpen,
      onClose
    } = this.props;
    const serializedQueries = dataview.queryBuckets.serialize();

    if (showChartApiCurlDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Chart API Call via cURL"
          isOpen={showChartApiCurlDialogOpen}
          onClose={onClose}
          value={this.getCurlCommand(serializedQueries, 'topxchart')}
          dialogHeight={500}
        />
      );
    }

    if (showDataApiCurlDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Data API Call via cURL"
          isOpen={showDataApiCurlDialogOpen}
          onClose={onClose}
          value={this.getCurlCommand(serializedQueries, 'topxdata')}
          dialogHeight={500}
        />
      );
    }

    if (showApiJsonDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Data API JSON Input"
          isOpen={showApiJsonDialogOpen}
          onClose={onClose}
          value={JSON.stringify({ queries: this.convertQueriesForAPI(serializedQueries) }, null, 2)}
          dialogHeight={600}
        />
      );
    }

    return null;
  }
}
