import { toJS } from 'mobx';
import { isEqual } from 'lodash';

export function getValues(field) {
  return field.getValue() || [];
}

export function getFieldValues({ field }) {
  let values = getValues(field);

  if (!Array.isArray(values)) {
    values = [values];
  }

  return values;
}

export function getFieldSelectOptions({ field, options }) {
  return field.options || options ? toJS(field.options || options) : [];
}

export function getOptionsWithNone(options) {
  return [{ value: '', label: 'None' }].concat(options);
}

export function getClassNameFromGroup(group) {
  return group.toLowerCase().replace(/[\s+]/g, '-');
}

export function getOption(options, value, parentGroup) {
  if (Array.isArray(options)) {
    let matchingOption = null;

    options.some(option => {
      /* eslint eqeqeq: 0 */
      if (option.value === undefined) {
        matchingOption = getOption(option, value);
      } else if (option.value == value || isEqual(option.value, value)) {
        matchingOption = option;
      }

      return !!matchingOption;
    });

    return matchingOption;
  }

  let matchingOption = null;
  Object.keys(options).some(groupName => {
    const fullGroupName = parentGroup ? `${parentGroup} ${groupName}` : groupName;
    matchingOption = getOption(options[groupName], value, fullGroupName);

    if (matchingOption) {
      matchingOption = {
        group: fullGroupName, // Note: needs to be like this recurse properly
        className: getClassNameFromGroup(groupName),
        ...matchingOption
      };
    }

    return !!matchingOption;
  });

  return matchingOption;
}

export function getFilteredOptions(value, options) {
  const filterRegexp = new RegExp(value, 'i');

  if (Array.isArray(options)) {
    return options.filter(option => (option.label || option.value).match(filterRegexp));
  }

  const filteredOptions = {};
  Object.keys(options).forEach(groupName => {
    let groupOptions = options[groupName];

    if (!groupName.match(filterRegexp)) {
      if (Array.isArray(groupOptions)) {
        groupOptions = groupOptions.map(group => getFilteredOptions(value, group));
        if (groupOptions.every(group => Object.keys(group).length === 0)) {
          groupOptions = [];
        }
      } else {
        groupOptions = getFilteredOptions(value, groupOptions);
      }
    }

    if (
      (Array.isArray(groupOptions) && groupOptions.length > 0) ||
      (!Array.isArray(groupOptions) && Object.keys(groupOptions).length > 0)
    ) {
      filteredOptions[groupName] = groupOptions;
    }
  });

  return filteredOptions;
}

export function getFilteredOptionsLegacy(value, options) {
  const filterRegexp = new RegExp(value, 'i');

  if (Array.isArray(options)) {
    return options.filter(option => (option.label || option.value).match(filterRegexp));
  }

  const filteredOptions = {};
  Object.keys(options).forEach(groupName => {
    let groupOptions = options[groupName];

    if (!groupName.match(filterRegexp)) {
      groupOptions = getFilteredOptionsLegacy(value, groupOptions);
    }

    if (
      (Array.isArray(groupOptions) && groupOptions.length > 0) ||
      (!Array.isArray(groupOptions) && Object.keys(groupOptions).length > 0)
    ) {
      filteredOptions[groupName] = groupOptions;
    }
  });

  return filteredOptions;
}
