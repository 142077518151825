import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { safelyParseJSON } from 'util/utils';
import { Box } from 'components/flexbox';
import Transition from 'react-transition-group/Transition';

const defaultStyle = {
  transition: `opacity ${300}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 }
};

@inject('$dictionary')
@observer
class DismissibleCallout extends Component {
  static defaultProps = {
    isOpen: true
  };

  state = {
    isOpen: true
  };

  get localStorageKey() {
    const { name, $dictionary } = this.props;
    return `kentik_${$dictionary.dictionary.envName}_${name}_hidden`;
  }

  componentWillMount() {
    const isOpen = !safelyParseJSON(localStorage[this.localStorageKey]);
    this.setState({ isOpen });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
    localStorage.setItem(this.localStorageKey, true);
  };

  render() {
    const { children, className, ...rest } = this.props;
    const { isOpen } = this.state;

    return (
      <Transition in={isOpen} timeout={250} unmountOnExit>
        {state => {
          const styles = {
            ...defaultStyle,
            ...transitionStyles[state]
          };

          return (
            <Box className={className} {...rest} style={styles}>
              {children({ handleClose: this.handleClose })}
            </Box>
          );
        }}
      </Transition>
    );
  }
}

export default DismissibleCallout;
