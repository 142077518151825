import React from 'react';
import { observer } from 'mobx-react';
import AlertValueCell from '../../Table/AlertValueCell';
import KeyDimensionCell from '../../Table/KeyDimensionCell';

const MatchesByTimeRow = props => {
  const { values, onFilterFieldClick } = props;

  return (
    <tr>
      <td>
        <KeyDimensionCell values={values} onFilterFieldClick={onFilterFieldClick} />
      </td>
      <td>{parseInt(values.current_position, 10) + 1}</td>
      <td>
        <AlertValueCell values={values} />
      </td>
    </tr>
  );
};

export default observer(MatchesByTimeRow);
