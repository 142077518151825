import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Intent, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import ConfirmDialogModel from 'models/ConfirmDialogModel';
import { Popover2 } from '@blueprintjs/labs';
import classNames from 'classnames';

import { Box } from 'components/flexbox';
import Icon from 'components/Icon';
import { renderMenuLink } from 'views/nav/utils';

import CategoryHeading from './CategoryHeading';

const featuredIcon = <Icon name="endorsed" className="pt-intent-warning secondary-icon" />;
const dependencyIcon = <Icon name="warning-sign" className="pt-intent-danger secondary-icon" />;

@inject('$auth', '$library', '$app')
@observer
class ReportMenuItem extends Component {
  static defaultProps = {
    showCategory: false,
    highlightFeatured: true
  };

  state = {
    contextMenuOpen: false
  };

  componentWillMount() {
    const { report } = this.props;
    this.confirmDialog = new ConfirmDialogModel({ model: report, onConfirm: this.handleDeleteReport });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scrolling === true && this.props.scrolling === false) {
      this.setState({ contextMenuOpen: false });
    }
  }

  handleSelect = () => {
    const { report, onSelect } = this.props;
    onSelect(report);
    this.setState({ contextMenuOpen: false });
  };

  handleEdit = () => {
    const { report, onSelect } = this.props;
    onSelect(report, { isEditing: true });
    this.setState({ contextMenuOpen: false });
  };

  handleShowProperties = () => {
    const { report, onShowProperties } = this.props;
    onShowProperties(report);
    this.setState({ contextMenuOpen: false });
  };

  handleDeleteReport = () => {
    const { report, history } = this.props;

    // if you delete a report which you have currently active, just redirect back to home.
    if (report.isSelected) {
      history.push('/library');
    }

    report.collection.removeReport(report);
    this.setState({ contextMenuOpen: false });
  };

  handleCloneReport = async () => {
    const { history, report, onSelect } = this.props;
    report.duplicate().then(clone => {
      this.setState({ contextMenuOpen: false });

      if (report.get('type') === 'dashboard') {
        onSelect(clone, { isEditing: true, isEditingProperties: true });
      } else {
        clone.select();
        history.push(`/savedView/${clone.id}`);
      }
    });
  };

  handleFavoriteReport = () => {
    const { $library, report } = this.props;
    $library.toggleReportFavorite(report);
    this.setState({ contextMenuOpen: false });
  };

  handleFeatureReport = () => {
    const { $library, report } = this.props;
    $library.toggleReportFeatured(report);
    this.setState({ contextMenuOpen: false });
  };

  handleToggleContextMenu = e => {
    e.stopPropagation();
    this.setState({ contextMenuOpen: !this.state.contextMenuOpen });
  };

  handleInteraction = contextMenuOpen => {
    this.setState({ contextMenuOpen });
  };

  render() {
    const { contextMenuOpen, cloningReport } = this.state;
    const {
      $auth,
      $app: { isSubtenant },
      report,
      selected,
      showCategory,
      highlightFeatured,
      history
    } = this.props;

    const type = report.get('type');
    const favorite = report.get('favorite');
    const featured = report.get('featured');
    const loading = report.get('loading');

    const { canEdit, iconName } = report;

    const className = classNames('report-menu-item', {
      'pt-active': loading || selected || contextMenuOpen
    });

    let text = report.name;

    if (featured && highlightFeatured) {
      text = (
        <span>
          {featuredIcon}
          {report.name}
        </span>
      );
    }

    if (report.hasDependencyFailures) {
      text = (
        <span>
          {dependencyIcon}
          {report.name}
        </span>
      );
    }

    const category = report.get('category');
    let label;
    if (category && showCategory) {
      label = (
        <span className="small-caps pt-text-muted" style={{ fontSize: 11 }}>
          {category.name}
        </span>
      );
    }

    if (!isSubtenant && !showCategory) {
      label = (
        <Popover2
          isOpen={contextMenuOpen}
          onInteraction={this.handleInteraction}
          modifiers={{
            arrow: { enabled: true },
            flip: { enabled: false },
            keepTogether: { enabled: true },
            preventOverflow: { enabled: true, boundariesElement: 'window' }
          }}
          placement="right-start"
          content={
            <Box style={{ maxWidth: 375 }}>
              <Box px={1.5} pt={1}>
                <CategoryHeading item={report} />
                <h6>{report.name}</h6>
                {report.description && <div className="pt-text-muted">{report.description}</div>}
              </Box>
              <Menu>
                <MenuItem iconName="document-open" onClick={this.handleSelect} text="Open" className="pt-strong" />
                {type === 'report' &&
                  renderMenuLink(
                    { disabled: !canEdit, iconName: 'edit', text: 'Edit', to: `/admin/queryTemplates/${report.id}` },
                    history
                  )}
                {type === 'dashboard' && (
                  <MenuItem disabled={!canEdit} iconName="edit" text="Edit" onClick={this.handleEdit} />
                )}
                {type === 'savedView' &&
                  renderMenuLink(
                    { disabled: !canEdit, iconName: 'edit', text: 'Edit', to: `/savedView/${report.id}` },
                    history
                  )}
                {(type === 'dashboard' || type === 'savedView') &&
                  canEdit && <MenuItem iconName="cog" text="Properties" onClick={this.handleShowProperties} />}
                <MenuDivider />
                <MenuItem
                  iconName={favorite ? 'star-empty' : 'star'}
                  text={favorite ? 'Remove from Favorites' : 'Add to Favorites'}
                  onClick={this.handleFavoriteReport}
                />
                {($auth.hasSudo || $auth.isSpoofed) &&
                  type !== 'savedView' && (
                    <MenuItem
                      iconName="endorsed"
                      text={featured ? 'Remove from Featured' : 'Add to Featured'}
                      onClick={this.handleFeatureReport}
                    />
                  )}
                {type !== 'report' && (
                  <MenuItem
                    iconName="duplicate"
                    disabled={cloningReport}
                    text="Clone"
                    onClick={this.handleCloneReport}
                  />
                )}
                <MenuItem
                  intent={Intent.DANGER}
                  iconName="trash"
                  text="Delete"
                  disabled={!canEdit}
                  onClick={this.confirmDialog.showConfirmDialog}
                />
              </Menu>
            </Box>
          }
        >
          <Button className="pt-small pt-minimal context-menu" onClick={this.handleToggleContextMenu} iconName="more" />
        </Popover2>
      );
    }

    text = <span title={report.name}>{text}</span>;

    return (
      <Fragment>
        <MenuItem className={className} iconName={iconName} onClick={this.handleSelect} text={text} label={label} />
        {this.confirmDialog.component}
      </Fragment>
    );
  }
}

export default withRouter(ReportMenuItem);
