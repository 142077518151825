import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$savedFilters')
class SavedFilters extends Component {
  title = 'Saved Filter';

  url = '/admin/savedFilters';

  render() {
    const { result, $savedFilters } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="material-icons:filter_list"
        url={this.url}
        nameField="filter_name"
        collection={$savedFilters.collection}
        {...this.props}
      >
        <span>type: {result.filter_description}</span>
      </ResultRow>
    );
  }
}

export default SavedFilters;
