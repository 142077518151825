import { computed, action } from 'mobx';
import { omit, uniqueId } from 'lodash';

import $alerts from 'stores/$alerts';
import $dashboards from 'stores/$dashboards';
import $savedViews from 'stores/$savedViews';
import BaseModel from 'models/BaseModel';

import { normalizeQueries } from 'services/urlHash';
import { getSavedFilters } from '../../services/filters';

class SavedFilter extends BaseModel {
  get defaults() {
    return {
      filter_level: 'company',
      filters: {
        connector: 'All',
        filterGroups: [
          {
            not: false,
            filters: [{}]
          }
        ]
      }
    };
  }

  get urlRoot() {
    return '/api/portal/saved-filters';
  }

  get omitDuringSerialize() {
    return ['isFiltersPopoverOpen'];
  }

  @action
  duplicate = () => {
    const attributes = omit(this.attributes.toJS(), 'id');

    // duplicate titles are not allowed for Dashboards
    attributes.filter_name = `${this.get('filter_name')} ${uniqueId()}`;
    attributes.filter_level = 'company';
    const dupe = this.collection.build(attributes);
    dupe.save(attributes);
  };

  @action
  handleToggleFiltersPopover = () => this.set({ isFiltersPopoverOpen: !this.get('isFiltersPopoverOpen') });

  @action
  handlePopoverInteraction = state => this.set({ isFiltersPopoverOpen: state });

  /**
   * Returns the total number of filters in all FilterGroups
   */
  @computed
  get numFilters() {
    const groups = this.get('filters') && this.get('filters').filterGroups;
    return groups && groups.reduce((acc, curr) => acc + curr.filters.length, 0);
  }

  @computed
  get numGroups() {
    const groups = this.get('filters') && this.get('filters').filterGroups;
    return groups.length;
  }

  @computed
  get isPreset() {
    return this.get('filter_level') === 'global';
  }

  @computed
  get inUse() {
    return this.policies.length > 0 || this.dashboards.length > 0 || this.savedViews.length > 0;
  }

  @computed
  get policies() {
    return $alerts.myPolicies.models.reduce((prev, curr) => {
      const inUse = getSavedFilters(curr.get('filters')).filter(filter => filter.filter_id === this.id).length > 0;

      if (inUse) {
        return [...prev, { id: curr.get('id'), name: curr.get('policy_name') }];
      }

      return prev;
    }, []);
  }

  @computed
  get dashboards() {
    return $dashboards.dashboards.reduce((acc, dashboard) => {
      const query = dashboard.get('query');
      const inUse = getSavedFilters(query.filters).some(filter => filter.filter_id === this.id);

      if (inUse) {
        const id = dashboard.get('id');
        const dash_title = dashboard.get('dash_title');
        return acc.concat({ id, name: dash_title });
      }

      return acc;
    }, []);
  }

  @computed
  get savedViews() {
    return $savedViews.savedViews.reduce((acc, curr) => {
      const view = curr.toJS();
      const query = normalizeQueries(view.query);
      const inUse = query.some(
        q => q.query && getSavedFilters(q.query.filters_obj).some(filter => filter.filter_id === this.id)
      );

      if (inUse) {
        return acc.concat({ id: view.id, name: view.view_name });
      }

      return acc;
    }, []);
  }

  get messages() {
    return {
      create: `Filter ${this.get('filter_name')} was added successfully`,
      update: `Filter ${this.get('filter_name')} was updated successfully`,
      destroy: `Filter ${this.get('filter_name')} was removed successfully`,
      duplicate: 'Filter duplicated successfully'
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Saved Filter',
      text: `Are you sure you want to remove ${this.get('filter_name')}?`
    };
  }
}

export default SavedFilter;
