import moment from 'moment';
import { action, computed } from 'mobx';
import { translateFromUTCForUser } from 'util/utils';
import { Intent } from '@blueprintjs/core';

import BaseModel from '../BaseModel';

const criticalityMap = {
  10: { label: 'Info', intent: Intent.PRIMARY },
  20: { label: 'Minor', intent: Intent.WARNING },
  30: { label: 'Major', intent: Intent.DANGER },
  40: { label: 'Critical', intent: Intent.DANGER }
};

const stateMap = {
  100: 'CLEAR',
  200: 'IN_ALARM',
  300: 'ACK_REQ'
};

class SqlAlert extends BaseModel {
  get defaults() {
    return {
      alert_level: 30,
      alert_lookback: 60,
      alert_num_bad: 5,
      alert_num_good: 15,
      alert_status: 'S',
      alert_window: '7200',
      suppl_query_recall_lookback: 60
    };
  }

  @action
  toggleEnabled = e => {
    e.stopPropagation();
    const status = this.get('alert_status');
    this.save({
      alert_status: status === 'V' ? 'D' : 'V'
    });
  };

  @computed
  get percentTimeOverThreshold() {
    // calculate percent of time over treshold

    const { alert_start, alert_end, bad_instances_since_alarm_start } = this.get();

    const alert_poll_period = 60;
    const _start_time = translateFromUTCForUser(moment.utc(alert_start));
    const _end_time =
      this.alertState === 'IN_ALARM'
        ? translateFromUTCForUser(moment.utc())
        : translateFromUTCForUser(moment.utc(alert_end));
    const _duration =
      translateFromUTCForUser(moment(_end_time)).unix() - translateFromUTCForUser(moment(_start_time)).unix();
    let percent_of_time_over_treshold = parseInt(
      ((bad_instances_since_alarm_start * alert_poll_period) / _duration) * 100
    );
    if (percent_of_time_over_treshold > 100) {
      percent_of_time_over_treshold = 100;
    }
    return percent_of_time_over_treshold;
  }

  @computed
  get out1() {
    const { Out1Name, Out1Value } = this.get();
    return { name: Out1Name, value: Out1Value };
  }

  @computed
  get out2() {
    const { Out2Name, Out2Value } = this.get();
    return { name: Out2Name, value: Out2Value };
  }

  @computed
  get alertState() {
    const state = this.get('alert_key_state');
    return state === 300 ? 'ACK REQUIRED' : 'IN_ALARM';
  }

  @computed
  get alertEnabled() {
    const status = this.get('alert_status');
    if (status === 'V' || status === 'on') {
      return true;
    }
    return false;
  }

  @computed
  get oldAlertState() {
    const state = this.get('old_state');
    return stateMap[state];
  }

  @computed
  get newAlertState() {
    const state = this.get('new_state');
    return stateMap[state];
  }

  @computed
  get criticality() {
    return criticalityMap[this.get('alert_level')];
  }

  @computed
  get alertWindow() {
    let alert_window_string;

    switch (this.get('alert_window')) {
      case 600:
        alert_window_string = '10 Minutes';
        break;
      case 1800:
        alert_window_string = '30 Minutes';
        break;
      case 3600:
        alert_window_string = '1 Hour';
        break;
      case 7200:
        alert_window_string = '2 Hours';
        break;
      case 14400:
        alert_window_string = '4 Hours';
        break;
      case 28800:
        alert_window_string = '8 Hours';
        break;
      case 86400:
        alert_window_string = '24 Hours';
        break;
      default:
        alert_window_string = 'Not Set';
    }
    return alert_window_string;
  }

  get messages() {
    return {
      create: `Alert ${this.get('alert_name')} was added successfully`,
      update: `Alert ${this.get('alert_name')} was updated successfully`,
      destroy: `Alert ${this.get('alert_name')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Alert',
      text: `Are you sure you want to remove ${this.get('alert_name')}?`
    };
  }
}

export default SqlAlert;
