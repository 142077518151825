import Collection from 'models/Collection';
import AddOn from './AddOn';

class AddOnCollection extends Collection {
  get urlPaths() {
    return {
      fetch: '/api/portal/companysettings/addons'
    };
  }

  get model() {
    return AddOn;
  }
}

export default AddOnCollection;
