import React from 'react';
import { computed, action, toJS } from 'mobx';
import { orderBy } from 'lodash';

import { ParametricTag, ShareLevelBadge } from 'components';
import { Flex } from 'components/flexbox';
import DashboardCollection from 'models/dashboards/DashboardCollection';
import { getModelFilters } from 'services/filters';
import { nestFilterGroup, deepClone } from 'util/utils';

class DashboardsStore {
  getSourcePanelQuery = firstQuery => {
    const [
      all_devices,
      device_name,
      device_sites,
      device_labels,
      device_types,
      lookback_seconds,
      starting_time,
      ending_time,
      time_format,
      filters
    ] = firstQuery.get([
      'all_devices',
      'device_name',
      'device_sites',
      'device_labels',
      'device_types',
      'lookback_seconds',
      'starting_time',
      'ending_time',
      'time_format',
      'filters'
    ]);

    return {
      all_devices,
      device_name,
      device_sites,
      device_labels,
      device_types,
      lookback_seconds,
      starting_time,
      ending_time,
      time_format,
      filters
    };
  };

  getDashboardNavigationHistory = (currentDashboard, currentDashboardItem, destinationDashboard) => {
    const {
      location: { state }
    } = this.history;
    const { selectedModels } = currentDashboardItem;
    const destinationDashboardHistory = {
      id: destinationDashboard.id,
      parentPanelId: currentDashboardItem.id,
      parentDashboardId: currentDashboard.id,
      selectedModels: selectedModels.map(model => model.get('key')),
      parentPanelQuery: toJS(currentDashboardItem.lastFullQuery)
    };

    if (state) {
      const { dashboardNavigationHistory } = state;
      if (
        dashboardNavigationHistory &&
        dashboardNavigationHistory[dashboardNavigationHistory.length - 1].id === currentDashboard.id
      ) {
        return [...dashboardNavigationHistory, destinationDashboardHistory];
      }
    }

    return [{ id: currentDashboard.id }, destinationDashboardHistory];
  };

  @action
  navigateToNestedDashboard = (currentDashboard, currentDashboardItem) => {
    let destinationDashboardId = currentDashboardItem.get('destination_dashboard');

    if (this.store.$auth.isDan && Math.random() < 0.1) {
      const randIndex = Math.floor(Math.random() * this.collection.size) + 1;
      destinationDashboardId = this.collection.models.get(randIndex - 1).get('id');
    }

    const destinationDashboard = this.collection.get(destinationDashboardId);
    if (!destinationDashboard) {
      return;
    }

    const currentQuery = currentDashboard.get('query');
    const itemQuery = currentDashboardItem.queryBuckets[0].firstQuery;

    const parametric = currentDashboardItem.get('dashboard_navigation_options.parametric');
    const deviceRule = currentDashboardItem.get('dashboard_navigation_options.devices');
    const timeRangeRule = currentDashboardItem.get('dashboard_navigation_options.time_range');
    const filtersRule = currentDashboardItem.get('dashboard_navigation_options.filters');

    const parametric_fields = destinationDashboard.get('parametric_fields');

    if (destinationDashboard.get('parametric')) {
      if (parametric === 'applyFrom') {
        destinationDashboard.set({ parametric_fields: currentDashboard.get('parametric_fields') });
      } else if (parametric === 'drillDown') {
        const { selectedModels } = currentDashboardItem;
        const [selectedModel] = selectedModels;
        const [field] = parametric_fields;
        const value = selectedModel && currentDashboardItem.getParametricKeyFromModel(selectedModel, field);

        if (value) {
          destinationDashboard.set({
            parametric_fields: [Object.assign({}, field, { value })]
          });
        } else {
          this.store.$dashboard.setCompletedInitialParametricSubmit(false);
          parametric_fields[0].value = undefined;
        }
      } else if (parametric === 'prompt' && parametric_fields && parametric_fields.length) {
        parametric_fields[0].value = undefined;
      }
    }

    const querySource = {
      source_dashboard: currentQuery,
      destination_dashboard: destinationDashboard.originalQuery,
      source_panel: this.getSourcePanelQuery(itemQuery),
      source_destination: currentQuery,
      custom: currentDashboardItem.get('dashboard_navigation_options.custom')
    };

    const { all_devices, device_name, device_types, device_labels, device_sites } = querySource[deviceRule];
    const { lookback_seconds, starting_time, ending_time, time_format } = querySource[timeRangeRule];
    const { filters, filters_obj } = querySource[filtersRule];

    let filtersObj = filters || filters_obj;

    if (filtersRule === 'source_destination') {
      filtersObj = deepClone(querySource.destination_dashboard.filters);

      if (filtersObj.connector === 'Any') {
        nestFilterGroup(filtersObj);
      }
      filtersObj.filterGroups.push(...currentQuery.filters.filterGroups);
    }

    if (!destinationDashboard.get('parametric')) {
      const bucket = currentDashboardItem.queryBuckets[0];
      currentDashboardItem.selectedModels.reduce(
        (filtersAcc, model) =>
          getModelFilters({
            model,
            bucket,
            filtersAcc
          }),
        filtersObj
      );
    }

    const queryOverride = {
      all_devices,
      device_name,
      device_types,
      device_sites,
      device_labels,
      lookback_seconds,
      starting_time,
      ending_time,
      time_format,
      filters_obj: filtersObj
    };

    this.navigateToDashboard(
      destinationDashboard,
      parametric,
      this.getDashboardNavigationHistory(currentDashboard, currentDashboardItem, destinationDashboard),
      queryOverride
    );
  };

  @action
  navigateToDashboard = (dashboard, isParametric, dashboardNavigationHistory, overrides) => {
    this.history.push(`/library/dashboard/${dashboard.id}`, {
      completedInitialParametricSubmit: isParametric,
      initialParametricFields: dashboard.get('parametric_fields'),
      dashboardNavigationHistory,
      ...overrides
    });

    this.store.$recentlyViewed.add('report', dashboard);
  };

  @computed
  get parametricDashboards() {
    const dashboards = this.dashboards.filter(model => model.get('parametric'));
    return orderBy(dashboards, ['shareLevel', 'desc']);
  }

  @computed
  get nonParametricDashboards() {
    const dashboards = this.dashboards.filter(model => !model.get('parametric'));
    return orderBy(dashboards, ['shareLevel', 'desc']);
  }

  @computed
  get groupedDashboards() {
    const models = this.dashboards;

    return (
      models && {
        self: models.filter(model => model.isUserLevel),
        company: models.filter(model => model.isCompanyLevel),
        preset: models.filter(model => model.isPreset)
      }
    );
  }

  @computed
  get dashboardOptions() {
    return this.getDashboardOptions(this.dashboards);
  }

  getDashboardOptions(dashboards) {
    return dashboards.map(dashboard => ({
      value: dashboard.id,
      label: (
        <Flex justify="space-between" align="center" flexAuto>
          {dashboard.get('dash_title')}
          <Flex ml={1}>
            {dashboard.get('parametric') && <ParametricTag />}
            <ShareLevelBadge item={dashboard} minimal />
          </Flex>
        </Flex>
      ),
      filterLabel: dashboard.get('dash_title')
    }));
  }

  @computed
  get dashboards() {
    return this.store.$library.reportsCollection.models.filter(model => model.get('type') === 'dashboard');
  }

  @computed
  get collection() {
    return new DashboardCollection(null, { models: this.dashboards });
  }

  getDashboard(id) {
    /* eslint-disable eqeqeq */
    return this.dashboards.find(dashboard => dashboard.id == id);
  }
}

export default new DashboardsStore();
