import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { dateTimeFormatter } from 'util/dateUtils';
import MatchesByTimeTable from './MatchesByTimeTable';
import BaselineByTimeTable from './BaselineByTimeTable';

@inject('$alerts')
@observer
class AlertDebugByTime extends Component {
  render() {
    const { debugDataByTime } = this.props.$alerts;
    const { alert_id, policy_name, ctime } = debugDataByTime;
    const formattedTime = dateTimeFormatter({ value: ctime });

    return (
      <div>
        <h4>{`Current data for ${policy_name} (${alert_id}) at ${formattedTime}`}</h4>
        <div>
          <MatchesByTimeTable debugDataByTime={debugDataByTime} {...this.props} />
        </div>
        <br />
        <h4>{`Baseline history of ${policy_name} (${alert_id}) at ${formattedTime}`}</h4>
        <div>
          <BaselineByTimeTable debugDataByTime={debugDataByTime} {...this.props} />
        </div>
      </div>
    );
  }
}

export default AlertDebugByTime;
