export function parseQueryString(search = window.location.search) {
  const str = search.charAt(0) === '?' ? search.slice(1) : search;
  if (str.length === 0) return {};

  return str
    .split('&')
    .map(item => item.split('=', 2))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: decodeURIComponent(value) }), {});
}

export function createQueryString(obj, addQuestionMark = true) {
  const str = Object.keys(obj)
    .map(key => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');

  return str.length > 0 && addQuestionMark ? `?${str}` : str;
}
