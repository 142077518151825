import { observable, action } from 'mobx';
import { showSuccessToast } from 'components/Toast';
import api from 'util/api';
import Collection from 'models/Collection';
import RawFlowQueryModel from 'models/analytics/RawFlowQueryModel';
import QueryModel from 'models/query/QueryModel';
import $dictionary, { flatten } from 'stores/$dictionary';
import { metricColumns, timeColumns } from 'views/Analytics/RawFlow/flowColumns';
import { getQueryForHash, getHashForQuery } from 'services/urlHash';

const fixedColumns = {
  ctimestamp: 250,
  row_number: 100,
  i_start_time: 165
};

class RawFlowStore {
  @observable
  isFetching = false;

  @observable
  isCanceling = false;

  @observable
  queryModel = new RawFlowQueryModel({ all_devices: true, lookback_seconds: 300 });

  @observable
  resultsCollection = new Collection();

  @observable
  columns = [];

  @observable
  formState;

  queryData = {};

  @action
  loadHash = async query => {
    const hash = await getHashForQuery(query);
    this.history.push(`/analytics/rawFlow/${hash}`);
  };

  @action
  loadForensicsHash(query) {
    getHashForQuery(query).then(hash => {
      this.setQueryData(query);
      this.history.push(`/analytics/rawFlow/forensics/${hash}`);
    });
  }

  @action
  loadDataExplorerHash = async hash => {
    const query = await getQueryForHash(hash);
    const queryModel = QueryModel.create(query);
    this.queryModel.set({ ...queryModel.get(), flow_fields: [...queryModel.get('metric'), 'in_bytes', 'out_bytes'] });
    const rawFlowQuery = this.queryModel.get();
    this.setQueryData(rawFlowQuery);
    this.fetchResults(rawFlowQuery);
    return rawFlowQuery;
  };

  @action
  loadQuery = async hash => {
    const query = await getQueryForHash(hash);
    this.setQueryData(query);
    this.fetchResults(query);
    return query;
  };

  @action
  async loadForensicsQuery(hash, options = {}) {
    const { fetchResults = true } = options;
    return getQueryForHash(hash).then(query => {
      this.setQueryData(query);
      if (fetchResults) {
        this.fetchResults(query, { url: '/api/portal/analytics/raw-flow-forensics' });
      }
      return query;
    });
  }

  @action
  registerFormState(form) {
    this.formState = form;
  }

  @action
  fetchResults(queryData, options = {}) {
    const { url = '/api/portal/analytics/raw-flow/' } = options;
    this.resultsCollection.reset();

    const data = Object.assign({}, queryData, {
      filters_obj: queryData.filters,
      devices: queryData.device_name
    });

    this.isFetching = true;

    const startRequestTime = Date.now();

    api.post(url, { data }).then(
      res => {
        this.queryServiceUnavailable = false;
        this.isFetching = false;
        this.resultsCollection.set(res.results);
        this.columns = this.getTableColumns(res.columns);
        this.sql = res.sql;
        this.matchedResults = res.matched;
        this.totalResults = res.count;
        this.partialResults = res.partial || false;
        this.queryResponseTime = res.time;
        this.lastResponseTime = Date.now() - startRequestTime;
        showSuccessToast('Raw Flow Query executed successfully');
      },
      err => {
        if (err === 'Service unavailable') {
          this.queryServiceUnavailable = true;
        } else {
          this.queryServiceUnavailable = false;
        }
        this.isFetching = false;
        this.resultsCollection.set([]);
        this.columns = [];
        this.totalResults = 0;
        this.matchedResults = 0;
        this.partialResults = false;
        this.queryResponseTime = 0;
        this.lastResponseTime = Date.now() - startRequestTime;

        console.warn('Raw Flow received SQL error', err);
      }
    );
  }

  @action
  cancelForensicsQuery() {
    this.isCanceling = true;
    api.post('/api/portal/analytics/raw-flow-forensics/cancel', {}).then(
      res => {
        this.queryServiceUnavailable = false;
        this.isCanceling = false;
        console.warn('Cancel response', res);
      },
      err => {
        this.isCanceling = false;
        console.warn('Cancel error', err);
      }
    );
  }

  @action
  sortResults = column => {
    const { columns, resultsCollection } = this;
    const { name, sortDir: currentSortDir } = column;
    const sortDir = currentSortDir === 'asc' ? 'desc' : 'asc';

    this.columns = columns.map(col => {
      if (col === column) {
        return {
          ...col,
          sorted: true,
          sortDir
        };
      }
      return {
        ...col,
        sorted: false
      };
    });

    resultsCollection.setSortState(name, sortDir);
    resultsCollection.sort(name);
  };

  @action
  openQuery(query) {
    this.queryModel.set({ ...query.get(), flow_fields: [...query.get('metric'), 'in_bytes', 'out_bytes'] });
  }

  @action
  refresh() {
    this.fetchResults(this.queryData);
  }

  @action
  refreshForensicsResults() {
    this.fetchResults(this.queryData, { url: '/api/portal/analytics/raw-flow-forensics' });
  }

  @action
  setQueryData(values) {
    this.queryData = values;
  }

  @action
  setDashboardQueryModel(selectedQuery) {
    const serialized = selectedQuery.toJS();

    // Take all the computed fields
    serialized.device_name = serialized.all_devices ? [] : serialized.device_name;
    serialized.device_labels = serialized.all_devices ? [] : serialized.device_labels;
    serialized.device_sites = serialized.all_devices ? [] : serialized.device_sites;
    serialized.device_types = serialized.all_devices ? [] : serialized.device_types;

    if (serialized.all_devices) {
      serialized.device_name = [];
    } else if (!Array.isArray(serialized.device_name)) {
      serialized.device_name = serialized.device_name.split(',');
    }

    if (serialized.filters_obj && !serialized.filters) {
      serialized.filters = serialized.filters_obj;
      delete serialized.filters_obj;
    }

    if (serialized.saved_filters) {
      serialized.saved_filters = serialized.saved_filters.map(filter => ({
        filter_id: filter.filter_id,
        is_not: filter.is_not || false
      }));
    }

    this.queryModel.set(serialized);
  }

  @action
  resetDashboardQueryModel() {
    this.queryModel = new RawFlowQueryModel();
  }

  @action
  getTableColumns(columns) {
    const columnOptions = flatten(Object.assign({}, timeColumns, metricColumns, $dictionary.filterFieldOptions));

    return columns.map(name => {
      let label = name;
      const option = columnOptions.find(opt => opt.value.toLowerCase() === name.toLowerCase());
      if (option) {
        label = `${option.group} ${option.label}`;
      }
      return {
        name,
        label,
        width: fixedColumns[name] || null,
        sortDir: 'desc',
        sorted: false
      };
    });
  }
}

export default new RawFlowStore();
