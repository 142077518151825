import React from 'react';
import { observer } from 'mobx-react';
import { Slider } from '@blueprintjs/core';

import { whitelistFieldProps } from 'forms/utils';

export default observer(props => {
  const { field, max, min = 0, defaultValue } = props;

  // Slider control cannot accept an empty string, we have to give it a default
  let { value } = field;
  if (value === '' || value === undefined || value === null) {
    value = defaultValue || defaultValue === 0 ? defaultValue : Math.round((max - min) / 2);
  }

  return <Slider {...field.getProps()} {...whitelistFieldProps(props)} value={value} />;
});
