import { observable, action } from 'mobx';
import api from 'util/api';

class SignupStore {
  @observable
  reqInProgress = false;

  @action
  createAccount = async params => {
    this.reqInProgress = true;

    // could just send params, but prefer to be specific
    const {
      user_email,
      first_name,
      last_name,
      company_name,
      job_title,
      phone,
      region,
      email_optin,
      accept_terms,
      recaptcha,
      Lead_Region__c
    } = params;

    const data = {
      user_email,
      first_name,
      last_name,
      company_name,
      job_title,
      phone,
      region,
      email_optin,
      accept_terms,
      Lead_Region__c,
      'g-recaptcha-response': recaptcha
    };
    return api.post('/api/portal/signup', { showErrorToast: false, data, rawResponse: true }).then(
      () => {
        this.reqInProgress = true;

        try {
          window.dataLayer.push({ event: 'freeTrialSignupSuccess' });
        } catch (e) {
          console.error('error pushing GTM', e);
        }

        return { success: true };
      },
      error => {
        const { status } = error;
        this.reqInProgress = false;
        const msg =
          status === 400 && error && error.body && error.body.error ? error.body.error : 'Unable to create account';
        return { success: false, msg };
      }
    );
  };
}

export default new SignupStore();
