import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';

@observer
class CancelButton extends Component {
  static defaultProps = {
    text: 'Cancel'
  };

  render() {
    const { model, onCancel, text } = this.props;

    const isCreating = model && model.isRequestActive('creating');
    const isSaving = model && model.isRequestActive('updating');

    return (
      <Button
        disabled={isSaving || isCreating}
        onClick={onCancel}
        style={{ minWidth: 110, marginRight: 8 }}
        text={text}
      />
    );
  }
}

export default CancelButton;
