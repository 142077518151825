import React, { Component } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Field, Select } from 'components/forms';
import { tetherOptions } from 'forms/config/deviceDetails';

@inject('$sites', '$plans')
@observer
class PlanSite extends Component {
  static contextTypes = {
    form: any
  };

  render() {
    const { helpText, $sites, $plans } = this.props;

    const { siteOptions } = $sites;
    const { availablePlanOptions } = $plans;

    return (
      <div>
        <Field
          name="plan.id"
          disabled={!availablePlanOptions.length}
          helpText={
            availablePlanOptions.length
              ? helpText.plan
              : 'There are no available device slots in your plans. Please contact sales@kentik.com to add more devices.'
          }
          options={availablePlanOptions}
          inputStyle={{ width: 400 }}
        >
          <Select menuWidth={400} tetherOptions={tetherOptions} />
        </Field>

        <Field name="site.id" helpText={helpText.site_id} options={siteOptions} inputStyle={{ width: 300 }}>
          <Select menuWidth={300} tetherOptions={tetherOptions} />
        </Field>
      </div>
    );
  }
}

export default PlanSite;
