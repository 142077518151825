import BaseModel from 'models/BaseModel';

const aclTypes = ['portal', 'agent', 'api', 'dbms'];

const dbFieldMap = {
  portal: 'remote_portal_access_allowed',
  agent: 'remote_agent_access_allowed',
  api: 'remote_api_access_allowed',
  dbms: 'remote_login_allowed'
};

export const modelControlTypeMap = {
  portal: 'acl_portal_control_type',
  agent: 'acl_agent_control_type',
  api: 'acl_api_control_type',
  dbms: 'acl_dbms_control_type'
};

export const modelExceptionsMap = {
  portal: 'acl_portal_exceptions',
  agent: 'acl_agent_exceptions',
  api: 'acl_api_exceptions',
  dbms: 'acl_dbms_exceptions'
};

const allowAllCidrs = ['0.0.0.0/0', '::/0'];

function getControlType(exceptions) {
  return exceptions.length > 0 && exceptions.every(exception => allowAllCidrs.includes(exception)) ? 'allow' : 'deny';
}

function toExceptionArray(exceptionString) {
  if (!exceptionString) {
    return [];
  }

  return exceptionString.split(',').map(exception => exception.trim());
}

export default class AccessControlModel extends BaseModel {
  get defaults() {
    return {
      acl_portal_control_type: 'allow',
      acl_portal_exceptions: '',
      acl_agent_control_type: 'allow',
      acl_agent_exceptions: '',
      acl_api_control_type: 'deny',
      acl_api_exceptions: '',
      acl_dbms_control_type: 'deny',
      acl_dbms_exceptions: ''
    };
  }

  static create(data) {
    const acls = AccessControlModel.fromPayload(data);

    return new AccessControlModel(acls);
  }

  static fromPayload(payload) {
    const result = {};

    aclTypes.forEach(aclType => {
      const exceptions = payload[dbFieldMap[aclType]];
      const controlType = getControlType(exceptions);

      result[modelControlTypeMap[aclType]] = controlType;
      result[modelExceptionsMap[aclType]] = controlType === 'deny' ? exceptions.join(', ') : '';
    });

    return result;
  }

  toPayload() {
    const result = {};

    aclTypes.forEach(aclType => {
      const controlType = this.get(modelControlTypeMap[aclType]);
      const exceptions = toExceptionArray(this.get(modelExceptionsMap[aclType]));

      result[dbFieldMap[aclType]] = controlType === 'deny' ? exceptions : allowAllCidrs;
    });

    return result;
  }
}
