import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$users')
class Users extends Component {
  title = 'User';

  url = '/admin/users';

  render() {
    const { result, $users } = this.props;
    return (
      <ResultRow
        type={this.title}
        icon="material-icons:group"
        url={this.url}
        nameField="user_full_name"
        collection={$users.collection}
        {...this.props}
      >
        <span>{result.user_email}</span>
      </ResultRow>
    );
  }
}

export default Users;
