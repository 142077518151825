import React from 'react';

import { Page, PageBody } from 'components/page';

const NotFound = () => (
  <Page constrainWidth={600} title="Not Found">
    <PageBody scrollable={false} bodyStyle={{ paddingTop: 16 }}>
      <h2>404</h2>
      <p className="pt-text-muted pt-monospace-text">
        The page you were looking for appears to have been moved, deleted or does not exist. Your options are nearly
        limitless, but do not include finding useful information at this URL.
      </p>
    </PageBody>
  </Page>
);

export default NotFound;
