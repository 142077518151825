import Collection from '../Collection';
import PlanHistory from './PlanHistory';

class PlanHistoryCollection extends Collection {
  constructor(data, options) {
    super(data);
    this.companyId = options.companyId;
    this.planId = options.planId;
  }

  get model() {
    return PlanHistory;
  }

  get jsonRoot() {
    return 'planHistory';
  }

  get url() {
    return `/api/sudo/companies/${this.companyId}/plans/history/${this.planId}`;
  }

  get defaultSortState() {
    return {
      field: 'cdate',
      direction: 'desc'
    };
  }
}

export default PlanHistoryCollection;
