import React from 'react';
import classNames from 'classnames';

const PageBody = props => {
  const { scrollable, onScroll, children } = props;

  const wrapperStyle = {
    height: '100%',
    width: '100%',
    ...props.wrapperStyle
  };

  const bodyStyle = {
    height: scrollable ? 'auto' : '100%',
    ...props.bodyStyle
  };

  const wrapperClassName = classNames('page-scroll-wrapper');
  const bodyClassName = classNames('page-body');

  return (
    <div className={wrapperClassName} style={wrapperStyle} onScroll={scrollable ? onScroll : undefined}>
      <div className={bodyClassName} style={bodyStyle}>
        {children}
      </div>
    </div>
  );
};

export default PageBody;
