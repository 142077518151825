import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Spinner, CollapsibleList, Classes, MenuItem } from '@blueprintjs/core';
import classNames from 'classnames';
import sizeMe from 'react-sizeme';

import { HelpLink } from 'components';
import { Flex, Box } from 'components/flexbox';

const sizeMeHOC = sizeMe({
  monitorHeight: false,
  monitorWidth: true,
  refreshRate: 32
});

/**
 * @param {string} title - The page title and/or the last item in the breadcrumb.
 * @param {array (optional)} parents - An array of objects with title, href keys to build the breadcrumb links.
 * @param children - Child components to add to the page header.
 */
const PageHeader = observer(props => {
  const {
    title,
    helpTopic,
    children,
    scrolled,
    subnavSection = true,
    subSection,
    parents = [],
    loading,
    loadingText,
    userNav,
    style,
    beta,
    size
  } = props;
  const className = classNames('page-header', 'pt-navbar', {
    'page-header-user-nav': userNav,
    'page-header-sub-section': subnavSection,
    'page-header-scrolled': scrolled
  });

  const breadcrumbLinks = parents.map(parent => <MenuItem text={parent.text} href={parent.href} key={parent.text} />);

  const renderBreadcrumb = breadcrumbProps => {
    if (breadcrumbProps.href) {
      return (
        <Link style={{ fontSize: 16, fontWeight: 500 }} to={breadcrumbProps.href}>
          <span>{breadcrumbProps.text}</span>
        </Link>
      );
    }

    return <span className={classNames(Classes.BREADCRUMB, Classes.BREADCRUMB_CURRENT)}>{breadcrumbProps.text}</span>;
  };

  return (
    <nav className={className}>
      <Flex className="page-header-contents" style={style}>
        <Flex align="center">
          <CollapsibleList
            className={Classes.BREADCRUMBS}
            dropdownTarget={<span className={Classes.BREADCRUMBS_COLLAPSED} />}
            renderVisibleItem={renderBreadcrumb}
            visibleItemCount={5}
          >
            {breadcrumbLinks}
            <MenuItem text={title} />
          </CollapsibleList>
          {helpTopic && <HelpLink helpTopic={helpTopic} buttonSource="nav" style={{ top: 0 }} />}
          {beta && (
            <span style={{ marginLeft: 6 }} className="pt-tag beta-tag">
              beta
            </span>
          )}
          {loading && (
            <Box ml={1}>
              <Spinner className="pt-small" />
            </Box>
          )}
          {loadingText && (
            <Box ml={1} className="pt-text-muted">
              {loadingText}
            </Box>
          )}
        </Flex>
        {children &&
          size.width >= 700 && (
            <Flex flexAuto justify="flex-end" align="flex-start">
              {children}
            </Flex>
          )}
      </Flex>
      {children &&
        size.width < 700 && (
          <Flex flexAuto mx={3} mt={0.5} mb={1}>
            {children}
          </Flex>
        )}
      {subSection && <div className="page-header-subcontents">{subSection}</div>}
    </nav>
  );
});

export default sizeMeHOC(PageHeader);
