import $dictionary from 'stores/$dictionary';

const metricColumns = {
  Metrics: [
    [
      {
        excludeGroupClassName: true,
        group: '',
        className: 'dimension-tag metric',
        label: 'Sampled at Ingress bytes',
        value: 'in_bytes'
      },
      {
        group: '',
        className: 'dimension-tag metric',
        label: 'Sampled at Ingress packets',
        value: 'in_pkts'
      }
    ],
    [
      {
        excludeGroupClassName: true,
        group: '',
        className: 'dimension-tag metric',
        label: 'Sampled at Egress bytes',
        value: 'out_bytes'
      },
      {
        group: '',
        className: 'dimension-tag metric',
        label: 'Sampled at Egress packets',
        value: 'out_pkts'
      }
    ],
    [
      {
        excludeGroupClassName: true,
        group: '',
        className: 'dimension-tag metric',
        label: 'Client Latency (ms)',
        value: 'client_nw_latency_ms'
      }
    ]
  ]
};

const timeColumns = {
  Time: [
    [
      {
        excludeGroupClassName: true,
        group: '',
        className: 'dimension-tag metric',
        label: 'Timestamp',
        value: 'ctimestamp'
      }
    ],
    [],
    []
  ]
};

function flowColumns() {
  return Object.assign({}, timeColumns, metricColumns, $dictionary.dimensionOptions);
}

function orderByColumns() {
  return Object.assign({}, timeColumns, metricColumns, $dictionary.dimensionOptions);
}

export { flowColumns, orderByColumns, metricColumns, timeColumns };
