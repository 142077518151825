import React, { Fragment } from 'react';
import { computed } from 'mobx';

import BaseModel from '../BaseModel';

class MitigationPlatform extends BaseModel {
  get defaults() {
    return {
      mitigationMethods: []
    };
  }

  get removalConfirmText() {
    return {
      text: (
        <Fragment>
          Are you sure you want to remove <strong>{this.get('platform_name')}?</strong>
        </Fragment>
      )
    };
  }

  serialize(data) {
    if (data.platform_mitigation_device_detail) {
      const { ipAddress } = data.platform_mitigation_device_detail;
      if (ipAddress && !ipAddress.includes('https://')) {
        data.platform_mitigation_device_detail.ipAddress = `https://${ipAddress}`;
      }

      const { devices } = data.platform_mitigation_device_detail;
      if (devices) {
        data.platform_mitigation_device_detail.devices = devices.map(d => parseInt(d, 10));
      }
    }

    delete data.mitigationMethods;

    return super.serialize({ ...data });
  }

  deserialize(data = {}) {
    if (data.platform_mitigation_device_detail) {
      const { ipAddress } = data.platform_mitigation_device_detail;
      if (ipAddress && ipAddress.includes('https://')) {
        data.platform_mitigation_device_detail.ipAddress = ipAddress.replace('https://', '');
      }
    }

    // This is needed because the API sends mitigationMethods in the GET, but expects mitigationMethodIds in the PUT/POST
    if (data.mitigationMethods) {
      data.mitigationMethodIds = data.mitigationMethods.map(method => method.id);
    } else {
      data.mitigationMethodIds = [];
    }

    return super.deserialize(data);
  }

  get omitDuringSerialize() {
    return ['mitigationPlatformMethods'];
  }

  @computed
  get methods() {
    return this.get('mitigationMethods')
      .map(method => method.method_name)
      .join(', ');
  }
}

export default MitigationPlatform;
