import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Field, Input, Select } from 'components/forms';

@inject('$basicExplorer', '$dictionary', '$lookups')
@observer
class FilterField extends Component {
  handleChange = (f, value) => {
    const { onChange } = this.props;
    onChange(value);
  };

  // handle the type: Input case where they need to
  handleSubmit = () => {
    const { filter, onChange } = this.props;
    filter.filterValue.setPristine(true);
    onChange();
  };

  // resets a Filter back to it's base template
  handleClearFilter = () => {
    const { filter, onChange } = this.props;
    filter.filterValue.setValue('');
    filter.filterValue.setPristine(true);
    onChange();
  };

  render() {
    const { $basicExplorer, $lookups, $dictionary, filter, activeFilters } = this.props;
    const {
      dictionary: {
        queryFilters: { neverExactMatch }
      }
    } = $dictionary;

    const filterLabel = filter.label.getValue();
    const filterField = filter.filterField.getValue();
    const filterValueField = filter.filterValue;
    const filterOperator = filter.operator.getValue();

    // the options that are allowed for a specific filter.filterField
    const valueOptions = $basicExplorer.getFilterOptions(filterField);
    const onQuery = $lookups.getAutoCompleteFilterHandler(filterField);
    const exactMatch = !neverExactMatch.includes(filterField) && (filterOperator === '=' || filterOperator === '<>');

    const activeFilterFields = activeFilters.map(f => f.filterField);
    const isActive = activeFilterFields.includes(filterField);

    return (
      <Box mb={2}>
        <Flex align="center" justify="space-between" style={{ height: 24, marginBottom: 5 }}>
          <h6 className="pt-text-muted small-caps no-margin" style={{ fontSize: 12 }}>
            {filterLabel}
          </h6>
          {isActive && (
            <Button className="pt-small pt-minimal pt-strong pt-intent-primary" onClick={this.handleClearFilter}>
              Reset
            </Button>
          )}
        </Flex>
        <Box>
          {(valueOptions || onQuery) && (
            <Field
              showLabel={false}
              className="no-margin pt-fill"
              field={filterValueField}
              placeholder="Select value..."
              options={valueOptions}
              onChange={this.handleChange}
              validateOptions={exactMatch}
              autoFocus
            >
              <Select
                autoComplete
                exactMatch={exactMatch}
                showFilter={exactMatch}
                menuWidth={243}
                onQuery={onQuery}
                tetherOptions={{
                  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
                }}
              />
            </Field>
          )}
          {!valueOptions &&
            !onQuery && (
              <Field showLabel={false} className="pt-fill no-margin" field={filter.filterValue} autoFocus>
                <Input
                  rightElement={
                    !filterValueField.pristine ? (
                      <Button
                        onClick={this.handleSubmit}
                        className="pt-button pt-icon-tick pt-intent-primary pt-minimal pt-strong"
                        text="Go"
                      />
                    ) : null
                  }
                />
              </Field>
            )}
        </Box>
      </Box>
    );
  }
}

export default FilterField;
