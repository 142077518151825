import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';

const CreateSavedViewButton = ({ onCreateSavedView, showButtonText, loading }) => (
  <Button
    disabled={loading}
    text={showButtonText ? 'Save View' : null}
    onClick={onCreateSavedView}
    className="pt-small pt-medium"
    iconName="floppy-disk"
  />
);

export default observer(CreateSavedViewButton);
