import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex } from 'components/flexbox';
import DataViewSpinner from 'dataviews/DataViewSpinner';
import RawFlowResultsTable from 'views/Analytics/RawFlow/RawFlowResultsTable';
import Collection from 'models/Collection';
import RawFlowDataViewModel from './RawFlowDataViewModel';

export const config = {
  model: RawFlowDataViewModel,
  hideFromViewMenu: true
};

@inject('$rawFlow')
@observer
class RawFlow extends Component {
  state = {
    sortField: '',
    sortDir: 'desc'
  };

  getSortField() {
    const { sortField } = this.state;
    const { dataview } = this.props;
    return sortField || dataview.viewModel.get('rawFlowQuery.order_by');
  }

  handleSort = column => {
    const { sortDir } = this.state;
    const sortField = this.getSortField();

    if (sortField === column.name) {
      this.setState({ sortDir: sortDir === 'desc' ? 'asc' : 'desc' });
    } else {
      this.setState({ sortField: column.name, sortDir: 'desc' });
    }
  };

  getColumns(columnNames) {
    const { $rawFlow } = this.props;
    const { sortDir } = this.state;
    const sortField = this.getSortField();

    return $rawFlow.getTableColumns(columnNames).map(column => {
      const sorted = sortField === column.name;
      return { ...column, sorted, sortDir: sorted ? sortDir : 'desc' };
    });
  }

  render() {
    const { dataview } = this.props;
    const { sortDir } = this.state;
    const { viewModel } = dataview;

    if (dataview.loading) {
      return (
        <Flex flexAuto flexColumn>
          <Flex flexAuto className="dataview-scoreboard">
            {dataview.loading && <DataViewSpinner text="Loading Raw Flow data..." loading={dataview.loading} />}
          </Flex>
        </Flex>
      );
    }

    const columns = viewModel.get('columns');
    const results = viewModel.get('results');
    const sortField = this.getSortField();
    const collection = new Collection(results, { sortState: { field: sortField, direction: sortDir } });
    const showTable = !collection.isEmpty();

    return (
      <Flex flexAuto flexColumn>
        <Flex flexAuto className="dataview-scoreboard">
          {showTable && (
            <RawFlowResultsTable
              collection={collection}
              columns={this.getColumns(columns)}
              selectOnRowClick={false}
              onSortClick={this.handleSort}
            />
          )}
        </Flex>
      </Flex>
    );
  }
}

export default RawFlow;
