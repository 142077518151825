import { observer, inject } from 'mobx-react';
import { MOMENT_FN, DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';
import { getToFixed, zeroToText, greekPrefix, adjustByGreekPrefix, escapeHtml } from 'util/utils';

import StackedArea from './StackedArea';

@inject('$app')
@observer
export default class StackedAreaHundredPercentView extends StackedArea {
  totalData = {};

  renderOverlay(bucket, name, data) {
    if (name === 'Total' && data) {
      const bucketName = bucket.get('name');
      this.totalData[bucketName] = data;
      const yAxisIndex = bucketName === 'Left +Y Axis' ? 0 : 1;
      const yAxis = this.chart.yAxis[yAxisIndex];
      const otherYAxis = this.chart.yAxis[yAxisIndex ? 0 : 1];
      const totalSeries = this.chart.series.find(series => series.name === 'Other' && series.yAxis === yAxis);
      const otherTotalSeries = this.chart.series.find(series => series.name === 'Other' && series.yAxis === otherYAxis);
      if (!totalSeries) {
        const seriesConfig = { type: 'area', name: 'Other', data: this.totalData, yAxis: yAxisIndex };
        if (otherTotalSeries) {
          seriesConfig.color = otherTotalSeries.color;
        }
        this.chart.addSeries(seriesConfig);
      }
    }
  }

  updateRenderedOverlay(bucket, name, data) {
    if (name === 'Total' && data && data.flow) {
      this.totalData[bucket.get('name')] = data.flow;
    }
  }

  renderTotalSeries() {
    const totalKeys = Object.keys(this.totalData);
    if (this.chart && totalKeys.length) {
      totalKeys.forEach(bucket => {
        const totalData = this.totalData[bucket];
        const yAxisIndex = bucket === 'Left +Y Axis' ? 0 : 1;
        const yAxis = this.chart.yAxis[yAxisIndex];
        const dataSeries = this.chart.series.filter(series => series.name !== 'Other' && series.yAxis === yAxis);
        if (dataSeries.length) {
          totalData.forEach((row, index) => {
            dataSeries.forEach(series => {
              if (yAxisIndex) {
                row[1] += series.data[index].y;
              } else {
                row[1] -= series.data[index].y;
              }
            });
            if (yAxisIndex) {
              row[1] = Math.min(row[1], 0);
            } else {
              row[1] = Math.max(row[1], 0);
            }
          });
          const totalSeries = this.chart.series.find(series => series.name === 'Other' && series.yAxis === yAxis);
          if (totalSeries) {
            totalSeries.setData(totalData);
          }
        }
      });
    }
  }

  redraw(opts) {
    if (!this.chart) {
      return;
    }

    const query = this.props.dataview.queryBuckets.activeBuckets[0].firstQuery;
    let yAxisSuffix = '';
    if (this.chartOptions.plotOptions.area.stacking === 'percent') {
      yAxisSuffix = ' % of ';
      if (query.get('show_total_overlay')) {
        yAxisSuffix += 'total';
      } else {
        yAxisSuffix += `top ${query.get('topx')}`;
      }
    }
    this.chart.yAxis.forEach(yAxis => {
      if (!yAxis.options.title.text.includes(yAxisSuffix)) {
        const arrowSplit = yAxis.options.title.text.split(' \u2192');
        yAxis.update({
          title: {
            text: `${arrowSplit[0].split(' % of ')[0]}${yAxisSuffix}${arrowSplit[1] !== undefined ? ' \u2192' : ''}`
          }
        });
      }
    });
    super.redraw(opts);
    this.props.$app.renderSync(() => {
      this.renderTotalSeries();
    });
  }

  getComponent() {
    const { chartOptions } = this;
    chartOptions.plotOptions.area.stacking = 'percent';

    function formatter() {
      let tooltip = '';

      if (!this.points.length) {
        return tooltip;
      }
      const points = this.points;

      tooltip = (this.points[0].series.xAxis.options.momentFn || MOMENT_FN)(this.x).format(DEFAULT_DATETIME_FORMAT);

      const primarySeriesPoints = points.filter(point => point.series.chart.yAxis[0] === point.series.yAxis);
      const secondarySeriesPoints = points.filter(point => point.series.chart.yAxis[1] === point.series.yAxis);
      const seriesPoints = primarySeriesPoints;
      if (secondarySeriesPoints.length) {
        secondarySeriesPoints.forEach(point => {
          const matchingPrimarySeries = primarySeriesPoints.find(
            primaryPoint => primaryPoint.series.name === point.series.name
          );
          if (matchingPrimarySeries) {
            matchingPrimarySeries.secondary = point;
          } else {
            seriesPoints.push(point);
          }
        });
      }

      tooltip = seriesPoints.filter(point => point.series.name !== 'Other').reduce((value, point) => {
        const prefix = point.series.yAxis.prefix || greekPrefix([Math.abs(point.y)]);
        point.series.yAxis.prefix = prefix;
        const suffix = point.series.yAxis.options.title.text.split(' % of ')[0];
        let tooltipMarkup =
          `${value}<br/><span style="color:${point.series.color};">\u25CF</span> ` +
          `${point.series.name.replace(/----/g, '\u2192')}: <b>${point.percentage.toFixed(1)}% (${escapeHtml(
            zeroToText(adjustByGreekPrefix(point.y, prefix), { fix: getToFixed(point.series.yAxis.options.unit) })
          )} ` +
          `${suffix.includes('sampling rate') ? ': 1 ' : ''}${prefix}${suffix})</b>`;

        if (point.secondary) {
          const { secondary } = point;
          const {
            series: { yAxis }
          } = secondary;
          const secondaryPrefix = yAxis.prefix || greekPrefix([Math.abs(secondary.y)]);
          const secondarySuffix = yAxis.options.title.text.split(' % of ')[0];
          yAxis.prefix = secondaryPrefix;
          tooltipMarkup += `, <b>${Math.abs(secondary.percentage).toFixed(1)}% (${escapeHtml(
            zeroToText(adjustByGreekPrefix(secondary.y, secondaryPrefix), { fix: getToFixed(yAxis.options.unit) })
          )} ${secondarySuffix.includes('sampling rate') ? ': 1 ' : ''}${secondaryPrefix}${secondarySuffix})</b>`;
        }

        return tooltipMarkup;
      }, tooltip);

      // show total at the bottom
      return seriesPoints.filter(point => point.series.name === 'Other').reduce((value, point) => {
        const prefix = point.series.yAxis.prefix || greekPrefix([Math.abs(point.y)]);
        point.series.yAxis.prefix = prefix;
        const suffix = point.series.yAxis.options.title.text.split(' % of ')[0];
        let tooltipMarkup =
          `${value}<br/><span style="color:${point.series.color};">\u25CF</span> ` +
          `${point.series.name}: <b>${point.percentage.toFixed(1)}% (${escapeHtml(
            zeroToText(adjustByGreekPrefix(point.y, prefix), { fix: getToFixed(point.series.yAxis.options.unit) })
          )} ` +
          `${suffix.includes('sampling rate') ? ': 1 ' : ''}${prefix}${suffix})</b>`;

        if (point.secondary) {
          const { secondary } = point;
          const {
            series: { yAxis }
          } = secondary;
          const secondaryPrefix = yAxis.prefix || greekPrefix([Math.abs(secondary.y)]);
          const secondarySuffix = yAxis.options.title.text.split(' % of ')[0];
          yAxis.prefix = secondaryPrefix;
          tooltipMarkup += `, <b>${Math.abs(secondary.percentage).toFixed(1)}% (${escapeHtml(
            zeroToText(adjustByGreekPrefix(secondary.y, secondaryPrefix), { fix: getToFixed(yAxis.options.unit) })
          )} ${secondarySuffix.includes('sampling rate') ? ': 1 ' : ''}${secondaryPrefix}${secondarySuffix})</b>`;
        }

        return tooltipMarkup;
      }, tooltip);
    }

    chartOptions.tooltip = Object.assign(chartOptions.tooltip, { formatter });

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: true,
  enableToggle: true,
  mirrorable: true,
  allowsSecondaryOverlay: true,
  allowsSyncAxes: true,
  buckets: [
    {
      name: 'Left +Y Axis',
      mirrorBucket: 1,
      secondaryOverlayBucket: 1
    },
    {
      name: 'Right -Y Axis',
      sampleRateFactor: -1
    }
  ]
};

export { config };
