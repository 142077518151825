import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormDialog, Form } from 'components/forms';
import { getHashForQueries } from 'services/urlHash';

import { fields as dashbaordDetailsFields, options as dashboardDetailsOptions } from 'forms/config/dashboardDetails';
import { fields as dashboardItemFields, options as dashboardItemOptions } from 'forms/config/dashboardItemDetails';

import Dashboard from 'models/dashboards/Dashboard';
import DashboardItem from 'models/dashboards/DashboardItem';
import QueryModel from 'models/query/QueryModel';
import DashboardItemForm from './DashboardItemForm';
import DashboardDetailsForm from '../DashboardDetailsForm';

@Form({
  fields: {
    ...dashboardItemFields,
    ...dashbaordDetailsFields
  },
  options: {
    ...dashboardDetailsOptions,
    ...dashboardItemOptions
  }
})
@inject('$dashboard', '$dashboards', '$explorer', '$library')
@observer
export default class CloneDashboardItemDialog extends Component {
  state = {
    cloneNewDashboardStage: undefined,
    dashboardItem: undefined,
    dashboard: undefined,
    dashboardItemFormValues: undefined,
    dashboardItemGroupValues: undefined,
    dataviewValues: undefined
  };

  componentDidMount = () => {
    const { srcDashboardItem, isOpen } = this.props;
    if (isOpen && srcDashboardItem) {
      const dashboardItem = this.createNewDashboardItem(srcDashboardItem);

      if (dashboardItem.dataview.formConfig) {
        this.initializeDataViewFields(dashboardItem);
      }

      this.setState({
        cloneNewDashboardStage: 'dashboardItem',
        dashboardItem
      });
    }
  };

  componentWillUnmount = () => {
    this.props.$explorer.destroy();
  };

  /**
   * Adds the required form fields required by this DashboardItem's dataview for configuration,
   * and initializes their values.
   */
  initializeDataViewFields = dashboardItem => {
    const { form } = this.props;

    const dataviewFields = dashboardItem.dataview.formConfig;
    const dashboardItemValues = dashboardItem.get();
    const dataviewValues = dashboardItem.dataview.viewModel.get();

    const formValues = {
      ...dashboardItemValues,
      ...dataviewValues
    };

    // add the dataviews fields to the form
    form.addFields(dataviewFields);

    // add them to the `dataviews` field group so it's easier to pull them out later.
    form.addFieldsToGroup(dataviewFields, 'dataview');

    form.init(formValues);
    form.getField('dashboard_id').setRules('');
    form.getField('dash_title').setRules('');
    form.getField('share_level').setRules('');
  };

  createNewDashboardItem = sourceDashboardItem => {
    const { form } = this.props;
    const { id, dashboard, dashboard_id, panel_title, ...rest } = sourceDashboardItem.toJS();
    const { viewModel } = sourceDashboardItem.dataview;
    const options = {};
    if (viewModel) {
      options.viewModel = viewModel.duplicate();
    }

    const dashboardItem = new DashboardItem(
      {
        panel_title: `[COPY] ${panel_title}`,
        ...rest
      },
      options
    );
    form.setModel(dashboardItem);
    dashboardItem.fetchSavedQuery();
    form.getField('dashboard_id').setRules('');
    form.getField('dash_title').setRules('');
    form.getField('share_level').setRules('');
    return dashboardItem;
  };

  handleClose = () => {
    const { $dashboard, $explorer } = this.props;
    this.setState(() => ({
      cloneNewDashboardStage: undefined,
      dashboardItem: undefined,
      dashboard: undefined,
      dashboardItemFormValues: undefined,
      dashboardItemGroupValues: undefined,
      dataviewValues: undefined
    }));
    $explorer.destroy();
    $dashboard.setCloningDashboardItem(false);
  };

  handleToggleEditFilters = () => {
    this.setState(() => ({ editingFilters: !this.state.editingFilters }));
  };

  handleDashboardItemSave = form => {
    const { $explorer } = this.props;
    const { dashboardItem } = this.state;
    const { queryModel, formState } = $explorer;

    const dashboardItemFormValues = form.getValues();
    const dashboardItemGroupValues = form.getFieldGroupValues('item');

    const dataviewValues = {
      ...form.getFieldGroupValues('dataview'),
      query_title: dashboardItemFormValues.panel_title
    };
    dashboardItem.set(form.getValues());
    let explorerQuerySwitches = {};
    if (formState) {
      formState.submit(() => {
        queryModel.set(formState.getValues());
        $explorer.apply(QueryModel.create(queryModel.serialize()));
        formState.setModel(queryModel);
      });
      explorerQuerySwitches = {
        time_locked: formState.getValue('time_locked'),
        device_locked: formState.getValue('device_locked'),
        filter_source: formState.getValue('filter_source')
      };
    }

    const dashboard = new Dashboard();
    dashboard.set({ dash_title: 'My New Dashboard', share_level: 'self' });

    form.setModel(dashboard);
    form.getField('dashboard_id').setRules('');
    form.getField('dash_title').setRules('required');
    form.getField('share_level').setRules('required');

    this.setState(() => ({
      cloneNewDashboardStage: 'dashboard',
      dashboard,
      dashboardItemFormValues,
      dashboardItemGroupValues,
      explorerQuerySwitches,
      dataviewValues
    }));
  };

  handleDashboardSave = async form => {
    const { $explorer, $dashboards, $library } = this.props;
    const {
      dashboardItem,
      dashboardItemFormValues,
      dashboardItemGroupValues,
      dataviewValues,
      explorerQuerySwitches
    } = this.state;
    const { dataview } = dashboardItem;

    const { panel_title, parametric_mode, parametric_overrides, ...formValues } = form.getValues();
    let saved_query_id;

    if (!dataview.isFlowVisualization || (dashboardItem.isNew && dataview.isFlowVisualization)) {
      saved_query_id = await getHashForQueries(dataviewValues, { persist: true });
    } else {
      saved_query_id = dashboardItem.get('saved_query_id');
    }
    const attributes = { ...dashboardItemGroupValues, saved_query_id };

    const dashboardModel = $library.reportsCollection.forgeDashboard();
    return dashboardModel.save(formValues, { clearSelection: false }).then(() => {
      dashboardItem.setRequestStatus('updating');

      if (dataview.isFlowVisualization) {
        $explorer.dataview.queryBuckets.save(true).then(hash =>
          dashboardItem
            .save(
              Object.assign(
                {
                  ...dashboardItemFormValues,
                  ...{
                    dashboard_id: dashboardModel.get('id'),
                    panel_title,
                    parametric_mode,
                    parametric_overrides
                  }
                },
                { saved_query_id: hash, ...explorerQuerySwitches }
              ),
              { toast: false }
            )
            .then(() => {
              this.handleClose();
              $dashboards.navigateToDashboard(dashboardModel);
            })
        );
      } else {
        dashboardItem
          .save(
            Object.assign({
              ...attributes,
              ...dashboardItemFormValues,
              ...{
                dashboard_id: dashboardModel.get('id'),
                panel_title
              }
            })
          )
          .then(() => {
            this.handleClose();
            $dashboards.navigateToDashboard(dashboardModel);
          });
      }
    });
  };

  renderDashboardItemForm = () => (
    <FormDialog
      {...this.props}
      model={this.state.dashboardItem}
      style={{
        width: 'calc(100vw - 200px)',
        maxWidth: 1600
      }}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: 'calc(100vh - 100px)',
        paddingBottom: 0,
        marginBottom: 8
      }}
      entityName="Dashboard Panel"
      formActionsProps={{ onSubmit: this.handleDashboardItemSave, onCancel: this.handleClose }}
      formComponent={
        <DashboardItemForm {...this.props} handleSave={this.handleDashboardItemSave} showDashboardSelect={false} />
      }
    />
  );

  renderDashboardDetailsForm = () => {
    const { editingFilters, dashboard, dashboardItem } = this.state;

    return (
      <FormDialog
        {...this.props}
        model={dashboard}
        style={{ width: 850 }}
        entityName="New Dashboard"
        formActionsProps={{ onSubmit: this.handleDashboardSave, onCancel: this.handleClose }}
        formComponent={
          <DashboardDetailsForm
            {...this.props}
            dashboardItem={dashboardItem}
            editingFilters={editingFilters}
            onEditFilters={this.handleToggleEditFilters}
          />
        }
      />
    );
  };

  render() {
    const { isOpen } = this.props;
    const { cloneNewDashboardStage } = this.state;

    if (cloneNewDashboardStage === 'dashboard') {
      return this.renderDashboardDetailsForm();
    }

    if (cloneNewDashboardStage === 'dashboardItem') {
      return this.renderDashboardItemForm(isOpen);
    }

    return null;
  }
}
