import moment from 'moment';
import $auth from 'stores/$auth';
import { autorun } from 'mobx';

/* eslint-disable import/no-mutable-exports */
export let USER_TIMEZONE = $auth.userTimezone;
/* eslint-disable import/no-mutable-exports */
export let MOMENT_FN = $auth.userTimezone === 'Local' ? moment : moment.utc;
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';

autorun(() => {
  USER_TIMEZONE = $auth.userTimezone;
  MOMENT_FN = USER_TIMEZONE === 'Local' ? moment : moment.utc;
});

export function formatDate(date, format = DEFAULT_DATE_FORMAT) {
  return MOMENT_FN(date).format(format);
}

export function formatDateTime(date, format = DEFAULT_DATETIME_FORMAT) {
  return formatDate(date, format);
}

export function formatCalendar(date) {
  return MOMENT_FN(date).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: DEFAULT_DATE_FORMAT
  });
}

export function dateFormatter({ value }) {
  return value ? formatDate(value) : '';
}

export function dateTimeFormatter({ value }) {
  return value ? formatDateTime(value) : '';
}

export function getQueryTimeInterval({ lookback_seconds, starting_time, ending_time }) {
  if (lookback_seconds === 0 && starting_time && ending_time) {
    return moment(ending_time).diff(moment(starting_time), 'second');
  }
  return lookback_seconds;
}

export function secondsIntervalToText(interval) {
  const textInterval = [];
  let remainingInterval = interval;

  if (remainingInterval >= 604800) {
    const weeks = Math.floor(remainingInterval / 604800);
    textInterval.push(`${weeks}w`);
    remainingInterval %= 604800;
  }

  if (remainingInterval >= 86400) {
    const days = Math.floor(remainingInterval / 86400);
    textInterval.push(`${days}d`);
    remainingInterval %= 86400;
  }

  if (remainingInterval >= 3600) {
    const hours = Math.floor(remainingInterval / 3600);
    textInterval.push(`${hours}h`);
    remainingInterval %= 3600;
  }

  if (remainingInterval >= 60) {
    const minutes = Math.floor(remainingInterval / 60);
    textInterval.push(`${minutes}m`);
  }

  let text;
  if (textInterval.length === 0) {
    text = `${interval} seconds`; // Technically don't need this but doesn't hurt
  } else if (textInterval.length === 1) {
    text = textInterval[0];
  } else if (textInterval.length === 2) {
    text = textInterval.join(' and ');
  } else {
    text = `${textInterval.slice(0, -1).join(', ')} and ${textInterval.slice(-1)}`;
  }

  return text;
}

export function durationFromNow(date, hideSuffix) {
  return MOMENT_FN().to(MOMENT_FN(date), hideSuffix);
}
