import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { AnchorButton, Dialog, Button, Checkbox, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Fade } from 'components/animations';
import headingImage from 'kentik-insight-18.png';

const backdropProps = {
  style: {
    top: 50
  }
};

@inject('$auth')
@observer
class LoginInterstitial extends Component {
  handleEnabledChange = e => {
    this.props.$auth.handleSetShowInterstitial(e.target.checked);
  };

  render() {
    const { onClose, $auth } = this.props;
    return (
      <Dialog
        {...this.props}
        style={{ maxHeight: 'calc(100vh - 100px)', top: 65, width: 500 }}
        backdropProps={backdropProps}
        transitionName="pt-dialog"
      >
        <Flex flexColumn className="pt-dialog-body" style={{ padding: 0, margin: 0, overflow: 'hidden' }}>
          <img
            src={headingImage}
            alt="Kentik Insight '18"
            style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4, width: '100%' }}
          />
          <Box px={4} pt={3} pb={2} flexAuto style={{ overflow: 'auto' }}>
            <Fade in appear timeout={150}>
              {({ styles }) => (
                <div style={styles}>
                  <p className="pt-strong">
                    Come out to spring-time New York City and join us for a day of training, sneak previews of upcoming
                    functionality, and round table discussions about where Kentik is headed next.
                  </p>
                  <p>
                    We will be offering two tracks, a technical morning and an executive afternoon one. You are invited
                    to join us for both tracks or just spend part of the day with us.
                  </p>
                  <p>
                    Listen to Anthony Cairo, Infrastructure Performance Engineer at GoDaddy talk about how GoDaddy is
                    using Kentik every day to build a stronger, more reliable network. Hear Avi Freedman, Kentik’s CEO
                    and Co-Founder give a vision of our connected future.
                  </p>
                  <p>
                    Mingle among your peers in the industry and get a chance to shape the future of Kentik’s products.
                  </p>
                  <p>
                    <strong>May 17th | 9:00 AM</strong>
                    <br />
                    Kimpton Eventi Hotel
                    <br />
                    New York, NY
                  </p>
                  <strong>
                    <AnchorButton
                      href="https://www.greenvelope.com/view/.public-9b8c29a65a6e4310937027f8c88917e531313036353734#rsvp"
                      target="_blank"
                      rel="noopener noreferrer"
                      intent={Intent.PRIMARY}
                    >
                      Register Now
                    </AnchorButton>
                  </strong>
                </div>
              )}
            </Fade>
          </Box>
        </Flex>
        <Flex className="pt-dialog-footer" justify="flex-end" align="center" p={2}>
          <Box mr={2}>
            <Checkbox
              checked={$auth.hideLoginInterstitial}
              label="Don't show this again"
              onChange={this.handleEnabledChange}
              style={{ margin: 0 }}
            />
          </Box>
          <Button className="pt-medium" text="Close" onClick={onClose} />
        </Flex>
      </Dialog>
    );
  }
}

export default LoginInterstitial;
