import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { MenuItem, MenuDivider } from '@blueprintjs/core';

@inject('$dataviews', '$exports')
@observer
export default class ExportMenuItem extends Component {
  exportCsv(bucket, raw) {
    const {
      $exports,
      dataview: { hash }
    } = this.props;

    const { addLoadingExport, clearLoadingExport, addPayload } = $exports;

    const path = `csv/${hash}`;
    const fileName = `kentik-export-${raw ? 'chart-' : ''}data-${hash}`;
    const type = 'csv';
    const options = { path, fileName, type };

    const isFilterDimension = bucket.firstQuery.get('filterDimensionsEnabled');

    addLoadingExport(options);
    let payload;
    if (isFilterDimension) {
      payload = raw ? bucket.filterDimensionRawCsv : bucket.filterDimensionCsv;
    } else {
      payload = raw ? bucket.rawCsv : bucket.csv;
    }
    clearLoadingExport(options);
    addPayload(payload, options);
  }

  generateSeriesMenuItems(dataviewModels, $dataviews) {
    let result = [];
    Object.values(dataviewModels).forEach(model => {
      const { queryBuckets, key, viewType: generatorViewType } = model;
      const { timeBased: generatorTimeBased } = $dataviews.getConfig(generatorViewType);

      result = [
        ...result,
        ...queryBuckets.activeBuckets.map(b => {
          const name = b.get('name');
          let keyLabel = key.length > 60 ? `${key.substring(0, 60)}...` : key;
          if (queryBuckets.activeBuckets.length > 1) {
            keyLabel = (
              <span
                className="pt-text-overflow-ellipsis"
                style={{ display: 'inline-block', minWidth: name.length * 8 + 80, paddingRight: 50 }}
              >
                <strong>{name}</strong> {keyLabel}
              </span>
            );
          }

          return (
            <MenuItem
              key={`${key}${name}ChartCsv`}
              iconName="series-derived"
              label=".csv"
              text={keyLabel}
              onClick={() => this.exportCsv(b, generatorTimeBased)}
            />
          );
        })
      ];
    });

    return result;
  }

  render() {
    const {
      $dataviews,
      $exports,
      dataview: {
        hash,
        viewType,
        component,
        queryBuckets: { activeBuckets, selectedQuery }
      }
    } = this.props;

    const time_format = selectedQuery.get('time_format');
    let urlParams = null;
    if (time_format === 'Local') {
      urlParams = { time_format: moment().utcOffset() };
    }
    const path = `/api/portal/export/explorer/${hash}`;
    const fileName = `kentik-export-${hash}`;
    const { timeBased } = $dataviews.getConfig(viewType);

    const [bucket] = activeBuckets;
    let csvItems = [];

    if (viewType === 'generator') {
      const { dataviewModels } = component;
      csvItems = [...this.generateSeriesMenuItems(dataviewModels, $dataviews)];
    } else if (activeBuckets.length && activeBuckets.length > 1) {
      activeBuckets.forEach(b => {
        const { name } = b.get();

        csvItems = [
          ...csvItems,
          <MenuDivider key={`${name}Title`} title={name} />,
          <MenuItem
            key={`${name}ChartCsv`}
            text="Image"
            iconName="media"
            label=".png"
            onClick={() => this.exportCsv(b, timeBased)}
          />,
          <MenuItem
            key={`${name}LegendCsv`}
            text="Legend"
            iconName="th"
            label=".csv"
            onClick={() => this.exportCsv(b)}
          />
        ];
      });
    }

    const showCsvSubmenu = csvItems.length > 2;

    return (
      <MenuItem iconName="export" text="Export" {...this.props}>
        <MenuDivider title="Chart + Legend" />
        <MenuItem
          iconName="document"
          text="Document"
          label=".pdf"
          onClick={() => $exports.fetchExport({ path, fileName, urlParams, type: 'pdf' })}
        />
        <MenuDivider title="Chart Image" />
        <MenuItem
          iconName="media"
          text="Image"
          label=".png"
          onClick={() => $exports.fetchExport({ path, fileName, urlParams, type: 'png' })}
        />
        <MenuItem
          iconName="media"
          text="Vector"
          label=".svg"
          onClick={() => $exports.fetchExport({ path, fileName, urlParams, type: 'svg' })}
        />
        <MenuDivider title="Data" />
        <MenuItem text="Chart" iconName="th" label=".csv" onClick={() => this.exportCsv(bucket, timeBased)} />
        <MenuItem text="Legend" iconName="th" label=".csv" onClick={() => this.exportCsv(bucket)} />
        <Fragment>
          {showCsvSubmenu && (
            <Fragment>
              <MenuItem iconName="series-derived" text="Series Data">
                {csvItems}
              </MenuItem>
            </Fragment>
          )}
        </Fragment>
      </MenuItem>
    );
  }
}
