import Validator from 'validatorjs';

const options = {
  groups: {
    rangeRestriction: ['rangeRestriction.enabled', 'rangeRestriction.min', 'rangeRestriction.max']
  }
};

Validator.register(
  'increasingBracketValues',
  function increasingBracketValues() {
    const ranges = this.validator.input.ranges;
    return ranges.every((range, idx, array) => {
      if (idx < 1) {
        return true;
      }
      return +range.to > +array[idx - 1].to;
    });
  },
  'Bracketing range values must be increasing.'
);

Validator.register(
  'rangeRestrictionMinUnderMax',
  function rangeRestrictionMinUnderMax() {
    const restriction = this.validator.input.rangeRestriction;
    if (restriction.min.toString().trim() && restriction.max.toString().trim()) {
      return +restriction.max > +restriction.min;
    }
    // if min or max is blank, just fall through, it's fine.
    return true;
  },
  'The range limiting floor value must be less than ceiling value if both specified'
);

Validator.register(
  'rangeRestrictionEnabledRequiresMinOrMax',
  function rangeRestrictionEnabledRequiresMinOrMax() {
    const restriction = this.validator.input.rangeRestriction;
    const { min, max, enabled } = restriction;
    return (enabled && (min.toString().trim().length || max.toString().trim().length)) || !enabled;
  },
  'Must provide either range limiting floor or ceiling value'
);

const transformUnitValueIn = (unit, value) => {
  // don't transform to number if value is empty string, then validator won't catch as "not entered"
  if (typeof value === 'string' && value.trim() === '') {
    return '';
  }
  if (unit === 'bytes' || unit === 'in_bytes' || unit === 'out_bytes') {
    return value / 1000000;
  } else if (unit === 'packets' || unit === 'in_packets' || unit === 'out_packets') {
    return value / 1000;
  }
  return +value;
};

const transformUnitValueOut = (unit, value) => {
  // don't transform to number if value is empty string, then validator won't catch as "not entered"
  if (typeof value === 'string' && value.trim() === '') {
    return '';
  }
  if (unit === 'bytes' || unit === 'in_bytes' || unit === 'out_bytes') {
    return value * 1000000;
  } else if (unit === 'packets' || unit === 'in_packets' || unit === 'out_packets') {
    return value * 1000;
  }
  return value * 1; // do this so we ensure a number comes back
};

const getOutsortAggregateUnit = queryModel => {
  const { outsort, aggregates } = queryModel;
  const aggregate = aggregates.find(agg => agg.value === outsort);
  // fail with '' if outsort aggregate not found
  if (!aggregate) {
    return '';
  }
  return aggregate.unit;
};

const fields = {
  type: {
    label: 'Bracketing Type'
  },
  lastDatapoint: {
    helpText:
      'If disabled, series are evaluated by the value ​of the ​Primary Display & Sort Metric​ specified​ in the Metrics dialog'
  },
  groupResults: {
    defaultValue: true,
    helpText: 'In results table, show collapsible header row for each bracket'
  },
  ranges: {
    isComplexArray: true,
    rules: 'required|increasingBracketValues',
    messages: {
      required: 'At least one value range must be present'
    }
  },
  'rangeRestriction.enabled': {
    label: 'Range restrictions',
    rules: 'rangeRestrictionEnabledRequiresMinOrMax',
    helpText: 'Exclude values from bracketing range calculations.',
    defaultValue: false
  },
  'rangeRestriction.units': {
    defaultValue: 'percent'
  },
  'rangeRestriction.max': {
    label: 'range limiting ceiling',
    defaultValue: 100,
    transform: {
      in: (value, form) => {
        if (form.getField('rangeRestriction.units').value === 'raw') {
          return transformUnitValueIn(getOutsortAggregateUnit(form.model.currentQueryState), value);
        }
        return value;
      },
      out: (value, form) => {
        if (form.getField('rangeRestriction.units').value === 'raw') {
          return transformUnitValueOut(getOutsortAggregateUnit(form.model.currentQueryState), value);
        }
        return value;
      }
    }
  },
  'rangeRestriction.min': {
    label: 'range limiting floor',
    defaultValue: 0,
    transform: {
      in: (value, form) => {
        if (form.getField('rangeRestriction.units').value === 'raw') {
          return transformUnitValueIn(getOutsortAggregateUnit(form.model.currentQueryState), value);
        }
        return value;
      },
      out: (value, form) => {
        if (form.getField('rangeRestriction.units').value === 'raw') {
          return transformUnitValueOut(getOutsortAggregateUnit(form.model.currentQueryState), value);
        }
        return value;
      }
    }
  },
  'ranges[].to': {
    label: 'to:',
    messages: {
      numeric: 'Range value must be numeric',
      required: 'Range end value must be present'
    },
    transform: {
      in: (value, form) => {
        const type = form.getField('type').value;
        if (type === 'percentages' || type === 'percentiles') {
          return value;
        }
        return transformUnitValueIn(getOutsortAggregateUnit(form.model.currentQueryState), value);
      },
      out: (value, form) => {
        const type = form.getField('type').value;
        if (type === 'percentages' || type === 'percentiles') {
          return value * 1;
        }
        return transformUnitValueOut(getOutsortAggregateUnit(form.model.currentQueryState), value);
      }
    }
  },
  'ranges[].data.value': {
    label: 'color',
    placeholder: 'Select A Color',
    rules: 'required',
    messages: {
      required: 'Must select color for range'
    },
    options: ['#3a80eb', '#0f9960', '#ff850e', '#d65b4a', '#a82a2b']
  },
  over: {
    label: 'color',
    placeholder: 'Select A Color',
    rules: 'required',
    messages: {
      required: 'Must select color for range'
    },
    options: ['#3a80eb', '#0f9960', '#ff850e', '#d65b4a', '#a82a2b']
  }
};

export { fields, options, transformUnitValueIn, transformUnitValueOut, getOutsortAggregateUnit };
