import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { AnchorButton, Intent, Position, Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import $lookups from 'stores/$lookups';
import { getASNType } from './types';

const asNameMap = observable.map();

function fetchAsName(asn) {
  if (Number.isNaN(Number(asn))) return;
  if (asNameMap.has(asn)) return;

  asNameMap.set(asn, '');

  $lookups.asNumbers(asn, { limit: 1, debounce: false }).then(
    action(results => {
      if (results[0] && results[0].value === asn) {
        asNameMap.set(asn, results[0].matcher);
      } else {
        asNameMap.set(asn, '-');
      }
    })
  );
}

@observer
class ListASNs extends Component {
  render() {
    const { model } = this.props;
    const editable = this.props.editable && Boolean(model);
    const asNumbers = this.props.asNumbers || (model ? model.get('asNumbers') : []);
    const className = classNames('pt-table', 'pt-condensed', { 'pt-interactive': editable });
    const tdStyle = { verticalAlign: 'middle', cursor: 'default' };

    asNumbers.forEach(asn => fetchAsName(asn));

    return (
      <table className={className}>
        <thead>
          <tr>
            <th>ASN</th>
            <th>Name</th>
            <th>Type</th>
            {editable && <th />}
          </tr>
        </thead>
        <tbody>
          {asNumbers.map(asn => (
            <tr key={asn}>
              <td style={tdStyle}>{asn}</td>
              <td style={tdStyle}>{asNameMap.get(asn)}</td>
              <td style={tdStyle}>{getASNType(asn)}</td>
              {editable && (
                <td style={{ ...tdStyle, textAlign: 'right' }}>
                  <Tooltip
                    content="AS Groups must contain at least one AS"
                    isDisabled={asNumbers.length > 1}
                    position={Position.LEFT}
                  >
                    <AnchorButton
                      className="pt-minimal"
                      intent={Intent.DANGER}
                      onClick={() => model.removeASN(asn)}
                      iconName="trash"
                      disabled={asNumbers.length === 1}
                    />
                  </Tooltip>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default ListASNs;
