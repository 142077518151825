import React, { Component } from 'react';
import { action, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Responsive } from 'react-grid-layout';
import SplitPane from 'react-split-pane';
import classNames from 'classnames';

import { Flex } from 'components/flexbox';
import Dashboard from 'models/dashboards/Dashboard';

import DashboardItem from './DashboardItem/DashboardItem';
import dashboardWidthProvider from './DashboardWidthProvider';

const ResponsiveReactGridLayout = dashboardWidthProvider(Responsive);

const splitPaneProps = {
  split: 'horizontal',
  paneStyle: { padding: 0, overflow: 'hidden' },
  pane2Style: { padding: 1, display: 'flex', overflow: 'auto' },
  defaultSize: 'calc(50vh - 25px)'
};

@inject('$dashboards', '$dashboard')
@observer
class DashboardItemGrid extends Component {
  @observable
  splitPaneHeight;

  @observable
  dockCollapsed = false;

  splitPane;

  previousSplitPaneHeight;

  parentPanelDisposer;

  componentWillMount() {
    const { parentPanel, $dashboard } = this.props;
    if (parentPanel) {
      this.fetchParentPanelItem(parentPanel);
    }

    const layout = $dashboard.dashboard.get('layout');
    const savedSplitPaneHeight = window.localStorage[`kentik_splitpaneheight_${$dashboard.dashboard.get('id')}`];

    const splitPaneHeight = savedSplitPaneHeight || layout.cp;
    this.splitPaneHeight = splitPaneHeight ? parseInt(savedSplitPaneHeight) : undefined;
  }

  componentWillReceiveProps(nextProps) {
    const { parentPanel, collapsed } = nextProps;
    if (parentPanel && parentPanel !== this.props.parentPanel) {
      this.fetchParentPanelItem(parentPanel);
    }

    if (collapsed !== this.props.collapsed) {
      this.setDockCollapsed(collapsed);
    }
  }

  componentDidUpdate() {
    const { checkScroll } = this.props;
    if (checkScroll) {
      checkScroll();
    }
  }

  componentWillUnmount() {
    if (this.parentPanelDisposer) {
      this.parentPanelDisposer();
    }
  }

  fetchParentPanelItem = parentPanel => {
    const { $dashboards, $dashboard } = this.props;

    if (parentPanel.parentDashboardId) {
      const parentDashboard = $dashboards.getDashboard(parentPanel.parentDashboardId);

      parentDashboard.fetch().then(
        action(() => {
          $dashboard.parentPanelItem = parentDashboard.get('items').get(parentPanel.parentPanelId);

          const { parentPanelItem } = $dashboard;
          const { parentPanelVisibility } = parentPanelItem.get('dashboard_navigation_options.nesting');

          if (parentPanelVisibility !== 'hidden') {
            this.setDockCollapsed(parentPanelVisibility === 'collapsed');
          }

          this.parentPanelDisposer = reaction(
            () => parentPanelItem.dataview.fullyLoaded,
            () => {
              const { queryResults } = parentPanelItem.queryBuckets[0];
              const selectedModels = [];

              parentPanel.selectedModels.forEach(selectedModelKey => {
                const matchingModel = queryResults.find({ key: selectedModelKey });
                if (matchingModel) {
                  selectedModels.push(matchingModel);
                }
              });

              if (selectedModels.length) {
                parentPanelItem.selectedModels = selectedModels;
                parentPanelItem.dataview.setSelectedModels(selectedModels);
              } else {
                parentPanelItem.dataview.setSelectedModels(parentPanel.selectedModels);
              }

              this.parentPanelDisposer();
              this.parentPanelDisposer = null;
            },
            {
              delay: 100
            }
          );

          parentPanelItem.applyFullQuery(parentPanel.parentPanelQuery);
        })
      );
    }
  };

  onLayoutChange = (currentBreakpointLayout, layout) => {
    const { dashboard } = this.props.$dashboard;
    const { lg, sm } = dashboard.get('layout') || {};

    dashboard.set('layout', layout);

    if ((lg && layout.lg && lg.length !== layout.lg.length) || (sm && layout.sm && sm.length !== layout.sm.length)) {
      dashboard.updateLayout();
    }
  };

  onResizeStop = (layout, item) => {
    const { dashboard } = this.props.$dashboard;
    const dashboardItem = dashboard.get('items').get(item.i);

    if (dashboardItem) {
      setTimeout(() => dashboardItem.reflow(), 10);
    }
  };

  splitPaneRef = ref => {
    this.splitPane = ref;
  };

  @action
  handlePaneResize = size => {
    const { $dashboard } = this.props;

    setTimeout(() => {
      if ($dashboard.parentPanelItem) {
        $dashboard.parentPanelItem.reflow();
      }
      $dashboard.reflowItems();
    }, 50);

    this.splitPaneHeight = size;

    const layout = $dashboard.dashboard.get('layout');
    $dashboard.dashboard.set('layout', { ...layout, cp: size });
    $dashboard.dashboard.updateLayout();

    if (!$dashboard.dashboard.canSave) {
      window.localStorage.setItem(`kentik_splitpaneheight_${$dashboard.dashboard.get('id')}`, size);
    }
  };

  toggleDockCollapsed = () => {
    this.setDockCollapsed(!this.dockCollapsed);
  };

  @action
  setDockCollapsed(collapsed) {
    const { $dashboard } = this.props;

    this.dockCollapsed = collapsed;

    if (this.dockCollapsed) {
      this.previousSplitPaneHeight = this.splitPaneHeight;
      this.splitPaneHeight = 67;
    } else {
      this.splitPaneHeight = this.previousSplitPaneHeight || 200;

      setTimeout(() => {
        if ($dashboard.parentPanelItem) {
          $dashboard.parentPanelItem.reflow();
        }
      }, 10);
    }
  }

  getDefaultSize(layout) {
    const {
      $dashboard: { parentPanelItem }
    } = this.props;
    return layout.cp || (parentPanelItem ? 400 : 200);
  }

  renderDockedItems = (layout, defaultSize) => {
    const { $dashboard, onEditItem } = this.props;
    const { isEditing, parentPanelItem } = $dashboard;

    const dashboardItemClassNames = classNames('flex', {
      'pt-elevation-1 pt-interactive dashboard-item-editing': isEditing
    });

    return (
      <div
        key="dock"
        className={dashboardItemClassNames}
        style={{
          height: this.splitPaneHeight || defaultSize,
          width: '100%'
        }}
      >
        {parentPanelItem && (
          <DashboardItem
            item={parentPanelItem}
            dashboard={parentPanelItem.get('dashboard')}
            onEdit={isEditing ? onEditItem : null}
            onToggleCollapsed={this.toggleDockCollapsed}
            collapsed={this.dockCollapsed}
            isParentPanel
          />
        )}
      </div>
    );
  };

  renderDashboardItem = item => {
    const { $dashboard, onEditItem, navigationHistory } = this.props;
    const { dashboard, isEditing } = $dashboard;

    const { completedInitialParametricSubmit } = dashboard;

    const dashboardItemClassNames = classNames('pt-card', 'flex', {
      'pt-elevation-1 pt-interactive dashboard-item-editing': isEditing
    });

    return (
      <div key={item.id} className={dashboardItemClassNames}>
        <DashboardItem
          item={item}
          dashboard={dashboard}
          completedInitialParametricSubmit={completedInitialParametricSubmit}
          onEdit={isEditing ? onEditItem : null}
          navigationHistory={navigationHistory}
        />
      </div>
    );
  };

  render() {
    const { $dashboard } = this.props;
    const { dashboard, isDraggable, isEditing, isResizable, parentPanelItem } = $dashboard;
    const { breakpoints, cols, margin, containerPadding, rowHeight } = Dashboard.layoutDefaults;
    const hasParentPanel =
      parentPanelItem && parentPanelItem.get('dashboard_navigation_options.nesting.parentPanelVisibility') !== 'hidden';

    const layout = dashboard.generateItemLayout({ returnLayout: true });

    const itemGrid = (
      <ResponsiveReactGridLayout
        breakpoints={breakpoints}
        cols={cols}
        containerPadding={containerPadding}
        draggableHandle={isDraggable && isResizable ? null : '.matches-nothin'}
        layouts={layout}
        margin={margin}
        onLayoutChange={this.onLayoutChange}
        onResizeStop={this.onResizeStop}
        rowHeight={rowHeight}
        className={isEditing ? 'editing' : undefined}
        style={{ width: '100%' }}
      >
        {dashboard.get('items').models.map(this.renderDashboardItem)}
      </ResponsiveReactGridLayout>
    );

    if (hasParentPanel) {
      const defaultSize = this.getDefaultSize(layout);

      return (
        <Flex flexAuto style={{ position: 'relative', overflow: 'hidden' }}>
          <SplitPane
            onDragFinished={this.handlePaneResize}
            maxSize={-120}
            minSize={56}
            {...splitPaneProps}
            defaultSize={defaultSize}
            ref={this.splitPaneRef}
            size={this.splitPaneHeight}
          >
            {this.renderDockedItems(layout, defaultSize)}
            {itemGrid}
          </SplitPane>
        </Flex>
      );
    }

    return itemGrid;
  }
}

export default DashboardItemGrid;
