import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

@inject('$library')
@observer
class FavoriteButton extends Component {
  handleToggleFavorite = () => {
    const { $library, report } = this.props;
    $library.toggleReportFavorite(report);
  };

  render() {
    const { report, style } = this.props;

    const isFavorite = report.get('favorite');
    const iconName = isFavorite ? 'star' : 'star-empty';
    const styles = { marginLeft: 4, position: 'relative', top: -7, ...style };
    const className = classNames('report-favorite-button', {
      favorite: isFavorite
    });

    return <Button className={className} iconName={iconName} style={styles} onClick={this.handleToggleFavorite} />;
  }
}

export default FavoriteButton;
