export const ALERT_DIMENSIONS_WHITELIST = new Set([
  'AS_src',
  'Geography_src',
  'IP_src',
  'InterfaceID_src',
  'Port_src',
  'VLAN_src',
  'src_bgp_aspath',
  'src_bgp_community',
  'src_eth_mac',
  'src_geo_city',
  'src_geo_region',
  'src_nexthop_asn',
  'src_nexthop_ip',
  'src_route_prefix_len',
  'src_second_asn',
  'src_third_asn',
  'AS_dst',
  'Geography_dst',
  'IP_dst',
  'InterfaceID_dst',
  'Port_dst',
  'VLAN_dst',
  'dst_bgp_aspath',
  'dst_bgp_community',
  'dst_eth_mac',
  'dst_geo_city',
  'dst_geo_region',
  'dst_nexthop_asn',
  'dst_nexthop_ip',
  'dst_route_prefix_len',
  'dst_second_asn',
  'dst_third_asn',
  'Proto',
  'TOS',
  'Traffic',
  'i_device_id',
  'i_device_site_name',
  'inet_family',
  'tcp_flags',
  'sampledpktsize',
  'sampledpktsize_100',
  'src_cdn',
  'dst_cdn',
  'src_threat_type',
  'dst_threat_type',
  'src_threat_bnetcc',
  'dst_threat_bnetcc',
  'src_threat_host',
  'dst_threat_host',
  'Application Context & Security',
  'Cloud',
  'Custom',
  'i_trf_origination',
  'i_trf_termination',
  'i_trf_profile',
  'i_host_direction',
  'i_src_connect_type_name',
  'i_dst_connect_type_name',
  'i_src_network_bndry_name',
  'i_dst_network_bndry_name',
  'i_ult_exit_network_bndry_name',
  'i_ult_exit_connect_type_name',
  'i_src_provider_classification',
  'i_dst_provider_classification',
  'i_ult_exit_site',
  'i_ult_exit_device_name',
  'bgp_ult_exit_interface',
  'kt_src_market',
  'kt_dst_market',
  'input_vrf',
  'i_input_vrf',
  'i_input_vrf_rd',
  'i_input_vrf_rt',
  'output_vrf',
  'i_output_vrf',
  'i_output_vrf_rd',
  'i_output_vrf_rt'
]);

export const ALERT_METRIC_OPTIONS = [
  {
    value: 'bits',
    label: 'Bits/second'
  },
  {
    value: 'packets',
    label: 'Packets/second'
  },
  {
    value: 'retransmits_in',
    label: 'TCP Retransmits/second',
    isKProbeMetric: true
  },
  {
    value: 'perc_retransmits_in',
    label: '% TCP Retransmits',
    isKProbeMetric: true
  },
  {
    value: 'out_of_order_in',
    label: 'Out Of Order TCP Packets/second',
    isKProbeMetric: true
  },
  {
    value: 'perc_out_of_order_in',
    label: '% Out Of Order TCP Packets',
    isKProbeMetric: true
  },
  {
    value: 'fragments',
    label: 'Fragmented TCP Packets/second',
    isKProbeMetric: true
  },
  {
    value: 'perc_fragments',
    label: '% Fragmented TCP Packets',
    isKProbeMetric: true
  },
  {
    value: 'client_latency',
    label: 'TCP Client Latency (ms)',
    isKProbeMetric: true
  },
  {
    value: 'server_latency',
    label: 'TCP Server Latency (ms)',
    isKProbeMetric: true
  },
  {
    value: 'appl_latency',
    label: 'TCP Application Latency (ms)',
    isKProbeMetric: true
  },
  {
    value: 'fpex_latency_ms',
    label: 'First Payload Exchange Latency (ms)',
    isKProbeMetric: true
  },
  {
    value: 'fps',
    label: 'Flows/second'
  },
  {
    value: 'unique_src_ip',
    label: 'Unique Source IPs'
  },
  {
    value: 'unique_dst_ip',
    label: 'Unique Destination IPs'
  },
  {
    value: 'unique_src_port',
    label: 'Unique Source Ports'
  },
  {
    value: 'unique_dst_port',
    label: 'Unique Destination Ports'
  },
  {
    value: 'unique_src_as',
    label: 'Unique Source ASNs'
  },
  {
    value: 'unique_dst_as',
    label: 'Unique Destination ASNs'
  },
  {
    value: 'unique_dst_nexthop_asn',
    label: 'Unique Destination Nexthop ASNs'
  },
  {
    value: 'unique_src_geo',
    label: 'Unique Source Geo Identifiers'
  },
  {
    value: 'unique_dst_geo',
    label: 'Unique Destination Geo Identifiers'
  },
  {
    value: 'unique_src_region',
    label: 'Unique Source Regions'
  },
  {
    value: 'unique_dst_region',
    label: 'Unique Destination Regions'
  },
  {
    value: 'unique_src_city',
    label: 'Unique Source Cities'
  },
  {
    value: 'unique_dst_city',
    label: 'Unique Destination Cities'
  }
];

export const privateIpOptions = [
  {
    value: '0.0.0.0/8',
    label: '0.0.0.0/8',
    description: 'Default route, broadcast to the current (“this”)'
  },
  {
    value: '127.0.0.0/8',
    label: '127.0.0.0/8',
    description: 'Localhost loopback net'
  },
  {
    value: '100.64.0.0/10',
    label: '100.64.0.0/10',
    description: 'CGN (Carrier Grade NAT) reserved range'
  },
  {
    value: '10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16',
    label: '10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16',
    description: 'RFC 1918 IPv4 Private Networks'
  },
  {
    value: '169.254.0.0/16',
    label: '169.254.0.0/16',
    description: 'APIPA (auto-ip address for failed DHCP requests)'
  },
  {
    value: '192.0.0.0/24',
    label: '192.0.0.0/24',
    description: 'IANA special purpose IPv4 space'
  },
  {
    value: '192.0.2.0/24',
    label: '192.0.2.0/24',
    description: 'TEST-NET for use in documentation'
  },
  {
    value: '198.18.0.0/15',
    label: '198.18.0.0/15',
    description: 'Private Network - reserved for testing'
  },
  {
    value: '198.51.100.0/24',
    label: '198.51.100.0/24',
    description: 'TEST-NET-2 for use in documentation'
  },
  {
    value: '203.0.113.0/24',
    label: '203.0.113.0/24',
    description: 'TEST-NET-3 for use in documentation'
  },
  {
    value: '224.0.0.0/4',
    label: '224.0.0.0/4',
    description: 'Reserved for Multicast'
  },
  {
    value: '192.88.99.0/24',
    label: '192.88.99.0/24',
    description: 'Reserved for 6to4 anycast relays'
  },
  {
    value: '240.0.0.0/4',
    label: '240.0.0.0/4',
    description: 'Reserved for future use'
  },
  {
    value: 'fc00::/7',
    label: 'fc00::/7',
    description: 'RFC 4193 IPv6 Unique Local Address (ULA)'
  },
  {
    value: 'fe80::/10',
    label: 'fe80::/10',
    description: 'IPv6 link-local Address'
  }
];

export const PERCENT_METRICS = ['perc_retransmits_in', 'perc_out_of_order_in', 'perc_fragments'];

export const THRESHOLD_SEVERITIES = ['critical', 'major2', 'major', 'minor2', 'minor'];

export const ADDON_TYPES = {
  flowPak: { type: 'flowpak', name: 'FlowPak' },
  cloudPak: { type: 'cloudpak', name: 'CloudPak' },
  customDimension: { type: 'customdimension', name: 'Custom Dimension' },
  fastRetention: { type: 'fastretention', name: 'Traffic Retention (fast)' },
  fullRetention: { type: 'fullretention', name: 'Forensic Retention (full)' }
};

export const ADDON_TYPE_NAME_MAP = {};
Object.keys(ADDON_TYPES).reduce((acc, typeKey) => {
  acc[ADDON_TYPES[typeKey].type] = ADDON_TYPES[typeKey].name;
  return acc;
}, ADDON_TYPE_NAME_MAP);

export const PLAN_TYPES = {
  core: { code: 'core', name: 'Core Devices' },
  edge: { code: 'edge', name: 'Edge Devices' },
  cloud: { code: 'cloud', name: 'Cloudpak' },
  flowpak: { code: 'flowpak', name: 'Flowpak' },
  legacy: { code: 'legacy', name: 'Legacy' },
  metrics: { code: 'metrics', name: 'Metrics' },
  ksynth: { code: 'ksynth', name: 'Synthetics' },
  universalpak: { code: 'universalpak', name: 'Universalpak' }
};

export const PLAN_SIZES = {
  '1GB': { code: '1GB', name: 'Up to 1 GB' },
  '10GB': { code: '10GB', name: 'Up to 10 GB' },
  '100GB': { code: '100GB', name: 'Up to 100 GB' },
  '1000GB': { code: '1000GB', name: 'Up to 1 TB' }
};

export const RPKI_OPTIONS = [
  {
    label: 'RPKI Unknown',
    value: 'RPKI Unknown'
  },
  {
    label: 'RPKI Valid',
    value: 'RPKI Valid'
  },
  {
    label: 'RPKI Invalid - covering Valid/Unknown',
    value: 'RPKI Invalid - covering Valid/Unknown'
  },
  {
    label: 'RPKI Invalid - Will be dropped',
    value: 'RPKI Invalid - Will be dropped'
  }
];
