import React from 'react';
import { observer } from 'mobx-react';

import { getOption, getFieldSelectOptions } from './selectHelpers';

const ModalValuesPanel = props => {
  const {
    valueRenderer: SingleValue,
    multiValuesRenderer: MultiValues,
    multi,
    allowEmpty,
    emptyRenderer: EmptyValue,
    selectState
  } = props;

  const { values, openOptions, selectItem } = selectState;

  if (allowEmpty && EmptyValue && values.length === 0) {
    return <EmptyValue onClick={openOptions} />;
  }

  const options = getFieldSelectOptions(props);

  if (multi && MultiValues) {
    return <MultiValues {...props} values={values} options={options} onUnselect={selectItem} />;
  }

  return values.map(value => {
    const option = getOption(options, value);
    return <SingleValue {...props} key={value} onClick={openOptions} values={values} option={option} />;
  });
};

export default observer(ModalValuesPanel);
