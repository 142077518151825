import React, { Component } from 'react';
import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import { DropdownMenu } from 'components';
import { StandaloneDimensionSelector } from 'components/forms';
import { defaultPrimaryOverlayColor } from 'models/Colors';
import { getModelFilters } from 'services/filters';

export default class LegendRowOptions extends Component {
  state = {
    dimensionSelectorOpen: false
  };

  getModelFilters(include = true, overrideMetric) {
    const {
      model,
      bucket,
      dataview: { formState }
    } = this.props;
    return getModelFilters({ model, bucket, formState }, { include, overrideMetric });
  }

  pivot() {
    this.props.dataview.gotoDefaultDash({ filters: this.getModelFilters() });
  }

  applyOverrides(overrides) {
    const { dataview } = this.props;
    const { formState } = dataview;
    if (formState) {
      if (overrides.filters) {
        overrides['filters.connector'] = overrides.filters.connector;
        overrides['filters.filterGroups'] = overrides.filters.filterGroups;
        delete overrides.filters;
      }
      formState.setValues(overrides);
    } else {
      dataview.applyToAllQueries(overrides);
    }
  }

  include(dimension) {
    this.applyOverrides({ filters: this.getModelFilters(true, dimension) });
  }

  exclude(dimension) {
    this.applyOverrides({ filters: this.getModelFilters(false, dimension) });
  }

  showBy = metric => {
    // Note: we don't need to change the state because the cmp is destroyed from the click event
    this.applyOverrides({ filters: this.getModelFilters(), metric });
  };

  showShowBySelector = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ dimensionSelectorOpen: true });
  };

  hideShowBySelect = () => {
    this.setState({ dimensionSelectorOpen: false });
  };

  render() {
    const { dimensionSelectorOpen } = this.state;
    const { model, bucket } = this.props;
    const subincludes = [];
    const subexcludes = [];

    const metric = bucket.firstQuery.get('metric');
    if (metric.length > 1) {
      const values = (model.get('lookup') || model.get('key')).split(' ---- ');
      metric.forEach((dimension, index) => {
        const value = values[index];
        subincludes.push({
          key: dimension,
          iconName: 'th-derived',
          text: value,
          onClick: () => this.include(dimension)
        });
        subexcludes.push({
          key: dimension,
          iconName: 'exclude-row',
          text: value,
          onClick: () => this.exclude(dimension)
        });
      });
    }

    return (
      <DropdownMenu
        content={
          <Menu>
            <MenuItem iconName="pivot" text="Pivot" onClick={() => this.pivot()} />
            <MenuItem
              iconName="th-derived"
              text="Include"
              className="pt-popover-dismiss"
              onClick={() => this.include()}
              submenu={subincludes.length ? subincludes : undefined}
            />
            <MenuItem
              iconName="exclude-row"
              text="Exclude"
              className="pt-popover-dismiss"
              onClick={() => this.exclude()}
              submenu={subexcludes.length ? subexcludes : undefined}
            />
            <MenuDivider />
            <MenuItem iconName="pivot-table" text="Show By..." onClick={this.showShowBySelector} />
          </Menu>
        }
        tetherOptions={{ offset: '-4px 18px' }}
      >
        <span>
          <Button
            className="pt-minimal pt-small"
            iconName="menu"
            style={{ whiteSpace: 'nowrap', color: defaultPrimaryOverlayColor }}
          />
          {dimensionSelectorOpen && (
            <StandaloneDimensionSelector
              title="Show By Dimensions"
              isOpen={dimensionSelectorOpen}
              onSave={this.showBy}
              onClose={this.hideShowBySelect}
              saveButtonText="Show By Selected Dimensions"
              multi
            />
          )}
        </span>
      </DropdownMenu>
    );
  }
}
