import { computed } from 'mobx';
import BaseModel from 'models/BaseModel';

class QueryResultModel extends BaseModel {
  get url() {
    return '';
  }

  get defaults() {
    return {
      isOverlay: false,
      sampleRate: 1,
      enabled: true
    };
  }

  @computed
  get hasRawData() {
    const { rawData } = this.get();
    return rawData && !!Object.keys(rawData).length;
  }

  @computed
  get isOverlay() {
    return this.get('isOverlay');
  }
}

export default QueryResultModel;
