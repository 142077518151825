import { action, observable } from 'mobx';

import InterfaceCollection from 'models/interfaces/InterfaceCollection';

class InterfacesStore {
  @observable
  collection = new InterfaceCollection();

  @action
  fetchInterfacesForDevice(device) {
    this.collection.deviceId = device.id;
    return this.collection.fetch();
  }
}

export default new InterfacesStore();
