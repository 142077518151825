import React from 'react';
import { observer } from 'mobx-react';
import $dictionary from 'stores/$dictionary';
import $app from 'stores/$app';

import { getToFixed, zeroToText, adjustByGreekPrefix, greekPrefix, escapeHtml, PREFIXABLE_UNITS } from 'util/utils';
import { Dialog } from '@blueprintjs/core';
import { Flex, Box } from 'components/flexbox';

import DataViewWrapper from 'dataviews/DataViewWrapper';
import DataViewModel from 'models/DataViewModel';
import DataViewTools from 'dataviews/DataViewTools';
import { ChangeViewTypeMenu } from 'dataviews/tools/index';
import QueryModel from 'models/query/QueryModel';

import Highcharts from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';
import { getBracketingDataClasses } from 'services/bracketing';
import BaseHighchartsDataview from './BaseHighchartsDataview';

Heatmap(Highcharts);

@observer
export default class MatrixView extends BaseHighchartsDataview {
  state = {
    subview: null
  };

  chartOptions = {
    chart: {
      plotBorderWidth: 1
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    legend: {
      margin: 0,
      symbolHeight: 300,
      layout: 'vertical',
      verticalAlign: 'bottom',
      align: 'right'
    },
    colorAxis: {
      min: 0,
      minColor: '#FFFFFF',
      maxColor: '#F36F21',
      labels: {
        style: {
          color: `${this.chartLabelColor} !important`
        }
      }
    },
    xAxis: {
      categories: [],
      opposite: true,
      title: {
        text: '', //
        style: { fontWeight: 800 }
      },
      labels: {
        style: {
          cursor: 'pointer',
          color: this.chartLabelColor
        }
      }
    },
    yAxis: {
      categories: [],
      reversed: true,
      title: {
        text: '',
        style: { fontWeight: 800 }
      },
      labels: {
        style: {
          cursor: 'pointer',
          color: this.chartLabelColor
        }
      }
    },
    series: [
      {
        type: 'heatmap',
        name: '',
        allowPointSelect: false,
        borderWidth: 1,
        cursor: 'pointer',
        data: [],
        dataLabels: {
          color: '#000000',
          enabled: true,
          style: { color: '#000000', cursor: 'pointer', textOutline: 'none' },
          formatter() {
            const { point } = this;
            const metadata = point.options.metadata;
            return escapeHtml(zeroToText(adjustByGreekPrefix(point.value, metadata.prefix), { fix: metadata.fix }));
          }
        },
        point: {
          events: {
            click() {
              const { options } = this.series;
              const { dataview } = options;

              dataview.setState({ subview: new DataViewModel() });
              const parentQuery = JSON.parse(
                JSON.stringify(dataview.props.dataview.queryBuckets.activeBuckets[0].firstQuery.get())
              );
              parentQuery.metric = parentQuery.metric.concat(parentQuery.matrixBy);
              parentQuery.matrixBy = [];
              parentQuery.viz_type = 'sankey'; // 'line';
              parentQuery.bucket = 'Flow'; // 'Left +Y Axis';
              // TODO: other options with filters ?
              const query = QueryModel.create();
              query.set(parentQuery);
              dataview.state.subview.initializeQueries([
                {
                  bucket: 'Flow', // 'Left +Y Axis',
                  query: query.serialize()
                }
              ]);
            }
          }
        }
      }
    ],
    tooltip: {
      formatter() {
        const { point } = this;
        const metadata = point.options.metadata;
        const { prefix, displayUnit, fix } = metadata;
        return `<b>${this.series.yAxis.categories[this.point.y]} \u2192 ${
          this.series.xAxis.categories[this.point.x]
        }</b><br/>${escapeHtml(zeroToText(adjustByGreekPrefix(point.value, prefix), { fix }))} ${prefix}${displayUnit}`;
      }
    }
  };

  updateChartForBracketing({ metadata, bracketOptions, matrixMetadata }) {
    const legendTitle =
      bracketOptions.type === 'staticRanges' ? '' : `Units: ${matrixMetadata.prefix}${matrixMetadata.displayUnit}`;

    this.chart.colorAxis[0].update({ dataClasses: getBracketingDataClasses({ metadata, bracketOptions }) }, false);
    this.chart.legend.update(
      {
        symbolHeight: undefined,
        layout: 'horizontal',
        itemStyle: { color: `${this.chartLabelColor} !important` },
        title: {
          text: legendTitle,
          style: { color: `${this.chartLabelColor} !important` }
        }
      },
      false
    );
    this.chart.series[0].update({ dataLabels: { color: '#FFFFFF', style: { textOutline: false } } }, false);
  }

  matrixMetadata(matrix) {
    const { activeBuckets } = this.props.dataview.queryBuckets;
    const unit = activeBuckets[0].firstQuery.outsortUnit;
    return {
      unit,
      prefix: PREFIXABLE_UNITS.includes(unit) ? greekPrefix(matrix.map(matrixPoint => matrixPoint[2]), 1) : '',
      displayUnit: $dictionary.dictionary.units[unit],
      fix: getToFixed(unit)
    };
  }

  componentDidUpdate() {
    const { activeBuckets, activeBucketCount } = this.props.dataview.queryBuckets;
    if (!activeBucketCount || !activeBuckets[0].queryResults.size || !this.chart) {
      return;
    }

    this.clear();
    const row = activeBuckets[0].queryResults.at(0);
    const matrix = row.get('matrix');
    if (matrix) {
      // assumes matrix implies primary and secondary are present
      this.chart.yAxis[0].setCategories(row.get('primary'), false);
      this.chart.xAxis[0].setCategories(row.get('secondary'), false);
      const matrixMetadata = this.matrixMetadata(matrix);
      this.chart.series[0].setData(
        matrix.map(map => ({ x: map[1], y: map[0], value: map[2], metadata: matrixMetadata })),
        false
      );

      // TODO: for now ignore where specific type of tag_
      const tagDataKey = Object.keys(row.toJS()).find(prop => prop.startsWith('tag_'));
      const tagData = tagDataKey ? row.get(tagDataKey) : null;
      // add bracketing dataclasses
      if (tagData && tagData.isMatrix) {
        const { metadata, bracketOptions } = tagData;
        this.updateChartForBracketing({ metadata, bracketOptions, matrixMetadata });
      }

      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
        }
      });
      $app.renderSync(() => {
        this.dismissSpinner();
      });
    }
  }

  redraw({ setColors = false } = {}) {
    if (this.chart) {
      if (setColors) {
        this.chart.xAxis[0].update({ labels: { style: { color: this.chartLabelColor } } }, false);
        this.chart.yAxis[0].update({ labels: { style: { color: this.chartLabelColor } } }, false);
        this.chart.colorAxis[0].update({ labels: { style: { color: `${this.chartLabelColor} !important` } } }, false);
      }
      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
        }
      });
    }
  }

  clear() {
    if (this.chart) {
      this.chart.series[0].setData([]);
    }
  }

  getComponent() {
    const { chartOptions, state, props } = this;
    const { dataview } = props;
    const { subview } = state;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;
    const { dictionary } = $dictionary;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;
    const { metric: metrics, matrixBy } = query.get();

    chartOptions.xAxis.title.text = matrixBy.map(metric => dictionary.chartTypesValidations[metric]).join(', ');
    chartOptions.yAxis.title.text = metrics.map(metric => dictionary.chartTypesValidations[metric]).join(', ');
    chartOptions.series[0].name = dictionary.units[query.outsortUnit];
    chartOptions.series[0].dataview = this;

    const dataViewTools = (
      <DataViewTools dataview={subview}>
        {({ Wrapper, ...rest }) => (
          <Wrapper>
            <ChangeViewTypeMenu {...rest} />
          </Wrapper>
        )}
      </DataViewTools>
    );

    return (
      <div style={{ height: '100%' }}>
        {super.getComponent()}
        {!!subview && (
          <Dialog
            canEscapeKeyClose
            isOpen={!!subview}
            transitionName="pt-dialog"
            style={{ height: '60vh', width: '80vw', padding: 8 }}
            onClose={() => {
              subview.destroy();
              this.setState({ subview: null });
            }}
          >
            <Flex flexColumn flexAuto className="pt-card flat">
              <DataViewWrapper
                dataview={subview}
                viewProps={{
                  showNativeLegend: true
                }}
                headerProps={{
                  showTitleLink: false,
                  tools: dataViewTools
                }}
              >
                {({ component, header, className }) => (
                  <Flex className={className} flexAuto flexColumn>
                    <Box m={2} mb={0}>
                      {header}
                    </Box>
                    {component}
                  </Flex>
                )}
              </DataViewWrapper>
            </Flex>
          </Dialog>
        )}
      </div>
    );
  }
}

const config = {
  showTotalTrafficOverlay: false,
  showHistoricalOverlay: false,
  showLegend: false,
  timeBased: false,
  enableToggle: false,
  suppressLastPointBracketing: true,
  suppressBracketingGroupOption: true,
  suppressGeneratorMode: true,
  buckets: [
    {
      name: 'Matrix'
    }
  ]
};

export { config };
