import React from 'react';
import sizeMe from 'react-sizeme';

const sizeMeHOC = sizeMe({
  monitorWidth: true,
  monitorHeight: false,
  refreshRate: 16
});

let GridLayoutComponent;

export default function dashboardWidthProvider(GridLayout) {
  GridLayoutComponent = GridLayout;
  const WrappedGridLayout = props => <GridLayoutComponent {...props} width={props.size.width} />;
  return sizeMeHOC(WrappedGridLayout);
}
