import $dictionary from 'stores/$dictionary';

function getFilterFields(groups) {
  const filterFields = new Set();

  if (Array.isArray(groups)) {
    groups.forEach(group => {
      if (Array.isArray(group.filters)) {
        group.filters.forEach(filter => filterFields.add(filter.filterField));
      }

      getFilterFields(group.filterGroups || []).forEach(field => filterFields.add(field));
    });
  }

  return Array.from(filterFields);
}

export const getAppProtocol = (metrics, filters) => {
  const protocolMetric =
    metrics.find(metric => metric.startsWith('ktappprotocol')) ||
    getFilterFields(filters).find(filter => filter.startsWith('ktappprotocol'));

  if (protocolMetric) {
    const protocolName = protocolMetric.split('__')[1];
    const appProtocol = $dictionary.dictionary.app_protocols[protocolName];
    return appProtocol && appProtocol.id;
  }

  return undefined;
};

export const getDisabledAppProtocolDimensions = (metrics, filters) => {
  const protocolMetric =
    metrics.find(metric => metric.startsWith('ktappprotocol')) ||
    getFilterFields(filters).find(filter => filter.startsWith('ktappprotocol'));

  if (protocolMetric) {
    const protocolName = protocolMetric.split('__')[1];
    const appProtocol = $dictionary.dictionary.app_protocols[protocolName];
    if (!appProtocol.add_to_standard_fields) {
      const disabledSubgroups = [];
      const disabled = Object.keys($dictionary.dictionary.chartTypes).filter(groupName => {
        const same = groupName !== appProtocol.display_name;
        const group = $dictionary.dictionary.chartTypes[groupName];

        if (same && group.length === 4) {
          const childGroups = Object.keys(group[3]);
          const childGroupFilter = childGroups.filter(childGroup => childGroup !== appProtocol.display_name);
          if (childGroupFilter.length !== childGroups.length) {
            disabledSubgroups.push(...childGroupFilter);
            return false;
          }
          return same;
        }
        return same;
      });

      return disabled.concat(disabledSubgroups);
    }
    return Object.values($dictionary.dictionary.app_protocols).reduce((acc, protocol) => {
      if (protocol.id !== appProtocol.id) {
        acc.push(protocol.display_name);
      }
      return acc;
    }, []);
  }
  return [];
};
