import Validator from 'validatorjs';

import after from './after';
import before from './before';
import beforeNow from './beforeNow';
import date from './date';
import dateRangeRequired from './dateRangeRequired';
import email from './email';
import hex from './hex';
import imageFileType from './imageFileType';
import ip from './ip';
import ipNoCidr from './ipNoCidr';
import netmask from './netmask';
import ipPort from './ipPort';
import ipPublic from './ipPublic';
import ipv4 from './ipv4';
import ipv6 from './ipv6';
import maxDateRange from './maxDateRange';
import maxGenericAggregates from './maxGenericAggregates';
import min_if from './min_if';
import mirrorOrSecondaryMirrorOnly from './mirrorOrSecondaryMirrorOnly';
import noConflictingAggregateTypes from './noConflictingAggregateTypes';
import port from './port';
import uint32 from './uint32';
import url from './url';
import validMirrorAndSecondaryOutsort from './validMirrorAndSecondaryOutsort';
import atLeastOneIfNotAllDevices from './atLeastOneIfNotAllDevices';
import commaSeparatedEmails from './commaSeparatedEmails';
import noSpaceOrSemicolon from './noSpaceOrSemicolon';
import commaSeparatedIntegers from './commaSeparatedIntegers';
import commaSeparatedIPsCIDR from './commaSeparatedIPsCIDR';
import commaSeparatedMax from './commaSeparatedMax';
import requiredIfMultiple from './requiredIfMultiple';
import { startsWith, endsWith, sameAs } from './strings';
import {
  portValidator,
  protocolValidator,
  asnValidator,
  nextHopAsnValidator,
  tcpFlagValidator,
  countryValidator,
  macValidator,
  bgpCommunityValidator,
  bgpAspathValidator,
  interfaceNameValidator,
  vlanValidator
} from './tagValidations';

import {
  portArrayValidator,
  protocolArrayValidator,
  asnArrayValidator,
  nextHopAsnArrayValidator,
  tcpFlagsValidator,
  countryArrayValidator,
  macArrayValidator,
  bgpCommunityArrayValidator,
  bgpAspathArrayValidator,
  interfaceNameArrayValidator,
  vlanArrayValidator,
  atLeastOneTagPopulatorCriteria
} from './tagDetailValidations';
import validOptions from './validOptions';

const implicitValidators = {
  requiredIfMultiple
};

Object.keys(implicitValidators).forEach(name => {
  const { message, fn } = implicitValidators[name];
  Validator.registerImplicit(name, fn, message);
});

const validators = {
  after,
  atLeastOneIfNotAllDevices,
  before,
  beforeNow,
  date,
  dateRangeRequired,
  email,
  imageFileType,
  ip,
  ipNoCidr,
  netmask,
  ipPort,
  ipPublic,
  ipv4,
  ipv6,
  hex,
  maxDateRange,
  maxGenericAggregates,
  min_if,
  mirrorOrSecondaryMirrorOnly,
  noConflictingAggregateTypes,
  port,
  uint32,
  url,
  validMirrorAndSecondaryOutsort,
  startsWith,
  endsWith,
  sameAs,
  commaSeparatedEmails,
  commaSeparatedIPsCIDR,
  commaSeparatedMax,
  commaSeparatedIntegers,
  // begin legacy tagValidators, can be removed once migrated fully hippo
  portValidator,
  protocolValidator,
  asnValidator,
  nextHopAsnValidator,
  tcpFlagValidator,
  countryValidator,
  macValidator,
  bgpCommunityValidator,
  bgpAspathValidator,
  interfaceNameValidator,
  vlanValidator,
  // end legacy tagValidators
  // begin new tagDetailValidators
  portArrayValidator,
  protocolArrayValidator,
  asnArrayValidator,
  nextHopAsnArrayValidator,
  tcpFlagsValidator,
  countryArrayValidator,
  macArrayValidator,
  bgpCommunityArrayValidator,
  bgpAspathArrayValidator,
  interfaceNameArrayValidator,
  vlanArrayValidator,
  atLeastOneTagPopulatorCriteria,
  // end new tagDetailValidators
  validOptions,
  noSpaceOrSemicolon
};

export default function initValidators() {
  Object.keys(validators).forEach(name => {
    const { message, fn } = validators[name];
    Validator.register(name, fn, message);
  });
}
