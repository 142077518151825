import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box } from 'components/flexbox';
import Onboarding from 'views/Onboarding/Onboarding';
import LibraryEmptyState from 'views/Library/LibraryEmptyState';

@inject('$devices')
@observer
class Library extends Component {
  render() {
    const { reports, $devices } = this.props;

    return (
      <Box className="library-landing">
        {$devices.hasDevices && <LibraryEmptyState featuredReports={reports.featuredReports} />}
        {!$devices.hasDevices && <Onboarding />}
      </Box>
    );
  }
}

export default Library;
