import Validator from 'validatorjs';
import $alertingMitigation from 'stores/$alertingMitigation';
import { Address4, Address6 } from 'ip-address';

Validator.register(
  'rtbhMeetsNexthopFamily',
  function rtbhMeetsNexthopFamily(value) {
    const { pairingID } = this.validator.input;
    if (!pairingID) {
      return false;
    }

    const pair = $alertingMitigation.platformMethods.get(pairingID);
    if (!pair) {
      return false;
    }

    const mitigation_method_id = pair.get('mitigation_method_id');
    if (!mitigation_method_id) {
      return false;
    }

    const method = $alertingMitigation.methods.get(mitigation_method_id);
    if (!method) {
      return false;
    }

    const {
      method_mitigation_device_type,
      method_mitigation_device_detail: { nexthopV4, nexthopV6 }
    } = method.get();

    if (/^RTBH$/.test(method_mitigation_device_type)) {
      const value4 = new Address4(value);
      const value6 = new Address6(value);

      return (
        (value4.isValid() && nexthopV4 && new Address4(nexthopV4).isValid()) ||
        (value6.isValid() && nexthopV6 && new Address6(nexthopV6).isValid())
      );
    }

    // not an RTBH method, so just return true
    return true;
  },
  "IP to mitigate must belong to the same address family as your selected RTBH mitigation method's configured nexthop."
);

const fields = {
  pairingID: {
    label: 'Mitigation Platform and Method',
    rules: 'required'
  },
  alertKey: {
    label: 'IP/CIDR to mitigate',
    rules: 'required|ip|rtbhMeetsNexthopFamily',
    placeholder: '127.0.0.1/32',
    validateDebounceDurationMs: 500
  },
  comment: {
    label: 'Comment'
  },
  minutesBeforeAutoStop: {
    label: 'Minutes before auto stop (TTL)',
    helpText: 'Set the TTL to 0 to disable auto stop.',
    defaultValue: 0,
    rules: 'integer|min:0',
    transform: {
      out: value => parseInt(value)
    }
  }
};

export { fields };
