import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Tooltip } from '@blueprintjs/core';

@observer
export default class PivotButton extends Component {
  render() {
    const { showButtonText, buttonStyle, loading, dataview } = this.props;
    return (
      <Tooltip content="Pivot" isDisabled={showButtonText}>
        <Button
          className="pt-small"
          style={buttonStyle}
          disabled={loading}
          iconName="pivot"
          text={showButtonText ? 'Pivot' : undefined}
          onClick={() => dataview.gotoDefaultDash()}
        />
      </Tooltip>
    );
  }
}
