import React from 'react';
import { observer } from 'mobx-react';
import { Button, InputGroup } from '@blueprintjs/core';

const Filter = props => {
  const {
    collection,
    placeholder = 'Filter',
    autoFocus = true,
    style,
    clearSelectionsOnChange = false,
    ...rest
  } = props;

  const rightElement = collection.filterState ? (
    <Button
      className="pt-minimal"
      iconName="cross"
      onClick={() => {
        collection.filter('');
        if (clearSelectionsOnChange) {
          collection.clearSelection();
        }
      }}
    />
  ) : (
    undefined
  );

  return (
    <div style={style}>
      <InputGroup
        autoFocus={autoFocus}
        leftIconName="search"
        onChange={e => {
          collection.filter(e.target.value);
          if (clearSelectionsOnChange) {
            collection.clearSelection();
          }
        }}
        value={collection.filterState}
        placeholder={placeholder}
        rightElement={rightElement}
        className="pt-fill"
        {...rest}
      />
    </div>
  );
};

export default observer(Filter);
