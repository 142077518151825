import BaseModel from 'models/BaseModel';

export default class CompanyPermissions extends BaseModel {
  constructor(data) {
    super(data);
    this.companyId = data.company_id;
  }

  get isNew() {
    return false;
  }

  get urlPaths() {
    return {
      update: `/api/sudo/companies/${this.companyId}/permissions`,
      fetch: '/api/portal/companysettings/permissions'
    };
  }
}
