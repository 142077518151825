import { computed, observable } from 'mobx';
import api from 'util/api';

import NotificationCollection from 'models/alerting/NotificationCollection';

// TODO: merge into $alerts
class NotificationStore {
  @observable
  collection = new NotificationCollection();

  // TODO: put this on the collection
  @computed
  get notificationOptions() {
    return this.collection.map(model => {
      const { notification_name, id } = model.get();

      return {
        label: notification_name,
        value: id,
        iconCls: model.icon
      };
    });
  }

  saveTenantNotifications = () => api.post('/api/portal/alerts/tenant-notifications');
}

export default new NotificationStore();
