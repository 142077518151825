import $dictionary from 'stores/$dictionary';

function determineOperatorForMetric(metric, operator, cutFn) {
  const specialOps = $dictionary.dictionary.queryFilters.operators;
  const likeSwitch = operator === '=' ? 'ILIKE' : 'NOT ILIKE';

  if (cutFn && cutFn[metric]) {
    return likeSwitch;
  }

  if (specialOps[metric] && !specialOps[metric][operator] && specialOps[metric][likeSwitch]) {
    return likeSwitch;
  }

  return operator;
}

function getDistinctFilterFieldsForGroup(group = {}) {
  const { filters = [], filterGroups = [] } = group;
  let groupFilters = [];

  if (!filters.length && filterGroups.length) {
    // no filters, but has filterGroups, so use first group for "distinct" list
    groupFilters = getDistinctFilterFieldsForGroup(filterGroups[0]);

    // but if there's more than one group, make sure all the groups match
    if (
      filterGroups.length > 1 &&
      !filterGroups.every(filterGroup => {
        const filterGroupFilters = getDistinctFilterFieldsForGroup(filterGroup);
        return groupFilters.every(filterField => filterGroupFilters.includes(filterField));
      })
    ) {
      groupFilters = [];
    }
  } else if (filters.length && !filterGroups.length) {
    // has filters and no filterGroups, so just grab the filterFields
    groupFilters = filters.map(filter => filter.filterField);
  }

  // uniq the list before sending it back
  return Array.from(new Set(groupFilters));
}

function addToQueryFilters(queryFilters, filters, field, include) {
  let connector = include ? 'Any' : 'All';

  if (include && field.length && (field.length > 1 || filters.length > 1)) {
    connector = 'All';
  }

  const newGroup = {
    connector,
    filters,
    not: !include,
    named: false,
    name: '',
    filterGroups: [],
    autoAdded: true
  };

  if (queryFilters.connector === 'Any') {
    queryFilters.filterGroups = [
      {
        connector: 'Any',
        filterGroups: [...queryFilters.filterGroups]
      }
    ];
    queryFilters.connector = 'All';
  }

  const { metricToFilterParsers } = $dictionary.dictionary.queryFilters;
  const targetFilterFields = field.reduce((arr, dimension) => {
    if (metricToFilterParsers[dimension]) {
      metricToFilterParsers[dimension].forEach(parser => arr.push(parser.field));
    } else {
      arr.push(dimension);
    }
    return arr;
  }, []);

  const existingGroup = queryFilters.filterGroups.find(filterGroup => {
    const filterGroupFilterFields = getDistinctFilterFieldsForGroup(filterGroup);
    return (
      targetFilterFields.length === filterGroupFilterFields.length &&
      targetFilterFields.every(filterField => filterGroupFilterFields.includes(filterField)) &&
      filterGroup.not === !include
    );
  });

  if (existingGroup) {
    if (existingGroup.filters.length) {
      if (field.length && field.length === 1) {
        existingGroup.connector = 'Any';
        existingGroup.filters.push(...filters);
      } else {
        existingGroup.connector = existingGroup.not ? 'All' : 'Any';
        existingGroup.not = false;
        existingGroup.filterGroups = [
          {
            connector,
            filters: existingGroup.filters,
            named: false,
            name: '',
            filterGroups: [],
            not: !include,
            autoAdded: true
          }
        ];
        existingGroup.filters = [];
      }
    }

    if (existingGroup.filterGroups && existingGroup.filterGroups.length) {
      existingGroup.filterGroups.push(newGroup);
    }
  } else {
    queryFilters.filterGroups.push(
      field.length === 1 && filters.length > 1
        ? {
            connector: 'Any',
            filterGroups: [newGroup],
            autoAdded: true
          }
        : newGroup
    );
  }

  if (!include || queryFilters.filterGroups.find(filterGroup => filterGroup.not)) {
    queryFilters.connector = 'All';
  }

  return queryFilters;
}

/**
 * Takes a QueryResult model, a QueryBucket model and a form (which contains filters)
 * and generates a new set of filters combining existing filters with a filter
 * to include or exclude the provided model.
 *
 * @param model - the model from which to get data to build the filter
 * @param bucket - the bucket from which to get metrics for filters
 * @param formState - the form from which to merge the resultant filters
 * @param filtersAcc - the parent filters group which will be used to merge the filters
 * @param options - { include } to build a filter that includes/excludes the provided model (defaults to true)
 * @param options - { overrideMetric } to build a filter that includes/excludes only the overrideMetric from the provided model (defaults to null)
 * @returns Filter Object
 */
export function getModelFilters({ model, bucket, formState, filtersAcc }, options = {}) {
  const { include = true, overrideMetric = null } = options;
  const lookup = model.get('lookup');
  const key = model.get('key');

  // we are doing an array here in case we decide to handle all selected rows later
  const values = [lookup || key];

  const activeFilters = {
    connector: filtersAcc ? filtersAcc.connector : formState.getValue('filters.connector'),
    filterGroups: filtersAcc ? filtersAcc.filterGroups : formState.getValue('filters.filterGroups')
  };
  const { metric, filters: cutFn } = bucket.firstQuery.get();
  const parsedMetrics = $dictionary.dictionary.queryFilters.metricToFilterParsers;
  const { metricColumns } = $dictionary.dictionary;
  const connector = include ? 'Any' : 'All';

  let matches;
  let filters = [];
  let filterVal;

  let queryFilters = activeFilters;
  if (!activeFilters || !Array.isArray(activeFilters.filterGroups)) {
    queryFilters = {
      connector,
      filterGroups: []
    };
  }

  const fieldOverrides = [];
  if (overrideMetric) {
    fieldOverrides.push(overrideMetric);
  }
  const field = metric.slice();
  if (field.includes('src_geo_city') && !field.includes('src_geo_region')) {
    field.push('src_geo_region');
    if (overrideMetric === field) {
      fieldOverrides.push('src_geo_region');
    }
  }

  if (field.includes('dst_geo_city') && !field.includes('dst_geo_region')) {
    field.push('dst_geo_region');
    if (overrideMetric === field) {
      fieldOverrides.push('dst_geo_region');
    }
  }

  if (field.includes('src_geo_region') && !field.includes('Geography_src')) {
    field.push('Geography_src');
    if (overrideMetric === field) {
      fieldOverrides.push('Geography_src');
    }
  }

  if (field.includes('dst_geo_region') && !field.includes('Geography_dst')) {
    field.push('Geography_dst');
    if (overrideMetric === field) {
      fieldOverrides.push('Geography_dst');
    }
  }

  if (field.length && field.length > 1) {
    for (let y = 0, ylen = values.length; y < ylen; y += 1) {
      filters = [];
      for (let z = 0, zlen = field.length; z < zlen; z += 1) {
        if (!overrideMetric || field[z] === overrideMetric) {
          if (parsedMetrics[field[z]]) {
            for (let x = 0, xlen = parsedMetrics[field[z]].length; x < xlen; x += 1) {
              matches = new RegExp(parsedMetrics[field[z]][x].pattern).exec(values[y].split(' ---- ')[z]);
              filterVal = matches[matches.length - 1];
              if (filterVal === '---') {
                filterVal = '';
              }
              filters.push({
                filterField: parsedMetrics[field[z]][x].field,
                operator: determineOperatorForMetric(parsedMetrics[field[z]][x].field, '=', cutFn),
                filterValue: `${filterVal}`
              });
            }
          } else {
            filterVal = values[y].split(' ---- ')[z];
            if (filterVal === '---') {
              filterVal = '';
            }
            filters.push({
              filterField: metricColumns[field[z]] || field[z],
              operator: determineOperatorForMetric(metricColumns[field[z]] || field[z], '=', cutFn),
              filterValue: `${filterVal}`
            });
          }
        }
      }

      addToQueryFilters(queryFilters, filters, overrideMetric ? fieldOverrides : field, include);
    }
    return queryFilters;
  }

  if (parsedMetrics[field]) {
    for (let y = 0, ylen = values.length; y < ylen; y += 1) {
      filters = [];
      for (let x = 0, xlen = parsedMetrics[field].length; x < xlen; x += 1) {
        matches = new RegExp(parsedMetrics[field][x].pattern).exec(values[y]);
        filterVal = matches[matches.length - 1];
        if (filterVal === '---') {
          filterVal = '';
        }
        filters.push({
          filterField: parsedMetrics[field][x].field,
          operator: determineOperatorForMetric(parsedMetrics[field][x].field, '=', cutFn),
          filterValue: `${filterVal}`
        });
      }

      addToQueryFilters(queryFilters, filters, field, include);
    }
  } else {
    for (let x = 0, xlen = values.length; x < xlen; x += 1) {
      filterVal = values[x];
      if (filterVal === '---') {
        filterVal = '';
      }
      filters.push({
        filterField: metricColumns[field] || field[0],
        operator: determineOperatorForMetric(metricColumns[field] || field[0], '=', cutFn),
        filterValue: `${filterVal}`
      });
    }

    addToQueryFilters(queryFilters, filters, field, include);
  }

  return queryFilters;
}

export function injectLegacySavedFilters(saved_filters, filters) {
  if (!Array.isArray(saved_filters) || saved_filters.length === 0) {
    return filters;
  }

  const resultantFilters = {
    connector: 'All',
    filterGroups: [
      {
        autoAdded: '',
        connector: 'All',
        filterGroups: [],
        filters: [],
        name: '',
        named: false,
        not: false,
        saved_filters: saved_filters.map(({ filter_id, is_not = false }) => ({ filter_id, is_not }))
      }
    ]
  };

  if (filters.filterGroups.length > 0) {
    if (filters.connector === 'All') {
      // If it's 'all' then we can just combine groups
      resultantFilters.filterGroups.push(...filters.filterGroups);
    } else {
      // Otherwise we need to add as a sub-group
      resultantFilters.filterGroups.push(filters);
    }
  }

  return resultantFilters;
}

export function getSavedFilters(filters) {
  if (filters && filters.filterGroups && filters.filterGroups.length > 0) {
    return filters.filterGroups.reduce((acc, group) => {
      if (group.saved_filters) {
        acc.push(...group.saved_filters);
      }

      if (group.filterGroups) {
        acc.push(...getSavedFilters(group));
      }

      return acc;
    }, []);
  }

  return [];
}
