import React from 'react';
import { action, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { AutoSizer, List } from 'react-virtualized';
import classNames from 'classnames';

import { getScrollbarWidth } from 'util/utils';

import GroupRow from './GroupRow';
import Header from './Header';
import Row from './Row';
import Table from './Table';

const StickyHeader = props => {
  const { headerProps } = props;
  if (!headerProps.style) {
    return null;
  }

  const headerStyle = {
    ...headerProps.style,
    top: 0,
    height: Number.isInteger(headerProps.rowHeight) ? headerProps.rowHeight : 48,
    width: `calc(100% - ${getScrollbarWidth()}px)`
  };

  return <GroupRow {...headerProps} key={headerProps.groupKey || headerProps.key} style={headerStyle} />;
};

@observer
export default class VirtualizedTable extends Table {
  @observable.ref
  groupedRows = [];

  @observable
  theadTrStyle = {};

  isRenderingVisibleRows = false;

  @observable
  stickyHeaderProps = {};

  stickyHeaderModel = null;

  static defaultProps = {
    minimal: false,
    rowHeight: 52,
    breakpoint: 1024,
    responsive: false,
    responsiveHeightMultiplier: 2,
    selectOnRowClick: true,
    stickyHeader: false
  };

  setupReactions(collection) {
    this.filterDisposer = reaction(() => collection.filterState, this.updateGroupedRows, { delay: 50 });
    this.presetFilterDisposer = reaction(() => collection.activePresetFilter, this.updateGroupedRows, { delay: 50 });
    this.sortDisposer = reaction(() => collection.sortState, this.updateGroupedRows, { delay: 50 });
    this.groupByDisposer = reaction(() => collection.groupBy, this.updateGroupedRows);
    this.groupCollapseDisposer = reaction(() => this.collapsedRows.entries(), this.updateGroupedRows);
    this.lastUpdatedDisposer = reaction(() => collection.lastUpdated, this.updateGroupedRows);

    if (collection.groupBy) {
      this.updateGroupedRows();
    }
  }

  clearDisposers() {
    this.filterDisposer();
    this.presetFilterDisposer();
    this.sortDisposer();
    this.groupByDisposer();
    this.groupCollapseDisposer();
    this.lastUpdatedDisposer();
  }

  componentDidMount() {
    const { collection } = this.props;
    this.setupReactions(collection);
    super.componentDidMount();
    this.updatePadding();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.collection !== nextProps.collection) {
      this.clearDisposers();
      this.setupReactions(nextProps.collection);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.collection !== prevProps.collection || this.state.responsiveMode !== prevState.responsiveMode) {
      this.updateGrid();
    }

    this.updatePadding();
  }

  componentWillUnmount() {
    this.clearDisposers();
    super.componentWillUnmount();
  }

  listRefHandler = ref => {
    if (ref) {
      this.listRef = ref;
    }
  };

  @action
  updateGrid = () => {
    if (this.listRef && this.listRef.Grid) {
      this.listRef.recomputeRowHeights();
      this.listRef.forceUpdateGrid();
    }
  };

  @action
  updatePadding = () => {
    if (this.listRef && this.listRef.Grid) {
      const paddingRight = this.listRef.Grid._verticalScrollBarSize;
      if (!this.theadTrStyle || paddingRight !== this.theadTrStyle.paddingRight) {
        this.theadTrStyle = { paddingRight };
      }
    }
  };

  @action
  updateGroupedRows = () => {
    const { collection } = this.props;
    const groupedRows = [];

    if (collection.groupBy) {
      const groupedData = collection.groupedData;

      Object.keys(groupedData).forEach((groupKey, idx, arr) => {
        const isExpanded = !this.collapsedRows.has(groupKey);

        groupedRows.push({
          groupKey,
          isGroupSummary: true,
          isExpanded,
          onToggle: () => this.toggleGroup(groupKey),
          last: idx === arr.length - 1
        });

        if (isExpanded) {
          groupedRows.push(...groupedData[groupKey]);
        }
      });
    }

    this.groupedRows = groupedRows;

    this.updateGrid();
    this.updatePadding();
  };

  getRowHeight = ({ index }) => {
    const { collection, responsiveHeightMultiplier, rowHeight } = this.props;
    const { responsiveMode } = this.state;
    const calculatedRowHeight = typeof rowHeight === 'number' ? rowHeight : rowHeight({ index }, this);

    if (responsiveMode) {
      const model = collection.groupBy && index < this.groupedRows.length ? this.groupedRows[index] : null;

      if (!model || !model.isGroupSummary) {
        return calculatedRowHeight * responsiveHeightMultiplier;
      }
    }

    return calculatedRowHeight;
  };

  getGroupedRow(index) {
    return index < this.groupedRows.length ? this.groupedRows[index] : null;
  }

  @action
  setStickyHeader(model, props) {
    this.stickyHeaderModel = model;
    this.stickyHeaderProps = props;
  }

  handleScroll = ({ scrollTop }) => {
    if (scrollTop === 0) {
      this.setStickyHeader(null, {});
    }
  };

  renderRow = ({ index, key, style, isVisible }) => {
    const { collection, showTotalRow, stickyHeader } = this.props;
    const { responsiveMode } = this.state;

    let model = index < collection.size ? collection.at(index) : null;

    if (collection.groupBy) {
      model = this.getGroupedRow(index);

      if (stickyHeader) {
        // For the first item visible, let's make sure we get a group header
        if (!this.isRenderingVisibleRows && isVisible) {
          let stickyModel = model;
          for (let i = index - 1; i >= 0 && stickyModel && !stickyModel.isGroupSummary; i -= 1) {
            stickyModel = this.getGroupedRow(i);
          }
          if (stickyModel && stickyModel.isGroupSummary) {
            if (this.stickyHeaderModel !== stickyModel) {
              this.setStickyHeader(stickyModel, { ...this.props, ...stickyModel, key, style });
            }
          }
        }
        this.isRenderingVisibleRows = isVisible;
      }

      if (model && model.isGroupSummary) {
        return <GroupRow {...this.props} {...model} key={model.groupKey} style={style} />;
      }
    } else if (stickyHeader && this.stickyHeaderModel) {
      this.setStickyHeader(null, {});
    }

    if (!model) {
      if (showTotalRow) {
        return this.renderTotals({ key, style });
      }
      return null;
    }

    return (
      <Row
        {...this.props}
        key={key}
        model={model}
        responsiveMode={responsiveMode}
        style={style}
        selected={this.isRowSelected(model)}
        virtualized
      />
    );
  };

  renderBody() {
    const { bodyStyle, collection, flexed, className, showTotalRow, stickyHeader } = this.props;

    const style = Object.assign({}, flexed ? { flex: 1 } : {}, bodyStyle);

    this.isRenderingVisibleRows = false;

    if (collection.isRequestActive('fetching') || collection.size === 0) {
      return super.renderBody({ style });
    }

    const groupedSize = this.groupedRows.length;
    const fullSize = collection.size;
    let rowCount = collection.groupBy ? groupedSize : fullSize;
    if (showTotalRow) {
      rowCount += 1;
    }

    return (
      <div className={classNames('tbody', className)} style={style}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              height={height}
              noRowsRenderer={() => this.renderEmpty()}
              rowCount={rowCount}
              rowHeight={this.getRowHeight}
              rowRenderer={this.renderRow}
              ref={this.listRefHandler}
              width={width}
              onScroll={stickyHeader ? this.handleScroll : undefined}
            />
          )}
        </AutoSizer>
        {stickyHeader && <StickyHeader headerProps={this.stickyHeaderProps} />}
      </div>
    );
  }

  render() {
    const { className, collection, columns, flexed, minimal, selectOnRowClick, onRowClick } = this.props;
    const { responsiveMode } = this.state;

    const tableClassName = classNames('pt-table virtualized', className, {
      'pt-interactive': !minimal && (selectOnRowClick || onRowClick),
      'responsive-table': responsiveMode
    });

    const style = Object.assign(
      {},
      flexed ? { display: 'flex', flexDirection: 'column', flex: 1 } : {},
      this.props.style
    );

    return (
      <div style={style} className={tableClassName}>
        <Header collection={collection} columns={columns} responsiveMode={responsiveMode} style={this.theadTrStyle} />
        {this.renderBody()}
      </div>
    );
  }
}
