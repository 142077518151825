import React from 'react';
import { observer } from 'mobx-react';
import { Box } from 'components/flexbox';
import $dictionary from 'stores/$dictionary';

const FilterOperator = observer(({ operator, ...rest }) => (
  <Box className="pt-text-muted" {...rest} mx={0.5}>
    <small>{$dictionary.dictionary.queryFilters.filterOperators[operator]}</small>
  </Box>
));

export default FilterOperator;
