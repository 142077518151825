import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Tooltip } from '@blueprintjs/core';

@observer
export default class ViewInExplorerButton extends Component {
  render() {
    const { history, dataview, buttonStyle } = this.props;
    return (
      <Tooltip content="Open in Explorer">
        <Button
          className="pt-small"
          iconName="share"
          onClick={() => history.push(`/explorer/${dataview.hash}`)}
          style={buttonStyle}
        />
      </Tooltip>
    );
  }
}
