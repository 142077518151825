import React from 'react';
import Transition from 'react-transition-group/Transition';

const defaultStyle = {
  transition: 'opacity 300ms ease-out',
  opacity: 0
};

const transitionStyles = {
  entered: { opacity: 1 },
  exited: { opacity: 0 }
};

const defaultProps = {
  timeout: 200
};

const Fade = ({ in: inProp, children, ...rest }) => (
  <Transition in={inProp} {...defaultProps} {...rest}>
    {state => {
      const styles = {
        ...defaultStyle,
        ...transitionStyles[state]
      };

      return children({
        state,
        styles
      });
    }}
  </Transition>
);

export default Fade;
