import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';

import { Box } from 'components/flexbox';

const dialogStyles = {
  top: 150,
  width: 400
};

@observer
export default class ConfirmDialog extends Component {
  static defaultProps = {
    text: 'You sure you wanna do this?',
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Remove',
    bodyProps: {
      pt: 4,
      px: 4,
      pb: 1,
      className: 'pt-text-muted'
    }
  };

  render() {
    const {
      title,
      text,
      bodyProps,
      cancelButtonText,
      confirmButtonText,
      onConfirm,
      onCancel,
      isOpen,
      themeName,
      children,
      model
    } = this.props;

    const isDestroying = model && model.isRequestActive('destroying');

    return (
      <Dialog
        title={title}
        className={themeName}
        isOpen={isOpen}
        transitionName="pt-dialog"
        style={dialogStyles}
        onClose={onCancel}
      >
        {children && React.cloneElement(children)}
        {!children && <Box {...bodyProps}>{text}</Box>}

        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button text={cancelButtonText} onClick={onCancel} />
            <Button text={confirmButtonText} intent={Intent.PRIMARY} onClick={onConfirm} loading={isDestroying} />
          </div>
        </div>
      </Dialog>
    );
  }
}
