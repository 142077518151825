import React, { Fragment } from 'react';
import { Button, Dialog } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';

const paragraphStyles = {
  fontSize: 11,
  lineHeight: '1.5em',
  marginBottom: 16
};

const TermsParagraph = props => (
  <p className="pt-monospace-text pt-text-muted" style={paragraphStyles}>
    {props.children}
  </p>
);

const TermsOfService = props => {
  const { isOpen, onOpen, onClose } = props;

  return (
    <Fragment>
      <a
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
      >
        Terms Of Service
      </a>
      <Dialog
        title="Kentik Trial Agreement"
        isOpen={isOpen}
        style={{ width: 'calc(100vw - 200px)', maxHeight: 'calc(100vh - 100px)' }}
        transitionName="pt-dialog"
        onClose={onClose}
      >
        <Flex flexColumn style={{ overflow: 'hidden' }}>
          <Box px={3} pt={1} style={{ overflow: 'auto' }} flexAuto>
            <TermsParagraph>
              THIS KENTIK TRIAL AGREEMENT (THE "AGREEMENT") GOVERNS THE USE OF THE KENTIK CLOUD SERVICE. THIS AGREEMENT
              IS BETWEEN THE PARTY EXECUTING THIS AGREEMENT ("CUSTOMER") AND KENTIK TECHNOLOGIES, INC. LOCATED AT 625
              2ND STREET, SUITE 100, SAN FRANCISCO, CA 94107. CUSTOMER ACCEPTS AND AGREES TO THIS AGREEMENT BY EXECUTING
              AN ONLINE OR PAPER-BASED ORDER THAT REFERENCES THIS AGREEMENT OR OTHERWISE ACCESSING THE PRODUCTS. IN THE
              EVENT YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU ARE NOT AUTHORIZED TO USE THE CLOUD SERVICES.
            </TermsParagraph>

            <h6>1. Definitions.</h6>
            <TermsParagraph>
              "Cloud Service" means the hosted service offering as further described in an Order or otherwise on
              Kentik’s website. "Effective Date" means the date this Agreement is executed by Customer or Customer
              otherwise accesses or uses the Cloud Service. "Order" means an online form or ordering document for the
              Cloud Service executed Customer and accepted by Kentik. "Term" means the evaluation term defined on each
              Order, of if no such term is defined, thirty (30) days, commencing on the Effective Date.
            </TermsParagraph>

            <h6>2. Evaluation License and Restrictions.</h6>
            <TermsParagraph>
              2.1 License. Subject to the terms and conditions of this Agreement, Kentik grants Customer a limited,
              nonexclusive, non-transferable license to access and use and evaluate the Cloud Service for its internal,
              business purposes for the Term. 2.2 License Restrictions. Customer is prohibited from (i) reverse
              engineering the Cloud Service; (ii) reselling, sublicensing, leasing, time- sharing or otherwise making
              the Cloud Service available to any third party; (iii) sending or storing infringing or unlawful material
              using the SaaS Services; (iv) attempting to gain unauthorized access to, or disrupt the integrity or
              performance of, the Cloud Service or the data contained therein; (v) modifying, copying, or creating
              derivative works based on the Cloud Service; (vi) using or accessing the Cloud Service for the purpose of
              building a competitive product; or (vii) using the Cloud Service, or permitting any of them to be used,
              for purposes of product evaluation, benchmarking or other comparative analysis intended for publication.
            </TermsParagraph>

            <h6>3. Customer’s Obligations.</h6>
            <TermsParagraph>
              3.1 Fees. Unless otherwise stated in an Order, there are no fees due for the Cloud Service. Customer shall
              be responsible for all taxes owing in connection with the transactions contemplated herein, except for
              taxes on Kentik’s net income.
            </TermsParagraph>

            <h6>4. Ownership.</h6>
            <TermsParagraph>
              Customer acknowledges and agrees that Kentik owns all right, title and interest (including without
              limitation all patents, copyrights, trade secrets or other proprietary rights) in the Cloud Service, and
              any modifications, corrections or enhancements thereto.
            </TermsParagraph>

            <h6>5. Confidentiality.</h6>
            <TermsParagraph>
              5.1. As used herein, "Confidential Information" means all confidential and proprietary information of a
              party ("Disclosing Party") disclosed to the other party ("Receiving Party"), whether orally or in writing,
              that is designated as confidential or that reasonably should be understood to be confidential given the
              nature of the information and the circumstances of disclosure, including the Cloud Service, documentation,
              business and marketing plans, technology and technical information, product designs, and business
              processes. Confidential Information shall not include any information that: (i) is or becomes generally
              known to the public without breach of any obligation owed to Disclosing Party; (ii) was known to Receiving
              Party prior to its disclosure by Disclosing Party without breach of any obligation owed to Disclosing
              Party; (iii) was independently developed by Receiving Party without breach of any obligation owed to
              Disclosing Party; or (iv) is received from a third party without breach of any obligation owed to
              Disclosing Party. 5.2 Receiving Party shall not disclose any Confidential Information of Disclosing Party
              for any purpose outside the scope of this Agreement, except with Disclosing Party's prior written consent.
              Receiving Party shall protect the confidentiality of Disclosing Party's Confidential Information in the
              same manner that it protects the confidentiality of its own confidential information of like kind (but in
              no event using less than reasonable care). Receiving Party shall promptly notify Disclosing Party if it
              becomes aware of any actual or reasonably suspected breach of confidentiality of Disclosing Party's
              Confidential Information. 5.3 If Receiving Party is compelled by law to disclose Confidential Information
              of Disclosing Party, it shall provide Disclosing Party with prior notice of such compelled disclosure (to
              the extent legally permitted) and reasonable assistance, at Disclosing Party's cost, if Disclosing Party
              wishes to contest the disclosure. 5.4 Upon any termination of this Agreement, the Receiving Party shall
              continue to maintain the confidentiality of the Disclosing Party's Confidential Information and, upon
              request of the Disclosing Party, return to the Disclosing Party or destroy (at the Disclosing Party's
              election) all materials containing such Confidential Information.
            </TermsParagraph>

            <h6>6. Copyright and Patent Indemnity.</h6>
            <TermsParagraph>
              Kentik will defend or settle any action brought against Customer to the extent that it is based upon a
              claim that the Cloud Service, as provided by Kentik to Customer under this Agreement and used within the
              scope of this Agreement, infringe any U.S. patent or any copyright or misappropriates any trade secret,
              and will pay any costs, damages and reasonable attorneys’ fees attributable to such claim that are finally
              awarded against Customer or payable by Customer in settlement, provided that Customer: (i) promptly
              notifies Kentik in writing of the claim; (ii) grants Kentik sole control of the defense and settlement of
              the claim; and (iii) provides Kentik, at Kentik ’s expense, with all assistance, information and authority
              reasonably required for the defense and settlement of the claim. If Customer’s use of the Cloud Service
              is, or in Kentik ’s opinion is likely to be, enjoined due to the type of claim specified above, Kentik
              may, at its sole option and expense, terminate Customer’s right and Kentik’s obligations hereunder with
              respect to such Cloud Service. Notwithstanding the terms of this Section 6, Kentik will have no liability
              for any infringement or misappropriation claim of any kind to the extent that it results from: (a)
              modifications to the Cloud Service made by a party other than Kentik, if a claim would not have occurred
              but for such modifications; (b) the combination, operation or use of the Cloud Service with equipment,
              devices, software or data not supplied or approved by Kentik, if a claim would not have occurred but for
              such combination, operation or use; (c) Customer’s failure to use updated or modified Cloud Service
              provided by Kentik to avoid a claim; or (d) Customer’s use of the Cloud Service other than in accordance
              with this Agreement and the Documentation. THE PROVISIONS OF THIS SECTION 6 SET FORTH KENTIK ’S SOLE AND
              EXCLUSIVE OBLIGATIONS, AND CUSTOMER'S SOLE AND EXCLUSIVE REMEDIES, WITH RESPECT TO INFRINGEMENT OR
              MISAPPROPRIATION OF THIRD PARTY INTELLECTUAL PROPERTY -2- RIGHTS OF ANY KIND.
            </TermsParagraph>

            <h6>7. Term and Termination.</h6>
            <TermsParagraph>
              7.1 This Agreement shall commence on the Effective Date and terminate upon expiration of the Term of all
              Orders, unless earlier terminated pursuant to this Section 7. Either party may terminate this Agreement
              for any reason upon five (5) days written notice to the other party. 7.2 Upon the effective date of
              termination of this Agreement or any Order, Customer’s license to use the Cloud Service defined in the
              applicable Order will cease and Customer will delete and either destroy or return to Kentik all copies of
              any documentation related to the Cloud Service. The parties’ rights and obligations with respect to, title
              and protection, confidentiality, warranty disclaimers, limitation of liability and governing law
              provisions of this Agreement shall survive termination of this Agreement. 7.3 If Customer elects to
              license the Cloud Service for production use, Customer acknowledges and agrees that such production
              licenses will be subject to Kentik ’s then current terms and conditions.
            </TermsParagraph>

            <h6>8. No Warranty.</h6>
            <TermsParagraph>
              THE CLOUD SERVICE IS PROVIDED "AS IS", AND KENTIK MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT
              THERETO, INCLUDING BUT NOT LIMITED ANY IMPLIED WARRANTIES OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR
              PURPOSE. WITHOUT LIMITING THE FOREGOING, KENTIK DOES NOT WARRANT THAT THE CLOUD SERVICE WILL MEET
              CUSTOMER’S NEEDS, WILL FUNCTION PROPERLY, OR WILL BE ERROR-FREE.
            </TermsParagraph>

            <h6>9. Limitation of Liability.</h6>
            <TermsParagraph>
              9.1 IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER FOR LOSS OF PROFITS, DATA, OR SERVICE, OR ANY,
              INDIRECT, PUNITIVE, CONSEQUENTIAL, OR INCIDENTAL DAMAGES, HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY,
              EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING ANY FAILURE OF
              THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. 9.2 EACH PARTY’S TOTAL LIABILITY TO THE OTHER ARISING OUT OF
              OR UNDER THIS AGREEMENT OR FOR BREACH OF THIS AGREEMENT FOR DIRECT DAMAGES, WHETHER IN CONTRACT, TORT
              (INCLUDING WITHOUT LIMITATION NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, SHALL NOT EXCEED THE GREATER OF
              (I) FEES PAID BY CUSTOMER UNDER THIS AGREEMENT; OR (II) ONE THOUSAND DOLLARS ($1,000). 9.3 THE FOREGOING
              EXCLUSIONS AND LIMITATIONS OF LIABILITY IN SECTION 9.2 SHALL NOT APPLY TO (I) CLAIMS UNDER ARTICLE 5; OR
              (II) KENTIK’S INDEMNIFICATION OBLIGATIONS UNDER SECTION 6.
            </TermsParagraph>

            <h6>10. Export Restrictions.</h6>
            <TermsParagraph>
              Customer agrees to comply fully with all applicable regulations of the United States Department of
              Commerce and with the United States Export Administration Act, as amended from time to time, and with all
              applicable laws and regulations of other jurisdictions with respect to the importation and use of the
              Cloud Service.
            </TermsParagraph>

            <h6>11. Miscellaneous.</h6>
            <TermsParagraph>
              Notices shall be in writing to the addresses listed above, sent using a recognized private mail carrier or
              the United States Postal Service and effective on proof of delivery. The parties are independent
              contractors, and nothing in this Agreement shall create any agency, partnership or joint venture
              relationship between them. This Agreement is governed and interpreted in accordance with the laws of the
              State of California without reference to conflicts of laws principles. The parties hereby consent to the
              exclusive jurisdiction of the state and federal courts located in San Francisco County, California, for
              resolution of any disputes arising out of this Agreement. Customer may not assign this Agreement, or any
              of its rights or obligations hereunder, by operation of law or otherwise, without Kentik’s prior written
              consent. Any purported assignment by Customer other than as provided in this Section 11 shall be null and
              void. If any of the provisions of this Agreement are held to be invalid under any applicable statute or
              rule of law, they are, to that extent, deemed omitted. No failure by Kentik to enforce any of its rights
              under this Agreement will act as a waiver of such rights. This Agreement constitutes the entire agreement
              between Kentik and Customer with respect to the subject matter hereof, and merges all prior negotiations
              and drafts of the parties with regard thereto, and supersedes any and all other written or oral agreements
              existing between the parties hereto regarding the subject matter of this Agreement. Any click-wrap or
              other form of Kentik evaluation agreement shall be null and void. This Agreement may not be modified
              without the prior written consent of both parties.
            </TermsParagraph>
          </Box>
          <Box p={2}>
            <Flex justify="flex-end">
              <Button style={{ marginRight: 8 }} onClick={onClose} text="Close" />
            </Flex>
          </Box>
        </Flex>
      </Dialog>
    </Fragment>
  );
};

export default TermsOfService;
