import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import $auth from 'stores/$auth';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['InterfaceID_src', 'InterfaceID_dst'],
  filterFields: [
    'input_port',
    'i_input_snmp_alias',
    'i_input_interface_description',
    'output_port',
    'i_output_snmp_alias',
    'i_output_interface_description',
    'input|output_port',
    'i_input|output_snmp_alias',
    'i_input|output_interface_description'
  ]
};

class SnmpDependency extends AbstractDependency {
  get key() {
    return 'snmp';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $auth.snmpEstablished;
  }

  get message() {
    return (
      <Fragment>
        <Box className="pt-text-smaller" mb={0.5}>
          <strong>SNMP</strong> data is required by dimensions you're using, but has not been detected for any devices
          in your organization. Results shown herein may be highly inaccurate.
        </Box>
        {this.showConfigureButton && (
          <Button iconName="cog" className="pt-small" onClick={() => $auth.store.history.push('/admin/devices')}>
            Configure
          </Button>
        )}
      </Fragment>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $auth.snmpEstablished;
  }
}

export default SnmpDependency;
