import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Button, Intent } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import { Form, Field, Textarea } from 'components/forms/index';

const fields = {
  comment: {
    label: 'Add a comment and clear the selected alarms'
  }
};

@Form({ fields })
@observer
class AlarmCommentDialog extends Component {
  handleSubmit = () => {
    const { form, onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(form.getValues());
    }
  };

  handleCancel = () => {
    const { form, onClose } = this.props;
    form.reset();
    onClose();
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog
        iconName="inbox"
        isOpen={isOpen}
        onClose={onClose}
        title="Alarm Comments"
        style={{ width: '50%' }}
        transitionName="pt-dialog"
      >
        <div className="pt-dialog-body">
          <Box mr={2}>
            <Field name="comment" className="no-margin">
              <Textarea rows="5" />
            </Field>
          </Box>
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button text="Cancel" onClick={this.handleCancel} />
            <Button text="Submit" intent={Intent.PRIMARY} onClick={this.handleSubmit} />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AlarmCommentDialog;
