import request from 'superagent';
import loadGoogleMapsAPI from 'load-google-maps-api';

import $app from 'stores/$app';
import $dictionary from 'stores/$dictionary';

function getAddressString({ address, city, country, region, postal }) {
  const addressParts = [];

  if (address) {
    addressParts.push(address.trim());
  }

  if (city) {
    addressParts.push(city.trim());
  }

  if (postal && region) {
    addressParts.push(`${region} ${postal}`);
  } else if (region) {
    addressParts.push(region);
  } else if (postal) {
    addressParts.push(postal);
  }

  if (country) {
    addressParts.push(country);
  }

  return addressParts.join(', ');
}

function geocode(address) {
  const addressString = getAddressString(address);

  return request
    .get('https://maps.googleapis.com/maps/api/geocode/json', {
      address: addressString.replace(/\s/g, '+'),
      key: $dictionary.dictionary.googleMapsPublicApiKey
    })
    .then(
      response => {
        const data = JSON.parse(response.text);
        if (data.results.length > 1 && data.results.every(result => result.formatted_address !== addressString)) {
          throw new Error('Location was not sufficiently specific for geocoding.');
        } else if (data.results.length === 0) {
          throw new Error('Location could not be geocoded.');
        }

        return data.results[0].geometry.location;
      },
      error => {
        console.warn('Geocoding error', error);
        throw new Error('Geocoding could not be performed at this time.');
      }
    );
}

let googleMapsApi;

async function getMapApi() {
  if (!googleMapsApi) {
    googleMapsApi = await loadGoogleMapsAPI({
      key: $dictionary.dictionary.googleMapsPublicApiKey
    });
  }

  return googleMapsApi;
}

function getMapStyles() {
  return $app.darkThemeEnabled
    ? [
        { elementType: 'geometry', stylers: [{ color: '#273142' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#273142' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#263c3f' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#6b9a76' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#38414e' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#212a37' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9ca5b3' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#746855' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#1f2835' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#f3d19c' }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#2f3948' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#171f29' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#515c6d' }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#171f29' }]
        }
      ]
    : [];
}

export { geocode, getMapApi, getMapStyles };
