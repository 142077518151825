import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Page, PageBody } from 'components/page/index';
import DashboardView from 'views/Dashboards/DashboardView';
import DeviceDetailsDialog from 'views/Admin/Devices/DeviceDetailsDialog';
import UserDetailsDialog from 'views/Admin/Users/UserDetailsDialog';

import CloudExportDialog from '../Admin/Clouds/CloudExportDialog';
import BasicExplorerSidebar from './BasicExplorerSidebar';
import BasicExplorerDataView from './BasicExplorerDataView';
import Library from './Library';

@inject(
  '$basicExplorer',
  '$users',
  '$devices',
  '$library',
  '$queryTemplates',
  '$savedViews',
  '$dashboards',
  '$onboarding',
  '$clouds',
  '$alerts'
)
@observer
class BasicExplorerView extends Component {
  componentWillMount() {
    const { $clouds, $library, $alerts, match, location } = this.props;
    if (match.params.templateIdOrHash) {
      $library.loadReport(match.params, location.state);
    } else if (match.path.includes('/createcloud')) {
      $clouds.createCloudFromURLParams(match.params.params);
    } else if (match.path.includes('/alarm')) {
      $alerts.loadViewByAlarmId(match.params.alarmId, 'dashboard');
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      $library,
      match: { params }
    } = this.props;
    const {
      match: { params: newParams },
      location: { state: newState }
    } = nextProps;

    if (Object.keys(newParams).length > 0) {
      if (params.type !== newParams.type || params.templateIdOrHash !== newParams.templateIdOrHash) {
        $library.loadReport(newParams, newState);
      }
      // No else, the selected report hasn't changed - do nothing
    } else {
      $library.reportsCollection.clearSelection();
    }
  }

  componentWillUnmount() {
    const { $library } = this.props;
    $library.reportsCollection.clearSelection();
  }

  render() {
    const { $library, $clouds, $users, match, $devices } = this.props;
    const { loading, loadingFailed, reportsCollection } = $library;
    const selectedReport = reportsCollection.selected;
    const type = selectedReport && selectedReport.get('type');

    let title = 'Library';
    if (selectedReport) {
      title = selectedReport.name;
    }

    return (
      <Page title={title}>
        <PageBody bodyStyle={{ padding: 0 }}>
          <Flex flexAuto style={{ overflow: 'hidden' }}>
            {$devices.hasReceivedFlow && (
              <BasicExplorerSidebar reports={reportsCollection} loading={loading} params={match.params} />
            )}

            <Flex flexColumn flexAuto className="basic-explorer-wrapper">
              {selectedReport && (
                <Fragment>
                  {type === 'savedView' && <BasicExplorerDataView savedView={selectedReport} />}
                  {type === 'report' && <BasicExplorerDataView {...this.props} template={selectedReport} />}
                  {type === 'dashboard' && (
                    <DashboardView dashboard={selectedReport} showHeader={false} showSidebar={false} />
                  )}
                </Fragment>
              )}
              {!selectedReport && !loading && !loadingFailed && <Library reports={reportsCollection} />}
              {loading && (
                <Flex align="center" justify="center" p={4}>
                  <Spinner />
                </Flex>
              )}
              {loadingFailed && (
                <Box p={3} className="pt-monospace-text">
                  This view could not be found.
                </Box>
              )}
            </Flex>
          </Flex>
        </PageBody>

        {$devices.collection.selected && (
          <DeviceDetailsDialog
            isOpen={$devices.collection.selected}
            collection={$devices.collection}
            model={$devices.collection.selected}
            helpTopic="Device General Settings"
          />
        )}

        {$users.collection.selected && (
          <UserDetailsDialog
            isOpen={$users.collection.selected}
            model={$users.collection.selected}
            collection={$users.collection}
            fetchDetails={false}
          />
        )}

        {$clouds.collection.selected && (
          <CloudExportDialog
            isOpen={$clouds.collection.selected}
            collection={$clouds.collection}
            model={$clouds.collection.selected}
          />
        )}
      </Page>
    );
  }
}

export default withRouter(BasicExplorerView);
