import { computed, observable, action } from 'mobx';
import { flatten } from 'lodash';
import api from 'util/api';

import awsLogo from 'aws-logo.svg';
import kProbeIcon from 'add-kprobe-icon.svg';
import googleLogo from 'google-cloud-logo.svg';
import azureLogo from 'azure-logo.svg';
import addRoutersSwitches from 'add-routers-switches-icon.svg';

import $app from './$app';
import $devices from './$devices';
import $clouds from './$clouds';

class OnboardingStore {
  @observable
  productUpdates = [];

  @observable
  fetchingProductUpdates = false;

  @observable
  tasks = [
    [
      {
        title: 'Routers and Switches',
        description: 'Configure your hardware to send flow to Kentik',
        src: addRoutersSwitches,
        complete: false,
        loading: false,
        onClick: () => $devices.collection.forge({ device_type: 'router' })
      },
      {
        title: 'Servers and Hosts',
        description: 'Install the Kentik kprobe host-agent on your hardware',
        src: kProbeIcon,
        complete: false,
        loading: false,
        onClick: () => $devices.collection.forge({ device_type: 'host-nprobe-dns-www' })
      }
    ],
    [
      {
        title: 'Amazon Web Services',
        description: 'Connect your AWS hosted infrastructure',
        src: awsLogo,
        complete: false,
        loading: false,
        onClick: () => $clouds.collection.forge({ cloud_provider: 'aws' })
      },
      {
        title: 'Google Cloud Platform',
        description: 'Connect your GCP hosted infrastructure',
        src: googleLogo,
        complete: false,
        loading: false,
        onClick: () => $clouds.collection.forge({ cloud_provider: 'gce' })
      },
      {
        title: 'Microsoft Azure',
        description: 'Connect your Azure hosted infrastructure',
        src: azureLogo,
        complete: false,
        loading: false,
        onClick: () => $clouds.collection.forge({ cloud_provider: 'azure' })
      }
    ]

    // {
    //   step: 3,
    //   key: 'deviceGroupings',
    //   title: 'Configure device labels and sites',
    //   description: 'Grouping devices helps discoverability and other things.',
    //   iconName: 'pt-icon-tag',
    //   complete: false,
    //   loading: false
    // },
    // {
    //   step: 4,
    //   key: 'interfaces',
    //   title: 'Configure Interfaces',
    //   description: 'Invite the rest of your team, assign user roles and access controls',
    //   iconName: 'pt-icon-send-to-graph',
    //   complete: false,
    //   loading: false
    // },
    // {
    //   step: 5,
    //   key: 'interfaceClassRules',
    //   title: 'Setup interface classification rules',
    //   description: 'Invite the rest of your team, assign user roles and access controls',
    //   iconName: 'pt-icon-form',
    //   complete: false,
    //   loading: false
    // }
  ];

  @computed
  get forceOnboarding() {
    return !$app.isV4 && $devices.deviceSummaries.length === 0 && !$devices.hasReceivedFlow;
  }

  @computed
  get numCompleted() {
    return this.tasks.filter(task => task.complete).length;
  }

  @action
  fetchProductUpdates() {
    if (this.productUpdates.length) {
      return this.productUpdates;
    }

    this.fetchingProductUpdates = true;

    return api.get('/api/portal/productUpdates').then(response => {
      const headers = response.map(month => month.headers);
      this.productUpdates = flatten(headers).slice(0, 5);
      this.fetchingProductUpdates = false;

      return this.productUpdates;
    });
  }

  @action
  evaluateStatusDefault() {
    return false;
  }

  evaluateStatusDevices() {
    return !!$devices.deviceSummaries.length;
  }

  evaluateStatusUsers() {
    return true;
  }

  // evaluateStatusDeviceGroupings() {
  //   return new Promise(resolve => setTimeout(() => resolve(true), 1000));
  // }

  // evaluateStatusInterfaces() {
  //   return new Promise(resolve => setTimeout(() => resolve(false), 1250));
  // }

  // evaluateStatusInterfaceClassRules() {
  //   return new Promise(resolve => setTimeout(() => resolve(false), 1500));
  // }

  // taskByKey(key) {
  //   return key ? this.tasks.find(item => item.key.toString().toLowerCase() === key.toString().toLowerCase()) : null;
  // }

  // @action
  // async evaluateStatus(key) {
  //   if (key) {
  //     const evalFnName = `evaluateStatus${key ? key.charAt(0).toUpperCase() + key.slice(1) : 'Default'}`;
  //     const evalFn = this[evalFnName] ? this[evalFnName] : this.evaluateStatusDefault;
  //     const task = this.taskByKey(key);
  //     if (task) {
  //       task.loading = true;
  //       return Promise.resolve(evalFn()).then(
  //         action(`evalSuccess${key}`, evalResult => {
  //           task.loading = false;
  //           task.complete = evalResult;
  //           return task;
  //         }),
  //         action(`evalError${key}`, () => {
  //           task.loading = false;
  //         })
  //       );
  //     }
  //     // no task, just bail.
  //     return false;
  //   }

  //   const promises = [];
  //   this.tasks.forEach(item => {
  //     if (item && item.key) {
  //       promises.push(this.evaluateStatus(item.key));
  //     }
  //   });
  //   return Promise.all(promises);
  // }
}

export default new OnboardingStore();
