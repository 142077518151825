import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Button, ButtonGroup, Intent, Tag } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';

const OtpItem = observer(props => {
  const { otp, onRemoveClick, onStatusClick } = props;
  const isEnabled = otp.get('enabled');
  return (
    <Flex className="pt-card flat" justify="space-between" my={1} p={1}>
      <div>
        <Tag className="pt-minimal" style={{ marginRight: 8, width: 55 }}>
          {otp.get('type') === 'yubi' ? 'YubiKey' : 'TOTP'}
        </Tag>
        {otp.get('name')}
      </div>

      <div>
        <ButtonGroup minimal>
          <Button
            className="pt-small"
            iconName={isEnabled ? 'tick' : 'cross'}
            text={isEnabled ? 'Enabled' : 'Disabled'}
            intent={isEnabled ? Intent.SUCCESS : Intent.NONE}
            onClick={() => onStatusClick(otp, !isEnabled)}
          />
          <Button className="pt-small" iconName="trash" intent={Intent.DANGER} onClick={() => onRemoveClick(otp.id)} />
        </ButtonGroup>
      </div>
    </Flex>
  );
});

const OtpItems = props => {
  const { otps, onRemoveClick, onStatusClick } = props;

  return (
    <Fragment>
      {otps.map(otp => (
        <OtpItem otp={otp} key={otp.id} onStatusClick={onStatusClick} onRemoveClick={onRemoveClick} />
      ))}
    </Fragment>
  );
};

export default observer(OtpItems);
