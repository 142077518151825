import React, { Component } from 'react';
import { Icon, CollapsibleList, MenuItem } from '@blueprintjs/core';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router';
import FavoriteButton from 'views/Library/FavoriteButton';

@inject('$dashboards')
class DashboardNavigationBar extends Component {
  renderBreadcrumb = ({ onClick, href, text }) => {
    const { dashboard } = this.props;
    if (href) {
      return (
        <a className="pt-breadcrumb" onClick={onClick} style={{ fontWeight: 'normal' }}>
          {text}
        </a>
      );
    }
    return (
      <span className="pt-breadcrumb pt-breadcrumb-current">
        <Icon className="pt-text-muted" style={{ marginRight: 4 }} iconName="control" /> {text}{' '}
        <FavoriteButton report={dashboard} />
      </span>
    );
  };

  render() {
    const { navigationHistory, $dashboards, history } = this.props;

    return (
      <CollapsibleList
        className="pt-breadcrumbs"
        dropdownTarget={<span className="pt-breadcrumbs-collapsed" />}
        renderVisibleItem={this.renderBreadcrumb}
      >
        {navigationHistory.map((item, idx) => {
          const isCurrent = idx === navigationHistory.length - 1;
          const back = -1 * (navigationHistory.length - (idx + 1));
          const onClick = () => history.go(back);
          return (
            <MenuItem
              key={item.id}
              text={$dashboards.getDashboard(item.id).get('dash_title')}
              href={!isCurrent && '#'}
              onClick={onClick}
            />
          );
        })}
      </CollapsibleList>
    );
  }
}

export default withRouter(DashboardNavigationBar);
