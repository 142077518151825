// import { computed } from 'mobx';
// import { groupBy } from 'lodash';

import Collection from '../Collection';
import DatasetResult from './DatasetResult';

class DatasetResultCollection extends Collection {
  get url() {
    return false;
  }

  get model() {
    return DatasetResult;
  }
}

export default DatasetResultCollection;
