import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { Form, FormDialog } from 'components/forms';
import { fields } from 'forms/config/savedViewDetails';

import SavedViewDetailsForm from './SavedViewDetailsForm';

@Form({ fields })
@observer
class SavedViewDetailsDialog extends Component {
  // when a Saved View is "saved" (created or otherwise), reload explorer
  handleSave = () => {
    const { form, model, history, onSave } = this.props;
    const values = form.getValues();

    if (model.isNew) {
      return model.save(values).then(() => {
        history.push(`/savedView/${model.id}`);
      });
    }
    return model.save(values).then(() => {
      if (onSave) {
        onSave(model.toJS());
      }
    });
  };

  render() {
    return (
      <FormDialog
        {...this.props}
        entityName="Saved View"
        formComponent={<SavedViewDetailsForm />}
        formActionsProps={{ onSubmit: this.handleSave, showRemove: false }}
        style={{ width: 550 }}
      />
    );
  }
}

export default withRouter(SavedViewDetailsDialog);
