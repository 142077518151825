import BaseModel from 'models/BaseModel';
import QueryModel from 'models/query/QueryModel';

export default class RawFlowQueryModel extends BaseModel {
  get defaults() {
    return {
      all_selected: false,
      device_name: '',
      endDateTime: new Date(),
      startDateTime: null,
      ...QueryModel.create().serialize()
    };
  }
}
