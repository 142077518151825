import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { DimensionSelector, Field, Input, Select } from 'components/forms';

const operatorMenuWidths = {
  i_device_id: 265,
  i_device_name: 289,
  i_device_site_name: 231
};

@inject('$dictionary', '$lookups', '$devices')
@observer
class FilterItemEdit extends Component {
  state = {
    collapsedDimensions: {}
  };

  handleDimensionSectionCollapse = group => {
    const { collapsedDimensions } = this.state;
    const newDimensions = { ...collapsedDimensions };
    newDimensions[group] = !collapsedDimensions[group];
    this.setState({ collapsedDimensions: newDimensions });
  };

  handleFilterFieldChange = (field, value, previousValue) => {
    const { filter } = this.props;
    const {
      getFilterValueValidator,
      standardFilterOperators,
      filterOperatorOptions,
      dictionary: {
        queryFilters: { defaultValues, flatParametricFilterTypes }
      }
    } = this.props.$dictionary;
    const filterValidatorRules = getFilterValueValidator(value);
    filter.filterValue.setRules(filterValidatorRules);

    // reset the operator, and select the first value.
    let operator = (filterOperatorOptions[value] || standardFilterOperators)[0].value;
    // use special default operators for certain filter fields
    if (value.indexOf('bgp') !== -1) {
      operator = '~';
    } else if (
      value.indexOf('as_name') !== -1 ||
      value.indexOf('asn_name') !== -1 ||
      value.indexOf('interface_description') !== -1 ||
      value.indexOf('snmp_alias') !== -1
    ) {
      operator = 'ILIKE';
    }

    if (filter.operator.getValue() !== operator) {
      filter.operator.setValue(operator);
    }

    // grab the filter family for the previousValue
    const previousFilterFamily = Object.values(flatParametricFilterTypes).find(family =>
      family.includes(previousValue)
    );
    // if the prior filter field and the new one are in the same family, preserve the value
    if (previousFilterFamily && previousFilterFamily.includes(value)) {
      filter.filterValue.setPristine(false); // junk for lint
    } else if (defaultValues[value] !== undefined) {
      // if there's a default value, set it.
      filter.filterValue.setValue(defaultValues[value]);
    } else {
      filter.filterValue.setValue('');
    }
  };

  handleFilterOperatorChange = (field, value, previousValue) => {
    const { filter, onChange } = this.props;
    if (value.startsWith('misc_flags')) {
      filter.filterValue.setRules('');
    }
    if (onChange) {
      onChange(field, value, previousValue);
    }
  };

  componentWillMount() {
    const { $dictionary, filter, disabledDimensions } = this.props;
    const { filterField, operator } = filter;
    const { getFilterValueValidator } = $dictionary;
    if (!operator.getValue().startsWith('misc_flags')) {
      filter.filterValue.setRules(getFilterValueValidator(filterField.getValue()));
    }
    filter.filterValue.setPristine(false);
    if (disabledDimensions) {
      this.updateCollapsedDimensions(this.props);
    }
  }

  updateCollapsedDimensions(props) {
    const collapsedDimensions = {};
    const { $devices } = this.props;

    if (!$devices.hasDnsProbe) {
      collapsedDimensions['Layer 7'] = true;
    }
    props.disabledDimensions.forEach(dimension => {
      if (!dimension.includes('_')) {
        collapsedDimensions[dimension] = true;
      }
    });

    this.setState({ collapsedDimensions });
  }

  render() {
    const { disabledDimensions, filter, onRemove, onChange, index, $lookups } = this.props;
    const {
      standardFilterOperators,
      filterOperatorOptions,
      filterFieldOptions,
      filterFieldValueOptions,
      dictionary: {
        queryFilters: { suppressValueOperators, neverExactMatch, filterTypeInverses }
      }
    } = this.props.$dictionary;
    const { collapsedDimensions } = this.state;

    const filterField = filter.filterField.getValue();
    const filterOperator = filter.operator.getValue();

    // the set of operators for a filter.filterField
    const operatorOptions = filterOperatorOptions[filterField] || standardFilterOperators;

    // the options that are allowed for a specific filter.filterField
    const valueOptions = filterFieldValueOptions[filterField] || null;
    const showValueField = !suppressValueOperators.includes(filterOperator);

    const onQuery = $lookups.getAutoCompleteFilterHandler(filterField);

    const exactMatch = !neverExactMatch.includes(filterField) && (filterOperator === '=' || filterOperator === '<>');

    return (
      <Flex align="flex-start" className="filter-item filter-item-fields editing" px={1} mt={1}>
        <Flex flexAuto align="flex-start">
          <DimensionSelector
            disabledValues={disabledDimensions}
            className="no-margin filter-item-field"
            showLabel={false}
            field={filter.filterField}
            multi={false}
            isPopover
            onChange={this.handleFilterFieldChange}
            onCollapse={this.handleDimensionSectionCollapse}
            collapsedState={collapsedDimensions}
            options={filterFieldOptions}
            useFilterHeadings
            maxColumns={4}
          />
          {filterTypeInverses[filterField] && (
            <Button
              className="pt-small pt-minimal"
              iconName="swap-horizontal"
              title="Invert Perspective"
              style={{ marginLeft: 4 }}
              onClick={() => filter.filterField.setValue(filterTypeInverses[filterField])}
            />
          )}
        </Flex>
        <Box mx={1}>
          <Field
            showLabel={false}
            field={filter.operator}
            onChange={this.handleFilterOperatorChange}
            options={operatorOptions}
            className="no-margin filter-item-field"
            inputStyle={{ minWidth: 155 }}
          >
            <Select
              className="pt-small"
              menuWidth={operatorMenuWidths[filterField] || 165}
              tetherOptions={{
                offset: '-5px 0',
                constraints: [{ attachment: 'together', pin: true, to: 'window' }]
              }}
            />
          </Field>
        </Box>
        {showValueField && (
          <Box style={{ width: 260 }}>
            {(valueOptions || onQuery) && (
              <Field
                showLabel={false}
                className="no-margin pt-fill"
                field={filter.filterValue}
                placeholder="Select value..."
                options={valueOptions}
                onChange={onChange}
                validateOptions={exactMatch}
                autoFocus
              >
                <Select
                  autoComplete
                  exactMatch={exactMatch}
                  showFilter={exactMatch}
                  onQuery={onQuery}
                  className="pt-small"
                  menuWidth={260}
                  tetherOptions={{
                    offset: '-5px 0',
                    constraints: [{ attachment: 'together', pin: true, to: 'window' }]
                  }}
                />
              </Field>
            )}
            {!valueOptions &&
              !onQuery && (
                <Field
                  showLabel={false}
                  className="pt-fill no-margin"
                  field={filter.filterValue}
                  onChange={onChange}
                  options={null} /* need this to handle toggling from options to no options */
                  autoFocus
                >
                  <Input className="pt-small" />
                </Field>
              )}
          </Box>
        )}
        <Button
          iconName="cross"
          className="pt-small pt-minimal pt-intent-danger"
          onClick={() => onRemove(index)}
          style={{ marginLeft: 5, marginRight: -3 }}
        />
      </Flex>
    );
  }
}

export default FilterItemEdit;
