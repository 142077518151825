import { computed, action } from 'mobx';

import { showSuccessToast } from 'components/Toast';
import BaseModel from 'models/BaseModel';
import { defaultVisualizationSettings } from 'models/Colors';
import { injectLegacySavedFilters } from 'services/filters';
import $dictionary from 'stores/$dictionary';
import api from 'util/api';

export default class User extends BaseModel {
  get defaults() {
    return {
      email_product: true,
      email_service: true,
      role: 'Administrator',
      user_level: 1,
      settings: {
        visualizations: defaultVisualizationSettings
      }
    };
  }

  get urlRoot() {
    return '/api/portal/user';
  }

  get jsonRoot() {
    return 'user';
  }

  @action
  fetchFullProfile = () =>
    api.get(`/api/portal/users/${this.id}`).then(response => {
      this.set(this.deserialize(response.user));
      return this;
    });

  resetSessions = () =>
    api.post(`/api/portal/users/${this.id}/clearSessions`).then(() => {
      showSuccessToast('User sessions successfully reset');
      return this;
    });

  get messages() {
    return {
      create: `User ${this.get('user_email')} was added successfully`,
      update: `User ${this.get('user_email')} was updated successfully`,
      destroy: `User ${this.get('user_email')} was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove User',
      text: `Are you sure you want to remove ${this.get('user_email')}?`
    };
  }

  @computed
  get userLevel() {
    const dictionaryValue = $dictionary.dictionary.userLevels[this.get('user_level')];
    return dictionaryValue.includes('Administrator')
      ? dictionaryValue.replace('Administrator', 'Admin')
      : dictionaryValue;
  }

  @computed
  get lastLogin() {
    return this.toDate('last_login');
  }

  serialize(data) {
    const user_filter_json = JSON.stringify(data.filters);
    const serialized = { ...data, user_name: data.username, user_filter_json };

    delete serialized.username;
    delete serialized.saved_filters; // server ignores, but let's just not send it

    return super.serialize(serialized);
  }

  deserialize(inData) {
    if (!inData) {
      return inData;
    }
    // Allow for data with AND without jsonRoot structure.
    const data = this.jsonRoot && inData[this.jsonRoot] ? inData[this.jsonRoot] : inData;
    const { filters, saved_filters } = data;

    if (saved_filters && saved_filters.length > 0) {
      data.filters = injectLegacySavedFilters(saved_filters, filters);
    }

    delete data.saved_filters;
    return data;
  }
}
