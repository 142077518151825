import { action } from 'mobx';
import Collection from 'models/Collection';
import api from 'util/api';
import SqlAlert from './SqlAlert';

class SqlAlertIncidentsCollection extends Collection {
  get url() {
    return '/api/portal/alerts-active';
  }

  get model() {
    return SqlAlert;
  }

  @action
  fetchHistory = () =>
    api.get(`${this.url}/history`, { query: { eventsOnly: 1 } }).then(res => {
      this.set(res);
    });

  @action
  clearIncidents = (comment, incidents) => {
    const selected = incidents || this.selected;
    const ids = selected.map(incident => incident.get('event_id')).join(',');
    const data = {
      ids,
      comment
    };

    /**
     * Clears our selected alerts, and then re-fetches our collection.
     */
    return api.post(`${this.url}/cleared`, { data }).then(() => {
      this.clearSelection();
      return this.fetch();
    });
  };
}

export default SqlAlertIncidentsCollection;
