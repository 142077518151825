import { action, observable } from 'mobx';
import { get, sortBy } from 'lodash';

import { dimensionTagClassNames, dimensionCategoryIntentMap } from 'components/dimensions/Dimension';
import { getClassNameFromGroup } from 'components/forms/select/selectHelpers';
import api from 'util/api';
import { ALERT_DIMENSIONS_WHITELIST, ALERT_METRIC_OPTIONS } from 'util/constants';
import { TOOLTIP_DATA } from 'util/tooltips';

function getGroupDimension(tagLabels, category, metrics, groupName) {
  let className = dimensionTagClassNames[category || 'Full'];
  const intent = dimensionCategoryIntentMap[category];

  if (!className) {
    className = getClassNameFromGroup(category);
  }

  return Object.keys(metrics).map(metric => ({
    value: metric,
    label: metrics[metric],
    className,
    intent,
    group: groupName || category,
    tagLabel: tagLabels[metric] || metrics[metric]
  }));
}

function getGroupDimensions(tagLabels, category, optionGroups) {
  const [src, dst, other, subGroups] = optionGroups;

  const result = [
    getGroupDimension(tagLabels, 'Source', src),
    getGroupDimension(tagLabels, 'Destination', dst),
    getGroupDimension(tagLabels, '', other)
  ];

  if (subGroups) {
    const subGroupOptions = {};

    Object.keys(subGroups).forEach(subGroupName => {
      subGroupOptions[subGroupName] = getGroupDimensions(tagLabels, category, subGroups[subGroupName]);
    });

    result.push(subGroupOptions);
  }

  return result;
}

function getGroupFilters(tagLabels, category, optionGroups) {
  const [src, dst, src_dst, other, subGroups] = optionGroups;

  const result = [
    getGroupDimension(tagLabels, 'Source', src),
    getGroupDimension(tagLabels, 'Destination', dst),
    getGroupDimension(tagLabels, 'Source or Dest', src_dst),
    getGroupDimension(tagLabels, '', other)
  ];

  if (subGroups) {
    const subGroupOptions = {};

    Object.keys(subGroups).forEach(subGroupName => {
      subGroupOptions[subGroupName] = getGroupFilters(tagLabels, category, subGroups[subGroupName]);
    });

    result.push(subGroupOptions);
  }

  return result;
}

function getGroupedDimensionOptions(categorizedOptionData, tagLabels = {}) {
  const dimensionOptions = {};

  Object.keys(categorizedOptionData).forEach(category => {
    const options = categorizedOptionData[category];

    if (Array.isArray(options)) {
      dimensionOptions[category] = getGroupDimensions(tagLabels, category, options);
    } else {
      dimensionOptions[category] = getGroupDimension(tagLabels, category, options);
    }
  });

  return dimensionOptions;
}

function getGroupedFilterOptions(categorizedOptionData, tagLabels = {}) {
  const dimensionOptions = {};

  Object.keys(categorizedOptionData).forEach(category => {
    const options = categorizedOptionData[category];

    if (Array.isArray(options)) {
      dimensionOptions[category] = getGroupFilters(tagLabels, category, options);
    } else {
      dimensionOptions[category] = getGroupDimension(tagLabels, category, options);
    }
  });

  return dimensionOptions;
}

export function flatten(options) {
  return Object.keys(options).reduce((acc, category) => {
    const categoryOptions = options[category];

    categoryOptions.forEach(subCategory => {
      if (Array.isArray(subCategory)) {
        acc.push(...subCategory);
      } else {
        acc.push(...flatten(subCategory));
      }
    });

    return acc;
  }, []);
}

function getFlatDimensionOptions(categorizedOptionData, tagLabels = {}) {
  return flatten(getGroupedDimensionOptions(categorizedOptionData, tagLabels));
}

function getFlatFilterOptions(categorizedOptionData, tagLabels = {}) {
  return flatten(getGroupedFilterOptions(categorizedOptionData, tagLabels));
}

class Dictionary {
  @observable
  loadingDictionary;

  filterFieldOptions = {};

  filterFieldValueOptions = {};

  flatFilterFieldOptions = [];

  filterOperatorOptions = {};

  dimensionOptions = {};

  flatDimensionOptions = [];

  flatCustomDimensionOption = [];

  dictionary;

  @action
  initialize() {
    this.loadingDictionary = true;

    return api.get('/api/portal/dictionary').then(response => {
      this.dictionary = response || {};
      this.initializeOptions();
      this.loadingDictionary = false;
    });
  }

  initializeOptions() {
    const { queryFilters, chartTypes, chartTypeLabels, alertScoreboardDimensionTypes } = this.dictionary;
    const { filterTypes, standardFilterOperators, parametricFilterTypes, operators, options } = queryFilters;

    this.flatFilterFieldOptions = getFlatFilterOptions(filterTypes);
    this.filterFieldOptions = getGroupedFilterOptions(filterTypes);

    this.flatDimensionOptions = getFlatDimensionOptions(chartTypes, chartTypeLabels);
    this.dimensionOptions = getGroupedDimensionOptions(chartTypes, chartTypeLabels);

    this.flatCustomDimensionOption = this.flatFilterFieldOptions.filter(
      opt => opt.value.startsWith('c_') || opt.value.startsWith('kt_')
    );

    this.alertScoreboardDimensionOptions = sortBy(
      Object.keys(alertScoreboardDimensionTypes).map(dimension => ({
        value: dimension,
        label: alertScoreboardDimensionTypes[dimension].label
      })),
      'label'
    );

    this.standardFilterOperators = Object.keys(standardFilterOperators).map(operator => ({
      value: operator,
      label: standardFilterOperators[operator]
    }));

    this.parametricDashboardFilterOptions = sortBy(parametricFilterTypes, 'label');

    this.filterOperatorOptions = Object.keys(operators).reduce((opts, field) => {
      opts[field] = Object.keys(operators[field]).map(value => ({
        label: operators[field][value],
        value
      }));
      return opts;
    }, {});

    this.addFilterFieldValueOptions(
      Object.keys(options).reduce((opts, field) => {
        opts[field] = Object.keys(options[field]).map(value => ({
          label: options[field][value],
          value
        }));
        return opts;
      }, {})
    );

    this.flatFilterLabels = this.flatFilterFieldOptions.map(option => ({
      value: option.value,
      label: `${option.group} ${option.label}`
    }));

    this.dictionary.flex_columns.forEach(col => ALERT_DIMENSIONS_WHITELIST.add(col));
    this.dictionary.flex_metrics.forEach(metric => {
      if (!ALERT_METRIC_OPTIONS.find(alertMetric => alertMetric.value === metric.value)) {
        ALERT_METRIC_OPTIONS.push(metric);
      }
    });
  }

  addFilterFieldValueOptions = optionMap => {
    Object.keys(optionMap).forEach(field => {
      this.filterFieldValueOptions[field] = optionMap[field];
    });
  };

  getTooltips = section => get(TOOLTIP_DATA, section);

  getSelectOptions = (section, options = {}) => {
    const { parseKeys = false, omitValues = [] } = options;
    const selectOptionMap = get(this.dictionary, section) || {};

    const selectOptions = [];

    Object.keys(selectOptionMap).forEach(optionKey => {
      const value = parseKeys ? parseInt(optionKey, 10) : optionKey;

      if (!omitValues.includes(value)) {
        selectOptions.push({ value, label: selectOptionMap[optionKey] });
      }
    });

    return selectOptions;
  };

  getFilterValueValidator = dimension => {
    const { options, validators, validatorsBase, defaultValidator } = this.dictionary.queryFilters;
    const dimensionValidator = validators[dimension];

    if (options[dimension]) {
      return ['required', { in: Object.keys(options[dimension]) }];
    } else if (!dimensionValidator) {
      // no rules specified, use default validator
      return [`regex:/${validatorsBase[defaultValidator.replace('base:', '')]}/`];
    } else if (dimensionValidator.startsWith('base:')) {
      const rules = [];
      if (dimensionValidator.endsWith('+')) {
        // + rules require one or more values, so add required rule
        rules.push('required');
      }
      // rules specified as a regex from backend, use it.
      rules.push(`regex:/${validatorsBase[dimensionValidator.replace('base:', '')]}/`);
      return rules;
    }

    // rules specified, but not a hard coded regex from backend, so assume local rules.
    return dimensionValidator.split(',');
  };

  getAvailableFiltersForParametricType = parametric_type => {
    if (!parametric_type) {
      return [];
    }

    const { flatParametricFilterTypes } = this.dictionary.queryFilters;
    const parametricFilterOptions = flatParametricFilterTypes[parametric_type.type];

    if (!parametricFilterOptions) {
      return [];
    }

    return this.flatFilterLabels.filter(filter => parametricFilterOptions.includes(filter.value));
  };

  getHelpUrl = (section, key) => {
    const { helpUrls } = this.dictionary;
    return `${helpUrls.helpBaseUrl}/${helpUrls[section][key]}`;
  };

  getCloudDimensions(filter) {
    const dimensions = this.dictionary.cloudDimensions;
    if (filter) {
      return dimensions.filter(key => key.indexOf(filter) > -1);
    }
    return dimensions;
  }
}

export default new Dictionary();
