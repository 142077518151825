import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Dialog } from '@blueprintjs/core';

import Export from 'components/Export';
import { Table } from 'components/table';
import { dateTimeFormatter } from 'util/dateUtils';

const columns = [
  {
    name: 'type',
    label: 'Type',
    width: 60,
    renderer: ({ value }) => (value ? value.toUpperCase() : value)
  },
  {
    name: 'fileName',
    label: 'File',
    flexBasis: 200,
    renderer: ({ model }) => <Export {...model.toJS()} />
  },
  {
    name: 'generated',
    label: 'Generated',
    renderer: dateTimeFormatter
  }
];

const dialogStyles = {
  width: 650
};

@inject('$exports')
@observer
class ExportDialog extends Component {
  render() {
    const { recentExports, dialogVisible, toggleDialogVisible } = this.props.$exports;

    return (
      <Dialog
        isOpen={dialogVisible}
        title="Recent Exports"
        onClose={toggleDialogVisible}
        style={dialogStyles}
        transitionName="pt-dialog"
      >
        <div className="pt-dialog-body">
          <div className="pt-card flat">
            <Table collection={recentExports} columns={columns} selectOnRowClick={false} />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default ExportDialog;
