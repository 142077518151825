import React, { Component } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { Field, Input, Select } from 'components/forms';

export function getCutFnFieldName(fieldConfigName) {
  return fieldConfigName.slice('cutFn.'.length);
}

@inject('$dictionary')
@observer
class CutFnSection extends Component {
  static contextTypes = {
    form: any
  };

  getLabel = field => {
    const { chartTypesValidations } = this.props.$dictionary.dictionary;

    return chartTypesValidations[field];
  };

  handleCutFnChange = (field, value) => {
    const { form } = this.context;
    const cutFunction = this.props.$dictionary.dictionary.cutFn.functions.find(fn => fn.value === value);

    const name = getCutFnFieldName(field.name);
    const cutFnRegexField = form.getField(`cutFnRegex.${name}`);
    const cutFnSelectorField = form.getField(`cutFnSelector.${name}`);

    cutFnRegexField.setValue(value);
    cutFnSelectorField.setValue((cutFunction && cutFunction.selector) || '');
  };

  render() {
    const { form } = this.context;
    const { functions: cutFunctions, urlFields: cutFnUrlFields } = this.props.$dictionary.dictionary.cutFn;
    const cutFnFields = form.fieldStates.filter(field => field.name.startsWith('cutFn.'));
    const cutFnRegexFields = form.fieldStates.filter(field => field.name.startsWith('cutFnRegex.'));
    const cutFnSelectorFields = form.fieldStates.filter(field => field.name.startsWith('cutFnSelector.'));

    if (cutFnFields.length > 0) {
      return (
        <Box mt={1}>
          {cutFnFields.map((field, idx) => {
            const fieldName = getCutFnFieldName(field.name);
            const label = this.getLabel(fieldName);
            const regexField = cutFnRegexFields[idx];
            const selectorField = cutFnSelectorFields[idx];
            const type = cutFnUrlFields.includes(fieldName) ? 'url' : 'host';
            const cutFns = [{ value: '', label: 'None', selector: '' }].concat(cutFunctions.filter(fn => fn[type]));

            return (
              <Box key={fieldName} className="pt-callout" mb={1}>
                <Field field={field} label={`Extract from ${label}`} onChange={this.handleCutFnChange} options={cutFns}>
                  <Select className="pt-small" />
                </Field>
                <Flex>
                  <Box col={8} mr={1}>
                    <Field field={regexField} label="Regex" className="no-margin">
                      <Input className="pt-small" />
                    </Field>
                  </Box>
                  <Box col={4}>
                    <Field field={selectorField} label="Selector" className="no-margin">
                      <Input className="pt-small" />
                    </Field>
                  </Box>
                </Flex>
              </Box>
            );
          })}
        </Box>
      );
    }

    return null;
  }
}

export default CutFnSection;
