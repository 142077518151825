// If we add entityType and title fields to all models we won't need to keep below mapping (and a similar one on node side)
const entityTypes = {
  Device: { type: 'devices', titleField: 'device_name' },
  Interface: { type: 'interfaces', titleField: 'interface_description', parentId: 'device_id' },
  'Alert Policy': { type: 'alert-policies', titleField: 'policy_name' },
  'Saved Filter': { type: 'saved-filters', titleField: 'filter_name' },
  User: { type: 'users', titleField: 'user_full_name' },
  Site: { type: 'sites', titleField: 'title' },
  Subscription: { type: 'subscription', titleField: 'title' },
  Dashboard: { type: 'dashboards', titleField: 'dash_title' },
  'Dashboard Panel': { type: 'dashboards/dashItems', titleField: 'panel_title', id: 'dashboard_id' }
};

export default entityTypes;
