import { get } from 'lodash';

/**
 * atLeastOneIfNotAllDevices can be configured with no input (regular string inclusion)
 * or with deviceSelectionArrays, as such: [{ atLeastOneIfNotAllDevices: ['query.device_types', 'query.device_labels', ...] }]
 */

function atLeastOneIfNotAllDevices(
  value,
  deviceSelectionArrays = ['device_types', 'device_labels', 'device_sites', 'device_name']
) {
  if (value) {
    return true;
  }

  return deviceSelectionArrays.some(deviceArrayField => get(this.validator.input, deviceArrayField, []).length > 0);
}

export default {
  fn: atLeastOneIfNotAllDevices,
  message: 'You must select at least one device'
};
