import React from 'react';
import { action, computed, observable } from 'mobx';
import { sortBy } from 'lodash';
import QueryTemplateCollection from 'models/query/QueryTemplateCollection';
import CategoryCollection from 'models/categories/CategoryCollection';

class QueryTemplatesStore {
  @observable
  collection = new QueryTemplateCollection();

  @observable
  categories = new CategoryCollection();

  initialize() {
    this.categories.fetch();
  }

  @action
  createTemplateFromQueries = async (template, attributes, queryBuckets) => {
    const newTemplate = await this.collection.forge().save({ ...attributes, saved_query_id: '' }, { toast: false });

    // give each query a `template_id`.
    const buckets = queryBuckets.serialize();
    buckets.forEach(bucket => (bucket.query.template_id = newTemplate.id));
    queryBuckets.set(buckets);

    const saved_query_id = await queryBuckets.save(true);
    await this.collection.get(newTemplate.id).save({ saved_query_id }, { toast: false });

    this.history.push(`/admin/queryTemplates/${newTemplate.id}`);
  };

  getFlattenedCategory = (category, depth = 0) => {
    if (category.childCategories) {
      category.childCategories.forEach(cat => (cat.depth = depth + 1));
      return [category].concat(...category.childCategories.map(val => this.getFlattenedCategory(val, depth + 1)));
    }
    return [category];
  };

  flattenNestedCategories = categories =>
    categories.reduce((acc, category) => acc.concat(...this.getFlattenedCategory(category)), []);

  @computed
  get categoryOptions() {
    const categories = this.categories.get().map(cat => ({
      value: cat.id,
      label: cat.get('name'),
      iconName: cat.get('icon'),
      parent_id: cat.get('parent_id'),
      depth: 0
    }));

    const sorted = sortBy(categories, 'label');

    const nested = sorted.reduce((acc, category) => {
      if (category.parent_id) {
        const parent = categories.find(cat => cat.value === category.parent_id);
        if (!parent.childCategories) {
          parent.childCategories = [];
        }
        parent.childCategories.push(category);
      } else {
        acc.push(category);
      }
      return acc;
    }, []);

    const options = this.flattenNestedCategories(nested);
    options.unshift({ value: '', label: 'None' });

    // @TODO we maybe want to build this sort of functionality into <Select />>?
    options.unshift({
      value: 'ADD_NEW',
      label: <strong className="pt-intent-primary-text">Add New</strong>,
      iconCls: 'plus'
    });
    return options;
  }
}

export default new QueryTemplatesStore();
