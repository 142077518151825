import React from 'react';
import { observer } from 'mobx-react';
import Highcharts from 'highcharts/highcharts';
import ReactHighcharts from 'react-highcharts';
import $app from 'stores/$app';
import $auth from 'stores/$auth';
import BaseDataview from './BaseDataview';

(function updateHighchartsToSupportNegativeLogAxes(H) {
  // Pass error messages
  H.Axis.prototype.allowNegativeLog = true;

  // Override conversions
  H.Axis.prototype.log2lin = function log2lin(num) {
    const isNegative = num < 0;
    let adjustedNum = Math.abs(num);
    if (adjustedNum < 10) {
      adjustedNum += (10 - adjustedNum) / 10;
    }
    const result = Math.log(adjustedNum) / Math.LN10;
    return isNegative ? -result : result;
  };
  H.Axis.prototype.lin2log = function lin2log(num) {
    const isNegative = num < 0;
    const absNum = Math.abs(num);
    let result = 10 ** absNum;
    if (result < 10) {
      result = (10 * (result - 1)) / (10 - 1);
    }
    return isNegative ? -result : result;
  };
})(Highcharts);

const ReactHC = ReactHighcharts.withHighcharts(Highcharts);

@observer
export default class BaseHighchartsDataview extends BaseDataview {
  componentWillReceiveProps(nextProps) {
    const { onModelSelect } = this.props;
    if (this.chart && this.chart.series && nextProps.onModelSelect !== onModelSelect) {
      console.warn('this is firing...');
      this.chart.series.forEach(series => {
        series.setOptions({
          events: {
            legendItemClick: this.getLegendItemClick()
          }
        });
      });
    }
  }

  chartRef = refs => {
    this.chart = refs && refs.chart;
  };

  handleTimeZoom = event => {
    const { dataview } = this.props;

    event.preventDefault();

    if (event.xAxis) {
      setTimeout(() => dataview.updateTimeRange(event.xAxis[0].min, event.xAxis[0].max), 0);
    } else {
      setTimeout(() => dataview.resetTimeRange(), 0);
    }
  };

  renderTimeReset() {
    if (!this.chart) {
      return;
    }

    let btn = this.chart.container.querySelector('.highcharts-button');
    if (!btn) {
      this.chart.showResetZoom();
      btn = this.chart.container.querySelector('.highcharts-button');
      btn.querySelector('text > tspan').innerHTML = 'Zoom out';
      btn.querySelector('rect').setAttribute('width', 68);
    }
  }

  findSeries(bucket, name) {
    return this.chart.series.find(series => series.name === name);
  }

  updateRenderedSeries(bucket, name, data) {
    const { added, removed, flow } = data;
    if (!data.updated) {
      return;
    }

    data.updated = false;
    const existingSeries = this.findSeries(bucket, name);
    if (existingSeries) {
      if (added) {
        const dataToAdd = flow.slice(-added);
        dataToAdd.forEach(point => existingSeries.addPoint(point, false));
      }
      if (removed && existingSeries.data) {
        let toRemove = removed;
        while (toRemove > 0 && existingSeries.data.length) {
          existingSeries.data[0].remove(false);
          toRemove -= 1;
        }
      }
    } else {
      console.warn('no series matching', name, 'found');
    }
  }

  updateRenderedOverlay(bucket, name, data) {
    this.updateRenderedSeries(bucket, name, data);
  }

  removeSeries(bucketName, name) {
    const existingSeries = this.chart && this.chart.series.find(series => series.name === name);
    if (existingSeries) {
      existingSeries.remove(false);
    }
  }

  toggleSeries(series, toggled) {
    series.setVisible(!toggled);
    this.redraw();
  }

  highlightSeries(series) {
    series.setState('hover');
    this.chart.renderer.boxWrapper.addClass('highcharts-legend-series-active');
  }

  unhighlightSeries(series) {
    this.chart.renderer.boxWrapper.removeClass('highcharts-legend-series-active');
    series.setState();
  }

  addNativeLegendHoverEvents(series) {
    if (this.props.showNativeLegend && (!series.events || !series.events.afterAnimate)) {
      series.update(
        {
          events: Object.assign({}, series.events, {
            afterAnimate: function afterAnimate() {
              const allMatchingSeries = this.chart.series.filter(s => s.name === this.name);
              this.legendItem
                .on('mouseover', () => {
                  allMatchingSeries.forEach(s => s.setState('hover'));
                })
                .on('mouseout', () => {
                  allMatchingSeries.forEach(s => s.setState());
                });
            }
          })
        },
        false
      );
    }
  }

  setSelectedModels(models) {
    this.selectedModels = models; // Keeping this around in case it needs to be used later
    this.setVisibleModels(models);
  }

  setVisibleModels(models) {
    if (this.chart) {
      this.chart.series.forEach(series => {
        if (series.options && series.options.model) {
          const { model } = series.options;
          const seriesKey = model.get('key');

          const visible = models.length === 0 || models.some(m => m.get('key') === seriesKey);
          series.setVisible(visible, false);
        }
      });

      this.chart.redraw();
    }
  }

  clear() {
    if (this.chart) {
      this.chart.series.forEach(series => series.remove(false));
      this.chart.redraw();
    }
  }

  updateColors(qualitativeColors = false) {
    if (!this.chart) {
      return;
    }

    const seriesMap = this.chart.series.reduce((map, series) => {
      map[series.name] = series.options.model;
      return map;
    }, {});

    // chart color palette (defaults for series)
    this.chart.update({ colors: qualitativeColors ? this.qualitativeColors : this.chartColors }, false);

    // update overlay colors
    this.chart.series
      .filter(series => series.name === 'Total')
      .forEach(series => series.update({ color: this.primaryOverlayColor }, false));

    this.chart.series
      .filter(series => series.name.includes('Historical Total'))
      .forEach(series => series.update({ color: this.overlayColor }, false));

    // axis colors
    const axisUpdate = {
      labels: { style: { color: this.chartLabelColor } },
      title: { style: { color: this.chartLabelColor } }
    };
    this.chart.xAxis.forEach(axis => axis.update(axisUpdate, false));
    this.chart.yAxis.forEach(axis => {
      axis.plotLinesAndBands.forEach(plotLine => {
        plotLine.options.label.style = { color: this.chartLabelColor };
      });
      axis.update(axisUpdate, false);
    });

    // legend colors
    const { legend } = this.chart;
    if (legend.options.enabled) {
      legend.update(
        {
          itemStyle: { color: `${this.chartLabelColor} !important` },
          itemHoverStyle: { color: `${this.chartLabelColor} !important` }
        },
        false
      );
    }

    $app.renderSync(() => {
      if (this.chart) {
        this.chart.redraw();
        this.chart.series.forEach(series => {
          if (seriesMap[series.name]) {
            if (series.options.color) {
              const matchingSeries = this.chart.series.find(s => s.name === series.name && s !== series);
              if (matchingSeries) {
                series.update({ color: matchingSeries.color });
              }
            }
            series.options.model = seriesMap[series.name];
            series.options.model.set({ color: series.color });
          }
        });
      }
    });
  }

  redraw({ setColors = false } = {}) {
    if (this.chart) {
      if (setColors) {
        this.updateColors();
      } else {
        $app.renderSync(() => {
          if (this.chart) {
            this.chart.redraw();
          }
        });
      }
      $app.renderSync(() => {
        this.dismissSpinner();
      });
    }
  }

  reflow() {
    if (this.chart && this.el) {
      this.chart.setSize(this.el.offsetWidth, this.el.offsetHeight);
      Object.assign(this.chart.options.chart, { width: null, height: null });
    }
  }

  syncAxes(sync) {
    this.sync = sync;
    this.redraw();
  }

  useLogAxis(use) {
    if (this.chart.yAxis.length === 1) {
      this.chart.yAxis[0].update({ min: use ? 0 : 0.001 }, false);
    }
    this.chart.yAxis[0].update({ type: use ? 'logarithmic' : 'linear' });
  }

  useSecondaryLogAxis(use) {
    if (this.chart.yAxis[1]) {
      this.chart.yAxis[1].update({ type: use ? 'logarithmic' : 'linear' });
    }
  }

  getExtremes() {
    let min = 0;
    let max = 0;
    if (this.chart && this.chart.yAxis) {
      this.chart.yAxis.forEach(axis => {
        const { dataMin, dataMax } = axis.getExtremes();
        min = Math.min(min, dataMin);
        max = Math.max(max, dataMax);
      });
    }
    return { min, max };
  }

  setExtremes(min, max) {
    if (min !== null && max !== null) {
      this.hasOverriddenExtremes = true;
      this.chart.yAxis.forEach(axis => axis.setExtremes(min, max));
    } else {
      this.hasOverriddenExtremes = false;
      this.chart.yAxis.forEach(axis => axis.setExtremes());
      this.chart.redraw();
    }
  }

  refHandler = ref => {
    this.el = ref;
  };

  getLegendItemClick() {
    const {
      onModelSelect,
      sourceLink: { model }
    } = this.props;
    const config = this.chartOptions;
    config.chart = config.chart || {};

    if (!$app.isExport) {
      return function onLegendItemClick(e) {
        if (onModelSelect && this.options && this.options.model && !this.options.model.get('isOverlay')) {
          const { metaKey } = e.browserEvent || e;
          e.preventDefault();

          let queryBucketModel = this.options.model;
          if ($auth.isDan && Math.random() < 0.1) {
            const randIndex = Math.floor(Math.random() * this.chart.series.length) + 1;
            queryBucketModel = this.chart.series[randIndex - 1].options.model;
          }

          onModelSelect(queryBucketModel, model, metaKey);
        }
      };
    }

    return null;
  }

  getComponent() {
    const { dataview } = this.props;
    const config = this.chartOptions;

    const time_format = dataview.queryBuckets.selectedQuery.get('time_format');
    const highchartsTimeOptions = {};
    if (typeof time_format === 'number') {
      highchartsTimeOptions.timezoneOffset = -time_format;
      highchartsTimeOptions.useUTC = true;
    } else if (time_format === 'Local') {
      highchartsTimeOptions.useUTC = false;
    } else {
      highchartsTimeOptions.useUTC = true;
    }
    config.time = highchartsTimeOptions;

    config.chart = config.chart || {};
    if ($app.isExport) {
      config.chart.animation = false;

      config.plotOptions = config.plotOptions || {};
      config.plotOptions.series = config.plotOptions.series || {};
      config.plotOptions.series.animation = false;
    } else {
      config.chart.animation = { duration: 250 };

      if (config.plotOptions && config.plotOptions.series) {
        const { series } = config.plotOptions;
        if (series.animation === undefined) {
          series.animation = { duration: 350 };
        }

        if (this.props.onModelSelect) {
          if (!series.events) {
            series.events = {};
          }
          series.events.legendItemClick = this.getLegendItemClick();
        }
      }
    }

    // config.chart.reflow = false;

    // chart: {
    //         reflow: false
    //     },

    return (
      <div ref={this.refHandler} style={{ position: 'absolute', height: '100%', width: '100%', overflow: 'hidden' }}>
        <ReactHC
          isPureConfig
          ref={this.chartRef}
          config={config}
          domProps={{ style: { height: '100%', width: '100%' } }}
        />
      </div>
    );
  }
}
