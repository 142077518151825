import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Dialog, Button, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Form, Field, Select } from 'components/forms/index';
import { isDev } from 'util/utils';

const fields = {
  userGroup: {
    label: 'Tenant',
    required: true
  },
  target_user_id: {
    label: 'Spoof As User',
    placeholder: 'Select User...',
    rules: 'required'
  },
  token: {
    label: 'Please enter your TOTP code',
    placeholder: isDev ? 'Token is ignored in dev environments' : undefined
  }
};

const options = {
  name: 'Open Tenant View'
};

@Form({ fields, options })
@inject('$auth', '$userGroups')
@observer
export default class SubtenantViewDialog extends Component {
  state = {
    userGroup: undefined,
    isMakingRequest: false
  };

  componentWillReceiveProps(nextProps) {
    const { $userGroups, isOpen, form } = this.props;
    const { selectedSubtenantId } = $userGroups;
    if (!isOpen && nextProps.isOpen) {
      if (!$userGroups.collection.hasFetched) {
        $userGroups.collection.fetch();
      }
    }

    if (selectedSubtenantId) {
      form.getField('userGroup').setValue(selectedSubtenantId);
      $userGroups.clearSelectedSubtenantId();
      this.setState({ userGroup: $userGroups.collection.get(selectedSubtenantId) });
    }
  }

  handleUserGroupChange = (field, userGroup) => {
    this.setState({ userGroup: this.props.$userGroups.collection.get(userGroup) });
  };

  handleSubmit = () => {
    const { form, $userGroups } = this.props;
    this.setState({ isMakingRequest: true });

    $userGroups
      .clearSpoofSession()
      .then(() => $userGroups.launchLandlordSession(form.getValues()))
      .then(
        () => {
          window.location = '/';
        },
        () => {
          this.setState({ isMakingRequest: false });
        }
      );
  };

  handleCancel = () => {
    const { form, onClose } = this.props;

    setTimeout(() => {
      form.reset();
      this.setState({ userGroup: null });
    }, 500);

    onClose();
  };

  render() {
    const {
      form,
      $userGroups: { userGroupOptions }
    } = this.props;
    const { isMakingRequest, userGroup } = this.state;

    return (
      <Dialog
        title="Open in Tenant View"
        {...this.props}
        style={{ top: 100, width: 476, minHeight: 275 }}
        transitionName="pt-dialog"
      >
        <Box p={3} pb={0} flexAuto>
          <Field name="userGroup" options={userGroupOptions} onChange={this.handleUserGroupChange}>
            <Select autoComplete exactMatch showFilter menuWidth={428} />
          </Field>
          <Field
            name="target_user_id"
            disabled={!userGroup}
            options={userGroup && userGroup.get('users').userSelectOptions}
          >
            <Select autoComplete exactMatch showFilter menuWidth={428} />
          </Field>
        </Box>
        <Box className="pt-dialog-footer" mt={2}>
          <Flex justify="flex-end">
            <Button text="Cancel" onClick={this.handleCancel} style={{ marginRight: 8, width: 110 }} />
            <Button
              disabled={!form.valid || !form.dirty}
              intent={Intent.PRIMARY}
              className="pt-medium"
              loading={isMakingRequest}
              text="Spoof"
              onClick={this.handleSubmit}
              style={{ minWidth: 110 }}
            />
          </Flex>
        </Box>
      </Dialog>
    );
  }
}
