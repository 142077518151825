import React from 'react';
import { action, computed } from 'mobx';
import { isString } from 'lodash';
import BaseModel from 'models/BaseModel';

class Populator extends BaseModel {
  get omitDuringSerialize() {
    return ['mac_count', 'addr_count', 'edited_by', 'created_date', 'updated_date'];
  }

  // override create/destroy to update customDimension populator count if possible. Have to just go to server due to
  // potential for populator counts not being yet or not updated yet at this point. This might not be right spot for
  // populator count updates long term...
  async create(attributes = {}, options = {}) {
    return super.create(attributes, options).then(results => {
      const customD = this.collection && this.collection.parent;
      if (customD && customD.collection) {
        customD.set('populatorCount', null);
        customD.collection.fetchPopulatorCounts();
      }
      return results;
    });
  }

  async destroy(options = {}) {
    return super.destroy(options).then(results => {
      const customD = this.collection && this.collection.parent;
      if (customD && customD.collection) {
        customD.set('populatorCount', null);
        customD.collection.fetchPopulatorCounts();
      }
      return results;
    });
  }

  deserialize(data = {}) {
    return super.deserialize(data.populator || data);
  }

  get messages() {
    return {
      create: 'Populator added successfully',
      update: 'Populator updated successfully',
      destroy: 'Populator removed successfully'
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Populator',
      text: (
        <p>
          Are you sure you want to remove <strong>{this.get('value')}</strong>? This action is not reversible.
        </p>
      )
    };
  }

  /**
   * For field count is greater than actual length of field array, field was too large and has been truncated.
   * Legacy populators don't have count fields and actual fields are not arrays, so values should compare equal
   * at MAX_SAFE_INTEGER and just return false.
   * @returns {boolean}
   */
  @computed
  get truncatedFields() {
    const macCount = parseInt(this.get('mac_count'));
    const addrCount = parseInt(this.get('addr_count'));
    const macList = this.get('mac');
    const addrList = this.get('addr');

    // only evaluate if fields are correct (hippo tags), otherwise bail with false.
    if (!Number.isNaN(macCount) && !Number.isNaN(addrCount) && Array.isArray(macList) && Array.isArray(addrList)) {
      return macCount > macList.length || addrCount > addrList.length;
    }
    return false;
  }

  /*
    overrode 'set' to fix bug when deleted fields show up after update.
  */
  @action
  set = (dataOrFieldName = {}, fieldData) => {
    if (isString(dataOrFieldName) && fieldData) {
      this.attributes.set(dataOrFieldName, fieldData);
    } else {
      this.attributes.replace(dataOrFieldName);
    }

    this.setLastUpdated();

    return this;
  };

  // make sort case-insensitive for value
  getSortValue(field) {
    return field === 'value' ? super.getSortValue(field).toLowerCase() : super.getSortValue(field);
  }
}

export default Populator;
