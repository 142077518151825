import React from 'react';
import { observer } from 'mobx-react';
import { AnchorButton, Intent } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import { Field, Input, Textarea, ShareLevelSelector } from 'components/forms';
import CategorySelector from 'views/Admin/Templates/CategorySelector';

const SavedViewDetailsForm = observer(({ model, form }) => (
  <Box>
    {model.isNew && (
      <Box mb={2} className="pt-callout pt-intent-primary">
        <h5>Saved Views</h5>
        <p>
          A saved view is a collection of Data Explorer settings, including the settings of the Query, Time, Filter,
          Devices, and Display panes in the sidebar.
        </p>
        <p className="no-margin">
          <AnchorButton
            rel="noopener noreferrer"
            className="pt-minimal"
            intent={Intent.PRIMARY}
            iconName="share"
            target="_blank"
            href="https://kb.kentik.com/?Db03.htm#Db03-Saved_Views"
          >
            <strong>Learn More About Saved Views</strong>
          </AnchorButton>
        </p>
      </Box>
    )}

    <Field name="view_name" autoFocus>
      <Input />
    </Field>

    <Field name="view_description">
      <Textarea />
    </Field>

    <CategorySelector
      model={model}
      saveAfterCreateCategory={false}
      inputStyle={{ width: 250 }}
      field={form.getField('category_id')}
    />

    <Box mb={2}>
      <ShareLevelSelector
        label="Visibility"
        field={form.getField('share_level')}
        privateValue="user"
        sharedValue="company"
      />
    </Box>
  </Box>
));

export default SavedViewDetailsForm;
