import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$notifications')
class NotificationChannels extends Component {
  title = 'Notification Channel';

  url = '/alerting/notifications';

  render() {
    const { result, $notifications } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="notifications"
        url={this.url}
        nameField="notification_name"
        collection={$notifications.collection}
        {...this.props}
      >
        <span>type: {result.channel_type}</span>
      </ResultRow>
    );
  }
}

export default NotificationChannels;
