import React from 'react';

import { Page, PageBody } from 'components/page';

const Forbidden = () => (
  <Page constrainWidth={600} title="Not Found">
    <PageBody scrollable={false} bodyStyle={{ paddingTop: 16 }}>
      <h2>403 Forbidden</h2>
      <p className="pt-text-muted pt-monospace-text">
        You are not authorized to access this page. Please contact your administrative user for further details.
      </p>
    </PageBody>
  </Page>
);

export default Forbidden;
