import React from 'react';
import { observable, extendObservable, computed, action } from 'mobx';
import { pick } from 'lodash';

import AlertScoreboardDataViewModel from 'dataviews/views/AlertScoreboard/AlertScoreboardDataViewModel';
import RawFlowDataViewModel from 'dataviews/views/RawFlow/RawFlowDataViewModel';
import BaseModel from 'models/BaseModel';
import QueryModel from 'models/query/QueryModel';
import DataViewModel from 'models/DataViewModel';
import { getModelFilters, injectLegacySavedFilters } from 'services/filters';
import { getQueriesForHash } from 'services/urlHash';
import $dictionary from 'stores/$dictionary';
import $dashboards from 'stores/$dashboards';
import { addFilter, addFilterGroup, getDefaultFiltersObj, deepClone } from 'util/utils';

const parametricModes = {
  override_all: 'Override all existing filters',
  override_specific: 'Override specific existing filters',
  add_group: 'Add as a new Filter Group',
  ignore: 'Ignore'
};

const dataviewTypes = {
  explorer_query: DataViewModel,
  alert_scoreboard: AlertScoreboardDataViewModel,
  raw_flow: RawFlowDataViewModel
};

class DashboardItem extends BaseModel {
  @observable
  lastQuery = undefined;

  @observable
  fetchedQuery = false;

  @observable
  selectedModels = [];

  // We want lastQuery to layer parametric upon, this includes parametric
  lastFullQuery;

  constructor(attributes = {}, options = {}) {
    super();
    this.set(this.deserialize(attributes));
    const DashboardPanelDataView = dataviewTypes[attributes.panel_type] || DataViewModel;
    const { viewModel } = options;

    extendObservable(this, {
      dataview: new DashboardPanelDataView(viewModel || undefined)
    });
  }

  get urlRoot() {
    return '/api/portal/dashboards/dashItems';
  }

  get defaults() {
    return {
      x: 0,
      y: 0,
      w: 12,
      h: 4,

      minH: 2,
      minW: 3,

      device_locked: false,
      filter_source: 'additive',
      panel_title: 'New Panel',
      panel_type: 'explorer_query',
      parametric_mode: 'add_group',
      time_locked: false,
      dashboard_navigation: false,
      destination_dashboard: undefined
    };
  }

  get omitDuringSerialize() {
    return ['dashboard', 'queries', 'editActiveTab'];
  }

  get removalConfirmText() {
    return {
      title: 'Remove Panel',
      text: (
        <span>
          Are you sure you want to remove <strong>{this.dataview.title}</strong>?
        </span>
      )
    };
  }

  get messages() {
    return {
      create: `Panel "${this.dataview.title}" was added successfully`,
      update: `Panel "${this.dataview.title}" was updated successfully`,
      destroy: `Panel "${this.dataview.title}" was removed successfully`
    };
  }

  /**
   * Fetch the queries for this panel and then store them in a transient attribute
   */
  @action
  fetchSavedQuery = async () => {
    this.fetchedQuery = false;
    return getQueriesForHash(this.get('saved_query_id')).then(
      action(res => {
        const queries = res.map(({ query }) => QueryModel.create(query));
        this.set({ queries });

        // set query object if raw flow item
        if (res && res[0] && this.get('panel_type') === 'raw_flow') {
          const rawFlowQuery = QueryModel.create(res[0]);
          this.set({ rawFlowQuery });
        }

        this.fetchedQuery = true;
      })
    );
  };

  @computed
  get formState() {
    const { formComponent, formConfig } = this.dataview;
    return {
      component: formComponent,
      fields: formConfig
    };
  }

  @action
  openDashboardItemConfigurationDialog = () => {
    this.select();
  };

  @action
  clearSelectedModels = () => {
    this.selectedModels = [];
    this.dataview.setSelectedModels([]);
  };

  @action
  applyFullQuery = query => {
    this.dataview.resetTimeRange(true);
    this.dataview.initializeHashWithOverrides(this.get('saved_query_id'), query);
  };

  @computed
  get panelDescription() {
    const dashboard = this.get('dashboard');
    const panel_description = this.get('panel_description') || '';
    const parametric_mode = this.get('parametric_mode');
    const parametric_overrides = this.get('parametric_overrides');

    return this.translatePlaceholders(
      panel_description,
      dashboard.get('parametric') && parametric_mode !== 'ignore',
      dashboard.get('parametric_fields')[0],
      parametric_overrides
    );
  }

  @action
  updateQuery = queryOptions => {
    /*
     * Just keeps track of which query was run last, that way we can call `updateQuery` with no queryOptions and
     * force the dataView to update it's underlying query (when new parametric filters are applied, etc).
     */
    this.dataview.eachBucket('setLoading', [true]);
    if (queryOptions) {
      this.lastQuery = queryOptions;
    }
    const {
      panel_title,
      filter_source,
      parametric_mode,
      dashboard,
      saved_query_id,
      parametric_overrides,
      time_locked,
      device_locked
    } = this.get();
    const parametric_fields = dashboard.get('parametric_fields');
    const parametric = dashboard.get('parametric');
    const fieldsToExtract = [];

    if (!time_locked) {
      fieldsToExtract.push('lookback_seconds', 'starting_time', 'ending_time', 'time_format');
    }

    if (!device_locked) {
      fieldsToExtract.push('all_devices', 'device_name', 'device_types', 'device_sites', 'device_labels');
    }

    if (filter_source !== 'locked') {
      fieldsToExtract.push('filters', 'filters_obj');
    }

    // deep clone to avoid filter clashing across panels
    const query = deepClone(pick(queryOptions || this.lastQuery || dashboard.get('query'), fieldsToExtract));
    query.query_title = this.translatePlaceholders(
      panel_title,
      parametric && parametric_mode !== 'ignore',
      parametric_fields[0],
      parametric_overrides
    );

    const shouldOverlayFilters = filter_source === 'additive';
    const syncDepth = true;

    if (this.dataview.lastUpdated) {
      this.dataview.clear();
    }

    this.dataview.resetTimeRange(true);

    if (this.get('panel_type') === 'raw_flow') {
      this.dataview.setTitle(panel_title);
      this.dataview.initializeHashWithOverrides(saved_query_id, query);
    } else if (!this.dataview.isFlowVisualization) {
      this.dataview.initializeHash(saved_query_id);
    } else if (parametric && parametric_overrides) {
      const { filterField } = parametric_overrides;
      let { operator } = parametric_overrides;
      // Although we have certain parametric fields that should not allow a blank value, we currently have no way to determine
      // which should receive that treatment. For now, the UI should allow a blank value to be specified as a filter value
      let filterValue = parametric_fields[0].value;

      if (filterValue === undefined || filterValue === null) {
        filterValue = dashboard.get('parametric_value');
      }

      if (!operator) {
        operator = $dictionary.dictionary.queryFilters.parametricOperators[dashboard.parametricField.type];
      }

      if (parametric_mode === 'override_all') {
        const filters_obj = getDefaultFiltersObj();
        addFilterGroup(filters_obj);
        addFilter(filters_obj, filterField, operator, filterValue);
        const overrides = { filters_obj };
        this.dataview.initializeHashWithOverrides(saved_query_id, query, syncDepth, true, overrides);
      } else if (parametric_mode === 'add_group') {
        query.filters_obj = addFilterGroup(query.filters_obj || getDefaultFiltersObj());
        addFilter(query.filters_obj, filterField, operator, filterValue, query.filters_obj.filterGroups.length - 1);
        this.dataview.initializeHashWithOverrides(saved_query_id, query, syncDepth, shouldOverlayFilters);
      } else if (parametric_mode === 'override_specific') {
        const overrides = {
          specific: true,
          filterField,
          operator,
          filterValue
        };
        this.dataview.initializeHashWithOverrides(saved_query_id, query, syncDepth, true, overrides);
      } else {
        this.dataview.initializeHashWithOverrides(saved_query_id, query, syncDepth, shouldOverlayFilters);
      }
    } else {
      this.dataview.initializeHashWithOverrides(saved_query_id, query, syncDepth, shouldOverlayFilters);
    }

    this.lastFullQuery = query;
  };

  @action
  refresh = () => {
    this.dataview.refresh();
  };

  @action
  clear() {
    this.dataview.destroy();
  }

  @action
  reflow() {
    this.dataview.reflow();
  }

  @action
  setUpdateFrequency = frequency => {
    this.dataview.setUpdateFrequency(frequency);
  };

  @computed
  get fullyLoaded() {
    return this.dataview && this.dataview.fullyLoaded;
  }

  @computed
  get queryBuckets() {
    return this.dataview && this.dataview.queryBuckets.activeBuckets;
  }

  @computed
  get parametricModeDisplay() {
    return parametricModes[this.get('parametric_mode')];
  }

  @computed
  get layoutData() {
    const { id, w, x, y, h, minW, minH } = this.get();

    return {
      lg: {
        i: `${id}`,
        x,
        y,
        w,
        h,
        minH,
        minW
      },
      sm: {
        i: `${id}`,
        x,
        y,
        w: 1,
        h,
        minH,
        minW: 1
      }
    };
  }

  deserialize(data) {
    if (!data) {
      return data;
    }

    data = super.deserialize(data);
    const {
      dashboard_id,
      parametric_overrides,
      filters_obj,
      saved_filters,
      dashboard_navigation_options: navOptions
    } = data;

    let { dashboard } = data;
    if (!dashboard) {
      dashboard = $dashboards.getDashboard(dashboard_id);
    }

    // add parametric_overrides.operator if it doesn't exist
    if (dashboard && dashboard.isParametric && parametric_overrides && !parametric_overrides.operator) {
      const {
        dictionary: { queryFilters }
      } = $dictionary;
      parametric_overrides.operator = queryFilters.parametricOperators[dashboard.parametricField.type];
    }

    if (saved_filters && saved_filters.length > 0) {
      data.filters_obj = injectLegacySavedFilters(saved_filters, filters_obj);
      delete data.saved_filters;
    }

    if (navOptions && navOptions.filters === 'custom') {
      const { filters, saved_filters: navSavedFilters } = navOptions.custom;

      if (navSavedFilters && navSavedFilters.length > 0) {
        navOptions.custom.filters = injectLegacySavedFilters(navSavedFilters, filters);
        delete navOptions.custom.saved_filters;
      }
    }

    if (navOptions && !navOptions.nesting) {
      navOptions.nesting = {
        drillDownButtonText: 'Drill Down',
        parentPanelVisibility: 'visible'
      };
    }

    return data;
  }

  translatePlaceholders = (title, parametric, parametricField, parametricOverrides = {}) => {
    const valueRe = /{{filter_value}}/g;
    const labelRe = /{{filter_dimension}}/g;

    const filterField = parametricOverrides && parametricOverrides.filterField;

    let label = '';
    let value = '';
    if (parametric) {
      const filterLabel = $dictionary.flatFilterLabels.find(filter => filter.value === filterField);
      if (filterLabel) {
        label = filterLabel.label;
      }
      value = parametricField.value;
    }

    return title.replace(valueRe, value).replace(labelRe, label);
  };

  getParametricKeyFromModel = (model, parametric_field) => {
    const { type: parametricFieldType } = parametric_field;
    const dictionaryOption = $dictionary.parametricDashboardFilterOptions.find(
      option => option.type === parametricFieldType
    );
    if (dictionaryOption) {
      const filtersAcc = getDefaultFiltersObj();
      const filters_obj = getModelFilters({ model, bucket: this.queryBuckets[0], filtersAcc });

      const matchedFilter =
        filters_obj.filterGroups.length > 0 &&
        filters_obj.filterGroups[0].filters &&
        filters_obj.filterGroups[0].filters.find(filter => dictionaryOption.filterFields.includes(filter.filterField));

      if (matchedFilter) {
        return matchedFilter.filterValue;
      }
    }
    return undefined;
  };
}

export default DashboardItem;
