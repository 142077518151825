import React from 'react';
import { Tag } from '@blueprintjs/core';

const PanelTitleHelpText = ({ parametric }) => {
  if (parametric) {
    return (
      <div>
        <p>
          Note: For a title that reflects current Guided mode settings, insert{' '}
          <Tag className="pt-minimal pt-monospace-text">{'{{filter_dimension}}'}</Tag> and{' '}
          <Tag className="pt-minimal pt-monospace-text">{'{{filter_value}}'}</Tag> as placeholders.
        </p>
        <p className="no-margin">
          Ex: To show <strong>"Top Talkers: Dest Port 443"</strong>, enter{' '}
          {'"Top Talkers: {{filter_name}} {{filter_value}}"'}
        </p>
      </div>
    );
  }
  return (
    <span>
      <strong>Note: </strong> The <Tag className="pt-minimal pt-monospace-text">{'{{filter_dimension}}'}</Tag> and{' '}
      <Tag className="pt-minimal pt-monospace-text">{'{{filter_value}}'}</Tag> placeholders can only be used in the
      title if the dashboard is in Guided Mode.
    </span>
  );
};

export default PanelTitleHelpText;
