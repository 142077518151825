import PagedCollection from '../PagedCollection';
import Tag from './Tag';

// TODO: Change back to "TagCollection" name once hippo changeover complete and old TagCollection can go away.
class PagedTagCollection extends PagedCollection {
  get url() {
    return '/api/portal/tags/hyperscale';
  }

  get urlPaths() {
    return {
      pagedFetch: '/api/portal/tags/search'
    };
  }

  get queuedFetchKey() {
    return 'pagedTagCollection';
  }

  get jsonRoot() {
    return 'tags';
  }

  get model() {
    return Tag;
  }

  get defaultSortState() {
    return {
      field: 'value',
      direction: 'desc'
    };
  }

  get filterFieldWhitelist() {
    return new Set(['flow_tag', 'id', 'edited_by']);
  }
}

export default PagedTagCollection;
