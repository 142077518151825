import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';

import SelectedValueButton from './SelectedValueButton';

@inject('$deviceGroups', '$devices', '$sites')
@observer
class DeviceSelectorDisplay extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    showLabels: true,
    showSites: true,
    showTypes: true,
    readOnly: false
  };

  handleRemove = (model, fieldName, e) => {
    e.stopPropagation();

    const { form } = this.context;
    const { onRemove } = this.props;
    onRemove(model, form.getField(fieldName));
  };

  render() {
    const {
      selectedValues,
      allDevices,
      deviceTypesFieldName,
      deviceLabelsFieldName,
      deviceSitesFieldName,
      deviceNameFieldName,
      disabled,
      readOnly
    } = this.props;

    return (
      <Fragment>
        {allDevices && (
          <SelectedValueButton
            type="all"
            label="All Devices"
            iconName="multi-select"
            className="pt-small"
            devices={selectedValues.get('all')}
            disabled={disabled}
          />
        )}

        {selectedValues.get('types').map(selectedType => (
          <SelectedValueButton
            type="deviceTypes"
            className="pt-small"
            label={selectedType.label}
            key={selectedType.value}
            iconName={selectedType.iconName}
            devices={selectedType.devices}
            onRemove={readOnly ? undefined : e => this.handleRemove(selectedType, deviceTypesFieldName, e)}
            disabled={disabled}
          />
        ))}
        {selectedValues
          .get('labels')
          .map(
            selectedLabel =>
              selectedLabel && (
                <SelectedValueButton
                  type="deviceLabels"
                  className="pt-small"
                  iconName="tag"
                  label={selectedLabel.label}
                  key={selectedLabel.value}
                  devices={selectedLabel.activeDevices}
                  color={selectedLabel.color}
                  onRemove={readOnly ? undefined : e => this.handleRemove(selectedLabel, deviceLabelsFieldName, e)}
                />
              )
          )}
        {selectedValues
          .get('sites')
          .map(
            selectedSite =>
              selectedSite && (
                <SelectedValueButton
                  type="deviceSites"
                  className="pt-small"
                  iconName="map-marker"
                  label={selectedSite.label}
                  key={selectedSite.value}
                  devices={selectedSite.activeDevices}
                  onRemove={readOnly ? undefined : e => this.handleRemove(selectedSite, deviceSitesFieldName, e)}
                  disabled={disabled}
                />
              )
          )}

        {selectedValues
          .get('devices')
          .map(
            device =>
              device && (
                <SelectedValueButton
                  type="deviceNames"
                  className="pt-small"
                  iconName={device.iconName}
                  label={device.label}
                  key={device.value}
                  onRemove={readOnly ? undefined : e => this.handleRemove(device, deviceNameFieldName, e)}
                  disabled={disabled}
                />
              )
          )}
      </Fragment>
    );
  }
}

export default DeviceSelectorDisplay;
