import { computed, action, observable } from 'mobx';

import api from 'util/api';
import Collection from '../Collection';
import Company from './Company';

class CompanyCollection extends Collection {
  @observable
  fetchingFPS = false;

  get defaultSortState() {
    return {
      field: 'company_name',
      direction: 'asc'
    };
  }

  get presetFilters() {
    return [
      {
        label: 'Active Trials',
        fn: model => model.isActiveTrial
      },
      {
        label: 'Expired Trials',
        fn: model => model.isExpiredTrial
      },
      {
        label: 'Suspended',
        fn: model => model.isSuspendedCompany
      },
      {
        label: 'Free Plans',
        fn: model => model.isFreePlan
      },
      {
        label: 'Customers',
        fn: model => model.isActiveCustomer
      },
      {
        label: 'Kentik Companies',
        fn: model => model.isKentik
      }
    ];
  }

  get url() {
    return '/api/sudo/companies';
  }

  get model() {
    return Company;
  }

  @action
  fetchCompaniesFps = () => {
    this.fetchingFPS = true;
    return api.get('/api/sudo/companies/fps').then(
      action(fpsResults => {
        fpsResults.forEach(({ company_id, p98_fps_rate_limited }) => {
          const model = this.get(company_id);
          if (model) {
            model.set({ p98_fps_rate_limited: parseFloat(p98_fps_rate_limited, 10) });
          }
        });

        this.fetchingFPS = false;
      })
    );
  };

  @computed
  get numCompanies() {
    return this.get().length;
  }

  @computed
  get numActiveTrials() {
    return this.get().filter(model => model.isActiveTrial).length;
  }

  @computed
  get numExpiredTrials() {
    return this.get().filter(model => model.isExpiredTrial).length;
  }

  @computed
  get numActiveCustomers() {
    return this.get().filter(model => model.isActiveCustomer).length;
  }

  @computed
  get numFreeCompanies() {
    return this.get().filter(model => model.isFreePlan).length;
  }

  @computed
  get selectOptions() {
    return this.get().map(model => ({
      value: model.companyId,
      label: model.get('company_name'),
      plan: model.companyPlan
    }));
  }
}

export default CompanyCollection;
