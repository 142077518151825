import React from 'react';
import { observer } from 'mobx-react';

const staticLabelStyle = {
  marginBottom: 4,
  width: 170
};

const ProfileLabel = props => (
  <div style={props.style ? { ...staticLabelStyle, ...props.style } : staticLabelStyle}>
    <strong>{props.children}</strong>
    {props.description && <div className="pt-text-muted">{props.description}</div>}
  </div>
);

export default observer(ProfileLabel);
