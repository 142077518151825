const options = {
  suppressCurrentForm: true
};

const fields = {
  user_email: {
    label: 'Email Address',
    placeholder: 'Email Address',
    rules: 'required|email'
  },
  password: {
    type: 'password',
    placeholder: 'Password',
    rules: 'required'
  },
  recaptcha: {}
};

export { fields, options };
