import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import FilterOptionsDialog from 'components/FilterOptions/FilterOptionsDialog';
import FilterOptionsDisplay from 'components/FilterOptions/FilterOptionsDisplay';

@inject('$savedFilters')
@observer
class SidebarFilterOptions extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    fieldName: 'filters',
    smallText: true,
    emptyText: 'No filtering criteria have been applied',
    readOnly: false,
    showNames: false
  };

  componentWillMount() {
    this.props.$savedFilters.collection.fetch();
  }

  handleCloseFilterDialog = () => {
    this.props.onClose();
  };

  render() {
    const { form } = this.context;
    const { className, emptyText, fieldName, isOpen, smallText } = this.props;

    const numFilters = form.getValue(`${fieldName}.filterGroups`).length;
    const emptyTextClassName = classNames('pt-text-muted', { 'pt-text-smaller': smallText });

    return (
      <div className={className}>
        {numFilters === 0 && <div className={emptyTextClassName}>{emptyText}</div>}
        {numFilters > 0 && <FilterOptionsDisplay {...this.props} />}
        {isOpen && <FilterOptionsDialog {...this.props} showSaveFiltersButton onClose={this.handleCloseFilterDialog} />}
      </div>
    );
  }
}

export default SidebarFilterOptions;
