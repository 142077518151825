import { action, computed, observable } from 'mobx';

import BaseModel from 'models/BaseModel';
import QueryModel from 'models/query/QueryModel';
import { getQueriesForHash } from 'services/urlHash';
import $auth from 'stores/$auth';
import $dictionary from 'stores/$dictionary';

export default class QueryTemplate extends BaseModel {
  @observable
  fetchedQuery = false;

  get defaults() {
    return {
      name: '',
      share_level: 'org',
      pivots: [],
      filters: [],
      options: {
        dimensions: { enabled: false, locked: true, optionsWhitelist: [] },
        vizTypes: { enabled: false, locked: false, optionsWhitelist: [] },
        metrics: {
          enabled: false,
          locked: false,
          aggregateTypes: ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'],
          aggregateThresholds: {},
          outsort: 'max_bits_per_sec',
          secondaryOutsort: '',
          secondaryTopxSeparate: false,
          secondaryTopxMirrored: false
        },
        devices: { mode: 'all_devices' }
      }
    };
  }

  get urlRoot() {
    return '/api/portal/queryTemplates';
  }

  get omitDuringSerialize() {
    return ['category', 'queries', 'loading', 'aggregateThresholds', 'aggregateTypes', 'viz_type'];
  }

  @action
  fetchQueries = async () => {
    this.fetchedQuery = false;
    return getQueriesForHash(this.get('saved_query_id')).then(
      action(res => {
        const queries = res.map(({ query }) => QueryModel.create(query));
        this.set({ queries });
        this.fetchedQuery = true;
        return this;
      })
    );
  };

  get name() {
    return this.get('name');
  }

  get description() {
    return this.get('description');
  }

  get iconName() {
    return 'series-configuration';
  }

  get type() {
    return 'Query Template';
  }

  get featured() {
    return this.get('featured');
  }

  @computed
  get serializedQuery() {
    return this.get('queries') && this.get('queries')[0].serialize();
  }

  @computed
  get shareLevel() {
    const level = this.isPreset ? 'Preset' : 'Shared';
    return this.isUserLevel ? 'Private' : level;
  }

  @computed
  get showVizTypeSelector() {
    const { locked, enabled } = this.get('options.vizTypes');
    return !locked && enabled;
  }

  @computed
  get showDimensionSelector() {
    const { locked, enabled } = this.get('options.dimensions');
    return !locked && enabled;
  }

  @computed
  get showPivots() {
    return this.get('pivots') && this.get('pivots').length > 0;
  }

  @computed
  get showFilterInputs() {
    return this.get('filters') && this.get('filters').length > 0;
  }

  @computed
  get showDeviceSelector() {
    return this.get('options.devices.mode').includes('user');
  }

  @computed
  get showMetricSelector() {
    const { locked, enabled } = this.get('options.metrics');
    return !locked && enabled;
  }

  @computed
  get isLoading() {
    return this.get('loading');
  }

  get isPreset() {
    return `${this.get('company_id')}` === `${$dictionary.dictionary.templateDashboardsCID}`;
  }

  get isCompanyLevel() {
    return !this.isPreset;
  }

  @computed
  get canEdit() {
    return $auth.hasSudo || $auth.isSpoofed;
  }
}
