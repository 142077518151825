import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Form, DateTime, Field } from 'components/forms/index';
import { fields, options } from 'forms/config/debugFields';
import DebugKeyDimensionCell from '../DebugKeyDimensionCell';

@Form({ fields, options })
@observer
class DebugFields extends Component {
  handleSubmit = () => {
    const { form, onSubmit, values } = this.props;
    const { ctime } = form.getValues();
    if (onSubmit && ctime) {
      const debugFields = { ...values, ctime };
      onSubmit(debugFields);
    }
  };

  render() {
    const { values } = this.props;

    return (
      <Flex gutter={1} align="flex-end" px={1} mb={2}>
        <Box mr={2}>
          <label className="pt-label">Key/Dimension</label>
          <DebugKeyDimensionCell values={values} />
        </Box>
        <Box mr={2}>
          <Field name="ctime" inputStyle={{ width: 140 }} className="no-margin">
            <DateTime />
          </Field>
        </Box>
        <Box mr={2}>
          <Button
            type="button"
            text="Apply"
            intent={Intent.PRIMARY}
            className="pt-medium"
            onClick={this.handleSubmit}
          />
        </Box>
      </Flex>
    );
  }
}

export default DebugFields;
