import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Tooltip } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { MaterialIcon } from 'components/Icon';
import { MANUAL_MITIGATION_DUMMY_IP } from 'stores/$alertingMitigation';

import AlertFilterField from '../AlertFilterField';

@inject('$auth', '$dictionary')
@observer
class KeyDimensionCell extends Component {
  render() {
    const {
      $auth,
      $dictionary,
      model,
      values,
      onFilterFieldClick,
      addToSilentMode,
      showSilentButton = true
    } = this.props;
    const cellValues = model ? model.get() : values;
    const { alert_key, alert_key_lookup, alert_dimension, row_type } = cellValues;
    const key_dimensions = [];

    // TODO: a view really shouldn't be doing a lot of this logic

    if (alert_key && alert_key_lookup && alert_dimension) {
      const alert_dimension_arr = alert_dimension.split(':');
      const alert_key_arr = alert_key.split('__##__');
      const alert_key_lookup_arr = alert_key_lookup.split('__##__');

      if (row_type !== 'Mitigation') {
        for (let indx = 0; indx < alert_key_lookup_arr.length; indx += 1) {
          const alert_key_lookup_item = alert_key_lookup_arr[indx];
          const filterVal = `${alert_dimension_arr[indx]}:${alert_key_arr[indx]}`;

          const tooltipText =
            filterVal !== alert_key_lookup_item ? `${alert_key_lookup_item} (${filterVal})` : alert_key_lookup_item;

          const firstColon = filterVal.indexOf(':');
          const dimension = filterVal.substring(0, firstColon);
          const value = filterVal.substring(firstColon);

          const dimensionDisplay = $dictionary.dictionary.chartTypesValidations[dimension] || (
            <span className="pt-text-muted">{dimension}</span>
          );

          key_dimensions.push(
            <div key={filterVal} className="pt-text-overflow-ellipsis" title={tooltipText}>
              <span className="pt-text-muted">{dimensionDisplay}:</span>{' '}
              {value && (
                <AlertFilterField
                  model={model}
                  filterBy="dimension_key"
                  filterVal={filterVal}
                  displayName={alert_key_lookup_item}
                  onFilterFieldClick={onFilterFieldClick}
                  className="pt-text-overflow-ellipsis"
                  showTooltip={false}
                />
              )}
            </div>
          );
        }
      } else {
        const isDummyIP = alert_key === `${MANUAL_MITIGATION_DUMMY_IP}/32` && model.isManualMitigation;

        key_dimensions.push(
          <div key={`IP_dst:${alert_key}`} className="overflow-hidden">
            <AlertFilterField
              model={model}
              filterBy="dimension_key"
              filterVal={`IP_dst:${alert_key}`}
              displayName={isDummyIP ? 'N/A' : alert_key}
              onFilterFieldClick={onFilterFieldClick}
              className="pt-text-overflow-ellipsis"
            />
            <div className="pt-text-overflow-ellipsis">{isDummyIP ? 'N/A' : alert_dimension}</div>
          </div>
        );
      }
    }

    const canSilence = row_type !== 'Mitigation' && $auth.hasPermission('alerts.silentMode.create');
    const keyDimensionsWidth = canSilence ? 'calc(100% - 38px)' : '100%';

    return (
      <Flex style={{ width: '100%' }}>
        {showSilentButton && (
          <Tooltip isDisabled={!canSilence} content="Add to Silent Mode" key="silent-mode-tooltip" className="ellipsis">
            <Button
              className="pt-minimal"
              onClick={() => addToSilentMode(model)}
              disabled={!canSilence}
              style={{ padding: 2, margin: '0 8px 0 0' }}
            >
              <MaterialIcon name="volume_off" style={{ marginRight: 0 }} />
            </Button>
          </Tooltip>
        )}
        <div style={{ width: keyDimensionsWidth }}>{key_dimensions}</div>
      </Flex>
    );
  }
}
export default KeyDimensionCell;
