import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { debounce } from 'lodash';
import { ENTER, ESC } from 'util/keyCodes';
import { InputGroup, Spinner } from '@blueprintjs/core';

import { Box } from 'components/flexbox';

@inject('$search')
@observer
class SearchInput extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    flexAuto: PropTypes.bool,
    input: PropTypes.string,
    inputName: PropTypes.string,
    mx: PropTypes.number,
    handleKeyPress: PropTypes.func,
    handleSearchInput: PropTypes.func,
    handleSearchResults: PropTypes.func
  };

  static defaultProps = {
    autoFocus: false,
    flexAuto: false,
    input: '',
    inputName: 'universalSearch',
    mx: 1,
    handleKeyPress: () => null,
    handleSearchInput: () => null,
    handleSearchResults: () => null
  };

  state = {
    input: '',
    timestamp: 0
  };

  debounceWait = 300;

  constructor(props) {
    super(props);
    this.state.input = props.input;
  }

  handleSearchInput = e => {
    const { handleSearchInput } = this.props;
    const input = e.target.value;
    const timestamp = Date.now();

    if (input !== this.state.input) {
      this.setState({ input, timestamp }, this.debounceSearch(timestamp));
    }

    handleSearchInput(e);
  };

  handleKeyPress = e => {
    const { handleKeyPress } = this.props;
    const timestamp = Date.now();

    if (e.keyCode === ESC) {
      this.setState({ input: '' });
    }

    if (e.keyCode === ENTER) {
      this.setState({ timestamp }, this.debounceSearch(timestamp));
    }

    handleKeyPress(e);
  };

  debounceSearch = debounce(timestamp => {
    if (timestamp === this.state.timestamp) {
      this.executeSearch();
    }
  }, this.debounceWait);

  executeSearch = () => {
    const { $search, handleSearchResults } = this.props;
    const { input, timestamp } = this.state;

    if (input !== '') {
      $search.query(input, timestamp).then(response => {
        if (response && parseInt(response.timestamp) === this.state.timestamp) {
          delete response.timestamp;
          handleSearchResults(input, response);
        }
      });
    }
  };

  render() {
    const { $search, autoFocus, flexAuto, inputName, mx } = this.props;
    const { input } = this.state;

    let rightElement;
    if ($search.isSearchLoading) {
      rightElement = <Spinner className="pt-small pt-intent-primary" />;
    }

    return (
      <Box flexAuto={flexAuto} mx={mx} w={180}>
        <InputGroup
          name={inputName}
          value={input}
          leftIconName="search"
          onChange={this.handleSearchInput}
          onKeyUp={this.handleKeyPress}
          autoComplete="off"
          autoFocus={autoFocus}
          rightElement={rightElement}
        />
      </Box>
    );
  }
}

export default SearchInput;
