import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent, Spinner } from '@blueprintjs/core';
import Recaptcha from 'react-grecaptcha';

import { Box } from 'components/flexbox';
import { Field, Form, Input } from 'components/forms';
import { fields } from 'forms/config/passwordSetRequest';

const labelStyle = {
  fontSize: 16,
  fontWeight: 500
};

@Form({ fields })
@inject('$auth')
@observer
class PasswordSetRequestForm extends Component {
  state = {
    ready: false,
    error: false,
    recaptchaEnabled: false,
    recaptchaSiteKey: null,
    passwordReqComplete: false
  };

  componentDidMount() {
    const { $auth, form } = this.props;
    $auth
      .getOpenConfig()
      .then(res => {
        const { recaptchaEnabled, recaptchaSiteKey } = res;
        form.getField('recaptcha').setRules(recaptchaEnabled ? 'required' : []);
        this.setState({ recaptchaEnabled, recaptchaSiteKey, ready: true });
      })
      .catch(() => {
        this.setState({ ready: true, error: true });
      });
  }

  recaptchaCallback = response => {
    this.props.form.getField('recaptcha').setValue(response);
  };

  recaptchaExpire = () => {
    this.props.form.getField('recaptcha').setValue(null);
  };

  handlePasswordSetRequestSubmit = async (form, formData) => {
    const { user_email, recaptcha } = formData;
    const result = await this.props.$auth.requestPasswordReset({ user_email, recaptcha });

    if (this.state.recaptchaEnabled) {
      window.grecaptcha.reset();
      this.props.form.getField('recaptcha').setValue(null);
    }

    if (result.success) {
      this.setState({ passwordReqComplete: true });
    } else {
      const { status } = result;
      let msg;
      if (status === 400) {
        msg = 'Invalid request. Please check user email.';
      } else if (status === 404) {
        msg = `Account email (${user_email}) not found`;
      } else {
        msg = 'Unexpected Error, please contact administrator.';
      }
      form.invalidate(msg);
    }
  };

  render() {
    const { form, $auth } = this.props;
    const { ready, error, recaptchaEnabled, recaptchaSiteKey, passwordReqComplete } = this.state;

    // waiting for open config call
    if (!ready) {
      return <Spinner />;
    }

    // open config call complete, but error
    if (ready && error) {
      return <span> Error loading page </span>;
    }

    // Message on completion of password reset request.
    if (passwordReqComplete) {
      return (
        <div className="pt-callout pt-intent-success">
          <h5>Success!</h5>
          An email containing instructions to complete password reset has been sent.{' '}
          <a href="/" target="_blank">
            Return to Kentik
          </a>
        </div>
      );
    }

    return (
      <div>
        <Field name="user_email" autoFocus labelStyle={labelStyle}>
          <Input className="pt-large" leftIconName="user" />
        </Field>
        {recaptchaEnabled && (
          <Box mb={2}>
            <Recaptcha
              sitekey={recaptchaSiteKey}
              callback={this.recaptchaCallback}
              expiredCallback={this.recaptchaExpire}
            />
          </Box>
        )}
        {form.error && (
          <Box className="pt-callout pt-intent-danger pt-icon-error" my={2}>
            <h5>Password Reset Request Failed</h5>
            {form.error}
          </Box>
        )}
        <Button
          className="pt-large"
          intent={Intent.PRIMARY}
          text="Request Password Reset"
          disabled={!form.dirty || !form.valid}
          loading={$auth.passwordReqInProgress}
          onClick={() => form.submit(this.handlePasswordSetRequestSubmit)}
        />
      </div>
    );
  }
}

export default PasswordSetRequestForm;
