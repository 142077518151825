import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import { Button, Intent } from '@blueprintjs/core';
import { KentikLogo, PoweredByKentik, Icon } from 'components';
import { Box, Flex } from 'components/flexbox';

import store from 'stores/store';
import LoginModel from './LoginModel';
import LoginForm from './LoginForm';

@inject('$auth')
@observer
class Login extends Component {
  state = { redirectToReferrer: false };

  /**
   * Creation of Forms with our existing components requires you to pass a model.
   * Login isn't a great use case for the Form API, but simple enough to understand
   * we're creating a simple empty model here so we conform with the rest of the
   * more complex forms in the system.
   */
  componentWillMount() {
    const { $auth, location, match } = this.props;
    this.emptyUser = new LoginModel();
    if ($auth.authenticated) {
      // Do this here to avoid page being rendered when user is already authenticated.
      this.state.redirectToReferrer = true;
    }

    this.state.ssoCompanyIdentifier = match.params.companyIdentifier || '';
    if (location.search.includes('autologin=true')) {
      this.state.ssoAutoLogin = true;
    }

    const appName = get($auth.openConfig, 'subtenancy.config.branding.appName') || $auth.companyFullName;
    document.title = `Login · ${appName}`;
  }

  handleSubmit = async (form, credentials) => {
    const { $auth, location } = this.props;
    const { state } = location;

    // Make call to authenticate user.
    const result = await $auth.authenticate(credentials, state);

    if (result.success) {
      // Don't initialize app if 2fa still required
      if (!$auth.twoFactorVerifyRequired) {
        await store.initializeApp();
      }
      if (!result.suppressRedirect) {
        this.setState({ redirectToReferrer: true });
      }
    } else if (result.isAcl) {
      form.invalidate('Login was blocked due to a configured ACL.');
    } else {
      form.invalidate('Your email and password combination were incorrect.');
    }
  };

  render() {
    const { $auth, location } = this.props;
    const {
      authenticating,
      authenticated,
      // currentRegion,
      openConfig: { subtenancy, subtenantSso = false }
    } = $auth;
    const showReferralOffer = false; // currentRegion && !subtenancy;
    const { from } = location.state || { from: { pathname: '/' } };
    const { redirectToReferrer, ssoAutoLogin } = this.state;
    let { ssoCompanyIdentifier } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    // if subtenant, ignore any sso identifier from URL use subtenancy parent company_name
    ssoCompanyIdentifier =
      subtenancy && subtenantSso && subtenancy.company.company_name
        ? subtenancy.company.company_name
        : ssoCompanyIdentifier;

    if (ssoCompanyIdentifier) {
      if (ssoAutoLogin) {
        window.location = `/sso/${ssoCompanyIdentifier}`;
        return null;
      }
    }

    let supportPageLink;
    if (subtenancy && subtenancy.config && subtenancy.config.supportURL) {
      const sanitizedUrl = new URL(subtenancy.config.supportURL);

      if (['http:', 'https:'].includes(sanitizedUrl.protocol)) {
        supportPageLink = (
          <div className="pt-text-muted">
            <a href={sanitizedUrl} target="_blank" rel="noopener noreferrer">
              Visit our support page.
            </a>
          </div>
        );
      }
    }

    return (
      <Flex flexAuto justify="center" wrap className="login">
        <Flex flexAuto justify="center" wrap className="login-pane" px={1.5} col={6} xs={12}>
          <Box sm={12} col={8} py={2} px={1.5} className="login-form">
            <div style={{ textAlign: 'center' }}>
              {subtenancy &&
                subtenancy.config &&
                subtenancy.config.icon && (
                  <img
                    alt={subtenancy.subdomain}
                    src={`/api/portal/icons/${subtenancy.company_id}`}
                    style={{ width: 180, height: 'auto' }}
                  />
                )}
              {(!subtenancy || !subtenancy.config || !subtenancy.config.icon) && (
                <a href={window.location.origin}>
                  <KentikLogo style={{ width: 180 }} />
                </a>
              )}
            </div>
            {!ssoCompanyIdentifier && (
              <Box className="pt-callout" p={2} mt={2}>
                <h5 style={{ fontSize: 18, textAlign: 'center' }}>
                  {ssoCompanyIdentifier && <span>Single Sign-On</span>}
                  {!ssoCompanyIdentifier && <span>Login</span>} to {!subtenancy && <span>Kentik</span>}
                  {subtenancy && (
                    <span>{get(subtenancy, 'config.branding.appName') || 'Network Visibility Portal'}</span>
                  )}
                </h5>
                <LoginForm
                  authenticating={authenticating}
                  authenticated={authenticated}
                  handleSubmit={this.handleSubmit}
                  model={this.emptyUser}
                />
              </Box>
            )}
            {ssoCompanyIdentifier && (
              <div>
                <Box mt={2}>
                  <Button
                    className="pt-large pt-fill"
                    intent={Intent.PRIMARY}
                    text="Login"
                    loading={authenticated || authenticating}
                    onClick={() => (window.location = `/sso/${ssoCompanyIdentifier}`)}
                  />
                </Box>
                {$auth.openConfig &&
                  $auth.openConfig.baseUrl && (
                    <Box mb={2} mt={2} className="pt-callout pt-icon-info-sign pt-intent-primary">
                      <h5>To skip this page next time...</h5>
                      Once successful Single Sign-on has been verified, this page can be skipped by appending &quot;
                      <strong>?autologin=true</strong>
                      &quot; to the end of this page's URL.
                    </Box>
                  )}
                {$auth.ssoErrorMsg && (
                  <Box className="pt-callout pt-intent-danger pt-icon-error" my={2}>
                    <h5>Login Failed</h5>
                    {$auth.ssoErrorMsg}
                  </Box>
                )}
              </div>
            )}
            {!subtenancy && (
              <Fragment>
                <Box className="pt-callout" mt={2} p={2}>
                  <h6>Trouble Logging In?</h6>
                  <span className="pt-text-muted">
                    To log in, you must register via the email we sent you.{' '}
                    <a href="mailto:support@kentik.com">Contact our support team.</a>
                  </span>
                </Box>
                <div style={{ marginTop: 8, textAlign: 'center' }}>
                  <span style={{ fontWeight: 500, marginRight: 10 }}>New User?</span>
                  <a href="https://www.kentik.com/go/get-started/" className="link">
                    Sign up for free!
                  </a>
                </div>
              </Fragment>
            )}

            {subtenancy && (
              <Fragment>
                <Box className="pt-callout" mt={2} p={2}>
                  <h6>Trouble Logging In?</h6>

                  {supportPageLink}

                  <div className="pt-text-muted">
                    <a href={`mailto:${subtenancy.config.supportEmail || 'support@kentik.com'}`}>
                      Contact our support team.
                    </a>
                  </div>
                </Box>

                {!subtenancy.permissions.advancedMode && (
                  <div style={{ marginTop: 8, textAlign: 'center' }}>
                    <PoweredByKentik blackText />
                  </div>
                )}
              </Fragment>
            )}
          </Box>
        </Flex>
        {showReferralOffer && (
          <Flex justify="center" className="referral-pane" pb={4} col={6} xs={12}>
            <Box col={11} xs={12} px={3}>
              <h2 style={{ fontWeight: 800 }}>
                Join One of Our Upcoming <br />
                Virtual Design Clinics!
              </h2>
              <p style={{ fontSize: 20 }}>
                <Flex align="top" mb={1}>
                  <Icon
                    name="tick-circle"
                    intent={Intent.PRIMARY}
                    style={{ fontSize: 20, paddingTop: 3, marginRight: 8 }}
                  />
                  See Kentik's top features in action
                </Flex>
                <Flex align="top" mb={1}>
                  <Icon
                    name="tick-circle"
                    intent={Intent.PRIMARY}
                    style={{ fontSize: 20, paddingTop: 3, marginRight: 8 }}
                  />
                  <div>
                    Follow along in your own Kentik account <br />
                    (No account yet? <a href="https://www.kentik.com/go/get-started/">No problem.</a>)
                  </div>
                </Flex>
                <Flex align="top" mb={1}>
                  <Icon
                    name="tick-circle"
                    intent={Intent.PRIMARY}
                    style={{ fontSize: 20, paddingTop: 3, marginRight: 8 }}
                  />
                  Gain network observability via our preloaded data,
                  <br /> or upload your own
                </Flex>
                <Flex align="top">
                  <Icon
                    name="tick-circle"
                    intent={Intent.PRIMARY}
                    style={{ fontSize: 20, paddingTop: 3, marginRight: 8 }}
                  />
                  Get your questions answered live
                  <br /> by a Kentik product expert
                </Flex>
              </p>
              <a href="https://www.kentik.com/go/virtual-design-clinic/" className="link">
                Register for the Next Clinic
              </a>
            </Box>
          </Flex>
        )}
      </Flex>
    );
  }
}

export default withRouter(Login);
