import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@blueprintjs/core';

import { whitelistFieldProps } from 'forms/utils';

export default observer(props => {
  const { field, label, onChange } = props;

  const handleChange = e => {
    onChange(e.target.checked);
  };

  return (
    <Checkbox
      {...field.getProps()}
      {...whitelistFieldProps(props)}
      onChange={handleChange}
      checked={field.value}
      label={label || field.label}
    />
  );
});
