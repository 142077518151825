import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';

@inject('$sudo')
@observer
class SudoEjectButtonDialog extends Component {
  render() {
    const { $sudo } = this.props;

    return (
      <Dialog
        isOpen={$sudo.showSudoEjectButton}
        title="Warning!!!"
        canEscapeKeyClose={false}
        canClickOutside={false}
        isCloseButtonShown={false}
        transitionName="pt-dialog"
      >
        <div className="pt-dialog-body" style={{ minHeight: 0 }}>
          <p className="pt-running-text">
            You are now spoofed as a different user in this session. You must reload the page to continue using this
            tab.
          </p>
        </div>
        <Flex className="pt-dialog-footer" justify="flex-end" mt={-1}>
          <Button intent={Intent.PRIMARY} onClick={() => window.location.reload()} text="Reload" />
        </Flex>
      </Dialog>
    );
  }
}

export default SudoEjectButtonDialog;
