import React, { Component, Fragment } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/labs';
import classNames from 'classnames';

class ModalSelectTitle extends Component {
  state = {
    isOpen: false
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { title, helpContents } = this.props;

    return (
      <Fragment>
        {title}
        {helpContents && (
          <Popover2
            placement="bottom-start"
            rootElement="div"
            popoverClassName="pt-minimal"
            content={helpContents}
            onClose={this.onClose}
          >
            <Button
              iconName="help"
              className={classNames('pt-minimal', { 'pt-active': this.state.isOpen })}
              intent={Intent.PRIMARY}
              style={{ marginLeft: 4 }}
              onClick={this.onToggle}
            />
          </Popover2>
        )}
      </Fragment>
    );
  }
}

export default ModalSelectTitle;
