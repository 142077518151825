import React from 'react';

import { IPV6_REGEX } from 'forms/validators/regex';
import BaseModel from '../BaseModel';

const matchMapper = match => `${match.operator}${match.value}`;

const getFlowSpecMatchString = field => [
  [...field.matches.map(matchMapper), ...(field.groups || []).map(group => group.range.map(matchMapper).join('&'))]
    .filter(Boolean)
    .join(' ')
];

function getDefaultFlowSpecMatches() {
  return {
    matches: [
      {
        operator: '',
        value: ''
      }
    ]
  };
}
class MitigationMethod extends BaseModel {
  get defaults() {
    return {
      method_mitigation_scope_type: 'COMPANY',
      method_mitigation_device_detail: {
        baselineIcmpBytesPerSecond: 0,
        baselineIcmpPacketsPerSecond: 0,
        baselineTcpBytesPerSecond: 0,
        baselineTcpPacketsPerSecond: 0,
        baselineUdpBytesPerSecond: 0,
        baselineUdpPacketsPerSecond: 0,
        flowSpec: {
          protocol: getDefaultFlowSpecMatches(),
          src_port: getDefaultFlowSpecMatches(),
          dst_port: getDefaultFlowSpecMatches(),
          icmp_type: getDefaultFlowSpecMatches(),
          icmp_code: getDefaultFlowSpecMatches(),
          tcp_flags: getDefaultFlowSpecMatches(),
          packet_length: getDefaultFlowSpecMatches(),
          dscp: getDefaultFlowSpecMatches(),
          fragment: getDefaultFlowSpecMatches()
        }
      },
      notificationChannels: []
    };
  }

  get removalConfirmText() {
    return {
      text: (
        <p>
          Are you sure you want to remove <strong>{this.get('method_name')}</strong>? This action is not reversible.
        </p>
      )
    };
  }

  getFlowSpecMatchTemplate(flowSpecFormData) {
    const flowSpecMatchTemplate = {};
    const {
      protocol,
      src_ip,
      dst_ip,
      src_port,
      dst_port,
      icmp_type,
      icmp_code,
      tcp_flags,
      packet_length,
      dscp,
      fragment
    } = flowSpecFormData;

    /* eslint-disable no-template-curly-in-string */
    if (src_ip.enabled) {
      flowSpecMatchTemplate.source = src_ip.infer ? '${mit.ipCidr}' : src_ip.value;
    }
    if (dst_ip.enabled) {
      flowSpecMatchTemplate.destination = dst_ip.infer ? '${mit.ipCidr}' : dst_ip.value;
    }

    if (protocol.enabled) {
      flowSpecMatchTemplate.protocol = protocol.infer ? ['=${mit.proto}'] : getFlowSpecMatchString(protocol);
    }
    if (src_port.enabled) {
      flowSpecMatchTemplate['source-port'] = src_port.infer ? ['=${mit.srcPort}'] : getFlowSpecMatchString(src_port);
    }
    if (dst_port.enabled) {
      flowSpecMatchTemplate['destination-port'] = dst_port.infer
        ? ['=${mit.dstPort}']
        : getFlowSpecMatchString(dst_port);
    }
    if (icmp_type.enabled) {
      flowSpecMatchTemplate['icmp-type'] = getFlowSpecMatchString(icmp_type);
    }
    if (icmp_code.enabled) {
      flowSpecMatchTemplate['icmp-code'] = getFlowSpecMatchString(icmp_code);
    }
    if (tcp_flags.enabled) {
      flowSpecMatchTemplate['tcp-flags'] = getFlowSpecMatchString(tcp_flags);
    }
    if (packet_length.enabled) {
      flowSpecMatchTemplate['packet-length'] = getFlowSpecMatchString(packet_length);
    }
    if (dscp.enabled) {
      flowSpecMatchTemplate.dscp = getFlowSpecMatchString(dscp);
    }
    if (fragment.enabled) {
      flowSpecMatchTemplate.fragment = getFlowSpecMatchString(fragment);
    }
    /* eslint-enable */
    return flowSpecMatchTemplate;
  }

  getFlowSpecActions(flowSpecFormData) {
    const extendedCommunity = [];
    const flowSpecActions = { 'extended-community': extendedCommunity };

    const { trafficAction, rateLimitBytes, mark, routeTarget, nexthop, sample, terminal } = flowSpecFormData;

    if (trafficAction === 'rate-limit') {
      extendedCommunity.push({
        string: `rate-limit:${rateLimitBytes}`
      });
    } else if (trafficAction === 'discard') {
      extendedCommunity.push({
        string: 'rate-limit:0'
      });
    } else if (trafficAction === 'mark') {
      extendedCommunity.push({
        string: `mark ${mark}`
      });
    } else if (trafficAction === 'route-target') {
      extendedCommunity.push({
        string: `redirect:${routeTarget}`
      });
    } else if (trafficAction === 'redirect-to-nexthop') {
      extendedCommunity.push({
        string: 'redirect-to-nexthop'
      });
      const isIPV6 = IPV6_REGEX.test(nexthop);
      flowSpecActions.announce = {};
      const nextHopObj = { protocol: ['=tcp'], 'next-hop': nexthop, string: 'flow protocol =tcp' };
      const announceFlow = {};
      announceFlow[nexthop] = nextHopObj;
      flowSpecActions.announce[isIPV6 ? 'ipv6 flow' : 'ipv4 flow'] = announceFlow;
    }
    if (sample && terminal) {
      extendedCommunity.push({
        string: 'action sample-terminal'
      });
    } else if (sample) {
      extendedCommunity.push({
        string: 'action sample'
      });
    } else if (terminal) {
      extendedCommunity.push({
        string: 'action terminal'
      });
    }
    return flowSpecActions;
  }

  serialize(data) {
    const notificationChannels = data.notificationChannels.map(id => ({ id: parseInt(id) }));
    const { method_mitigation_device_type, method_mitigation_device_detail } = data;
    if (method_mitigation_device_type === 'Flowspec') {
      method_mitigation_device_detail.flowspecMatchTemplate = this.getFlowSpecMatchTemplate(
        method_mitigation_device_detail.flowSpec
      );
      method_mitigation_device_detail.flowspecActions = this.getFlowSpecActions(
        method_mitigation_device_detail.flowSpec
      );
    }
    return super.serialize({ ...data, notificationChannels });
  }
}

export default MitigationMethod;
