import React from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

import { Box } from 'components/flexbox';

import Input from './Input';
import Field from './Field';

const InputMultiple = (props, context) => {
  const { field, children, showAddButton, showRemoveButtons } = props;
  const { form } = context;
  const { name, addButtonText = 'Add Value', showLabel = false, inputStyle } = props;

  const fieldArrayField = field || form.getField(name);
  const addButton = <Button iconName="plus" onClick={() => fieldArrayField.add(undefined)} text={addButtonText} />;
  if (typeof children === 'function') {
    return children({
      field: fieldArrayField,
      value: fieldArrayField.getValue(),
      addButton,
      ...props
    });
  }

  return (
    <Box>
      {fieldArrayField.map(f => (
        <Field key={f._id} field={f} showLabel={showLabel} inputStyle={inputStyle}>
          <Input
            autoComplete="off"
            rightElement={
              showRemoveButtons ? (
                <Button className="pt-minimal" intent={Intent.DANGER} onClick={() => f.remove()} iconName="cross" />
              ) : null
            }
          />
        </Field>
      ))}
      {showAddButton && addButton}
    </Box>
  );
};

InputMultiple.defaultProps = {
  showAddButton: true,
  showRemoveButtons: true
};

InputMultiple.contextTypes = {
  form: any
};

export default observer(InputMultiple);
