import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import sizeMe from 'react-sizeme';

import { Flex } from 'components/flexbox';
import getErrorBoundary from 'util/components/getErrorBoundary';

import DataViewHeader from './DataViewHeader';
import DataViewFooter from './DataViewFooter';
import ConfigureDataViewDialog from './ConfigureDataViewDialog';

const sizeMeHOC = sizeMe({
  monitorHeight: true,
  monitorWidth: true
});

const ErrorBoundaryCmp = getErrorBoundary('DataViewWrapper');

@inject('$explorer', '$dataviews', '$auth')
@observer
class DataViewWrapper extends Component {
  static defaultProps = {
    className: 'dataview-wrapper',
    dataview: null,
    isExport: false,
    viewProps: {
      showNativeLegend: false,
      showLegendOptions: true
    },
    headerProps: {
      showTitleLink: true,
      shouldArrangeVertically: false
    },
    footerProps: {
      showLastUpdated: true,
      showLiveUpdate: false,
      showSiteMarkers: false
    }
  };

  render() {
    const {
      $auth,
      $dataviews,
      dataview,
      className,
      sourceLink,
      viewProps,
      headerProps,
      footerProps,
      size
    } = this.props;

    if (!$dataviews.hasViewModel(dataview.viewType)) {
      return (
        <Flex className="dataview-wrapper" align="center" justify="center" p={3}>
          <p className="pt-text-muted">
            Visualization type <strong>{dataview.viewType}</strong> is not available at this time.
          </p>
        </Flex>
      );
    }

    const { viewType } = dataview;
    const dataViewConfig = $dataviews.getConfig(viewType);
    let secondaryDataviewConfig = dataViewConfig;
    if (dataview.queryBuckets && dataview.queryBuckets.selectedQuery) {
      secondaryDataviewConfig = $dataviews.getConfig(dataview.queryBuckets.selectedQuery.get('viz_type'));
    }

    const ViewComponent = $dataviews.getComponent(viewType);

    const viewHeader = <DataViewHeader {...this.props} {...headerProps} />;
    const viewFooter = (
      <DataViewFooter
        {...this.props}
        {...footerProps}
        showSyncAxes={secondaryDataviewConfig.allowsSyncAxes}
        showUseLogAxis={secondaryDataviewConfig.supportsLogAxis}
        showSyncExtents={secondaryDataviewConfig.supportsSyncExtents}
      />
    );
    const viewComponent = (
      <ViewComponent
        {...this.props}
        viewProps={{
          colors: $auth.userSettings.visualizations,
          ...this.props.viewProps
        }}
        lastUpdated={dataview.lastUpdated}
        isViewCmp
        showNativeLegend={viewProps.showNativeLegend}
        {...viewProps}
      />
    );

    /**
     * Dataviews can contain their own configurations now, and we'll ship a Dialog that opens
     * those options. Allows the consumer to optionally render it if they want it.
     */

    const ConfigureDialog = dataview.hasConfigurationOptions ? ConfigureDataViewDialog : null;

    return (
      <ErrorBoundaryCmp>
        {this.props.children({
          className,
          dataview,
          sourceLink,
          loading: dataview.loading,
          component: viewComponent,
          footer: viewFooter,
          header: viewHeader,
          dataViewConfig,
          ConfigureDialog,
          size
        })}
      </ErrorBoundaryCmp>
    );
  }
}

export default sizeMeHOC(props => <DataViewWrapper {...props} />);
