import { showErrorToast, showSuccessToast } from 'components/Toast';
import api from 'util/api';

import PolicyCollection from './PolicyCollection';

class PolicyLibraryCollection extends PolicyCollection {
  get url() {
    return '/api/portal/alert-policies/templates';
  }

  copyToMyPolicies(model) {
    return api
      .post(`/api/portal/alert-policies/${model.id}/copy`, { data: { isCopyFromTemplate: true } })
      .then(
        () => showSuccessToast(`Policy '${model.get('policy_name')}' was successfully copied to your Policies`),
        () => showErrorToast(`Policy '${model.get('policy_name')}' failed to copy to your Policies`)
      );
  }
}

export default PolicyLibraryCollection;
