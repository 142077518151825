function minIfValidation(val) {
  const [name, match, min] = this.getParameters();

  if (this.validator.input[name] === match) {
    return this.validator.getRule('min').validate(val, min);
  }

  return true;
}

export default {
  message: ':attribute is too short',
  fn: minIfValidation
};
