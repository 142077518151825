import React from 'react';
import { MenuDivider, MenuItem } from '@blueprintjs/core';
import { MaterialIcon } from 'components/Icon';
import $app from 'stores/$app';
import $search from 'stores/$search';

const isModifiedEvent = e => !!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);

export const renderMenuLink = (link, history) => {
  if (!history) {
    throw new Error('Must pass a `history` instance to renderMenuLink');
  }

  if (link.display === false) {
    return null;
  }

  if (link.divider) {
    return <MenuDivider key={`divider${link.title}`} title={link.title} />;
  }

  // Make links accessible, but override behavior to use React Router
  const handleClick = e => {
    if (link.onClick) {
      link.onClick(e);
    }

    if (!e.defaultPrevented && e.button === 0 && !isModifiedEvent(e)) {
      e.preventDefault();

      history.push(link.to);
      $search.closeSearch();
      $app.closeHelp();
    }
  };

  let text = link.text;
  if (link.materialIcon) {
    text = (
      <span className="menu-text">
        <MaterialIcon name={link.iconName} /> {link.text}
      </span>
    );
  }

  return (
    <MenuItem
      key={link.key || link.text}
      disabled={link.disabled}
      intent={link.intent}
      href={link.to}
      onClick={handleClick}
      iconName={!link.materialIcon ? link.iconName : ''}
      text={text}
    />
  );
};
