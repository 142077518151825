import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Popover, Position, Intent, Menu, MenuDivider, MenuItem, Button } from '@blueprintjs/core';
import { isDev } from 'util/utils';

const tetherOptions = {
  constraints: [
    {
      to: 'scrollParent',
      attachment: 'together',
      pin: true
    }
  ]
};

const popoverProps = {
  tetherOptions,
  popoverClassName: 'pt-minimal',
  position: Position.BOTTOM_RIGHT
};

@inject('$auth')
@observer
export default class LiveUpdateButton extends Component {
  renderLiveUpdateMenu(dataview) {
    const { $auth } = this.props;
    return (
      <Menu>
        <MenuDivider title="Update every..." />
        {($auth.hasSudo || $auth.isSpoofed || isDev) && (
          <MenuItem text="5 Seconds" onClick={() => dataview.setUpdateFrequency(5)} />
        )}
        {($auth.hasSudo || $auth.isSpoofed || isDev) && (
          <MenuItem text="10 Seconds" onClick={() => dataview.setUpdateFrequency(10)} />
        )}
        <MenuItem text="60 Seconds" onClick={() => dataview.setUpdateFrequency(60)} />
        <MenuItem text="90 Seconds" onClick={() => dataview.setUpdateFrequency(90)} />
        <MenuItem text="120 Seconds" onClick={() => dataview.setUpdateFrequency(120)} />
      </Menu>
    );
  }

  render() {
    const { dataview, loading } = this.props;

    if (dataview.hasUpdateFrequency) {
      return (
        <Button
          className="pt-small"
          iconName="stop"
          intent={Intent.DANGER}
          onClick={() => dataview.setUpdateFrequency()}
          style={{ marginLeft: 8 }}
        >
          Stop Updating
        </Button>
      );
    }

    return (
      <Popover content={this.renderLiveUpdateMenu(dataview)} {...popoverProps}>
        <Button
          className="pt-small"
          disabled={loading}
          iconName="automatic-updates"
          rightIconName="caret-down"
          style={{ marginLeft: 8 }}
          text="Live Update"
        />
      </Popover>
    );
  }
}
