import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import FavoritesList from './FavoritesList';
import RecentlyViewedList from './RecentlyViewedList';
import ReportSelector from './ReportSelector';

@inject('$recentlyViewed')
@observer
class SubtenantLibrarySidebar extends Component {
  render() {
    const { $recentlyViewed, reports } = this.props;

    const hasFavoritesOrRecents = reports.favoriteReports.length > 0 || $recentlyViewed.reports.length > 0;

    return (
      <Fragment>
        <FavoritesList {...this.props} />

        <RecentlyViewedList {...this.props} />

        {hasFavoritesOrRecents && <hr className="sidebar-separator" />}

        <ReportSelector {...this.props} reportType="subtenant" />
      </Fragment>
    );
  }
}

export default withRouter(SubtenantLibrarySidebar);
