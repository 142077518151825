import Clouds from './Clouds';
import CustomDimensions from './CustomDimensions';
import Devices from './Devices';
import Documentation from './Documentation';
import FlowTags from './FlowTags';
import Interfaces from './Interfaces';
import MitigationMethods from './MitigationMethods';
import MitigationPlatforms from './MitigationPlatforms';
import NotificationChannels from './NotificationChannels';
import PeeringAnalytics from './PeeringAnalytics';
import Plans from './Plans';
import Policies from './Policies';
import Reports from './Reports';
import SavedFilters from './SavedFilters';
import SilentMode from './SilentMode';
import Sites from './Sites';
import Subscriptions from './Subscriptions';
import Users from './Users';

export default {
  alertPolicies: Policies,
  clouds: Clouds,
  customDimensions: CustomDimensions,
  devices: Devices,
  docs: Documentation,
  flowTags: FlowTags,
  interfaces: Interfaces,
  mitigationMethods: MitigationMethods,
  mitigationPlatforms: MitigationPlatforms,
  notificationChannels: NotificationChannels,
  peeringAnalytics: PeeringAnalytics,
  plans: Plans,
  reports: Reports,
  savedFilters: SavedFilters,
  silentMode: SilentMode,
  sites: Sites,
  subscriptions: Subscriptions,
  users: Users
};
