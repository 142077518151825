import React, { Component } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { Box } from 'components/flexbox';

import FilterGroupDisplay from './FilterGroupDisplay';
import FilterGroupConnector from './FilterGroupConnector';

@inject('$dictionary')
@observer
class FilterOptionsDisplay extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    readOnly: false,
    showEmptyText: true,
    showButtonText: true,
    fieldName: 'filters'
  };

  render() {
    const { form } = this.context;
    const { readOnly, fieldName } = this.props;

    const filterGroups = form.getField(`${fieldName}.filterGroups`);
    const filterGroupsValue = form.getValue(`${fieldName}.filterGroups`);
    const groupConnector = form.getField(`${fieldName}.connector`);

    const className = classNames('filters', { 'read-only': readOnly });
    const filterGroupClassName = classNames('filter-groups', {
      'filter-groups-readonly': readOnly
    });

    const numFilterGroups = filterGroupsValue && filterGroupsValue.length;

    return (
      <Box {...this.props} className={className}>
        {numFilterGroups > 0 && (
          <Box className={filterGroupClassName}>
            {numFilterGroups > 1 && (
              <Box mb={1}>
                <FilterGroupConnector connector={groupConnector} readOnly />
              </Box>
            )}

            {filterGroups.map((group, index) => (
              <FilterGroupDisplay
                {...this.props}
                key={group.connector._id}
                form={form}
                group={group}
                index={index}
                groups={filterGroups}
              />
            ))}
          </Box>
        )}
        {numFilterGroups === 0 && <div className="pt-text-muted">No filters selected</div>}
      </Box>
    );
  }
}

export default FilterOptionsDisplay;
