import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { AnchorButton, Intent, Tooltip, Position } from '@blueprintjs/core';
import classNames from 'classnames';

import { Icon } from 'components/index';

@observer
class SubmitButton extends Component {
  static defaultProps = {
    text: 'Save'
  };

  getTooltipProps() {
    const { valid, errors } = this.props.form;

    let content;
    if (!valid) {
      content = (
        <ul className="pt-list pt-list-unstyled">
          {errors.map(error => (
            <li key={error}>
              <Icon name="dot" /> {error}
            </li>
          ))}
        </ul>
      );
    }

    return {
      content,
      intent: Intent.DANGER,
      position: Position.LEFT
    };
  }

  render() {
    const { className, model, form, onSubmit, text, disabled } = this.props;

    const { valid } = form;
    const isCreating = model && model.isRequestActive('creating');
    const isSaving = model && model.isRequestActive('updating');

    const button = (
      <AnchorButton
        className={classNames('pt-medium', className)}
        disabled={disabled || isSaving || isCreating || !valid}
        intent={Intent.PRIMARY}
        loading={isSaving || isCreating}
        onClick={() => form.submit(onSubmit)}
        style={{ minWidth: 110 }}
        text={text}
      />
    );

    const tooltipProps = this.getTooltipProps();

    if (tooltipProps.content) {
      return <Tooltip {...tooltipProps}>{button}</Tooltip>;
    }

    return button;
  }
}

export default SubmitButton;
