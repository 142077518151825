import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Collapse, Icon } from '@blueprintjs/core';

import { Box, Flex } from 'components/flexbox';

@observer
export default class Documentation extends Component {
  state = {
    open: {}
  };

  componentWillMount() {
    const { collection } = this.props;
    const open = {};

    collection.forEach((doc, index) => {
      open[doc.anchor] = index === 0;
    });

    this.setState({ open });
  }

  handleCollapseButton = category => {
    this.setState({ open: { [category]: !this.state.open[category] } });
  };

  render() {
    const { collection } = this.props;
    const { open } = this.state;

    /* eslint-disable react/no-danger */
    const sections = collection.map(section => (
      <Box className="search-docs" key={section.url}>
        <Flex className="pt-callout" justify="space-between">
          <Flex align="center">
            <Button
              className="pt-button"
              iconName={open[section.anchor] ? 'caret-down' : 'caret-right'}
              onClick={() => this.handleCollapseButton(section.anchor)}
              style={{ marginRight: 8 }}
            />
            <h5>{section.title}</h5>
          </Flex>
          <a
            href={section.url}
            target="_blank"
            rel="noopener noreferrer"
            className="pt-button pt-minimal pt-intent-primary"
          >
            View Full Documentation
            <Icon
              iconName="share"
              style={{ fontSize: 12, position: 'relative', top: -2, opacity: 0.8, marginLeft: 6, marginRight: 0 }}
            />
          </a>
        </Flex>
        <Collapse isOpen={open[section.anchor]}>
          <Box p={2} pb={1}>
            <div dangerouslySetInnerHTML={{ __html: section.content }} />
            <a href={section.url} target="_blank" rel="noopener noreferrer" className="pt-button">
              View Full Documentation
              <Icon
                iconName="share"
                style={{ fontSize: 12, position: 'relative', top: -2, marginLeft: 6, marginRight: 0 }}
                className="pt-text-muted"
              />
            </a>
          </Box>
        </Collapse>
      </Box>
    ));

    return <Box p={2}>{sections}</Box>;
  }
}
