import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { InputGroup } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { Card } from 'components';
import { Page, PageHeader, PageBody } from 'components/page';

import Label from './ProfileLabel';

const breadcrumbLinks = [
  {
    text: 'My Profile',
    href: '/profile'
  }
];

const hardcodedData = {
  host: 'db.kentik.com',
  port: '10376'
};

@inject('$users')
@observer
class KdeSettings extends Component {
  render() {
    const { fullUserProfile } = this.props.$users;

    return (
      <Page constrainWidth title="Profile">
        <PageHeader
          loading={!fullUserProfile}
          parents={breadcrumbLinks}
          title="Kentik Data Engine (KDE)"
          helpTopic="User KDE Information"
        />
        <PageBody scrollable bodyStyle={{ maxWidth: '800px' }}>
          <Card>
            <Flex pb={2} flexColumn>
              <Label>KDE host:port</Label>
              <InputGroup
                leftIconName="database"
                className="pt-monospace-text"
                readOnly
                value={`${hardcodedData.host}:${hardcodedData.port}`}
              />
            </Flex>
            <Flex pb={2} flexColumn>
              <Label>KDE user</Label>
              <InputGroup
                leftIconName="user"
                className="pt-monospace-text"
                readOnly
                value={(fullUserProfile && fullUserProfile.hydra_user_name) || ''}
              />
            </Flex>
            <Flex pb={2} flexColumn>
              <Label>KDE password</Label>
              <InputGroup
                leftIconName="lock"
                className="pt-monospace-text"
                readOnly
                value={(fullUserProfile && fullUserProfile.hydra_password) || ''}
              />
            </Flex>
            <Flex flexColumn>
              <Label>PSQL command line</Label>
              <textarea
                readOnly
                style={{ resize: 'none' }}
                className="pt-input pt-fill pt-monospace-text"
                rows={4}
                value={
                  fullUserProfile
                    ? `psql -h ${hardcodedData.host} -p ${hardcodedData.port} -U ${
                        fullUserProfile.hydra_user_name
                      } -W ${fullUserProfile.hydra_database}`
                    : ''
                }
              />
            </Flex>
          </Card>
        </PageBody>
      </Page>
    );
  }
}

export default KdeSettings;
