import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import Icon, { MaterialIcon } from 'components/Icon';
import { Box, Flex } from 'components/flexbox';
import RemoveButton from 'components/forms/actions/RemoveButton';

@inject('$dashboard', '$app')
@observer
class DashboardAddPanel extends Component {
  render() {
    const { $dashboard, $app, onAddItem, onDashboardRemove } = this.props;
    const { dashboard } = $dashboard;
    const { darkThemeEnabled } = $app;

    const addBtnCls = classNames({
      'pt-minimal': darkThemeEnabled,
      'pt-intent-success': darkThemeEnabled
    });

    return (
      <Flex py={2} align="flex-start" justify="space-between" className="dashboard-add-panel">
        <Box>
          <h5 className="pt-normal pt-text-muted" style={{ marginBottom: 16 }}>
            <Icon name="add" style={{ position: 'relative', top: -1 }} /> Add A Dashboard Panel
          </h5>
          <Flex>
            <Flex className="pt-callout add-panel-callout" align="flex-start" justify="space-between">
              <Flex align="center">
                <MaterialIcon name="insert_chart" style={{ fontSize: 36 }} />
                <span className="pt-medium">Data Explorer View</span>
              </Flex>
              <Button className={addBtnCls} onClick={() => onAddItem()} style={{ alignSelf: 'flex-end' }} text="Add" />
            </Flex>
            <Flex className="pt-callout add-panel-callout" align="flex-start" justify="space-between">
              <Flex align="center">
                <MaterialIcon name="notifications" style={{ fontSize: 36 }} />
                <span className="pt-medium">Alert Scoreboard</span>
              </Flex>
              <Button
                className={addBtnCls}
                onClick={() => onAddItem('alert_scoreboard')}
                style={{ alignSelf: 'flex-end' }}
                text="Add"
              />
            </Flex>
            <Flex className="pt-callout add-panel-callout" align="flex-start" justify="space-between">
              <Flex align="center">
                <Icon name="search-template" style={{ fontSize: 30 }} />
                <span className="pt-medium">&nbsp;Raw Flow View</span>
              </Flex>
              <Button
                className={addBtnCls}
                onClick={() => onAddItem('raw_flow')}
                style={{ alignSelf: 'flex-end' }}
                text="Add"
              />
            </Flex>
          </Flex>
        </Box>
        <Flex flexColumn align="center">
          <Button className="pt-button pt-minimal" onClick={() => $dashboard.setEditingProperties(true)}>
            <Icon name="cog" style={{ paddingTop: 8, fontSize: 36, marginRight: 0, marginBottom: 8 }} />
            <div style={{ lineHeight: '14px', paddingBottom: 8, fontSize: 12 }}>
              Dashboard <br /> Properties
            </div>
          </Button>
          <RemoveButton className="pt-minimal" onRemove={onDashboardRemove} model={dashboard} text="Delete" showIcon />
        </Flex>
      </Flex>
    );
  }
}

export default DashboardAddPanel;
