import { observer } from 'mobx-react';
import { getToFixed, zeroToText, adjustByGreekPrefix, escapeHtml } from 'util/utils';
import $dictionary from 'stores/$dictionary';
import $app from 'stores/$app';
import BaseHighchartsDataview from './BaseHighchartsDataview';

@observer
export default class BarView extends BaseHighchartsDataview {
  chartOptions = {
    chart: {
      zoomType: 'x'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          color: this.chartLabelColor,
          'max-width': '100px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        useHTML: true,
        format: '<span title="{value}">{value}</span>'
      }
    },
    yAxis: {
      labels: { style: { color: this.chartLabelColor } },
      title: {
        text: '',
        style: { color: this.chartLabelColor }
      },
      gridLineWidth: 1,
      plotLines: []
    },
    plotOptions: {
      bar: {},
      series: {
        cursor: 'pointer',
        stacking: 'normal',
        marker: {
          enabled: false
        },
        groupPadding: 0,
        pointPadding: 0.05
      }
    },
    legend: { enabled: false },
    series: [
      {
        type: 'bar',
        name: '',
        data: []
      }
    ]
  };

  buildSeriesInternal(bucket, models) {
    this.clear();

    if (!this.chart) {
      return;
    }

    const { name } = bucket.get();
    const seriesIndex = name.startsWith('Negative') ? 1 : 0;
    const { outsort } = bucket.firstQuery.get();
    const { aggregates, outsortUnit, prefixUnit, outsortDataKey } = bucket.firstQuery;
    const bracketOptions = bucket.firstQuery.get('bracketOptions');
    const { dictionary } = $dictionary;
    const axisText = dictionary.units[outsortUnit];
    let suffix = axisText;
    if (outsortUnit.includes('sample_rate')) {
      suffix = ` : 1 ${suffix}`;
    }

    if (!this.chart.series[seriesIndex]) {
      this.chart.xAxis[0].update(
        {
          opposite: true
        },
        false
      );
      this.chart.addAxis(
        {
          type: 'category',
          categories: [],
          labels: {
            style: {
              'max-width': '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            },
            useHTML: true,
            format: '<span title="{value}">{value}</span>'
          }
        },
        true,
        false
      );
      this.chart.yAxis[0].setTitle({ text: `${axisText}, ${this.chart.yAxis[0].options.title.text}` }, false);
      this.chart.addSeries({
        type: 'bar',
        name: outsort,
        data: [],
        xAxis: 1,
        suffix
      });
    }

    models.forEach(model => {
      const { lookup, key, isOverlay } = model.get();
      let data = model.get(outsortDataKey);

      if (outsortUnit.includes('sample_rate') && aggregates.find(agg => agg.name === outsort).fn !== 'percentile') {
        data /= 100;
      }

      if (isOverlay) {
        if (!this.chart.yAxis[seriesIndex].plotLinesAndBands.find(pl => pl.id === key)) {
          const { prefix } = bucket.queryResults;
          const label = `${prefix[prefixUnit]}${suffix}`;

          this.chart.yAxis[seriesIndex].addPlotLine({
            color: key === 'Total' ? this.primaryOverlayColor : this.overlayColor,
            dashStyle: key === 'Total' ? 'Solid' : 'Dash',
            id: key,
            label: {
              text: `${key}: ${escapeHtml(zeroToText(adjustByGreekPrefix(data, prefix[prefixUnit])))} ${label}</b>`,
              style: { color: this.chartLabelColor }
            },
            value: data,
            width: 3,
            zIndex: 5
          });
        }
      } else {
        const point = {
          name: lookup || key,
          y: data,
          model
        };
        if (bracketOptions) {
          const tagData = model.get(bracketOptions.tagKey);
          Object.assign(point, { metadata: { tagData }, color: tagData && tagData.value ? tagData.value : null });
        }
        this.chart.series[seriesIndex].addPoint(point, false);
      }
      if (!this.chart.yAxis[0].max || this.chart.yAxis[0].max < data) {
        this.chart.yAxis[0].update({ max: data * 1.05 });
      }
    });

    $app.renderSync(() => {
      if (this.chart) {
        this.chart.redraw();
      }
    });
    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  redraw({ setColors = false } = {}) {
    if (this.chart) {
      if (setColors) {
        const axisUpdate = {
          labels: { style: { color: this.chartLabelColor } },
          title: { style: { color: this.chartLabelColor } }
        };
        this.chart.update({ colors: this.chartColors }, false);
        this.chart.xAxis.forEach(axis => axis.update(axisUpdate, false));
        this.chart.yAxis.forEach(axis => {
          axis.plotLinesAndBands.forEach(plotLine => {
            plotLine.options.label.style = { color: this.chartLabelColor };
          });
          axis.update(axisUpdate, false);
        });
      }
      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
        }
      });
    }
  }

  clear() {
    if (this.chart) {
      this.chart.series[0].setData([], false);
      if (this.chart.series[1]) {
        this.chart.series[1].setData([], false);
      }
      this.chart.yAxis.forEach(axis => {
        axis.plotLinesAndBands.forEach(plotLine => plotLine.axis.removePlotBandOrLine(plotLine.id), false);
      });

      this.chart.redraw();
    }
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;
    const { outsort } = query.get();
    const { outsortUnit } = query;
    const { dictionary } = $dictionary;

    const axisText = dictionary.units[outsortUnit];
    let suffix = axisText;
    if (outsortUnit.includes('sample_rate')) {
      suffix = ` : 1 ${suffix}`;
    }
    chartOptions.yAxis.title.text = axisText;
    chartOptions.series[0].name = outsort;
    chartOptions.series[0].suffix = suffix;
    chartOptions.colors = this.chartColors;

    chartOptions.tooltip = {
      formatter() {
        const { prefix } = activeBuckets[0].queryResults;

        const val = escapeHtml(
          zeroToText(adjustByGreekPrefix(this.y, prefix[query.prefixUnit]), { fix: getToFixed(outsortUnit) })
        );
        const label = `${prefix[query.prefixUnit] || ''}${suffix}`;

        return `${this.point.name.replace(/----/g, '\u2192')}:<br/><b>${val} ${label}</b><br/>${(this.point.metadata &&
          this.point.metadata.tagData &&
          this.point.metadata.tagData.bracketLabel) ||
          ''}`;
      }
    };

    if (chartOptions.plotOptions && chartOptions.plotOptions.bar) {
      const { bar } = chartOptions.plotOptions;

      if (this.props.onModelSelect) {
        if (!bar.point) {
          bar.point = { events: {} };
        }
        bar.point.events.click = this.getLegendItemClick();
      }
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: false,
  enableToggle: false,
  buckets: [
    {
      name: 'Positive X Axis'
    },
    {
      name: 'Negative X Axis',
      sampleRateFactor: -1
    }
  ]
};

export { config };
