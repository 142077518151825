import { action, computed, observable } from 'mobx';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import { hasDependencyFailures } from 'dataviews/dependencies/ReportDependencyChecker';
import BaseModel from 'models/BaseModel';
import $auth from 'stores/$auth';
import Socket from 'util/Socket';
import { safelyParseJSON } from 'util/utils';
import api from 'util/api';

export default class SavedView extends BaseModel {
  get defaults() {
    return { preset: false, share_level: 'company', shared: true, saved_query_id: undefined };
  }

  // this won't be populated until the SQL is requested
  @observable
  rawSql = '';

  constructor(attributes = {}) {
    // allow for empty model to be created for save form.
    const query =
      attributes.savedQuery && attributes.savedQuery.value ? safelyParseJSON(attributes.savedQuery.value) : '';
    super(
      Object.assign({}, attributes, {
        query
      })
    );
  }

  get urlRoot() {
    return '/api/portal/savedViews';
  }

  serialize(data) {
    const serialized = { ...data };

    if (serialized.category_id === '') {
      serialized.category_id = null;
    }

    return super.serialize(serialized);
  }

  @action
  fetchViewSQL = () => {
    this.getNewQueryBuilderQuery('realtime', this.get('query'));
  };

  @action
  onRemove = () => {
    if (this.collection) {
      this.collection.clearSelection();
    }
    this.destroy();
  };

  get messages() {
    return {
      create: `View ${this.get('view_name')} was added successfully`,
      update: `${this.get('view_name')} was updated successfully`,
      destroy: `${this.get('view_name')} was removed successfully`,
      duplicate: `${this.get('view_name')} was duplicated successfully`
    };
  }

  get removalConfirmText() {
    return { title: 'Remove Saved View', text: `Are you sure you want to remove ${this.get('view_name')}?` };
  }

  get omitDuringSerialize() {
    return ['category'];
  }

  get name() {
    return this.get('view_name');
  }

  get type() {
    return 'Saved View';
  }

  get description() {
    return this.get('view_description');
  }

  get iconName() {
    return 'timeline-area-chart';
  }

  @computed
  get vizType() {
    return this.query && this.query.get('viz_type');
  }

  @action
  save(attributes = {}, options = {}) {
    return super.save(attributes, options).then(
      action(() => {
        this.hasDependencyFailures = hasDependencyFailures(this);
      })
    );
  }

  @action
  duplicate = () => {
    const data = { ...this.attributes.toJS(), view_name: this.getUniqueCopyName('view_name') };

    // Preset views must have their share_level reset to be cloned successfully (org is arbitrary choice)
    if (data.share_level === 'global') {
      data.share_level = 'company';
    }

    if (this.collection) {
      this.collection.requestStatus = 'Copying';
    }

    return api.post(this.urlRoot, { data }).then(
      attributes => {
        showSuccessToast(`${this.name} was cloned successfully`);

        return this.collection.fetch().then(() => this.collection.get(attributes.id));
      },
      () => {
        showErrorToast('Saved View could not be copied');
        if (this.collection) {
          this.collection.requestStatus = null;
        }
      }
    );
  };

  @computed
  get shareLevel() {
    const level = this.isPreset ? 'Preset' : 'Shared';
    return this.isUserLevel ? 'Private' : level;
  }

  @computed
  get isPreset() {
    return this.get('share_level') === 'global';
  }

  @computed
  get isCompanyLevel() {
    return this.get('share_level') === 'company';
  }

  @computed
  get isUserLevel() {
    return this.get('share_level') === 'user';
  }

  @computed
  get canEdit() {
    return (
      this.isUserLevel || // Personal
      (this.isCompanyLevel && $auth.isAdministrator) || // Company
      (this.isPreset && $auth.isPresetCompany) // Preset
    );
  }

  getQuerySocket() {
    return new Socket({
      outType: 'getQuery',
      inType: 'getQuery',
      frequency: 0,
      delaySend: true,
      onError(err) {
        console.warn('Failed to fetch SavedView query data', err);
      },
      onSuccess: action(data => {
        this.rawSql = data;
      })
    });
  }

  getNewQueryBuilderQuery(type, query) {
    const socket = this.getQuerySocket();
    socket.setPayload({ type, query });
    socket.send();
  }
}
