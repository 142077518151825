import { action, computed, observable } from 'mobx';
import { getHashForQueries } from 'services/urlHash';

import Collection from 'models/Collection';
import $dataviews from 'stores/$dataviews';
import QueryModel from './QueryModel';
import QueryBucketModel from './QueryBucketModel';

class QueryBucketCollection extends Collection {
  @observable
  viewType = 'stackedArea';

  get model() {
    return QueryBucketModel;
  }

  get url() {
    return '/api/portal/urlHash';
  }

  @computed
  get activeBuckets() {
    return this.models.filter(model => model.hasQueries);
  }

  @computed
  get activeBucketCount() {
    return this.models.reduce((value, bucket) => value + (bucket.hasQueries ? 1 : 0), 0);
  }

  @computed
  get loading() {
    // we only want this to return true if all activeBuckets buckets are loading.
    return this.activeBuckets.reduce((loading, bucket) => loading && bucket.loading, true);
  }

  @computed
  get title() {
    if (!this.size) {
      return null;
    }
    const bucket = this.at(0);
    return bucket.firstQuery && bucket.firstQuery.autoTitle;
  }

  set title(title) {
    this.at(0)
      .queries.at(0)
      .set('query_title', title);
  }

  @computed
  get updateFrequency() {
    return this.size && this.at(0).updateFrequency;
  }

  set updateFrequency(frequency) {
    this.each(bucket => (bucket.updateFrequency = frequency));
  }

  @computed
  get selectedQuery() {
    const bucket = this.selected;
    return bucket && (bucket.queries.selected || bucket.overlayQueries.selected);
  }

  @action
  mirrorQuery(queryModel, mirrorBucket, overrides = {}) {
    const mirroredQuery = queryModel.invert();
    mirroredQuery.bucket = mirrorBucket.get('name');
    delete mirroredQuery.aggregates;
    const created = mirrorBucket[mirroredQuery.isOverlay ? 'overlayQueries' : 'queries'].add(
      QueryModel.create(mirroredQuery).serialize()
    );
    created[0].set(Object.assign({ mirrored: true }, overrides));
  }

  @action
  set = queries => {
    if (!queries.length) {
      const defaultQuery = QueryModel.create().serialize();
      queries = [{ query: defaultQuery, bucket: defaultQuery.bucket }];
    }

    this.viewType = queries[0].query.generatorMode === true ? 'generator' : queries[0].query.viz_type;
    this.reset();
    const { buckets, mirrorable, allowsSecondaryOverlay } = $dataviews.getConfig(this.viewType);
    buckets.forEach(bucket => this.add(bucket));

    queries.forEach((query, index) => {
      const isLastQuery = index === queries.length - 1;
      let bucket = this.find({ name: query.bucket || query.query.bucket });
      if (!bucket) {
        bucket = this.at(0);
      }

      const queryCollection = bucket[query.isOverlay ? 'overlayQueries' : 'queries'];
      // we do this so we can select the last query
      const queryModel = queryCollection.add(QueryModel.create(query.query).serialize())[0];
      if (isLastQuery) {
        queryCollection.select(queryModel);
        this.select(bucket);
      }

      let secondaryBucketIndex = bucket.get('secondaryOverlayBucket');
      if (query.query.secondaryTopxMirrored) {
        secondaryBucketIndex = bucket.get('secondaryMirrorBucket') || secondaryBucketIndex;
      }

      if (
        query.query.secondaryOutsort &&
        allowsSecondaryOverlay &&
        secondaryBucketIndex !== undefined &&
        query.query.secondaryTopxSeparate === true
      ) {
        const secondaryBucket = this.at(secondaryBucketIndex);
        const secondaryQuery = queryModel.serialize();
        secondaryQuery.outsort = secondaryQuery.secondaryOutsort;
        secondaryQuery.bucket = secondaryBucket.get('name');
        delete secondaryQuery.aggregates;
        delete secondaryQuery.secondaryOutsort;

        // we do this instead of a direct collection.add() so we can send the querymodel to mirror if required.
        const secondaryQueryCollection = secondaryBucket[secondaryQuery.isOverlay ? 'overlayQueries' : 'queries'];
        const secondaryQueryModel = QueryModel.create(secondaryQuery);
        const created = secondaryQueryCollection.add(secondaryQueryModel.serialize());
        created[0].set({ isSecondary: true });

        const secondaryMirrorBucketIndex = secondaryBucket.get('mirrorBucket');
        if (secondaryQuery.mirror && mirrorable && secondaryMirrorBucketIndex !== undefined) {
          this.mirrorQuery(secondaryQueryModel, this.at(secondaryMirrorBucketIndex), { isSecondary: true });
        }
      }

      // only do this if mirroring is available, and hasn't already been used up by secondary overlay.
      const mirrorBucketIndex = bucket.get('mirrorBucket');
      if (
        query.query.mirror &&
        mirrorable &&
        mirrorBucketIndex !== undefined &&
        (!query.query.secondaryOutsort || mirrorBucketIndex !== secondaryBucketIndex)
      ) {
        this.mirrorQuery(queryModel, this.at(mirrorBucketIndex));
      }
    });
  };

  serialize() {
    const data = [];
    this.each(bucket => {
      bucket.queries.each(query => {
        if (!query.get('mirrored') && !query.get('isSecondary')) {
          data.push({
            bucket: query.get('bucket'),
            isOverlay: false,
            query: query.serialize()
          });
        }
      });
      bucket.overlayQueries.each(query => {
        if (!query.get('mirrored') && !query.get('isSecondary')) {
          data.push({
            bucket: query.get('bucket'),
            isOverlay: true,
            query: query.serialize()
          });
        }
      });
    });
    return data;
  }

  async save(persist) {
    return getHashForQueries(this.serialize(), persist);
  }
}

export default QueryBucketCollection;
