import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Collapse, Dialog, Intent, Spinner } from '@blueprintjs/core';
import { Box } from 'components/flexbox';

import Documentation from './Documentation';

const docs = {
  collection: false,
  isTable: true,
  name: 'Kentik Documentation',
  table: Documentation,
  url: false
};

@inject('$app')
@observer
class HelpDialog extends Component {
  state = {
    open: {
      alertPolicies: false,
      dashboards: false,
      devices: false,
      docs: false,
      interfaces: false,
      sites: false,
      users: false
    }
  };

  handleCollapseButton = category => {
    this.setState({ open: { [category]: !this.state.open[category] } });
  };

  renderDocs(resultSet) {
    const { history } = this.props;
    const category = 'docs';
    const component = docs;
    const Table = component.table;

    let topResultsCollection;
    let hiddenResultsCollection;
    let numTopResults = 0;
    let numHiddenResults = 0;

    if (component.table) {
      /* eslint new-cap: 0 */
      if (component.collection) {
        topResultsCollection = new component.collection(resultSet.slice(0, 5));
        hiddenResultsCollection = new component.collection(resultSet.slice(5));
      } else {
        topResultsCollection = resultSet.slice(0, 5);
        hiddenResultsCollection = resultSet.slice(5);
      }

      numTopResults = component.collection ? topResultsCollection.models.length : topResultsCollection.length;
      numHiddenResults = component.collection ? hiddenResultsCollection.models.length : hiddenResultsCollection.length;
    }

    return (
      <Box key={category} mb={2}>
        {topResultsCollection &&
          numTopResults > 0 && (
            <Box>
              <Box className={component.isTable ? 'pt-card' : ''} mt={2}>
                <Table
                  className="pt-card-body"
                  collection={topResultsCollection}
                  history={history}
                  query={this.props.input}
                />
              </Box>
              <Box mt={2} mb={2} class="pt-button-group">
                {numHiddenResults > 0 && (
                  <Button
                    className="pt-intent-primary"
                    iconName={this.state.open[category] ? 'caret-down' : 'caret-right'}
                    onClick={() => this.handleCollapseButton(category)}
                  >
                    {this.state.open[category] ? 'Hide' : 'Show'} {numHiddenResults}{' '}
                    {this.state.open[category] ? '' : 'More'} {component.name}
                  </Button>
                )}
                {component.url && (
                  <Button onClick={() => (window.location.href = component.url)}>View All {component.name}</Button>
                )}
              </Box>
              <Collapse isOpen={this.state.open[category]}>
                <Box>
                  <Box className={component.isTable ? 'pt-card' : ''} mt={2} mb={2}>
                    {component.table && (
                      <Table className="pt-card-body" mt={2} collection={hiddenResultsCollection} history={history} />
                    )}
                  </Box>
                </Box>
              </Collapse>
            </Box>
          )}
      </Box>
    );
  }

  render() {
    const { $app, response } = this.props;
    const results = [];
    let totalResults = 0;

    if (response) {
      const resultSet = response.docs;

      if (resultSet.length > 0) {
        results.push(this.renderDocs(resultSet));
      }
      totalResults += resultSet.length;
    }

    return (
      <Box className="search-container">
        <Dialog
          isOpen={$app.showHelp}
          onClose={$app.closeHelp}
          autoFocus={false}
          enforceFocus={false}
          hasBackdrop={false}
          canOutsideClickClose={false}
          inline
          transitionName="pt-dialog"
          title="Help"
        >
          {results.length === 0 &&
            !totalResults && (
              <Box className="pt-dialog-body" pt={1}>
                <Spinner intent={Intent.PRIMARY} />
              </Box>
            )}
          {results.length > 0 && (
            <Box className="pt-dialog-body" pr={2}>
              {totalResults === 0 && <div>No results found.</div>}
              {totalResults > 0 && <div className="search-results-container">{results}</div>}
            </Box>
          )}
        </Dialog>
      </Box>
    );
  }
}

export default HelpDialog;
