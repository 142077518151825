import Collection from '../Collection';
import SavedView from './SavedView';

class SavedViewCollection extends Collection {
  get url() {
    return '/api/portal/savedViews';
  }

  get model() {
    return SavedView;
  }

  get defaultGroupBy() {
    return 'category.name';
  }

  get presetFilters() {
    return [
      {
        label: 'Personal',
        fn: model => model.isUserLevel
      },
      {
        label: 'Company',
        fn: model => model.isCompanyLevel
      }
      // leaving this to uncomment whenever we do expose presets (should be sometime Q4 2017)
      // ,{
      //   label: 'Presets',
      //   fn: model => model.isPreset
      // }
    ];
  }

  get defaultSortState() {
    return {
      field: 'share_level',
      direction: 'asc'
    };
  }
}

export default SavedViewCollection;
