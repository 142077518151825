import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Field, Select } from 'components/forms';
import { Flex, Box } from 'components/flexbox';

const selectValueRenderer = (option, placeholder) => {
  if (!option) {
    return <span className="pt-text-muted">{placeholder || 'Select a value...'}</span>;
  }

  return (
    <span>
      <strong className={option.value === 'Any' ? 'pt-intent-success' : 'pt-intent-warning'}>{option.label}</strong> of
      the following groups
    </span>
  );
};

@observer
class FilterGroupConnector extends Component {
  render() {
    const { readOnly, connector } = this.props;
    const connectorValue = connector.getValue();

    const connectorClassName = classNames('filter-group-connector', {
      'filter-group-connector-all': connectorValue === 'All'
    });

    if (readOnly) {
      return (
        <div className="pt-text-muted">
          <div className="any-all-operator">
            <strong>{connectorValue}</strong>
          </div>{' '}
          of the following
        </div>
      );
    }

    return (
      <Flex className={connectorClassName} align="center">
        <small>Match</small>
        <Box style={{ margin: '0 4px' }}>
          <Field className="no-margin" field={connector} showLabel={false}>
            <Select className="pt-small" valueRenderer={selectValueRenderer} />
          </Field>
        </Box>
      </Flex>
    );
  }
}

export default FilterGroupConnector;
