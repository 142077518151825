import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Highcharts from 'highcharts/highcharts';
import ReactHighcharts from 'react-highcharts';

const ReactHC = ReactHighcharts.withHighcharts(Highcharts);

@inject('$app')
@observer
class Highchart extends Component {
  darkMode;

  series = [];

  componentWillMount() {
    this.darkMode = this.props.$app.darkThemeEnabled;
  }

  // listen for new data to come in, and re-do the chart.
  componentWillReceiveProps(nextProps) {
    const { collection, lastUpdated } = nextProps;

    if (this.chartRef && (this.series.length === 0 || lastUpdated !== this.props.lastUpdated)) {
      this.renderData(collection.toJS());
    }
  }

  shouldComponentUpdate(nextProps) {
    return this.darkMode !== nextProps.$app.darkThemeEnabled;
  }

  componentWillUpdate(nextProps) {
    this.darkMode = nextProps.$app.darkThemeEnabled;
  }

  renderData(chartData) {
    if (this.chartRef) {
      this.chartRef.chart.series.forEach(series => series.remove(false));

      if (chartData.length > 0) {
        const { getSeries } = this.props;
        this.series = getSeries(chartData);
        this.series.forEach(series => this.chartRef.chart.addSeries(series));
      }

      this.chartRef.chart.redraw();
    }
  }

  chartRef = refs => {
    if (refs) {
      this.chartRef = refs;
    }
  };

  render() {
    const { title, config } = this.props;

    const hcConfig = { ...config, series: this.series };

    return (
      <div style={{ width: '100%' }}>
        <h6>{title}</h6>
        <ReactHC ref={this.chartRef} config={hcConfig} domProps={{ width: '100%' }} />
      </div>
    );
  }
}

export default Highchart;
