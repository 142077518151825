import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, ButtonGroup, Intent, Spinner, Tag } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';

@inject('$onboarding')
@observer
class ProductUpdates extends Component {
  componentDidMount() {
    this.props.$onboarding.fetchProductUpdates();
  }

  render() {
    const { productUpdates, fetchingProductUpdates } = this.props.$onboarding;

    return (
      <Fragment>
        <h4>Product Updates</h4>

        <p className="pt-text-muted">
          Find a{' '}
          <a href="https://kentik.com/product-updates" rel="noopener noreferrer" target="_blank">
            full list of updates
          </a>{' '}
          on our website
        </p>
        {fetchingProductUpdates && <Spinner className="pt-small" />}
        <ButtonGroup vertical className="pt-fill pt-minimal pt-align-left" style={{ marginTop: 16, marginLeft: -9 }}>
          {productUpdates &&
            productUpdates.map(update => (
              <Button key={update.value} onClick={() => window.open(update.href)}>
                <Flex justify="space-between">
                  {update.value}
                  <Tag intent={Intent.WARNING} className="pt-round pt-strong" style={{ alignSelf: 'center' }}>
                    NEW
                  </Tag>
                </Flex>
              </Button>
            ))}
        </ButtonGroup>
      </Fragment>
    );
  }
}

export default ProductUpdates;
