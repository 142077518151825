import React, { Component } from 'react';
import { Popover, Position, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';

import { Box } from 'components/flexbox';
import Icon from 'components/Icon';
import { UP, DOWN, ENTER, ESC, TAB } from 'util/keyCodes';

import SelectInput from './SelectInput';
import { getOptionsWithNone } from './selectHelpers';

export const defaultOptionRenderer = props => {
  const {
    className,
    disabled,
    field,
    iconCls,
    iconName,
    key,
    label,
    selectItem,
    selected,
    style,
    separator = false,
    value
  } = props;

  if (separator) {
    return <hr key="separator" style={{ margin: '4px 8px' }} />;
  }

  const onClick = !selected && !disabled ? () => selectItem(field, value) : undefined;
  const icon = iconCls || iconName;

  return (
    <div key={key || value} className={className} onClick={onClick} style={style}>
      {icon && <Icon name={icon} style={{ marginRight: 6 }} />}
      {label}
    </div>
  );
};

class SelectPopover extends Component {
  static defaultProps = {
    disabled: false,
    disabledValues: [],
    loading: false,
    menuHeight: 300,
    menuWidth: 230,
    multi: false,
    optionLimit: -1,
    optionRenderer: defaultOptionRenderer,
    rowHeight: 28,
    showFilter: false,
    tetherOptions: {
      offset: '-3px 0',
      constraints: [{ attachment: 'together', pin: true, to: 'scrollParent' }]
    }
  };

  state = {
    optionsFilter: ''
  };

  componentWillUpdate(nextProps) {
    const { isOpen, showFilter } = this.props;

    if (showFilter && !isOpen && nextProps.isOpen) {
      this.filterOptions('');
      this.focusInput();
    }
  }

  handleFilterOptions = e => {
    this.filterOptions(e.target.value);
  };

  handleKeyboardEvents = e => {
    const { field, onClose, onSelectItem } = this.props;
    const { selectedIndex } = this.state;
    const selectOptions = this.getFilteredOptions();

    const key = e.keyCode ? e.keyCode : e.which;
    const keyCodes = [UP, DOWN, ENTER, ESC, TAB];

    if (keyCodes.includes(key)) {
      e.stopPropagation();

      if (key === DOWN) {
        this.setState(
          {
            selectedIndex: selectedIndex < selectOptions.length - 1 ? selectedIndex + 1 : 0
          },
          this.updateScrollPosition
        );
      }
      if (key === UP) {
        this.setState(
          {
            selectedIndex: selectedIndex > 0 ? selectedIndex - 1 : selectOptions.length - 1
          },
          this.updateScrollPosition
        );
      }

      if (key === ENTER) {
        const item = selectOptions[selectedIndex === -1 ? 0 : selectedIndex];
        if (item && field.getValue() !== item.value) {
          onSelectItem(field, item.value);
        } else {
          onClose();
        }

        this.setState({ optionsFilter: '', selectedIndex: -1, options: [] });
      }

      if (key === ESC || key === TAB) {
        onClose();
        this.setState({ optionsFilter: '', selectedIndex: -1, options: [] });
      }
    }
  };

  handleSelectItem = (field, value) => {
    const { onSelectItem } = this.props;

    const { options } = this.state;

    this.setState({ optionsFilter: '', selectedIndex: -1, options: [] });

    let option;
    if (options) {
      option = options.find(opt => opt.value === value);
    }

    onSelectItem(field, value, option);
  };

  updateScrollPosition = () => {
    const { selectedIndex } = this.state;
    const itemHeight = this.scrollEl.firstChild.offsetHeight;

    if (this.scrollEl) {
      const selectedIndexY = (selectedIndex + 1) * itemHeight;

      if (selectedIndexY > this.scrollEl.scrollTop + this.scrollEl.offsetHeight) {
        this.scrollEl.scrollTop = selectedIndexY - this.scrollEl.offsetHeight + 8;
      } else if (selectedIndexY - itemHeight < this.scrollEl.scrollTop) {
        this.scrollEl.scrollTop = selectedIndexY - itemHeight;
      }
    }
  };

  handleScrollRef = refs => {
    const { scrollRef } = this.props;

    if (scrollRef) {
      scrollRef(refs);
    } else {
      this.scrollEl = refs;
    }
  };

  focusInput = () => {
    if (this.inputEl) {
      this.inputEl.focus();
    }
  };

  filterOptions(optionsFilter) {
    const { clearable, onQuery, field } = this.props;

    if (onQuery) {
      this.setState({ optionsFilter });

      const { selectedIndex, options } = this.state;

      const value =
        selectedIndex >= 0 && options && options.length > selectedIndex
          ? options[selectedIndex].value
          : field.getValue();

      onQuery(optionsFilter).then(newOptions => {
        let newSelectedIndex = newOptions.findIndex(option => option.value === value);
        if (clearable) {
          newSelectedIndex += 1;
        }
        this.setState({ options: newOptions, selectedIndex: newSelectedIndex });
        this.focusInput();
      });
    } else {
      this.setState({ optionsFilter, selectedIndex: -1 });
    }
  }

  getFilteredOptions() {
    const { clearable, minFilterChars, onQuery, options, optionLimit, showFilter } = this.props;
    const { optionsFilter } = showFilter ? this.state : this.props;

    let filteredOptions = options;

    if (onQuery) {
      filteredOptions = this.state.options || [];
    } else if (optionsFilter) {
      const filter = optionsFilter.toLowerCase();
      filteredOptions = options.filter(
        ({ label, value, filterLabel }) =>
          (filterLabel && filterLabel.toLowerCase().includes(filter)) ||
          (typeof label === 'string' && label.toLowerCase().includes(filter)) ||
          (typeof label === 'number' && label.toString().startsWith(filter)) ||
          (typeof value === 'string' && value.toLowerCase().includes(filter)) ||
          (typeof value === 'number' && value.toString().startsWith(filter))
      );
    }

    if (minFilterChars > 0 && optionsFilter.length < minFilterChars) {
      return [];
    }

    const allFilteredOptions = clearable ? getOptionsWithNone(filteredOptions) : filteredOptions;

    if (optionLimit > 0 && showFilter) {
      return allFilteredOptions.slice(0, optionLimit);
    }

    return allFilteredOptions;
  }

  renderFilterInput() {
    const { showFilter } = this.props;
    const { optionsFilter } = this.state;

    const groupClassName = classNames('pt-input-group');
    const inputClassName = classNames('pt-input');

    return (
      showFilter && (
        <Box p={0.5}>
          <div className={groupClassName}>
            <SelectInput
              type="text"
              className={inputClassName}
              placeholder="Filter options..."
              ref={inputEl => {
                this.inputEl = inputEl;
              }}
              onKeyDownCapture={this.handleKeyboardEvents}
              onChange={this.handleFilterOptions}
              value={optionsFilter}
            />
          </div>
        </Box>
      )
    );
  }

  renderOption(option, index) {
    const { field, optionRenderer, multi, showFilter, values, disabledValues } = this.props;
    const { selectedIndex } = showFilter ? this.state : this.props;

    // This is purely done to make sure it's a string compare (coerces) in multi select scenarios
    // It's not perfect because it'll match across delimiters
    const value = values && values.join ? values.join(',') : values;

    /* eslint eqeqeq: 0 */
    const selected = multi ? values.indexOf(option.value) >= 0 : value == option.value;
    const menuItemClassName = classNames('pt-menu-item', option.className, {
      'pt-active': selected,
      'pt-focused': selectedIndex === index,
      'pt-disabled': option.disabled || disabledValues.includes(option.value)
    });

    return optionRenderer({
      ...option,
      className: menuItemClassName,
      field,
      selectItem: this.handleSelectItem,
      focused: selectedIndex === index,
      selected
    });
  }

  renderOptions() {
    const { menuHeight, menuWidth, popoverRef, showFilter, fetchingOptions } = this.props;

    const padding = 4;
    const filteredOptions = this.getFilteredOptions();
    const optionsHeight = menuHeight - (showFilter ? 38 : 0);

    return (
      <div ref={popoverRef} style={{ maxHeight: menuHeight, width: menuWidth || 180, overflow: 'hidden' }}>
        {this.renderFilterInput()}
        <div ref={this.handleScrollRef} style={{ maxHeight: optionsHeight, overflow: 'auto', padding }}>
          {fetchingOptions && (
            <div className="pt-menu-item pt-disabled">
              <Spinner className="extra-small-spinner" />
            </div>
          )}
          {!fetchingOptions &&
            filteredOptions.length === 0 && <div className="pt-menu-item pt-disabled">No options found</div>}
          {!fetchingOptions && filteredOptions.map((option, index) => this.renderOption(option, index))}
        </div>
      </div>
    );
  }

  render() {
    const { children, isOpen, multi, popoverClassName, popoverPosition, showFilter, style, tetherOptions } = this.props;

    const popoverCls = classNames('select-popover pt-minimal', { multi }, popoverClassName);

    return (
      <div className={classNames('select-wrapper', { multi })} style={style}>
        <Popover
          autoFocus={showFilter}
          canEscapeKeyClose
          content={this.renderOptions()}
          enforceFocus={showFilter}
          isModal={multi}
          isOpen={isOpen}
          popoverClassName={popoverCls}
          position={popoverPosition || Position.BOTTOM_LEFT}
          tetherOptions={tetherOptions}
          popoverDidOpen={this.focusInput}
        >
          {children}
        </Popover>
      </div>
    );
  }
}

export default SelectPopover;
