import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Dialog, InputGroup, Intent } from '@blueprintjs/core';
import { Flex, Box } from 'components/flexbox';

@inject('$users')
@observer
export default class YubiDialog extends Component {
  state = {
    yubiOtp: null,
    name: ''
  };

  debouncer;

  handleYubiOtpChange = e => {
    if (this.debouncer) {
      clearTimeout(this.debouncer);
    }

    const { value } = e.target;
    this.debouncer = setTimeout(() => {
      this.setState({ yubiOtp: value });
    }, 100);
  };

  handleNameChange = e => {
    const { value } = e.target;

    this.setState(() => ({ name: value }));
  };

  finishRegistration = () => {
    const { $users, onClose } = this.props;
    const { yubiOtp, name } = this.state;
    $users.registerTotp(yubiOtp, true, name).then(onClose, () => {
      this.setState({ yubiOtp: '' });
      document.getElementById('yubiInput').value = '';
      document.getElementById('yubiInput').focus();
      document.getElementById('yubiInput').select();
    });
  };

  render() {
    const { isOpen, onClose, $users } = this.props;
    const { yubiOtp } = this.state;

    return (
      <Dialog
        isOpen={isOpen}
        title="Register YubiKey"
        onClose={onClose}
        style={{ width: 500 }}
        transitionName="pt-dialog"
      >
        <div className="pt-dialog-body">
          <Flex style={{ width: '100%' }}>
            <Box flexAuto mb={2}>
              <InputGroup placeholder="Enter Name" onChange={this.handleNameChange} />
            </Box>
          </Flex>
          <Flex style={{ width: '100%' }}>
            <Box flexAuto>
              <InputGroup id="yubiInput" placeholder="Enter Yubi OTP" onChange={this.handleYubiOtpChange} />
            </Box>
          </Flex>
        </div>
        <Flex className="pt-dialog-footer" justify="flex-end">
          <Button onClick={onClose} style={{ marginRight: 12 }} text="Cancel" />
          <Button
            disabled={$users.registeringTotp || !yubiOtp}
            loading={$users.registeringTotp}
            onClick={this.finishRegistration}
            intent={Intent.PRIMARY}
            text="Finish Registration"
          />
        </Flex>
      </Dialog>
    );
  }
}
