import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import $auth from 'stores/$auth';
import AbstractDependency from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['i_src_provider_classification', 'i_dst_provider_classification'],
  filterFields: ['i_src_provider_classification', 'i_dst_provider_classification']
};

class ProviderClassDependency extends AbstractDependency {
  get key() {
    return 'pc';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return !$auth.providerClassBelowThreshold;
  }

  get message() {
    return (
      <Fragment>
        <p className="no-margin">
          <small>
            <strong>Provider Classification</strong> is in use, but your organization currently has only{' '}
          </small>
        </p>

        {this.showConfigureButton && (
          <Button
            className="pt-small"
            iconName="cog"
            onClick={() => $auth.store.history.push('/admin/networkClassification')}
          >
            Configure
          </Button>
        )}
      </Fragment>
    );
  }
}

export default ProviderClassDependency;
