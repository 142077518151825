import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';
import Recaptcha from 'react-grecaptcha';
import { Redirect, Link } from 'react-router-dom';

import { Box, Flex } from 'components/flexbox';
import { showClientErrorToast } from 'components/Toast';
import { Page, PageBody } from 'components/page';

import { Field, Form, Input } from 'components/forms';
import Icon from 'components/Icon';
import { fields } from 'forms/config/ssoLookup';

const labelStyle = {
  fontSize: 16,
  fontWeight: 500
};

@Form({ fields })
@inject('$auth')
@observer
class SsoLookup extends Component {
  state = {};

  componentDidMount() {
    const { $auth, form } = this.props;
    $auth
      .getOpenConfig()
      .then(() => {
        form.getField('recaptcha').setRules($auth.openConfig.recaptchaEnabled ? 'required' : []);
      })
      .catch(() => {
        showClientErrorToast('Error fetching initial configuration');
      });
  }

  recaptchaCallback = response => {
    this.props.form.getField('recaptcha').setValue(response);
  };

  recaptchaExpire = () => {
    this.props.form.getField('recaptcha').setValue(null);
  };

  handleSubmit = async (form, formData) => {
    const { $auth } = this.props;
    const { email, recaptcha } = formData;
    const result = await $auth.ssoLookup({
      email,
      recaptcha
    });

    const { companies, errorStatus } = result;

    if ($auth.openConfig.recaptchaEnabled) {
      window.grecaptcha.reset();
      form.getField('recaptcha').setValue(null);
    }

    if (errorStatus || !companies.length) {
      form.invalidate(
        "This could mean either your company doesn't have SSO enabled or that we were unable to " +
          'locate a SSO enabled company via email domain within allowed security parameters. If ' +
          'your company has SSO enabled, please contact your Kentik system administrator for Single Sign-on details.'
      );
    } else if (companies.length > 1) {
      this.setState({ reqComplete: true, companies });
    } else {
      this.setState({ ssoRedirectPath: `/login/sso/${companies[0].company_name}` });
    }
  };

  render() {
    const { form, $auth } = this.props;
    const { reqComplete, ssoRedirectPath, companies } = this.state;
    const showRecaptcha = $auth.openConfig && $auth.openConfig.recaptchaEnabled;

    if (!$auth.openConfig) {
      return null;
    }

    // If ssoRedirect path is set, redirect immediately.
    if (ssoRedirectPath) {
      return <Redirect to={{ pathname: ssoRedirectPath }} />;
    }

    return (
      <Page title="SSO Locator">
        <PageBody>
          <Flex flexColumn align="center" col={12} p={4}>
            <Flex flexColumn align="center" col={10}>
              <h2>Locate your company&apos;s Single Sign-on page.</h2>
            </Flex>
            <Box className="pt-callout" col={6} mt={2} p={2} mb={2}>
              <h5>Don&apos;t have Single-Sign on?</h5>
              <span className="pt-text-muted">
                If your company doesn&apos;t have Kentik Single Sign-on and you&apos;d like to see this feature enabled,
                please contact your Kentik system administrator to request it.
                <p>
                  <Link to="/">Return to Kentik</Link>
                </p>
              </span>
            </Box>
            {!reqComplete && (
              <Box col={6}>
                <Field
                  name="email"
                  autoFocus
                  labelStyle={labelStyle}
                  placeholder="email@yourCompany.com"
                  helpText="Enter email associated with your Kentik account. NOTE: For security purposes, only the email domain will be used for lookup"
                >
                  <Input className="pt-large" />
                </Field>
                {showRecaptcha && (
                  <Box mb={2}>
                    <Recaptcha
                      sitekey={$auth.openConfig.recaptchaSiteKey}
                      callback={this.recaptchaCallback}
                      expiredCallback={this.recaptchaExpire}
                    />
                  </Box>
                )}
                {form.error && (
                  <Box className="pt-callout pt-intent-danger pt-icon-error" my={2}>
                    <h5>SSO results not found</h5>
                    {form.error}
                  </Box>
                )}
                <Button
                  className="pt-large"
                  intent={Intent.PRIMARY}
                  text="Submit"
                  disabled={!$auth.openConfig || !form.dirty || !form.valid}
                  loading={$auth.ssoLookupInProgress}
                  onClick={() => form.submit(this.handleSubmit)}
                />
              </Box>
            )}
            {reqComplete &&
              companies && (
                <Flex flexColumn align="center" col={6} pb={2} className="pt-callout pt-intent-success">
                  <h5>Mulitple Results Found</h5> Multiple results have been found for the provided email domain. Select
                  to be redirected to the appropriate Kentik login page.
                  <ul className="pt-list pt-list-unstyled">
                    {companies.map(company => (
                      <li key={company.company_name}>
                        <Icon name="dot" />{' '}
                        <Link to={`/login/sso/${company.company_name}`}>{company.company_name_full}</Link>
                      </li>
                    ))}
                  </ul>
                </Flex>
              )}
          </Flex>
        </PageBody>
      </Page>
    );
  }
}

export default SsoLookup;
