import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';
import { withRouter } from 'react-router-dom';

import { Form, Field, Select, Switch } from 'components/forms';
import { fields, options } from 'forms/config/explorerQuery';
import { itemExplorerFields } from 'forms/config/dashboardItemDetails';
import { Flex, Box } from 'components/flexbox';
import DeviceSelector2 from 'components/forms/device/DeviceSelector2';
import { BracketOptions, FilterOptions, TimeOptions } from 'components/sidebar';
import QueryModel from 'models/query/QueryModel';
import QueryOptions from 'views/Explorer/sidebar/QueryOptions';
import { getDisabledCloudDimensions, getDisabledSubtypeDimensions } from 'util/devices';
import { getDisabledAppProtocolDimensions } from 'util/dimensions';

import ItemExplorerOptionTitle from './ItemExplorerOptionTitle';

@Form({ fields: { ...fields, ...itemExplorerFields }, options })
@inject('$dataviews', '$devices', '$dictionary', '$explorer')
@observer
class ItemExplorerOptions extends Component {
  componentWillMount() {
    const { $explorer, form } = this.props;

    if ($explorer.formState && form && $explorer.formState !== form) {
      // we had an old formstate, and now we have a new form, so let's restore old formstate's values
      // this is to support tab switching inside of the dash item edit dialog
      form.init($explorer.formState.getValues());
    }

    $explorer.registerFormState(form);
  }

  componentWillReceiveProps({ $explorer, form, model, dashboardItem }) {
    if (form !== $explorer.formState) {
      $explorer.registerFormState(form);
    }

    if (!form.dirty && model.get('time_locked') !== form.getValue('time_locked')) {
      // set from source of truth if model and form are not in sync.
      form.setValues({
        time_locked: dashboardItem.get('time_locked'),
        device_locked: dashboardItem.get('device_locked'),
        filter_source: dashboardItem.get('filter_source')
      });
    }
  }

  handleTimeModeChange = mode => {
    const {
      $explorer: { dataview },
      model
    } = this.props;
    if (mode !== 'lookback' && dataview.hasUpdateFrequency) {
      model.set({ update_frequency: 0 });
    }
  };

  toggleFiltersEditing = () => {
    this.props.$explorer.sidebar.toggleEditingSection('filters');
  };

  toggleBracketingEditing = () => {
    this.props.$explorer.sidebar.toggleEditingSection('bracketing');
  };

  onRunQueryClick = () => {
    const { form, model, $explorer } = this.props;

    form.submit(() => {
      model.set(form.getValues());
      $explorer.apply(QueryModel.create(model.serialize()));
      form.setModel(model);
    });
  };

  syncTimeLocked = (field, value) => {
    const { form, dashboard } = this.props;
    if (value) {
      form.setValues({
        lookback_seconds: dashboard.get('query').lookback_seconds
      });
    }
  };

  syncDeviceLocked = (field, value) => {
    const { form, dashboard } = this.props;
    if (value) {
      const query = dashboard.get('query');
      form.setValues({
        device_name: query.device_name || [],
        device_sites: query.device_sites || [],
        device_labels: query.device_labels || [],
        device_types: query.device_types || [],
        all_devices: query.all_devices
      });
    }
  };

  syncFilterSource = (field, value) => {
    const { form, dashboard } = this.props;

    if (value === 'dashboard') {
      const query = dashboard.get('query');

      if (query.filters) {
        form.setValue('filters.connector', query.filters.connector);
        form.setValue('filters.filterGroups', query.filters.filterGroups);
      }
    }
  };

  render() {
    const { form, model, $dataviews, $devices, $dictionary, $explorer } = this.props;
    const { dictionary } = $dictionary;
    const { sidebar } = $explorer;
    const device_name = form.getValue('device_name');
    const device_labels = form.getValue('device_labels');
    const device_sites = form.getValue('device_sites');
    const device_types = form.getValue('device_types');
    const all_devices = form.getValue('all_devices');
    const viz_type = form.getValue('viz_type');
    const allowBrackets = !$dataviews.getConfig(viz_type).suppressBracketing;

    const hasKProbeSelected = all_devices
      ? $devices.hasDnsProbe
      : $devices.containsDnsProbe({ device_name, device_labels, device_sites, device_types });

    const disabledCloudDimensions = getDisabledCloudDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types
    });

    const disabledSubtypeDimensions = getDisabledSubtypeDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types
    });

    const disabledKProbeDimensions = !hasKProbeSelected ? dictionary.kprobeDimensions : [];

    const disabledDimensions = [
      ...disabledCloudDimensions,
      ...disabledKProbeDimensions,
      ...disabledSubtypeDimensions,
      ...getDisabledAppProtocolDimensions(form.getValue('metric'), form.getValue('filters.filterGroups'))
    ];

    const disabledMetrics = !hasKProbeSelected ? ['TCP'] : [];
    const disabledFilters = disabledDimensions;

    const editingBracketing = $explorer.sidebar.editingSections.get('bracketing');
    const editingFilters = $explorer.sidebar.editingSections.get('filters');

    const isDeviceLocked = form.getValue('device_locked');
    const isTimeLocked = form.getValue('time_locked');
    const isFilterLocked = form.getValue('filter_source') !== 'dashboard';

    return (
      <Flex flexColumn className="dashboard-item-explorer-options overflow-auto" p={0.25} pr={0.5}>
        <Button
          intent={Intent.PRIMARY}
          text="Preview Query"
          className="pt-medium"
          onClick={this.onRunQueryClick}
          disabled={!form.valid}
        />

        <Box className="pt-card flat" py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Query" iconCls="code" />
          <QueryOptions
            model={model}
            disabledDimensions={disabledDimensions}
            disabledMetrics={disabledMetrics}
            hasKProbeSelected={hasKProbeSelected}
          />
        </Box>

        <Box className="pt-card flat sidebar-time" py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Time Range" iconCls="access_time" />
          <Field
            name="time_locked"
            showLabel={false}
            style={{ marginBottom: 16 }}
            width={230}
            onChange={this.syncTimeLocked}
          >
            <Switch switchLabel="Controlled by Dashboard" />
          </Field>
          <TimeOptions
            className="no-margin"
            onModeChange={this.handleTimeModeChange}
            disabled={!isTimeLocked}
            showTimeZoneSelector={false}
            showJumpButtons={false}
            showModes={['lookback']}
            showFieldLabels={false}
          />
        </Box>

        {allowBrackets && (
          <Box className="pt-card flat" py={2} px={1} my={1}>
            <ItemExplorerOptionTitle title="Bracketing" iconCls="graphic_eq" />
            <BracketOptions
              sidebar={sidebar}
              isOpen={editingBracketing}
              onClose={this.toggleBracketingEditing}
              disabledDimensions={disabledDimensions}
            />
            <Box mt={1}>
              <Button
                className="pt-small pt-medium"
                onClick={this.toggleBracketingEditing}
                iconName="edit"
                text="Edit Bracketing"
              />
            </Box>
          </Box>
        )}

        <Box className="pt-card flat" py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Filtering" iconCls="filter_list" />
          <Field name="filter_source" onChange={this.syncFilterSource}>
            <Select />
          </Field>
          <FilterOptions
            sidebar={sidebar}
            isOpen={editingFilters}
            onClose={this.toggleFiltersEditing}
            disabledDimensions={disabledFilters}
            readOnly={!isFilterLocked}
            allowNestedFilters
          />
          <Box mt={1}>
            <Button
              className="pt-small pt-medium"
              onClick={this.toggleFiltersEditing}
              iconName="edit"
              text="Edit Filters"
              disabled={!isFilterLocked}
            />
          </Box>
        </Box>

        <Box className="pt-card flat" py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Devices" iconCls="settings_ethernet" />
          <Field name="device_locked" showLabel={false} onChange={this.syncDeviceLocked}>
            <Switch switchLabel="Controlled by Dashboard" />
          </Field>
          <DeviceSelector2 className="pt-small" readOnly={!isDeviceLocked} disabled={!isDeviceLocked} />
        </Box>
      </Flex>
    );
  }
}

export default withRouter(ItemExplorerOptions);
