const defaultColors = [
  '#8fcea0',
  '#b3e0b6',
  '#e9f4b0',
  '#fffdcf',
  '#fee3a5',
  '#fdba7f',
  '#fd825f',
  '#e35764',
  '#d5322f',
  '#a3062a'
];
const defaultPrimaryOverlayColor = '#6da4e9';
const defaultOverlayColor = '#808f9a';

const defaultDarkPrimaryOverlayColor = '#7FADEA';
const defaultDarkOverlayColor = '#909BA6';

const marketColors = [
  '#3b75af',
  '#b3c7e5',
  '#ee8535',
  '#f5be82',
  '#519e3f',
  '#a8dc92',
  '#c53932',
  '#f29d99',
  '#8d68b8',
  '#c2b0d2',
  '#84584e',
  '#bd9e96',
  '#d57cbf',
  '#edb8d1',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd45',
  '#dbdb96',
  '#58bccc',
  '#aad9e4'
];

const labelColors = [
  '#d9a19e',
  '#cf665b',
  '#c45245',
  '#a94137',
  '#db98af',
  '#ca597a',
  '#b84162',
  '#933357',
  '#be9ad0',
  '#9557b1',
  '#793b9f',
  '#592e8f',
  '#aca1d3',
  '#735fb6',
  '#5340a5',
  '#3c3294',
  '#a2a9d2',
  '#606db5',
  '#3e4d9f',
  '#2b3989',
  '#a5c9f1',
  '#6da4e9',
  '#5188d8',
  '#3b67b4',
  '#a1d2f2',
  '#6fb4eb',
  '#599ad9',
  '#4377b3',
  '#a5dae6',
  '#78c1d4',
  '#64a8bb',
  '#4b808b',
  '#9dc7c4',
  '#65a19a',
  '#4f857b',
  '#3b665c',
  '#b6d2af',
  '#89b679',
  '#6c9b5a',
  '#527942',
  '#cedeb0',
  '#adc87c',
  '#91af5f',
  '#6a8747',
  '#e9ecad',
  '#d9de7d',
  '#c4c764',
  '#9e9b4c',
  '#fcf3b1',
  '#faec84',
  '#f2d86e',
  '#e4ac59',
  '#f1ce94',
  '#e8ad5a',
  '#e09449',
  '#d2783e',
  '#e9b19a',
  '#df7e5a',
  '#d36440',
  '#ba5536',
  '#b7aba6',
  '#847067',
  '#644e45',
  '#473630',
  '#b5bec3',
  '#808f9a',
  '#5d6d78',
  '#3c474d'
];

const defaultQualitativeColors = [
  '#2965cc',
  '#29a634',
  '#d99e0b',
  '#d13913',
  '#8f398f',
  '#00b3a4',
  '#db2c6f',
  '#9bbf30',
  '#96622d',
  '#7157d9'
];

const orangeHue = [
  '#FFE39F',
  '#F8CE87',
  '#F0B971',
  '#E7A55D',
  '#DD914A',
  '#D27D3A',
  '#C66A2C',
  '#B9561F',
  '#AC4115',
  '#9E2B0E'
];

const neutralHue = [
  '#FFEEC5',
  '#FBD1B6',
  '#F4B5A7',
  '#E99A9A',
  '#DB7F8D',
  '#CA6781',
  '#B55076',
  '#9D3C6C',
  '#802D63',
  '#5C255C'
];

const blueHue = [
  '#FFE39F',
  '#D7D7A1',
  '#B3C8A2',
  '#93B9A2',
  '#78A8A2',
  '#6096A1',
  '#4B84A0',
  '#3A729E',
  '#2C5F9C',
  '#1F4B99'
];

const purpleHue2 = [
  '#D1E1FF',
  '#C3CAF8',
  '#B5B3EF',
  '#A79CE4',
  '#9986D7',
  '#8C71C7',
  '#7F5DB3',
  '#73499B',
  '#67367F',
  '#5C255C'
];

const pinkHue = [
  '#E1BAE1',
  '#E2A2CA',
  '#DE8CB4',
  '#D676A1',
  '#CA6290',
  '#BB5081',
  '#A84075',
  '#93336A',
  '#792A62',
  '#5C255C'
];

const greenHue = [
  '#FFE39F',
  '#DCD99F',
  '#BACE9B',
  '#9AC394',
  '#7DB78A',
  '#62AA7C',
  '#4A9D6C',
  '#378F58',
  '#278140',
  '#1D7324'
];

const qualitative = [
  '#29A634',
  '#4794C1',
  '#B9561F',
  '#AAAAAA',
  '#8F398F',
  '#00B3A4',
  '#DB2C6F',
  '#9BBF30',
  '#96622D',
  '#7157D9'
];

const qualitative2 = [
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#d9d9d9'
];

const qualitative3 = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6'
];

const leGreg = [
  '#FFCBA9',
  '#FEA09E',
  '#FC7592',
  '#F84887',
  '#CB378D',
  '#A02794',
  '#741A9B',
  '#5E157E',
  '#481060',
  '#320C42'
];

const colorsafe1 = [
  '#c51b7d',
  '#de77ae',
  '#f1b6da',
  '#fde0ef',
  '#f7f7f7',
  '#e6f5d0',
  '#b8e186',
  '#7fbc41',
  '#4d9221',
  '#36750d'
];
const colorsafe2 = [
  '#b35806',
  '#e08214',
  '#fdb863',
  '#fee0b6',
  '#f7f7f7',
  '#d8daeb',
  '#b2abd2',
  '#8073ac',
  '#542788',
  '#30164e'
];
const colorsafe3 = [
  '#d73027',
  '#f46d43',
  '#fdae61',
  '#fee090',
  '#ffffbf',
  '#e0f3f8',
  '#abd9e9',
  '#74add1',
  '#4575b4',
  '#305280'
];

const defaultLabelColor = '#666666';
const defaultDarkLabelColor = '#dedede';

const DEFAULT_PALETTES = {
  default: defaultColors,
  orangeHue,

  neutralHue,

  blueHue,

  greenHue,

  purpleHue2,

  pinkHue,

  colorsafe1,
  colorsafe2,
  colorsafe3
};

const DEFAULT_QUALITATIVE_PALETTES = {
  default: defaultQualitativeColors,
  qualitative,
  qualitative2,
  qualitative3
};

const defaultVisualizationSettings = {
  standard: {
    palette: 'default',
    qualitative: 'default',
    primaryOverlay: defaultPrimaryOverlayColor,
    overlay: defaultOverlayColor,
    labels: defaultLabelColor
  },
  dark: {
    palette: 'neutralHue',
    qualitative: 'default',
    primaryOverlay: defaultDarkPrimaryOverlayColor,
    overlay: defaultDarkOverlayColor,
    labels: defaultDarkLabelColor
  }
};

export {
  defaultColors,
  marketColors,
  defaultQualitativeColors,
  defaultPrimaryOverlayColor,
  defaultOverlayColor,
  defaultDarkPrimaryOverlayColor,
  defaultDarkOverlayColor,
  defaultVisualizationSettings,
  defaultLabelColor,
  defaultDarkLabelColor,
  greenHue,
  blueHue,
  orangeHue,
  neutralHue,
  labelColors,
  leGreg,
  qualitative,
  DEFAULT_PALETTES,
  DEFAULT_QUALITATIVE_PALETTES
};
