import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Radio, RadioGroup, Switch } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { Card, Heading } from 'components';

import LandingPage from './LandingPage';
import Label from './ProfileLabel';
import { getSwitchChecked, getSwitchValue, getSwitchLabel } from './NotificationsSettings';

const labels = {
  email_service: 'Service Updates',
  email_product: 'Product Updates',
  bgpNotification: 'BGP Session Events',
  userHistoryState: 'Historical Overlay',
  userDnsLookup: 'rDNS Lookup',
  userTimezone: 'Time Zone'
};

@inject('$users', '$app')
@observer
class DefaultSettings extends Component {
  handleProfileSwitchChange = e => {
    const { name } = e.target;
    const { $users } = this.props;

    $users.updateUserProfile({ [name]: getSwitchValue($users.fullUserProfile, name) }, labels[name]);
  };

  handleTimezoneChange = e => {
    const { value } = e.target;
    const { $users } = this.props;
    $users.updateUserProfile({ userTimezone: value }, 'Time Zone');
  };

  renderSwitch(name, description, mb = 2) {
    const { fullUserProfile } = this.props.$users;

    return (
      <Flex align="center" mb={mb}>
        <Label description={description}>{labels[name]}</Label>
        <Switch
          type="checkbox"
          name={name}
          onChange={this.handleProfileSwitchChange}
          checked={getSwitchChecked(fullUserProfile, name)}
          label={getSwitchLabel(fullUserProfile, name)}
        />
      </Flex>
    );
  }

  render() {
    const { $app } = this.props;
    const { isSubtenant } = $app;

    return (
      <div>
        <Heading helpTopic="User Default Settings">Default Settings</Heading>
        <Card>
          {!isSubtenant && this.renderSwitch('userHistoryState')}
          {!isSubtenant && this.renderSwitch('userDnsLookup')}
          <h6 className="pt-text-muted" style={{ marginBottom: 12 }}>
            Time Zone
          </h6>
          <RadioGroup
            selectedValue={this.props.$users.fullUserProfile.userTimezone || 'UTC'}
            onChange={this.handleTimezoneChange}
          >
            <Radio value="UTC" label="UTC" />
            <Radio value="Local" label="Local" />
          </RadioGroup>
          {!isSubtenant && <LandingPage />}
        </Card>
      </div>
    );
  }
}

export default DefaultSettings;
