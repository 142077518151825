import { action, computed, observable } from 'mobx';
import CustomDimension from 'models/customDimensions/CustomDimension';
import { showClientErrorToast } from 'components/Toast';
import Collection from '../Collection';
import AsGroup, { AS_GROUP_DIMENSIONS } from './AsGroup';

class AsGroupCollection extends Collection {
  @observable
  dimensions = {};

  get url() {
    return '';
  }

  get model() {
    return AsGroup;
  }

  @computed
  get populated() {
    return this.get().length > 0;
  }

  @action
  fetch = options => {
    this.setRequestStatus('fetching');
    this.hasFetched = true;
    return Promise.all(
      AS_GROUP_DIMENSIONS.map(({ name, displayName }) =>
        CustomDimension.fetchByName(name, { showErrorToast: false, rawError: true })
          .catch(async response => {
            const { notFound, statusText } = response;
            if (notFound) {
              const dimension = new CustomDimension();
              await dimension.create({ display_name: displayName, name }, { toast: false });
              return dimension;
            }

            showClientErrorToast({ statusText, text: `Unable to fetch ${name}` });
            throw statusText;
          })
          .then(dimension => {
            this.dimensions[name] = dimension;
            dimension.populators.setSortState('id');
            return dimension.populators.fetch(options).then(() => dimension.populators);
          })
      )
    ).then(
      action(([srcPopulators, dstPopulators]) => {
        const groupData = srcPopulators.map(src => {
          const name = src.get('value');
          const dst = dstPopulators.find({ value: name });
          return AsGroup.getData(src, dst);
        });

        this.set(groupData);
        this.setRequestStatus(null);
      })
    );
  };
}

export default AsGroupCollection;
