import pick from 'lodash/pick';
import { getCookie, setCookie } from './cookies';
import { parseQueryString } from './querystring';

const utmCookieMap = {
  utm_campaign: 'ppcCampaign',
  utm_content: 'ppcContent',
  utm_medium: 'ppcMedium',
  utm_source: 'ppcSource',
  utm_term: 'ppcKeyword',
  gclid: 'gclid'
};

const utmPropNames = Object.keys(utmCookieMap);

export function setUtmCookies() {
  const utmValues = pick(parseQueryString(), utmPropNames);

  Object.keys(utmValues).forEach(key => {
    setCookie(utmCookieMap[key], utmValues[key]);
  });
}

export function getUtmCookies() {
  return utmPropNames.reduce((obj, key) => {
    const value = getCookie(utmCookieMap[key]);
    return value ? { ...obj, [key]: value } : obj;
  }, {});
}
