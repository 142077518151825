import { action, computed, observable } from 'mobx';

import BaseModel from 'models/BaseModel';
import QueryModel from 'models/query/QueryModel';
import { getModelFilters } from 'services/filters';
import { USER_TIMEZONE } from 'util/dateUtils';
import { getQueryForHash } from 'services/urlHash';
import { replaceFilterField } from 'util/utils';

class DashboardStore {
  @observable
  formState;

  @observable
  completedInitialParametricSubmit = false;

  @observable
  initialized = false;

  @observable
  isFetching = false;

  @observable
  isEditing = false;

  @observable
  isDraggable = false;

  @observable
  isResizable = false;

  @observable
  isEditingProperties = false;

  @observable
  isEditingDashboardItem = false;

  @observable
  isCloningDashboardItem = false;

  @observable
  parentPanelItem = undefined;

  selectedModels = [];

  @computed
  get dashboard() {
    return this.store.$library.reportsCollection.selected;
  }

  @action
  toggleEditing = options => {
    const { updateLayout = true } = options;
    this.isEditing = !this.isEditing;
    this.isDraggable = !this.isDraggable;
    this.isResizable = !this.isResizable;

    if (!this.isEditing && updateLayout) {
      this.dashboard.updateLayout();
    }

    setTimeout(this.reflowItems, 50);

    // Persist the editing state with this route so refresh doesn't reset it
    this.history.replace(this.history.location.pathname, { ...this.history.location.state, isEditing: this.isEditing });
  };

  @action
  setEditingProperties = isEditingProperties => {
    this.isEditingProperties = isEditingProperties;
  };

  @action
  setEditingDashboardItem = isEditingDashboardItem => {
    this.isEditingDashboardItem = isEditingDashboardItem;
  };

  @action
  setCloningDashboardItem = isCloningDashboardItem => {
    this.isCloningDashboardItem = isCloningDashboardItem;
  };

  @action
  refresh = () => this.dashboard.get('items').each(item => item.refresh());

  @action
  reflowItems = () => this.dashboard.get('items').each(item => item.reflow());

  @action
  destroyDataViews = () => {
    const currentDashboardItems = this.dashboard && this.dashboard.get('items');
    if (currentDashboardItems && currentDashboardItems.models) {
      currentDashboardItems.each(item => item.dataview.destroy());
    }
  };

  @action
  loadDashboard(id, options = {}) {
    const { isEditing = false, isEditingProperties = false, completedInitialParametricSubmit, params } = options;

    console.info('Loading dashboard', id, options);

    this.fetchById(id).then(
      action(dashboard => {
        this.reset();
        this.isEditing = isEditing;
        this.isEditingProperties = isEditingProperties;
        this.setCompletedInitialParametricSubmit(completedInitialParametricSubmit);
        this.prepareDashboard(dashboard, params);
      })
    );
  }

  @action
  reset() {
    this.completedInitialParametricSubmit = false;
    this.initialized = false;
    this.isFetching = false;
    this.isEditing = false;
    this.isDraggable = false;
    this.isResizable = false;
    this.isEditingProperties = false;
    this.selectedModels = [];
    this.parentPanelItem = undefined;
    this.destroyDataViews();
  }

  @action
  fetchById(id, doSelect = true) {
    this.isFetching = true;
    this.initialized = false;

    // returns either an existing Dashboard from the collection, or creates a new Dashboard
    // if we landed on a direct link to the dashboard.

    const dashboardId = parseInt(id, 10);

    let dashboard = this.store.$dashboards.getDashboard(dashboardId);
    if (!dashboard) {
      dashboard = this.store.$library.reportsCollection.forgeDashboard({ id: dashboardId });
    }

    dashboard.select();

    return dashboard.fetch().then(
      action(() => {
        if (doSelect) {
          return dashboard.generateItemLayout().then(
            action(dash => {
              this.isFetching = false;
              return dash;
            })
          );
        }

        this.isFetching = false;
        return Promise.resolve(dashboard);
      })
    );
  }

  /**
   * When new query options are selected from the Dashboard sidebar, we handle that here
   * and handle updating all of the individual Dashboard items with new query data.
   */
  @action
  handleQueryUpdate = query => {
    this.propagateQuery(query);
  };

  handleDashboardSave = () => {
    const { itemsWithSelectedModels } = this.dashboard;
    const values = this.formState.getValues();
    const parametric = this.dashboard.get('parametric');
    const parametric_value_options = this.dashboard.get('parametric_value_options');
    const isPredefinedValueType = this.dashboard.get('parametric_value_type') === 'predefined';
    const lookupParametricTypes = [
      'country',
      'cdn',
      'network_boundary',
      'connectivity_type',
      'device',
      'site',
      'provider'
    ];

    if (parametric) {
      if (
        isPredefinedValueType &&
        lookupParametricTypes.indexOf(this.dashboard.get('parametric_fields')[0].type) === -1
      ) {
        this.setCompletedInitialParametricSubmit(parametric_value_options.indexOf(values.parametric_value) !== -1);
      }

      if (
        values.parametric_fields &&
        values.parametric_fields[0].type === this.dashboard.get('parametric_fields')[0].type
      ) {
        this.dashboard.set({ parametric_fields: values.parametric_fields });
      }
    }

    if (itemsWithSelectedModels.length) {
      itemsWithSelectedModels.forEach(item => item.clearSelectedModels());
      this.setQueryFilterSelections();
    } else {
      this.handleQueryUpdate();
    }
  };

  @action
  addDashboardItem = (panel_type, attributes = {}, keepDashboard = true, srcDashboardItem) => {
    const { id, dashboard, dashboard_id, panel_type: attrPanelType, panel_title, ...rest } = attributes;
    const options = {};
    if (srcDashboardItem) {
      const { viewModel } = srcDashboardItem.dataview;
      if (viewModel) {
        options.viewModel = viewModel.duplicate();
      }
    }

    const positionData = {};
    if (keepDashboard) {
      positionData.y = this.dashboard.newItemY;
    }

    this.dashboard.get('items').forge(
      {
        ...rest,
        panel_title: srcDashboardItem ? `[COPY] ${panel_title}` : undefined,
        dashboard: keepDashboard ? this.dashboard : undefined,
        dashboard_id: keepDashboard ? this.dashboard.id : undefined,
        panel_type,
        ...positionData
      },
      options
    );

    this.setEditingDashboardItem(true);
  };

  // Note: this logic depends on always fetching a fresh copy of a dashboard model
  // when loading since it mutates the collection. Any use of the query in the Dashboards
  // view will be impacted
  @action
  prepareDashboard(dashboard, params) {
    const {
      location: { state }
    } = this.history;

    if (dashboard.isPreset) {
      const device_types = dashboard.get('query').device_types || [];
      Object.assign(dashboard.get('query'), {
        all_devices: !device_types.length,
        device_name: [],
        device_labels: [],
        device_sites: [],
        device_types
      });
    }
    let overrides = {};

    if (state) {
      const { completedInitialParametricSubmit, initialParametricFields, ...queryOverrides } = state;
      if (completedInitialParametricSubmit && initialParametricFields && initialParametricFields.length) {
        dashboard.set({ parametric_fields: initialParametricFields });
      }
      if (queryOverrides) {
        overrides = queryOverrides;
      }
    }

    if (!this.store.$app.isExport) {
      overrides.time_format = USER_TIMEZONE;
    }

    if (params) {
      const { parametric_fields, ...parsedParams } = JSON.parse(decodeURIComponent(params));
      if (Array.isArray(parametric_fields) && parametric_fields.length) {
        dashboard.set({ parametric_fields });
      }
      overrides = { ...overrides, ...parsedParams };
    }

    // In case of legacy saved queries, we need to ensure all_selected is not defined.
    if (Object.keys(overrides).length) {
      overrides.all_selected = undefined;
    }

    this.propagateQuery(overrides);

    this.initialized = true;
  }

  @action
  applyQuery() {
    this.dashboard.itemsWithSelectedModels.forEach(dashboardItem => {
      dashboardItem.clearSelectedModels();
    });

    this.setQueryFilterSelections(this.parentPanelItem);
  }

  clearFilterGroups(groups, filterFields) {
    return (
      Array.isArray(groups) &&
      groups.forEach(group => {
        if (Array.isArray(group.filters)) {
          group.filters = group.filters.filter(filter => !filterFields.includes(filter.filterField));
        }

        return group.filterGroups ? this.clearFilterGroups(group.filterGroups, filterFields) : undefined;
      })
    );
  }

  async augmentQueryForCustomDimensions(item, reserializedQuery) {
    const { custom_dimensions } = this.store.$auth.activeUser.userGroup.config;
    if (custom_dimensions && custom_dimensions.length) {
      return getQueryForHash(item.get('saved_query_id')).then(query => {
        const filtersObj = { ...query.filters_obj };

        const filterMapping = custom_dimensions.reduce((acc, customDimension) => {
          const mappingEntry = acc[customDimension.dimension];
          if (mappingEntry) {
            acc[customDimension.dimension] = {
              filterValue: `${mappingEntry.filterValue},${customDimension.populator}`
            };
          } else {
            acc[customDimension.dimension] = { filterValue: customDimension.populator };
          }
          return acc;
        }, {});

        replaceFilterField(filtersObj, filterMapping);

        return { ...reserializedQuery, filters: filtersObj };
      });
    }
    return { ...reserializedQuery };
  }

  @action
  propagateQuery(query = {}, options = {}) {
    const { targetDashboardItem = undefined } = options;
    const dashboardQuery = this.dashboard.get('query');
    // Note: this logic depends on always fetching a fresh copy of a dashboard model
    // when loading since it mutates the collection. Any use of the query in the Dashboards
    // view will be impacted
    if (this.dashboard.isIncompleteParametric) {
      return;
    }

    if (this.dashboard.get('parametric') && query.filters_obj) {
      const parametric_fields = this.dashboard.get('parametric_fields');
      const [field] = parametric_fields;
      const { type: parametricFieldType } = field;

      const dictionaryOption = this.store.$dictionary.parametricDashboardFilterOptions.find(
        option => option.type === parametricFieldType
      );
      if (dictionaryOption) {
        const { filterFields } = dictionaryOption;
        this.clearFilterGroups(query.filters_obj.filterGroups, filterFields);
      }
    }

    this.history.replace(this.history.location.pathname, {
      ...this.history.location.state,
      completedInitialParametricSubmit: this.dashboard.isParametric,
      initialParametricFields: this.dashboard.get('parametric_fields')
    });

    const newQuery = Object.assign({}, dashboardQuery, query);
    const reserializedQuery = QueryModel.create(newQuery).serialize();

    const items = this.dashboard.get('items');
    if (targetDashboardItem) {
      items.get(targetDashboardItem.get('id')).updateQuery(reserializedQuery);
    } else {
      items.each(item => {
        if (this.store.$app.isSubtenant) {
          this.augmentQueryForCustomDimensions(item, reserializedQuery).then(newReserializedQuery => {
            item.updateQuery(newReserializedQuery);
          });
        } else {
          item.updateQuery(reserializedQuery);
        }
      });
    }

    this.dashboard.query = reserializedQuery;
  }

  clearSelectedModels(dashboardItem) {
    dashboardItem.clearSelectedModels();
    if (dashboardItem.get('panel_filtering')) {
      this.setQueryFilterSelections();
    }
  }

  @action
  selectModel = (model, dashboardItem, shouldNavigate) => {
    const isNavigationEnabled = dashboardItem.get('dashboard_navigation');
    const hasDashboardDestination = isNavigationEnabled && dashboardItem.get('destination_dashboard');
    const isParametric =
      this.dashboard.get('parametric') && dashboardItem.get('dashboard_navigation_options.parametric') === 'drillDown';
    const key = model.get('key');
    const isParentPanel = dashboardItem === this.parentPanelItem;
    const { selectedModels } = dashboardItem;
    const idx = selectedModels.findIndex(selectedModel => selectedModel.get('key') === key);

    if (!isParametric || !isParentPanel) {
      if (idx >= 0) {
        selectedModels.splice(idx, 1);
      } else {
        selectedModels.push(model);
      }
    }

    if (hasDashboardDestination && !isParentPanel) {
      if (shouldNavigate) {
        const parametric = dashboardItem.get('dashboard_navigation_options.parametric');
        this.setCompletedInitialParametricSubmit(parametric !== 'prompt');

        this.store.$dashboards.navigateToNestedDashboard(this.dashboard, dashboardItem);
        return;
      }
      dashboardItem.dataview.setSelectedModels(selectedModels);
    }

    if (isParentPanel) {
      dashboardItem.selectedModels = [model];
      dashboardItem.dataview.setSelectedModels([model]);
      if (isParametric) {
        this.applyParametricValue(model, dashboardItem);
      }

      this.setQueryFilterSelections(isParametric ? undefined : dashboardItem);
    } else {
      clearTimeout(this.queryDebouncer);
      if (dashboardItem.get('panel_filtering')) {
        this.dashboard
          .get('items')
          .filter(item => item !== dashboardItem, { immutable: true })
          .forEach(item => {
            item.dataview.eachBucket('setLoading', [true]);
          });

        this.queryDebouncer = setTimeout(() => this.setQueryFilterSelections(), 500);
      } else {
        dashboardItem.dataview.setSelectedModels(selectedModels);
      }
    }
  };

  applyParametricValue(model, dashboardItem) {
    const parametric_fields = this.dashboard.get('parametric_fields');
    const [field] = parametric_fields;

    const value = dashboardItem.getParametricKeyFromModel(model, field);
    if (value) {
      this.dashboard.set({
        parametric_fields: [Object.assign({}, field, { value })]
      });

      const { dashboardNavigationHistory } = this.history.location.state;
      dashboardNavigationHistory[dashboardNavigationHistory.length - 1].selectedModels = [value];

      this.history.replace(this.history.location.pathname, {
        ...this.history.location.state,
        initialParametricFields: this.dashboard.get('parametric_fields'),
        dashboardNavigationHistory
      });
    }
  }

  getGeneratorModelFilters(item, model) {
    const { formState } = this;
    const modelDataview = model.collection.dataview;
    const modelMetrics = modelDataview.queryBuckets.activeBuckets[0].firstQuery
      .get('metric')
      .filter(m => m !== 'Traffic');

    // merge root item metrics and subpanel metrics together (excluding Total/Traffic)
    const metric = item.queryBuckets[0].firstQuery.get('metric').concat(modelMetrics);
    // get lookup values so we can merge these together
    const modelLookup = modelMetrics.length > 0 ? model.get('lookup') || model.get('key') : '';
    const lookup = modelDataview.lookup || modelDataview.key;

    // fake out getModelFilters by giving it what it needs to create proper filter sets
    return getModelFilters({
      model: new BaseModel({ lookup: `${lookup}${modelLookup ? ' ---- ' : ''}${modelLookup}` }),
      bucket: { firstQuery: new BaseModel({ metric, filters: {} }) },
      formState
    });
  }

  setQueryFilterSelections(parentPanelItem) {
    // Clear out existing filters (created via selected models)
    const existingFilterGroups = this.formState.getValue('filters.filterGroups');
    this.formState.setValue('filters.filterGroups', existingFilterGroups.filter(group => group.autoAdded !== true));
    const updatedItems = [];
    // Get current filter values (those not removed)
    let { filters: filters_obj } = this.formState.getValues();

    if (parentPanelItem) {
      parentPanelItem.selectedModels.forEach(m => {
        if (parentPanelItem.dataview.viewType === 'generator') {
          filters_obj = this.getGeneratorModelFilters(parentPanelItem, m);
        } else {
          filters_obj = getModelFilters({
            model: m,
            bucket: parentPanelItem.queryBuckets[0],
            formState: this.formState
          });
        }
        this.formState.getField('filters.filterGroups').init(filters_obj.filterGroups);
        this.formState.getField('filters.connector').init(filters_obj.connector);
        parentPanelItem.dataview.setSelectedModels(parentPanelItem.selectedModels);
      });

      // Apply filter to subviews (i.e. submit form)
      this.propagateQuery({ filters_obj });
    }

    this.dashboard.itemsWithSelectedModels.forEach(dashboardItem => {
      const { dataview, selectedModels } = dashboardItem;
      selectedModels.forEach(m => {
        if (dataview.viewType === 'generator') {
          filters_obj = this.getGeneratorModelFilters(dashboardItem, m);
        } else {
          filters_obj = getModelFilters({
            model: m,
            bucket: dashboardItem.queryBuckets[0],
            formState: this.formState
          });
        }
        this.formState.getField('filters.filterGroups').init(filters_obj.filterGroups);
        this.formState.getField('filters.connector').init(filters_obj.connector);
      });
      this.dashboard
        .get('items')
        .filter(item => item.get('id') !== dashboardItem.get('id') && item !== this.parentPanelItem, {
          immutable: true
        })
        .forEach(targetDashboardItem => {
          updatedItems.push(targetDashboardItem);
          this.propagateQuery({ filters_obj }, { targetDashboardItem });
        });

      dataview.setSelectedModels(selectedModels);
    });

    this.dashboard
      .get('items')
      .filter(item => !item.selectedModels.length && !updatedItems.includes(item), { immutable: true })
      .forEach(nonSelectedModelItem => {
        this.propagateQuery({ filters_obj }, { targetDashboardItem: nonSelectedModelItem });
      });

    // Reset form state so no UnappliedChanges
    this.formState.init(this.formState.getValues());
  }

  setInPlaceSelections() {
    this.dashboard.get('items').each(item => {
      if (item === this.controlPanel) {
        item.dataview.setSelectedModels(this.selectedModels);
      } else {
        item.dataview.setVisibleModels(this.selectedModels);
      }
    });
  }

  @action
  setCompletedInitialParametricSubmit(completed) {
    this.completedInitialParametricSubmit = completed;
  }

  @action
  registerFormState(form) {
    this.formState = form;
  }
}

export default new DashboardStore();
