import { computed } from 'mobx';

import Plan from '../plans/Plan';
import PlanHistoryCollection from './PlanHistoryCollection';

/**
 * Endpoints under `/sudo` do not require jsonRoot because the API doesn't
 * use the same services.
 */
export default class SudoPlan extends Plan {
  get jsonRoot() {
    return false;
  }

  get urlRoot() {
    return `/api/sudo/${this.get('company_id')}/plans`;
  }

  get removalConfirmText() {
    return {
      title: 'Expire Plan',
      text: 'Are you sure you want to expire this plan?',
      confirmButtonText: 'Expire'
    };
  }

  deserialize(plan) {
    plan = super.deserialize(plan);
    if (plan) {
      plan.history = new PlanHistoryCollection([], { companyId: plan.company_id, planId: plan.id });
    }

    return plan;
  }

  @computed
  get numDevices() {
    const { companyDevices } = this.collection;
    if (!companyDevices) {
      return 'UNK';
    }

    return companyDevices.reduce((count, device) => {
      if (device.plan_id === this.get('id') && device.device_status === 'V') {
        return count + 1;
      }
      return count;
    }, 0);
  }

  /**
   * Plans are not "updated" in the standard sense. An update creates a new plan, which
   * we can then rely on the "edate" to determine when a plan was "ended".
   */
  @computed
  get planEnded() {
    const { edate, active } = this.get();
    return edate && !active ? edate : undefined;
  }
}
