import $alertingMitigation, { MANUAL_MITIGATION_DUMMY_IP } from 'stores/$alertingMitigation';
import $auth from 'stores/$auth';

import BaseModel from '../BaseModel';

class ManualMitigation extends BaseModel {
  get defaults() {
    return {
      pairingID: null,
      action: 'start_mitigation',
      alertKey: '',
      comment: '',
      minutesBeforeAutoStop: 0
    };
  }

  serialize(data) {
    const dataToSerialize = {
      action: data.action,
      alertKey: data.alertKey,
      comment: `[${$auth.activeUser.user_name} manually started] ${data.comment}`,
      minutesBeforeAutoStop: data.minutesBeforeAutoStop
    };

    const pmModel = $alertingMitigation.platformMethods.get(data.pairingID);
    if (pmModel) {
      const platformID = pmModel.get('mitigation_platform_id');
      const methodID = pmModel.get('mitigation_method_id');
      const mitigationMethod = $alertingMitigation.methods.find({ id: methodID });

      dataToSerialize.platformID = platformID;
      dataToSerialize.methodID = methodID;

      if (mitigationMethod.get('method_mitigation_device_type') === 'Flowspec') {
        const {
          flowSpec: { src_ip, dst_ip }
        } = mitigationMethod.get('method_mitigation_device_detail');

        if ((src_ip.enabled && !src_ip.infer) || (dst_ip.enabled && !dst_ip.infer)) {
          dataToSerialize.alertKey = MANUAL_MITIGATION_DUMMY_IP;
        }
      }
    }

    return super.serialize(dataToSerialize);
  }
}

export default ManualMitigation;
