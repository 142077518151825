import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

const defaultInputStyle = {
  width: 250
};

const Search = props => {
  const {
    onChange,
    className,
    placeholder = 'Filter',
    autoFocus = true,
    style = defaultInputStyle,
    disabled = false,
    searchState = '',
    type = 'search',
    ...rest
  } = props;

  return (
    <div style={style}>
      <div className={classNames('pt-input-group', 'pt-fill', className)}>
        <span className="pt-icon pt-icon-search" />
        <input
          className="pt-input"
          autoFocus={autoFocus}
          type={type}
          placeholder={placeholder}
          dir="auto"
          disabled={disabled}
          onChange={onChange}
          value={searchState}
          {...rest}
        />
      </div>
    </div>
  );
};

export default observer(Search);
