import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Menu, MenuDivider, MenuItem, Tooltip } from '@blueprintjs/core';

import { DropdownMenu } from 'components';
import DashboardItem from 'models/dashboards/DashboardItem';
import DashboardDetailsDialog from 'views/Dashboards/DashboardDetailsDialog';
import DashboardPanelFormDialog from 'views/Dashboards/DashboardPanelFormDialog';

import ExportMenuItem from './options/ExportMenuItem';
import ViewSqlMenuItem from './options/ViewSqlMenuItem';
import ShowApiCallDialog from './options/ShowApiCallDialog';
import ShareViewDialog from './options/ShareViewDialog';

@inject('$auth', '$dashboards', '$library', '$rawFlow')
@observer
export default class OptionsMenu extends Component {
  state = {
    showApiJsonDialogOpen: false,
    showChartApiCurlDialogOpen: false,
    showDataApiCurlDialogOpen: false,
    addToDashboardDialogOpen: false,
    shareUrlDialogOpen: false
  };

  toggleDialog(e, name) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ [name]: !this.state[name] });
  }

  toggleShowChartApiCurl = e => {
    this.toggleDialog(e, 'showChartApiCurlDialogOpen');
  };

  toggleShowDataApiCurl = e => {
    this.toggleDialog(e, 'showDataApiCurlDialogOpen');
  };

  toggleShowApiJson = e => {
    this.toggleDialog(e, 'showApiJsonDialogOpen');
  };

  toggleShareUrlDialog = e => {
    this.toggleDialog(e, 'shareUrlDialogOpen');
  };

  createDashboardItem = () => {
    const { dataview } = this.props;

    if (dataview.hasUpdateFrequency) {
      dataview.setUpdateFrequency();
    }

    // create a new DashboardItem model that we'll manipulate in the Dialog. We default the
    // `title` to whatever the current DataView title is.
    const dashboardItem = new DashboardItem();

    dashboardItem.set({
      panel_title: dataview.title || 'My Dashboard Panel',
      saved_query_id: dataview.hash,
      queries: [dataview.queryBuckets.selectedQuery]
    });
    return dashboardItem;
  };

  handleAddToDashboard = () => {
    const { reportsCollection } = this.props.$library;
    const dashboardItem = this.createDashboardItem();

    const newDash = reportsCollection.forgeDashboard({ dash_title: 'My New Dashboard', share_level: 'self' });
    reportsCollection.select(newDash);

    this.setState({ addToNewDashoardDialogOpen: true, dashboardItem });
  };

  handleAddToDashboardItem = () => {
    const dashboardItem = this.createDashboardItem();

    this.setState({ addToDashboardDialogOpen: true, dashboardItem });
  };

  onAddNewDashboardSubmit = dashboard => {
    this.props.$dashboards.navigateToDashboard(dashboard);
  };

  openInRawFlowViewer = () => {
    this.props.$rawFlow.openQuery(this.props.dataview.queryBuckets.selectedQuery);
    this.props.history.push('/analytics/rawFlow');
  };

  onClose = () => {
    this.setState({
      showApiJsonDialogOpen: false,
      showChartApiCurlDialogOpen: false,
      showDataApiCurlDialogOpen: false,
      addToDashboardDialogOpen: false,
      addToNewDashoardDialogOpen: false,
      shareUrlDialogOpen: false
    });
  };

  renderOptionsMenu() {
    return (
      <Menu>
        <MenuItem iconName="plus" text="Add To New Dashboard" onClick={this.handleAddToDashboard} />
        <MenuItem iconName="new-grid-item" text="Add To Existing Dashboard" onClick={this.handleAddToDashboardItem} />
        <MenuDivider />
        <ExportMenuItem {...this.props} />
        {this.props.$auth.hasPermission('sql.editor') && <ViewSqlMenuItem {...this.props} />}
        <MenuItem iconName="code" text="Show API Call" {...this.props}>
          <MenuItem text="For Chart" onClick={this.toggleShowChartApiCurl} />
          <MenuItem text="For Data" onClick={this.toggleShowDataApiCurl} />
          <MenuItem text="JSON input" onClick={this.toggleShowApiJson} />
        </MenuItem>
        <MenuItem iconName="share" text="Share View" onClick={this.toggleShareUrlDialog} {...this.props} />
        <MenuItem iconName="search-template" text="Explore Raw Flows" onClick={this.openInRawFlowViewer} />
      </Menu>
    );
  }

  render() {
    const { loading, $library, dataview, buttonStyle } = this.props;
    const { addToDashboardDialogOpen, addToNewDashoardDialogOpen, dashboardItem } = this.state;

    if (!dataview) {
      return null;
    }

    return (
      <DropdownMenu content={this.renderOptionsMenu()} tetherOptions={{ offset: '-8px 18px' }}>
        <span>
          <Tooltip content="View Options">
            <Button className="pt-small" disabled={loading} iconName="menu" style={buttonStyle} />
          </Tooltip>
          <ShowApiCallDialog {...this.props} {...this.state} onClose={this.onClose} />
          {addToDashboardDialogOpen && (
            <DashboardPanelFormDialog
              isOpen={addToDashboardDialogOpen}
              onCancel={this.onClose}
              model={dashboardItem}
              dataview={dataview}
            />
          )}
          {addToNewDashoardDialogOpen && (
            <DashboardDetailsDialog
              isOpen={addToNewDashoardDialogOpen}
              onSubmit={this.onAddNewDashboardSubmit}
              onClose={this.onClose}
              model={$library.reportsCollection.selected}
              collection={$library.reportsCollection}
              title="New Dashboard"
              dashboardItem={dashboardItem}
            />
          )}
          <ShareViewDialog {...this.props} {...this.state} onClose={this.onClose} />
        </span>
      </DropdownMenu>
    );
  }
}
