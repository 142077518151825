import React from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import { Popover, Position } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';

import AbstractSelect from './AbstractSelect';
import ModalOptions from './ModalOptions';
import ModalSelectFooter from './ModalSelectFooter';
import DefaultValueRenderer from './DefaultValueRenderer';
import ModalSelectValuesPanel from './ModalSelectValuesPanel';
import ModalValuesPanel from './ModalValuesPanel';
import SelectFilter from './SelectFilter';

@observer
class ModalSelect extends AbstractSelect {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    closeOnSelect: false,
    filterable: true,
    filterPlaceholder: 'Search options...',
    multi: false,
    reorderable: false,
    saveButtonText: 'Save',
    valueRenderer: DefaultValueRenderer,
    valuesPanelWidth: 260
  };

  handlePopoverOpen = () => {
    if (this.filterRef) {
      setTimeout(() => this.filterRef.focus(), 10);
    }
  };

  renderPopoverContent() {
    const { multi, tools } = this.props;

    const popoverCls = classNames('modal-select modal-select-popover pt-minimal', { multi });

    return (
      <Box className={popoverCls} style={{ maxWidth: 800 }}>
        <Flex p={1} align="center" justify="space-between" flex="none">
          {tools && tools(this.selectState.filterValue)}
          <SelectFilter
            selectState={this.selectState}
            filterRef={ref => {
              this.filterRef = ref;
            }}
          />
        </Flex>
        <Flex className="pt-dialog-body" p={0}>
          {multi && <ModalSelectValuesPanel {...this.props} selectState={this.selectState} />}
          <ModalOptions {...this.props} selectState={this.selectState} padding={1} />
        </Flex>
        <ModalSelectFooter {...this.props} selectState={this.selectState} />
      </Box>
    );
  }

  render() {
    const { multi, style } = this.props;

    const tetherOptions = {
      offset: '-3px 0',
      constraints: [{ to: 'window', attachment: 'together', pin: true }]
    };

    return (
      <div className={classNames('modal-select-input modal-select-input-popover', { multi })} style={style}>
        <Popover
          popoverClassName="pt-minimal"
          content={this.renderPopoverContent()}
          position={Position.BOTTOM_LEFT}
          tetherOptions={tetherOptions}
          autoFocus={false}
          popoverDidOpen={this.handlePopoverOpen}
        >
          <ModalValuesPanel {...this.props} selectState={this.selectState} />
        </Popover>
      </div>
    );
  }
}

export default ModalSelect;
