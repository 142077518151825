import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import { Spinner } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { DashboardSelector, Field, Input, Switch, Textarea } from 'components/forms';
import MultistepTabs from 'components/MultistepTabs';

import PanelTitleHelpText from './PanelTitleHelpText';
import GuidedModeBehavior from './Form/GuidedModeBehavior';
import NavigateDashboard from './Form/NavigateDashboard';
import ItemExplorer from './Form/ItemExplorer';

@inject('$dictionary', '$dashboards', '$dashboard', '$explorer')
@observer
class DashboardItemForm extends Component {
  static defaultProps = {
    showDashboardSelect: true,
    showItemProperties: true,
    showDataviewConfiguration: true,
    activeTab: 'query',
    p: 2
  };

  componentWillMount() {
    const { $dashboards, $explorer, form, model } = this.props;
    const dashboard = $dashboards.getDashboard(form.getValue('dashboard_id'));
    const panel_type = model.get('panel_type');

    if (dashboard && dashboard.get('parametric') && panel_type === 'explorer_query') {
      form.getField('parametric_overrides.filterField').setRules('required');
      form.getField('parametric_overrides.operator').setRules('required');
    }

    if (panel_type === 'explorer_query') {
      const overrides = {};

      if (dashboard) {
        const query = dashboard.get('query');

        if (!model.get('time_locked')) {
          overrides.lookback_seconds = query.lookback_seconds;
        }

        if (!model.get('device_locked')) {
          overrides.device_name = query.device_name || [];
          overrides.device_sites = query.device_sites || [];
          overrides.device_labels = query.device_labels || [];
          overrides.device_types = query.device_types || [];
          overrides.all_devices = query.all_devices;
        }

        if (model.get('filter_source') === 'dashboard') {
          overrides.filters = query.filters;
        }
      }

      $explorer.loadView(model, false, overrides);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { model } = nextProps;

    if (model) {
      const { queries } = model.get();
      if (queries) {
        const timeLockedDisabled = queries[0].get('lookback_seconds') === 0;
        this.handleTimeLockedField(timeLockedDisabled);
      }
    }
  }

  @action
  handleTimeLockedField = timeLockedDisabled => {
    const { form } = this.props;
    const field = form.getField('time_locked');

    field.options[1].disabled = !!timeLockedDisabled;

    field.options[1].helpText = timeLockedDisabled
      ? 'This option is only enabled when the panel is set to a Lookback Time Range'
      : "The time range represented in this panel's graph will be controlled by the panel's lookback timeframe.";

    if (timeLockedDisabled) {
      field.setValue(false);
    }
  };

  /**
   * Only gets used when DashboardItem.isNew, because otherwise this Form only appears
   * in the DashboardItemDetail dialog, which never changes Dashboards.
   */
  handleDashboardChange = (field, dashboardId) => {
    const { form, $dashboards, $dictionary, model } = this.props;
    const queryFilters = $dictionary.dictionary.queryFilters;

    const selectedDashboard = $dashboards.getDashboard(dashboardId);
    const parametric = selectedDashboard.get('parametric');
    const parametricType = selectedDashboard.get('parametric_fields') && selectedDashboard.get('parametric_fields')[0];
    const { availableFilters } = this.getAvailableFiltersConfig(parametricType);
    const isExplorerQuery = model.get('panel_type') === 'explorer_query';

    form.getField('parametric_overrides.filterField').setRules(parametric && isExplorerQuery ? 'required' : '');
    form.getField('parametric_overrides.operator').setRules(parametric && isExplorerQuery ? 'required' : '');
    if (parametric) {
      form.setValue('parametric_overrides.filterField', availableFilters[0].value);
      form.setValue('parametric_overrides.operator', queryFilters.parametricOperators[parametricType.type]);
    }
  };

  getAvailableFiltersConfig = parametricType => {
    const { $dictionary, $dashboards, form, model: item } = this.props;

    const selectedDashboard = $dashboards.getDashboard(form.getValue('dashboard_id'));
    const { activeFilterFields } = item.get('queries')[0];

    const { parametric } = selectedDashboard.get();
    const { parametric_mode } = form.getValues();

    // we disable the `override_specific` option if there are no filters in this Items existing filters
    // that can be overridden by the selected parametric type
    const availableFilters = $dictionary.getAvailableFiltersForParametricType(parametricType);
    const canOverrideSpecific = availableFilters.some(filter => activeFilterFields.includes(filter.value));

    if (canOverrideSpecific) {
      if (parametric && parametric_mode === 'override_specific') {
        const filters = availableFilters.filter(filter => activeFilterFields.includes(filter.value));
        return { availableFilters: filters, canOverrideSpecific };
      }
    }

    return { availableFilters, canOverrideSpecific };
  };

  render() {
    const {
      model: dashboardItem,
      form,
      $dashboards,
      $dictionary,
      showDashboardSelect,
      showItemProperties,
      showDataviewConfiguration,
      activeTab,
      p
    } = this.props;
    const {
      filterOperatorOptions,
      standardFilterOperators,
      dictionary: { queryFilters }
    } = $dictionary;

    const parametric_mode = form.getValue('parametric_mode');
    const panel_type = dashboardItem.get('panel_type');

    if (!dashboardItem.fetchedQuery) {
      return (
        <Flex align="center" justify="center" p={p}>
          <Spinner />
        </Flex>
      );
    }

    let availableFiltersConfig;
    let parametric;
    let selectedDashboard;

    const formDashboard = form.getValue('dashboard_id');

    if (formDashboard) {
      selectedDashboard = $dashboards.getDashboard(form.getValue('dashboard_id'));
      parametric = selectedDashboard.get('parametric');

      if (parametric) {
        const parametricFields = selectedDashboard.get('parametric_fields');
        const parametricType = parametricFields && parametricFields[0];
        availableFiltersConfig = this.getAvailableFiltersConfig(parametricType);
      }
    }

    const DataViewConfigurationForm = dashboardItem.formState.component;

    const tabs = [
      {
        id: 'query',
        group: 'dataview',
        title: 'Query',
        panel: <ItemExplorer dashboard={selectedDashboard} dashboardItem={dashboardItem} />
      },
      parametric && {
        id: 'guided',
        group: 'parametric',
        title: 'Guided Mode',
        iconName: 'chat',
        panel: (
          <GuidedModeBehavior
            availableFiltersConfig={availableFiltersConfig}
            parametric_mode={parametric_mode}
            filterOperatorOptions={filterOperatorOptions}
            standardFilterOperators={standardFilterOperators}
            queryFilters={queryFilters}
            form={form}
            boxProps={{}}
          />
        )
      },
      {
        id: 'navigate',
        group: 'nestedDashboard',
        title: 'Navigate To',
        iconName: 'arrow-right',
        panel: <NavigateDashboard />
      }
    ].filter(tab => Boolean(tab));

    return (
      <Flex flexColumn flexAuto p={p}>
        <Field
          name="panel_title"
          helpText={dashboardItem.dataview.isFlowVisualization && <PanelTitleHelpText parametric={parametric} />}
        >
          <Input className="pt-large pt-fill" />
        </Field>

        <Field name="panel_description">
          <Textarea rows={2} />
        </Field>

        {panel_type === 'explorer_query' && (
          <Box className="pt-callout" p={2} mb={2}>
            <Field name="panel_filtering" className="no-margin">
              <Switch showOptionLabel />
            </Field>
          </Box>
        )}

        {dashboardItem.formState.component &&
          showDataviewConfiguration && (
            <Box className="pt-callout" p={2} mb={2}>
              <DataViewConfigurationForm form={form} dashboardItem={dashboardItem} />
            </Box>
          )}

        {showDashboardSelect && (
          <Box className="pt-card flat" p={2} mb={2}>
            <h5>Dashboard</h5>
            <p className="pt-text-muted">Select the Dashboard this new Panel will be added to</p>
            <DashboardSelector
              name="dashboard_id"
              className="no-margin"
              onChange={this.handleDashboardChange}
              onlyEditable
              showPresets={false}
              menuWidth={598}
              tetherOptions={{ constraints: [{ attachment: 'together', pin: true, to: 'window' }] }}
            />
          </Box>
        )}

        {showItemProperties &&
          panel_type === 'explorer_query' && (
            <Flex flexAuto className="pt-card flat">
              <MultistepTabs
                showNavigation={false}
                tabs={tabs}
                flexAuto
                className="overflow-hidden"
                scrollAreaStyle={{}}
                activeTabId={activeTab}
              />
            </Flex>
          )}
      </Flex>
    );
  }
}

export default DashboardItemForm;
