import React from 'react';
import { Box } from 'components/flexbox';
import { Dialog } from '@blueprintjs/core';

const AzureManualDialog = ({ resourceGroup, location, storageAccount, isOpen, onClose }) => (
  <Dialog
    title="Manual NSG Flow Log Configuration"
    isOpen={isOpen}
    onClose={onClose}
    style={{ top: 100, width: 600 }}
    canEscapeKeyClose
    transitionName="pt-dialog"
  >
    <Box p={2}>
      <p>
        Click{' '}
        <a href="https://docs.microsoft.com/en-us/azure/network-watcher/network-watcher-nsg-flow-logging-portal">
          here
        </a>{' '}
        to view Microsoft's documentation on how to enable NSG Flow Logging
      </p>
      <ol className="pt-list">
        <li>
          Verify that your subscription has a service principal for the Kentik application "Kentik NSG Flow Exporter".
        </li>
        <li>
          Grant the Kentik application 'Kentik NSG Flow Exporter' Reader role access to your
          <strong> {resourceGroup} </strong>
          resource group. This allows it to access Azure APIs for this resource group's network security groups,
          subnets, virtual machines, and network interfaces.
        </li>
        <li>
          Create a storage account named <strong>stroage_acct</strong> in the region <strong> {location} </strong>. This
          is where NSG flow logs will be configured to be written. Fetch an access key for this storage account to
          provide to Kentik. Kentik will poll the blob container
          <strong> insights-logs-networksecuritygroupflowevent </strong>
          that the NSG flow logs will be written to.
        </li>
        <li>
          Ensure that Network Watcher is registered and enabled for the location <strong> {location} </strong>.
        </li>
        <li>Register the Microsoft.Insights provider</li>
        <li>
          Enable NSG flow logging (version 2) for every network security group in the resource group
          <strong> {resourceGroup} </strong>, location <strong> {location} </strong>. Configure the flow logs to be
          written to the storage account <strong> {storageAccount.toLowerCase()} </strong> (storage accounts must be
          lowercased). We recommend a retention period of 2 days.
        </li>
      </ol>
    </Box>
  </Dialog>
);

export default AzureManualDialog;
