import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex } from 'components/flexbox';
import Icon from 'components/Icon';

import CELL_ACTIONS from './CELL_ACTIONS';
import CELL_TYPES from './CELL_TYPES';
import { getCellStyle } from './Cell';

const SelectAllCheckbox = props => {
  const { collection } = props;

  return (
    <Checkbox
      indeterminate={
        !!(
          collection.selected &&
          collection.selected.length > 0 &&
          collection.selected.length < collection.models.length
        )
      }
      style={{ margin: 0 }}
      className="pt-inline"
      key="select"
      checked={!!collection.isAllSelected}
      onChange={() => null}
      onClick={e => {
        e.stopPropagation();
        collection.selectAll();
      }}
    />
  );
};

class HeaderCell extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      field: oldField,
      direction: oldDirection,
      column: oldColumn,
      isAllSelected,
      columns,
      responsiveMode
    } = this.props;
    const {
      field: newField,
      direction: newDirection,
      column: newColumn,
      isAllSelected: nextIsAllSelected,
      responsiveModel: nextResponsiveMode,
      columns: nextColumns
    } = nextProps;

    return (
      isAllSelected !== nextIsAllSelected ||
      oldDirection !== newDirection ||
      oldField !== newField ||
      oldColumn.label !== newColumn.label ||
      responsiveMode !== nextResponsiveMode ||
      columns.length !== nextColumns.length
    );
  }

  render() {
    const { collection, column } = this.props;

    let onClick;

    const { field, direction } = collection.sortState;
    const sortable = column.sortable !== false && column.type !== CELL_TYPES.ACTION;
    const serverSortable = column.serverSortable === true && column.type !== CELL_TYPES.ACTION;
    const sorted = field && field === column.name;

    const selectAllCheckbox = (column.actions && column.actions.includes(CELL_ACTIONS.SELECT)) || column.customSelect;

    if (sortable) {
      if (serverSortable) {
        onClick = () => {
          collection.serverSort(column.sortField || column.name);
        };
      } else {
        onClick = () => {
          collection.sort(column.sortField || column.name);
        };
      }
    }

    const className = classNames('th', { sortable, sorted });
    const style = getCellStyle(this.props);
    const align = column.headerAlign || column.align;

    return (
      <Flex
        className={className}
        onClick={onClick}
        title={column.title}
        style={style}
        align="flex-end"
        justify={align === 'right' ? 'flex-end' : align}
      >
        {selectAllCheckbox && <SelectAllCheckbox collection={collection} />}
        {column.label || ''}
        {sorted && <Icon name={direction === 'asc' ? 'chevron-up' : 'chevron-down'} />}
        {!sorted &&
          !selectAllCheckbox &&
          sortable &&
          align !== 'right' && <div style={{ width: 16, display: 'inline-block' }} />}
      </Flex>
    );
  }
}

export default observer(HeaderCell);
