import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Intent, Popover, Position } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { MaterialIcon } from 'components/Icon';

import SidebarSectionWarnings from './SidebarSectionWarnings';

const tetherOptions = {
  offset: '-6px -5px'
};

@observer
class SidebarSection extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    hasChanges: true,
    readOnly: false
  };

  // DOM node reference of the Collapsed mode Popover for this section
  popoverContents = undefined;

  handleEditClick = () => {
    const { sidebar, sectionName } = this.props;
    if (this.props.onEdit && !sidebar.collapsed) {
      this.props.onEdit();
    } else {
      sidebar.toggleEditingSection(sectionName);
    }
  };

  handleToggleCollapsedSection = () => {
    const { sidebar, sectionName } = this.props;
    sidebar.toggleActiveSection(sectionName);
  };

  handleCancel = () => {
    const { form } = this.context;
    const { fieldGroup, sidebar, sectionName } = this.props;

    // need to reset the particular Form group back so we don't have unsaved
    // changes hanging around
    form.fieldGroups[fieldGroup].forEach(fieldName => {
      const field = form.getField(fieldName);
      if (field) {
        field.reset();
      }
    });

    sidebar.toggleEditingSection(sectionName);
  };

  // Since we're controlling the Popover `isOpen` manually, we need to handle the generic use cases of ESC
  // and clicking outside of the Popover

  handlePopoverSectionClose = e => {
    const { sidebar } = this.props;

    // ESC
    if (e.keyCode === 27) {
      sidebar.toggleActiveSection();
    }

    // if this function is called with any target, it means we clicked outside the Popover
    const existingDialog = document.querySelector('.pt-dialog');
    const existingPopovers = document.querySelectorAll('.pt-popover');

    let existingPopover;
    if (existingPopovers.length > 1) {
      existingPopover = existingPopovers.item(existingPopovers.length - 1);
    }

    if (e && e.target) {
      const isMenuItem = e.target.classList.contains('pt-menu-item');
      const isScrollContent =
        e.target.classList.contains('scrollarea-content') || e.target.classList.contains('scrollbar');
      const isDialog =
        document.querySelector('.pt-dialog-container') || (existingDialog && existingDialog.contains(e.target));
      const isPopover = existingPopover && existingPopover.contains(e.target);

      if (isMenuItem || isScrollContent || isDialog || isPopover) {
        return;
      }

      // general clicks that are outside of the popover close.
      if (!this.popoverContents.contains(e.target)) {
        sidebar.toggleActiveSection();
      }
    }
  };

  handleCollapsedSubmit = e => {
    e.stopPropagation();

    const { sidebar, onSubmit } = this.props;
    onSubmit();
    sidebar.toggleActiveSection();
  };

  handleSectionRef = ref => {
    if (ref) {
      this.popoverContents = ref;
    }
  };

  isDirty() {
    const { form } = this.context;
    const { isDirty, fieldGroup } = this.props;

    if (isDirty) {
      return isDirty(form);
    }

    return form.isGroupDirty(fieldGroup);
  }

  isEditing() {
    const { sidebar, sectionName } = this.props;
    return sidebar && sidebar.editingSections.get(sectionName);
  }

  render() {
    const { form } = this.context;
    const { fieldGroup, label, sectionName, iconCls, children, className, displayView, readOnly, sidebar } = this.props;

    const isSectionDirty = this.isDirty();
    const isEditing = this.isEditing();

    // in collapsed mode, which section's Popover is open
    const isOpen = sidebar && sidebar.activeOpenSection === sectionName;
    const isSidebarCollapsed = sidebar && sidebar.collapsed;
    const sectionClassName = classNames(className, {
      'read-only': readOnly,
      'display-view': !isEditing,
      collapsed: sidebar && sidebar.collapsed
    });

    let btnText = 'Edit';
    if (isEditing) {
      btnText = isSectionDirty ? 'Cancel' : 'Show Summary';
    }

    const btnClass = classNames('pt-small', 'pt-minimal', 'sidebar-edit-btn', {
      editing: isEditing
    });

    const sidebarSectionContent = (
      <section
        className={sectionClassName}
        onClick={!isEditing && !readOnly ? this.handleEditClick : undefined}
        ref={this.handleSectionRef}
      >
        <Flex flexColumn col={12} className="title">
          <Flex align="center" flexAuto>
            {!isSidebarCollapsed && <MaterialIcon name={iconCls} className="sidebar-section-icon" />}
            <Box flexAuto className="section-label">
              {label}
            </Box>

            {!readOnly &&
              (!isSidebarCollapsed || sectionName === 'filters') && (
                <Button
                  intent={Intent.PRIMARY}
                  className={btnClass}
                  onClick={!isEditing ? undefined : this.handleCancel}
                  text={btnText}
                />
              )}
          </Flex>

          {!isEditing && displayView && <Box mt={1}>{React.cloneElement(displayView, { sidebar })}</Box>}
          {(isEditing || !displayView) &&
            React.Children.map(children, child => <Box mt={1}>{React.cloneElement(child, { sidebar })}</Box>)}

          {isSidebarCollapsed &&
            form.dirty && (
              <Flex>
                <Button
                  style={{ marginTop: 8 }}
                  onClick={this.handleCollapsedSubmit}
                  className="pt-medium"
                  text="Run Query"
                  disabled={!form.valid}
                  intent={Intent.PRIMARY}
                />
              </Flex>
            )}

          <SidebarSectionWarnings fieldGroup={fieldGroup} sidebar={sidebar} isSectionDirty={isSectionDirty} />
        </Flex>
      </section>
    );

    // if a section has a defined `onEdit`, that means we just defer to whatever it does (Filters)
    // and not show a popover.
    const sidebarMenuButton = (
      <Popover
        isOpen={isOpen}
        position={Position.RIGHT_TOP}
        onClose={this.handlePopoverSectionClose}
        popoverClassName="sidebar sidebar-section-popover pt-minimal"
        tetherOptions={tetherOptions}
      >
        <Button
          className="pt-fill sidebar-section-button"
          active={isOpen}
          intent={isOpen ? Intent.WARNING : null}
          onClick={this.handleToggleCollapsedSection}
        >
          <MaterialIcon name={iconCls} className="md-24 sidebar-section-icon" />
        </Button>

        {sidebarSectionContent}
      </Popover>
    );

    return isSidebarCollapsed ? sidebarMenuButton : sidebarSectionContent;
  }
}

export default SidebarSection;
