import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';
import { orderBy } from 'lodash';

import { Flex, Box } from 'components/flexbox';

import ShortcutButton from './ShortcutButton';

@observer
class ShortcutGroup extends Component {
  static defaultProps = {
    showAddAllButton: false,
    showClearButton: true,
    expandable: true,
    filterable: true,
    mb: 2
  };

  state = {
    expanded: false
  };

  componentWillMount() {
    const { field } = this.props;
    const expanded = field.getValue().length;
    this.setState({ expanded });
  }

  handleResetGroup = () => {
    const { field } = this.props;
    field.setValue([]);
  };

  handleAddAll = () => {
    const { filterable, searchInputValue, field, options: unfilteredOptions, onAdd } = this.props;
    const options = filterable
      ? unfilteredOptions.filter(opt => opt.label.toLowerCase().includes(searchInputValue.toLowerCase()))
      : unfilteredOptions;

    options.forEach(opt => onAdd(opt, field, true));
  };

  handleToggleExpanded = () => this.setState({ expanded: !this.state.expanded });

  render() {
    const {
      title,
      noResultsText = 'No results',
      options: unfilteredOptions,
      iconName,
      onAdd,
      field,
      type,
      selectedValues,
      disabled,
      filterable,
      expandable,
      showClearButton,
      searchInputValue,
      mb
    } = this.props;
    const { expanded } = this.state;

    const filteredOptions = filterable
      ? unfilteredOptions.filter(opt => opt.label.toLowerCase().includes(searchInputValue.toLowerCase()))
      : unfilteredOptions;
    const options = orderBy(filteredOptions, ['label'], ['asc']);

    const showAddAll = searchInputValue && options.length > 0;
    const showClear = showClearButton && selectedValues.length > 0 && !searchInputValue;
    const showExpandButton = expandable && options.length > 5 && !expanded;

    return (
      <Box mb={mb} flexAuto>
        {title && (
          <Flex align="center" justify="space-between" style={{ height: 25 }}>
            <small className="pt-strong small-caps">{title}</small>
            <div style={{ marginTop: -1 }}>
              {showAddAll && (
                <Button
                  className="pt-small pt-minimal pt-intent-primary"
                  onClick={this.handleAddAll}
                  disabled={options.every(opt => !!selectedValues.find(val => val.id === opt.id))}
                >
                  Add All
                </Button>
              )}
              {showClear && (
                <Button className="pt-small pt-minimal pt-intent-primary" onClick={this.handleResetGroup}>
                  Clear
                </Button>
              )}
            </div>
          </Flex>
        )}
        {options.length === 0 && <small className="pt-text-muted">{noResultsText}</small>}

        {options &&
          options.filter(Boolean).map((opt, index) => {
            const selected = selectedValues.find(sel => sel.value === opt.value);

            const button = (
              <ShortcutButton
                {...opt}
                disabled={disabled}
                active={selected}
                iconName={opt.iconName || iconName}
                key={opt.value}
                onClick={() => onAdd(opt, field)}
                text={opt.label}
                type={type}
              />
            );

            if (expandable === false) {
              return button;
            }

            if (expanded || index <= 4) {
              return button;
            }

            return null;
          })}
        {showExpandButton && (
          <Button
            className={classNames('pt-small pt-minimal pt-fill pt-text-left pt-text-muted')}
            disabled={disabled}
            onClick={this.handleToggleExpanded}
            text={`Show ${options.length - 5} more...`}
          />
        )}
      </Box>
    );
  }
}

export default ShortcutGroup;
