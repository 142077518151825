import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import $devices from 'stores/$devices';

import DeviceValueRenderer from './DeviceValueRenderer';
import DeviceValuesRenderer from './DeviceValuesRenderer';
import Field from '../Field';
import { ModalSelect } from '../select';
import RadioGroup from '../RadioGroup';

@observer
class DeviceSelector extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    allDevicesField: 'all_devices',
    fieldName: 'device_name',
    readOnly: false,
    showEditLink: false,
    showLabel: false,
    showAllDevices: true,
    deviceFilter: undefined
  };

  state = {
    isEditing: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isEditing !== this.props.isEditing) {
      this.setState({ isEditing: nextProps.isEditing });
    }
  }

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  onToggleAllDevices = ({ value }) => {
    const { form } = this.context;
    const { fieldName, allDevicesDevicesValue } = this.props;
    const field = form.getField(fieldName);
    const devices = field.getValue();

    if (value) {
      field.setRules('');
      field.setValue(allDevicesDevicesValue ? [allDevicesDevicesValue] : []);
    } else {
      field.setPristine(false);
      field.setRules('required');
      field.setValue(allDevicesDevicesValue ? devices.filter(device => device !== allDevicesDevicesValue) : devices);
      this.toggleEditing();
    }
  };

  handleDevicesChange = () => {
    const { form } = this.context;
    const { fieldName, allDevicesField } = this.props;

    if (!form.getValue(allDevicesField)) {
      const field = form.getField(fieldName);
      field.setPristine(false);
      field.setRules('required');
    }
  };

  getConvenienceButton({ baseFilter, filterValue, text, iconName, fieldName, disabled }) {
    const { form } = this.context;
    const field = form.getField(fieldName);
    const { deviceValue, deviceFilter } = this.props;

    let { deviceSummaries } = $devices;

    if (deviceFilter) {
      deviceSummaries = deviceSummaries.filter(deviceFilter);
    }

    if (baseFilter) {
      const lowerCaseBaseFilter = baseFilter.toLowerCase();
      deviceSummaries = deviceSummaries.filter(
        device =>
          device.device_name.toLowerCase().includes(lowerCaseBaseFilter) ||
          (device.title && device.title.toLowerCase().includes(lowerCaseBaseFilter))
      );
    }

    let numDevices = deviceSummaries.length;

    if (filterValue === 'NONE') {
      numDevices = null;
    } else if (filterValue && filterValue !== 'ALL') {
      numDevices = deviceSummaries.filter(device => device.device_type === filterValue).length;
    }

    // hides filter buttons when there are no matching
    if (filterValue && numDevices === 0) {
      return null;
    }

    const className = classNames('device-selector', filterValue);
    const onClick = () => {
      let selectedDevices;
      if (filterValue === 'NONE') {
        field.setPristine(false);
        field.setRules('required');
        selectedDevices = [];
      } else {
        selectedDevices = form.getValue(fieldName) || [];
        let filteredDevices = deviceSummaries;
        if (filterValue && filterValue !== 'ALL') {
          filteredDevices = deviceSummaries.filter(device => device.device_type === filterValue);
        }
        const devicesToSelect = filteredDevices.map(({ id, device_name }) => (deviceValue === 'id' ? id : device_name));
        // union currently selected and devices to select
        devicesToSelect.forEach(value => {
          if (!selectedDevices.some(selectedDevice => selectedDevice.toString() === value.toString())) {
            selectedDevices.push(value);
          }
        });
      }

      form.setValue(fieldName, selectedDevices);
    };

    return (
      <Button disabled={disabled} className={className} iconName={iconName} onClick={onClick}>
        {text} {numDevices > 0 && <span className="pt-text-muted pt-normal">({numDevices})</span>}
      </Button>
    );
  }

  getTools = baseFilter => {
    const { fieldName } = this.props;

    return (
      <div className="pt-button-group">
        {this.getConvenienceButton({
          text: 'All',
          iconName: 'asterisk',
          filterValue: 'ALL',
          fieldName,
          baseFilter
        })}
        {this.getConvenienceButton({
          text: 'None',
          iconName: 'remove',
          filterValue: 'NONE',
          fieldName,
          baseFilter
        })}
        {this.getConvenienceButton({
          text: 'nProbe',
          iconName: 'send-to',
          filterValue: 'host-nprobe-basic',
          fieldName,
          baseFilter
        })}
        {this.getConvenienceButton({
          text: 'kprobe (beta)',
          iconName: 'cloud',
          filterValue: 'host-nprobe-dns-www',
          fieldName,
          baseFilter
        })}
        {this.getConvenienceButton({
          text: 'Routers',
          iconName: 'dev-router-plain',
          filterValue: 'router',
          fieldName,
          baseFilter
        })}
      </div>
    );
  };

  renderDeviceOptions(style) {
    const { form } = this.context;
    const {
      fieldName,
      allDevicesField,
      deviceValue,
      showEditLink,
      readOnly,
      onClose,
      dialogRef,
      dialogStyle,
      required,
      deviceFilter
    } = this.props;

    const { isEditing } = this.state;

    const all_devices = form.getField(allDevicesField);
    const isAllDevicesSelected = all_devices && all_devices.getValue();

    const boxStyle = {
      paddingLeft: all_devices && !all_devices.getValue() ? 26 : 0,
      cursor: readOnly ? 'none' : 'pointer'
    };

    const rules = !isAllDevicesSelected && required ? 'required' : undefined;

    return (
      <div style={boxStyle} onClick={readOnly ? null : this.toggleEditing}>
        <Field
          {...this.props}
          className="no-margin"
          isEditing={isEditing}
          name={fieldName}
          noEditLink={!showEditLink}
          options={$devices.getDeviceOptions(deviceValue, deviceFilter)}
          rules={rules}
          style={style}
          toggleEditing={onClose || this.toggleEditing}
          onChange={this.handleDevicesChange}
        >
          <ModalSelect
            title="Selected Devices"
            multiValuesRenderer={DeviceValuesRenderer}
            valueRenderer={DeviceValueRenderer}
            tools={this.getTools}
            filterPlaceholder="Search Devices..."
            selectedValuesTitle="Selected Devices"
            noSelectedValuesText="No devices selected"
            dialogStyle={dialogStyle}
            dialogRef={dialogRef}
            className="legacy-device-selector"
            multi
          />
        </Field>
      </div>
    );
  }

  render() {
    const { form } = this.context;
    const { showAllDevices, allDevicesField, className, readOnly } = this.props;

    const allDevices = form.getField(allDevicesField);

    if (showAllDevices && allDevices) {
      return (
        <div className={classNames('device-options', className)}>
          <Field
            {...this.props}
            field={allDevices}
            style={{ marginBottom: 0 }}
            onChange={this.onToggleAllDevices}
            disabled={readOnly}
          >
            <RadioGroup inline={false} />
          </Field>
          {!allDevices.getValue() && this.renderDeviceOptions()}
        </div>
      );
    }

    return this.renderDeviceOptions();
  }
}

export default DeviceSelector;
