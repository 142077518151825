import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { PageHeader } from 'components/page';
import { Flex, Box } from 'components/flexbox';
import UnappliedChanges from 'components/sidebar/UnappliedChanges';
import CategoryHeading from 'views/Library/CategoryHeading';
import FavoriteButton from 'views/Library/FavoriteButton';

import DashboardToolbar from './DashboardToolbar';
import DashboardDescription from './DashboardDescription';
import DashboardAddPanel from './DashboardAddPanel';
import DashboardNavigationBar from './DashboardNavigationBar';

@inject('$dashboard')
@observer
class DashboardHeader extends Component {
  render() {
    const { $dashboard, navigationHistory, parentPanel } = this.props;
    const { dashboard, formState, isFetching } = $dashboard;

    const title = (
      <Flex align="flex-end">
        <div>
          {navigationHistory && <DashboardNavigationBar dashboard={dashboard} navigationHistory={navigationHistory} />}
          {!navigationHistory && (
            <Box mb={1}>
              <CategoryHeading item={dashboard} />
            </Box>
          )}
          {!navigationHistory && (
            <h3 className="pt-strong">
              {dashboard.get('dash_title')}
              <FavoriteButton report={dashboard} />
            </h3>
          )}
        </div>
      </Flex>
    );

    const dataviews = (dashboard.get('items') || []).map(item => item.dataview);
    const showAddItem = dashboard.canEdit && !isFetching && (!dashboard.hasItems || $dashboard.isEditing);

    const extraControlsClassName = classNames('dashboard-extra-controls', {
      transparent: !parentPanel
    });

    return (
      <Fragment>
        <PageHeader
          parent="Dashboards"
          title={title}
          subnavSection={false}
          style={{ paddingRight: 16, paddingLeft: 16 }}
          subSection={
            <Fragment>
              <DashboardDescription />
              {dashboard &&
                !dashboard.isIncompleteParametric && (
                  <UnappliedChanges
                    form={formState}
                    buttonText="Apply"
                    dataviews={dataviews}
                    mx={2}
                    mt={1}
                    style={{ width: 'auto' }}
                    flexAuto
                  />
                )}
            </Fragment>
          }
        >
          {dashboard && <DashboardToolbar dashboard={dashboard} {...this.props} />}
        </PageHeader>

        <Box px={2} className={extraControlsClassName}>
          {showAddItem && <DashboardAddPanel {...this.props} />}
        </Box>
      </Fragment>
    );
  }
}

export default DashboardHeader;
