import { action, observable, computed } from 'mobx';
import { omit } from 'lodash';
import { getHashForQueries } from 'services/urlHash';
import { nestFilterGroup } from 'util/utils';
import { showErrorToast } from 'components/Toast';
import { MOMENT_FN, DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

import QueryBucketCollection from 'models/query/QueryBucketCollection';
import QueryModel from 'models/query/QueryModel';
import $dictionary from 'stores/$dictionary';
import $dataviews from 'stores/$dataviews';
import $devices from 'stores/$devices';

import AbstractDataViewModel from './AbstractDataViewModel';
import ExplorerQueryModel from './query/ExplorerQueryModel';

export default class DataViewModel extends AbstractDataViewModel {
  @observable
  queryBuckets = new QueryBucketCollection();

  @observable
  hash;

  @observable
  hashSource;

  @observable
  timeOverride = null;

  @observable
  resetTimeConfig = [];

  @observable
  fullyLoaded = false;

  @observable
  formState;

  @observable
  preventQuery = false;

  @computed
  get hasTimeReset() {
    return this.resetTimeConfig.length > 0;
  }

  @computed
  get hasUpdateFrequency() {
    return !!this.queryBuckets.updateFrequency;
  }

  setUpdateFrequency(frequency = 0) {
    this.queryBuckets.activeBuckets.forEach(bucket => (bucket.updateFrequency = frequency));
    this.saveAndReinitialize();
  }

  @computed
  get viewType() {
    /* commenting and leaving for posterity; Dan figured it out on 03/20/2018
    if ($auth.isDan && Math.random() < 0.1) {
      const viewTypes = $dataviews.viewTypeOptions.filter(
        viewType =>
          viewType.value !== 'sankey' &&
          viewType.value !== 'matrix' &&
          viewType.value !== 'geoHeatMap' &&
          viewType.value !== 'alertScoreboard'
      );
      const randIndex = Math.floor(Math.random() * viewTypes.length) + 1;
      return viewTypes[randIndex - 1].value;
    } */

    return this.queryBuckets.viewType;
  }

  set viewType(viewType) {
    const overrides = { viz_type: viewType };
    // type specific logic
    // matrix
    if (viewType !== 'matrix') {
      overrides.matrixBy = [];
    }

    if (viewType === 'line') {
      overrides.show_total_overlay = false;
    }

    // table (and not table)
    if (viewType === 'table') {
      overrides.topx = 100;
      overrides.show_total_overlay = false;
    } else if (this.queryBuckets.selectedQuery.get('viz_type') === 'table') {
      overrides.topx = 8;
    }

    // general logic for show overlays
    const {
      allowsSecondaryOverlay,
      buckets,
      showTotalTrafficOverlay,
      showHistoricalOverlay,
      suppressSecondaryTopxSeparate,
      suppressBracketing,
      suppressGeneratorMode,
      timeBased
    } = $dataviews.getConfig(viewType);
    if (!allowsSecondaryOverlay) {
      overrides.secondaryOutsort = '';
    }
    if (showTotalTrafficOverlay === false) {
      overrides.show_total_overlay = false;
    }
    if (showHistoricalOverlay === false) {
      overrides.show_overlay = false;
    }
    if (suppressSecondaryTopxSeparate === true) {
      overrides.secondaryTopxSeparate = false;
    }
    if (suppressGeneratorMode === true) {
      overrides.generatorMode = false;
    }

    // don't keep bracket options for non-supported views as results will get tagged anyway. Kill-n-fill for now.
    if (suppressBracketing === true) {
      overrides.bracketOptions = null;
    }

    if (this.formState) {
      if (timeBased) {
        const aggTypesField = this.formState.getField('aggregateTypes');
        const aggTypes = aggTypesField.getValue();
        aggTypesField.setPristine(false);
        overrides.aggregateTypes = aggTypes.filter(agg => !agg.startsWith('agg_total'));
      }
      if (this.formState.getValue('secondaryOutsort')) {
        this.formState.getField('mirror').setPristine(false);
        this.formState.getField('secondaryOutsort').setPristine(false);
      }

      overrides.secondaryTopxMirrored =
        this.formState.getValue('secondaryTopxMirrored') &&
        buckets.some(bucket => bucket.secondaryMirrorBucket !== undefined);

      this.formState.setValues(overrides);
    } else {
      overrides.secondaryTopxMirrored =
        this.queryBuckets.selectedQuery.get('secondaryTopxMirrored') &&
        buckets.some(bucket => bucket.secondaryMirrorBucket !== undefined);

      this.applyToAllBuckets(overrides);
    }
  }

  @computed
  get loading() {
    return !this.preventQuery && this.queryBuckets.loading;
  }

  @computed
  get appliedFilters() {
    let query = this.queryBuckets.selectedQuery;
    if (!query && this.queryBuckets.activeBucketCount) {
      query = this.queryBuckets.activeBuckets[0].firstQuery || QueryModel.create();
    }
    return query.serialize().filters_obj;
  }

  @computed
  get lastUpdated() {
    return this.queryBuckets.lastUpdated;
  }

  @computed
  get title() {
    return this.queryBuckets.title;
  }

  get isLegacyMDS() {
    return this.queryBuckets.serialize().length > 1;
  }

  reflow() {
    if (this.component && this.component.reflow) {
      this.component.reflow();
    }
  }

  @action
  destroy() {
    this.hash = null;
    this.resetTimeConfig = [];
    this.timeOverride = null;
    this.formState = null;
    this.clear();
  }

  clear() {
    this.eachBucket('unsubscribe');
  }

  refresh() {
    this.eachBucket('refresh');
  }

  gotoDefaultDash(overrides) {
    if (!this.history) {
      return;
    }

    const {
      all_devices,
      device_labels,
      device_name,
      device_sites,
      device_types,
      filters,
      lookback_seconds,
      starting_time,
      ending_time
    } = this.queryBuckets.selectedQuery.get();

    const json = Object.assign(
      {
        all_devices,
        device_labels,
        device_name,
        device_sites,
        device_types,
        filters,
        lookback_seconds,
        starting_time,
        ending_time
      },
      overrides
    );

    const { defaultExplorerDashboard } = $dictionary.dictionary;

    window.open(
      `/library/dashboard/${defaultExplorerDashboard}/urlParams/${encodeURIComponent(JSON.stringify(json))}`,
      '_blank'
    );
  }

  @action
  updateTimeRange(starting, ending) {
    if (!this.queryBuckets.activeBucketCount) {
      return;
    }

    const query = this.queryBuckets.activeBuckets[0].firstQuery;
    const { lookback_seconds, starting_time, ending_time } = query.get();
    let originalWidth = lookback_seconds;
    const start = Math.floor(starting / 1000);
    const end = Math.floor(Math.min(ending, Date.now()) / 1000);
    const width = end - start;

    if (originalWidth === 0) {
      originalWidth = MOMENT_FN(query.ending_time).diff(MOMENT_FN(query.starting_time), 'seconds');
    }

    if (width !== originalWidth) {
      this.resetTimeConfig.push({
        lookback_seconds,
        starting_time,
        ending_time,
        time_override: !!this.resetTimeConfig.length
      });

      this.timeOverride = {
        lookback_seconds: 0,
        update_frequency: 0,
        starting_time: MOMENT_FN(start * 1000)
          .utc()
          .format(DEFAULT_DATETIME_FORMAT),
        ending_time: MOMENT_FN(end * 1000)
          .utc()
          .format(DEFAULT_DATETIME_FORMAT),
        time_override: true
      };

      this.eachBucket('applyToEachQuery', [this.timeOverride]);
      this.saveAndReinitialize();
    }
  }

  @action
  resetTimeRange(empty = false) {
    if (empty && this.timeOverride) {
      this.timeOverride = null;
      this.resetTimeConfig = [];
    } else if (this.resetTimeConfig.length) {
      this.timeOverride = this.resetTimeConfig.pop();
      this.eachBucket('applyToEachQuery', [this.timeOverride]);
      this.saveAndReinitialize();
    }
  }

  overlayFilters(filters, parametricOverrides) {
    if (filters) {
      if (filters.connector === 'Any') {
        nestFilterGroup(filters);
      }
      this.queryBuckets.activeBuckets.forEach(bucket => {
        bucket.queries.each(query => {
          let filters_obj;
          if (parametricOverrides && parametricOverrides.filters_obj) {
            filters_obj = parametricOverrides.filters_obj;
          } else {
            filters_obj = query.get('filters_obj');
          }

          if (filters_obj && filters_obj.filterGroups && filters_obj.filterGroups.length) {
            if (filters_obj.connector === 'Any') {
              nestFilterGroup(filters_obj);
            }

            filters_obj.filterGroups = (filters.filterGroups || []).concat(filters_obj.filterGroups);

            /**
             * If override_specific, go through filters_obj and override any of the filterFields
             * which matched what the user selected
             * */
            if (parametricOverrides && parametricOverrides.specific) {
              filters_obj.filterGroups.forEach(group => {
                group.filters.forEach(filter => {
                  if (filter.filterField === parametricOverrides.filterField) {
                    filter.filterValue = parametricOverrides.filterValue;
                  }
                });
              });
            }

            query.set({ filters_obj });
          } else {
            query.set({ filters_obj: filters });
          }
        });
      });
    }
  }

  createExplorerQueryModelFromSelectedQuery() {
    return ExplorerQueryModel.createFromQueryModel(this.queryBuckets.selectedQuery);
  }

  createQueryModelFromExplorerQueryModel(explorerQueryModel) {
    if (this.timeOverride) {
      explorerQueryModel.set(this.timeOverride);
    }
    return QueryModel.create(explorerQueryModel.serialize());
  }

  @action
  applyToAllQueries(options, suppressFetch, normalizeDepth) {
    const explorerQuery = this.createExplorerQueryModelFromSelectedQuery();
    explorerQuery.set(options);
    const overrides = this.createQueryModelFromExplorerQueryModel(explorerQuery).serialize();

    const fieldsToOmit = ['bucket', 'bucketIndex', 'descriptor'];
    if (!options.query_title) {
      fieldsToOmit.push('query_title');
    }

    // special logic to only override filters when explicitly provided
    // we may need to add more here in the future, not sure... this is mostly for legacy MDS support
    if (!options.filters && !options.filters_obj) {
      fieldsToOmit.push('filters', 'filters_obj');
    }

    this.eachBucket('applyToEachQuery', [omit(overrides, fieldsToOmit)]);
    this.saveAndReinitialize(suppressFetch, normalizeDepth);
  }

  @action
  applyToAllBuckets(options) {
    const explorerQuery = this.createExplorerQueryModelFromSelectedQuery();
    explorerQuery.set(options);
    const overrides = this.createQueryModelFromExplorerQueryModel(explorerQuery).serialize();
    this.queryBuckets.each(
      bucket =>
        bucket.firstQuery &&
        bucket.firstQuery.set(omit(overrides, ['bucket', 'bucketIndex', 'descriptor', 'query_title']))
    );
    this.saveAndReinitialize();
  }

  @action
  applyToSelectedQuery(explorerQuery) {
    this.queryBuckets.selectedQuery.set(this.createQueryModelFromExplorerQueryModel(explorerQuery).serialize());
    this.saveAndReinitialize();
  }

  @action
  saveAndReinitialize(suppressFetch, normalizeDepth) {
    this.queryBuckets.save().then(hash => {
      this.eachBucket('unsubscribe');
      this.initializeHash(hash, 'apply', suppressFetch, normalizeDepth);
    });
  }

  @action
  initializeHashWithOverrides(hash, overrides, syncDepth = false, overlayFilters = true, parametricOverrides) {
    this.queryBuckets.fetch({ query: { key: hash }, preserveSelection: true }).then(() => {
      const fieldsToOmit = [];
      if (overlayFilters) {
        this.overlayFilters(overrides.filters_obj, parametricOverrides);
        fieldsToOmit.push('filters_obj');
      }
      if (syncDepth) {
        this.queryBuckets.activeBuckets.forEach(bucket =>
          bucket.queries.each(query => {
            let depth = Math.min(1000, query.get('depth'));
            if (!['matrix', 'table'].includes(query.get('viz_type'))) {
              const topx = query.get('topx');
              depth = topx <= 20 ? topx * 2 : topx;
            }
            query.set({ depth });
          })
        );
      }

      if (!this.preventQuery) {
        this.applyToAllQueries(omit(overrides, fieldsToOmit), false, false);
      }
    });
  }

  @action
  initializeHash(hash, source, suppressFetch = false, normalizeDepth = true) {
    this.hashSource = source || 'init';
    this.hash = hash;

    if (suppressFetch !== true) {
      this.queryBuckets.fetch({ query: { key: hash }, preserveSelection: true }).then(() => {
        const { selectedQuery } = this.queryBuckets;
        if (normalizeDepth === true) {
          const topx = selectedQuery.get('topx');
          const depthThresholds = [10, 20, 75, 125, 200, 350];
          const topxThresholds = [1, 4, 8, 16, 25, 40];
          let depth = 100;
          topxThresholds.forEach((threshold, index) => {
            if (topx >= threshold) {
              depth = depthThresholds[index];
            }
          });
          if (selectedQuery.get('viz_type') === 'table') {
            depth = topx;
          }
          selectedQuery.set({ depth });
        }

        // @TODO: revisit this, because I'm not sure it's necessary anymore?
        const hasDevices = $devices.deviceSummaries.length > 0;
        const { all_devices, device_name, device_labels, device_types, device_sites } = selectedQuery.get();

        // has at least one of the required fields
        if (
          hasDevices &&
          (all_devices ||
            device_name.length > 0 ||
            device_labels.length > 0 ||
            device_types.length > 0 ||
            device_sites.length > 0)
        ) {
          this.eachBucket('subscribe');
        } else {
          this.queryBuckets.activeBuckets.forEach(bucket => bucket.setLoading(false));
          showErrorToast('You must select at least one device.');
        }
      });
    }
  }

  initializeQueries(queries, suppressFetch = false) {
    getHashForQueries(queries).then(hash => this.initializeHash(hash, 'init', suppressFetch));
  }

  setQuery(query, save = false) {
    this.queryBuckets.set([{ query }]);
    if (save === true) {
      this.queryBuckets.save().then(action(hash => (this.hash = hash)));
    }
    this.eachBucket('subscribe');
  }

  @action
  eachBucket(method, params = []) {
    this.queryBuckets.each(bucket => bucket[method](...params));
  }

  @action
  setFullyLoaded() {
    this.fullyLoaded = true;
  }

  @action
  registerFormState(form) {
    this.formState = form;
  }
}
