import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import $devices from 'stores/$devices';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['kt_cloud_provider'],
  filterFields: ['kt_cloud_provider']
};

class CloudDependency extends AbstractDependency {
  get key() {
    return 'cloud';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get preventQuery() {
    return true;
  }

  get meets() {
    return $devices.hasAWSDevice || $devices.hasGCEDevice || $devices.hasAzureDevice;
  }

  get message() {
    return (
      <Fragment>
        <Box className="pt-text-smaller" mb={0.5}>
          <strong>Cloud</strong> devices are required by dimensions you're using, but have not been detected for any
          devices in your organization. Results shown herein may be highly inaccurate.
        </Box>
        {this.showConfigureButton && (
          <Button iconName="cog" className="pt-small" onClick={() => $devices.store.history.push('/admin/clouds')}>
            Configure
          </Button>
        )}
      </Fragment>
    );
  }

  static queryMeets(query) {
    return (
      !queryContains(query, CONSTRAINTS) || $devices.hasAWSDevice || $devices.hasGCEDevice || $devices.hasAzureDevice
    );
  }
}

export default CloudDependency;
