import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$alertingMitigation')
class MitigationMethods extends Component {
  title = 'Mitigation Method';

  url = '/alerting/mitigations/methods';

  render() {
    const { result, $alertingMitigation } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="wrench"
        url={this.url}
        nameField="method_name"
        collection={$alertingMitigation.methods}
        {...this.props}
      >
        <span>type: {result.method_mitigation_device_type}</span>
      </ResultRow>
    );
  }
}

export default MitigationMethods;
