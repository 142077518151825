import React, { Component } from 'react';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { Flex, Box } from 'components/flexbox';
import { PageNavMenu } from 'components/page';
import ShimHeader from 'components/ShimHeader';
import getErrorBoundary from 'util/components/getErrorBoundary';
import NotFound from 'views/NotFound';

import ProfileFormWrapper from './ProfileFormWrapper';
import ProfileSettings from './ProfileSettings';
import AuthSettings from './AuthSettings';
import KdeSettings from './KdeSettings';
import Visualizations from './Visualizations';

const profileLinks = [
  {
    divider: true,
    title: 'Profile'
  },
  {
    to: '/profile4/info',
    text: 'User Information',
    iconName: 'user'
  },
  {
    to: '/profile4/settings',
    text: 'User Settings',
    iconName: 'settings',
    materialIcon: true
  },
  {
    to: '/profile4/authentication',
    text: 'Authentication',
    iconName: 'fingerprint',
    materialIcon: true
  },
  {
    to: '/profile4/visualizations',
    text: 'Visualizations',
    iconName: 'timeline-area-chart'
  }
];

const navWidth = 225;
const bodyStyle = {
  width: `calc(100vw - ${navWidth}px)`
};

const ErrorBoundaryCmp = getErrorBoundary('Profile');

@inject('$app', '$auth', '$users')
@observer
class Profile extends Component {
  componentWillMount() {
    const { $users } = this.props;

    $users.fetchFullProfile();
    $users.fetchOtps();
  }

  getProfileLinks() {
    const {
      $app: { isSubtenant },
      $auth
    } = this.props;

    if (!isSubtenant && $auth.hasPermission('sql.psql')) {
      return [
        ...profileLinks,
        {
          to: '/profile4/kde',
          text: 'Kentik Data Engine (KDE)',
          iconName: 'view_comfy',
          materialIcon: true
        }
      ];
    }

    return profileLinks;
  }

  render() {
    const {
      $auth,
      $app: { isSubtenant }
    } = this.props;

    return (
      <Box>
        <ShimHeader />
        <Flex>
          <PageNavMenu links={this.getProfileLinks()} width={navWidth} />
          <Box className="admin-v4-shim" style={bodyStyle}>
            <ErrorBoundaryCmp>
              <Switch>
                <Route exact path="/profile4" render={() => <Redirect to="/profile4/info" />} />
                <Route path="/profile4/info" component={ProfileFormWrapper} />
                <Route path="/profile4/settings" component={ProfileSettings} />
                <Route path="/profile4/authentication" component={AuthSettings} />
                <Route path="/profile4/visualizations" component={Visualizations} />
                {!isSubtenant &&
                  $auth.hasPermission('sql.psql') && <Route path="/profile4/kde" component={KdeSettings} />}
                <Route path="*" component={NotFound} />
              </Switch>
            </ErrorBoundaryCmp>
          </Box>
        </Flex>
      </Box>
    );
  }
}

export default withRouter(Profile);
