import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Flex, Box } from 'components/flexbox';
import { Checkbox } from '@blueprintjs/core';

import LiveUpdateButton from './tools/LiveUpdateButton';
import LastUpdatedTag from './tools/LastUpdatedTag';

@observer
class DataViewFooter extends Component {
  handleToggleSyncAxes = e => {
    const { dataview, $explorer } = this.props;
    const sync_axes = e.target.checked;
    dataview.component.syncAxes(sync_axes);
    dataview.queryBuckets.selectedQuery.set({ sync_axes });
    dataview.saveAndReinitialize(true);
    $explorer.formState.getField('sync_axes').init(sync_axes);
  };

  handleToggleUseLogAxis = e => {
    const { dataview, $explorer } = this.props;
    const use_log_axis = e.target.checked;
    dataview.component.useLogAxis(use_log_axis);
    dataview.queryBuckets.selectedQuery.set({ use_log_axis });
    dataview.saveAndReinitialize(true);
    $explorer.formState.getField('use_log_axis').init(use_log_axis);
  };

  handleToggleUseSecondaryLogAxis = e => {
    const { dataview, $explorer } = this.props;
    const use_secondary_log_axis = e.target.checked;
    dataview.component.useSecondaryLogAxis(use_secondary_log_axis);
    dataview.queryBuckets.selectedQuery.set({ use_secondary_log_axis });
    dataview.saveAndReinitialize(true);
    $explorer.formState.getField('use_secondary_log_axis').init(use_secondary_log_axis);
  };

  handleToggleSyncExtents = e => {
    const { dataview, $explorer } = this.props;
    const sync_extents = e.target.checked;
    dataview.component.syncExtents(sync_extents);
    dataview.queryBuckets.selectedQuery.set({ sync_extents });
    dataview.saveAndReinitialize(true);
    $explorer.formState.getField('sync_extents').init(sync_extents);
  };

  handleToggleSyncAllAxes = e => {
    const { dataview, $explorer } = this.props;
    const sync_all_axes = e.target.checked;
    dataview.component.syncAllAxes(sync_all_axes);
    dataview.queryBuckets.selectedQuery.set({ sync_all_axes });
    dataview.saveAndReinitialize(true);
    $explorer.formState.getField('sync_all_axes').init(sync_all_axes);
  };

  handleShowSiteMarkers = e => {
    const { dataview, $explorer } = this.props;
    const show_site_markers = e.target.checked;
    if (dataview.viewType === 'geoHeatMap') {
      dataview.component.showSiteMarkers(show_site_markers);
      dataview.queryBuckets.selectedQuery.set({ show_site_markers });
      dataview.saveAndReinitialize(true);
      $explorer.formState.getField('show_site_markers').init(show_site_markers);
    }
  };

  render() {
    const {
      size: dataViewSize,
      dataview,
      showSyncAxes,
      showUseLogAxis,
      showSyncExtents,
      showLiveUpdate,
      showLastUpdated,
      showSiteMarkers
    } = this.props;
    const loading = dataview.loading || !dataview.lastUpdated;
    const { selectedQuery, activeBucketCount } = dataview.queryBuckets;

    const displaySyncAxes =
      showSyncAxes && (activeBucketCount > 1 || selectedQuery.get('secondaryOutsort') || selectedQuery.get('mirror'));

    if (dataViewSize.height <= 150) {
      return null;
    }

    return (
      <Flex className="pt-small">
        {displaySyncAxes && (
          <Box pt={0.5}>
            <Checkbox
              checked={selectedQuery.get('sync_axes')}
              disabled={loading}
              label="Sync Axes"
              style={{ marginBottom: 0 }}
              onChange={this.handleToggleSyncAxes}
            />
          </Box>
        )}
        {showSyncAxes &&
          selectedQuery.get('generatorMode') && (
            <Box pt={0.5}>
              <Checkbox
                checked={selectedQuery.get('sync_all_axes')}
                disabled={loading}
                label="Sync Axes Across Panels"
                style={{ marginBottom: 0 }}
                onChange={this.handleToggleSyncAllAxes}
              />
            </Box>
          )}
        {showUseLogAxis && (
          <Box pt={0.5} ml={1.25}>
            <Checkbox
              checked={selectedQuery.get('use_log_axis')}
              disabled={loading}
              label={`Use Logarithmic Scale${displaySyncAxes ? ' on Primary Axis' : ''}`}
              style={{ marginBottom: 0 }}
              onChange={this.handleToggleUseLogAxis}
            />
          </Box>
        )}
        {displaySyncAxes &&
          showUseLogAxis && (
            <Box pt={0.5} ml={1.25}>
              <Checkbox
                checked={selectedQuery.get('use_secondary_log_axis')}
                disabled={loading}
                label="Use Logarithmic Scale on Secondary Axis"
                style={{ marginBottom: 0 }}
                onChange={this.handleToggleUseSecondaryLogAxis}
              />
            </Box>
          )}
        {showSyncExtents && (
          <Box pt={0.5} ml={1.25}>
            <Checkbox
              checked={selectedQuery.get('sync_extents')}
              disabled={loading}
              label="Sync Density Scale Across Series"
              style={{ marginBottom: 0 }}
              onChange={this.handleToggleSyncExtents}
            />
          </Box>
        )}
        {showSiteMarkers && (
          <Box pt={0.5} ml={1.25}>
            <Checkbox
              checked={selectedQuery.get('show_site_markers')}
              disabled={loading}
              label="Show Site Markers"
              style={{ marginBottom: 0 }}
              onChange={this.handleShowSiteMarkers}
            />
          </Box>
        )}
        <Flex flexAuto align="center" justify="flex-end">
          {showLastUpdated && <LastUpdatedTag {...this.props} loading={loading} />}
          {showLiveUpdate && <LiveUpdateButton {...this.props} loading={loading} />}
        </Flex>
      </Flex>
    );
  }
}

export default DataViewFooter;
