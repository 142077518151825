import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Dialog, Button, Intent } from '@blueprintjs/core';
import DataViewWrapper from 'dataviews/DataViewWrapper';
import DataViewTools from 'dataviews/DataViewTools';
import { AppliedFiltersButton, ChangeViewTypeMenu } from 'dataviews/tools/index';
import { Flex, Box } from 'components/flexbox';

@inject('$app')
export default class ViewInExplorerDialog extends Component {
  static defaultProps = {
    showDialogTitle: true,
    showAppliedFilters: true,
    dialogTitle: 'Related Flow Data'
  };

  handleClose = () => {
    const { dataview, onClose } = this.props;
    dataview.destroy();
    if (onClose) {
      onClose(dataview);
    }
  };

  render() {
    const { $app, isOpen, dataview, dialogTitle, showDialogTitle, showAppliedFilters } = this.props;

    const dataViewTools = (
      <DataViewTools dataview={dataview}>
        {({ Wrapper, ...rest }) => (
          <Wrapper>
            {showAppliedFilters && <AppliedFiltersButton {...rest} />}
            <ChangeViewTypeMenu {...rest} />
          </Wrapper>
        )}
      </DataViewTools>
    );

    return (
      <Dialog
        title={showDialogTitle ? dialogTitle : null}
        canEscapeKeyClose
        isOpen={isOpen}
        style={{ height: '60vh', width: '80vw' }}
        onClose={this.handleClose}
        transitionName="pt-dialog"
      >
        <Flex className="pt-card flat" m={2} flexAuto>
          <DataViewWrapper
            dataview={dataview}
            viewProps={{
              showNativeLegend: true
            }}
            headerProps={{
              showTitleLink: false,
              tools: dataViewTools
            }}
          >
            {({ component, header, className }) => (
              <Flex className={className} flexAuto flexColumn style={{ overflow: 'hidden' }}>
                <Box p={2}>{header}</Box>
                {component}
              </Flex>
            )}
          </DataViewWrapper>
        </Flex>
        <Flex className="pt-dialog-footer" justify="flex-end" style={{ paddingTop: 0 }}>
          <Button text="Close" onClick={this.handleClose} />
          <Button
            className="pt-medium"
            intent={Intent.PRIMARY}
            onClick={() => window.open(`${$app.isV4 ? '/v4/core' : ''}/explorer/${dataview.hash}`, '_blank')}
            style={{ marginLeft: 8 }}
            text="View in Explorer"
          />
        </Flex>
      </Dialog>
    );
  }
}
