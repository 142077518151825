import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'components/flexbox';
import { Button, Intent } from '@blueprintjs/core';
import { Field, Select, Switch, ValidationErrorsOrHelpText, Input } from 'components/forms';
import { Card } from 'components';
import { any } from 'prop-types';
import { uniqueId } from 'lodash';
import ColorPicker from 'components/forms/ColorPicker';
import { getOutsortAggregateUnit, transformUnitValueOut } from 'forms/config/bracketOptions';
import { getDefaultRanges, colorOptions } from 'services/bracketing';
import StaticRange from './StaticRange';

const percentMinMaxRules = 'integer|min:0|max:100';
const rangeMinUnderMaxRule = 'rangeRestrictionMinUnderMax';
const rawMinMaxRules = 'numeric';
const bracketTypeHelpTextMap = {
  percentages: 'Series are evaluated by Bracketing Value and bracketed by percent of the highest value',
  percentiles: 'Series are ranked by Bracketing Value and bracketed by percentile relative to all series',
  staticRanges: 'Series are bracketed by comparing Bracketing Value to user-specified numeric ranges'
};

const updateRangeValueRules = form => {
  const type = form.getField('type').value;
  const ranges = form.getField('ranges');
  ranges.fieldStates.forEach(fieldState => {
    const { to } = fieldState;
    if (to) {
      const rules = type === 'percentages' ? `required|${percentMinMaxRules}` : 'required|numeric';
      to.setRules(rules);
    }
  });
};

const addStaticRange = (form, model) => {
  const rangesField = form.getField('ranges');
  const type = form.getField('type').value;
  const isPercentageBased = type === 'percentages' || type === 'percentiles';
  const numRanges = rangesField.size();
  const color = colorOptions[numRanges % colorOptions.length];
  const unit = isPercentageBased ? null : getOutsortAggregateUnit(model.currentQueryState);
  const ultimate = +rangesField.at(numRanges - 1).to.value;
  const penultimate = numRanges >= 2 ? +rangesField.at(numRanges - 2).to.value : 0;
  let nextValue = ultimate + (ultimate - penultimate);

  // Don't put next value over 99 if bracket type is %
  if (isPercentageBased && nextValue > 99) {
    nextValue = 99;
  }

  form.getField('ranges').add({
    to: transformUnitValueOut(unit, nextValue),
    data: { value: color }
  });

  updateRangeValueRules(form);
};

const AddStaticRangeButton = observer(props => {
  const { form, model } = props;
  const rangesField = form.getField('ranges');
  const staticRangeCount = rangesField.size();

  if (staticRangeCount >= 4 || !form.valid) {
    return null;
  }

  return (
    <Button
      text="Add Range"
      className="pt-minimal"
      intent={Intent.PRIMARY}
      onClick={() => addStaticRange(form, model)}
      iconName="plus"
    />
  );
});

@inject('$explorer', '$dictionary')
@observer
class BracketOptionsForm extends Component {
  static contextTypes = {
    form: any
  };

  componentDidMount() {
    this.updateRangeRestrictionRules();
    this.updateRangeValueRules();
  }

  updateRangeRestrictionRules = () => {
    const { form } = this.context;
    if (form.getField('rangeRestriction.enabled').value) {
      const units = form.getField('rangeRestriction.units').value;

      // update rules for min/max fields accordingly
      if (units === 'percent' || units === 'percentile') {
        // set rangeMinUnderMaxRule rule on min or max, but not both.
        form.getField('rangeRestriction.min').setRules(`${percentMinMaxRules}|${rangeMinUnderMaxRule}`);
        form.getField('rangeRestriction.max').setRules(percentMinMaxRules);
      } else {
        form.getField('rangeRestriction.min').setRules(`${rawMinMaxRules}|${rangeMinUnderMaxRule}`);
        form.getField('rangeRestriction.max').setRules(rawMinMaxRules);
      }
    } else {
      form.getField('rangeRestriction.min').setRules('');
      form.getField('rangeRestriction.max').setRules('');
    }
  };

  updateRangeValueRules = () => {
    const { form } = this.context;
    updateRangeValueRules(form);
  };

  getValueLabelFromQuery() {
    const { model, $dictionary } = this.props;
    const { currentQueryState } = model;
    const { outsort, aggregates } = currentQueryState;
    const aggregate = aggregates.find(agg => agg.value === outsort);
    // fail with '' if outsort aggregate not found
    if (!aggregate) {
      return '';
    }
    const units = aggregate.unit;
    const queryUnits = $dictionary.dictionary.units[units];
    if (units === 'bytes' || units === 'in_bytes' || units === 'out_bytes') {
      return `M${queryUnits.toLowerCase()}`;
    }
    if (units === 'packets' || units === 'in_packets' || units === 'out_packets') {
      return `K${queryUnits.toLowerCase()}`;
    }
    return queryUnits;
  }

  getValueLabel() {
    const { form } = this.props;
    const type = form.getField('type').value;
    if (type === 'percentages') {
      return '%';
    }
    if (type === 'percentiles') {
      return 'percentile';
    }
    return this.getValueLabelFromQuery();
  }

  removeStaticRange = (rangesField, index) => {
    rangesField.remove(index);
  };

  handleBracketTypeChange = bracketTypeFieldState => {
    const { form } = this.context;
    const type = bracketTypeFieldState.value;
    const ranges = form.getField('ranges');
    ranges.setValue(getDefaultRanges({ type }));
    this.updateRangeValueRules();
  };

  bracketTypeOptionValueRenderer = option => {
    const { className, label, value, field, selectItem, selected } = option;

    const onClick = !selected ? () => selectItem(field, value) : undefined;

    return (
      <div key={value} className={className} onClick={onClick}>
        <div>
          <strong>{label}</strong>
        </div>
        <span className="pt-text-muted pt-helper-text">{bracketTypeHelpTextMap[value]}</span>
      </div>
    );
  };

  render() {
    const { allowRangeRestriction, allowLastDataPoint, bracketTypeOptions, allowBracketingGroupOption } = this.props;
    const { form } = this.context;
    const rangesField = form.getField('ranges');
    const type = form.getField('type').value;
    const valueLabel = this.getValueLabel();
    const rangeRestrictionErrors = form.invalidGroups.rangeRestriction;

    // create array of staticRange components.
    let staticRangeCount = 0;
    const staticRanges = rangesField.map((range, idx, ranges) => {
      staticRangeCount += 1;
      return (
        <StaticRange
          key={uniqueId()}
          range={range}
          valueLabel={valueLabel}
          count={staticRangeCount}
          allowRemove={ranges.length > 1}
          valueMaxWidth={type === 'percentages' || type === 'percentiles' ? 50 : 125}
          onRemove={() => this.removeStaticRange(rangesField, idx)}
        />
      );
    });

    // set up range restriction select options
    const restrictionUnitsOptions = [{ value: 'percent', label: '%' }, { value: 'percentile', label: 'Percentile' }];
    restrictionUnitsOptions.push({ value: 'raw', label: this.getValueLabelFromQuery() });

    return (
      <div>
        <Card flat title="Bracketing Type" mb={1}>
          <Field
            options={bracketTypeOptions}
            name="type"
            onChange={this.handleBracketTypeChange}
            fieldStyle={{ minWidth: 125 }}
            showLabel={false}
            helpText={bracketTypeHelpTextMap[type]}
          >
            <Select optionRenderer={this.bracketTypeOptionValueRenderer} menuWidth={500} />
          </Field>
        </Card>
        {allowLastDataPoint && (
          <Card flat title="Bracketing Value" mb={1}>
            <Field mt={2} name="lastDatapoint" showLabel={false}>
              <Switch switchLabel="Use last datapoint value" />
            </Field>
          </Card>
        )}
        <Card flat title="Bracketing Ranges">
          {allowBracketingGroupOption && (
            <Field mt={2} name="groupResults" showLabel={false}>
              <Switch switchLabel="Group results" />
            </Field>
          )}
          {allowRangeRestriction && (
            <div>
              <Field
                mt={2}
                name="rangeRestriction.enabled"
                onChange={this.updateRangeRestrictionRules}
                showLabel={false}
                showHelpText={false}
              >
                <Switch switchLabel="Trim Overall Range" />
              </Field>
              {form.getField('rangeRestriction.enabled').value && (
                /* fix this css, this is not a threshold */
                <Box className="threshold">
                  <Box>
                    <Flex className="static-condition single-line-fields" style={{ alignItems: 'center' }} mb={2}>
                      <Box style={{ whiteSpace: 'nowrap' }}>Exclude values under</Box>
                      <Field
                        className="no-margin"
                        name="rangeRestriction.min"
                        inputClassName="small-number-field"
                        labelAlign="left"
                        showLabel={false}
                        showHelpText={false}
                        fieldStyle={{ maxWidth: 125 }}
                      >
                        <Input />
                      </Field>
                      <Box style={{ whiteSpace: 'nowrap' }}>or over</Box>
                      <Field
                        className="no-margin"
                        style={{ display: 'inline-block' }}
                        name="rangeRestriction.max"
                        inputClassName="small-number-field"
                        labelAlign="left"
                        showLabel={false}
                        showHelpText={false}
                        fieldStyle={{ maxWidth: 125 }}
                      >
                        <Input />
                      </Field>
                      <Field
                        options={restrictionUnitsOptions}
                        className="no-margin"
                        onChange={this.updateRangeRestrictionRules}
                        name="rangeRestriction.units"
                        fieldStyle={{ maxWidth: 125 }}
                        showLabel={false}
                        showHelpText={false}
                      >
                        <Select />
                      </Field>
                    </Flex>
                    {rangeRestrictionErrors &&
                      rangeRestrictionErrors.length > 0 && (
                        <Box
                          mb={1}
                          className="pt-tag pt-minimal pt-intent-danger"
                          style={{ textAlign: 'left', width: '100%' }}
                        >
                          <ul>
                            {rangeRestrictionErrors
                              .map(fieldName =>
                                form.getField(fieldName).errors.map(error => <li key={uniqueId()}>{error}</li>)
                              )
                              .reduce((acc, current) => {
                                acc.push(...current);
                                return acc;
                              }, [])}
                          </ul>
                        </Box>
                      )}
                  </Box>
                </Box>
              )}
              <hr />
            </div>
          )}
          {staticRanges}
          <ValidationErrorsOrHelpText calloutStyle field={rangesField} />
          <AddStaticRangeButton {...this.props} />
          <Box className="threshold" mt={2}>
            <Box>
              <Flex className="static-condition single-line-fields" mb={2}>
                <Field
                  className="no-margin"
                  field={form.getField('over')}
                  fieldStyle={{ minWidth: 125 }}
                  showLabel={false}
                  showHelpText={false}
                >
                  <ColorPicker />
                </Field>
                <Flex style={{ marginTop: 5 }}>values greater than last specified range.</Flex>
              </Flex>
            </Box>
          </Box>
        </Card>
      </div>
    );
  }
}

export default BracketOptionsForm;
