import Collection from '../Collection';
import DeviceGroup from './DeviceGroup';

class DeviceCollection extends Collection {
  get url() {
    return '/api/portal/deviceGroups';
  }

  get model() {
    return DeviceGroup;
  }

  get useAsyncAdd() {
    return true;
  }
}

export default DeviceCollection;
