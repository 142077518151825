import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import $devices from 'stores/$devices';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: [
    'ktsubtype__gcp_subnet__STR00',
    'ktsubtype__gcp_subnet__STR01',
    'ktsubtype__gcp_subnet__STR02',
    'ktsubtype__gcp_subnet__STR03',
    'ktsubtype__gcp_subnet__STR04',
    'ktsubtype__gcp_subnet__STR05',
    'ktsubtype__gcp_subnet__STR06',
    'ktsubtype__gcp_subnet__STR07',
    'ktsubtype__gcp_subnet__STR08',
    'ktsubtype__gcp_subnet__STR09',
    'ktsubtype__gcp_subnet__STR10',
    'ktsubtype__gcp_subnet__STR11'
  ],
  filterFields: [
    'ktsubtype__gcp_subnet__STR00',
    'ktsubtype__gcp_subnet__STR01',
    'ktsubtype__gcp_subnet__STR02',
    'ktsubtype__gcp_subnet__STR03',
    'ktsubtype__gcp_subnet__STR04',
    'ktsubtype__gcp_subnet__STR05',
    'ktsubtype__gcp_subnet__STR06',
    'ktsubtype__gcp_subnet__STR07',
    'ktsubtype__gcp_subnet__STR08',
    'ktsubtype__gcp_subnet__STR09',
    'ktsubtype__gcp_subnet__STR10',
    'ktsubtype__gcp_subnet__STR11'
  ]
};

class GCEDependency extends AbstractDependency {
  get key() {
    return 'gce';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $devices.hasGCEDevice;
  }

  get preventQuery() {
    return true;
  }

  get message() {
    return (
      <Fragment>
        <Box className="pt-text-smaller" mb={0.5}>
          <strong>GCP</strong> devices are required by dimensions you're using, but have not been detected for any
          devices in your organization. Results shown herein may be highly inaccurate.
        </Box>
        {this.showConfigureButton && (
          <Button iconName="cog" className="pt-small" onClick={() => $devices.store.history.push('/admin/clouds')}>
            Configure
          </Button>
        )}
      </Fragment>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $devices.hasGCEDevice;
  }
}

export default GCEDependency;
