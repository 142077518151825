import Collection from 'models/Collection';
import QueryModel from './QueryModel';

class QueryCollection extends Collection {
  get model() {
    return QueryModel;
  }

  get url() {
    return '';
  }
}

export default QueryCollection;
