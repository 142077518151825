import React from 'react';
import { observer } from 'mobx-react';
import { TimePicker } from '@blueprintjs/datetime';

import { whitelistFieldProps } from 'forms/utils';

export default observer(props => {
  const { field } = props;
  return <TimePicker {...field.getProps()} {...whitelistFieldProps(props)} />;
});
