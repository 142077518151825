import { action } from 'mobx';
import api from 'util/api';
import Collection from '../Collection';
import Rule from './Rule';

class RuleCollection extends Collection {
  get url() {
    return '/api/portal/rules';
  }

  get model() {
    return Rule;
  }

  @action
  updateRuleInterfaceMatchData(ruleInterfaceMatches) {
    const keys = Object.keys(ruleInterfaceMatches);

    if (keys.length > 0) {
      keys.forEach(key => {
        const rule = this.get(key);
        if (rule) {
          rule.set(ruleInterfaceMatches[key]);
        }
      });
    } else {
      this.each(rule =>
        rule.set({
          interfacesClassified: [],
          interfacesMatched: []
        })
      );
    }
  }

  @action
  getRuleMatchResults = () => api.get(`${this.url}/results`);
}

export default RuleCollection;
