import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import $auth from 'stores/$auth';
import ConfirmDialogModel from 'models/ConfirmDialogModel';
import { Tooltip, Button } from '@blueprintjs/core';
import { Table, CELL_ACTIONS, CELL_TYPES } from 'components/table';
import { boldFormatter, mutedFormatter } from 'util/formatters';
import { formatDateTime } from 'util/dateUtils';

@inject('$userGroups')
@observer
export default class UserTable extends Component {
  componentWillMount() {
    this.confirmDialog = new ConfirmDialogModel({ confirmButtonText: 'Yes' });
    this.props.collection.setPresetFilter({ fn: model => model.get('user_group_id') === null });
  }

  render() {
    const actions = [CELL_ACTIONS.REMOVE];
    if ($auth.isSuperAdministrator || ($auth.activeUser && $auth.activeUser.chfAdmin)) {
      actions.unshift(model => (
        <Tooltip key="resetSessions" content="Reset Active Sessions">
          <Button
            iconName="log-out"
            className="pt-minimal"
            onClick={e => {
              e.stopPropagation();
              this.confirmDialog.confirmText = `Are you sure you want to reset ${model.get(
                'user_full_name'
              )}'s sessions?`;
              this.confirmDialog.onConfirm = model.resetSessions;
              this.confirmDialog.showConfirmDialog();
            }}
            text="Reset Active Sessions"
          />
        </Tooltip>
      ));
    }

    const columns = [
      {
        responsiveOrder: 2,
        name: 'id',
        label: 'ID',
        width: 70,
        renderer: mutedFormatter
      },
      {
        responsiveOrder: 1,
        name: 'user_full_name',
        label: 'Full Name',
        renderer: boldFormatter
      },
      {
        responsiveOrder: 4,
        name: 'user_email',
        label: 'Email'
      },
      {
        responsiveOrder: 3,
        name: 'userLevel',
        label: 'Level',
        computed: true
      },
      {
        responsiveOrder: 5,
        name: 'last_login',
        label: 'Last Login',
        renderer: ({ value }) =>
          value ? formatDateTime(value) : <span className="pt-text-muted">Never logged in</span>
      },
      {
        responsiveOrder: 6,
        type: CELL_TYPES.ACTION,
        actions
      }
    ];

    if (this.props.disableActions) {
      columns.splice(-1, 1);
    }

    return (
      <div className="overflow-auto">
        <Table {...this.props} columns={columns} clearFiltersOnUnmount />
        {this.confirmDialog.component}
      </div>
    );
  }
}
