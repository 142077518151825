import { computed } from 'mobx';
import { times } from 'lodash';
import BaseModel from 'models/BaseModel';
import $dashboards from 'stores/$dashboards';

const days = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
};

const delivery_period_options = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly'
};

const weekOptions = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' }
];

const dayOptions = times(28).map(day => ({ value: day + 1, label: day + 1 }));

function getDaySuffix(day) {
  if (day % 10 === 1 && day !== 11) {
    return 'st';
  } else if (day % 10 === 2 && day !== 12) {
    return 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    return 'rd';
  }

  return 'th';
}

class Subscription extends BaseModel {
  get defaults() {
    return {
      content_type: 'dashboard',
      delivery_period: 'week',
      lookback_days: 7,
      recurrence_ordinal: 1,
      parametric_fields: [],
      parametric_value: ''
    };
  }

  deserialize(data) {
    if (data && data.content_type === 'dashboard') {
      const dashboard = $dashboards.getDashboard(data.content_id);
      if (dashboard) {
        data.parametric_value_type = dashboard.get('parametric_value_type');
        data.parametric_value_options = dashboard.get('parametric_value_options');
        if (data.group_key) {
          Object.assign(data, JSON.parse(data.group_key));
          if (data.parametric_fields && data.parametric_fields.length) {
            data.parametric_value = data.parametric_fields[0].value;
          }
        }
      }
    }
    return data;
  }

  get omitDuringSerialize() {
    return [
      'date',
      'last_run',
      'subtitle',
      'time',
      'parametric_value',
      'parametric_value_type',
      'parametric_value_options'
    ];
  }

  get urlRoot() {
    return '/api/portal/subscription/reports/email';
  }

  @computed
  get allRecipients() {
    const recipients = this.get('recipients') && this.get('recipients').split(',');
    return recipients && recipients.map(r => r.trim());
  }

  get messages() {
    return {
      create: `Subscription "${this.get('title')}" was added successfully`,
      update: `Subscription "${this.get('title')}" was updated successfully`,
      destroy: `Subscription "${this.get('title')}" was removed successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Subscription',
      text: `Are you sure you want to remove ${this.get('title')}?`
    };
  }

  @computed
  get deliveryFrequency() {
    const deliveryPeriod = this.get('delivery_period');
    const deliveryPeriodDisplay = delivery_period_options[deliveryPeriod];
    const recurrence = this.get('recurrence_ordinal');

    if (deliveryPeriod === 'week') {
      return `${deliveryPeriodDisplay} every ${days[recurrence]}`;
    } else if (deliveryPeriod === 'month') {
      return `${deliveryPeriodDisplay} on the ${recurrence}${getDaySuffix(recurrence)}`;
    }

    return deliveryPeriodDisplay;
  }

  @computed
  get lookbackDays() {
    return this.get('lookback_days') === 7 ? '1 Week' : `${this.get('lookback_days')} Days`;
  }

  recurrenceOrdinalOptions(period) {
    if (period === 'week') {
      return weekOptions;
    }
    return dayOptions;
  }
}

export default Subscription;
