import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { Card, Heading } from 'components';

import Label from './ProfileLabel';

export function getSwitchChecked(profile, field) {
  const value = profile[field];
  const customFns = {
    userHistoryState: val => val !== 'OFF',
    userDnsLookup: val => val !== 'OFF',
    userTimezone: val => val !== 'Local'
  };

  return customFns[field] ? customFns[field](value) : !!value;
}

export function getSwitchValue(profile, field) {
  const value = !getSwitchChecked(profile, field);

  const customFns = {
    userHistoryState: val => (val ? 'ON' : 'OFF'),
    userDnsLookup: val => (val ? 'ON' : 'OFF'),
    userTimezone: val => (val ? 'UTC' : 'Local')
  };

  return customFns[field] ? customFns[field](value) : value;
}

export function getSwitchLabel(profile, field) {
  const value = profile[field];
  const customFns = {
    userHistoryState: val => (val !== 'OFF' ? 'On' : 'Off'),
    userDnsLookup: val => (val !== 'OFF' ? 'On' : 'Off'),
    userTimezone: val => (val === 'UTC' ? 'UTC' : 'Local')
  };

  if (customFns[field]) {
    return customFns[field](value);
  }

  return value ? 'On' : 'Off';
}

const labels = {
  email_service: 'Service Updates',
  email_product: 'Product Updates',
  bgpNotification: 'BGP Session Events',
  userHistoryState: 'Historical Overlay',
  userDnsLookup: 'rDNS Lookup',
  userTimezone: 'Time Zone'
};

@inject('$users')
@observer
class NotificationsSettings extends Component {
  handleProfileSwitchChange = e => {
    const { name } = e.target;
    const { $users } = this.props;

    $users.updateUserProfile({ [name]: getSwitchValue($users.fullUserProfile, name) }, labels[name]);
  };

  renderSwitch(name, description, mb = 2) {
    const { fullUserProfile } = this.props.$users;

    return (
      <Flex align="center" mb={mb}>
        <Label description={description}>{labels[name]}</Label>
        <Switch
          type="checkbox"
          name={name}
          onChange={this.handleProfileSwitchChange}
          checked={getSwitchChecked(fullUserProfile, name)}
          label={getSwitchLabel(fullUserProfile, name)}
        />
      </Flex>
    );
  }

  render() {
    return (
      <div>
        <Heading helpTopic="User Notifications">Notifications</Heading>
        <Card mb={2}>
          {this.renderSwitch('email_service')}
          {this.renderSwitch('email_product')}
          {this.renderSwitch('bgpNotification', '(non service-affecting)', 0)}
        </Card>
      </div>
    );
  }
}

export default NotificationsSettings;
