import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Intent, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { MaterialIcon } from 'components/Icon';
import store from 'stores/store';
import SupportSubMenu from './SupportSubMenu';

@inject('$auth', '$app', '$sudo', '$exports', '$userGroups')
@observer
class UserMenu extends Component {
  handleToggleDarkTheme = () => {
    this.props.$app.toggleDarkTheme();
  };

  render() {
    const { $app, $auth, $exports, $sudo, $userGroups, loginInterstitialOpen } = this.props;
    const { activeUser, openConfig } = $auth;
    const darkThemeToggleText = $app.darkThemeEnabled ? 'Use Standard Theme' : 'Use Dark Theme';

    const { toggleReportIssueDialog } = $app;

    return (
      <Menu className="admin-menu">
        {$auth.isSpoofed && (
          <MenuItem
            intent={Intent.WARNING}
            text={<span>Spoofing {activeUser.user_name}</span>}
            onClick={() => $sudo.clearSpoofSession({ reload: true })}
          />
        )}
        <li>
          <Link to="/profile" className="pt-menu-item pt-popover-dismiss pt-icon-user">
            My Profile
          </Link>
        </li>
        <MenuItem
          iconName="export"
          className="pt-normal"
          text="Recent Exports"
          onClick={$exports.toggleDialogVisible}
          disabled={loginInterstitialOpen}
        />
        <MenuItem
          iconName="contrast"
          className="pt-normal"
          text={darkThemeToggleText}
          onClick={this.handleToggleDarkTheme}
          disabled={loginInterstitialOpen}
        />
        {$auth.hasPermission('subtenancy.memberSpoof') && (
          <MenuItem
            onClick={() => $userGroups.setSubtenantViewDialogOpen(true)}
            text={
              <span className="menu-text">
                <MaterialIcon name="supervised_user_circle" style={{ fontSize: 16, marginRight: 7 }} />
                Tenant View
              </span>
            }
          />
        )}
        <MenuDivider />
        <MenuItem
          iconName={loginInterstitialOpen ? 'error' : 'comment'}
          intent={loginInterstitialOpen && Intent.PRIMARY}
          className={loginInterstitialOpen ? 'pt-strong' : 'pt-normal'}
          text="Report an Issue"
          onClick={toggleReportIssueDialog}
        />

        <li>
          <a
            href="https://kb.kentik.com"
            className="pt-menu-item pt-popover-dismiss pt-icon-info-sign"
            target="_blank"
            rel="noopener noreferrer"
          >
            Knowledge Base
          </a>
        </li>

        {$app.isSubtenant && (
          <SupportSubMenu
            supportEmail={openConfig.subtenancy.config.supportEmail}
            supportURL={openConfig.subtenancy.config.supportURL}
          />
        )}

        {($auth.hasSudo || $auth.isSpoofed) && (
          <Fragment>
            <MenuDivider title="Kentik" />
            <MenuItem onClick={() => $sudo.setSudoOpen(true)} iconName="globe-network" text="Customers" />
            {$auth.hasSudo && (
              <Fragment>
                <li>
                  <Link to="/admin/companies" className="pt-menu-item pt-popover-dismiss pt-icon-office">
                    Kentik Admin
                  </Link>
                </li>
                <li>
                  <Link to="/admin/usageStats" className="pt-menu-item pt-popover-dismiss pt-icon-chart">
                    Usage Stats
                  </Link>
                </li>
                <li>
                  <Link to="/admin/messages" className="pt-menu-item pt-popover-dismiss pt-icon-comment">
                    Manage Banners
                  </Link>
                </li>
              </Fragment>
            )}

            <MenuItem iconName="shop" text="Asset Migration" onClick={() => $sudo.setAssetMigrationOpen(true)} />
            <MenuItem onClick={$app.toggleDebugMode} iconName="wrench" text="Debug Mode" />
            <MenuItem onClick={() => $app.setBuildInfoOpen(true)} iconName="build" text="Version Info" />
          </Fragment>
        )}
        <MenuDivider />
        {$auth.isLandlordSpoofed && (
          <MenuItem
            intent={Intent.WARNING}
            iconName="log-out"
            text={<span>Stop Spoofing {activeUser.user_name}</span>}
            onClick={() => $userGroups.clearSpoofSession({ reload: true })}
          />
        )}
        <MenuItem
          intent={Intent.DANGER}
          disabled={loginInterstitialOpen}
          iconName="log-out"
          text="Logout"
          onClick={store.logout}
        />
      </Menu>
    );
  }
}

export default withRouter(UserMenu);
