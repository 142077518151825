import React from 'react';
import { Button, Checkbox, Tooltip } from '@blueprintjs/core';

import RemoveButton from 'components/forms/actions/RemoveButton';

/*
 * Reusable buttons that take action on a table row (model).
 * One-off buttons can be injected with raw JSX and do not belong here.
 *
 * TODO: these tooltips are really distracting and probably need some sensible defaults, theme-based styling, etc.
 */
export default {
  EDIT: model => (
    <Tooltip key="edit" content="Edit">
      <Button
        iconName="edit"
        className="pt-minimal"
        onClick={e => {
          e.stopPropagation();
          model.select();
        }}
        text="Edit"
      />
    </Tooltip>
  ),
  DUPLICATE: model => (
    <Tooltip key="duplicate" content="Copy">
      <Button
        iconName="duplicate"
        className="pt-minimal"
        onClick={e => {
          e.stopPropagation();
          model.duplicate();
        }}
        text="Copy"
      />
    </Tooltip>
  ),
  REMOVE: model => (
    <Tooltip key="remove" content="Remove">
      <RemoveButton model={model} showIcon />
    </Tooltip>
  ),
  SELECT: model => (
    <Checkbox
      style={{ margin: 0, minHeight: 13 }}
      className="pt-inline"
      key="select"
      checked={model.isSelected}
      onClick={e => {
        e.stopPropagation();
        model.select({ multi: true, shiftKey: e.shiftKey });
      }}
    />
  )
};
