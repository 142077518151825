import Collection from 'models/Collection';
import SqlAlert from './SqlAlert';

class SqlAlertsCollection extends Collection {
  get url() {
    return '/api/portal/admin/alerts';
  }

  get model() {
    return SqlAlert;
  }
}

export default SqlAlertsCollection;
