import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';
import { hexToRgbA } from 'util/colors';

import { Flex } from 'components/flexbox';

const GroupRow = props => {
  const { collection, groupSummaryLookup, groupKey, onToggle, isExpanded, last, style } = props;
  const { groupBy } = collection;
  const group = collection.groupedData[groupKey];

  if (!group) {
    return null;
  }

  const groupSummary = groupSummaryLookup({ groupBy: collection.groupBy, groupKey, group });
  const groupCls = classNames('tr', 'group-summary', { last, collapsed: !isExpanded });
  const iconCls = classNames({ 'caret-down': isExpanded, 'caret-right': !isExpanded });

  let contents = (
    <Fragment>
      <Button className="pt-minimal" iconName={iconCls} onClick={onToggle} />
      <Flex flexAuto align="center" justify="flex-start">
        {groupSummary || 'Unassigned'}
      </Flex>
    </Fragment>
  );

  let p = 2;

  // For special case of bracket grouping, set background based on bracket color
  if (typeof groupBy === 'object' && groupBy.type && groupBy.type === 'bracketing') {
    const groupItem = collection.groupedData[groupKey][0];
    const tagDataKey = Object.keys(groupItem.toJS()).find(prop => prop.startsWith('tag_'));
    const tagData = groupItem.get(tagDataKey);

    contents = (
      <Flex
        align="center"
        justify="flex-start"
        flexAuto
        style={{ background: hexToRgbA(tagData.value).replace('1)', '.25)') }}
        p={p}
      >
        {contents}
      </Flex>
    );

    p = 0;
  }

  return (
    <Flex className={groupCls} p={p} style={style} align="center" justify="flex-start">
      {contents}
    </Flex>
  );
};

export default observer(GroupRow);
