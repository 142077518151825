import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import $auth from 'stores/$auth';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['i_trf_origination', 'i_trf_termination', 'i_trf_profile'],
  filterFields: ['i_trf_origination', 'i_trf_termination', 'i_trf_origination|termination', 'i_trf_profile']
};

class NetworkClassDependency extends AbstractDependency {
  get key() {
    return 'nc';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $auth.networkClassEstablished;
  }

  get message() {
    return (
      <Fragment>
        <Box className="pt-text-smaller" mb={0.5}>
          <strong>Network Classification</strong> is required by dimensions you're using, but your organization does not
          have its internal IP space defined. Results shown herein may be highly inaccurate.
        </Box>
        {this.showConfigureButton && (
          <Button
            iconName="cog"
            className="pt-small"
            onClick={() => $auth.store.history.push('/admin/networkClassification')}
          >
            Configure
          </Button>
        )}
      </Fragment>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $auth.networkClassEstablished;
  }
}

export default NetworkClassDependency;
