import BaseModel from 'models/BaseModel';
import { getDefaultRanges, overColorValue } from 'services/bracketing';

export default class BracketOptionsModel extends BaseModel {
  constructor(attributes, options) {
    const { bracketOptions = {}, outsort } = attributes;
    // for now, set bracketAggregation field to query outsort.
    Object.assign(bracketOptions, { bracketAggregation: outsort });

    // set fields for model (bracketOptions subset)
    super(bracketOptions, options);
    // keep hold of full query state.
    this.currentQueryState = attributes;
  }

  get defaults() {
    return {
      type: 'percentages',
      ranges: getDefaultRanges({ type: 'percentages' }),
      over: overColorValue
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Bracketing Options',
      text: 'Are you sure you want to remove bracketing options?'
    };
  }
}
