// disable max-length rules because of long URLs.
/* eslint-disable max-len */
import { observable, action, toJS } from 'mobx';
import { groupBy } from 'lodash';

import SidebarModel from 'models/SidebarModel';
import Dataset, { REPORT_TYPES } from 'models/analytics/Dataset';
import DatasetCollection from 'models/analytics/DatasetCollection';
import DatasetResult from 'models/analytics/DatasetResult';
import DatasetResultCollection from 'models/analytics/DatasetResultCollection';
import api from 'util/api';

const ANALYSIS_PATH = '/api/portal/bgpDatasets/analysis';

class DatasetsStore {
  // general state tracking
  @observable
  fetchingASNDetails = false;

  @observable
  fetchingResults = false;

  @observable
  sidebar = new SidebarModel({ name: 'datasets', sections: ['filters', 'options'] });

  // stores all of our Datasets
  @observable
  collection = new DatasetCollection();

  // used when we're on the detail page for a Dataset.
  @observable
  resultsCollection = new DatasetResultCollection();

  @observable
  totalResult = new DatasetResult();

  @observable
  sankeyResult = undefined;

  @observable
  graphResult = undefined;

  @observable
  topASNs = undefined;

  @observable
  allReports;

  // stores out temporary collections / models for details about a particular ASN
  @observable
  asnDetailResults;

  @observable
  asnDetailInterfaces;

  @action
  fetchAll() {
    this.fetchingResults = true;
    const promises = [this.fetchTableTotal()];

    REPORT_TYPES.forEach(({ value: reportField }) => {
      promises.push(
        this.fetchTableData({ reportField, create: true }),
        this.fetchSankey({ reportField }),
        this.fetchGraphData({ reportField })
      );
    });

    const serializedModel = toJS(this.collection.selected.attributes);

    return Promise.all(promises).then(
      action(responses => {
        const allReports = [];
        for (let i = 1; i < promises.length; i += 3) {
          const { label, value } = REPORT_TYPES[allReports.length];

          allReports.push({
            table: responses[i],
            sankey: responses[i + 1],
            graph: responses[i + 2],
            label,
            model: new Dataset({ ...serializedModel, activeField: value })
          });
        }

        this.allReports = allReports;
        this.fetchingResults = false;
      })
    );
  }

  /**
   * When a user clicks an individual ASNs, we do all of that fetching logic here. Populate a bunch of
   * data in some collections and models, and then destroy it when the Dialog is closed.
   */
  @action
  fetchASNDetails(asn) {
    this.fetchingASNDetails = true;

    const sankeyPortPath = this.fetchSankey({ reportField: 'PortPath', asn });
    const sankeyCountry = this.fetchSankey({ reportField: 'Country', asn });
    const tableTotal = this.fetchTableTotal({ asn });
    const tableASPaths = this.fetchTableData({ reportField: 'ASPath', asn });
    const tableTopDevice = this.fetchTableData({ reportField: 'Device', asn });
    const tableDstGeo = this.fetchTableData({ reportField: 'DstGeo', asn });
    const tableSrcGeo = this.fetchTableData({ reportField: 'SrcGeo', asn });

    const graphASCumulative = this.fetchGraphData({ reportField: 'ASPathCumulative', asn });
    const graphASPath = this.fetchGraphData({ reportField: 'ASPath', asn });

    return Promise.all([
      sankeyPortPath,
      sankeyCountry,
      tableTotal,
      tableASPaths,
      tableTopDevice,
      tableDstGeo,
      tableSrcGeo,
      graphASPath,
      graphASCumulative
    ]).then(
      action(res => {
        this.fetchingASNDetails = false;
        this.asnDetailResults = res;
      })
    );
  }

  @action
  fetchTopASNs() {
    const { datasetInfo } = this.collection.selected;
    api.get(`${ANALYSIS_PATH}/${datasetInfo.id}/topASNLookup`).then(results => {
      this.topASNs = results;
    });
  }

  @action
  fetchGroupedDatasets() {
    this.collection.fetch().then(() => {
      this.collection.group('report_pub_name');
    });
  }

  @action
  fetchDatasetResults = values => {
    this.fetchingResults = true;

    if (values) {
      this.collection.selected.set(values);
    }

    this.resetAllResults();

    this.fetchTopASNs();

    return Promise.all([this.fetchTableTotal(), this.fetchTableData(), this.fetchSankey(), this.fetchGraphData()]).then(
      action(res => {
        this.fetchingResults = false;
        return res;
      })
    );
  };

  /**
   * Only used in the Details dialog when a device row is exxpanded, we do another querty and then show the interface
   * information for that device.
   */
  @action
  fetchInterfaceData(options = {}) {
    const { datasetInfo, ignoreFirstHop, peeringDepth, filterByASNs, filterByDevices } = this.collection.selected;
    const { asn, deviceName } = options;

    return api
      .get(
        `${ANALYSIS_PATH}/${
          datasetInfo.id
        }/${ignoreFirstHop}/${peeringDepth}/${filterByASNs}/table/Interface/ASN/${asn}/Device/${deviceName}?filterByDevices=${filterByDevices}&showDevices=false`
      )
      .then(results => {
        results.forEach(result => (result.timeRange = datasetInfo.timeRange));
        return new DatasetResultCollection(results);
      })
      .then(action(results => (this.asnDetailInterfaces = results)));
  }

  /**
   * Fetch data that populates the "Total" area. Returns a DatasetResult model without a collection.
   */
  @action
  fetchTableTotal(options = {}) {
    const {
      datasetInfo,
      ignoreFirstHop,
      peeringDepth,
      filterByASNs,
      filterByDevices,
      reportField
    } = this.collection.selected;
    const isASNDetails = options.asn ? `/ASN/${options.asn}` : '';
    const isIgnoreFirstHop = reportField === 'ASPath' ? 'Full' : ignoreFirstHop; // ignore first hop is not applied in ASPath tab

    return api
      .get(
        `${ANALYSIS_PATH}/${
          datasetInfo.id
        }/${isIgnoreFirstHop}/${peeringDepth}/${filterByASNs}/table/Total${isASNDetails}?filterByDevices=${filterByDevices}&showDevices=false`
      )
      .then(results => {
        if (isASNDetails) {
          return new DatasetResult({ timeRange: datasetInfo.timeRange, ...results[0] });
        }

        return this.totalResult.set({ timeRange: datasetInfo.timeRange, ...results[0] });
      });
  }

  /**
   * Fetch data to populate the DatasetResultsTable, then return a collection with those results.
   */
  @action
  fetchTableData(options = {}) {
    const { create = false } = options;
    const {
      datasetInfo,
      ignoreFirstHop,
      peeringDepth,
      filterByASNs,
      reportField,
      filterByDevices
    } = this.collection.selected;

    const isASNDetails = options.asn ? `/ASN/${options.asn}` : '';
    const isIgnoreFirstHop = reportField === 'ASPath' ? 'Full' : ignoreFirstHop; // ignore first hop is not applied in ASPath tab

    return api
      .get(
        `${ANALYSIS_PATH}/${
          datasetInfo.id
        }/${isIgnoreFirstHop}/${peeringDepth}/${filterByASNs}/table/${options.reportField ||
          reportField}${isASNDetails}?filterByDevices=${filterByDevices}&showDevices=false`
      )
      .then(results => {
        results.forEach(result => (result.timeRange = datasetInfo.timeRange));

        if (isASNDetails || create) {
          return new DatasetResultCollection(results);
        }

        this.resultsCollection.set(results);
        return this.resultsCollection;
      });
  }

  /**
   * Fetch data to populate the DatasetResultsTable, then return a collection with those results.
   * details     /portal/bgpDatasets/analysis/6658/Full/normal/noFilterASN/sankey/PortPath/ASN/209?filterByDevices=2951,8937,4806,1206,1466,12589,10705,10969,11259,11260,11261,10967,11258,12243,12600,10646,12242&showDevices=false
   * no details: /portal/bgpDatasets/analysis/6658/Full/normal/noFilterASN/sankey/ASPath?filterByDevices=2951,8937,4806,1206,1466,12589,10705,10969,11259,11260,11261,10967,11258,12243,12600,10646,12242&showDevices=false
   *
   */
  @action
  fetchSankey(options = {}) {
    const {
      datasetInfo,
      ignoreFirstHop,
      peeringDepth,
      filterByASNs,
      reportField,
      filterByDevices,
      showDevicesInSite
    } = this.collection.selected;

    const isASNDetails = options.asn ? `/ASN/${options.asn}` : '';
    const isIgnoreFirstHop = reportField === 'ASPath' ? 'Full' : ignoreFirstHop; // ignore first hop is not applied in ASPath tab

    return api
      .get(
        `${ANALYSIS_PATH}/${
          datasetInfo.id
        }/${isIgnoreFirstHop}/${peeringDepth}/${filterByASNs}/sankey/${options.reportField ||
          reportField}${isASNDetails}?filterByDevices=${filterByDevices}&showDevices=${showDevicesInSite}`
      )
      .then(result => {
        if (isASNDetails) {
          return result;
        }

        this.sankeyResult = result;
        return result;
      });
  }

  @action
  fetchGraphData(options = {}) {
    const {
      datasetInfo,
      ignoreFirstHop,
      peeringDepth,
      filterByASNs,
      reportField,
      filterByDevices
    } = this.collection.selected;

    const isASNDetails = options.asn ? `/ASN/${options.asn}` : '';
    const isIgnoreFirstHop = reportField === 'ASPath' ? 'Full' : ignoreFirstHop; // ignore first hop is not applied in ASPath tab

    return api
      .get(
        `${ANALYSIS_PATH}/${
          datasetInfo.id
        }/${isIgnoreFirstHop}/${peeringDepth}/${filterByASNs}/graph/${options.reportField ||
          reportField}${isASNDetails}?filterByDevices=${filterByDevices}&showDevices=false`
      )
      .then(result => {
        const graphResult = this.formatChartData(result);

        if (!isASNDetails) {
          this.graphResult = graphResult;
        }

        return graphResult;
      });
  }

  formatChartData(rawData) {
    const series = groupBy(rawData, 'reportfield');

    return Object.keys(series).map(name => {
      const data = series[name].map(({ start_time, sum }) => [
        new Date(start_time).getTime() / 1000,
        parseInt((sum * 8) / 1000000 / 3600, 10)
      ]);

      return { data, name };
    });
  }

  @action
  resetAllResults() {
    this.resultsCollection.reset();
    this.totalResult = new DatasetResult();
    this.sankeyResult = undefined;
    this.graphResult = undefined;
    this.topASNs = undefined;
  }
}

export default new DatasetsStore();
