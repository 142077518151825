import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Form, FormDialog } from 'components/forms';
import { fields, options } from 'forms/config/deviceDetails';
import DeviceDetailsForm from './DeviceDetailsForm';

@Form({ fields, options })
@inject('$devices', '$dictionary')
@observer
export default class DeviceDetailsDialog extends Component {
  onRemove = () => {
    const { onClose, model, $devices } = this.props;
    const remove = !model.isActive;
    return model
      .destroy({ remove })
      .then(() => $devices.loadDeviceSummaries())
      .then(() => model.set({ device_status: 'D' }))
      .then(() => {
        if (typeof onClose === 'function') {
          onClose();
        }
      });
  };

  onSubmit = (form, values) => {
    const { onClose, model, $devices } = this.props;
    return model
      .save(values)
      .then(() => $devices.loadDeviceSummaries())
      .then(() => {
        if (typeof onClose === 'function') {
          onClose();
        }
      });
  };

  render() {
    const { model, onClose } = this.props;
    const deviceName = model && model.get('device_name');
    const isNew = model && model.isNew;

    return (
      <FormDialog
        {...this.props}
        style={{ width: 700, maxHeight: 'calc(100vh - 80px)' }}
        bodyStyle={{ padding: 0 }}
        entityName="Device"
        title={
          isNew ? (
            'Add Device'
          ) : (
            <span>
              Edit Device &middot; <span className="pt-normal pt-text-muted">{deviceName}</span>
            </span>
          )
        }
        formComponent={<DeviceDetailsForm onSubmit={this.onSubmit} />}
        formActionsProps={{
          onCancel: onClose,
          onRemove: this.onRemove,
          onSubmit: this.onSubmit
        }}
      />
    );
  }
}
