import { action } from 'mobx';
import Collection from '../Collection';
import DashboardItem from './DashboardItem';

class DashboardItemCollection extends Collection {
  constructor(data, options) {
    super(data, options);
    this.dashboard = options.dashboard;
  }

  @action
  create(attributes) {
    const item = this.build(attributes);
    return item.save({ dashboard_id: this.dashboard.id });
  }

  get url() {
    return '/api/portal/dashboards/dashItems';
  }

  get model() {
    return DashboardItem;
  }
}

export default DashboardItemCollection;
