import Collection from '../Collection';
import Site from './Site';

class SiteCollection extends Collection {
  get url() {
    return '/api/portal/sites';
  }

  get model() {
    return Site;
  }

  get defaultSortState() {
    return {
      field: 'title',
      direction: 'asc'
    };
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'title', 'address', 'city', 'region', 'country', 'postal', 'countryName']);
  }
}

export default SiteCollection;
