import React from 'react';
import { observer } from 'mobx-react';
import { Box } from 'components/flexbox';

// Use for when you have 1 or more inputs on a single line with each other or labels

const SingleLineErrors = props => {
  const { fields = [], showPristineErrors = false } = props;

  const renderFields = fields.map(field => (
    <div key={field._id}>
      {!field.valid &&
        (field.dirty || showPristineErrors) && (
          <div className="pt-form-group pt-intent-danger">
            <div className="pt-form-helper-text pt-intent-danger">
              {field.errors.map(error => (
                <Box key={`error-${error}`}>{error}</Box>
              ))}
            </div>
          </div>
        )}
    </div>
  ));

  return <div>{renderFields}</div>;
};

export default observer(SingleLineErrors);
