import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box } from 'components/flexbox';

import BasicExplorerTimeOptions from './BasicExplorerTimeOptions';
import VizTypes from './Display/VizTypes';
import Pivots from './Display/Pivots';
import Metrics from './Display/Metrics';
import Filters from './Display/Filters';
import Dimensions from './Display/Dimensions';

const Separator = props => <hr {...props} className="sidebar-separator" style={{ margin: '16px 0 ' }} />;
@inject('$basicExplorer')
@observer
class BasicExplorerControls extends Component {
  render() {
    const { template } = this.props;

    const tools = [];

    if (template.showPivots) {
      tools.push(<Pivots key="pivots" template={template} />);
    }

    if (tools.length > 0) {
      tools.push(<Separator key="separator1" />);
    }

    tools.push(<BasicExplorerTimeOptions key="time" />);

    if (template.showDimensionSelector) {
      if (tools.length > 0) {
        tools.push(<Separator key="separator2" />);
      }
      tools.push(<Dimensions key="dimensions" template={template} />);
    }

    if (template.showFilterInputs) {
      if (tools.length > 0) {
        tools.push(<Separator key="separator3" />);
      }
      tools.push(<Filters key="filters" model={template} />);
    }

    if (template.showMetricSelector) {
      if (tools.length > 0) {
        tools.push(<Separator key="separator4" />);
      }
      tools.push(<Metrics key="metrics" template={template} />);
    }

    if (template.showVizTypeSelector) {
      if (tools.length > 0) {
        tools.push(<Separator key="separator5" />);
      }
      tools.push(
        <VizTypes key="viz" template={template} options={template.get('options.vizTypes.optionsWhitelist')} />
      );
    }

    return (
      <Box className="basic-explorer-options-panel" p={2} pb={0}>
        {tools}
      </Box>
    );
  }
}

export default BasicExplorerControls;
