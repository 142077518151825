import { action, observable, computed } from 'mobx';
import CloudExportTask from 'models/cloudExportTask/CloudExportTask';
import CloudExportTaskCollection from 'models/cloudExportTask/CloudExportTaskCollection';
import moment from 'moment';
import { PROVIDERS } from 'forms/config/cloudExportDetails';
import { parseQueryString } from 'util/utils';

import api from 'util/api';
import { showErrorToast } from 'components/Toast';

// const providerBytesPerFlow = {
//   aws: 128,
//   awsKinesis: 1028,
//   gcp: 1000,
//   azure: 550
// };

class CloudStore {
  @observable
  collection = new CloudExportTaskCollection();

  @observable
  statsFetched = false;

  @observable
  isAwsValid = null;

  @observable
  isAwsCheckLoading = false;

  @action
  init() {
    this.isAwsValid = null;
  }

  @action
  loadClouds() {
    this.collection.statsFetched = false;
    this.statsFetched = false;

    return this.collection.fetch({ preserveSelection: true }).then(
      action(clouds => {
        if (clouds.length) {
          return this.loadDeviceStatistics(this.collection);
        }
        this.collection.statsFetched = true;
        this.statsFetched = true;
        return undefined;
      })
    );
  }

  loadCloudStatistics(cloud) {
    return api.get(`/api/portal/devices/stats/cloud/${cloud.id}`).then(stats => {
      this.processCloudStatistics(cloud, stats);
    });
  }

  @action
  loadDeviceStatistics(collection) {
    return api.get('/api/portal/devices/stats').then(stats => {
      this.collection.statsFetched = true;
      this.statsFetched = true;

      collection.models.forEach(cloud => this.processCloudStatistics(cloud, stats));
    });
  }

  @action
  processCloudStatistics(cloud, stats) {
    const properties = cloud.get('properties');
    // get the devices for the given cloud model and tack on the matching stats
    const devicesWithStats = cloud.get('devices').map(device => ({
      ...device,
      stats: {
        ...stats[device.id],
        fps2h: parseFloat((stats[device.id].flowVol30d && stats[device.id].flowVol30d.fps) || stats[device.id].fps2h),
        sampling: parseFloat(stats[device.id].flowVol30d && stats[device.id].flowVol30d.sample_rate) || 1
        // flowVol30d: this.getAssociatedFlowLogVolume30d(
        //   device.company_id,
        //   device.device_subtype,
        //   stats[device.id].flowVol30d
        // )
      }
    }));

    cloud.set({ devices: devicesWithStats, ...this.aggregateStats(devicesWithStats) });
    if (
      cloud.get('cloud_provider') === 'azure' &&
      properties.azure_security_principal_enabled &&
      properties.azure_access_check &&
      !properties.azure_access_check.flow_found
    ) {
      return this.updateAzureCheck(cloud).then(() => this.updateTaskStatus(cloud));
    }
    return this.updateTaskStatus(cloud);
  }

  @action
  updateTaskStatus(cloud) {
    const properties = cloud.get('properties');
    const { task_status: taskStatus, error_message: errorMessage } = properties;

    if (cloud.get('enabled') && taskStatus !== 'OK') {
      let newTaskStatus = taskStatus;
      let newErrorMessage = errorMessage;
      if (cloud.get('fps2hTotal') || cloud.get('fps5mTotal')) {
        newTaskStatus = 'OK';
        newErrorMessage = '';
      } else if (
        taskStatus !== 'ERROR' &&
        taskStatus !== 'HALT' &&
        moment().isAfter(moment(cloud.get('edate')).add(30, 'minutes'))
      ) {
        newTaskStatus = 'ERROR';
        newErrorMessage = 'Export Process timed out';
      } else if (taskStatus !== 'ERROR' && taskStatus !== 'START' && taskStatus !== 'HALT') {
        newTaskStatus = 'PENDING';
        newErrorMessage = '';
      }

      if (taskStatus !== newTaskStatus) {
        cloud.save(
          { properties: { ...properties, task_status: newTaskStatus, error_message: newErrorMessage } },
          { patch: true, toast: false }
        );
      }
    }
  }

  @action
  updateAzureCheck(cloud) {
    const properties = cloud.get('properties');

    return this.checkAzure({
      subscription_id: properties.azure_subscription_id,
      resource_group: properties.azure_resource_group,
      location: properties.azure_location,
      storage_account: properties.azure_storage_account
    }).then(azure_access_check =>
      cloud.save(
        {
          enabled: azure_access_check.storage_account_access && azure_access_check.api_access,
          properties: { ...properties, azure_access_check }
        },
        { patch: true }
      )
    );
  }

  getAwsErrorCode(error) {
    const matcher = error.match(/^error: (.+):/);
    return (matcher && matcher[1]) || 'AccessDenied';
  }

  @action
  checkAws(data) {
    this.isAwsValid = null;
    this.isAwsCheckLoading = true;

    return api.post('/api/portal/cloudExport/awsCloudCheck', { data, showErrorToast: false, rawResponse: true }).then(
      response => {
        this.isAwsValid = response.body.success;
        this.isAwsCheckLoading = false;
        return { success: response.body.success };
      },
      response => {
        const { error } = response.body;
        this.isAwsValid = false;
        this.isAwsCheckLoading = false;
        return { success: false, errorCode: this.getAwsErrorCode(error), error };
      }
    );
  }

  @action
  checkAzure(data) {
    return api.post('/api/portal/cloudExport/azureCloudCheck', { data }).then(response => response.access_check);
  }

  @computed
  get hasFlowLogVolume() {
    return this.collection.some(cloudExport => cloudExport.get('fps2hTotal') > 0);
  }

  @computed
  get cloudProviderChartData() {
    return this.collection.models.reduce((acc, cloudExport) => {
      if (cloudExport.get('fps2hTotal')) {
        acc.push({
          name: cloudExport.get('name'),
          model: cloudExport,
          data: [
            {
              y: Math.ceil(cloudExport.get('fps2hTotal')),
              name: PROVIDERS[cloudExport.get('cloud_provider').toUpperCase()].code
            }
          ]
        });
      }
      return acc;
    }, []);
  }

  // getAssociatedFlowLogVolume30d(company_id, device_subtype, fps) {
  //   let provider = device_subtype.replace('_subnet', '');
  //   // hack for mcgraw until we figure out kinesis long term
  //   if (`${company_id}` === '62381') {
  //     provider += 'Kinesis';
  //   }
  //   return (parseFloat(fps) || 0) * 3600 * 24 * 30 * (providerBytesPerFlow[provider] || 0);
  // }

  aggregateStats = devices =>
    devices.reduce(
      (acc, { stats }) => ({
        fps2hTotal: acc.fps2hTotal + ((stats && parseFloat(stats.fps2h)) || 0),
        fps5mTotal: acc.fps5mTotal + ((stats && stats.fps5m && parseFloat(stats.fps5m.value)) || 0)
        // ,flowVol30dTotal: acc.flowVol30dTotal + ((stats && stats.flowVol30d && parseFloat(stats.flowVol30d)) || 0)
      }),
      {
        fps2hTotal: 0,
        fps5mTotal: 0
        // ,flowVol30dTotal: 0
      }
    );

  createCloudFromURLParams(urlParams) {
    const { params, success, errorMsg } = parseQueryString(urlParams);
    const isSuccessful = success === 'true';

    if (!isSuccessful) {
      showErrorToast(`Error enabling Azure Security Prinicpal: ${errorMsg}`);
    }
    const defaultsStub = new CloudExportTask().get('properties');
    const cloudAttributes = JSON.parse(params);

    cloudAttributes.properties = {
      ...defaultsStub,
      ...cloudAttributes.properties,
      azure_security_principal_enabled: isSuccessful
    };
    return this.collection.forge(cloudAttributes);
  }
}

export default new CloudStore();
