import React from 'react';
import { Tag } from '@blueprintjs/core';

import { Icon } from 'components';

const DefaultValueRenderer = ({ option, showGroupLabel, style }) => {
  if (showGroupLabel) {
    return (
      <span className={option && option.className} style={style}>
        <Tag className={`${option.className} pt-minimal`}>{option.group}</Tag>
        {option && option.iconCls && <Icon name={option.iconCls} style={{ marginRight: 6 }} />}
        <strong>{option && option.label}</strong>
      </span>
    );
  }

  return (
    <span className={option && option.className} style={style}>
      {option && option.iconCls && <Icon name={option.iconCls} style={{ marginRight: 6 }} />}
      {option && option.label}
    </span>
  );
};

export default DefaultValueRenderer;
