import React, { Component } from 'react';
import { Menu } from '@blueprintjs/core';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import $auth from 'stores/$auth';
import { renderMenuLink } from './utils';

function getLinks() {
  return [
    {
      to: '/analytics/peering',
      text: 'Peering',
      iconName: 'exchange'
    },
    {
      to: '/analytics/routeTraffic',
      text: 'Route Traffic',
      iconName: 'dev-router-plain'
    },
    {
      key: 'capacity',
      to: '/analytics/capacity',
      text: (
        <span>
          Capacity <span className="pt-tag pt-intent-warning">Preview</span>
        </span>
      ),
      iconName: 'heat-grid'
    },
    {
      key: 'rawFlowStandard',
      to: '/analytics/rawFlow',
      text: 'Raw Flow',
      iconName: 'search-template',
      display: $auth.hasPermission('rawFlow.viewer')
    },
    {
      key: 'rawFlowForensics',
      to: '/analytics/rawFlow/forensics',
      text: 'Forensic Raw Flow Viewer',
      iconName: 'search',
      display: $auth.hasPermission('rawFlow.forensicViewer')
    },
    {
      key: 'queryEditor',
      to: '/analytics/queryEditor',
      text: 'Query Editor',
      iconName: 'code',
      display: $auth.hasPermission('sql.editor')
    }
  ];
}

@observer
class AnalyticsMenu extends Component {
  render() {
    const { history } = this.props;

    return <Menu className="admin-menu">{getLinks().map(link => renderMenuLink(link, history))}</Menu>;
  }
}

export default withRouter(AnalyticsMenu);
