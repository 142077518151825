import { observer } from 'mobx-react';
import $dictionary from 'stores/$dictionary';
import moment from 'moment';
import $app from 'stores/$app';
import { getMetadata, getBracketingDataClasses } from 'services/bracketing';
import { greekPrefix, adjustByGreekPrefix } from 'util/utils';

import BaseHighchartsDataview from './BaseHighchartsDataview';
import { getSharedTooltip } from './tooltipRenderers';

@observer
export default class LineView extends BaseHighchartsDataview {
  sync = false;

  chartOptions = {
    chart: {
      zoomType: 'x',
      events: {
        selection: this.handleTimeZoom
      }
    },
    colors: this.qualitativeColors,
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%m/%e',
        minute: '%H:%M'
      },
      labels: { style: { color: this.chartLabelColor } },
      title: {
        text: 'UTC',
        style: { color: this.chartLabelColor }
      },
      gridLineWidth: 1
    },
    yAxis: [
      {
        min: 0.001,
        labels: { style: { color: this.chartLabelColor } },
        title: {
          text: 'units go here',
          style: { color: this.chartLabelColor }
        },
        gridLineWidth: 1
      }
    ],
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      }
    },
    legend: { enabled: false },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter() {
        return getSharedTooltip(this.points);
      }
    },
    series: []
  };

  redraw({ setColors = false } = {}) {
    if (this.chart) {
      $app.renderSync(() => {
        if (this.chart.series.length > 0) {
          const { dateRangeDisplay } = this.props.dataview.queryBuckets.activeBuckets[0].firstQuery;
          this.chart.xAxis[0].setTitle({
            text: `${dateRangeDisplay} (${this.chart.series[0].userOptions.data[0][2] / 60} minute intervals)`
          });
        }

        if (!this.hasOverriddenExtremes) {
          if (this.chart.yAxis.length > 1) {
            if (this.sync) {
              const { dataMin, dataMax } = this.chart.yAxis[0].getExtremes();
              const { dataMin: secondaryMin, dataMax: secondaryMax } = this.chart.yAxis[1].getExtremes();
              const extreme = Math.max(
                Math.abs(dataMin),
                Math.abs(secondaryMin),
                Math.abs(secondaryMax),
                Math.abs(dataMax)
              );
              if (extreme) {
                this.chart.yAxis.forEach(axis =>
                  axis.setExtremes(dataMin < 0 || secondaryMin < 0 ? extreme * -1 : 0.001, extreme, false)
                );
              }
            } else {
              const { dataMin: primaryMin } = this.chart.yAxis[0].getExtremes();
              const { dataMin: secondaryMin } = this.chart.yAxis[1].getExtremes();
              const extremeMin = Math.min(primaryMin, secondaryMin);
              this.chart.yAxis.forEach(axis => {
                const { dataMin, dataMax } = axis.getExtremes();
                const extreme = Math.max(Math.abs(dataMin), Math.abs(dataMax));
                if (extreme) {
                  axis.setExtremes(extremeMin < 0 ? extreme * -1 : 0.001, extreme, false);
                }
              });
            }
          } else {
            this.chart.yAxis.forEach(axis => {
              const { dataMin, dataMax } = axis.getExtremes();
              const extreme = Math.max(Math.abs(dataMin), Math.abs(dataMax));
              if (extreme) {
                axis.setExtremes(dataMin < 0 ? extreme * -1 : 0.001, extreme, false);
              }
            });
          }
        }

        if (setColors) {
          this.updateColors(true);
        } else {
          this.chart.redraw();
        }
      });
    }
    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  renderSeries(bucket, name, data) {
    if (!this.chart) {
      return null;
    }

    const type = 'line';
    const yAxis = bucket.get('name').includes('Left') ? 0 : 1;
    const seriesData = { type, name, data, yAxis, dashStyle: yAxis ? 'Dot' : 'Solid' };
    const matchingSeries = this.chart.series.find(series => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = matchingSeries.name;
      seriesData.color = matchingSeries.color;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
    }
    return this.chart.addSeries(seriesData, false);
  }

  findSeries(bucket, name) {
    const yAxisIdx = bucket.get('name').includes('Left') ? 0 : 1;
    return this.chart.series.find(series => series.name === name && series.yAxis === this.chart.yAxis[yAxisIdx]);
  }

  removeSeries(bucketName, name) {
    const yAxisIndex = bucketName.includes('Left') ? 0 : 1;
    const isNegative = bucketName.includes('-Y Axis');
    const existingSeries =
      this.chart &&
      this.chart.series.find(
        series =>
          series.name === name &&
          series.yAxis === this.chart.yAxis[yAxisIndex] &&
          ((isNegative && series.dataMax < 0) || series.dataMax > 0)
      );
    if (existingSeries) {
      existingSeries.remove(false);
    } else {
      console.warn('Attempted to remove series', name, 'from bucket', bucketName, ', but no series was found.');
    }
  }

  renderOverlay(bucket, name, data) {
    if (!this.chart) {
      return null;
    }

    const yAxis = bucket.get('name').includes('Left') ? 0 : 1;
    const type = 'line';
    const color = name.startsWith('Historical Total') ? this.overlayColor : this.primaryOverlayColor;
    const dashStyle = yAxis === 0 ? 'Dash' : 'LongDashDotDot';
    const seriesData = { type, name, data, yAxis, color, dashStyle };
    const matchingSeries = this.chart.series.find(series => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = name;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
    }
    return this.chart.addSeries(seriesData, false);
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview, showNativeLegend } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;

    if (query.get('outsort').includes('agg_total')) {
      throw new Error('This Time-Series chart does not support Display & Sort By Total.');
    }

    chartOptions.xAxis.momentFn = query.get('time_format') === 'UTC' ? moment.utc : moment;
    chartOptions.xAxis.title.text = query.dateRangeDisplay;
    chartOptions.yAxis[0].title.text = $dictionary.dictionary.units[query.outsortUnit];
    chartOptions.yAxis[0].unit = query.outsortUnit;
    chartOptions.yAxis[0].type = query.get('use_log_axis') ? 'logarithmic' : 'linear';
    chartOptions.yAxis[0].min = query.get('use_log_axis') ? 0 : 0.001;

    if (showNativeLegend) {
      chartOptions.legend = {
        itemStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'bold !important'
        },
        labelFormatter() {
          return this.name.substr(0, 16);
        },
        verticalAlign: 'bottom',
        align: 'center'
      };
    }

    if (activeBucketCount > 1 || (query.get('secondaryOutsort') && query.get('secondaryTopxSeparate') === false)) {
      chartOptions.yAxis[0].title.text = `${$dictionary.dictionary.units[query.outsortUnit]} \u2014`;
      const minusYbucket = activeBuckets.find(bucket => bucket.get('name').includes('-Y'));
      if (minusYbucket) {
        chartOptions.yAxis.forEach(axis => (axis.min = null));
      }

      const rightBucket = activeBuckets.find(bucket => bucket.get('name').includes('Right'));
      if (rightBucket || (query.get('secondaryOutsort') && query.get('secondaryTopxSeparate') === false)) {
        const outsortUnit = rightBucket ? rightBucket.firstQuery.outsortUnit : query.secondaryOutsortUnit;
        if (chartOptions.yAxis.length === 1) {
          chartOptions.yAxis.push({
            title: {
              text: `${$dictionary.dictionary.units[outsortUnit]} \u2011\u2011\u2011`
            },
            opposite: true,
            gridLineColor: '#DEDEDE',
            gridLineWidth: 1,
            unit: outsortUnit,
            type: query.get('use_secondary_log_axis') ? 'logarithmic' : 'linear'
          });
        } else {
          chartOptions.yAxis[1].title.text = `${$dictionary.dictionary.units[outsortUnit]} \u2011\u2011\u2011`;
          chartOptions.yAxis[1].unit = outsortUnit;
        }
      } else if (chartOptions.yAxis.length > 1) {
        chartOptions.yAxis = chartOptions.yAxis.slice(0, 1);
      }
    } else if (chartOptions.yAxis.length > 1) {
      chartOptions.yAxis = chartOptions.yAxis.slice(0, 1);
    }

    const bracketOptions = query.get('bracketOptions');
    if (bracketOptions && bracketOptions.type === 'staticRanges') {
      const metadata = getMetadata({ queryResults: activeBuckets[0].queryResults, bracketOptions });
      const bracketDataClasses = getBracketingDataClasses({ metadata, bracketOptions });
      chartOptions.yAxis.forEach((axis, index) => {
        axis.plotLines = [];
        bracketDataClasses.forEach(dataClass => {
          const value = dataClass.to * (index ? -1 : 1);
          const prefix = greekPrefix([value], 1.5);
          axis.plotLines.push({
            color: dataClass.color,
            dashStyle: 'Dashed',
            label: {
              text: `${adjustByGreekPrefix(value, prefix)} ${prefix}${$dictionary.dictionary.units[query.outsortUnit]}`,
              style: { color: this.chartLabelColor }
            },
            value,
            width: 2,
            zIndex: 5
          });
        });
      });
    } else {
      chartOptions.yAxis.forEach(axis => delete axis.plotLines);
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showHistoricalOverlay: false,
  showLegend: true,
  timeBased: true,
  enableToggle: true,
  mirrorable: true,
  allowsSecondaryOverlay: true,
  allowsSyncAxes: true,
  supportsLogAxis: true,
  buckets: [
    {
      name: 'Left +Y Axis',
      mirrorBucket: 2,
      secondaryMirrorBucket: 3,
      secondaryOverlayBucket: 1
    },
    {
      name: 'Right +Y Axis',
      mirrorBucket: 3
    },
    {
      name: 'Left -Y Axis',
      sampleRateFactor: -1,
      secondaryOverlayBucket: 3
    },
    {
      name: 'Right -Y Axis',
      sampleRateFactor: -1
    }
  ]
};

export { config };
