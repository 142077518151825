import { generateFilterFields, generateTimeFields } from './helpers';

const options = {
  name: 'Dashboard Sidebar',
  groups: {
    time: Object.keys(generateTimeFields()),
    filters: ['filters'],
    devices: ['device_types', 'device_labels', 'device_sites', 'device_name', 'all_devices']
  }
};

const fields = {
  ...generateFilterFields(),
  ...generateTimeFields(),

  all_devices: {
    defaultValue: false,
    rules: 'atLeastOneIfNotAllDevices'
  },
  device_name: {
    defaultValue: []
  },
  device_labels: {
    defaultValue: []
  },
  device_sites: {
    defaultValue: []
  },
  device_types: {
    defaultValue: []
  },

  parametric_fields: {
    isComplexArray: true
  },
  'parametric_fields[].type': {},
  'parametric_fields[].value': {},
  'parametric_fields[].label': {},
  'parametric_fields[].question': {}
};

export { fields, options };
