import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Menu, MenuDivider, MenuItem, Button, Tooltip } from '@blueprintjs/core';
import { StandaloneDimensionSelector } from 'components/forms';
import { DropdownMenu } from 'components';

@inject('$dataviews', '$devices', '$dictionary', '$explorer')
@observer
export default class ChangeViewTypeMenu extends Component {
  static defaultProps = {
    showButtonText: true
  };

  state = {
    dimensionSelectorOpen: false
  };

  matrixBy = matrixBy => {
    // Note: we don't need to change the state because the cmp is destroyed from the click event
    // suppress fetch if matrixBy has no length; invalid query anyway so we just leave the form in error state
    this.props.dataview.applyToAllQueries({ matrixBy }, matrixBy.length === 0);
  };

  hideMatrixBySelect = () => {
    this.setState({ dimensionSelectorOpen: false });
  };

  handleViewTypeChange(viewType) {
    const { dataview, onViewTypeChange } = this.props;

    if (viewType === 'matrix') {
      dataview.eachBucket('applyToEachQuery', [
        { viz_type: viewType, show_total_overlay: false, show_overlay: false, generatorMode: false }
      ]);
      this.setState({ dimensionSelectorOpen: true });
    } else {
      dataview.viewType = viewType;
    }

    if (onViewTypeChange) {
      onViewTypeChange(viewType);
    }
  }

  renderViewTypeMenu() {
    const {
      dataview,
      $dataviews: { viewTypeOptions, categories, categoryTitles },
      filterView = () => true
    } = this.props;

    return (
      <Menu>
        {categories.reduce((opts, category, index) => {
          opts.push(<MenuDivider key={category} title={categoryTitles[index]} />);
          return opts.concat(
            viewTypeOptions
              .filter(view => view.category === category && !view.hideFromViewMenu)
              .filter(filterView)
              .map(view => (
                <MenuItem
                  key={view.value}
                  text={view.label}
                  className={dataview.viewType === view.value ? 'pt-active' : undefined}
                  iconName={view.iconName}
                  onClick={e => {
                    if (view.value === 'matrix') {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                    this.handleViewTypeChange(view.value);
                  }}
                />
              ))
          );
        }, [])}
      </Menu>
    );
  }

  render() {
    const {
      dataview,
      $dataviews,
      $devices,
      $dictionary: { dictionary },
      showButtonText,
      buttonStyle,
      loading
    } = this.props;
    const { dimensionSelectorOpen } = this.state;

    if (!dataview) {
      return null;
    }

    let viz_type = dataview.viewType;
    let disabledDimensions = [];
    let field;
    if (dataview.formState) {
      field = dataview.formState.getField('matrixBy');
      viz_type = dataview.formState.getValue('viz_type');
      const device_name = dataview.formState.getValue('device_name');
      const device_labels = dataview.formState.getValue('device_labels');
      const device_sites = dataview.formState.getValue('device_sites');
      const device_types = dataview.formState.getValue('device_types');
      const all_devices = dataview.formState.getValue('all_devices');

      const hasKProbeSelected = all_devices
        ? $devices.hasDnsProbe
        : $devices.containsDnsProbe({ device_name, device_labels, device_sites, device_types });
      disabledDimensions = !hasKProbeSelected ? dictionary.kprobeDimensions : [];
    }

    const { iconName, label } = $dataviews.getViewType(viz_type);

    return (
      <Tooltip
        content="Change View Type"
        isDisabled={loading}
        tetherOptions={{ constraints: [{ attachment: 'together', pin: true, to: 'window' }] }}
      >
        <DropdownMenu content={this.renderViewTypeMenu()} tetherOptions={{ offset: '-7px 0px' }}>
          <span>
            <Button
              className="pt-small"
              disabled={loading}
              iconName={iconName}
              text={showButtonText ? label : null}
              rightIconName={showButtonText ? 'caret-down' : null}
              style={buttonStyle}
            />

            {dimensionSelectorOpen && (
              <StandaloneDimensionSelector
                title="Matrix By Dimensions"
                isOpen={dimensionSelectorOpen}
                onSave={this.matrixBy}
                onClose={this.hideMatrixBySelect}
                saveButtonText="Matrix By Selected Dimensions"
                multi
                field={field}
                disabledValues={disabledDimensions}
              />
            )}
          </span>
        </DropdownMenu>
      </Tooltip>
    );
  }
}
