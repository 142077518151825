import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

import { Tag } from '@blueprintjs/core';
import Icon from 'components/Icon';
import { durationFromNow } from 'util/dateUtils';

@inject('$auth')
@observer
class TrialDaysRemaining extends Component {
  handleCloseClick = () => {
    this.props.$auth.clearTrialNotice();
  };

  render() {
    const { user, $auth } = this.props;
    const { trial_end_date } = user && user.company;

    let trialExpirationText;
    if (trial_end_date) {
      if (moment(trial_end_date).diff(moment()) > 0) {
        trialExpirationText = (
          <span>
            <strong>{durationFromNow(trial_end_date, true)}</strong> remaining in Trial
          </span>
        );
      } else {
        trialExpirationText = (
          <span>
            Trial ended <strong>{durationFromNow(trial_end_date)}</strong>
          </span>
        );
      }
    }

    if (!$auth.isTrialNoticeVisible) {
      return null;
    }

    return (
      <div className="pt-dark">
        <Tag className="pt-tag pt-large pt-dark pt-minimal pt-intent-warning" onRemove={this.handleCloseClick}>
          <Icon name="time" /> {trialExpirationText}
        </Tag>
      </div>
    );
  }
}

export default TrialDaysRemaining;
