import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Form, FormDialog } from 'components/forms';
import { fields, options } from 'forms/config/dashboardDetails';

import DashboardDetailsForm from './DashboardDetailsForm';

@Form({ fields, options })
@inject('$queryTemplates')
@observer
class DashboardDetailsDialog extends Component {
  state = {
    editingFilters: false
  };

  handleToggleEditFilters = () => {
    this.setState({ editingFilters: !this.state.editingFilters });
  };

  handleSubmit = () => {
    const { $queryTemplates, model: dashboard, form, onSubmit, dashboardItem } = this.props;
    const isNew = dashboard.isNew;
    const { panel_title, parametric_mode, parametric_overrides, ...formValues } = form.getValues();
    const isParametricTypeChanged =
      dashboard.isParametric && dashboard.get('parametric_fields')[0].type !== formValues.parametric_fields[0].type;

    return dashboard.save(formValues, { clearSelection: false, optimistic: true }).then(() => {
      if (!isNew && dashboard.hasItems && isParametricTypeChanged) {
        // we only call this if the parametric field's type has changed.
        dashboard.updateItemsParametricFilterField();
      }

      // Reset category to be a full category, not just an ID
      const category_id = dashboard.get('category_id');
      dashboard.set('category', category_id ? $queryTemplates.categories.get(category_id).toJS() : {});

      if (dashboardItem) {
        dashboardItem.set({
          dashboard_id: dashboard.get('id'),
          panel_title,
          parametric_mode,
          parametric_overrides
        });

        dashboardItem.save().then(() => {
          if (onSubmit) {
            onSubmit(dashboard, isNew);
          }
        });
      } else if (onSubmit) {
        onSubmit(dashboard, isNew);
      }
    });
  };

  render() {
    const { editingFilters } = this.state;
    const { model } = this.props;

    return (
      <FormDialog
        {...this.props}
        style={{ width: 850 }}
        title={model && model.isNew ? 'Add Dashboard' : 'Dashboard Properties'}
        entityName="Dashboard"
        formActionsProps={{ onSubmit: this.handleSubmit }}
        formComponent={
          <DashboardDetailsForm
            {...this.props}
            editingFilters={editingFilters}
            onEditFilters={this.handleToggleEditFilters}
          />
        }
      />
    );
  }
}

export default DashboardDetailsDialog;
