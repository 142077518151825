const fields = {
  view_name: {
    label: 'Name',
    rules: 'required'
  },

  view_description: {
    label: 'Description',
    placeholder: 'Brief description of the queries...',
    rules: 'max:300'
  },

  category_id: {
    label: 'Category'
  },

  share_level: {
    label: 'Visible To',
    rules: 'required'
  }
};

export { fields };
