import React from 'react';
import { observer } from 'mobx-react';

const DebugKeyDimensionCell = props => {
  const { values } = props;
  const { alert_key, alert_key_lookup, alert_dimension } = values;
  const key_dimensions = [];

  if (alert_key && alert_key_lookup && alert_dimension) {
    const alert_dimension_arr = alert_dimension.split(':');
    const alert_key_arr = alert_key.split('__##__');
    const alert_key_lookup_arr = alert_key_lookup.split('__##__');

    for (let indx = 0; indx < alert_key_lookup_arr.length; indx += 1) {
      const alert_key_lookup_item = alert_key_lookup_arr[indx];

      key_dimensions.push(
        <div key={`${alert_dimension_arr[indx]}:${alert_key_arr[indx]}${indx}`} className="pt-text-overflow-ellipsis">
          {alert_dimension_arr[indx]}: <strong>{alert_key_lookup_item}</strong>
        </div>
      );
    }
  }
  return <div>{key_dimensions}</div>;
};

export default observer(DebugKeyDimensionCell);
