import React, { Component } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider, observer } from 'mobx-react';
import { FocusStyleManager } from '@blueprintjs/core';
import { isDev, getScrollbarWidth } from 'util/utils';

import store from 'stores/store';
import AppWrapper from 'views/AppWrapper';
import 'app.scss';
import SubtenantAppWrapper from 'views/SubtenantAppWrapper';

if (isDev) {
  // const Mobx = require('mobx');
  // const mobxFormatters = require('mobx-formatters').default;
  // mobxFormatters(Mobx);
}

@observer
class App extends Component {
  constructor(props) {
    super(props);
    console.info(`Kentik portal running in ${isDev ? 'dev' : 'prod'} mode.`);
  }

  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();
    getScrollbarWidth();
  }

  getConfirmation(message, callback) {
    // @TODO make this a Blueprint <Alert> component
    /* eslint-disable no-alert */
    const allowTransition = window.confirm(message);
    callback(allowTransition);
  }

  render() {
    return (
      <Provider {...this.props.store}>
        <BrowserRouter getUserConfirmation={this.getConfirmation}>
          <Switch>
            {isDev && <Route path="/subtenant" component={SubtenantAppWrapper} />}
            <Route path="/" component={AppWrapper} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export function renderApp() {
  render(<App store={store} />, document.getElementById('root'));
}

renderApp();
