import { computed } from 'mobx';
import $auth from 'stores/$auth';
import Collection from '../Collection';
import CustomDimension from './CustomDimension';
import api from '../../util/api';
import collatePromises from '../../services/collatePromises';

class CustomDimensionCollection extends Collection {
  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  get url() {
    return '/api/portal/customdimensions';
  }

  get model() {
    return CustomDimension;
  }

  get jsonRoot() {
    return 'customDimensions';
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'display_name', 'name', 'type']);
  }

  @computed
  get isMaxDimensionsReached() {
    return this.models.length >= $auth.activeUser.permissions.customDimensions.maxDimensions;
  }

  // fetch populator counts can happen in multiple places, and due to async nature, may be called multiple times
  // before intial call has resolved with actions that could make initial call inaccurate. For this reason only
  // process results from last call via collatePromises to ensure accurate count.
  fetchPopulatorCounts() {
    const promise = api.get('/api/portal/customdimensions/populatorcounts', {
      showErrorToast: false,
      rawResponse: true
    });
    return collatePromises({ key: 'CustomDimensionCollection.fetchPopulatorCounts', promise }).then(
      ({ body: popCounts }) => {
        this.each(customD => {
          // only update bulk (popCount === null), and set to 0 if no results came back so no eternal spinner.
          const id = customD.get('id');
          customD.set('populatorCount', (popCounts[id] && popCounts[id].populatorCount) || 0);
        });
      },
      () => {
        this.each(customD => {
          // just set to 0 if no results came back so no eternal spinner.
          customD.set('populatorCount', 0);
        });
      }
    );
  }
}

export default CustomDimensionCollection;
