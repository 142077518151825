import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { MaterialIcon } from 'components/Icon';
import { Flex, Box } from 'components/flexbox';
import Dimension from 'components/dimensions/Dimension';

@inject('$dictionary', '$lookups')
@observer
class FilterItem extends Component {
  state = {
    selectedLabel: ''
  };

  componentWillMount() {
    this.lookupValues();
  }

  componentWillReceiveProps(nextProps) {
    const { filterValue, filterField, operator } = this.props;
    const { filterValue: nextFilterValue, filterField: nextFilterField, operator: nextOperator } = nextProps;

    if (filterValue !== nextFilterValue || filterField !== nextFilterField || operator !== nextOperator) {
      this.lookupValues();
    }
  }

  lookupValues() {
    const { filter } = this.props;
    const { autoCompleteFilterQueryHandlers } = this.props.$lookups;
    const {
      dictionary: {
        queryFilters: { neverExactMatch }
      }
    } = this.props.$dictionary;

    const filterField = filter.filterField.getValue();
    const filterOperator = filter.operator.getValue();
    const filterValue = filter.filterValue.getValue();

    const onQuery = autoCompleteFilterQueryHandlers[filterField];
    const exactMatch = !neverExactMatch.includes(filterField) && (filterOperator === '=' || filterOperator === '<>');

    if (onQuery && exactMatch && filterValue) {
      onQuery(filterValue, { debounce: false }).then(newOptions => {
        const selectedOption = newOptions.find(option => option.value === filterValue);
        if (selectedOption) {
          this.setState({ selectedLabel: selectedOption.label });
        } else {
          this.setState({ selectedLabel: '' });
        }
      });
    } else {
      this.setState({ selectedLabel: '' });
    }
  }

  handleCancel = () => {
    const { filterField, operator, filterValue } = this.state.value;
    const { filter } = this.props;

    filter.filterField.setValue(filterField);
    filter.operator.setValue(operator);
    filter.filterValue.setValue(filterValue);
  };

  render() {
    const { filter, $dictionary } = this.props;
    const { selectedLabel } = this.state;
    const { dictionary, filterFieldValueOptions, filterOperatorOptions, standardFilterOperators } = $dictionary;

    const filterField = filter.filterField.getValue();
    const filterOperator = filter.operator.getValue();

    const operatorDisplay = filterOperatorOptions[filterField]
      ? filterOperatorOptions[filterField].find(operator => operator.value === filterOperator)
      : standardFilterOperators.find(operator => operator.value === filterOperator);

    const { neverExactMatch } = dictionary.queryFilters;
    const exactMatch = !neverExactMatch.includes(filterField) && (filterOperator === '=' || filterOperator === '<>');

    const value = filter.filterValue.getValue();
    const options = filterFieldValueOptions[filterField];
    let valueDisplay = value || '';
    if (options) {
      const option = options.find(opt => opt.value === value);
      if (option) {
        valueDisplay = option.label;
      } else if (value && exactMatch) {
        valueDisplay = `Invalid: ${value}`;
      }
    } else if (selectedLabel) {
      valueDisplay = selectedLabel;
    }

    return (
      <Flex align="center" className="filter-item filter-item-readonly">
        <MaterialIcon
          className="pt-text-muted"
          name="subdirectory_arrow_right"
          style={{ fontSize: 12, marginRight: 2, marginTop: 3, alignSelf: 'flex-start' }}
        />
        <Flex flexAuto wrap align="center" style={{ textAlign: 'left', fontSize: 11, lineHeight: '20px' }}>
          <Dimension dimension={filter.filterField.getValue()} />
          {operatorDisplay && (
            <Box className="filter-item-operator pt-text-muted" px={1}>
              {operatorDisplay.label.toLowerCase()}
            </Box>
          )}
          <span style={{ maxWidth: 250 }} className="pt-text-overflow-ellipsis">
            {valueDisplay}
          </span>
        </Flex>
      </Flex>
    );
  }
}

export default FilterItem;
