import React from 'react';
import { MaterialIcon } from 'components/Icon';
import { Flex } from 'components/flexbox';

const ItemExplorerOptionTitle = ({ title, iconCls }) => (
  <Flex align="center" className="section-label" mb={1}>
    <MaterialIcon name={iconCls} className="section-icon" />
    <span>{title}</span>
  </Flex>
);

export default ItemExplorerOptionTitle;
