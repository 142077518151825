import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import { Tooltip2, Popover2 } from '@blueprintjs/labs';
import { Box } from 'components/flexbox';

import FiltersDisplay from 'components/FiltersDisplay/FiltersDisplay';
import Dimension from 'components/dimensions/Dimension';

const filterSources = {
  dashboard: 'Panel Filters Ignored',
  additive: 'Dashboard Filters added to Panel Filters',
  locked: 'Locked to Panel Filters'
};

const parametricSetting = {
  override_specific: 'Overriding specific filters',
  override_all: 'Overriding all existing filters',
  add_group: 'Adding a new filter group with',
  ignore: 'Ignoring Guided Mode settings'
};

@inject('$dictionary')
@observer
export default class AppliedFiltersButton extends Component {
  render() {
    const { dataview, item: dashboardItem, loading, buttonStyle } = this.props;

    const hasNoFilters =
      dataview.appliedFilters &&
      dataview.appliedFilters.filterGroups &&
      dataview.appliedFilters.filterGroups.length === 0;

    // if there are no FilterGroups or Saved Filters, don't render the button
    if (hasNoFilters && !dashboardItem) {
      return null;
    }

    return (
      <Popover2
        modifiers={{
          arrow: { enabled: true },
          flip: { enabled: true },
          keepTogether: { enabled: true },
          preventOverflow: { enabled: true, boundariesElement: 'window' }
        }}
      >
        <Tooltip2 content="Show Applied Filters" isDisabled={loading}>
          <Button className="pt-small " iconName="filter" disabled={loading} style={buttonStyle} />
        </Tooltip2>
        <Box p={1} style={{ maxHeight: 450, maxWidth: 600, overflow: 'auto' }}>
          {dashboardItem && (
            <Box mb={dataview.appliedFilters ? 1 : 0}>
              <Box>
                <small>
                  <span className="pt-text-muted">Filter Source:</span>{' '}
                  {filterSources[dashboardItem.get('filter_source')]}
                </small>
              </Box>
              {dashboardItem.get('device_locked') && (
                <Box>
                  <small>
                    <strong>Device Locked</strong>
                  </small>
                </Box>
              )}
              {dashboardItem.get('parametric_mode') &&
                dashboardItem.get('parametric_overrides') && (
                  <Box mt={1}>
                    <small>
                      <strong>Guided Mode Behavior: </strong>
                      <br />
                      {parametricSetting[dashboardItem.get('parametric_mode')]}{' '}
                      {dashboardItem.get('parametric_mode') !== 'ignore' && (
                        <Dimension dimension={dashboardItem.get('parametric_overrides').filterField} />
                      )}
                    </small>
                  </Box>
                )}
            </Box>
          )}

          <small className="pt-strong">Applied Filters:</small>
          <br />
          <FiltersDisplay filters={dataview.appliedFilters} />
        </Box>
      </Popover2>
    );
  }
}
