import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$plans')
class Plans extends Component {
  title = 'Plan';

  url = '/admin/plans';

  render() {
    const { result, $plans } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="material-icons:card_membership"
        url={this.url}
        nameField="name"
        collection={$plans.collection}
        {...this.props}
      >
        <span>{result.description}</span>
      </ResultRow>
    );
  }
}

export default Plans;
