import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import { Button, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/labs';
import { Form, Field } from 'components/forms';
import TransferList from 'components/forms/TransferList';

import { Flex, Box } from 'components/flexbox';
import Dimension from 'components/dimensions/Dimension';

const fields = {
  metric: {}
};

const AvailableDimensionsPopover = ({ options, onSelect }) => (
  <Menu>
    {options.map(dimension => (
      <MenuItem
        key={dimension.value}
        onClick={() => onSelect(dimension.value)}
        iconName="add-to-artifact"
        text={
          <Dimension className=" solid" dimension={dimension.value} useChartTypes>
            {({ className, name }) => (
              <Box
                className={className}
                style={{
                  display: 'inline-block',
                  height: 'auto',
                  lineHeight: '20px',
                  padding: '0px 7px'
                }}
              >
                {name}
              </Box>
            )}
          </Dimension>
        }
      />
    ))}
  </Menu>
);

@Form({ fields })
@inject('$basicExplorer', '$dictionary')
@observer
class Dimensions extends Component {
  componentWillMount() {
    const { $basicExplorer, form } = this.props;
    this.activeDimensionsDisposer = reaction(
      () => $basicExplorer.activeDimensions,
      () => {
        form.init({ metric: $basicExplorer.activeDimensions });
      },
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.activeDimensionsDisposer();
  }

  handleDimensionsChange = (field, dimensions) => {
    const { $basicExplorer } = this.props;
    $basicExplorer.setDimensions(dimensions);
  };

  renderSelectedDimension = ({ value, dragHandle, onSelect }) => (
    <Flex align="center" flexAuto>
      {dragHandle}
      <Box flexAuto>
        <Dimension className="solid pt-tag pt-tag-removable" dimension={value} useChartTypes>
          {({ className, name }) => (
            <Box
              className={className}
              style={{
                display: 'inline-block',
                height: 'auto',
                lineHeight: '20px',
                padding: '0px 20px 0 7px'
              }}
            >
              {name}
              <div
                className="pt-tag-remove"
                onClick={() => onSelect(value)}
                style={{ alignSelf: 'inherit', position: 'absolute' }}
              />
            </Box>
          )}
        </Dimension>
      </Box>
    </Flex>
  );

  render() {
    const { $basicExplorer, $dictionary, template } = this.props;
    const { activeDimensions } = $basicExplorer;
    const { optionsWhitelist } = template.get('options.dimensions');
    const options = $dictionary.flatDimensionOptions.filter(opt => optionsWhitelist.includes(opt.value));

    return (
      <Box onMouseOver={this.handleMouseEvent} onMouseOut={this.handleMouseEvent}>
        <h6 className="pt-text-muted small-caps" style={{ fontSize: 12, margin: '12px 0' }}>
          Dimensions
        </h6>

        {activeDimensions && (
          <Field name="metric" options={options} onChange={this.handleDimensionsChange} className="no-margin">
            <TransferList
              className="pt-vertical pt-align-left pt-fill"
              selectedItemRenderer={this.renderSelectedDimension}
            >
              {({ selectedList, availableOptions, onSelect }) => (
                <Fragment>
                  {selectedList}
                  <Popover2
                    modifiers={{
                      arrow: { enabled: true },
                      flip: { enabled: false },
                      keepTogether: { enabled: true },
                      preventOverflow: { enabled: true, boundariesElement: 'window' }
                    }}
                    placement="bottom"
                    content={<AvailableDimensionsPopover onSelect={onSelect} options={availableOptions} />}
                  >
                    <Button
                      style={{ marginTop: -5 }}
                      className="pt-small pt-minimal pt-text-muted pt-strong"
                      iconName="add"
                      text="Add Dimension"
                    />
                  </Popover2>
                </Fragment>
              )}
            </TransferList>
          </Field>
        )}
      </Box>
    );
  }
}

export default Dimensions;
