import React from 'react';
import { observable, computed } from 'mobx';

import PlanCollection from 'models/plans/PlanCollection';

class PlanStore {
  @observable
  collection = new PlanCollection();

  isCloudPlan(plan) {
    return plan.get('metadata.type') === 'cloud';
  }

  getPlanOption(plan) {
    const devices = plan.get('devices').size;
    const max_devices = plan.get('max_devices');
    return {
      disabled: devices >= max_devices,
      value: plan.id,
      label: (
        <div>
          <div>
            <span>{plan.get('name')}</span>{' '}
            <span className="pt-text-muted">
              ({devices}/{max_devices})
            </span>
          </div>
          <small className="pt-text-muted">{plan.get('description')}</small>
        </div>
      )
    };
  }

  @computed
  get availablePlanOptions() {
    return this.collection.models
      .filter(plan => plan.get('active') && !this.isCloudPlan(plan))
      .map(plan => this.getPlanOption(plan));
  }

  availableCloudPlanOptions(provider) {
    return this.collection.models
      .filter(
        plan =>
          plan.get('active') &&
          this.isCloudPlan(plan) &&
          (provider
            ? plan.get('metadata.cloudProvider') &&
              (plan.get('metadata.cloudProvider') === provider || plan.get('metadata.cloudProvider') === 'universal')
            : true)
      )
      .map(plan => this.getPlanOption(plan));
  }
}

export default new PlanStore();
