import { getMetadata } from 'services/bracketing';
import BaseTagGenerator from './BaseTagGenerator';

class StaticRanges extends BaseTagGenerator {
  constructor() {
    super({ type: 'staticRanges', display: 'Static Ranges' });
  }

  tagResults({ queryResults, tagGenData: bracketOptions }) {
    const { tagKey } = bracketOptions;
    const metadata = getMetadata({ queryResults, bracketOptions });
    queryResults.nonOverlayRows.forEach(row => {
      this.tagRow({ row, tagKey, tagContent: this.generateTagContent({ row, metadata, bracketOptions }) });
    });
  }
}

export { StaticRanges };
