import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import Recaptcha from 'react-grecaptcha';

import { Flex, Box } from 'components/flexbox';
import { showClientErrorToast } from 'components/Toast';
import { Field, Form, Input } from 'components/forms';
import { fields, options } from 'forms/config/login';

// one off's here, just inline style them.
const labelStyle = {
  fontSize: 16,
  fontWeight: 500
};

@Form({ fields, options })
@inject('$auth')
@observer
class LoginForm extends Component {
  state = {};

  handleKeyboardSubmit = e => {
    const { $auth, form } = this.props;
    if (e && e.keyCode === 13) {
      if ($auth.openConfig && form.dirty && form.valid) {
        form.submit(this.handleSubmitWrapper);
      }
    }
  };

  componentDidMount() {
    const { $auth } = this.props;
    document.addEventListener('keydown', this.handleKeyboardSubmit);

    $auth.getOpenConfig().catch(() => {
      showClientErrorToast('Error fetching initial configuration');
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyboardSubmit);
  }

  componentWillUpdate() {
    const { form, $auth } = this.props;
    const showRecaptcha = $auth.openConfig && $auth.openConfig.recaptchaEnabled && $auth.showLoginRecaptcha;
    // if recaptcha is to be shown, update the form rules to make it required.
    form.getField('recaptcha').setRules(showRecaptcha ? 'required' : []);
  }

  handlePasswordReset = () => {
    window.location = '/v4/password';
  };

  handleSubmitWrapper = async (form, data) => {
    const { $auth, handleSubmit } = this.props;
    const showRecaptcha = $auth.openConfig && $auth.openConfig.recaptchaEnabled && $auth.showLoginRecaptcha;
    await handleSubmit(form, data);

    // reset recaptcha after submit.
    if (showRecaptcha) {
      window.grecaptcha.reset();
      this.props.form.getField('recaptcha').setValue(null);
    }
  };

  recaptchaCallback = response => {
    this.props.form.getField('recaptcha').setValue(response);
  };

  recaptchaExpire = () => {
    this.props.form.getField('recaptcha').setValue(null);
  };

  render() {
    const { form, authenticating, authenticated, $auth } = this.props;
    const { openConfig, showLoginRecaptcha } = $auth;
    const showRecaptcha = openConfig && openConfig.recaptchaEnabled && showLoginRecaptcha;

    return (
      <Flex flexColumn justify="center" mt={2}>
        <Field disabled={authenticated || authenticating} name="user_email" autoFocus showLabel={false}>
          <Input className="pt-large" autoFocus leftIconName="user" />
        </Field>
        <Field disabled={authenticated || authenticating} name="password" labelStyle={labelStyle}>
          <Input
            type="password"
            className="pt-large"
            leftIconName="lock"
            rightElement={
              <Button
                iconName="help"
                className="pt-minimal pt-small"
                intent="kentik"
                text="Forgot Password"
                onClick={this.handlePasswordReset}
              />
            }
          />
        </Field>
        {form.error && (
          <Box className="pt-callout pt-intent-danger pt-icon-error" my={2}>
            <h5>Login Failed</h5>
            {form.error}
          </Box>
        )}
        {showRecaptcha && (
          <Box mb={1}>
            <Recaptcha
              sitekey={$auth.openConfig.recaptchaSiteKey}
              callback={this.recaptchaCallback}
              expiredCallback={this.recaptchaExpire}
            />
          </Box>
        )}
        <Button
          className="pt-large pt-intent-kentik"
          text="Login"
          loading={authenticated || authenticating}
          disabled={!$auth.openConfig || !form.dirty || !form.valid}
          onClick={() => form.submit(this.handleSubmitWrapper)}
        />
        {openConfig &&
          !openConfig.subtenancy && (
            <p style={{ marginTop: 5, textAlign: 'center' }}>
              <Link to="/ssolookup">Looking for Single Sign-on?</Link>
            </p>
          )}
      </Flex>
    );
  }
}

export default LoginForm;
