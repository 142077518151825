import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Box } from 'components/flexbox';

const ToggleButtonGroup = props => {
  const { selectedValue, className, children, label, style, disabled, ...boxProps } = props;

  const getButtonProps = buttonProps => {
    const { value, intent, className: buttonClassName } = buttonProps;

    return {
      ...buttonProps,
      disabled,
      intent,
      className: classNames(selectedValue === value && 'pt-active', buttonClassName)
    };
  };

  return (
    <Box className="pt-form-group no-margin" style={style} {...boxProps}>
      {label && <label className="pt-label">{label}</label>}
      <div className="pt-form-content">
        <Box className={`pt-button-group ${className}`}>
          {React.Children.map(children, button => React.cloneElement(button, getButtonProps(button.props)))}
        </Box>
      </div>
    </Box>
  );
};

export default observer(ToggleButtonGroup);
