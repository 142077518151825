import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Flex } from 'components/flexbox';
import api from 'util/api';
import entityTypes from 'util/entityTypes';
import RemoveButton from './RemoveButton';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';

@observer
class FormActions extends Component {
  static defaultProps = {
    className: 'pt-dialog-footer',
    permissions: { create: true, delete: true, edit: true },
    showCancel: true,
    showRemove: true,
    showSave: true
  };

  state = {
    loading: false
  };

  saveLog = ({ action, attributes }) => {
    const { entityName, form } = this.props;
    const entityType = entityTypes[entityName];
    const data = {
      params: {
        objectType: entityType && entityType.type,
        objectName: entityType && entityType.titleField && attributes[entityType.titleField],
        objectId: (entityType && entityType.id && attributes[entityType.id]) || attributes.id, // for entities like dashboard_panel id is their parent id
        parentId: entityType && entityType.parentId && attributes[entityType.parentId],
        action,
        values: action === 'Edit' ? form.getChangedValues() : attributes
      }
    };
    api.post('/api/portal/userLogging/clientEvent', { data });
  };

  handleRemove = () => {
    const { form, onCancel, model } = this.props;
    const attributes = model.toJS();
    const onRemove = this.props.onRemove || form.handleRemove;

    this.saveLog({ action: 'Delete', attributes });

    const promise = onRemove();
    if (promise && typeof promise.then === 'function') {
      promise.then(onCancel);
      return promise;
    }
    onCancel();
    return undefined;
  };

  handleCancel = () => {
    const { form } = this.props;
    const onCancel = this.props.onCancel || form.handleCancel;

    onCancel();
  };

  handleSubmit = (form, values) => {
    const { onCancel } = this.props;
    const onSubmit = this.props.onSubmit || form.handleSave;

    const submit = onSubmit(form, values);

    const action = this.props.model.isNew ? 'Create' : 'Edit';

    if (submit.then) {
      this.setState({ loading: true });
      return submit
        .then(() => {
          this.saveLog({ action, attributes: this.props.model.toJS() });
        })
        .then(() => {
          // Don't setState here because we're closing so it'll warn about memory leaks
          onCancel();
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }

    return onCancel();
  };

  render() {
    const { className, form, model, entityName, showCancel, customElements } = this.props;
    const { loading } = this.state;
    const { permissions } = form;

    const isNew = model && model.isNew;

    const showRemove = this.props.showRemove && !isNew && permissions.delete;
    const showSave = this.props.showSave && ((isNew && permissions.create) || (!isNew && permissions.edit));

    const onRemove = this.handleRemove;
    const onCancel = this.handleCancel;
    const onSubmit = this.handleSubmit;

    const cancelText = permissions.edit ? 'Cancel' : 'Close';
    const submitText = isNew ? `Add ${entityName}` : 'Save';
    const removeButton = this.props.removeButton || <RemoveButton model={model} onRemove={onRemove} />;

    return (
      <Flex className={className}>
        {showRemove && removeButton}
        {customElements}
        <Flex justify="flex-end" flexAuto>
          {showCancel && <CancelButton model={model} onCancel={onCancel} text={cancelText} />}
          {showSave && (
            <SubmitButton model={model} form={form} onSubmit={onSubmit} text={submitText} loading={loading} />
          )}
        </Flex>
      </Flex>
    );
  }
}

export default FormActions;
