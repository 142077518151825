import React, { Component, Fragment } from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, HotkeysTarget, Hotkey, Hotkeys, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';

import { Flex, Box } from 'components/flexbox';
import { Fade, SlideIn, SidebarCollapse } from 'components/animations';
import DashboardDetailsDialog from 'views/Dashboards/DashboardDetailsDialog';
import SavedViewDetailsDialog from 'views/SavedViews/SavedViewDetailsDialog';
import DashboardSidebar from 'views/Dashboards/DashboardSidebar';
import ExplorerQueryModel from 'models/query/ExplorerQueryModel';
import QueryModel from 'models/query/QueryModel';

import BasicExplorerControls from './BasicExplorerControls';
import LibrarySidebar from './LibrarySidebar';
import SubtenantLibrarySidebar from './SubtenantLibrarySidebar';
import SidebarTabs from './SidebarTabs';

const SidebarWrapper = ({ onScroll, scrollRef, styles, children, footer }) => (
  <Flex
    flexColumn
    style={{
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      paddingRight: 1,
      ...styles
    }}
  >
    <Fragment>
      <div
        className="overflow-auto"
        style={{ height: '100%', width: '100%', paddingTop: 6, paddingBottom: 6 }}
        onScroll={onScroll}
        ref={scrollRef}
      >
        {children}
      </div>
      {footer}
    </Fragment>
  </Flex>
);

@inject(
  '$app',
  '$basicExplorer',
  '$dashboard',
  '$dashboards',
  '$library',
  '$recentlyViewed',
  '$savedViews',
  '$queryTemplates'
)
@HotkeysTarget
@observer
class BasicExplorerSidebar extends Component {
  state = {
    activeTab: 'library',
    showReportControls: false,
    dashboardModel: undefined,
    savedViewModel: undefined,
    openDashboardOnSave: false,
    scrolling: false
  };

  scrollingTimeout;

  loadingFailedDisposer;

  renderHotkeys() {
    const { $library, reports } = this.props;
    const isDashboard = reports.selected && reports.selected.get('type') === 'dashboard';

    return (
      <Hotkeys>
        {isDashboard && (
          <Hotkey
            global
            group="Dashboard"
            combo="shift + enter"
            label="Apply Changes"
            onKeyDown={this.handleDashboardSubmit}
          />
        )}
        {isDashboard && (
          <Hotkey
            global
            group="Dashboard"
            combo="esc"
            label="Discard Changes Changes"
            onKeyDown={this.handleResetDashboardSidebar}
          />
        )}
        <Hotkey
          global
          group="Sidebar"
          combo="shift + ["
          allowInInput
          label="Collapse Sidebar"
          onKeyDown={$library.toggleSidebarCollapse}
        />
      </Hotkeys>
    );
  }

  componentWillMount() {
    const { $library, params } = this.props;
    const { templateIdOrHash, type } = params;

    if (templateIdOrHash && type !== 'savedView') {
      this.state.showReportControls = true;
      this.state.activeTab = 'controls';
    }

    this.loadingFailedDisposer = reaction(
      () => $library.loadingFailed,
      () => {
        if ($library.loadingFailed) {
          this.setState({
            showReportControls: false,
            activeTab: 'library'
          });
        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    const {
      params: { templateIdOrHash }
    } = this.props;
    const {
      params: { templateIdOrHash: newTemplateIdOrHash, type }
    } = nextProps;

    if (!newTemplateIdOrHash) {
      this.state.showReportControls = false;
      this.state.activeTab = 'library';
    } else if (newTemplateIdOrHash && type !== 'savedView' && newTemplateIdOrHash !== templateIdOrHash) {
      this.state.showReportControls = true;
      this.state.activeTab = 'controls';
    }
  }

  componentWillUnmount() {
    if (this.loadingFailedDisposer) {
      this.loadingFailedDisposer();
    }
  }

  getScrollRef = () => (this.state.activeTab === 'controls' ? this.controlsScrollRef : this.libraryScrollRef);

  handleSidebarScroll = () => {
    this.setState({ scrolling: true, scrolled: this.getScrollRef().scrollTop > 10 });

    if (this.scrollingTimeout) {
      clearTimeout(this.scrollingTimeout);
    }

    this.scrollingTimeout = setTimeout(() => {
      this.setState({ scrolling: false });
    }, 250);
  };

  handleLibraryScrollRef = refs => {
    this.libraryScrollRef = refs;
  };

  handleControlsScrollRef = refs => {
    this.controlsScrollRef = refs;
  };

  handleCreateDashboard = () => {
    const { $library } = this.props;
    const dashboardModel = $library.reportsCollection.forgeDashboard({}, { select: false });
    this.setState({ dashboardModel, openDashboardOnSave: false });
  };

  handleSelectReport = (report, options) => {
    const { $library } = this.props;
    $library.selectReport(report, options);

    // always toggle back to report controls if they select.
    if (report.type !== 'Saved View') {
      this.handleChangeTab('controls');
    }
  };

  handleShowReportProperties = reportModel => {
    const { $dashboards, $savedViews } = this.props;
    const type = reportModel.get('type');
    const isDashboard = type === 'dashboard';

    // dashboard or a savedView
    const report = isDashboard ? $dashboards.getDashboard(reportModel.id) : $savedViews.getSavedView(reportModel.id);

    this.setState({
      dashboardModel: isDashboard && report,
      savedViewModel: !isDashboard && report,
      openDashboardOnSave: isDashboard
    });
  };

  onDashboardSave = dashboard => {
    const { $dashboards, $library } = this.props;
    const { openDashboardOnSave } = this.state;

    if (!openDashboardOnSave) {
      $library.selectReport(dashboard);
    } else {
      // Don't overwrite items/layout in case it's open
      const { items, layout, ...rest } = dashboard.toJS();
      $dashboards.getDashboard(dashboard.id).set(rest);
    }

    this.setState({ dashboardModel: undefined });
  };

  handleSavedViewSave = attributes => {
    const { $savedViews } = this.props;
    $savedViews.getSavedView(attributes.id).set(attributes);
    this.setState({ savedViewModel: undefined });
  };

  handleResetDashboardSidebar = () => {
    const { $dashboard } = this.props;
    const { dirty } = $dashboard.formState;

    if (dirty) {
      $dashboard.formState.reset();
    }
  };

  handleDashboardSubmit = () => {
    const { $dashboard } = this.props;

    $dashboard.formState.submit((form, values) => {
      $dashboard.setCompletedInitialParametricSubmit(true);
      $dashboard.dashboard.set(values);
      $dashboard.handleQueryUpdate(values);
    });
  };

  handleSidebarCollapse = () => {
    const { $library } = this.props;
    $library.toggleSidebarCollapse();
  };

  onSidebarCollapse = () => {
    const { $dashboard, $basicExplorer, reports } = this.props;
    if (reports.selected && reports.selected.get('type') === 'dashboard') {
      setTimeout(() => $dashboard.reflowItems(), 250);
    } else {
      setTimeout(() => $basicExplorer.dataview.reflow(), 250);
    }
  };

  handleChangeTab = tab => {
    const showReportControls = tab === 'controls';
    const scrollRef = showReportControls ? this.controlsScrollRef : this.libraryScrollRef;

    this.setState({ activeTab: tab, showReportControls, scrolled: scrollRef.scrollTop > 10 });
  };

  render() {
    const {
      $app,
      $library,
      $dashboard,
      $dashboards,
      reports,
      loading,
      $queryTemplates: { categories }
    } = this.props;
    const { activeTab, showReportControls, dashboardModel, savedViewModel, scrolled, scrolling } = this.state;

    let reportControls;
    if (reports.selected) {
      const type = reports.selected.get('type');
      if (type === 'report') {
        reportControls = <BasicExplorerControls template={reports.selected} />;
      } else if (type === 'dashboard' && $dashboard.initialized && reports.selected.id === $dashboard.dashboard.id) {
        const query = $dashboard.dashboard.get('query');
        const model = ExplorerQueryModel.createFromQueryModel(QueryModel.create(query));

        model.set('parametric_fields', $dashboard.dashboard.get('parametric_fields'));

        reportControls = <DashboardSidebar model={model} />;
      }
    }

    const loadingLibraryTab = loading && activeTab === 'library';
    const sidebarClassName = classNames('basic-explorer-sidebar overflow-hidden');

    return (
      <SidebarCollapse
        in={!$library.sidebarCollapsed}
        onEntered={this.onSidebarCollapse}
        onExited={this.onSidebarCollapse}
      >
        {({ styles: sidebarStyles }) => (
          <Flex flexColumn className={sidebarClassName} style={sidebarStyles}>
            <Fade in={!$library.sidebarCollapsed} timeout={0} mountOnEnter unmountOnExit>
              {({ styles: tabStyle }) => (
                <Flex
                  align="center"
                  justify="space-between"
                  className={classNames('library-sidebar-tabs-wrapper', { 'pt-elevation-1': scrolled })}
                  style={{ zIndex: 1, ...tabStyle }}
                  pr={$library.sidebarCollapsed ? 0 : 1}
                >
                  <SidebarTabs activeTab={activeTab} onChange={this.handleChangeTab} report={reports.selected} />
                  <Button className="pt-minimal" iconName="double-chevron-left" onClick={this.handleSidebarCollapse} />
                </Flex>
              )}
            </Fade>

            <Fade in={loadingLibraryTab} timeout={{ enter: 0, exit: 150 }} mountOnEnter unmountOnExit>
              {({ styles }) => (
                <Flex justify="center" align="center" style={styles} p={2} className="pt-text-muted">
                  <Spinner className="pt-small" />
                  <small style={{ paddingLeft: 4 }}>Loading...</small>
                </Flex>
              )}
            </Fade>

            <Fade in={$library.sidebarCollapsed} timeout={100} mountOnEnter unmountOnExit>
              {({ styles }) => (
                <Button
                  className="pt-minimal"
                  iconName="double-chevron-right"
                  onClick={this.handleSidebarCollapse}
                  style={{ flex: '1 1 auto', borderRadius: 0, ...styles }}
                />
              )}
            </Fade>

            <Box style={{ position: 'relative', overflow: 'hidden', width: 275 }} flexAuto={!$library.sidebarCollapsed}>
              <SlideIn
                in={!$library.sidebarCollapsed && showReportControls && !!reports.selected && !loadingLibraryTab}
                from="right"
                timeout={0}
              >
                {({ styles }) => (
                  <SidebarWrapper
                    styles={styles}
                    onScroll={this.handleSidebarScroll}
                    scrollRef={this.handleControlsScrollRef}
                  >
                    {reportControls}
                  </SidebarWrapper>
                )}
              </SlideIn>

              <SlideIn
                in={!$library.sidebarCollapsed && !showReportControls && !loadingLibraryTab}
                from="left"
                timeout={0}
              >
                {({ styles }) => (
                  <SidebarWrapper
                    styles={styles}
                    onScroll={this.handleSidebarScroll}
                    scrollRef={this.handleLibraryScrollRef}
                  >
                    {!$app.isSubtenant && (
                      <LibrarySidebar
                        onDashboardCreate={this.handleCreateDashboard}
                        onSelect={this.handleSelectReport}
                        onShowProperties={this.handleShowReportProperties}
                        reports={reports}
                        scrolling={scrolling}
                        queryTemplateCategories={categories}
                      />
                    )}
                    {$app.isSubtenant && (
                      <SubtenantLibrarySidebar
                        reports={reports}
                        onSelect={this.handleSelectReport}
                        scrolling={scrolling}
                        queryTemplateCategories={categories}
                      />
                    )}
                  </SidebarWrapper>
                )}
              </SlideIn>
            </Box>

            {dashboardModel && (
              <DashboardDetailsDialog
                isOpen={dashboardModel}
                onSubmit={this.onDashboardSave}
                title={dashboardModel.isNew ? 'Add Dashboard' : 'Dashboard Properties'}
                onClose={() => this.setState({ dashboardModel: undefined })}
                model={dashboardModel}
                collection={$dashboards.collection}
              />
            )}

            {savedViewModel && (
              <SavedViewDetailsDialog
                isOpen={savedViewModel}
                onClose={() => this.setState({ savedViewModel: undefined })}
                title="Saved View Properties"
                model={savedViewModel}
                onSave={this.handleSavedViewSave}
              />
            )}
          </Flex>
        )}
      </SidebarCollapse>
    );
  }
}

export default withRouter(BasicExplorerSidebar);
