import React, { Component } from 'react';
import { inject } from 'mobx-react';
import ResultRow from 'views/Search/ResultRow';

@inject('$clouds')
class Clouds extends Component {
  title = 'Cloud';

  url = '/admin/clouds';

  render() {
    const { result, $clouds } = this.props;

    return (
      <ResultRow
        type={this.title}
        icon="cloud"
        url={this.url}
        nameField="name"
        collection={$clouds.collection}
        {...this.props}
      >
        <span>provider: {result.cloud_provider}</span>
      </ResultRow>
    );
  }
}

export default Clouds;
