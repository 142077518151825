import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { Form } from 'components/forms';
import DeviceSelector2 from 'components/forms/device/DeviceSelector2';
import { Flex, Box } from 'components/flexbox';
import { FilterOptions, TimeOptions } from 'components/sidebar';
import { MaterialIcon } from 'components/Icon';

import { fields, options } from 'forms/config/dashboardQuery';
import { getDisabledCloudDimensions, getDisabledSubtypeDimensions } from 'util/devices';

import ApplyParametricOptions from './ApplyParametricOptions';

const SectionHeader = ({ title, className }) => (
  <h6 className={classNames('pt-text-muted small-caps', className)} style={{ fontSize: 12, marginBottom: 12 }}>
    {title}
  </h6>
);

@Form({ fields, options })
@inject('$dashboard', '$devices', '$dictionary', '$app')
@observer
export default class DashboardSidebar extends Component {
  state = {
    isEditingFilters: false
  };

  componentDidMount() {
    this.updateFormState(this.props);
  }

  updateFormState(props) {
    const { $dashboard, form } = props;

    if (form && $dashboard.formState !== form) {
      $dashboard.registerFormState(form);
    }
  }

  handleToggleEditFilters = () => {
    this.setState({ isEditingFilters: !this.state.isEditingFilters });
  };

  handleCancel = () => {
    const { form } = this.props;
    form.reset();
  };

  handleSubmit = () => {
    const { $dashboard } = this.props;
    $dashboard.formState.submit(() => {
      const values = $dashboard.formState.getValues();
      $dashboard.setCompletedInitialParametricSubmit(true);
      $dashboard.dashboard.set(values);
      $dashboard.handleQueryUpdate(values);
    });
  };

  render() {
    const {
      form,
      $devices,
      $dashboard,
      $dictionary: { dictionary },
      $app: { isSubtenant }
    } = this.props;
    const { isEditingFilters } = this.state;

    const { dashboard } = $dashboard;
    const device_name = form.getValue('device_name');
    const device_labels = form.getValue('device_labels');
    const device_sites = form.getValue('device_sites');
    const device_types = form.getValue('device_types');
    const all_devices = form.getValue('all_devices');
    const hasKProbeSelected = all_devices
      ? $devices.hasDnsProbe
      : $devices.containsDnsProbe({ device_name, device_labels, device_sites, device_types });

    const disabledCloudDimensions = getDisabledCloudDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types
    });

    const disabledSubtypeDimensions = getDisabledSubtypeDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types
    });

    const disabledKProbeDimensions = !hasKProbeSelected ? dictionary.kprobeDimensions : [];

    const disabledDimensions = [...disabledCloudDimensions, ...disabledKProbeDimensions, ...disabledSubtypeDimensions];

    const disabledFilters = disabledDimensions;

    return (
      <Box className="basic-explorer-options-panel basic-explorer-dashboard" p={2} pt={1}>
        <Flex mb={2}>
          <Button
            text="Reset"
            className="pt-fill"
            onClick={this.handleCancel}
            disabled={!form.dirty}
            style={{ marginRight: 8 }}
          />
          <Button
            text="Apply"
            className="pt-fill pt-strong pt-intent-primary"
            onClick={this.handleSubmit}
            disabled={(!dashboard.isIncompleteParametric && !form.dirty) || !form.valid}
          />
        </Flex>

        {dashboard &&
          dashboard.isParametric && (
            <Box mb={1}>
              <ApplyParametricOptions handleSubmit={this.handleSubmit} dashboard={dashboard}>
                {({ inputField, question }) => (
                  <Box className="pt-callout pt-intent-primary" p={1} mb={1}>
                    <SectionHeader
                      className="pt-intent-primary-text"
                      title={
                        <Flex align="center">
                          <MaterialIcon
                            name="question_answer"
                            className="pt-intent-primary"
                            style={{ marginRight: 6, fontSize: 16 }}
                          />{' '}
                          {question}
                        </Flex>
                      }
                    />
                    {inputField}
                  </Box>
                )}
              </ApplyParametricOptions>
            </Box>
          )}
        {dashboard && (
          <Fragment>
            <SectionHeader title="Time Range" />
            <Box className="sidebar-time">
              <TimeOptions />
            </Box>

            <hr className="sidebar-separator" style={{ margin: '16px 0' }} />

            {!isSubtenant && (
              <Fragment>
                <SectionHeader title="Filters" />
                <FilterOptions
                  isOpen={isEditingFilters}
                  onClose={this.handleToggleEditFilters}
                  allowNestedFilters
                  disabledDimensions={disabledFilters}
                />
                <Box mt={1}>
                  <Button className="pt-small" onClick={this.handleToggleEditFilters}>
                    Edit Filters
                  </Button>
                </Box>

                <hr className="sidebar-separator" style={{ margin: '16px 0' }} />

                <SectionHeader title="Devices" />
                <DeviceSelector2 />
              </Fragment>
            )}
          </Fragment>
        )}
      </Box>
    );
  }
}
