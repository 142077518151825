import React, { Component } from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box } from 'components/flexbox';
import Icon from 'components/Icon';
import { Form, Field, Select } from 'components/forms/index';

const valueRenderer = (option, placeholder) => {
  if (!option) {
    return <span className="pt-text-muted">{placeholder || 'Select a value...'}</span>;
  }

  return (
    <span>
      <Icon name="time" style={{ marginRight: 7 }} />
      {option && option.label}
    </span>
  );
};

const fields = {
  lookback_seconds: {}
};

@Form({ fields })
@inject('$basicExplorer', '$dictionary')
@observer
class BasicExplorerTimeOptions extends Component {
  componentWillMount() {
    const { $basicExplorer, form } = this.props;
    this.activeLookbackDisposer = reaction(
      () => $basicExplorer.activeLookbackSeconds,
      lookback_seconds => {
        form.init({ lookback_seconds });
      },
      { fireImmediately: true }
    );
  }

  handleRunQuery = () => {
    const { $basicExplorer, form } = this.props;
    const { lookback_seconds } = form.getValues();
    $basicExplorer.setLookbackSeconds(lookback_seconds);
  };

  componentWillUnmount() {
    this.activeLookbackDisposer();
  }

  render() {
    const { $dictionary } = this.props;
    const lookbackOptions = $dictionary
      .getSelectOptions('showLast', { parseKeys: true })
      .filter(opt => opt.label !== 'Custom')
      .map(opt => ({ ...opt, iconCls: 'clock' }));

    return (
      <Box className="basic-explorer-time-options">
        <h6 className="pt-text-muted small-caps" style={{ fontSize: 12, marginBottom: 12 }}>
          Time Range
        </h6>

        <Field
          name="lookback_seconds"
          className="no-margin"
          showLabel={false}
          options={lookbackOptions}
          onChange={this.handleRunQuery}
        >
          <Select
            valueRenderer={valueRenderer}
            menuWidth={243}
            tetherOptions={{
              constraints: [{ attachment: 'together', pin: true, to: 'scrollParent' }]
            }}
          />
        </Field>
      </Box>
    );
  }
}

export default BasicExplorerTimeOptions;
