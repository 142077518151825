import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button } from '@blueprintjs/core';

import { Box, Flex } from 'components/flexbox';
import { MaterialIcon } from 'components/Icon';
import { DashboardSelector, Field, Switch, RadioGroup, Input } from 'components/forms';
import FiltersDisplay from 'components/FiltersDisplay/FiltersDisplay';
import FilterOptions from 'components/sidebar/FilterOptions';
import { DeviceDisplay, TimeDisplay, TimeOptions } from 'components/sidebar';
import PopoverLabel from 'components/PopoverLabel';
import DeviceSelector2 from 'components/forms/device/DeviceSelector2';

@inject('$dashboards')
@observer
export default class NavigateDashboard extends Component {
  static contextTypes = {
    form: any
  };

  constructor(props, context) {
    super(props, context);

    const { form } = context;
    let selectedDashboard = form.getValue('destination_dashboard');
    if (selectedDashboard) {
      selectedDashboard = props.$dashboards.getDashboard(selectedDashboard);
    }

    this.setDeviceRules(form.getValue('dashboard_navigation_options.devices'));

    this.state = {
      isDashboardNavigationEnabled: form.getValue('dashboard_navigation'),
      editingFilters: false,
      selectedDashboard
    };
  }

  getDeviceOptions = dashboard => [
    {
      label: 'Use Devices from SOURCE dashboard',
      value: 'source_dashboard'
    },
    {
      label: (
        <PopoverLabel
          label="Use"
          popoverText="Devices from DESTINATION dashboard"
          content={<DeviceDisplay query={dashboard.originalQuery} />}
        />
      ),
      value: 'destination_dashboard'
    },
    {
      label: 'Use Devices from SOURCE panel',
      value: 'source_panel'
    },
    {
      label: 'Use Custom Device List',
      value: 'custom'
    }
  ];

  getTimeRangeOptions = dashboard => [
    {
      label: 'Use Time Range from SOURCE dashboard',
      value: 'source_dashboard'
    },
    {
      label: (
        <PopoverLabel
          label="Use"
          popoverText="Time Range from DESTINATION dashboard"
          content={<TimeDisplay {...dashboard.originalQuery} />}
        />
      ),
      value: 'destination_dashboard'
    },
    {
      label: 'Use Time Range from SOURCE panel',
      value: 'source_panel'
    },
    {
      label: 'Use Custom Time Range',
      value: 'custom'
    }
  ];

  getFilterOptions = dashboard => [
    {
      label: 'Use Filters from SOURCE dashboard',
      value: 'source_dashboard'
    },
    {
      label: (
        <PopoverLabel
          label="Use"
          popoverText="Filters from DESTINATION dashboard"
          content={<FiltersDisplay filters={dashboard.originalQuery.filters || dashboard.originalQuery.filters_obj} />}
        />
      ),
      value: 'destination_dashboard'
    },
    {
      label: 'Use SOURCE and DESTINATION dashboard filters',
      value: 'source_destination'
    },
    {
      label: 'Use Filters from SOURCE panel',
      value: 'source_panel'
    },
    {
      label: 'Use Custom Filters',
      value: 'custom'
    }
  ];

  onDashboardNavigationChange = () => {
    const isDashboardNavigationEnabled = !this.state.isDashboardNavigationEnabled;

    this.setState({ isDashboardNavigationEnabled }, () => {
      if (!isDashboardNavigationEnabled) {
        const { form } = this.context;

        // "Clear out" fields that could have validation issues
        form.setValue('dashboard_navigation_options.parametric', 'prompt');
      }
    });
  };

  onDeviceOptionsChange = ({ value }) => {
    this.setDeviceRules(value);
  };

  handleDashboardSelect = (field, value) => {
    const { $dashboards } = this.props;
    this.setState(() => ({ selectedDashboard: $dashboards.getDashboard(value) }));
  };

  toggleFiltersEditing = () =>
    this.setState(({ editingFilters: prevEditingFilters }) => ({ editingFilters: !prevEditingFilters }));

  setDeviceRules(value) {
    this.context.form.getField('dashboard_navigation_options.custom.all_devices').setRules(
      value === 'custom'
        ? [
            {
              atLeastOneIfNotAllDevices: [
                'dashboard_navigation_options.custom.device_name',
                'dashboard_navigation_options.custom.device_labels',
                'dashboard_navigation_options.custom.device_types',
                'dashboard_navigation_options.custom.device_sites'
              ]
            }
          ]
        : ''
    );
  }

  render() {
    const { form } = this.context;
    const { $dashboards } = this.props;
    const { isDashboardNavigationEnabled, selectedDashboard, editingFilters } = this.state;
    const parametric = selectedDashboard && selectedDashboard.get('parametric');

    const isCustomDevicesSelected = form.getValue('dashboard_navigation_options.devices') === 'custom';
    const isCustomTimeRangeSelected = form.getValue('dashboard_navigation_options.time_range') === 'custom';
    const isCustomFiltersSelected = form.getValue('dashboard_navigation_options.filters') === 'custom';

    const parametricOptions = [
      {
        label: 'Use filter value from this Guided Dashboard',
        value: 'applyFrom',
        disabled: !$dashboards.getDashboard(form.getValue('dashboard_id')).get('parametric')
      },
      {
        label: (
          <span>
            Use Drill Down selection
            <br />
            <span className="pt-text-muted">(if no selection, user will be prompted)</span>
          </span>
        ),
        value: 'drillDown'
      },
      {
        label: 'Prompt User',
        value: 'prompt'
      }
    ];

    return (
      <Flex flexColumn className="overflow-auto" flexAuto>
        <h5>
          <MaterialIcon
            name="arrow_forward"
            className="pt-text-muted"
            style={{ fontSize: 20, verticalAlign: 'bottom' }}
          />
          <MaterialIcon
            name="dashboard"
            className="pt-text-muted"
            style={{ marginRight: 8, fontSize: 20, verticalAlign: 'bottom' }}
          />
          Navigate To
        </h5>

        <p className="pt-text-muted">Configure navigation from this dashboard to another.</p>
        <Box mt={2}>
          <Field
            name="dashboard_navigation"
            labelStyle={{ textTransform: 'none' }}
            onChange={this.onDashboardNavigationChange}
          >
            <Switch />
          </Field>
        </Box>

        {isDashboardNavigationEnabled && (
          <Box className="pt-card flat" p={2}>
            <Flex flexAuto>
              <div>
                <DashboardSelector
                  name="destination_dashboard"
                  className="no-margin"
                  dashboard={selectedDashboard}
                  hiddenDashboards={[parseInt(form.getValue('dashboard_id'), 10)]}
                  onChange={this.handleDashboardSelect}
                  menuWidth={355}
                  tetherOptions={{
                    offset: '-3px 0',
                    constraints: [{ attachment: 'together', pin: true, to: 'window' }]
                  }}
                />
                {isDashboardNavigationEnabled &&
                  selectedDashboard && (
                    <Box mt={3}>
                      <label className="pt-label">Destination Dashboard Settings</label>
                      <Box ml={2} mt={2}>
                        <label className="pt-label">
                          <MaterialIcon name="storage" style={{ marginRight: 4, verticalAlign: 'bottom' }} />
                          Devices
                        </label>
                        <Field
                          name="dashboard_navigation_options.devices"
                          options={this.getDeviceOptions(selectedDashboard)}
                          onChange={this.onDeviceOptionsChange}
                          showLabel={false}
                        >
                          <RadioGroup inline={false} />
                        </Field>
                        {isCustomDevicesSelected && (
                          <Box mb={2} ml={2} pl={1.5} style={{ width: 270 }}>
                            <DeviceSelector2
                              allDevicesFieldName="dashboard_navigation_options.custom.all_devices"
                              deviceNameFieldName="dashboard_navigation_options.custom.device_name"
                              deviceTypesFieldName="dashboard_navigation_options.custom.device_types"
                              deviceSitesFieldName="dashboard_navigation_options.custom.device_sites"
                              deviceLabelsFieldName="dashboard_navigation_options.custom.device_labels"
                            />
                          </Box>
                        )}
                        <label className="pt-label">
                          <MaterialIcon name="access_time" style={{ marginRight: 4, verticalAlign: 'bottom' }} />
                          Time Range
                        </label>
                        <Field
                          name="dashboard_navigation_options.time_range"
                          options={this.getTimeRangeOptions(selectedDashboard)}
                          showLabel={false}
                        >
                          <RadioGroup inline={false} />
                        </Field>
                        {isCustomTimeRangeSelected && (
                          <Box mb={2} ml={2} pl={1.5} style={{ width: 270 }}>
                            <TimeOptions showJumpButtons={false} rootFieldName="dashboard_navigation_options.custom" />
                          </Box>
                        )}
                        <label className="pt-label">
                          <MaterialIcon name="filter_list" style={{ marginRight: 4, verticalAlign: 'bottom' }} />
                          Filters
                        </label>
                        <Field
                          name="dashboard_navigation_options.filters"
                          options={this.getFilterOptions(selectedDashboard)}
                          showLabel={false}
                          className="no-margin"
                        >
                          <RadioGroup inline={false} />
                        </Field>
                        {isCustomFiltersSelected && (
                          <div>
                            <Flex ml={2} pl={1.5} style={{ width: 360 }}>
                              <FilterOptions
                                fieldName="dashboard_navigation_options.custom.filters"
                                isOpen={editingFilters}
                                onClose={this.toggleFiltersEditing}
                              />
                            </Flex>
                            <Button
                              className="pt-small pt-medium"
                              style={{ marginLeft: 28, marginTop: 8 }}
                              onClick={this.toggleFiltersEditing}
                              iconName="edit"
                              text="Edit Filters"
                            />
                          </div>
                        )}
                      </Box>
                    </Box>
                  )}
              </div>
              <Box ml={4} flexAuto>
                {parametric && (
                  <Box className="pt-callout" my={3}>
                    <label className="pt-label">
                      <MaterialIcon
                        name="question_answer"
                        className="pt-text-muted"
                        style={{ fontSize: 18, marginRight: 8, verticalAlign: 'bottom' }}
                      />
                      Guided Mode Settings
                    </label>
                    <Field
                      name="dashboard_navigation_options.parametric"
                      options={parametricOptions}
                      inputGroupClassName="destination-parametric-radio-group"
                      style={{ marginLeft: 16, marginBottom: 0 }}
                    >
                      <RadioGroup inline={false} />
                    </Field>
                  </Box>
                )}
                {selectedDashboard && (
                  <Fragment>
                    <Field name="dashboard_navigation_options.nesting.drillDownButtonText">
                      <Input />
                    </Field>
                    <Field name="dashboard_navigation_options.nesting.parentPanelVisibility">
                      <RadioGroup />
                    </Field>
                  </Fragment>
                )}
              </Box>
            </Flex>
          </Box>
        )}
      </Flex>
    );
  }
}
