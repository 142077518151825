import React from 'react';
import classNames from 'classnames';
import { Intent } from '@blueprintjs/core';

export const MaterialIcon = props => {
  const { name, className, ...otherProps } = props;

  const iconClassNames = classNames(
    {
      'material-icons': true,
      'md-18': true
    },
    className
  );

  return (
    <i {...otherProps} className={iconClassNames}>
      {name}
    </i>
  );
};

const Icon = props => {
  const { name, large, xlarge, muted, intent, ...otherProps } = props;
  const iconName = name && !name.includes('pt') ? `pt-icon-${name}` : name;
  const intentClass = Intent[intent] ? `pt-intent-${Intent[intent].toLowerCase()}` : '';

  const className = classNames(
    {
      'pt-icon-standard': true,
      'pt-icon-large': large,
      'pt-icon-xlarge': xlarge,
      'pt-text-muted': muted,
      [iconName]: true,
      [intentClass]: true
    },
    props.className
  );

  return <span {...otherProps} className={className} />;
};

export default Icon;
