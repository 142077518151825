import Collection from 'models/Collection';
import Scoreboard from './Scoreboard';

export default class ScoreboardCollection extends Collection {
  get url() {
    return '/api/portal/alerts/scoreboards';
  }

  get model() {
    return Scoreboard;
  }
}
