import request from 'superagent';
import { action } from 'mobx';

import { showNetworkErrorToast, showClientErrorToast } from 'components/Toast';
import $auth from 'stores/$auth';
import { safelyParseJSON } from 'util/utils';

/*
 * Takes provided promise and overrides its then() with an implementation that will wrap callbacks given to it as
 * mobx actions. This should reduce boilerplate/gotchas around mobx strict mode.
 */
function thenInActionify(promise) {
  const originalThen = promise.then;

  promise.then = (success, error) => {
    const maybePromise = originalThen.call(promise, action(success), error && action(error));

    if (Object.prototype.hasOwnProperty.call(maybePromise, 'then')) {
      return thenInActionify(maybePromise);
    }

    return maybePromise;
  };

  return promise;
}

function makeRequest(path, options, wrapActions = true) {
  let method = (options.method || 'GET').toLowerCase();
  const rawResponse = !!options.rawResponse;

  if (!request[method]) {
    method = 'get';
  }

  let promise = request[method](path);
  promise.withCredentials().set('X-CSRF-Token', $auth.csrfToken);

  if (options.body) {
    promise = promise.send(options.body);
  } else {
    promise.set('Content-Type', 'application/json');
  }

  if (options.data) {
    promise = promise.send(JSON.stringify(options.data));
  }

  if (options.query) {
    promise = promise.query(options.query);
  }

  promise = promise.then(
    response => {
      const { headers } = response;

      if (headers && Object.prototype.hasOwnProperty.call(headers, 'x-2fa-required')) {
        $auth.twoFactorVerifyRequired = true;
      }

      // if selected, return raw response before body processing.
      if (rawResponse) {
        return response;
      }

      if (response.body || response.text.length > 0) {
        const json = safelyParseJSON(response.text);
        return response.body || json;
      }
      return response;
    },
    error => {
      const { response = {} } = error;
      const { status, headers } = response;

      if (headers && Object.prototype.hasOwnProperty.call(headers, 'x-2fa-required')) {
        $auth.twoFactorVerifyRequired = true;
      }

      if (headers && Object.prototype.hasOwnProperty.call(headers, 'x-2fa-retry-after')) {
        $auth.setTwoFactorDelay(parseInt(headers['x-2fa-retry-after']));
        setTimeout(() => {
          $auth.setTwoFactorDelay(0);
        }, $auth.twoFactorDelay * 1000);
      } else {
        $auth.setTwoFactorDelay(0);
      }

      if (status === 401) {
        $auth.logout();
      } else if (options.showErrorToast !== false) {
        if (status >= 500) {
          showNetworkErrorToast(response || 'Unknown error', error);
        } else {
          showClientErrorToast(response);
        }
      }

      if (rawResponse || options.rawError) {
        throw response;
      }

      throw response.statusText;
    }
  );

  if (wrapActions) {
    return thenInActionify(promise);
  }

  return promise;
}

const methodMap = {
  get: 'GET',
  put: 'PUT',
  post: 'POST',
  patch: 'PATCH',
  del: 'DELETE'
};

const methods = Object.keys(methodMap).reduce((result, method) => {
  result[method] = (path, options = {}, wrapActions) =>
    makeRequest(path, { ...options, method: methodMap[method] }, wrapActions);

  return result;
}, {});

export default methods;
