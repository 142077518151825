import React from 'react';
import { observer } from 'mobx-react';
import { Button, Menu, Popover, Position, MenuItem } from '@blueprintjs/core';
import { Box } from 'components/flexbox';

const tetherOptions = {
  offset: '-5px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'scrollParent' }]
};

const handleFilterSelect = (filter, collection) => {
  if (collection.activePresetFilter && collection.activePresetFilter.label === filter.label) {
    collection.setPresetFilter();
  } else {
    collection.setPresetFilter(filter);
  }
};

const PresetFilterSelect = props => {
  const {
    collection,
    className,
    style,
    allLabelText = 'All',
    buttonText = 'Show',
    hideMenuOnSelect = false,
    iconName,
    hideFilters = [],
    ml = 2,
    ...otherProps
  } = props;
  const { activePresetFilter, presetFilters } = collection;

  if (!presetFilters || presetFilters.length === 0) {
    return null;
  }

  const loadingOrNotFetched = collection.isRequestActive() || (!collection.hasFetched && collection.size === 0);

  return (
    <Box ml={ml} {...otherProps}>
      <Popover position={Position.BOTTOM_LEFT} popoverClassName="pt-minimal" tetherOptions={tetherOptions}>
        <Button iconName={iconName} rightIconName="chevron-down" disabled={loadingOrNotFetched} style={style}>
          <span>
            <strong>{buttonText}: </strong> {activePresetFilter ? activePresetFilter.label : allLabelText}
          </span>
        </Button>
        <Menu>
          <MenuItem
            disabled={loadingOrNotFetched}
            iconName={!activePresetFilter ? 'small-tick' : 'blank'}
            onClick={() => collection.setPresetFilter()}
            shouldDismissPopover={hideMenuOnSelect}
            text={allLabelText}
          />
          {presetFilters.map(filter => {
            if (hideFilters.includes(filter.label)) {
              return null;
            }

            const isActive =
              !collection.isRequestActive() && activePresetFilter && activePresetFilter.label === filter.label;

            const count = collection.counts.get(filter.label);

            return (
              <MenuItem
                className={isActive ? 'pt-strong' : filter.className}
                disabled={filter.disabled || loadingOrNotFetched}
                iconName={isActive ? 'small-tick' : 'blank'}
                intent={filter.intent}
                key={filter.label}
                onClick={() => handleFilterSelect(filter, collection)}
                shouldDismissPopover={hideMenuOnSelect}
                text={filter.label}
                label={count}
              />
            );
          })}
        </Menu>
      </Popover>
    </Box>
  );
};

export default observer(PresetFilterSelect);
