import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Form, FormDialog } from 'components/forms';
import { fields, options } from 'forms/config/bracketOptions';
import BracketOptionsForm from './BracketOptionsForm';

@Form({ fields, options })
@inject('$explorer')
@observer
export default class BracketOptionsDialog extends Component {
  static defaultProps = {
    fieldName: ''
  };

  // BracketOptionsDialog is last level we have access to the bracketOptions dialog form, so handle form.reset
  // it when dialog goes away. Doing form.reset in the normal handlers clears form before dialog closes and looks odd.
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && this.props.isOpen !== prevProps.isOpen) {
      this.props.form.reset();
    }
  }

  handleClose = e => {
    // in this handler, we always want to close the dialog and prevent any other hotkeys/actions from firing.
    // method 1 to get here: ESC key (27). In this case, we want to revert changes
    // method 2 to get here: Click cancel button. In this case, we want to revert changes
    // method 3 to get here: click outside dialog. In this case, we want to preserve changes

    // standard event stoppage
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.props.onClose();
  };

  handleSave = (form, formData) => {
    const { model, onSave } = this.props;
    // save formData into model, then serialize ALL model attributes back out
    model.set(formData);
    // TODO: better way to do this model isNew.
    onSave(Object.assign(model.serialize(model.toJS()), { id: Date.now() }));

    return Promise.resolve();
  };

  handleRemove = () => {
    const { onBracketOptionRemove } = this.props;
    onBracketOptionRemove();
  };

  render() {
    const {
      title = 'Bracketing Options',
      model,
      allowRangeRestriction,
      allowLastDataPoint,
      allowBracketingGroupOption,
      bracketTypeOptions
    } = this.props;
    return (
      <FormDialog
        {...this.props}
        style={{ width: 900, top: 50 }}
        title={title}
        canOutsideClickClose
        formActionsProps={{
          onSubmit: this.handleSave,
          onCancel: this.handleClose,
          onRemove: this.handleRemove,
          showRemove: true
        }}
        formComponent={
          <BracketOptionsForm
            model={model}
            allowRangeRestriction={allowRangeRestriction}
            allowLastDataPoint={allowLastDataPoint}
            allowBracketingGroupOption={allowBracketingGroupOption}
            bracketTypeOptions={bracketTypeOptions}
          />
        }
      />
    );
  }
}
