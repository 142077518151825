const fields = {
  password: {
    label: 'Password',
    type: 'password',
    rules: 'required|string|min:7'
  },
  password_confirm: {
    label: 'Confirm Password',
    type: 'password',
    rules: 'required|same:password'
  },
  token: {
    rules: 'required'
  }
};

export { fields };
