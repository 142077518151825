import React, { Component } from 'react';
import { any } from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { AnchorButton, Intent, Tag } from '@blueprintjs/core';

import Icon from 'components/Icon';
import { Box, Flex } from 'components/flexbox';
import { Field, Input, Select, Switch } from 'components/forms';
import { defaultOptionRenderer } from 'components/forms/select/SelectPopover';

const tetherOptions = {
  offset: '-3px 0',
  constraints: [{ attachment: 'together', pin: true, to: 'window' }]
};

const DeviceOptionRenderer = props => {
  const { className, disabled, field, key, label, selectItem, selected, style, value } = props;
  if (value !== 'device') {
    return defaultOptionRenderer(props);
  }

  const onClick = !selected && !disabled ? () => selectItem(field, value) : undefined;

  return (
    <Flex justify="space-between" key={key || value} className={className} onClick={onClick} style={style}>
      {label}
      <Tag className="pt-small" style={{ paddingLeft: 6, paddingRight: 6 }} intent={Intent.PRIMARY}>
        Required for RTBH/FlowSpec
      </Tag>
    </Flex>
  );
};

const DeviceValueRenderer = (option, placeholder) => {
  if (!option) {
    return <span className="pt-text-muted">{placeholder || 'Select a value...'}</span>;
  }

  const className = classNames('pt-text-overflow-ellipsis', option.className);
  const iconName = option ? option.iconCls || option.iconName : false;

  if (option.label === 'Peer with device') {
    return (
      <Flex justify="space-between" className={className} style={{ width: '100%' }}>
        <span style={{ padding: 2 }}>{option && option.label}</span>
        <Tag className="pt-small" style={{ paddingLeft: 6, paddingRight: 6 }} intent={Intent.PRIMARY}>
          Required for RTBH/FlowSpec
        </Tag>
      </Flex>
    );
  }

  return (
    <span className={className}>
      {iconName && <Icon name={iconName} style={{ marginRight: 6 }} />}
      {option && option.label}
    </span>
  );
};

@inject('$devices', '$dictionary', '$auth', '$alertingMitigation')
@observer
class BGPSettings extends Component {
  static contextTypes = {
    form: any
  };

  state = {
    associatedMitigationPlatforms: []
  };

  componentWillMount() {
    const { form } = this.context;
    const device_bgp_type = form.getValue('device_bgp_type');
    if (device_bgp_type === 'device') {
      const associatedMitigationPlatforms = this.getAssociatedMitigationPlatforms();
      form.getField('device_bgp_type')[associatedMitigationPlatforms.length ? 'disable' : 'enable']();
      form.getField('device_bgp_flowspec')[associatedMitigationPlatforms.length ? 'disable' : 'enable']();
      this.setState({
        associatedMitigationPlatforms
      });
    }
  }

  componentWillUpdate() {
    this.updateRules();
  }

  updateRules = () => {
    const { form } = this.context;
    const deviceBgpType = form.getValue('device_bgp_type');
    const ipValue = form.getValue('device_bgp_neighbor_ip');
    const ip6Value = form.getValue('device_bgp_neighbor_ip6');
    const asnFieldState = form.getField('device_bgp_neighbor_asn');
    const ipFieldState = form.getField('device_bgp_neighbor_ip');
    const ip6FieldState = form.getField('device_bgp_neighbor_ip6');
    let asnRules = '';
    let ipnRules = '';
    let ip6nRules = '';
    if (deviceBgpType === 'device') {
      asnRules = 'required|integer|min:1|max:4294967295';
      ipnRules = ip6Value ? 'ip' : 'required|ip';
      ip6nRules = ipValue ? 'ip' : 'required|ip';
    }
    form.setRules(asnFieldState, asnRules);
    form.setRules(ipFieldState, ipnRules);
    form.setRules(ip6FieldState, ip6nRules);
  };

  getAssociatedMitigationPlatforms = () => {
    const { form } = this.context;
    const { $alertingMitigation } = this.props;
    const { id, isNew } = form.model;
    if (!isNew) {
      return $alertingMitigation.platforms.models.filter(platform => {
        const platform_mitigation_device_detail = platform.get('platform_mitigation_device_detail');

        return (
          Array.isArray(platform_mitigation_device_detail.devices) &&
          platform_mitigation_device_detail.devices.includes(parseInt(id))
        );
      });
    }
    return [];
  };

  render() {
    const { helpText, $devices, $dictionary, model, onGetBgpIngestIps, $auth } = this.props;
    const { form } = this.context;
    const { associatedMitigationPlatforms } = this.state;
    const { masterBGPDevices } = $devices;
    const device_bgp_type = form.getValue('device_bgp_type');
    const kbLink = $dictionary.getHelpUrl('admin', 'configuringBGP');

    return (
      <Box col={12}>
        <Box className="pt-callout pt-intent-primary" mb={2}>
          <p className="pt-running-text">
            Configure each peering session as iBGP(internal), as a <strong>route-reflector-client</strong>.
          </p>

          <AnchorButton
            rel="noopener noreferrer"
            className="pt-minimal"
            intent={Intent.PRIMARY}
            iconName="share"
            target="_blank"
            href={kbLink}
          >
            <strong>Need help configuring your BGP sessions to Kentik’s ingest?</strong>
          </AnchorButton>
        </Box>

        <Field name="device_bgp_type" helpText={helpText.bgp} inputStyle={{ width: 350 }} onChange={onGetBgpIngestIps}>
          <Select
            optionRenderer={DeviceOptionRenderer}
            valueRenderer={DeviceValueRenderer}
            menuWidth={350}
            tetherOptions={tetherOptions}
          />
        </Field>

        {associatedMitigationPlatforms.length > 0 && (
          <Box mb={2} className="pt-callout pt-intent-warning pt-icon-warning-sign">
            To unlock BGP Type and/or Flowspec Compatibility, remove the device from the following mitigation
            platform(s):{' '}
            {associatedMitigationPlatforms.map(platform => (
              <span key={platform.id} style={{ marginRight: 8 }}>
                {platform.get('platform_name')}
              </span>
            ))}
          </Box>
        )}

        {$auth.hasPermission('alerts.flowSpec.enabled') && (
          <Field name="device_bgp_flowspec" inputStyle={{ width: 250 }} showLabel={false}>
            <Switch switchLabel={<strong>BGP Flowspec Compatible</strong>} />
          </Field>
        )}

        {device_bgp_type === 'device' && (
          <Box className="pt-callout" p={2}>
            <Field
              name="device_bgp_neighbor_ip"
              helpText={helpText.peering_device_ip}
              inputStyle={{ width: 475 }}
              onChange={this.updateRules}
            >
              <Input
                rightElement={
                  <Box className="pt-tag pt-minimal pt-intent-primary">
                    <Icon name="arrow-right" className="pt-text-muted" style={{ margin: '0 4px' }} /> Kentik
                    Ingest&#39;s Peering IPv4:
                    {'  '}
                    <span className="pt-monospace-text">
                      <b>{model.get('bgpPeerIP4')}</b>
                    </span>
                  </Box>
                }
              />
            </Field>

            <Field
              name="device_bgp_neighbor_ip6"
              helpText={helpText.peering_device_ip6}
              inputStyle={{ width: 475 }}
              onChange={this.updateRules}
            >
              <Input
                rightElement={
                  <Box className="pt-tag pt-minimal pt-intent-primary">
                    <Icon name="arrow-right" className="pt-text-muted" style={{ margin: '0 4px' }} /> Kentik
                    Ingest&#39;s Peering IPv6:
                    {'  '}
                    <span className="pt-monospace-text">
                      <b>{model.get('bgpPeerIP6')}</b>
                    </span>
                  </Box>
                }
              />
            </Field>
            <Field name="device_bgp_neighbor_asn" helpText={helpText.yourASN} inputStyle={{ width: 150 }}>
              <Input />
            </Field>

            <Field
              name="device_bgp_password"
              className="no-margin"
              helpText={helpText.bgp_md5_pass}
              inputStyle={{ width: 250 }}
            >
              <Input />
            </Field>
          </Box>
        )}

        {device_bgp_type === 'other_device' && (
          <Box className="pt-callout" p={2}>
            <Field
              name="use_bgp_device_id"
              className="no-margin"
              inputStyle={{ width: 300 }}
              options={masterBGPDevices}
            >
              <Select showFilter menuWidth={300} tetherOptions={tetherOptions} />
            </Field>
          </Box>
        )}
      </Box>
    );
  }
}

export default BGPSettings;
