import React, { Component, Fragment } from 'react';
import { any } from 'prop-types';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Intent, Collapse, Icon, Position, Tooltip } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Field, Input, Select, Slider, Switch } from 'components/forms';
import DimensionSelector from 'components/forms/dimension/DimensionSelector';
import ExplorerDimensionSelector from 'components/forms/dimension/ExplorerDimensionSelector';
import MetricSelector from 'components/forms/metric/MetricSelector';
import { getAggregateTypeLabel, getAggregateUnitLabel } from 'components/forms/metric/metricRenderers';
import {
  getAllUnitsLegacyNames,
  getBaseSorts,
  getUnitsLegacy,
  getVisualizationDepthLabel
} from 'models/query/ExplorerQueryModel';
import $dataviews from 'stores/$dataviews';
import { getQueryTimeInterval } from 'util/dateUtils';

import CutFnSection, { getCutFnFieldName } from './CutFnSection';

@inject('$devices', '$dictionary', '$explorer')
@observer
class QueryOptions extends Component {
  static contextTypes = {
    form: any
  };

  state = {
    showAdvancedOptions: false,
    isEditingMetrics: false,
    collapsedDimensions: {}
  };

  componentWillMount() {
    const { form } = this.context;
    const { $dictionary } = this.props;

    this.updateCutFnFields();
    this.initializeCutFnFields(this.props.model);
    this.updateCollapsedDimensions(this.props);

    if (!$dictionary.dictionary.asGroups.populated) {
      const customAsGroups = form.getField('customAsGroups');
      customAsGroups.disable();
      customAsGroups.setValue(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.model !== this.props.model) {
      this.updateCutFnFields();
      this.initializeCutFnFields(nextProps.model);
    }

    if (nextProps.disabledDimensions.length !== this.props.disabledDimensions.length) {
      this.updateCollapsedDimensions(nextProps);
    }
  }

  componentDidUpdate() {
    this.updateMetricField();
  }

  updateCollapsedDimensions(props) {
    const collapsedDimensions = {};
    const { $devices } = this.props;

    if (!$devices.hasDnsProbe) {
      collapsedDimensions['Layer 7'] = true;
    }
    props.disabledDimensions.forEach(dimension => {
      if (!dimension.includes('_')) {
        collapsedDimensions[dimension] = true;
      }
    });

    this.setState({ collapsedDimensions });
  }

  getSortOptions() {
    const { form } = this.context;
    const { aggregateTypes, viz_type } = form.getValues();

    if (!aggregateTypes) {
      return [];
    }

    const baseSorts = getBaseSorts(aggregateTypes, viz_type);

    return baseSorts.map(option => ({
      ...option,
      value: option.value === 'sum_logsum' ? `${option.value}_${option.unit}` : option.value,
      label: (
        <span>
          {getAggregateTypeLabel({ aggregate: option, useLineBreaks: false })}{' '}
          {option.value && getAggregateUnitLabel({ aggregate: option, useLineBreaks: false })}
        </span>
      )
    }));
  }

  handleVizDepthChange = vizDepth => {
    const { form } = this.context;
    const topx = vizDepth.getValue();
    const topxThresholds = [1, 4, 8, 16, 25, 40];
    const depthThresholds = [10, 20, 75, 125, 200, 350];

    let depth = 100;

    topxThresholds.forEach((threshold, index) => {
      if (topx >= threshold) {
        depth = depthThresholds[index];
      }
    });

    if (form.getValue('viz_type') === 'table') {
      depth = topx;
    }

    form.setValue('depth', depth);
  };

  handleDimensionSectionCollapse = group => {
    const { collapsedDimensions } = this.state;
    const newDimensions = { ...collapsedDimensions };
    newDimensions[group] = !collapsedDimensions[group];
    this.setState({ collapsedDimensions: newDimensions });
  };

  handleToggleAdvancedOptions = () => {
    this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions });
  };

  handleUnitsLegacyChange = (field, value) => {
    const { form } = this.context;
    const { unitsLegacy, logsumUnits } = this.props.$dictionary.dictionary;

    const aggTypesField = form.getField('aggregateTypes');
    const aggTypes = unitsLegacy[value] || [];

    aggTypesField.setValue(aggTypes);
    aggTypesField.onChange(aggTypes);
    form.getField('outsort').setValue('');

    this.handleMetricSelection(aggTypesField, aggTypes, logsumUnits.includes(value));

    // If 'Custom' was selected, pop open the dialog
    if (aggTypes.length === 0) {
      this.setState({ isEditingMetrics: true });
    }
  };

  handleMetricSelectorToggle = () => {
    this.context.form.getField('aggregateTypes').setPristine(false);
    this.setState({ isEditingMetrics: !this.state.isEditingMetrics });
  };

  @action
  handleMetricSelection = (aggTypesField, aggTypesValues, isLogsum = false) => {
    const { form } = this.context;
    const { hasKProbeSelected } = this.props;
    const outsortOptions = this.getSortOptions();

    // With outsort, we want to automatically select the first metric for convenience
    // ** However, if there as a kt intelligent ordering option and we're in logsum mode, give it precedence
    const outsortField = form.getField('outsort');
    let outsortValue = outsortField.getValue();
    if (!outsortOptions.find(option => option.value === outsortValue)) {
      let intellOrderOption;
      if (!outsortValue || isLogsum === true) {
        intellOrderOption = outsortOptions.find(option => option.column === 'kt_intell_order');
      }
      if (intellOrderOption) {
        outsortValue = intellOrderOption.value;
      } else if (outsortOptions.length) {
        outsortValue = outsortOptions[0].value;
      } else {
        outsortValue = '';
      }
      outsortField.options = outsortOptions;
      outsortField.setValue(outsortValue);
      outsortField.setPristine(false);
    }

    // With secondaryOutsort, we just want to clear it out if its value is no longer available
    const secondaryOutsortField = form.getField('secondaryOutsort');
    const secondaryOutsortValue = secondaryOutsortField.getValue();
    if (
      outsortValue === secondaryOutsortValue ||
      !outsortOptions.find(option => option.value === secondaryOutsortValue)
    ) {
      secondaryOutsortField.setValue('');
      secondaryOutsortField.setPristine(false);
      form.getField('secondaryTopxMirrored').setValue(false);
      form.getField('secondaryTopxSeparate').setValue(false);
    }

    form.setValue('unitsLegacy', getUnitsLegacy(aggTypesValues, hasKProbeSelected));
  };

  updateMetricField() {
    const { hasKProbeSelected, $dictionary } = this.props;
    const { form } = this.context;

    if (!form.getValue('all_devices') && (!form.getValue('device_name') || form.getValue('device_name').length === 0)) {
      return;
    }

    // If there is no kprobe/dns device selected, we need to remove host metrics.
    // field.setValue() does trigger onChange handlers, so we need to do this too.
    if (!hasKProbeSelected) {
      const tcpMetrics = $dictionary.dictionary.aggregates.TCP;
      const aggregateTypesField = form.getField('aggregateTypes');
      const aggregateTypes = aggregateTypesField.getValue();
      const newAggregateTypes = aggregateTypes.filter(
        aggregateType =>
          !Object.keys(tcpMetrics).some(group => tcpMetrics[group].some(aggregate => aggregate.value === aggregateType))
      );

      if (newAggregateTypes.length < aggregateTypes.length) {
        aggregateTypesField.setValue(newAggregateTypes);
        aggregateTypesField.setPristine(false);
        this.handleMetricSelection(aggregateTypesField, aggregateTypes);
      }
    }
  }

  @action
  handleDimensionSelection = () => {
    this.updateCutFnFields();
  };

  @action
  updateCutFnFields = () => {
    const { fields: cutFnFields } = this.props.$dictionary.dictionary.cutFn;
    const { form } = this.context;

    const metric = form.getValue('metric');

    const cutFnFieldConfigs = form.fieldConfigs.filter(config => config.name.startsWith('cutFn.'));

    cutFnFieldConfigs.forEach(field => {
      const fieldName = getCutFnFieldName(field.name);
      if (!metric.includes(fieldName)) {
        form.removeField(field.name);
        form.removeField(`cutFnRegex.${fieldName}`);
        form.removeField(`cutFnSelector.${fieldName}`);
      }
    });

    metric.forEach(field => {
      if (cutFnFields.includes(field)) {
        const currCutFn = cutFnFieldConfigs.find(config => config.name.startsWith(`cutFn.${field}`));
        if (!currCutFn) {
          form.addField(`cutFn.${field}`, {}, '');
          form.addField(`cutFnRegex.${field}`, {}, '');
          form.addField(`cutFnSelector.${field}`, {}, '');
        }
      }
    });
  };

  @action
  initializeCutFnFields(model) {
    const { form } = this.context;
    const cutFn = model.get('cutFn');
    const cutFnRegex = model.get('cutFnRegex');
    const cutFnSelector = model.get('cutFnSelector');

    Object.keys(cutFn).forEach(metric => form.getField(`cutFn.${metric}`).init(cutFn[metric]));
    Object.keys(cutFnRegex).forEach(metric => form.getField(`cutFnRegex.${metric}`).init(cutFnRegex[metric]));
    Object.keys(cutFnSelector).forEach(metric => form.getField(`cutFnSelector.${metric}`).init(cutFnSelector[metric]));
  }

  getFastDataOptions() {
    const { form } = this.context;
    const interval = getQueryTimeInterval(form.getValues());

    const options = [{ value: 'Auto', label: 'Auto' }];

    if (interval >= 3 * 3600 && interval <= 3 * 24 * 3600) {
      return options.concat({ value: 'Fast', label: 'Fast' }, { value: 'Full', label: 'Full' });
    }

    // If field is set to Full/Fast and these aren't valid, fix it!
    const fastDataField = form.getField('fastData');
    if (fastDataField.getValue() !== 'Auto') {
      fastDataField.setValue('Auto');
    }

    return options;
  }

  getUnitsLegacyOptions() {
    const { hasKProbeSelected, $dictionary } = this.props;
    const { units } = $dictionary.dictionary;

    return getAllUnitsLegacyNames(hasKProbeSelected)
      .map(unit => ({
        value: unit,
        label: units[unit] || unit
      }))
      .concat({
        value: '',
        label: 'Custom'
      });
  }

  visualizationDepthLabel = value => (
    <div>
      {value}
      <div className="description">{getVisualizationDepthLabel(value)}</div>
    </div>
  );

  generatorMinHeightLabelRenderer = value => {
    const heightMap = {
      250: 'Short',
      375: 'Medium',
      500: 'Tall'
    };
    return <div>{heightMap[value] || value}</div>;
  };

  render() {
    const { dialogRef, disabledDimensions, disabledMetrics, $dictionary } = this.props;
    const { dictionary } = $dictionary;
    const { form } = this.context;
    const { showAdvancedOptions, isEditingMetrics, collapsedDimensions } = this.state;

    const metrics = form.getValue('metric');
    const viz_type = form.getValue('viz_type');
    const showOverlayOn = form.getValue('show_overlay');
    const generatorMode = form.getValue('generatorMode');
    const generatorDimensions = form.getValue('generatorDimensions');
    const isAggTotal = form.getValue('outsort').includes('agg_total');

    const { suppressGeneratorMode, showTotalTrafficOverlay, mirrorable } = $dataviews.getConfig(viz_type);
    const { cidrMetrics } = dictionary;

    const showCidr = metrics.some(metric => cidrMetrics.includes(metric));
    const showMatrixBy = viz_type === 'matrix';
    const hasGeneratorDimensions = !!generatorMode && !!generatorDimensions && generatorDimensions.length > 0;

    let topxMax = 40;
    if (viz_type === 'table') {
      if (isAggTotal) {
        topxMax = 50000;
      } else {
        topxMax = 350;
      }
    }

    return (
      <div>
        <ExplorerDimensionSelector
          onChange={this.handleDimensionSelection}
          onCollapse={this.handleDimensionSectionCollapse}
          collapsedState={collapsedDimensions}
          className="pt-small"
          selectButtonClassName="pt-minimal pt-small pt-intent-primary"
          showClearButton
          fieldName="metric"
          disabledValues={disabledDimensions}
          dialogRef={dialogRef}
          isTabbed
        />
        {showCidr && (
          <Flex>
            <Field name="cidr" width={50}>
              <Input className="pt-small" />
            </Field>
            <Field name="cidr6" width={50} style={{ marginLeft: 16 }}>
              <Input className="pt-small" />
            </Field>
          </Flex>
        )}
        {showMatrixBy && (
          <Box flexAuto mr={1}>
            <DimensionSelector
              title="Matrix By Dimensions"
              selectButtonClassName="pt-minimal pt-small pt-intent-primary"
              showClearButton
              fieldName="matrixBy"
              disabledValues={disabledDimensions}
              dialogRef={dialogRef}
              onCollapse={this.handleDimensionSectionCollapse}
              collapsedState={collapsedDimensions}
            />
          </Box>
        )}
        <Box className="pt-label" style={{ marginBottom: 6, fontWeight: 700 }}>
          Metrics
        </Box>
        <Field
          name="unitsLegacy"
          showLabel={false}
          options={this.getUnitsLegacyOptions()}
          onChange={this.handleUnitsLegacyChange}
          style={{ margin: 0 }}
        >
          <Select className="pt-small" />
        </Field>
        <MetricSelector
          isEditing={isEditingMetrics}
          onChange={this.handleMetricSelection}
          onEditComplete={this.handleMetricSelectorToggle}
          disabledValues={disabledMetrics}
          collapsible={false}
        />
        <Field name="fastData" options={this.getFastDataOptions()}>
          <Select className="pt-small" />
        </Field>
        <Button
          className="pt-minimal pt-strong pt-small"
          iconName={showAdvancedOptions ? 'caret-down' : 'caret-right'}
          intent={Intent.PRIMARY}
          onClick={this.handleToggleAdvancedOptions}
          style={{ marginBottom: showAdvancedOptions ? 8 : 0 }}
        >
          Advanced Options
        </Button>
        <Collapse isOpen={showAdvancedOptions}>
          <Field name="topx" className="viz-depth-input no-margin" onChange={this.handleVizDepthChange}>
            <Slider
              min={1}
              max={topxMax}
              stepSize={1}
              labelStepSize={topxMax - 1}
              renderLabel={this.visualizationDepthLabel}
            />
          </Field>
          {showTotalTrafficOverlay && (
            <Field showLabel={false} name="show_total_overlay">
              <Switch switchLabel={<strong>Show Total Overlay</strong>} />
            </Field>
          )}
          <Flex>
            <Field showLabel={false} name="show_overlay" width="70%">
              <Switch switchLabel={<strong>Show Historical Overlay</strong>} />
            </Field>
            {showOverlayOn && (
              <Field name="overlay_day" width={50}>
                <Input className="pt-small" />
              </Field>
            )}
          </Flex>
          <Field showLabel={false} name="hostname_lookup">
            <Switch switchLabel={<strong>Enable Reverse DNS Lookups</strong>} />
          </Field>
          <Field showLabel={false} name="customAsGroups">
            <Switch
              switchLabel={
                <Fragment>
                  <strong>Use AS Groups</strong>{' '}
                  {form.getField('customAsGroups').disabled && (
                    <Tooltip
                      content="Set up AS Groups in the Admin panel to enable this option"
                      position={Position.RIGHT}
                    >
                      <Icon iconName="help" />
                    </Tooltip>
                  )}
                </Fragment>
              }
            />
          </Field>
          {mirrorable && (
            <Flex>
              <Field showLabel={false} flexAuto name="mirror">
                <Switch switchLabel={<strong>Bi-Directional Mode</strong>} />
              </Field>
              {/* do not delete this, we're going to re-enable it later */}
              {/* {mirror && */}
              {/* <Field flexAuto name="mirrorUnits"> */}
              {/* <Switch /> */}
              {/* </Field>} */}
            </Flex>
          )}
          {!suppressGeneratorMode && (
            <Box className={generatorMode ? 'pt-callout' : ''}>
              <Field showLabel={false} name="generatorMode" className={generatorMode ? '' : 'no-margin'}>
                <Switch switchLabel={<strong>Generate One Chart Per Series</strong>} />
              </Field>
              <Collapse isOpen={generatorMode}>
                <Fragment>
                  <Field name="generatorQueryTitle">
                    <Input className="pt-small" />
                  </Field>
                  <Field name="generatorColumns" className="viz-depth-input no-margin">
                    <Slider min={1} max={4} stepSize={1} labelStepSize={1} />
                  </Field>
                  <Field name="generatorPanelMinHeight" className="viz-depth-input no-margin">
                    <Slider
                      min={250}
                      max={500}
                      stepSize={125}
                      labelStepSize={125}
                      renderLabel={this.generatorMinHeightLabelRenderer}
                    />
                  </Field>
                  <Box flexAuto mr={1}>
                    <DimensionSelector
                      title="Group By Dimensions for Generated Panels"
                      selectButtonClassName="pt-minimal pt-small pt-intent-primary"
                      showClearButton
                      className="pt-small"
                      fieldName="generatorDimensions"
                      disabledValues={disabledDimensions}
                      dialogRef={dialogRef}
                    />
                  </Box>
                  {hasGeneratorDimensions && (
                    <Field name="generatorTopx" className="viz-depth-input no-margin">
                      <Slider
                        min={1}
                        max={topxMax}
                        stepSize={1}
                        labelStepSize={topxMax - 1}
                        renderLabel={this.visualizationDepthLabel}
                      />
                    </Field>
                  )}
                </Fragment>
              </Collapse>
            </Box>
          )}
          <CutFnSection />
        </Collapse>
      </div>
    );
  }
}

export default QueryOptions;
