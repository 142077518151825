import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ProgressBar } from '@blueprintjs/core';

import { ToggleButtonGroup, ValueButton } from 'components';
import { Flex, Box } from 'components/flexbox';
import { Page, PageHeader, PageBody } from 'components/page';
import { Filter, NewButton, PresetGroupingButtons } from 'components/table';

import DeviceDetailsDialog from './DeviceDetailsDialog';
import DeviceTable from './DeviceTable';

const breadcrumbLinks = [
  {
    text: 'Admin',
    href: '/admin'
  }
];

const componentBase = '/devices';

@inject('$devices', '$plans', '$dictionary', '$alertingMitigation')
@observer
class Devices extends Component {
  state = {
    selectedColumnGroup: 'deviceStats'
  };

  componentDidMount() {
    const { $devices, $plans, $alertingMitigation, match } = this.props;
    const { deviceId } = match.params;
    $alertingMitigation.platforms.fetch();
    $devices.loadDevices().then(() => {
      if (deviceId) {
        if (deviceId === 'new') {
          $devices.collection.forge();
        } else {
          $devices.collection.find({ id: deviceId }).select();
        }
      }
    });
    $plans.collection.fetch();
  }

  componentWillUnmount() {
    const { $devices, $plans } = this.props;
    $devices.collection.reset({ hard: true });
    $plans.collection.reset({ hard: true });
  }

  onToggleButtonClick = button => {
    this.setState(prevState => ({
      selectedColumnGroup: prevState.selectedColumnGroup !== button ? button : undefined
    }));
  };

  render() {
    const { selectedColumnGroup } = this.state;
    const { $devices, location } = this.props;
    const { collection } = $devices;
    const { selected } = collection;
    const rootUrl = location.pathname.substring(0, location.pathname.indexOf(componentBase) + componentBase.length);

    // adjust breadcrumbs if coming from onboarding.
    if (rootUrl.startsWith('/onboarding')) {
      breadcrumbLinks[0].text = 'Onboarding';
      breadcrumbLinks[0].href = '/library';
    }

    const { fetchedDeviceStatistics } = $devices;

    return (
      <Page constrainWidth={2000} centered title="Devices">
        <PageHeader
          loading={collection.isRequestActive('fetching')}
          parents={breadcrumbLinks}
          title="Devices"
          helpTopic="Device List"
          subSection={
            <Flex justify="space-between" my={1} mx={3}>
              <Flex flexAuto mr={1} align="center">
                <Box mr={1} flexAuto style={{ maxWidth: 350 }}>
                  <Filter style={{ width: '100%' }} collection={collection} placeholder="Search Devices..." autoFocus />
                </Box>
                <strong>Group by: </strong>
                <PresetGroupingButtons collection={collection} ml={1} />
                <Flex ml={1} flexAuto mr={1} align="center">
                  <strong>Show: </strong>
                  <ToggleButtonGroup selectedValue={selectedColumnGroup} ml={1}>
                    <ValueButton text="Stats" value="deviceStats" onClick={this.onToggleButtonClick} />
                    <ValueButton text="Details" value="deviceDetails" onClick={this.onToggleButtonClick} />
                  </ToggleButtonGroup>
                </Flex>
              </Flex>
              <NewButton collection={collection} rootUrl={rootUrl} entityName="Device" style={{ marginRight: 5 }} />
            </Flex>
          }
        />
        <PageBody>
          {!fetchedDeviceStatistics && (
            <Box className="pt-callout" mb={1} p={1}>
              <div>Loading device flow status...</div>
              <Box style={{ width: 175, marginTop: 4 }}>
                <ProgressBar className="pt-intent-primary" value={1} />
              </Box>
            </Box>
          )}

          <Flex className="pt-card flat" flexAuto flexColumn>
            <DeviceTable collection={collection} selectedColumnGroup={selectedColumnGroup} rootUrl={rootUrl} />
          </Flex>
          {selected && (
            <DeviceDetailsDialog
              isOpen={selected}
              collection={collection}
              model={selected}
              rootUrl={rootUrl}
              helpTopic="Device General Settings"
            />
          )}
        </PageBody>
      </Page>
    );
  }
}

export default withRouter(Devices);
