import BaseModel from 'models/BaseModel';

export default class AddOn extends BaseModel {
  constructor(data) {
    super(data);
    this.companyId = data.company_id;
  }

  static dataFieldsByType = {
    customdimension: ['customDimensions'],
    flowpak: ['devices', 'fps', 'planId'],
    cloudpak: ['maxFlowLogVolume', 'planId'],
    fastretention: ['planId', 'fastRetention'],
    fullretention: ['planId', 'fullRetention']
  };

  get urlRoot() {
    return `/api/sudo/companies/${this.companyId}/addons`;
  }

  /**
   * Override to prevent collection check (fetches and saves don't come from same place) collection doesn't have url
   */
  get url() {
    const urlRoot = this.urlRoot;
    if (this.isNew) {
      return urlRoot;
    }

    return `${urlRoot}/${this.get('id')}`;
  }

  get removalConfirmText() {
    return {
      title: 'Remove Add-On',
      text: 'Are you sure you want to remove this Add-On? Removal will unapply this add-on from applicable items.'
    };
  }

  get urlPaths() {
    return {
      update: `/api/sudo/companies/${this.companyId}/addons`,
      create: `/api/sudo/companies/${this.companyId}/addons`,
      fetch: '/api/portal/companysettings/permissions'
    };
  }
}
