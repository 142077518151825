import React, { Fragment } from 'react';
import { Button } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import $devices from 'stores/$devices';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: [
    'kt_aws_interface_id',
    'kt_aws_action',
    'kt_aws_status',
    'kt_aws_src_acc_id',
    'kt_aws_src_vm_name',
    'kt_aws_src_vm_id',
    'kt_aws_src_region',
    'kt_aws_src_zone',
    'kt_aws_src_vm_type',
    'kt_aws_src_image_id',
    'kt_aws_src_sg',
    'kt_aws_src_asg',
    'kt_aws_src_pub_dns',
    'kt_aws_src_priv_dns',
    'kt_aws_src_vpc_id',
    'kt_aws_src_subnet_id',
    'kt_aws_dst_acc_id',
    'kt_aws_dst_vm_name',
    'kt_aws_dst_vm_id',
    'kt_aws_dst_region',
    'kt_aws_dst_zone',
    'kt_aws_dst_vm_type',
    'kt_aws_dst_image_id',
    'kt_aws_dst_sg',
    'kt_aws_dst_asg',
    'kt_aws_dst_pub_dns',
    'kt_aws_dst_priv_dns',
    'kt_aws_dst_vpc_id',
    'kt_aws_dst_subnet_id'
  ],
  filterFields: [
    'kt_aws_interface_id',
    'kt_aws_action',
    'kt_aws_status',
    'kt_aws_src_acc_id',
    'kt_aws_src_vm_name',
    'kt_aws_src_vm_id',
    'kt_aws_src_region',
    'kt_aws_src_zone',
    'kt_aws_src_vm_type',
    'kt_aws_src_image_id',
    'kt_aws_src_sg',
    'kt_aws_src_asg',
    'kt_aws_src_pub_dns',
    'kt_aws_src_priv_dns',
    'kt_aws_src_vpc_id',
    'kt_aws_src_subnet_id',
    'kt_aws_dst_acc_id',
    'kt_aws_dst_vm_name',
    'kt_aws_dst_vm_id',
    'kt_aws_dst_region',
    'kt_aws_dst_zone',
    'kt_aws_dst_vm_type',
    'kt_aws_dst_image_id',
    'kt_aws_dst_sg',
    'kt_aws_dst_asg',
    'kt_aws_dst_pub_dns',
    'kt_aws_dst_priv_dns',
    'kt_aws_dst_vpc_id',
    'kt_aws_dst_subnet_id'
  ]
};

class AWSDependency extends AbstractDependency {
  get key() {
    return 'aws';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get preventQuery() {
    return true;
  }

  get meets() {
    return $devices.hasAWSDevice;
  }

  get message() {
    return (
      <Fragment>
        <Box className="pt-text-smaller" mb={0.5}>
          <strong>AWS</strong> devices are required by dimensions you're using, but have not been detected for any
          devices in your organization. Results shown herein may be highly inaccurate.
        </Box>
        {this.showConfigureButton && (
          <Button iconName="cog" className="pt-small" onClick={() => $devices.store.history.push('/admin/clouds')}>
            Configure
          </Button>
        )}
      </Fragment>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $devices.hasAWSDevice;
  }
}

export default AWSDependency;
