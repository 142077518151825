import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Tag } from '@blueprintjs/core';

import SidebarGroup from './SidebarGroup';
import ReportMenuItem from './ReportMenuItem';

@observer
class FavoritesList extends Component {
  render() {
    const { reports } = this.props;

    if (reports.favoriteReports.length === 0) {
      return null;
    }

    return (
      <SidebarGroup
        {...this.props}
        title={<strong>Favorites</strong>}
        iconName="star"
        badge={
          <Tag className="pt-minimal pt-round pt-strong" style={{ width: 25 }}>
            {reports.favoriteReports.length}
          </Tag>
        }
      >
        {reports.favoriteReports.map(report => (
          <ReportMenuItem {...this.props} key={report.id} report={report} />
        ))}
      </SidebarGroup>
    );
  }
}

export default FavoritesList;
