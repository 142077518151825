import { getAggregateTypeLabel, getAggregateUnitLabel } from 'components/forms/metric/metricRenderers';
import { getRangeMetadata } from 'services/bracketing';
import { StaticRanges } from './tagGenerators/StaticRanges';
import { Percentages } from './tagGenerators/Percentages';
import { Percentiles } from './tagGenerators/Percentiles';
import BaseTagGenerator from './tagGenerators/BaseTagGenerator';

const staticRanges = new StaticRanges();
const percentages = new Percentages();
const percentiles = new Percentiles();
const defaultGenerator = new BaseTagGenerator({ type: 'default' });
const generatorMap = {
  [staticRanges.type]: staticRanges,
  [percentages.type]: percentages,
  [percentiles.type]: percentiles
};

function getTagGenerationData({ query, queryResults }) {
  // short circuit right here if no query
  if (!query) {
    return null;
  }
  const bracketOptions = query.get('bracketOptions');
  // short circuit right here if no bracketOptions
  if (!bracketOptions) {
    return null;
  }

  // Have query with bracketOptions at this point, get bracketOptions to tag with
  // TODO: find better way, for now just use outsort aggregation column to get field name for last data point.
  // Outsort overrides lastDatapoint if outsort doesn't return a lastDatapoint value (agg_total)
  const queryOutsort = query.outsortDataKey;

  const queryOutsortAggregate = query.outsortAggregate;
  const bracketAggregation =
    bracketOptions.lastDatapoint && !queryOutsort.includes('agg_total')
      ? `${queryOutsortAggregate.column}__k_last`
      : queryOutsort;

  let bracketAggregationDisplay;
  // if outsort is Total, use totalUnits dictionary to get display units (modified with prefix as appropriate)
  if (queryOutsort.includes('agg_total')) {
    bracketAggregationDisplay = 'Total';
  } else {
    bracketAggregationDisplay = bracketOptions.lastDatapoint
      ? 'Last Datapoint'
      : getAggregateTypeLabel({ aggregate: queryOutsortAggregate, useLineBreaks: false });
  }

  const bracketAggregationDisplayUnit = getAggregateUnitLabel({
    aggregate: queryOutsortAggregate,
    prefix: queryResults.prefix,
    useLineBreaks: false
  });

  // required for rangeMetadata
  const bracketAggregationUnit = query.outsortUnit;
  // required for bracketing popover data
  const rangeMetadata = getRangeMetadata({
    bracketOptions: Object.assign(bracketOptions, { bracketAggregationUnit })
  });

  return bracketOptions
    ? Object.assign(bracketOptions, {
        bracketAggregation,
        bracketAggregationUnit,
        bracketAggregationDisplay,
        bracketAggregationDisplayUnit,
        rangeMetadata
      })
    : null;
}

function groupByFn(model) {
  const tagDataKey = Object.keys(model.toJS()).find(prop => prop.startsWith('tag_'));
  if (tagDataKey) {
    const { bracketLabel, bracketAggregationDisplay, bracketAggregationDisplayUnit } = model.get(tagDataKey);
    return `${bracketLabel} (${bracketAggregationDisplay} ${bracketAggregationDisplayUnit.props.children.join('')})`;
  }
  return 'Unassigned';
}

function tagQueryBucket({ queryBucket /* , options = {} */ }) {
  const { queryResults, firstQuery } = queryBucket;
  const bracketOptions = getTagGenerationData({ query: firstQuery, queryResults });

  // short circuit right here if no tagging data
  if (!bracketOptions) {
    return;
  }
  const tagger = generatorMap[bracketOptions.type] || defaultGenerator;
  tagger.tagResults({ queryResults, tagGenData: bracketOptions });

  // if it doesn't have groupResult set (legacy) OR it's set truthy, group bracket results;
  if (!Object.hasOwnProperty.call(bracketOptions, 'groupResults') || bracketOptions.groupResults) {
    queryResults.group({ groupByFn, type: 'bracketing' });
  }
}

export { tagQueryBucket, generatorMap };
