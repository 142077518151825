import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';

@inject('$exports')
export class ExportCsvButton extends Component {
  static defaultProps = {
    text: 'Export CSV',
    iconName: 'export',
    className: 'pt-intent-primary',
    fileNameText: 'kentik-export'
  };

  getCsvData(collection, data, columns) {
    return Promise.resolve().then(() => {
      const exportColumns = columns.filter(column => column.name && !column.hidden);
      const csvRows = [];

      // Header row
      csvRows.push(
        exportColumns
          .map(column => {
            const label = column.exportLabel || column.label;

            if (!label || typeof label === 'object') {
              return column.name;
            }

            return label;
          })
          .join(',')
      );

      if (collection) {
        // Data rows if using a proper collection
        collection.each(model => {
          const csvCols = [];

          exportColumns.forEach(column => {
            let value = model.get(column.name);

            if (column.renderer) {
              // If this thing gives us JSX, we have to throw up our hands
              const formattedValue = column.renderer({ value, model, collection, column });
              if (typeof formattedValue !== 'object') {
                value = formattedValue;
              }
            } else if (column.name === 'row_number') {
              value = parseInt(value, 10);
            }

            if (typeof value === 'string') {
              value = `"${value}"`;
            }

            csvCols.push(value);
          });

          csvRows.push(csvCols.join(','));
        });
      } else {
        // Data rows if using a raw array
        data.forEach(row => {
          const csvCols = [];

          exportColumns.forEach(column => {
            let value = row[column.name];

            if (column.name === 'row_number') {
              value = parseInt(value, 10);
            } else if (typeof value === 'string') {
              value = `"${value}"`;
            }

            csvCols.push(value);
          });

          csvRows.push(csvCols.join(','));
        });
      }

      return csvRows.join('\r\n');
    });
  }

  handleExport = () => {
    const { collection, columns, data, $exports, fileNameText } = this.props;
    if ((!data && !collection) || !columns) {
      return;
    }

    const hash = Math.floor(Math.random() * 10000000000)
      .toString(16)
      .toUpperCase();

    const path = 'csv';
    const fileName = `${fileNameText}-${hash}`;
    const type = 'csv';
    const options = { path, fileName, type };

    this.getCsvData(collection, data, columns).then(payload => {
      $exports.addPayload(payload, options);
    });
  };

  render() {
    const { style, disabled, text, iconName, className } = this.props;

    return (
      <Button
        iconName={iconName}
        className={className}
        text={text}
        onClick={this.handleExport}
        style={style}
        disabled={disabled}
      />
    );
  }
}
