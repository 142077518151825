import Collection from 'models/Collection';
import ManualMitigation from 'models/alerting/ManualMitigation';

class ManualMitigationCollection extends Collection {
  get url() {
    return '/api/portal/alerts/active/manual-mitigate';
  }

  get model() {
    return ManualMitigation;
  }
}

export default ManualMitigationCollection;
