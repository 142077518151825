import Collection from 'models/Collection';
import Notification from 'models/alerting/Notification';
import $app from 'stores/$app';

class NotificationCollection extends Collection {
  get url() {
    return '/api/portal/alerts/notification-channels';
  }

  get model() {
    return Notification;
  }

  deserialize(data) {
    data = super.deserialize(data);
    if (!$app.isSubtenant) {
      return data.filter(channel => !channel.user_group_id);
    }
    return data;
  }
}

export default NotificationCollection;
