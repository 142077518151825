import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Intent } from '@blueprintjs/core';
import { withRouter } from 'react-router-dom';

import { showErrorToast } from 'components/Toast';
import { Form, Field, Select, Switch } from 'components/forms';
import { fields, options } from 'forms/config/rawFlow';
import { itemExplorerFields } from 'forms/config/dashboardItemDetails';
import { Flex, Box } from 'components/flexbox';
import DeviceSelector2 from 'components/forms/device/DeviceSelector2';
import { FilterOptions, TimeOptions } from 'components/sidebar';
import ItemExplorerOptionTitle from 'views/Dashboards/DashboardItem/Form/ItemExplorerOptionTitle';
import SidebarModel from 'models/SidebarModel';
import RawFlowOptions from 'views/Analytics/RawFlow/RawFlowOptions';
import { getDisabledCloudDimensions, getDisabledSubtypeDimensions } from 'util/devices';
import { getDisabledAppProtocolDimensions } from 'util/dimensions';

@Form({ fields: { ...fields, ...itemExplorerFields }, options })
@inject('$dataviews', '$devices', '$dictionary', '$rawFlow')
@observer
class RawFlowDashboardOptions extends Component {
  @observable
  sidebar = new SidebarModel({
    name: 'rawFlow',
    sections: ['options', 'time', 'filters', 'devices'],
    directToggleSections: ['filters', 'devices']
  });

  componentWillMount() {
    const { $rawFlow, form } = this.props;
    this.setDashboardOverrides();
    $rawFlow.registerFormState(form);
  }

  componentWillReceiveProps({ $rawFlow, form, model, dashboardItem }) {
    if (form !== $rawFlow.formState) {
      $rawFlow.registerFormState(form);
    }

    if (!form.dirty && model.get('time_locked') !== form.getValue('time_locked')) {
      // set from source of truth if model and form are not in sync.
      form.setValues({
        time_locked: dashboardItem.get('time_locked'),
        device_locked: dashboardItem.get('device_locked'),
        filter_source: dashboardItem.get('filter_source')
      });
    }
  }

  setDashboardOverrides = () => {
    const { form } = this.props;
    const { time_locked, device_locked, filter_source } = form.getValues();
    this.syncDeviceLocked(null, !device_locked);
    this.syncTimeLocked(null, !time_locked);
    this.syncFilterSource(null, filter_source);
  };

  toggleFiltersEditing = () => {
    this.sidebar.toggleEditingSection('filters');
  };

  onRunQueryClick = () => {
    const { form, model, $rawFlow } = this.props;

    form.submit(() => {
      const values = form.getValues();
      const { flow_fields, order_by } = values;
      if (!(flow_fields.includes(order_by) || order_by === 'ctimestamp')) {
        showErrorToast('Order column should be in the selected fields');
        return;
      }
      $rawFlow.setQueryData(values);
      $rawFlow.fetchResults(values);
      model.set(values);
      // onSubmit(values);
      form.setModel(model);
    });
  };

  syncTimeLocked = (field, value) => {
    const { form, dashboard } = this.props;
    if (value) {
      form.setValues({
        lookback_seconds: dashboard.get('query').lookback_seconds
      });
    }
  };

  syncDeviceLocked = (field, value) => {
    const { form, dashboard } = this.props;
    if (value) {
      const query = dashboard.get('query');
      form.setValues({
        device_name: query.device_name || [],
        device_sites: query.device_sites || [],
        device_labels: query.device_labels || [],
        device_types: query.device_types || [],
        all_devices: query.all_devices
      });
    }
  };

  syncFilterSource = (field, value) => {
    const { form, dashboard } = this.props;
    if (value === 'dashboard') {
      const query = dashboard.get('query');
      form.setValues({
        saved_filters: query.saved_filters || []
      });
      if (query.filters) {
        form.setValue('filters.connector', query.filters.connector);
        form.setValue('filters.filterGroups', query.filters.filterGroups);
      }
    }
  };

  render() {
    const { form, $devices, $dictionary } = this.props;
    const { dictionary } = $dictionary;
    const { sidebar } = this;
    const device_name = form.getValue('device_name');
    const device_labels = form.getValue('device_labels');
    const device_sites = form.getValue('device_sites');
    const device_types = form.getValue('device_types');
    const all_devices = form.getValue('all_devices');

    const hasKProbeSelected = all_devices
      ? $devices.hasDnsProbe
      : $devices.containsDnsProbe({ device_name, device_labels, device_sites, device_types });
    const disabledCloudDimensions = getDisabledCloudDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types
    });
    const disabledSubtypeDimensions = getDisabledSubtypeDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types
    });
    const disabledKProbeDimensions = !hasKProbeSelected ? dictionary.kprobeDimensions : [];
    const disabledFilters = [
      ...disabledCloudDimensions,
      ...disabledKProbeDimensions,
      ...disabledSubtypeDimensions,
      ...getDisabledAppProtocolDimensions(form.getValue('flow_fields'), form.getValue('filters'))
    ];
    const editingFilters = this.sidebar.editingSections.get('filters');

    const isDeviceLocked = form.getValue('device_locked');
    const isTimeLocked = form.getValue('time_locked');
    const isFilterLocked = form.getValue('filter_source') !== 'dashboard';

    return (
      <Flex flexColumn className="dashboard-item-explorer-options overflow-auto" p={0.25} pr={0.5}>
        <Button
          intent={Intent.PRIMARY}
          text="Preview Query"
          className="pt-medium"
          onClick={this.onRunQueryClick}
          disabled={!form.valid}
        />

        <Box className="pt-card flat" py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Query" iconCls="code" />
          <RawFlowOptions.Edit disabledDimensions={disabledFilters} />
        </Box>

        <Box className="pt-card flat sidebar-time" py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Time Range" iconCls="access_time" />
          <Field
            name="time_locked"
            showLabel={false}
            style={{ marginBottom: 16 }}
            width={230}
            onChange={this.syncTimeLocked}
          >
            <Switch switchLabel="Controlled by Dashboard" />
          </Field>
          <TimeOptions
            className="no-margin"
            onModeChange={this.handleTimeModeChange}
            disabled={!isTimeLocked}
            showTimeZoneSelector={false}
            showJumpButtons={false}
            showModes={['lookback']}
            showFieldLabels={false}
          />
        </Box>

        <Box className="pt-card flat" py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Filtering" iconCls="filter_list" />
          <Field name="filter_source" onChange={this.syncFilterSource}>
            <Select />
          </Field>
          <FilterOptions
            sidebar={sidebar}
            isOpen={editingFilters}
            onClose={this.toggleFiltersEditing}
            disabledDimensions={disabledFilters}
            readOnly={!isFilterLocked}
            allowNestedFilters
          />
          <Box mt={1}>
            <Button
              className="pt-small pt-medium"
              onClick={this.toggleFiltersEditing}
              iconName="edit"
              text="Edit Filters"
              disabled={!isFilterLocked}
            />
          </Box>
        </Box>

        <Box className="pt-card flat" py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Devices" iconCls="settings_ethernet" />
          <Field name="device_locked" showLabel={false} onChange={this.syncDeviceLocked}>
            <Switch switchLabel="Controlled by Dashboard" />
          </Field>
          <DeviceSelector2 className="pt-small" readOnly={!isDeviceLocked} disabled={!isDeviceLocked} />
        </Box>
      </Flex>
    );
  }
}

export default withRouter(RawFlowDashboardOptions);
