import React, { Component } from 'react';
import { computed, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import mapboxgl, { LngLatBounds, NavigationControl, AttributionControl } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Box, Flex } from 'components/flexbox';

export const getBoundsFromBoundsArray = bounds => {
  let maxLon;
  let maxLat;
  let minLon;
  let minLat;

  bounds.forEach(bound => {
    const { minLon: currMinLon, minLat: currMinLat, maxLon: currMaxLon, maxLat: currMaxLat } = bound;

    minLon = minLon === undefined ? currMinLon : Math.min(minLon, currMinLon);
    minLat = minLat === undefined ? currMinLat : Math.min(minLat, currMinLat);
    maxLon = maxLon === undefined ? currMaxLon : Math.max(maxLon, currMaxLon);
    maxLat = maxLat === undefined ? currMaxLat : Math.max(maxLat, currMaxLat);
  });

  if (maxLat === minLat && maxLon === minLon) {
    maxLat += 1;
    minLat -= 1;
    maxLon += 1;
    minLon -= 1;
  }

  return new LngLatBounds({ lng: minLon, lat: minLat }, { lng: maxLon, lat: maxLat });
};

const getBoundsFromMarkers = markers => {
  let minLon;
  let minLat;
  let maxLon;
  let maxLat;

  markers.forEach(marker => {
    const lon = marker.get('lon');
    const lat = marker.get('lat');

    if (!Number.isNaN(lon) && !Number.isNaN(lat)) {
      minLon = minLon === undefined ? lon : Math.min(minLon, lon);
      minLat = minLat === undefined ? lat : Math.min(minLat, lat);
      maxLon = maxLon === undefined ? lon : Math.max(maxLon, lon);
      maxLat = maxLat === undefined ? lat : Math.max(maxLat, lat);
    }
  });

  return new LngLatBounds({ lng: minLon, lat: minLat }, { lng: maxLon, lat: maxLat });
};

@inject('$app')
@observer
class Map extends Component {
  static defaultProps = {
    containerWrapperProps: {
      flexAuto: true
    },
    containerProps: {
      style: { height: '100%', width: '100%' }
    },
    useContainerWrapper: true,
    scrollZoom: true
  };

  map;

  markers;

  mapIsLoaded = false;

  styleDisposer;

  @computed
  get style() {
    const { $app, useDarkestTheme } = this.props;

    if ($app.darkThemeEnabled) {
      if (useDarkestTheme) {
        return 'https://api.maptiler.com/maps/a65fb329-7e54-4269-9c82-9e34f77e354e/style.json?key=mrAzw6DpV1HgZBwys1Ko#'; // darkest
      }
      return 'https://api.maptiler.com/maps/e0f3df0c-154e-412d-9737-8832388599cb/style.json?key=mrAzw6DpV1HgZBwys1Ko#'; // dark
    }

    return 'https://api.maptiler.com/maps/05e71d3a-d712-4960-8a18-47e4626d1309/style.json?key=mrAzw6DpV1HgZBwys1Ko#'; // light
  }

  componentDidMount() {
    if (mapboxgl.supported()) {
      this.initializeMap();
    }
  }

  componentDidUpdate() {
    this.removeMarkers();
    this.addMarkers();
  }

  componentWillUnmount() {
    if (this.styleDisposer) {
      this.styleDisposer();
    }
  }

  initializeMap = () => {
    const { $app, onLoad, scrollZoom, zoom } = this.props;
    const style = this.style;

    this.map = new mapboxgl.Map({
      container: this.mapRef,
      style,
      attributionControl: false,
      zoom: zoom || 1,
      maxZoom: 18,
      center: [0, 40],
      interactive: !$app.isExport
    });

    if (!scrollZoom) {
      this.map.scrollZoom.disable();
    }

    if (!$app.isExport) {
      this.map.addControl(
        new NavigationControl({
          showCompass: false,
          showZoom: true
        }),
        'top-left'
      );
    }

    this.map.addControl(new AttributionControl({ compact: true }));

    this.map.on('style.load', () => {
      this.map.resize();
      this.mapIsLoaded = true;
      this.addMarkers();

      if (onLoad) {
        onLoad(this.map);
      }
    });

    if (!$app.isExport) {
      this.styleDisposer = reaction(() => $app.darkThemeEnabled, () => this.updateMapStyle());
    }
  };

  handleMapRef = refs => {
    this.mapRef = refs;
  };

  updateMapStyle() {
    const { onUpdateMapStyle } = this.props;

    if (this.map && this.mapIsLoaded) {
      this.map.setStyle(this.style);

      if (onUpdateMapStyle) {
        onUpdateMapStyle();
      }
    }
  }

  addMarkers() {
    const { markers, disableFitToMarkers } = this.props;

    if (this.mapIsLoaded && markers) {
      this.markers = markers.map(m => {
        const marker = new mapboxgl.Marker();
        marker.setLngLat([m.get('lon'), m.get('lat')]);
        marker.addTo(this.map);
        return marker;
      });

      if (!disableFitToMarkers) {
        this.map.fitBounds(getBoundsFromMarkers(markers), { padding: 50, easing: t => t * (2 - t) });
      }
    }
  }

  removeMarkers() {
    if (this.mapIsLoaded && this.markers) {
      this.markers.forEach(marker => marker.remove());
      this.markers = [];
    }
  }

  renderContainer = () => {
    const { containerProps } = this.props;

    if (mapboxgl.supported()) {
      return <div {...containerProps} ref={this.handleMapRef} />;
    }

    return (
      <Flex {...containerProps} align="center" justify="center" className="pt-callout pt-intent-primary">
        <div style={{ textAlign: 'center' }}>
          <span className="pt-icon-info-sign">&nbsp;Maps require&nbsp;</span>
          <a href="http://caniuse.com/webgl" target="_blank" rel="noopener noreferrer">
            WebGL support
          </a>
          . Please check that you are using a supported browser and that WebGL is&nbsp;
          <a href="http://get.webgl.org/" target="_blank" rel="noopener noreferrer">
            enabled
          </a>
          .
        </div>
      </Flex>
    );
  };

  render() {
    const { containerWrapperProps, useContainerWrapper } = this.props;
    const container = this.renderContainer();

    if (!useContainerWrapper) {
      return container;
    }

    return <Box {...containerWrapperProps}>{container}</Box>;
  }
}

export default Map;
