import React from 'react';
import { Button, Dialog } from '@blueprintjs/core';

import { CopyToClipboardButton } from 'components';
import { Flex, Box } from 'components/flexbox';

const dialogStyles = {
  overflow: 'auto',
  width: 600
};

const copyTextAreaStyle = {
  resize: 'none'
};

const CopyToClipboardDialog = props => {
  const { dialogHeight: height, description, value } = props;

  return (
    <Dialog {...props} style={Object.assign({ height }, dialogStyles)} transitionName="pt-dialog">
      <Box className="pt-dialog-body" py={2}>
        {description && <Box mb={1}>{description}</Box>}
        <textarea
          style={Object.assign({ height: Math.max((height || 136) - 136, 288), fontSize: 12 }, copyTextAreaStyle)}
          className="pt-input pt-fill pt-text-monospace"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          readOnly
          value={value}
        />
      </Box>
      <Flex justify="flex-end" pt={0} p={3}>
        <CopyToClipboardButton text={value}>
          <Button text="Copy to Clipboard" iconName="duplicate" style={{ minWidth: 150 }} />
        </CopyToClipboardButton>
      </Flex>
    </Dialog>
  );
};

export default CopyToClipboardDialog;
