import { observable, computed, action } from 'mobx';
import UserGroupCollection from 'models/userGroups/UserGroupCollection';
import TemplateCollection from 'models/userGroups/TemplateCollection';
import api from 'util/api';

class UserGroupStore {
  @observable
  collection = new UserGroupCollection();

  @observable
  templates = new TemplateCollection();

  @observable
  subtenantViewDialogOpen = false;

  selectedSubtenantId = undefined;

  @action
  setSubtenantViewDialogOpen = (isOpen, userGroup) => {
    if (userGroup) {
      this.selectedSubtenantId = userGroup.id;
    }

    this.subtenantViewDialogOpen = isOpen;
  };

  @action
  clearSelectedSubtenantId = () => {
    this.selectedSubtenantId = null;
  };

  launchLandlordSession = params =>
    api.post('/api/portal/companySettings/companySubtenancy/landlordSpoof', { data: params });

  clearSpoofSession = (options = {}) => {
    const { reload } = options;

    return api.get('/api/portal/companySettings/companySubtenancy/landlordUnspoof', {}).then(() => {
      if (reload) {
        window.location = '/';
      }
    });
  };

  @computed
  get userGroupOptions() {
    return this.collection.unfiltered.map(userGroup => {
      const value = userGroup.id;
      const label = userGroup.get('name');
      return { value, label };
    });
  }

  @computed
  get templateOptions() {
    return this.templates.unfiltered.map(template => {
      const value = template.id;
      const label = template.get('name');
      return { value, label };
    });
  }

  getUserGroupsByTemplate(template) {
    return this.collection.filter(userGroup => userGroup.get('template_id') === template.id, {
      immutable: true
    });
  }
}

export default new UserGroupStore();
