import { observable, action } from 'mobx';
import $auth from 'stores/$auth';
import { showInfoToast } from 'components/Toast';
import api from 'util/api';
import Socket from 'util/Socket';

class MessagesStore {
  messages = observable.array();

  newVersionToastVisible = false;

  portal;

  timerId;

  socket;

  @action
  initialize() {
    this.fetchMessages();

    // Check for new messages every 1 minute
    this.timerId = setInterval(() => this.fetchHealthCheck(), 60000);

    this.socket = new Socket({
      outType: 'portalMessages',
      inType: 'portalMessages',
      frequency: 60,
      delaySend: true,
      listenToBroadcast: true,
      onSuccess: this.onPollMessageResponse,
      onError(err) {
        console.warn('Received Portal Messages Socket Error', err);
      },
      onReconnect: () => {
        this.socket.send();
      }
    });
  }

  destroy() {
    clearInterval(this.timerId);
    this.socket.destroy();
  }

  @action
  fetchMessages() {
    api.get('/api/portal/messages').then(response => {
      if (response && response.length > 0) {
        this.messages.push(...response);
      }
      this.socket.send();
    });
  }

  @action
  fetchHealthCheck() {
    if (!$auth.activeUser.email_product) {
      return;
    }
    api.get('/hc', { showErrorToast: false }).then(response => {
      if (response) {
        const portalRev = response.rev.portal;

        if (portalRev > this.portal && !this.newVersionToastVisible) {
          showInfoToast('A new version of Kentik is available.', {
            title: 'Update Available!',
            timeout: 0,
            action: {
              onClick: () => window.location.reload(true),
              className: 'pt-medium',
              text: 'Reload'
            },
            onDismiss: this.handleNewVersionToastClose
          });

          this.newVersionToastVisible = true;
        }

        this.portal = portalRev;
      }
    });
  }

  @action
  onPollMessageResponse = data => {
    if ($auth.activeUser.email_service && data && data.length > 0) {
      this.messages.push(...data);
    }
  };

  handleNewVersionToastClose = () => {
    this.newVersionToastVisible = false;
  };

  @action
  dismissMessages = () => {
    this.messages.clear();
  };
}

export default new MessagesStore();
