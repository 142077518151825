import { computed } from 'mobx';
import { flatten } from 'lodash';
import Collection from '../Collection';
import UserGroup from './UserGroup';

export default class UserGroupCollection extends Collection {
  get url() {
    return '/api/portal/userGroup';
  }

  get model() {
    return UserGroup;
  }

  get filterFieldWhitelist() {
    return new Set(['id', 'name', 'description']);
  }

  get defaultSortState() {
    return {
      field: 'name',
      direction: 'asc'
    };
  }

  @computed
  get assignedUsers() {
    const users = this.models.map(group => group.get('users').toJS());
    return flatten(users);
  }
}
