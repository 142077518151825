import { action, computed, observable, toJS } from 'mobx';
import { isBoolean, isEqual, isString, omit, uniqueId, get } from 'lodash';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import api from 'util/api';
import { formatCalendar } from 'util/dateUtils';

// we never serialize these fields to the API.
const EDITED_CREATED_FIELDS = ['edate', 'cdate'];

export default class BaseModel {
  error = observable.shallow(null);

  optimisticId = uniqueId('i_');

  attributes;

  collection = null;

  @observable
  requestStatus;

  @observable
  requestProgress = 0;

  @observable
  lastUpdated;

  constructor(attributes = {}) {
    /*
     * Very important to note that any fields used here, must be defined with getter syntax
     * in subclasses or they will be undefined here!
     */

    let data = Object.assign({}, this.defaults, attributes);
    if (this.omitDuringDeserialize && this.omitDuringDeserialize.length) {
      data = omit(data, this.omitDuringDeserialize);
    }
    this.attributes = observable.shallowMap(data);
  }

  get defaults() {
    return {};
  }

  /**
   * Return the base url used in the `url` method
   *
   * @abstract
   */
  get urlRoot() {
    throw new Error('`urlRoot` getter not implemented');
  }

  /**
   * If the model has a "root" json node in the response. IE:
   * { "user" : { ... }}
   */
  get jsonRoot() {
    return false;
  }

  /**
   * Keys that are ignored in the response when turning data into attributes
   */
  get omitDuringDeserialize() {
    return [];
  }

  get omitDuringSerialize() {
    return [];
  }

  /**
   * Default sets of messages.
   */
  get messages() {
    return {
      create: 'Added successfully',
      update: 'Updated successfully',
      destroy: 'Removed successfully',
      duplicate: 'Duplicated successfully'
    };
  }

  get showToastTitles() {
    return true;
  }

  get removalConfirmText() {
    return {};
  }

  @computed
  get index() {
    if (!this.collection) {
      throw new Error('index: Model is not a part of a collection');
    }

    return this.collection.models.indexOf(this);
  }

  /**
   * Return the url for this given REST resource
   */
  get url() {
    const urlRoot = this.collection ? this.collection.url : this.urlRoot;

    if (!urlRoot) {
      throw new Error('Either implement `urlRoot` or assign a collection');
    }

    if (this.isNew) {
      return urlRoot;
    }

    return `${urlRoot}/${this.get('id')}`;
  }

  @computed
  get lastEdited() {
    return this.toDate('etime') || this.toDate('edate');
  }

  @computed
  get created() {
    return this.toDate('ctime') || this.toDate('cdate');
  }

  /**
   * Several of the entities in our system have `user_email` fields to indicate created by.
   */
  @computed
  get createdBy() {
    const user = this.get('user_email');
    return user;
  }

  /**
   * Whether the resource is new or not
   *
   * We determine this asking if it contains the `id` attribute (set by the server).
   */
  @computed
  get isNew() {
    return !this.has('id') || (!this.get('id') && this.get('id') !== 0);
  }

  /**
   * Determines whether or not this Model is selected in it's collection.
   */
  @computed
  get isSelected() {
    if (this.collection.selected && this.collection.selected.length) {
      return this.collection.selected.includes(this);
    }

    return this.collection.selected === this;
  }

  /**
   * Get an id from the model. It will use either
   * the backend assigned one or the client.
   */
  get id() {
    return this.has('id') ? this.get('id') : this.optimisticId;
  }

  /**
   * Returns value for given fieldName as a moment calendar
   */
  toDate(fieldName) {
    return this.has(fieldName) && formatCalendar(this.get(fieldName));
  }

  /**
   * Questions whether the request exists and matches a certain label.
   * If no label are passed it will check if any requests are active.
   */
  isRequestActive = label => {
    if (!label) {
      return this.requestStatus !== null;
    }

    return (
      (!!this.requestStatus && this.requestStatus === label) ||
      (this.collection && this.collection.isRequestActive(label))
    );
  };

  /**
   * Get the attribute from the model.
   *
   * For optional fields use `has()` to check whether the field is populated.
   * If no attribute is passed, returns all of the model attributes as a plain object.
   */
  get(attribute) {
    if (!attribute) {
      return this.attributes.toJS();
    }

    if (attribute.includes('.')) {
      return get(this.attributes.toJS(), attribute);
    }

    if (Array.isArray(attribute)) {
      return attribute.map(attr => this.attributes.get(attr));
    }

    return this.attributes.get(attribute);
  }

  /**
   * Returns whether the given field exists for the model.
   */
  has(attribute) {
    return this.attributes.has(attribute) && this.attributes.get(attribute) !== null;
  }

  get sortValues() {
    return {};
  }

  getSortValue(field) {
    let value;

    if (this.sortValues[field]) {
      value = this.sortValues[field](this);
    } else {
      value = this.get(field);
      if (value === undefined) {
        value = this[field];
      }
    }

    // Replacing undefined with empty string makes _.orderBy happy when ordering numbers and strings
    return value !== undefined ? value : '';
  }

  /**
   * Toggles an attribute value that is a Boolean value
   * from true to false, vice versa, yay.
   */
  toggle(attribute) {
    const value = this.get(attribute);
    if (!isBoolean(value)) {
      throw new Error(`Cannot toggle a non boolean attribute (${attribute}), value: ${value}`);
    }

    this.set({ [attribute]: !value });
  }

  /**
   * Returns a raw JS instance of our Model, used for debugging purposes mostly.
   */
  toJS(supportCycles = true) {
    return toJS(this.attributes, supportCycles);
  }

  isEqual(otherModel) {
    return otherModel && otherModel.toJS && isEqual(this.toJS(), otherModel.toJS());
  }

  @action
  setRequestStatus = status => (this.requestStatus = status);

  /**
   * Set the current selected model in a collection to this model. Typically this
   * method is called from a UI with a direct reference to `this`, so we keep the
   * binding with an arrow function. `onClick={model.select}`
   */
  @action
  select = (options = {}) => {
    const { multi = false, shiftKey = false, fetch = false } = options;

    if (!this.collection) {
      console.warn(`BaseModel (${this.constructor.name}) is not a part of a Collection. Cannot call select()`);
    }

    if (fetch) {
      this.fetch().then(() => {
        this.collection.select(this, { multi });
      });
    } else {
      this.collection.select(this, { multi, shiftKey });
    }
  };

  @action
  deselect = () => {
    if (!this.collection) {
      console.warn(`Model (${this.constructor.name}: ${this.id}) is not a part of a Collection. Cannot call select()`);
    }

    if (!this.isSelected) {
      console.warn(`Model (${this.constructor.name}: ${this.id}) is not selected.`);
    }

    this.collection.clearSelection();
  };

  @action
  setLastUpdated = () => {
    this.lastUpdated = Date.now();
  };

  /**
   * Merge the given data with the current attributes
   */
  @action
  set = (dataOrFieldName = {}, fieldData) => {
    if (isString(dataOrFieldName) && fieldData !== undefined) {
      this.attributes.set(dataOrFieldName, fieldData);
    } else {
      this.attributes.merge(dataOrFieldName);
    }

    this.setLastUpdated();

    return this;
  };

  /**
   * Replaces all of the attributes with new ones.
   */
  @action
  replace = (data = {}) => {
    this.attributes.replace(data);

    this.setLastUpdated();
  };

  @action
  duplicate = (options = {}) => {
    const { removeId = true, save = true } = options;
    const attributes = removeId ? omit(this.attributes.toJS(), ['id']) : this.attributes.toJS();

    if (!this.collection) {
      return new this.constructor(attributes);
    }

    const dupe = this.collection.build(attributes);

    if (save) {
      dupe.save(attributes);
    }

    return dupe;
  };

  /**
   * Saves the resource on the backend.
   *
   * If the item has an `id` it updates it, otherwise it creates the new resource.
   *
   * It supports optimistic and patch updates.
   *
   * optimistic - updates the model before we get a response back from the server.
   * patch - uses `PATCH` versus `PUT`.
   */
  @action
  async save(attributes = {}, options = {}) {
    const {
      optimistic = false,
      patch = false,
      preserialize = true,
      setOnPatchSuccess = true,
      clearSelection = true,
      toast = true,
      sendProvidedValuesOnly = false
    } = options;

    this.requestStatus = 'updating';
    this.requestProgress = 0;

    if (this.isNew) {
      this.set(Object.assign({}, attributes));
      return this.create(attributes, { optimistic, toast, clearSelection });
    }

    let data = attributes;
    const originalAttributes = this.attributes.toJS();

    // if PATCH, we only want to send the values that changed.
    if (patch) {
      data = Object.keys(attributes).reduce((result, attribute) => {
        const newValue = attributes[attribute];
        const oldValue = originalAttributes[attribute];
        if (newValue !== oldValue) {
          result[attribute] = newValue;
        }
        return result;
      }, {});
    } else if (!sendProvidedValuesOnly) {
      data = Object.assign({}, originalAttributes, attributes);
    }

    if (optimistic) {
      this.set(attributes);
    }

    const url = this.urlPaths ? this.urlPaths.update : this.url;
    const apiMethod = patch ? 'patch' : 'put';
    const promise = api[apiMethod](url, { data: preserialize ? this.serialize(data) : data });

    return promise.then(
      success => {
        if (this.collection && clearSelection) {
          this.collection.clearSelection();
        }

        if (!patch || setOnPatchSuccess) {
          this.set(this.deserialize(success));
        }

        if (toast) {
          showSuccessToast(this.messages.update, { title: this.showToastTitles ? 'Updated' : undefined });
        }

        if (this.collection) {
          this.collection.setLastUpdated();
        }

        this.setLastUpdated();

        this.requestStatus = null;

        return success;
      },
      error => {
        if (optimistic) {
          this.set(originalAttributes);
        }
        this.error = { label: 'updating' };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  /**
   * Saves this new model on the backend (via POST operation)
   */
  @action
  async create(attributes = {}, options = {}) {
    const label = 'creating';
    const data = Object.assign({}, this.attributes.toJS(), attributes);
    const payload = this.serialize(data);

    const { clearSelection, toast = true } = options;

    // handle case where collection doesn't have url overridden
    /* eslint-disable no-empty */
    let collectionUrl;
    try {
      collectionUrl = this.collection && this.collection.url;
    } catch (error) {}
    /* eslint-enable no-empty */

    const url = collectionUrl || this.urlRoot;
    const promise = api.post(url, { data: payload });

    this.requestStatus = label;

    return promise.then(
      success => {
        this.set(this.deserialize(success));

        if (this.collection) {
          this.collection.add(this);

          if (clearSelection) {
            this.collection.clearSelection();
            this.collection.sort(); // Note: it seemed to make sense not to re-apply existing filter rules
          }
        }

        if (toast) {
          const createMessage = this.messages ? this.messages.create : 'Created Successfully';
          showSuccessToast(createMessage, { title: this.showToastTitles ? 'Created' : undefined });
        }

        this.requestStatus = null;

        return success;
      },
      error => {
        this.error = { label, body: error };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  @action
  async fetch(options = {}) {
    const label = 'fetching';
    this.requestStatus = label;
    this.requestProgress = 0;

    const url = this.urlPaths ? this.urlPaths.fetch : this.url;
    const promise = api.get(url, { data: options.data, query: options.query });

    return promise.then(
      success => {
        this.set(this.deserialize(success));
        this.setRequestStatus(null);
        return success;
      },
      error => {
        this.error = { label, body: error };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  @action
  destroyModel = async (options = {}) => {
    const { toast = true, remove = true } = options;

    const url =
      this.collection && this.collection.urlPaths && this.collection.urlPaths.destroy
        ? `${this.collection.urlPaths.destroy}/${this.get('id')}`
        : this.url;

    this.requestStatus = 'destroying';

    return api.del(url).then(
      action(success => {
        if (toast) {
          showSuccessToast(this.messages.destroy, { title: this.showToastTitles ? 'Removed' : undefined });
        }

        if (this.collection) {
          if (remove) {
            this.collection.remove([this.id]);
          }

          this.collection.clearSelection();
        }

        this.requestStatus = null;

        return success;
      }),
      action(error => {
        console.error('destroyModel error', error);
        if (options.optimistic && this.collection) {
          this.collection.add([this.attributes.toJS()]);
        }
        this.error = { label: 'destroying', body: error };
        this.requestStatus = null;
        showErrorToast('Error Removing');

        return error;
      })
    );
  };

  /**
   * Destroys the resource on the client and requests the backend to delete it there too
   */
  /* eslint-disable consistent-return */
  async destroy(options = {}) {
    if (this.collection) {
      if (this.isNew) {
        return Promise.resolve(this.collection.remove([this.id]));
      }

      return this.destroyModel(options);
    }
  }

  serialize(data) {
    const prunedData = omit(data, [...this.omitDuringSerialize, ...EDITED_CREATED_FIELDS]);
    return this.jsonRoot ? { [this.jsonRoot]: prunedData } : prunedData;
  }

  deserialize(data) {
    let prunedData = data;
    if (this.omitDuringDeserialize && this.omitDuringDeserialize.length) {
      prunedData = omit(data, this.omitDuringDeserialize);
    }
    return prunedData && this.jsonRoot ? prunedData[this.jsonRoot] : prunedData;
  }

  getUniqueCopyName(nameField) {
    let name = this.get(nameField);

    if (this.collection) {
      // Remove the [Copy] or [Copy x] from the name
      const nameMatch = name.match(/\[Copy.*\] (.+)/);
      if (nameMatch) {
        name = nameMatch[1];
      }

      if (!this.collection.find({ [nameField]: `[Copy] ${name}` })) {
        return `[Copy] ${name}`;
      }
      for (let i = 1; ; i += 1) {
        if (!this.collection.find({ [nameField]: `[Copy ${i}] ${name}` })) {
          return `[Copy ${i}] ${name}`;
        }
      }
    }

    return name;
  }
}
