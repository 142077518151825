import React from 'react';
import { Spinner } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import classNames from 'classnames';

const Title = props => {
  const { title, actions, bigTitle, titleCmp, titleIntent = 'primary' } = props;

  if (!title && !titleCmp) {
    return null;
  }

  const className = `pt-intent-${titleIntent}-text`;
  const titleMarkup = bigTitle ? <h5 className={className}>{title}</h5> : <h6 className={className}>{title}</h6>;

  return (
    <Flex p={2} pt={2} pb={0} align="flex-start" justify="space-between">
      {titleCmp || titleMarkup}
      <div className="pt-card-actions">{actions}</div>
    </Flex>
  );
};

export default props => {
  const { children, isFetching, title, titleCmp, flat = false, footer, ...boxProps } = props;

  return (
    <Flex
      flexColumn
      flexAuto
      justify="space-between"
      className={classNames('pt-card', {
        'pt-elevation-1': !flat,
        flat
      })}
      {...boxProps}
    >
      {isFetching && (
        <Flex align="center" justify="center" flexAuto>
          <Flex align="center" justify="center" flexAuto>
            <Spinner className="pt-small" />
            <span className="pt-text-muted" style={{ marginLeft: 6 }}>
              Loading...
            </span>
          </Flex>
        </Flex>
      )}
      {!isFetching && (
        <Flex flexColumn flexAuto>
          <Title {...props} />
          <Box className="pt-card-body" flexAuto p={2} pt={title || titleCmp ? 1 : 2}>
            {children}
          </Box>
          {footer && (
            <Flex align="flex-end" justify="space-between" p={2} pb={2} pt={0}>
              {footer}
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};
