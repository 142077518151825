import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, AnchorButton, CollapsibleList, MenuItem } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/labs';
import SplitPane from 'react-split-pane';

import { Fade } from 'components/animations';
import { Flex, Box } from 'components/flexbox';
import DependencyCallout from 'components/sidebar/DependencyCallout';
import Icon from 'components/Icon';
import DataViewWrapper from 'dataviews/DataViewWrapper';
import DataViewTools from 'dataviews/DataViewTools';
import { AppliedFiltersButton, OptionsMenu } from 'dataviews/tools';
import Legend from 'dataviews/views/legend/Legend';

import CategoryHeading from './CategoryHeading';
import FavoriteButton from './FavoriteButton';

const splitPaneProps = {
  split: 'horizontal',
  paneStyle: { padding: 1 },
  pane2Style: { padding: 1, display: 'flex' },
  defaultSize: 'calc(50vh - 25px)',
  overflow: 'hidden'
};

@inject('$basicExplorer', '$dashboards', '$dataviews', '$library', '$app', '$exports')
@observer
class BasicExplorerDataView extends Component {
  handlePaneResize = () => {
    const { $basicExplorer } = this.props;
    const { dataview } = $basicExplorer;
    dataview.reflow();
  };

  handleEditTemplate = () => {
    const { history, template } = this.props;
    history.push(`/admin/queryTemplates/${template.id}`);
  };

  renderBreadcrumb = props => (
    <a
      className="pt-breadcrumb small-caps pt-intent-primary-text"
      onClick={props.onClick}
      style={{ marginBottom: 6, fontWeight: 600, fontSize: 11 }}
    >
      {props.text}
    </a>
  );

  renderCategoryOrBreadcrumbs() {
    const { $dashboards, history, template, savedView } = this.props;
    const {
      location: { state }
    } = history;

    if (state && state.navigationHistory) {
      const { navigationHistory } = state;

      return (
        <CollapsibleList
          className="pt-breadcrumbs"
          dropdownTarget={<span className="pt-breadcrumbs-collapsed" />}
          renderVisibleItem={this.renderBreadcrumb}
        >
          {navigationHistory.map((item, idx) => {
            const onClick = () => {
              history.go(-1 * (navigationHistory.length - idx));
            };

            return (
              <MenuItem key={item.id} text={$dashboards.getDashboard(item.id).get('dash_title')} onClick={onClick} />
            );
          })}
        </CollapsibleList>
      );
    }

    return <CategoryHeading item={template || savedView} />;
  }

  render() {
    const {
      $basicExplorer,
      $dataviews,
      $app: { isSubtenant },
      template,
      savedView,
      $exports
    } = this.props;

    const viewCfg = $dataviews.getConfig($basicExplorer.dataview.viewType);
    const description = template ? template.get('description') : savedView.get('view_description');
    const { hash } = $basicExplorer.dataview;

    const exportOptions = {
      path: `/api/portal/export/explorer/${hash}`,
      fileName: `kentik-export-${hash}`,
      type: 'pdf'
    };

    const dataViewTools = (
      <DataViewTools dataview={$basicExplorer.dataview}>
        {({ Wrapper, ...rest }) => (
          <Flex style={{ alignSelf: 'flex-start' }}>
            {template &&
              template.canEdit && (
                <Button
                  className="pt-small"
                  text="Edit Template"
                  onClick={this.handleEditTemplate}
                  style={{ marginRight: 4 }}
                />
              )}
            {!isSubtenant && (
              <Button
                className="pt-small"
                iconName="share"
                text="Open in Data Explorer"
                style={{ marginRight: 4 }}
                onClick={() => $basicExplorer.loadInAdvancedExplorer(template || savedView)}
              />
            )}
            {$basicExplorer.dataview.appliedFilters && <AppliedFiltersButton {...rest} />}
            {!isSubtenant && <OptionsMenu {...rest} />}
            {isSubtenant && (
              <Tooltip2 placement="bottom" hoverOpenDelay={250} content="Export">
                <AnchorButton
                  iconName="export"
                  className="pt-small"
                  onClick={() => {
                    $exports.fetchExport(exportOptions);
                  }}
                  loading={$exports.isLoading(exportOptions)}
                  style={{ marginLeft: 4 }}
                />
              </Tooltip2>
            )}
          </Flex>
        )}
      </DataViewTools>
    );

    const dataViewWrapper = (
      <DataViewWrapper
        dataview={$basicExplorer.dataview}
        viewProps={{
          showNativeLegend: false
        }}
        headerProps={{
          showTitleLink: false,
          tools: dataViewTools
        }}
      >
        {({ component, dataview, size }) => (
          <Flex flexAuto>
            <Fade in appear timeout={150}>
              {({ styles }) => (
                <Flex flexAuto flexColumn style={styles}>
                  <Flex align="center" p={2} pb={0}>
                    <Box flexAuto>
                      {this.renderCategoryOrBreadcrumbs()}
                      <h3 className="pt-strong">
                        {template ? template.get('name') : savedView.get('view_name')}
                        {$basicExplorer.activePivot && (
                          <Fragment>
                            <Icon
                              name="double-chevron-right"
                              className="pt-text-muted"
                              style={{ margin: '0 6px', position: 'relative', top: -3 }}
                            />
                            <span className="pt-text-muted pt-normal">{$basicExplorer.activePivot.label}</span>
                          </Fragment>
                        )}
                        <FavoriteButton report={template || savedView} />
                      </h3>
                    </Box>
                    {size.width > 750 && dataViewTools}
                  </Flex>

                  <Box className="pt-text-muted pt-text-smaller" px={2} pb={2}>
                    {template && template.isLoading && 'Loading...'}
                    {<span>{description || dataview.title}</span>}
                  </Box>
                  {size.width < 750 && (
                    <Box mb={1} pl={2}>
                      {dataViewTools}
                    </Box>
                  )}
                  <DependencyCallout
                    dataview={$basicExplorer.dataview}
                    style={{ margin: '0 16px 16px', width: 'auto' }}
                  />
                  <Flex flexAuto className="overflow-hidden">
                    <Flex flexAuto>{component}</Flex>
                  </Flex>
                </Flex>
              )}
            </Fade>
          </Flex>
        )}
      </DataViewWrapper>
    );

    return (
      <Flex flexColumn flexAuto style={{ position: 'relative' }}>
        {!viewCfg.showLegend && <Flex flexAuto>{dataViewWrapper}</Flex>}
        {viewCfg.showLegend && (
          <SplitPane
            onDragFinished={this.handlePaneResize}
            maxSize={-120}
            minSize={56}
            {...splitPaneProps}
            ref={this.splitPaneRef}
          >
            {dataViewWrapper}
            <Flex flexAuto flexColumn>
              <Legend showLegendOptions={false} dataview={$basicExplorer.dataview} />
            </Flex>
          </SplitPane>
        )}
      </Flex>
    );
  }
}

export default withRouter(BasicExplorerDataView);
