import { observable } from 'mobx';

import BaseModel from 'models/BaseModel';

class NetworkClassificationModel extends BaseModel {
  get urlRoot() {
    return '/api/portal/networkClassification';
  }

  get showToastTitles() {
    return false;
  }

  get messages() {
    return {
      create: 'Updated network classification settings successfully',
      update: 'Updated network classification settings successfully'
    };
  }
}

class NetworkClassificationStore {
  @observable
  model = new NetworkClassificationModel();
}

export default new NetworkClassificationStore();
