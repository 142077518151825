import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Form, Field, Input } from 'components/forms';
import { Page, PageHeader, PageBody } from 'components/page';
import { Card } from 'components';
import { fields, options } from 'forms/config/userProfile';

const breadcrumbLinks = [
  {
    text: 'My Profile',
    href: '/profile'
  }
];

@inject('$users', '$dictionary')
@Form({ fields, options })
@observer
class ProfileForm extends Component {
  updateUserProfile = () => {
    const { $users, form } = this.props;
    $users.updateUserProfile(form.getValues());
  };

  render() {
    const { $users, $dictionary } = this.props;
    const { fullUserProfile, updatingUser } = $users;

    return (
      <Page constrainWidth title="Profile">
        <PageHeader
          loading={!fullUserProfile}
          parents={breadcrumbLinks}
          title="User Information"
          helpTopic="User Information"
        />
        <PageBody scrollable bodyStyle={{ maxWidth: '800px' }}>
          <Card mb={2}>
            <div className="pt-form-group">
              <div className="flex align-center">
                <label className="pt-label">Level</label>
              </div>
              <div className="pt-form-content">
                <div className="pt-input-group">{$dictionary.dictionary.userLevels[fullUserProfile.user_level]}</div>
              </div>
            </div>
            <Flex>
              <Box flexAuto pr={1}>
                <Field name="user_full_name" autoFocus>
                  <Input />
                </Field>
              </Box>
            </Flex>
            <Box mb={2} className="pt-callout">
              <Field name="user_email">
                <Input />
              </Field>
              <div>
                Provide an updated email address below to send an account verification message to the updated address.
                Only after the verification instructions have been completed will the change be finalized.
              </div>
            </Box>
            <Flex justify="flex-end">
              <Button
                onClick={this.updateUserProfile}
                text="Update User Information"
                loading={updatingUser}
                intent={Intent.PRIMARY}
              />
            </Flex>
          </Card>
        </PageBody>
      </Page>
    );
  }
}

export default ProfileForm;
