import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Flex } from 'components/flexbox';
import { Form, FormDialog } from 'components/forms';
import { fields, options, PROVIDERS } from 'forms/config/cloudExportDetails';

import CloudExportForm from './CloudExportForm';

@Form({ fields, options })
@observer
export default class CloudExportDialog extends Component {
  render() {
    const { model } = this.props;
    const { code, logo } = PROVIDERS.byId(model ? model.get('cloud_provider') : PROVIDERS.AWS.id);

    const title = (
      <Flex align="center">
        <img src={logo} alt={code} style={{ maxWidth: 32, height: 32, marginRight: 8 }} />
        <span>
          {model && model.isNew ? 'Add' : 'Edit'} {code} Cloud
        </span>
      </Flex>
    );

    return (
      <FormDialog
        {...this.props}
        entityName={`${code} Cloud`}
        formComponent={<CloudExportForm />}
        title={title}
        style={{ width: 800 }}
      />
    );
  }
}
