import { computed, action } from 'mobx';
import { orderBy } from 'lodash';
import BaseModel from 'models/BaseModel';
import api from 'util/api';
import $dictionary from 'stores/$dictionary';
import $devices from 'stores/$devices';

const deviceTypeAcronyms = {
  router: 'Router',
  host: 'cHst',
  'host-nprobe-basic': 'nHst',
  'host-nprobe-dns-www': 'KPb'
};

class Device extends BaseModel {
  get urlRoot() {
    return '/api/portal/devices';
  }

  get jsonRoot() {
    return 'device';
  }

  get defaults() {
    return {
      device_snmp_v3_conf_enabled: false,
      sending_ips: [''],
      cdn_attr: 'N',
      labels: [],
      bgpPeerIP4: null,
      bgpPeerIP6: null
    };
  }

  get omitDuringSerialize() {
    return ['labels', 'site', 'plan'];
  }

  serialize(data) {
    const site_id = data.site.id;
    const plan_id = data.plan.id;
    const device_snmp_v3_conf =
      data.device_snmp_v3_conf_enabled && data.device_snmp_v3_conf.UserName.trim() ? data.device_snmp_v3_conf : null;

    if ($devices.getDeviceTypeFromSubtype(data.device_subtype) !== 'host-nprobe-dns-www') {
      delete data.cdn_attr;
    }

    if (data.device_bgp_type === 'device' || data.device_bgp_type === 'none') {
      data.use_bgp_device_id = null;
    }

    if (data.device_bgp_type !== 'device') {
      data.device_bgp_neighbor_ip = null;
      data.device_bgp_neighbor_ip6 = null;
      data.device_bgp_neighbor_asn = null;
      data.device_bgp_password = null;
      data.device_bgp_flowspec = false;
      data.bgpPeerIP4 = null;
      data.bgpPeerIP6 = null;
    }

    return super.serialize({ ...data, site_id, plan_id, device_snmp_v3_conf });
  }

  deserialize(data) {
    if (data && data[this.jsonRoot]) {
      data = super.deserialize(data);
    }
    if (data) {
      data.device_snmp_v3_conf_enabled = !!data.device_snmp_v3_conf;
    }
    return data;
  }

  @action
  async save(values) {
    const { labels } = values;
    await super.save(values);
    this.updateDeviceLabels(labels);
  }

  get sortValues() {
    return {
      id: () => parseInt(this.get('id')),
      bgpSession: () => this.bgpSession.message,
      sending_ips: () => this.get('sending_ips') && this.get('sending_ips').join(' '),
      deviceType: () => this.deviceType.toLowerCase()
    };
  }

  get messages() {
    return {
      create: `Device ${this.get('device_name')} was added successfully`,
      update: `Device ${this.get('device_name')} was updated successfully`,
      destroy: `Device ${this.get('device_name')} was ${this.isActive ? 'archived' : 'removed'} successfully`
    };
  }

  get removalConfirmText() {
    const name = this.get('device_name');

    if (this.isActive) {
      return {
        title: 'Archive Device',
        text: `Are you sure you want to archive ${name}? You will have up to 7 days to unarchive ${name} before it is fully removed from Kentik.`,
        buttonText: 'Archive'
      };
    }

    return {
      title: 'Remove Device',
      text: `Are you sure you want to permanently remove ${name} from Kentik? This action is IRREVERSIBLE and you will lose any flow data associated with this device.`
    };
  }

  /**
   * Returns us the number of Interfaces that were Classified / Matched by a particular Rule. Need this at the
   * device level to show breakdowns in the Rule Details Dialog when testing Rules against Devices.
   */
  interfacesMatchedByRule = rule => {
    const rules = this.get('rules');

    if (rule && rules) {
      return rules.find(r => r.id === rule.id);
    }

    return false; // TODO: this is a bad practice. Should return [];
  };

  @action
  updateDeviceLabels = labels => {
    const data = labels.map(l => ({ id: l.id }));

    return api
      .put(`/api/portal/devices/${this.id}/labels`, { data })
      .then(response => this.set({ labels: response.labels }));
  };

  @computed
  get labels() {
    return this.get('labels')
      ? orderBy(this.get('labels'), ['name'], ['asc']).map(label => ({ id: label.id, name: label.name }))
      : [];
  }

  // we use this field to allow searching by label
  @computed
  get appliedLabels() {
    return this.get('labels').length > 0
      ? this.get('labels')
          .map(label => label.name)
          .join(' ')
      : 'no labels';
  }

  @computed
  get isActive() {
    return this.get('device_status') === 'V';
  }

  @computed
  get isArchived() {
    return this.get('device_status') === 'D';
  }

  @computed
  get isIncomplete() {
    return this.get('device_status') === 'INI';
  }

  @computed
  get numInterfaces() {
    return this.get('all_interfaces') && this.get('all_interfaces').length;
  }

  @computed
  get flowType() {
    return this.get('FlowType');
  }

  @computed
  get maxFps() {
    return this.get('FpsPostCap');
  }

  @computed
  get flowSource() {
    const isAgent = parseInt(this.get('IsAgent'));
    const maxFps = this.maxFps;

    if (isAgent === 0) {
      return 'client';
    } else if (isAgent === 1) {
      return 'agent';
    }

    return maxFps && maxFps.source ? maxFps.source : 'client';
  }

  @computed
  get hasLabels() {
    return this.get('labels') && this.get('labels').length > 0;
  }

  @computed
  get deviceType() {
    return this.get('device_subtype') || deviceTypeAcronyms[this.get('device_type')];
  }

  @computed
  get deviceSubtype() {
    const deviceSubtype = $dictionary.dictionary.device_subtypes[this.get('device_subtype')];
    return deviceSubtype ? deviceSubtype.display_name : this.deviceType;
  }

  @computed
  get sampleRate() {
    const maxSampleRate = this.get('MaxSampleRate');

    if (maxSampleRate) {
      return Math.round((maxSampleRate.value || maxSampleRate) / 100);
    }

    return maxSampleRate;
  }

  getDefaultSampleRateForDeviceType = device_type => (device_type === 'router' ? 1024 : 32);

  @computed
  get bgpSession() {
    const icons = [];

    if (this.get('device_bgp_type') === 'device') {
      if (this.get('RibSessionState') === 1) {
        icons.push({
          label: 'v4',
          message: 'v4 BGP Peering is enabled and established.',
          iconName: 'exchange',
          intent: 'SUCCESS'
        });
      } else if (!this.get('device_bgp_neighbor_ip')) {
        icons.push({
          label: 'v4',
          message: 'v4 BGP Peering is not configured.',
          iconName: 'small-minus'
        });
      } else {
        icons.push({
          label: 'v4',
          message: 'v4 BGP Peering is not established.',
          iconName: 'cross',
          intent: 'DANGER'
        });
      }

      if (this.get('RibSessionState6') === 1) {
        icons.push({
          label: 'v6',
          message: 'v6 BGP Peering is enabled and established.',
          iconName: 'exchange',
          intent: 'SUCCESS'
        });
      } else if (!this.get('device_bgp_neighbor_ip6')) {
        icons.push({
          label: 'v6',
          message: 'v6 BGP Peering is not configured.',
          iconName: 'small-minus'
        });
      } else {
        icons.push({
          label: 'v6',
          message: 'v6 BGP Peering is not established.',
          iconName: 'cross',
          intent: 'DANGER'
        });
      }
    } else if (this.get('device_bgp_type') === 'other_device') {
      icons.push(
        {
          message: 'BGP Peering is enabled through another device: ',
          iconName: 'link',
          label: 'v4',
          intent: 'PRIMARY'
        },
        {
          message: 'BGP Peering is enabled through another device: ',
          iconName: 'link',
          label: 'v6',
          intent: 'PRIMARY'
        }
      );
    } else {
      icons.push(
        {
          label: 'v4',
          message: 'v4 BGP Peering is not configured.',
          iconName: 'small-minus'
        },
        {
          label: 'v6',
          message: 'v6 BGP Peering is not configured.',
          iconName: 'small-minus'
        }
      );
    }

    return icons;
  }

  getSortValue(field) {
    if (field === 'bgpSession') {
      if (this.get('device_bgp_type') === 'device') {
        if (this.get('RibPrefixes') && this.get('RibPrefixes6')) {
          return 5;
        } else if (this.get('RibPrefixes') || this.get('RibPrefixes6')) {
          return 4;
        }
        return 3;
      } else if (this.get('device_bgp_type') === 'other_device') {
        return 2;
      }
      return 1;
    }
    return super.getSortValue(field);
  }
}

export default Device;
