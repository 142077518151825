import { toJS } from 'mobx';
import moment from 'moment';
import { isNumber, isString } from 'lodash';
import $dictionary from '../stores/$dictionary';

function calculateScrollbarWidth() {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'scrollbar-measure';
  document.body.appendChild(scrollDiv);

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
}

export function getScrollbarWidth() {
  let scrollbarWidth;

  if (scrollbarWidth === undefined) {
    scrollbarWidth = calculateScrollbarWidth();
  }

  return scrollbarWidth;
}

export function deepClone(obj) {
  if (typeof obj !== 'object') {
    return obj;
  }

  let res = obj;
  try {
    res = JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error('Attempted to deep clone an invalid JSON object');
  }
  return res;
}

export const getToFixed = (units = 'bytes') => {
  if (units.includes('bytes') || units.includes('packets') || units === 'fps') {
    return 2;
  } else if (units.startsWith('perc_')) {
    return 3;
  }
  return 0;
};

export function processLargeArrayAsync(array, processFn, options = {}) {
  return new Promise(resolve => {
    const { maxTimePerChunk = 20, context = this, timeoutDuration = 1 } = options;
    let index = 0;

    function doChunk() {
      const startTime = Date.now();
      while (index < array.length && Date.now() - startTime <= maxTimePerChunk) {
        processFn.call(context, array[index], index, array);
        index += 1;
      }
      if (index < array.length) {
        setTimeout(doChunk, timeoutDuration);
      } else {
        resolve();
      }
    }

    doChunk();
  });
}

const kflowTypeLabelMap = {
  router: 'Router',
  host: 'LEGACY host',
  'host-nprobe-basic': 'nProbe Basic',
  'host-nprobe-dns-www': 'kProbe'
};

export function deviceTypeByKflow(...kflowTypes) {
  const { deviceTypes } = $dictionary.dictionary;
  const results = [];
  return kflowTypes.reduce((accumulator, kflowType) => {
    const temp = deviceTypes.find(deviceType => deviceType.kflow_type === kflowType);
    if (temp) {
      const { id, kflow_type } = temp;
      accumulator.push({ id, kflow_type, label: kflowTypeLabelMap[kflowType] || kflowType });
    } else {
      throw new Error(`Unrecognized kflow type: ${kflowType}`);
    }
    return accumulator;
  }, results);
}

export function arrayToCommaSeparatedString(value, withSpace = true) {
  if (Array.isArray(value)) {
    return value.join(`,${withSpace ? ' ' : ''}`);
  }
  return value.toString();
}

export function commaSeparatedStringToArray(value) {
  if (typeof value === 'string') {
    value = value.trim();
    if (!value) {
      return [];
    }
    if (value.endsWith(',')) {
      value = value.slice(0, -1);
    }
    return value.split(',').map(item => item.trim());
  }
  return [];
}

export function addCommas(nStr) {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;
}

export const removeCommas = str => str.replace(/,/g, '');

export function numberWithCommas(x) {
  return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function zeroToText(val, options = {}) {
  const { fix = 2, limit = 0.01, useAbsVal = true } = options;

  const absVal = Math.abs(val);
  let returnVal = (useAbsVal ? absVal : val).toFixed(fix);

  if (absVal !== 0 && absVal < limit) {
    returnVal = `<${limit}`;
  }

  returnVal = addCommas(returnVal);
  if (useAbsVal === false && val < 0) {
    returnVal = `(${returnVal})`;
  }
  return returnVal;
}

export function escapeHtml(str) {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export function escapeForRegExp(str) {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export function safelyParseJSON(json) {
  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    // Oh well, but whatever...
    parsed = undefined;
  }

  return parsed;
}

export function translateFromUTCForUser(aMoment, stringDate, subtractHours) {
  let theMomentToReturn;
  // const { userTimezone } = PortalApp.userProfile.attributes;
  const userTimeZone = 'UTC';

  if (aMoment instanceof moment) {
    theMomentToReturn = aMoment;
  } else if (isString(stringDate)) {
    theMomentToReturn = moment(stringDate);
  }

  if (isNumber(subtractHours)) {
    theMomentToReturn.subtract(subtractHours, 'hours');
  }

  if (userTimeZone === 'Local') {
    return theMomentToReturn.local();
  }

  return theMomentToReturn.utc();
}

export function applyWhitelist(options, whitelist) {
  return options.filter(option => !whitelist || whitelist.has(option.value) || whitelist.has(option.category));
}

export function applyBlacklist(options, blacklist) {
  return options.filter(option => !blacklist || (!blacklist.has(option.value) && !blacklist.has(option.category)));
}

export const PREFIXABLE_UNITS = [
  'bytes',
  'packets',
  'in_bytes',
  'out_bytes',
  'in_packets',
  'out_packets',
  'total_bytes',
  'total_packets',
  'bytes_per_src_ip',
  'bytes_per_dst_ip',
  'total_bytes_per_src_ip',
  'total_bytes_per_dst_ip'
];

const PREFIXES = [
  { value: 'P', magnitude: 10 ** 15 },
  { value: 'T', magnitude: 10 ** 12 },
  { value: 'G', magnitude: 10 ** 9 },
  { value: 'M', magnitude: 10 ** 6 },
  { value: 'K', magnitude: 10 ** 3 }
];

export function greekPrefix(data, scaleMax = 5) {
  const avg = data.reduce((sum, row) => sum + row, 0) / data.length;
  const diffs = data.map(row => (row - avg) * (row - avg));
  const stddev = Math.sqrt(diffs.reduce((sum, val) => sum + val, 0) / diffs.length);
  const twostddevs = avg + stddev + stddev;
  const prefix = PREFIXES.find(pf => twostddevs / pf.magnitude > scaleMax);
  return prefix ? prefix.value : '';
}

export function adjustByGreekPrefix(value, prefix) {
  const prefixFound = PREFIXES.find(pf => pf.value === prefix);

  return prefixFound ? value / prefixFound.magnitude : value;
}

export function formatBytesGreek(value, suffix = 'B') {
  const prefix = greekPrefix(Array.isArray(value) ? value : [value]);
  return (
    adjustByGreekPrefix(
      Array.isArray(value) ? value.reduce((sum, row) => sum + parseFloat(row), 0) : parseFloat(value),
      prefix
    ).toFixed() +
    prefix +
    suffix
  );
}
export function getOrdinalSuffix(num) {
  const ones = num % 10;
  const tens = num % 100;
  if (ones === 1 && tens !== 11) {
    return 'st';
  }
  if (ones === 2 && tens !== 12) {
    return 'nd';
  }
  if (ones === 3 && tens !== 13) {
    return 'rd';
  }
  return 'th';
}

export function getDefaultFiltersObj() {
  return {
    connector: 'All',
    filterGroups: []
  };
}

export function mergeFilterGroups(filter1, filter2) {
  if (!filter1) return filter2;
  if (!filter2) return filter1;

  return {
    connector: 'All',
    filterGroups: [
      {
        ...filter1,
        connector: filter1.connector || 'All',
        filterGroups: filter1.filterGroups.map(group => deepClone(group))
      },
      {
        ...filter2,
        connector: filter2.connector || 'All',
        filterGroups: filter2.filterGroups.map(group => deepClone(group))
      }
    ]
  };
}

// this just appends a group to the existing filter groups
export function augmentQueryFiltersWithFilterGroup(query, filterGroup) {
  if (Array.isArray(query)) {
    query.forEach(q => {
      augmentQueryFiltersWithFilterGroup(q, filterGroup);
    });
    return;
  }

  if (query.filters_obj) {
    query.filters_obj.filterGroups = query.filters_obj.filterGroups || [];
    query.filters_obj.filterGroups.push(filterGroup);
  } else {
    query.filters_obj = {
      connector: 'All',
      filterGroups: [filterGroup]
    };
  }
}

// this will do appropriate logical nesting and when adding a filter group to existing filter groups
export function augmentOrNestQueryFiltersWithFilterGroups(query, filterGroup) {
  if (Array.isArray(query)) {
    query.forEach(q => {
      augmentOrNestQueryFiltersWithFilterGroups(q, filterGroup);
    });
    return;
  }

  if (query.filters_obj) {
    query.filters_obj.filterGroups = query.filters_obj.filterGroups || [];

    const { connector, filterGroups } = query.filters_obj;
    if (connector === 'All') {
      filterGroups.push(filterGroup);
    } else {
      query.filters_obj.filterGroups = [{ connector, filterGroups }, filterGroup];
      query.filters_obj.connector = 'All';
    }
  } else {
    query.filters_obj = {
      connector: 'All',
      filterGroups: [filterGroup]
    };
  }
}

export function nestFilterGroup(filter) {
  filter.filterGroups = [
    {
      connector: 'Any',
      filterGroups: [...filter.filterGroups]
    }
  ];
  filter.connector = 'All';
}

// TODO: note if there's a dirty state issue around autoAdded - this may be the culprit
export function addFilterGroup(
  filtersObj,
  connector = 'All',
  not = false,
  metric = [],
  filters = [],
  name = '',
  atIndex
) {
  const newGroup = {
    connector,
    not,
    metric,
    filters,
    name,
    named: !!name
  };

  if (atIndex || atIndex === 0) {
    filtersObj.filterGroups = [
      ...filtersObj.filterGroups.slice(0, atIndex),
      newGroup,
      ...filtersObj.filterGroups.slice(atIndex)
    ];
  } else if (filtersObj && filtersObj.filterGroups) {
    filtersObj.filterGroups.push(newGroup);
  }

  return filtersObj;
}

export function addFilter(filtersObj, filterField, operator, filterValue, atIndex = 0) {
  if (filtersObj && filtersObj.filterGroups && filtersObj.filterGroups[atIndex]) {
    filtersObj.filterGroups[atIndex].filters.push({
      filterField,
      operator,
      filterValue
    });
  }
  return filtersObj;
}

export function replaceFilterField(filtersObj = {}, filterMapping) {
  if (filtersObj.filters) {
    filtersObj.filters = filtersObj.filters.map(filter => {
      const overrides = filterMapping[filter.filterField] || {};
      return {
        ...filter,
        ...overrides
      };
    });
  }
  if (filtersObj.filterGroups) {
    filtersObj.filterGroups.forEach(filterGroup => replaceFilterField(filterGroup, filterMapping));
  }
}

export const isProduction = process.env.NODE_ENV === 'production';
export const isDev = process.env.NODE_ENV !== 'production';

if (isDev) {
  window.toJS = toJS; // Note: don't ever rely on this in code. Only for using in devtools since mobx sucks.
}

export function parseQueryString(queryString) {
  const params = {};
  let temp;
  if (queryString && typeof queryString === 'string') {
    const queries = queryString.split('&');
    for (let i = 0; i < queries.length; i += 1) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1] && decodeURIComponent(temp[1]);
    }
  }
  return params;
}

export function createQueryStringFromObject(object) {
  if (typeof object === 'object' && Object.keys(object).length) {
    return Object.keys(object).reduce((queryString, key) => {
      if (queryString.length) {
        queryString += '&';
      }
      return `${queryString}${key}=${object[key]}`;
    }, '');
  }
  return '';
}

export const stopBubbling = e => e.stopPropagation();

export const scrollbarSize = (() => {
  const div1 = document.createElement('div');
  const div2 = document.createElement('div');

  div1.style.width = '100px';
  div1.style.overflowX = 'scroll';
  div2.style.width = '100px';

  document.body.appendChild(div1);
  document.body.appendChild(div2);

  const size = div1.offsetHeight - div2.offsetHeight;

  document.body.removeChild(div1);
  document.body.removeChild(div2);

  return size;
})();
