import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { AnchorButton } from '@blueprintjs/core';

import Icon, { MaterialIcon } from 'components/Icon';
import { Flex, Box } from 'components/flexbox';

@observer
class ResultRow extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    result: PropTypes.object.isRequired,
    collection: PropTypes.object,
    nameField: PropTypes.string.isRequired,
    url: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
    onResultClick: PropTypes.func,
    truncateDescription: PropTypes.bool
  };

  static defaultProps = {
    icon: '',
    url: '',
    collection: undefined,
    link: undefined,
    onResultClick: () => {},
    truncateDescription: false
  };

  handleAnchorClick = event => {
    const { history, onResultClick, result, collection } = this.props;
    const { link } = event.currentTarget.dataset;

    if (link) {
      if (link.startsWith('http')) {
        window.open(link, '_blank');
      } else {
        history.push(link);
      }
    }

    if (collection) {
      collection.forge(result).select();
    }

    onResultClick(link, result.resultCategory);
  };

  render() {
    const { type, url, result, nameField, icon, link, children, truncateDescription } = this.props;
    const dataLink = link || `${url}/${result.id}`;

    const descriptionClassName = classNames('pt-text-muted pt-text-small', {
      'pt-text-overflow-ellipsis': truncateDescription
    });

    return (
      <AnchorButton
        onClick={this.handleAnchorClick}
        data-link={dataLink}
        className="pt-button pt-minimal search-result"
      >
        {icon.startsWith('material-icons:') ? <MaterialIcon name={icon.split(':')[1]} /> : <Icon name={icon} />}

        <Box flexAuto className="overflow-hidden" mx={1.5}>
          <div>{result[nameField]}</div>
          <div className={descriptionClassName}>
            {type}
            &nbsp;
            {children}
          </div>
        </Box>

        <Flex align="center" justify="flex-end" className="search-result-score">
          {result.score > 30 && <Icon name="dot" />}
          {result.score > 50 && <Icon name="dot" />}
          {result.score > 70 && <Icon name="dot" />}
          {result.score > 130 && <Icon name="dot" />}
        </Flex>
      </AnchorButton>
    );
  }
}

export default withRouter(ResultRow);
