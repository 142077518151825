import moment from 'moment';
import { computed, action, observable } from 'mobx';
import { invert } from 'lodash';
import $dictionary from 'stores/$dictionary';

import BaseModel from 'models/BaseModel';
import Subscription from 'models/subscriptions/Subscription';
import { injectLegacySavedFilters } from 'services/filters';
import $explorer from 'stores/$explorer';
import $devices from 'stores/$devices';
import { DEFAULT_DATETIME_FORMAT, USER_TIMEZONE, MOMENT_FN } from 'util/dateUtils';
import { safelyParseJSON, translateFromUTCForUser, getDefaultFiltersObj, addFilterGroup, addFilter } from 'util/utils';

import DatasetResult from './DatasetResult';
import DatasetResultCollection from './DatasetResultCollection';

const TABLE_VALUE_FIELDS = {
  ASN: 'Transit ASN',
  NextHop: 'Next-Hop ASN',
  LastHop: 'Last-Hop ASN',
  ASPath: 'BGP Path',
  Dst_Geo: 'Destination Country',
  Country: 'Destination Country'
};

const metricDict = {
  ASPath: 'Geography_dst',
  Country: 'i_device_id',
  NextHop: 'dst_nexthop_ip',
  LastHop: 'dst_bgp_aspath',
  ASN: 'dst_bgp_aspath'
};

const filtersDict = {
  ASPath: 'dst_bgp_aspath',
  Country: 'dst_geo',
  DstGeo: 'dst_geo',
  Dst_Geo: 'dst_geo',
  SrcGeo: 'src_geo',
  Device: 'i_device_name',
  LastHop: 'dst_as',
  NextHop: 'dst_nexthop_as',
  ASN: 'dst_bgp_aspath',
  Interface: 'output_port'
};

export const REPORT_TYPES = [
  {
    value: 'ASPath',
    label: 'BGP Paths',
    helpText: 'Shows traffic by major network path as it leaves your network.'
  },
  {
    value: 'ASN',
    label: 'Transit ASNs',
    helpText:
      'Shows summed through and to traffic for each AS past the first hop, so that you can see potential peers and customers.'
  },
  {
    value: 'LastHop',
    label: 'Last-Hop ASNs',
    helpText:
      'Shows the ASNs and countries to which traffic leaving your network is ultimately going, so that you can quickly see where your traffic is being consumed.'
  },
  {
    value: 'NextHop',
    label: 'Next-Hop ASNs',
    helpText: 'Shows the traffic flow from your network to the ASs with which you have a direct relationship.'
  },
  {
    value: 'Country',
    label: 'Countries',
    helpText: 'Shows the source and destination countries of traffic flowing through your network.'
  }
];

export default class Dataset extends BaseModel {
  @observable
  resultsCollection = new DatasetResultCollection();

  @observable
  totalResult = new DatasetResult();

  @observable
  sankeyResult = undefined;

  @observable
  graphResult = undefined;

  @observable
  topASNs = undefined;

  get defaults() {
    return {
      // ui related fields, refers to the "BGP Path", "ASPath", from our tabs.
      // seems like a point here that could use some refactoring.
      activeField: 'ASPath',

      // sidebar fields
      showDevicesInSite: false,
      ignoreFirstHop: true,
      peeringDepth: 1,
      includeAllDevices: true,

      selected_interfaces: '',
      selected_asns: '',

      device_name: [],
      device_id: [],
      all_selected: true,
      host_selected: false,
      num_device: 0,

      direction: 'DST',
      device_ids: $devices.deviceSummaries.map(device => device.id),

      filters_obj: {
        filterGroups: [
          { filters: [{ filterField: 'i_dst_network_bndry_name', operator: '=', filterValue: 'external' }] }
        ]
      },

      time_range: [
        MOMENT_FN()
          .subtract(7, 'days')
          .toDate(),
        MOMENT_FN().toDate()
      ]
    };
  }

  deserialize(data = {}) {
    const deserialized = { ...data };

    if (data.filters_obj) {
      deserialized.filters_obj = safelyParseJSON(data.filters_obj);
    }

    if (data.saved_filters) {
      const saved_filters = safelyParseJSON(data.saved_filters);

      if (saved_filters && saved_filters.length > 0) {
        deserialized.filters_obj = injectLegacySavedFilters(saved_filters, deserialized.filters_obj);
      }
      delete deserialized.saved_filters;
    }

    if (data.time_start || data.time_end) {
      deserialized.time_range = [data.time_start, data.time_end];
    }

    // we are using device_ids for selected devices in device component too
    if (data.device_ids) {
      deserialized.dataset_device_ids = data.device_ids;
    }

    return deserialized;
  }

  serialize(data) {
    const { startTime, endTime, interval } = this.getSelectedTime(data);
    return {
      saved_filters: [],
      ...data,
      time_start: startTime,
      time_end: endTime,
      run_interval: interval
    };
  }

  @action
  async save(values) {
    return super.save(values).then(response => {
      const newDatasetId = response && response.id;
      if (values.schedule_type !== 'once' && newDatasetId) {
        this.saveSubscription(newDatasetId, values);
      }
    });
  }

  @action
  saveSubscription(newDatasetId, values) {
    const { schedule_type, day_of_week, day_of_month, lookback, report_pub_name, recipients } = values;
    let isScheduled;
    let delivery_period;
    let recurrence_ordinal;
    switch (schedule_type) {
      case 'daily':
        isScheduled = true;
        delivery_period = 'day';
        recurrence_ordinal = 0;
        break;
      case 'weekly':
        isScheduled = true;
        delivery_period = 'week';
        recurrence_ordinal = parseInt(day_of_week, 10);
        break;
      case 'monthly':
        isScheduled = true;
        delivery_period = 'month';
        recurrence_ordinal = parseInt(day_of_month, 10);
        break;
      default:
        isScheduled = false;
    }
    if (isScheduled && recipients) {
      const subscription = new Subscription({
        title: report_pub_name,
        recipients: recipients.trim(),
        content_id: newDatasetId,
        content_type: 'peering',
        group_key: report_pub_name,
        delivery_period,
        recurrence_ordinal,
        lookback_days: lookback
      });
      return subscription.save();
    }
    return null;
  }

  getSelectedTime(data) {
    // format time as expected by scheduled reports backend
    const { schedule_type, day_of_week, day_of_month, lookback, time_range } = data;
    let startTime;
    let endTime;
    let interval;

    switch (schedule_type) {
      case 'daily':
        // set endTime to today at 8 UTC
        endTime = moment()
          .utc()
          .format('YYYY-MM-DD 08:00:00Z');
        startTime = moment(endTime)
          .subtract(lookback, 'days')
          .utc()
          .format('YYYY-MM-DD 08:00:00Z');
        interval = '1 day';
        break;
      case 'weekly':
        endTime =
          moment().day() < day_of_week
            ? moment()
                .day(day_of_week)
                .utc()
                .format('YYYY-MM-DD 08:00:00Z')
            : moment()
                .day(day_of_week)
                .add(7, 'days')
                .utc()
                .format('YYYY-MM-DD 08:00:00Z');
        startTime = moment(endTime)
          .subtract(lookback, 'days')
          .utc()
          .format('YYYY-MM-DD 08:00:00Z');
        interval = '7 days';
        break;
      case 'monthly':
        endTime =
          moment().date() < day_of_month
            ? moment()
                .date(day_of_month)
                .utc()
                .format('YYYY-MM-DD 08:00:00Z')
            : moment()
                .date(day_of_month)
                .add(1, 'months')
                .utc()
                .format('YYYY-MM-DD 08:00:00Z');
        startTime = moment(endTime)
          .subtract(lookback, 'days')
          .utc()
          .format('YYYY-MM-DD 08:00:00Z');
        interval = '1 month';
        break;
      default:
        startTime = time_range[0];
        endTime = time_range[1];
        // if end time is today, choose now - 2 hours
        if (
          moment(endTime)
            .utc()
            .format('YYYY-MM-DD') ===
          moment()
            .utc()
            .format('YYYY-MM-DD')
        ) {
          endTime = moment()
            .utc()
            .subtract(2, 'hour');
        }
        if (moment(endTime).diff(moment(startTime), 'days') > 31) {
          // max limit of a dataset is one month
          startTime = moment(endTime)
            .subtract(30, 'days')
            .utc()
            .format('YYYY-MM-DD 08:00:00Z');
        } else if (moment(endTime).diff(moment(startTime), 'days') < 2) {
          // min limit of a dataset is 2 days
          startTime = moment(endTime)
            .subtract(2, 'days')
            .utc()
            .format('YYYY-MM-DD 08:00:00Z');
        }
        interval = null;
    }
    if (moment(startTime).isValid() && moment(endTime).isValid()) {
      return {
        startTime,
        endTime,
        interval
      };
    }
    return {};
  }

  getDatasetRowFilters({ resultModel, selectedASN, resultField, parentDevice }) {
    // we are adding filters for the followings:
    // dataset filters
    // + row filters
    // + device filter if the row is an interface
    // + ASN filters (if in ASN details dialog)
    // + ASNs selected in sidebar

    // rebuild dataset filters
    const { filters_obj } = this.get();

    const datasetFilters = getDefaultFiltersObj();
    if (filters_obj && filters_obj.connector && filters_obj.filterGroups && Array.isArray(filters_obj.filterGroups)) {
      datasetFilters.connector = filters_obj.connector;
      filters_obj.filterGroups.forEach((group, index) => {
        addFilterGroup(datasetFilters, group.connector, group.not);
        group.filters.forEach(filter => {
          addFilter(datasetFilters, filter.filterField, filter.operator, filter.filterValue, index);
        });
      });
    }

    // add sidebar ASNs
    if (this.asnFilterModel) {
      datasetFilters.filterGroups.push(this.asnFilterModel);
    }

    // add filters for current row
    const resultFilterValue = resultModel.get('reportfield');
    const filterField = filtersDict[resultField];
    const operatorDict = $dictionary.dictionary.queryFilters.operators;
    let operator;
    let filterValue;
    if (Array.isArray(resultFilterValue) && resultFilterValue.length > 0) {
      operator = '=';
      filterValue = resultFilterValue.join(' ');
    } else if (resultField === 'ASN') {
      // for transit ASN
      operator = '~';
      filterValue = `_${resultFilterValue}_`;
    } else {
      operator = invert(operatorDict[filterField]).equals || '=';
      filterValue = resultFilterValue;
    }
    datasetFilters.filterGroups.push({
      connector: 'Any',
      not: false,
      metric: [],
      filters: [
        {
          filterField,
          operator,
          filterValue
        }
      ]
    });

    // add a filter for parent ASN field if it's a second-level table (in ASN details modals)
    if (selectedASN && selectedASN.asn) {
      datasetFilters.filterGroups.push({
        connector: 'Any',
        not: false,
        metric: [],
        filters: [
          {
            filterField: 'dst_bgp_aspath',
            operator: '~',
            filterValue: `_${selectedASN.asn}_`
          }
        ]
      });
    }

    // add filter for device_name if the row is an interface
    if (resultField === 'Interface' && parentDevice) {
      datasetFilters.filterGroups.push({
        connector: 'Any',
        not: false,
        metric: [],
        filters: [
          {
            filterField: 'i_device_name',
            operator: '=',
            filterValue: parentDevice
          }
        ]
      });
    }

    return datasetFilters;
  }

  @action
  openResultInExplorer = async ({ resultModel, selectedASN, resultField, parentDevice }) => {
    resultModel.loadingExplorerResult = true;
    // get start and end time
    const dataset_end = this.get('time_end');
    const starting_time = translateFromUTCForUser(
      moment(dataset_end)
        .subtract(1, 'days')
        .utc()
    ).format('YYYY-MM-DD HH:mm:00Z');
    const ending_time = translateFromUTCForUser(moment(dataset_end).utc()).format('YYYY-MM-DD HH:mm:00Z');

    const selected_devices = this.filterByDevices;
    const device_name = await $devices.getDeviceNames(selected_devices);
    const filters = this.getDatasetRowFilters({ resultModel, selectedASN, resultField, parentDevice });
    const metric = metricDict[resultField];

    const explorerQuery = {
      filters,
      starting_time,
      ending_time,
      lookback_seconds: 0,
      metric,
      device_name,
      time_format: USER_TIMEZONE
    };

    $explorer.preset(explorerQuery);
    window.open('/explorer/preset', '_blank');
  };

  @computed
  get chartTitles() {
    let sankey;
    let chart;

    const { activeField } = this.get();
    const ignoreTitle = this.ignoreFirstHop === 'Ignore' && activeField !== 'ASPath' ? ' (First-Hop ignored)' : '';
    // ignore First hop is not applied when displaying path

    switch (activeField) {
      case 'ASPath':
        sankey = 'Top Device -> BGP Path -> Destination Country, p95th Mbps';
        chart = 'Top BGP Paths Traffic over time';
        break;

      case 'LastHop':
        sankey = 'Top Device -> Last-Hop ASN -> Destination Country, p95th Mbps';
        chart = 'Top Last-Hop ASNs Traffic over time';
        break;

      case 'NextHop':
        sankey = 'Top Device -> Interface -> Next-Hop ASN -> Destination Country, p95th Mbps';
        chart = 'Top Next-Hop ASNs Traffic over time';
        break;

      case 'Country':
        sankey = 'Source Country -> Device -> Destination Country, p95th Mbps';
        chart = 'Top Countries Traffic over time';
        break;

      default:
        sankey = 'Top Device -> Transit ASN -> Destination Country, p95th Mbps';
        chart = 'Top Transit ASNs Traffic over time';
        break;
    }

    sankey += ignoreTitle;
    chart += ignoreTitle;

    const sankeyTitle = sankey.split('->').map(part => part.trim());

    return {
      chart,
      sankey: sankeyTitle
    };
  }

  @computed
  get reportType() {
    const { activeField } = this.get();
    return REPORT_TYPES.find(report => report.value === activeField);
  }

  /**
   * When we transition to Explorer, we need to translate our "Filter ASNs" input
   * into Filters QueryModel understands
   */
  @computed
  get asnFilterModel() {
    let asns = this.get('selected_asns');
    if (asns && asns.length > 0) {
      asns = asns.split('_') || [];

      // get a clean list of ASNs
      const filterByASNs = [];
      asns.forEach(asn => {
        const asnInt = parseInt(asn, 10);
        if (asnInt && asnInt > 0) {
          filterByASNs.push(asnInt);
        }
      });
      if (filterByASNs.length > 0) {
        const filterGroup = {
          connector: 'Any',
          not: false,
          metric: [],
          filters: []
        };
        filterGroup.filters = filterByASNs.map(asn => ({
          filterField: 'dst_bgp_aspath',
          operator: '~',
          filterValue: `_${asn}_`
        }));
        return filterGroup;
      }
    }
    return undefined;
  }

  @computed
  get datasetInfo() {
    const { time_end, time_start, id, report_pub_name } = this.get();
    return {
      id,
      direction: this.direction,
      report_pub_name,
      timeRange: (translateFromUTCForUser(moment(time_end)) - translateFromUTCForUser(moment(time_start))) / 1000,
      time_start: moment.utc(time_start).format(DEFAULT_DATETIME_FORMAT),
      time_end: moment.utc(time_end).format(DEFAULT_DATETIME_FORMAT)
    };
  }

  @computed
  get reportField() {
    return this.get('activeField');
  }

  @computed
  get direction() {
    return this.get('direction') === 'DST' ? 'Destination' : 'Source';
  }

  @computed
  get ignoreFirstHop() {
    return this.get('ignoreFirstHop') ? 'Ignore' : 'Full';
  }

  // a part of the URL has to include a `_` separated string of ASNS, or a default.
  @computed
  get filterByASNs() {
    return this.get('selected_asns') || 'noFilterASN';
  }

  // pretty straightforward hash of strings that go in the URL.
  @computed
  get peeringDepth() {
    const depths = { 0: 'low', 1: 'normal', 2: 'high' };
    return depths[this.get('peeringDepth')];
  }

  // some camel_case stuff here
  @computed
  get includeAllDevices() {
    return this.get('includeAllDevices');
  }

  @computed
  get showDevicesInSite() {
    return this.get('showDevicesInSite');
  }

  @computed
  get filterByDevices() {
    const { device_ids, dataset_device_ids, includeAllDevices } = this.get();
    const selected_device_ids = includeAllDevices ? dataset_device_ids : device_ids;
    return Array.isArray(selected_device_ids) ? selected_device_ids.join(',') : selected_device_ids;
  }

  @computed
  get filterByInterfaces() {
    return this.get('selected_interfaces');
  }

  // time_start and time_end are computed fields, but we handle them in the UI as one field
  @computed
  get time_start() {
    return this.get('time_range')[0];
  }

  @computed
  get time_end() {
    return this.get('time_range')[1];
  }

  @computed
  get tableValueColumn() {
    return TABLE_VALUE_FIELDS[this.get('activeField')];
  }

  @computed
  get depth() {
    const { min_traffic } = this.get();
    if (min_traffic <= 200) return 'Low';
    if (min_traffic > 800) return 'High';
    return 'Medium';
  }

  @computed
  get createdOn() {
    return moment.utc(this.get('ctime')).format('YYYY-MM-DD');
  }

  @computed
  get isExpired() {
    const { ctime, report_expire } = this.get();
    const expireDate = moment.utc(ctime).add(report_expire.days, 'days');
    return !moment().isBefore(expireDate);
  }

  @computed
  get expiresOn() {
    const { ctime, report_expire } = this.get();
    const expireDate = moment.utc(ctime).add(report_expire.days, 'days');
    return {
      date: expireDate.format('YYYY-MM-DD'),
      diff: moment.utc().to(expireDate)
    };
  }

  @computed
  get interval() {
    const { interval, time_end } = this.get();

    let formatted_interval;
    if (interval && interval.days && interval.days === 1) {
      formatted_interval = 'Every day';
    } else if (interval && interval.days && interval.days === 7) {
      formatted_interval = `Every ${translateFromUTCForUser(moment.utc(time_end)).format('dddd')}`;
    } else if (interval && interval.months && interval.months === 1) {
      formatted_interval = `${this.getOrdinal(translateFromUTCForUser(moment.utc(time_end)).date())} of each month`;
    } else {
      formatted_interval = 'Invalid Interval';
    }
    return formatted_interval;
  }

  @computed
  get lookback() {
    const { time_start, time_end } = this.get();
    let lookback = moment.duration(moment(time_end).diff(moment(time_start))).asDays();
    lookback = parseInt(lookback, 10);
    return lookback;
  }

  get removalConfirmText() {
    const datasetLabel =
      this.get('sehceule_interval') !== null
        ? `${this.get('report_pub_name')} (created on ${this.createdOn})`
        : this.get('report_pub_name');
    return {
      title: 'Remove Dataset',
      text: `Are you sure you want to remove dataset ${datasetLabel}?`
    };
  }

  getOrdinal(number) {
    // we just care about numbers 1-31
    switch (number) {
      case 1:
      case 21:
      case 31:
        return `${number}st`;
      case 2:
      case 22:
        return `${number}nd`;
      case 3:
      case 23:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }
}
