import React from 'react';
import { observer } from 'mobx-react';
import { DateInput, TimePickerPrecision } from '@blueprintjs/datetime';

import { defaultPopoverProps } from 'components/forms/DateInput';
import { whitelistFieldProps } from 'forms/utils';
import { DEFAULT_DATETIME_FORMAT } from 'util/dateUtils';

const DateTime = props => {
  const { field, style } = props;
  return (
    <div style={style}>
      <DateInput
        popoverProps={defaultPopoverProps}
        {...field.getProps()}
        {...whitelistFieldProps(props)}
        timePrecision={TimePickerPrecision.MINUTE}
        format={DEFAULT_DATETIME_FORMAT}
        closeOnSelection={false}
      />
    </div>
  );
};

export default observer(DateTime);
