import { action, observable, computed } from 'mobx';
import { getHashForQueries } from 'services/urlHash';

export default class AbstractDataViewModel {
  @observable
  collapsed = false;

  @observable
  fullyLoaded = false;

  @observable
  hash;

  @observable
  hashSource;

  @observable
  isConfigureDialogOpen = false;

  @observable
  loading = true;

  @observable
  dvTitle; // Disambiguate the underlying property from getter/setter subclass stuff.

  constructor() {
    const ViewModel = this.viewModelClass;
    this.viewModel = ViewModel ? new ViewModel() : null;
  }

  get formConfig() {
    return false;
  }

  get formComponent() {
    return false;
  }

  get isFlowVisualization() {
    return true;
  }

  get lastUpdated() {
    return false;
  }

  get title() {
    return this.dvTitle;
  }

  set title(title) {
    this.dvTitle = title;
  }

  get viewType() {
    return false;
  }

  get viewName() {
    return false;
  }

  /**
   * For Dataviews that require custom configurations, and don't rely on QueryModel
   */
  @computed
  get hasConfigurationOptions() {
    return !!this.formConfig || !!this.formComponent;
  }

  @action
  setFullyLoaded() {
    this.fullyLoaded = true;
    this.loading = false;
  }

  @action
  showConfigureDialog = () => {
    this.isConfigureDialogOpen = true;
  };

  @action
  hideConfigureDialog = () => {
    this.isConfigureDialogOpen = false;
  };

  @action
  setCollapsed(collapsed) {
    this.collapsed = collapsed;
  }

  setSelectedModels(models) {
    if (this.component && this.component.setSelectedModels) {
      this.component.setSelectedModels(models);
    }
  }

  setVisibleModels(models) {
    if (this.component && this.component.setVisibleModels) {
      this.component.setVisibleModels(models);
    }
  }

  /**
   * Takes our options, gets a new hash, and then re-initializes
   */
  @action
  saveAndReinitialize(viewOptions) {
    return getHashForQueries(viewOptions, { persist: true });
  }

  /**
   * noops
   */

  clear() {
    return false;
  }

  destroy() {
    return false;
  }

  eachBucket() {
    return false;
  }

  gotoDefaultDash() {
    return false;
  }

  initializeHash() {
    return false;
  }

  initializeHashWithOverrides() {
    return false;
  }

  overlayFilters() {
    return false;
  }

  reflow() {
    return false;
  }

  refresh() {
    return false;
  }

  registerFormState() {
    return false;
  }

  resetTimeRange() {
    return false;
  }

  setUpdateFrequency() {
    return false;
  }

  updateTimeRange() {
    return false;
  }
}
