import Collection from 'models/Collection';
import MitigationPlatform from 'models/alerting/MitigationPlatform';

class MitigationPlatformCollection extends Collection {
  get url() {
    return '/api/portal/alerts/mitigation-platforms';
  }

  get model() {
    return MitigationPlatform;
  }
}

export default MitigationPlatformCollection;
