const fields = {
  thresholds: {
    label: 'Thresholds',
    isComplexArray: true
  },

  'thresholds[].enabled': {},
  'thresholds[].color': {},

  'thresholds[].min': {},
  'thresholds[].min.value': {
    rules: ['min:1', 'integer']
  },

  'thresholds[].min.type': {},

  'thresholds[].above': {},
  'thresholds[].above.value': {
    rules: ['min:1', 'integer']
  },

  'thresholds[].above.type': {},

  xaxis: {
    label: 'xaxis'
  },

  'xaxis.dimension': {
    label: 'Dimension'
  },

  'xaxis.limit': {
    label: 'Limit to'
  },

  yaxis: {
    label: 'yaxis'
  },

  'yaxis.limit': {
    label: 'Limit to'
  },

  'yaxis.display': {
    label: 'Policies',
    options: [
      { value: 'all_active', label: 'Show All with active alarms' },
      { value: 'specific', label: 'Always show specific policies' }
    ]
  },

  'yaxis.policies': {
    label: 'Select policies'
  }
};

export { fields };
