import React from 'react';

import { Flex } from 'components/flexbox';
import { getOption } from 'components/forms/select/selectHelpers';
import $dictionary from 'stores/$dictionary';
import { ALERT_METRIC_OPTIONS } from 'util/constants';

export function tagGroupFormatterGenerator(options) {
  const getLabel = value => (options && getOption(options, value) ? getOption(options, value).label : value);

  return ({ value }) => (
    <Flex style={{ marginBottom: -3 }} wrap>
      {value.map ? (
        value.map(v => <TagRenderer key={v} label={getLabel(v)} />)
      ) : (
        <TagRenderer label={getLabel(value)} />
      )}
    </Flex>
  );
}

export function TagRenderer(props) {
  return (
    <div style={{ margin: '0 2px 2px 0' }} className="pt-tag pt-minimal">
      {props.label}
    </div>
  );
}

export function dimensionsFormatterGenerator() {
  return tagGroupFormatterGenerator($dictionary.dimensionOptions);
}

export function metricsFormatterGenerator() {
  return tagGroupFormatterGenerator(ALERT_METRIC_OPTIONS);
}

export function boldFormatter({ value }) {
  return <strong>{value}</strong>;
}

export function mutedFormatter({ value }) {
  return <span className="pt-text-muted">{value}</span>;
}

export function monospaceFormatter({ value }) {
  return <span className="pt-monospace-text">{value}</span>;
}

export function numberWithCommas({ value }) {
  return value.toLocaleString();
}
