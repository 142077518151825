import React, { Fragment, Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';
import { Box } from 'components/flexbox';

@inject('$basicExplorer', '$dataviews')
@observer
class VizTypes extends Component {
  handleSelect = viz_type => {
    const { $basicExplorer } = this.props;
    $basicExplorer.setVizType(viz_type);
  };

  render() {
    const { $basicExplorer, $dataviews, options } = this.props;
    const viewTypeOptions = $dataviews.viewTypeOptions.filter(opt => options.includes(opt.value));

    return (
      <Fragment>
        <h6 className="pt-text-muted small-caps" style={{ fontSize: 12, margin: '12px 0' }}>
          Visualization
        </h6>

        <Box className="pt-button-group pt-vertical pt-align-left pt-fill">
          {viewTypeOptions.map(viewType => (
            <Button
              active={$basicExplorer.activeVizType === viewType.value}
              className="pt-text-overflow-ellipsis"
              iconName={viewType.iconName}
              text={viewType.label}
              key={viewType.label}
              onClick={() => this.handleSelect(viewType.value)}
            />
          ))}
        </Box>
      </Fragment>
    );
  }
}

export default VizTypes;
