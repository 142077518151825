import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Field, Input, Select, RadioGroup, CheckboxGroup } from 'components/forms';
import { Box, Flex } from 'components/flexbox';
import { MaterialIcon } from 'components/Icon';

import ScoreboardThreshold from './ScoreboardThreshold';

@inject('$alerts', '$dictionary')
@observer
class ConfigureScoreboardForm extends Component {
  componentDidMount() {
    const { $alerts } = this.props;
    $alerts.myPolicies.fetch();
  }

  handleDimensionTypeChange = () => {
    const { form } = this.props;

    // always reset specific policies when changing dimension
    form.setValue('yaxis.policies', []);
  };

  renderPolicyItem = ({ label, policy_description }) => (
    <span>
      <strong>{label}</strong> <br />
      {policy_description && <small className="pt-text-muted pt-normal">{policy_description}</small>}
    </span>
  );

  handleThresholdEnableDisable = (enabled, selectedIndex) => {
    // enable all thresholds above the one that was enabled.
    const { form } = this.props;
    const thresholds = form.getField('thresholds');

    if (enabled) {
      thresholds.forEach((t, index) => {
        if (index < selectedIndex) {
          t.enabled.setValue(true);
        }
      });
    }
  };

  render() {
    const { $dictionary, $alerts, form } = this.props;
    const thresholds = form.getField('thresholds');
    const xAxisDimension = form.getField('xaxis.dimension');
    const yAxisDisplay = form.getValue('yaxis.display');

    const availablePolicies = $alerts.getPoliciesWithDimensionType(xAxisDimension.getValue());

    return (
      <div>
        {xAxisDimension.getValue() !== 'ip' && (
          <Flex className="pt-callout" align="center" p={2} mb={2}>
            <MaterialIcon style={{ fontSize: 18, marginRight: 8 }} name="security" className="pt-intent-purple-text" />
            <p className="no-margin">
              <strong>Note:</strong> To see Mitigations displayed on the Scoreboard, select the {'IP/CIDR'} Dimension.
            </p>
          </Flex>
        )}
        <Flex flexAuto mb={2}>
          <Field
            field={xAxisDimension}
            options={$dictionary.alertScoreboardDimensionOptions}
            onChange={this.handleDimensionTypeChange}
          >
            <Select menuWidth={285} />
          </Field>
          <Box ml={2}>
            <Field name="xaxis.limit" className="no-margin" inputStyle={{ width: 85 }}>
              <Input />
            </Field>
          </Box>
        </Flex>

        <Flex flexAuto>
          <Field name="yaxis.display" className="no-margin" onChange={this.handleDisplayPoliciesChange}>
            <RadioGroup inline={false} />
          </Field>
          <Box ml={2}>
            <Field
              name="yaxis.limit"
              className="no-margin"
              disabled={yAxisDisplay !== 'all_active'}
              inputStyle={{ width: 85 }}
            >
              <Input />
            </Field>
          </Box>
        </Flex>

        {yAxisDisplay === 'specific' && (
          <Box className="pt-callout" p={1} style={{ maxHeight: 250, overflow: 'auto' }}>
            <Field name="yaxis.policies" showLabel={false} className="no-margin" options={availablePolicies}>
              <CheckboxGroup itemRenderer={this.renderPolicyItem} />
            </Field>
          </Box>
        )}

        <hr />
        <Box flexAuto>
          <h5 className="pt-strong">Thresholds</h5>
          <Box mt={2}>
            {thresholds.map((thresholdField, index) => (
              <ScoreboardThreshold
                key={thresholdField.min._id}
                index={index}
                threshold={thresholdField}
                thresholds={thresholds}
                onEnableDisable={this.handleThresholdEnableDisable}
              />
            ))}
          </Box>
        </Box>
      </div>
    );
  }
}

export default ConfigureScoreboardForm;
