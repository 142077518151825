import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Button, AnchorButton, Checkbox } from '@blueprintjs/core';
import { Box, Flex } from 'components/flexbox';

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import { getOption } from 'components/forms/select/selectHelpers';

const DragHandle = SortableHandle(() => (
  <AnchorButton
    className="pt-minimal pt-small"
    style={{ cursor: 'move', flex: 'none', margin: '0', width: 24 }}
    iconName="drag-handle-horizontal"
  />
));

export const TransferListItem = SortableElement(
  ({ label, value, onSelect, group, className, selected, selectedItemRenderer }) => {
    const dragHandle = <DragHandle />;

    if (selectedItemRenderer) {
      return selectedItemRenderer({
        value,
        label,
        selected,
        dragHandle,
        onSelect,
        className,
        group
      });
    }

    return (
      <Button onClick={() => onSelect(value)} className="pt-text-overflow-ellipsis pt-fill">
        <Flex align="center">
          {dragHandle}
          <Checkbox checked={selected} onChange={onSelect} />
          <Box flexAuto>{label}</Box>
        </Flex>
      </Button>
    );
  }
);

export const TransferListContainer = SortableContainer(
  ({ field, className, onSelect, selectedItemRenderer, values = [], options }) => (
    <Box className={className} mb={1}>
      {values &&
        values.map((value, index) => {
          const valueOption = getOption(options, value);
          const selected = field.getValue().includes(value);

          return (
            <TransferListItem
              key={value}
              collection="selected"
              index={index}
              selected={selected}
              onSelect={onSelect}
              selectedItemRenderer={selectedItemRenderer}
              {...valueOption}
            />
          );
        })}
    </Box>
  )
);

@observer
class TransferList extends Component {
  static defaultProps = {
    sortableHocProps: {
      useDragHandle: true,
      lockToContainerEdges: true
    }
  };

  handleSelect = value => {
    const { field, onChange } = this.props;

    const newValues = toJS(field.value) || [];
    const indexOfValue = newValues.indexOf(value);

    if (indexOfValue === -1) {
      newValues.push(value);
    } else {
      newValues.splice(indexOfValue, 1);
    }

    onChange(toJS(newValues));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { field, onChange } = this.props;
    const newValues = arrayMove(field.getValue(), oldIndex, newIndex);
    onChange(newValues);
  };

  render() {
    const { field, options, children, sortableHocProps, ...rest } = this.props;
    const values = field.getValue();

    const availableOptions = field.options.filter(opt => !values.includes(opt.value));

    const selectedList = (
      <TransferListContainer
        {...sortableHocProps}
        {...rest}
        field={field}
        onSelect={this.handleSelect}
        onSortEnd={this.onSortEnd}
        options={options}
        values={values}
      />
    );

    return children({
      selectedList,
      availableOptions,
      values,
      onSelect: this.handleSelect,
      onSortEnd: this.onSortEnd
    });
  }
}

export default observer(TransferList);
