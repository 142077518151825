function validateMirrorAndSecondaryOutsort() {
  const { viz_type, mirror, secondaryOutsort } = this.validator.input;
  if (viz_type !== 'line' && mirror && !!secondaryOutsort) {
    return false;
  }
  return true;
}

export default {
  fn: validateMirrorAndSecondaryOutsort,
  message:
    'You cannot use both Bi-directional Charting and Secondary Metric together on this chart. Please try a Line Chart to use these together.'
};
