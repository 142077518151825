import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { Dialog } from '@blueprintjs/core';

import { Flex } from 'components/flexbox';
import { Filter, PresetFilterButtons, Table } from 'components/table';
import { ShareLevelBadge } from 'components';

const tableColumns = [
  {
    label: 'Share Level',
    name: 'isPreset',
    computed: true,
    renderer: ({ model }) => <ShareLevelBadge item={model} />,
    width: 120
  },
  {
    name: 'view_name',
    label: 'Name',
    wrapText: true,
    renderer: ({ value, model }) => (
      <div>
        <strong>{value}</strong>
        <div className="pt-text-muted">{model.get('view_description')}</div>
      </div>
    )
  }
];

const groupSummaryLookup = ({ groupKey }) => groupKey;

@inject('$savedViews', '$explorer')
@observer
class SavedViewsDialog extends Component {
  loadView = savedView => {
    const { onClose, history } = this.props;
    history.push(`/savedView/${savedView.id}`);
    onClose();
  };

  render() {
    const {
      $savedViews,
      isOpen,
      onClose,
      dialogProps = {},
      title = 'Saved Views',
      onClick = this.loadView
    } = this.props;
    const { collection } = $savedViews;

    return (
      <Dialog
        {...dialogProps}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        style={{ width: 600, top: 72 }}
        transitionName="pt-dialog"
      >
        <div className="pt-dialog-body">
          <Flex mb={2}>
            <Filter style={{ minWidth: 250 }} collection={collection} placeholder="Search Saved Views..." autoFocus />
            <PresetFilterButtons collection={collection} />
          </Flex>
          <div className="pt-card flat">
            <Table
              collection={collection}
              columns={tableColumns}
              onRowClick={onClick}
              groupSummaryLookup={groupSummaryLookup}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withRouter(SavedViewsDialog);
