import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';

import { Box } from 'components/flexbox';
import { Field, Form, Input } from 'components/forms';
import { fields } from 'forms/config/passwordSet';

const labelStyle = {
  fontSize: 16,
  fontWeight: 500
};

@Form({ fields })
@inject('$auth')
@observer
class PasswordSetForm extends Component {
  state = { passwordReqComplete: false };

  handlePasswordSetSubmit = async (form, formData) => {
    const { password, token } = formData;
    const result = await this.props.$auth.passwordSet({ password, token });

    if (result.success) {
      this.setState({ passwordReqComplete: true });
    } else {
      form.invalidate(result.msg);
    }
  };

  render() {
    const { $auth, form, passwordSetToken, accountActivation } = this.props;
    const { passwordReqComplete } = this.state;
    this.props.form.getField('token').setValue(passwordSetToken);

    // Message on completion of password set.
    if (passwordReqComplete) {
      return (
        <div className="pt-callout pt-intent-success">
          <h5>Success!</h5>
          {!accountActivation && <span>Password has been set. </span>}
          {accountActivation && <span>Account has been activated. </span>}
          <a href="/" target="_blank">
            Return to Kentik
          </a>
        </div>
      );
    }

    return (
      <div>
        <Field name="password" autoFocus labelStyle={labelStyle}>
          <Input type="password" className="pt-large" leftIconName="lock" />
        </Field>
        <Field name="password_confirm" labelStyle={labelStyle}>
          <Input type="password" className="pt-large" leftIconName="lock" />
        </Field>
        {form.error &&
          !accountActivation && (
            <Box className="pt-callout pt-intent-danger pt-icon-error" my={2}>
              <h5>Password Reset Failed</h5>
              {form.error}
              <p>
                <a href="/v4/password">Request a new password reset email.</a>
              </p>
            </Box>
          )}
        {form.error &&
          accountActivation && (
            <Box className="pt-callout pt-intent-danger pt-icon-error" my={2}>
              <h5>Account Activation Failed</h5>
              {form.error}
              <p>
                <a href="/v4/password">Request a new password reset email.</a>
              </p>
            </Box>
          )}
        <Button
          className="pt-large"
          intent={Intent.PRIMARY}
          text="Set Password"
          disabled={!form.dirty || !form.valid}
          loading={$auth.passwordReqInProgress}
          onClick={() => form.submit(this.handlePasswordSetSubmit)}
        />
      </div>
    );
  }
}

export default PasswordSetForm;
