import moment from 'moment';

function beforeValidation(value, req) {
  const val1 = moment(this.validator.input[req]);
  const val2 = moment(value);
  return val1.isValid() && val2.isValid() && val2.isBefore(val1);
}

export default {
  message: ':attribute must be before other date',
  fn: beforeValidation
};
