import { action, computed, observable } from 'mobx';
import $auth from 'stores/$auth';
import Socket from 'util/Socket';

class RuleState {
  @observable
  rule;

  @observable
  complete = false;

  @observable
  evaluating = false;

  @observable
  devicesCompleted = 0;

  @observable
  devicesRemaining = 0;

  @observable
  deviceBreakdownVisible = false;

  @observable
  unclassifiedInterfacesTableVisible = true;

  evaluationResults = observable.shallowArray();

  deviceMatches = {};

  ruleInterfaceMatches = {};

  deviceCollection;

  ruleCollection;

  settings;

  onSuccess;

  onError;

  socket;

  constructor(options) {
    const { onSuccess, onError, ruleCollection, deviceCollection, settings } = options;
    this.onSuccess = onSuccess;
    this.onError = onError;
    this.ruleCollection = ruleCollection;
    this.deviceCollection = deviceCollection;
    this.settings = settings;

    this.reset();

    this.socket = new Socket({
      outType: 'classifyInterfaces',
      inType: 'classification',
      frequency: 30,
      delaySend: true,
      onSuccess: this.handleSuccess,
      onError: this.handleError
    });
  }

  @action
  reset() {
    this.rule = null;

    this.complete = false;
    this.evaluating = false;
    this.devicesCompleted = 0;
    this.devicesRemaining = 0;
    this.evaluationResults.clear();

    this.deviceBreakdownVisible = false;
    this.unclassifiedInterfacesTableVisible = true;

    this.ruleInterfaceMatches = {};
    this.deviceMatches = {};
  }

  @action
  send(params) {
    this.evaluating = true;
    this.complete = false;
    this.evaluationResults.clear();
    this.deviceMatches = {};
    this.ruleInterfaceMatches = {};

    this.socket.send(params);
  }

  addEvaluationResult(data) {
    this.evaluationResults.push(data);

    // set up device match info
    const { device_id, matchInfo = {}, rules = [], interfaces = [] } = data;
    if (device_id !== undefined) {
      const { interfaceCount = 0, interfacesClassifiedCount = 0 } = matchInfo;

      this.deviceMatches[device_id] = {
        ...matchInfo,
        percentMatched: interfaceCount > 0 ? interfacesClassifiedCount / interfaceCount : undefined,
        rules,
        interfaces
      };
    }

    // set up rule interface match info
    rules.forEach(rule => {
      const { id, interfacesMatched, interfacesClassified } = rule;

      if (!this.ruleInterfaceMatches[id]) {
        this.ruleInterfaceMatches[id] = {
          interfacesMatched: [],
          interfacesClassified: []
        };
      }

      const matchedRule = this.ruleInterfaceMatches[id];
      matchedRule.interfacesMatched.push(...interfacesMatched);
      matchedRule.interfacesClassified.push(...interfacesClassified);
    });
  }

  @action
  setEvaluationComplete(complete) {
    this.complete = complete;
  }

  /**
   *  Comes in 3 parts: `totalDevices` is the first response, followed by a bunch of devices
   *  and then followed by `complete: true`
   */
  @action
  handleSuccess = data => {
    const { totalDevices } = this;
    let { deviceBreakdownVisible, devicesCompleted, devicesRemaining } = this;

    let complete = false;

    // after the test is complete, update the DeviceCollection with the results.
    if (data.complete) {
      complete = true;
      devicesCompleted = totalDevices;
      devicesRemaining = 0;
      deviceBreakdownVisible = true;
      $auth.verifyAuth().then(
        action(() => {
          this.evaluating = false;
        })
      );
      this.deviceCollection.sort();
    } else if (data.totalDevices) {
      devicesRemaining = totalDevices;
      devicesCompleted = 0;
    } else if (Object.keys(data).length > 0) {
      this.addEvaluationResult(data);
      devicesCompleted += 1;
      devicesRemaining -= 1;
    }

    this.complete = complete;
    this.devicesCompleted = devicesCompleted;
    this.devicesRemaining = devicesRemaining;
    this.deviceBreakdownVisible = deviceBreakdownVisible;

    this.deviceCollection.updateDeviceInterfaceMatchData(this.deviceMatches);

    if (this.ruleCollection) {
      this.ruleCollection.updateRuleInterfaceMatchData(this.ruleInterfaceMatches);
    }

    if (this.onSuccess) {
      this.onSuccess(data);
    }
  };

  @action
  handleError = err => {
    // code === 0: Unknown error
    // code === 1: No SNMP data for device
    // code === 2: Engine already running for device
    switch (err.code) {
      /**
       * If we get here, it means that there's no SNMP (Interface) information available
       * for this device.
       */
      case 1:
        // console.warn('no interface data for device', err);
        break;

      case 2:
        // console.warn('engine already running for device', err);
        break;

      case -1:
        // console.warn('an unknown error occurred', err);
        break;

      default:
        // console.error('IC Socket Error', err);
        break;
    }

    if (this.onError) {
      this.onError(err);
    }
  };

  @computed
  get totalDevices() {
    return this.deviceCollection.size;
  }

  @computed
  get totalDevicesClassified() {
    return this.evaluationResults.length;
  }

  @computed
  get totalInterfaces() {
    return this.evaluationResults.reduce((sum, { matchInfo = {} }) => sum + matchInfo.interfaceCount, 0);
  }

  @computed
  get totalClassified() {
    return this.evaluationResults.reduce((sum, { matchInfo = {} }) => sum + matchInfo.interfacesClassifiedCount, 0);
  }

  @computed
  get totalPercentClassified() {
    return this.totalInterfaces === 0 ? 0 : this.totalClassified / this.totalInterfaces;
  }
}

export default RuleState;
