import React from 'react';
import { Flex, Box } from 'components/flexbox';
import { ParametricTag, ShareLevelBadge } from 'components';

const DashLink = ({ dashboard, ...boxProps }) => (
  <Flex justify="space-between" style={{ whiteSpace: 'nowrap' }} {...boxProps}>
    <Box mr={0.5} className="pt-text-overflow-ellipsis">
      <a href={`/library/dashboard/${dashboard.get('id')}`}>{dashboard.get('dash_title')}</a>
    </Box>
    <Flex flexAuto justify="flex-end">
      {dashboard.get('parametric') && <ParametricTag />}
      <ShareLevelBadge showIcon={false} item={dashboard} />
    </Flex>
  </Flex>
);

export default DashLink;
