import { computed } from 'mobx';
import { groupBy } from 'lodash';

import Collection from '../Collection';
import Dataset from './Dataset';

class DatasetCollection extends Collection {
  get url() {
    return '/api/portal/bgpDatasets';
  }

  get model() {
    return Dataset;
  }

  @computed
  get groupedDatasets() {
    return groupBy(this.models, model => model.get('report_pub_name'));
  }
}

export default DatasetCollection;
