const colorFields = mode => ({
  [`settings.visualizations.${mode}`]: {},
  [`settings.visualizations.${mode}.palette`]: {},
  [`settings.visualizations.${mode}.paletteCustom[]`]: {
    children: {
      rules: 'required|hex',
      messages: {
        required: 'You must enter a valid hex code'
      }
    }
  },
  [`settings.visualizations.${mode}.qualitative`]: {},
  [`settings.visualizations.${mode}.qualitativeCustom[]`]: {
    children: {
      rules: 'required|hex',
      messages: {
        required: 'You must enter a Sending IP address'
      }
    }
  },
  [`settings.visualizations.${mode}.primaryOverlay`]: {
    rules: 'required|hex'
  },
  [`settings.visualizations.${mode}.overlay`]: {
    rules: 'required|hex'
  },
  [`settings.visualizations.${mode}.labels`]: {
    rules: 'required|hex'
  }
});

const fields = {
  settings: {},
  ...colorFields('standard'),
  ...colorFields('dark')
};

const options = {
  name: 'Visualizations'
};

export { fields, options };
