import React from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';

import { Box } from 'components/flexbox';

/**
 * Simple representation of a `pt-callout` with Intent automatically applied and such.
 */
const Callout = props => {
  const { className, iconName } = props;
  const calloutClassNames = classNames(
    'pt-callout',
    {
      [`pt-icon-${iconName}`]: iconName
    },
    className
  );

  return (
    <Box {...omit(props, 'iconName')} className={calloutClassNames}>
      {props.children}
    </Box>
  );
};

export default Callout;
