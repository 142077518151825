import { computed } from 'mobx';

import { PROVIDERS } from 'forms/config/cloudExportDetails';
import BaseModel from 'models/BaseModel';
import DeviceCollection from 'models/devices/DeviceCollection';
import { PLAN_TYPES } from 'util/constants';
import { deviceTypeByKflow } from 'util/utils';

class Plan extends BaseModel {
  get defaults() {
    return {
      devices: []
    };
  }

  get urlRoot() {
    return '/api/portal/plans';
  }

  get jsonRoot() {
    return 'plan';
  }

  get omitDuringSerialize() {
    return ['devices'];
  }

  /**
   * A good example of creating a "sub" collection, and enhancing data we get back from the server
   * for use in our components. Creating a collection here allows us to use our <Table> component
   * and be more flexible about data management if we want!
   */
  constructor(attributes = {}) {
    super(attributes);
    this.set({ devices: new DeviceCollection(attributes.devices) });
  }

  deserialize(data) {
    if (
      data &&
      data.metadata &&
      (data.metadata.type === 'cloud' || data.metadata.type === 'flowpak' || data.metadata.type === 'universalpak')
    ) {
      if (data.max_fps % 10 === 5) {
        data.max_fps -= 5;
        data.metadata.isPak = true;
      }
    }
    return super.deserialize(data);
  }

  serialize(data) {
    if (
      data &&
      data.metadata &&
      (data.metadata.type === 'cloud' || data.metadata.type === 'flowpak' || data.metadata.type === 'universalpak')
    ) {
      if (data.metadata.isPak === true) {
        data.max_fps += 5;
      }
    }
    return super.serialize(data);
  }

  @computed
  get numDevices() {
    const devices = this.get('devices');
    const numDevices = devices.models && devices.models.length;

    if (numDevices === 0) {
      return 'No Devices';
    }

    return numDevices > 1 ? `${numDevices} Devices` : '1 Device';
  }

  @computed
  get allowedDeviceTypes() {
    return this.get('deviceTypes')
      .map(({ device_type, kflow_type }) => deviceTypeByKflow(device_type || kflow_type).map(dt => dt.label))
      .join(', ');
  }

  @computed
  get typeLabel() {
    const type = this.get('metadata.type');
    const planTypeLabel = type !== 'legacy' && PLAN_TYPES[type] ? PLAN_TYPES[type].name : null;

    if (planTypeLabel) {
      const cloudProvider = this.get('metadata.cloudProvider');
      return cloudProvider && PROVIDERS.byId(cloudProvider)
        ? `${planTypeLabel}${cloudProvider ? ` - ${PROVIDERS.byId(cloudProvider).code}` : ''}`
        : '';
    }

    return '';
  }
}

export default Plan;
