import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'components/flexbox';
import DataViewWrapper from 'dataviews/DataViewWrapper';
import DataViewTools from 'dataviews/DataViewTools';
import Legend from 'dataviews/views/legend/Legend';
import { ChangeViewTypeMenu } from 'dataviews/tools';

import ExplorerQueryModel from 'models/query/ExplorerQueryModel';
import ItemExplorerOptions from './ItemExplorerOptions';

@inject('$dataviews', '$explorer')
@observer
export default class ItemExplorer extends Component {
  componentWillMount() {
    const { dashboardItem } = this.props;
    this.setState(() => ({ sourceLink: { type: 'dashboard', model: dashboardItem } }));
  }

  render() {
    const { $dataviews, $explorer, dashboardItem, dashboard } = this.props;
    const { dataview } = $explorer;
    const { selectedQuery } = dataview.queryBuckets;

    const { sourceLink } = this.state;
    const modelOverrides = {};
    if ($explorer.queryModel) {
      Object.assign(modelOverrides, {
        time_locked: $explorer.queryModel.get('time_locked'),
        device_locked: $explorer.queryModel.get('device_locked'),
        filter_source: $explorer.queryModel.get('filter_source')
      });
    }
    $explorer.queryModel = ExplorerQueryModel.createFromQueryModel(selectedQuery);
    $explorer.queryModel.set(modelOverrides);
    const dataViewTools = (
      <DataViewTools dataview={dataview}>
        {({ Wrapper, ...buttonProps }) => (
          <Wrapper>
            <ChangeViewTypeMenu {...buttonProps} />
          </Wrapper>
        )}
      </DataViewTools>
    );

    const viewCfg = $dataviews.getConfig(dataview.viewType);

    return (
      <Flex className="overflow-hidden" flexAuto>
        <ItemExplorerOptions model={$explorer.queryModel} dashboardItem={dashboardItem} dashboard={dashboard} />
        <Flex className="overflow-hidden" flexColumn ml={1} flexAuto>
          <DataViewWrapper
            dataview={dataview}
            sourceLink={sourceLink}
            headerProps={{
              shouldArrangeVertically: true,
              tools: dataViewTools
            }}
            viewProps={{
              showNativeLegend: false,
              forceLoad: !$explorer.dataview.loading
            }}
          >
            {({ component, header, footer, className }) => (
              <Flex className={className} style={{ height: 500 }} flexColumn>
                <Box m={2}>{header}</Box>
                <Flex flexAuto>{component}</Flex>
                <Box m={2} mt={0}>
                  {footer}
                </Box>
              </Flex>
            )}
          </DataViewWrapper>
          {viewCfg.showLegend && <Legend showLegendOptions dataview={dataview} />}
        </Flex>
      </Flex>
    );
  }
}
