import { generateFilterFields } from './helpers';

const fields = {
  user_full_name: {
    label: 'Full Name',
    placeholder: 'ie. Joe Smith',
    rules: 'max:50'
  },

  user_email: {
    label: 'Email Address',
    placeholder: 'Email will be used to access the system',
    rules: 'required|email',
    validateDebounceDurationMs: 500
  },

  role: {
    label: 'User Level',
    type: 'select',
    placeholder: 'Select User Level'
  },

  user_group_id: {
    label: 'Tenant',
    type: 'select',
    defaultValue: null,
    placeholder: 'N/A'
  },

  email_product: {
    label: 'Service Updates',
    options: [
      {
        value: true,
        label: 'Yes'
      },
      {
        value: false,
        label: 'No'
      }
    ]
  },

  email_service: {
    label: 'Product Updates',
    options: [
      {
        value: true,
        label: 'Yes'
      },
      {
        value: false,
        label: 'No'
      }
    ]
  },

  user_api_token: {
    label: 'API Token'
  },

  user_2fa: {
    label: '2-Factor'
  },

  defaultLandingPageType: { defaultValue: 'library' },

  defaultLandingPageValue: {
    defaultValue: null,
    messages: {
      required: 'Please select a dashboard or a saved view'
    }
  },

  settings: {},

  'settings.canCancelRawFlow': {
    defaultValue: false,
    label: 'Cancel Forensic Queries'
  },

  ...generateFilterFields()
};

export { fields };
