import React from 'react';
import { observer } from 'mobx-react';
import { Tag } from '@blueprintjs/core';

const ColorBlock = observer(({ color, style, disabled }) => (
  <Tag
    className="pt-large"
    style={{
      width: 16,
      height: 16,
      padding: 0,
      minWidth: 'auto',
      boxShadow: 'inset 3px 3px 0px rgba(255, 255, 255, 0.5)',
      background: color,
      opacity: disabled ? 0.65 : 1,
      ...style
    }}
  >
    &nbsp;
  </Tag>
));

export default ColorBlock;
