import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Box } from 'components/flexbox';
import { Intent, Button } from '@blueprintjs/core';
import scoreboardScreenshot from 'scoreboard-sample.png';
import $auth from 'stores/$auth';

const NoAlertScoreboards = ({ onCreateScoreboard }) => {
  if (!$auth.isAdministrator) {
    return (
      <Box style={{ textAlign: 'center' }} p={4}>
        <strong>No alert scoreboard has been configured. Please contact your administrator to get started.</strong>
      </Box>
    );
  }

  return (
    <Box col={12} p={0} pb={1}>
      <Flex align="center" justify="space-between" p={2} pb={0}>
        <h6 className="no-margin pt-normal pt-text-muted">
          <div className="pt-tag pt-strong pt-intent-warning" style={{ marginRight: 6 }}>
            NEW
          </div>{' '}
          Use the Policy Scoreboard to get a quick overview of your Policies
        </h6>
        <Button
          iconName="cog"
          className="pt-strong"
          text="Configure Scoreboard"
          intent={Intent.PRIMARY}
          onClick={onCreateScoreboard}
        />
      </Flex>
      <Box mx={1}>
        <img
          style={{ width: '100%', height: 'auto', opacity: 0.6 }}
          src={scoreboardScreenshot}
          alt="Sample Scoreboard Screenshot"
        />
      </Box>
    </Box>
  );
};

export default observer(NoAlertScoreboards);
