import moment from 'moment';

function afterValidation(value, req) {
  const val1 = moment(this.validator.input[req]);
  const val2 = moment(value);

  return val1.isBefore(val2);
}

export default {
  message: ':attribute must be after other date',
  fn: afterValidation
};
