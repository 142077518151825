import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Dialog, Intent } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';
import { Form, Field, Select } from 'components/forms/index';
import Dashboard from 'models/dashboards/Dashboard';
import { companyPlanRenderer } from 'views/Admin/Companies/components/CompanyTable';

const fields = {
  company_id: {
    label: 'Company',
    rules: 'required'
  },
  dashboard_id: {
    label: 'Dashboard',
    placeholder: 'Select Dashboard...',
    rules: 'required'
  }
};

const options = {
  name: 'Asset Migration'
};

@Form({ fields, options })
@inject('$auth', '$library', '$sudo')
@observer
class AssetMigration extends Component {
  state = {
    isFetchingCompanies: false,
    isFetchingDashboards: false,
    isCloning: false,
    dashboardOptions: []
  };

  handleSubmit = (form, values) => {
    const { $auth, $library } = this.props;
    this.setState({ isCloning: true });

    const { dashboard_id } = values;
    const { dash_title } = this.state.dashboardOptions.find(option => option.value === dashboard_id);

    Dashboard.duplicate(
      dashboard_id,
      `[Copy from ${$auth.activeUser.company.company_name}] ${dash_title}`,
      $library.reportsCollection
    ).then(
      dashboard => {
        this.setState({ isCloning: false });
        this.handleClose();
        $library.selectReport(dashboard, {});
      },
      () => {
        this.setState({ isCloning: false });
      }
    );
  };

  componentDidMount() {
    const { companiesCollection } = this.props.$sudo;
    this.state.isFetchingCompanies = true;

    companiesCollection.fetch().then(() => {
      this.setState({ isFetchingCompanies: false });
    });
  }

  handleClose = () => {
    this.props.$sudo.setAssetMigrationOpen(false);
  };

  handleCancel = () => {
    const { form } = this.props;

    setTimeout(() => {
      form.reset();
    }, 500);

    this.handleClose();
  };

  handleCompanyChange = (field, companyId) => {
    const { $library } = this.props;

    this.setState({ isFetchingDashboards: true, dashboardOptions: [] });

    $library.fetchDashboardsForCompany(companyId).then(dashboards => {
      this.setState({
        isFetchingDashboards: false,
        dashboardOptions: dashboards.map(d => ({
          value: d.id,
          label: (
            <span>
              <strong>{d.dash_title}</strong>
              <div className="pt-text-muted">{d.description}</div>
            </span>
          ),
          dash_title: d.dash_title
        }))
      });
    });
  };

  render() {
    const { form, $sudo } = this.props;

    if (!form) {
      return null;
    }

    const { isCloning, isFetchingCompanies, isFetchingDashboards, dashboardOptions } = this.state;
    const company_id = form.getValue('company_id');

    return (
      <Dialog
        isOpen
        title="Asset Migration"
        onClose={this.handleClose}
        style={{ top: 100, width: 476, minHeight: 275 }}
        transitionName="pt-dialog"
      >
        <Box p={3} pb={0} flexAuto>
          <Box mb={2} className="pt-callout pt-intent-primary">
            Use this form to migrate a dashboard from another company to the company you're currently in. Note: you will
            likely need to reconcile aspects of the dashboard to make it fully functional (custom dimensions, saved
            filters, etc.).
          </Box>
          <Field
            name="company_id"
            options={$sudo.companiesCollection.selectOptions}
            onChange={this.handleCompanyChange}
          >
            <Select
              loading={isFetchingCompanies}
              autoComplete
              exactMatch
              showFilter
              menuWidth={428}
              optionLimit={50}
              optionRenderer={({ label, plan, className, selected, selectItem, field, value }) => {
                const onClick = !selected ? () => selectItem(field, value) : undefined;
                return (
                  <Flex key={value} justify="space-between" className={className} onClick={onClick}>
                    <div>
                      {label} <span className="pt-text-muted">({value})</span>
                    </div>
                    <Box>{companyPlanRenderer({ value: plan })}</Box>
                  </Flex>
                );
              }}
            />
          </Field>
        </Box>
        <Box px={3} mt={2}>
          <Field name="dashboard_id" disabled={!company_id} options={dashboardOptions}>
            <Select menuWidth={428} loading={isFetchingDashboards} />
          </Field>
        </Box>
        <Flex className="pt-dialog-footer" mt={2} justify="flex-end">
          <Button text="Cancel" onClick={this.handleCancel} style={{ marginRight: 8, width: 110 }} />
          <Button
            disabled={!form.valid || !form.dirty}
            intent={Intent.PRIMARY}
            className="pt-medium"
            loading={isCloning}
            text="Migrate Dashboard"
            onClick={() => form.submit(this.handleSubmit)}
            style={{ minWidth: 110 }}
          />
        </Flex>
      </Dialog>
    );
  }
}

export default AssetMigration;
