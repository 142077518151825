import React, { Component, Fragment } from 'react';
import { inject } from 'mobx-react';
import { Radio } from '@blueprintjs/core';
import { Box } from 'components/flexbox';
import { createQueryString } from 'util/querystring';
import { getUtmCookies } from 'util/utmTracking';

@inject('$auth')
export default class RegionSelect extends Component {
  handleRegionChange = region => {
    window.location = region.baseUrl + window.location.pathname + createQueryString(getUtmCookies());
  };

  render() {
    const { $auth } = this.props;
    const { regions, currentRegion } = $auth;

    if (!currentRegion) {
      return null;
    }

    return (
      currentRegion && (
        <Box mb={2}>
          <Box className="pt-card flat trial-signup-region" p={2}>
            <p style={{ fontSize: 16, fontWeight: 500 }}>Deploy trial to our datacenter in...</p>

            {regions.map((region, index) => (
              <Radio
                key={region.baseUrl}
                value={region.name}
                checked={currentRegion.name === region.name}
                onChange={() => this.handleRegionChange(region)}
                style={index > 0 ? { marginBottom: 0 } : {}}
                label={
                  <Fragment>
                    <strong>{region.name}: </strong>{' '}
                    <span className="pt-text-muted">{region.baseUrl.replace('https://', '')}</span>
                  </Fragment>
                }
              />
            ))}
          </Box>
        </Box>
      )
    );
  }
}
