import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Field, Select } from 'components/forms';
import { Flex, Box } from 'components/flexbox';
import Icon from 'components/Icon';

const selectValueRenderer = option => (
  <span>
    <strong>{option.label}</strong>
  </span>
);

const groupIncludeExcludeRenderer = option => (
  <span>
    <Icon name={option.value ? 'cross' : 'tick'} style={{ fontSize: 12, marginRight: 4 }} />
    <strong>{option.label}</strong>
  </span>
);

@observer
class FilterConnector extends Component {
  render() {
    const { group, readOnly } = this.props;
    const connector = group.connector.getValue();
    const shouldExcludeResults = group.not.getValue();

    if (readOnly) {
      return (
        <Box p={0.5}>
          <Icon
            className={shouldExcludeResults ? 'pt-intent-danger-text' : 'pt-intent-success-text'}
            name={shouldExcludeResults ? 'small-cross' : 'small-tick'}
            style={{ fontSize: 12, marginRight: 2 }}
          />
          <span className={shouldExcludeResults ? 'pt-intent-danger-text' : 'pt-intent-success-text'}>
            {shouldExcludeResults ? 'Exclude ' : 'Include'}
          </span>{' '}
          <div className="any-all-operator pt-monospace-text" style={{ marginRight: 4 }}>
            {connector}
          </div>
          <span>of the following:</span>
        </Box>
      );
    }

    return (
      <Flex align="center">
        <Field className="no-margin" field={group.not} showLabel={false}>
          <Select
            className={`pt-small pt-minimal ${shouldExcludeResults ? 'pt-intent-danger' : 'pt-intent-success'}`}
            valueRenderer={groupIncludeExcludeRenderer}
          />
        </Field>
        <Box px={1} style={{ lineHeight: '24px' }}>
          <small>results matching</small>
        </Box>
        <Field className="no-margin" field={group.connector} showLabel={false}>
          <Select className="pt-small pt-minimal pt-intent-primary" valueRenderer={selectValueRenderer} />
        </Field>
        <Box px={1} style={{ lineHeight: '24px' }}>
          <small>of the following conditions:</small>
        </Box>
      </Flex>
    );
  }
}

export default FilterConnector;
