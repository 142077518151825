import React from 'react';
import { observer } from 'mobx-react';
import { InputGroup } from '@blueprintjs/core';
import { Box } from 'components/flexbox';
import { whitelistFieldProps, shiftInputCursor } from 'forms/utils';

// const vowelRemap = {
//   a: 's',
//   e: 'w',
//   i: 'u',
//   o: 'i',
//   u: 'y'
// };

const Input = props => {
  const { field, className, style, onChange, inputRef } = props;

  function handleChange(e) {
    if (onChange) {
      onChange(e);
    }
  }

  return (
    <Box style={style}>
      <InputGroup
        {...field.getProps()}
        {...whitelistFieldProps(props)}
        inputRef={inputRef}
        className={className}
        autoComplete="off"
        onChange={handleChange}
        onFocus={props.autoFocus ? shiftInputCursor : undefined}
      />
    </Box>
  );
};

export default observer(Input);
