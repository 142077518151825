import { observer } from 'mobx-react';
import $dictionary from 'stores/$dictionary';
import moment from 'moment';
import $app from 'stores/$app';

import BaseHighchartsDataview from './BaseHighchartsDataview';
import { getSharedTooltip } from './tooltipRenderers';

@observer
export default class StackedAreaView extends BaseHighchartsDataview {
  sync = false;

  chartOptions = {
    chart: {
      zoomType: 'x',
      events: {
        selection: this.handleTimeZoom
      }
    },
    colors: this.chartColors,
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%m/%e',
        minute: '%H:%M'
      },
      title: {
        text: 'UTC',
        style: { color: this.chartLabelColor }
      },
      labels: { style: { color: this.chartLabelColor } },
      gridLineWidth: 1
    },
    yAxis: [
      {
        min: 0.001,
        title: {
          text: 'units go here',
          style: { color: this.chartLabelColor }
        },
        labels: { style: { color: this.chartLabelColor } },
        gridLineWidth: 1
      }
    ],
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      },
      area: {
        stacking: 'normal'
      }
    },
    legend: { enabled: false },
    tooltip: {
      shared: true,
      crosshairs: true,
      formatter() {
        return getSharedTooltip(this.points, 'area', getSharedTooltip(this.points));
      }
    },
    series: []
  };

  renderSeries(bucket, name, data) {
    if (!this.chart) {
      return null;
    }

    const type = 'area';
    const yAxis = bucket.get('name') === 'Left +Y Axis' ? 0 : 1;
    const seriesData = { type, name, data, yAxis };
    const matchingSeries = this.chart.series.find(series => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = matchingSeries.name;
      seriesData.color = matchingSeries.color;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
    }
    return this.chart.addSeries(seriesData, false);
  }

  findSeries(bucket, name) {
    const yAxisIdx = bucket.get('name') === 'Left +Y Axis' ? 0 : 1;
    return this.chart.series.find(series => series.name === name && series.yAxis === this.chart.yAxis[yAxisIdx]);
  }

  removeSeries(bucketName, name) {
    const yAxisIndex = bucketName === 'Left +Y Axis' ? 0 : 1;
    const existingSeries =
      this.chart &&
      this.chart.series.find(series => series.name === name && series.yAxis === this.chart.yAxis[yAxisIndex]);
    if (existingSeries) {
      existingSeries.remove(false);
    } else {
      console.warn('Attempted to remove series', name, 'from bucket', bucketName, ', but no series was found.');
    }
  }

  renderOverlay(bucket, name, data) {
    if (!this.chart) {
      return null;
    }

    const yAxis = bucket.get('name') === 'Left +Y Axis' ? 0 : 1;
    const type = 'line';
    let color = this.primaryOverlayColor;
    let dashStyle = 'Solid';

    if (name.startsWith('Historical Total')) {
      color = this.overlayColor;
      dashStyle = 'Dash';
    }

    const seriesData = { type, name, data, yAxis, color, dashStyle, zIndex: 1 };
    const matchingSeries = this.chart.series.find(series => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = name;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
    }

    return this.chart.addSeries(seriesData, false);
  }

  redraw(opts) {
    super.redraw(opts);
    $app.renderSync(() => {
      if (this.chart.series.length > 0) {
        const { dateRangeDisplay } = this.props.dataview.queryBuckets.activeBuckets[0].firstQuery;
        this.chart.xAxis[0].setTitle({
          text: `${dateRangeDisplay} (${this.chart.series[0].userOptions.data[0][2] / 60} minute intervals)`
        });
      }
      if (!this.hasOverriddenExtremes && this.chart && this.chart.yAxis.length > 1) {
        if (this.sync) {
          const { dataMin } = this.chart.yAxis[1].getExtremes();
          const { dataMax } = this.chart.yAxis[0].getExtremes();
          const extreme = Math.max(Math.abs(dataMin), dataMax);
          if (extreme) {
            this.chart.yAxis.forEach(axis => axis.setExtremes(dataMin < 0 ? extreme * -1 : 0.001, extreme));
          }
        } else {
          const { dataMin: extremeMin } = this.chart.yAxis[1].getExtremes();
          this.chart.yAxis.forEach(axis => {
            const { dataMin, dataMax } = axis.getExtremes();
            const extreme = Math.max(Math.abs(dataMin), Math.abs(dataMax));
            if (extreme) {
              axis.setExtremes(extremeMin < 0 ? extreme * -1 : 0.001, extreme);
            }
          });
        }
      }
    });
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview, showNativeLegend } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;

    if (query.get('outsort').includes('agg_total')) {
      throw new Error('This Time-Series chart does not support Display & Sort By Total.');
    }

    chartOptions.xAxis.momentFn = query.get('time_format') === 'UTC' ? moment.utc : moment;
    chartOptions.xAxis.title.text = query.dateRangeDisplay;
    chartOptions.yAxis[0].title.text = `${$dictionary.dictionary.units[query.outsortUnit]}`;
    chartOptions.yAxis[0].unit = query.outsortUnit;
    chartOptions.yAxis[0].type = query.get('use_log_axis') ? 'logarithmic' : 'linear';
    chartOptions.yAxis[0].min = query.get('use_log_axis') ? 0 : 0.001;
    chartOptions.colors = this.chartColors;

    if (showNativeLegend) {
      chartOptions.legend = {
        itemStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'bold !important'
        },
        labelFormatter() {
          return this.name.substr(0, 16);
        },
        verticalAlign: 'bottom',
        align: 'center'
      };
    }

    if (activeBucketCount > 1 || (query.get('secondaryOutsort') && query.get('secondaryTopxSeparate') === false)) {
      chartOptions.yAxis[0].min = null;
      chartOptions.yAxis[0].title.text = `${$dictionary.dictionary.units[query.outsortUnit]} \u2192`;
      const outsortUnit = activeBuckets[1] ? activeBuckets[1].firstQuery.outsortUnit : query.secondaryOutsortUnit;
      if (!chartOptions.yAxis[1]) {
        chartOptions.yAxis.push({
          title: {
            text: `${$dictionary.dictionary.units[outsortUnit]} \u2192`
          },
          opposite: true,
          gridLineColor: '#DEDEDE',
          gridLineWidth: 1,
          plotLines: [
            {
              id: 'zero-plot',
              value: 0,
              width: 2,
              color: '#c0c0c0',
              zIndex: 5
            }
          ],
          unit: outsortUnit,
          type: query.get('use_secondary_log_axis') ? 'logarithmic' : 'linear'
        });
      } else {
        chartOptions.yAxis[1].title.text = `${$dictionary.dictionary.units[outsortUnit]} \u2192`;
        chartOptions.yAxis[1].unit = outsortUnit;
      }
    } else if (chartOptions.yAxis.length > 1) {
      chartOptions.yAxis = chartOptions.yAxis.slice(0, 1);
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: true,
  enableToggle: true,
  mirrorable: true,
  allowsSecondaryOverlay: true,
  allowsSyncAxes: true,
  supportsLogAxis: true,
  buckets: [
    {
      name: 'Left +Y Axis',
      mirrorBucket: 1,
      secondaryOverlayBucket: 1
    },
    {
      name: 'Right -Y Axis',
      sampleRateFactor: -1
    }
  ]
};

export { config };
