import { computed, action } from 'mobx';
import { orderBy } from 'lodash';

import { showErrorToast, showSuccessToast } from 'components/Toast';
import { hasDependencyFailures } from 'dataviews/dependencies/ReportDependencyChecker';
import $dictionary from 'stores/$dictionary';
import $auth from 'stores/$auth';
import $app from 'stores/$app';
import BaseModel from 'models/BaseModel';
import QueryModel from 'models/query/QueryModel';
import { deepClone } from 'util/utils';
import api from 'util/api';
import { injectLegacySavedFilters } from '../../services/filters';

import DashboardItemCollection from './DashboardItemCollection';

export default class Dashboard extends BaseModel {
  static layoutDefaults = {
    breakpoints: { lg: 900, sm: 0 },
    cols: { lg: 12, sm: 1 },
    containerPadding: [16, 16],
    margin: [16, 16],
    rowHeight: 100
  };

  get defaults() {
    return {
      devices_source: 'dashboard',
      filters_source: 'dashboard',
      query: {},
      share_level: $auth.isAdministrator ? 'org' : 'self',
      time_source: 'dashboard',
      parametric: false,
      type: 'dashboard',
      liveUpdateInterval: 0,

      // We always have a default parametric field, but disable the functionality by default.
      parametric_fields: [
        { type: 'as_name', value: '', label: 'AS Name', question: 'Which AS Name would you like to see?' }
      ],
      parametric_value_type: 'freeform',
      parametric_value_options: []
    };
  }

  get url() {
    // Force dashboards (in e.g. ReportsCollection to use proper dashboard URLs)
    if (this.isNew) {
      return this.urlRoot;
    }

    return `${this.urlRoot}/${this.get('id')}`;
  }

  get urlRoot() {
    return '/api/portal/dashboards';
  }

  get omitDuringSerialize() {
    return ['items', 'liveUpdateInterval', 'device_name', 'dependents', 'dependencies', 'category'];
  }

  get name() {
    return this.get('dash_title');
  }

  get description() {
    return this.get('description');
  }

  get type() {
    return 'Dashboard';
  }

  get featured() {
    return this.get('featured');
  }

  @action
  async updateLayout(layout) {
    if (this.canEdit) {
      return this.save(
        { layout: layout || Object.assign({}, this.get('layout')) },
        {
          optimistic: false,
          patch: true,
          setOnPatchSuccess: false,
          clearSelection: false,
          toast: false
        }
      ).then(res => this.set({ layout: res.layout }));
    }

    return this.set({ layout });
  }

  deserialize(data) {
    if (!data) {
      return data;
    }

    data = super.deserialize(data);
    const { query, items, id, filters, saved_filters } = data;

    if (id) {
      data.id = parseInt(id);
    }

    if (query) {
      data.query = QueryModel.create(query).get();
    }

    if (items && Array.isArray(items)) {
      // create an easy reference to the Dashboard to make it easier for DashboardItems to self-sustain
      const dashboardItems = items.map(item => ({ ...item, dashboard: this }));

      data.items = new DashboardItemCollection(dashboardItems, { dashboard: this });
    }

    if (saved_filters && saved_filters.length > 0) {
      data.filters = injectLegacySavedFilters(saved_filters, filters);
      delete data.saved_filters;
    }

    return data;
  }

  serialize(data) {
    const serialized = { ...data };
    const queryModel = QueryModel.create().set(serialized.query);
    serialized.query = queryModel.serialize();

    if (!serialized.parametric_value_options) {
      serialized.parametric_value_options = [];
    }

    // clear out values so we don't persist user entered values to the db
    if (serialized.parametric && serialized.parametric_fields.length) {
      serialized.parametric_fields.forEach(field => delete field.value);
    }

    if (serialized.category_id === '') {
      serialized.category_id = null;
    }

    return super.serialize(serialized);
  }

  @action
  duplicate = () =>
    Dashboard.duplicate(this.get('id'), this.getUniqueCopyName('dash_title').substr(0, 50), this.collection);

  @action
  static duplicate(id, dash_title, collection) {
    const url = `/api/portal/dashboards/copy/${id}`;
    const data = { dash_title };

    if (collection) {
      collection.requestStatus = 'Copying';
    }

    return api.post(url, { data }).then(
      attributes => {
        showSuccessToast(`Successfully created copy: ${attributes.dash_title}`);

        return collection.fetch().then(() => collection.get(attributes.id));
      },
      () => {
        showErrorToast('Dashboard copy could not be created.');
        if (collection) {
          collection.requestStatus = null;
        }
      }
    );
  }

  save(attributes = {}, options = {}) {
    if ($auth.isPresetCompany || !this.isPreset) {
      const { parametric, parametric_fields } = attributes;
      let parametricFields = [];

      if (parametric) {
        // Make a copy of the parametric fields as they'll be mutated when serialized
        // When serialized, the 'value' field is removed to prevent saving in the db
        parametricFields = parametric_fields.map(f => ({ ...f }));
      }

      return super.save(attributes, options).then(
        action(response => {
          this.hasDependencyFailures = hasDependencyFailures(this);

          if (parametric) {
            // Reinstate the parametric fields with values so the parametric form doesn't lose state
            this.set({ parametric_fields: parametricFields });

            return {
              ...response,
              parametric_fields: parametricFields
            };
          }

          return response;
        })
      );
    }

    return Promise.resolve(Object.assign({}, this.attributes.toJS(), attributes));
  }

  /**
   * Emergency fix -Aaron
   */
  @action
  async create(attributes = {}, options = {}) {
    const label = 'creating';
    const data = Object.assign({}, this.attributes.toJS(), attributes);
    const payload = this.serialize(data);

    const { clearSelection, toast = true } = options;

    const url = this.urlRoot;
    const promise = api.post(url, { data: payload });

    this.requestStatus = label;

    return promise.then(
      success => {
        this.set(this.deserialize(success));

        if (this.collection) {
          this.collection.add(this);

          if (clearSelection) {
            this.collection.clearSelection();
            this.collection.sort(); // Note: it seemed to make sense not to re-apply existing filter rules
          }
        }

        if (toast) {
          const createMessage = this.messages ? this.messages.create : 'Created Successfully';
          showSuccessToast(createMessage, { title: this.showToastTitles ? 'Created' : undefined });
        }

        this.requestStatus = null;

        return success;
      },
      error => {
        this.error = { label, body: error };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  @action
  fetchDependentsAndDependencies = () => {
    const url = `/api/portal/dashboards/dependentsDependencies/${this.id}`;
    return api.get(url).then(data => {
      this.set({ ...data });
    });
  };

  @action
  generateItemLayout = (options = {}) => {
    const { returnLayout = false } = options;
    const items = [].concat(this.get('items').models.toJS());
    const currentLayout = this.get('layout');
    let updated = false;
    let layout;

    if (currentLayout === undefined || currentLayout === null) {
      layout = { lg: [], sm: [] };
      updated = true;
    } else {
      layout = deepClone(currentLayout);
    }

    items.forEach(item => {
      if (!layout.lg.find(l => l.i === `${item.get('id')}`)) {
        const itemLayout = item.layoutData;
        layout.lg.push(itemLayout.lg);
        layout.sm.push(itemLayout.sm);
        updated = true;
      }
    });

    if (updated) {
      const firstItem = layout.lg.find(l => l.y === 0);
      if (firstItem) {
        firstItem.y = 1;
      }
    }

    if (returnLayout) {
      return layout;
    }

    return updated ? this.updateLayout(layout) : Promise.resolve(this);
  };

  @action
  setUpdateInterval = liveUpdateInterval => {
    this.get('items').each(item => item.setUpdateFrequency(liveUpdateInterval));
    this.set({ liveUpdateInterval });
  };

  /**
   * If a Dashboards parametric_type changes, all of it's Items `parametric_overrides` field must be updated.
   * This happens on the server automatically, but don't want to return (and create) an entire `items` Collection
   * when we save, so just update locally.
   */
  @action
  updateItemsParametricFilterField = () => {
    const { flatParametricFilterTypes, parametricOperators } = $dictionary.dictionary.queryFilters;
    const parametric_field = this.get('parametric_fields')[0];

    // when the Parametric field changes, need to update the value to something valid.
    const updatedParametricFields = { parametric_fields: [{ ...parametric_field, value: '' }] };

    this.save(updatedParametricFields, {
      optimistic: false,
      patch: true,
      setOnPatchSuccess: false,
      clearSelection: false,
      toast: false
    });

    // and update each Item with the proper `filterField` it should override
    this.get('items').each(item => {
      item.set({
        parametric_overrides: {
          filterField: flatParametricFilterTypes[parametric_field.type][0],
          operator: parametricOperators[parametric_field.type]
        }
      });
    });
  };

  // Helper to get which DashboardItem is selected from the Dashboards items collection
  @computed
  get selectedPanel() {
    return this.get('items') && this.get('items').selected;
  }

  /**
   * Also checks if a `layout` is created so we don't render the GridLayout with invalid props.
   */
  @computed
  get hasItems() {
    const items = this.get('items');
    const hasItems = items && items.size > 0;

    return !!hasItems;
  }

  @computed
  get sortableShareLevel() {
    const map = {
      Preset: 2,
      Shared: 1,
      Private: 0
    };

    return map[this.shareLevel];
  }

  @computed
  get shareLevel() {
    const level = this.isPreset ? 'Preset' : 'Shared';
    return this.isUserLevel ? 'Private' : level;
  }

  @computed
  get parametricField() {
    return this.get('parametric_fields') && this.get('parametric_fields')[0];
  }

  @computed
  get isUserLevel() {
    return this.get('user_id') === $auth.activeUser.id && this.get('share_level') === 'self';
  }

  @computed
  get isPreset() {
    return (
      this.get('share_level') === 'org' &&
      `${this.get('company_id')}` === `${$dictionary.dictionary.templateDashboardsCID}`
    );
  }

  @computed
  get isCompanyLevel() {
    return this.get('share_level') === 'org' && !this.isPreset;
  }

  @computed
  get canEdit() {
    return (
      !$app.isSubtenant &&
      (`${this.get('user_id')}` === `${$auth.activeUser.id}` || // Personal
      ($auth.isAdministrator && this.isCompanyLevel) || // Company
        (this.isPreset && $auth.isPresetCompany)) // Preset
    );
  }

  get iconName() {
    return 'control';
  }

  @computed
  get itemsSortedByLayout() {
    if (!this.hasItems) {
      return [];
    }

    // sort layout, y first, then x
    const layout = this.get('layout');
    const items = this.get('items');
    const sorted = orderBy(layout.lg, ['y', 'x'], ['asc', 'asc']);
    return sorted.map(layoutItem => items.get(parseInt(layoutItem.i)));
  }

  @computed
  get newItemY() {
    if (!this.hasItems) {
      return 0;
    }

    const { max } = Math;
    return this.get('layout').lg.reduce((y, item) => max(y, item.y + item.h), 0);
  }

  @computed
  get isParametric() {
    return !!(this.get('parametric') && this.get('parametric_fields').length);
  }

  @computed
  get fullyLoaded() {
    const items = this.get('items');
    return this.hasItems && items.models.every(item => item.dataview.fullyLoaded);
  }

  @computed
  get isIncompleteParametric() {
    const parametric_fields = this.get('parametric_fields');
    return this.isParametric && (parametric_fields[0].value === undefined || parametric_fields[0].value === null);
  }

  @computed
  get originalQuery() {
    return this._originalQuery || this.get('query');
  }

  @computed
  get canSave() {
    return $auth.isPresetCompany || !this.isPreset;
  }

  @computed
  get itemsWithSelectedModels() {
    const items = this.get('items');
    return this.hasItems ? items.filter(item => item.selectedModels.length, { immutable: true }) : [];
  }

  set query(query) {
    this._originalQuery = this.originalQuery;
    this.set('query', query);
  }

  get messages() {
    return {
      create: `Dashboard "${this.get('dash_title')}" was added successfully`,
      update: `Dashboard "${this.get('dash_title')}" was updated successfully`,
      destroy: `Dashboard "${this.get('dash_title')}" was removed successfully`,
      duplicate: `Dashboard "${this.get('dash_title')}" was duplicated successfully`
    };
  }

  get removalConfirmText() {
    return {
      title: 'Remove Dashboard',
      text: `Are you sure you want to remove ${this.get('dash_title')}?`
    };
  }
}
