import Collection from 'models/Collection';
import Alarm from './Alarm';

class AlarmCollection extends Collection {
  get url() {
    return '/api/portal/alerts-active/alarms';
  }

  get model() {
    return Alarm;
  }
}

export default AlarmCollection;
