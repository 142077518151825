import BaseModel from 'models/BaseModel';
import { computed } from 'mobx';
import UserCollection from 'models/users/UserCollection';
import { addFilter, addFilterGroup, getDefaultFiltersObj } from 'util/utils';
import $sites from 'stores/$sites';

const filterMapping = {
  device_id: 'i_device_id',
  device_site: 'i_device_site_name',
  device_type: 'i_device_type',
  device_name: 'i_device_name',
  interface_name: 'i_input|output_interface_description',
  snmp_alias: 'i_input|output_snmp_alias',
  // interface_id: 'input|output_port',
  cidr: 'inet_src|dst_addr',
  asn: 'src|dst_as'
};

export default class UserGroup extends BaseModel {
  get defaults() {
    return {
      type: 'subtenant',
      config: {
        custom_dimensions: [{}],
        assets: {
          reports: []
        },
        alerts: [],
        devices: {
          all_devices: false,
          device_types: [],
          device_labels: [],
          device_sites: [],
          device_name: []
        }
      },
      users: new UserCollection(undefined, { userGroup: this })
    };
  }

  get urlRoot() {
    return '/api/portal/userGroup';
  }

  get messages() {
    return {
      create: `${this.get('type')} ${this.get('name')} was added successfully`,
      update: `${this.get('type')} ${this.get('name')} was updated successfully`,
      destroy: `${this.get('type')} ${this.get('name')} was removed successfully`
    };
  }

  get omitDuringSerialize() {
    return ['users', 'template'];
  }

  get removalConfirmText() {
    return {
      title: 'Remove Tenant',
      text: `Are you sure you want to remove ${this.get('name')}?`
    };
  }

  @computed
  get numUsers() {
    return this.get('users').size;
  }

  @computed
  get numViews() {
    const reports = this.get('config.assets.reports');
    return reports ? reports.length : 0;
  }

  @computed
  get numAlertPolicies() {
    const alertPolicies = this.get('config.alerts');
    return alertPolicies ? alertPolicies.length : 0;
  }

  deserialize(data) {
    if (!data) {
      return data;
    }

    const { users, config, template } = data;

    if (users && Array.isArray(users)) {
      data.users = new UserCollection(users, { userGroup: this });
    }

    if (config.alerts) {
      data.config.alerts = config.alerts.filter(policy => !policy.is_template);
    }

    data.template_id = undefined;
    data.template = [];

    if (template && template.length) {
      data.template = template[0];
      data.template_id = template[0].id;
    }

    return super.deserialize(data);
  }

  serialize(data) {
    const {
      config,
      config: { devices, interface_name, snmp_alias, cidr, asn, alerts, custom_dimensions }
    } = data;
    const { device_sites, device_name } = devices;

    const filtersObj = getDefaultFiltersObj();

    if (device_sites.length || device_name.length) {
      addFilterGroup(filtersObj, 'Any');

      device_sites.forEach(deviceSiteId => {
        const site = $sites.collection.get(deviceSiteId);
        if (site) {
          addFilter(filtersObj, filterMapping.device_site, '=', site.get('title'));
        }
      });

      device_name.forEach(deviceName => {
        addFilter(filtersObj, filterMapping.device_name, '=', deviceName);
      });
    }

    if (interface_name) {
      addFilterGroup(filtersObj, 'Any');
      addFilter(filtersObj, filterMapping.interface_name, '~*', interface_name, filtersObj.filterGroups.length - 1);
    }

    if (snmp_alias) {
      addFilterGroup(filtersObj, 'Any');
      addFilter(filtersObj, filterMapping.snmp_alias, '~*', snmp_alias, filtersObj.filterGroups.length - 1);
    }

    if (cidr) {
      addFilterGroup(filtersObj, 'Any');
      addFilter(filtersObj, filterMapping.cidr, 'ILIKE', cidr, filtersObj.filterGroups.length - 1);
    }

    if (asn) {
      addFilterGroup(filtersObj, 'Any');
      addFilter(filtersObj, filterMapping.asn, '=', asn, filtersObj.filterGroups.length - 1);
    }

    if (custom_dimensions && custom_dimensions.length) {
      const customDimensionFiltersObj = getDefaultFiltersObj();
      customDimensionFiltersObj.connector = 'Any';
      custom_dimensions.forEach(customDimension => {
        addFilterGroup(customDimensionFiltersObj, 'Any');
        addFilter(
          customDimensionFiltersObj,
          `${customDimension.dimension}`,
          '=',
          customDimension.populator,
          customDimensionFiltersObj.filterGroups.length - 1
        );
      });
      filtersObj.filterGroups.push(customDimensionFiltersObj);
    }

    config.filters = filtersObj;

    // from [10] to [{ id: 10 }]
    config.alerts = alerts.map(alert => ({
      ...alert,
      thresholds: alert.thresholds.map(threshold => ({
        ...threshold,
        notificationChannels: threshold.notificationChannels.map(channelId => ({
          id: channelId
        }))
      }))
    }));

    return super.serialize(data);
  }

  getSortValue(field) {
    if (field === 'template') {
      const template = this.get('template');
      return template ? template.name : 0;
    }
    return super.getSortValue(field);
  }
}
