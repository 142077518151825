import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box } from 'components/flexbox';
import { Form } from 'components/forms/index';

import FilterField from './FilterField';

const fields = {
  filters: {
    isComplexArray: true
  },

  'filters[].helpText': {},
  'filters[].label': {},
  'filters[].filterField': {},
  'filters[].operator': {},
  'filters[].filterValue': {}
};

@Form({ fields })
@inject('$basicExplorer', '$dataviews', '$dictionary')
@observer
class Filters extends Component {
  componentWillReceiveProps() {
    const { $basicExplorer, form } = this.props;

    /**
     * When we load from a hash, we determine which applied Filters come from the Template, and
     * we set our form to reflect that.
     */
    if ($basicExplorer.activeFilters.length) {
      form.getField('filters').fieldStates.forEach(filter => {
        const matchingFilter = $basicExplorer.activeFilters.find(f => f.filterField === filter.filterField.getValue());

        if (matchingFilter) {
          filter.filterValue.setValue(matchingFilter.filterValue);
        }
      });
    } else {
      form.reset();
    }
  }

  // we only care about the Filter states that are not pristine.
  handleFilterChange = () => {
    const { form } = this.props;
    const { $basicExplorer } = this.props;

    const filterValues = form.getField('filters').getValue();

    // only set the filters which have a value.
    const filters = filterValues.filter(filter => filter.filterValue);
    $basicExplorer.setFilters(filters);
  };

  render() {
    const { $basicExplorer, form } = this.props;
    const filtersFieldState = form.getField('filters');

    return (
      <Box mb={1}>
        {filtersFieldState.map(filterState => (
          <FilterField
            onChange={this.handleFilterChange}
            filter={filterState}
            filters={filtersFieldState}
            form={form}
            key={filterState.label._id}
            activeFilters={$basicExplorer.activeFilters}
          />
        ))}
      </Box>
    );
  }
}

export default Filters;
