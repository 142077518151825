import { observable, action, computed, toJS } from 'mobx';

import $dictionary from './$dictionary';

class RecentlyViewedStore {
  @observable
  recentReports = JSON.parse(
    (window.localStorage && $dictionary.dictionary
      ? window.localStorage[`kentik_${$dictionary.dictionary.envName}_recentReports_${this.store.$auth.activeUser.id}`]
      : '[]') || '[]'
  );

  @action
  add(type = 'report', item, options = {}) {
    const { shiftToTop = true } = options;
    const existingViewIdx = this.recentReports.findIndex(view => view.attributes.id === item.get('id'));
    if (existingViewIdx === -1) {
      const clonedView = toJS(item);
      clonedView.lastViewedDate = new Date();
      clonedView.type = type;
      delete clonedView.collection; // need this to eliminate circular references
      this.recentReports.unshift(clonedView);
    } else if (shiftToTop) {
      // if item already present, move it to front so it's "freshly recently used"

      const tempItem = this.recentReports[existingViewIdx];
      this.recentReports.splice(existingViewIdx, 1);
      this.recentReports.unshift(tempItem);
    }

    // limit the list to 10, chop the last one.
    if (this.recentReports.length > 10) {
      this.recentReports.splice(-1, 1);
    }

    this.updateLocalStorage();
    return this.recentReports.length;
  }

  @action
  clear = () => {
    this.recentReports = [];
    this.updateLocalStorage();
  };

  @action
  removeIfExists(item) {
    const idx = this.recentReports.findIndex(view => view.attributes.id === item.get('id'));
    if (idx !== -1) this.recentReports.splice(idx, 1);
    this.updateLocalStorage();
  }

  updateLocalStorage() {
    if (window.localStorage) {
      window.localStorage.setItem(
        `kentik_${$dictionary.dictionary.envName}_recentReports_${this.store.$auth.activeUser.id}`,
        JSON.stringify(this.recentReports)
      );
    }
  }

  @computed
  get reports() {
    const res = [];
    this.recentReports.forEach(view => {
      const found = this.store.$library.reportsCollection.get().find(m => m.id === view.attributes.id);

      if (found) {
        res.push(found);
      }
    });
    return res;
  }
}

export default new RecentlyViewedStore();
