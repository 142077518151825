import { computed } from 'mobx';

import Collection from 'models/Collection';
import Policy from 'models/alerting/Policy';
import api from 'util/api';
import { showErrorToast, showSuccessToast } from 'components/Toast';

class PolicyCollection extends Collection {
  get url() {
    return '/api/portal/alert-policies';
  }

  get model() {
    return Policy;
  }

  copyToMyPolicies(model) {
    return api
      .post(`/api/portal/alert-policies/${model.id}/copy`, { data: { isCopyFromTemplate: false } })
      .then(
        () => showSuccessToast(`Policy '${model.get('policy_name')}' was successfully copied`),
        () => showErrorToast(`Policy '${model.get('policy_name')}' failed to copy`)
      );
  }

  @computed
  get enabledCount() {
    return this.unfiltered.reduce((count, model) => count + (model.policyStatus === 'Enabled' ? 1 : 0), 0);
  }

  @computed
  get policyOptions() {
    return this.unfiltered.map(policy => ({
      value: policy.id,
      label: `${policy.get('policy_name')} (${policy.id})`,
      policy_description: policy.get('policy_description'),
      status: policy.get('status')
    }));
  }
}

export default PolicyCollection;
