import { observable, action } from 'mobx';

import CompanyCollection from 'models/sudo/CompanyCollection';
import api from 'util/api';
import Socket from 'util/Socket';

class SudoStore {
  // @observable collection = new SiteCollection();
  @observable
  companiesCollection = new CompanyCollection();

  @observable
  sessionSpoofed = false;

  @observable
  showSudoEjectButton = false;

  @observable
  sudoOpen = false;

  @observable
  assetMigrationOpen = false;

  selectedCompanyId = null;

  suppressEjectButton = false;

  @action
  clearSelectedCompanyId = () => {
    this.selectedCompanyId = null;
  };

  @action
  setSudoOpen = (isOpen, selectedCompanyId = null) => {
    this.sudoOpen = isOpen;
    this.selectedCompanyId = selectedCompanyId;
  };

  @action
  setAssetMigrationOpen = isOpen => {
    this.assetMigrationOpen = isOpen;
  };

  @action
  launchSudoSession = params => {
    this.suppressEjectButton = true;
    return api.post('/api/sudo/mimic', { data: params });
  };

  @action
  clearSpoofSession = (options = {}) => {
    const { reload } = options;

    this.suppressEjectButton = true;

    return api.get('/api/portal/users/nospoof', {}).then(() => {
      if (reload) {
        window.location.reload();
      }
    });
  };

  sudoSubscribe() {
    const { $auth } = this.store;

    if (!this.sudoSocket && ($auth.isSpoofed || $auth.hasSudo)) {
      this.sudoSocket = new Socket({
        id: $auth.activeUser.chfAdmin ? $auth.activeUser.chfAdmin.id : $auth.activeUser.id,
        outType: 'sudo',
        inType: 'sudo',
        frequency: 30,
        delaySend: true,
        onSuccess: action(() => {
          const { id } = $auth.activeUser;

          if (this.suppressEjectButton) {
            return;
          }

          $auth.verifyAuth().then(
            action(() => {
              this.showSudoEjectButton = id !== $auth.activeUser.id;

              if (this.showSudoEjectButton) {
                document.title = `***${document.title}`;
              }
            })
          );
        }),
        onError(err) {
          console.warn('Received Sudo Error (sudo)', err);
        },
        onReconnect: () => {
          this.sudoSocket.send();
        }
      });
      this.sudoSocket.setPayload({});
      this.sudoSocket.send();
    }
  }
}

export default new SudoStore();
