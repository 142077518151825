import React, { Fragment } from 'react';
import { Button, Dialog, Icon } from '@blueprintjs/core';

import { Flex, Box } from 'components/flexbox';

const TrialDetails = props => {
  const { isOpen, onOpen, onClose } = props;

  return (
    <Fragment>
      <Box mt={2}>
        <p>
          <a
            id="detailsDialogLink"
            className="demo-link"
            onClick={e => {
              e.stopPropagation();
              onOpen();
            }}
          >
            <Icon iconName="plus" size={16} style={{ marginRight: 2 }} /> Free trial details
          </a>
        </p>
      </Box>
      <Dialog
        title="Free trial details"
        isOpen={isOpen}
        style={{ width: '95vw', maxWidth: 640 }}
        transitionName="pt-dialog"
        onClose={onClose}
        className="trial-signup-dialog"
      >
        <Box p={2} className="pt-dialog-body">
          <p className="pt-running-text">
            You will receive a link to your full version, 30-day trial of Kentik via email.{' '}
          </p>
          <p className="pt-running-text pt-medium">SET UP</p>
          <ul className="pt-running-text">
            <li>Built-in help will guide you through setup and ongoing use.</li>
            <li>Configure your network elements (routers, switches) to export flow data to Kentik. (10-15 minutes.)</li>
            <li>For cloud infrastructure (AWS, GCP, Azure), Kentik connects to your VPC flow logs.</li>
            <li>Immediately see and analyze your network data in Kentik!</li>
          </ul>

          <p className="pt-running-text pt-medium">SECURITY</p>
          <ul className="pt-running-text">
            <li>
              Your network data is safe with Kentik. All data is encrypted at rest and can be optionally encrypted in
              transit.
            </li>
          </ul>

          <p className="pt-running-text pt-medium">NEED MORE HELP?</p>
          <p className="pt-running-text">
            Your success is assured with access to the Kentik Knowledge Base and other resources.
          </p>
        </Box>
        <Box p={2}>
          <Flex justify="flex-end">
            <Button style={{ marginRight: 8 }} onClick={onClose} text="Close" />
          </Flex>
        </Box>
      </Dialog>
    </Fragment>
  );
};

export default TrialDetails;
